import { store } from 'redux/store';

import { createSociety as createSocietyAction } from 'modules/society/redux/societyActions';

const DEFAULT_LANG = 'es';

const createSociety = async (
  societyData: any,
  userId: string,
  updateUser: boolean = true
) => {
  try {
    const {
      name,
      cif,
      legalAddress,
      language,
      sendNotifications,
      hasAccessCaptable,
      isSocietyHolding,
    } = societyData;

    const society = {
      name,
      cif,
      legalAddress: {
        line1: legalAddress?.line1 || '',
        zip: legalAddress?.zip || '',
        city: legalAddress?.city || '',
        country: legalAddress?.country || '',
      },
      language: language || DEFAULT_LANG,
      creationDate: new Date(),
      mainAdmin: userId,
      administrators: userId,
      hasHoldingClasses: true,
      // optionals
      sendNotifications,
      hasAccessCaptable,
      isSocietyHolding,
    };

    const result = await store.dispatch(
      createSocietyAction(society, true, updateUser)
    );
    return result;
  } catch (error) {
    throw new Error(String(error));
  }
};

export default createSociety;
