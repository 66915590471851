import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  name: string;
  handleChangeName: any;
};

const AddCommitteeModalBody: FC<Props> = ({
  name,
  handleChangeName,
}: Props) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-12">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="committee-name">
                {t('Name')}
              </label>

              <input
                id="committee-name"
                type="text"
                className="form-control form-control-lg"
                value={name}
                onChange={handleChangeName}
                placeholder={t('IntroduceCommitteeName')}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AddCommitteeModalBody;
