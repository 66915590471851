import { useTranslate } from 'hooks/useTranslate';
import { useHistory } from 'react-router-dom';
import { isMobileScreen } from 'utils/getScreenSize';

type GoBackButtonProps = {
  className?: string;
  icon?: string;
  label?: string;
  onClick?: () => void;
  customAction?: boolean;
};

const GoBackButton = (props: GoBackButtonProps) => {
  const { t } = useTranslate();
  const history = useHistory();

  const { className, icon, label, onClick, customAction } = props;

  const buttonClass = `btn btn-outline-light ${className || ''}`;

  const buttonIcon = `icon ni ${icon || 'ni-arrow-left'}`;

  const buttonLabel = label || t('Back');

  const buttonOnClick = () => {
    if (!customAction) history.goBack();
    if (onClick) onClick();
  };

  return (
    <button type="button" className={buttonClass} onClick={buttonOnClick}>
      <em className={buttonIcon} />
      {!isMobileScreen() && <span>{buttonLabel}</span>}
    </button>
  );
};

export default GoBackButton;
