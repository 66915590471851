/* eslint-disable no-console */
import { Partner, Society } from 'types';
import { getOtherUsers } from './getOtherUsers';

const getOtherUsersFromPartner = (partner: Partner) =>
  partner?.otherUsers?.length > 0
    ? partner?.otherUsers?.map((user) => user)
    : [];

export const getSocietySecondaryEmails = async (actualSociety: Society) => {
  try {
    const userIdsByPartner: any = {};

    const partnersWithShares = actualSociety?.partners.filter(
      (partner: Partner) => partner.sharesCount.actual > 0
    );

    if (!partnersWithShares.length) {
      throw new Error();
    }

    partnersWithShares.forEach((partner: Partner) => {
      const userIds = getOtherUsersFromPartner(partner);
      userIdsByPartner[partner._id] = userIds;
    });

    const partnersWithOtherUsersAndEmails = await getOtherUsers(
      userIdsByPartner
    );

    return partnersWithOtherUsersAndEmails;
  } catch (error) {
    console.error(error);
    return {};
  }
};
