import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

type Props = {
  partner: Partner;
  handleChangePartnerData: any;
};

const OtherDataView: FC<Props> = ({ partner, handleChangePartnerData }) => {
  const { t } = useTranslate();

  const [bankAccountNumber, setBankAccountNumber] = useState<string>(
    partner?.bankAccountNumber || ''
  );
  const [hideAccountNumber, setHideAccountNumber] = useState<boolean>(
    !!partner?.bankAccountNumber
  );

  const handleHideAccountNumber = () => {
    setHideAccountNumber(!hideAccountNumber);
  };

  const getMaskValue = (value: string) => {
    if (!value) return '';
    return value?.replace(/.(?=.{4})/g, '*');
  };

  const handleChangeBankAccountNumber = (event: any) => {
    const partnerBankAccountNumber = event.target.value;
    setBankAccountNumber(partnerBankAccountNumber);
    handleChangePartnerData({
      ...partner,
      bankAccountNumber: partnerBankAccountNumber,
    });
  };

  return (
    <>
      <Modal.Body>
        <div className="tab-pane active">
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="account-number">
                  {t('BankAccountNumber')}
                </label>
                <div className="form-control-wrap">
                  <div
                    className="form-icon form-icon-right"
                    onClick={handleHideAccountNumber}
                  >
                    {hideAccountNumber ? (
                      <em className="icon ni ni-eye cursor-pointer" />
                    ) : (
                      <em className="icon ni ni-eye-off cursor-pointer" />
                    )}
                  </div>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="account-number"
                    placeholder={t('EnterIBAN')}
                    disabled={hideAccountNumber}
                    value={
                      hideAccountNumber
                        ? getMaskValue(bankAccountNumber)
                        : bankAccountNumber
                    }
                    onChange={handleChangeBankAccountNumber}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default OtherDataView;
