/* eslint-disable react/jsx-props-no-spreading */
import {
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import React from 'react';
import NumberFormat from 'react-number-format';
import { getNameInitials } from 'utils/filters';

const generateKey = (data) => {
  const index = data?.index || '';
  const name = data?.name.toLowerCase().replace(/\s/g, '') || '';
  const buyerName = data?.buyerName.toLowerCase().replace(/\s/g, '') || '';

  return `${index}-${name}-${buyerName}`;
};

function DraftSellSharesRow({ index, data, print }) {
  const {
    name,
    email,
    buyerName,
    totalShares,
    shareClass,
    shareFrom,
    shareTo,
    sharePrice,
    totalPrice,
    totalND,
  } = data;

  return (
    <>
      <tr className="nk-tb-item" key={generateKey(data)}>
        <td className="nk-tb-col">
          <span className="tb-amount">{index}</span>
        </td>

        <td className="nk-tb-col">
          <div className="user-card">
            {!print && (
              <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                <span>{getNameInitials(name)}</span>
              </div>
            )}

            <div className="user-info">
              <span className="tb-lead">{name}</span>
              {!print && <span>{email}</span>}
            </div>
          </div>
        </td>

        {print && (
          <td className="nk-tb-col  text-left">
            <span>{email}</span>
          </td>
        )}

        <td className="nk-tb-col  text-left">
          <span>{buyerName}</span>
        </td>

        <td className="nk-tb-col text-right">
          <span className="tb-amount">
            <NumberFormat
              value={totalShares}
              displayType="text"
              {...numberFormat}
            />
          </span>
        </td>

        <td className="nk-tb-col  text-left">
          <span>{shareClass}</span>
        </td>

        <td className="nk-tb-col text-right">
          <span className="tb-amount">
            <NumberFormat
              value={shareFrom}
              displayType="text"
              {...numberFormat}
            />
          </span>
        </td>

        <td className="nk-tb-col text-right">
          <span className="tb-amount">
            <NumberFormat
              value={shareTo}
              displayType="text"
              {...numberFormat}
            />
          </span>
        </td>

        <td className="nk-tb-col text-right">
          <span className="tb-amount">
            <NumberFormat
              value={sharePrice}
              displayType="text"
              {...currencyFormatDecimals}
            />
          </span>
        </td>

        <td className="nk-tb-col text-right">
          <span className="tb-amount">
            <NumberFormat
              value={totalPrice}
              displayType="text"
              {...currencyFormatDecimals}
            />
          </span>
        </td>

        <td className="nk-tb-col text-right">
          <span className="tb-amount">
            <NumberFormat
              value={totalND}
              displayType="text"
              suffix="%"
              {...numberFormatDecimals}
            />
          </span>
        </td>
      </tr>
    </>
  );
}

export default DraftSellSharesRow;
