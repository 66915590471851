import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import NumberFormat from 'react-number-format';

import { numberFormat } from 'constants/formats';

import { Range } from 'types';

type Props = {
  validRanges: Range[];
  // eslint-disable-next-line no-unused-vars
  handleClickRange: (range: Range) => void;
};

const ShareEncumbranceViewFormAvailableRanges: FC<Props> = ({
  validRanges,
  handleClickRange,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex">
          <div className="form-label mb-0">{t('Available')}:</div> &nbsp;
          {validRanges?.length > 0 ? (
            validRanges
              .filter((range: Range) => range?.to > range?.from)
              .map((range: Range) => (
                <>
                  <b
                    className="badge btn-primary ml-1 cursor-pointer align-items-center"
                    onClick={() => handleClickRange(range)}
                  >
                    <NumberFormat
                      displayType="text"
                      value={range?.from}
                      thousandSeparator={numberFormat.thousandSeparator}
                      decimalSeparator={numberFormat.decimalSeparator}
                    />
                    &nbsp;-&nbsp;
                    <NumberFormat
                      displayType="text"
                      value={range?.to}
                      thousandSeparator={numberFormat.thousandSeparator}
                      decimalSeparator={numberFormat.decimalSeparator}
                    />
                    &nbsp;
                  </b>
                </>
              ))
          ) : (
            <span className="sub-text text-danger">
              <i>{t('NoRangesAvailable')}</i>
            </span>
          )}
        </div>
      </div>
      {validRanges?.length > 0 && (
        <span className="sub-text">
          <i>{t('AvailableSharesInfo')}</i>
        </span>
      )}
    </>
  );
};

export default ShareEncumbranceViewFormAvailableRanges;
