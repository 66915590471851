import React from 'react';
import i18n from 'i18n/config';

import 'assets/styles/Components.scss';

export const generateVotationToast = (data) => {
  const { order, name, vote, picture } = data;
  const { text, style } = vote;

  return `
    <div class="d-flex align-center py-2" style="width: 500px">
      <div class="user-avatar bg-dim-primary d-flex ml-2 mr-3">
        ${
          picture
            ? `<img src='${picture}' alt="partner" class="rounded-circle" />`
            : `<span>${name ? name.slice(0, 2).toUpperCase() : ''}</span>`
        }
        <div class='status dot dot-lg dot-${style}'></div>
      </div>

      <div class="flex-column">
        <span
          class="d-block text-secondary fw-bold pb-1"
        >
          ${i18n.t('NewVoteAlertTitle')}
        </span>
        <span class="py-0 pr-2 mt-1">
          ${name}&nbsp;
          ${i18n.t('NewVotePersonHasVoted')}
          <b>${text}</b>&nbsp;
          ${i18n.t('NewVoteInTheSubjectNr')}
          ${order}
        </span>
      </div>
    </div>`;
};

export const PartnersOnlineBoardToast = () => (
  <div className="user-avatar bg-dim-primary d-flex">
    <img src="hola" alt="" className="rounded-circle" />
  </div>
);

/**
 * Backup of different toast versions
 */

/* ICON VERSION
  <div class="nk-block d-flex flex-row justify-content-between my-4">
    <div
      class="d-flex align-center p-2"
      style={{
        maxWidth: '450px',
        border: '1px solid #dbdfea',
        borderRadius: '6px',
        borderBottom: '4px solid #798bff',
      }}
    >
      <div class="bg-dim-primary d-flex ml-1 mr-2">
        <img
          src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGVEz9mqHDE-rU5TGPqfgGd8=/sweetalert-2023-01-04%2000-00-00-2023-06-22%2017-51-49"
          alt=""
          width="50"
        />
      </div>
      <div class="flex-column">
        <span
          class="text-secondary fw-bold pb-2"
          style={{ lineHeight: '30px' }}
        >
          ¡Nuevo voto recibido!
        </span>

        <br />
        <span class="py-0 pr-2 mt-1">
          Fulanito Pérez ha votado <b>Sí</b> en el Asunto No.2
        </span>
      </div>
    </div>
    <div
      class="d-flex align-center p-2"
      style={{
        maxWidth: '450px',
        border: '1px solid #dbdfea',
        borderRadius: '6px',
        borderBottom: '4px solid #798bff',
      }}
    >
      <div class="bg-dim-primary d-flex ml-1 mr-2">
        <img
          src="https://i.ibb.co/17JGNKc/Captura-de-pantalla-2023-06-23-a-la-s-3-01-04-p-m.png"
          alt=""
          width="50"
          style={{ padding: '5px' }}
        />
      </div>
      <div class="flex-column">
        <span
          class="text-secondary fw-bold pb-2"
          style={{ lineHeight: '30px' }}
        >
          ¡Nuevo voto recibido!
        </span>

        <br />
        <span class="py-0 pr-2 mt-1">
          Fulanito Pérez ha votado <b>No</b> en el Asunto No.2
        </span>
      </div>
    </div>
  </div>
*/

/* AVATER BORDERED VERSION
  <div class="nk-block d-flex flex-row justify-content-between my-4">
    <div
      class="d-flex align-center p-2"
      style={{
        maxWidth: '450px',
        border: '1px solid #dbdfea',
        borderRadius: '6px',
        borderBottom: '4px solid #798bff',
      }}
    >
      <div class="user-avatar bg-dim-primary d-flex ml-2 mr-3">
        <img
          src="https://dashlite.net/demo4/images/avatar/b-sm.jpg"
          alt=""
          class="rounded-circle"
          style={{
            border: '3px solid #1ee0ac',
          }}
        />
      </div>
      <div class="flex-column">
        <span
          class="text-secondary fw-bold pb-2"
          style={{ lineHeight: '30px' }}
        >
          ¡Nuevo voto recibido!
        </span>

        <br />
        <span class="py-0 pr-2 mt-1">
          Fulanito Pérez ha votado <b>Sí</b> en el Asunto No.2
        </span>
      </div>
    </div>
    <div
      class="d-flex align-center p-2"
      style={{
        maxWidth: '450px',
        border: '1px solid #dbdfea',
        borderRadius: '6px',
        borderBottom: '4px solid #798bff',
      }}
    >
      <div class="user-avatar bg-dim-primary d-flex ml-2 mr-3">
        <img
          src="https://dashlite.net/demo4/images/avatar/b-sm.jpg"
          alt=""
          class="rounded-circle"
          style={{
            border: '3px solid #e85347',
          }}
        />
      </div>
      <div class="flex-column">
        <span
          class="text-secondary fw-bold pb-2"
          style={{ lineHeight: '30px' }}
        >
          ¡Nuevo voto recibido!
        </span>

        <br />
        <span class="py-0 pr-2 mt-1">
          Fulanito Pérez ha votado <b>No</b> en el Asunto No.2
        </span>
      </div>
    </div>
  </div>
  */
