import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { Accordion, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

type Props = {
  societyId: string;
};

const DEFAULT_LABEL = 'Home';

const CustomLandingMenu: FC<Props> = ({ societyId }) => {
  const { t } = useTranslate();
  const history = useHistory();

  const menuLabel =
    useSelector(
      (state: State) =>
        state.society?.actualSociety?.configuration?.customLanding?.menuLabel
    ) || DEFAULT_LABEL;

  const goToCustomLandingPage = () => {
    history.push(`/home/${societyId}`);
  };

  return (
    <Accordion.Toggle as={Button} variant="link" eventKey="0">
      <li className="nk-menu-item" onClick={goToCustomLandingPage}>
        <a className="nk-menu-link">
          <span className="nk-menu-icon">
            <em className="icon ni ni-home" />
          </span>
          <span className="nk-menu-text">{t(menuLabel)}</span>
        </a>
      </li>
    </Accordion.Toggle>
  );
};

export default CustomLandingMenu;
