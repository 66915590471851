import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import InputError from 'modules/_shared/components/InputError';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import { actions } from '../constants/actions';

type Props = {
  action: (typeof actions)[keyof typeof actions];
  name: string;
  cif: string;
  email: string;
  address: string;
  jobTitle: string;
  validEmail: boolean;
  // eslint-disable-next-line no-unused-vars
  handleChangeName: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeCif: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeEmail: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeAddress: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeJobTitle: (event: any) => void;
};

const AddBeneficiaryModalPersonalInfoView: FC<Props> = ({
  action,
  name,
  cif,
  email,
  address,
  jobTitle,
  validEmail,
  handleChangeName,
  handleChangeCif,
  handleChangeEmail,
  handleChangeAddress,
  handleChangeJobTitle,
}) => {
  const { t } = useTranslate();

  const isFormDisabled = action === actions.EDIT;

  return (
    <>
      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-name">
          {t('NameAndSurnameOfTheBenefi')}
        </label>
        <div className="form-control-wrap">
          <input
            type="text"
            className="form-control form-control-lg"
            id="beneficiary-name"
            name="beneficiary-name"
            value={name}
            placeholder={t('NameAndSurname')}
            onChange={handleChangeName}
            required
            disabled={isFormDisabled}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-identity">
          {t('NifPassportNie')}
        </label>
        <div className="form-control-wrap">
          <input
            type="text"
            className="form-control form-control-lg"
            id="beneficiary-identity"
            name="beneficiary-identity"
            value={cif}
            onChange={handleChangeCif}
            placeholder={t('NifNoPassportOrDen')}
            required
            disabled={isFormDisabled}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-email">
          {t('BeneficiaryEmail')}
        </label>
        <div className="form-control-wrap">
          <input
            type="email"
            className="form-control form-control-lg"
            id="beneficiary-email"
            name="beneficiary-email"
            placeholder={t('EmailOfTheNewBeneficiary')}
            value={email}
            onChange={handleChangeEmail}
            pattern={EMAIL_PATTERN}
            required
            disabled={isFormDisabled}
          />
        </div>
        {!validEmail && <InputError errorMessage={t('InvalidEmail')} />}
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-address">
          {t('Address')}
        </label>
        <div className="form-control-wrap">
          <input
            type="text"
            className="form-control form-control-lg"
            id="beneficiary-address"
            name="beneficiary-address"
            value={address}
            placeholder={`${t('Address')} (${t('Optional')})`}
            onChange={handleChangeAddress}
            disabled={isFormDisabled}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-job">
          {t('JobTitle')}
        </label>
        <div className="form-control-wrap">
          <input
            type="text"
            className="form-control form-control-lg"
            id="beneficiary-job"
            name="beneficiary-job"
            placeholder={t('JobTitleOptional')}
            value={jobTitle}
            onChange={handleChangeJobTitle}
            disabled={isFormDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default AddBeneficiaryModalPersonalInfoView;
