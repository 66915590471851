/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select, { StylesConfig } from 'react-select';

import { useSelector } from 'react-redux';
import { Board, Partner, Society } from 'types';

import PartnerAvatarInfo from 'modules/_shared/components/PartnerAvatarInfo';
import IconTooltip from 'modules/_shared/components/Icon/IconTooltip';
import { isPartnerOfUser, getPartnerFromSociety } from '../../../utils';

const SELECTOR_WIDTH = '300px';
const GRAY = '#dedede';
const LIGHT_GRAY = '#f2f2f2';
const WHITE = '#ffffff';

type Props = {
  currentPartner: Partner;
  currentBoard: Board;
  currentSociety: Society;
};
type Option = {
  value: string;
  label: string;
  name: string;
  email: string;
  image: string;
  societyId: string;
  haveStatus?: boolean;
  haveLink?: boolean;
};

const BoardPartnerUsersCard: FC<Props> = ({
  currentPartner,
  currentBoard,
  currentSociety,
}) => {
  const { t } = useTranslate();
  const history = useHistory();

  const user = useSelector((state: any) => state.user);

  const [partnerOptions, setPartnerOptions] = useState<Option[]>([]);
  const [selectedPartnerOption, setSelectedPartnerOption] = useState<Option>({
    value: '',
    label: '',
    name: '',
    email: '',
    image: '',
    societyId: '',
    haveStatus: false,
    haveLink: false,
  });

  const customStyles: StylesConfig<any, false> = {
    option: (provided, state) => ({
      ...provided,
      width: SELECTOR_WIDTH,
      backgroundColor: state.isSelected ? GRAY : WHITE,
      '&:hover': {
        backgroundColor: state.isSelected ? state.data.value : LIGHT_GRAY,
      },
      ':active': {
        backgroundColor: state.isSelected ? GRAY : 'inherit',
      },
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      width: SELECTOR_WIDTH,
      border: 'none',
      boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    }),
    menu: (provided) => ({
      ...provided,
      width: SELECTOR_WIDTH,
    }),
  };

  const redirectToSelectedPatnerVote = (partnerId: string) => {
    history.push(
      `/voto-junta/${currentSociety._id}/${currentBoard._id}/${partnerId}`
    );
  };

  const handleChangeSelectedPartnerOption = (option: Option) => {
    redirectToSelectedPatnerVote(option.value);
  };

  const formatOptionLabel = (data: any) => (
    <div style={{ display: 'flex' }}>
      <PartnerAvatarInfo
        id={data.value}
        name={data.name}
        email={data.email}
        image={data.image}
        societyId={data.societyId}
        haveStatus={data.haveStatus}
        haveLink={data.haveLink}
      />
    </div>
  );

  const getPartnerOptions = (): Option[] => {
    const options: Option[] = [];

    currentBoard?.participants?.forEach((participant) => {
      if (participant?.member !== currentPartner._id) {
        const partner = getPartnerFromSociety(
          participant.member,
          currentSociety
        );
        if (partner && isPartnerOfUser(partner, user)) {
          options.push({
            value: partner._id,
            label: partner.name,
            name: partner.name,
            email: partner.email,
            image: partner.image,
            societyId: currentSociety._id,
            haveStatus: false,
            haveLink: false,
          });
        }
      }
    });

    return options;
  };

  useEffect(() => {
    if (currentPartner && currentBoard && currentSociety) {
      const { _id, email, image, name } = currentPartner;
      setSelectedPartnerOption({
        value: _id,
        label: name,
        name,
        email,
        image,
        societyId: currentSociety._id,
        haveStatus: true,
        haveLink: true,
      });
      const options = getPartnerOptions();
      setPartnerOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartner, currentBoard, currentSociety]);

  return (
    <div className="board-partner-participants-card card card-bordered p-2 w-fit-content">
      <div className="d-flex">
        <h5>{t('SelectPartner')}</h5>
        <IconTooltip
          id="partner-card-tooltip"
          tooltip={t('BoardPartnerUsersCardTooltip')}
        />
      </div>

      <Select
        value={selectedPartnerOption}
        formatOptionLabel={formatOptionLabel}
        isSearchable={false}
        name="color"
        options={partnerOptions}
        styles={customStyles}
        onChange={handleChangeSelectedPartnerOption}
      />
    </div>
  );
};

export default BoardPartnerUsersCard;
