import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';

import boardTypes from 'constants/boardTypes';
import votePeriods from 'constants/votePeriods';
import { formatDateToTime } from 'constants/formats';
import attendanceTypes from 'constants/attendanceTypes';

type Props = {
  date: string;
  place: string;
  attendanceType: string;
  votePeriod: string;
  boardType: string;
};

const BoardVotesBasicInfo = ({
  date,
  place,
  attendanceType,
  votePeriod,
  boardType,
}: Props) => {
  const { t } = useTranslate();

  return (
    <div className="card-inner sp-plan-desc sp-plan-desc-mb">
      <ul className="row gx-1">
        <li className="col-12 col-md-6 col-lg-4">
          <p>
            <span className="text-soft">{t('Date')}</span>
            {format(new Date(date), 'dd/MM/yyyy')}
          </p>
        </li>

        <li className="col-12 col-md-6 col-lg-4">
          <p>
            <span className="text-soft">{t('StartHour')}</span>
            {formatDateToTime(date)}
          </p>
        </li>

        {attendanceType === attendanceTypes.FACE_TO_FACE.value ||
        attendanceType === attendanceTypes.HYBRID.value ? (
          <li className="col-12 col-md-6 col-lg-4">
            <p>
              <span className="text-soft">{t('PlaceOfCelebration')}</span>
              {place || '-'}
            </p>
          </li>
        ) : (
          <></>
        )}

        <li className="col-12 col-md-6 col-lg-4">
          <p>
            <span className="text-soft">{t('AssistanceForm')}</span>
            {attendanceTypes?.[attendanceType as keyof typeof attendanceTypes]
              ?.text || '-'}
          </p>
        </li>

        <li className="col-12 col-md-6 col-lg-4">
          <p>
            <span className="text-soft">{t('VotingPeriod')}</span>
            {votePeriods.find((period) => period.value === votePeriod)?.text ||
              '-'}
          </p>
        </li>

        <li className="col-12 col-md-6 col-lg-4">
          <p>
            <span className="text-soft">{t('BoardType')}</span>
            {boardTypes?.[boardType as keyof typeof boardTypes]?.text || '-'}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default BoardVotesBasicInfo;
