/* eslint-disable new-cap */
/* eslint-disable no-plusplus */

import 'jspdf-autotable';

const jsPDF = require('jspdf');

type Certificate = {
  header: string;
  subHeader: string;
  tableColumns: string[];
  tableData: Record<string, any>[];
  summaryTableColumns: string[];
  summaryTableData: string[];
  paragraph: string;
  footerText: string;
  signerName: string;
  signerJob: string;
};

type Props = {
  html: string;
  template?: string;
  fileName?: string;
  certificate?: Certificate;
};

const headRows = (columns: string[]) => [
  {
    id: columns[0],
    name: columns[1],
    shares: columns[2],
    percent: columns[3],
    representative: columns[4],
    attend: columns[5],
    signature: columns[6],
  },
];

const summaryHeadRows = (columns: string[]) => [
  {
    assistants: columns[0],
    represented: columns[1],
    absents: columns[2],
    quorum: columns[3],
  },
];

const bodyRows = (rows: Record<string, any>) =>
  rows.map((row: any, index: number) => ({
    id: index + 1,
    name: row.name,
    shares: row.sharesCount,
    percent: `${row.percentage?.toFixed(3)}%`,
    representative: row.representative?.name || '-',
    attend: row.attend,
    signature: row.member,
  }));

const summaryBodyRows = (row: string[]) => [
  {
    assistants: row[0],
    represented: row[1],
    absents: row[2],
    quorum: row[3],
  },
];

const showText = (text: string | undefined, doc: any, pageWidth: number) =>
  doc.splitTextToSize(text, pageWidth - 28, {});

const htmlToPdfFile = async ({ fileName, certificate }: Props) => {
  try {
    const doc = new jsPDF();
    const { pageSize } = doc.internal;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();

    if (certificate?.header) {
      doc.setFontSize(14);
      doc.setFontStyle('bold');
      doc.text(showText(certificate?.header, doc, pageWidth), 14, 20);
    }

    if (certificate?.subHeader) {
      doc.setFontSize(11);
      doc.setFontStyle('normal');
      doc.text(showText(certificate?.subHeader, doc, pageWidth), 14, 36);
    }

    doc.setTextColor(100);

    if (certificate?.tableColumns && certificate?.tableData) {
      const startY = certificate?.subHeader ? 56 : 36;
      doc.setFontSize(10);
      doc.autoTable({
        theme: 'grid',
        head: headRows(certificate?.tableColumns || []),
        body: bodyRows(certificate?.tableData || []),
        startY,
        showHead: 'everyPage',
        headStyles: {
          fillColor: '#f3f3f3',
          textColor: '#333333',
          fontSize: 9,
        },
        bodyStyles: {
          minCellHeight: 12,
          valign: 'middle',
        },
        columnStyles: {
          signature: { valign: 'bottom', textColor: '#fff', fontSize: 6 },
        },
      });
    }

    if (certificate?.paragraph) {
      doc.setFontSize(11);
      doc.text(
        showText(certificate?.paragraph, doc, pageWidth),
        14,
        doc.lastAutoTable.finalY + 16
      );
    }

    if (certificate?.summaryTableColumns && certificate?.summaryTableData) {
      doc.autoTable({
        theme: 'plain',
        head: summaryHeadRows(certificate?.summaryTableColumns || []),
        body: summaryBodyRows(certificate?.summaryTableData || []),
        startY: doc.lastAutoTable.finalY + 36,
        showHead: 'everyPage',
      });
    }

    doc.setFontSize(11);

    if (certificate?.footerText) {
      doc.text(
        showText(certificate?.footerText, doc, pageWidth),
        14,
        doc.lastAutoTable.finalY + 16
      );
    }

    if (certificate?.signerName) {
      doc.text(
        showText(certificate?.signerName, doc, pageWidth),
        14,
        doc.lastAutoTable.finalY + 30
      );
    }

    if (certificate?.signerJob) {
      doc.text(
        showText(certificate?.signerJob, doc, pageWidth),
        14,
        doc.lastAutoTable.finalY + 38
      );
    }

    const fileBlob = doc.output('blob');

    const pdfFile = fileBlob
      ? new File([fileBlob], `${fileName}.pdf`, {
          type: 'application/pdf',
        })
      : null;

    return pdfFile;
  } catch (error) {
    throw new Error(String(error));
  }
};

export default htmlToPdfFile;
