/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import {
  MAX_FILESIZE_ALLOWED,
  PDF_TYPE_FILE_ALLOWED,
} from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import presetRecipients from 'constants/presetRecipients';
import signatureTypes from 'constants/signatureTypes';
import subcategoryTypes from 'constants/subcategoryTypes';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';
import { startSignProcess, toBase64 } from 'utils/docusignActions';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import Swal from 'sweetalert2';
import getEnvelopeData from 'utils/getEnvelopeData';

const ALL = 'ALL';

function SignatureModal() {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();
  const { actualSociety, user } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
  }));

  const [file, setFile] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [signer, setSigner] = useState('');
  const [message, setMessage] = useState('');
  const [selectedSignerOption, setSelectedSignerOption] = useState();
  const [signerOptions, setSignerOptions] = useState([]);
  const [signersList, setSignersList] = useState([]);
  const [saved, setSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const placeholderLabels = {
    [presetRecipients.PARTNERS]: t('SelectPartner'),
    [presetRecipients.BENEFICIARIES]: t('SelectBeneficiary'),
    [presetRecipients.DIRECTORS]: t('SelectDirectorship'),
    [presetRecipients.INVESTORS]: t('SelectInvestor'),
  };

  const roleToApi = {
    [presetRecipients.PARTNERS]: 'PARTNER',
    [presetRecipients.BENEFICIARIES]: 'BENEFICIARY',
    [presetRecipients.DIRECTORS]: 'DIRECTOR',
    [presetRecipients.INVESTORS]: 'INVESTOR',
  };

  const signaturesByRole = {
    [presetRecipients.PARTNERS]: signatureTypes.FREE_SIGNATURE_PARTNER,
    [presetRecipients.BENEFICIARIES]: signatureTypes.FREE_SIGNATURE_BENEF,
    [presetRecipients.DIRECTORS]: signatureTypes.FREE_SIGNATURE_DIRECTOR,
    [presetRecipients.INVESTORS]: signatureTypes.FREE_SIGNATURE_INVESTOR,
  };

  const signaturesByRoleAll = {
    [presetRecipients.PARTNERS]: signatureTypes.FREE_SIGNATURE_PARTNER_ALL,
    [presetRecipients.BENEFICIARIES]: signatureTypes.FREE_SIGNATURE_BENEF_ALL,
    [presetRecipients.DIRECTORS]: signatureTypes.FREE_SIGNATURE_DIRECTOR_ALL,
    [presetRecipients.INVESTORS]: signatureTypes.FREE_SIGNATURE_INVESTOR_ALL,
  };

  const getSignersData = (signerId) => {
    let signers = [];

    if (signerId === ALL) {
      signers = signersList.slice(1).map((signer) => ({
        id: signer.id,
        email: signer.email,
        name: signer.name,
        role: roleToApi[selectedRole],
        type: signaturesByRoleAll[selectedRole],
      }));
    } else {
      const signerData = signersList.find((signer) => signer.id === signerId);
      signers = [
        {
          id: signerId,
          email: signerData?.email,
          name: signerData?.name,
          role: roleToApi[selectedRole],
          type: signaturesByRole[selectedRole],
        },
      ];
    }

    return signers;
  };

  const sendSignature = async (e) => {
    e.preventDefault();

    setSaved(true);
    setIsLoading(true);

    try {
      const { name, size, type } = file;

      const newSignature = {
        name,
        file: null,
        size,
        date: new Date(),
        fileType: fileTypes[type],
        lastAccess: new Date(),
        isGenerated: false,
        author: user?._id,
        society: actualSociety?._id,
        category: documentTypes.SIGNATURES,
        subcategory: subcategoryTypes.SIGNATURES.PENDING,
        description: t('SentByUsername', {
          name: user?.name,
        }),
        url: '',
      };
      const uploadeddocument = await store.dispatch(addDocument(newSignature));

      const signers = getSignersData(signer);

      let beneficiaries = [];
      if (selectedRole === presetRecipients.BENEFICIARIES) {
        beneficiaries = signersList.map((beneficiary) => ({
          id: beneficiary.id,
          planId: beneficiary.plan,
        }));
      }

      const envelopeData = getEnvelopeData({
        signers,
        signType: signaturesByRole[selectedRole],
        fileName: name,
        fileType: fileTypes[type],
        linkedType: roleToApi[selectedRole],
        role: selectedRole,
        userId: user?._id,
        societyId: actualSociety?._id,
        beneficiaries,
        documentId: uploadeddocument?._id,
      });

      const signFileBase64 = await toBase64(file);

      const signProcessData = {
        signers,
        societyName: actualSociety?.name,
        documentName: name,
        planName: '',
        signatureType: signaturesByRole[selectedRole],
        language: actualSociety?.societyLanguage || 'es',
        message,
        signatureFile: signFileBase64,
        signatureFileName: name,
        envelopeData,
      };

      await startSignProcess(signProcessData);

      dispatch(setModal(null));
      Swal.fire({
        icon: 'success',
        title: `<h4 class="nk-modal-title">${t('DocumentSentToSign')}<br></h4>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    } catch (error) {
      console.log(error); // eslint-disable-line
    } finally {
      setIsLoading(false);
    }
  };

  const getPartnersList = () => {
    const filteredPartners = actualSociety?.partners?.filter(
      (partner) => partner?.sharesCount?.actual > 0
    );

    const partnersList = filteredPartners.map((partner) => ({
      id: partner?.['_id'],
      text: `${partner?.name} | ${partner?.email}`,
      name: partner?.name,
      email: partner?.email,
    }));
    return partnersList;
  };

  const getBeneficiariesList = () => {
    const filteredBeneficiaries = actualSociety?.beneficiaries?.filter(
      (beneficiary) =>
        !beneficiary?.isDraft &&
        (!beneficiary?.isCanceled ||
          (beneficiary?.isCanceled && beneficiary?.finalConsolidatedUnits > 0))
    );
    const beneficiariesList = filteredBeneficiaries?.map((beneficiary) => ({
      id: beneficiary?.['_id'],
      text: `${beneficiary?.name} | ${beneficiary?.email}`,
      name: beneficiary?.name,
      email: beneficiary?.email,
      plan: beneficiary?.plan,
    }));
    return beneficiariesList;
  };

  const getInvestorsList = () => {
    const investorsList = actualSociety?.investors?.map((investor) => ({
      id: investor?.['_id'],
      text: `${investor?.name} | ${investor?.email}`,
      name: investor?.name,
      email: investor?.email,
    }));
    return investorsList;
  };

  const isActiveDate = (date) => {
    if (!date) return true;
    const today = new Date();
    const dateToCompare = new Date(date);
    return today < dateToCompare;
  };

  const getDirectorsList = () => {
    const filteredDirectors = actualSociety?.directors?.filter(
      (director) =>
        (director?.directorStatus === 'ACTIVE' && director?.email !== '') ||
        (!director?.directorStatus && isActiveDate(director?.endDate))
    );

    const directorsList = filteredDirectors.map((director) => ({
      id: director?.['_id'],
      text: `${director?.socialDenomination} | ${director?.email}`,
      name: director?.socialDenomination,
      email: director?.email,
    }));
    return directorsList;
  };

  const getSignersList = (role) => {
    let list = [];
    switch (role) {
      case presetRecipients.PARTNERS:
        list = getPartnersList();
        break;

      case presetRecipients.BENEFICIARIES:
        list = getBeneficiariesList();
        break;

      case presetRecipients.INVESTORS:
        list = getInvestorsList();
        break;

      case presetRecipients.DIRECTORS:
        list = getDirectorsList();
        break;

      default:
        break;
    }

    const totalSigners = list?.length || 0;

    if (totalSigners !== 0) {
      const allOption = {
        id: ALL,
        text: `${t('All')} (${totalSigners})`,
      };
      setSignersList([allOption, ...list]);
    } else {
      setSignersList([]);
    }
  };

  const handleSetRole = (event) => {
    const role = event.target.value;
    if (role) {
      setSigner('');
      setSelectedSignerOption(null);
      setSelectedRole(role);
      getSignersList(role);
    }
  };

  const handleChangeSignerOption = (selectedOption) => {
    setSelectedSignerOption(selectedOption);
    setSigner(selectedOption.value);
  };

  useEffect(() => {
    const options = signersList.map((signer) => ({
      value: signer?.id,
      label: signer?.text,
    }));

    setSignerOptions(options);
  }, [signersList]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('SignNewDocument')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => store.dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={sendSignature}>
          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              {t('AddDocument')}
            </label>
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  id="customFile"
                  type="file"
                  accept={PDF_TYPE_FILE_ALLOWED}
                  className="custom-file-input"
                  onChange={(event) => setFile(event.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {file?.name || t('SelectDocument')}
                </label>
              </div>
            </div>

            <div className="sub-text mt-1">
              {t('MaxSizeAllowed')} = <code>{MAX_FILESIZE_ALLOWED.text}</code>
              {' | '}
              {t('ActualFileSize')} ={' '}
              <code>
                {(file && (file.size / 1024 / 1024).toFixed(2)) || 0}mb
              </code>
            </div>

            {file && file.size > MAX_FILESIZE_ALLOWED.value && (
              <div className="alert alert-icon alert-danger mt-2" role="alert">
                <em className="icon ni ni-alert-circle" />
                <strong>{t('InvalidFile')}</strong>.{' '}
                {t('ExceedsMaximumSizePermitted')}.
              </div>
            )}
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('SelectSignerRole')}
            </label>

            <ul className="custom-control-group d-flex flex-row justify-content-between">
              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolPartner"
                    value={presetRecipients.PARTNERS}
                    onChange={handleSetRole}
                  />
                  <label className="custom-control-label" htmlFor="rolPartner">
                    {t(presetRecipients.PARTNERS)}
                  </label>
                </div>
              </li>

              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolBeneficiary"
                    value={presetRecipients.BENEFICIARIES}
                    onChange={handleSetRole}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="rolBeneficiary"
                  >
                    {t(presetRecipients.BENEFICIARIES)}
                  </label>
                </div>
              </li>

              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolInvestor"
                    value={presetRecipients.INVESTORS}
                    onChange={handleSetRole}
                  />
                  <label className="custom-control-label" htmlFor="rolInvestor">
                    {t(presetRecipients.INVESTORS)}
                  </label>
                </div>
              </li>

              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolDirector"
                    value={presetRecipients.DIRECTORS}
                    onChange={handleSetRole}
                  />
                  <label className="custom-control-label" htmlFor="rolDirector">
                    {t(presetRecipients.DIRECTORS)}
                  </label>
                </div>
              </li>
            </ul>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('Select')}
            </label>
            <div className="form-control-wrap">
              <Select
                closeMenuOnSelect
                className="react-select"
                value={selectedSignerOption}
                options={signerOptions}
                components={animatedComponents}
                onChange={handleChangeSignerOption}
                placeholder={placeholderLabels[selectedRole] || t('Select')}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('Message')}
            </label>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  className="form-control form-control-lg no-resize"
                  placeholder={t('TypeHere')}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!file || !selectedRole || !signer || !message || saved}
          >
            {t('Send')}
          </button>
        </form>
        {isLoading && <CustomLoading />}
      </Modal.Body>
    </>
  );
}

export default SignatureModal;

/*
https://app.sttok.com/?signed=%3fredirect%3dsocios%2f64158b7c4e0cfc51020805bb%2f64158be34e0cfc51020805d5
*/
