/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import {
  formatCurrencyDecimals,
  formatCurrencyDecimalsLong,
} from 'utils/formats';
import { getTheme } from 'utils/theme';
import { isMobileScreen } from 'utils/getScreenSize';
import { getActualSociety, kFormatter } from 'utils/filters';

import menuTypes from 'constants/menuTypes';

import { setMenu } from 'modules/_shared/redux/menuActions';
import WidgetChartDoughnut, {
  legendModes,
} from 'modules/dashboard/components/WidgetChartDoughnut';
import WidgetList from 'modules/dashboard/components/WidgetList';
import WidgetChartBar from 'modules/dashboard/components/WidgetChartBar';
import WidgetChartLine from 'modules/dashboard/components/WidgetChartLine';
import WidgetTableSimple from 'modules/dashboard/components/WidgetTableSimple';
import WidgetTableExtended from 'modules/dashboard/components/WidgetTableExtended';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import LegalDashboard from 'modules/dashboard/pages/LegalDashboard';
import useGetSocietyDashboardData from 'modules/society/hooks/useGetSocietyDashboardData';
import { useGetDashboardSectionPermissions } from 'modules/dashboard/hooks/useGetDashboardSectionPermissions';

import { hasShowDetails } from 'utils/showDetails';
import { getDataCapitalDetailsChart } from './getDataCapitalDetailsChart';

const dashboardTypes = {
  STANDARD: 'STANDARD',
  LEGAL: 'LEGAL',
};

const dashboardIcons = {
  STANDARD: 'ni-chart-up',
  LEGAL: 'ni-list-thumb',
};

const SocietyDashboard = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const printRef = useRef();

  const user = useSelector((state) => state?.user);
  const role = useSelector((state) => state.society?.role);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const { isAdmin, isReadOnly } = useGetDashboardSectionPermissions().data;
  const { data, loadingState } = useGetSocietyDashboardData();

  const [selectedTheme, setSelectedTheme] = useState(getTheme());
  const [dashboardTypeSelected, setDashboardTypeSelected] = useState(
    dashboardTypes.STANDARD
  );

  const societyName = actualSociety?.name;

  // TODO: Implement loading state for each widget
  const isLoading = Object.values(loadingState).some((value) => value);

  const handleSwitchDashboardType = (e) => {
    setDashboardTypeSelected(e.target.value);
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId || !role)
      getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]); // eslint-disable-line

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = getTheme();
      setSelectedTheme(theme);
    });
  }, []);

  return (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <PageListHeader
            subTitle={societyName}
            description={`${t('SocietyDashboardTitle')} ${societyName}`}
            actions={
              isAdmin && [
                <ReactToPrint
                  trigger={() => (
                    <button
                      type="button"
                      id="screen-only"
                      className="btn btn-white btn-outline-light"
                    >
                      <em className="icon ni ni-printer-fill" />
                      <span>{t('Print')}</span>
                    </button>
                  )}
                  content={() => printRef.current}
                  documentTitle={`${actualSociety?.name} - Sttok ${format(
                    new Date(),
                    'dd-MM-yyyy'
                  )}`}
                />,
                <div className="form-group cursor-pointer">
                  <div className="form-control-wrap ">
                    <div className="form-control-select">
                      <div className="form-icon form-icon-left">
                        <em
                          className={`icon fw-bold text-base ni ${dashboardIcons[dashboardTypeSelected]}`}
                        />
                      </div>
                      <select
                        id="dashboard-view"
                        name="dashboard-view"
                        value={dashboardTypeSelected}
                        onChange={handleSwitchDashboardType}
                        className="form-control cursor-pointer fw-bold text-base pr-4"
                      >
                        <option
                          value={dashboardTypes.STANDARD}
                          className="cursor-pointer"
                        >
                          {t('ViewStandardDashboard')}
                        </option>
                        <option
                          value={dashboardTypes.LEGAL}
                          className="cursor-pointer"
                        >
                          {t('ViewLegalDashboard')}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>,
              ]
            }
          />

          <div
            className="nk-block d-flex flex-wrap justify-content-between"
            ref={printRef}
            id="margin-print-only"
          >
            <PrintHeader title={t('PrintHeaderDashboardSociety')} />

            {dashboardTypeSelected === dashboardTypes.LEGAL ? (
              <LegalDashboard />
            ) : (
              <div className="row">
                {data?.summaryChartViewData && (
                  <WidgetChartLine
                    title={t('CompanySummary')}
                    subtitle={t('CompanyMainIndicators', { societyName })}
                    kpis={[
                      {
                        title: t('Partners'),
                        value: kFormatter(
                          data?.summaryChartViewData?.kpisData?.partnersCount
                        ),
                        icon: 'ni-user',
                        link: `/socios/${actualSociety?._id}`,
                      },
                      {
                        title: t('Shares'),
                        value: kFormatter(
                          data?.summaryChartViewData?.kpisData
                            ?.societyActualShares
                        ),
                        icon: 'ni-layers',
                        link: `/socios/${actualSociety?._id}`,
                      },
                      !isMobileScreen()
                        ? {
                            title: t('Nominal'),
                            value: formatCurrencyDecimalsLong(
                              data?.summaryChartViewData?.kpisData
                                ?.societyNominalValue
                            ),
                            icon: 'ni-layers',
                            link: `/socios/${actualSociety?._id}`,
                          }
                        : null,
                      {
                        title: t('NoteConv.'),
                        value: kFormatter(
                          data?.summaryChartViewData?.kpisData
                            ?.convertibleNotesShares
                        ),
                        icon: 'ni-invest',
                        link: `/borradores/${actualSociety?._id}`,
                      },
                      {
                        title: t('Incentives'),
                        value: kFormatter(
                          data?.summaryChartViewData?.kpisData?.plansUsedShares
                        ),
                        icon: 'ni-layers',
                        link: `/beneficiarios-planes/${actualSociety?._id}`,
                      },
                    ].filter((item) => item)}
                    data={data?.summaryChartViewData}
                    extraLabel={t('SocialCapital')}
                    extraValue={formatCurrencyDecimals(
                      data?.summaryChartViewData?.kpisData
                        ?.societyActualShares *
                        data?.summaryChartViewData?.kpisData
                          ?.societyNominalValue || 0
                    )}
                    isReadOnly={isReadOnly}
                  />
                )}

                {/* {loadingState?.summaryChartViewData && (
                  <div className="col-12 col-xl-7 mb-4">
                    <div className="card card-bordered h-100">
                      <WidgetChartLineLoader />{' '}
                    </div>
                  </div>
                )} */}

                {data?.financialChartViewData && (
                  <WidgetChartBar
                    title={t('Financing')}
                    subtitle={t('FinancingEvolution')}
                    height={222}
                    kpis={[
                      {
                        title: t('Shar.'),
                        value: kFormatter(
                          data?.financialChartViewData?.kpisData
                            ?.societyActualShares
                        ),
                        icon: 'ni-layers',
                      },
                      {
                        title: t('Investment.'),
                        value: kFormatter(
                          data?.financialChartViewData?.kpisData?.inversionValue
                        ),
                        icon: 'ni-invest',
                      },
                      {
                        title: t('ValueShar.'),
                        value: `${data?.financialChartViewData?.kpisData?.sharesValue?.toFixed(
                          2
                        )}€`,
                        icon: 'ni-layers',
                      },
                    ]}
                    data={data?.financialChartViewData}
                  />
                )}

                {/* {loadingState?.financialChartViewData && (
                  <div className="col-12 col-xl-5 mb-4">
                    <div className="card card-bordered h-100">
                      <WidgetChartLineLoader metricCount={3} buttonCount={3} />{' '}
                    </div>
                  </div>
                )} */}

                {data?.partnersViewData && (
                  <WidgetTableExtended
                    title={t('MainPartners')}
                    linkText={t('SeeAlls')}
                    linkUrl={`/socios/${societyId}`}
                    columns={[t('Partner'), t('Shar'), t('%ND'), t('%FD')]}
                    data={data?.partnersViewData}
                    isReadOnly={isReadOnly}
                  />
                )}

                {data?.partnersChartViewData && (
                  <WidgetChartDoughnut
                    title={t('Partners')}
                    linkText={t('SeeAlls')}
                    linkUrl={`/socios/${societyId}`}
                    data={data?.partnersChartViewData?.chart}
                    legendMode={legendModes.VERTICAL}
                    legend={data?.partnersChartViewData?.partners}
                    isReadOnly={isReadOnly}
                  />
                )}

                {data?.capitalViewData && (
                  <WidgetTableSimple
                    title={t('Capital')}
                    subtitle={t('CapitalDistributionTitle')}
                    data={data?.capitalViewData}
                  />
                )}

                {data?.capitalChartViewData && (
                  <WidgetChartDoughnut
                    title={t('CapitalDetail')}
                    data={getDataCapitalDetailsChart({
                      capitalChartViewData: data?.capitalChartViewData,
                      selectedTheme,
                      t,
                    })}
                    legendMode={legendModes.HORIZONTAL}
                    legend={[
                      {
                        title: t('Share.'),
                        icon: 'ni-reports-alt',
                        color: '#798bff',
                        value: kFormatter(
                          data?.capitalChartViewData?.societyActualShares
                        ),
                        percent:
                          data?.capitalChartViewData?.societyActualSharesPercentage?.toFixed(
                            2
                          ),
                      },
                      {
                        title: t('NoteConv.'),
                        icon: 'ni-report-profit',
                        color: '#b8acff',
                        value: kFormatter(
                          data?.capitalChartViewData?.convertibleNotesShares
                        ),
                        percent:
                          data?.capitalChartViewData?.convertibleNotesSharesPercentage?.toFixed(
                            2
                          ),
                      },
                      {
                        title: t('StockOptions'),
                        icon: 'ni-users',
                        color: '#ffa9ce',
                        value: kFormatter(
                          data?.capitalChartViewData?.stockOptionsShares
                        ),
                        percent:
                          data?.capitalChartViewData?.stockOptionsSharesPercentage?.toFixed(
                            2
                          ),
                      },
                      {
                        title: t('Phantoms'),
                        icon: 'ni-users-fill',
                        color: '#7de1f8',
                        value: kFormatter(
                          data?.capitalChartViewData?.phantomShares
                        ),
                        percent:
                          data?.capitalChartViewData?.phantomSharesPercentage?.toFixed(
                            2
                          ),
                      },
                    ]}
                  />
                )}

                {data?.plansViewData && (
                  <WidgetTableSimple
                    title={t('IncentivePlans')}
                    subtitle={t('SocietyHasXPlans', {
                      societyName,
                      plansCount: data.plansViewData.length - 1, // One row is the total
                    })}
                    linkText={t('SeeAlls')}
                    linkUrl={`/beneficiarios-planes/${societyId}`}
                    data={data?.plansViewData}
                    isReadOnly={isReadOnly}
                  />
                )}

                {data?.operationsViewData && (
                  <WidgetList
                    title={t('Transactions')}
                    subtitle={t('LastTransactions')}
                    linkText={t('SeeAll')}
                    linkUrl={`/transacciones/${societyId}`}
                    data={data?.operationsViewData}
                    isReadOnly={isReadOnly}
                  />
                )}

                {data?.beneficiariesViewData && (
                  <WidgetTableExtended
                    title={t('MainBeneficiaries')}
                    linkText={t('SeeAlls')}
                    linkUrl={`/beneficiarios/${societyId}`}
                    columns={[t('Beneficiary'), t('Unit'), t('%FD')]}
                    data={data?.beneficiariesViewData}
                    isReadOnly={isReadOnly}
                  />
                )}

                {data?.draftsViewData && (
                  <WidgetTableExtended
                    title={t('OperationsSimulator')}
                    linkText={t('SeeAlls')}
                    linkUrl={`/borradores/${societyId}`}
                    columns={[t('Operation'), t('Amount')]}
                    data={data?.draftsViewData}
                    isReadOnly={isReadOnly}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {isLoading && <CustomLoading />}
    </>
  );
};

export default SocietyDashboard;
