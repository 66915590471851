/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import Swal from 'sweetalert2';
import { store } from 'redux/store';
import wNumb from 'wnumb';

import { updateSociety } from 'modules/society/redux/societyActions';
import { format } from 'date-fns';

import eventTypes from 'constants/eventTypes';
import trackEvent from './trackEvent';

export default function deleteSocietyValue(params) {
  const {
    user,
    societyId,
    societyName,
    societyValueId,
    societyValue,
    societyValueDate,
    mixpanel,
  } = params;

  const currencyFormat = wNumb({
    decimals: 0,
    thousand: '.',
    suffix: ' €',
  });

  const date = format(new Date(societyValueDate), 'dd/MM/yyyy');
  const value = currencyFormat.to(societyValue);

  Swal.fire({
    icon: 'warning',
    title:
      '<h4 class="nk-modal-title">¿Está seguro de que desea eliminar este Valor de la sociedad?</h4>',
    html: `<h5 class="text-primary">${date} | ${value}</h5><br /><div class="caption-text">Este procedimiento no se puede deshacer.</div>`,
    confirmButtonText: 'Ok, eliminar',
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch(
        updateSociety(societyId, { societyValue: { remove: societyValueId } })
      );

      trackEvent(mixpanel, eventTypes.DELETE_SOCIETY_VALUE, {
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
        societyId,
        societyName,
        operation: eventTypes.DELETE_SOCIETY_VALUE,
      });
    }
  });
}
