/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import Nouislider from 'nouislider-react';
import NumberFormat from 'react-number-format';

import { SocietyValue } from 'types';
import { currencyFormatDecimals } from 'constants/formats';

import { getSliderConfig } from './utils/getSliderConfig';

import './SocietyValueSimulator.scss';

type BeneficiarySocietyValueSimulatorProps = {
  societyValue: SocietyValue;
  onChange: (value: number[]) => void; // eslint-disable-line
  valuation: number;
  onClickSimulatorButton?: () => void;
};

const BeneficiarySocietyValueSimulator: FC<
  BeneficiarySocietyValueSimulatorProps
> = ({ societyValue, onChange, valuation, onClickSimulatorButton }) => {
  const { t } = useTranslate();

  const sliderStep = societyValue
    ? +((societyValue.value * 10) / 100).toFixed(2)
    : 0;

  const hasFutureValues = !!((societyValue?.futureValues?.length || 0) > 0);

  return (
    <div className="card-inner">
      <div className="overline-title-alt text-center pb-3">
        {t('SocietyValueSimulator')}
      </div>

      {societyValue?.value && (
        <div
          className={`form-control-wrap m-5 ${
            hasFutureValues
              ? 'noUI-slider-multi-steps'
              : 'noUI-slider-single-step'
          }`}
        >
          <Nouislider
            {...getSliderConfig({
              societyValue,
              sliderStep,
              onChange,
            })}
          />
        </div>
      )}

      <div className="overline-title-alt text-center mt-5 pt-4">
        {t('SimulatedIncentiveTotalValue')}
      </div>

      <div className="profile-balance-amount mt-2">
        <div className="number text-center d-flex justify-content-center align-items-start">
          <NumberFormat
            value={valuation || 0}
            displayType="text"
            {...currencyFormatDecimals}
          />
        </div>
      </div>

      <div className="profile-balance-subtitle text-center text-muted">
        {t('TotalIncentiveValueDescription')}
      </div>

      <div className="mt-4 text-center">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={onClickSimulatorButton}
        >
          {t('CalculateIncentiveValue')}
        </button>
      </div>
    </div>
  );
};

export default BeneficiarySocietyValueSimulator;
