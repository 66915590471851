import { BoardNewParticipant } from 'types';

export const getDelegationDocumentsFromParticipantsList = (
  participants: BoardNewParticipant[]
): string[] => {
  if (!participants || !participants?.length) {
    return [];
  }

  const documents: string[] = [];

  participants.forEach((participant) => {
    if (participant?.delegationDocuments?.length) {
      documents.push(...participant.delegationDocuments);
    }
  });

  return documents;
};
