import { PartnerRepresentant, Society, User } from "types";

type GetMemberIdProps = {
  user: User;
  society: Society;
  partnerRepresentants: PartnerRepresentant[];
}

const extractIds = (entities: { _id: string }[]) => entities.map(entity => entity._id);
const getUserMemberIds = (user: User) => [...(user?.partners || []), ...(user?.partnerRepresentants || [])];


export const getMemberId = ({ user, society, partnerRepresentants}: GetMemberIdProps): string => {
  if (!user || !society) return '';

  const societyPartnersId = extractIds(society.partners);
  const societyRepresentantsId = extractIds(partnerRepresentants);

  const societyMembersId = [...societyPartnersId, ...societyRepresentantsId];
  const userMembers = getUserMemberIds(user);

  const memberId = societyMembersId.length
    ? userMembers.find((member) => societyMembersId?.includes(member))
    : null;

  return memberId || '';
};
