/* eslint-disable no-console */
import { updateBoard } from 'modules/boards/redux/boardActions';
import { getPartnerFromParticipant } from 'modules/boards/utils';
import { store } from 'redux/store';
import { Board, Society } from 'types';

const getUpdatedParticipants = (
  currentBoard: Board,
  actualSociety: Society
) => {
  let hasDifferentEmails = false;

  const updatedParticipants = currentBoard?.participants?.map((participant) => {
    const currentParticipant = participant;
    const currentPartner = getPartnerFromParticipant(
      participant,
      actualSociety
    );

    if (currentPartner?.email !== currentParticipant.email) {
      hasDifferentEmails = true;
      currentParticipant.email =
        currentPartner?.email || currentParticipant.email;
    }

    return currentParticipant;
  });

  return { updatedParticipants, hasDifferentEmails };
};

export const updateParticipantsEmails = async (
  currentBoard: Board,
  actualSociety: Society
) => {
  if (!currentBoard?.participants || !actualSociety) return;

  try {
    const { updatedParticipants, hasDifferentEmails } = getUpdatedParticipants(
      currentBoard,
      actualSociety
    );

    if (hasDifferentEmails) {
      await store.dispatch(
        updateBoard(
          currentBoard['_id'],
          { participants: updatedParticipants },
          false
        )
      );

      return true;
    }

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};
