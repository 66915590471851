import MenuDots from 'modules/_shared/components/MenuDots';
import { dateFormat } from 'constants/formats';
import userTypes from 'constants/userTypes';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import menuOptions from './menuOptions';

function UserWithAccessRow({ userId, admin }) {
  const [lastAccess, setLastAccess] = useState('');
  const [verification, setVerification] = useState('');

  useEffect(() => {
    setVerification(
      admin?.verificationDate &&
        format(new Date(admin?.verificationDate), dateFormat)
    );
    setLastAccess(
      admin?.lastAccess && format(new Date(admin?.lastAccess), dateFormat)
    );
  }, [admin]);

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col">
        <span>{admin?.name || ''}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{verification}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{lastAccess}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{admin?.email}</span>
      </td>

      <td className="nk-tb-col">
        <span className="badge badge-dot badge-danger">Completo</span>
      </td>

      <td className="nk-tb-col tb-tnx-action">
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={menuOptions}
                id={admin?.['_id']}
                params={{
                  data: admin,
                  userType: userTypes.USER,
                  userId,
                }}
              />
            </div>
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default UserWithAccessRow;
