/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import NoPreviewImage from 'assets/images/no-preview.jpg';

import './PDFViewer.scss';

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const DEFAULT_WIDTH = 466;
const DEFAULT_HEIGHT = 660;

const PDFPreview = ({ file, width, height }) => {
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="pdf--container" style={{ height }}>
      <Document
        file={file}
        options={options}
        onLoadSuccess={onDocumentLoadSuccess}
        noData={<img src={NoPreviewImage} alt="" width="100%" />}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            height={height || DEFAULT_HEIGHT}
            width={width || DEFAULT_WIDTH}
          />
        ))}
      </Document>
    </div>
  );
};

export default PDFPreview;
