/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import { SelectorOption } from 'types';

type Props = {
  laboralCategory: string;
  handleChangeLaboralCategory: (event: any) => void;
};

export function useGetLaboralCategorySelectedOption({
  laboralCategory,
  handleChangeLaboralCategory,
}: Props) {
  const [selectedLaboralCategoryOption, setSelectedLaboralCategoryOption] =
    useState<SelectorOption>();

  const handleChangeLaboralCategoryOption = (option: any) => {
    const { value } = option;
    handleChangeLaboralCategory(value);
  };

  useEffect(() => {
    try {
      const parsedCategory = JSON.parse(laboralCategory);
      const option = {
        value: laboralCategory,
        label: parsedCategory?.name,
      };
      setSelectedLaboralCategoryOption(option);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Invalid JSON string in laboralCategory:', error);
      setSelectedLaboralCategoryOption(undefined);
    }
  }, [laboralCategory]);

  return {
    data: {
      selectedLaboralCategoryOption,
    },
    isLoading: false,
    isError: false,
    actions: {
      handleChangeLaboralCategoryOption,
    },
  };
}
