import boardTypes from 'constants/boardTypes';
import { formatDateToTime } from 'constants/formats';
import { format } from 'date-fns';
import { Board, Society, User } from 'types';
import { translate } from 'hooks/useTranslate';
import { boardDelegationRoles } from '../constants/boardDelegation';

type CreateDelegationEmailProps = {
  user: User;
  selectedRepresentant: any;
  currentBoard: Board;
  currentDelegation: any;
  actualSociety: Society;
  delegationFile: File;
};

const templatesByRol = {
  [boardDelegationRoles.PARTNER]: 'notify-delegate-es',
  [boardDelegationRoles.PARTNER_REPRESENTANT]:
    'notify-delegate-representant-es',
};

const getDelegationEmailData = (props: CreateDelegationEmailProps) => {
  const {
    user,
    selectedRepresentant,
    currentBoard,
    currentDelegation,
    actualSociety,
    delegationFile,
  } = props;

  const role = currentDelegation.participant?.hasPartnerRepresentant
    ? boardDelegationRoles.PARTNER_REPRESENTANT
    : boardDelegationRoles.PARTNER;

  const boardTime = currentBoard.date
    ? formatDateToTime(currentBoard.date)
    : '';

  const boardDate = currentBoard.date
    ? format(new Date(currentBoard.date), 'dd/MM/yyyy')
    : '';

  const boardType =
    boardTypes?.[currentBoard?.boardType as keyof typeof boardTypes]?.text ||
    '-';

  const boardId = currentBoard['_id'];
  const societyId = actualSociety['_id'];
  const selectedRepresentantId =
    role === boardDelegationRoles.PARTNER_REPRESENTANT
      ? selectedRepresentant._id
      : selectedRepresentant.member;

  return {
    to: [
      {
        email: selectedRepresentant?.email,
        name: selectedRepresentant?.name,
      },
    ],
    file: delegationFile,
    subject: translate('VoteDelegation'),
    template: templatesByRol[role], // TODO: Add template in other languages and update here
    templateData: [
      {
        name: 'partnerName',
        content: currentDelegation.partner?.name || '',
      },
      {
        name: 'partnerCif',
        content: currentDelegation.partner?.cif || '',
      },
      {
        name: 'delegateName',
        content: selectedRepresentant?.name || '',
      },
      {
        name: 'boardDate',
        content: boardDate,
      },
      {
        name: 'boardTime',
        content: boardTime,
      },
      {
        name: 'boardType',
        content: boardType,
      },
      {
        name: 'societyName',
        content: actualSociety?.name || '',
      },
      {
        name: 'targetUrl',
        content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-junta/${societyId}/${boardId}/${selectedRepresentantId}`,
      },
    ],
    user: user['_id'],
    society: actualSociety['_id'],
    date: new Date(),
  };
};

export const createDelegationEmail = (props: CreateDelegationEmailProps) => {
  const emailData = getDelegationEmailData(props);

  return emailData;
};
