import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { store } from 'redux/store';

import restrictedview from 'assets/images/restricted-view.svg';

import TabHeader from 'modules/_shared/components/Headers/TabHeader';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

import AddAdmin from './components/AddAdmin';
import InformationComponent from './components/InformationComponent';
import SocietyAdministratorTable from './components/SocietyAdministratorTable';
import useFetchAdmins from './hooks/useFetchAdmins';

const SocietyAdministrator = () => {
  const { t } = useTranslate();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const { isAdmin, isReadOnly } = useGetSocietySectionPermissions().data;

  const { data: societyAdmins } = useFetchAdmins({
    actualSociety,
    user,
    isAdmin,
  });

  const handleAddAdmin = async () => {
    if (isReadOnly) return;

    await store.dispatch(
      !isReadOnly && setModal(<AddAdmin societyId={actualSociety?.['_id']} />)
    );
  };

  return (
    <div className="nk-block">
      <TabHeader
        title={t('ManagerUsersOfTheCompany')}
        subTitle={t('HereYouCanGiveAccessToOth')}
        actions={[
          <button
            type="button"
            className="btn btn-success"
            key="add-admin-button"
            data-testid="add-admin-button"
            onClick={handleAddAdmin}
            disabled={isReadOnly}
          >
            {t('AddUsers')}
          </button>,
        ]}
      />

      {!isReadOnly ? (
        <SocietyAdministratorTable societyAdmins={societyAdmins} />
      ) : (
        <div className="d-flex justify-content-center">
          <InformationComponent
            mainMessage={t('RestrictedInfo')}
            infoMessage={t('NoAccessToThisInfo')}
            imageIcon={restrictedview}
          />
        </div>
      )}
    </div>
  );
};

export default SocietyAdministrator;
