/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

import i18n from 'i18n/config';

import {
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import draftTypes from 'constants/draftTypes';
import { DATE_FORMAT_SHORT } from 'constants/defaultConstants';

import MenuDots from 'modules/_shared/components/MenuDots';
import menuOptions from 'modules/drafts/components/DraftSafeRow/menuOptions';

function DraftSafeRow({
  partner,
  draft,
  addPartnerToDraft,
  updatePartnerToDraft,
  valuation,
  premium,
  societyDiscountPercent,
  dueDate,
  setTouched,
  removePartnerFromDraft,
}) {
  const {
    name,
    email,
    contribution,
    realContribution,
    contributionDate,
    discountPercent,
    NDTotal,
    FDTotal,
    FDPotential,
    shares,
  } = partner;

  const [firstName, lastName] = name?.split(' ') ?? [];
  const [currentMenuOptions] = useState(menuOptions(i18n));

  const userInitials = lastName
    ? `${firstName[0]}${lastName[0]}` // get 2 first letters of the firstname
    : firstName?.substring(0, 2);

  return (
    <>
      <tr className="nk-tb-item">
        {/* CHECK COLUMN hidden until decide if would be usable */}
        {/* <td className="nk-tb-col nk-tb-col-check">
          <div className="custom-control custom-control-sm custom-checkbox notext">
            <input type="checkbox" className="custom-control-input" id="uid1" />
            <label className="custom-control-label" htmlFor="uid1" />
          </div>
        </td> */}
        <td className="nk-tb-col">
          <div className="user-card">
            <div className="user-avatar bg-dim-primary d-none d-sm-flex">
              <span style={{ lineHeight: '26px' }}>
                {userInitials.toUpperCase()}
              </span>
            </div>
            <div className="user-info">
              <span className="tb-lead">
                {name}
                <span className="dot dot-success d-md-none ml-1" />
              </span>
              <span>{email}</span>
            </div>
          </div>
        </td>
        <td className="nk-tb-col tb-col-md">
          <span className="tb-date">
            {contributionDate
              ? format(new Date(contributionDate), DATE_FORMAT_SHORT)
              : '-'}
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount d-flex flex-column">
            <NumberFormat
              value={realContribution || contribution}
              displayType="text"
              {...currencyFormatDecimals}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            <NumberFormat
              value={discountPercent}
              displayType="text"
              suffix="%"
              {...numberFormatDecimals}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <span className="tb-amount">
            <NumberFormat
              value={shares.new}
              displayType="text"
              {...numberFormat}
            />
            <span className="currency" />
          </span>
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <span className="tb-amount">
            <NumberFormat
              value={shares.total}
              displayType="text"
              {...numberFormat}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <ul className="list-status text-right">
            <li className="pr-0">
              <em className="icon text-success ni ni-pie-fill" />
              <span>
                <NumberFormat
                  value={NDTotal}
                  displayType="text"
                  suffix="%"
                  {...numberFormatDecimals}
                />
              </span>
            </li>
          </ul>
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <ul className="list-status text-right">
            <li className="pr-0">
              <em className="icon text-success ni ni-pie-fill" />
              <span>
                <NumberFormat
                  value={FDTotal}
                  displayType="text"
                  suffix="%"
                  {...numberFormatDecimals}
                />
              </span>
            </li>
          </ul>
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <ul className="list-status text-right">
            <li className="pr-0">
              <em className="icon text-success ni ni-pie-fill" />
              <span>
                <NumberFormat
                  value={FDPotential}
                  displayType="text"
                  suffix="%"
                  {...numberFormatDecimals}
                />
              </span>
            </li>
          </ul>
        </td>
        <td className="nk-tb-col nk-tb-col-tools">
          <div className="nk-file-actions">
            <div className="tb-lead">
              <MenuDots
                menuOptions={currentMenuOptions}
                params={{
                  addPartnerToDraft,
                  updatePartnerToDraft,
                  removePartnerFromDraft,
                  valuation,
                  premium,
                  action: 'EDIT',
                  partnerData: partner,
                  currentDraft: draft,
                  discountPercent: societyDiscountPercent,
                  dueDate,
                  draftType: draftTypes.DRAFT_SAFE,
                  setTouched,
                }}
                direction="left"
              />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default DraftSafeRow;
