/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import { updateBoard } from 'modules/boards/redux/boardActions';

import '../Modals.scss';

function EditAnnouncementModal({ board }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [announcement, setAnnouncement] = useState(board?.announcement || '');

  async function handleSaveAnnouncement(event) {
    event.preventDefault();
    try {
      dispatch(updateBoard(board['_id'], { announcement }));
      dispatch(setModal(null));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setModal(null));
    }
  }

  return (
    <>
      <form
        className="form-validate is-alter"
        onSubmit={handleSaveAnnouncement}
      >
        <Modal.Header
          style={{ borderBottom: 'none', padding: '36px 40px 0 40px' }}
        >
          <h5 className="modal-title">{t('CommunicationTextTitle')}</h5>
          <a
            className="close cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </Modal.Header>
        <Modal.Body style={{ margin: '0px 16px 0px 16px' }}>
          <div className="form-group">
            <p className="text-soft">{t('CommunicationTextDescription')}</p>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  className="form-control form-control-lg no-resize"
                  placeholder={t('CommunicationTextPlaceholder')}
                  value={announcement || ''}
                  onChange={(event) => setAnnouncement(event.target.value)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: 'none', padding: '0px 40px 36px 40px' }}
        >
          <button
            type="button"
            className="btn btn-lg btn-outline-primary border-0"
            onClick={() => dispatch(setModal(null))}
          >
            {t('Cancel')}
          </button>
          <button type="submit" className="btn btn-lg btn-primary">
            {t('Save')}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}

export default EditAnnouncementModal;
