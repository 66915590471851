/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import EmployeeAside from 'modules/beneficiaries/components/EmployeeAside';
import EmployeeHistoric from 'modules/beneficiaries/components/EmployeeHistoric';
import EmployeePersonal from 'modules/beneficiaries/components/EmployeePersonal';
import EmployeeSociety from 'modules/beneficiaries/components/EmployeeSociety';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getEmployeeById } from 'modules/beneficiaries/redux/employeeActions';

import cleanCIF from 'utils/cleanCIF';
import { getEmployeeCIF, getEmployeeFirstEffectiveDate } from 'utils/employees';
import { getActualSociety } from 'utils/filters';

import { dateFormat } from 'constants/formats';
import screens from 'constants/screens';

import menuTypes from 'constants/menuTypes';
import './EmployeeDetail.scss';

const EmployeeDetail = ({ initialPage = 0 }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, employeeId, pageId } = useParams();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [actualEmployee, setActualEmployee] = useState();
  const [employeeIncentiveData, setEmployeeIncentiveData] = useState(null);

  const [incorporationDate, setIncorporationDate] = useState(null);
  const [page, setPage] = useState(+pageId || initialPage);
  const [pages, setPages] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [screenShowAside, setScreenShowAside] = useState(screens.XL);

  const getEmployeeIdData = async (employeeId) => {
    try {
      const employeeData = await dispatch(getEmployeeById(employeeId));
      setActualEmployee(employeeData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (employeeId) {
      getEmployeeIdData(employeeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  useEffect(() => {
    if (user && actualSociety && actualEmployee) {
      if (actualEmployee) {
        const firstEffectiveDate =
          getEmployeeFirstEffectiveDate(actualEmployee);

        const formattedDate = firstEffectiveDate
          ? format(new Date(firstEffectiveDate), dateFormat)
          : '-';

        setIncorporationDate(formattedDate);

        const employeeCIF = getEmployeeCIF(actualEmployee);

        const employeeBeneficiaries =
          actualSociety?.beneficiaries?.filter(
            (beneficiary) =>
              cleanCIF(beneficiary?.cif) === cleanCIF(employeeCIF)
          ) || null;

        if (employeeBeneficiaries.length) {
          const societyId = actualSociety?.['_id'];
          const planId = employeeBeneficiaries[0]?.plan;
          const beneficiaryId = employeeBeneficiaries[0]?.['_id'];

          const beneficiaryURL = `/detalle-beneficiario/${societyId}/${planId}/${beneficiaryId}`;

          const incentiveData = {
            // TODO: calculate these values from Beneficiaries Details
            // totalUnits: 15000,
            // vestedUnits: 11450,
            // incentiveValue: 650025,
            beneficiaryURL,
          };

          setEmployeeIncentiveData(incentiveData);
        }

        const currentPages = [
          <EmployeeSociety
            key="EmployeeSociety"
            society={actualSociety}
            employee={actualEmployee}
            setPage={setPage}
          />,
          <EmployeePersonal
            key="EmployeePersonal"
            society={actualSociety}
            employee={actualEmployee}
            incorporationDate={formattedDate}
          />,
          <EmployeeHistoric
            key="EmployeeHistoric"
            society={actualSociety}
            employee={actualEmployee}
          />,
        ];

        setPages(currentPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualEmployee, user, actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyName: actualSociety?.name,
          societyId: actualSociety?.['_id'],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (page !== 3 && page !== 4) {
      setIsCardVisible(true);
    } else {
      setIsCardVisible(false);
    }
  }, [page]);

  const handleClick = () => {
    setIsCardVisible(!isCardVisible);
  };

  return actualEmployee ? (
    <div className="nk-content-body">
      <PageListHeader
        title={actualSociety?.name}
        subTitle={t('Employee')}
        subTitleSuffix={actualEmployee?.fullName}
        description={`${t('Since')}: ${incorporationDate}`}
        actions={[
          <>
            <GoBackButton />
          </>,
        ]}
      />

      <div className="nk-content-wrap">
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content">
                <ul
                  className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card justify-content-start gx-4"
                  id="partner-details--tabs"
                >
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 0 ? 'active' : ''}`}
                      onClick={() => setPage(0)}
                    >
                      <em className="icon ni ni-user-circle" />
                      <span>{t('Employee')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 1 ? 'active' : ''}`}
                      onClick={() => setPage(1)}
                    >
                      <em className="icon ni ni-list" />
                      <span>{t('Data')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 2 ? 'active' : ''}`}
                      onClick={() => setPage(2)}
                    >
                      <em className="icon ni ni-histroy" />
                      <span>{t('Historic')}</span>
                    </p>
                  </li>
                </ul>
                {pages[page]}
              </div>

              {isCardVisible && (
                <div
                  className={
                    isCardVisible
                      ? `card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-${screenShowAside} toggle-screen-${screenShowAside} content-active`
                      : `card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-${screenShowAside} toggle-screen-${screenShowAside}`
                  }
                  data-content="userAside"
                  data-toggle-screen={screenShowAside}
                  data-toggle-overlay="true"
                  data-toggle-body="true"
                >
                  <div className="card-inner-group">
                    <div className="simplebar-wrapper">
                      <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer" />
                      </div>
                      <div className="simplebar-mask">
                        <div className="simplebar-offset">
                          <div className="simplebar-content-wrapper">
                            <div className="simplebar-content">
                              <ul>
                                <li
                                  className={`nav-item nav-item-trigger d-${screenShowAside}-none m-4`}
                                >
                                  <p
                                    className="toggle btn btn-icon btn-trigger"
                                    data-target="userAside"
                                    onClick={handleClick}
                                  >
                                    <em className="icon ni ni-cross" />
                                  </p>
                                </li>
                              </ul>
                              <EmployeeAside
                                employee={actualEmployee}
                                incentiveData={employeeIncentiveData}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simplebar-placeholder" />
                  </div>
                  <div className="simplebar-track simplebar-horizontal">
                    <div className="simplebar-scrollbar" />
                  </div>
                  <div className="simplebar-track simplebar-vertical">
                    <div className="simplebar-scrollbar" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default EmployeeDetail;
