import envelopeStatus from 'constants/envelopeStatus';

type CustomBeneficiary = {
  id: string;
  planId: string;
};

type InputSigner = {
  id: string;
  email: string;
  name: string;
  image?: string;
  role: string;
  type: string;
  claimDate?: Date;
};

type OutputSigner = {
  id: string;
  signDate?: Date;
  status: string;
  redirectUrl: string;
};

type DocumentData = {
  name: string;
  fileType: string;
  linkedTo: { linkedId: string; linkedType: string }[];
};

type EnvelopeData = {
  author: string;
  society: string;
  type: string;
  document: DocumentData;
  signers: OutputSigner[];
};

type GetEnvelopeDataProps = {
  signers: InputSigner[];
  signType: string;
  fileName: string;
  fileType: string;
  linkedType: string;
  role: string;
  userId: string;
  societyId: string;
  beneficiaries?: CustomBeneficiary[];
  boardId?: string;
  documentId?: string;
};

function getRedirectUrl(
  signerId: string,
  role: string,
  societyId: string,
  beneficiaries?: CustomBeneficiary[]
): string {
  const beneficiary = beneficiaries?.find(
    (beneficiary) => beneficiary?.id === signerId
  );

  const beneficiaryUrl = beneficiary
    ? `/detalle-beneficiario/${societyId}/${beneficiary?.planId}/${signerId}`
    : `/sociedad/${societyId}`;

  switch (role) {
    case 'PARTNERS':
      return `/detalle-socio/${societyId}/${signerId}`;
    case 'BENEFICIARIES':
      return beneficiaryUrl;
    case 'DIRECTORS':
      return `/detalle-miembro/${societyId}/${signerId}`;
    case 'INVESTORS':
      return `/detalle-inversor/${societyId}/${signerId}`;
    default:
      return `/sociedad/${societyId}`;
  }
}

function getEnvelopeDocumentData(
  signers: InputSigner[],
  fileName: string,
  fileType: string,
  linkedType: string
): DocumentData {
  const linkedTo = signers
    .filter((signer) => signer.id !== '')
    .map((signer) => ({
      linkedId: signer.id,
      linkedType,
    }));

  const documentData = {
    name: fileName,
    fileType,
    linkedTo: linkedType === 'BOARD' ? [] : linkedTo,
  };

  return documentData;
}

function getEnvelopeSignersData(
  signers: InputSigner[],
  role: string,
  societyId: string,
  beneficiaries?: CustomBeneficiary[]
): OutputSigner[] {
  const signersData = signers.map((signer) => ({
    id: signer?.id,
    name: signer?.name,
    email: signer?.email,
    status: 'sent',
    redirectUrl: getRedirectUrl(signer?.id, role, societyId, beneficiaries),
  }));

  return signersData;
}

const getEnvelopeData = ({
  signers,
  signType,
  fileName,
  fileType,
  linkedType,
  role,
  userId,
  societyId,
  beneficiaries,
  boardId,
  documentId,
}: GetEnvelopeDataProps): EnvelopeData => {
  const documentData = getEnvelopeDocumentData(
    signers,
    fileName,
    fileType,
    linkedType
  );

  const signersData = getEnvelopeSignersData(
    signers,
    role,
    societyId,
    beneficiaries
  );

  const envelopeData = {
    author: userId,
    society: societyId,
    type: signType,
    document: documentData,
    signers: signersData,
    boardId,
    documentId,
    creationDate: new Date(),
    status: envelopeStatus.ACTIVE,
  };
  return envelopeData;
};

export default getEnvelopeData;
