/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { StockPlan } from 'types';
import NumberFormat from 'react-number-format';
import { currencyFormatDecimals } from 'constants/formats';

type Props = {
  date: string;
  planId: string;
  currentPlanShares: number;
  shares: number;
  salary: number;
  laboralCategory: any;
  currentPlan: StockPlan | undefined;
  hasCustomConditions: boolean;
  sharePrice: number;
  amount: number;
  handleChangeDate: (event: any) => void;
  handleChangeShares: (event: any) => void;
};

const AddBeneficiaryModalUnitsView: FC<Props> = ({
  date,
  planId,
  currentPlanShares,
  shares,
  salary,
  laboralCategory,
  currentPlan,
  hasCustomConditions,
  sharePrice,
  amount,
  handleChangeDate,
  handleChangeShares,
}) => {
  const { t } = useTranslate();

  const isSharesDisabled =
    !currentPlanShares ||
    (salary > 0 && !!Object.entries(laboralCategory).length);

  const priceLabel = currentPlan?.isFixedPrice
    ? `(*) ${t('FixedPrice')}:`
    : `(*) ${t('UnitPrice')}:`;

  const unitPrice =
    hasCustomConditions && sharePrice
      ? sharePrice
      : currentPlan?.sharePrice || 0;

  const handleOnWheel = (event: any) => {
    event.target.blur();
  };

  return (
    <>
      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-plan-date">
          {t('DateOfConcessionOfTheBene')}
        </label>
        <div className="form-control-wrap">
          <input
            id="beneficiary-plan-date"
            name="beneficiary-plan-date"
            type="date"
            className="form-control date-picker form-control-lg"
            value={date}
            onChange={handleChangeDate}
            required
            max="2100-01-01T00:00"
          />
        </div>
      </div>

      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-plan-shares">
          {t('UnitsGrantedToTheBenefici')}
        </label>
        <div className="form-control-wrap">
          {!isSharesDisabled && (
            <div className="form-text-hint">
              {planId && (
                <span className="overline-title">/{currentPlanShares}</span>
              )}
            </div>
          )}

          <input
            type="number"
            className="form-control form-control-lg"
            id="beneficiary-plan-shares"
            name="beneficiary-plan-shares"
            value={shares}
            disabled={isSharesDisabled}
            placeholder={t('TotalShares')}
            onChange={handleChangeShares}
            onWheel={handleOnWheel}
            min="0"
          />
        </div>
        <label className="form-label" htmlFor="beneficiary-plan-shares">
          {priceLabel}
          &nbsp;
        </label>

        <span className="text-primary">
          <NumberFormat
            value={+unitPrice}
            displayType="text"
            {...currencyFormatDecimals}
          />
        </span>

        <label className="form-label" htmlFor="beneficiary-plan-shares">
          &nbsp;| {t('TotalToPay')}:&nbsp;
        </label>

        <span className="text-primary">
          <NumberFormat
            value={amount}
            displayType="text"
            {...currencyFormatDecimals}
          />
        </span>
      </div>
    </>
  );
};

export default AddBeneficiaryModalUnitsView;
