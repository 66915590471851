import { useEffect, useState } from 'react';
import { EnvelopeSigner } from 'types';
import { getSignedSignersCount } from '../utils/getSignedSignersCount';

export default function useGetSignedSignersCount(signers: EnvelopeSigner[]) {
  const [signedSignersCount, setSignSignersCount] = useState(0);

  useEffect(() => {
    const count = getSignedSignersCount(signers);
    setSignSignersCount(count);
  }, [signers]);

  return { signedSignersCount };
}
