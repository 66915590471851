import { FC } from 'react';
import { Society } from 'types';
import { STTOK_LOGO_2X } from 'constants/defaultConstants';

import './LogoSociety.scss';

type LogoSocietyProps = {
  society: Society;
};

const LogoSociety: FC<LogoSocietyProps> = ({ society }) => {
  const hasWhiteBranding = society?.configuration?.hasWhiteBranding || false;

  const defaultLogo = society?.additional?.logo || STTOK_LOGO_2X;
  const brandingLogo =
    society?.customization?.theme?.emailFrameLogo ||
    society?.customization?.theme?.logo;

  const logoSrc = hasWhiteBranding ? brandingLogo || defaultLogo : defaultLogo;

  return (
    <div className="logo-society">
      <img
        className="logo-dark logo-img logo-img-lg"
        src={logoSrc}
        alt="logo-dark"
      />
    </div>
  );
};

export default LogoSociety;
