import { store } from 'redux/store';

import getSignRedirection from 'modules/_shared/api/envelopeActions';

interface RedirectUrlProps {
  prefix: string;
  redirectUrl: string;
}

interface QueryParams {
  [key: string]: string;
}

function extractQueryParams(queryString: string): QueryParams {
  const queryParams: QueryParams = {};
  if (queryString) {
    const paramPairs = queryString.split('&');

    paramPairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value || '');
      queryParams[decodedKey] = decodedValue;
    });
  }

  return queryParams;
}

const getRedirectURL = async (): Promise<RedirectUrlProps> => {
  try {
    const prefix: string = '#';
    const emailRedirectUrl: string | null =
      localStorage.getItem('redirect_url');
    const signRedirectUrl: string | null =
      localStorage.getItem('sign_redirect');

    let redirectUrl: string = '';

    if (emailRedirectUrl) {
      redirectUrl = emailRedirectUrl;
      localStorage.removeItem('redirect_url');
    } else if (signRedirectUrl) {
      const queryParams = extractQueryParams(signRedirectUrl);
      const { envelopeId, signerId } = queryParams;

      const result = await store.dispatch(
        getSignRedirection({
          envelopeId,
          signerId,
        })
      );

      if (result) {
        redirectUrl = result;
      }

      localStorage.removeItem('sign_redirect');
    }

    return { prefix, redirectUrl };
  } catch (error) {
    return { prefix: '#', redirectUrl: '' };
  }
};

export default getRedirectURL;
