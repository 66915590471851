import i18n from 'i18n/config';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { store } from 'redux/store';

export type TranslateFn = (
  key: string, // eslint-disable-line
  options?: Record<string, any> // eslint-disable-line
) => string;

/**
 * Custom hook for translation.
 * Uses the `useTranslation` hook from the `react-i18next` library to provide translation functionality.
 * The translated strings are contextualized based on the `societyType` obtained from the Redux store.
 * @returns An object containing the `t` function for translation.
 */
export const useTranslate = () => {
  const { t: translate, i18n } = useTranslation();
  const societyType =
    useSelector((state: State) => state.society?.actualSociety?.societyType) ||
    '';

  const t = (key: string, options = {}) =>
    translate(key, { ...options, context: societyType });

  return { t, i18n };
};

/**
 * Translates the given key using the i18n library.
 * @param key - The translation key.
 * @param options - Additional options for translation.
 * @returns The translated string.
 */
export const translate = (key: string, options = {}) => {
  const state: State = store.getState();
  const societyType = state.society?.actualSociety?.societyType || '';

  return i18n.t(key, { ...options, context: societyType });
};
