import { store } from 'redux/store';
import React from 'react';
import { setModal } from 'modules/_shared/redux/modalActions';
import previewDownload from 'utils/previewDownload';
import downloadWord from 'utils/downloadWord';
import ReplaceBoardTemplate from 'modules/boards/modals/ReplaceBoardTemplate';
import boardTemplateTypes from 'modules/boards/constants/boardTemplateTypes';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-file-pdf',
      text: i18n.t('DownloadPDF'),
      action: previewDownload,
      disabled: false,
    },
    {
      icon: 'ni-file-doc',
      text: i18n.t('DownloadWord'),
      action: ({ preview }) =>
        downloadWord(preview.documentModel, preview.name),
    },
    {
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      action: ({ setShowEditor }) => setShowEditor(true),
      disabled: false,
    },
    {
      icon: 'ni-files',
      text: i18n.t('ChangeTemplate'),
      action: ({ board }) =>
        store.dispatch(
          setModal(
            <ReplaceBoardTemplate
              board={board}
              templateType={boardTemplateTypes.CERTIFICATION}
            />
          )
        ),
      disabled: false,
    },
  ];
}
export default menuOptions;
