import i18n from 'i18n/config';

export default [
  {
    icon: 'ni-eye',
    text: i18n.t('SeePartners'),
    url: '/socios',
  },
  /*
    {
      icon: 'ni-file-text',
      text: 'Ver Documentos',
      url: '/documentos'
    },
    */
  {
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    url: '/perfil-sociedad/datos',
  },
];
