import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';

import { Beneficiary } from 'types';

type OtherDataViewProps = {
  beneficiary: Beneficiary;
  setUpdatedBeneficiary: any;
};

const OtherDataView: FC<OtherDataViewProps> = ({
  beneficiary,
  setUpdatedBeneficiary,
}) => {
  const { t } = useTranslate();

  const [bankAccountNumber, setBankAccountNumber] = useState<string>(
    beneficiary?.bankAccountNumber || ''
  );
  const [hideAccountNumber, setHideAccountNumber] = useState<boolean>(
    !!beneficiary?.bankAccountNumber
  );

  const handleHideAccountNumber = () => {
    setHideAccountNumber(!hideAccountNumber);
  };

  const getMaskValue = (value: string) => {
    if (!value) return '';
    return value?.replace(/.(?=.{4})/g, '*');
  };

  const handleChangeBankAccountNumber = (event: any) => {
    const beneficiaryBankAccountNumber = event.target.value;
    setBankAccountNumber(beneficiaryBankAccountNumber);
    setUpdatedBeneficiary({
      ...beneficiary,
      bankAccountNumber: beneficiaryBankAccountNumber,
    });
  };

  return (
    <>
      <div className="tab-pane active">
        <div className="row gy-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="account-number">
                {t('BankAccountNumber')}
              </label>
              <div className="form-control-wrap">
                <div
                  className="form-icon form-icon-right"
                  onClick={handleHideAccountNumber}
                >
                  {hideAccountNumber ? (
                    <em className="icon ni ni-eye cursor-pointer" />
                  ) : (
                    <em className="icon ni ni-eye-off cursor-pointer" />
                  )}
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="account-number"
                  placeholder={t('EnterIBAN')}
                  disabled={hideAccountNumber}
                  value={
                    hideAccountNumber
                      ? getMaskValue(bankAccountNumber)
                      : bankAccountNumber
                  }
                  onChange={handleChangeBankAccountNumber}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherDataView;
