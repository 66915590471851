import { getUsers } from 'modules/profile/redux/userActions';
import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Society, User } from 'types';

type UseFetchAdminsProps = {
  user: User;
  isAdmin: boolean;
  actualSociety: Society;
};

const useFetchAdmins = ({
  user,
  isAdmin,
  actualSociety,
}: UseFetchAdminsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [societyAdmins, setSocietyAdmins] = useState([]);

  const fetchAdmins = useCallback(async () => {
    setIsLoading(true);
    try {
      const admins = isAdmin
        ? [...actualSociety?.administrators, ...user?.accountAdmins]
        : actualSociety?.administrators;

      if (admins.length) {
        const adminsList = await store.dispatch(
          getUsers({ usersList: admins })
        );

        setSocietyAdmins(adminsList);
      } else {
        setSocietyAdmins([]);
      }
    } catch (error) {
      setSocietyAdmins([]);
    } finally {
      setIsLoading(false);
    }
  }, [actualSociety?.administrators, user?.accountAdmins, isAdmin]);

  useEffect(() => {
    if (actualSociety && user) {
      fetchAdmins();
    }
  }, [actualSociety, user]); // eslint-disable-line

  return {
    data: societyAdmins,
    isLoading,
  };
};

export default useFetchAdmins;
