import { format } from 'date-fns';
import { dateFormat } from 'constants/formats';
import { TranslateFn } from 'hooks/useTranslate';
import { CommitteeMember } from 'types';
import { getMemberTypeLabel } from '../../CommitteeMembers/utils/getMemberTypeLabel';
import { getMemberStatusLabel } from '../../CommitteeMembers/utils/getMemberStatusLabel';
import { getMemberConditionLabel } from '../../CommitteeMembers/utils/getMemberConditionLabel';

export const getSummaryDetailsList = (
  member: CommitteeMember,
  t: TranslateFn
) => [
  { label: t('Email'), value: member.email },
  { label: t('CIF'), value: member.cif },
  { label: t('Nationality'), value: member.nationality },
  { label: t('Address'), value: member.address },
  {
    label: t('StartDate'),
    value: format(new Date(member.startDate), dateFormat),
  },
  {
    label: t('EndDate'),
    value: member.endDate ? format(new Date(member.endDate), dateFormat) : '-',
  },
  {
    label: t('BirthDate'),
    value: member.birthdate
      ? format(new Date(member.birthdate), dateFormat)
      : '-',
  },
  { label: t('Position'), value: t(getMemberTypeLabel(member)) },
  { label: t('Status'), value: t(getMemberStatusLabel(member)) },
  { label: t('MemberCondition'), value: t(getMemberConditionLabel(member)) },
  {
    label: t('NaturalPerson'),
    value: t(member.isNaturalPerson ? 'Yes' : 'No'),
  },
];
