import { FC, JSX, useEffect, useState } from 'react';

import parse from 'html-react-parser';

import { Communication } from 'types';

type CommunicationPreviewProps = {
  data: { communication: Communication | Partial<Communication> };
};

const CommunicationPreview: FC<CommunicationPreviewProps> = ({ data }) => {
  const { communication } = data;

  const [parsedDocument, setParsedDocument] = useState<
    string | JSX.Element | JSX.Element[]
  >();

  const getCommunicationStyle = () => ({
    whiteSpace: typeof parsedDocument === 'string' ? 'pre-line' : 'normal',
  });

  useEffect(() => {
    if (communication?.body) {
      const parsedResult = parse(communication.body);
      setParsedDocument(parsedResult);
    }
  }, [communication?.body]);

  return (
    <div className="nk-reply-entry entry">
      <p style={getCommunicationStyle()}>{parsedDocument || ''}</p>
    </div>
  );
};

export default CommunicationPreview;
