import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import BeneficiarySellInvoiceView from 'modules/beneficiaries/components/BeneficiarySellInvoiceView';

const BeneficiarySell = ({
  society,
  beneficiary,
  beneficiaryAllPlans,
  setSimulatorValue,
  societyValue,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const participant = beneficiary;

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('Simulador Valor Incentivo')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <BeneficiarySellInvoiceView
          participant={participant}
          society={society}
          societyValue={societyValue}
          beneficiary={beneficiary}
          beneficiaryAllPlans={beneficiaryAllPlans}
        />
      </Modal.Body>
    </>
  );
};

export default BeneficiarySell;
