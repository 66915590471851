import { useEffect, useState } from 'react';

import { Partner, PartnerCategory, Society } from 'types';

import civilStatus from 'constants/civilStatus';
import { useTranslate } from 'hooks/useTranslate';
import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';

type Option = {
  label: string;
  value: string;
};

type Props = {
  currentPartner: Partner;
  actualSociety: Society;
  partnerCategories: PartnerCategory[];
};

export function useGetPartnerOptions({
  currentPartner,
  actualSociety,
  partnerCategories,
}: Props) {
  const { t } = useTranslate();

  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);
  const [sindicationOptions, setSindicationOptions] = useState<Option[]>([]);
  const [civilStatusOptions, setCivilStatusOptions] = useState<Option[]>([]);

  useEffect(() => {
    const options = partnerCategories.map((category) => ({
      value: category['_id'],
      label: category.name,
    }));
    setCategoryOptions(options);
  }, [partnerCategories]);

  useEffect(() => {
    const options = actualSociety?.partners
      ?.filter((partner) => partner?._id !== currentPartner?._id)
      .map((partner) => ({
        value: partner?.['_id'],
        label: `${partner?.cif} | ${partner?.name}`,
        role: boardDelegationRoles.PARTNER,
      }));
    setSindicationOptions(options);
  }, [actualSociety, currentPartner]);

  useEffect(() => {
    const options = Object.entries(civilStatus).map(([, value]) => ({
      value,
      label: t(value),
    }));
    setCivilStatusOptions(options);
  }, [civilStatus]); // eslint-disable-line

  return {
    data: { categoryOptions, sindicationOptions, civilStatusOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
