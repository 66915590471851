import { useTranslate } from 'hooks/useTranslate';

type Props = {
  line1: string;
  setLine1: (value: string) => void; // eslint-disable-line no-unused-vars
  city: string;
  setCity: (value: string) => void; // eslint-disable-line no-unused-vars
  zip: string;
  setZip: (value: string) => void; // eslint-disable-line no-unused-vars
  country: string;
  setCountry: (value: string) => void; // eslint-disable-line no-unused-vars
  hasTaxAddress: boolean;
  handleChangeTaxAddress: () => void; // eslint-disable-line no-unused-vars
  taxLine1: string;
  setTaxLine1: (value: string) => void; // eslint-disable-line no-unused-vars
  taxCity: string;
  setTaxCity: (value: string) => void; // eslint-disable-line no-unused-vars
  taxZip: string;
  setTaxZip: (value: string) => void; // eslint-disable-line no-unused-vars
  taxCountry: string;
  setTaxCountry: (value: string) => void; // eslint-disable-line no-unused-vars
  page: boolean;
};

const EditAddressForm = ({
  line1,
  setLine1,
  city,
  setCity,
  zip,
  setZip,
  country,
  setCountry,
  hasTaxAddress,
  handleChangeTaxAddress,
  taxLine1,
  setTaxLine1,
  taxCity,
  setTaxCity,
  taxZip,
  setTaxZip,
  taxCountry,
  setTaxCountry,
  page,
}: Props) => {
  const { t } = useTranslate();

  return (
    <div className={`tab-pane ${page && 'active'}`}>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="address-l1">
              {t('Address')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="address-l1"
              placeholder={t('Line1')}
              value={line1}
              onChange={(event) => setLine1(event.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="address-l2">
              {t('City')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="address-l2"
              placeholder={t('IntroduceCity')}
              value={city}
              onChange={(event) => setCity(event.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="address-st">
              {t('PostalCode')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="address-st"
              placeholder={t('IntroduceZipCode')}
              value={zip}
              onChange={(event) => setZip(event.target.value)}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="address-county">
              {t('Country')}
            </label>
            <input
              type="text"
              className="form-control form-control-lg"
              id="country-st"
              placeholder={t('IntroduceCountry')}
              value={country}
              onChange={(event) => setCountry(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-2">
          <div className="form-group mt-2">
            <div className="custom-control custom-control-xs custom-checkbox">
              <input
                type="checkbox"
                id="checkbox-notifications"
                className="custom-control-input"
                checked={hasTaxAddress}
                onChange={handleChangeTaxAddress}
              />
              <label
                className="custom-control-label"
                htmlFor="checkbox-notifications"
              >
                {t('HasFiscalResidenceDifferentFromAddress')}
              </label>
            </div>
          </div>
        </div>
      </div>

      {hasTaxAddress && (
        <>
          <h6 className="my-4">{t('FiscalResidence')}</h6>

          <div className="row gy-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="tax-address-l1">
                  {t('Address')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="tax-address-l1"
                  placeholder={t('Line1')}
                  value={taxLine1}
                  onChange={(event) => setTaxLine1(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="tax-address-city">
                  {t('City')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="tax-address-city"
                  placeholder={t('IntroduceCity')}
                  value={taxCity}
                  onChange={(event) => setTaxCity(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="tax-address-zip">
                  {t('PostalCode')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="tax-address-zip"
                  placeholder={t('IntroduceZipCode')}
                  value={taxZip}
                  onChange={(event) => setTaxZip(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label w-100"
                  htmlFor="tax-address-country"
                >
                  {t('Country')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="tax-address-country"
                  placeholder={t('IntroduceCountry')}
                  value={taxCountry}
                  onChange={(event) => setTaxCountry(event.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditAddressForm;
