import i18n from 'i18n/config';

type Language = {
  value: string,
  name: string,
};
type LanguageList = Record<string, Language>;

const languages: LanguageList = {
  es: {
    value: 'es',
    name: i18n.t('Spanish'),
  },
  en: {
    value: 'en',
    name: i18n.t('English'),
  },
  fr: {
    value: 'fr',
    name: i18n.t('French'),
  },
};

export default languages;
