import boardStatus from 'constants/boardStatus';

type BoardStatusBadgeProps = {
  status: keyof typeof boardStatus;
};

const BoardStatusBadge = ({ status }: BoardStatusBadgeProps) => {
  const badgeClassName = `badge badge-pill ${boardStatus?.[status]?.style}`;
  const badgeText = boardStatus?.[status]?.text || '';

  return status ? <span className={badgeClassName}>{badgeText}</span> : null;
};

export default BoardStatusBadge;
