/* eslint-disable import/no-extraneous-dependencies */
import { useEffect } from 'react';
import * as docx from 'docx-preview';

import { fileToArrayBuffer } from 'utils/files';
import './DOCXViewer.scss';

const DOCXViewer = ({ file, height }) => {
  useEffect(() => {
    if (!file) return;
    const template = fileToArrayBuffer(file);
    docx.renderAsync(template, document.getElementById('docx-container'));
  }, [file]);

  return (
    <div id="docx-container" className="docx--container" style={{ height }} />
  );
};

export default DOCXViewer;
