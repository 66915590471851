import i18n from 'i18n/config';
import { Society } from 'types';
import { getSocietyEmailConfiguration } from 'utils/emails';
import { getAnnouncementCommunication } from 'utils/getAnnouncementCommunication';

type AnnouncementEmailDataProps = {
  announcementData: Record<string, unknown>;
  userId: string;
  societyId: string;
  society: Society;
  userEmail?: string;
};

const getButtonText = (votePeriod: string) => {
  if (
    [
      'FROM_SENDING_TO_CLOSING',
      'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE',
    ].includes(votePeriod)
  ) {
    return 'Votar ahora';
  }
  return 'Acceder a la Junta';
};

const getTemplateData = (
  announcementData: Record<string, unknown>,
  buttonText: string
) => [
  {
    name: 'societyName',
    content: announcementData?.societyName,
  },
  {
    name: 'boardName',
    content: announcementData?.boardName,
  },
  {
    name: 'boardDate',
    content: announcementData?.date,
  },
  {
    name: 'boardTime',
    content: announcementData?.time,
  },
  {
    name: 'onlineAccess',
    content: announcementData?.onlineAccess,
  },
  {
    name: 'boardPlace',
    content: announcementData?.place,
  },
  {
    name: 'announcement',
    content: announcementData?.announcement,
  },
  {
    name: 'boardType',
    content: announcementData?.boardType,
  },
  {
    name: 'delegationUrl',
    content: announcementData?.delegationUrl,
  },
  {
    name: 'buttonText',
    content: buttonText,
  },
];

export const createAnnouncementEmailData = ({
  announcementData,
  societyId,
  society,
  userId,
  userEmail,
}: AnnouncementEmailDataProps) => {
  const buttonText = getButtonText(announcementData?.votePeriod as string);

  const societyTheme = getSocietyEmailConfiguration(society, {
    addSuffix: false,
  })?.theme;

  const hasWhiteBranding = society?.configuration?.hasWhiteBranding;

  const emailData = {
    subject: i18n.t('BoardAnnouncement'),
    body: [''],
    bodySummary: announcementData.announcement,
    template: 'board-announcement-new-es',
    templateData: getTemplateData(announcementData, buttonText),
    templateBody: getAnnouncementCommunication(
      societyTheme,
      hasWhiteBranding,
      announcementData,
      i18n.language || 'es'
    ),
    user: userId,
    society: societyId,
    date: new Date(),
    status: 'SENT',
    iCal: {
      societyEmail: userEmail,
      societyName: announcementData.societyName,
      boardName: announcementData.boardName,
      boardDate: announcementData.date,
      boardLink: announcementData.onlineAccess,
    },
  };

  return emailData;
};
