/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import screens from 'constants/screens';
import { translate } from 'hooks/useTranslate';
import CellData from 'modules/_shared/components/Tables/CellData';

function tableColumns(i18n) {
  return [
    {
      name: translate('Nr'),
      field: 'participantNumber',
      selector: (row) => row[translate('Nr')],
      cell: (row) =>
        CellData.PlainText({
          text: row[translate('Nr')],
        }),
      grow: 0.1,
      minWidth: 20,
      sortable: true,
      center: true,
      export: true,
    },
    {
      name: translate('Name'),
      field: 'participantName',
      selector: (row) => row[translate('Name')],
      cell: (row) =>
        CellData.PlainTextLink({
          text: row[translate('Name')],
          url: `/detalle-socio/${row.societyId}/${row.participantMemberId}`,
        }),
      grow: 4,
      sortable: true,
      left: true,
      export: true,
    },
    {
      name: translate('Shares'),
      field: 'shares',
      selector: (row) => row[translate('Shares')],
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[translate('Shares')] }),
      grow: 2,
      hide: screens.SM,
      sortable: true,
      center: true,
      compact: true,
      export: true,
    },
    {
      name: translate('%ND'),
      field: 'percentage',
      selector: (row) => row[translate('%ND')],
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[translate('%ND')],
          decimals: row.decimals,
        }),
      grow: 2,
      hide: screens.MD,
      sortable: true,
      center: true,
      compact: true,
      export: true,
    },
    {
      name: translate('RepresentativeBoard'),
      field: 'representativeName',
      selector: (row) => row[translate('RepresentativeBoard')],
      cell: (row) =>
        CellData.PlainText({
          text: row[translate('RepresentativeBoard')],
        }),
      grow: 4,
      hide: screens.SM,
      sortable: true,
      left: true,
      compact: false,
      export: true,
    },
    {
      name: translate('Attend'),
      field: 'participant',
      selector: (row) => row[translate('Attend')],
      cell: (row) =>
        CellData.AttendanceBadge({
          assistant: row[translate('Attend')],
        }),
      grow: 3,
      sortable: true,
      left: true,
      compact: true,
      export: true,
    },
  ];
}
export default tableColumns;
