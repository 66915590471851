import wNumb from 'wnumb';

import { SocietyValue } from 'types';
import { NouisliderProps } from 'nouislider-react';
import { kFormatter } from 'utils/kFormatter';
import { cleanValue } from 'constants/formats';

export const InitialSliderConfig = {
  start: 0,
  range: {
    min: 0,
    max: 1,
  },
  step: 0,
  animate: false,
  tooltips: false,
  connect: false,
};

type GetSliderConfigProps = {
  societyValue: SocietyValue;
  sliderStep: number;
  onChange: (value: number[]) => void; // eslint-disable-line
};

const defaultFormat = wNumb({
  decimals: 0,
  thousand: '.',
  suffix: ' €',
});

const pinpFormat = {
  to(value: number): string {
    return kFormatter(value, 0).toString();
  },
  from(value: string): number {
    const numericValue = cleanValue(value);
    return parseFloat(numericValue);
  },
};

const getMaxValueFromFutureValues = (societyValue: SocietyValue) =>
  Math.max(...(societyValue?.futureValues || []));

const getMinMaxValues = (
  societyValue: SocietyValue,
  hasFutureValues: boolean
) => {
  const minValue = Math.trunc(societyValue.value);

  const maxValue = hasFutureValues
    ? getMaxValueFromFutureValues(societyValue)
    : minValue * 10;

  return {
    minValue,
    maxValue,
  };
};

const getStep = (
  societyValue: SocietyValue,
  sliderStep: number,
  hasFutureValues: boolean
) => (hasFutureValues ? 1 : sliderStep);

const getRanges = (societyValue: SocietyValue, hasFutureValues: boolean) => {
  const { minValue, maxValue } = getMinMaxValues(societyValue, hasFutureValues);

  if (!hasFutureValues)
    return {
      min: minValue,
      max: maxValue,
    };

  const values = [minValue, ...(societyValue?.futureValues || [maxValue])];
  const dynamicRanges = values.reduce(
    (acc: Record<string, number[]>, value: number, index: number) => {
      if (index === 0) acc.min = [value];
      else if (index === values.length - 1) acc.max = [value];
      else acc[`${(index / (values.length - 1)) * 100}%`] = [value];
      return acc;
    },
    {}
  );

  return dynamicRanges;
};

const getPips = (societyValue: SocietyValue, hasFutureValues: boolean) => {
  const { minValue, maxValue } = getMinMaxValues(societyValue, hasFutureValues);

  const values = hasFutureValues
    ? [minValue, ...(societyValue?.futureValues || [maxValue])]
    : [minValue, maxValue];

  return {
    mode: 'values',
    values,
    density: 50,
    stepped: true,
    format: pinpFormat,
  };
};

const getSnap = (hasFutureValues: boolean) => !!hasFutureValues;

export const getSliderConfig = ({
  societyValue,
  sliderStep,
  onChange,
}: GetSliderConfigProps): NouisliderProps => {
  const minValue = Math.trunc(societyValue.value);
  const hasFutureValues = (societyValue?.futureValues?.length || 0) > 0;

  const start = minValue;

  const range = getRanges(societyValue, hasFutureValues);

  const step = getStep(societyValue, sliderStep, hasFutureValues);

  const pips = getPips(societyValue, hasFutureValues);

  const snap = getSnap(hasFutureValues);

  const format = defaultFormat;

  const sliderOnChange = (sliderValues: number[]) => {
    const value = sliderValues[0];

    if (onChange) {
      onChange([value]);
    }
  };

  return {
    start,
    range,
    step,
    pips,
    snap,
    format,
    onChange: sliderOnChange,
    animate: true,
    tooltips: true,
    connect: true,
  };
};
