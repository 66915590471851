/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import Step1 from './Step1';
import Step2 from './Step2';
// import Step3 from './Step3';
import Step4 from './Step4';
import StepFinal from './StepFinal';

import './AddSocietyWizard.scss';

interface AddSocietyWizardProps {
  fixedSize?: boolean;
}

const AddSocietyWizard: FC<AddSocietyWizardProps> = ({ fixedSize = true }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const [data, setData] = React.useState<Record<string, any>>({});

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [completedSteps, setCompletedSteps] = React.useState<
    Record<string, boolean>
  >({});

  const steps = [
    {
      label: t('Alta Sociedad'),
      description: t('AddSocietyWizard.step1Description'),
      component: (
        <Step1
          data={data}
          setData={setData}
          setCompletedSteps={setCompletedSteps}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: t('Constitution'),
      description: t('AddSocietyWizard.step2Description'),
      component: (
        <Step2
          data={data}
          setData={setData}
          setCompletedSteps={setCompletedSteps}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: t('AddSocietyWizard.step4'),
      description: t('AddSocietyWizard.step4Description'),
      component: (
        <Step4
          data={data}
          setData={setData}
          setCompletedSteps={setCompletedSteps}
          setActiveStep={setActiveStep}
        />
      ),
    },
    {
      label: t('AddSocietyWizard.completed'),
      description: t('AddSocietyWizard.completedDescription'),
      component: <StepFinal data={data} />,
    },
  ];

  return (
    <>
      {/* @ts-ignore */}
      <Modal.Header>
        <h5 className="modal-title">{t('AddSociety')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body className="py-0 px-2">
        <div className="nk-block nk-block-lg">
          <div className={`card  ${fixedSize ? 'wizard__fixed-size' : ''}`}>
            <form
              className="nk-stepper stepper-init is-alter nk-stepper-s1"
              action="#"
              id="stepper-create-project"
              data-step-current="first"
              noValidate
              style={{ display: 'block' }}
            >
              <div className="row g-0 col-sep col-sep-md col-sep-xl">
                <div className="wizard--sidebar col-md-2 col-xl-2">
                  <div className=" card-inner">
                    <ul className="nk-stepper-nav nk-stepper-nav-s1 stepper-nav is-vr">
                      {steps.map((step, index) => (
                        <li
                          className={activeStep === index ? 'current' : ''}
                          key={step.label}
                        >
                          <div
                            className="step-item cursor-pointer"
                            onClick={() => setActiveStep(index)}
                          >
                            <div className="step-text">
                              <div className="lead-text">{step.label}</div>
                              <div className="sub-text">{step.description}</div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="wizard--content col-md-10 col-xl-10">
                  <section className="wizard--content--step card-inner">
                    <div className="nk-stepper-content">
                      <div className="nk-stepper-steps stepper-steps">
                        {steps[activeStep].component}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AddSocietyWizard;
