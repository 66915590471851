import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  value: string;
  onChange: any;
  name: string;
};

const imageStyle = { maxWidth: '80px', maxHeight: '80px' };

const PersonalDataViewAvatar: FC<Props> = ({ value, onChange, name }) => {
  const { t } = useTranslate();

  return (
    <>
      <div className="col-md-3 col-4">
        <label className="form-label mr-2" htmlFor="imageInput">
          {t('Image')}
        </label>
        <input
          type="file"
          id="imageInput"
          className="d-none"
          onChange={onChange}
          accept="image/*"
        />
        <label
          htmlFor="imageInput"
          className="btn btn-primary form-control-lg m-0"
        >
          {t('Choose')}
        </label>
      </div>

      <div className="col-md-3 col-4">
        <div
          className="user-avatar mx-auto lg bg-primary-dim"
          style={imageStyle}
        >
          {value ? (
            <img id="user-image" src={value} alt={name} style={imageStyle} />
          ) : (
            <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default PersonalDataViewAvatar;
