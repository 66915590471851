/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(guests = initialState.guests, action) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.guests;

    case actionTypes.CREATE_GUEST:
      return [...guests, action.guest];

    case actionTypes.GET_GUESTS: {
      return action.data;
    }

    case actionTypes.UPDATE_GUEST:
      return [
        ...guests.filter((guest) => guest['_id'] !== action.guest['_id']),
        action.guest,
      ];

    case actionTypes.DELETE_GUEST:
      return guests.filter((guest) => guest['_id'] !== action.guestId);

    default:
      return guests;
  }
}
