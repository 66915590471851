import { TranslateFn } from 'hooks/useTranslate';
import { Committee, CommitteeMember } from 'types';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { getMemberTypeLabel } from 'modules/administratives/pages/CommitteeMembers/utils/getMemberTypeLabel';
import { getMemberConditionLabel } from 'modules/administratives/pages/CommitteeMembers/utils/getMemberConditionLabel';
import {
  LegalDashboardCommitee,
  LegalDashboardCommitteeMember,
} from '../types';
import { createTermFromDates } from './createTermFromDates';

const COMMITTEE_MEMBERS_URL = '/comite-miembros';
const COMMITTEE_MEMBER_DETAILS_URL = '/detalle-miembro-comite';

const generateMembersURL = (
  member: CommitteeMember,
  societyId: string
): string =>
  `${COMMITTEE_MEMBER_DETAILS_URL}/${societyId}/${member.committee}/${member._id}`;

const getMembersFromCommittee = (
  members: CommitteeMember[],
  societyId: string,
  translate: TranslateFn
): LegalDashboardCommitteeMember[] =>
  members.map((member) => {
    const memberTypeLabel = getMemberTypeLabel(member);
    const conditionLabel = getMemberConditionLabel(member);
    return {
      name: member.name,
      link: generateMembersURL(member, societyId),
      value1: translate(memberTypeLabel),
      value2: translate(conditionLabel),
      value3: createTermFromDates(
        member?.startDate?.toString() || '',
        member?.endDate?.toString() || '',
        translate
      ),
      image: member?.image || BLANK_PROFILE_PICTURE,
      hasProgressBar: false,
    };
  });

export const mapCommitteesToLegalDashboard = (
  committees: Committee[],
  translate: TranslateFn
): LegalDashboardCommitee[] => {
  const committeeData: any = [];
  committees.forEach((committee) => {
    if (committee?.members?.length > 0) {
      committeeData.push({
        id: committee._id,
        title: translate(committee.name),
        linkUrl: COMMITTEE_MEMBERS_URL,
        data: getMembersFromCommittee(
          committee.members,
          committee.society as string,
          translate
        ),
      });
    }
  });
  return committeeData;
};
