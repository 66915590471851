import types from 'redux/actions/actionTypes';

export function addAlert(data) {
  return {
    type: types.ADD_ALERT,
    data,
  };
}

export function removeAlert() {
  return {
    type: types.REMOVE_ALERT,
  };
}
