import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

type ReferenceType = {
  boardId?: string;
};

export const getSignersStatus = (envelopeId: string) => async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/signersStatus/${envelopeId}`
    );
    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEnvelopesByReference =
  (reference: ReferenceType) => async () => {
    const query = { params: { ...reference } };
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/envelopes/`,
        query
      );
      const decodedData = await decryptResponse(data);
      return decodedData;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
