/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import {
  getEmployeeFirstEffectiveDate,
  getEmployeeJobTitle,
} from 'utils/employees';
import EmployeeDetailsList from './EmployeeDetailsList';

function EmployeeSociety({ employee, society, setPage }) {
  const { t } = useTranslate();

  const initialDate = getEmployeeFirstEffectiveDate(employee);
  const jobTitle = getEmployeeJobTitle(employee);

  return (
    <div className="card-inner">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="user-card user-card-s2">
            <div className="user-avatar lg bg-primary">
              {society.additional.logo ? (
                <img src={society.additional.logo} alt="Logo" />
              ) : (
                <span>{society.name.slice(0, 2).toUpperCase()}</span>
              )}
            </div>
            <div className="user-info">
              <h5>{employee?.fullName}</h5>

              <div className="d-flex flex-wrap justify-content-center align-center">
                <div className="badge badge-outline badge-pill text-primary ucap">
                  {t('Employee')}
                </div>
              </div>

              <span className="sub-text">
                {t('EmployeeOfSocietyName', {
                  societyName: society.name,
                })}
              </span>
            </div>
          </div>
        </div>

        <div className="card-inner card-inner-sm px-0">
          <ul className="btn-toolbar justify-center gx-1">
            <li>
              <button type="button" className="btn btn-success" disabled>
                <em className="icon ni ni-coins mr-1" />
                {t('AssignIncentive')}
              </button>
            </li>
          </ul>
        </div>

        <div className="card-inner">
          <h6 className="overline-title-alt mb-2">
            {t('EmployeeDetails')} {employee?.fullName}
          </h6>

          <div className="d-flex justify-content-around flex-wrap text-center">
            <div className="my-2">
              <div className="profile-balance-amount">
                <div className="number">
                  {format(new Date(initialDate), dateFormat)}
                </div>
              </div>
              <div className="profile-stats">
                <span className="badge badge-outline-primary">
                  {t('Shares')}
                </span>
              </div>
            </div>
            <div className="my-2">
              <div className="profile-balance-amount">
                <div className="number">{jobTitle}</div>
              </div>
              <div className="profile-stats">
                <span className="badge badge-outline-primary">
                  {t('Puesto')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-inner">
          <h6 className="overline-title-alt mb-4">
            {t('EmployeeDetails')} {employee?.fullName}
          </h6>

          <EmployeeDetailsList
            employee={employee}
            societyName={society?.name}
          />
        </div>
      </div>
    </div>
  );
}

export default EmployeeSociety;
