import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import toggleSideNav from 'modules/_shared/redux/sideNavActions';

import '../SideNav.scss';

type Props = {
  linkTo: string;
  text: string;
};

const MenuItem: FC<Props> = ({ linkTo, text }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  return (
    <li className="nk-menu-item">
      <Link
        to={linkTo}
        className="nk-menu-link"
        onClick={() => dispatch(toggleSideNav())}
      >
        <span className="nk-menu-text">{t(text)}</span>
      </Link>
    </li>
  );
};

export default MenuItem;
