/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useState } from 'react';
import {
  Route,
  HashRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { Modal } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';

import ThemeContext from 'modules/_shared/contexts/ThemeContext';

import { generateToken, saveToStorage } from 'utils/token';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import Footer from 'modules/_shared/components/Footer';
import Header from 'modules/_shared/components/Header';
import SideNav from 'modules/_shared/components/SideNav';

import DemoTS from 'modules/_shared/components/_DemoTS/index';

import Page403 from 'modules/_shared/pages/403';
import Page404 from 'modules/_shared/pages/404';
import Login from 'modules/auth/pages/Login';
import LoginPWC from 'modules/auth/pages/LoginPWC';
import Register from 'modules/auth/pages/Register';
import TryDemo from 'modules/auth/pages/TryDemo';
import BeneficiaryDetails from 'modules/beneficiaries/pages/BeneficiaryDetails';
import BeneficiaryDrafts from 'modules/beneficiaries/pages/BeneficiaryDrafts';
import BeneficiaryFiles from 'modules/beneficiaries/pages/BeneficiaryFiles';
import BeneficiaryList from 'modules/beneficiaries/pages/BeneficiaryList';
import BeneficiaryPlanList from 'modules/beneficiaries/pages/BeneficiaryPlanList';
import BeneficiatedList from 'modules/beneficiaries/pages/BeneficiatedList';
import EmployeeDetail from 'modules/beneficiaries/pages/EmployeeDetail';
import EmployeeList from 'modules/beneficiaries/pages/EmployeeList';
import EmployeesNotificationsList from 'modules/beneficiaries/pages/EmployeesNotificationsList';
import EmployeesPlanDetailsList from 'modules/beneficiaries/pages/EmployeesPlanDetailsList';
import EmployeesPlanList from 'modules/beneficiaries/pages/EmployeesPlanList';
import SignConfirmation from 'modules/beneficiaries/pages/SignConfirmation';
import SummaryBook from 'modules/beneficiaries/pages/SummaryBook';
import Communications from 'modules/communications/pages/Communications';
import Contact from 'modules/contact/pages/Contact';
import Dashboard from 'modules/dashboard/pages/Dashboard';
import Documents from 'modules/documents/pages/Documents';
import Templates from 'modules/documents/pages/Templates';
import DraftCapitalIncrease from 'modules/drafts/pages/DraftCapitalIncrease';
import DraftConvertibleNote from 'modules/drafts/pages/DraftConvertibleNote';
import DraftSafe from 'modules/drafts/pages/DraftSafe';
import DraftSell from 'modules/drafts/pages/DraftSell';
import DraftSellParticipations from 'modules/drafts/pages/DraftSellParticipations';
import DraftsList from 'modules/drafts/pages/DraftsList';
import HoldingClassList from 'modules/partners/pages/HoldingClassList';
import InvestorDetail from 'modules/partners/pages/InvestorDetail';
import InvestorsList from 'modules/partners/pages/InvestorsList';
import PartnerBook from 'modules/partners/pages/PartnerBook';
import PartnerCategoriesList from 'modules/partners/pages/PartnerCategoriesList';
import PartnerDetail from 'modules/partners/pages/PartnerDetail';
import PartnerFiles from 'modules/partners/pages/PartnerFiles';
import PartnerList from 'modules/partners/pages/PartnerList';
import UserProfile from 'modules/profile/pages/UserProfile';
import AdministratedDiagram from 'modules/society/pages/AdministratedDiagram';
import AdministratedList from 'modules/society/pages/AdministratedList';
import AdministratedTable from 'modules/society/pages/AdministratedTable';
import DirectorDetail from 'modules/society/pages/DirectorDetail';
import ParticipatedDiagram from 'modules/society/pages/ParticipatedDiagram';
import ParticipatedList from 'modules/society/pages/ParticipatedList';
import ParticipatedTable from 'modules/society/pages/ParticipatedTable';
import PortfolioCards from 'modules/society/pages/PortfolioCards';
import PortfolioList from 'modules/society/pages/PortfolioList';
import RepresentativeDetail from 'modules/society/pages/RepresentativeDetail';
import SocietiesAdd from 'modules/society/pages/SocietiesAdd';
import SocietyAnnualValue from 'modules/society/pages/SocietyAnnualValue';
import SocietyDashboard from 'modules/society/pages/SocietyDashboard';
import SocietyDocuments from 'modules/society/pages/SocietyDocuments';
import SocietyProfile from 'modules/society/pages/SocietyProfile';
import TransactionDetails from 'modules/transactions/pages/TransactionDetails';
import TransactionsList from 'modules/transactions/pages/TransactionsList';

import AdminCenter from 'modules/_shared/pages/AdminCenter';
import OpenAiIntegration from 'modules/_shared/pages/OpenAiIntegration';
import BoardDetails from 'modules/boards/pages/BoardDetails';
import BoardPartner from 'modules/boards/pages/BoardPartner';
import BoardsList from 'modules/boards/pages/BoardsList';
import BoardsPartnerList from 'modules/boards/pages/BoardsPartnerList';
import DelegationPartner from 'modules/boards/pages/DelegationPartner';
import DelegationSignature from 'modules/boards/pages/DelegationSignature';
import CustomDashboard from 'modules/dashboard/pages/CustomDashboard';
import CustomDashboardDetails from 'modules/dashboard/pages/CustomDashboardDetails';
import CertificateSignature from 'modules/society/pages/CertificateSignature';
import OrgChartLayoutView from 'modules/society/pages/ChartJointJS';
import TenderOfferDetails from 'modules/transactions/pages/TenderOfferDetails';
import TenderOfferList from 'modules/transactions/pages/TenderOfferList';
import TenderOfferParticipation from 'modules/transactions/pages/TenderOfferParticipation';
import TenderOfferSharesDetails from 'modules/transactions/pages/TenderOfferSharesDetails';
import TenderOfferSharesList from 'modules/transactions/pages/TenderOfferSharesList';
import PartnerRepresentantsList from 'modules/partners/pages/PartnerRepresentantsList';
import PartnerRepresentantDetail from 'modules/partners/pages/PartnerRepresentantDetail';
import Members from 'modules/administratives/pages/Members';
import Committees from 'modules/administratives/pages/Committees';
import CommitteeMembers from 'modules/administratives/pages/CommitteeMembers';
import CommitteeMemberDetails from 'modules/administratives/pages/CommitteeMemberDetails';

// import UnderMaintenance from 'modules/_shared/pages/_UnderMaintenance';

import { APP_THEME_FF } from 'constants/defaultConstants';
import sizes from 'constants/sizes';
import LegalAlert from 'modules/_shared/components/Alert';
import Notification from 'modules/_shared/components/Notification';
import { findUser, getUser } from 'modules/profile/redux/userActions';
import { applyGlobalTheme, getThemeContentPadding } from 'utils/theme';
import validateRoute from 'utils/validateRoute';
import ScrollToTop from './ScrollToTop';

import './App.scss';
import './assets/styles/Bootstrap.scss';
import './assets/styles/Components.scss';
import './assets/styles/Fonts.scss';
import './assets/styles/Icons.scss';
import './assets/styles/Layouts.scss';
import './assets/styles/Misc.scss';
import './assets/styles/Placeholder.scss';
import './assets/styles/Print.scss';
import './assets/styles/ReactSelect.scss';
import './assets/styles/Stepper.scss';
import './assets/styles/Styles.scss';
import './assets/styles/Timeline.scss';
import './assets/styles/Animation.scss';

function LegalRoute({ id, path, component, user, isLoading, isFullScreen }) {
  const location = useLocation();
  const history = useHistory();
  const notification = useSelector((state) => state.notification);
  const fullWidth = useSelector((state) => state.fullWidth);
  const theme = useSelector(
    (state) => state?.society?.actualSociety?.customization?.theme
  );

  const isFullWidth = fullWidth || isFullScreen ? 'app-full-width' : '';

  useEffect(() => {
    if (!isLoading && !localStorage.token && !user) history.push('/login');
  });

  return user ? (
    <div className="app-content nk-wrap">
      {!isFullScreen && <Header />}

      {notification?.visible && (
        <Notification
          id={notification.id}
          title={notification.title}
          message={notification.message}
          type={notification.type}
          link={notification.link}
          linkText={notification.linkText}
          icon={notification.icon}
          fill={notification.fill}
          dismissible={notification.dismissible}
          action={notification.action}
          actionText={notification.actionText}
          actionData={notification.actionData}
        />
      )}

      <div
        className="nk-content"
        style={{ paddingTop: getThemeContentPadding(theme) }}
      >
        <div className={`container wide-xl d-flex ${isFullWidth}`}>
          <SideNav className="container__nav" />

          <div className="container__main">
            {validateRoute(user, id, location.pathname) ? (
              <Route path={path} component={component} />
            ) : (
              <Route component={Page403} />
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  ) : (
    <CustomLoading />
  );
}

function validateAndStoreToken(token, storageField) {
  const redirectTokenString = token[1];
  const encodedSlash = ['%2F', '%2f'];

  const containsEncodedSlash = new RegExp(encodedSlash.join('|'), 'gi').test(
    redirectTokenString
  );

  const decodedRedirectToken = containsEncodedSlash
    ? redirectTokenString.replace(/%2F|%2f/gi, '/')
    : redirectTokenString;

  saveToStorage(storageField, decodedRedirectToken);
  window.history.replaceState({}, document.title, '/');
}

function App({ alerts, currentAlert, user, dispatch, statemodal }) {
  const { user: auth0User, isAuthenticated, isLoading } = useAuth0();
  const { setDarkTheme } = useContext(ThemeContext);

  const theme = useSelector(
    (state) => state?.society?.actualSociety?.customization?.theme
  );

  const signedRedirectRegex = /signed=[^&]*&?(.*)/;
  const emailRedirectRegex = /redirect=([^&]*)/;

  const signedRedirectUrl = useLocation().search.match(signedRedirectRegex);
  const emailRedirectUrl = useLocation().search.match(emailRedirectRegex);

  const [modal, setModal] = useState(false);
  const [isGettingUser, setIsGettingUser] = useState(false);

  if (isAuthenticated && auth0User && !user) {
    const accessToken = generateToken(auth0User);
    saveToStorage('access-token', accessToken);
    dispatch(findUser(auth0User));
  }

  if (!user && localStorage.token && !currentAlert) {
    if (!isGettingUser) {
      setIsGettingUser(true);
      dispatch(getUser(localStorage.token));
    }
  }

  useEffect(() => {
    applyGlobalTheme(theme);
  }, [theme]);

  useEffect(() => {
    setModal(statemodal);
  }, [statemodal]);

  useEffect(() => {
    if (signedRedirectUrl && !emailRedirectUrl) {
      validateAndStoreToken(signedRedirectUrl, 'sign_redirect');
    } else if (emailRedirectUrl) {
      validateAndStoreToken(emailRedirectUrl, 'redirect_url');
    }
  }, [signedRedirectUrl, emailRedirectUrl]);

  useEffect(() => {
    const savedTheme = localStorage.getItem(APP_THEME_FF);

    if (savedTheme) {
      const isDarkTheme = savedTheme === 'dark';
      setDarkTheme(isDarkTheme);
    }
  }, []); // eslint-disable-line

  return (
    <>
      {!!alerts.length && <LegalAlert {...currentAlert} />}
      <Router>
        <ScrollToTop />
        <div className="nk-body npc-default has-aside no-touch nk-nio-theme">
          <div className="nk-app-root">
            {modal ? (
              <Modal
                size={modal?.props?.size || sizes.MD}
                dialogClassName={modal?.props?.fullScreen ? 'modal-90w' : ''}
                show
                onHide={() => setModal(null)}
                backdrop="static"
                keyboard={false}
                centered
              >
                {modal}
              </Modal>
            ) : (
              <> </>
            )}
            <div className="nk-main ">
              <Switch>
                <LegalRoute
                  id="dashboard"
                  path="/"
                  exact
                  component={Dashboard}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="admin-center"
                  path="/admin-center"
                  component={AdminCenter}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="participated-cards"
                  path="/cuadro-participadas"
                  component={ParticipatedTable}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="participated-diagram"
                  path="/diagrama-participadas"
                  component={ParticipatedDiagram}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="participated-list"
                  path="/lista-participadas"
                  component={ParticipatedList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiated-list"
                  path="/lista-beneficiadas"
                  component={BeneficiatedList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="administrated-cards"
                  path="/cuadro-administradas"
                  component={AdministratedTable}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="participated-diagram"
                  path="/diagrama-administradas"
                  component={AdministratedDiagram}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="administrated-list"
                  path="/lista-administradas"
                  component={AdministratedList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="portfolio-cards"
                  path="/cuadro-portafolio/:societyId"
                  component={PortfolioCards}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="portfolio-cards-filter"
                  path="/cuadro-portafolio/:societyId/:filterIndex"
                  component={PortfolioCards}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="portfolio-list"
                  path="/lista-portafolio/:societyId"
                  component={PortfolioList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="portfolio-list-filter"
                  path="/lista-portafolio/:societyId/:filterIndex"
                  component={PortfolioList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="custom-dashboard"
                  path="/home/:societyId/:sectionIndex/:folderIndex"
                  component={CustomDashboardDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="custom-dashboard"
                  path="/home/:societyId"
                  component={CustomDashboard}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="society-dashboard"
                  path="/sociedad/:societyId"
                  component={SocietyDashboard}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="transactions-society-partner"
                  path="/transacciones/:societyId/:partnerId"
                  component={TransactionsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="transactions-society"
                  path="/transacciones/:societyId"
                  component={TransactionsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="transactions"
                  path="/transacciones/"
                  component={TransactionsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="transaction-details"
                  path="/detalle-transacciones/:societyId/:transactionId"
                  component={TransactionDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="new-society"
                  path="/nueva-sociedad"
                  component={SocietiesAdd}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="investors-list"
                  path="/inversores/:societyId"
                  component={InvestorsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partners-list-user"
                  path="/socios/:societyId/:userId"
                  component={PartnerList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partners-list"
                  path="/socios/:societyId"
                  component={PartnerList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partner-cards"
                  path="/socios-fichas/:societyId"
                  component={PartnerFiles}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partners-book"
                  path="/socios-libro/:societyId"
                  component={PartnerBook}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="investor-details-page"
                  path="/detalle-inversor/:societyId/:investorId/:pageId"
                  component={InvestorDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="investor-details"
                  path="/detalle-inversor/:societyId/:investorId"
                  component={InvestorDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partner-details-page"
                  path="/detalle-socio/:societyId/:partnerId/:pageId"
                  component={PartnerDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partner-details"
                  path="/detalle-socio/:societyId/:partnerId"
                  component={PartnerDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="representative-details"
                  path="/detalle-apoderado/:societyId/:representativeId"
                  component={RepresentativeDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="director-details"
                  path="/detalle-miembro/:societyId/:directorId"
                  component={DirectorDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="holding-classes"
                  path="/clases-participaciones/:societyId"
                  component={HoldingClassList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partners-categories"
                  path="/socios-categorias/:societyId"
                  component={PartnerCategoriesList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partner-representant-details"
                  path="/detalle-representante-socio/:societyId/:partnerRepresentantId"
                  component={PartnerRepresentantDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="partners-representants"
                  path="/socios-representantes/:societyId"
                  component={PartnerRepresentantsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiary-plan"
                  path="/beneficiarios/:societyId/:planId"
                  component={BeneficiaryList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiary-list"
                  path="/beneficiarios/:societyId"
                  component={BeneficiaryList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiary-cards"
                  path="/beneficiarios-fichas/:societyId"
                  component={BeneficiaryFiles}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="employee-list"
                  path="/empleados/:societyId"
                  component={EmployeeList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="employee-details"
                  path="/detalle-empleado/:societyId/:employeeId"
                  component={EmployeeDetail}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="employees-plan-list"
                  path="/empleados-planes/:societyId/:employeesPlanId"
                  component={EmployeesPlanDetailsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="employees-plan-list"
                  path="/empleados-planes/:societyId"
                  component={EmployeesPlanList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="employees-notifications-list"
                  path="/empleados-notificaciones/:societyId"
                  component={EmployeesNotificationsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="stockplans-society"
                  path="/beneficiarios-planes/:societyId"
                  component={BeneficiaryPlanList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="summary-plans"
                  path="/resumen-planes/:societyId"
                  component={SummaryBook}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiary-sign"
                  path="/beneficiarios-firma/:authToken"
                  component={SignConfirmation}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiary-drafts-plan"
                  path="/beneficiarios-borradores/:societyId/:planId"
                  component={BeneficiaryDrafts}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiary-drafts"
                  path="/beneficiarios-borradores/:societyId"
                  component={BeneficiaryDrafts}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="beneficiary-details"
                  path="/detalle-beneficiario/:societyId/:planId/:beneficiaryId"
                  component={BeneficiaryDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-society"
                  path="/borradores/:societyId"
                  component={DraftsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-increase-id"
                  path="/borrador-ampliacion/:societyId/:draftId"
                  component={DraftCapitalIncrease}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-increase"
                  path="/borrador-ampliacion/:societyId"
                  component={DraftCapitalIncrease}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-sell-id"
                  path="/borrador-venta/:societyId/:draftId"
                  component={DraftSell}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-sell"
                  path="/borrador-venta/:societyId"
                  component={DraftSell}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-safe-id"
                  path="/borrador-safe/:societyId/:draftId"
                  component={DraftSafe}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-safe"
                  path="/borrador-safe/:societyId"
                  component={DraftSafe}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-note-id"
                  path="/borrador-nota/:societyId/:draftId"
                  component={DraftConvertibleNote}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-note"
                  path="/borrador-nota/:societyId"
                  component={DraftConvertibleNote}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-note-id"
                  path="/borrador-compra-venta/:societyId/:draftId"
                  component={DraftSellParticipations}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="drafts-note"
                  path="/borrador-compra-venta/:societyId"
                  component={DraftSellParticipations}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="user-profile"
                  path="/perfil-usuario/:activeTab"
                  component={UserProfile}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="society-profile"
                  path="/perfil-sociedad/:pageType/:societyId"
                  component={SocietyProfile}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-docs"
                  path="/documentos/documentos/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-new"
                  path="/documentos/nuevo/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-recents"
                  path="/documentos/recientes/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-writings"
                  path="/documentos/escrituras/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-certificates"
                  path="/documentos/certificados/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-letters"
                  path="/documentos/cartas/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-signs"
                  path="/documentos/firmas/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-assignments"
                  path="/documentos/nombramientos/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-authority"
                  path="/documentos/poderes/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-annualreports"
                  path="/documentos/annualreports/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-templates-all"
                  path="/documentos/plantillas/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-minutebook"
                  path="/documentos/libro-actas/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-councilminutes"
                  path="/documentos/actas-consejo/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-templates-new"
                  path="/documentos/plantillas/nueva/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-deleted"
                  path="/documentos/eliminados/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="mis-carpetas"
                  path="/documentos/mis-carpetas/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents-society"
                  path="/documentos-sociedad/:societyId"
                  component={SocietyDocuments}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="documents"
                  path="/documentos/:societyId"
                  component={Documents}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="templates"
                  path="/plantillas-sociedad/:societyId"
                  component={Templates}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="com-certificate"
                  path="/comunicaciones/:societyId/:communicationId/certificacion"
                  component={Communications}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="com-communication"
                  path="/comunicaciones/:societyId/:communicationId"
                  component={Communications}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="com-society"
                  path="/comunicaciones/:societyId"
                  component={Communications}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="annual-value"
                  path="/valor-anual-sociedad/:societyId"
                  component={SocietyAnnualValue}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="contact"
                  path="/contacto"
                  component={Contact}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="boards-partner-list"
                  path="/juntas/:societyId/:memberId"
                  component={BoardsPartnerList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="boards-list"
                  path="/juntas/:societyId"
                  component={BoardsList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="board-details"
                  path="/detalle-junta/:societyId/:boardId"
                  component={BoardDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="board-partner"
                  path="/voto-junta/:societyId/:boardId/:partnerId"
                  component={BoardPartner}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="tender-offers-list"
                  path="/tender-offers/:societyId"
                  component={TenderOfferList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="tender-offers-shares-list"
                  path="/tender-offers-shares/:societyId"
                  component={TenderOfferSharesList}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="tender-offers-details"
                  path="/detalle-tender-offer/:societyId/:tenderOfferId"
                  component={TenderOfferDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="tender-offers-shares-details"
                  path="/detalle-tender-offer-shares/:societyId/:tenderOfferSharesId"
                  component={TenderOfferSharesDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="tender-offers-participation-beneficiary"
                  path="/participacion-tender-offer/:societyId/:tenderOfferId/beneficiario/:beneficiaryId"
                  component={TenderOfferParticipation}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />
                <LegalRoute
                  id="tender-offers-participation-partner"
                  path="/participacion-tender-offer/:societyId/:tenderOfferId/socio/:partnerId"
                  component={TenderOfferParticipation}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="signature-certificate"
                  path="/firma-certificado/:societyId"
                  component={CertificateSignature}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="portfolio-chart"
                  path="/diagrama-portafolio/:societyId"
                  component={OrgChartLayoutView}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="demo-ts"
                  path="/demo-ts"
                  component={DemoTS}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="signature-delegation"
                  path="/firma-delegacion/:societyId/:boardId/:partnerId"
                  component={DelegationSignature}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                  isFullScreen
                />

                <LegalRoute
                  id="partner-delegation"
                  path="/delegacion/:societyId/:boardId/:partnerId"
                  component={DelegationPartner}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                  isFullScreen
                />

                <LegalRoute
                  id="members"
                  path="/miembros/:societyId"
                  component={Members}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="committees"
                  path="/comites/:societyId"
                  component={Committees}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="committees-members"
                  path="/comite-miembros/:societyId/:committeeId"
                  component={CommitteeMembers}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="committees-member-details"
                  path="/detalle-miembro-comite/:societyId/:committeeId/:memberId"
                  component={CommitteeMemberDetails}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <LegalRoute
                  id="openai-integration"
                  path="/openai"
                  component={OpenAiIntegration}
                  user={user}
                  isLoading={isAuthenticated || isLoading}
                />

                <Route path="/demo" component={TryDemo} />
                <Route path="/register" component={Register} />
                <Route path="/login" component={Login} />
                <Route path="/pwc-login" component={LoginPWC} />

                <Route component={Page404} />

                {/* <Route component={UnderMaintenance} /> */}
              </Switch>
            </div>
          </div>
        </div>
      </Router>
    </>
  );
}

function mapStateToProps({ alerts, user, modal }) {
  const currentAlert = alerts[0];
  return {
    alerts,
    currentAlert,
    user,
    statemodal: modal,
  };
}

export default connect(mapStateToProps)(App);
