export default [
  {
    name: 'dashboard',
    label: 'Dashboard',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'partners',
    label: 'Shareholders',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'portfolio',
    label: 'Portfolio',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'drafts',
    label: 'Drafts',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'plans',
    label: 'Plans',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'transactions',
    label: 'Transactions',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'communications',
    label: 'Communications',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'boards',
    label: 'Boards',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'documents',
    label: 'Documents',
    enabled: true,
    checked: false,
    readOnly: false,
  },
  {
    name: 'societyConfiguration',
    label: 'SocietyConfiguration',
    enabled: true,
    checked: false,
    readOnly: false,
  },
];
