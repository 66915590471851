import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';

type Props = {
  societyName: string;
  page: number;
  // eslint-disable-next-line no-unused-vars
  handleChangePage: (page: number) => void;
};

const MembersPageHeader: FC<Props> = ({
  societyName,
  page,
  handleChangePage,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <PageListHeader
        title={t('AdministrativeMembers')}
        subTitle={societyName}
      />
      <ul className="nk-nav nav nav-tabs">
        <li className="nav-item">
          <p
            id="directors-tab"
            className={`nav-link ${page === 0 ? 'active' : ''}`}
            onClick={() => handleChangePage(0)}
          >
            <span>{t('Directorship')}</span>
          </p>
        </li>
        <li className="nav-item">
          <p
            id="representatives-tab"
            className={`nav-link ${page === 1 ? 'active' : ''}`}
            onClick={() => handleChangePage(1)}
          >
            <span>{t('Representatives')}</span>
          </p>
        </li>
        <li className="nav-item">
          <p
            id="auditors-tab"
            className={`nav-link ${page === 2 ? 'active' : ''}`}
            onClick={() => handleChangePage(2)}
          >
            <span>{t('Auditors')}</span>
          </p>
        </li>
      </ul>
    </>
  );
};

export default MembersPageHeader;
