import React from 'react';
import {
  getHeaderMenuBackgroundColor,
  getHeaderMenuTextColor,
} from 'utils/theme';

type HeaderMenuDropdownProps = {
  title: string;
  children: React.ReactNode;
  data: Record<string, any>;
};

const HeaderMenuDropdown = ({
  title,
  children,
  data,
}: HeaderMenuDropdownProps) => (
  <li className="nk-menu-item has-sub">
    <a
      className="nk-menu-link nk-menu-toggle cursor-pointer"
      style={getHeaderMenuTextColor(data)}
    >
      <span className="nk-menu-text">{title}</span>
    </a>
    <ul className="nk-menu-sub" style={getHeaderMenuBackgroundColor(data)}>
      {children}
    </ul>
  </li>
);

export default HeaderMenuDropdown;
