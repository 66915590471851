import { CommitteeMember } from 'types';
import conditions from '../constants/memberConditions';

export const getMemberConditionLabel = (member: CommitteeMember) => {
  const conditon = conditions.find(
    (condition) => condition.value === member?.condition
  );
  const conditionLabel = conditon ? conditon.label : '-';

  return conditionLabel;
};
