import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import sizes from 'constants/sizes';
import BeneficiariesPendingSignature from 'modules/beneficiaries/modals/BeneficiariesPendingSignature';

const PendingInvitationLetters = ({ pendingInvitations }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  return (
    pendingInvitations.length > 0 && (
      <div
        className="alert alert-fill alert-icon alert-danger alert-dismissible w-100 py-1 mt-2 cursor-pointer"
        role="alert"
        onClick={() =>
          dispatch(
            setModal(
              <BeneficiariesPendingSignature
                beneficiaries={pendingInvitations}
                size={sizes.XL}
              />
            )
          )
        }
      >
        <em
          className="icon ni ni-alert-circle"
          style={{ marginTop: '-10px' }}
        />
        {t('InvitationLettersPendingOfSignature')}:&nbsp;
        <strong>{pendingInvitations.length || 0}</strong>
      </div>
    )
  );
};

export default PendingInvitationLetters;
