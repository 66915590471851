import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const PWC_LOGIN_BACKGROUND =
  'https://www.resources.sttok.com/images/login-bg-jpg';

const LoginPWC = () => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect();
  }, [loginWithRedirect]);

  return (
    <div
      className="vw-100 vh-100"
      style={{
        backgroundImage: `url(${PWC_LOGIN_BACKGROUND})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};

export default LoginPWC;
