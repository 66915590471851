import { translate } from 'hooks/useTranslate';
import { deleteGuest } from './deleteGuest';

export const menuOptions = [
  // {
  //   id: 'EDIT_GUEST',
  //   icon: 'ni-edit',
  //   text: translate('Edit'),
  //   action: editUser,
  // },
  {
    id: 'DELETE_GUEST',
    icon: 'ni-trash',
    text: translate('Delete'),
    action: deleteGuest,
  },
];
