import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { sindicationTypes } from 'modules/partners/constants/sindicationTypes';
import { Partner } from 'types';
import downloadFile from 'utils/downloadFile';

type PartnersRepresentedRowProps = {
  index: number;
  partner: Partner;
};

const PartnersRepresentedRow: FC<PartnersRepresentedRowProps> = ({
  index,
  partner,
}) => {
  const { t } = useTranslate();
  const { name, cif, sindicationType } = partner;

  const documentColumnStyle = partner?.sindicationDocument
    ? 'btn btn-icon btn-trigger cursor-pointer'
    : 'btn btn-icon btn-trigger disabled';

  const handleDownload = async () => {
    if (partner?.sindicationDocument) {
      downloadFile({ documentId: partner.sindicationDocument });
    }
  };

  return (
    <tr>
      <td>
        <b>{index}</b>
      </td>
      <td>{name}</td>
      <td>{cif}</td>
      <td>{t(sindicationType || sindicationTypes.STANDARD)}</td>
      <td className="py-0">
        <span className={documentColumnStyle} onClick={handleDownload}>
          <em className="icon ni ni-file-download" />
        </span>
      </td>
    </tr>
  );
};

export default PartnersRepresentedRow;
