/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useRef } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import { tenderOfferSharesTypes } from 'utils/tenderOfferSharesTypes';

import { dateFormat } from 'constants/formats';

import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';

import './SharesOfferRow.scss';

const SharesOfferParticipantRow = ({ participantsData, offerData }) => {
  const partners = useSelector(
    (state) => state.society?.actualSociety?.partners
  );
  const holdingClasses = useSelector((state) => state?.holdingClasses);

  const { t } = useTranslate();
  const { societyId } = useParams();
  const printRef = useRef();

  const downloadFilename = `${t('Offer')} - ${offerData.name} - ${t(
    'Partners'
  )}`;

  const getPartnerName = (partnerId) => {
    const name = partners?.find((partner) => partner?._id === partnerId)?.name;
    return name;
  };

  const getClassName = (classId) => {
    if (classId) {
      const name = holdingClasses?.find(
        (holdingClass) => holdingClass?._id === classId
      )?.name;
      return name;
    }
    return '-';
  };

  const getNumeration = (participant) => {
    if (participant?.sharesFrom && participant?.sharesTo) {
      return `${formatNumber(participant.sharesFrom)} - ${formatNumber(
        participant.sharesTo
      )}`;
    }
    return '-';
  };

  const getNetPrice = (participant) => {
    if (!participant) return 0;

    const retentionAmmount =
      (offerData.retentionPercent * participant.totalPrice) / 100;

    return participant?.totalPrice - retentionAmmount;
  };

  return (
    <div className="nk-block" style={{ paddingBottom: '50px' }}>
      <div className="card card-bordered">
        <div className="card-inner card-inner-md">
          <div className="card-title-group">
            <h6 className="card-title">{t('Participants')}</h6>
            <div className="card-action">
              <ul className="nk-block-tools gx-3">
                <li>
                  <ReactToPrint
                    trigger={() => (
                      <span className="btn btn-icon btn-primary screen-only">
                        <em className="icon ni ni-printer" />
                      </span>
                    )}
                    content={() => printRef.current}
                    documentTitle={downloadFilename}
                  />
                </li>
                <li>
                  <ReactHtmlTableToExcel
                    id="table-nostatus-xls-button"
                    className="btn btn-white btn-outline-light mr-2 mb-sx-2 mb-0"
                    table="participantsTable"
                    filename={downloadFilename}
                    sheet={t('Participants')}
                    buttonText={`${t('Download')}`}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="margin-print-only" ref={printRef}>
          <PrintHeader
            title={t('PrintHeaderTenderOffer')}
            subTitle={offerData?.name}
          />

          <table className="table table-tranx" id="participantsTable">
            <thead>
              <tr className="tb-tnx-head">
                <th className="tb-tnx-id">
                  <span className="">Nº</span>
                </th>
                <th>
                  <span>{t('Date')}</span>
                </th>
                <th>
                  <span>{t('Name')}</span>
                </th>
                <th className="text-center">
                  <span>{t('TotalShares')}</span>
                </th>
                <th className="text-center">
                  <span>{t('Class')}</span>
                </th>
                <th className="text-center">
                  <span>{t('Numeration')}</span>
                </th>
                {offerData?.type === tenderOfferSharesTypes.SELL && (
                  <th className="text-center">
                    <span>{t('TotalPrice')}</span>
                  </th>
                )}
                <th className="text-center">
                  <span>{t('PriceByShare')}</span>
                </th>
                {offerData?.type === tenderOfferSharesTypes.SELL && (
                  <th className="text-center">
                    <span>{t('Retention')}</span>
                  </th>
                )}
                {offerData?.type === tenderOfferSharesTypes.SELL && (
                  <th className="text-center">
                    <span>{t('RetentionAmount')}</span>
                  </th>
                )}
                <th className="text-center">
                  <span>{t('NetAmount')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {participantsData.length > 0 ? (
                participantsData
                  ?.sort(
                    (a, b) =>
                      new Date(b.operationDate) - new Date(a.operationDate)
                  )
                  .map((item, index) => (
                    <tr className="tb-tnx-item" key={item.id}>
                      <td className="tb-tnx-id">
                        <span className="text-primary">{index + 1}</span>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-left w-100">
                          <span className="amount">
                            {item?.operationDate
                              ? format(new Date(item.operationDate), dateFormat)
                              : '-'}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-info">
                        <div className="tb-tnx-info text-left w-150px">
                          <Link to={`/detalle-socio/${societyId}/${item?.id}`}>
                            <span className="title">
                              {getPartnerName(item?.id)}
                            </span>
                          </Link>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatNumber(item?.totalShares)}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-center w-100">
                          <span className="amount">
                            {getClassName(item?.class)}
                          </span>
                        </div>
                      </td>
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-center w-100">
                          <span className="amount">{getNumeration(item)}</span>
                        </div>
                      </td>
                      {offerData?.type === tenderOfferSharesTypes.SELL && (
                        <td className="tb-tnx-amount">
                          <div className="tb-tnx-total text-right w-100">
                            <span className="amount">
                              {formatCurrencyDecimals(item?.totalPrice)}
                            </span>
                          </div>
                        </td>
                      )}
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatCurrencyDecimals(
                              item?.totalPrice / item?.totalShares
                            )}
                          </span>
                        </div>
                      </td>
                      {offerData?.type === tenderOfferSharesTypes.SELL && (
                        <td className="tb-tnx-amount">
                          <div className="tb-tnx-total text-right w-100">
                            <span className="amount">
                              {formatNumberDecimals(
                                offerData?.retentionPercent
                              )}
                              %
                            </span>
                          </div>
                        </td>
                      )}
                      {offerData?.type === tenderOfferSharesTypes.SELL && (
                        <td className="tb-tnx-amount">
                          <div className="tb-tnx-total text-right w-100">
                            <span className="amount">
                              {formatCurrencyDecimals(
                                (offerData?.retentionPercent *
                                  item?.totalPrice) /
                                  100 || 0
                              )}
                            </span>
                          </div>
                        </td>
                      )}
                      <td className="tb-tnx-amount">
                        <div className="tb-tnx-total text-right w-100">
                          <span className="amount">
                            {formatCurrencyDecimals(getNetPrice(item))}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <NoItemsAvailable mainMessage={t('NoParticipantsYet')} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SharesOfferParticipantRow;
