/* eslint-disable react/react-in-jsx-scope */
import { store } from 'redux/store';
import AddBoard from 'modules/_shared/components/Modals/AddBoard';
import actionTypes from 'constants/actionTypes';
import { setModal } from 'modules/_shared/redux/modalActions';

function actionOptions(i18n) {
  return [
    {
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      action: ({ currentBoard }) => {
        store.dispatch(
          setModal(<AddBoard action={actionTypes.EDIT} board={currentBoard} />)
        );
      },
      dropdown: true,
      dontShowCurrent: ({ isEditButtonEnabled }) => !isEditButtonEnabled(),
    },
    {
      icon: 'ni-send',
      text: i18n.t('SendAnnouncement'),
      action: ({ handleSendAnnouncement }) => {
        handleSendAnnouncement();
      },
      dropdown: true,
      dontShowCurrent: ({ isSendButtonEnabled, currentBoard }) =>
        !isSendButtonEnabled() || currentBoard?.announcementComm,
    },
    {
      icon: 'ni-send',
      text: i18n.t('SendReminder'),
      action: ({ handleSendReminder }) => {
        handleSendReminder();
      },
      dropdown: true,
      dontShowCurrent: ({ isSendButtonEnabled, currentBoard }) =>
        !isSendButtonEnabled() || !currentBoard?.announcementComm,
    },
    {
      icon: 'ni-na',
      text: i18n.t('CloseBoard'),
      action: ({ handleCloseBoard }) => {
        handleCloseBoard();
      },
      dropdown: true,
      dontShowCurrent: ({ isCloseButtonEnabled }) => !isCloseButtonEnabled(),
    },
  ];
}
export default actionOptions;
