import voteResultValues from 'constants/voteResultValues';
import { getTenderOfferStatus } from './filters';

export const tenderOfferChartLabels = [
  'Ejecutado',
  'Aceptado',
  'Rechazado',
  'Disponible',
];

export const votingResultsChartLabels = [
  voteResultValues.YES.text,
  voteResultValues.NO.text,
  voteResultValues.ABS.text,
];

export const votingResultsChartColors = ['#1ee0ac', '#e85347', '#09c2de'];

export const tenderOfferChartColors = [
  '#798bff',
  '#b8acff',
  '#ffa9ce',
  '#7de1f8',
];

export const votingResultsChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
    tooltip: {
      bodyFont: { size: 11 },
      position: 'nearest',
      callbacks: {
        label: (tooltipItem) => {
          const currentValue = tooltipItem.raw || 0;
          return `${tooltipItem.label}: ${currentValue}%`;
        },
      },
    },
  },
  cutout: '50%',
};

export const tenderOfferChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
  },
  cutout: '60%',
};

export const getPercentCategory = (total, category) => {
  if (!total || !category) return 0;
  const percent = (category * 100) / total;
  return percent;
};

export const getTenderOfferLegend = (params) => {
  const {
    totalOffer = 0,
    totalExecuted = 0,
    totalAccepted = 0,
    totalRejected = 0,
    totalAvailable = 0,
  } = params;

  return [
    {
      title: 'Ejecutado',
      icon: 'ni-user',
      color: '#798bff',
      value: totalExecuted,
      percent: getPercentCategory(totalOffer, totalExecuted),
    },
    {
      title: 'Aceptado',
      icon: 'ni-user',
      color: '#b8acff',
      value: totalAccepted,
      percent: getPercentCategory(totalOffer, totalAccepted),
    },
    {
      title: 'Rechazado',
      icon: 'ni-user',
      color: '#ffa9ce',
      value: totalRejected,
      percent: getPercentCategory(totalOffer, totalRejected),
    },
    {
      title: 'Disponible',
      icon: 'ni-user',
      color: '#7de1f8',
      value: totalAvailable,
      percent: getPercentCategory(totalOffer, totalAvailable),
    },
  ];
};

export const getChartTenderOfferData = (
  society,
  tenderOffer,
  totalOffer = 0
) => {
  const { totalExecuted, totalAccepted, totalRejected } = getTenderOfferStatus(
    society,
    tenderOffer
  );

  const totalAvailable = totalOffer - totalAccepted - totalRejected;

  const legend = getTenderOfferLegend({
    totalOffer,
    totalExecuted,
    totalAccepted,
    totalRejected,
    totalAvailable,
  });

  const chartValues = [
    totalExecuted,
    totalAccepted,
    totalRejected,
    totalAvailable,
  ];

  return {
    legend,
    chartValues,
  };
};

export const getChartVotingResultsData = (voteData) => ({
  labels: votingResultsChartLabels,
  datasets: [
    {
      fill: true,
      data: voteData,
      borderWidth: 2,
      borderColor: '#ffffff',
      backgroundColor: votingResultsChartColors,
    },
  ],
});
