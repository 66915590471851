import { Society, User } from 'types';

type FilterOptionsProps = {
  user: User;
  society: any;
  menuOptions: any[];
  administrator: any;
};

const optionIDs = {
  EDIT_ADMIN: 'EDIT_ADMIN',
  EDIT_PERMISSIONS: 'EDIT_PERMISSIONS',
  DELETE_ADMIN: 'DELETE_ADMIN',
};

const isLimitedAdmin = (administrator: User, society: Society) => {
  const currentSocietyPermissions = administrator?.adminPermissions?.find(
    (permission) => permission.society === society?.['_id']
  );

  if (!currentSocietyPermissions) {
    return false;
  }

  const hasAllPermissionsEnabled = currentSocietyPermissions
    ? Object.values(currentSocietyPermissions.permissions).every(
        (permission) => permission.enabled
      )
    : false;

  return !hasAllPermissionsEnabled;
};

export const filterOptions = ({
  menuOptions,
  society,
  user,
  administrator,
}: FilterOptionsProps) => {
  const societyMainAdmin = society?.mainAdmin || society?.administrators[0];

  const isAdminSameThanUser = user?._id === administrator?._id;
  const isAdminMainAdmin = administrator?._id === societyMainAdmin;
  const isUserTheMainAdmin = user?._id === societyMainAdmin;
  const isUserOneOfTheAdmin = society?.administrators.includes(user?._id);
  const isUserLimitedAdmin = isLimitedAdmin(user, society);

  const getDeleteOptionEnabled = () => {
    if (isUserTheMainAdmin) {
      return true;
    }

    const isRegularAdmin =
      isUserOneOfTheAdmin && !isUserLimitedAdmin && !isAdminMainAdmin;

    if (isRegularAdmin) {
      return true;
    }
    if (isUserLimitedAdmin && isAdminSameThanUser) {
      return true;
    }

    return false;
  };

  const getEditOptionEnabled = () => isAdminSameThanUser;

  const getEditPermissionsOptionEnabled = () => isUserTheMainAdmin;

  return menuOptions.map((option) => {
    switch (option.id) {
      case optionIDs.EDIT_ADMIN: {
        return getEditOptionEnabled()
          ? { ...option }
          : { ...option, disabled: true };
      }

      case optionIDs.EDIT_PERMISSIONS: {
        return getEditPermissionsOptionEnabled()
          ? { ...option }
          : { ...option, disabled: true };
      }

      case optionIDs.DELETE_ADMIN: {
        return getDeleteOptionEnabled()
          ? { ...option }
          : { ...option, disabled: true };
      }

      default:
        return option;
    }
  });
};
