import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

import PartnersPreviewTableHeader from './PartnersPreviewTableHeader';
import PartnersPreviewTableBody from './PartnersPreviewTableBody';

type Props = {
  partners: (Partner & { isExisting?: boolean })[];
};

const PartnersPreviewTable: FC<Props> = ({ partners }) => (
  <>
    <Modal.Body>
      <div className="nk-block">
        <div className="card">
          <table className="table table-tranx">
            <PartnersPreviewTableHeader />
            <PartnersPreviewTableBody partners={partners} />
          </table>
        </div>
      </div>
    </Modal.Body>
  </>
);

export default PartnersPreviewTable;
