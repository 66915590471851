import React, { useState } from 'react';
import i18n from 'i18n/config';

import List from 'modules/documents/components/List';
import documentSections from 'constants/documentSections';
import documentTypes from 'constants/documentTypes';
import CouncilMinute from 'modules/documents/components/CouncilMinute';

const currentSection = documentTypes.COUNCILMINUTES;

const CouncilMinutes = ({
  documents,
  isLoadingData,
  currentCouncilMinute,
  setCurrentCouncilMinute,
  searchTerm,
}) => {
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );

  return (
    <>
      {!currentCouncilMinute ? (
        <div className="nk-fmg-body-content">
          <CouncilMinute
            setCurrentCouncilMinute={setCurrentCouncilMinute}
            section={section}
          />
        </div>
      ) : (
        <List
          section={section}
          documents={documents}
          isLoadingData={isLoadingData}
          currentCouncilMinute={currentCouncilMinute}
          setCurrentCouncilMinute={setCurrentCouncilMinute}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};

export default CouncilMinutes;
