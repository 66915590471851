import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  name: string;
  handleSaveCommittee: any;
};

const AddCommitteeModalFooter: FC<Props> = ({
  name,
  handleSaveCommittee,
}: Props) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={handleSaveCommittee}
          disabled={!name}
        >
          {t('SaveCommittee')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default AddCommitteeModalFooter;
