import userTypes from 'constants/userTypes';
import { tenderParticipantStatusTypes } from 'utils/tenderOfferTypes';

export const getPlansSummary = ({
  beneficiaries,
  plans,
  consolidationPeriods,
  tenderOffers,
  endDate,
}) => {
  if (!plans.length || !beneficiaries.length) return null;
  // initialize summary object
  const summary = plans.reduce((acc, plan) => {
    acc[plan['_id']] = {
      totalUnits: 0,
      assigned: 0,
      availables: 0,
      vested: 0,
      canceled: 0,
      acquired: 0,
    };
    return acc;
  }, {});

  // initialize availables count for each plan in the correspondent period for plan start date
  plans.forEach((plan) => {
    if (plan.sharesTotal && new Date(plan?.startDate) < new Date(endDate)) {
      if (summary[plan['_id']]) {
        summary[plan['_id']].availables = plan.sharesTotal;
        summary[plan['_id']].totalUnits = plan.sharesTotal;
      }
    }
  });

  beneficiaries.forEach((beneficiary) => {
    let currentAssigned = 0;
    let lastDate = new Date();
    const currentPlan = plans.find((plan) => plan['_id'] === beneficiary?.plan);
    if (new Date(beneficiary.planStartDate) < new Date(endDate)) {
      if (summary[currentPlan['_id']]) {
        currentAssigned = beneficiary?.sharesCount?.future || 0;
        lastDate = new Date(beneficiary.planStartDate);
        // summary[currentPlan['_id']].assigned +=
        //   beneficiary?.sharesCount?.future || 0;
        // summary[currentPlan['_id']].availables =
        //   currentPlan.sharesTotal -
        //   summary[currentPlan['_id']].assigned +
        //   summary[currentPlan['_id']].canceled +
        //   summary[currentPlan['_id']].acquired;
      }
    }
    if (
      beneficiary.isCanceled &&
      new Date(beneficiary?.cancelationDate) < new Date(endDate)
    ) {
      if (summary[currentPlan['_id']]) {
        const canceledUnits =
          beneficiary?.sharesCount?.future -
            beneficiary?.finalConsolidatedUnits || 0;
        summary[currentPlan['_id']].canceled += canceledUnits || 0;
        currentAssigned = beneficiary?.finalConsolidatedUnits || 0;
        lastDate = new Date(beneficiary?.cancelationDate);
        // summary[currentPlan['_id']].availables =
        //   currentPlan.sharesTotal -
        //   summary[currentPlan['_id']].assigned +
        //   summary[currentPlan['_id']].canceled +
        //   summary[currentPlan['_id']].acquired;
      }
    }
    const beneficiaryConsolidationPeriods =
      consolidationPeriods[beneficiary['_id']] || [];
    beneficiaryConsolidationPeriods.forEach((period) => {
      const { date, vestedUnits } = period;
      if (new Date(date) < new Date(endDate)) {
        if (summary[currentPlan['_id']]) {
          summary[currentPlan['_id']].vested += vestedUnits || 0;
        }
      }
    });
    const beneficiaryTenders = beneficiary.soldUnits || [];
    const tenders = tenderOffers.filter((tender) =>
      beneficiaryTenders.includes(tender['_id'])
    );
    tenders.forEach((tender) => {
      const participants = tender.participants.filter(
        (participant) => participant.id === beneficiary['_id']
      );
      participants.forEach((participant) => {
        const { type, totalAccepted, status, operationDate } = participant;
        if (
          type === userTypes.BENEFICIARY &&
          status === tenderParticipantStatusTypes.ACCEPTED.value &&
          totalAccepted &&
          new Date(operationDate) < new Date(endDate)
        ) {
          if (summary[currentPlan['_id']]) {
            summary[currentPlan['_id']].acquired += totalAccepted || 0;
            if (new Date(operationDate) > lastDate) {
              currentAssigned -= totalAccepted || 0;
            }
            // summary[currentPlan['_id']].availables =
            //   currentPlan.sharesTotal -
            //   summary[currentPlan['_id']].assigned +
            //   summary[currentPlan['_id']].canceled +
            //   summary[currentPlan['_id']].acquired;
          }
        }
      });
    });
    summary[currentPlan['_id']].assigned += currentAssigned;
    summary[currentPlan['_id']].availables =
      currentPlan.sharesTotal - summary[currentPlan['_id']].assigned;
  });
  return summary;
};
