/* eslint-disable react/display-name */
import CellData from 'modules/_shared/components/Tables/CellData';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Society'),
      field: 'societyName',
      selector: (row) => row[i18n.t('Society')],
      sortable: true,
      grow: 2,
      cell: (row) =>
        CellData.Society({
          society: row.society,
          url: '/detalle-representante',
          type: 'light',
          showHolding: false,
          customLink: row.link || '',
          noRedirect: row.noRedirect,
        }),
      export: true,
    },
  ];
}
export default tableColumns;
