/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useEffect, useState } from 'react';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from 'react-grid-dnd';
import { useDispatch, useSelector } from 'react-redux';

import documentTypes from 'constants/documentTypes';

import MenuDots from 'modules/_shared/components/MenuDots';
import folders from 'assets/images/icon-sections/folders.svg';
import { updateSociety } from 'modules/society/redux/societyActions';
import { useGetDocumentsSectionPermissions } from 'modules/documents/hooks/useGetDocumentsSectionPermissions';

import NoDocumentsAlert from '../List/NoDocumentsAlert';
import menuOptions from './menuOptions';

import './CouncilMinute.scss';

const CouncilMinute = ({ setCurrentCouncilMinute, section }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isReadOnly } = useGetDocumentsSectionPermissions().data;

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const documents = useSelector((state) => state.documents);
  const councilMinutes = useSelector(
    (state) => state.society?.actualSociety?.councilMinutes
  );

  const [sortedCouncilMinutes, setSortedCouncilMinutes] =
    useState(councilMinutes);
  const [currentMenuOptions] = useState(menuOptions(i18n));

  const handleClick = (councilMinute) => {
    if (setCurrentCouncilMinute) {
      setCurrentCouncilMinute(councilMinute);
    }
  };

  const updateCouncilMinutesOnSociety = (newCouncilMinutesData) => {
    dispatch(
      updateSociety(
        actualSociety['_id'],
        {
          councilMinutes: newCouncilMinutesData,
        },
        false
      )
    );
  };

  const getReorderedCouncilMinutes = (sourceIndex, targetIndex) => {
    const reorderedCouncilMinutes = [...sortedCouncilMinutes];
    const [movedItem] = reorderedCouncilMinutes.splice(sourceIndex, 1);
    reorderedCouncilMinutes.splice(targetIndex, 0, movedItem);

    return reorderedCouncilMinutes.map((item, index) => ({
      ...item,
      index,
    }));
  };

  const getDocumentsCountInCouncilMinute = (id) => {
    const documentsInCouncilMinute = documents.filter(
      (document) =>
        document?.category === documentTypes.COUNCILMINUTES &&
        document?.subcategory === id
    ).length;

    const suffix =
      documentsInCouncilMinute === 1 ? t('document') : t('documents');
    return `${documentsInCouncilMinute} ${suffix}`;
  };

  const handleOnDragEnd = (sourceId, sourceIndex, targetIndex, targetId) => {
    const nextState = swap(sortedCouncilMinutes, sourceIndex, targetIndex);
    setSortedCouncilMinutes(nextState);

    const reorderedCouncilMinutes = getReorderedCouncilMinutes(
      sourceIndex,
      targetIndex
    );
    updateCouncilMinutesOnSociety(reorderedCouncilMinutes);
  };

  useEffect(() => {
    if (
      councilMinutes &&
      councilMinutes?.length > sortedCouncilMinutes?.length
    ) {
      const sortedArray = councilMinutes.sort((a, b) => a?.index - b?.index);
      setSortedCouncilMinutes(sortedArray);
    }
  }, [councilMinutes]);

  const Item = ({ councilMinute }) => (
    <div
      key={councilMinute['_id']}
      className="nk-file-item nk-file cursor-pointer w-100 h-100 mx-0"
    >
      <div>
        <div
          className="nk-file-info"
          style={{
            cursor: 'grab',
          }}
        >
          <div className="nk-file-link">
            <div className="nk-file-title">
              <div
                onClick={() => handleClick(councilMinute)}
                style={{
                  cursor: 'pointer',
                }}
              >
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={folders} alt="section" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{councilMinute.label}</span>
                    <span className="sub-text">
                      {getDocumentsCountInCouncilMinute(councilMinute._id)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-file-meta councilminutes-menudots">
        <div className="tb-lead">
          <MenuDots
            menuOptions={currentMenuOptions}
            id={councilMinute._id}
            params={{
              currentCouncilMinute: councilMinute,
              actualSociety,
              documents,
              isReadOnly,
            }}
            direction="left"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="nk-fmg-quick-list nk-block">
      <div className="nk-block-head-xs">
        <div className="nk-block-between g-2">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{t('CouncilMinutes')}</h3>
          </div>
        </div>
      </div>

      {sortedCouncilMinutes?.length > 0 ? (
        <GridContextProvider onChange={handleOnDragEnd}>
          <GridDropZone
            id="items"
            boxesPerRow={4}
            rowHeight={200}
            style={{ height: '400px' }}
          >
            {sortedCouncilMinutes?.map((councilMinute) => (
              <GridItem key={councilMinute?.index}>
                <div className="nk-files nk-files-view-grid p-1">
                  <Item councilMinute={councilMinute} />
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
      ) : (
        <NoDocumentsAlert section={section} />
      )}
    </div>
  );
};

export default CouncilMinute;
