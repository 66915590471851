import convertibleNoteStatus from './convertibleNoteStatus';

export default [
  {
    name: convertibleNoteStatus.name.NC_DRAFT,
    value: convertibleNoteStatus.var.NC_DRAFT,
  },
  {
    name: convertibleNoteStatus.name.NC_ACTIVE,
    value: convertibleNoteStatus.var.NC_ACTIVE,
  },
  {
    name: convertibleNoteStatus.name.NC_EXECUTED,
    value: convertibleNoteStatus.var.NC_EXECUTED,
  },
];