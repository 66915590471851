import { useTranslate } from 'hooks/useTranslate';
import Select, { MultiValue, SingleValue } from 'react-select';
import makeAnimated from 'react-select/animated';

import '../../../../assets/styles/ReactSelect.scss';

type Props = {
  delegationRepresentedOptions: any[];
  delegationRepresentativeOptions: any[];
  defaultRepresentedOption: any;
  defaultRepresentativeOption: any;
  selectedRepresented: string;
  onChangeRepresented: (value: string) => void; // eslint-disable-line
  selectedRepresentative: string;
  onChangeRepresentative: (value: string) => void; // eslint-disable-line
};

type Option = {
  value: string;
  label: string;
  disabled: boolean;
};

const animatedComponents = makeAnimated();

const DocumentModalDelegationSection = ({
  delegationRepresentedOptions,
  delegationRepresentativeOptions,
  defaultRepresentedOption,
  defaultRepresentativeOption,
  selectedRepresented,
  onChangeRepresented,
  selectedRepresentative,
  onChangeRepresentative,
}: Props) => {
  const { t } = useTranslate();

  const handleChangeRepresented = (
    newValue: SingleValue<Option> | MultiValue<Option>
  ) => {
    if (!Array.isArray(newValue)) {
      const selectedOption = newValue as SingleValue<Option>;
      if (selectedOption) {
        onChangeRepresented(selectedOption.value);
      }
    }
  };

  const handleChangeRepresentative = (
    newValue: SingleValue<Option> | MultiValue<Option>
  ) => {
    if (!Array.isArray(newValue)) {
      const selectedOption = newValue as SingleValue<Option>;
      if (selectedOption) {
        onChangeRepresentative(selectedOption.value);
      }
    }
  };

  return (
    <div className="form-group">
      <div className="form-group row mb-0">
        <div className="form-group col-6">
          <label className="form-label">{t('Represented')}</label>

          <div className="form-group">
            <Select
              className="react-select react-select-options__no-wrap"
              placeholder={t('SelectRepresented')}
              components={animatedComponents}
              options={delegationRepresentedOptions}
              isOptionDisabled={(option: Option) => option.disabled}
              closeMenuOnSelect
              defaultValue={defaultRepresentedOption}
              onChange={handleChangeRepresented}
            />
          </div>
        </div>

        <div className="form-group col-6">
          <label className="form-label">{t('Delegate')}</label>

          <div className="form-group">
            <Select
              className="react-select react-select-options__no-wrap"
              placeholder={t('SelectRepresentative')}
              components={animatedComponents}
              options={delegationRepresentativeOptions}
              isOptionDisabled={(option: Option) => option.disabled}
              closeMenuOnSelect
              defaultValue={defaultRepresentativeOption}
              onChange={handleChangeRepresentative}
            />
          </div>
        </div>
      </div>

      {selectedRepresented === selectedRepresentative &&
        selectedRepresented && (
          <div className="alert alert-icon alert-danger" role="alert">
            <em className="icon ni ni-alert-circle" />
            {t('CannotSelectSamePartner')}
          </div>
        )}
    </div>
  );
};

export default DocumentModalDelegationSection;
