import types from 'redux/actions/actionTypes';
import initialState from 'redux/initialState';

export default function reducer(
  notification = initialState.notification,
  action
) {
  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      return { ...action.data, visible: true };

    case types.HIDE_NOTIFICATION:
      return null;

    default:
      return notification;
  }
}
