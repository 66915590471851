import React from 'react';
import { COMMENTS_MAX_LENGTH } from 'constants/defaultConstants';

const CommentsCharCounter = ({ comments }) => {
  const counterClassName =
    comments?.length === COMMENTS_MAX_LENGTH ? 'text-danger' : 'text-primary';

  return (
    <span className={counterClassName}>
      {comments?.length} / {COMMENTS_MAX_LENGTH}
    </span>
  );
};

export default CommentsCharCounter;
