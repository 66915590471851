function addValues(
  node: any,
  societyName: string,
  parentNDValue = 100,
  parentFDValue = 100
) {
  const absoluteNDValue =
    (parentNDValue *
      (node.percentNDValue !== null ? node.percentNDValue : 100)) /
    100;
  const absoluteFDValue =
    (parentFDValue *
      (node.percentFDValue !== null ? node.percentFDValue : 100)) /
    100;

  const children =
    node.children?.map((child: any) =>
      addValues(child, societyName, absoluteNDValue, absoluteFDValue)
    ) || [];

  const result = {
    ...node,
    absoluteNDValue,
    absoluteFDValue,
    tooltip: `${societyName}: ${absoluteNDValue.toFixed(
      2
    )}% / ${absoluteFDValue.toFixed(2)}%`,
  };

  if (children.length > 0) {
    result.children = children;
  }

  return result;
}

const addAbsolutePercents = (nodes: any) => {
  const mainSociety = nodes?.name || '';
  const result = addValues(nodes, mainSociety);

  return result;
};

export default addAbsolutePercents;
