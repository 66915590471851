/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import parse from 'html-react-parser';

import { dateTimeFormat } from 'constants/formats';
import handleGenerateCertificatePDF from 'utils/handleGenerateCertificatePDF';

import {
  getDeliveredIp,
  getMailDeliveredDate,
  getMailStatus,
} from 'modules/communications/components/utils';

const Certification = ({ communication, recipientsInfo, download = false }) => {
  const { t } = useTranslate();
  const printRef = useRef();
  const dispatch = useDispatch();

  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [parsedDocument, setParsedDocument] = useState();

  const certificateId = communication?.certificate?.id || '00746F5K28';

  useEffect(() => {
    if (communication?.body) {
      setParsedDocument(parse(communication?.body));
    }
  }, [actualSociety, communication]);

  const getRecipientsMail = (recipients) => {
    if (!recipients) return '';
    return recipients.map((recipient) => recipient.email).join(', ');
  };

  // eslint-disable-next-line arrow-body-style
  // function staticHtml() {
  //   return (
  //     <html lang="en">
  //       <head>
  //         <meta charset="UTF-8" />
  //         <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  //         <meta
  //           name="viewport"
  //           content="width=device-width, initial-scale=1.0"
  //         />
  //         <title>Certificado</title>
  //         <link rel="stylesheet" href="./Certification.css" />
  //       </head>
  //       <body>
  //         <Provider store={store}>
  //           <Certification communication={communication} download />
  //         </Provider>
  //       </body>
  //     </html>
  //   );
  // }

  // const handleGenerateCertificatePDF = async () => {
  //   try {
  //     const html = ReactDOMServer.renderToString(
  //       React.createElement(staticHtml, {
  //         cssUrl: 'https://dashlite.net/demo4/assets/css/dashlite.css',
  //       })
  //     );
  //     const options = {
  //       filename: `Certificado_${certificateId}_${format(
  //         new Date(),
  //         'yyyy-MM-dd_HH-mm-ss'
  //       )}.pdf`,
  //       html2canvas: { scale: 2 },
  //     };
  //     html2pdf().from(html).set(options).save();
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log({ error });
  //   }
  // };

  return (
    <>
      <div className="nk-ibx-reply-item nk-reply-item" ref={printRef}>
        <div className="nk-block-head m-0">
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <div className="nk-block-head-content d-flex justify-content-between">
                <h4 className="title">
                  {t('Certificate')}
                  <strong className="text-primary small ml-2">
                    #{certificateId}
                  </strong>
                </h4>

                <ul className={download ? 'd-none' : 'd-flex g-1'}>
                  <li className="">
                    <span
                      className="btn btn-icon btn-trigger btn-tooltip"
                      onClick={() => {
                        handleGenerateCertificatePDF(communication);
                      }}
                    >
                      <em className="icon ni ni-download" />
                    </span>
                  </li>
                  <li className="">
                    <ReactToPrint
                      trigger={() => (
                        <span className="btn btn-icon btn-trigger btn-tooltip">
                          <em className="icon ni ni-printer" />
                        </span>
                      )}
                      content={() => printRef.current}
                      documentTitle={`Certificado - ${certificateId} - ${format(
                        new Date(),
                        'dd-MM-yyyy'
                      )}`}
                    />
                  </li>
                </ul>
              </div>

              <p className="heading">{t('SttokCertifies')}:</p>
              <p className="heading text-justify">
                {t('CertificationMessage')}
              </p>

              <div className="nk-block-des text-soft">
                <ul className="list-inline">
                  <li>
                    <span className="text-base">
                      <b>{t('GeneratedCertificate')}:</b>{' '}
                      {format(new Date(), dateTimeFormat)}
                    </span>
                  </li>
                  <li>
                    <span className="text-base">
                      <b>{t('Certificate')} Nº:</b> #{certificateId}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="nk-block m-0">
          <div className="invoice">
            <div className="invoice-action">
              <span className="fs-20px">
                <em className="icon ni ni-shield-check-fill" />
              </span>
            </div>
            <div className="invoice-wrap">
              <div className="invoice-head">
                <div className="invoice-contact">
                  <span className="overline-title">{t('Email')}</span>
                  <div className="invoice-contact-info">
                    <h4 className="title">{communication?.subject}</h4>
                    <ul className="list-plain">
                      <li>
                        <em className="icon ni ni-user-alt" />
                        <span>
                          {t('Sender')}:
                          <br /> {communication?.sender?.email}
                        </span>
                      </li>

                      <li>
                        <em className="icon ni ni-calendar" />
                        <span>
                          {t('SentDate')}:
                          <br />
                          {communication?.date
                            ? format(
                                new Date(communication?.date),
                                dateTimeFormat
                              )
                            : ''}
                        </span>
                      </li>

                      <li>
                        <em className="icon ni ni-user-alt" />
                        <span>{t('Recipient(s)')}:</span>
                        <br />
                        <div className="ml-4">
                          <span className="text-soft">
                            {getRecipientsMail(communication?.recipients)}
                          </span>
                        </div>
                      </li>

                      <li>
                        <em className="icon ni ni-mail" />
                        <span>
                          {t('Subject')}:
                          <br /> {communication?.subject}
                        </span>
                      </li>

                      <li>
                        <em className="icon ni ni-file" />
                        <span>
                          {t('AttachedFiles')}:
                          <br />
                          {communication?.attachments?.length > 0
                            ? communication?.attachments?.map((attachment) => (
                                <span className="d-block" key={attachment._id}>
                                  {attachment?.name}
                                </span>
                              ))
                            : t('NoAttachments')}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="invoice-body">
                <span className="overline-title">{t('Textual')}</span>
                <br />
                <div className="nk-ibx-context">
                  <span className="nk-ibx-context-text">
                    <br />
                    <div className="nk-reply-entry entry">
                      <div
                        style={{
                          whiteSpace:
                            typeof parsedDocument === 'string'
                              ? 'pre-line'
                              : 'normal',
                        }}
                      >
                        {parsedDocument || ''}
                      </div>
                    </div>
                  </span>
                </div>
                <br />
              </div>

              <div className="invoice-bills">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>{t('Email')}</th>
                        <th>{t('Recipient')}</th>
                        <th>{t('IPDelivered')}</th>
                        <th>{t('Delivered')} (UTC)</th>
                        <th className="text-left">{t('Status')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recipientsInfo?.map((recipient) => (
                        <tr key={recipient?._id}>
                          <td>{recipient?.email}</td>
                          <td>{recipient?.name}</td>
                          <td>{getDeliveredIp(recipient) || '-'}</td>
                          <td>{getMailDeliveredDate(recipient)}</td>
                          <td className="text-left">
                            {getMailStatus(recipient, t)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <br />
                  <span className="overline-title d-none">
                    <p style={{ textTransform: 'uppercase' }}>
                      {t('OriginalMessage')}
                    </p>
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Certification;
