import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addConfigurationToEmailData } from 'utils/emails';

import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export function sendEmail(newEmailData, showAlert = true) {
  const { file, ...emailData } = newEmailData;
  const formData = new FormData();

  return async (dispatch, getState) => {
    try {
      const state = getState();
      const emailDataWithConfiguration = addConfigurationToEmailData(
        state.society.actualSociety,
        emailData
      );

      if (file) {
        formData.append('file', file);
      }
      formData.append('data', JSON.stringify(emailDataWithConfiguration));

      await axios.post(`${process.env.REACT_APP_API_URL}/mail`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      if (showAlert) dispatch(addAlert(alertBodyTypes['SEND_EMAIL_SENT']));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_SEND_EMAIL']));
    }
  };
}

export default sendEmail;
