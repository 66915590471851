/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { format, isValid } from 'date-fns';
import html2pdf from 'html3pdf';
import { useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import {
  getStockPlans,
  updateStockPlan,
} from 'modules/beneficiaries/redux/planActions';
import {
  addDocument,
  getDocument,
} from 'modules/documents/redux/documentActions';
import {
  getTenderOffers,
  updateTenderOffer,
} from 'modules/transactions/redux/tenderOfferActions';
import { store } from 'redux/store';

import { startSignProcess, toBase64 } from 'utils/docusignActions';
import { getActualSociety, getFullAddress } from 'utils/filters';
import {
  formatCurrencyDecimals,
  formatCurrencyDecimalsLong,
  formatNumber,
  formatNumberDecimalsLong,
} from 'utils/formats';
import getEnvelopeData from 'utils/getEnvelopeData';
import {
  tenderOfferParticipantStatus,
  tenderStatusTypes,
} from 'utils/tenderOfferTypes';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import { dateFormat } from 'constants/formats';
import signatureTypes from 'constants/signatureTypes';
import sizes from 'constants/sizes';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import AcceptTenderOffer from 'modules/_shared/components/Modals/AcceptTenderOffer';
import SellTenderOffer from 'modules/_shared/components/Modals/SellTenderOffer';
import TenderOfferDynamic from 'modules/transactions/components/TenderOfferDynamic';
import { useGetTransactionsSectionPermissions } from 'modules/transactions/hooks/useGetTransactionsSectionPermissions';

import { useTranslate } from 'hooks/useTranslate';
import parseParticipant from './parseParticipant';
import parseTenderOfferSummary from './parseTenderOfferSummary';

import './TenderOfferParticipation.scss';

const TenderOfferParticipation = () => {
  const { t, i18n } = useTranslate();
  const printRef = useRef();
  const dispatch = useDispatch();
  const { societyId, tenderOfferId, partnerId, beneficiaryId } = useParams();

  const { isReadOnly } = useGetTransactionsSectionPermissions().data;

  const {
    actualSociety,
    user,
    plans,
    actualTenderOffer,
    partners,
    beneficiaries,
  } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    user: state.user,
    actualTenderOffer: state.tenderOffers?.find(
      (tenderOffer) => tenderOffer._id === tenderOfferId
    ),
    plans: state.plans,
    partners: state.society?.actualSociety?.partners,
    beneficiaries: state.society?.actualSociety?.beneficiaries,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState({});

  const [isPartner] = useState(!!(partnerId && !beneficiaryId));
  const [isBeneficiary] = useState(!!(beneficiaryId && !partnerId));
  const [isOfferActive, setIsOfferActive] = useState(false);

  const [participant, setParticipant] = useState({});
  const [participantInOffer, setParticipantInOffer] = useState({});

  const [planUnitPrice, setPlanUnitPrice] = useState(0);
  const [totalExercisePrice, setTotalExercisePrice] = useState(0);

  const [totalUnitsAccepted, setTotalUnitsAccepted] = useState(0);
  const [totalPriceAccepted, setTotalPriceAccepted] = useState(0);

  const [totalSharesAccepted, setTotalSharesAccepted] = useState(0);
  const [totalStocksAccepted, setTotalStocksAccepted] = useState(0);
  const [totalPhantomsAccepted, setTotalPhantomsAccepted] = useState(0);

  const handleModifyUnits = ({ customSell = false, ...sellData }) => {
    const participantType = isPartner ? 'PARTNER' : 'BENEFICIARY';
    const participantShares = participant?.shares || 0;
    const participantStocks = participant?.stocks || 0;
    const participantPhantoms = participant?.phantoms || 0;

    let totalAccepted;

    if (!customSell) {
      totalAccepted = 0;
      const {
        sellAll,
        maxUnits,
        maxPercentUnits,
        maxAmount,
        maxPhantoms,
        maxPercentPhantoms,
        maxStocks,
        maxPercentStocks,
        totalPriceByShare,
      } = actualTenderOffer;

      if (participantType === 'PARTNER') {
        if (sellAll) {
          totalAccepted = participantShares;
        } else {
          const sharesMaxAllowed = maxUnits;
          const sharesPercentAllowed =
            (participantShares * maxPercentUnits) / 100;

          const totalSharesAllowed = Math.max(
            sharesMaxAllowed,
            sharesPercentAllowed
          );

          const priceTotalShares = totalSharesAllowed * totalPriceByShare;

          if (priceTotalShares < maxAmount) {
            totalAccepted = totalSharesAllowed;
          } else {
            totalAccepted = Math.floor(maxAmount / totalPriceByShare);
          }
        }
      }

      if (participantType === 'BENEFICIARY') {
        if (sellAll) {
          totalAccepted = participantStocks + participantPhantoms;
        } else {
          const unitsMaxAllowed = maxUnits;

          const stocksMaxAllowed = maxStocks;
          const stocksPercentAllowed =
            (participantStocks * maxPercentStocks) / 100;
          const totalStocksAllowed = Math.max(
            stocksMaxAllowed,
            stocksPercentAllowed
          );

          const phantomsMaxAllowed = maxPhantoms;
          const phantomsPercentAllowed =
            (participantPhantoms * maxPercentPhantoms) / 100;
          const totalPhantomsAllowed = Math.max(
            phantomsMaxAllowed,
            phantomsPercentAllowed
          );

          const totalUnitsAllowed = Math.max(
            unitsMaxAllowed,
            totalStocksAllowed + totalPhantomsAllowed
          );

          const priceTotalUnits = totalUnitsAllowed * totalPriceByShare;

          if (priceTotalUnits < maxAmount) {
            totalAccepted = totalUnitsAllowed;
          } else {
            totalAccepted = Math.floor(maxAmount / totalPriceByShare);
          }
        }
      }
    } else {
      totalAccepted = sellData?.totalAccepted || 0;
    }

    setTotalUnitsAccepted(totalAccepted);
  };

  const handleAcceptOffer = async () => {
    const participantId = partnerId || beneficiaryId;
    const participantType = isPartner ? 'PARTNER' : 'BENEFICIARY';

    try {
      dispatch(
        updateTenderOffer(tenderOfferId, {
          participantId,
          participantData: {
            id: participantId,
            type: participantType,
            status: tenderOfferParticipantStatus.ACCEPTED,
            totalAccepted: totalUnitsAccepted,
            operationDate: new Date(),
          },
        })
      );

      if (actualTenderOffer?.template) {
        const currentTemplate = await dispatch(
          getDocument(actualTenderOffer?.template)
        );
        const templateUpdated = updateDocumentWithValues(
          currentTemplate?.editorModel,
          tags({
            plan: {},
            society: actualSociety,
            tenderOffer: actualTenderOffer,
            beneficiary: currentBeneficiary,
            partner: null,
          })
        );
        const { hasTenderOfferSummary } = templateUpdated;

        const templateModel = templateUpdated.html;

        const templateFinal = (
          <TenderOfferDynamic
            hasTenderOfferSummary={hasTenderOfferSummary}
            tenderOffer={parseTenderOfferSummary({
              actualTenderOffer,
              participant,
              participantInOffer,
              beneficiary: currentBeneficiary,
              totalUnitsAccepted,
              totalPriceAccepted,
              totalExercisePrice,
            })}
          />
        );
        const tenderHtml = renderToStaticMarkup(templateFinal);
        const options = {
          filename: 'invitation.pdf',
          margin: [10, 10, 10, 10], // top, right, bottom, left
          pagebreak: { mode: 'avoid-all' },
          html2canvas: { scale: 2 },
          jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
          },
        };
        const fileBlob = await html2pdf()
          .from(templateModel + tenderHtml)
          .set(options)
          .output('blob');

        const templateFile = new File([fileBlob], 'invitation.pdf', {
          type: 'application/pdf',
        });

        const fileName = `Tender_Offer_${actualTenderOffer.name}_${currentBeneficiary.name}.pdf`;
        const newDocument = {
          name: fileName,
          file: templateFile,
          size: templateFile?.size || 0,
          date: new Date(),
          lastAccess: new Date(),
          category: documentTypes.SCRIPTURES,
          author: user['_id'],
          society: actualSociety['_id'],
          isGenerated: true,
          fileType: fileTypes.PDF,
          description: t('TenderOffer'),
        };

        const uploadedDocument = await store.dispatch(addDocument(newDocument));

        const signFileBase64 = await toBase64(templateFile);

        const signatureType =
          participantType === 'PARTNER'
            ? signatureTypes.TENDER_OFFER_PARTNER
            : signatureTypes.TENDER_OFFER_BENEF;

        const signers = [
          {
            id: participant?.id,
            email: participant?.email,
            name: participant?.name,
            role: participantType,
            type: signatureType,
          },
          {
            id: actualTenderOffer?._id,
            email: actualTenderOffer?.buyerEmail || '',
            name: actualTenderOffer?.buyerName || '',
            role: 'SOCIETY',
            type: signatureTypes.FREE_SIGNATURE,
          },
        ];

        let beneficiaries = [];
        if (signatureType === signatureTypes.TENDER_OFFER_BENEF) {
          beneficiaries = [
            {
              id: participant.id,
              planId: participant?.planData._id,
            },
          ];
        }

        const linkedType = participantType;
        const role =
          participantType === 'PARTNER' ? 'PARTNERS' : 'BENEFICIARIES';

        const envelopeData = getEnvelopeData({
          signers,
          signType: signatureType,
          fileName,
          fileType: fileTypes.PDF,
          linkedType,
          role,
          userId: user?._id,
          societyId: actualSociety?._id,
          beneficiaries,
          documentId: uploadedDocument?._id,
        });

        const signProcessData = {
          signers,
          societyName: actualSociety?.name,
          documentName: fileName,
          planName: actualTenderOffer?.name,
          language: actualSociety?.societyLanguage || 'es',
          signatureType: signatureTypes.TENDER_OFFER,
          signatureFile: signFileBase64,
          signatureFileName: fileName,
          envelopeData,
        };

        await startSignProcess(signProcessData);
      }

      if (actualTenderOffer?.targetPlan && participantType === 'BENEFICIARY') {
        const targetPlan = plans.find(
          (plan) => plan._id === actualTenderOffer?.targetPlan
        );
        const sourcePlan = plans.find(
          (plan) => plan._id === currentBeneficiary.plan
        );

        const sourcePlanSharesTotal =
          sourcePlan.sharesTotal - totalUnitsAccepted;
        const targetPlanSharesTotal =
          targetPlan.sharesTotal + totalUnitsAccepted;

        await store.dispatch(
          updateStockPlan(
            sourcePlan._id,
            { sharesTotal: sourcePlanSharesTotal },
            false
          )
        );

        await store.dispatch(
          updateStockPlan(
            targetPlan._id,
            { sharesTotal: targetPlanSharesTotal },
            true
          )
        );
      }

      dispatch(
        addAlert(alertBodyTypes[`TENDER_OFFER_ACCEPTED_${i18n.language}`])
      );
    } catch (error) {
      console.log('[ERROR]', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRejectOffer = async () => {
    if (!isOfferActive || isReadOnly) return;

    const participantId = partnerId || beneficiaryId;
    const participantType = isPartner ? 'PARTNER' : 'BENEFICIARY';
    const participantUnits =
      participant?.stocks || participant?.phantoms || participant?.shares || 0;
    try {
      dispatch(
        updateTenderOffer(tenderOfferId, {
          participantId,
          participantData: {
            id: participantId,
            type: participantType,
            status: tenderOfferParticipantStatus.REJECTED,
            totalAccepted: participantUnits,
            operationDate: new Date(),
          },
        })
      );

      dispatch(
        addAlert(alertBodyTypes[`TENDER_OFFER_REJECTED_${i18n.language}`])
      );
    } catch (error) {
      console.log('[ERROR]', error);
    }
  };

  const handleOpenConfirmationModal = () => {
    if (isReadOnly) return;
    dispatch(
      setModal(
        <AcceptTenderOffer
          handleAcceptOffer={handleAcceptOffer}
          totalSharesAccepted={totalSharesAccepted}
          totalStocksAccepted={totalStocksAccepted}
          totalPhantomsAccepted={totalPhantomsAccepted}
          totalPriceAccepted={totalPriceAccepted}
          totalPriceByShare={actualTenderOffer?.totalPriceByShare}
          totalRetentionPercent={actualTenderOffer?.retentionPercent}
          setIsLoading={setIsLoading}
        />
      )
    );
  };

  const handleSelectQuantity = () => {
    if (!isOfferActive || isReadOnly) return;
    dispatch(
      setModal(
        <SellTenderOffer
          size={sizes.LG}
          participant={{ ...participant, ...participantInOffer }}
          actualTenderOffer={actualTenderOffer}
          handleModifyUnits={handleModifyUnits}
        />
      )
    );
  };

  const getInvoiceLineDescription = () => {
    const plan = participant?.planData?.name;
    const unitType = participant?.stocks
      ? '(Stock Options)'
      : '(Phantom Shares)';

    return `${plan} ${unitType}`;
  };

  const getBackLink = () => {
    const societyId = actualSociety?._id;
    const planId = participant?.planData?._id;
    const participantId = participant?.id;

    if (isPartner) {
      return `/detalle-socio/${societyId}/${participantId}`;
    }

    return `/detalle-beneficiario/${societyId}/${planId}/${participantId}`;
  };

  const updateTenderStatusIfOutdated = (tender) => {
    const today = new Date();
    if (
      today > new Date(tender.endDate) &&
      tender.status === tenderStatusTypes.ACTIVE.value
    ) {
      dispatch(
        updateTenderOffer(tender['_id'], {
          status: tenderStatusTypes.FINISHED.value,
        })
      );
    }
  };

  useEffect(() => {
    if (isPartner && partners && actualTenderOffer) {
      const partner = partners?.find((partner) => partner._id === partnerId);
      setParticipant(
        parseParticipant(partner, actualTenderOffer, actualSociety, isPartner)
      );
    }

    if (isBeneficiary && beneficiaries && actualTenderOffer && plans.length) {
      const beneficiary = beneficiaries?.find(
        (beneficiary) => beneficiary._id === beneficiaryId
      );
      beneficiary.planData = plans?.find(
        (plan) => plan._id === beneficiary.plan
      );
      setCurrentBeneficiary(beneficiary);
      setPlanUnitPrice(beneficiary.planData?.sharePrice || 0);

      setParticipant(
        parseParticipant(
          beneficiary,
          actualTenderOffer,
          actualSociety,
          isPartner
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    plans,
    partners,
    beneficiaries,
    isPartner,
    isBeneficiary,
    actualTenderOffer,
  ]);

  useEffect(() => {
    if (actualTenderOffer) {
      updateTenderStatusIfOutdated(actualTenderOffer);
      const currentParticipant = actualTenderOffer.participants?.find(
        (participant) =>
          (isPartner && participant.id === partnerId) ||
          (isBeneficiary && participant.id === beneficiaryId)
      );

      if (currentParticipant) {
        if (
          currentParticipant.status === tenderOfferParticipantStatus.NOTIFIED &&
          actualTenderOffer.status !== tenderStatusTypes.FINISHED.value
        ) {
          setIsOfferActive(true);
        } else {
          setIsOfferActive(false);
        }

        setParticipantInOffer(currentParticipant);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTenderOffer]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getTenderOffers(societyId));
      dispatch(getStockPlans(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (!isOfferActive) return;
    if (!participant) return;

    const { shares, phantoms, stocks } = participant;

    setTotalUnitsAccepted(shares + phantoms + stocks);
  }, [actualTenderOffer?.totalPriceByShare, participant, isOfferActive]);

  useEffect(() => {
    if (isOfferActive) return;
    if (participantInOffer.status === tenderOfferParticipantStatus.ACCEPTED) {
      const { totalAccepted } = participantInOffer;

      setTotalUnitsAccepted(totalAccepted);
    }
  }, [participantInOffer, participant, isOfferActive]);

  useEffect(() => {
    setTotalPriceAccepted(
      totalUnitsAccepted * actualTenderOffer?.totalPriceByShare
    );
    setTotalExercisePrice(
      totalUnitsAccepted * (participant?.planData?.sharePrice || 0)
    );
    setTotalSharesAccepted(participant?.shares ? totalUnitsAccepted : 0);
    setTotalPhantomsAccepted(participant?.phantoms ? totalUnitsAccepted : 0);
    setTotalStocksAccepted(participant?.stocks ? totalUnitsAccepted : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTenderOffer?.totalPriceByShare, planUnitPrice, totalUnitsAccepted]);

  return (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <div className="nk-block-head-sub">
                <Link className="back-to" to={getBackLink()}>
                  <em className="icon ni ni-arrow-left" />
                  <span>{t('MyCard')}</span>
                </Link>
              </div>

              <div className="nk-block-between-md g-4">
                <div className="nk-block-head-content">
                  <h2 className="nk-block-title fw-normal">
                    {actualTenderOffer?.name}
                  </h2>
                  {actualTenderOffer?.startDate && (
                    <div className="nk-block-des">
                      <p>
                        {t('StartDate')}:{' '}
                        {format(
                          new Date(actualTenderOffer?.startDate),
                          dateFormat
                        )}
                        &nbsp;
                        <span
                          className={`badge badge-pill ml-1 ${
                            tenderStatusTypes[actualTenderOffer?.status]?.style
                          }`}
                        >
                          {t(
                            `TenderOffer${
                              tenderStatusTypes[actualTenderOffer?.status]
                                ?.label
                            }`
                          )}
                        </span>
                      </p>
                    </div>
                  )}
                </div>

                <div className="nk-block-head-content">
                  <ul className="nk-block-tools gx-3">
                    <li>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleRejectOffer}
                        disabled={!isOfferActive || isReadOnly}
                      >
                        <em className="icon ni ni-cross mr-2" />
                        {t('RejectTenderOffer')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block">
            <div className="card card-bordered sp-plan">
              <div className="row no-gutters">
                <div className="col-md-12">
                  <div className="sp-plan-info card-inner">
                    <div className="row gx-0 gy-3">
                      <div className="col-xl-9 col-sm-8">
                        <div className="sp-plan-name">
                          <h6 className="title text-primary mb-3">
                            {t('YouCurrentlyHave')}{' '}
                          </h6>
                          <p>
                            {isPartner && t('PartnerName')}
                            {isBeneficiary && t('BeneficiaryName')}:&nbsp;
                            <span className="text-base">
                              {participant?.name}
                            </span>
                          </p>
                          <p>
                            {t('DateTenderOfferConditions')}:{' '}
                            <span className="text-base">
                              <b>
                                {actualTenderOffer?.calculationDate
                                  ? format(
                                      new Date(
                                        actualTenderOffer?.calculationDate
                                      ),
                                      dateFormat
                                    )
                                  : ''}
                                {!actualTenderOffer?.calculationDate &&
                                actualTenderOffer?.startDate
                                  ? format(
                                      new Date(actualTenderOffer?.startDate),
                                      dateFormat
                                    )
                                  : ''}
                              </b>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-4">
                        <div className="sp-plan-opt">
                          <div className="custom-control custom-switch" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sp-plan-desc card-inner">
                    <ul className="row gx-1">
                      <li className="col-4">
                        <p>
                          <span className="text-soft">{t('Shares')}</span>{' '}
                          {formatNumber(participant?.originalShares)}
                        </p>
                      </li>
                      <li className="col-4">
                        <p>
                          <span className="text-soft">{t('Phantoms')}</span>{' '}
                          {formatNumber(participant?.originalPhantoms)}
                        </p>
                      </li>
                      <li className="col-4">
                        <p>
                          <span className="text-soft">{t('StockOptions')}</span>
                          {formatNumber(participant?.originalStocks)}
                        </p>
                      </li>
                    </ul>
                    <ul className="row gx-1">
                      <li className="col-4">
                        <p>
                          <span className="text-soft">
                            {t('AcquisitionPrice')}
                          </span>{' '}
                          {formatCurrencyDecimals(
                            participant?.acquisitionPrice
                          ) || '0€'}
                        </p>
                      </li>
                      <li className="col-4">
                        <p>
                          <span className="text-soft">
                            {t('StrikePricePhantoms')}
                          </span>{' '}
                          {formatCurrencyDecimals(
                            participant?.originalPhantoms * planUnitPrice
                          )}
                        </p>
                      </li>
                      <li className="col-4">
                        <p>
                          <span className="text-soft">
                            {t('StrikePriceStocks')}
                          </span>
                          {formatCurrencyDecimals(
                            participant?.originalStocks * planUnitPrice
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="card card-bordered sp-plan">
              <div className="row no-gutters">
                <div className="col-md-8">
                  <div className="sp-plan-info card-inner">
                    <div className="row gx-0 gy-3">
                      <div className="col-12">
                        <div className="sp-plan-name">
                          <h6 className="title text-primary mb-3">
                            {t('YouCanSell')}{' '}
                          </h6>
                          <p>
                            {t('Conditions')}:{' '}
                            <span className="text-base">
                              {t('ConditionsAllowSell')}{' '}
                              <b>
                                {participant?.stocks
                                  ? `${formatNumber(
                                      participant?.stocks
                                    )} Stock Options`
                                  : ''}
                                {participant?.phantoms
                                  ? `${formatNumber(
                                      participant?.phantoms
                                    )} Phantoms Shares`
                                  : ''}
                              </b>
                              .
                            </span>
                          </p>
                          <p>
                            {t('DateTenderOfferConditions')}:{' '}
                            <span className="text-base">
                              <b>
                                {actualTenderOffer?.calculationDate
                                  ? format(
                                      new Date(
                                        actualTenderOffer?.calculationDate
                                      ),
                                      dateFormat
                                    )
                                  : ''}
                                {!actualTenderOffer?.calculationDate &&
                                actualTenderOffer?.startDate
                                  ? format(
                                      new Date(actualTenderOffer?.startDate),
                                      dateFormat
                                    )
                                  : ''}
                              </b>
                              .
                            </span>
                          </p>
                          <p>
                            {t('SellingPricePerUnit')}:{' '}
                            <span className="text-base">
                              <b>
                                {formatNumberDecimalsLong(
                                  actualTenderOffer?.totalPriceByShare
                                )}
                                €
                              </b>
                              .
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="col-xl-3 col-sm-4">
                        <div className="sp-plan-opt" />
                      </div>
                    </div>
                  </div>
                  <div className="sp-plan-desc card-inner">
                    <ul className="row gx-1">
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">{t('NoMaxShares')}</span>{' '}
                          {formatNumber(actualTenderOffer?.maxUnits) || 0}
                        </p>
                      </li>
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">{t('NoMaxPhantom')}</span>{' '}
                          {formatNumber(actualTenderOffer?.maxPhantoms) || 0}
                        </p>
                      </li>
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">
                            {t('NoMaxStockOptions')}
                          </span>{' '}
                          {formatNumber(actualTenderOffer?.maxStocks) || 0}
                        </p>
                      </li>
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">
                            {t('MaxAmountBySHBenef')}
                          </span>{' '}
                          {formatCurrencyDecimals(
                            actualTenderOffer?.maxAmount
                          ) || 0}
                        </p>
                      </li>
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">
                            {t('MaxPercentShares')}
                          </span>{' '}
                          {formatNumber(actualTenderOffer?.maxPercentUnits) ||
                            0}
                          %
                        </p>
                      </li>
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">
                            {t('MaxPercentPhantom')}
                          </span>{' '}
                          {formatNumber(
                            actualTenderOffer?.maxPercentPhantoms
                          ) || 0}
                          %
                        </p>
                      </li>
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">
                            {t('MaxPercentStockOptions')}
                          </span>{' '}
                          {formatNumber(actualTenderOffer?.maxPercentStocks) ||
                            0}
                          %
                        </p>
                      </li>
                      <li className="col-sm-3">
                        <p>
                          <span className="text-soft">
                            {t('MaxUnitVestedPercent')}
                          </span>{' '}
                          {formatNumber(
                            actualTenderOffer?.maxPercentUnvested
                          ) || 0}
                          %
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="sp-plan-action card-inner">
                    <span className="sp-package-price border-left-0 p-0">
                      <span className="sp-package-amount yearly">
                        <span className="amount">
                          {formatCurrencyDecimals(totalPriceAccepted)}&nbsp;
                        </span>
                        <span className="text-soft w-100 border-bottom pb-1">
                          {t('Shar.')}: {formatNumber(totalSharesAccepted || 0)}{' '}
                          |{t('Phantoms')}:&nbsp;
                          {formatNumber(totalPhantomsAccepted || 0)} |
                          {t('Stocks')}:&nbsp;
                          {formatNumber(totalStocksAccepted || 0)}
                        </span>
                        <span className="text-soft">
                          {t('Difference')}:&nbsp;
                          <span className="badge badge-light badge-pill">
                            {formatCurrencyDecimals(
                              totalPriceAccepted - totalExercisePrice
                            ) || 0}
                            &nbsp;
                          </span>
                          *
                        </span>
                      </span>
                    </span>

                    <div className="sp-plan-btn mt-2">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSelectQuantity}
                        disabled={!isOfferActive || isReadOnly}
                      >
                        <span>{t('ChangeAmount')}</span>
                      </button>
                    </div>

                    <div className="sp-plan-note text-md-center">
                      <p>{t('QuantityToSell')}</p>
                    </div>
                    <span
                      className="text-soft fs-11px position-absolute"
                      style={{ bottom: 5 }}
                    >
                      {t('TransactionWithholdingTax')}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card-bordered sp-plan">
              <div className="row no-gutters">
                <div className="col-12">
                  <div className="sp-plan-info card-inner">
                    <div className="row gx-0 gy-3">
                      <div className="col-12">
                        <div className="sp-plan-name">
                          <h6 className="title text-primary mb-3">
                            {t('MethodOfPaymentComments')}
                          </h6>
                          <p>
                            {t('MethodOfPayment')}:&nbsp;
                            <span className="text-base">
                              {actualTenderOffer?.paymentConditions}
                            </span>
                          </p>
                          <p>
                            {t('Comments')}:&nbsp;
                            <span className="text-base">
                              {actualTenderOffer?.details}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div
              className="invoice-wrap pt-0"
              ref={printRef}
              id="margin-print-only"
            >
              <>
                <ul className="d-flex justify-content-end mt-2 screen-only">
                  <li className="">
                    <ReactToPrint
                      trigger={() => (
                        <span className="btn btn-icon btn-trigger btn-tooltip screen-only">
                          <em className="icon ni ni-printer" />
                        </span>
                      )}
                      content={() => printRef.current}
                      documentTitle={`${t('Invoice')} - ${
                        actualTenderOffer?.name
                      } - ${format(new Date(), 'dd-MM-yyyy')}`}
                    />
                  </li>
                </ul>
                <PrintHeader
                  title={t('PrintHeaderTenderOffer')}
                  subTitle={actualTenderOffer?.name}
                />
                <div className="invoice-head">
                  <div className="invoice-contact col-4">
                    <span className="overline-title">{t('Seller')}</span>
                    <div className="invoice-contact-info">
                      <h5 className="title">{participant.name}</h5>
                      <ul className="list-plain d-flex flex-column">
                        <li className="d-inline-flex">
                          <em className="icon ni ni-wallet" />
                          <span>{participant.cif}</span>
                        </li>
                        <li className="d-inline-flex">
                          <em className="icon ni ni-map-pin-fill" />
                          <span>{participant.address}</span>
                        </li>
                        <li className="d-inline-flex">
                          <em className="icon ni ni-mail" />
                          <span>{participant.email}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-contact col-4">
                    <span className="overline-title">{t('Buyer')}</span>
                    <h5 className="title">{actualSociety?.name}</h5>
                    <ul className="list-plain d-flex flex-column">
                      <li className="d-inline-flex">
                        <em className="icon ni ni-wallet" />
                        <span>{actualSociety?.cif}</span>
                      </li>
                      <li className="d-inline-flex">
                        <em className="icon ni ni-map-pin-fill" />
                        <span>
                          {getFullAddress(actualSociety?.legalAddress)}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-head mt-2">
                  <div className="invoice-contact col-4">
                    <span className="overline-title">{t('OperationDate')}</span>
                    <div className="invoice-contact-info mt-2">
                      <ul className="list-plain d-flex flex-column">
                        <li className="d-inline-flex">
                          <em className="icon ni ni-calendar" />
                          <span>
                            {participantInOffer?.operationDate &&
                            isValid(participantInOffer.operationDate)
                              ? format(
                                  new Date(participantInOffer.operationDate),
                                  dateFormat
                                )
                              : '-'}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="invoice-contact col-8">
                    <span className="overline-title">{t('Company')}</span>
                    <div className="invoice-contact-info mt-2">
                      <ul className="list-plain d-flex flex-column">
                        <li className="d-inline-flex">
                          <em className="icon ni ni-building" />
                          <span>{actualSociety?.name || '-'}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="invoice-bills">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="text-center" style={{ width: '5%' }}>
                            Nº
                          </th>
                          <th className="text-left" style={{ width: '35%' }}>
                            {isPartner ? t('Class') : t('Plan')}
                          </th>
                          <th className="text-right" style={{ width: '10%' }}>
                            {isPartner ? t('Nº Part.') : t('NoOfUnits')}
                          </th>
                          <th className="text-right" style={{ width: '15%' }}>
                            {isPartner
                              ? 'Precio Adquis. Part.'
                              : t('StrikePriceUnit')}
                          </th>
                          <th className="text-right" style={{ width: '15%' }}>
                            {isPartner
                              ? 'Precio Venta Part.'
                              : t('SellingPriceUnit')}
                          </th>
                          <th className="text-right" style={{ width: '20%' }}>
                            {t('PercentRetentionColumn')}
                          </th>
                          <th className="text-right" style={{ width: '15%' }}>
                            {t('Profit')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-center">1</td>
                          <td className="text-left">
                            {isPartner
                              ? t('Shares')
                              : getInvoiceLineDescription()}
                          </td>
                          <td className="text-right">
                            {formatNumber(totalUnitsAccepted)}
                          </td>
                          <td className="text-right">
                            {formatCurrencyDecimalsLong(
                              participant?.planData?.sharePrice
                            )}
                          </td>
                          <td className="text-right">
                            {formatCurrencyDecimalsLong(
                              actualTenderOffer?.totalPriceByShare
                            )}
                          </td>
                          <td className="text-right">
                            {formatNumberDecimalsLong(
                              participant?.retentionPercent
                            )}
                            %
                          </td>
                          <td className="text-right">
                            {formatCurrencyDecimals(
                              totalPriceAccepted -
                                totalExercisePrice -
                                ((participant?.retentionPercent || 0) *
                                  (totalPriceAccepted - totalExercisePrice)) /
                                  100
                            )}
                            <sup>&nbsp;</sup>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan="3" />
                          <td className="text-right" colSpan="2">
                            {t('Subtotal')}
                          </td>
                          <td />

                          <td className="text-right">
                            {formatCurrencyDecimals(
                              totalPriceAccepted -
                                totalExercisePrice -
                                ((participant?.retentionPercent || 0) *
                                  (totalPriceAccepted - totalExercisePrice)) /
                                  100
                            )}
                            <sup>&nbsp;</sup>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="3" />
                          <td className="text-right" colSpan="2">
                            {t('Total')}
                          </td>
                          <td />

                          <td className="text-right">
                            {formatCurrencyDecimals(
                              totalPriceAccepted -
                                totalExercisePrice -
                                (participant?.retentionPercent *
                                  (totalPriceAccepted - totalExercisePrice)) /
                                  100
                            )}
                            <sup>*</sup>
                            <span
                              className="text-right text-soft fs-11px mt-4 d-block"
                              colSpan="3"
                            >
                              {t('AmountWithholdingTax')}
                            </span>
                          </td>
                        </tr>
                      </tfoot>
                    </table>

                    {isOfferActive && (
                      <div className="d-flex flex-column mt-4">
                        <button
                          type="button"
                          className="btn btn-success mx-auto text-secondary"
                          onClick={handleOpenConfirmationModal}
                          disabled={isReadOnly}
                        >
                          <em className="icon ni ni-check" />
                          <span>{t('AcceptTenderOffer')}</span>
                        </button>
                        <div className="nk-notes ff-italic fs-12px text-soft mt-2 text-center">
                          {' '}
                          {t('AcceptTenderOfferWarning1')}
                          <br />
                          {t('AcceptTenderOfferWarning2')}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default TenderOfferParticipation;
