import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

type Option = {
  label: string;
  value: string;
};

type Props = {
  value: Option;
  options: Option[];
  onChange: any;
};

const PersonalDataViewCategory: FC<Props> = ({ value, options, onChange }) => {
  const { t } = useTranslate();

  const animatedComponents = makeAnimated();

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label" htmlFor="partner-category">
          {t('Category')}
        </label>

        <div id="partner-category" className="form-control-wrap">
          <Select
            closeMenuOnSelect
            className="react-select react-select-lg"
            value={value}
            options={options}
            components={animatedComponents}
            onChange={onChange}
            placeholder={t('SelectCategory')}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDataViewCategory;
