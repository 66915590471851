import { formatNumberDecimals } from 'utils/formats';

const parseTenderOfferSummary = ({
  participant,
  beneficiary,
  totalUnitsAccepted,
  totalPriceAccepted,
  totalExercisePrice,
}) => {
  const planName = participant?.planData?.name;
  const planTotalUnits = beneficiary?.sharesCount?.future;
  const planGrantDate = beneficiary?.planStartDate;
  const vestedPhantoms =
    participant.planType === 'STOCK_OPTION'
      ? participant?.originalStocks
      : participant?.originalPhantoms;
  const unVestedPhantoms = planTotalUnits - vestedPhantoms;
  const planStrikePrice = participant?.planData?.sharePrice;
  const soldUnits = totalUnitsAccepted;
  const remainingVestedPhantoms = vestedPhantoms - soldUnits;
  const totalStrikePrice = formatNumberDecimals(
    totalUnitsAccepted * planStrikePrice
  );
  const totalPrice = formatNumberDecimals(
    totalPriceAccepted - totalExercisePrice
  );

  return {
    planName,
    planTotalUnits,
    planGrantDate,
    vestedPhantoms,
    unVestedPhantoms,
    planStrikePrice,
    soldUnits,
    remainingVestedPhantoms,
    totalStrikePrice,
    totalPrice,
  };
};

export default parseTenderOfferSummary;
