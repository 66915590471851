import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

import useGetValidPartners from '../hooks/useGetValidPartners';

type Props = {
  partners: (Partner & { isExisting?: boolean })[];
  onClose: () => void;
};

const PartnersPreviewHeader: FC<Props> = ({ partners, onClose }) => {
  const { t } = useTranslate();

  const { validPartners } = useGetValidPartners({ partners }).data;

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-flex justify-content-between">
        <div className="d-flex align-items-baseline">
          <h5 className="modal-title">
            {`${partners?.length || 0} ${t('Partners')}`}
          </h5>
          <span className="sub-text ml-2">
            (
            {t('OnlyWillBeUploaded', {
              partners: validPartners?.length || 0,
            })}
            )
          </span>
        </div>

        <a className="close cursor-pointer" onClick={handleClose}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
    </>
  );
};

export default PartnersPreviewHeader;
