import axios from 'axios';
import updateHeaderInterceptor from './updateHeader';

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

updateHeaderInterceptor(httpClient);

export default httpClient;
