const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

// Return {day, month, year}
function getDayMonthYear(date) {
  const formatedDate = new Date(date);
  const year = formatedDate.getFullYear();
  const month = formatedDate.getMonth();
  const day = formatedDate.getDate();
  return { day, month, year };
}
// 2 => 02      14 => 14
const displayNumber = (number) =>
  `${number}`.length === 2 ? number : `0${number}`;

// DAY de MONTH de YEAR
export function dayMonthYear(date) {
  let result;
  if (date) {
    const formatedDate = getDayMonthYear(date);
    const month = months[formatedDate.month];
    result = `${formatedDate.day} de ${month} de ${formatedDate.year}`;
  } else {
    result = '';
  }

  return result;
}

// YEAR-MONTH-DAY
export function yearNmonthDay(date) {
  const formatedDate = getDayMonthYear(date);
  return `${formatedDate.year}-${displayNumber(
    formatedDate.month + 1
  )}-${displayNumber(formatedDate.day)}`;
}

// MONTH DAY, YEAR
// (3 chars)
export function monDayYear(date) {
  if (!date) return;
  const formatedDate = getDayMonthYear(date);
  const month = months[formatedDate.month].slice(0, 3);
  return `${month} ${formatedDate.day}, ${formatedDate.year}`;
}

// DAY/MONTH/YEAR
export function dayMonYea(date) {
  const formatDate = getDayMonthYear(date);
  const month =
    formatDate.month + 1 < 10
      ? `0${formatDate.month + 1}`
      : formatDate.month + 1;
  return `${formatDate.day}/${month}/${formatDate.year}`;
}

/**
 * @param {number} date as 44635
 * @return {object} formatted date as "Tue Mar 15 2022 01:00:00 GMT+0100 (hora estándar de Europa central)"
 */
export function excelDateToJSDate(date) {
  return new Date(Math.round((date - 25569) * 86400 * 1000));
}
