import actionTypes from 'redux/actions/actionTypes';
import initialState from 'redux/initialState';

export default function reducer(fullWidth = initialState.fullWidth, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_FULL_WIDTH:
      return !fullWidth;

    case actionTypes.SET_FULL_WIDTH:
      return action.fullWidth;

    default:
      return fullWidth;
  }
}
