import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import { addDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';
import { Document, Society, User } from 'types';

type UploadPartnerRepresentantDocument = {
  file: File;
  t: any;
  user: User;
  actualSociety: Society;
};

export const uploadPartnerRepresentantDocument = async ({
  file,
  t,
  user,
  actualSociety,
}: UploadPartnerRepresentantDocument): Promise<Document> => {
  const newDocument = {
    file,
    date: new Date(),
    size: file.size,
    name: file.name,
    author: user['_id'],
    society: actualSociety['_id'],
    fileType: fileTypes[file.type as keyof typeof fileTypes],
    category: documentTypes.DOCUMENTS,
    description: t('DocumentLinkedToPartnerRepresentant'),
  };

  const uploadedDocument: Document = await store.dispatch(
    addDocument(newDocument)
  );

  return uploadedDocument;
};
