/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import WidgetTableExtendedCell, {
  cellFormats,
} from '../WidgetTableExtendedCell';
import { isMobileScreen } from '../../../../utils/getScreenSize';

const WidgetTableExtended = ({
  title,
  linkText,
  linkUrl,
  columns,
  data,
  maxRows = 10,
  isReadOnly = false,
  isFullWidth = false,
}) => {
  const containerClass = isFullWidth ? 'col-12' : 'col-12 col-xl-6';

  return (
    <div className={`${containerClass} mb-4`}>
      <div className="card card-bordered h-100">
        <div className="card-inner mb-n2">
          <div className="card-title-group">
            <div className="card-title card-title-sm">
              <h6 className="title">{title}</h6>
            </div>
            <div className="card-tools">
              {!isReadOnly && (
                <Link to={linkUrl} className="link">
                  {linkText}
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="nk-tb-list is-loose">
          <div className="nk-tb-item nk-tb-head">
            {columns?.map((column, index) => (
              <div
                className={`nk-tb-col ${index > 0 ? 'text-center' : ''}`}
                key={column}
              >
                <span>{column}</span>
              </div>
            ))}
          </div>

          {data?.slice(0, maxRows)?.map((item) => (
            <div className="nk-tb-item" key={uuidv4()}>
              <div className="nk-tb-col text-ellipsis">
                <div className="icon-text">
                  <em className={`text-primary mr-1 icon ni ${item?.icon}`} />
                  {item.link && !isReadOnly ? (
                    <Link to={item.link} className="link">
                      <span
                        className="tb-lead text-ellipsis"
                        style={{
                          width: '100px',
                        }}
                      >
                        {item?.name}
                      </span>
                    </Link>
                  ) : (
                    <span
                      className="tb-lead text-ellipsis"
                      style={{
                        width: isMobileScreen() ? '120px' : '200px',
                      }}
                    >
                      {item?.name}
                    </span>
                  )}
                </div>
              </div>

              <WidgetTableExtendedCell
                value={item?.value1}
                format={cellFormats.NUMBER_FORMAT}
                cellClass="text-end"
                textClass="tb-amount"
              />

              {item?.value2 !== undefined && (
                <WidgetTableExtendedCell
                  value={item?.value2}
                  format={cellFormats.NUMBER_FORMAT_DECIMALS}
                />
              )}

              {item?.value3 !== undefined && (
                <WidgetTableExtendedCell
                  value={item?.value3}
                  format={cellFormats.NUMBER_FORMAT_DECIMALS}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WidgetTableExtended;
