import { useState } from 'react';
import i18n from 'i18n/config';

import List from 'modules/documents/components/List';
import TemplateHeader from 'modules/documents/components/Header/TemplateHeader';
import TemplateCreator from 'modules/documents/components/Creators/TemplateCreator';
import documentSections from 'constants/documentSections';

const currentSection = 'TEMPLATES';

const Templates = ({
  documents,
  setOpenTemplateCreator,
  openTemplateCreator,
  isLoadingData,
  searchTerm,
  fullScreen,
  handleToggleFullScreen,
}) => {
  const [documentSelected, setDocumentSelected] = useState(null);
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );

  return (
    <>
      {!openTemplateCreator ? (
        <>
          <div className="nk-fmg-body-content">
            <TemplateHeader
              openTemplateCreator={openTemplateCreator}
              setOpenTemplateCreator={setOpenTemplateCreator}
              setDocumentSelected={setDocumentSelected}
            />
          </div>
          <List
            section={section}
            documents={documents}
            setDocumentSelected={setDocumentSelected}
            setOpenTemplateCreator={setOpenTemplateCreator}
            isLoadingData={isLoadingData}
            searchTerm={searchTerm}
          />
        </>
      ) : (
        <TemplateCreator
          document={documentSelected}
          fullScreen={fullScreen}
          handleToggleFullScreen={handleToggleFullScreen}
        />
      )}
    </>
  );
};

export default Templates;
