import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

type Props = {
  cancelAllPans: boolean;
  handleChangeAllPlansCancelation: (event: any) => void;
};

const CancelBeneficiaryAllPlansSection: FC<Props> = ({
  cancelAllPans,
  handleChangeAllPlansCancelation,
}) => {
  const { t } = useTranslate();

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="default-01">
        {t('AllPlansCancelationMessage1')}
      </label>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mt-2">
            <div className="custom-control custom-control-xs custom-checkbox">
              <input
                type="checkbox"
                id="checkbox-access-captable"
                className="custom-control-input"
                checked={cancelAllPans}
                onChange={handleChangeAllPlansCancelation}
              />
              <label
                className="custom-control-label"
                htmlFor="checkbox-access-captable"
              >
                {t('AllPlansCancelationMessage2')}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelBeneficiaryAllPlansSection;
