import { Board, Society, User } from 'types';
import { NewBoardData } from './saveBoard';

type ParseExistingToNewBoardProps = {
  newBoardName: string;
  newBoardDate: string;
  existingBoard: Board;
  actualSociety: Society;
  user: User;
};

export const parseExistingToNewBoard = ({
  newBoardName,
  newBoardDate,
  existingBoard,
  actualSociety,
  user,
}: ParseExistingToNewBoardProps): NewBoardData => {
  const {
    attendanceType,
    place,
    announcement,
    onlineAccess,
    votePeriod,
    boardType,
    digitalVoting,
    orders,
    president,
    secretary,
    delegationType,
    announcementTemplate,
    actTemplate,
    actCertificateTemplate,
  } = existingBoard;

  const newBoard = {
    name: newBoardName,
    date: new Date(newBoardDate),
    user,
    files: [],
    society: actualSociety,
    attendanceType,
    place,
    announcement,
    onlineAccess,
    votePeriod,
    boardType,
    digitalVoting,
    orders,
    president,
    secretary,
    delegationType,
    announcementTemplate,
    actTemplate,
    actCertificateTemplate,
    selectedDelegation: 2,
    selectedPartners: [],
    selectedRepresentants: [],
  };

  return newBoard;
};
