import screenSizes from 'constants/screenSizes';

const getScreenSize = (): number => {
  const width = window.innerWidth;

  if (width < 576) {
    return screenSizes.XS;
  }
  if (width < 768) {
    return screenSizes.SM;
  }
  if (width < 992) {
    return screenSizes.MD;
  }
  if (width < 1200) {
    return screenSizes.LG;
  }
  if (width < 1400) {
    return screenSizes.XL;
  }

  return screenSizes.XXL;
};

const isMobileScreen = (): boolean => getScreenSize() === screenSizes.XS;

const isTabletScreen = (): boolean => getScreenSize() === screenSizes.SM;

const isDesktopScreen = (): boolean => getScreenSize() === screenSizes.MD;

const isLargeScreen = (): boolean => getScreenSize() === screenSizes.LG;

const isExtraLargeScreen = (): boolean => getScreenSize() === screenSizes.XL;

const isXXLargeScreen = (): boolean => getScreenSize() === screenSizes.XXL;

export {
  getScreenSize,
  isMobileScreen,
  isTabletScreen,
  isDesktopScreen,
  isLargeScreen,
  isExtraLargeScreen,
  isXXLargeScreen,
};
