/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { ReactElement, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { updateSociety } from 'modules/society/redux/societyActions';

import alertTypes from 'constants/alertTypes';
import invitationStatus from 'constants/invitationStatus';

import { Beneficiary, Society, StockPlan } from 'types';

import CalendarTab from './CalendarTab';
import DetailsTab from './DetailsTab';

import '../Modals.scss';

const TABS_PAGES: Record<string, number> = {
  DETAILS: 0,
  CALENDAR: 1,
};

type SocietyUpdatedProps = Society & {
  societyId: string;
};

type Props = {
  beneficiary: Beneficiary;
  plan: StockPlan;
  society: SocietyUpdatedProps;
  tab?: string;
};

function BeneficiaryInvitation({
  beneficiary,
  plan,
  society,
  tab = 'DETAILS',
}: Props) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [page, setPage] = useState<number>(TABS_PAGES[tab]);
  const [pages, setPages] = useState<ReactElement[]>([]);
  const [hasAccepted, setHasAccepted] = useState<boolean>(false);
  const [isPlanWithSignature] = useState(beneficiary?.planData?.hasInvitation);
  const [isPlanAccepted] = useState(
    beneficiary?.invitationStatus === invitationStatus.ACCEPTED.value
  );

  const detailsTab = (
    <DetailsTab
      plan={plan}
      society={society}
      beneficiary={beneficiary}
      key="details"
    />
  );

  const calendarTab = (
    <CalendarTab plan={plan} beneficiary={beneficiary} key="calendar" />
  );

  const handleAcceptPlan = async () => {
    try {
      const updateData = {
        beneficiary: {
          id: beneficiary?.['_id'],
          updateData: {
            invitationStatus: invitationStatus.ACCEPTED.value,
          },
        },
      };

      await dispatch(
        updateSociety(society?.societyId, { ...updateData }, false)
      );

      dispatch(
        addAlert({
          type: alertTypes.SUCCESS,
          title: `<h4 class="nk-modal-title">${t(
            'PlanInvitationAcceptedTitle'
          )} 🎉</h4>`,
          html: `<div class="caption-text">${t(
            'PlanInvitationAcceptedMessage',
            { planName: plan?.name, societyName: society?.name }
          )}</div>`,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setModal(null));
    }
  };

  useEffect(() => {
    const tabs: ReactElement[] = [detailsTab, calendarTab];

    setPages(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Header className="border-0 pb-0">
        <h5 className="modal-title">
          {t('PlanInvitation')} &quot;{plan?.name}&quot;
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <ul className="nav nav-tabs nav-tabs-card p-0 mb-4">
              <li className="nav-item">
                <p
                  className={`nav-link pt-0 ${page === 0 ? 'active' : ''}`}
                  onClick={() => setPage(0)}
                >
                  <span>{t('PlanDetail')}</span>
                </p>
              </li>
              <li className="nav-item">
                <p
                  className={`nav-link pt-0 ${page === 1 ? 'active' : ''}`}
                  onClick={() => setPage(1)}
                >
                  <span>{t('ConsolidationCalendar')}</span>
                </p>
              </li>
              {plan?.invitationModel?.blocks && (
                <li className="nav-item">
                  <p
                    className={`nav-link pt-0 ${page === 2 ? 'active' : ''}`}
                    onClick={() => setPage(2)}
                  >
                    <span>{t('PlanConditionsDetails')}</span>
                  </p>
                </li>
              )}
            </ul>
            {pages[page]}
          </div>
        </div>

        {isPlanWithSignature && !isPlanAccepted && (
          <div className="alert alert-icon alert-warning text-justify">
            <em className="icon ni ni-info notification-icon" />
            {t('YouHavePendingPlansMessage')}
          </div>
        )}

        {!isPlanWithSignature && !isPlanAccepted && (
          <>
            <div className="custom-control custom-control-xs custom-checkbox my-3">
              <input
                type="checkbox"
                id="checkbox-privacy"
                className="custom-control-input"
                checked={hasAccepted}
                onChange={(event) => setHasAccepted(event.target.checked)}
                required
              />
              <label
                className="custom-control-label"
                htmlFor="checkbox-privacy"
              >
                {t('IFormallyAcceptTheConditi')}
              </label>
            </div>

            <button
              type="button"
              className="btn btn-success"
              disabled={!hasAccepted}
              onClick={handleAcceptPlan}
            >
              {t('AcceptInvitation')}
            </button>
          </>
        )}
      </Modal.Body>
    </>
  );
}

export default BeneficiaryInvitation;
