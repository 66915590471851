import { CommitteeMember } from 'types';
import memberStatus from '../constants/memberStatus';

export const getMemberStatusLabel = (member: CommitteeMember) => {
  const status = memberStatus.find(
    (status) => status.value === member?.memberStatus
  );
  const statusLabel = status ? status.label : '-';

  return statusLabel;
};
