/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { generateColorScaleSVG } from 'modules/dashboard/utils/generateColorScaleSVG';

export type WidgetLegalTableData = {
  icon: string;
  name: string;
  value1: string | number;
  value2?: string | number;
  value3?: string | number;
  link?: string;
};

type WidgetCardProps = {
  title: string;
  linkText?: string;
  linkUrl?: string;
  data: string | number | Record<string, string | number>[];
  isReadOnly?: boolean;
  isFullWidth?: boolean;
};

const WidgetCard: FC<WidgetCardProps> = ({
  title,
  linkText,
  linkUrl,
  data,
  isReadOnly = false,
  isFullWidth = false,
}) => {
  const headerTextHoverColor = useSelector(
    (state: State) =>
      state.society?.actualSociety?.customization?.theme?.headerTextHoverColor
  );

  const containerClass = isFullWidth ? 'col-12' : 'col-12 col-xl-6';
  const waveClass = {
    backgroundImage: `url("${generateColorScaleSVG(headerTextHoverColor)}")`,
  };

  const isNumber = data && typeof data === 'number';
  const isString = data && typeof data === 'string';
  const isArray = data && Array.isArray(data);

  return (
    <div className={`${containerClass} mb-4`}>
      <div className="card card-bordered h-100">
        <div className="card-inner mb-n2 bg-wave" style={waveClass}>
          <div className="card-title-group">
            <div className="card-title card-title-sm">
              <h6 className="title">{title}</h6>
            </div>
            <div className="card-tools">
              {!isReadOnly && linkText && linkUrl && (
                <Link to={linkUrl} className="link">
                  {linkText}
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className="card-inner bg-white">
          {isNumber && (
            <div className="nk-tb-list is-compact">
              <div className="nk-tb-item">
                <div className="nk-tb-col">
                  <span className="tb-lead">{data}</span>
                </div>
              </div>
            </div>
          )}

          {isString && (
            <div className="nk-tb-list is-compact">
              <div className="nk-tb-item">
                <div className="nk-tb-col">
                  <span className="tb-lead">{data}</span>
                </div>
              </div>
            </div>
          )}

          {isArray && (
            <div className="nk-tb-list is-compact">
              {data.map(
                (item: Record<string, string | number>, index: number) => (
                  <div className="nk-tb-item" key={index}>
                    <div className="nk-tb-col">
                      <span className="tb-lead">{item.key}</span>
                    </div>
                    <div className="nk-tb-col">
                      <span className="tb-sub">{item.value}</span>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WidgetCard;
