import isValidEmail from 'utils/isValidEmail';
import normalizeEmail from 'utils/normalizeEmail';

const getInvalidEmails = (beneficiariesData: any): any => {
  const result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const data of beneficiariesData) {
    const email = data['EMAIL'] || '-';
    const normalizedEmail = normalizeEmail(email);

    const validEmial = isValidEmail(normalizedEmail);
    if (!validEmial) {
      result.push(normalizedEmail);
    }
  }
  return result;
};

export default getInvalidEmails;
