/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import wNumb from 'wnumb';
import { format } from 'date-fns';
import Nouislider from 'nouislider-react';
import { Tooltip } from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { useTranslate } from 'hooks/useTranslate';

import {
  getDecimalScale,
  getPartnerDividends,
  getPartnerFDPercent,
  getPartnerInversion,
  getPartnerNDPercent,
  getPartnerOperations,
  getTransactionsByPartner,
} from 'utils/filters';
import handleInputNumberFormat from 'utils/handleInputNumberFormat';
import { formatCurrencyDecimals, formatNumberDecimals } from 'utils/formats';

import { cleanValue, numberFormat } from 'constants/formats';
import { getTransactionHoldingClass } from 'modules/transactions/utils/getTransactionHoldingClass';
import { getOperationNominalValue } from 'modules/transactions/utils/getOperationNominalValue';

import './PartnerInvestment.scss';

const InvesmentTabs = {
  ALL: 'ALL',
  INCREASE: 'INCREASE',
  BUY: 'BUY',
};

const ReturnmentTabs = {
  ALL: 'ALL',
  DIVIDENDS: 'DIVIDENDS',
  SELL: 'SELL',
};

function PartnerInvestment({ society, partner, setPage, holdingClasses }) {
  const { t } = useTranslate();
  const { societyValue } = partner;

  const [increaseTransactions, setIncreaseTransactions] = useState([]);
  const [buyTransactions, setBuyTransactions] = useState([]);
  const [dividendsTransactions, setDividendsTransactions] = useState([]);
  const [sellTransactions, setSellTransactions] = useState([]);

  const [totalIncrease, setTotalIncrease] = useState(0);
  const [totalBuy, setTotalBuy] = useState(0);
  const [totalDividends, setTotalDividends] = useState(0);
  const [totalSell, setTotalSell] = useState(0);

  const [totalROI, setTotalROI] = useState(0);
  const [percentROI, setPercentROI] = useState(0);
  const [coefficientROI, setCoefficientROI] = useState(0);

  const [inversionSign, setInversionSign] = useState(''); // eslint-disable-line no-unused-vars
  const [inversion] = useState(getPartnerInversion(partner['_id'], society));

  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue?.value) : 2
  );
  const [NDpercent] = useState(
    getPartnerNDPercent(partner, society).toFixed(decimalScale)
  );
  const [FDpercent] = useState(
    getPartnerFDPercent(partner, society).toFixed(decimalScale)
  );
  const [participationsValueND] = useState(
    societyValue ? (+NDpercent * societyValue?.value) / 100 : 0
  );
  const [participationsValueFD] = useState(
    societyValue ? (+FDpercent * societyValue?.value) / 100 : 0
  );
  const sliderStep = participationsValueND
    ? +((societyValue?.value * 10) / 100).toFixed(2)
    : 0;
  const [sliderValue, setSliderValue] = useState(societyValue?.value || 0);
  const [valuationND, setValuationND] = useState(participationsValueND);
  const [valuationFD, setValuationFD] = useState(participationsValueFD);

  const [activeInvestmentTab, setActiveInvestmentTab] = useState(
    InvesmentTabs.ALL
  );
  const [activeReturnmentTab, setActiveReturnmentTab] = useState(
    ReturnmentTabs.ALL
  );

  const transactionNames = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    BUY: t('Buy'),
    SELL: t('Sale'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
  };

  const calculateAmount = (transaction) => {
    const { shareFrom, shareTo, sharePremium } = transaction;

    const totalShares = shareTo - shareFrom + 1;
    const holdingClass = getTransactionHoldingClass(
      holdingClasses,
      transaction
    );
    const nominalValue = getOperationNominalValue({
      holdingClass,
      operation: transaction,
      society,
    });
    const nominal = transaction?.sharePrice || nominalValue + sharePremium;

    return totalShares * nominal;
  };

  const getTotalsByType = (transactions) => {
    const totals = {
      CAPITAL_INCREASE: 0,
      BUY: 0,
      SELL: 0,
      DIVIDENDS: 0,
    };

    totals.CAPITAL_INCREASE = transactions.CAPITAL_INCREASE.reduce(
      (acc, transaction) => acc + calculateAmount(transaction),
      0
    );

    totals.BUY = transactions.BUY.reduce(
      (acc, transaction) => acc + calculateAmount(transaction),
      0
    );

    totals.SELL = transactions.SELL.reduce(
      (acc, transaction) => acc + calculateAmount(transaction),
      0
    );

    totals.DIVIDENDS = transactions.DIVIDENDS.reduce(
      (acc, transaction) => acc + transaction.amount,
      0
    );

    return totals;
  };

  const getTransactionsByType = (society, partner) => {
    if (!society || !partner) return [];

    const partnerTransactions = getTransactionsByPartner(
      getPartnerOperations(partner, society),
      partner
    );

    const transactionsAllowed = ['CAPITAL_INCREASE', 'BUY', 'SELL'];
    const transactionsByType = {
      CAPITAL_INCREASE: [],
      BUY: [],
      SELL: [],
      DIVIDENDS: [],
    };

    const sortedTransactions = partnerTransactions
      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
      ?.forEach((transaction) => {
        if (transactionsAllowed.includes(transaction.transactionType)) {
          transactionsByType[transaction.transactionType].push(transaction);
        }
      });

    transactionsByType.DIVIDENDS = getPartnerDividends(partner._id, society);

    setIncreaseTransactions(transactionsByType.CAPITAL_INCREASE);
    setBuyTransactions(transactionsByType.BUY);
    setDividendsTransactions(transactionsByType.DIVIDENDS);
    setSellTransactions(transactionsByType.SELL);

    const totalsByTransaction = getTotalsByType(transactionsByType);

    setTotalIncrease(totalsByTransaction.CAPITAL_INCREASE);
    setTotalBuy(totalsByTransaction.BUY);
    setTotalDividends(totalsByTransaction.DIVIDENDS);
    setTotalSell(totalsByTransaction.SELL);

    const totalIncomes =
      totalsByTransaction.SELL + totalsByTransaction.DIVIDENDS;
    const totalExpenses =
      totalsByTransaction.BUY + totalsByTransaction.CAPITAL_INCREASE;

    setTotalROI(totalIncomes - totalExpenses);

    const differenceROI = totalExpenses
      ? (((totalIncomes || 0) - (totalExpenses || 0)) * 100) / totalExpenses
      : 0;

    setPercentROI(differenceROI);
    setCoefficientROI(totalIncomes / totalExpenses);

    return sortedTransactions;
  };

  const navigateToTransactionsTab = () => {
    setPage(2);
  };

  const handleChangeSlider = (value) => {
    setSliderValue(value[0]);
    setValuationFD((+FDpercent * cleanValue(value[0])) / 100);
    setValuationND((+NDpercent * cleanValue(value[0])) / 100);
  };

  const handleChangeInputSlider = (e) => {
    setSliderValue(e.target.value);
    setValuationFD((+FDpercent * cleanValue(e.target.value)) / 100);
    setValuationND((+NDpercent * cleanValue(e.target.value)) / 100);
  };

  useEffect(() => {
    getTransactionsByType(society, partner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inversion) {
      const temp = inversion >= 0 ? '+' : '';
      setInversionSign(temp);
    }
  }, [inversion]);

  return (
    <section className="card-inner partner-investment-wrapper">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">{t('InvestmentAnalysis')}</h4>
          <div className="nk-block-des">
            <p>{t('InvestmentAnalysisInfo')}</p>
          </div>
        </div>
      </div>

      <div className="nk-block">
        <div className="row g-gs">
          <div className="col-12 col-md-4">
            <div className="card card-bordered card-full">
              <div className="card-inner">
                <div className="card-title-group align-start mb-0">
                  <div className="nk-iv-wg2-title">
                    <h6 className="title">{t('TotalInversion')}</h6>
                  </div>
                  <div className="card-tools">
                    <em
                      id="tooltip-total-investment"
                      className="card-hint icon ni ni-help-fill"
                    />
                    <Tooltip
                      anchorId="tooltip-total-investment"
                      place="top"
                      className="tooltip w-200px"
                    >
                      {t('TotalInversionInfo')}
                    </Tooltip>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {formatCurrencyDecimals(totalBuy + totalIncrease)}
                    <span className="currency currency-usd" />
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">{t('Buy')}</div>
                      <div className="amount">
                        {formatCurrencyDecimals(totalBuy)}
                        <span className="currency currency-usd" />
                      </div>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">{t('CapitalIncrease')}</div>
                      <div className="amount">
                        {formatCurrencyDecimals(totalIncrease)}
                        <span className="currency currency-usd" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="card card-bordered card-full">
              <div className="card-inner">
                <div className="card-title-group align-start mb-0">
                  <div className="nk-iv-wg2-title">
                    <h6 className="title">{t('TotalGenerated')}</h6>
                  </div>
                  <div className="card-tools">
                    <em
                      id="tooltip-total-generated"
                      className="card-hint icon ni ni-help-fill"
                    />
                    <Tooltip
                      anchorId="tooltip-total-generated"
                      place="top"
                      className="tooltip w-200px"
                    >
                      {t('TotalGeneratedInfo')}
                    </Tooltip>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {formatCurrencyDecimals(totalDividends + totalSell)}
                    <span className="currency currency-usd" />
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">{t('Sell')}</div>
                      <div className="amount">
                        {formatCurrencyDecimals(totalSell)}
                        <span className="currency currency-usd" />
                      </div>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">{t('Dividends')}</div>
                      <div className="amount">
                        {formatCurrencyDecimals(totalDividends)}
                        <span className="currency currency-usd" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="card card-bordered  card-full">
              <div className="card-inner">
                <div className="card-title-group align-start mb-0">
                  <div className="nk-iv-wg2-title">
                    <h6 className="title">{t('Result')} (ROI)</h6>
                  </div>
                  <div className="card-tools">
                    <em
                      id="tooltip-total-roi"
                      className="card-hint icon ni ni-help-fill"
                    />
                    <Tooltip
                      anchorId="tooltip-total-roi"
                      place="top"
                      className="tooltip w-200px"
                    >
                      {t('ResultInfo')}
                    </Tooltip>
                  </div>
                </div>
                <div className="card-amount">
                  <span className="amount">
                    {formatCurrencyDecimals(totalROI)}
                  </span>
                </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                      <div className="title">{t('Multiple')}</div>
                      <div className="amount">
                        {formatNumberDecimals(coefficientROI, 2)}
                        <span className="currency currency-usd" />
                      </div>
                    </div>
                    <div className="invest-data-history">
                      <div className="title">{t('Percentage')}</div>
                      <div className="amount">
                        <span
                          className={`change text-danger ${
                            percentROI >= 0 ? 'up' : 'down'
                          }`}
                        >
                          <em
                            className={`icon ni ni-arrow-long-${
                              percentROI >= 0 ? 'up' : 'down'
                            }`}
                          />
                          {formatNumberDecimals(percentROI, 2)}%
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="card card-bordered card-full card-with-table">
              <div className="card-inner">
                <div className="card-title-group mb-1">
                  <div className="card-title">
                    <h6 className="title">{t('Investment')}</h6>
                    <p>
                      {t('InvestmentSummary')}.&nbsp;
                      <span
                        className="cursor-pointer text-primary "
                        onClick={navigateToTransactionsTab}
                      >
                        {t('InvestmentDetail')}
                      </span>
                    </p>
                  </div>
                </div>

                <ul
                  className="nav nav-tabs nav-tabs-card nav-tabs-xs justify-content-between"
                  role="tablist"
                >
                  <li className="nav-item text-center pr-0" role="presentation">
                    <a
                      className={`nav-link cursor-pointer ${
                        activeInvestmentTab === InvesmentTabs.ALL
                          ? 'active'
                          : ''
                      } `}
                      data-bs-toggle="tab"
                      aria-selected={
                        activeInvestmentTab === InvesmentTabs.ALL
                          ? 'true'
                          : 'false'
                      }
                      role="tab"
                      tabIndex={0}
                      onClick={() => setActiveInvestmentTab(InvesmentTabs.ALL)}
                    >
                      {t('All')}
                    </a>
                  </li>
                  <li className="nav-item text-center pr-0" role="presentation">
                    <a
                      className={`nav-link cursor-pointer ${
                        activeInvestmentTab === InvesmentTabs.INCREASE
                          ? 'active'
                          : ''
                      } `}
                      data-bs-toggle="tab"
                      aria-selected={
                        activeInvestmentTab === InvesmentTabs.INCREASE
                          ? 'true'
                          : 'false'
                      }
                      role="tab"
                      tabIndex={-1}
                      onClick={() =>
                        setActiveInvestmentTab(InvesmentTabs.INCREASE)
                      }
                    >
                      {t('CapitalIncrease')}
                    </a>
                  </li>
                  <li className="nav-item text-center pr-0" role="presentation">
                    <a
                      className={`nav-link cursor-pointer ${
                        activeInvestmentTab === InvesmentTabs.BUY
                          ? 'active'
                          : ''
                      } `}
                      data-bs-toggle="tab"
                      aria-selected={
                        activeInvestmentTab === InvesmentTabs.BUY
                          ? 'true'
                          : 'false'
                      }
                      role="tab"
                      tabIndex={-2}
                      onClick={() => setActiveInvestmentTab(InvesmentTabs.BUY)}
                    >
                      {t('Buys')}
                    </a>
                  </li>
                </ul>

                <div className="tab-content mt-0">
                  <div
                    className={`tab-pane ${
                      activeInvestmentTab === InvesmentTabs.ALL ? 'active' : ''
                    } `}
                    id="detail-investment-all"
                    role="tabpanel"
                  >
                    <table className="table table-striped table-font-sm">
                      <tbody>
                        {[...increaseTransactions, ...buyTransactions]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((transaction) => (
                            <tr key={transaction._id}>
                              <td className="pl-0">
                                {format(
                                  new Date(transaction?.date),
                                  'dd/MM/yy'
                                )}
                              </td>
                              <td
                                className="text-primary cursor-pointer d-none d-lg-table-cell"
                                onClick={navigateToTransactionsTab}
                              >
                                {transactionNames[transaction?.transactionType]}
                              </td>
                              <td className="tb-col-end pr-0">
                                {formatCurrencyDecimals(
                                  calculateAmount(transaction)
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeInvestmentTab === InvesmentTabs.INCREASE
                        ? 'active'
                        : ''
                    } `}
                    id="detail-investment-increase"
                    role="tabpanel"
                  >
                    <table className="table table-striped table-font-sm">
                      <tbody>
                        {[...increaseTransactions]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((transaction) => (
                            <tr key={transaction._id}>
                              <td className="pl-0">
                                {format(
                                  new Date(transaction?.date),
                                  'dd/MM/yy'
                                )}
                              </td>
                              <td
                                className="text-primary cursor-pointer d-none d-lg-table-cell"
                                onClick={navigateToTransactionsTab}
                              >
                                {transactionNames[transaction?.transactionType]}
                              </td>
                              <td className="tb-col-end pr-0">
                                {formatCurrencyDecimals(
                                  calculateAmount(transaction)
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeInvestmentTab === InvesmentTabs.BUY ? 'active' : ''
                    } `}
                    id="detail-investment-buy"
                    role="tabpanel"
                  >
                    <table className="table table-striped table-font-sm">
                      <tbody>
                        {[...buyTransactions]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((transaction) => (
                            <tr key={transaction._id}>
                              <td className="pl-0">
                                {format(
                                  new Date(transaction?.date),
                                  'dd/MM/yy'
                                )}
                              </td>
                              <td
                                className="text-primary cursor-pointer d-none d-lg-table-cell"
                                onClick={navigateToTransactionsTab}
                              >
                                {transactionNames[transaction?.transactionType]}
                              </td>
                              <td className="tb-col-end pr-0">
                                {formatCurrencyDecimals(
                                  calculateAmount(transaction)
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="card card-bordered card-full card-with-table">
              <div className="card-inner">
                <div className="card-title-group mb-1">
                  <div className="card-title">
                    <h6 className="title">{t('ReturnROI')}</h6>
                    <p>
                      {t('ReturnSummary')}.&nbsp;
                      <span
                        className="cursor-pointer text-primary "
                        onClick={navigateToTransactionsTab}
                      >
                        {t('ReturnDetails')}
                      </span>
                    </p>
                  </div>
                </div>
                <ul
                  className="nav nav-tabs nav-tabs-card nav-tabs-xs"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link cursor-pointer ${
                        activeReturnmentTab === ReturnmentTabs.ALL
                          ? 'active'
                          : ''
                      } `}
                      data-bs-toggle="tab"
                      aria-selected={
                        activeReturnmentTab === ReturnmentTabs.ALL
                          ? 'true'
                          : 'false'
                      }
                      role="tab"
                      tabIndex={0}
                      onClick={() => setActiveReturnmentTab(ReturnmentTabs.ALL)}
                    >
                      {t('All')}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link cursor-pointer ${
                        activeReturnmentTab === ReturnmentTabs.DIVIDENDS
                          ? 'active'
                          : ''
                      } `}
                      data-bs-toggle="tab"
                      aria-selected={
                        activeReturnmentTab === ReturnmentTabs.DIVIDENDS
                          ? 'true'
                          : 'false'
                      }
                      tabIndex="-1"
                      role="tab"
                      onClick={() =>
                        setActiveReturnmentTab(ReturnmentTabs.DIVIDENDS)
                      }
                    >
                      {t('Dividends')}
                    </a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a
                      className={`nav-link cursor-pointer ${
                        activeReturnmentTab === ReturnmentTabs.SELL
                          ? 'active'
                          : ''
                      } `}
                      data-bs-toggle="tab"
                      aria-selected={
                        activeReturnmentTab === ReturnmentTabs.SELL
                          ? 'true'
                          : 'false'
                      }
                      tabIndex="-2"
                      role="tab"
                      onClick={() =>
                        setActiveReturnmentTab(ReturnmentTabs.SELL)
                      }
                    >
                      {t('Sales')}
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-0">
                  <div
                    className={`tab-pane ${
                      activeReturnmentTab === ReturnmentTabs.ALL ? 'active' : ''
                    } `}
                    id="detail-returnment-all"
                    role="tabpanel"
                  >
                    <table className="table table-striped table-font-sm">
                      <tbody>
                        {[...dividendsTransactions, ...sellTransactions]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((transaction) => (
                            <tr key={transaction._id}>
                              <td className="pl-0">
                                {format(
                                  new Date(transaction?.date),
                                  'dd/MM/yy'
                                )}
                              </td>
                              <td
                                className="text-primary cursor-pointer d-none d-lg-table-cell"
                                onClick={navigateToTransactionsTab}
                              >
                                {transaction?.transactionType
                                  ? transactionNames[
                                      transaction?.transactionType
                                    ]
                                  : t('Dividends')}
                              </td>
                              <td className="tb-col-end pr-0">
                                {transaction?.transactionType
                                  ? formatCurrencyDecimals(
                                      calculateAmount(transaction)
                                    )
                                  : formatCurrencyDecimals(transaction?.amount)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeReturnmentTab === ReturnmentTabs.DIVIDENDS
                        ? 'active'
                        : ''
                    } `}
                    id="detail-returnment-increase"
                    role="tabpanel"
                  >
                    <table className="table table-striped table-font-sm">
                      <tbody>
                        {[...dividendsTransactions]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((transaction) => (
                            <tr key={transaction._id}>
                              <td className="pl-0">
                                {format(
                                  new Date(transaction?.date),
                                  'dd/MM/yy'
                                )}
                              </td>
                              <td
                                className="text-primary cursor-pointer d-none d-lg-table-cell"
                                onClick={navigateToTransactionsTab}
                              >
                                {transaction?.transactionType
                                  ? transactionNames[
                                      transaction?.transactionType
                                    ]
                                  : t('Dividends')}
                              </td>
                              <td className="tb-col-end pr-0">
                                {transaction?.transactionType
                                  ? formatCurrencyDecimals(
                                      calculateAmount(transaction)
                                    )
                                  : formatCurrencyDecimals(transaction?.amount)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeReturnmentTab === ReturnmentTabs.SELL
                        ? 'active'
                        : ''
                    } `}
                    id="detail-returnment-sell"
                    role="tabpanel"
                  >
                    <table className="table table-striped table-font-sm">
                      <tbody>
                        {[...sellTransactions]
                          .sort((a, b) => new Date(b.date) - new Date(a.date))
                          .map((transaction) => (
                            <tr key={transaction._id}>
                              <td className="pl-0">
                                {format(
                                  new Date(transaction?.date),
                                  'dd/MM/yy'
                                )}
                              </td>
                              <td
                                className="text-primary cursor-pointer d-none d-lg-table-cell"
                                onClick={navigateToTransactionsTab}
                              >
                                {transaction?.transactionType
                                  ? transactionNames[
                                      transaction?.transactionType
                                    ]
                                  : t('Dividends')}
                              </td>
                              <td className="tb-col-end pr-0">
                                {transaction?.transactionType
                                  ? formatCurrencyDecimals(
                                      calculateAmount(transaction)
                                    )
                                  : formatCurrencyDecimals(transaction?.amount)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-4">
            <div className="card card-bordered card-full">
              <div className="card-inner">
                <div className="card-title-group mb-1">
                  <div className="card-title">
                    <h6 className="title">{t('ShareValuation')}</h6>
                    <p>{t('ValueOfMyShares')}* </p>
                  </div>
                </div>

                <div className="tab-content mt-0">
                  <div className="tab-pane active" id="overview">
                    <div className="invest-ov gy-2">
                      <div className="invest-ov-details">
                        <div className="invest-ov-info w-100">
                          <div className="amount">
                            {formatCurrencyDecimals(valuationFD)}
                            <span className="currency currency-usd" />
                          </div>
                          <div className="title">{t('FDValue')}</div>
                        </div>

                        {/* <div className="invest-ov-stats">
                          <div>
                            <span className="amount">x1,5</span>
                            <span className="change up text-danger">
                              <em className="icon ni ni-arrow-long-up" />
                              150%
                            </span>
                          </div>
                          <div className="title">Variac ión</div>
                        </div> */}
                      </div>
                      <div className="invest-ov-details">
                        <div className="invest-ov-info w-100">
                          <div className="amount">
                            {formatCurrencyDecimals(valuationND)}
                            <span className="currency currency-usd" />
                          </div>
                          <div className="title">{t('NDValue')}</div>
                        </div>

                        {/* <div className="invest-ov-stats">
                          <div>
                            <span className="amount">x1,5</span>
                            <span className="change up text-danger">
                              <em className="icon ni ni-arrow-long-up" />
                              150%
                            </span>
                          </div>
                          <div className="title">Variac ión</div>
                        </div> */}
                      </div>
                    </div>
                    <div className="invest-ov gy-2">
                      <div className="subtitle">{t('SocietyValuation')}</div>
                      <div className="invest-ov-details">
                        <div className="invest-ov-info w-100">
                          <NumberFormat
                            id="outlined-normal"
                            className="form-control form-control-outlined"
                            value={sliderValue}
                            onChange={(e) => handleChangeInputSlider(e)}
                            isAllowed={(inputObj) =>
                              handleInputNumberFormat(inputObj)
                            }
                            {...numberFormat}
                          />
                        </div>
                      </div>
                      <div className="title">{t('Value')}</div>
                      <div className="form-control-wrap mt-5">
                        <Nouislider
                          start={sliderValue || 0}
                          range={{
                            min: (societyValue?.value || 0) * 0.1,
                            max: (societyValue?.value || 0.001) * 10,
                          }}
                          step={sliderStep}
                          format={wNumb({
                            decimals: 0,
                            thousand: '.',
                            suffix: ' €',
                          })}
                          animate
                          tooltips
                          connect
                          onChange={(value) => handleChangeSlider(value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PartnerInvestment;
