/* eslint-disable no-console */
import { getUsers } from 'modules/profile/redux/userActions';
import { store } from 'redux/store';
import { User } from 'types';
import { hasShowDetails } from 'utils/showDetails';

export const getOtherUsers = async (userIdsByPartner: any) => {
  const partnersWithOtherUsers = Object.entries(userIdsByPartner);

  if (!partnersWithOtherUsers.length) {
    return;
  }

  try {
    const otherUsersId = Object.values(userIdsByPartner).flat();

    const otherUsersList: any = await store.dispatch(
      getUsers({
        usersList: otherUsersId,
      })
    );

    const partnersWithOtherUsersAndEmails: any = {};

    Object.entries(partnersWithOtherUsers).forEach(([_, tupla]) => {
      const partnerId = tupla[0];
      const userIds: any = tupla[1];
      const users = userIds.map((userId: string) => {
        const user = otherUsersList.find((user: User) => user._id === userId);
        return user
          ? { email: user.email, name: user.name, id: partnerId }
          : null;
      });

      partnersWithOtherUsersAndEmails[partnerId] = users;
    });

    if (hasShowDetails()) {
      console.log(
        '🚀 cclog ~ getOtherUsers ~ partnersWithOtherUsersAndEmails:',
        partnersWithOtherUsersAndEmails
      );
    }

    return partnersWithOtherUsersAndEmails;
  } catch (error) {
    return null;
  }
};
