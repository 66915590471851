import { FC, ReactNode, JSX, memo } from 'react';
import NumberFormat from 'react-number-format';

export type TransactionDetailsOperationTableCellProps = {
  cellValue: number | string | undefined;
  format: Record<string, any>;
  renderText?: (formattedValue: string) => ReactNode; // eslint-disable-line
};

const TransactionDetailsOperationTableCell: FC<
  TransactionDetailsOperationTableCellProps
> = ({ cellValue, format, renderText }): JSX.Element => (
  <td className="text-right">
    {cellValue ? (
      <NumberFormat
        value={cellValue}
        displayType="text"
        renderText={renderText}
        thousandSeparator={format?.thousandSeparator}
        decimalSeparator={format?.decimalSeparator}
        decimalScale={format?.decimalScale}
        suffix={format?.suffix}
        fixedDecimalScale={format?.fixedDecimalScale}
      />
    ) : (
      '-'
    )}
  </td>
);

export default memo(TransactionDetailsOperationTableCell);
