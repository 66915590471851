import React from 'react';
import { format } from 'date-fns';

import downloadFile from 'utils/downloadFile';
import { dateFormat } from 'constants/formats';

function PartnerDocumentsRow({ document, index }) {
  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col d-none d-md-table-cell">
        <span className="text-bold">{index}</span>
      </td>
      <td className="nk-tb-col">
        <span className="text-2-lines-ellipsis">{document?.name}</span>
      </td>
      <td className="nk-tb-col d-none d-md-table-cell">
        <span>{format(new Date(document?.date), dateFormat)}</span>
      </td>
      <td className="nk-tb-col py-0">
        <span
          className="btn btn-icon btn-trigger"
          onClick={() => downloadFile({ documentId: document?.['_id'] })}
        >
          <em className="icon ni ni-file-download" />
        </span>
      </td>
    </tr>
  );
}

export default PartnerDocumentsRow;
