import React from 'react';

function Badge({ creationDate, isAdmin }) {
  const badgeIcon = isAdmin ? 'calendar-alt' : 'clock';

  return (
    <span className="badge badge-dim badge-light text-gray">
      <em className={`icon ni ni-${badgeIcon}`} />
      <span>
        {`${creationDate.getMonth() + 1}/${creationDate.getFullYear()}`}
      </span>
    </span>
  );
}

export default Badge;
