import isValidEmail from 'utils/isValidEmail';

type Props = {
  extraSignerName: string;
  extraSignerEmail: string;
};

export default function isValidExtraSigner({
  extraSignerName,
  extraSignerEmail,
}: Props): boolean {
  const validName = extraSignerName !== '';
  const validEmail = isValidEmail(extraSignerEmail);
  return validName && validEmail;
}
