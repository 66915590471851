import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';
import isValidEmail from 'utils/isValidEmail';
import InputError from 'modules/_shared/components/InputError';
import { EMAIL_PATTERN } from 'constants/defaultConstants';
import { Partner, User } from 'types';
import AddedEmailsView from './AddedEmailsView';

type Props = {
  value: string;
  onChange: any;
  isAdmin: boolean;
  user: User;
  partner: Partner;
  originalPartner: Partner;
  onUpdatePartner: any;
};

const PersonalDataViewEmails: FC<Props> = ({
  value,
  onChange,
  isAdmin,
  user,
  partner,
  originalPartner,
  onUpdatePartner,
}) => {
  const { t } = useTranslate();

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label w-100" htmlFor="partner-email">
          {t('MainEmail')}
          <em
            className="icon ni ni-info text-gray ml-2"
            data-tooltip-id="modal-email-category-main-info"
          />
          <Tooltip
            id="modal-email-category-main-info"
            place="right"
            style={{ zIndex: 9999 }}
          >
            {t('EmailCategoryMainInfo')}
          </Tooltip>
        </label>

        <input
          id="patner-email"
          type="email"
          className="form-control form-control-lg"
          value={value}
          onChange={onChange}
          disabled={!isAdmin && user['_id'] !== partner?.user}
          placeholder={t('IntroduceNewEmail')}
          pattern={EMAIL_PATTERN}
        />
        {!isValidEmail(value) && (
          <InputError errorMessage={t('InvalidEmail')} />
        )}
      </div>

      <AddedEmailsView
        currentPartner={partner}
        originalPartner={originalPartner}
        handleUpdatePartner={onUpdatePartner}
      />
    </div>
  );
};

export default PersonalDataViewEmails;
