/* eslint-disable no-console */

import { hasBlockAI } from 'utils/showDetails';
import mockedResponse from '../constants/mockedResponse';
import {
  extractDataFromPDF,
  extractDataFromText,
  mapResultToJSON,
} from './documents';

const emulateAPIResponse = async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  return mockedResponse;
};

export default async function extractData(
  text: string,
  queryType: string,
  pdfFile?: any
) {
  try {
    let extractedData;

    if (pdfFile) {
      extractedData = hasBlockAI()
        ? await emulateAPIResponse()
        : await extractDataFromPDF(pdfFile, queryType);
    } else {
      extractedData = await extractDataFromText(text, queryType);
    }

    const resultData = mapResultToJSON(extractedData);
    return resultData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
