function generateDocumentOptions() {
  return [
    {
      icon: 'ni-info',
      text: 'Borrador Acta Junta General en castellano',
      action: ({ generateScripture }) => generateScripture(),
      dropdown: true,
    },
    {
      icon: 'ni-info',
      text: 'Borrador Acta Junta General en castellano e inglés',
      action: ({ generateScripture }) => generateScripture(),
      dropdown: true,
      disabled: true,
    },
    {
      icon: 'ni-info',
      text: 'Borrador Escritura Ampliación',
      action: ({ generateScripture }) => generateScripture(),
      dropdown: true,
      disabled: true,
    },
  ];
}
export default generateDocumentOptions;
