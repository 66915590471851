import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import NumberFormat from 'react-number-format';

import { CoOwner, Encumbrance } from 'types';

import { numberFormat } from 'constants/formats';
import ShareEncumbranceViewTableCoOwnerRow from './ShareEncumbranceViewTableCoOwnerRow';

type Props = {
  index: number;
  encumbrance: Encumbrance;
  isEditing: boolean;
  // eslint-disable-next-line no-unused-vars
  handleEditEncumbrance: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveEncumbrance: (index: number) => void;
};

const ShareEncumbranceViewTableRow: FC<Props> = ({
  index,
  encumbrance,
  isEditing,
  handleEditEncumbrance,
  handleRemoveEncumbrance,
}) => (
  <>
    <tr>
      <td
        className="text-left text-primary"
        key={`encumbrance-name-${index}`}
        data-tooltip-id={`encumbrance-name-${index}`}
        data-tooltip-content={encumbrance?.name}
      >
        <span className="encumbrance-name">{encumbrance?.name}</span>
        <Tooltip id={`encumbrance-name-${index}`} place="top" />
      </td>

      <td className="text-center" key={`encumbrance-affected-shares-${index}`}>
        <NumberFormat
          displayType="text"
          value={encumbrance?.to - encumbrance?.from}
          thousandSeparator={numberFormat.thousandSeparator}
          decimalSeparator={numberFormat.decimalSeparator}
        />
      </td>

      <td className="text-center" key={`encumbrance-from-${index}`}>
        <NumberFormat
          displayType="text"
          value={encumbrance?.from}
          thousandSeparator={numberFormat.thousandSeparator}
          decimalSeparator={numberFormat.decimalSeparator}
        />
      </td>

      <td className="text-center" key={`encumbrance-to-${index}`}>
        <NumberFormat
          displayType="text"
          value={encumbrance?.to}
          thousandSeparator={numberFormat.thousandSeparator}
          decimalSeparator={numberFormat.decimalSeparator}
        />
      </td>

      <td className="text-left" key={`encumbrance-co-owner-row-${index}`}>
        {encumbrance?.coOwners?.map((coOwner: CoOwner, idx) => (
          <ShareEncumbranceViewTableCoOwnerRow index={idx} coOwner={coOwner} />
        ))}
      </td>

      <td className="text-center" key={`encumbrance-edit-${index}`}>
        <button
          type="button"
          className="text-soft btn btn-icon pt-0 pb-0"
          onClick={() => handleEditEncumbrance(index)}
          disabled={isEditing}
        >
          <em className="icon ni ni-edit" />
        </button>
      </td>

      <td className="text-center" key={`encumbrance-remove-${index}`}>
        <button
          type="button"
          className="text-soft btn btn-icon pt-0 pb-0"
          onClick={() => handleRemoveEncumbrance(index)}
          disabled={isEditing}
        >
          <em className="icon ni ni-trash" />
        </button>
      </td>
    </tr>
  </>
);

export default ShareEncumbranceViewTableRow;
