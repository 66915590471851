import { Range } from 'types';

export default function validRange(
  from: number,
  to: number,
  validRanges: Range[]
) {
  const isValid = validRanges?.some(
    (range) => range?.from <= from && range?.to >= to && from < to
  );
  return isValid;
}
