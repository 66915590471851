import { translate } from 'hooks/useTranslate';
import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';
import { getBoardRepresentants } from 'modules/boards/utils';
import { Board, Partner, PartnerRepresentant } from 'types';

type Options = {
  role: string;
  value: string;
  label: string;
};

type SelectedPartnerOptions = {
  label: string;
  options: Options[];
};

export const getSelectedPartnerOptions = (
  partners: Partner[],
  board: Board,
  partnerRepresentants: PartnerRepresentant[]
): SelectedPartnerOptions[] => {
  const partnerOptions = partners.map((partner) => ({
    role: boardDelegationRoles.PARTNER,
    value: partner['_id'],
    label: `${partner?.cif} | ${partner?.name}`,
  }));

  const representants = getBoardRepresentants(board, partnerRepresentants);

  const representantOptions = representants.map((representant) => ({
    role: boardDelegationRoles.PARTNER_REPRESENTANT,
    value: representant['_id'],
    label: `${representant?.cif} | ${representant?.name}`,
  }));

  return [
    { label: translate('PartnerRepresentants'), options: representantOptions },
    { label: translate('Partners'), options: partnerOptions },
  ];
};
