import { useEffect, useState } from 'react';

import { SelectorOption } from 'types';

import cliffOptions from 'constants/cliffOptions';

export function useGetCliffOptions() {
  const [resultCliffOptions, setResultCliffOptions] = useState<
    SelectorOption[]
  >([]);

  useEffect(() => {
    const options = cliffOptions.map((option: any) => ({
      value: option.value,
      label: option.text,
    }));

    setResultCliffOptions(options);
  }, []);

  return {
    data: { resultCliffOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
