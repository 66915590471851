/* eslint-disable import/prefer-default-export */
import { Document } from 'types';

type DocumentsByPlan = {
  [key: string]: {
    planId: string;
    planName: string;
    documents: Document[];
  };
};

export const getDocumentsByPlans = (
  beneficiaries: Record<string, any>
): DocumentsByPlan => {
  const documentsByPlans: DocumentsByPlan = {};

  beneficiaries.forEach((beneficiary: Record<string, any>) => {
    const { planData, documents } = beneficiary;
    const { _id: planId, name: planName } = planData;

    if (!documents?.length || planData?.isDraft) return;

    if (!documentsByPlans[planId]) {
      documentsByPlans[planId] = {
        planId,
        planName,
        documents: [],
      };
    }

    documentsByPlans[planId].documents.push(...documents);
  });

  return documentsByPlans;
};
