import { FC, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Board, BoardParticipant } from 'types';

type ParticipantsTableProps = {
  currentBoard: Board;
  otherPartnerEmails: Record<string, any>;
  handleEditParticipant: (participant: BoardParticipant) => void; // eslint-disable-line
  isParticipantAllowedForAnnouncement: (
    participant: BoardParticipant // eslint-disable-line
  ) => boolean;
  getTotalParticipantsAllowed: () => number;
};

const ParticipantsTable: FC<ParticipantsTableProps> = ({
  currentBoard,
  otherPartnerEmails,
  handleEditParticipant,
  getTotalParticipantsAllowed,
  isParticipantAllowedForAnnouncement,
}) => {
  const { t } = useTranslate();

  const [expandView, setExpandView] = useState<boolean>(false);
  const [expandDirection, setExpandDirection] = useState<string>('down');

  const handleViewChange = (): void => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  return (
    <div key="participants-card" className="card card-bordered mb-4">
      <table
        className="table"
        id="participants-table"
        style={{ marginBottom: '0px' }}
      >
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={2}>
              <span>
                {t('Participants')} ({getTotalParticipantsAllowed()})
              </span>
            </th>
            <th className="col-2 text-right">
              <button
                type="button"
                className="badge btn-primary"
                onClick={() => handleViewChange()}
              >
                <em className={`icon ni ni-chevron-${expandDirection}`} />
              </button>
            </th>
          </tr>
        </thead>
      </table>
      <div
        key="participants-card "
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
        }}
      >
        <table className="table" id="participants-body">
          {expandView && (
            <tbody>
              {currentBoard?.participants
                ?.filter((participant: BoardParticipant) =>
                  isParticipantAllowedForAnnouncement(participant)
                )
                ?.map((participant: BoardParticipant, index: number) => (
                  <tr className="tb-tnx-item">
                    <td
                      className="tb-tnx-info text-left py-0"
                      id={`participant-${index}`}
                    >
                      <span className="title">{participant.name}</span>
                    </td>
                    <td
                      className="tb-tnx-info text-soft text-left py-0"
                      id={`participant-${index}-email`}
                    >
                      <span className="title d-block">{participant.email}</span>
                      {otherPartnerEmails?.[participant.member]?.map(
                        (otherUser: any) => (
                          <span className="title d-block">
                            {otherUser.email}
                          </span>
                        )
                      )}
                    </td>
                    <td
                      className="tb-tnx-info text-soft text-left py-0"
                      id={`participant-${index}-blank`}
                    >
                      <div className="nk-block float-right">
                        <button
                          type="button"
                          className="btn btn-icon btn-trigger px-1"
                          onClick={() => {
                            handleEditParticipant(participant);
                          }}
                        >
                          <em className="icon ni ni-edit" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default ParticipantsTable;
