import i18n from 'i18n/config';

export default {
  ORDINARY: {
    value: 'ORDINARY',
    text: i18n.t('Ordinary'),
  },
  EXTRAORDINARY: {
    value: 'EXTRAORDINARY',
    text: i18n.t('Extraordinary'),
  },
  ORDINARY_EXTRAORDINARY: {
    value: 'ORDINARY_EXTRAORDINARY',
    text: i18n.t('OrdinaryAndExtraordinary'),
  },
  UNIVERSAL: {
    value: 'UNIVERSAL',
    text: i18n.t('Universal'),
  },
};
