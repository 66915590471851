import envelopeSignerStatus from 'constants/envelopeSignerStatus';
import { EnvelopeSigner } from 'types';

export function getSignedSignersCount(signers: EnvelopeSigner[]) {
  const signedSignersCount =
    signers?.filter(
      (signer) =>
        signer?.status === envelopeSignerStatus.var.completed ||
        signer?.status === envelopeSignerStatus.var.signed
    )?.length || 0;
  return signedSignersCount;
}
