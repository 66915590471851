import { ChangeEvent, FC } from 'react';

import { BoardOrder } from 'types';

import voteTypes from 'constants/voteTypes';
import voteValues from 'constants/voteValues';

import VoteInput from './SendVoteInput';

type Props = {
  order: BoardOrder;
  voteValue: string;
  handleChangeVoteValue: (event: ChangeEvent<HTMLInputElement>) => void; // eslint-disable-line
};

const SendVoteManualView: FC<Props> = ({
  order,
  voteValue,
  handleChangeVoteValue,
}) => (
  <>
    {order.voteType === voteTypes.YES_NO_ABS.value && (
      <div className="form-group vote-input-wrapper">
        <div
          className="d-flex flex-column justify-content-center"
          style={{
            position: 'relative',
            height: '400px',
          }}
        >
          <ul
            className="sp-package-list d-flex flex-column w-100 h-100 m-0 p-0"
            style={{ listStyleType: 'none' }}
          >
            <li className="d-flex flex-column flex-grow-1">
              <VoteInput
                voteType={voteValues.YES.value}
                voteValue={voteValue}
                handleChangeVoteValue={handleChangeVoteValue}
              />
            </li>
            <li className="d-flex flex-column  flex-grow-1">
              <VoteInput
                voteType={voteValues.NO.value}
                voteValue={voteValue}
                handleChangeVoteValue={handleChangeVoteValue}
              />
            </li>
            <li className="d-flex flex-column">
              <VoteInput
                voteType={voteValues.ABS.value}
                voteValue={voteValue}
                handleChangeVoteValue={handleChangeVoteValue}
              />
            </li>
          </ul>
        </div>
      </div>
    )}
  </>
);

export default SendVoteManualView;
