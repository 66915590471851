import { HoldingClass, Operation, Society } from 'types';

type GetOperationNominalValueProps = {
  holdingClass: HoldingClass | null;
  operation: Operation;
  society: Society;
};

export const getOperationNominalValue = ({
  holdingClass,
  operation,
  society,
}: GetOperationNominalValueProps): number => {
  if (holdingClass?.nominalValue) {
    return holdingClass.nominalValue;
  }

  if (operation?.nominalValue) {
    return operation.nominalValue;
  }

  return society?.nominalValue || 0;
};
