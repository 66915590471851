/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { format } from 'date-fns';

import { dateFormat } from 'constants/formats';

import statusTypes from 'modules/society/components/SocietyRepresentative/statusTypes';
import { setModal } from 'modules/_shared/redux/modalActions';
import representativeTypes from 'modules/society/components/SocietyRepresentative/representativeTypes';
import AddSocietyRepresentative from 'modules/_shared/components/Modals/AddSocietyRepresentative';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

function RepresentativePersonal({ society, representative }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isAdmin, isReadOnly } = useGetSocietySectionPermissions().data;

  const { user } = useSelector((state) => ({
    user: state.user,
  }));
  const [address, setAddress] = useState();
  const [status, setStatus] = useState();

  const handleEditRepresentative = () => {
    const representativeData = { ...representative };
    dispatch(
      setModal(
        <AddSocietyRepresentative
          societyId={society['_id']}
          action="EDIT"
          representativeData={representativeData}
          representativeId={representative['_id']}
          userId={user?.['_id']}
        />
      )
    );
  };

  useEffect(() => {
    const { line1, zip, city, country } = representative.address || {};

    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : '-'
      }`
    );
  }, [representative.address]);

  useEffect(() => {
    if (!representative?.representativeStatus) {
      if (
        !representative?.endDate ||
        new Date(representative.endDate) > new Date()
      ) {
        setStatus(statusTypes.var.CURRENT);
      } else {
        setStatus(statusTypes.var.REVOKED);
      }
    } else {
      setStatus(representative.representativeStatus);
    }
  }, [representative]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        {!isReadOnly && (
          <div className="nk-block-head-content">
            <div className="float-right">
              <button
                type="button"
                className="btn btn-icon btn-trigger btn-tooltip"
                onClick={handleEditRepresentative}
              >
                <em className="icon ni ni-edit mr-1" />
              </button>
            </div>
          </div>
        )}

        <h5 className="title">{t('PersonalInformation')}</h5>
        <p>{t('BasicRepresentativeInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Name')}</span>
          <span className="profile-ud-value text-left">
            {t(representative?.socialDenomination) || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value text-left">
            {t(representative?.nif) || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Email')}</span>
          <span className="profile-ud-value text-left">
            {t(representative?.email) || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">
            {t('RepresentativeDate')}
          </span>
          <span className="profile-ud-value text-left">
            {representative?.initDate
              ? format(new Date(representative?.initDate), dateFormat)
              : '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">
            {t('RevocationDate')}
          </span>
          <span className="profile-ud-value text-left">
            {representative?.endDate
              ? format(new Date(representative?.endDate), dateFormat)
              : '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">
            {t('TypeOfAuthority')}
          </span>
          <span className="profile-ud-value text-left">
            {representative?.representativeType
              ? representativeTypes.name[representative?.representativeType]
              : ''}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Status')}</span>
          {status && (
            <span
              className={`profile-ud-value text-left badge badge-dot badge-${statusTypes.badge[status]}`}
            >
              {statusTypes.name[status]}
            </span>
          )}
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value text-left">
            {representative?.nationality || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value text-left">{address || '-'}</span>
        </div>
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </div>
  );
}

export default RepresentativePersonal;
