import i18n from "i18n/config";

export default {
    var: {
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      FINISHED: 'FINISHED'
    },
    name: {
      ACTIVE: i18n.t('Valid'),
      INACTIVE: i18n.t('TimeOut'),
      FINISHED: i18n.t('NotValid'),
    },
    badge: {
      ACTIVE: 'success',
      INACTIVE: 'danger',
      FINISHED: 'light'
    },
  }