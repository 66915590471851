/* eslint-disable no-useless-concat */
/* eslint-disable no-console */
import Record from 'typescript';
import { Partner } from 'types';
import { format } from 'date-fns';
import { dateFormat } from 'constants/formats';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import { getFullAddress } from './filters';
import {
  formatNumber,
  formatNumberDecimals,
  formatNumberDecimalsLong,
} from './formats';

type Attendee = {
  name: string;
  status: string;
  address: string;
  cif: string;
};

const NAME = '[NOMBRE Y APELLIDOS]';
const NATIONALITY = '[NACIONALIDAD]';
const STATUS = '[ESTADO CIVIL]';
const ADDRESS = '[DIRECCIÓN COMPLETA]';
const CIF = '[DNI/NIF]';
const CITY = '[CIUDAD]';
const PROTOCOL_NUMBER = '[NÚMERO DE PROTOCOLO]';
const SHARES_TOTAL = '[TOTAL DE PARTICIPACIONES]';
const SHARES_PERCENT = '[PORCENTAJE DE PARTICIPACIONES]';
const SHARE_FROM = '[PARTICIPACIÓN DESDE]';
const SHARE_TO = '[PARTICIPACIÓN HASTA]';
const SHARE_PRICE = '[PRECIO DE LA PARTICIPACIÓN]';
const BUYER_NAME = '[NOMBRE DEL COMPRADOR]';
const SOCIETY_NAME = '[NOMBRE DE LA SOCIEDAD]';
const OPERATION_DATE = '[FECHA DE LA OPERACIÓN]';
const OPERATION_TYPE = '[TIPO DE OPERACIÓN]';
const NOTARY_CITY = '[CIUDAD DEL NOTARIO]';
const NOTARY_NAME = '[NOMBRE DEL NOTARIO]';
const NOTARY_NUMBER = '[NÚMERO DE PROTOCOLO]';
const HOLDING_CLASS = '[CLASE DE PARTICIPACIÓN]';
const HOLDING_CLASS_RANGES = '[RANGOS DE NUMERACIÓN]';
const TOTAL_CONTRIBUTION = '[TOTAL APORTACIÓN]';
const NOMINAL_VALUE = '[VALOR NOMINAL]';
const PRIME_VALUE = '[PRIMA DE ASUNCIÓN]';
const SHARES_PRICE = '[PRECIO DE LAS PARTICIPACIONES]';

const INIT_BLOCK =
  '<p><span style="font-family: arial, helvetica, sans-serif;">';
const END_BLOCK = '</span></p>';

export const attendeeDescription = (
  attendee: Record<string, any>,
  isNatural: boolean
) => {
  const person: Attendee = {
    name: isNatural ? attendee?.name : attendee?.representative?.name,
    status: isNatural
      ? attendee?.civilStatus
      : attendee?.representative?.status,
    address: isNatural
      ? getFullAddress(attendee?.address)
      : attendee?.representative?.address,
    cif: isNatural ? attendee?.cif : attendee?.representative?.nif,
  };

  return `${INIT_BLOCK}
  ${person?.name || NAME}, mayor de edad, ${
    person?.status || STATUS
  } en régimen legal [...], vecino de ${
    person?.address || ADDRESS
  }, con D.N.I ${person?.cif || CIF}.
  ${END_BLOCK}`;
};

export const naturalPersonsList = (persons: Partner[]) => {
  if (!persons?.length) return '';
  const naturalPersons = persons
    .map((person) => (person?.isNaturalPerson ? person?.name : null))
    .filter(Boolean)
    .join(', ');

  return `${INIT_BLOCK}
  ${naturalPersons}
  ${END_BLOCK}`;
};

export const participantLegalPerson = (participant: Partner, notary: any) => {
  if (!participant) return '';

  const { representative } = participant;
  const representativeName = representative?.name || NAME;
  const sellerNif = participant?.cif || CIF;
  const sellerName = participant?.name || NAME;
  const notaryCity = notary?.city || CITY;
  const notaryName = notary?.name || NAME;
  const notaryNumber = notary?.number || PROTOCOL_NUMBER;

  return `${INIT_BLOCK}
  ${representativeName}, en nombre y representación, en su condición de
  apoderado de la sociedad ${sellerName}, sociedad válidamente constituida
  conforme a las leyes del Reino de España, con domicilio social en $
  {sellerAddress} constituida por tiempo indefinido mediante escritura
  otorgada ante el Notario de ${notaryCity}, ${notaryName}, de fecha [FECHA
  CONSTITUCIÓN VENDEDOR], con el número [NÚMERO DE PROTOCOLO] de protocolo e
  inscrita en el Registro Mercantil de [REGISTRO MERCANTIL],
  Tomo/Sección[NÚMERO TOMO/SECCIÓN] Folio [NÚMERO FOLIO], Hoja [NÚMERO HOJA]
  y número de identificación fiscal (N.I.F.) ${sellerNif}. \n\n Se encuentra
  expresamente facultado para el presente otorgamiento en virtud de poder
  [TIPO DE PODER] otorgado a su favor en la escritura de fecha [FECHA
  APODERAMIENTO] ante el notario de ${notaryCity} ${notaryName} con el
  número ${notaryNumber} de protocolo, copia de la cual me exhibe y
  devuelvo.
  ${END_BLOCK}`;
};

export const sharesOwnership = (
  participant: any,
  operation: any,
  society: any
) => {
  if (!participant) return '';

  const partnerName = participant?.name || NAME; // ✅
  const sharesTotal = participant?.sharesTotal || SHARES_TOTAL;
  const sharesPercent = participant?.sharesPercent || SHARES_PERCENT;
  const shareFrom = participant?.shareFrom || SHARE_FROM;
  const shareTo = participant?.shareTo || SHARE_TO;
  const sharePrice = participant?.sharePrice || SHARE_PRICE;
  const operationDate = participant?.sourceOperation?.date
    ? format(new Date(participant?.sourceOperation?.date), dateFormat)
    : OPERATION_DATE;
  const operationType =
    operationTypesRealNames[participant?.sourceOperation?.type] ||
    OPERATION_TYPE;

  const societyName = society?.name || SOCIETY_NAME;

  const notaryCity = operation?.notary?.city || NOTARY_CITY;
  const notaryName = operation?.notary?.name || NOTARY_NAME;
  const notaryNumber = operation?.notary?.protocolNumber || NOTARY_NUMBER;

  return `${INIT_BLOCK}
  ${partnerName} es propietario de ${sharesTotal} participaciones sociales que representa el ${sharesPercent}% del capital social, números de la ${shareFrom} a la ${shareTo}, todas ellas inclusive, de ${sharePrice}EUR de valor nominal cada una de ellas, de la compañía mercantil ${societyName},
  ${END_BLOCK}
  ${INIT_BLOCK}
  TÍTULO. - Le pertenecen las participaciones sociales anteriormente reseñadas por haberlas suscrito en escritura de ${operationType}, otorgada ante el Notario de ${notaryCity}, ${notaryName}, de fecha ${operationDate}, número ${notaryNumber} de protocolo.
  ${END_BLOCK}`;
};

export const sharesPerfection = (
  participant: any,
  operation: any,
  society: any
) => {
  if (!participant) return '';

  const partnerName = participant?.name || NAME;
  const sharesTotal = participant?.sharesTotal || SHARES_TOTAL;
  const shareFrom = participant?.shareFrom || SHARE_FROM;
  const shareTo = participant?.shareTo || SHARE_TO;
  const sharePrice = participant?.sharePrice || SHARE_PRICE;
  const shareTotalPrice = sharesTotal * sharePrice;
  const shareBuyer = participant?.shareBuyer || BUYER_NAME;
  const societyName = society?.name || SOCIETY_NAME;

  return `${INIT_BLOCK}
  ${partnerName}, VENDE Y TRANSMITE, ${sharesTotal} participaciones sociales números ${shareFrom} - ${shareTo} ambos inclusive, de las que es titular en la sociedad ${societyName} a ${shareBuyer}, quien las COMPRA Y ADQUIERE, por precio total de ${shareTotalPrice} euros, lo que representa un precio por participación de ${sharePrice}EUR.
  ${END_BLOCK}
  ${INIT_BLOCK}
  El importe de ${sharePrice} euros, correspondiente al precio de las participaciones sociales a transmitir, citadas en esta cláusula, titularidad de Don ${partnerName} mediante una transferencia bancaria ordenada a la cuenta titularidad del mismo, abierta en [BANCO], con el número [NUMERO CUENTA];
  ${END_BLOCK}`;
};

export const sharesInvestorsDetails = (participant: any, operation: any) => {
  if (!participant) return '';

  const investorName = participant?.name || NAME;
  const investorNationality = participant?.nationality || NATIONALITY;
  const investorCivilStatus = participant?.civilStatus || STATUS;
  const investorAddress = getFullAddress(participant?.address) || ADDRESS;
  const investorCif = participant?.cif || CIF;
  const investorTotalShares =
    formatNumber(participant?.shares?.new) || SHARES_TOTAL;
  const investorShareClass = participant?.holdingClassName || HOLDING_CLASS;
  const investorSharesFrom = formatNumber(participant?.shareFrom) || SHARE_FROM;
  const investorSharesTo = formatNumber(participant?.shareTo) || SHARE_TO;
  const investorTotalContribution =
    formatNumberDecimals(participant?.contribution) || TOTAL_CONTRIBUTION;
  const investorNominalValue =
    formatNumberDecimals(participant?.nominal) || NOMINAL_VALUE;
  const operationPremiumValue =
    formatNumberDecimals(operation?.premium) || PRIME_VALUE;
  const operationSharesPrice =
    formatNumberDecimalsLong(operation?.sharesPrice) || SHARES_PRICE;

  return `${INIT_BLOCK}
  - ${investorName}, mayor de edad, de nacionalidad ${investorNationality}, ${investorCivilStatus}, con domicilio en ${investorAddress}, y titular de [NIE/NIF/PASAPORTE] ${investorCif}; que asume ${investorTotalShares} participaciones sociales Clase ${investorShareClass}, números ${investorSharesFrom} a ${investorSharesTo}, ambos inclusive, por un valor en conjunto de ${investorTotalContribution}€, de los que ${investorNominalValue} € corresponden al valor nominal de las participaciones y ${operationPremiumValue} € corresponden a la prima de asunción, a razón de ${operationSharesPrice} € por participación. El contravalor consiste en el íntegro desembolso de una aportación dineraria.
  ${END_BLOCK}`;
};

export const holdingClassesNameAndRange = (
  holdingClass: Record<string, any>,
  ranges: Record<string, any>[],
  index: number
) => {
  if (!holdingClass) return '';

  const holdingClassName = holdingClass?.name || HOLDING_CLASS;
  const holdingClassRanges =
    ranges
      ?.map((range) => `${formatNumber(range.from)}-${formatNumber(range.to)}`)
      .join(', ') || HOLDING_CLASS_RANGES;
  const holdingClassTotalShares = formatNumber(
    ranges?.reduce((acc, range) => acc + (range.to - range.from + 1), 0) || 0
  );

  const romanNumbers = [
    'i',
    'ii',
    'iii',
    'iv',
    'v',
    'vi',
    'vii',
    'viii',
    'ix',
    'x',
    'xi',
    'xii',
    'xiii',
    'xiv',
    'xv',
  ];

  return `${INIT_BLOCK}
  (${romanNumbers[index]}) ${holdingClassTotalShares} participaciones sociales de Clase "${holdingClassName}", numeradas correlativamente de la ${holdingClassRanges}, ambos inclusive.
  ${END_BLOCK}`;
};
