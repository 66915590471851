import getDocumentBlob from 'modules/_shared/components/CKEditor/utils/getDocumentBlob';
import { useEffect, useState } from 'react';

type Props = {
  htmlContent: string;
};

export function useGetDocumentFrame({ htmlContent }: Props) {
  const [documentFrame, setDocumentFrame] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getDocuemntUrl = async (htmlContent: string) => {
    try {
      setIsLoading(true);
      const blob = await getDocumentBlob(htmlContent);
      const url = URL.createObjectURL(blob);
      setDocumentFrame(url);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (htmlContent) {
      getDocuemntUrl(htmlContent);
    }
  }, [htmlContent]);

  return {
    data: { documentFrame },
    isLoading,
    isError,
    actions: {},
  };
}
