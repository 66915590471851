import { editUser } from 'utils/modals';
import i18n from 'i18n/config';
import removePartner from 'utils/removePartner';

export default [
  {
    icon: 'ni-eye',
    text: i18n.t('Watch'),
    url: '/detalle-socio',
  },
  {
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    action: editUser,
    dontShowCurrent: ({ isReadOnly, isReadOnlyUser }) =>
      isReadOnly === true || isReadOnlyUser === true,
  },
  {
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: async ({ data, society, userId }) => {
      await removePartner(data, society, userId, i18n);
    },
    dontShowCurrent: ({ data, isReadOnly, isReadOnlyUser }) =>
      data?.operations?.length > 1 || isReadOnly || isReadOnlyUser === true,
  },
];
