import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { Society, SocietyDirector } from 'types';

export const getDirectorImage = (
  director: SocietyDirector,
  society: Society
) => {
  if (director?.image) return director.image;

  const partner = society?.partners?.find(
    (partner) => partner?.cif === director?.cif
  );

  return partner?.image || BLANK_PROFILE_PICTURE;
};
