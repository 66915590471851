import { v4 as uuidv4 } from 'uuid';
import { Society, SocietyChart } from 'types';

import {
  getPartnerActualShares,
  getPartnerByCif,
  getPartnerFDPercent,
  getPartnerNDPercent,
} from 'utils/filters';
import { countSocietyShares } from 'utils/getShareCount';

import CompanyLogo from 'assets/images/logo-company.png';

const getHoldingReference = (
  society: Society,
  previousSocieties: Society[]
) => {
  const societyReferenceIds: string[] = [];

  previousSocieties.forEach((previousSociety) => {
    const isPartner = society?.partners?.find(
      (partner) => partner?.cif === previousSociety?.cif
    );

    if (isPartner) {
      societyReferenceIds.push(previousSociety._id);
    }
  });
  return societyReferenceIds.length ? societyReferenceIds : null;
};

const getHoldingInfoForChart = (
  societiesArray: Society[][],
  actualSociety: Society
): SocietyChart[][] => {
  const holdingInfo = societiesArray?.map((currentSocieties, index) =>
    currentSocieties.map((society: Society) => {
      const childSociety: Society =
        index > 0 ? societiesArray[index - 1][0] : actualSociety;

      const parentSocietyWithSharesCount = countSocietyShares(society);

      const partner = getPartnerByCif(
        parentSocietyWithSharesCount,
        childSociety.cif
      );

      const holdingShares = getPartnerActualShares(
        partner._id,
        parentSocietyWithSharesCount
      );

      const holdingPercentND = getPartnerNDPercent(
        partner,
        parentSocietyWithSharesCount
      );

      const holdingPercentFD = getPartnerFDPercent(
        partner,
        parentSocietyWithSharesCount
      );

      const holdingReference =
        index > 0
          ? getHoldingReference(society, societiesArray[index - 1])
          : [actualSociety?._id];

      const holdingId = society?._id;
      const holdingName = society?.name;
      const holdingCIF = society?.cif;
      const holdingLogo = society?.additional?.logo || CompanyLogo;
      const holdingKey = uuidv4();
      const holdingReferenceLevel = index;

      return {
        holdingId,
        holdingName,
        holdingCIF,
        holdingLogo,
        holdingShares,
        holdingPercentND,
        holdingPercentFD,
        holdingReference,
        holdingReferenceLevel,
        holdingKey,
      };
    })
  );

  return holdingInfo;
};

export default getHoldingInfoForChart;
