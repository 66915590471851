/* eslint-disable no-underscore-dangle */
import { Partner } from 'types';
import Record from 'typescript';
import { getPartnerActualShares } from 'utils/filters';

type Eligible = {
  id: string;
  type: 'PARTNER' | 'BENEFICIARY';
  status: 'NOTIFIED' | 'ACCEPTED' | 'REJECTED' | 'CANCELED';
  totalPrice: number;
  totalShares: number;
  operationDate: string;
};

const parsePartnersToEligibles = (
  partnersList: Partner[],
  actualSociety: Record<string, any>
): Eligible[] => {
  if (!partnersList) {
    return [];
  }

  const eligibles: Eligible[] = partnersList.map((partner) => ({
    id: partner?._id,
    type: 'PARTNER',
    status: 'NOTIFIED',
    totalPrice: 0,
    totalShares: getPartnerActualShares(partner._id, actualSociety),
    operationDate: '',
  }));

  return eligibles;
};

export default parsePartnersToEligibles;
