import { FC } from 'react';

import SendVoteModalStep from './SendVoteModalStep';

type Props = {
  voteStep: number;
  isPartnerRepresentant: boolean;
};

const SendVoteModalBodySteps: FC<Props> = ({
  voteStep,
  isPartnerRepresentant,
}) => (
  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
    <div className="steps clearfix text-center">
      <ul role="tablist">
        <SendVoteModalStep
          currentStep={0}
          voteStep={voteStep}
          title="RepresentedWithInstructions"
        />

        <SendVoteModalStep
          currentStep={1}
          voteStep={voteStep}
          title="RepresentedWithoutInstructions"
        />

        {!isPartnerRepresentant && (
          <SendVoteModalStep
            currentStep={2}
            voteStep={voteStep}
            title="YourVote"
          />
        )}
      </ul>
    </div>
  </div>
);

export default SendVoteModalBodySteps;
