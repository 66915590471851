import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import html2pdf from 'html3pdf';
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getBoards, updateBoard } from 'modules/boards/redux/boardActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';
import { store } from 'redux/store';

import boardTypes from 'constants/boardTypes';
import { formatDateToTime } from 'constants/formats';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';

import { getSingleDocument } from 'utils/downloadDocument';

import { Board, BoardParticipant, Society } from 'types';

import getSocietyLogoSrc from 'modules/_shared/components/Logos/getSocietyLogo';
import { emailsFooter } from 'modules/communications/constants/emailTemplates';
import { State } from 'redux/initialState';
import EditRecipientModal from '../../pages/BoardDetails/EditRecipientModal';

type RetryAnnouncementModalProps = {
  recipients: BoardParticipant[];
  currentBoard: Board;
  actualSociety: Society;
  announcementTemplateDoc: any;
};

const RetryAnnouncementModal: FC<RetryAnnouncementModalProps> = ({
  recipients,
  currentBoard,
  actualSociety,
  announcementTemplateDoc,
}) => {
  const { t } = useTranslate();

  const user = useSelector((state: State) => state.user);
  const hasWhiteBranding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [expandDirection, setExpandDirection] = useState<string>('down');
  const [expandView, setExpandView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleEditRecipient = (participant: any): void => {
    store.dispatch(
      setModal(
        <EditRecipientModal
          currentBoard={currentBoard}
          actualSociety={actualSociety}
          recipient={participant}
          announcementTemplateDoc={announcementTemplateDoc}
          recipients={recipients}
        />
      )
    );
  };

  const handleViewChange = (): void => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  const handleSendAnnouncement = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const buttonText =
        currentBoard?.votePeriod === 'FROM_SENDING_TO_CLOSING' ||
        currentBoard?.votePeriod === 'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE'
          ? 'Votar ahora'
          : 'Acceder a la Junta';

      const recipientsForDynamicData = recipients.map((recipient) => {
        const { _id, email, name } = recipient;
        return {
          id: _id,
          email: email ? email.trim() : '',
          name,
        };
      });
      const data = {
        societyName: actualSociety?.name || '-',
        boardName: currentBoard?.name || '',
        date,
        time,
        announcement: currentBoard?.announcement || '',
        place: currentBoard?.place || '-',
        onlineAccess: currentBoard?.onlineAccess || '-',
        boardType: boardTypes?.[currentBoard?.boardType]?.text || '-',
        delegationUrl: '',
      };
      let fileBlob;
      if (currentBoard?.announcementDocument) {
        fileBlob = await getSingleDocument(currentBoard?.announcementDocument);
      } else {
        const documentUpdated = updateDocumentWithValues(
          announcementTemplateDoc?.editorModel,
          tags({
            society: actualSociety,
            board: currentBoard,
            plan: null,
            partner: null,
            beneficiary: null,
            signatures: null,
            tenderOffer: null,
            tenderOfferShares: null,
            operation: null,
            holdingClasses: null,
            annualValue: null,
          })
        );

        const options = {
          filename: `${t('AnnouncementBoard')}.pdf`,
          margin: [10, 10, 10, 10], // top, right, bottom, left
          pagebreak: { mode: 'avoid-all' },
          html2canvas: { scale: 2 },
          jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
          },
        };
        fileBlob = await html2pdf()
          .from(documentUpdated?.html)
          .set(options)
          .output('blob');
      }
      const announcementFile = new File(
        [fileBlob],
        `${t('AnnouncementBoard')}.pdf`,
        {
          type: 'application/pdf',
        }
      );

      const emailData = {
        to: recipients,
        cc: '',
        bcc: '',
        files: announcementFile ? [announcementFile] : [],
        subject: t('BoardAnnouncement'),
        bodySummary: data.announcement,
        template: 'board-announcement-new-es',
        templateBody: `<div class="main" style="padding: 24px">
                <div style="
                    background: #f5f6fa;
                    font-family: Roboto;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #8094ae;
                    width: 100%;
                    text-align: center;
                  ">
                  <a href="https://www.sttok.com">
                    <img src="${getSocietyLogoSrc(
                      actualSociety
                    )}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
                  </a>
                  <p style="
                      font-size: 13px;
                      color: #6576ff;
                      padding-top: 6px;
                      margin-top: 0;
                      padding-bottom: 24px;
                    ">
                    Gestión de Socios
                  </p>
                  <div class="email-body" style="
                      width: 96%;
                      max-width: 620px;
                      text-align: left;
                      margin: 0 auto;
                      padding: 1.75rem 2.75rem;
                      background: #ffffff;
                    ">
                    <h2 style="
                      font-family: Nunito, sans-serif;
                      font-size: 18px;
                      color: #6576ff;
                      font-weight: 600;
                      margin: 0;
                      line-height: 1.4;
                      margin: 10px 0;
                      padding-bottom: 15px;
                      text-align: left;
                      ">
                        Convocatoria Junta de ${data.societyName}
                    </h2>
                    <p style="text-align: left;">${data.announcement}</p>
                    <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                    ">
                        ${data.boardName}
                    </h2>
                    <table style="width:100%;">
                        <tr>
                            <td style="text-align: left; padding-bottom: 10px;">
                              <span>Fecha: ${data.date}</span>
                            </td>
                            <td style="text-align: left; padding-bottom: 10px;">
                              <span>Hora: ${data.time}</span>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;">
                              <span>Tipo Junta: ${data.boardType}</span>
                            </td>
                            <td style="text-align: left;">
                              <span>Videollamada: ${data.onlineAccess}</span>
                            </td>
                        </tr>
                      </table>
                      <p style="text-align: left; margin-top: 10px;">Lugar de celebración: ${
                        data.place
                      }</p>
                      <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                      ">
                        Delegación de voto
                    </h2>
                    <p style="text-align: left;">Si no puedes asistir a la Junta, puedes delegar tu voto aquí:</p>
                    <span style="
                        background: rgb(101, 118, 255);
                        border-radius: 4px;
                        border: none;
                        color: white;
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        margin: 0 auto 30px;
                        padding: 0 30px;
                        width: fit-content;
                      ">
                        Delegar mi voto
                    </span>
                    <br/>
                    <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                      ">
                        Acceso a la Junta y votación
                    </h2>
                    <p style="text-align: left;">Puedes acceder a los detalles y documentación de la Junta:</p>
                    <span style="
                        background: rgb(101, 118, 255);
                        border-radius: 4px;
                        border: none;
                        color: white;
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        margin: 0 auto 30px;
                        padding: 0 30px;
                        width: fit-content;
                      ">
                        ${buttonText}
                      </span>
                      <br/>
                      <p style="text-align: justify">
                          El proceso está gestionado a través de la plataforma Sttok desde la cual podrás acceder a la convocatoria, poder revisar la documentación y votar.
                      </p>
                    </div>
                    <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
                      ${!hasWhiteBranding ? emailsFooter : ''}
                    </p>
                </div>
              </div>`,
        templateData: [
          {
            name: 'societyName',
            content: data.societyName,
          },
          {
            name: 'boardName',
            content: data.boardName,
          },
          {
            name: 'boardDate',
            content: data.date,
          },
          {
            name: 'boardTime',
            content: data.time,
          },
          {
            name: 'onlineAccess',
            content: data.onlineAccess,
          },
          {
            name: 'boardPlace',
            content: data.place,
          },
          {
            name: 'announcement',
            content: data.announcement,
          },
          {
            name: 'boardType',
            content: data.boardType,
          },
          {
            name: 'delegationUrl',
            content: data.delegationUrl,
          },
          {
            name: 'buttonText',
            content: buttonText,
          },
        ],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'name', content: name },
              {
                name: 'delegationUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=delegacion/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
              {
                name: 'boardUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-junta/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
            ],
          })
        ),
        user: user['_id'],
        society: actualSociety['_id'],
        date: new Date(),
        iCal: {
          societyEmail: user.email,
          societyName: data.societyName,
          boardName: data.boardName,
          boardDate: currentBoard.date,
          boardLink: data.onlineAccess,
        },
      };

      const communicationId = await store.dispatch(sendCommEmail(emailData));
      const retriedAnnouncements = currentBoard?.retriedAnnouncements
        ? [...currentBoard?.retriedAnnouncements, communicationId]
        : [communicationId];
      await store.dispatch(
        updateBoard(
          currentBoard['_id'],
          {
            retriedAnnouncements,
          },
          false
        )
      );

      await store.dispatch(getBoards(actualSociety['_id']));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentBoard) {
      setTime(currentBoard?.date ? formatDateToTime(currentBoard?.date) : '');
      setDate(
        currentBoard?.date
          ? format(new Date(currentBoard?.date), 'dd/MM/yyyy')
          : ''
      );
    }
  }, [currentBoard]);

  return (
    <>
      <Modal.Body>
        <div className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <button
              type="button"
              className="close"
              onClick={() => store.dispatch(setModal(null))}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt" />
            <h4 className="nk-modal-title">{t('ResendAnnouncement')}</h4>
            <div className="nk-modal-text">
              <p className="lead">
                {t('ResendNotDeliveredAnnouncementMessage')}
              </p>
            </div>
            <div key="recipients-card" style={{ paddingBottom: '20px' }}>
              <table
                className="table"
                id="recipients-table"
                style={{ marginBottom: '0px' }}
              >
                <thead>
                  <tr className="tb-tnx-head">
                    <th className="text-left" colSpan={2}>
                      <span>
                        {t('Participants')} ({recipients?.length})
                      </span>
                    </th>
                    <th className="col-2 text-right">
                      <button
                        type="button"
                        className="badge btn-primary"
                        onClick={() => handleViewChange()}
                      >
                        <em
                          className={`icon ni ni-chevron-${expandDirection}`}
                        />
                      </button>
                    </th>
                  </tr>
                </thead>
              </table>
              <div
                key="recipients-card"
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                <table className="table" id="recipients-body">
                  {expandView && (
                    <tbody>
                      {recipients?.map((recipient, index) => (
                        <tr className="tb-tnx-item">
                          <td
                            className="tb-tnx-info text-left"
                            id={`recipient-${index}`}
                          >
                            <span className="title">{recipient.name}</span>
                          </td>
                          <td
                            className="tb-tnx-info text-soft text-left"
                            id={`recipient-${index}-email`}
                          >
                            <span className="title">{recipient.email}</span>
                          </td>
                          <td
                            className="tb-tnx-info text-soft text-left"
                            id={`participant-${index}-blank`}
                          >
                            <div className="nk-block float-right">
                              <button
                                type="button"
                                className="btn btn-icon btn-trigger btn-tooltip"
                                onClick={() => {
                                  handleEditRecipient(recipient);
                                }}
                              >
                                <em className="icon ni ni-edit mr-1" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleSendAnnouncement()}
            >
              {t('SendNow')}
            </button>
          </div>
        </div>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default RetryAnnouncementModal;
