import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import html2pdf from 'html3pdf';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import { addDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';
import { Society, User } from 'types';
import replaceImageUrlsWithDataUrls from './replaceImageUrlsWithDataUrls';

type Props = {
  html: string;
  documentName: string;
  documentDescription: string;
  tagsData: any;
  society: Society;
  user: User;
};

export default async function createDocumentFromHtml({
  html,
  documentName,
  documentDescription,
  tagsData,
  society,
  user,
}: Props) {
  const documentUpdated = updateDocumentWithValues(html, tags(tagsData));
  const options = {
    filename: documentName,
    margin: [10, 10, 10, 10], // top, right, bottom, left
    pagebreak: { mode: 'avoid-all' },
    html2canvas: { scale: 2 },
    jsPDF: {
      unit: 'mm',
      format: 'a4',
      orientation: 'portrait',
    },
  };
  const htmlStringWithImages = await replaceImageUrlsWithDataUrls(
    documentUpdated?.html
  );
  const fileBlob = await html2pdf()
    .from(htmlStringWithImages)
    .set(options)
    .output('blob');

  const documentFile = new File([fileBlob], documentName, {
    type: 'application/pdf',
  });
  const documentData = {
    name: `${documentName}.pdf`,
    file: documentFile,
    fileType: fileTypes.PDF,
    size: documentFile.size,
    date: new Date(),
    lastAccess: new Date(),
    category: documentTypes.DOCUMENTS,
    author: user['_id'],
    society: society['_id'],
    isGenerated: true,
    description: documentDescription,
  };
  const resultDocumentId = await store.dispatch(addDocument(documentData));
  return resultDocumentId;
}
