/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';

import { dateFormat, dateFormatYear } from 'constants/formats';

import downloadFile from 'utils/downloadFile';
import { formatCurrencyDecimals } from 'utils/formats';

import MenuDots from 'modules/_shared/components/MenuDots';

function PartnerDividendsRow({ dividend, index, summaryView = false }) {
  const { t } = useTranslate();
  const {
    date,
    percent,
    totalAmount,
    amount,
    retention,
    grossAmount,
    documents,
  } = dividend;

  const showLoadedDocuments = (documents) => {
    if (documents?.length === 1) {
      return (
        <span
          className="btn btn-icon btn-trigger"
          onClick={() => {
            downloadFile({ documentId: documents[0] });
          }}
        >
          <em className="icon ni ni-file-download" />
        </span>
      );
    }
    if (documents?.length > 1) {
      const options = documents.map((doc, index) => ({
        icon: 'ni-file',
        text: doc?.name || `${t('Document')} #${index}`,
        download: true,
        documentId: doc,
      }));
      return (
        <span className="d-flex mt-2">
          <MenuDots
            menuOptions={options}
            direction="left"
            customIcon="ni-files"
          />
        </span>
      );
    }
    return <span>-</span>;
  };

  return (
    <tr>
      <td className="text-left vertical-align-middle d-none d-md-table-cell w-20px">
        <b>{index}</b>
      </td>
      <td className="text-left vertical-align-middle pl-2 pl-md-2">
        {format(new Date(date), summaryView ? dateFormatYear : dateFormat)}
      </td>
      <td className="text-right vertical-align-middle d-none d-md-table-cell">
        {formatCurrencyDecimals(totalAmount)}
      </td>
      <td className="text-right vertical-align-middle">
        {(percent || 0).toFixed(2)}%
      </td>
      <td className="text-right vertical-align-middle d-none d-md-table-cell">
        {formatCurrencyDecimals(grossAmount || 0)}
      </td>
      <td className="text-right vertical-align-middle d-none d-md-table-cell">
        {(retention || 0).toFixed(2)}%
      </td>
      <td className="text-right vertical-align-middle pr-2 pr-md-3">
        {formatCurrencyDecimals(amount || 0)}
      </td>
      {!summaryView && (
        <td
          className="text-center vertical-align-middle p-0"
          style={{ width: '20px !important' }}
        >
          {documents.length ? (
            showLoadedDocuments(documents)
          ) : (
            <span className="btn btn-icon disabled">
              <em className="icon ni ni-file-download" />
            </span>
          )}
        </td>
      )}
    </tr>
  );
}
export default PartnerDividendsRow;
