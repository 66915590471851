/* eslint-disable no-console */
import { v4 as uuidv4 } from 'uuid';
import { Partner } from 'types';

type DraftPartner = Record<string, any>;

type NewPartnerData = {
  _id: string;
  name: string;
  sharesCount: { actual: number; new: number };
  ndPercent?: number;
  fdPercent?: number;
  pfdPercent?: number;
  realContribution?: number;
};

type GetDataFromDraftProps = {
  tableRows: any[];
  partners: NewPartnerData[];
  allPartners: Partner[];
  updatedPartners: DraftPartner[];
};

const generateAlternativeId = () => uuidv4().replace(/-/g, '').substring(0, 24);

export const getDataFromDraft = ({
  tableRows,
  partners,
  updatedPartners,
  allPartners,
}: GetDataFromDraftProps) => {
  let newPartners: Record<string, any>[] = [...partners];
  let newSharesTotal = 0;

  const addedPartners: any = {};
  const newRows: Record<string, any> = { ...tableRows };

  updatedPartners.forEach((partner: DraftPartner) => {
    newSharesTotal += partner.shares.new;

    const currentPartnerId =
      partner.societyPartnerId || generateAlternativeId();

    if (partner?.holdingClass) {
      const key = `${currentPartnerId}-${partner.holdingClass}`;
      newRows[key] = newRows[key]
        ? newRows[key] + partner.shares.new
        : partner.shares.new;
    }

    if (!partner?.societyPartnerId) {
      const isPartnerAdded = addedPartners[currentPartnerId];

      if (!isPartnerAdded) {
        addedPartners[currentPartnerId] = true;
        newPartners.push({
          _id: currentPartnerId,
          name: partner.name,
          sharesCount: { actual: partner.shares.new, new: partner.shares.new },
          ndPercent: partner.NDTotal,
          fdPercent: partner.FDTotal,
          pfdPercent: partner.FDPotential,
          realContribution: partner?.realContribution || 0,
        });
      }
    } else {
      const otherPartners: Record<string, any>[] =
        newPartners.filter(
          (otherPartner) => otherPartner?.['_id'] !== partner.societyPartnerId
        ) || [];

      const currentPartner = allPartners.find(
        (otherPartner) => otherPartner?.['_id'] === partner.societyPartnerId
      );

      otherPartners.push({
        ...currentPartner,
        sharesCount: {
          actual: partner.shares?.total,
          new: partner.shares?.new,
        },
        ndPercent: partner.NDTotal,
        fdPercent: partner.FDTotal,
        pfdPercent: partner.FDPotential, // TODO: check why this is affecting to % Potential FD column
        realContribution: partner?.realContribution || 0,
      });

      newPartners = otherPartners;
    }
  });

  return { newRows, newPartners, newSharesTotal };
};
