import { Partner, Society } from 'types';

export const getPartnerFromSociety = (
  partnerId: string,
  society: Society
): Partner | undefined => {
  const partner = society?.partners?.find(
    (societyPartner) => societyPartner._id === partnerId
  );
  return partner;
};
