export default {
  customLanding: '/home/',
  dashboard: '/sociedad/',
  partners: '/socios/',
  portfolio: '/cuadro-portafolio/',
  drafts: '/borradores/',
  plans: '/beneficiarios-planes/',
  transactions: '/transacciones/',
  communications: '/comunicaciones/',
  boards: '/juntas/',
  documents: '/documentos/',
  societyConfiguration: '/perfil-sociedad/datos/',
};
