import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import DocumentModal from 'modules/documents/pages/Documents/documentModal';

type PartnerDocumentsSectionProps = {
  data: {
    title: string;
    subtitle: string;
    isAdmin: boolean;
    isReadOnly: boolean;
    userId: string;
    societyId: string;
    partnerId: string;
    rows: any[];
  };
};

const PartnerDocumentsSection: FC<PartnerDocumentsSectionProps> = ({
  data,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const {
    title,
    subtitle,
    isAdmin,
    isReadOnly,
    userId,
    societyId,
    partnerId,
    rows,
  } = data;

  return (
    <>
      <div className="card-inner">
        <div className="nk-block-head">
          <div className="nk-block-between g-3 d-flex flex-column flex-md-row">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">{title}</h4>
              <div className="nk-block-des">
                <p>{subtitle}</p>
              </div>
            </div>

            <div className="nk-block-head-content">
              {isAdmin && (
                <div className="nk-block float-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      dispatch(
                        setModal(
                          // @ts-ignore
                          <DocumentModal
                            userId={userId}
                            societyId={societyId}
                            partnerId={partnerId}
                          />
                        )
                      )
                    }
                    disabled={isReadOnly}
                  >
                    <em className="icon ni ni-upload mr-1" />
                    {t('UpDocument')}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {rows.length > 0 && (
          <div className="nk-block nk-block-lg">
            <div className="card card-bordered card-preview">
              <table className="nk-tb-list nk-tb-ulist">
                <thead>
                  <tr className="nk-tb-item nk-tb-head">
                    <th
                      className="nk-tb-col d-none d-md-table-cell"
                      scope="col"
                    >
                      {t('No')}
                    </th>
                    <th className="nk-tb-col" scope="col">
                      {t('Name')}
                    </th>
                    <th
                      className="nk-tb-col d-none d-md-table-cell"
                      scope="col"
                    >
                      {t('Date')}
                    </th>
                    <th className="nk-tb-col" scope="col">
                      {t('Doc')}
                    </th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PartnerDocumentsSection;
