import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import userTypes from 'constants/userTypes';
import { dateFormat } from 'constants/formats';

import MenuDots from 'modules/_shared/components/MenuDots';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

import { filterOptions, menuOptions } from '../utils';
import AdministratorAccessBadge from './AdministratorAccessBadge';

const formatDate = (date) => format(date && new Date(date), dateFormat);

function SocietyAdministratorRow({ administrator, society, user, index }) {
  const { isAdmin, isReadOnly } = useGetSocietySectionPermissions().data;

  const creationDate = formatDate(administrator.creationDate);

  const lastAccess = formatDate(administrator.lastAccess);

  const showMenuDots = isAdmin && !isReadOnly;

  const currentOptionsFiltered = filterOptions({
    user,
    society,
    menuOptions,
    administrator,
  });

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col tb-col-xl">
        <span>{index + 1}</span>
      </td>

      <td className="nk-tb-col">
        <span>{administrator.name}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{creationDate}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{lastAccess}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{administrator.email}</span>
      </td>

      <td className="nk-tb-col">
        <AdministratorAccessBadge
          administrator={administrator}
          society={society}
        />
      </td>

      {showMenuDots && (
        <td className="nk-tb-col  nk-tb-col-tools">
          <MenuDots
            menuOptions={currentOptionsFiltered}
            id={administrator._id}
            params={{
              data: administrator,
              userType: userTypes.ADMIN,
              societyId: society._id,
            }}
          />
        </td>
      )}
    </tr>
  );
}

export default SocietyAdministratorRow;
