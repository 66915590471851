/* eslint-disable no-underscore-dangle */
import Swal from 'sweetalert2';
import { store } from 'redux/store';
import i18n from 'i18n/config';
import { format } from 'date-fns';

import { rollbackAction } from 'modules/_shared/redux/modalsActions';
import { getUser } from 'modules/profile/redux/userActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import operationTypes from 'constants/operationTypes';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import { dateFormat } from 'constants/formats';
import eventTypes from 'constants/eventTypes';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import trackEvent from './trackEvent';
import { hasShowDetails } from './showDetails';

const excludedOperations = [
  operationTypes.ADD_BENEFICIARY,
  operationTypes.STOCK_OPTION,
  operationTypes.ADD_PARTNER,
  operationTypes.ADD_PARTNERS,
  operationTypes.ADD_HOLDING_CLASS,
];

const noRemovablesOperations = [
  operationTypes.ADD_BENEFICIARY,
  operationTypes.STOCK_OPTION,
];

const excludedEmbeddedOperations = [
  operationTypes.ADD_BENEFICIARY,
  operationTypes.STOCK_OPTION,
];

function isSubset(movementA, movementB) {
  const sharesCountA = movementA.shareTo - movementA.shareFrom + 1;
  const sharesCountB = movementB.shareTo - movementB.shareFrom + 1;
  return (
    sharesCountA < sharesCountB &&
    movementA.shareFrom >= movementB.shareFrom &&
    movementA.shareTo <= movementB.shareTo
  );
}

export function getRollbackMovements(movements) {
  if (!movements) return null;
  const { decrements, increments } = movements.reduce(
    (acc, curr) => {
      if (curr.movementType) acc.decrements.push(curr);
      else acc.increments.push(curr);
      return acc;
    },
    { decrements: [], increments: [] }
  );
  const result = decrements.map((mov) => {
    const { movementType, ...rest } = mov;
    if (
      decrements.some(
        (decrement) =>
          mov.shareClass === decrement.shareClass && isSubset(mov, decrement)
      )
    ) {
      return rest;
    }
    return mov;
  });
  return [...increments, ...result];
}

const getLastOperation = (society) => {
  const sortedOperations = society.operations
    .filter((op) => !noRemovablesOperations.includes(op?.operationType))
    .sort((a, b) => (new Date(a.date) > new Date(b.date) ? -1 : 1));

  const lastOperations = sortedOperations.filter(
    (op) =>
      new Date(op?.date).getTime() ===
      new Date(sortedOperations?.[0]?.date).getTime()
  );

  let result = lastOperations[0];

  for (let i = 1; i < lastOperations.length; i += 1) {
    if (lastOperations[i]?.operationType !== operationTypes.ADD_PARTNER)
      result = lastOperations[i];
  }

  if (hasShowDetails) {
    console.log('cclog', { sortedOperations, lastOperations, result }); // eslint-disable-line no-console
  }
  return result;
};

export function getPreviousNominalValue(society, operationId) {
  if (!society || !operationId) return null;

  const validOps = [operationTypes.CONSTITUTION, operationTypes.SPLIT];
  let nominalValue;

  const sortedOperations = society?.operations
    .filter((operation) => validOps.includes(operation.operationType))
    .sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));

  for (let i = 0; i < sortedOperations.length; i += 1) {
    if (sortedOperations[i]?.['_id'] !== operationId)
      nominalValue = sortedOperations[i]?.nominalValue || 0;
    else break;
  }
  return nominalValue;
}

const hasValidMovements = (operation) => {
  const { operationType } = operation;
  if (!operationType) return false;
  switch (operationType) {
    case operationTypes.SPLIT:
    case operationTypes.RENUMERATION:
    case operationTypes.RECLASSIFICATION:
      return operation?.movements?.some(
        (movement) => movement?.movementType === 'DECREMENT'
      );
    case operationTypes.ADD_PARTNERS:
    case operationTypes.ADD_PARTNER:
    case operationTypes.ADD_HOLDING_CLASS:
      return operation?.transactions?.length > 0;
    default:
      return !operation?.movements?.some(
        (movement) => !movement?.shareClass || !movement.partner
      );
  }
};

const executeRollback = (operations, society, user, mixpanel) => {
  if (!society || !operations?.length) return;
  store.dispatch(
    rollbackAction({
      operations,
      society: society['_id'],
    })
  );
  if (user?.['_id']) {
    store.dispatch(getUser(user?.['_id']));
  }
  const operationType = operations.find(
    (op) => !noRemovablesOperations.includes(op.operationType)
  )?.operationType;
  if (operationType && mixpanel && user) {
    trackEvent(mixpanel, eventTypes.ROLLBACK_OPERATION, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: society?._id,
      societyName: society?.name,
      operation: eventTypes.ROLLBACK_OPERATION,
      operationRollbacked: operationTypesRealNames[operationType],
    });
  }
};

export default async function rollbackOperation(params) {
  const { society, user, mixpanel } = params;
  const lastOperation = getLastOperation(society);
  const { operationType, date } = lastOperation;
  const formattedDate = format(new Date(date), dateFormat);
  const formattedOperation = operationTypesRealNames[operationType];

  Swal.fire({
    icon: 'warning',
    title:
      '<h4 class="nk-modal-title">¿Está seguro de que desea eliminar la última operación?</h4>',
    html: `<h5 class="text-primary">${formattedDate} | ${formattedOperation}</h5><br /><div class="caption-text">${i18n.t(
      'ThisProcedureCantUndo'
    )}</div>`,
    confirmButtonText: i18n.t('OkDelete'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
    cancelButtonText: i18n.t('Cancel'),
  }).then((result) => {
    if (result.isConfirmed) {
      if (!hasValidMovements(lastOperation)) {
        store.dispatch(addAlert(alertBodyTypes.ROLLBACK_DENIED));
        return () => {};
      }

      // Create rollback data
      const validOps = society.operations.filter(
        (operation) => !noRemovablesOperations.includes(operation.operationType)
      );
      const validOpsSorted = validOps.sort((a, b) =>
        new Date(a.date) > new Date(b.date) ? -1 : 1
      );
      const lastOps = validOpsSorted.filter(
        (op) =>
          new Date(op?.date).getTime() ===
          new Date(validOpsSorted?.[0]?.date).getTime()
      );
      const rollbackOps = lastOps.reduce((acc, operation) => {
        const { operationType } = operation;

        switch (operationType) {
          case operationTypes.ADD_PARTNERS:
          case operationTypes.ADD_PARTNER:
          case operationTypes.ADD_HOLDING_CLASS:
            acc.push({
              operationId: operation['_id'],
              operationType,
              transactions: operation.transactions,
              date: operation.date,
              user: user?.['_id'],
            });
            break;
          case operationTypes.SELL_PARTICIPATION:
          case operationTypes.CAPITAL_DECREASE:
          case operationTypes.HERITAGE:
          case operationTypes.DONATION:
            acc.push({
              operationId: operation['_id'],
              operationType,
              movements: getRollbackMovements(operation.movements),
              date: operation.date,
              user: user?.['_id'],
            });
            break;
          case operationTypes.SPLIT:
            acc.push({
              operationId: operation['_id'],
              operationType,
              nominalValue:
                getPreviousNominalValue(society, operation['_id']) ||
                operation?.nominalValue,
              movements: operation.movements,
              date: operation.date,
              user: user?.['_id'],
            });
            break;
          case operationTypes.DIVIDENDS_DISTRIBUTION:
            acc.push({
              operationId: operation['_id'],
              operationType,
              dividends: operation.dividends,
              date: operation.date,
              user: user?.['_id'],
            });
            break;
          default:
            acc.push({
              operationId: operation['_id'],
              operationType,
              movements: operation.movements,
              date: operation.date,
              user: user?.['_id'],
            });
            break;
        }
        return acc;
      }, []);

      const hasEmbeddedOperation = rollbackOps.some((operation) =>
        excludedEmbeddedOperations.includes(operation.operationType)
      );

      if (hasEmbeddedOperation) {
        Swal.fire({
          icon: 'question',
          title:
            '<h4 class="nk-modal-title">¿Desea eliminar los socios o clases añadidos dentro de la operación?</h4>',
          html: `<div class="caption-text">${i18n.t(
            'ThisProcedureCantUndo'
          )}</div>`,
          confirmButtonText: i18n.t('Yes'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showDenyButton: true,
          denyButtonText: i18n.t('Nope'),
        }).then((result) => {
          console.log({ result }); // eslint-disable-line no-console
          if (result.isConfirmed) {
            executeRollback(rollbackOps, society, user, mixpanel);
          } else if (result.isDenied) {
            const filteredRollbackOps = rollbackOps.filter(
              (op) => !excludedOperations.includes(op.operationType)
            );
            if (hasShowDetails()) {
              console.log({ filteredRollbackOps }); // eslint-disable-line no-console
            }
            executeRollback(filteredRollbackOps, society, user, mixpanel);
          }
        });
      } else {
        executeRollback(rollbackOps, society, user, mixpanel);
      }
    }
  });
}
