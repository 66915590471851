import finalProcesses from './finalProcesses';

export default [
  {
    name: 'Consolidar como aportación',
    value: finalProcesses.CONSOLIDATE,
  },
  {
    name: 'No Consolidar (pagar)',
    value: finalProcesses.TO_PAY,
  },
  {
    name: 'No se pagan',
    value: finalProcesses.NOT_TO_PAY,
  },
];
