/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getBoards } from 'modules/boards/redux/boardActions';

import { useGetBoardsSectionPermissions } from 'modules/boards/hooks/useGetBoardsSectionPermissions';
import BoardOrdersTable from './BoardOrdersTable';
import BoardVotesTable from './BoardVotesTable';
import getOrderVotesData from './BoardVotesTable/utils/getOrderVotesData';

const BoardParticipants = ({ board }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const votesTableRef = useRef();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const { isReadOnly } = useGetBoardsSectionPermissions().data;

  const [selectedOrder, setSelectedOrder] = useState('default_option');
  const [ordersVotesData, setOrdersVotesData] = useState([]);

  const [selectOnlyAssistants, setSelectOnlyAssistants] = useState(false);

  const handleSelectOnlyAssistants = () => {
    if (isReadOnly) return;
    setSelectOnlyAssistants(!selectOnlyAssistants);
  };

  const handleGetBoards = () => {
    if (isReadOnly) return;
    dispatch(getBoards(actualSociety['_id']));
  };

  useEffect(() => {
    if (selectedOrder !== 'default_option') {
      if (votesTableRef.current) {
        const containerPosition = votesTableRef.current.offsetTop;
        window.scrollTo({
          top: containerPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (board && actualSociety) {
      const result = [];
      board.orders.forEach((order) => {
        const orderVotesData = getOrderVotesData(
          order,
          board.participants,
          actualSociety
        );
        result.push(orderVotesData);
      });
      setOrdersVotesData(result);
    }
  }, [board, actualSociety]);

  return (
    <>
      {board?.orders?.length && ordersVotesData && (
        <div className="nk-block pt-4">
          <div className="card card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="sp-plan-head">
                    <h6 className="title">{t('Subjects')}</h6>
                  </div>
                </div>
              </div>

              <BoardOrdersTable
                board={board}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                ordersVotesData={ordersVotesData}
              />
            </div>
          </div>
        </div>
      )}

      <div className="nk-block pt-4" ref={votesTableRef}>
        <div className="card card-bordered">
          <div className="card-inner-group">
            {board.orders.length ? (
              <>
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="sp-plan-head">
                      <h6 className="title">{t('Voting')}</h6>
                    </div>
                    <div className="card-tools d-flex">
                      <select
                        className="form-control "
                        value={selectedOrder}
                        onChange={(event) =>
                          setSelectedOrder(event.target.value)
                        }
                      >
                        <option value="default_option">
                          {t('SelectVote')}
                        </option>
                        {board?.orders?.map((order, index) => (
                          <option value={order?.['_id']} key={order._id}>
                            {index + 1}.&nbsp;
                            {order.subject.length > 50
                              ? `${order.subject.substring(0, 50)}...`
                              : order.subject}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        className="btn btn-outline-light ml-2"
                        onClick={handleGetBoards}
                        disabled={isReadOnly}
                      >
                        <em className="icon ni ni-reload" />
                        <span>{t('CountVotes')}</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-light ml-2"
                        onClick={handleSelectOnlyAssistants}
                        disabled={isReadOnly}
                      >
                        {selectOnlyAssistants ? (
                          <>
                            <em className="icon ni ni-user-cross" />{' '}
                            <span>{t('CancelSelection')}</span>
                          </>
                        ) : (
                          <>
                            <em className="icon ni ni-user-check" />
                            <span>{t('SelectAttendees')}</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {selectedOrder !== 'default_option' && (
                  <div className="card-inner p-0">
                    <BoardVotesTable
                      board={board}
                      order={selectedOrder}
                      selectOnlyAssistants={selectOnlyAssistants}
                      setSelectOnlyAssistants={setSelectOnlyAssistants}
                    />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardParticipants;
