import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import { FixedShare } from '../types/Share';

type Props = {
  share: FixedShare;
  encumbranceName: string;
  encumbranceDate: string;
  encumbranceFrom: number;
  encumbranceTo: number;
  // eslint-disable-next-line no-unused-vars
  handleChangeEncumbranceName: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeEncumbranceDate: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeEncumbranceFrom: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeEncumbranceTo: (event: any) => void;
};

const ShareEncumbranceViewFormEncumbranceData: FC<Props> = ({
  share,
  encumbranceName,
  encumbranceDate,
  encumbranceFrom,
  encumbranceTo,
  handleChangeEncumbranceName,
  handleChangeEncumbranceDate,
  handleChangeEncumbranceFrom,
  handleChangeEncumbranceTo,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-name">
              {t('EncumbranceName')}
              {!encumbranceName && <span className="text-danger"> *</span>}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="encumbrance-name"
                placeholder={t('Name')}
                className="form-control form-control-md"
                value={encumbranceName}
                onChange={handleChangeEncumbranceName}
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-date">
              {t('EncumbranceDate')}
              {!encumbranceDate && <span className="text-danger"> *</span>}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                id="encumbrance-date"
                className="form-control form-control-md"
                value={encumbranceDate}
                onChange={handleChangeEncumbranceDate}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-from">
              {t('From')}
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id="encumbrance-from"
                min={share?.from}
                className="form-control form-control-md"
                value={encumbranceFrom}
                onChange={handleChangeEncumbranceFrom}
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-to">
              {t('To')}
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                max={share?.to}
                id="encumbrance-to"
                className="form-control form-control-md"
                value={encumbranceTo}
                onChange={handleChangeEncumbranceTo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareEncumbranceViewFormEncumbranceData;
