import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { Society } from 'types';
import i18n from 'i18n/config';

import { getPartnersViewData } from 'modules/society/pages/SocietyDashboard/getPartnersViewData';
import { getPartnersChartViewData } from 'modules/society/pages/SocietyDashboard/getPartnersChartViewData';
import { LegalDashboardData } from '../types';
import { mapAuditorsToLegalDashboard } from '../utils/mapAuditorsToLegalDashboard';
import { mapDirectorsToLegalDashboard } from '../utils/mapDirectorsToLegalDashboard';
import { mapRepresentativesToLegalDashboard } from '../utils/mapRepresentativesToLegalDashboard';
import { mapAdditionalToLegalDashboard } from '../utils/mapAdditionalToLegalDashboard';
import { mapCapitalToLegalDashboard } from '../utils/mapCapitalToLegalDashboard';
import { mapCommitteesToLegalDashboard } from '../utils/mapCommitteesToLegalDashboard';

const legalDashboardInitialState: LegalDashboardData = {
  summary: null,
  directors: [],
  auditors: [],
  representatives: [],
  committees: [],
  capital: null,
  partners: [],
  partnersChart: [],
};

export const useLegalDashboard = (translate: any) => {
  const dispatch = useDispatch();
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const committees = useSelector((state: State) => state.committees);

  const [data, setData] = useState<any>(legalDashboardInitialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const getPartnersView = async () => {
    const partnersViewData = await getPartnersViewData(
      actualSociety?._id,
      dispatch
    );
    setData((prev: LegalDashboardData) => ({
      ...prev,
      partners: partnersViewData,
    }));

    const partnersChartViewData = getPartnersChartViewData(partnersViewData);
    setData((prev: LegalDashboardData) => ({
      ...prev,
      partnersChart: partnersChartViewData,
    }));
  };

  const fetchLegalDashboardData = useCallback(
    async (society: Society) => {
      setIsLoading(true);

      try {
        await getPartnersView();

        setData((prev: LegalDashboardData) => ({
          ...prev,
          summary: mapAdditionalToLegalDashboard(society?.additional),
          auditors: mapAuditorsToLegalDashboard(society?.auditors, translate),
          directors: mapDirectorsToLegalDashboard(
            society?.directors,
            society,
            translate
          ),
          representatives: mapRepresentativesToLegalDashboard(
            society?.representatives,
            society?._id,
            translate
          ),
          committees: mapCommitteesToLegalDashboard(committees, translate),
          capital: mapCapitalToLegalDashboard(society, translate),
        }));
        setIsError(false);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    },
    [translate, committees] // eslint-disable-line
  );

  useEffect(() => {
    fetchLegalDashboardData(actualSociety);
  }, [actualSociety, committees, i18n.language]); // eslint-disable-line

  return {
    data,
    isLoading,
    isError,
  };
};
