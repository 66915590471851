import { type } from 'os';

type SharesRange = {
  sharesFrom: string;
  sharesTo: string;
};

const getSharesRangeFromDraft = (
  operation: Record<string, any>
): SharesRange => {
  // TODO: implement this function
  console.log('[getSharesRangeFromDraft]');
  return {
    sharesFrom: '',
    sharesTo: '',
  };
};

export default getSharesRangeFromDraft;
