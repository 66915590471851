import { useEffect, useState } from 'react';
import i18n from 'i18n/config';

import { SelectorOption } from 'types';

import memberStatus from '../constants/memberStatus';
import memberTypes from '../constants/memberTypes';
import conditions from '../constants/memberConditions';

export default function useGetSelectorsOptions() {
  const [memberStatusOptions, setMemberStatusOptions] = useState<
    SelectorOption[]
  >([]);
  const [memberTypeOptions, setMemberTypeOptions] = useState<SelectorOption[]>(
    []
  );
  const [memberConditionsOptions, setConditionsOptions] = useState<
    SelectorOption[]
  >([]);

  useEffect(() => {
    const statusOptions = memberStatus.map((status) => ({
      value: status.value,
      label: i18n.t(status.label),
    }));
    setMemberStatusOptions(statusOptions);

    const typeOptions = memberTypes.map((type) => ({
      value: type.value,
      label: i18n.t(type.label),
    }));
    setMemberTypeOptions(typeOptions);

    const conditionsOptions = conditions.map((condition) => ({
      value: condition.value,
      label: i18n.t(condition.label),
    }));
    setConditionsOptions(conditionsOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return {
    data: { memberStatusOptions, memberTypeOptions, memberConditionsOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
