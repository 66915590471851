/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

import { getActualSociety } from 'utils/filters';

import menuTypes from 'constants/menuTypes';
import sharesCertificateTemplate from 'constants/sharesCertificateTemplate';

import CertificateTemplateView from './CertificateTemplateView';
import NotificationView from './NotificationView';
import './SocietyAnnualValue.scss';
import ValueSharesView from './ValueSharesView';

function SocietyAnnualValue() {
  const { t } = useTranslate();
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);

  // Template view variables.
  const [sharesCertificateDocument, setSharesCertificateDocument] = useState();

  const getSharesCertificateTemplate = async () => {
    // Get the shares certificate template id.
    const sharesCertificateTemplateId =
      actualSociety?.sharesCertificateTemplate ||
      sharesCertificateTemplate[process.env.NODE_ENV];

    // Get the certificate template docuemnt.
    const document = await store.dispatch(
      getDocument(sharesCertificateTemplateId)
    );
    // Set the shares certificate template document.
    setSharesCertificateDocument(document);
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      // Get the shares certificate template document.
      getSharesCertificateTemplate();
    }
  }, [actualSociety]);

  useEffect(() => {
    // Set the pages.
    const viewPages = [
      <ValueSharesView
        key="value-shares-view"
        userId={user['_id']}
        societyId={actualSociety['_id']}
        annualValues={actualSociety?.annualValues}
      />,
      <CertificateTemplateView
        key="certificate-template-view"
        sharesCertificateDocument={sharesCertificateDocument}
        setShraesCertificateDocument={setSharesCertificateDocument}
        actualSociety={actualSociety}
      />,
      <NotificationView
        key="notification-view"
        actualSociety={actualSociety}
      />,
    ];

    setPages(viewPages);
  }, [actualSociety, sharesCertificateDocument]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety]);

  return (
    <div className="pt-2">
      <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
        <li className="nav-item">
          <p
            id="value-shares-tab"
            className={`nav-link ${page === 0 ? 'active' : ''}`}
            onClick={() => setPage(0)}
          >
            <em className="icon ni ni-coins" />
            <span>{t('ValueShares')}</span>
          </p>
        </li>
        <li className="nav-item">
          <p
            id="certificate-template-tab"
            className={`nav-link ${page === 1 ? 'active' : ''}`}
            onClick={() => setPage(1)}
          >
            <em className="icon ni ni-file-code" />
            <span>{t('CertificateTemplate')}</span>
          </p>
        </li>
        <li className="nav-item">
          <p
            id="notify-partners-tab"
            className={`nav-link ${page === 2 ? 'active' : ''}`}
            onClick={() => setPage(2)}
          >
            <em className="icon ni ni-send" />
            <span>{t('NotifyPartners')}</span>
          </p>
        </li>
      </ul>
      {pages[page]}
    </div>
  );
}

export default SocietyAnnualValue;
