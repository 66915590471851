import spinner from 'assets/images/css-loading-white.png';

import './CustomLoading.scss';

const CustomLoading = () => (
  <div className="loading-overlay">
    <div className="loading-wrapper d-flex justify-content-center align-items-center">
      <div className="loading-pulse">
        <img src={spinner} alt="Processing..." srcSet="" />
      </div>
    </div>
  </div>
);

export default CustomLoading;
