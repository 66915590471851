export default {
  HEADER: 'HEADER',
  SECTION: 'SECTION',
  FOLDER: 'FOLDER',
};

export const dashboardEditTitle = {
  HEADER: 'EditDashboardHeader',
  SECTION: 'EditDashboardSection',
};
