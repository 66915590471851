import isValidEmail from 'utils/isValidEmail';

type DataForPartnerProps = {
  currentEmail: string;
  emails: string[];
  categories: string[];
  cif: string;
  partnerCategory: string;
  sindication: string;
  naturalPerson: boolean;
  register: string;
  section: string;
  sheet: string;
  employeeNumber: number;
  taxAddress: string;
  bankAccountNumber: string;
  civilStatusSelected: string;
  representativeName: string;
  representativeEmail: string;
  representativeInitDate: Date;
  representativeEndDate: Date;
  representativeType: string;
  representativeStatus: string;
  representativeNif: string;
  representativeNationality: string;
  representativeAddress: string;
};

const isEmailValid = (email: string) => email !== '' && isValidEmail(email);

const isEmailUnique = (email: string, emails: string[]) =>
  email !== emails[emails.length - 1];

const filterValidEmails = (emails: string[]) =>
  emails.filter((email) => email !== '' && isValidEmail(email));

const mapCategories = (emails: string[], categories: string[]) =>
  emails.map((_, index) => categories[index] || null);

export const createPartnerData = (data: DataForPartnerProps) => {
  const {
    currentEmail,
    emails,
    categories,
    cif,
    partnerCategory,
    sindication,
    naturalPerson,
    register,
    section,
    sheet,
    employeeNumber,
    taxAddress,
    bankAccountNumber,
    civilStatusSelected,
    representativeName,
    representativeEmail,
    representativeInitDate,
    representativeEndDate,
    representativeType,
    representativeStatus,
    representativeNif,
    representativeNationality,
    representativeAddress,
  } = data;

  const newData: any = {};

  const isCurrentEmailValid = isEmailValid(currentEmail);
  const isCurrentEmailUnique = isEmailUnique(currentEmail, emails);

  if (isCurrentEmailValid && isCurrentEmailUnique) {
    emails.push(currentEmail);
  }

  const newEmails = filterValidEmails(emails);

  const newCategories = mapCategories(newEmails, categories);

  newData['cif'] = cif;
  newData['category'] = partnerCategory || null;
  newData['sindication'] = sindication || null;
  newData['isNaturalPerson'] = naturalPerson;
  newData['employeeNumber'] = employeeNumber;
  newData['taxAddress'] = taxAddress;
  newData['bankAccountNumber'] = bankAccountNumber;

  if (naturalPerson) {
    newData['civilStatus'] = civilStatusSelected;
  } else {
    newData['legalInfo'] = { register, section, sheet };
    newData['representative'] = {
      name: representativeName,
      email: representativeEmail,
      initDate: representativeInitDate,
      endDate: representativeEndDate,
      authority: representativeType,
      status: representativeStatus,
      nif: representativeNif,
      nationality: representativeNationality,
      address: representativeAddress,
    };
  }

  return {
    emails: newEmails,
    categories: newCategories,
    data: newData,
  };
};

export const createBeneficiaryData = () => {};

export const createInvestorData = () => {};
