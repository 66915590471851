/* eslint-disable react/require-default-props */
import { useTranslate } from 'hooks/useTranslate';
import Swal from 'sweetalert2';

import { PDF_TYPE_FILE_ALLOWED } from 'constants/defaultConstants';
import { FC } from 'react';

type UploadDocumentProps = {
  label: string;
  inputLabel?: string;
  file: File | undefined;
  setFile: any;
  accept?: string;
  idIndex?: number;
};

const UploadDocument: FC<UploadDocumentProps> = ({
  label,
  inputLabel,
  file,
  setFile,
  accept = PDF_TYPE_FILE_ALLOWED,
  idIndex = 0,
}) => {
  const { t } = useTranslate();

  const handleDrop = (e: any) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];

    if (files.length > 1) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('MustUploadOneDocument')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t(
          'WantUploadThisDocument'
        )} </h4>`,
        confirmButtonText: t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setFile(files[0]);
        }
      });
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleChooseDocument = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];

    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('WantUploadThisDocument')} </h4>`,
      confirmButtonText: t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setFile(file);
      }
    });
  };

  return (
    <div
      className="form-group"
      style={{
        zIndex: 0,
      }}
    >
      <label
        className="form-label"
        htmlFor={`drag-drop-input-component-id-${idIndex}`}
      >
        {t(label)}
      </label>
      <div
        className="nk-block-head-content"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          id={`drag-drop-input-component-id-${idIndex}`}
          key={`drag-drop-input-component-key-${idIndex}`}
          style={{ width: '90%' }}
        >
          <div className="card card-bordered sp-plan">
            <div
              className="sp-plan-action card-inner"
              style={{
                height: '10px',
              }}
            >
              <div>
                <span className="text-center w-300px d-block text-ellipsis">
                  {file?.name || inputLabel || t('DragDocumentToUpload')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <input
          id={`file-input-component-id-${idIndex}`}
          type="file"
          key={`file-input-component-key-${idIndex}`}
          style={{ display: 'none' }}
          onChange={handleChooseDocument}
          accept={accept}
        />
        <label
          htmlFor={`file-input-component-id-${idIndex}`}
          className="btn btn-light"
          style={{ marginBottom: '0px' }}
        >
          {t('Choose')}
        </label>
      </div>
    </div>
  );
};

export default UploadDocument;
