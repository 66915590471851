import PartnerCategoryModal from 'modules/_shared/components/Modals/PartnerCategory';

import i18n from 'i18n/config';

export default [
  {
    icon: 'ni-book-read',
    text: i18n.t('NewCategory'),
    url: PartnerCategoryModal,
  },
];
