import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';

import employeesSource from 'constants/employeesSource';
import actionTypes from 'redux/actions/actionTypes';
import { decryptResponse } from 'utils/token';
import axios from 'interceptors/axios';
import { dispatchError } from 'utils/dispatchError';

const URL_EMPLOYEES = process.env.REACT_APP_FACTORIAL_EMPLOYEES_URL;
const URL_CONTRACTS = process.env.REACT_APP_FACTORIAL_CONTRACTS_URL;
const URL_TEAMS = process.env.REACT_APP_FACTORIAL_TEAMS_URL;

const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    'x-api-key': process.env.REACT_APP_FACTORIAL_KEY,
  },
};

export const getContractsFromFactorial = () => async (dispatch) => {
  let contracts = [];
  await fetch(URL_CONTRACTS, options)
    .then((response) => response.json())
    .then((response) => {
      contracts = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES');
    });
  return contracts;
};

export const getTeamsFromFactorial = () => async (dispatch) => {
  let teams = [];
  await fetch(URL_TEAMS, options)
    .then((response) => response.json())
    .then((response) => {
      teams = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES');
    });
  return teams;
};

export const getEmployeesFromFactorial = () => async (dispatch) => {
  let employees = [];

  await fetch(URL_EMPLOYEES, options)
    .then((response) => response.json())
    .then((response) => {
      employees = response;
      return response;
    })
    .catch(() => {
      throw new Error('ERROR_GETTING_EMPLOYEES');
    });

  return employees;
};

const parseFactorialEmployee = (employee) => {
  const employeeTeams =
    employee?.teams.map((team) => ({
      externalId: team.id,
      name: team.name,
      avatar: team.avatar,
      description: team.description,
      companyId: team.company_id,
      employees: team.employee_ids,
      leads: team.lead_ids,
    })) || [];

  const employeeContracts =
    employee?.contracts.map((contract) => ({
      externalId: contract.id,
      employeeId: contract.employee_id,
      jobTitle: contract.job_title,
      role: contract.role,
      level: contract.level,
      startDate: contract.starts_on,
      endDate: contract.ends_on,
      effectiveDate: contract.effective_on,
      salaryAmount: contract.salary_amount,
      salaryFrequency: contract.salary_frequency,
    })) || [];

  return {
    id: employee.id,
    firstName: employee.first_name || '',
    lastName: employee.last_name || '',
    fullName: employee.full_name || '',
    email: employee.email || '',
    birthDate: employee.birthday_on || null,
    gender: employee.gender || '',
    nationality: employee.nationality || '',
    createdAt: employee.created_at || null,
    updatedAt: employee.updated_at || null,
    cif: employee.identifier || '',
    cifType: employee.identifier_type || '',
    socialSecurityNumber: employee.social_security_number || '',
    companyId: employee.company_id || null,
    companyCif: employee.company_identifier || '',
    managerId: employee.manager_id || null,
    legalEntityId: employee.legal_entity_id || null,
    timeOffManagerId: employee.timeoff_manager_id || null,
    timeOffPolicyId: employee.timeoff_policy_id || null,
    terminationDate: employee.terminated_on || null,
    terminationReason: employee.termination_reason || '',
    terminationObservations: employee.termination_observations || '',
    address: {
      line1: employee.address_line_1 || '',
      line2: employee.address_line_2 || '',
      zip: employee.postal_code || '',
      city: employee.city || '',
      state: employee.state || '',
      country: employee.country || '',
    },
    teams: employeeTeams,
    contracts: employeeContracts,
  };
};

export const getEmployeesInfo = (source) => async (dispatch) => {
  try {
    if (source === employeesSource.FACTORIAL) {
      const teams = await dispatch(getTeamsFromFactorial());
      const contracts = await dispatch(getContractsFromFactorial());
      const employees = await dispatch(getEmployeesFromFactorial());

      const employeesWithContractsAndTeams = employees.map((employee) => {
        const employeeContracts = contracts.filter(
          (contract) => contract.employee_id === employee.id
        );

        const employeeTeams = teams.filter((team) =>
          team.employee_ids.includes(employee.id)
        );

        return parseFactorialEmployee({
          ...employee,
          contracts: employeeContracts,
          teams: employeeTeams,
        });
      });

      return employeesWithContractsAndTeams;
    }
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const getSocietyEmployeesPlans = (societyId) => async (dispatch) => {
  try {
    const query = societyId ? { params: { societyId } } : { params: {} };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/employeesPlans`,
      query
    );

    const decodedData = await decryptResponse(data);

    dispatch({
      type: actionTypes.GET_EMPLOYEES_PLANS,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateEmployeesPlan =
  (employeesPlanId, updateEmployeesPlanData) => async (dispatch) => {
    const body = {
      _id: employeesPlanId,
      ...updateEmployeesPlanData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/employeesPlans`,
        body
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: actionTypes.UPDATE_EMPLOYEES_PLAN,
        employeesPlan: decodedData,
      });
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const saveEmployees =
  (employees, source, societyId) => async (dispatch) => {
    const employeesForUpload = employees.map((employee) => ({
      ...employee,
      source,
      society: societyId,
      importationDate: Date.now(),
    }));

    const body = {
      societyId,
      newEmployees: employeesForUpload.slice(0, 5),
      existentEmployees: [],
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employees`,
        body
      );

      const decodedData = await decryptResponse(response);

      dispatch({
        type: actionTypes.GET_EMPLOYEES,
        data: decodedData.data,
      });

      if (decodedData && decodedData.status === 200) {
        dispatch(addAlert(alertBodyTypes.EMPLOYEES_IMPORTED_SUCCESSFULLY));
      }
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
