import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  societyId: string;
};

const TransactionsMenu: FC<Props> = ({ societyId }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="5">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-exchange" />
            </span>
            <span className="nk-menu-text">{t('Transactions')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="5">
        <ul className="nk-menu-sub">
          <MenuItem
            linkTo={`/transacciones/${societyId}`}
            text="TransactionsList"
          />
          <MenuItem
            linkTo={`/tender-offers/${societyId}`}
            text="TenderOffers"
          />
          <MenuItem
            linkTo={`/tender-offers-shares/${societyId}`}
            text="TenderOffersShares"
          />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default TransactionsMenu;
