/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslate } from 'hooks/useTranslate';

import {
  currencyFormat,
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';

export const cellFormats = {
  NUMBER_FORMAT: 'numberFormat',
  NUMBER_FORMAT_DECIMALS: 'numberFormatDecimals',
  CURRENCY_FORMAT: 'currencyFormat',
  CURRENCY_FORMAT_DECIMALS: 'currencyFormatDecimals',
};

export type CellFormat =
  | 'numberFormat'
  | 'numberFormatDecimals'
  | 'currencyFormat'
  | 'currencyFormatDecimals';

const formats = {
  [cellFormats.NUMBER_FORMAT]: numberFormat,
  [cellFormats.NUMBER_FORMAT_DECIMALS]: numberFormatDecimals,
  [cellFormats.CURRENCY_FORMAT]: currencyFormat,
  [cellFormats.CURRENCY_FORMAT_DECIMALS]: currencyFormatDecimals,
};

type WidgetTableExtendedCellProps = {
  value: string | number;
  format?: CellFormat;
  cellClass?: string;
  textClass?: string;
};

const WidgetTableExtendedCell: FC<WidgetTableExtendedCellProps> = ({
  value,
  format,
  cellClass,
  textClass,
}) => {
  const { t } = useTranslate();

  const isNumber = typeof value === 'number';
  const isString = typeof value === 'string';
  const isTranslated = true;

  const currentFormat = formats?.[format || cellFormats.NUMBER_FORMAT];

  const cellClassName = `nk-tb-col text-right ${cellClass || ''}`;
  const textClassName = `tb-sub ${textClass || ''}`;

  return (
    <div className={cellClassName}>
      <span className={textClassName}>
        {isNumber && (
          <NumberFormat value={value} displayType="text" {...currentFormat} />
        )}

        {isString && (
          <span className="text-left">{isTranslated ? t(value) : value}</span>
        )}
      </span>
    </div>
  );
};

export default WidgetTableExtendedCell;
