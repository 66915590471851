/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';
import { setMenu } from 'modules/_shared/redux/menuActions';

import {
  getTenderOffers,
  updateTenderOffer,
} from 'modules/transactions/redux/tenderOfferActions';
import { getActualSociety } from 'utils/filters';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import TenderOfferRow from 'modules/transactions/components/TenderOfferRow';

import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';

import { tenderStatusTypes } from 'utils/tenderOfferTypes';

import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import TenderOfferSharesRow from 'modules/transactions/components/TenderOfferSellRow';

import { useTranslate } from 'hooks/useTranslate';
import { getTenderOffersShares } from 'modules/transactions/redux/tenderOffersSharesActions';
import { useGetTransactionsSectionPermissions } from 'modules/transactions/hooks/useGetTransactionsSectionPermissions';
import menuOptions from './menuOptions';
import TenderOfferBanner from './TenderOfferBanner';

const TenderOfferList = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { isAdmin, isReadOnly } = useGetTransactionsSectionPermissions().data;

  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const tenderOffers = useSelector((state) => state.tenderOffers);
  const tenderOffersShares = useSelector((state) =>
    state.tenderOffersShares.filter((offer) => offer?.type === 'BUY')
  );
  const partners = useSelector(
    (state) => state.society?.actualSociety?.partners
  );
  const beneficiaries = useSelector(
    (state) => state.society?.actualSociety?.beneficiaries
  );
  const holdingClasses = useSelector((state) => state.holdingClasses);
  const phantomPlans = useSelector((state) =>
    state.plans?.filter((plan) => plan.planType === 'PHANTOM_SHARE')
  );
  const stockOptionsPlans = useSelector((state) =>
    state.plans?.filter((plan) => plan.planType === 'STOCK_OPTION')
  );
  const holdingsAndPlans = useSelector((state) =>
    state.holdingClasses?.concat(state.plans)
  );
  const partnersAndBeneficiaries = useSelector((state) =>
    state.society?.actualSociety?.partners?.concat(
      state.society?.actualSociety?.beneficiaries
    )
  );

  const [tenderOffersUpdated, setTenderOffersUpdated] = useState(false);

  const availableOptions = useMemo(
    () => ({
      PARTNERS: {
        SOME_GROUPS: holdingClasses,
        SOME_MEMBERS: partners,
      },
      BENEFICIARIES_PHANTOM_SHARES: {
        SOME_GROUPS: phantomPlans,
        SOME_MEMBERS: beneficiaries,
      },
      BENEFICIARIES_STOCK_OPTIONS: {
        SOME_GROUPS: stockOptionsPlans,
        SOME_MEMBERS: beneficiaries,
      },
      ALL_MEMBERS: {
        SOME_GROUPS: holdingsAndPlans,
        SOME_MEMBERS: partnersAndBeneficiaries,
      },
    }),
    [
      holdingClasses,
      partners,
      phantomPlans,
      beneficiaries,
      stockOptionsPlans,
      partnersAndBeneficiaries,
      holdingsAndPlans,
    ]
  );

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getTenderOffers(societyId));
    dispatch(getTenderOffersShares(societyId));
  };

  const getSocietyValues = async () => {
    dispatch(getStockPlans(societyId));
    dispatch(getHoldingClasses(societyId));
  };

  const updateTenderStatusIfOutdated = async (tenders) => {
    const today = new Date();

    tenders.forEach((tender) => {
      if (
        today > new Date(tender.endDate) &&
        tender.status === tenderStatusTypes.ACTIVE.value
      ) {
        dispatch(
          updateTenderOffer(tender['_id'], {
            status: tenderStatusTypes.FINISHED.value,
          })
        );
      }
    });
    setTenderOffersUpdated(true);
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
      getSocietyValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (!tenderOffers || tenderOffersUpdated) return;

    updateTenderStatusIfOutdated(tenderOffers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenderOffers]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('TenderOffers')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>{t('TenderOffersList')}</p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    {isAdmin && (
                      <ul className="nk-block-tools g-3">
                        <li>
                          <OperationMenuDots
                            menuOptions={menuOptions()}
                            title={t('ConfigureOffer')}
                            id="TENDER_OFFER"
                            disabled={isReadOnly}
                          />
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(tenderOffers?.length > 0 || tenderOffersShares?.length > 0) &&
            [...tenderOffers, ...tenderOffersShares]
              ?.sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
              ?.map((offer) =>
                offer?.type === 'BUY' ? (
                  <TenderOfferSharesRow key={offer._id} data={{ ...offer }} />
                ) : (
                  <TenderOfferRow
                    key={offer._id}
                    data={{ ...offer }}
                    plans={plans}
                    actualSociety={actualSociety}
                    availableOptions={availableOptions}
                  />
                )
              )}

          <TenderOfferBanner />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default TenderOfferList;
