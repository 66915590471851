import { Society } from 'types';
import { formatCurrency, formatNumber } from 'utils/formats';
import { TranslateFn } from 'hooks/useTranslate';
import { LegalDashboardCapital } from '../types/LegalDashboard';

export const mapCapitalToLegalDashboard = (
  society: Society,
  translate: TranslateFn
): LegalDashboardCapital => {
  const shares = Number(
    society.sharesCount?.actual +
      society.sharesCount?.future +
      society?.sharesCount?.drafts
  );
  const shareValue = society?.nominalValue || 0;

  const capital = shares * shareValue;

  const description = translate('ShareCapitalDashboardDescription', {
    capital: formatCurrency(capital),
    shares: formatNumber(shares),
    shareValue: formatCurrency(shareValue),
  });

  return {
    capital,
    shares,
    shareValue,
    description,
  };
};
