import { SocietyAuditor } from 'types';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { WidgetLegalTableData } from '../components/WidgetLegalTableExtended';
import { createTermFromDates } from './createTermFromDates';

export const mapAuditorsToLegalDashboard = (
  auditors: SocietyAuditor[],
  translate: any
): WidgetLegalTableData[] =>
  auditors.map((auditor) => ({
    name: auditor.socialDenomination,
    image: auditor?.image || BLANK_PROFILE_PICTURE,
    value1: auditor.auditorStatus,
    value2: createTermFromDates(auditor?.initDate, auditor?.endDate, translate),
    link: '',
  }));
