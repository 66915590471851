export const tenderOfferSharesStatus = {
  DRAFT: 'DRAFT',
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
  FINISHED: 'FINISHED',
  REJECTED: 'REJECTED',
};

export const tenderOfferSharesStatusTypes = {
  DRAFT: { value: 'DRAFT', label: 'Draft', style: 'badge-primary' },
  ACTIVE: { value: 'ACTIVE', label: 'Active', style: 'badge-success' },
  CANCELED: { value: 'CANCELED', label: 'Canceled', style: 'badge-danger' },
  FINISHED: { value: 'FINISHED', label: 'Finished', style: 'badge-secondary' },
  REJECTED: { value: 'REJECTED', label: 'Rejected', style: 'badge-danger' },
};

export const tenderOfferSharesParticipantStatus = {
  NOTIFIED: 'NOTIFIED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
};

export const tenderOfferSharesParticipantStatusTypes = {
  NOTIFIED: { value: 'NOTIFIED', label: 'Notified', style: 'badge-primary' },
  ACCEPTED: { value: 'ACCEPTED', label: 'Accepted', style: 'badge-success' },
  REJECTED: { value: 'REJECTED', label: 'Rejected', style: 'badge-danger' },
  CANCELED: { value: 'CANCELED', label: 'Canceled', style: 'badge-danger' },
};

export const tenderOfferSharesPriceTypes = {
  PRICE_BY_SHARE: 'PRICE_BY_SHARE',
  PRICE_BY_SOCIETY: 'PRICE_BY_SOCIETY',
};

export const tenderOfferSharesTypes = {
  BUY: 'BUY',
  SELL: 'SELL',
};
