import React, { FC } from 'react';
import { formatNumberDecimals } from 'utils/formats';

const styles = {
  title: {
    color: 'rgb(107,114,128)',
    height: '20px',
    lineHeight: '20px',
  },
  value: {
    fontSize: '30px',
  },
  text: {
    fontSize: '12px',
  },
  card: {
    borderRadius: '8px',
    height: '140px',
    overflow: 'hidden',
  },
  cardContent: {
    lineHeight: '1em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

type CardBoardAttendanceProps = {
  title: string;
  value: number;
  text?: string;
  secondaryText?: string;
};

const CardBoardAttendance: FC<CardBoardAttendanceProps> = ({
  title,
  value,
  text,
  secondaryText,
}) => (
  <div className="card card-bordered mt-0 p-3 flex-1 w-100" style={styles.card}>
    <div className="" style={styles.cardContent}>
      <p className="text-gray mb-1" style={styles.title}>
        {title}
      </p>
      <div className="flex w-full flex-row justify-start align-items-baseline">
        <h4 className="fw-medium mb-0" style={styles.value}>
          {formatNumberDecimals(value)}%
        </h4>
      </div>
      <div className="mt-2">
        <p className="text-gray mb-1" style={styles.text}>
          {text || ''}
        </p>
        <p className="text-gray" style={styles.text}>
          {secondaryText || ''}
        </p>
      </div>
    </div>
  </div>
);
export default CardBoardAttendance;
