import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getActualSociety } from 'utils/filters';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { useGetPages } from 'modules/administratives/pages/Members/hook/useGetPages';
import { useGetMenu } from '../../hooks/useGetMenu';
import MembersPageHeader from './components/MembersPageHeader';

function Members() {
  const { societyId } = useParams();

  const user = useSelector((state: any) => state.user);
  const actualSociety = useSelector(
    (state: any) => state.society?.actualSociety
  );

  const pagesData = useGetPages();
  const { page, pages } = pagesData.data;
  const { setPage } = pagesData.actions;

  useGetMenu();

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    if (user && societyId) getActualSociety(user, societyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  return actualSociety ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <MembersPageHeader
          societyName={actualSociety?.name}
          page={page}
          handleChangePage={handleChangePage}
        />

        {pages ? pages[page] : null}
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default Members;
