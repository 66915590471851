import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import { dateFormat } from 'constants/formats';
import { formatNumber, formatNumberDecimals } from 'utils/formats';

interface TenderOfferBuySharesInvoiceProps {
  data: {
    partner: Record<string, any>;
    actualSociety: Record<string, any>;
    sellerName: string;
    sharesClass: string;
    sharesPrice: number;
    totalShares: number;
    totalAmount: number;
  };
}

const TenderOfferBuySharesInvoice: FC<TenderOfferBuySharesInvoiceProps> = ({
  data,
}) => {
  const { t } = useTranslate();

  const {
    partner,
    actualSociety,
    sellerName,
    sharesClass,
    sharesPrice,
    totalShares,
    totalAmount,
  } = data;

  return (
    <div className="invoice-wrap text-left">
      <>
        <div className="invoice-head">
          <div className="invoice-contact col-6">
            <span className="overline-title">{t('Buyer')}</span>

            <div className="invoice-contact-info">
              <h5 className="title">{partner.name}</h5>
            </div>
          </div>

          <div className="invoice-contact col-6">
            <span className="overline-title">{t('SharesOf')}</span>

            <h5 className="title">{sellerName}</h5>
          </div>
        </div>

        <div className="invoice-head mt-2">
          <div className="invoice-contact col-6">
            <span className="overline-title">{t('OperationDate')}</span>

            <div className="invoice-contact-info mt-2">
              <ul className="list-plain d-flex flex-column">
                <li className="d-inline-flex">
                  <em className="icon ni ni-calendar" />
                  <span>{format(new Date(), dateFormat)}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="invoice-contact col-6">
            <span className="overline-title">{t('Company')}</span>

            <div className="invoice-contact-info mt-2">
              <ul className="list-plain d-flex flex-column">
                <li className="d-inline-flex">
                  <em className="icon ni ni-building" />
                  <span>{actualSociety?.name || '-'}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="invoice-bills">
          <div className="table-responsive">
            <table className="table table-striped" style={{ minWidth: 0 }}>
              <thead>
                <tr>
                  <th className="text-center" style={{ width: '5%' }}>
                    Nº
                  </th>
                  <th className="text-left" style={{ width: '35%' }}>
                    {t('Class')}
                  </th>
                  <th className="text-right" style={{ width: '20%' }}>
                    {t('Nº Part.')}
                  </th>
                  <th className="text-right" style={{ width: '20%' }}>
                    {t('Price/Share')}
                  </th>
                  <th className="text-right" style={{ width: '20%' }}>
                    {t('Total')}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="text-center">1</td>
                  <td className="text-left">{sharesClass}</td>
                  <td className="text-right">{formatNumber(totalShares)}</td>
                  <td className="text-right">
                    {formatNumberDecimals(sharesPrice)}€
                  </td>
                  <td className="text-right">
                    {formatNumberDecimals(totalAmount)}€
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={2} />
                  <td className="text-right">{t('Subtotal')}</td>
                  <td className="text-right" colSpan={2}>
                    {formatNumberDecimals(totalAmount)}€
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} />
                  <td className="text-right">{t('Total')}</td>
                  <td className="text-right" colSpan={2}>
                    {formatNumberDecimals(totalAmount)}€
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </>
    </div>
  );
};

export default TenderOfferBuySharesInvoice;
