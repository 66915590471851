export const tenderEligibleTypes = {
  PARTNERS: 'PARTNERS',
  BENEFICIARIES_PHANTOM_SHARES: 'BENEFICIARIES_PHANTOM_SHARES',
  BENEFICIARIES_STOCK_OPTIONS: 'BENEFICIARIES_STOCK_OPTIONS',
  ALL_MEMBERS: 'ALL_MEMBERS',
};

export const tenderStatusTypes = {
  DRAFT: { value: 'DRAFT', label: 'Draft', style: 'badge-primary' },
  ACTIVE: { value: 'ACTIVE', label: 'Active', style: 'badge-success' },
  CANCELED: { value: 'CANCELED', label: 'Canceled', style: 'badge-danger' },
  FINISHED: { value: 'FINISHED', label: 'Finished', style: 'badge-secondary' },
  REJECTED: { value: 'REJECTED', label: 'Rejected', style: 'badge-danger' },
};

export const tenderParticipantStatusTypes = {
  NOTIFIED: { value: 'NOTIFIED', label: 'Notified', style: 'badge-primary' },
  ACCEPTED: { value: 'ACCEPTED', label: 'Accepted', style: 'badge-success' },
  REJECTED: { value: 'REJECTED', label: 'Rejected', style: 'badge-danger' },
  CANCELED: { value: 'CANCELED', label: 'Canceled', style: 'badge-danger' },
};

export const eligiblesSubgroupTypes = {
  ALL: 'ALL',
  SOME_GROUPS: 'SOME_GROUPS',
  SOME_MEMBERS: 'SOME_MEMBERS',
};

export const tenderPriceTypes = {
  PRICE_BY_SHARE: 'PRICE_BY_SHARE',
  PRICE_BY_SOCIETY: 'PRICE_BY_SOCIETY',
};

export const tenderOfferParticipantStatus = {
  NOTIFIED: 'NOTIFIED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
};
