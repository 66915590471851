/* eslint-disable no-param-reassign */
import { store } from 'redux/store';
import { addAlert } from 'modules/_shared/redux/alertActions';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

function addDocxExtension(text) {
  if (!text.includes('.docx')) {
    text += '.docx';
  }
  return text;
}

function convertTables(html) {
  const container = document.createElement('div');
  container.innerHTML = html;
  // Convert HTML tables to Word format
  const tables = container.querySelectorAll('table');
  tables.forEach((table) => {
    table.innerHTML = table.outerHTML
      .replace(/<thead/g, '<w:tblHeader')
      .replace(/thead>/g, 'tblHeader>')
      .replace(/<tbody/g, '<w:tbl')
      .replace(/tbody>/g, 'w:tbl>');
  });

  return container.innerHTML;
}
async function downloadFile(htmlString, fileName) {
  const name = addDocxExtension(fileName);
  try {
    const convertedHtml = convertTables(htmlString);
    const preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' <head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    const postHtml = '</body></html>';
    const html = preHtml + convertedHtml + postHtml;

    const blob = new Blob(['\ufeff', html], {
      type: 'application/msword',
    });

    // Specify link url
    const url = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(
      html
    )}`;

    // Create download link element
    const downloadLink = document.createElement('a');

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, name);
    } else {
      // Create a link to the file
      downloadLink.href = url;

      // Setting the file name
      downloadLink.download = name;

      // Triggering the function
      downloadLink.click();
    }

    document.body.removeChild(downloadLink);
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes.ERROR_DOWNLOADING_DOCUMENT));
  }
}

export default downloadFile;
