/* eslint-disable no-case-declarations */
import initialState from 'redux/initialState';
import allActionTypes from 'redux/actions/actionTypes'

import { actionTypes } from './userActions';

export default function reducer(user = initialState.user, action) {
  let result;
  let administrated;
  let societies;

  switch (action.type) {
    case actionTypes.SET_USER:
      result = action.user;
      break;

    case allActionTypes.CREATE_SOCIETY:
      administrated = [...user.societies.administrated, action.society];
      societies = { ...user.societies, administrated };
      result = { ...user, societies };
      break;

    case allActionTypes.DELETE_SOCIETY:
      administrated = user.societies.administrated.filter(
        (societyId) => societyId !== action.society
      );
      societies = { ...user.societies, administrated };
      result = { ...user, societies };
      break;

    default:
      result = null;
      break;
  }
  return result || user;
}
