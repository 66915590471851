import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';

function Slider() {
  const { t } = useTranslate();
  const [imageIndex, setImageIndex] = useState(0);

  const images = [
    {
      img: '/images/slides/promo-a2x.png',
      description: t('WithSttokYouManageYourBoo1'),
    },
    {
      img: '/images/slides/promo-b2x.png',
      description: t('AdministerInTheEasiestAnd'),
    },
    {
      img: '/images/slides/promo-c2x.png',
      description: t('WithSttokYouManageYourBoo2'),
    },
  ];

  return (
    <div
      className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right toggle-screen-lg"
      data-content="athPromo"
      data-toggle-screen="lg"
      data-toggle-overlay="true"
    >
      <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
        <div
          className="slider-init slick-initialized slick-slider slick-dotted"
          data-slick='{"dots":true, "arrows":false}'
        >
          <div className="slick-list draggable">
            <div className="slick-track">
              <div
                className="slider-item slick-slide slick-cloned"
                data-slick-index="-1"
                id=""
                aria-hidden="true"
                tabIndex="-1"
              >
                <div className="nk-feature nk-feature-center">
                  <div className="nk-feature-img">
                    <img
                      className="round"
                      src={images[imageIndex].img}
                      alt=""
                    />
                  </div>
                  <div className="nk-feature-content py-4 p-sm-5">
                    <h4>Sttok</h4>
                    <p>{images[imageIndex].description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="slick-dots" role="tablist">
            <li
              onClick={() => setImageIndex(0)}
              className={imageIndex === 0 ? 'slick-active' : ''}
            >
              <button type="button">1</button>
            </li>
            <li
              onClick={() => setImageIndex(1)}
              className={imageIndex === 1 ? 'slick-active' : ''}
            >
              <button type="button">2</button>
            </li>
            <li
              onClick={() => setImageIndex(2)}
              className={imageIndex === 2 ? 'slick-active' : ''}
            >
              <button type="button">3</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Slider;
