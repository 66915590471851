import { useCallback, useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Committee } from 'types';
import { getCommittee } from 'modules/administratives/redux/committeeActions';

type Props = {
  committeeId: string | undefined;
};

export default function useGetCommittee({ committeeId }: Props) {
  const [committee, setCommittee] = useState<Committee>();

  const getUpdatedCommittee = useCallback(async () => {
    try {
      if (committeeId) {
        const getCommitteeData = async (committeeId: string) => {
          try {
            const committee = await store.dispatch(getCommittee(committeeId));
            setCommittee(committee);
          } catch (error) {
            // eslint-disable-next-line
            console.error(error);
          }
        };

        getCommitteeData(committeeId);
      }
    } catch (error) {
      console.log(error);
    }
  }, [committeeId]);

  useEffect(() => {
    getUpdatedCommittee();
  }, [getUpdatedCommittee]);

  return {
    data: { committee },
    isLoading: false,
    isError: false,
    actions: { getUpdatedCommittee },
  };
}
