import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import boardTemplateTypes from 'modules/boards/constants/boardTemplateTypes';
import ReplaceBoardTemplate from 'modules/boards/modals/ReplaceBoardTemplate';

import downloadWord from 'utils/downloadWord';
import previewDownload from 'utils/previewDownload';

function menuOptions(i18n) {
  return [
    {
      id: 'download-pdf',
      icon: 'ni-file-pdf',
      text: i18n.t('DownloadPDF'),
      action: previewDownload,
      disabled: false,
    },
    {
      id: 'download-word',
      icon: 'ni-file-doc',
      text: i18n.t('DownloadWord'),
      action: ({ preview }) =>
        downloadWord(preview.documentModel, preview.name),
    },
    {
      id: 'edit-template',
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      action: ({ setShowEditor }) => setShowEditor(true),
      disabled: false,
    },
    {
      id: 'change-template',
      icon: 'ni-files',
      text: i18n.t('ChangeTemplate'),
      action: ({ board }) =>
        store.dispatch(
          setModal(
            <ReplaceBoardTemplate
              board={board}
              templateType={boardTemplateTypes.ANNOUNCEMENT}
            />
          )
        ),
      disabled: false,
    },
  ];
}
export default menuOptions;
