/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { getDrafts } from 'modules/drafts/redux/draftActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';

import { getActualSociety } from 'utils/filters';

import { SEARCH_MIN_LENGTH } from 'constants/defaultConstants';
import documentSections from 'constants/documentSections';
import documentTypes from 'constants/documentTypes';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import SearchBar from 'modules/documents/components/SearchBar';
import SideNav from 'modules/documents/components/Sidenav';
import { useGetDocumentsSectionPermissions } from 'modules/documents/hooks/useGetDocumentsSectionPermissions';

import AllsSection from './Alls';
import AnnualReportsSection from './AnnualReports';
import AssignmentsSection from './Assignments';
import AuthoritySection from './Authority';
import CertificatesSection from './Certificates';
import CouncilMinutesSection from './CouncilMinutes';
import CouncilMinutesModal from './councilMinutesModal';
import DeletedSection from './Deleted';
import DocumentModal from './documentModal';
import DocumentsSection from './Documents';
import LettersSection from './Letters';
import MinuteBookSection from './MinuteBook';
import MinuteBookModal from './minuteBookModal';
import MyFoldersSection from './MyFolders';
import MyFoldersModal from './myFoldersModal';
import ScripturesSection from './Scriptures';
import SignaturesSection from './Signatures';
import TemplatesSection from './Templates';
import { useGetFullScreen } from './hooks/useGetFullScreen';

function Documents() {
  const { t } = useTranslate();
  const { location } = useHistory();
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const hasEdit = useLocation().search === '?edit=true';
  const hasPreview = useLocation().search === '?preview=true';

  const { isAdmin, isReadOnly } = useGetDocumentsSectionPermissions().data;

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openTemplateCreator, setOpenTemplateCreator] = useState(false);
  const [isSideNavClick, setIsSideNavClick] = useState(false);

  const user = useSelector((state) => state.user);
  const documents = useSelector((state) => state.documents);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [selectedSection, setSelectedSection] = useState(
    documentSections(i18n)[0]
  );
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [searchedDocuments, setSearchedDocuments] = useState([]);

  const [currentBook, setCurrentBook] = useState(
    JSON.parse(localStorage.getItem('previousFolder')) || null
  );
  const [currentCouncilMinute, setCurrentCouncilMinute] = useState(null);

  const [currentFolder, setCurrentFolder] = useState(null);

  const [searchTerm, setSearchTerm] = useState('');

  const [signatureDocumentCategory, setSignatureDocumentCategory] =
    useState(null);

  const fullScreenResult = useGetFullScreen({ hasPreview });

  const { fullScreen } = fullScreenResult.data;
  const { handleToggleFullScreen } = fullScreenResult.actions;

  const showDocumentsToolbar = useMemo(
    () => !fullScreen && !hasEdit && !hasPreview,
    [fullScreen, hasEdit, hasPreview]
  );

  const createButtonDisabled =
    (selectedSection.tag !== documentTypes.MINUTEBOOK &&
      selectedSection.tag !== documentTypes.MYFOLDERS &&
      selectedSection.tag !== documentTypes.COUNCILMINUTES) ||
    isReadOnly;

  const handleCreate = () => {
    if (isReadOnly) return;
    switch (selectedSection.tag) {
      case documentTypes.MINUTEBOOK: {
        dispatch(
          setModal(
            <MinuteBookModal
              userId={user['_id']}
              societyId={actualSociety['_id']}
              showLinkOptions
            />
          )
        );
        break;
      }
      case documentTypes.MYFOLDERS: {
        dispatch(
          setModal(
            <MyFoldersModal
              userId={user['_id']}
              societyId={actualSociety['_id']}
              showLinkOptions
            />
          )
        );
        break;
      }
      case documentTypes.COUNCILMINUTES: {
        dispatch(
          setModal(
            <CouncilMinutesModal
              userId={user['_id']}
              societyId={actualSociety['_id']}
              showLinkOptions
            />
          )
        );
        break;
      }
      default:
        break;
    }

    return false;
  };

  const validateSearchBarDisabled = () => {
    if (filteredDocuments?.length === 0) return true;

    return false;
  };

  const filterDocumentsPerSection = (sectionTag) => {
    if (!sectionTag || !documents.length) return;
    setIsLoadingData(false);
    let docs = [];

    switch (sectionTag) {
      case documentTypes.TEMPLATES: // Templates
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.TEMPLATES &&
            document.subcategory !== documentTypes.DOCUMENTS &&
            (document?.isCommon || document.society === actualSociety?._id)
        );
        break;

      case documentTypes.SCRIPTURES: // Escrituras
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.SCRIPTURES &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.MINUTEBOOK: // Libro de actas
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.MINUTEBOOK &&
            document?.subcategory === currentBook?._id &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.COUNCILMINUTES: // Actas Consejo
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.COUNCILMINUTES &&
            document?.subcategory === currentCouncilMinute?._id &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.CERTIFICATES: // Certificados
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.CERTIFICATES &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.LETTERS: // Cartas
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.LETTERS &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.SIGNATURES: // Firmas
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.SIGNATURES &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        // Filter the signed documents
        if (signatureDocumentCategory === 'SIGNED') {
          docs = docs?.filter((document) => document?.subcategory === 'SIGNED');
        }
        // Filter the pending documents
        else if (signatureDocumentCategory === 'PENDING') {
          docs = docs?.filter(
            (document) =>
              !document?.subcategory || document?.subcategory === 'PENDING'
          );
        }

        break;
      case documentTypes.ASSIGNMENTS: // Nombramientos
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.ASSIGNMENTS &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;
      case documentTypes.AUTHORITY: // Poderes
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.AUTHORITY &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.ANNUALREPORTS: // Cuentas Anuales
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.ANNUALREPORTS &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.MYFOLDERS: // Mis Carpetas
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.MYFOLDERS &&
            document?.subcategory === currentFolder?._id &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      case documentTypes.DELETED: // Deleted
        docs = documents?.filter(
          (document) =>
            document?.category === documentTypes.DELETED &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;
      case documentTypes.DOCUMENTS: // Documents
        docs = documents?.filter(
          (document) =>
            ((document.category === documentTypes.TEMPLATES &&
              document.subcategory === documentTypes.DOCUMENTS) ||
              document.category === documentTypes.DOCUMENTS) &&
            document.society === actualSociety?._id &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;

      default: // All
        docs = documents?.filter(
          (document) =>
            ((document.category === documentTypes.TEMPLATES &&
              document.subcategory === documentTypes.DOCUMENTS) ||
              document?.category !== documentTypes.TEMPLATES) &&
            document?.category !== documentTypes.ALL &&
            document?.category !== documentTypes.DELETED &&
            document.society === actualSociety?._id &&
            (!document?.isForAdmins || (document?.isForAdmins && isAdmin))
        );
        break;
    }
    return docs;
  };

  const handleUploadDocument = () => {
    if (isReadOnly) return;

    dispatch(
      setModal(
        <DocumentModal
          userId={user['_id']}
          societyId={actualSociety['_id']}
          showLinkOptions
          defaultCategory={selectedSection.tag}
          defaultSubcategory={
            currentBook?._id ||
            currentFolder?._id ||
            currentCouncilMinute?._id ||
            null
          }
        />
      )
    );
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (societyId && actualSociety) {
      dispatch(getDrafts(societyId));
      dispatch(getStockPlans(societyId));
      dispatch(getHoldingClasses(actualSociety?.['_id']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId, actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (societyId) {
      dispatch(
        getDocuments({
          society: societyId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useMemo(() => {
    if (selectedSection && selectedSection.tag === 'TEMPLATES') {
      setOpenTemplateCreator(false);
    }
    if (isSideNavClick) {
      setCurrentBook(null);
      setCurrentFolder(null);
      setSignatureDocumentCategory(null);
      setCurrentCouncilMinute(null);
      setIsSideNavClick(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  useEffect(() => {
    if (location.pathname && societyId) {
      const currentUrl = location.pathname.split(`/${societyId}`)[0];
      const currentSection = documentSections(i18n).find(
        (section) => section?.url === currentUrl
      );
      if (currentSection) {
        setSelectedSection(currentSection);
        setFilteredDocuments(filterDocumentsPerSection(currentSection.tag));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, societyId, documents]);

  useEffect(() => {
    if (currentBook || currentFolder || currentCouncilMinute) {
      setFilteredDocuments(filterDocumentsPerSection(selectedSection.tag));
    }
  }, [currentBook, currentFolder, currentCouncilMinute]);

  useEffect(() => {
    setFilteredDocuments(filterDocumentsPerSection(selectedSection.tag));
  }, [signatureDocumentCategory]);

  useEffect(() => {
    if (filteredDocuments?.length === 0) {
      setSearchedDocuments([]);
      return;
    }

    if (searchTerm && searchTerm.length >= SEARCH_MIN_LENGTH) {
      const filtered = filteredDocuments?.filter(
        (document) =>
          document?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          document?.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchedDocuments(filtered);
    } else {
      setSearchedDocuments(filteredDocuments);
    }
  }, [searchTerm, filteredDocuments]);

  return !isLoading ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        {!fullScreen && (
          <div className="nk-block-head nk-block-between">
            <div className="nk-block-head-content">
              <nav>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{t('Dashboard')}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/sociedad/${actualSociety?._id}`}>
                      {actualSociety?.name}
                    </Link>
                  </li>
                  <li
                    className={`breadcrumb-item ${
                      selectedSection?.tag === documentTypes.ALL ? 'active' : ''
                    }`}
                  >
                    {selectedSection?.tag === documentTypes.ALL ? (
                      t('Documents')
                    ) : (
                      <Link to={`/documentos/${actualSociety?._id}`}>
                        {t('Documents')}
                      </Link>
                    )}
                  </li>
                  {selectedSection?.tag !== documentTypes.ALL && (
                    <li className="breadcrumb-item active" aria-current="page">
                      {t(selectedSection?.tag)}
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        )}

        <div
          className={`nk-fmg nk-fmg-boxed ${
            fullScreen ? 'nk-fmg-boxed__no-padding' : ''
          }`}
        >
          {!fullScreen && (
            <SideNav
              selectedSection={selectedSection}
              setSelectedSection={setSelectedSection}
              setIsSideNavClick={setIsSideNavClick}
              setIsLoading={setIsLoading}
            />
          )}
          <div className="nk-fmg-body" style={{ minHeight: '800px' }}>
            {showDocumentsToolbar && (
              <div className="nk-fmg-body-head d-flex justify-content-between">
                <div className="flex-grow-1">
                  <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    disabled={validateSearchBarDisabled()}
                  />
                </div>
                <div className="flex-grow-1">
                  <ul className="nk-block-tools justify-content-end g-3">
                    <li>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={createButtonDisabled}
                        onClick={handleCreate}
                      >
                        <em className="icon ni ni-plus mr-md-2" />
                        <span className="px-0 d-none d-md-inline-block">
                          {t('Create')}
                        </span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={handleUploadDocument}
                        disabled={isReadOnly}
                      >
                        <em className="icon ni ni-upload-cloud mr-0 mr-md-2" />
                        <span className="px-0 d-none d-md-inline-block">
                          {t('Upload')}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {selectedSection && selectedSection.tag === documentTypes.ALL && (
              <AllsSection
                documents={searchedDocuments}
                setSelectedSection={setSelectedSection}
                setCurrentBook={setCurrentBook}
                setCurrentFolder={setCurrentFolder}
                setCurrentCouncilMinute={setCurrentCouncilMinute}
                setSignatureDocumentCategory={setSignatureDocumentCategory}
                isLoadingData={isLoadingData}
                searchTerm={searchTerm}
              />
            )}
            {selectedSection &&
              selectedSection.tag === documentTypes.SCRIPTURES && (
                <ScripturesSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.ASSIGNMENTS && (
                <AssignmentsSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.AUTHORITY && (
                <AuthoritySection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.MINUTEBOOK && (
                <MinuteBookSection
                  setCurrentBook={setCurrentBook}
                  currentBook={currentBook}
                  documents={searchedDocuments}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.COUNCILMINUTES && (
                <CouncilMinutesSection
                  setCurrentCouncilMinute={setCurrentCouncilMinute}
                  currentCouncilMinute={currentCouncilMinute}
                  documents={searchedDocuments}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.CERTIFICATES && (
                <CertificatesSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.TEMPLATES && (
                <TemplatesSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  setOpenTemplateCreator={setOpenTemplateCreator}
                  openTemplateCreator={openTemplateCreator}
                  searchTerm={searchTerm}
                  fullScreen={fullScreen}
                  handleToggleFullScreen={handleToggleFullScreen}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.DOCUMENTS && (
                <DocumentsSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                  fullScreen={fullScreen}
                  handleToggleFullScreen={handleToggleFullScreen}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.LETTERS && (
                <LettersSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.SIGNATURES && (
                <SignaturesSection
                  signatureDocumentCategory={signatureDocumentCategory}
                  setSignatureDocumentCategory={setSignatureDocumentCategory}
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.ANNUALREPORTS && (
                <AnnualReportsSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.MYFOLDERS && (
                <MyFoldersSection
                  setCurrentFolder={setCurrentFolder}
                  currentFolder={currentFolder}
                  documents={searchedDocuments}
                  searchTerm={searchTerm}
                />
              )}
            {selectedSection &&
              selectedSection.tag === documentTypes.DELETED && (
                <DeletedSection
                  documents={searchedDocuments}
                  isLoadingData={isLoadingData}
                  searchTerm={searchTerm}
                />
              )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default Documents;
