import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import cancelationTypes from 'constants/cancelationTypes';

type Props = {
  cancelationMode: string;
  handleChangeCancelationMode: (event: any) => void; // eslint-disable-line
};

const CancelBeneficiaryModeSection: FC<Props> = ({
  cancelationMode,
  handleChangeCancelationMode,
}) => {
  const { t } = useTranslate();

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="default-01">
        {t('HowDoYouWantToCancel?')}
      </label>

      <div className="row">
        <div className="col-md-4">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name="cancelation-full"
              id="cancelation-full"
              value={cancelationTypes.FULL}
              checked={cancelationMode === cancelationTypes.FULL}
              onChange={handleChangeCancelationMode}
            />
            <label className="custom-control-label" htmlFor="cancelation-full">
              {t('DoNotKeepConsolidatedUnit')}
            </label>
          </div>
        </div>

        <div className="col-md-4">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name="cancelation-partial"
              id="cancelation-partial"
              value={cancelationTypes.PARTIAL}
              checked={cancelationMode === cancelationTypes.PARTIAL}
              onChange={handleChangeCancelationMode}
            />
            <label
              className="custom-control-label"
              htmlFor="cancelation-partial"
            >
              {t('KeepConsolidatedUnitsUnti')}
            </label>
          </div>
        </div>

        <div className="col-md-4">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name="cancelation-consolidated"
              id="cancelation-consolidated"
              value={cancelationTypes.CONSOLIDATED}
              checked={cancelationMode === cancelationTypes.CONSOLIDATED}
              onChange={handleChangeCancelationMode}
            />
            <label
              className="custom-control-label"
              htmlFor="cancelation-consolidated"
            >
              {t('ConsolidateAllUnits')}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelBeneficiaryModeSection;
