import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import SocietyDirectors from 'modules/society/components/SocietyDirectors';
import SocietyAuditors from 'modules/society/components/SocietyAuditors';
import SocietyRepresentative from 'modules/society/components/SocietyRepresentative';

export const useGetPages = () => {
  const society = useSelector((state: State) => state.society?.actualSociety);
  const user = useSelector((state: State) => state.user);

  const [page, setPage] = useState<number>(0);
  const [pages, setPages] = useState<ReactElement[]>();

  useEffect(() => {
    if (society && user) {
      const initialPages = [
        React.createElement(SocietyDirectors, {
          society,
          userId: user['_id'],
        }),
        React.createElement(SocietyRepresentative, {
          society,
          userId: user['_id'],
        }),

        React.createElement(SocietyAuditors, {
          society,
          userId: user['_id'],
        }),
      ];
      setPages(initialPages);
    }
  }, [society, user]);

  return {
    data: { page, pages },
    isLoading: false,
    isError: false,
    actions: { setPage },
  };
};
