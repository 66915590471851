/* eslint-disable react/jsx-props-no-spreading */
import { FC, memo } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';
import NumberFormat from 'react-number-format';

import { Beneficiary } from 'types';
import { currencyFormatDecimals } from 'constants/formats';

type BeneficiaryAsideTotalIncentiveSectionProps = {
  beneficiary: Beneficiary;
  incentiveValue: number;
  summary: Record<string, number>;
};

const BeneficiaryAsideTotalIncentiveSection: FC<
  BeneficiaryAsideTotalIncentiveSectionProps
> = ({ incentiveValue, summary, beneficiary }) => {
  const { t } = useTranslate();

  return (
    <div className="card-inner">
      <div className="overline-title-alt mb-2 text-center">
        {incentiveValue ? t('TotalIncentiveValue') : t('ExercisePrice')}
      </div>

      <div className="profile-balance">
        <div className="profile-balance-group">
          <div className="profile-balance-sub mx-auto">
            <div className="profile-balance-amount">
              <div
                className="number w-100 d-flex justify-content-center align-items-start"
                style={{ textAlign: 'center' }}
              >
                <NumberFormat
                  value={
                    summary?.incentiveValue ||
                    (beneficiary?.amount || 0) -
                      (beneficiary?.soldUnits || 0) *
                        beneficiary?.planData?.sharePrice ||
                    0
                  }
                  displayType="text"
                  {...currencyFormatDecimals}
                />

                <em
                  className="icon ni ni-info notification-icon ml-1 text-primary"
                  id="icon-incentive-value-message"
                />
              </div>
            </div>
            <div className="profile-balance-subtitle text-center">
              {incentiveValue
                ? t('TotalIncentiveValueDescription')
                : t('ExercisePrice')}
            </div>

            {incentiveValue ? (
              <Tooltip
                anchorId="icon-incentive-value-message"
                place="bottom"
                style={{ maxWidth: '300px' }}
              >
                {t('IncentiveValueDetails', {
                  amount: summary?.exercisePrice || '',
                })}
              </Tooltip>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BeneficiaryAsideTotalIncentiveSection);
