import React, { FC } from 'react';
import i18n from 'i18n/config';
import Record from 'typescript';

type Props = {
  draft: Record<string, any>;
};

enum Status {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  EXECUTED = 'EXECUTED',
}

export const draftStatus: Record<string, any> = {
  DRAFT: {
    label: 'Draft',
    style: 'badge-success',
  },
  ACTIVE: {
    label: 'InForce',
    style: 'badge-danger',
  },
  EXECUTED: {
    label: 'Converted',
    style: 'badge-gray',
  },
};

const getStatusFromDraft = (draft: Record<string, any>): string => {
  if (draft?.isConverted) {
    return Status.EXECUTED;
  }

  return Status.DRAFT;
};

const BadgeDraftStatus: FC<Props> = (props: Props) => {
  const { draft } = props;

  const status: string = getStatusFromDraft(draft);
  const statusLabel: string = i18n.t(draftStatus[status].label);
  const statusStyle: string = draftStatus[status].style;

  if (typeof statusLabel !== 'string') {
    throw new Error(
      `Expected a string translation for status "${status}", but got a ${typeof statusLabel}`
    );
  }

  return (
    <span className={`badge badge-pill ${statusStyle}`}>{statusLabel}</span>
  );
};

export default BadgeDraftStatus;
