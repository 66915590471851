import { useEffect, useState } from 'react';
import i18n from 'i18n/config';
import { Society } from 'types';
import { getPercentCategory } from 'utils/charts';
import { tenderOfferSharesTypes } from 'utils/tenderOfferSharesTypes';

type Props = {
  tenderOfferShares: any;
  society: Society;
  // eslint-disable-next-line no-unused-vars
  t: (text: string) => string;
};

const getParticipantsShares = (tenderOfferShares: any) => {
  let participantsShares = 0;
  tenderOfferShares?.participants?.forEach((participant: any) => {
    participantsShares += participant?.totalShares || 0;
  });

  return participantsShares;
};

const getSellerPartnerShares = (tenderOfferShares: any, society: Society) => {
  const sellerPartner = society?.partners?.find(
    (partner) => partner['_id'] === tenderOfferShares?.sellerId
  );
  const sellerShares = sellerPartner?.sharesCount?.actual || 0;

  return sellerShares;
};

export const useGetChartData = ({ tenderOfferShares, society }: Props) => {
  const [chartLegend, setChartLegend] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>(null);

  useEffect(() => {
    if (!tenderOfferShares) return;

    const participantsShares = getParticipantsShares(tenderOfferShares);
    const sellerShares = getSellerPartnerShares(tenderOfferShares, society);
    const totalOffer = sellerShares + participantsShares;

    const chartValues = [participantsShares, sellerShares];
    const labels = [
      tenderOfferShares?.type === tenderOfferSharesTypes.BUY
        ? i18n.t('Bought')
        : i18n.t('Sold'),
      i18n.t('Available'),
    ];
    const colors = ['#798bff', '#7de1f8'];

    const percent = getPercentCategory(totalOffer, participantsShares);
    const legend = [
      {
        title:
          tenderOfferShares?.type === tenderOfferSharesTypes.BUY
            ? i18n.t('Bought')
            : i18n.t('Sold'),
        icon: 'ni-user',
        color: '#798bff',
        value: participantsShares,
        percent,
      },
      {
        title: i18n.t('Available'),
        icon: 'ni-user',
        color: '#7de1f8',
        value: sellerShares,
        percent,
      },
    ];
    setChartLegend(legend);

    setChartData({
      labels,
      datasets: [
        {
          fill: true,
          label: 'Porciento',
          data: chartValues,
          borderWidth: 2,
          borderColor: '#ffffff',
          backgroundColor: colors,
        },
      ],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenderOfferShares, society, i18n.language]);

  return {
    data: { chartData, chartLegend },
  };
};
