import { FC } from 'react';

type DetailsHeaderSectionProps = {
  title?: string;
  description?: string;
};

const DetailsHeaderSection: FC<DetailsHeaderSectionProps> = ({
  title,
  description,
}) => (
  <div className="card-inner">
    <div className="nk-block-head">
      <div className="nk-block-head-content">
        <div className="nk-block float-right" />
      </div>

      <h5 className="title">{title}</h5>
      <p>{description}</p>
    </div>
  </div>
);

export default DetailsHeaderSection;
