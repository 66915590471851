/* eslint-disable react/jsx-props-no-spreading */
import { DEFAULT_HEADER_LOGO } from 'constants/defaultConstants';
import { Link } from 'react-router-dom';
import { getThemeLogoLink } from 'utils/theme';

type HeaderLogoProps = {
  data: Record<string, any>;
};

type HeaderLogoImage = {
  data: Record<string, any>;
  mode: 'dark' | 'light';
};

const Logo = ({ data, mode }: HeaderLogoImage) => (
  <img
    className={`logo-img logo-${mode}`}
    src={data?.theme?.headerLogo}
    srcSet={data?.theme?.headerLogo}
    alt={`logo-${mode}`}
  />
);

const HeaderLogo = ({ data }: HeaderLogoProps) => {
  const logoLink = getThemeLogoLink(data);

  const isExternalLink = !!(
    data?.hasWhiteBranding && logoLink?.includes('http')
  );

  const hasPoweredText =
    data?.theme?.headerLogo !== DEFAULT_HEADER_LOGO && !data?.hasWhiteBranding;

  return (
    <div className="nk-header-brand d-flex flex-column">
      {isExternalLink ? (
        <a
          className="logo-link"
          href={logoLink}
          target="_self"
          rel="noopener noreferrer"
        >
          <Logo data={data} mode="dark" />
          <Logo data={data} mode="light" />
        </a>
      ) : (
        <Link className="logo-link" to={logoLink}>
          <Logo data={data} mode="dark" />
          <Logo data={data} mode="light" />
        </Link>
      )}

      {hasPoweredText && (
        <sub
          className="text-right mt-1"
          style={{
            color: data?.theme?.headerTextColor,
            fontSize: '9px',
          }}
        >
          powered by Sttok
        </sub>
      )}
    </div>
  );
};

export default HeaderLogo;
