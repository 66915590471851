export default [
  '#754c24',
  '#6e82a5',
  '#ba6ed4',
  '#ff87a3',
  '#e85347',
  '#2c3782',
  '#09c2de',
  '#f4bd0e',
  '#20c997',
  '#6576ff',
  '#ff63a5',
  '#ffffff',
  '#000000',
];

export const githubColors = [
  '#B80000',
  '#DB3E00',
  '#FCCB00',
  '#008B02',
  '#006B76',
  '#1273DE',
  '#004DCF',
  '#5300EB',
  '#EB9694',
  '#FAD0C3',
  '#FEF3BD',
  '#C1E1C5',
  '#BEDADC',
  '#C4DEF6',
  '#BED3F3',
  '#D4C4FB',
];

export const twitterColors = [
  '#6576ff',
  '#F78DA7',
  '#EB144C',
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#ABB8C3',
  '#FFFFFF',
  '#000000',
];

export const twitterLightColors = ['#F78DA7', '#8ED1FC', '#ABB8C3', '#FFFFFF'];

export const signatureColors = ['#000000', '#071AD9', '#3F4DC1'];
