export default {
  var: {
    APPROVED: 'APPROVED',
    PENDING: 'PENDING',
  },
  text: {
    APPROVED: 'Approved',
    PENDING: 'Pending',
  },
  badge: {
    APPROVED: 'success',
    PENDING: 'secondary',
  },
};
