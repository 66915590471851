function downloadOptions(i18n) {
  return [
    {
      icon: 'ni-file-xls',
      text: i18n.t('ListOfAttendees'),
      action: ({ handleDownload }) => {
        handleDownload(0);
      },
      dropdown: true,
    },
    {
      icon: 'ni-file-pdf',
      text: i18n.t('AttendanceCertificate'),
      action: ({ handleDownload }) => {
        handleDownload(1);
      },
      dropdown: true,
      // TODO: Remove disabled option when download if fixed
      disabled: true,
    },
  ];
}
export default downloadOptions;
