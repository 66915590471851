import i18n from 'i18n/config';

import { store } from 'redux/store';
import { Society, User } from 'types';

import boardStatus from 'constants/boardStatus';
import { boardAction } from 'modules/_shared/redux/modalsActions';
import createDocumentFromHtml from 'utils/createDocumentFromHtml';
import { BoardDelegation, BoardDelegations } from 'types/Board';
import duplicateCurrentTemplate from '../modals/ReplaceBoardTemplate/utils/duplicateCurrentTemplate';

import {
  addBoardDocuments,
  generateBoardTemplates,
  getDelegationDocumentsFromParticipantsList,
  getPartnerRepresentantsFromParticipantsList,
  parsePartnersToParticipants,
} from '.';

export type NewBoardData = {
  name: string;
  user: User;
  society: Society;
  files: File[];
  date: Date;
  attendanceType: unknown;
  place: unknown;
  announcement: unknown;
  onlineAccess: unknown;
  votePeriod: unknown;
  boardType: unknown;
  digitalVoting: unknown;
  orders: unknown;
  president: unknown;
  secretary: unknown;
  delegationType?: BoardDelegation;
  selectedPartners: unknown[];
  selectedRepresentants: unknown[];
  announcementTemplate?: unknown;
  actTemplate?: unknown;
  actCertificateTemplate?: unknown;
};

export const saveBoard = async (
  boardData: NewBoardData,
  isDuplicate = false
) => {
  const {
    name,
    user,
    files,
    society,
    date,
    attendanceType,
    place,
    announcement,
    onlineAccess,
    votePeriod,
    boardType,
    digitalVoting,
    orders,
    president,
    secretary,
    delegationType,
    selectedPartners,
    selectedRepresentants,
    announcementTemplate: existingAnnouncementTemplate,
    actTemplate: existingActTemplate,
    actCertificateTemplate: existingActCertificateTemplate,
  } = boardData;

  try {
    const uploadedDocuments = !isDuplicate
      ? await addBoardDocuments({ files, user, society })
      : [];

    let announcementTemplate;
    let actTemplate;
    let actCertificateTemplate;

    if (isDuplicate) {
      announcementTemplate = await duplicateCurrentTemplate(
        existingAnnouncementTemplate as string
      );
      actTemplate = await duplicateCurrentTemplate(
        existingActTemplate as string
      );
      actCertificateTemplate = await duplicateCurrentTemplate(
        existingActCertificateTemplate as string
      );
    } else {
      const templates = await generateBoardTemplates({ user, society });
      announcementTemplate = templates.announcementTemplate;
      actTemplate = templates.actTemplate;
      actCertificateTemplate = templates.actCertificateTemplate;
    }

    const participants = parsePartnersToParticipants(society?.partners);

    const delegationPartners =
      delegationType === BoardDelegations.SELECTED_PARTNERS
        ? selectedPartners
        : [];

    const delegationRepresentants =
      getPartnerRepresentantsFromParticipantsList(participants);

    const delegationDocuments =
      getDelegationDocumentsFromParticipantsList(participants);

    const newBoard: Record<string, any> = {
      name,
      status: boardStatus.PENDING.value,
      date: date ? new Date(date) : new Date(),
      attendanceType,
      place,
      announcement,
      onlineAccess,
      votePeriod,
      boardType,
      digitalVoting,
      orders,
      participants,
      president,
      secretary,
      delegationType,
      delegationDocuments,
      delegationPartners,
      delegationRepresentants,
      attachedDocuments: uploadedDocuments,
      announcementTemplate: announcementTemplate
        ? announcementTemplate['_id']
        : null,
      actTemplate: actTemplate ? actTemplate['_id'] : null,
      actCertificateTemplate: actCertificateTemplate
        ? actCertificateTemplate['_id']
        : null,
      society: society?.['_id'],
    };

    const announcementDocName = `${i18n.t('AnnouncementBoard')}.pdf`;
    const announcementDocDescription = `${i18n.t(
      'GeneratedByTheTemplate'
    )} ${i18n.t('AnnouncementTemplate')}_${name}`;

    const documentId = await createDocumentFromHtml({
      html: announcementTemplate.editorModel,
      tagsData: { society, board: newBoard },
      documentName: announcementDocName,
      documentDescription: announcementDocDescription,
      society,
      user,
    });

    if (documentId) {
      newBoard.announcementDocument = documentId;
    }

    const board = await store.dispatch(boardAction(newBoard, false));

    return board;
  } catch (error) {
    throw new Error();
  }
};
