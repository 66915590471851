/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import transformData from 'modules/_shared/components/Tables/helpers';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import TableLoader from 'modules/_shared/components/Tables/TableLoader';

import { setMenuType } from 'modules/_shared/redux/menuActions';
import { getUserSocieties } from 'modules/society/redux/societyActions';

import menuTypes from 'constants/menuTypes';

import { useTranslate } from 'hooks/useTranslate';
import tableColumns from './tableColumns';

import './RepresentedList.scss';

function RepresentedList({ items = 10 }) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const [representedData, setRepresentedData] = useState([]);

  useEffect(() => {
    dispatch(setMenuType(menuTypes.MAIN));
  }, [dispatch]);

  useEffect(() => {
    if (user && user?.societies?.represented?.length) {
      getUserSocieties(
        user['_id'],
        'partnerRepresentant',
        setRepresentedData,
        'table'
      ).then(() => setIsLoadingData(false));
    } else {
      setIsLoadingData(false);
    }
  }, [user]);

  useEffect(() => {
    if (i18n.language) {
      setCurrentTableColumns(tableColumns(i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const memoizedData = useMemo(() => {
    if (user && representedData?.length) {
      const dataForTable = representedData.map((currentData) => ({
        society: currentData.society,
        societyName: currentData.society?.name,
        link: `/juntas/${currentData?.society?._id}/${currentData.representantId}`,
      }));

      return dataForTable;
    }
    return [];
  }, [user, representedData]);

  useEffect(() => {
    if (memoizedData.length) {
      setTableData(
        transformData({
          data: memoizedData,
          columns: tableColumns(i18n),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedData, i18n.language]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('SocietiesWithPartnerRepresented')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {tableData?.length === 0
                    ? t('YouDonHaveSocietiesWithPartnerRepresented')
                    : t('YouHaveSocietiesWithPartnerRepresented', {
                        count: tableData?.length,
                      })}
                </p>
              </div>
            </div>
          </div>
        </div>

        {tableData?.length > 0 ? (
          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('Society')}
            pagination
            className="nk-tb-list"
            rowsPerPage={items}
            actions
            showDownload={false}
          />
        ) : (
          isLoadingData && <TableLoader rows={items} />
        )}
      </div>
    </div>
  );
}

export default RepresentedList;
