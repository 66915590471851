import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import userTypes from 'constants/userTypes';
import { BoardParticipant, Partner } from 'types';
import { BoardParticipantUserType } from 'types/Board';

export const mapParticipantForInitialList = (
  assistant: any,
  partner: Partner,
  partnerPercent: number
): BoardParticipant => ({
  _id: assistant?._id || '',
  image: partner?.image || BLANK_PROFILE_PICTURE,
  member: partner?._id || '',
  name: partner?.name || '',
  cif: partner?.cif || '',
  email: partner?.email || '',
  userType: userTypes.PARTNER as BoardParticipantUserType,
  percentage: partnerPercent || 0,
  assists: !!assistant?.assists,
  isAbsent: !!assistant?.isAbsent,
  hasDelegated: !!assistant?.hasDelegated,
  hasPartnerRepresentant: !!assistant?.hasPartnerRepresentant,
  isRepresented: !!assistant?.isRepresented,
  representative: assistant?.representative || null,
  delegationVote: assistant?.delegationVote || null,
  delegationDocuments: assistant?.delegationDocuments || [],
  votes: assistant?.votes || [],
  hasShares: partner?.sharesCount?.actual > 0,
});
