export const getNameFromEmail = (email: string): string => {
  if (!email) {
    return '';
  }

  const prefix = email.split('@')[0];
  const formatted = prefix.charAt(0).toUpperCase() + prefix.slice(1);

  return formatted;
};
