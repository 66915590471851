import { store } from 'redux/store';
import i18n from 'i18n/config';
import EditCommentsModal from 'modules/_shared/components/Modals/EditComments';
import { setModal } from 'modules/_shared/redux/modalActions';

export default [
  {
    icon: 'ni-info',
    text: i18n.t('SeeSociety'),
    url: '/socios',
  },
  {
    icon: 'ni-book-read',
    text: i18n.t('PartnerBook'),
    url: '/socios-libro',
  },
  {
    icon: 'ni-edit',
    text: i18n.t('EditComments'),
    action: ({ operation, isReadOnly }) => {
      if (!isReadOnly) {
        store.dispatch(setModal(<EditCommentsModal operation={operation} />));
      }
    },
    dontShowCurrent: ({ isReadOnly }) => isReadOnly,
  },
];
