import { ChangeEvent } from 'react';

type DelegationPartnerVoteOptionProps = {
  orderId: string;
  voteValue: string;
  checked: boolean;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void; // eslint-disable-line
};

const DelegationPartnerVoteOption = ({
  orderId,
  voteValue,
  checked,
  label,
  onChange,
}: DelegationPartnerVoteOptionProps) => (
  <div className="col-md-4">
    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
      <input
        type="radio"
        className="custom-control-input"
        name={`btnRadio-${orderId}`}
        id={`btnRadio-${orderId}-${voteValue}`}
        value={voteValue}
        checked={checked}
        onChange={onChange}
      />
      <label
        className="custom-control-label"
        htmlFor={`btnRadio-${orderId}-${voteValue}`}
      >
        {label}
      </label>
    </div>
  </div>
);

export default DelegationPartnerVoteOption;
