import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import { User } from 'types';

import isSuperAdmin from 'utils/isSuperAdmin';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  societyId: string;
  user: User;
  isBeneficiary: boolean;
  isAdmin: boolean;
};

const PlansMenu: FC<Props> = ({ societyId, user, isBeneficiary, isAdmin }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="4">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-star" />
            </span>
            <span className="nk-menu-text">{t('Plans')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="4">
        <ul className="nk-menu-sub">
          {isAdmin && (
            <MenuItem
              linkTo={`/beneficiarios-planes/${societyId}`}
              text="PlansList"
            />
          )}

          {(isAdmin || isBeneficiary) && (
            <>
              <MenuItem
                linkTo={`/beneficiarios/${societyId}`}
                text="BeneficiariesList"
              />
              <MenuItem
                linkTo={`/beneficiarios-fichas/${societyId}`}
                text="BeneficiariesCards"
              />
            </>
          )}

          {isAdmin && (
            <>
              <MenuItem
                linkTo={`/resumen-planes/${societyId}`}
                text="SummaryView"
              />
              <MenuItem
                linkTo={`/beneficiarios-borradores/${societyId}`}
                text="BeneficiariesDraftsMenu"
              />

              {isSuperAdmin(user) && (
                <>
                  <MenuItem
                    linkTo={`/empleados/${societyId}`}
                    text="Employees"
                  />
                  <MenuItem
                    linkTo={`/empleados-planes/${societyId}`}
                    text="EmployeesFilter"
                  />
                  <MenuItem
                    linkTo={`/empleados-notificaciones/${societyId}`}
                    text="Notifications"
                  />
                </>
              )}
            </>
          )}
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default PlansMenu;
