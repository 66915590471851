import i18n from 'i18n/config';

import {
  formatCurrency,
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import {
  getEmployeeFirstEffectiveDate,
  getEmployeeTerminationDate,
} from 'utils/employees';

import {
  Employee,
  EmployeeContract,
  EmployeeSupplement,
  EmployeeProfileChange,
} from 'types';

import historicTypes from '../constants/employeeHistoricTypes';

type EmployeeTimeline = {
  date: Date;
  type: string;
  text: string;
} | null;

const getIncorporationItem = (employee: Employee): EmployeeTimeline => {
  const incorporationDate = getEmployeeFirstEffectiveDate(employee);

  return {
    date: incorporationDate,
    type: historicTypes.INCORPORATION_DATE,
    text: 'INCORPORATION_DATE_TEXT',
  };
};

const getTerminationItem = (employee: Employee): EmployeeTimeline => {
  const terminationDate = getEmployeeTerminationDate(employee);

  if (!terminationDate) {
    return null;
  }

  return {
    date: terminationDate,
    type: historicTypes.LEAVING_DATE,
    text: 'LEAVING_DATE_TEXT',
  };
};

const formatSalaryAmount = (salaryAmount: number): string => {
  const salary = salaryAmount / 100; // Factorial store the salary in cents
  return formatCurrency(salary);
};

const getContractReason = (
  previousContract: EmployeeContract,
  currentContract: EmployeeContract
): string => {
  if (previousContract.salaryAmount !== currentContract.salaryAmount) {
    if (previousContract.salaryAmount < currentContract.salaryAmount) {
      return i18n.t('SALARY_INCREASE_TEXT', {
        oldSalary: formatSalaryAmount(previousContract.salaryAmount),
        newSalary: formatSalaryAmount(currentContract.salaryAmount),
      });
    }
    if (previousContract.salaryAmount > currentContract.salaryAmount) {
      return 'SALARY_DECREASE_TEXT';
    }

    return 'SALARY_CHANGE_TEXT';
  }

  return 'CONTRACT_CHANGE_TEXT';
};

const getContractsItems = (employee: Employee): EmployeeTimeline[] => {
  const contractItems = employee.contracts
    .map((contract, index) => {
      const contractDate = contract?.effectiveDate || contract?.startDate;

      if (!contractDate) {
        return null;
      }

      const previousContract = employee.contracts[index - 1];

      const reason =
        index > 0
          ? getContractReason(previousContract, contract)
          : 'FIRST_CONTRACT_TEXT';

      return {
        date: contractDate,
        type: historicTypes.CONTRACT_CHANGE,
        text: reason,
      };
    })
    .filter(Boolean);

  return contractItems;
};

const getSupplementsItems = (employee: Employee): EmployeeTimeline[] => {
  const supplementsItems = employee.supplements.map(
    (supplement: EmployeeSupplement) => {
      const amount = Number(supplement.amountInCents) / 100;
      const amountFormatted = formatCurrencyDecimals(amount);

      const reason = i18n.t('SUPPLEMENT_ADDED_TEXT', {
        amount: amountFormatted,
      });

      return {
        date: supplement.effectiveOn,
        type: historicTypes.SUPPLEMENT_ADDED,
        text: reason,
      };
    }
  );

  return supplementsItems;
};

const getProfileChangesItems = (employee: Employee): EmployeeTimeline[] => {
  const profileChangesItems = employee?.profileChanges?.map(
    (change: EmployeeProfileChange) => {
      const reason = i18n.t('PERSONAL_DATA_CHANGE_TEXT', {
        field: i18n.t(change.field),
        oldValue: change.oldValue,
        newValue: change.newValue,
      });

      return {
        date: change.date,
        type: historicTypes.PERSONAL_DATA_CHANGE,
        text: reason,
      };
    }
  );

  return profileChangesItems;
};

const getCompensationsItems = (employee: Employee): EmployeeTimeline[] => {
  const compensationsItems = employee.compensations.map((compensation: any) => {
    const amount = Number(compensation.amount) / 100;
    const amountFormatted = formatCurrencyDecimals(amount);

    const reason = i18n.t('COMPENSATION_ADDED_TEXT', {
      amount: amountFormatted,
    });

    return {
      date: compensation.starts_on,
      type: historicTypes.COMPENSATION_ADDED,
      text: reason,
    };
  });

  return compensationsItems;
};

const getEmployeeTimeline = (employee: Employee): EmployeeTimeline[] => {
  const incorporationItem = getIncorporationItem(employee);
  const terminationItem = getTerminationItem(employee);
  const contractItems = getContractsItems(employee);
  const supplementsItems = getSupplementsItems(employee);
  const compensationsItems = getCompensationsItems(employee);
  const profileChangesItems = getProfileChangesItems(employee);

  const firstContract = contractItems.find(
    (item) => item && item.text === 'FIRST_CONTRACT_TEXT'
  );

  const hasSameDateContractAndIncorporation =
    firstContract?.date === incorporationItem?.date;

  if (hasSameDateContractAndIncorporation) {
    const index = contractItems.findIndex(
      (item) => item && item.text === 'FIRST_CONTRACT_TEXT'
    );
    contractItems.splice(index, 1);
  }

  const timelineSorted = [
    terminationItem,
    ...contractItems,
    ...supplementsItems,
    ...compensationsItems,
    ...profileChangesItems,
    incorporationItem,
  ]
    .filter(Boolean)
    .sort((a, b) => {
      if (a && b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      }
      return 0;
    });

  return timelineSorted;
};

export default getEmployeeTimeline;
