import Swal from 'sweetalert2';

function operationOptions(i18n) {
  return [
    {
      icon: 'ni-info',
      text: i18n.t('GoToOperation'),
      action: ({ societyId, convertedOperation }) => {
        window.location.href = `#/detalle-transacciones/${societyId}/${convertedOperation}`;
      },
    },
    {
      icon: 'ni-info',
      text: i18n.t('SaveAsOperation'),
      action: ({ handleSaveAsOperation }) => {
        handleSaveAsOperation();
      },
    },
    {
      icon: 'ni-info',
      text: i18n.t('GenerateNewCapitalIncrease'),
      action: ({ societyId, draftId, partners }) => {
        let tableHtml = `<h6 class="text-base m-1">${i18n.t(
          'TheNextDataWillBeTransferred'
        )}:</h5><div class="nk-block m-2"><table class="table table-bordered table-striped">`;
        tableHtml += `<tr><th>${i18n.t('Name')}</th><th>${i18n.t(
          'Contribution'
        )}</th></tr>`;
        partners.forEach((partner) => {
          tableHtml += `<tr><td>${partner?.name}</td><td>${
            partner?.realContribution || partner?.contribution
          }€</td></tr>`;
        });
        tableHtml += '</table></div>';

        Swal.fire({
          title: `<h5 class="nk-modal-title">${i18n.t(
            'TransferDataToCapitalIncrease'
          )}</h4>`,
          html: tableHtml,
          showCancelButton: true,
          confirmButtonText: `${i18n.t('Accept')}`,
          cancelButtonText: `${i18n.t('Cancel')}`,
          confirmButtonColor: '#6576ff',
          cancelButtonClass: 'bg-gray-200 text-base',
          showCloseButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = `#/borrador-ampliacion/${societyId}?initial=${draftId}`;
          }
        });
      },
    },
  ];
}
export default operationOptions;
