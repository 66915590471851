import { getBeneficiariesSummary } from './getBeneficiariesSummary';
import { getBeneficiariesGroupedByCif } from './getBeneficiariesGroupedByCif';

export const getBeneficiariesMixedSummary = ({
  beneficiaries,
  plans,
  consolidationPeriods,
  tenderOffers,
  endDate,
}) => {
  const hasCif = true;

  const currentBeneficiaries = getBeneficiariesSummary({
    beneficiaries,
    plans,
    consolidationPeriods,
    tenderOffers,
    endDate,
    hasCif,
  });

  const beneficiariesGroupedByCif =
    getBeneficiariesGroupedByCif(currentBeneficiaries);

  Object.keys(beneficiariesGroupedByCif).forEach((key) => {
    const item = beneficiariesGroupedByCif[key];
    delete item.cif;
  });

  return beneficiariesGroupedByCif;
};
