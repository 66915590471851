import { useGetEditorConfig } from './hooks/useGetEditorConfig';
import { useGetEditor } from './hooks/useGetEditor';

import './styles/ckeditor5.css';
import './styles/ckeditor5-premium-features.css';
import './styles/ck-content.css';
import './CKEditor.scss';

type Props = {
  editorInstance: any;
  setEditorInstance: any;
  documentName: string;
  documentModel: string;
  enableMentions: boolean;
  fullScreen?: boolean;
  isReadOnly?: boolean;
  tagsToShow?: any;
  onChangeContent?: () => void;
};

const CKEditor = ({
  editorInstance,
  setEditorInstance,
  documentName,
  documentModel,
  enableMentions,
  fullScreen = false,
  isReadOnly = false,
  tagsToShow,
  onChangeContent,
}: Props) => {
  const { editorConfig } = useGetEditorConfig({
    documentName,
    documentModel,
    enableMentions,
    tagsToShow,
  }).data;

  const { editorRef, editorToolbarRef } = useGetEditor({
    editorInstance,
    setEditorInstance,
    editorConfig,
    isReadOnly,
    onChangeContent,
  }).data;

  return (
    <div>
      <div className="main-container">
        <div className="editor-container editor-container_document-editor editor-container_include-outline editor-container_include-annotations editor-container_include-pagination">
          <div className="editor-container__menu-bar" />
          <div className="editor-container__toolbar" ref={editorToolbarRef} />
          <div className="editor-container__editor-wrapper">
            <div
              className={
                fullScreen
                  ? 'editor-container__sidebar__fullscreen'
                  : 'editor-container__sidebar'
              }
            />

            <div className="editor-container__editor">
              <div id="ck-editor-instance" ref={editorRef} />
            </div>
            <div
              className={
                fullScreen
                  ? 'editor-container__sidebar__fullscreen'
                  : 'editor-container__sidebar'
              }
            />
          </div>
        </div>
        <div className="revision-history">
          <div className="revision-history__wrapper">
            <div className="revision-history__editor" />
            <div className="revision-history__sidebar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CKEditor;
