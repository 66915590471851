export default [
  'Society Name',
  'Society Address',
  'Society Fiscal Identification Number',
  'Society Protocol Number',
  'Society Commercial Registry',
  'Society Social object',
];

export const societyParamsContext = [
  'The official name of the society as registered. Do not include single or double quotation marks',
  'The complete address of the society, including street, city, postal code, and country. The address cannot be that of the Notary. This should be where it says "Domicilio Social," "Domicilio de la Sociedad," or "Domiciliada en." "Registered Address," "Society Address,"',
  'The unique fiscal identification number assigned to the society, without any prefixes or labels. ',
  'The protocol number associated with the registration of the society. ',
  'The registry where the society is officially registered, often including a reference number. ',
  "The description of the society's purpose or business activities.",
];
