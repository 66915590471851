import { TranslateFn } from 'hooks/useTranslate';
import { CommitteeMember, Society } from 'types';
import { getMemberTypeLabel } from '../../CommitteeMembers/utils/getMemberTypeLabel';

export const getSummaryProfileData = (
  member: CommitteeMember,
  society: Society,
  t: TranslateFn
) => {
  const societyName = society?.name || '-';

  const name = member?.name || '-';

  const image = member?.image || undefined;

  const position = member
    ? t(getMemberTypeLabel(member))
    : t('CommitteeMember');

  const description = t('HeIsCommitteeMemberOfSocietyName', { societyName });

  return { name, image, position, description };
};
