import statusTypes from './statusTypes';

const statusTypesOptions = (i18n) => {
  const currStatusTypes = statusTypes(i18n);
  return [
    {
      name: currStatusTypes.name.ACTIVE,
      value: currStatusTypes.var.ACTIVE,
    },
    {
      name: currStatusTypes.name.INACTIVE,
      value: currStatusTypes.var.INACTIVE,
    },
    {
      name: currStatusTypes.name.FINISHED,
      value: currStatusTypes.var.FINISHED,
    },
  ];
};
export default statusTypesOptions;
