import { useEffect, useState } from 'react';

import { Board, BoardOrder, Society } from 'types';

import { getRepresented, getCurrentRepresented } from '../utils/getRepresented';
import { Represented } from '../types/Represented';

type Props = {
  partnerId: string;
  board: Board;
  order: BoardOrder;
  actualSociety: Society;
};

export default function useGetRepresented({
  partnerId,
  order,
  board,
  actualSociety,
}: Props) {
  const [representedWithInstructions, setRepresentedWithInstructions] =
    useState<Represented[]>([]);

  const [representedWithoutInstructions, setRepresentedWithoutInstructions] =
    useState<Represented[]>([]);

  const [currentRepresented, setCurrentRepresented] = useState<
    Represented | undefined
  >();

  useEffect(() => {
    const representedInstructions = getRepresented({
      partnerId,
      board,
      order,
      actualSociety,
      withInstructions: true,
    });

    const representedNonInstructions = getRepresented({
      partnerId,
      board,
      order,
      actualSociety,
      withInstructions: false,
    });

    const represented = getCurrentRepresented({
      partnerId,
      board,
      order,
      actualSociety,
    });

    const filteredRepresentedInstructions = representedInstructions.filter(
      (represented) => !represented?.vote
    );

    const filteredRepresentedNonInstructions =
      representedNonInstructions.filter((represented) => !represented?.vote);

    setCurrentRepresented(represented);
    setRepresentedWithInstructions(filteredRepresentedInstructions);
    setRepresentedWithoutInstructions(filteredRepresentedNonInstructions);
  }, [actualSociety, board, order, partnerId]);

  return {
    data: {
      representedWithInstructions,
      representedWithoutInstructions,
      currentRepresented,
    },
    isLoading: false,
    isError: false,
  };
}
