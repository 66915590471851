/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(
  partnerRepresentants = initialState.partnerRepresentants,
  action
) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.partnerRepresentants;

    case actionTypes.CREATE_PARTNER_REPRESENTANT:
      return [...partnerRepresentants, action.partnerRepresentant];

    case actionTypes.GET_PARTNER_REPRESENTANTS: {
      return action.data;
    }

    case actionTypes.UPDATE_PARTNER_REPRESENTANT:
      return [
        ...partnerRepresentants.filter(
          (partnerRepresentant) =>
            partnerRepresentant['_id'] !== action.partnerRepresentant['_id']
        ),
        action.partnerRepresentant,
      ];

    case actionTypes.DELETE_PARTNER_REPRESENTANT:
      return partnerRepresentants.filter(
        (partnerRepresentant) =>
          partnerRepresentant['_id'] !== action.partnerRepresentantId
      );

    default:
      return partnerRepresentants;
  }
}
