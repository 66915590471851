import { useEffect, useState } from 'react';

import isValidEmail from 'utils/isValidEmail';

import { getEmailColumn } from 'constants/getExcelColumns';

type Props = {
  partners: any;
};

export default function useGetValidPartners({ partners }: Props) {
  const [validPartners, setValidPartners] = useState<any>([]);

  useEffect(() => {
    const filteredPartners = partners?.filter((partner: any) => {
      const email = getEmailColumn(partner);
      return !partner?.isExisting && isValidEmail(email);
    });

    setValidPartners(filteredPartners);
  }, [partners]);

  return { data: validPartners, isLoading: false, isError: false, actions: {} };
}
