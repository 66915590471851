import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  value: string;
  onChange: any;
};

const PersonalDataViewNationality: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslate();

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label w-100" htmlFor="partner-nationality">
          {t('Nationality')}
        </label>

        <input
          id="partner-nationality"
          type="text"
          className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
          value={value}
          onChange={onChange}
          placeholder={t('IntroduceNationality')}
        />
      </div>
    </div>
  );
};

export default PersonalDataViewNationality;
