// TODO validate inside components: [society-dashboard, partners-book]

import superAdmins from 'constants/superAdmins';

const superAdminIds = [
  'admin-center',
  'employee-list',
  'employees-plan-list',
  'employees-notifications-list',
];

const adminOrPartnerOrRepresentantValidationIds = ['society-dashboard'];

const adminOrPartnerValidationIds = [
  'partners-list',
  'partner-cards',
  'partners-list-user',
  'partners-book',
  'board-details',
  'signature-delegation',
  'portfolio-cards',
  'portfolio-list-filter',
  'portfolio-cards-filter',
  'portfolio-list',
  'portfolio-chart',
];

const adminOrBeneficiaryValidationIds = [
  'beneficiary-list',
  'beneficiary-cards',
  'beneficiary-plan',
];

const adminOrInvestorValidationIds = ['investors-list'];

const adminValidationIds = [
  'holding-classes',
  'partners-categories',
  'partners-representants',
  'partner-representant-details',
  'transactions-society',
  'transaction-details',
  'stockplans-society',
  'beneficiary-drafts',
  'beneficiary-drafts-plan',
  'drafts-society',
  'drafts-increase-id',
  'drafts-increase',
  'drafts-sell-id',
  'drafts-sell',
  'drafts-safe-id',
  'drafts-safe',
  'drafts-note-id',
  'drafts-note',
  'templates',
  'annual-value',
  'boards-list',
  'tender-offers-list',
  'tender-offers-details',
  'summary-plans',
  'representative-details',
  'director-details',
];

const otherAdminIds = [
  'documents-docs',
  'documents-new',
  'documents-recents',
  'documents-minutebook',
  'documents-writings',
  'documents-certificates',
  'documents-letters',
  'documents-signs',
  'documents-assignments',
  'documents-authority',
  'documents-annualreports',
  'documents-templates-all',
  'documents-templates-new',
  'documents-deleted',
  'documents-society',
  'documents',
  'society-profile',
];

const partnerValidationIds = [
  'transactions-society-partner',
  'partner-details',
  'partner-details-page',
];

const investorValidationIds = ['investor-details', 'investor-details-page'];

const representedValidationIds = ['society-dashboard'];

const communicationsIds = [
  'com-certificate',
  'com-communication',
  'com-society',
];

const allAccessIds = ['signature-certificate'];

const boardValidationIds = ['board-partner', 'partner-delegation'];

function isSuperAdmin(user) {
  return !!superAdmins.includes(user?.email);
}

function isAdmin(user, societyId) {
  if (user?.societies?.administrated.includes(societyId)) return true;
  const { usersWithAccess } = user;
  let hasAccess = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.societies?.administrated.includes(societyId))
      hasAccess = true;
  });
  return hasAccess;
}

function isPartner(user, societyId) {
  if (user?.societies?.participated.includes(societyId)) return true;
  const { usersWithAccess } = user;
  let hasAccess = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.societies?.participated.includes(societyId))
      hasAccess = true;
  });
  return hasAccess;
}

function isBeneficiary(user, societyId) {
  if (user?.societies?.beneficiated.includes(societyId)) return true;
  const { usersWithAccess } = user;
  let hasAccess = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.societies?.beneficiated.includes(societyId))
      hasAccess = true;
  });
  return hasAccess;
}

function isInvestor(user, societyId) {
  if (user?.societies?.invested.includes(societyId)) return true;
  const { usersWithAccess } = user;
  let hasAccess = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.societies?.invested.includes(societyId)) hasAccess = true;
  });
  return hasAccess;
}

function isRepresentant(user, societyId) {
  if (user?.societies?.represented.includes(societyId)) return true;
  const { usersWithAccess } = user;
  let hasAccess = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.societies?.represented.includes(societyId))
      hasAccess = true;
  });
  return hasAccess;
}

function isGuest(user, societyId) {
  if (user?.societies?.invited.includes(societyId)) return true;
  const { usersWithAccess } = user;
  let hasAccess = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.societies?.invited.includes(societyId)) hasAccess = true;
  });
  return hasAccess;
}

function representsPartner(user, partnerId) {
  if (user?.partners?.includes(partnerId)) return true;
  const { usersWithAccess } = user;
  let hasPartner = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.partners?.includes(partnerId)) hasPartner = true;
  });
  return hasPartner;
}

function representsBeneficiary(user, beneficiaryId) {
  if (user?.beneficiaries?.includes(beneficiaryId)) return true;
  const { usersWithAccess } = user;
  let hasBeneficiary = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.beneficiaries?.includes(beneficiaryId))
      hasBeneficiary = true;
  });
  return hasBeneficiary;
}

function representsInvestor(user, investorId) {
  if (user?.investors?.includes(investorId)) return true;
  const { usersWithAccess } = user;
  let hasInvestor = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.investors?.includes(investorId)) hasInvestor = true;
  });
  return hasInvestor;
}

function representsGuest(user, guestId) {
  if (user?.guests?.includes(guestId)) return true;
  const { usersWithAccess } = user;
  let hasGuest = false;
  usersWithAccess.forEach((currentUser) => {
    if (currentUser?.guests?.includes(guestId)) hasGuest = true;
  });
  return hasGuest;
}

function validateRoute(user, id, url) {
  if (!user || !id || !url) return false;

  if (superAdminIds.includes(id)) {
    return isSuperAdmin(user);
  }

  if (adminOrPartnerOrRepresentantValidationIds.includes(id)) {
    const [, , societyId] = url.split('/');
    return (
      isAdmin(user, societyId) ||
      isPartner(user, societyId) ||
      isRepresentant(user, societyId) ||
      isGuest(user, societyId)
    );
  }

  if (adminOrPartnerValidationIds.includes(id)) {
    const [, , societyId] = url.split('/');
    return isAdmin(user, societyId) || isPartner(user, societyId);
  }

  if (adminValidationIds.includes(id)) {
    const [, , societyId] = url.split('/');
    return isAdmin(user, societyId);
  }

  if (otherAdminIds.includes(id)) {
    const societyId = url.split('/').pop();
    return isAdmin(user, societyId);
  }

  if (communicationsIds.includes(id)) {
    const [, , societyId] = url.split('/');
    return isAdmin(user, societyId);
  }

  if (partnerValidationIds.includes(id)) {
    const [, , societyId, partnerId] = url.split('/');
    return (
      isAdmin(user, societyId) ||
      (representsPartner(user, partnerId) && isPartner(user, societyId))
    );
  }

  if (investorValidationIds.includes(id)) {
    const [, , societyId, investorId] = url.split('/');
    return (
      isAdmin(user, societyId) ||
      (representsInvestor(user, investorId) && isInvestor(user, societyId))
    );
  }

  if (representedValidationIds.includes(id)) {
    const [, , societyId] = url.split('/');
    return isRepresentant(user, societyId);
  }

  if (adminOrBeneficiaryValidationIds.includes(id)) {
    const [, , societyId] = url.split('/');
    return isAdmin(user, societyId) || isBeneficiary(user, societyId);
  }

  if (adminOrInvestorValidationIds.includes(id)) {
    const [, , societyId] = url.split('/');
    return isAdmin(user, societyId) || isInvestor(user, societyId);
  }

  if (boardValidationIds.includes(id)) {
    const [, , societyId, , partnerId] = url.split('/');
    return (
      isAdmin(user, societyId) ||
      (representsPartner(user, partnerId) && isPartner(user, societyId)) ||
      isRepresentant(user, societyId)
    );
  }

  // Special validations
  if (allAccessIds.includes(id)) {
    return true;
  }
  if (id === 'beneficiary-details') {
    const [, , societyId, , beneficiaryId] = url.split('/');
    return (
      isAdmin(user, societyId) ||
      (representsBeneficiary(user, beneficiaryId) &&
        isBeneficiary(user, societyId))
    );
  }
  if (id === 'beneficiary-sign') {
    // TODO Find out what to do here
    return true;
  }
  return true;
}
export default validateRoute;
