import { CommitteeMember } from 'types';
import memberTypes from '../constants/memberTypes';

export const getMemberTypeLabel = (member: CommitteeMember) => {
  const memberType = memberTypes.find(
    (type) => type.value === member?.memberType
  );

  const memberTypeLabel = memberType ? memberType.label : '-';

  return memberTypeLabel;
};
