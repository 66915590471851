import i18n from 'i18n/config';
import { dateFormat, getNumberFormatted } from 'constants/formats';
import { format } from 'date-fns';
import { getDashboardData } from 'modules/society/actions/dashBoardActions';

const getAmountsByDates = (financialChartViewData) => {
  const amountsByDate = financialChartViewData.reduce((acc, operation) => {
    const date = format(new Date(operation.date), dateFormat);
    const label = `${i18n.t(operation.operationType)}: ${getNumberFormatted(
      operation.amount
    )}`;

    if (!acc[date]) {
      acc[date] = {
        price: operation.amount,
        type: operation.operationType,
        date: operation.date,
        label,
      };
    } else {
      acc[date].price += operation.amount;
      acc[date].label += ` | ${label}`;
    }
    return acc;
  }, {});

  const amountsByDateArray = Object.values(amountsByDate);

  if (amountsByDateArray?.length) {
    const lastOperation = amountsByDateArray[amountsByDateArray.length - 1];
    const today = new Date();
    if (new Date(lastOperation.date).toDateString() !== today.toDateString()) {
      amountsByDateArray.push({
        date: today,
        label: '',
        price: 0,
        type: '',
      });
    }
  }
  return amountsByDateArray;
};

const getInversionValue = (financialChartViewData) => {
  const inversionValue = financialChartViewData.reduce(
    (acc, operation) => acc + operation.amount,
    0
  );

  return inversionValue;
};

const getHigherSharePremium = (financialChartViewData) => {
  let maxSharePremium = 0;

  financialChartViewData?.forEach((operation) => {
    if (operation?.maxSharePremium > maxSharePremium) {
      maxSharePremium = operation?.maxSharePremium;
    }
  });

  return maxSharePremium;
};

const getOptions = () => ({
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Resumen Sociedad',
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label: (context) => {
          if (context.datasetIndex === 1) return '';
          return `${context.dataset?.label[context?.dataIndex] || ''}`;
        },
      },
    },
  },
  response: true,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'month',
      },
      offset: true,
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        precision: 0,
      },
    },
  },
});

export const getFinancingChartViewData = async (societyId, dispatch) => {
  const financialViewData = await dispatch(
    getDashboardData(societyId, 'financial')
  );

  const financialChartViewData = financialViewData.chartData;

  const amountsByDate = getAmountsByDates(financialChartViewData);

  const prices = amountsByDate.map((op) => op.price);

  const labels = amountsByDate.map((op) => new Date(op.date));

  const operations = amountsByDate.map((op) => op.label);

  const options = getOptions();

  const chartData = {
    labels,
    datasets: [
      {
        fill: true,
        label: operations,
        data: prices,
        borderColor: '#798bff',
        backgroundColor: '#798bff',
        barThickness: 10,
        minBarThickness: 5,
        maxBarThickness: 30,
        pointStyle: 'circle',
        grouped: true,
      },
    ],
  };

  return { kpisData: financialViewData.kpisData, data: chartData, options };
};
