/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { Folder, FolderContent } from 'modules/dashboard/types/CustomDashboard';
import { FC, JSX, useState } from 'react';

import { folderContentKeys } from 'modules/dashboard/constants/foldersContentType';

import DashboardEditContentStep1 from './DashboardEditContentStep1';
import DashboardEditContentStep2 from './DashboardEditContentStep2';
import DashboardEditContentStep3 from './DashboardEditContentStep3';

import './DashboardEditContent.scss';

type DashboardEditContentProps = {
  folder: Folder;
  onSave: any;
};

type DashboardEditContentStep = {
  icon?: string;
  component: JSX.Element;
  isDisabled: () => boolean;
};

const DashboardEditContent: FC<DashboardEditContentProps> = ({
  folder,
  onSave,
}) => {
  const { t } = useTranslate();

  const [step, setStep] = useState<number>(1);

  const [currentContent, setCurrentContent] = useState<FolderContent>({
    index: 0,
    title: '',
    type: '',
  });

  const handleSelectContentType = (type: string) => {
    setCurrentContent({
      ...currentContent,
      type,
    });
  };

  const handleChangeContentTitle = (title: string) => {
    setCurrentContent({
      ...currentContent,
      title,
    });
  };

  const handleChangeContentId = (id: string) => {
    const contentKey =
      folderContentKeys[currentContent.type as keyof typeof folderContentKeys];

    setCurrentContent({
      ...currentContent,
      [contentKey]: id,
    });
  };

  const handleGoNextStep = () => {
    setStep(step + 1);
  };

  const handleSaveCurrentContent = () => {
    onSave({
      ...currentContent,
      index: folder.content.length + 1,
    });
    setStep(0);
  };

  const getIsContentDisabled = () => {
    const contentKey =
      folderContentKeys[currentContent.type as keyof typeof folderContentKeys];

    return !currentContent[contentKey as keyof FolderContent];
  };

  const steps: Record<number, DashboardEditContentStep> = {
    1: {
      icon: 'icon ni ni-chevron-right',
      component: (
        <DashboardEditContentStep1
          onSelect={handleSelectContentType}
          contentType={currentContent.type}
        />
      ),
      isDisabled: () => !currentContent.type,
    },
    2: {
      icon: 'icon ni ni-chevron-right',
      component: (
        <DashboardEditContentStep2 onChange={handleChangeContentTitle} />
      ),
      isDisabled: () => !currentContent.title,
    },
    3: {
      icon: 'icon ni ni-save',
      component: (
        <DashboardEditContentStep3
          onChange={handleChangeContentId}
          contentType={currentContent.type}
        />
      ),
      isDisabled: () => getIsContentDisabled(),
    },
  };

  return (
    <div id="dashboard-edit-content-container" className="row">
      {steps[step].component}

      {[1, 2].includes(step) && (
        <div className="col-12">
          <button
            type="button"
            className="btn btn-block btn-outline-primary"
            onClick={handleGoNextStep}
            disabled={steps[step].isDisabled()}
          >
            <em className={`${steps[step].icon} mr-1`} />

            {t('NextStep')}
          </button>
        </div>
      )}
      {step === 3 && (
        <div className="col-12">
          <button
            type="button"
            className="btn btn-block btn-outline-primary"
            onClick={handleSaveCurrentContent}
            disabled={steps[step].isDisabled()}
          >
            <em className={`${steps[step].icon} mr-1`} />

            {t('Save')}
          </button>
        </div>
      )}
    </div>
  );
};

export default DashboardEditContent;
