/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { PartnerCategory } from 'types';

import { setModal } from 'modules/_shared/redux/modalActions';
import {
  createPartnerCategory,
  updatePartnerCategory,
} from 'modules/partners/redux/partnerCategoryActions';
import { State } from 'redux/initialState';

import actionTypes from 'constants/actionTypes';

import '../Modals.scss';

type PartnerCategoryModalProps = {
  action: string;
  currentPartnerCategory: PartnerCategory;
};

const PartnerCategoryModal: FC<PartnerCategoryModalProps> = ({
  action = 'ADD',
  currentPartnerCategory,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  function savePartnerCategory(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const newPartnerCategory = {
      name,
      description,
      society: actualSociety['_id'],
    };

    if (action === actionTypes.EDIT) {
      dispatch(
        updatePartnerCategory(currentPartnerCategory['_id'], newPartnerCategory)
      );
    } else {
      dispatch(createPartnerCategory(newPartnerCategory));
    }

    dispatch(setModal(null));
  }

  useEffect(() => {
    if (action === actionTypes.EDIT) {
      setName(currentPartnerCategory?.name);
      setDescription(currentPartnerCategory?.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === actionTypes.EDIT ? t('Edit') : t('Add')}{' '}
          {` ${t('PartnerCategory')}`}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form
          className="form-validate is-alter"
          onSubmit={(event) => savePartnerCategory(event)}
        >
          <div className="form-group">
            <label className="form-label" htmlFor="category-name">
              {t('CategoryName')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="category-name"
                className="form-control"
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder={t('Socios Fundadores')}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="category-description">
              {t('Description')}
            </label>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  id="category-description"
                  className="form-control form-control-lg no-resize"
                  placeholder={t('Agrupamos a los principales socios')}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name}
          >
            {action === actionTypes.EDIT ? t('ToUpdate') : t('Add')}{' '}
            {t('Category')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
};

export default PartnerCategoryModal;
