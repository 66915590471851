/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import screens from 'constants/screens';
import userTypes from 'constants/userTypes';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';

import { translate } from 'hooks/useTranslate';
import investorOptions from './investorOptions';

function tableColumns(i18n) {
  return [
    {
      name: translate('Nr'),
      field: 'index',
      hide: screens.SM,
      selector: (row, index) => index + 1,
      cell: (row, index) => index + 1,
      disableSortBy: true,
      disableFilters: true,
      grow: 0.5,
      minWidth: 20,
      export: true,
    },
    {
      // mandatory props
      name: translate('Investor'),
      field: 'name',
      selector: (row) => row[translate('Investor')],
      sortable: true,
      // optional props
      grow: 4,
      compact: true,
      cell: (row) =>
        CellData.User({
          societyId: row?.societyId,
          partner: row?.investor,
          hasLink: row?.hasLink,
          url: '/detalle-inversor',
        }),
      export: true,
    },
    {
      name: translate('DraftConvertibleNote'),
      field: 'draftName',
      selector: (row) => row[translate('DraftConvertibleNote')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.PlainText({
          text: row[translate('DraftConvertibleNote')],
        }),
      export: true,
    },
    {
      name: translate('Contribution'),
      field: 'contribution',
      selector: (row) => row[translate('Contribution')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.CurrencyDecimals({
          value: row[translate('Contribution')],
        }),
      export: true,
    },
    {
      name: translate('Discount'),
      field: 'discountPercent',
      selector: (row) => row[translate('Discount')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[translate('Discount')],
          decimals: 2,
        }),
      export: true,
    },
    // {
    //   name: translate('Interest'),
    //   field: 'interest',
    //   selector: (row) => row[translate('Interest')],
    //   sortable: true,
    //   center: true,
    //   hide: screens.LG,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.CurrencyDecimals({
    //       value: row[translate('Interest')],
    //     }),
    //   export: true,
    // },
    {
      name: translate('Shares'),
      field: 'shares',
      selector: (row) => row[translate('Shares')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[translate('Shares')] }),
      export: true,
    },
    {
      name: translate('%ND'),
      field: 'NDPercent',
      selector: (row) => row[translate('%ND')],
      sortable: true,
      center: true,
      hide: screens.SM,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[translate('%ND')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: translate('%FD'),
      field: 'FDPercent',
      selector: (row) => row[translate('%FD')],
      sortable: true,
      center: true,
      hide: screens.SM,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[translate('%FD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: translate('%PotentialFD'),
      field: 'FDPotential',
      selector: (row) => row[translate('%PotentialFD')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[translate('%PotentialFD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: translate('ContributionDate'),
      field: 'contributionDate',
      selector: (row) => row[translate('ContributionDate')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PlainDate({
          date: row[translate('ContributionDate')],
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={investorOptions(i18n)}
                direction="left"
                id={`${row.societyId}/${row.investor['_id']}`}
                params={{
                  data: row.investor,
                  societyId: row.societyId,
                  userType: userTypes.INVESTOR,
                  society: row.society,
                  isReadOnly: row.isReadOnly,
                }}
              />
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}

export default tableColumns;
