/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import foldersContentType from 'modules/dashboard/constants/foldersContentType';
import { FC } from 'react';

import DashboardFolderContentItem from '../DashboardFolderContentItem';

type DashboardEditContentStep1Props = {
  onSelect: (type: string) => void;
  contentType: string;
};

const contentOptions = [
  {
    index: 1,
    title: 'Video',
    type: foldersContentType.VIDEO_EMBEDDED,
  },
  {
    index: 2,
    title: 'Documento PDF',
    type: foldersContentType.PDF_EMBEDDED,
  },
];

const DashboardEditContentStep1: FC<DashboardEditContentStep1Props> = ({
  onSelect,
  contentType,
}) => {
  const { t } = useTranslate();

  const handleSelectOption = (type: string) => {
    onSelect(type);
  };

  return (
    <div className="col-12 mb-4">
      <h6 className="mb-3">{t('SelectContentType')}</h6>

      <div className="row">
        {contentOptions.map((contentOption) => (
          <div className="col-6">
            <div
              className={`card card-bordered card-selectable ${
                contentType === contentOption.type ? 'active' : ''
              }`}
              onClick={() => handleSelectOption(contentOption.type)}
            >
              <DashboardFolderContentItem {...contentOption} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardEditContentStep1;
