import { useTranslate } from 'hooks/useTranslate';
import { FC, memo } from 'react';
import { Beneficiary } from 'types';

type BeneficiaryAsidePersonalSectionProps = {
  beneficiary: Beneficiary;
};

const BeneficiaryAsidePersonalSection: FC<
  BeneficiaryAsidePersonalSectionProps
> = ({ beneficiary }) => {
  const { t } = useTranslate();

  return (
    <div className="card-inner">
      <div className="user-card user-card-s2">
        <div className="user-avatar lg bg-primary">
          {beneficiary.image ? (
            <img src={beneficiary.image} alt={beneficiary.name || ''} />
          ) : (
            <span>{beneficiary?.name?.slice(0, 2)?.toUpperCase()}</span>
          )}
        </div>

        <div className="user-info">
          <div className="badge badge-outline badge-pill text-primary ucap">
            {t('Beneficiary')}
          </div>
          <h5>{beneficiary.name || ''}</h5>
          <span className="sub-text">{beneficiary.email}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(BeneficiaryAsidePersonalSection);
