import { FC } from 'react';

import { Partner } from 'types';

import useGetPartnerRowData from '../hooks/useGetPartnerRowData';

type Props = {
  partner: Partner & { isExisting?: boolean };
  index: number;
};

const PartnersPreviewTableRow: FC<Props> = ({ partner, index }) => {
  const { name, email, nroId, ppss, validPartner } = useGetPartnerRowData({
    partner,
  }).data;

  return (
    <>
      <tr className="tb-tnx-item" key={partner?.['_id']}>
        <td className="tb-tnx-id">
          <span>{index + 1}</span>
        </td>
        <td className="tb-tnx-info">
          <span className="tb-tnx-info">{name}</span>
        </td>
        <td className="tb-tnx-info">
          <span className="tb-tnx-info">{email}</span>
        </td>
        <td className="tb-tnx-info">
          <span className="tb-tnx-info">
            {validPartner ? (
              <em className="icon ni ni-check-circle-fill text-success" />
            ) : (
              <em className="icon ni ni-alert-circle-fill text-danger" />
            )}
          </span>
        </td>
        <td className="tb-tnx-info">
          <span className="tb-tnx-info">{nroId}</span>
        </td>
        <td className="tb-tnx-info text-right">
          <span className="tb-tnx-info text-right">{ppss}</span>
        </td>
      </tr>
    </>
  );
};

export default PartnersPreviewTableRow;
