/* eslint-disable no-console */
import {
  addDocument,
  getDocument,
} from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

const duplicateCurrentTemplate = async (
  templateId: string | undefined,
  templateDocument?: Document | null,
  name?: string
) => {
  if (!templateId && !templateDocument) return null;

  try {
    const currentTemplate =
      templateDocument || (await store.dispatch(getDocument(templateId)));

    if (!currentTemplate) return null;

    const newTemplateData = {
      ...currentTemplate,
      subcategory: 'GENERATED',
    };

    if (name) {
      newTemplateData.name = `${currentTemplate.name}_${name}`;
    }

    delete newTemplateData._id;

    const newTemplate = await store.dispatch(addDocument(newTemplateData));

    return newTemplate;
  } catch (error) {
    // @javierfaife - We should handle this error
    console.error(error);
    return null;
  }
};

export default duplicateCurrentTemplate;
