/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  AsyncTypeahead,
  ClearButton,
  Typeahead,
} from 'react-bootstrap-typeahead';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addPartner } from 'modules/_shared/redux/modalsActions';
import {
  getSocietiesRegistered,
  getUserSocieties,
} from 'modules/society/redux/societyActions';

import { generateEmail, isCifAlready } from 'utils/filters';
import isValidEmail from 'utils/isValidEmail';
import normalizeCif from 'utils/normalizeCif';
import normalizeEmail from 'utils/normalizeEmail';
import trackEvent from 'utils/trackEvent';

import {
  EMAIL_PATTERN,
  OLDEST_DATE,
  SELECT_CATEGORY,
} from 'constants/defaultConstants';
import eventTypes from 'constants/eventTypes';
import operationTypes from 'constants/operationTypes';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import InputError from '../../InputError';
import '../Modals.scss';

function AddPartnerModal() {
  const mixpanel = useMixpanel();
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partnerCategories = useSelector((state) => state.partnerCategories);

  const [email, setEmail] = useState('');
  const [cif, setCif] = useState();
  const [name, setName] = useState('');
  const [naturalPerson, setNaturalPerson] = useState(true);
  const [validEmail, setValidEmail] = useState(false);

  const [partnerCategory, setPartnerCategory] = useState('');
  const [partnerCategoriesOptions, setPartnerCategoriesOptions] = useState([]);
  const [selectedPartnerCategoryOption, setSelectedPartnerCategoryOption] =
    useState({
      value: SELECT_CATEGORY,
      label: t('SelectCategory'),
    });

  const [societies, setSocieties] = useState([]);
  const [partners, setPartners] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [textFromSociety, setTextFromSociety] = useState('');
  const [societiesList, setSocietiesList] = useState([]);

  const animatedComponents = makeAnimated();

  const handleSearch = (query) => {
    const filteredOption = partners.filter((partner) =>
      partner.name.toLowerCase().includes(query.toLowerCase())
    );
    setOptions(filteredOption);
    setName(query);
  };

  const handleOptionSelected = (selectedOption) => {
    setName(selectedOption?.name);
    setCif(selectedOption?.cif);
    setEmail(selectedOption.email);
  };

  const clearSelectedOption = () => {
    setCif('');
    setEmail('');
  };

  const handleSelectCompany = (society) => {
    if (society?.customOption) {
      setName(society.label);
    } else {
      const { name } = society?.nameAddress || '';
      const { registeredOfficeCode } = society?.registeredOffice || '';

      setName(name);
      setCif(registeredOfficeCode);
    }
    setSocietiesList([]);
    setTextFromSociety('');
  };

  const clearSelectCompany = () => {
    setName('');
    setCif('');
  };

  const handleAutoCompleteBlur = () => {
    if (textFromSociety) {
      handleSelectCompany({
        customOption: true,
        label: textFromSociety,
        id: 'new-id-1',
      });
    }
  };

  const handlePartnerCategoryOptionChange = (selectedOption) => {
    setPartnerCategory(selectedOption.value);
    setSelectedPartnerCategoryOption(selectedOption);
  };

  const handleChangeCif = (event) => {
    const { value } = event.target;

    const normalicedCif = normalizeCif(value);
    setCif(normalicedCif);
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;

    const normalicedEmail = normalizeEmail(value);
    setEmail(normalicedEmail);

    const isValid = isValidEmail(normalicedEmail);
    setValidEmail(isValid);
  };

  function getOperationDate() {
    const excludedOps = [
      operationTypes.ADD_BENEFICIARY,
      operationTypes.STOCK_OPTION,
      operationTypes.PHANTOM_SHARE,
    ];
    const filteredOperations = actualSociety?.operations.filter(
      (operation) => !excludedOps.includes(operation.operationType)
    );
    if (!filteredOperations.length) return OLDEST_DATE;

    let lastOperation = filteredOperations.reduce((acc, op) =>
      new Date(acc.date) > new Date(op.date) ? acc : op
    );
    // Get last operation date from UTC to local timezone with new Date()
    lastOperation = new Date(lastOperation.date);
    lastOperation.setMinutes(lastOperation.getMinutes() + 1);
    return lastOperation;
  }

  function handleChangePersonValue() {
    setNaturalPerson(!naturalPerson);
  }

  function savePartner(event) {
    event.preventDefault();

    const validationCif = isCifAlready(cif, actualSociety);

    if (validationCif) {
      dispatch(addAlert(alertBodyTypes.DUPLICATED_CIF));
    } else {
      const date = getOperationDate();
      dispatch(
        addPartner({
          name,
          email: email !== '' ? email : generateEmail(actualSociety),
          category: partnerCategory || null,
          date,
          society: actualSociety?.['_id'],
          societyName: actualSociety?.name,
          userEmail: user.email,
          cif,
          user: user['_id'],
          isNaturalPerson: naturalPerson,
        })
      );
      dispatch(setModal(null));

      trackEvent(mixpanel, eventTypes.ADD_PARTNER, {
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?._id,
        societyName: actualSociety?.name,
        operation: eventTypes.ADD_PARTNER,
      });
    }
  }

  useEffect(() => {
    if (user && user.societies.administrated.length) {
      getUserSocieties(user['_id'], 'admin', setSocieties);
    }
  }, [user]);

  useEffect(() => {
    if (societies) {
      let partnersMerge = [];
      societies.forEach((society) => {
        const societyPartners = society?.partners || [];
        partnersMerge = [...partnersMerge, ...societyPartners];
      });
      setPartners(partnersMerge);
    }
  }, [societies]);

  useEffect(() => {
    if (partnerCategories?.length) {
      const partnerCategoriesOptions = partnerCategories.map((category) => ({
        value: category['_id'],
        label: category.name,
      }));
      setPartnerCategoriesOptions(partnerCategoriesOptions);
    } else {
      setPartnerCategoriesOptions([]);
    }
  }, [partnerCategories]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('AddPartner')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={savePartner}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label" htmlFor="partner-name">
                      {t('PartnerNameSociety')}
                    </label>
                  </div>
                  <div className="col-4">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="natural-person"
                        id="natural-person"
                        value={naturalPerson}
                        checked={naturalPerson}
                        onChange={() => handleChangePersonValue()}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="natural-person"
                      >
                        {t('NaturalPerson')}
                      </label>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="legal-person"
                        id="legal-person"
                        value={!naturalPerson}
                        checked={!naturalPerson}
                        onChange={() => handleChangePersonValue()}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="legal-person"
                      >
                        {t('LegalPerson')}
                      </label>
                    </div>
                  </div>
                </div>
                {naturalPerson ? (
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-left">
                      <em className="icon ni ni-user" />
                    </div>
                    <Typeahead
                      labelKey="name"
                      className="form-input-icon-left"
                      minLength={1}
                      onInputChange={handleSearch}
                      options={options}
                      placeholder={t('NameAndSurname')}
                      selected={name ? [name] : []}
                      onChange={(selectedOption) => {
                        if (selectedOption.length > 0) {
                          handleOptionSelected(selectedOption[0]);
                        } else {
                          clearSelectedOption();
                        }
                      }}
                    >
                      {({ onClear, selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton onClick={onClear} />
                          )}
                        </div>
                      )}
                    </Typeahead>
                  </div>
                ) : (
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-left">
                      <em className="icon ni ni-user" />
                    </div>
                    <AsyncTypeahead
                      id="societies-search-box"
                      className="form-input-icon-left"
                      isLoading={isLoading}
                      labelKey="label"
                      minLength={3}
                      delay={300}
                      onSearch={(query) => {
                        setName(query);
                        setIsLoading(true);

                        setTextFromSociety(query);
                        getSocietiesRegistered(query).then((societies) => {
                          setSocietiesList(societies);
                        });
                        setIsLoading(false);
                      }}
                      onChange={([society]) => {
                        if (society) {
                          handleSelectCompany(society);
                        } else {
                          clearSelectCompany();
                        }
                      }}
                      onBlur={handleAutoCompleteBlur}
                      options={societiesList}
                      useCache={false}
                      defaultInputValue={name}
                      newSelectionPrefix={`${t('Select:')} `}
                      placeholder={t('NameAndSurname')}
                      allowNew
                    >
                      {({ onClear, selected }) => (
                        <div className="rbt-aux">
                          {!!selected.length && (
                            <ClearButton onClick={onClear} />
                          )}
                        </div>
                      )}
                    </AsyncTypeahead>
                  </div>
                )}
              </div>
              <div className="form-group mt-4">
                <label className="form-label" htmlFor="partner-cif">
                  {t('FiscalIdentification')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-cc-alt2" />
                  </div>
                  <input
                    type="text"
                    name="partner-cif"
                    className="form-control"
                    value={cif}
                    onChange={handleChangeCif}
                    placeholder={t('EnterTheCifOrNif')}
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group mt-4">
            <label className="form-label" htmlFor="partner-email">
              {t('Email')}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-mail" />
              </div>
              <input
                type="email"
                name="partner-email"
                className="form-control"
                placeholder={t('EmailOfTheNewRepresentant')}
                value={email}
                onChange={handleChangeEmail}
                pattern={EMAIL_PATTERN}
              />
            </div>
            {!validEmail && <InputError errorMessage={t('InvalidEmail')} />}
          </div>

          {/* TODO: Review how to add default category */}
          {/* <div className="form-group mt-3">
            <label className="form-label" htmlFor="default-01">
              {t('Category')}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-user" />
              </div>
              <Select
                closeMenuOnSelect
                className="react-select"
                value={selectedPartnerCategoryOption}
                options={partnerCategoriesOptions}
                components={animatedComponents}
                onChange={handlePartnerCategoryOptionChange}
              />
            </div>
          </div> */}

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name || !cif || !validEmail}
          >
            {t('AddPartner')}
          </button>
        </form>
      </Modal.Body>
      {actualSociety?.isConstituted && (
        <Modal.Footer className="bg-light">
          <span className="sub-text">{t('ThePartnerWillReceiveANot')}</span>
        </Modal.Footer>
      )}
    </>
  );
}

export default AddPartnerModal;
