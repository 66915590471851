/* eslint-disable react/jsx-indent */
import { ChangeEvent, FC, useState } from 'react';

import { useTranslate } from 'hooks/useTranslate';
import DeleteButton from 'modules/_shared/components/Buttons/DeleteButton';
import EditButton from 'modules/_shared/components/Buttons/EditButton';
import Dialog from 'modules/_shared/components/Dialog';
import { folderContentIcons } from 'modules/dashboard/constants/foldersContentType';
import {
  Folder,
  FolderContent,
  Section,
} from 'modules/dashboard/types/CustomDashboard';
import { Accordion, Button } from 'react-bootstrap';
import DashboardEditContent from '../DashboardEditContent';

type DashboardEditSectionFoldersProps = {
  section: Section;
  setFolders: any;
};

type DashboardContentItemTypes =
  | 'LINK'
  | 'PDF_LINK'
  | 'VIDEO_LINK'
  | 'PDF_EMBEDDED'
  | 'VIDEO_EMBEDDED';

const DashboardEditSectionFolders: FC<DashboardEditSectionFoldersProps> = ({
  section,
  setFolders,
}) => {
  const { t } = useTranslate();

  const [folderSelected, setFolderSelected] = useState<number>(0);

  const [existingFolders, setExistingFolders] = useState<Folder[]>(
    section?.folders || []
  );
  const [newFolders, setNewFolders] = useState<Folder[]>([]);

  const [isAddingFolder, setIsAddingFolder] = useState<boolean>(false);
  const [isAddingContent, setIsAddingContent] = useState<boolean>(false);
  const [isEditingContent, setIsEditingContent] = useState<boolean>(false);

  const [editContentIndex, setEditContentIndex] = useState<number>(0);
  const [editContentTitle, setEditContentTitle] = useState<string>('');

  const deleteContent = (contentIndex: number, folder: Folder) => {
    const folderIndex = folder.index;

    const isExistingFolder = existingFolders.some(
      (folder) => folder.index === folderIndex
    );
    const isNewFolder = newFolders.some(
      (folder) => folder.index === folderIndex
    );

    let updatedExistingFolders: Folder[] = existingFolders;
    let updatedNewFolders: Folder[] = newFolders;

    if (isExistingFolder) {
      updatedExistingFolders = existingFolders.map((folder) => {
        if (folder.index === folderIndex) {
          const currentFolder = folder;
          currentFolder.content = currentFolder.content.filter(
            (content) => content.index !== contentIndex
          );

          return currentFolder;
        }
        return folder;
      });

      setExistingFolders(updatedExistingFolders);
    }

    if (isNewFolder) {
      updatedNewFolders = newFolders.map((folder) => {
        if (folder.index === folderIndex) {
          const currentFolder = folder;
          currentFolder.content = currentFolder.content.filter(
            (content) => content.index !== contentIndex
          );

          return currentFolder;
        }
        return folder;
      });

      setNewFolders(updatedNewFolders);
    }
  };

  const deleteFolder = (folder: Folder) => {
    const folderIndex = folder.index;

    const isExistingFolder = existingFolders.some(
      (folder) => folder.index === folderIndex
    );

    const isNewFolder = newFolders.some(
      (folder) => folder.index === folderIndex
    );

    let updatedExistingFolders: Folder[] = existingFolders;
    let updatedNewFolders: Folder[] = newFolders;

    if (isExistingFolder) {
      updatedExistingFolders = existingFolders.filter(
        (folder) => folder.index !== folderIndex
      );

      setExistingFolders(updatedExistingFolders);
    }

    if (isNewFolder) {
      updatedNewFolders = newFolders.filter(
        (folder) => folder.index !== folderIndex
      );

      setNewFolders(updatedNewFolders);
    }

    const allFolders = [...updatedExistingFolders, ...updatedNewFolders];

    setFolders(allFolders);
  };

  const handleSelectFolder = (index: number) => {
    if (folderSelected === index) {
      setFolderSelected(0);
      return;
    }
    setFolderSelected(index);
  };

  const handleAddFolder = () => {
    if (isAddingFolder) {
      return;
    }
    setIsAddingFolder(!isAddingFolder);
    newFolders.push({
      index: existingFolders.length + newFolders.length + 1,
      title: '',
      content: [],
    });
  };

  const handleAddContent = (folder: Folder) => {
    setIsAddingContent(true);
  };

  const handleEditContent = (content: FolderContent) => {
    setEditContentIndex(content.index);
    setEditContentTitle(content.title);
    setIsEditingContent(true);
  };

  const handleEditContentTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setEditContentTitle(e.target.value);
  };

  const handleEditCurrentFolder = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setNewFolders((prevFolders) => {
      const updatedFolders = [...prevFolders];
      const folderToUpdate = updatedFolders.find(
        (folder) => folder.index === index
      );
      if (folderToUpdate) {
        folderToUpdate.title = event.target.value;
      }
      return updatedFolders;
    });
  };

  const handleSaveCurrentContent = (folder: Folder, content: FolderContent) => {
    const currentFolderIndex = folderSelected;
    const isExistingFolder = existingFolders.some(
      (folder) => folder.index === currentFolderIndex
    );
    const isNewFolder = newFolders.some(
      (folder) => folder.index === currentFolderIndex
    );

    let updatedExistingFolders: Folder[] = existingFolders;
    let updatedNewFolders: Folder[] = newFolders;

    if (isExistingFolder) {
      updatedExistingFolders = existingFolders.map((folder) => {
        if (folder.index === currentFolderIndex) {
          const currentFolder = folder;
          currentFolder.content = currentFolder.content.map((folderContent) => {
            if (folderContent.index === editContentIndex) {
              const currentContent = folderContent;
              currentContent.title = editContentTitle;

              return currentContent;
            }
            return folderContent;
          });

          return currentFolder;
        }
        return folder;
      });

      setExistingFolders(updatedExistingFolders);
    }

    if (isNewFolder) {
      updatedNewFolders = newFolders.map((folder) => {
        if (folder.index === currentFolderIndex) {
          const currentFolder = folder;
          currentFolder.content = currentFolder.content.map((folderContent) => {
            if (folderContent.index === editContentIndex) {
              const currentContent = folderContent;
              currentContent.title = editContentTitle;

              return currentContent;
            }
            return folderContent;
          });

          return currentFolder;
        }
        return folder;
      });

      setNewFolders(updatedNewFolders);
    }

    const allFolders = [...updatedExistingFolders, ...updatedNewFolders];

    setFolders(allFolders);
    setEditContentIndex(0);
    setEditContentTitle('');
    setIsEditingContent(false);
  };

  const handleSaveCurrentFolder = () => {
    const allFolders = [...existingFolders, ...newFolders];

    setFolders(allFolders);
    setIsAddingFolder(false);
  };

  const handleDeleteFolder = (folder: Folder) => {
    Dialog({
      icon: 'ni-trash',
      title: t('AreYouSure'),
      body: t('DeleteFolderConfirmation'),
      buttonText: t('YesDelete'),
      onConfirm: async () => deleteFolder(folder),
    });
  };

  const handleDeleteContent = (contendIndex: number, folder: Folder) => {
    Dialog({
      icon: 'ni-trash',
      title: t('AreYouSure'),
      body: t('DeleteContentConfirmation'),
      buttonText: t('YesDelete'),
      onConfirm: async () => deleteContent(contendIndex, folder),
    });
  };

  const handleSaveContent = (content: FolderContent) => {
    const currentFolderIndex = folderSelected;
    const isExistingFolder = existingFolders.some(
      (folder) => folder.index === currentFolderIndex
    );
    const isNewFolder = newFolders.some(
      (folder) => folder.index === currentFolderIndex
    );

    let updatedExistingFolders: Folder[] = existingFolders;
    let updatedNewFolders: Folder[] = newFolders;

    if (isExistingFolder) {
      updatedExistingFolders = existingFolders.map((folder) => {
        if (folder.index === currentFolderIndex) {
          const currentFolder = folder;
          currentFolder.content.push(content);

          return currentFolder;
        }
        return folder;
      });

      setExistingFolders(updatedExistingFolders);
    }

    if (isNewFolder) {
      updatedNewFolders = newFolders.map((folder) => {
        if (folder.index === currentFolderIndex) {
          const currentFolder = folder;

          currentFolder.content.push(content);

          return currentFolder;
        }
        return folder;
      });

      setNewFolders(updatedNewFolders);
    }

    const allFolders = [...updatedExistingFolders, ...updatedNewFolders];

    setFolders(allFolders);
    setIsAddingContent(false);
  };

  return (
    <>
      <div className="col-12 mb-2">
        <h5>{t('Folders')}</h5>
      </div>

      <div className="col-12 mb-3">
        <div className="card card-bordered">
          <Accordion id="section-folders">
            {[...existingFolders, ...newFolders].map((folder: Folder) => (
              <>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey={String(folder.index)}
                  className="accordion-item"
                  onClick={() => handleSelectFolder(folder?.index)}
                >
                  {isAddingFolder &&
                  folder.index ===
                    existingFolders.length + newFolders.length ? (
                    <>
                      <div className="col-8">
                        <input
                          type="text"
                          placeholder={t('FolderTitle')}
                          className="form-control"
                          value={folder.title}
                          onChange={(event) =>
                            handleEditCurrentFolder(event, folder.index)
                          }
                        />
                      </div>
                      <div className="col-4">
                        <button
                          type="button"
                          className="btn btn-block btn-outline-primary"
                          onClick={() => handleSaveCurrentFolder()}
                        >
                          <em className="icon ni ni-plus mr-1" />

                          {t('Save')}
                        </button>
                      </div>
                    </>
                  ) : (
                    <span
                      className={`accordion-head cursor-pointer text-left ${
                        folderSelected !== folder?.index ? 'collapsed' : ''
                      }`}
                    >
                      <h6 className="title">{folder?.title}</h6>
                      <span className="accordion-icon" />
                    </span>
                  )}
                </Accordion.Toggle>

                {!isAddingFolder && (
                  <Accordion.Collapse
                    eventKey={String(folder.index)}
                    className="accordion-inner"
                  >
                    <>
                      <ul className="nk-menu-sub">
                        {folder?.content?.map((content: FolderContent) => (
                          <li className="nk-menu-item d-flex flex-row justify-content-between">
                            <div className="d-flex align-items-center w-100">
                              <span className="nk-file-content-type">
                                <em
                                  className={`fs-20px icon ${
                                    folderContentIcons[
                                      content.type as DashboardContentItemTypes
                                    ]
                                  }`}
                                />
                              </span>

                              {isEditingContent &&
                              editContentIndex === content.index ? (
                                <div className="row w-100">
                                  <div className="col-8">
                                    <input
                                      type="text"
                                      placeholder={t('FolderTitle')}
                                      className="form-control w-100"
                                      value={editContentTitle}
                                      onChange={handleEditContentTitle}
                                    />
                                  </div>
                                  <div className="col-4">
                                    <button
                                      type="button"
                                      className="btn btn-block btn-outline-primary"
                                      onClick={() =>
                                        handleSaveCurrentContent(
                                          folder,
                                          content
                                        )
                                      }
                                    >
                                      <em className="icon ni ni-plus mr-1" />

                                      {t('Save')}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <span className="title">{content.title}</span>
                              )}
                            </div>

                            {!isEditingContent && (
                              <span className="nk-file-content-actions gap-30px d-flex align-items-center">
                                <EditButton
                                  className=""
                                  action={() => handleEditContent(content)}
                                />
                                <DeleteButton
                                  className="ml-1"
                                  action={() =>
                                    handleDeleteContent(content.index, folder)
                                  }
                                />
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>

                      {isAddingContent && (
                        <DashboardEditContent
                          folder={folder}
                          onSave={handleSaveContent}
                        />
                      )}

                      {!isAddingContent && (
                        <div className="d-flex flex-row justify-content-between w-100">
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => handleAddContent(folder)}
                            disabled={isAddingContent}
                          >
                            <em className="icon ni ni-plus mr-1" />

                            {t('AddContent')}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => handleDeleteFolder(folder)}
                            disabled={isAddingContent}
                          >
                            <em className="icon ni ni-trash mr-1" />

                            {t('DeleteFolder')}
                          </button>
                        </div>
                      )}
                    </>
                  </Accordion.Collapse>
                )}
              </>
            ))}
          </Accordion>
        </div>
      </div>

      <div className="col-12">
        <button
          type="button"
          className="btn btn-block btn-outline-primary"
          onClick={handleAddFolder}
          disabled={isAddingFolder}
        >
          <em className="icon ni ni-plus mr-1" />

          {t('AddFolder')}
        </button>
      </div>
    </>
  );
};

export default DashboardEditSectionFolders;
