import { Investor } from 'types';

type Props = {
  investor: Investor;
  draftPartners: any[];
  defaultClassId: string;
};

const getDraftPartner = (draftPartners: any[], investor: Investor) => {
  const draftPartner = draftPartners.find(
    (partner) =>
      partner?.societyPartnerId !== null &&
      partner?.societyPartnerId === investor?.societyPartnerId
  );
  return draftPartner;
};

export const getInitialHoldingClassId = ({
  investor,
  draftPartners,
  defaultClassId,
}: Props) => {
  if (investor?.holdingClass) {
    return investor.holdingClass;
  }

  const draftPartner = getDraftPartner(draftPartners, investor);
  if (draftPartner?.holdingClass) {
    return draftPartner.holdingClass;
  }

  return defaultClassId;
};
