import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

type Tab = { key: string; title: string; icon: string };

type DetailsTabProps = {
  tab: Tab;
  index: number;
  currentPage: number;
  onChangePage: any;
};

const DetailsTab: FC<DetailsTabProps> = ({
  tab,
  index,
  currentPage,
  onChangePage,
}) => {
  const { t } = useTranslate();

  return (
    <li className="nav-item" key={tab.key} style={{ flex: 1, paddingRight: 0 }}>
      <p
        className={`nav-link px-2 ${currentPage === index ? 'active' : ''}`}
        onClick={() => onChangePage(index)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <em className={`icon ${tab.icon}`} />
        <span>{t(tab.title)}</span>
      </p>
    </li>
  );
};

export default DetailsTab;
