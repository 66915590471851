/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';

import screens from 'constants/screens';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';

import societyOptions from './societyOptions';

function tableColumns(i18n) {
  return [
    {
      // mandatory props
      name: i18n.t('Society'),
      field: 'name',
      selector: (row) => row[i18n.t('Society')],
      sortable: true,
      // optional props
      grow: 4,
      cell: (row) =>
        CellData.Society({
          society: row.society,
          url: '/sociedad',
          type: 'light',
        }),
      export: true,
    },
    {
      name: i18n.t('Partners'),
      field: 'partners',
      selector: (row) => row[i18n.t('Partners')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.SocietyPartners({
          partners: row[i18n.t('Partners')],
          isAdmin: true,
          society: row.society,
        }),
      export: true,
    },
    {
      name: i18n.t('Value'),
      field: 'value',
      selector: (row) => row[i18n.t('Value')]?.value,
      sortable: true,
      center: true,
      grow: 2,
      compact: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.SocietyValue({
          value: row[i18n.t('Value')],
        }),
      export: true,
    },
    {
      name: i18n.t('ConstDate'),
      field: 'creationDate',
      selector: (row) => row[i18n.t('ConstDate')],
      sortable: true,
      compact: true,
      center: true,
      hide: screens.LG,
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('ConstDate')],
          monthYear: true,
        }),
      export: true,
    },
    // TODO* this column looks like it's not being used
    {
      name: i18n.t('LastModification'),
      field: '',
      selector: (row) => row[i18n.t('LastModification')],
      sortable: true,
      compact: true,
      center: true,
      omit: true,
      hide: screens.LG,
      cell: (row) =>
        CellData.ColoredDate({
          // date: row['Ult. Modificación'],
          date: '8/1/2022',
          isAdmin: row.isAdmin,
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) =>
        !row?.isDemo && (
          <ul
            className="nk-tb-actions gx-1"
            style={{
              zIndex: 999,
            }}
          >
            <li>
              <div className="dropdown">
                <MenuDots
                  menuOptions={societyOptions(i18n)}
                  id={row.society['_id']}
                  direction="left"
                  params={{
                    societyName: row.society?.name,
                    user: row?.user,
                    mixpanel: row?.mixpanel,
                  }}
                />
              </div>
            </li>
          </ul>
        ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}
export default tableColumns;
