/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import sizes from 'constants/sizes';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getBoard } from 'modules/boards/redux/boardActions';
import { store } from 'redux/store';

import AssistentsModal from 'modules/boards/components/AssistentsModal/AssistentsModal';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import BoardAssistantsMetrics from 'modules/boards/components/BoardAssistantsMetrics';
import BoardAssistantsTable from 'modules/boards/components/BoardAssistantsTable';
import { useGetBoardsSectionPermissions } from 'modules/boards/hooks/useGetBoardsSectionPermissions';

import getSummaryByCapital from './utils/getSummaryByCapital';

const BoardAttendance = ({ board }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const { isReadOnly } = useGetBoardsSectionPermissions().data;

  const [isUpdatingAssistance, setIsUpdatingAssistance] = useState(false);

  const [attendanceSummaryByCapital, setAttendanceSummaryByCapital] = useState({
    assistants: 0,
    represented: 0,
    absents: 0,
    pending: 0,
    quorum: 0,
  });

  const updateAssistance = () => {
    const capitals = getSummaryByCapital(board, actualSociety);

    setAttendanceSummaryByCapital({
      ...capitals,
      quorum: capitals.assistants + capitals.represented,
    });

    setIsUpdatingAssistance(false);
  };

  const handleUpdateAssistance = async () => {
    if (isReadOnly) return;
    setIsUpdatingAssistance(true);
    try {
      await store.dispatch(getBoard(board?._id));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.BOARD_UPDATE_ATTENDANCE_ERROR));
    }
  };

  const handleRegisterAttendees = () => {
    if (isReadOnly) return;
    dispatch(
      setModal(
        <AssistentsModal
          society={actualSociety}
          boardId={board?._id}
          assistants={board?.participants}
          size={sizes.XL}
        />
      )
    );
  };

  useEffect(() => {
    if (board && actualSociety) {
      updateAssistance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, actualSociety]);

  return (
    <>
      <div className="nk-block pt-4">
        <div className="d-flex justify-content-end mb-2">
          <button
            type="button"
            className="btn btn-outline-light mr-2"
            onClick={handleUpdateAssistance}
            disabled={isReadOnly}
          >
            <em className="icon ni ni-reload" />
            <span> {t('UpdateAttendance')}</span>
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleRegisterAttendees}
            disabled={isReadOnly}
          >
            {t('RegisterAttendees')}
          </button>
        </div>

        {attendanceSummaryByCapital && (
          <BoardAssistantsMetrics metrics={attendanceSummaryByCapital} />
        )}
      </div>

      <div id="assistantsList" className="mt-4">
        <BoardAssistantsTable
          board={board}
          attendanceSummaryByCapital={attendanceSummaryByCapital}
        />
      </div>

      {isUpdatingAssistance && <CustomLoading />}
    </>
  );
};

export default BoardAttendance;
