import { useEffect, useState } from 'react';

import { SelectorOption } from 'types';

import vestingOptions from 'constants/vestingOptions';

export function useGetVestingOptions() {
  const [resultVestingOptions, setResultVestingOptions] = useState<
    SelectorOption[]
  >([]);

  useEffect(() => {
    const options = vestingOptions.map((option: any) => ({
      value: option.value,
      label: option.text,
    }));

    setResultVestingOptions(options);
  }, []);

  return {
    data: { resultVestingOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
