export const fileTypes = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const params = [
  'Nombre de la sociedad',
  'Número de identificación fiscal',
  'Dirección',
  'Código postal',
  'Ciudad',
  'País',
];

export const labels = {
  'Nombre de la sociedad': 'Sociedad',
  'Número de identificación fiscal': 'CIF',
  Dirección: 'Dirección',
  'Código postal': 'Código Postal',
  Ciudad: 'Ciudad',
  País: 'País',
};

export const fields = {
  'Nombre de la sociedad': 'name',
  'Número de identificación fiscal': 'cif',
  Dirección: 'line1',
  'Código postal': 'zip',
  Ciudad: 'city',
  País: 'country',
};

export const requiredParams = [
  'Nombre de la sociedad',
  'Número de identificación fiscal',
];
