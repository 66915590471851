export default {
  ALL: 'ALL',
  DOCUMENTS: 'DOCUMENTS',
  SCRIPTURES: 'SCRIPTURES',
  MINUTEBOOK: 'MINUTEBOOK',
  AUTHORITY: 'AUTHORITY',
  ASSIGNMENTS: 'ASSIGNMENTS',
  ANNUALREPORTS: 'ANNUALREPORTS',
  SIGNATURES: 'SIGNATURES',
  TEMPLATES: 'TEMPLATES',
  LETTERS: 'LETTERS',
  CERTIFICATES: 'CERTIFICATES',
  BOARDS: 'BOARDS',
  DELETED: 'DELETED',
  MYFOLDERS: 'MYFOLDERS',
  COUNCILMINUTES: 'COUNCILMINUTES',
};
