import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getActualSociety, getBeneficiaryAmount } from 'utils/filters';

import menuTypes from 'constants/menuTypes';
import i18n from 'i18n/config';
import BeneficiaryCard from 'modules/beneficiaries/components/BeneficiaryCard';
import { calculateConsolidationPercent } from 'utils/consolidationPeriods';

// TODO* move to constants file
const beneficiaryStatus = {
  CONSOLIDATED: i18n.t('Consolidated'),
  ACTIVE: i18n.t('Active'),
  CANCELED: i18n.t('Canceled'),
};

const BeneficiaryFiles = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const plans = useSelector((state) => state?.plans);

  const [totalShares, setTotalShares] = useState();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    dispatch(getStockPlans(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety && plans.length) {
      setTotalShares(
        actualSociety.sharesCount?.actual +
          actualSociety.sharesCount?.future +
          actualSociety.sharesCount?.drafts
      );
      const filteredBeneficiaries = actualSociety?.beneficiaries?.filter(
        (beneficiary) =>
          !isAdmin ? user['_id'] === beneficiary.user : !beneficiary?.isDraft
      );

      setCards(
        filteredBeneficiaries.map((beneficiary) => {
          const plan = plans?.find((plan) => plan['_id'] === beneficiary.plan);
          let percent;
          if (beneficiary?.isCanceled)
            percent = Number(
              (
                (beneficiary.finalConsolidatedUnits * 100) /
                beneficiary.sharesCount.future
              ).toFixed(2)
            );
          else
            percent = calculateConsolidationPercent(
              Date.now(),
              beneficiary,
              plan
            );

          let currentStatus;
          if (beneficiary?.isCanceled)
            currentStatus = beneficiaryStatus.CANCELED;
          else if (percent < 100) currentStatus = beneficiaryStatus.ACTIVE;
          else currentStatus = beneficiaryStatus.CONSOLIDATED;

          return (
            <BeneficiaryCard
              beneficiary={{
                ...beneficiary,
                amount: getBeneficiaryAmount(beneficiary, plan),
                status: currentStatus,
                planId: beneficiary.plan,
                planName: plan.name,
              }}
              totalShares={totalShares}
              society={actualSociety}
              key={beneficiary['_id']}
            />
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, actualSociety, societyId, totalShares, plans]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes?.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
          <p />
        </div>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('BeneficiatedFile')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {`${actualSociety?.name} ${t('HaveBeneficiaries', {
                    count: actualSociety?.beneficiaries?.filter(
                      (beneficiary) => !beneficiary?.isDraft
                    ).length,
                  })}`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-gs">{cards}</div>
      </div>
    </div>
  );
};

export default BeneficiaryFiles;
