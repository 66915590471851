import { useEffect, useState } from 'react';

type Props = {
  hasPreview: boolean;
};

export function useGetFullScreen({ hasPreview }: Props) {
  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const handleToggleFullScreen = () => {
    setFullScreen((prev) => !prev);
  };

  useEffect(() => {
    if (!hasPreview) {
      setFullScreen(false);
    }
  }, [hasPreview]);

  return {
    data: { fullScreen },
    isLoading: false,
    isError: false,
    actions: { handleToggleFullScreen },
  };
}
