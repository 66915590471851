/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import eventTypes from 'constants/eventTypes';
import { store } from 'redux/store';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import 'modules/_shared/components/Modals/Modals.scss';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import sendEmail from 'modules/communications/redux/mailActions';
import useGetPartnerEmails from 'modules/partners/modals/EditPartner/hooks/useGetEmails';
import { updatePartner } from 'modules/partners/redux/partnerActions';

import { getActualSociety } from 'utils/filters';
import trackEvent from 'utils/trackEvent';
import { createShareEmail } from './utils/createShareEmail';

function PartnerDetailInviteModal({ partner }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [email, setEmail] = useState('');

  const partnerEmails = useGetPartnerEmails({
    partner,
    partnerEmails: partner?.emails || [],
  });

  const sendNotificationEmail = () => {
    const { language } = i18n;
    const emailData = createShareEmail({
      t,
      language,
      user,
      email,
      actualSociety,
      partner,
    });

    dispatch(sendEmail(emailData));
  };

  async function sendInvitation(event) {
    event.preventDefault();

    try {
      const societyId = actualSociety._id;
      const userId = user._id;

      const emails = partner?.emails || partnerEmails || [];
      emails.push({
        text: email,
        category: 'SECONDARY',
      });

      await store.dispatch(
        updatePartner({
          id: partner?._id,
          data: {
            ...partner,
            emails,
            userId,
            societyId,
          },
        })
      );

      sendNotificationEmail();
      getActualSociety(user, societyId);

      const currentEvent = eventTypes.INVITE_PARTNER_DETAIL;
      trackEvent(mixpanel, currentEvent, {
        userId: user?.['_id'],
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
        operation: currentEvent,
        invitedEmail: email,
      });
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_SEND_EMAIL));
    } finally {
      dispatch(setModal(null));
    }
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('InvitePartnerModalTitle')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={sendInvitation}>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  {t('Email')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-mail" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t('EmailOfTheInvited')}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    pattern={EMAIL_PATTERN}
                  />
                </div>
              </div>
              <span className="text-soft mt-1">
                {t('InvitePartnerModalMessage')}
              </span>
            </div>
          </div>

          <button type="submit" className="btn btn-lg btn-primary mt-4">
            {t('SendInvitation')}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">
          {t('TheInvitedWillReceiveANotification')}
        </span>
      </Modal.Footer>
    </>
  );
}

export default PartnerDetailInviteModal;
