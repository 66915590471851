import { SocietyChart } from 'types';
import { formatNumberDecimals } from 'utils/formats';

const getPercentInfo = (society: SocietyChart) => {
  const { holdingShares, holdingPercentND, holdingPercentFD } = society;

  if (holdingShares) {
    const prefix = `${formatNumberDecimals(holdingPercentND)}% ND`;
    const suffix = `${formatNumberDecimals(holdingPercentFD)}% FD`;

    return `${prefix} / ${suffix}`;
  }

  return `0% ND / 0% FD`;
};

export default getPercentInfo;
