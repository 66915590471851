import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getGlobalNotificationsBySociety } from 'modules/_shared/redux/globalNotificationActions';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { State } from 'redux/initialState';

import menuTypes from 'constants/menuTypes';

import { getActualSociety } from 'utils/filters';

import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';

import { useTranslate } from 'hooks/useTranslate';
import notificationOptions from './utils/notificationOptions';
import tableColumns from './utils/tableColumns';

const EmployeesNotificationsList = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslate();
  const { societyId } = useParams();

  const user = useSelector((state: State) => state.user);

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const notifications = useSelector(
    (state: State) => state?.globalNotifications
  );

  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );
  const [currentNotificationOptions, setCurrentNotificationOptions] = useState(
    notificationOptions(i18n)
  );

  const getPageDescription = (data: any[], t: any) => {
    if (data.length === 0) {
      return t('EmployeesNoNotifications', {
        societyName: actualSociety?.name,
      });
    }

    return t('EmployeesHaveNotifications', {
      societyName: actualSociety?.name,
      count: data.length,
    });
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (societyId) {
      dispatch(getGlobalNotificationsBySociety(societyId));
    }
  }, [societyId]);

  useEffect(() => {
    const notificationsData: any[] = notifications
      ?.filter((notification) => notification.status !== 'DELETED')
      ?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      ?.map((notification) => ({
        ...notification,
        employeeName: notification?.reference?.name || '',
        actions: currentNotificationOptions,
      }));

    if (notificationsData.length > 0) {
      setTableData(
        transformData({
          data: notificationsData,
          columns: tableColumns(i18n),
        })
      );
    }
  }, [notifications]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
    setCurrentNotificationOptions(notificationOptions(i18n));
  }, [i18n.language]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <PageListHeader
          title={actualSociety?.name}
          subTitle={t('EmployeesNotifications')}
          description={getPageDescription(notifications, t)}
        />

        <CustomDataTable
          data={tableData}
          columns={currentTableColumns}
          searchBy={[t('Notification')]}
          searchByPlaceholder={t('name')}
          pagination
          className="nk-tb-list"
          actions
          showDense
          fileName={`${t('EmployeesList')} ${actualSociety?.name}`}
        />
      </div>
    </div>
  );
};

export default EmployeesNotificationsList;
