import React from 'react';
import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';

import CancelBeneficiaryModal from 'modules/beneficiaries/modals/CancelBeneficiary';

export default function cancelBeneficiary(params, beneficiaryId) {
  const { data } = params;
  store.dispatch(
    setModal(
      <CancelBeneficiaryModal
        beneficiaryId={beneficiaryId}
        beneficiary={data}
      />
    )
  );
}
