import { Board, BoardParticipant, Society } from 'types';
import { getPartnerById, getPartnerNDPercent } from 'utils/filters';

type SummaryByCapital = {
  assistants: number;
  represented: number;
  absents: number;
  delegated: number;
  pending: number;
};

const getSummaryByCapital = (
  board: Board,
  actualSociety: Society
): SummaryByCapital => {
  const capitals: SummaryByCapital = board.participants.reduce(
    (acc, participant: BoardParticipant) => {
      const partner = getPartnerById(actualSociety, participant.member);
      const percent = getPartnerNDPercent(partner, actualSociety);

      if (participant?.hasDelegated) {
        acc.delegated += percent || 0;
      }
      if (participant.isRepresented && participant.assists) {
        acc.represented += percent || 0;
      } else if (participant.assists) {
        acc.assistants += percent || 0;
      } else if (participant?.isAbsent) {
        acc.absents += percent || 0;
      }

      return acc;
    },
    {
      assistants: 0,
      represented: 0,
      absents: 0,
      delegated: 0,
      pending: 0,
    }
  );

  const notPending =
    +capitals.assistants + +capitals.represented + +capitals.absents;
  capitals.pending = +(100 - notPending).toFixed(10);

  if (Math.abs(capitals.pending) < 1e-10) {
    capitals.pending = 0;
  }
  return capitals;
};

export default getSummaryByCapital;
