import { BoardNewParticipant } from 'types';

export const getPartnerRepresentantsFromParticipantsList = (
  participants: BoardNewParticipant[]
): string[] => {
  if (!participants || !participants?.length) {
    return [];
  }

  const representants = participants
    .filter((participant) => participant?.hasPartnerRepresentant)
    .map((participant) => participant.representative) as string[];

  return representants;
};
