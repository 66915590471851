/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getBeneficiaryData } from 'modules/beneficiaries/redux/beneficiaryActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { getPartnerCategories } from 'modules/partners/redux/partnerCategoryActions';
import { getUsers, updateUser } from 'modules/profile/redux/userActions';
import { store } from 'redux/store';

import { getActualSociety } from 'utils/filters';

import civilStatus from 'constants/civilStatus';
import { SELECCIONAR_SOCIO, SELECT_CATEGORY } from 'constants/defaultConstants';
import { dateFormatInverted } from 'constants/formats';
import legalPersonDocumentTypes from 'constants/legalPersonDocumentTypes';
import userTypes from 'constants/userTypes';

import sendEmail from 'modules/communications/redux/mailActions';
import { createAdminEmail } from 'modules/society/components/SocietyAdministrator/utils';
import representativeTypes from 'modules/society/components/SocietyRepresentative/representativeTypes';
import representativeTypesOptions from 'modules/society/components/SocietyRepresentative/representativeTypesOptions';
import statusTypesOptions from 'modules/society/components/SocietyRepresentative/statusTypeOptions';
import statusTypes from 'modules/society/components/SocietyRepresentative/statusTypes';

import { useTranslate } from 'hooks/useTranslate';
import EditAddressForm from './components/EditAddressForm';
import EditLegalPersonRepresentationForm from './components/EditLegalPersonRepresentationForm';
import EditLegalPersonScripturesForm from './components/EditLegalPersonScripturesForm';
import EditModalHeaderOptions from './components/EditModalHeaderOptions';
import EditModalNavTabs from './components/EditModalNavTabs';
import EditOtherInfoForm from './components/EditOtherInfoForm';
import EditPersonalInformationForm from './components/EditPersonalInformationForm';
import rolesWithMultipleCategories from './constants/rolesWithMultipleCategories';
import getUserCategoriesFromPartner from './utils/getUserCategoriesFromPartner';
import sortEmailsAndCategories from './utils/sortEmailsAndCategories';
import uploadDocument from './utils/uploadDocument';
import { createPartnerData } from './utils/userCreation';

import './EditModal.scss';

const userLabels = {
  [userTypes.PARTNER]: () => 'Partner',
  [userTypes.BENEFICIARY]: () => 'Beneficiary',
  [userTypes.BENEFICIARY_DRAFT]: () => 'Beneficiary draft',
  [userTypes.INVESTOR]: () => 'Investor',
  [userTypes.USER]: () => 'User',
  [userTypes.ADMIN]: () => 'Admin',
};

const EditModal = ({
  user,
  societyId,
  userType,
  setBeneficiarySocietyData,
}) => {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const documents = useSelector((state) => state?.documents);
  const loggedUser = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const partnerCategories = useSelector((state) => state?.partnerCategories);

  const [page, setPage] = useState(0);
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email);
  const [cif, setCif] = useState(user?.cif);
  const [jobTitle, setJobTitle] = useState(user?.jobTitle || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [birthdate, setBirthdate] = useState(
    user?.birthdate ? format(new Date(user?.birthdate), dateFormatInverted) : ''
  );
  const [image, setImage] = useState(user?.image);
  const [nationality, setNationality] = useState(user?.nationality);
  const [line1, setLine1] = useState(user?.address?.line1 || '');
  const [city, setCity] = useState(user?.address?.city || '');
  const [zip, setZip] = useState(user?.address?.zip || '');
  const [country, setCountry] = useState(user?.address?.country || '');
  const [sindication, setSindication] = useState(
    actualSociety?.partners?.find(
      (partner) => partner?.['_id'] === user?.sindication
    )
  );
  const [emails, setEmails] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [civilStatusSelected, setCivilStatusSelected] = useState(
    user?.civilStatus || ''
  );
  const [civilStatusOptionValue, setCivilStatusOptionValue] = useState('');
  const [sindicationOptionValue, setSindicationOptionValue] = useState({
    value: SELECCIONAR_SOCIO,
    label: SELECCIONAR_SOCIO,
  });
  const [sindicationPartnerOptions, setSindicationPartnerOptions] = useState(
    []
  );
  const [employeeNumber, setEmployeeNumber] = useState(
    user?.employeeNumber || ''
  );
  const [hasTaxAddress, setHasTaxAddress] = useState(
    !!(
      user?.taxAddress?.line1 ||
      user?.taxAddress?.city ||
      user?.taxAddress?.zip ||
      user?.taxAddress?.country
    )
  );
  const [taxLine1, setTaxLine1] = useState(user?.taxAddress?.line1 || '');
  const [taxCity, setTaxCity] = useState(user?.taxAddress?.city || '');
  const [taxZip, setTaxZip] = useState(user?.taxAddress?.zip || '');
  const [taxCountry, setTaxCountry] = useState(user?.taxAddress?.country || '');
  const [bankAccountNumber, setBankAccountNumber] = useState(
    user?.bankAccountNumber || ''
  );
  const [hideAccountNumber, setHideAccountNumber] = useState(
    !!user?.bankAccountNumber
  );

  const [constitutionFile, setConstitutionFile] = useState();
  const [constitutionDocumentName, setConstitutionDocumentName] = useState();
  const [appointmentFile, setAppointmentFile] = useState();
  const [appointmentDocumentName, setAppointmentDocumentName] = useState();
  const [ownershipFile, setOwnershipFile] = useState();
  const [ownershipDocumentName, setOwnershipDocumentName] = useState();
  const [register, setRegister] = useState();
  const [section, setSection] = useState();
  const [sheet, setSheet] = useState();
  const [representativeName, setRepresentativeName] = useState();
  const [representativeEmail, setRepresentativeEmail] = useState();
  const [representativeInitDate, setRepresentativeInitDate] = useState();
  const [representativeEndDate, setRepresentativeEndDate] = useState();
  const [representativeType, setRepresentativeType] = useState();
  const [representativeStatus, setRepresentativeStatus] = useState();
  const [typeOptionValue, setTypeOptionValue] = useState();
  const [statusOptionValue, setStatusOptionValue] = useState();
  const [representativeNif, setRepresentativeNif] = useState();
  const [representativeAddress, setRepresentativeAddress] = useState();
  const [representativeNationality, setRepresentativeNationality] = useState();
  const [naturalPerson, setNaturalPerson] = useState();
  const [partnerCategory, setPartnerCategory] = useState('');
  const [partnerCategoriesOptions, setPartnerCategoriesOptions] = useState([]);
  const [selectedPartnerCategoryOption, setSelectedPartnerCategoryOption] =
    useState({
      value: SELECT_CATEGORY,
      label: t('SelectCategory'),
    });

  const setJuridicPersonValues = () => {
    const { representative } = user;
    setRepresentativeName(representative?.name);
    setRepresentativeEmail(representative?.email);
    if (representative?.initDate) {
      setRepresentativeInitDate(
        format(new Date(representative.initDate), 'yyyy-MM-dd')
      );
    }
    if (representative?.endDate) {
      setRepresentativeEndDate(
        format(new Date(representative.endDate), 'yyyy-MM-dd')
      );
    }
    if (representative?.authority) {
      setRepresentativeType(representative?.authority);
      setTypeOptionValue({
        label: representativeTypes.name[representative?.authority],
        value: representative?.authority,
      });
    }
    if (representative?.status) {
      setRepresentativeStatus(representative?.status);
      setStatusOptionValue({
        label: statusTypes.name[representative?.status],
        value: representative?.status,
      });
    }
    setRepresentativeNif(representative?.nif);
    setRepresentativeNationality(representative?.nationality);
    setRepresentativeAddress(representative?.address);
  };

  const hasPersonOptions = () => userType === userTypes.PARTNER;

  const handleUploadDocument = async (file, subcategory) => {
    await uploadDocument({
      file,
      subcategory,
      user,
      societyId,
    });
  };

  const handleChangeCivilStatus = (selectedOption) => {
    setCivilStatusSelected(selectedOption.value);
    setCivilStatusOptionValue(selectedOption);
  };

  const handleChangeSelectedPartner = (selectedOption) => {
    setSindication(selectedOption.value);
    setSindicationOptionValue(selectedOption);
  };

  const handleChangeSelectedType = (selectedOption) => {
    setRepresentativeType(selectedOption.value);
    setTypeOptionValue(selectedOption);
  };

  const handleChangeSelectedStatus = (selectedOption) => {
    setRepresentativeStatus(selectedOption.value);
    setStatusOptionValue(selectedOption);
  };

  const handleChangeTaxAddress = () => {
    if (hasTaxAddress) {
      setTaxLine1('');
      setTaxCity('');
      setTaxZip('');
      setTaxCountry('');
    }
    setHasTaxAddress(!hasTaxAddress);
  };

  const handleHideAccountNumber = () => {
    setHideAccountNumber(!hideAccountNumber);
  };

  const handleChangePersonValue = () => {
    if (!naturalPerson && page > 1) {
      setPage(1);
    }
    setNaturalPerson(!naturalPerson);
  };

  const handlePartnerCategoryOptionChange = (selectedOption) => {
    setPartnerCategory(selectedOption.value);
    setSelectedPartnerCategoryOption(selectedOption);
  };

  const isValidEmail = (email) => {
    if (email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
  };

  const fileSelected = async (event) => {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const getEmailsAndCategories = async (users) => {
    if (!users?.length) return;

    try {
      const queryParam = { usersList: users };
      const partnerUsersList = await dispatch(getUsers(queryParam));

      if (!partnerUsersList?.length) return;

      let emailsList = partnerUsersList?.length
        ? partnerUsersList.map((curr) => curr.email)
        : [''];

      if (rolesWithMultipleCategories.includes(userType)) {
        const emailsWithCategory = await getUserCategoriesFromPartner(
          user,
          partnerUsersList
        );
        const currentCategories = emailsList.map((currentEmail) => {
          const { category } = emailsWithCategory.find(
            (emailsWithCategory) => emailsWithCategory.email === currentEmail
          );
          return category;
        });

        const { sortedEmails, sortedCategories } = sortEmailsAndCategories(
          emailsList,
          currentCategories
        );

        emailsList = sortedEmails;
        setCategories(sortedCategories);
      }

      setEmails(emailsList);

      const lastUser = emailsList[emailsList.length - 1];
      setCurrentEmail(lastUser);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const getUserLabel = (user) => t(userLabels[user]?.() ?? '');

  const onUpdateUser = async (event) => {
    event.preventDefault();
    try {
      const newData = {
        name,
        email,
        phone,
        birthdate,
        image,
        nationality,
        address: {
          line1,
          city,
          zip,
          country,
        },
      };

      const taxAddress = {
        line1: hasTaxAddress ? taxLine1 : '',
        city: hasTaxAddress ? taxCity : '',
        zip: hasTaxAddress ? taxZip : '',
        country: hasTaxAddress ? taxCountry : '',
      };

      if (userType === userTypes.INVESTOR) {
        await dispatch(
          updateUser(
            user?.user,
            {
              investor: user?.['_id'],
              society: societyId,
              investorData: { ...newData },
            },
            loggedUser?.['_id']
          )
        );
        getActualSociety(loggedUser, societyId);
        dispatch(setModal(null));
        return;
      }

      if (userType === userTypes.PARTNER) {
        const partnerData = createPartnerData({
          currentEmail,
          emails,
          categories,
          cif,
          partnerCategory,
          sindication,
          naturalPerson,
          register,
          section,
          sheet,
          employeeNumber,
          taxAddress,
          bankAccountNumber,
          civilStatusSelected,
          representativeName,
          representativeEmail,
          representativeInitDate,
          representativeEndDate,
          representativeType,
          representativeStatus,
          representativeNif,
          representativeNationality,
          representativeAddress,
        });

        if (constitutionFile) {
          await handleUploadDocument(
            constitutionFile,
            legalPersonDocumentTypes.CONSTITUTION
          );
        }
        if (appointmentFile) {
          await handleUploadDocument(
            appointmentFile,
            legalPersonDocumentTypes.APPOINTMENT
          );
        }
        if (ownershipFile) {
          await handleUploadDocument(
            ownershipFile,
            legalPersonDocumentTypes.OWNERSHIP
          );
        }

        await dispatch(
          updateUser(
            user?.user,
            {
              partner: user?.['_id'],
              society: societyId,
              partnerData: {
                ...newData,
                newEmails: partnerData.emails,
                newCategories: partnerData.categories,
              },
            },
            loggedUser?.['_id']
          )
        );
        getActualSociety(loggedUser, societyId);
        dispatch(setModal(null));
        return;
      }

      if (userType === userTypes.BENEFICIARY) {
        const isCurrentEmailValid =
          currentEmail !== '' && isValidEmail(currentEmail);
        const isCurrentEmailUnique = currentEmail !== emails[emails.length - 1];

        if (isCurrentEmailValid && isCurrentEmailUnique) {
          emails.push(currentEmail);
        }
        const newEmails = emails.filter(
          (curr) => curr !== '' && isValidEmail(curr)
        );
        newData['cif'] = cif;
        newData['jobTitle'] = jobTitle;
        newData['employeeNumber'] = employeeNumber;
        newData['taxAddress'] = taxAddress;
        newData['bankAccountNumber'] = bankAccountNumber;

        await dispatch(
          updateUser(
            user?.user,
            {
              beneficiary: user?.['_id'],
              society: societyId,
              beneficiaryData: { ...newData, newEmails },
            },
            loggedUser?.['_id']
          )
        );
        getBeneficiaryData(
          {
            beneficiaryId: user['_id'],
            societyId,
            userId: loggedUser?.['_id'],
            source: 'society',
          },
          setBeneficiarySocietyData
        );
        getActualSociety(loggedUser, societyId);
        dispatch(setModal(null));
        return;
      }

      if (userType === userTypes.BENEFICIARY_DRAFT) {
        newData['cif'] = cif;
        newData['jobTitle'] = jobTitle;
        newData['employeeNumber'] = employeeNumber;
        newData['taxAddress'] = taxAddress;
        newData['bankAccountNumber'] = bankAccountNumber;

        await store.dispatch(
          updateUser(
            user?.user,
            {
              beneficiaryDraft: user?.['_id'],
              society: societyId,
              beneficiaryData: { ...newData },
            },
            loggedUser?.['_id']
          )
        );
        getActualSociety(loggedUser, societyId);
        dispatch(setModal(null));
        return;
      }

      if (userType === userTypes.USER) {
        dispatch(updateUser(user?.['_id'], newData, loggedUser?.['_id']));
      }

      if (userType === userTypes.ADMIN) {
        await dispatch(updateUser(user?.['_id'], newData, loggedUser?.['_id']));
        const mainAdminId =
          actualSociety?.mainAdmin || actualSociety?.administrators[0];

        if (mainAdminId) {
          const mainAdmin = await dispatch(
            getUsers({
              usersList: [mainAdminId],
            })
          );

          if (mainAdmin?.length > 0) {
            const { language } = i18n;
            const emailData = createAdminEmail({
              t,
              language,
              actualSociety,
              user: loggedUser,
              email: mainAdmin[0].email,
              mainAdminName: mainAdmin[0].name,
              updatedAdminName: newData.name,
              action: 'EDIT',
            });

            await dispatch(sendEmail(emailData, false));
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      dispatch(setModal(null));
    }
  };

  useEffect(() => {
    if (userType === userTypes.PARTNER) {
      const otherUsers = user?.otherUsers || [];
      const readOnlyUsers = user?.readOnlyUsers || [];
      const allUsers = [...otherUsers, ...readOnlyUsers];

      getEmailsAndCategories(allUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocuments({ society: actualSociety['_id'] }));
      if (actualSociety?.partners) {
        const options = actualSociety?.partners
          ?.filter((partner) => partner?._id !== user?._id)
          .map((partner) => ({
            value: partner?.['_id'],
            label: `${partner?.cif} | ${partner?.name}`,
          }));
        setSindicationPartnerOptions(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (userType === userTypes.PARTNER) {
      dispatch(getPartnerCategories(actualSociety?._id));
    }
    if (userType !== userTypes.USER && userType !== userTypes.ADMIN) {
      if (sindication) {
        const sindicationPartner = actualSociety?.partners?.find(
          (partner) => partner?.['_id'] === user?.sindication
        );
        setSindicationOptionValue({
          value: sindication || '',
          label: `${sindicationPartner?.cif} | ${sindicationPartner?.name}`,
        });
      }

      if (!user?.isNaturalPerson) {
        setJuridicPersonValues();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userType !== userTypes.USER && userType !== userTypes.ADMIN) {
      if (!user?.representative?.status && representativeEndDate) {
        if (new Date(representativeEndDate) - new Date() > 0) {
          setRepresentativeStatus(statusTypes.var.CURRENT);
          setStatusOptionValue({
            label: statusTypes.name[statusTypes.var.CURRENT],
            value: statusTypes.var.CURRENT,
          });
        } else {
          setRepresentativeStatus(statusTypes.var.REVOKED);
          setStatusOptionValue({
            label: statusTypes.name[statusTypes.var.REVOKED],
            value: statusTypes.var.REVOKED,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativeEndDate]);

  useEffect(() => {
    if (userType !== userTypes.USER && userType !== userTypes.ADMIN) {
      if (user?.isNaturalPerson) {
        setCivilStatusOptionValue({
          value: user?.civilStatus || '',
          label: t(user?.civilStatus) || t('SelectCivilStatus'),
        });
      } else {
        setRegister(user?.legalInfo?.register);
        setSection(user?.legalInfo?.section);
        setSheet(user?.legalInfo?.sheet);
      }
      setNaturalPerson(user?.isNaturalPerson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (
      documents &&
      userType !== userTypes.USER &&
      userType !== userTypes.ADMIN
    ) {
      const constitutionDocName = documents?.filter((document) =>
        user?.documents.some(
          (userDocumentId) =>
            userDocumentId === document['_id'] &&
            document?.subcategory === legalPersonDocumentTypes.CONSTITUTION
        )
      )?.[0]?.name;
      setConstitutionDocumentName(constitutionDocName);
      const appointmentDocName = documents?.filter((document) =>
        user?.documents.some(
          (userDocumentId) =>
            userDocumentId === document['_id'] &&
            document?.subcategory === legalPersonDocumentTypes.APPOINTMENT
        )
      )?.[0]?.name;
      setAppointmentDocumentName(appointmentDocName);
      const ownershipDocName = documents?.filter((document) =>
        user?.documents.some(
          (userDocumentId) =>
            userDocumentId === document['_id'] &&
            document?.subcategory === legalPersonDocumentTypes.OWNERSHIP
        )
      )?.[0]?.name;
      setOwnershipDocumentName(ownershipDocName);
    }
  }, [documents, user]);

  useEffect(() => {
    if (constitutionFile) {
      setConstitutionDocumentName(constitutionFile?.name);
    }
    if (appointmentFile) {
      setAppointmentDocumentName(appointmentFile?.name);
    }
    if (ownershipFile) {
      setOwnershipDocumentName(ownershipFile?.name);
    }
  }, [constitutionFile, appointmentFile, ownershipFile]);

  useEffect(() => {
    if (!naturalPerson) {
      setJuridicPersonValues();
    }
  }, [naturalPerson]);

  useEffect(() => {
    if (partnerCategories?.length) {
      const partnerCategoriesOptions = partnerCategories.map((category) => ({
        value: category['_id'],
        label: category.name,
      }));
      setPartnerCategoriesOptions(partnerCategoriesOptions);

      if (user?.category) {
        const selectedPartnerCategory = partnerCategories.find(
          (category) => category['_id'] === user?.category
        );
        setSelectedPartnerCategoryOption({
          value: selectedPartnerCategory['_id'],
          label: selectedPartnerCategory.name,
        });
        setPartnerCategory(selectedPartnerCategory['_id']);
      }
    } else {
      setPartnerCategoriesOptions([]);
    }
  }, [partnerCategories]);

  return (
    <>
      <Modal.Header size="xl" className="justify-content-between">
        <div className="flex-grow-1">
          <h5 className="title">
            {t('UpdateData')}&nbsp;
            <span className="text-muted ff-alt fs-14px text-right">
              ({getUserLabel(userType)})
            </span>
          </h5>
        </div>

        <div className="flex-grow-1">
          {hasPersonOptions() && (
            <EditModalHeaderOptions
              naturalPerson={naturalPerson}
              handleChangePersonValue={handleChangePersonValue}
            />
          )}
        </div>

        <div className="flex-grow-1">
          <a
            className="close cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </div>
      </Modal.Header>

      <Modal.Body size="xl">
        <EditModalNavTabs
          page={page}
          setPage={setPage}
          naturalPerson={naturalPerson}
          hasPersonOptions={hasPersonOptions}
        />

        <div className="tab-content">
          {/* PERSONAL INFORMATION TAB */}
          {page === 0 && (
            <EditPersonalInformationForm
              page={!!page}
              cif={cif}
              civilStatus={civilStatus}
              civilStatusOptionValue={civilStatusOptionValue}
              currentEmail={currentEmail}
              email={email}
              emails={emails}
              categories={categories}
              employeeNumber={employeeNumber}
              fileSelected={fileSelected}
              handleChangeCivilStatus={handleChangeCivilStatus}
              handleChangeSelectedPartner={handleChangeSelectedPartner}
              handlePartnerCategoryOptionChange={
                handlePartnerCategoryOptionChange
              }
              hasPersonOptions={hasPersonOptions}
              image={image}
              jobTitle={jobTitle}
              name={name}
              nationality={nationality}
              naturalPerson={naturalPerson}
              partnerCategoriesOptions={partnerCategoriesOptions}
              phone={phone}
              selectedPartnerCategoryOption={selectedPartnerCategoryOption}
              setBirthdate={setBirthdate}
              setCategories={setCategories}
              setCif={setCif}
              setCurrentEmail={setCurrentEmail}
              setEmail={setEmail}
              setEmails={setEmails}
              setEmployeeNumber={setEmployeeNumber}
              setJobTitle={setJobTitle}
              setName={setName}
              setNationality={setNationality}
              setPhone={setPhone}
              sindicationOptionValue={sindicationOptionValue}
              sindicationPartnerOptions={sindicationPartnerOptions}
              user={user}
              userType={userType}
            />
          )}

          {/* ADDRESS TAB */}
          {page === 1 && (
            <EditAddressForm
              page={!!page}
              line1={line1}
              city={city}
              zip={zip}
              country={country}
              setLine1={setLine1}
              setCity={setCity}
              setZip={setZip}
              setCountry={setCountry}
              hasTaxAddress={hasTaxAddress}
              handleChangeTaxAddress={handleChangeTaxAddress}
              taxLine1={taxLine1}
              taxCity={taxCity}
              taxZip={taxZip}
              taxCountry={taxCountry}
              setTaxLine1={setTaxLine1}
              setTaxCity={setTaxCity}
              setTaxZip={setTaxZip}
              setTaxCountry={setTaxCountry}
            />
          )}

          {/* SCRIPTURES TAB - JURIDIC PERSON */}
          {page === 2 && (
            <EditLegalPersonScripturesForm
              page={!!page}
              register={register}
              setRegister={setRegister}
              section={section}
              setSection={setSection}
              sheet={sheet}
              setSheet={setSheet}
              constitutionFile={constitutionFile}
              setConstitutionFile={setConstitutionFile}
              constitutionDocumentName={constitutionDocumentName}
              appointmentFile={appointmentFile}
              setAppointmentFile={setAppointmentFile}
              appointmentDocumentName={appointmentDocumentName}
              ownershipFile={ownershipFile}
              setOwnershipFile={setOwnershipFile}
              ownershipDocumentName={ownershipDocumentName}
            />
          )}

          {/* REPRESENTATION TAB - JURIDIC PERSON */}
          {page === 3 && (
            <EditLegalPersonRepresentationForm
              representativeName={representativeName}
              setRepresentativeName={setRepresentativeName}
              representativeEmail={representativeEmail}
              setRepresentativeEmail={setRepresentativeEmail}
              representativeInitDate={representativeInitDate}
              setRepresentativeInitDate={setRepresentativeInitDate}
              representativeEndDate={representativeEndDate}
              setRepresentativeEndDate={setRepresentativeEndDate}
              representativeNif={representativeNif}
              setRepresentativeNif={setRepresentativeNif}
              representativeAddress={representativeAddress}
              setRepresentativeAddress={setRepresentativeAddress}
              representativeNationality={representativeNationality}
              setRepresentativeNationality={setRepresentativeNationality}
              typeOptionValue={typeOptionValue}
              statusOptionValue={statusOptionValue}
              statusTypesOptions={statusTypesOptions}
              handleChangeSelectedType={handleChangeSelectedType}
              handleChangeSelectedStatus={handleChangeSelectedStatus}
              representativeTypesOptions={representativeTypesOptions}
            />
          )}

          {/* OTHER INFO */}
          {page === 4 && (
            <EditOtherInfoForm
              page={!!page}
              bankAccountNumber={bankAccountNumber}
              setBankAccountNumber={setBankAccountNumber}
              hideAccountNumber={hideAccountNumber}
              handleHideAccountNumber={handleHideAccountNumber}
            />
          )}

          <div className="row mt-4">
            <div className="col-12">
              <button
                type="button"
                className="btn btn-lg btn-primary"
                onClick={onUpdateUser}
                disabled={email === ''}
              >
                {t('SaveChanges')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default EditModal;
