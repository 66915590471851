import React from 'react';
import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';
import StockOptionsModal from 'modules/_shared/components/Modals/StockOption';

export default function watchPlan(data, planId) {
  const { plans } = store.getState();
  const currentPlan = plans?.find((plan) => plan['_id'] === planId);
  if (currentPlan) {
    store.dispatch(
      setModal(<StockOptionsModal action="watch" currentPlan={currentPlan} />)
    );
  }
}
