import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import SocietyAdministratorPermissionsForm from './SocietyAdministratorPermissionsForm';

type Props = {
  administratorPermissions: any[];
  // eslint-disable-next-line no-unused-vars
  handleChangePermissions: (permissions: any[]) => void;
};

const EditPermissionsModalBody: FC<Props> = ({
  administratorPermissions,
  handleChangePermissions,
}) => (
  <Modal.Body>
    <SocietyAdministratorPermissionsForm
      className="my-4"
      defaultPermissions={administratorPermissions}
      onChangePermissions={handleChangePermissions}
    />
  </Modal.Body>
);

export default EditPermissionsModalBody;
