/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable no-plusplus */
import queryTypes from 'constants/queryTypes';
import JSZip from 'jszip';
import { DOMParser } from 'xmldom';
import axios from 'interceptors/axios';
import { decryptResponse } from 'utils/token';
import {
  generatePromptAddConstitution,
  generatePromptAddPartners,
  generatePromptAddSociety,
  generatePromptDefault,
} from './generatePrompts';
import societyParams, {
  societyParamsContext,
} from '../constants/societyParams';
import partnerParams, {
  partnerParamsContext,
} from '../constants/partnerParams';
import constitutionParams from '../constants/constitutionParams';
import representativeParams from '../constants/representativeParams';

export const NOT_FOUND_TAG = 'NOT_FOUND';

export const getDocxContent = async (arrayBuffer) => {
  try {
    const zip = new JSZip();
    await zip.loadAsync(arrayBuffer);
    const documentXml = await zip.file('word/document.xml').async('string');
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(documentXml, 'application/xml');
    const textElements = xmlDoc.getElementsByTagName('w:t');

    return textElements;
  } catch (error) {
    return new Error(error);
  }
};

export const extractDataFromText = async (
  text,
  paramsList,
  representativeParams,
  queryType
) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/openai`;
  let prompt = '';
  switch (queryType) {
    case queryTypes.SOCIETY:
      prompt = `Extracts from the following text: "${text}", a JSON representation, where ${paramsList} is obtained. In the fields that you cannot find, return '', the response has to be just the JSON and the keys with the same text in which they are requested, fiscal identification must be only the value not the type. Dates must be in YYYY-MM-DD format.`;
      break;
    case queryTypes.PARTNERS:
      prompt = `Extracts from the following text: "${text}", a JSON representation with root 'partners', where ${paramsList} is obtained. In the fields that you cannot find, return '', the response has to be just the JSON and the keys with the same text in which they are requested, fiscal identification must be only the value not the type. Dates must be in YYYY-MM-DD format. If have a representative, one of the keys will be Representative, with the following information ${representativeParams} if dont have dont return this key`;
      break;
    case queryTypes.CONSTITUTION:
      prompt = `Extracts from the following text: "${text}", a JSON representation, where ${paramsList} is obtained. In the fields that you cannot find, return '', the response has to be just the JSON object and the keys with the same text in which they are requested, dates must be in YYYY-MM-DD format, amounts must be only the number`;
      break;
    default:
      prompt = `Extrae ${paramsList} del siguiente texto: "${text}", en los campos que no encuentres retorna "NOT_FOUND".`;
      break;
  }
  const response = await axios.post(endpoint, { prompt });

  const decodedData = await decryptResponse(response.data);

  if (decodedData.status !== 200) {
    return '';
  }

  const { message } = decodedData;
  return message.text;
};

export const extractDataFromPDF = async (file, queryType) => {
  let prompt = '';

  switch (queryType) {
    case queryTypes.SOCIETY:
      prompt = generatePromptAddSociety(societyParams, societyParamsContext);
      break;

    case queryTypes.PARTNERS:
      prompt = generatePromptAddPartners(
        partnerParams,
        representativeParams,
        partnerParamsContext
      );
      break;

    case queryTypes.CONSTITUTION:
      prompt = generatePromptAddConstitution(constitutionParams);
      break;

    case queryTypes.SOCIETY_PARTNERS_CONSTITUTION:
      prompt = {
        society: generatePromptAddSociety(societyParams, societyParamsContext),
        partners: generatePromptAddPartners(
          partnerParams,
          representativeParams,
          partnerParamsContext
        ),
        constitution: generatePromptAddConstitution(constitutionParams),
      };
      break;

    default:
      prompt = generatePromptDefault('');
      break;
  }

  const isMultiple = !!(queryType === queryTypes.SOCIETY_PARTNERS_CONSTITUTION);

  const formData = new FormData();
  formData.append('file', file);

  const endpoint = `${process.env.REACT_APP_API_URL}/openai`;

  const response = await axios.post(endpoint, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    params: {
      prompt,
      multiple: isMultiple,
    },
  });

  const decodedData = await decryptResponse(response.data);

  if (decodedData.status !== 200) {
    return '';
  }
  const { message } = decodedData;
  return message.text;
};

export const mapResultToParams = (result) => {
  const resultArray = result.split('\n').filter((line) => line !== '');
  const resultObject = {};

  for (let i = 0; i < resultArray.length; i++) {
    const line = resultArray[i];
    if (line.includes(':')) {
      const lineArray = line.split(':');
      const key = lineArray[0].trim();
      const value = lineArray[1].trim();
      resultObject[key] = value;
    }
  }

  return resultObject;
};

function cleanText(inputText) {
  const startIndex = inputText.indexOf('{');
  const lastIndex = inputText.lastIndexOf('}');
  const extractedText = inputText.substring(startIndex, lastIndex + 1);
  return extractedText;
}

export const mapResultToJSON = (result) => {
  const parseResult = (input) => {
    const cleanedText = cleanText(input);
    return JSON.parse(cleanedText);
  };

  if (Array.isArray(result)) {
    return result.map(parseResult);
  }

  return parseResult(result);
};
