/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux';
import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';

import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';
import MenuDots from '../../../_shared/components/MenuDots';
import menuOptions from './menuOptions';

const logicalValue = {
  true: 'Sí',
  false: 'No',
};
function HoldingClassRow({
  index,
  societyId,
  nominalValue,
  sharesNumber,
  numerationValues,
  holdingClass,
}) {
  const { isAdmin, isReadOnly } = useGetPartnersSectionPermissions().data;

  const holdingClasses = useSelector((state) => state?.holdingClasses);

  const {
    name,
    votes,
    numerationOption,
    lockupPeriod,
    conversionRate,
    hasConversionRights,
    hasLiquidationRights,
    hasAntidilutionRights,
  } = holdingClass;

  const numeration = numerationOption
    ? holdingClasses?.find((curr) => curr['_id'] === numerationOption)?.name
    : 'Desde 1';

  return (
    <tr className="nk-tb-item text-center" key={`${index}-${societyId}`}>
      <td className="nk-tb-col">
        <span className="tb-lead">{name}</span>
      </td>

      <td className="nk-tb-col">
        <span>{nominalValue}</span>
      </td>

      <td className="nk-tb-col">
        <span>{sharesNumber}</span>
      </td>

      <td className="nk-tb-col">
        <span>
          <span>
            {numerationValues &&
              numerationValues.map((row) => (
                <>
                  {row}
                  <br />
                </>
              ))}
          </span>
        </span>
      </td>

      <td className="nk-tb-col">
        <span>{votes}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{numeration}</span>
      </td>

      <td className="nk-tb-col">
        <span>{logicalValue[hasLiquidationRights]}</span>
      </td>

      <td className="nk-tb-col">
        <span>{logicalValue[hasAntidilutionRights]}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {lockupPeriod ? format(new Date(lockupPeriod), dateFormat) : 'No'}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{logicalValue[hasConversionRights]}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{conversionRate}</span>
      </td>

      <td className="nk-tb-col nk-tb-col-tools">
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                disabled={!isAdmin}
                direction="left"
                menuOptions={menuOptions}
                id={holdingClass['_id']}
                params={{
                  data: holdingClass,
                  isReadOnly,
                }}
              />
            </div>
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default HoldingClassRow;
