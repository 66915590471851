import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

type Props = {
  handleCloseModal: (event: any) => void;
};

const CancelBeneficiaryHeader: FC<Props> = ({ handleCloseModal }) => {
  const { t } = useTranslate();

  return (
    <>
      <h5 className="modal-title">{t('CancelBeneficiary')}</h5>
      <a className="close cursor-pointer" onClick={handleCloseModal}>
        <em className="icon ni ni-cross" />
      </a>
    </>
  );
};

export default CancelBeneficiaryHeader;
