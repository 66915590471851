function viewOptions(i18n) {
  return [
    {
      name: 'quarterly',
      icon: 'ni-book-read',
      text: i18n.t('ByQuarter'),
      action: ({ setView }) => {
        setView(0);
      },
      dropdown: true,
    },
    {
      name: 'anually',
      icon: 'ni-book-read',
      text: i18n.t('ByYear'),
      action: ({ setView }) => {
        setView(1);
      },
      dropdown: true,
    },
    {
      name: 'summary',
      icon: 'ni-book-read',
      text: i18n.t('Summary'),
      action: ({ setView }) => {
        setView(2);
      },
      dropdown: true,
    },
  ];
}
export default viewOptions;
