import React from 'react';
import { Link } from 'react-router-dom';
import { getHeaderMenuTextColor } from 'utils/theme';

enum MenuItemTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

type HeaderMenuItemType = MenuItemTypes.INTERNAL | MenuItemTypes.EXTERNAL;

type HeaderMenuItemProps = {
  to: string;
  type?: HeaderMenuItemType;
  text: string;
  data: Record<string, any>;
  isSub?: boolean;
  isDisabled?: boolean;
};

const HeaderMenuItem = ({
  to,
  text,
  type = MenuItemTypes.INTERNAL,
  data,
  isSub = false,
  isDisabled = false,
}: HeaderMenuItemProps) => (
  <li className="nk-menu-item">
    {type === MenuItemTypes.INTERNAL ? (
      <Link
        to={to}
        className={`nk-menu-link  ${isSub ? 'nk-menu-link-secondary' : ''} ${
          isDisabled ? 'is-disable' : ''
        }`}
        style={getHeaderMenuTextColor(data)}
      >
        <span className="nk-menu-text">{text}</span>
      </Link>
    ) : (
      <a
        href={to}
        target="_self"
        rel="noreferrer"
        className={`nk-menu-link  ${isSub ? 'nk-menu-link-secondary' : ''} ${
          isDisabled ? 'is-disable' : ''
        }`}
        style={getHeaderMenuTextColor(data)}
      >
        <span className="nk-menu-text">{text}</span>
      </a>
    )}
  </li>
);

export default HeaderMenuItem;
