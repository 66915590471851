import { useEffect, useState } from 'react';

import { SelectorOption } from 'types';

import consolidationOptions from 'constants/consolidationOptions';

export function useGetConsolidationOptions() {
  const [resultConsolidationOptions, setResultConsolidationOptions] = useState<
    SelectorOption[]
  >([]);

  useEffect(() => {
    const options = consolidationOptions.map((option: any) => ({
      value: option.value,
      label: option.text,
    }));

    setResultConsolidationOptions(options);
  }, []);

  return {
    data: { resultConsolidationOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
