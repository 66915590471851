import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import userTypes from 'constants/userTypes';
import { dateFormat } from 'constants/formats';

import MenuDots from 'modules/_shared/components/MenuDots';

import { Guest, Society, User } from 'types';
import { FC } from 'react';
import { State } from 'redux/initialState';
import { getNameFromEmail } from 'utils/getNameFromEmail';
import SocietyGuestsAccessBadge from './SocietyGuestsAccessBadge';
import { filterOptions, menuOptions } from '../utils';

const formatDate = (date: Date): string =>
  format(date && new Date(date), dateFormat);

const formatName = (guest: Guest): string =>
  guest?.name || getNameFromEmail(guest?.email);

type SocietyGuestsRowProps = {
  guest: Guest;
  society: Society;
  user: User;
  isAdmin: boolean;
  index: number;
};

const SocietyGuestsRow: FC<SocietyGuestsRowProps> = ({
  guest,
  society,
  user,
  isAdmin,
  index,
}) => {
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);

  const showMenuDots = isAdmin && !isDemo;

  const name = formatName(guest);
  const creationDate = formatDate(guest?.createdAt);

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col tb-col-xl">
        <span>{index + 1}</span>
      </td>

      <td className="nk-tb-col">
        <span>{name}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{creationDate}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{guest.email}</span>
      </td>

      <td className="nk-tb-col">
        <SocietyGuestsAccessBadge guest={guest} society={society} />
      </td>

      {showMenuDots && (
        <td className="nk-tb-col  nk-tb-col-tools">
          <MenuDots
            menuOptions={menuOptions}
            // @ts-ignore
            id={guest._id}
            params={{
              data: guest,
              userType: userTypes.ADMIN,
              societyId: society._id,
            }}
          />
        </td>
      )}
    </tr>
  );
};

export default SocietyGuestsRow;
