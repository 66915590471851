import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';
import { countSocietyShares } from 'utils/getShareCount';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export function createDraft(draft) {
  return {
    type: types.CREATE_DRAFT,
    draft,
  };
}

// TODO* create a function for getting drafts by Reference
export const getDrafts = (societyId) => async (dispatch) => {
  const query = { params: { societyId } };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/drafts`,
      query
    );
    const decodedData = await decryptResponse(data);
    const draftsPartnersPopulated = decodedData?.map((draft) => ({
      ...draft,
      draftPartners: draft?.draftPartners
        ? JSON.parse(draft.draftPartners)
        : [],
    }));
    dispatch({
      type: types.GET_DRAFTS,
      data: draftsPartnersPopulated,
    });

    return draftsPartnersPopulated;
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes['ERROR_GETTING_DRAFTS']));
  }
};

export const addDraft = (draftData) => async (dispatch) => {
  const body = { ...draftData };
  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/drafts`,
    body
  );
  const decodedData = await decryptResponse(data);
  dispatch({
    type: types.CREATE_DRAFT,
    draft: decodedData,
  });
  return decodedData;
};

export const updateDraft =
  (draftId, updateDraftData, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: draftId,
      ...updateDraftData,
    };
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/drafts`,
        body
      );
      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_DRAFT,
        draft: decodedData,
      });

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteDraft = (draftId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/drafts/${draftId}`
    );
    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.DELETE_DRAFT,
      draft: decodedData,
    });
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));

    return decodedData;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};
