/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getSocietyEmployees } from 'modules/beneficiaries/redux/employeeActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';

import menuTypes from 'constants/menuTypes';

import { getActualSociety, getEmployeesEligibles } from 'utils/filters';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';

import planTypes from 'constants/planTypes';
import sizes from 'constants/sizes';
import { useTranslate } from 'hooks/useTranslate';
import AddEmployeesToPlan from 'modules/_shared/components/Modals/AddEmployeesToPlan';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getSocietyEmployeesPlans } from 'modules/beneficiaries/redux/employeesPlanActions';
import { employeesPlanStatusTypes } from 'utils/employeesFilterTypes';
import { useGetPlanSectionPermissions } from 'modules/beneficiaries/hooks/useGetPlanSectionPermissions';
import tableColumns from './tableColumns';

function EmployeesPlanList() {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { isAdmin, isReadOnly } = useGetPlanSectionPermissions().data;

  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state.plans);
  const employees = useSelector((state) => state.employees);
  const employeesPlans = useSelector((state) => state.employeesPlans);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const [activeEmployees, setActiveEmployees] = useState([]);
  const [beneficiariesCIFList, setBeneficiariesCIFList] = useState([]);

  const getEmployeesPlanMainInfo = (employeesPlan) => {
    const currentPlan = plans?.find(
      (plan) => plan['_id'] === employeesPlan?.planSelected
    );

    return {
      name: employeesPlan?.name,
      units: currentPlan?.sharesTotal,
      planName: currentPlan?.name,
      planType: currentPlan?.planType,
      planIsDraft: currentPlan?.isDraft,
    };
  };

  const handleFilterEmployees = () => {
    if (isReadOnly) return;
    if (plans?.length) {
      dispatch(setModal(<AddEmployeesToPlan size={sizes.LG} />));
    } else {
      return Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t('HasNoPlans')}</h4>`,
        html: `<p class="text-soft px-4">${t('HasNoPlansDescription')}</p>
        <p class="text-primary" style="margin-top:24px;margin-bottom:30px">
          ${t('PlansListPage')}
        </p>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }
  };

  const getAllBeneficiariesCIFs = (beneficiaries) => {
    const cifs = beneficiaries?.map((beneficiary) =>
      beneficiary.cif?.toUpperCase()
    );
    setBeneficiariesCIFList(cifs);
  };

  useEffect(() => {
    dispatch(getStockPlans(societyId));
    dispatch(getSocietyEmployees(societyId));
    dispatch(getSocietyEmployeesPlans(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );

      getAllBeneficiariesCIFs(actualSociety?.beneficiaries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (employees.length && beneficiariesCIFList) {
      const newData = employeesPlans?.map((employeesPlan) => {
        const { name, planIsDraft, planType, planName, units } =
          getEmployeesPlanMainInfo(employeesPlan);

        const employeesEligibles = getEmployeesEligibles(
          employees,
          employeesPlan,
          beneficiariesCIFList
        );

        return {
          id: employeesPlan['_id'],
          employeesPlan: { name, planType },
          name: planName,
          status: {
            text: employeesPlanStatusTypes[employeesPlan?.status]?.label,
            color: employeesPlanStatusTypes[employeesPlan?.status]?.style,
          },
          units,
          percentFD: '-',
          beneficiaries: employeesEligibles?.length || 0,
          societyId: actualSociety?.['_id'],
          isDraft: planIsDraft,
          initials: planTypes[planType]?.initials,
        };
      });

      if (JSON.parse(localStorage.getItem('showDetails'))) {
        console.log('newData', newData);
      }

      if (newData?.length > 0) {
        setTableData(
          transformData({
            data: newData,
            columns: tableColumns(i18n),
          })
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [employees, beneficiariesCIFList]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
  }, [i18n.language]);

  useEffect(() => {
    if (employees?.length) {
      setActiveEmployees(
        employees.filter((employee) => !employee.terminationDate)
      );
    } else {
      setActiveEmployees([]);
    }
  }, [employees]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('EmployeesPlansList')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${actualSociety?.name} ${t('HaveEmployeesPlans', {
                      count: employeesPlans?.length,
                    })}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <>
                          <li>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleFilterEmployees}
                              disabled={isReadOnly}
                            >
                              <em className="icon ni ni-filter" />
                              <span>{t('FilterEmployees')}</span>
                            </button>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('name')}
            pagination
            className="nk-tb-list"
            // expandableRows
            actions
            showDense
            fileName={`${t('EmployeesList')} ${actualSociety?.name}`}
          />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default EmployeesPlanList;
