export default {
  NOMBRE: 'NOMBRE',
  NOMBRE_APELLIDOS: 'NOMBRE APELLIDOS',
  NOMBRE_Y_APELLIDOS: 'NOMBRE Y APELLIDOS',
  NACIONALIDAD: 'NACIONALIDAD',
  TIPO_ID: 'TIPO ID',
  NRO_ID: 'NRO ID',
  CIF: 'CIF',
  DIRECCION: 'DIRECCION',
  DOMICILIO: 'DOMICILIO',
  POBLACION: 'POBLACION',
  CIUDAD: 'CIUDAD',
  CP: 'C.P.',
  CODIGO_POSTAL: 'CODIGO POSTAL',
  PAIS: 'PAIS',
  ESTADO_CIVIL: 'ESTADO CIVIL',
  FECHA_NACIMIENTO: 'FECHA NACIMIENTO',
  FECHA_DE_NACIMIENTO: 'FECHA DE NACIMIENTO',
  IMPORTE_APORTACION: 'IMPORTE APORTACION',
  CAPITAL: 'CAPITAL',
  NRO_PPSS: 'NRO PPSS',
  NRO_DE_PPSS: 'NRO DE PPSS',
  NRO_PS_INICIAL: 'NRO PS INICIAL',
  NRO_DE_PS_INICIAL: 'NRO DE PS INICIAL',
  NRO_PS_FINAL: 'NRO PS FINAL',
  PHONE: 'PHONE',
  TELEFONO: 'TELEFONO',
  TIPO_INVERSOR: 'TIPO INVERSOR',
  FECHA_INVERSION: 'FECHA INVERSION',
  PRECIO_PARTICIPACION: 'PRECIO PARTICIPACION',
  NOMINAL_PS: 'NOMINAL PS',
  PRIMA_PS: 'PRIMA PS',
  PARTICIPACIONES: 'PARTICIPACIONES',
  CLASE: 'CLASE',
  NRO_EMPLEADO: 'NRO EMPLEADO',
  CUENTA_BANCARIA: 'CUENTA BANCARIA',
  EMAIL: 'EMAIL',
  CORREO_ELECTRONICO: 'CORREO ELECTRONICO',
  RESIDENCIA_FISCAL: 'RESIDENCIA FISCAL',
  PERSONA_JURIDICA: 'PERSONA JURIDICA',
  PRIMA: 'PRIMA',
};
