/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { store } from 'redux/store';

import { Document } from 'types';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';

import InputCharCounter from 'modules/_shared/components/InputCharCounter';
import foldersContentType from 'modules/dashboard/constants/foldersContentType';
import UploadDocument from 'modules/documents/components/UploadDocument';
import { addDocument } from 'modules/documents/redux/documentActions';

type DashboardEditContentStep3Props = {
  onChange: (type: string) => void;
  contentType: string;
};

const labels = {
  [foldersContentType.VIDEO_EMBEDDED]: 'EnterTheVideoId',
  [foldersContentType.PDF_EMBEDDED]: 'SelectTheFilePDF',
};

const DashboardEditContentStep3: FC<DashboardEditContentStep3Props> = ({
  onChange,
  contentType,
}) => {
  const { t } = useTranslate();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const [contentVideoId, setContentVideoId] = useState<string>('');

  const [file, setFile] = useState<File | undefined>(undefined);

  const handleChangeVideoId = (event: ChangeEvent<HTMLInputElement>) => {
    setContentVideoId(event.target.value);
    onChange(event.target.value);
  };

  const handleUploadDocument = async (file: File) => {
    const newDocument = {
      file,
      date: new Date(),
      size: file.size,
      name: file.name,
      author: user['_id'],
      society: actualSociety['_id'],
      fileType: fileTypes[file.type as keyof typeof fileTypes],
      category: documentTypes.DOCUMENTS,
      description: t('DocumentLinkedToOperation'),
    };

    const uploadedDocument: Document = await store.dispatch(
      addDocument(newDocument)
    );

    onChange(uploadedDocument._id);
  };

  useEffect(() => {
    if (file) {
      handleUploadDocument(file);
    }
  }, [file]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="col-12 mb-4">
      <h6 className="mb-3">{t(labels[contentType])}</h6>

      {contentType === foldersContentType.VIDEO_EMBEDDED && (
        <>
          <input
            type="text"
            className="form-control"
            value={contentVideoId}
            maxLength={30}
            onChange={handleChangeVideoId}
          />

          <InputCharCounter value={contentVideoId} limit={30} />
        </>
      )}

      {contentType === foldersContentType.PDF_EMBEDDED && (
        <div className="dashboard-edit-content-file-box">
          <UploadDocument file={file} setFile={setFile} label="" />
        </div>
      )}
    </div>
  );
};

export default DashboardEditContentStep3;
