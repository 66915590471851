/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { store } from 'redux/store';
import userTypes from 'constants/userTypes';
import voteValues from 'constants/voteValues';
import { updateBoard } from 'modules/boards/redux/boardActions';

import { useGetBoardsSectionPermissions } from 'modules/boards/hooks/useGetBoardsSectionPermissions';
import { getParticipantRows } from './utils/getParticipantRows';

const BoardVotesTable = ({
  board,
  order,
  selectOnlyAssistants,
  setSelectOnlyAssistants,
}) => {
  const { t } = useTranslate();

  const { isReadOnly } = useGetBoardsSectionPermissions().data;

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partnerRepresentants = useSelector(
    (state) => state.partnerRepresentants
  );

  const [rows, setRows] = useState([]);
  const [hasSelectedAll, setHasSelectedAll] = useState(false);
  const [customSelected, setCustomSelected] = useState({});

  const updateMultipleVotes = async (assistants, selectedVote) => {
    try {
      const participantsSelected = Object.entries(assistants).filter(
        ([, value]) => value
      );

      for (const [id] of participantsSelected) {
        const participant = board?.participants?.find((p) => p._id === id);
        if (participant) {
          const currentVote = participant?.votes?.find(
            (v) => v?.order === order
          );
          if (
            (!currentVote && selectedVote !== 'no_vote') ||
            (currentVote && currentVote?.vote !== selectedVote)
          ) {
            const newVotes = participant?.votes?.filter(
              (v) => v?.order !== order
            );
            if (selectedVote !== 'no_vote') {
              participant.assists = true;
              participant.isAbsent = false;
              newVotes.push({
                order,
                vote: selectedVote,
                voterData: {
                  voterId: user?.['_id'],
                  voterType: userTypes.USER,
                  voterName: user?.name,
                },
              });
              // List of participants represented by current voter which will be updated as assistants
              const participantsToUpdate = board?.participants?.filter(
                (p) => p?.representative === participant?.member
              );
              // eslint-disable-next-line no-restricted-syntax
              for (const currentParticipant of participantsToUpdate) {
                if (!currentParticipant?.assists) {
                  // eslint-disable-next-line no-await-in-loop
                  await store.dispatch(
                    updateBoard(
                      board['_id'],
                      {
                        participantData: {
                          ...currentParticipant,
                          assists: true,
                          isAbsent: false,
                        },
                      },
                      false
                    )
                  );
                }
              }
            }
            await store.dispatch(
              updateBoard(
                board['_id'],
                {
                  participantData: {
                    ...participant,
                    votes: newVotes,
                  },
                },
                false
              )
            );
          }
        }
      }
    } catch (error) {
      return Swal.fire({
        title: t('Error'),
        text: t('ErrorDescription'),
        icon: 'error',
        confirmButtonText: t('Accept'),
        confirmButtonColor: '#6576ff',
      });
    }
  };

  const handleVoteForAll = (value) =>
    Swal.fire({
      title: `<h4 class="nk-modal-title">${t('MultipleVote')}<br/></h4>`,
      html: `<p class="mb-4">${t('MultipleVoteDescription', {
        value: voteValues[value]?.text,
      })}</p>`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: t('No'),
      confirmButtonText: t('Yes'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        updateMultipleVotes(customSelected, value);
      }
    });

  const isInputDisabled = () => {
    const isSomeSelected = Object.values(customSelected).some(
      (value) => value === true
    );

    if (isSomeSelected) return false;

    if (!hasSelectedAll) return true;
  };

  useEffect(() => {
    if (board && order && actualSociety) {
      if (order === 'default_option') {
        setRows([]);
      } else if (board?.participants?.length) {
        const participantRows = getParticipantRows({
          order,
          board,
          actualSociety,
          partnerRepresentants,
          customSelected,
          setCustomSelected,
        });
        setRows(participantRows);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    board,
    order,
    actualSociety,
    hasSelectedAll,
    customSelected,
    setCustomSelected,
  ]);

  useEffect(() => {
    if (hasSelectedAll) {
      const newCustomSelected = {};

      board.participants.forEach((participant) => {
        newCustomSelected[participant['_id']] = true;
      });

      if (selectOnlyAssistants) {
        setSelectOnlyAssistants(false);
      }

      setCustomSelected(newCustomSelected);
    } else {
      setCustomSelected({});
    }
  }, [board.participants, hasSelectedAll]);

  useEffect(() => {
    const totalSelected = Object.values(customSelected).filter(
      (value) => value === true
    );

    const totalParticipants = board?.participants?.length || 0;

    if (totalSelected.length === totalParticipants) {
      setHasSelectedAll(true);
    }
  }, [board?.participants?.length, customSelected]);

  useEffect(() => {
    const assistantsSelected = {};

    let value = selectOnlyAssistants;
    if (hasSelectedAll && selectOnlyAssistants) {
      value = true;
    }

    board?.participants
      ?.filter(
        (participant) => participant?.assists || participant?.isRepresented
      )
      .forEach((participant) => {
        const id = participant['_id'];
        assistantsSelected[id] = value;
      });

    if (selectOnlyAssistants === true) {
      setHasSelectedAll(false);
    }
    setCustomSelected(assistantsSelected);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOnlyAssistants]);

  return (
    <table
      className="nk-tb-list is-loose traffic-channel-table"
      id="votes-table"
    >
      <thead>
        <tr className="nk-tb-item nk-tb-head">
          <th className="nk-tb-col nk-tb-channel">
            <span>{t('Partner')}</span>
          </th>
          <th className="nk-tb-col nk-tb-sessions text-center">
            <span>{t('Shares')}</span>
          </th>
          <th className="nk-tb-col nk-tb-change text-center">
            <span>ND%</span>
          </th>
          <th className="nk-tb-col nk-tb-prev-sessions">
            <span>{t('RepresentativeBoard')}</span>
          </th>
          <th className="nk-tb-col nk-tb-trend tb-col-sm">
            <span>{t('Vote')}</span>
          </th>
          <th className="nk-tb-col nk-tb-trend tb-col-sm">
            <div className="form-control-select">
              <select
                className="form-control form-control-sm ml-1"
                value
                onChange={(e) => handleVoteForAll(e.target.value)}
                disabled={isInputDisabled() || isReadOnly}
              >
                <option value="default_option">{t('Vote')}</option>
                {Object.values(voteValues).map((voteValue) => (
                  <option
                    value={voteValue.value}
                    key={JSON.stringify(voteValue)}
                  >
                    {voteValue.text}
                  </option>
                ))}
                <option value="no_vote">{t('NoVote')}</option>
              </select>
            </div>
          </th>
          <th className="nk-tb-col nk-tb-trend tb-col-sm">
            <div className="custom-control custom-control-xs custom-checkbox d-flex justify-content-center align-content-center">
              <input
                type="checkbox"
                id="all-votes"
                className="custom-control-input"
                value={hasSelectedAll}
                checked={hasSelectedAll}
                onChange={(event) => setHasSelectedAll(event.target.checked)}
              />
              <label className="custom-control-label" htmlFor="all-votes" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default BoardVotesTable;
