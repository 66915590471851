import { FC, ReactNode } from 'react';

type DetailsContentSectionProps = {
  children?: ReactNode;
};

const DetailsContentSection: FC<DetailsContentSectionProps> = ({
  children,
}) => <div className="card-inner">{children}</div>;

export default DetailsContentSection;
