// eslint-disable-next-line no-use-before-define
import React, { FC, useState } from 'react';

import './PageWithTabs.scss';
import PageWithTabsError from './components/PageWithTabsError';
import PageWithTabsLoading from './components/PageWithTabsLoading';

type Tab = {
  key: string;
  icon?: string;
  title: string;
  content: React.ReactNode;
};

type PageWithTabsProps = {
  tabs: Tab[];
  isLoading?: boolean;
  isError?: boolean;
};

const PageWithTabs: FC<PageWithTabsProps> = ({ tabs, isLoading, isError }) => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const onChangePage = (index: number) => {
    setCurrentPage(index);
  };

  const getNavLinkClassName = (index: number) =>
    `nav-link px-2 ${currentPage === index ? 'active' : ''}`;

  const tabsContent = () => {
    if (isLoading) return <PageWithTabsLoading />;
    if (isError) return <PageWithTabsError />;

    return tabs[currentPage]?.content;
  };

  return (
    <div className="nk-block pageWithTabs">
      <div className="card card-bordered">
        <div className="card-aside-wrap">
          <div className="card-content">
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
              {tabs.map((tab, index) => (
                <li className="nav-item" key={tab.key}>
                  <p
                    className={getNavLinkClassName(index)}
                    onClick={() => onChangePage(index)}
                  >
                    <em className={`icon ni ${tab.icon}`} />
                    <span>{tab.title}</span>
                  </p>
                </li>
              ))}
            </ul>

            <section className="card-inner">{tabsContent()}</section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWithTabs;
