import { store } from 'redux/store';
import { Document } from 'types';
import { deleteDocuments } from 'modules/documents/redux/documentActions';

type File = Omit<Document, 'fileType'> & {
  type: string;
};

export default async function removeAdminDeletedDocuments(
  initialDocuments: string[],
  files: File[]
): Promise<void> {
  const documents =
    initialDocuments.filter(
      (documentId) => !files.find((file) => file?._id === documentId)
    ) || [];

  if (documents?.length > 0) {
    await store.dispatch(deleteDocuments(documents));
  }
}
