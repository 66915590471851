import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import { useDispatch, useSelector } from 'react-redux';

import { Document } from 'types';

import fileTypes from 'constants/fileTypes';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import UploadDocument from 'modules/documents/components/UploadDocument';
import {
  getDocuments,
  replaceDocument,
} from 'modules/documents/redux/documentActions';
import Swal from 'sweetalert2';

type Props = {
  document: Document;
  // eslint-disable-next-line no-unused-vars
  setIsDocumentReplaced?: (isDocumentReplaced: boolean) => void;
  societyId?: string;
};

function ReplaceDocumentModal(props: Props) {
  const { document, setIsDocumentReplaced, societyId } = props;

  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);

  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };
  const handleReplaceDocument = async (event: any) => {
    event.preventDefault();
    try {
      const documentData = {
        date: new Date(),
        size: file?.size,
        name: file?.name,
        author: user?._id || document?.author,
        /* @ts-ignore */
        fileType: fileTypes[file?.type],
      };

      setIsLoading(true);

      const replacedDocument = await store.dispatch(
        replaceDocument({ documentId: document?._id, file, documentData })
      );

      if (setIsDocumentReplaced) {
        setIsDocumentReplaced(true);
      }

      if (societyId) {
        await store.dispatch(getDocuments({ society: societyId }));
      }
      setIsLoading(false);

      if (replacedDocument) {
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'DocumentReplacedSuccesfully'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
      }
    } catch (error) {
      setIsLoading(false);
    }

    handleCloseModal();
  };

  return (
    <>
      <Modal.Header>
        <div className="row">
          <h5 className="title col-12">
            <span className="d-flex"> {t('ReplaceDocument')}&nbsp;</span>
            <span className="text-muted ff-alt fs-14px">
              ({document?.name})
            </span>
          </h5>
        </div>
        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <UploadDocument file={file} setFile={setFile} label="AddDocument" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={handleReplaceDocument}
          disabled={!file}
        >
          {t('Replace')}
        </button>
      </Modal.Footer>
      {isLoading && <CustomLoading />}
    </>
  );
}

export default ReplaceDocumentModal;
