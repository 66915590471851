import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  societyId: string;
};

const CommunicationsMenu: FC<Props> = ({ societyId }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="6">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-mail" />
            </span>
            <span className="nk-menu-text">{t('Communications')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="6">
        <ul className="nk-menu-sub">
          <MenuItem
            linkTo={`/comunicaciones/${societyId}`}
            text="Communications"
          />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default CommunicationsMenu;
