import { FC } from 'react';

import { CommitteeMember } from 'types';

import CommitteeMembersTableHeader from './CommitteeMembersTableHeader';
import CommitteeMembersTableBody from './CommitteeMembersTableBody';

type Props = {
  members: CommitteeMember[];
};

const CommitteeMembersTable: FC<Props> = ({ members }) => (
  <div className="card card-bordered card-preview">
    <div className="table-responsive">
      <table className="nk-tb-list nk-tb-ulist">
        <CommitteeMembersTableHeader />

        <CommitteeMembersTableBody members={members || []} />
      </table>
    </div>
  </div>
);

export default CommitteeMembersTable;
