import { translate } from 'hooks/useTranslate';
import { BoardParticipant } from 'types';

export const getParticipantAttendance = (
  participant: BoardParticipant
): string => {
  if (participant.isRepresented) {
    return translate('Represented');
  }

  if (participant.assists) {
    return translate('Yes');
  }

  if (participant.isAbsent) {
    return translate('Nope');
  }

  return '';
};
