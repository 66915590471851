const documentDownload = (doc) => {
  const downloadDocument = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    link.click();
  };

  downloadDocument(doc.url);
};

export default documentDownload;
