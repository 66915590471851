import { store } from 'redux/store';

import { Document } from 'types';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';

import { addDocument } from 'modules/documents/redux/documentActions';

type Props = {
  file: File;
  userId: string;
  societyId: string;
  description: string;
};

export default async function saveAttachment({
  file,
  userId,
  societyId,
  description,
}: Props): Promise<Document | undefined> {
  try {
    const newDocument = {
      name: file?.name,
      file,
      size: file?.size || 0,
      date: new Date(),
      fileType: fileTypes.PDF,
      lastAccess: new Date(),
      isGenerated: true,
      author: userId,
      society: societyId,
      category: documentTypes.LETTERS,
      description,
    };
    const attachmentDocument = await store.dispatch(addDocument(newDocument));
    return attachmentDocument;
  } catch (error) {
    console.error('Error saving attachment', error);
  }
}
