import { format, isValid } from 'date-fns';

export const numberFormat = {
  thousandSeparator: '.',
  decimalSeparator: ',',
};

export const numberFormatDecimals = {
  ...numberFormat,
  decimalScale: 2,
  fixedDecimalScale: true,
};

export const currencyFormat = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  suffix: '€',
};

export const currencyFormatDecimals = {
  ...currencyFormat,
  decimalScale: 2,
  fixedDecimalScale: true,
};

export const cleanValue = (data) => {
  if (typeof data === 'string') {
    const removeSeparator = data?.replace(/\./g, '');
    const changeDecimals = removeSeparator?.replace(',', '.');
    const removeSuffix = changeDecimals?.replace('€', '');
    return +removeSuffix;
  }
  return data;
};

export const getNumberFormatted = (data) => {
  const formatStyle = new Intl.NumberFormat('de-DE');
  return formatStyle.format(data);
};

export const dateFormat = 'dd/MM/yyyy';
export const dateFormatLong = 'PPP';
export const dateFormatInverted = 'yyyy-MM-dd';
export const dateTimeFormat = 'dd/MM/yyyy HH:mm';
export const timeFormat = 'HH:mm';
export const dateFormatFilename = 'dd-MM-yyyy';
export const dateFormatYear = 'yyyy';

export const formatDateToInput = (date) => {
  if (!date || !isValid(new Date(date))) return '';
  return format(new Date(date), dateFormatInverted);
};

export const formatDateToTime = (date) => {
  if (!date || !isValid(new Date(date))) return '';

  return `${new Date(date).getHours()}:${new Date(date)
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};
