import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';

import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export const createGuest =
  (guest, showAlert = true) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/guests`,
        guest
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_GUEST,
        guest: decodedData,
      });
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.GUEST_ADDED_SUCCESSFULLY));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const getGuests = (params) => async (dispatch) => {
  try {
    const query = params ? { params } : { params: {} };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_V2}/guests`,
      query
    );

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_GUESTS,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateGuest = (guestId, updateGuestData) => async (dispatch) => {
  const body = {
    _id: guestId,
    ...updateGuestData,
  };

  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL_V2}/guests`,
      body
    );

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.UPDATE_GUEST,
      guest: decodedData,
    });
    dispatch(addAlert(alertBodyTypes.GUEST_UPDATED_SUCCESSFULLY));
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const deleteGuest =
  ({ guestId, societyId }, showAlert = true) =>
  async (dispatch) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_V2}/guests/${guestId}`,
        { params: { guestId, societyId } }
      );

      dispatch({
        type: types.DELETE_GUEST,
        guestId,
      });

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.GUEST_DELETED));
      }
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
