/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import AddSocietyDirector from 'modules/_shared/components/Modals/AddSocietyDirector';
import { setModal } from 'modules/_shared/redux/modalActions';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import statusTypes from 'modules/society/components/SocietyDirectors/statusTypes';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function DirectorPersonal({ society, director }) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.user,
  }));

  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const [address, setAddress] = useState();
  const [status, setStatus] = useState();

  const [currStatusTypes, setCurrStatusTypes] = useState(statusTypes(i18n));
  const [currDirectorTypes, setCurrDirectorTypes] = useState(
    directorTypes(i18n)
  );

  const handleEditDirector = () => {
    const directorData = { ...director };
    dispatch(
      setModal(
        <AddSocietyDirector
          societyId={society['_id']}
          action="EDIT"
          directorData={directorData}
          directorId={director['_id']}
          userId={user?.['_id']}
        />
      )
    );
  };

  useEffect(() => {
    const { line1, zip, city, country } = director.address || {};

    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : '-'
      }`
    );
  }, [director.address]);

  useEffect(() => {
    if (!director?.directorStatus) {
      if (!director?.endDate || new Date(director.endDate) > new Date()) {
        setStatus(currStatusTypes.var.ACTIVE);
      } else {
        setStatus(currStatusTypes.var.INACTIVE);
      }
    } else {
      setStatus(director.directorStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [director]);

  useEffect(() => {
    setCurrStatusTypes(statusTypes(i18n));
    setCurrDirectorTypes(directorTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        {!isReadOnly && (
          <div className="nk-block-head-content">
            <div className="float-right">
              <button
                type="button"
                className="btn btn-icon btn-trigger btn-tooltip"
                onClick={handleEditDirector}
              >
                <em className="icon ni ni-edit mr-1" />
              </button>
            </div>
          </div>
        )}

        <h5 className="title">{t('PersonalInformation')}</h5>
        <p>{t('BasicDirectorInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Name')}</span>
          <span className="profile-ud-value text-left">
            {director?.socialDenomination || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Cif')}</span>
          <span className="profile-ud-value text-left">
            {director?.cif || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Email')}</span>
          <span className="profile-ud-value text-left">
            {director?.email || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('StartDate')}</span>
          <span className="profile-ud-value text-left">
            {director?.initDate
              ? format(new Date(director?.initDate), 'yyyy-MM-dd')
              : '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('EndDate')}</span>
          <span className="profile-ud-value text-left">
            {director?.endDate
              ? format(new Date(director?.endDate), 'yyyy-MM-dd')
              : '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Position')}</span>
          <span className="profile-ud-value text-left">
            {director.directorType
              ? currDirectorTypes.name[director.directorType]
              : ''}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Status')}</span>
          {status && (
            <span
              className={`profile-ud-value text-left badge badge-dot badge-${currStatusTypes.badge[status]}`}
            >
              {currStatusTypes.name[status]}
            </span>
          )}
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value text-left">
            {director?.nationality || '-'}
          </span>
        </div>
        <div className="profile-ud wider">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value text-left">{address || '-'}</span>
        </div>
        {!director?.isNaturalPerson && (
          <>
            <div className="profile-ud wider">
              <span className="profile-ud-value text-left w-200px">
                {t('LegalPartnerRepresentative')}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label w-200px">{t('Name')}</span>
              <span className="profile-ud-value text-left">
                {director?.representative?.name || '-'}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label w-200px">{t('Cif')}</span>
              <span className="profile-ud-value text-left">
                {director?.representative?.cif || '-'}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label w-200px">{t('Email')}</span>
              <span className="profile-ud-value text-left">
                {director?.representative?.email || '-'}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label w-200px">{t('Address')}</span>
              <span className="profile-ud-value text-left">
                {director?.representative?.address || '-'}
              </span>
            </div>
            <div className="profile-ud wider">
              <span className="profile-ud-label w-200px">
                {t('Nationality')}
              </span>
              <span className="profile-ud-value text-left">
                {director?.representative?.nationality || '-'}
              </span>
            </div>
          </>
        )}
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </div>
  );
}

export default DirectorPersonal;
