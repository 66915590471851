import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { formatCurrencyDecimals, formatNumber } from 'utils/formats';

const AcceptTenderOffer = ({
  handleAcceptOffer,
  totalSharesAccepted,
  totalStocksAccepted,
  totalPhantomsAccepted,
  totalPriceAccepted,
  totalPriceByShare,
  totalRetentionPercent,
  setIsLoading,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const discountByRetention =
    (totalRetentionPercent * totalPriceAccepted) / 100;

  const closeModal = () => {
    setIsLoading(true);
    dispatch(setModal(null));
    handleAcceptOffer();
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('ConfirmSale')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-preview">
          <div className="row mb-4">
            <div className="col-12">
              <div className="modal-body modal-body-lg text-center">
                <div className="nk-modal">
                  <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-coin-eur bg-warning" />
                  <h4 className="nk-modal-title">{t('SaleDetails')}</h4>
                  <div className="nk-modal-text d-flex justify-content-center align-items-center flex-column">
                    <table className="w-350px">
                      <tr className="lead">
                        <td className="text-left py-1">
                          {t('NumberOfShares')}:
                        </td>
                        <td className="text-right">
                          <b> {formatNumber(totalSharesAccepted || 0)}</b>
                        </td>
                      </tr>
                      <tr className="lead">
                        <td className="text-left py-1">
                          {t('NumberOfPhantoms')}:
                        </td>
                        <td className="text-right">
                          <b> {formatNumber(totalPhantomsAccepted || 0)}</b>
                        </td>
                      </tr>
                      <tr className="lead">
                        <td className="text-left py-1">
                          {t('NumberOfStockOptions')}:
                        </td>
                        <td className="text-right">
                          <b> {formatNumber(totalStocksAccepted || 0)}</b>
                        </td>
                      </tr>
                      <tr className="lead">
                        <td className="text-left py-1">
                          {t('ShareUnitPrice')}:
                        </td>
                        <td className="text-right">
                          <b>
                            {formatCurrencyDecimals(totalPriceByShare) || 0}
                          </b>
                        </td>
                      </tr>
                      <tr className="lead">
                        <td className="text-left py-1">
                          {t('RetentionPercent')}:
                        </td>
                        <td className="text-right">
                          <b>{formatNumber(totalRetentionPercent || 0)}%</b>
                        </td>
                      </tr>
                      <tr className="lead">
                        <td className="text-left py-1">
                          {t('TotalSellingAmount')}:
                        </td>
                        <td className="text-right">
                          <b>
                            {formatCurrencyDecimals(
                              totalPriceAccepted - discountByRetention
                            ) || 0}
                          </b>
                        </td>
                      </tr>
                      {/* <tr className="text-soft">
                        <td colSpan={2} className="pt-3">
                          Para vender, debes marcar el siguiente checkbox y
                          presionar Vender Ahora.
                        </td>
                      </tr> */}
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-400px mx-auto">
              <div className="form-group mt-2">
                <div className="custom-control custom-control-xs custom-checkbox">
                  <input
                    type="checkbox"
                    id="checkbox-notifications"
                    className="custom-control-input"
                    value={isConfirmed}
                    checked={isConfirmed}
                    onChange={() => setIsConfirmed(!isConfirmed)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox-notifications"
                  >
                    {t('AcceptTenderCheckboxMessage')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-lg btn-success mt-4 text-center text-secondary"
            onClick={closeModal}
            disabled={!isConfirmed}
          >
            {t('SellNow')}
          </button>
          <button
            type="button"
            className="btn btn-lg btn-light mt-4 text-center"
            onClick={closeModal}
          >
            {t('Cancel')}
          </button>
        </div>
      </Modal.Body>
    </>
  );
};

export default AcceptTenderOffer;
