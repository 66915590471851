import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';

import { Employee } from 'types';

import { dateFormat } from 'constants/formats';
import {
  getEmployeeFirstEffectiveDate,
  getEmployeeJobTitle,
  getEmployeeLastContractModificationDate,
  getEmployeeSalaryAmount,
  getEmployeeTerminationDate,
} from 'utils/employees';

type Props = {
  employee: Employee;
  societyName: string;
};

const EmployeeDetailsList = (props: Props) => {
  const { t } = useTranslate();

  const { employee, societyName } = props;

  const salary = getEmployeeSalaryAmount(employee);
  const bonus = '-';
  const incorporationYear = format(
    new Date(getEmployeeFirstEffectiveDate(employee)),
    'yyyy'
  );
  const jobTitle = getEmployeeJobTitle(employee);
  const category = '-';
  const lastContractModification = format(
    new Date(getEmployeeLastContractModificationDate(employee)),
    dateFormat
  );
  const terminationDate =
    format(new Date(getEmployeeTerminationDate(employee)), dateFormat) || '-';

  const dataList = [
    {
      label: t('EmployeeName'),
      value: employee?.fullName,
    },
    {
      label: t('NifNiPassport'),
      value: employee?.cif,
    },
    {
      label: t('Business'),
      value: societyName,
    },
    {
      label: t('Salary'),
      value: salary,
    },
    {
      label: t('Bonus'),
      value: bonus,
    },
    {
      label: t('IncorporationYear'),
      value: incorporationYear,
    },
    {
      label: t('JobTitle'),
      value: jobTitle,
    },
    {
      label: t('LaboralCategory'),
      value: category,
    },
    {
      label: t('LastContractModification'),
      value: lastContractModification,
    },
    {
      label: t('TerminationDate'),
      value: terminationDate,
    },
  ];

  return (
    <ul className="list">
      {dataList.map(({ label, value }) => (
        <li key={label}>
          <b>{label}: </b>
          {value}
        </li>
      ))}
    </ul>
  );
};

export default EmployeeDetailsList;
