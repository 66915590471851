import i18n from 'i18n/config';

export default {
  PENDING: {
    value: 'PENDING',
    text: i18n.t('Pending'),
    style: 'badge-light',
    icon: 'ni ni-clock',
  },
  ACTIVE: {
    value: 'ACTIVE',
    text: i18n.t('Convocated'),
    style: 'badge-primary',
    icon: 'ni ni-check-circle',
  },
  CELEBRATING: {
    value: 'CELEBRATING',
    text: i18n.t('Celebrating'),
    style: 'badge-warning',
    icon: 'ni ni-map-pin',
  },
  CELEBRATED: {
    value: 'CELEBRATED',
    text: i18n.t('Celebrated'),
    style: 'badge-success',
    icon: 'ni ni-calendar-check',
  },
  CLOSED: {
    value: 'CLOSED',
    text: i18n.t('Closed'),
    style: 'badge-secondary',
    icon: 'ni ni-na',
  },
  CANCELED: {
    value: 'CANCELED',
    text: i18n.t('CanceledF'),
    style: 'badge-danger',
    icon: 'ni ni-cross',
  },
};
