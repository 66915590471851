import { Range } from 'types';

function addRangesFormIndex(ranges: Range[], index: number): Range[] {
  const result: Range[] = [];
  for (let i = index; i < ranges.length; i += 1) {
    result.push(ranges[i]);
  }
  return result;
}

export default function addRangeToRanges(
  from: number,
  to: number,
  ranges: Range[]
) {
  const result: Range[] = [];
  let added = false;

  const firstRange = ranges[0];
  if (firstRange.from === to + 1) {
    result.push({ from, to: firstRange.to });
    const otherRanges = addRangesFormIndex(ranges, 1);
    result.push(...otherRanges);
    return result;
  }

  for (let i = 0; i < ranges.length; i += 1) {
    const range = ranges[i];

    if (i === ranges.length - 1) {
      if (range.to + 1 === from) {
        result.push({ from: range.from, to });
      } else if (!added) {
        result.push(range);
        result.push({ from, to });
      } else {
        result.push(range);
      }
    } else {
      const nextRange = ranges[i + 1];
      if (range.from === to + 1) {
        result.push({ from, to: nextRange.to });
        added = true;
        i += 1;
      }
      if (range.to + 1 === from && to + 1 === nextRange.from) {
        result.push({ from: range.from, to: nextRange.to });
        added = true;
        i += 1;
      } else if (range.to + 1 === from) {
        result.push({ from: range.from, to });
        added = true;
      } else if (to + 1 === nextRange.from) {
        result.push(range);
        result.push({ from, to: nextRange.to });
        added = true;
        i += 1;
      } else if (range.to < from && to < nextRange.from) {
        result.push({ from, to });
        added = true;
      } else {
        result.push(range);
      }
    }
  }

  return result;
}
