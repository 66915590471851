/* eslint-disable react/no-array-index-key */
import {
  dateFormat,
  dateFormatFilename,
  dateFormatInverted,
} from 'constants/formats';
import invitationStatus from 'constants/invitationStatus';
import { format, isValid } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useDispatch } from 'react-redux';
import { getFullAddress, getVestedUnits } from 'utils/filters';

const DownloadBeneficiary = ({
  tableData,
  actualSociety,
  plans,
  getStatus,
  getStatusName,
  getUnits,
  getBeneficiaryFDPercent,
  getIncentive,
  getPlanName,
  getToVest,
  getMonthsToVest,
  getStrikePrice,
  getCertificateData,
  getConsolidatedPercent,
  getConsolidationDate,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [updatedTableData, setUpdatedTableData] = useState([]);

  const [date, setDate] = useState(format(new Date(), dateFormatInverted));

  const closeModal = () => {
    dispatch(setModal(null));
  };

  useEffect(() => {
    if (tableData) {
      setUpdatedTableData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (!tableData || !date || !actualSociety) return;

    const newData = tableData.map((props) => {
      const currentPlan = plans?.find(
        (plan) => plan['_id'] === props.beneficiary?.plan
      );
      return {
        beneficiary: props.beneficiary,
        name: props.beneficiary?.name,
        status: getStatus(props.beneficiary, props.beneficiary?.plan, date),
        statusName: getStatusName(
          props.beneficiary,
          props.beneficiary?.plan,
          date
        ),
        planId: props.beneficiary?.plan,
        societyId: actualSociety['_id'],
        units: getUnits(props.beneficiary),
        decimals: props.decimals,
        amount: props.amount,
        FDpercent: getBeneficiaryFDPercent(props.beneficiary, actualSociety),
        jobTitle: props.beneficiary?.jobTitle || '-',
        incentive: getIncentive(props.beneficiary?.plan),
        planName: getPlanName(props.beneficiary?.plan),
        consolidatedPercent: getConsolidatedPercent(
          props.beneficiary,
          props.beneficiary?.plan,
          date
        ),
        consolidationDate: getConsolidationDate(
          props.beneficiary,
          props.beneficiary?.plan
        ),
        planStartDate: new Date(props.beneficiary?.planStartDate),
        vestedUnits: getVestedUnits(props.beneficiary, currentPlan, date),
        toVestUnits: getToVest(props.beneficiary, currentPlan, date),
        months: getMonthsToVest(
          props.beneficiary,
          props.beneficiary?.plan,
          date
        ),
        salary: props.beneficiary?.salary || '-',
        laboralCategory: props.beneficiary?.laboralCategory?.name || '-',
        strikePrice: getStrikePrice(props.beneficiary?.plan),
        certificateData: getCertificateData(
          props.beneficiary,
          props.beneficiary?.plan
        ),
        documentURL: props.beneficiary?.documentURL,
        invitationStatus: invitationStatus[props.beneficiary?.invitationStatus],
        cancelationDate: props.beneficiary?.cancelationDate
          ? new Date(props.beneficiary?.cancelationDate)
          : '',
        address: getFullAddress(props.beneficiary?.address),
        country: props.beneficiary?.address?.country,
      };
    });

    setUpdatedTableData(newData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, tableData, actualSociety]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('BeneficiariesList')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-preview">
          <div className="row mb-4">
            <div className="col-12">
              <div className="form-group d-flex justify-content-between">
                <label className="form-label mt-1" htmlFor="download-date">
                  {t('SelectDateForDownloadBeneficiaries')}
                </label>
                <div className="form-control-wrap">
                  <input
                    id="download-date"
                    name="download-date"
                    type="date"
                    className="form-control date-picker"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    required
                    max="2100-01-01T00:00"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between">
          <ReactHtmlTableToExcel
            id="table-xls-button"
            className="btn btn-lg btn-primary mt-4 text-center"
            table="download-table"
            filename={`${t('BeneficiariesList')} ${
              actualSociety?.name
            } - ${format(new Date(date), dateFormatFilename)}`}
            sheet={t('ConsolidationCalendarOf')}
            buttonText={t('Download')}
          />
          <button
            type="button"
            className="btn btn-lg btn-danger mt-4 text-center"
            onClick={closeModal}
          >
            {t('Close')}
          </button>
        </div>

        <div>
          <table id="download-table" style={{ display: 'none' }}>
            <thead>
              <tr>
                <th>{t('Beneficiary')}</th>
                <th>{t('DateOfConcession')}</th>
                <th>{t('Units')}</th>
                <th>{t('ExercisePrice')}</th>
                <th>{t('%FD')}</th>
                <th>{t('JobTitle')}</th>
                <th>{t('Incentive')}</th>
                <th>{t('Plan')}</th>
                <th>{t('ConsolidatedPercent')}</th>
                <th>{t('Invitation')}</th>
                <th>{t('ConsolidationDate')}</th>
                <th>{t('Status')}</th>
                <th>{t('UnitsSeen')}</th>
                <th>{t('ToVest')}</th>
                <th>{t('MonthsToVest')}</th>
                <th>{t('Salary')}</th>
                <th>{t('LaboralCategory')}</th>
                <th>{t('StrikePrice')}</th>
                <th>{t('CancelationDate')}</th>
                <th>{t('PostalAddress')}</th>
                <th>{t('Country')}</th>
              </tr>
            </thead>
            <tbody>
              {updatedTableData?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.name || ''}</td>
                  <td>
                    {format(new Date(item?.planStartDate), dateFormat) || ''}
                  </td>
                  <td>{item?.units || ''}</td>
                  <td>{item?.amount || ''}</td>
                  <td>{item?.FDpercent || ''}</td>
                  <td>{item?.jobTitle || ''}</td>
                  <td>{item?.incentive || ''}</td>
                  <td>{item?.planName || ''}</td>
                  <td>{item?.consolidatedPercent || ''}</td>
                  <td>{item?.invitationStatus?.text || ''}</td>
                  <td>
                    {item?.consolidationDate &&
                    isValid(new Date(item?.consolidationDate))
                      ? format(new Date(item?.consolidationDate), dateFormat)
                      : '-'}
                  </td>
                  <td>{item?.statusName || ''}</td>
                  <td>{item?.vestedUnits || ''}</td>
                  <td>{item?.toVestUnits || ''}</td>
                  <td>{item?.months || ''}</td>
                  <td>{item?.salary || ''}</td>
                  <td>{item?.laboralCategory || ''}</td>
                  <td>{item?.strikePrice || ''}</td>
                  <td>
                    {item?.cancelationDate
                      ? format(new Date(item.cancelationDate), dateFormat)
                      : ''}
                  </td>
                  <td>{item?.address || ''}</td>
                  <td>{item?.country || ''}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>
                  Fecha seleccionada para la descarga:{' '}
                  {format(new Date(date), dateFormat)}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </Modal.Body>
    </>
  );
};

export default DownloadBeneficiary;
