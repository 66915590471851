import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';

import menuTypes from 'constants/menuTypes';
import PartnerCard from 'modules/partners/components/PartnerCard';

import activePartner from 'utils/activePartner';
import { getActualSociety } from 'utils/filters';

const PartnerFiles = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const { user, isAdmin, actualSociety, hasAccessCaptable } = useSelector(
    (state) => ({
      hasAccessCaptable: state.society?.actualSociety?.hasAccessCaptable,
      actualSociety: state.society?.actualSociety,
      isAdmin: state.society?.role?.isAdmin,
      user: state?.user,
    })
  );

  const [cards, setCards] = useState([]);
  const [activePartners] = useState(
    actualSociety?.partners.filter((partner) => activePartner(partner))
  );

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setCards(
        actualSociety.partners
          .filter((partner) => activePartner(partner))
          .filter((partner) =>
            !hasAccessCaptable && !isAdmin
              ? user['_id'] === partner?.user ||
                partner?.otherUsers?.includes(user['_id']) ||
                user?.usersWithAccess.some(
                  (currentUser) => partner?.user === currentUser?.['_id']
                )
              : true
          )
          .map((partner) => (
            <PartnerCard
              partner={partner}
              society={actualSociety}
              key={partner['_id']}
              hasLink={
                partner?.user === user['_id'] ||
                partner?.otherUsers?.includes(user['_id']) ||
                isAdmin
              }
            />
          ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, actualSociety, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes?.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
          <p />
        </div>
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">{t('PartnerCards')}</h3>
              <div className="nk-block-des text-soft">
                <p>
                  {`${actualSociety?.name} ${t('HavePartners', {
                    count: activePartners?.length,
                  })}`}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-gs">{cards}</div>
      </div>
    </div>
  );
};

export default PartnerFiles;
