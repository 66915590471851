import { useEffect, useState } from 'react';

import { SelectorOption, StockPlan } from 'types';

type Props = {
  plans: StockPlan[];
  planId: string;
  // eslint-disable-next-line no-unused-vars
  handleChangePlan: (event: any) => void;
};

export function useGetSelectedPlanOption({
  plans,
  planId,
  handleChangePlan,
}: Props) {
  const [selectedPlanOption, setSelectedPlanOption] =
    useState<SelectorOption>();

  const handleChangeSelectedPlanOption = (option: any) => {
    setSelectedPlanOption(option);
    handleChangePlan(option.value);
  };

  useEffect(() => {
    const selectedPlan = plans?.find((plan) => plan?._id === planId);

    if (selectedPlan) {
      const option = {
        value: selectedPlan?._id,
        label: selectedPlan?.name,
      };

      setSelectedPlanOption(option);
    }
  }, [plans, planId]);

  return {
    data: { selectedPlanOption },
    isLoading: false,
    isError: false,
    actions: { handleChangeSelectedPlanOption },
  };
}
