import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { BoardParticipant } from 'types';
import { getPartnerRepresentantsFromParticipantsList } from 'modules/boards/utils';

export const usePartnerRepresentantsParticipants = (
  participants: BoardParticipant[]
) => {
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  const representants = useMemo(() => {
    const representativesIds =
      getPartnerRepresentantsFromParticipantsList(participants);

    const uniqueRepresentativesIds = [...new Set(representativesIds)];

    return partnerRepresentants.filter((partnerRepresentant) =>
      uniqueRepresentativesIds.includes(partnerRepresentant._id)
    );
  }, [participants, partnerRepresentants]);

  return {
    data: representants,
  };
};
