import { utils, writeFile } from 'xlsx';

export const defaultSheetName = 'Sheet1';
export const defaultFileName = 'Download.xlsx';

const getColumnsWidth = (columns) => {
  if (!columns.length) return [];

  const firstRow = columns[0];
  const totalColumns = Object.keys(firstRow).length;
  const columnsWidth = Array(totalColumns).fill({ wpx: 200 });

  return columnsWidth;
};

const downloadXLS = (
  jsonData,
  fileName = defaultFileName,
  sheetName = defaultSheetName
) => {
  const ws = utils.json_to_sheet(jsonData);
  const wb = utils.book_new();

  ws['!cols'] = getColumnsWidth(jsonData);
  utils.book_append_sheet(wb, ws, sheetName);

  writeFile(wb, fileName);
};

export default downloadXLS;
