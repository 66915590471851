import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  label: string;
  value: string;
  onChange: any;
};

const PersonalDataViewBirthDate: FC<Props> = ({ label, value, onChange }) => {
  const { t } = useTranslate();

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label w-100" htmlFor="partner-birthday">
          {label}
        </label>

        <input
          id="partner-birthday"
          type="date"
          className="form-control form-control-lg date-picker"
          value={value}
          onChange={onChange}
          placeholder={t('IntroduceBirthDate')}
          max="2100-01-01"
          min="1000-01-01"
        />
      </div>
    </div>
  );
};

export default PersonalDataViewBirthDate;
