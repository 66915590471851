/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useTranslate } from 'hooks/useTranslate';
import { updateDraft } from 'modules/drafts/redux/draftActions';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';

import Swal from 'sweetalert2';
import '../Modals.scss';

function RenameDraft({ draft }) {
  const { t } = useTranslate();

  const [draftName, setDraftName] = useState(draft?.name || '');

  const saveDraft = async () => {
    const draftData = { name: draftName };
    await store.dispatch(updateDraft(draft['_id'], draftData, false));
    Swal.fire({
      title: `${t('DraftUpdatedSuccessfully')}`,
      icon: 'success',
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });

    store.dispatch(setModal(null));
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('UpdateDraft')} </h5>
        <a
          className="close cursor-pointer"
          onClick={() => store.dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross cursor-pointer" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDraft}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('Name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={draftName}
                onChange={(event) => setDraftName(event.target.value)}
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!draftName}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default RenameDraft;
