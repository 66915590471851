import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { actions } from '../constants/actions';

type Props = {
  action: (typeof actions)[keyof typeof actions];
  handleCloseModal: any;
};

const AddBeneficiaryModalHeader: FC<Props> = ({ action, handleCloseModal }) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === actions.EDIT ? t('EditBeneficiary') : t('AddBeneficiary')}
        </h5>
        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
    </>
  );
};

export default AddBeneficiaryModalHeader;
