export default {
  var: {
    pending: 'pending',
    completed: 'completed',
  },
  text: {
    pending: 'Pending',
    completed: 'Completed',
  },
  badge: {
    pending: 'secondary',
    completed: 'success',
  },
};
