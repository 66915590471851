function viewOptions(i18n) {
  return [
    {
      name: 'equity',
      icon: 'ni-book-read',
      text: i18n.t('EquityStory'),
      action: ({ setView }) => {
        setView(0);
      },
      dropdown: true,
    },
    {
      name: 'commercial',
      icon: 'ni-book-read',
      text: i18n.t('CommercialRegister'),
      action: ({ setView }) => {
        setView(1);
      },
      dropdown: true,
    },
    {
      name: 'summary',
      icon: 'ni-book-read',
      text: i18n.t('Summary'),
      action: ({ setView }) => {
        setView(2);
      },
      dropdown: true,
    },
    {
      name: 'summaryaccumulated',
      icon: 'ni-book-read',
      text: i18n.t('SummaryAccumulated'),
      action: ({ setView }) => {
        setView(3);
      },
      dropdown: true,
    },
  ];
}
export default viewOptions;
