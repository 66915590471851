import types from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(documents = initialState.documents, action) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.documents;

    case types.ADD_DOCUMENT:
      return [...documents, action.documents];

    case types.SET_DOCUMENTS:
      return action.documents;

    case types.UPDATE_DOCUMENT:
      return documents.map((doc) => {
        // eslint-disable-next-line no-underscore-dangle
        if (doc._id === action.document._id) {
          return action.document;
        }
        return doc;
      });

    case types.DELETE_DOCUMENT:
      return documents.filter((doc) => doc.id !== action.id);

    case types.DELETE_DOCUMENTS: {
      const { ids } = action;
      return documents.filter((doc) => !ids.includes(doc.id));
    }

    default:
      return documents;
  }
}
