import i18n from 'i18n/config';

export default {
  DATA_NOT_FOUND: i18n.t('NoBeneficiariesHaveBeenFo'),
  DATA_WRONG_MODEL: i18n.t('TheColumnsDoNotCorrespond'),
  DATA_INCOMPLETE: i18n.t('TheNamesAdhesionAndNifDat'),
  INVALID_DATES: i18n.t('AdhesionDateFieldsMustBeI'),
  INVALID_SALARIES: i18n.t('TheSalaryFieldsMustBeNume'),
  INVALID_UNITS: i18n.t('UnitsFieldsMustBeEntireVa'),
  DUPLICATE_CIFS_TEMPLATE: i18n.t(
    'TheTemplateContainsBeneficiaryDuplicatedCif'
  ),
  DUPLICATE_CIFS_PLAN: i18n.t('TheTemplateContainsBeneficiaryAlreadyExists'),
  MISSING_CATEGORIES: i18n.t('TheTemplateContainsCatego'),
  MISSING_UNITS: i18n.t('IfABeneficiaryHasNoDefine'),
  MISSING_SALARIES: i18n.t('EachCategoryMustBeDefined'),
  OVERFLOW_UNITS: i18n.t('UnitsValuesExceedAvailables'),
  INVALID_EMAILS: i18n.t('ThereAreBeneficiariesWithInvalidEmails'),
};
