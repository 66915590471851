/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-undef */
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { Html } from 'react-pdf-html';

import { dateFormat } from 'constants/formats';

import customStyles from './customStyles';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: `/fonts/Roboto-Regular.ttf`,
    },
    {
      src: `/fonts/Roboto-Bold.ttf`,
      fontWeight: 'bold',
    },
    {
      src: `/fonts/Roboto-Italic.ttf`,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: `/fonts/Roboto-BoldItalic.ttf`,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

const DocumentsGenerator = ({
  invitationModel,
  hasConsolidationCalendar,
  hasConsolidationCalendarNoStatus,
  hasConsolidationCalendarNoStatusENES,
  consolidationCalendar,
}) => {
  const { t } = useTranslate();

  return (
    <Document>
      <Page size="A4" style={customStyles.page}>
        <Html stylesheet={customStyles}>{invitationModel}</Html>
      </Page>

      {hasConsolidationCalendar && (
        <Page size="A4" style={customStyles.page}>
          <View style={customStyles.calendar}>
            <Text style={customStyles.calendarTitle}>
              Anexo - Calendario de consolidación
            </Text>

            <Table data={consolidationCalendar}>
              <TableHeader textAlign={'center'}>
                <TableCell style={customStyles.calendarHeader}>Fecha</TableCell>
                <TableCell style={customStyles.calendarHeader}>
                  Unidades a vestear
                </TableCell>
                <TableCell style={customStyles.calendarHeader}>
                  Total Unidades vesteadas
                </TableCell>
                <TableCell style={customStyles.calendarHeader}>
                  Estado
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => format(new Date(r.date), dateFormat)}
                />
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => r.vestedUnits}
                />
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => r.totalVestedUnits}
                />
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => r.status}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
      )}

      {hasConsolidationCalendarNoStatus && (
        <Page size="A4" style={customStyles.page}>
          <View style={customStyles.calendar}>
            <Text style={customStyles.calendarTitle}>
              Anexo - Calendario de consolidación
            </Text>

            <Table data={consolidationCalendar}>
              <TableHeader textAlign={'center'}>
                <TableCell style={customStyles.calendarHeader}>Fecha</TableCell>
                <TableCell style={customStyles.calendarHeader}>
                  Unidades a vestear
                </TableCell>
                <TableCell style={customStyles.calendarHeader}>
                  Total Unidades vesteadas
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => format(new Date(r.date), dateFormat)}
                />
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => r.vestedUnits}
                />
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => r.totalVestedUnits}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
      )}

      {hasConsolidationCalendarNoStatusENES && (
        <Page size="A4" style={customStyles.page}>
          <View style={customStyles.calendar}>
            <Text style={customStyles.calendarTitle}>
              {t('AnnexConsolidationCalendar', { lng: 'en' })}
              &nbsp;/&nbsp;
              {t('AnnexConsolidationCalendar', { lng: 'es' })}
            </Text>

            <Table data={consolidationCalendar}>
              <TableHeader textAlign={'center'}>
                <TableCell style={customStyles.calendarHeader}>
                  {t('Date', { lng: 'en' })}
                  &nbsp;/&nbsp;
                  {t('Date', { lng: 'es' })}
                </TableCell>
                <TableCell style={customStyles.calendarHeader}>
                  {t('UnitsToVesting', { lng: 'en' })}
                  &nbsp;/&nbsp;
                  {t('UnitsToVesting', { lng: 'es' })}
                </TableCell>
                <TableCell style={customStyles.calendarHeader}>
                  {t('TotalVestedUnits', { lng: 'en' })}
                  &nbsp;/&nbsp;
                  {t('TotalVestedUnits', { lng: 'es' })}
                </TableCell>
              </TableHeader>
              <TableBody>
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => format(new Date(r.date), dateFormat)}
                />
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => r.vestedUnits}
                />
                <DataTableCell
                  textAlign={'center'}
                  style={customStyles.calendarCell}
                  getContent={(r) => r.totalVestedUnits}
                />
              </TableBody>
            </Table>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default DocumentsGenerator;
