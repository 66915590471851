/* eslint-disable react/no-array-index-key */
import { EMAIL_PATTERN } from 'constants/defaultConstants';
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Tooltip } from 'react-tooltip';

import { Partner } from 'types';

import isValidEmail from 'utils/isValidEmail';

import InputError from 'modules/_shared/components/InputError';
import categoryTypes from 'modules/profile/components/UserMyData/constants/categoryTypes';

import normalizeEmail from 'utils/normalizeEmail';
import useGetPartnerEmails from '../hooks/useGetEmails';

const MAX_EMAILS_ALLOWED = 11;

type Props = {
  originalPartner: Partner;
  currentPartner: any;
  handleUpdatePartner: any;
};

const AddedEmailsView: FC<Props> = ({
  originalPartner,
  currentPartner,
  handleUpdatePartner,
}) => {
  const { t } = useTranslate();

  const { emails } = useGetPartnerEmails({
    partner: originalPartner,
    partnerEmails: currentPartner?.emails,
  }).data;

  const validEmails = () => {
    if (currentPartner?.emails?.length > 0) {
      return currentPartner?.emails?.every(
        (email: any) => email?.text !== '' && isValidEmail(email?.text)
      );
    }
    return true;
  };

  const handleChangeEmail = (value: string, index: number) => {
    const newEmails = [...emails];
    const normalizedEmail = normalizeEmail(value);

    newEmails[index].text = normalizedEmail;
    handleUpdatePartner({ ...currentPartner, emails: newEmails });
  };

  const handleRemoveEmail = (index: number) => {
    if (emails?.length > 1) {
      const newEmails = [...emails];
      newEmails.splice(index, 1);
      handleUpdatePartner({ ...currentPartner, emails: newEmails });
    } else {
      handleUpdatePartner({ ...currentPartner, emails: [] });
    }
  };

  const handleAddEmail = () => {
    const newEmails = [...emails];
    newEmails.push({ text: '', category: categoryTypes.PRIMARY });
    handleUpdatePartner({ ...currentPartner, emails: newEmails });
  };

  const handleChangeCategory = (value: any, index: number) => {
    const newEmails = [...emails];
    newEmails[index].category =
      value === categoryTypes.PRIMARY
        ? categoryTypes.SECONDARY
        : categoryTypes.PRIMARY;
    handleUpdatePartner({ ...currentPartner, emails: newEmails });
  };

  return (
    <>
      {emails?.map((email: any, index: number) => (
        <div key={`email-${index}`} className="form-control-wrap pb-1">
          <div className="input-group">
            <input
              type="email"
              className="form-control form-control-lg"
              value={email?.text}
              onChange={(event) => handleChangeEmail(event.target.value, index)}
              placeholder={t('IntroduceNewEmail')}
              pattern={EMAIL_PATTERN}
            />

            <button
              className="btn btn-outline-primary btn-dim border-radius-0 col-2 d-flex justify-content-center align-items-center"
              type="button"
              onClick={() => handleRemoveEmail(index)}
            >
              <em className="icon ni ni-minus" />
            </button>

            <div className="custom-control custom-checkbox custom-control-sm align-items-center ml-2">
              <input
                type="checkbox"
                className="custom-control-input"
                name={`email-category-${index}`}
                id={`email-category-${index}`}
                value={email?.category}
                checked={email?.category === categoryTypes.PRIMARY}
                onChange={(event) =>
                  handleChangeCategory(event.target.value, index)
                }
              />
              <label
                className="custom-control-label"
                htmlFor={`email-category-${index}`}
              >
                <span>{t('Primary')}</span>
                <em
                  className="icon ni ni-info text-gray ml-2"
                  id={`email-category-info-${index}`}
                />
                <Tooltip
                  anchorId={`email-category-info-${index}`}
                  place="top"
                  style={{
                    zIndex: 9999,
                  }}
                >
                  <p>
                    {`${t('Primary')}: ${t('EmailCategoryPrimaryInfo')}`} <br />
                    {`${t('Secondary')}: ${t('EmailCategorySecondaryInfo')}`}
                  </p>
                </Tooltip>
              </label>
            </div>
          </div>
        </div>
      ))}
      {emails?.length < MAX_EMAILS_ALLOWED && (
        <button
          type="button"
          className="btn btn-lg btn-dim btn-outline-primary w-100 d-flex align-items-center justify-content-center"
          onClick={handleAddEmail}
        >
          <div className="d-flex align-items-center">
            <em className="icon ni ni-plus me-2" />
            <span>{t('AddAnotherEmail')}</span>
          </div>
        </button>
      )}
      {!validEmails() && <InputError errorMessage="InvalidEmail" />}
    </>
  );
};

export default AddedEmailsView;
