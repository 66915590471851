export default {
  factorial: {
    name: 'Factorial HR',
    description: 'FactorialDescription',
    url: 'https://apidoc.factorialhr.com/docs',
    logo: 'https://files.readme.io/57cfbd5-small-Factorial_logo_radical_cut.png',
    instructions: `
      <blockquote className="callout callout_info" style="margin-bottom:0;" theme="📘">
        <h4 className="callout-heading" style="display:flex; margin-bottom:14px;">
          <em class="icon ni ni-info text-primary" style="margin-right:16px;"></em>
          <p>API KEY Generation</p>
        </h4>
        <p>
          An administrator should generate the KEY from the user interface
          (Factorial platform) following this
          <a
            target="_blank"
            href="https://help.factorialhr.com/how-to-create-api-keys-in-factorial"
            rel="noreferrer"
          >
            guide
          </a>
          <br />
          You can also manage KEYS via API, check Core &gt; Keys section.
        </p>
      </blockquote>`,
  },
  personio: {
    name: 'Personio',
    description: 'PersonioDescription',
    url: 'https://developer.personio.de/',
    logo: 'https://logosarchive.com/wp-content/uploads/2021/12/Personio-logo.svg',
  },
};
