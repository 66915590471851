/* eslint-disable import/prefer-default-export */
import { Society } from 'types';

export const getSocietyDataByUser = (
  society: Society,
  user: Record<string, any>
) => {
  if (!society?.demos?.length) {
    return society;
  }

  const userDemo = society.demos.find((demo) => demo.userId === user._id);

  if (!userDemo) {
    return society;
  }

  const societyData = society;

  societyData.name = userDemo.societyName;
  societyData.additional.logo = userDemo.societyLogo;
  societyData.additional.description = userDemo.societyDescription;

  return societyData;
};
