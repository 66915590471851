import { useTranslate } from 'hooks/useTranslate';
import { HoldingClass } from 'types';
import { partnerInputTypes, partnerLabels } from '../constants/partnerParams';

type ConstitutionAiModalPartnersRowProps = {
  partnerKey: string;
  index: number;
  fieldIndex: number;
  value: any;
  holdingClasses: HoldingClass[];
  onChangePartnerParam: any;
  onChangePartnerExpandView: any;
};

const ConstitutionAiModalPartnersRow = ({
  partnerKey,
  index,
  fieldIndex,
  value,
  holdingClasses,
  onChangePartnerParam,
  onChangePartnerExpandView,
}: ConstitutionAiModalPartnersRowProps) => {
  const { t } = useTranslate();

  const classOptions = holdingClasses.map((holdingClass) => (
    <option value={holdingClass['_id']} key={`class-${holdingClass['_id']}`}>
      {holdingClass.name}
    </option>
  ));

  return (
    <div className="row row-internal">
      <div className="col-4">
        <span>
          <b>
            {t(partnerLabels[partnerKey as keyof typeof partnerLabels]) ||
              partnerKey}
          </b>
        </span>
      </div>

      {partnerKey === 'Shares class' ? (
        <div className="col-7 px-0">
          <select
            className="w-100 form-control"
            value={value}
            onChange={(event) =>
              onChangePartnerParam(index, partnerKey, event.target.value)
            }
          >
            {classOptions}
          </select>
        </div>
      ) : (
        <div className="col-7 px-0">
          <input
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            id={`partner-${partnerKey}-input`}
            type={
              partnerInputTypes[partnerKey as keyof typeof partnerInputTypes]
            }
            value={value}
            className="w-100 form-control date-picker"
            readOnly={false}
            onChange={(event) =>
              onChangePartnerParam(index, partnerKey, event.target.value)
            }
          />
        </div>
      )}

      {fieldIndex === 0 ? (
        <div className="col-1">
          <button
            type="button"
            className="badge btn-primary"
            onClick={() => onChangePartnerExpandView(index, false)}
          >
            <em className="icon ni ni-chevron-up" />
          </button>
        </div>
      ) : (
        <div className="col-1" />
      )}
    </div>
  );
};

export default ConstitutionAiModalPartnersRow;
