import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  handleSavePermissions: () => void;
};

const EditPermissionsModalFooter: FC<Props> = ({ handleSavePermissions }) => {
  const { t } = useTranslate();

  return (
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-lg btn-primary "
        onClick={handleSavePermissions}
      >
        {t('SavePermissions')}
      </button>
    </Modal.Footer>
  );
};

export default EditPermissionsModalFooter;
