import { format } from 'date-fns';
import { Society, User } from 'types';

export type GuestEmailActions = 'ADD' | 'EDIT' | 'DELETE';

type CreateGuestEmailProps = {
  t: any;
  language: string;
  action: GuestEmailActions;
  user: User;
  email: string;
  actualSociety: Society;
  mainAdminName?: string;
  updatedGuestName?: string;
};

const getAddGuestEmailData = (props: CreateGuestEmailProps) => {
  const { t, language, email, user, actualSociety } = props;

  return {
    to: [{ email, name: 'Invitado' }],
    subject: t('MailAddSocietyGuestSubject'),
    template: `add-guest-${language}`,
    templateData: [
      {
        name: 'mainAdminName',
        content: user?.name || '',
      },
      {
        name: 'newGuestEmail',
        content: email,
      },
      {
        name: 'newGuestName',
        content: '',
      },
      {
        name: 'societyName',
        content: actualSociety?.name || '',
      },
      {
        name: 'targetUrl',
        content: `${process.env.REACT_APP_DOMAIN}/#/sociedad/${actualSociety?._id}`,
      },
    ],
  };
};

const getEditGuestEmailData = (props: CreateGuestEmailProps) => {
  const {
    t,
    language,
    email,
    user,
    actualSociety,
    mainAdminName,
    updatedGuestName,
  } = props;

  return {
    to: [{ email, name: mainAdminName }],
    subject: t('MailUpdateSocietyGuestSubject'),
    template: `edit-guest-${language}`,
    templateData: [
      {
        name: 'mainAdminName',
        content: mainAdminName,
      },
      {
        name: 'updatedGuestName',
        content: updatedGuestName,
      },
      {
        name: 'userName',
        content: user.name,
      },
      {
        name: 'societyName',
        content: actualSociety.name,
      },
      {
        name: 'dateTime',
        content: format(new Date(), 'dd/MM/yyyy HH:mm'),
      },
      {
        name: 'targetUrl',
        content: `${process.env.REACT_APP_DOMAIN}/#/perfil-sociedad/administradores/${actualSociety?._id}`,
      },
    ],
  };
};

const getDeleteGuestEmailData = (props: CreateGuestEmailProps) => {
  const {
    t,
    language,
    email,
    user,
    actualSociety,
    mainAdminName,
    updatedGuestName,
  } = props;

  return {
    to: [{ email, name: mainAdminName }],
    subject: t('MailDeleteSocietyGuestSubject'),
    template: `delete-guest-${language}`,
    templateData: [
      {
        name: 'mainAdminName',
        content: mainAdminName,
      },
      {
        name: 'updatedGuestName',
        content: updatedGuestName,
      },
      {
        name: 'userName',
        content: user.name,
      },
      {
        name: 'societyName',
        content: actualSociety.name,
      },
      {
        name: 'dateTime',
        content: format(new Date(), 'dd/MM/yyyy HH:mm'),
      },
      {
        name: 'targetUrl',
        content: `${process.env.REACT_APP_DOMAIN}/#/perfil-sociedad/administradores/${actualSociety?._id}`,
      },
    ],
  };
};

export const createGuestEmail = (props: CreateGuestEmailProps) => {
  const { user, actualSociety } = props;

  const baseEmailData = {
    user: user['_id'],
    society: actualSociety['_id'],
    date: new Date(),
    status: 'SENT',
  };

  let emailData = {};

  switch (props.action) {
    case 'ADD':
      emailData = getAddGuestEmailData(props);
      break;
    case 'EDIT':
      emailData = getEditGuestEmailData(props);
      break;
    case 'DELETE':
      emailData = getDeleteGuestEmailData(props);
      break;
    default:
      return;
  }

  return {
    ...baseEmailData,
    ...emailData,
  };
};
