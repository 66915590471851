/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import CellData from 'modules/_shared/components/Tables/CellData';
import screenSizes from 'constants/screenSizes';

function tableColumns(i18n) {
  return [
    {
      // mandatory props
      name: i18n.t('Employee'),
      field: 'employee',
      selector: (row) => row[i18n.t('Employee')],
      sortable: true,
      // optional props
      grow: 2,
      cell: (row) =>
        CellData.Employee({
          employee: row[i18n.t('Employee')],
          societyId: row.societyId,
          url: '/detalle-empleado',
        }),
      export: true,
    },
    {
      name: i18n.t('Name'),
      field: 'name',
      selector: (row) => row[i18n.t('Name')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Name')],
        }),
      export: false,
      omit: true,
    },
    {
      name: i18n.t('Location'),
      field: 'location',
      selector: (row) => row[i18n.t('Location')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Location')],
        }),
      export: true,
    },
    {
      name: i18n.t('Status'),
      field: 'status',
      selector: (row) => row[i18n.t('Status')],
      sortable: true,
      compact: true,
      cell: (row) =>
        CellData.BadgeText({
          text: row[i18n.t('Status')]?.text,
          style: row[i18n.t('Status')]?.color,
          outline: false,
        }),
      export: true,
    },
    {
      name: i18n.t('TaxId'),
      field: 'cif',
      selector: (row) => row[i18n.t('TaxId')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('TaxId')],
        }),
      export: true,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('Type'),
      field: 'cifType',
      selector: (row) => row[i18n.t('Type')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.BadgeText({
          text: row[i18n.t('Type')]?.toUpperCase(),
          style: 'gray',
          outline: true,
        }),
      export: true,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('Salary'),
      field: 'salary',
      selector: (row) => row[i18n.t('Salary')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('Salary')],
        }),
      export: true,
    },
    {
      name: i18n.t('Currency'),
      field: 'currency',
      selector: (row) => row[i18n.t('Currency')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Currency')],
        }),
      export: true,
    },
    {
      name: i18n.t('Incentive'),
      field: 'hasIncentive',
      selector: (row) => row[i18n.t('Incentive')],
      sortable: true,
      compact: true,
      cell: (row) =>
        CellData.BadgeText({
          text: row[i18n.t('Incentive')]?.text,
          style: row[i18n.t('Incentive')]?.color,
          outline: false,
        }),
      export: true,
    },
    {
      name: i18n.t('Department'),
      field: 'department',
      selector: (row) => row[i18n.t('Department')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Department')],
        }),
      export: true,
      hide: screenSizes.LG,
    },

    // {
    //   name: i18n.t('DateOfConcession'),
    //   field: 'planStartDate',
    //   selector: (row) => row[i18n.t('DateOfConcession')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainDate({
    //       date: row[i18n.t('DateOfConcession')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('Units'),
    //   field: 'units',
    //   selector: (row) => row[i18n.t('Units')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('Units')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('ExercisePrice'),
    //   field: 'amount',
    //   selector: (row) => row[i18n.t('ExercisePrice')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.CurrencyDecimals({
    //       value: row[i18n.t('ExercisePrice')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('%FD'),
    //   field: 'FDpercent',
    //   selector: (row) => row[i18n.t('%FD')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PercentDecimals({
    //       value: row[i18n.t('%FD')],
    //       decimals: row.decimals,
    //     }),
    //   export: true,
    //   hide: screenSizes.LG,
    // },
    // {
    //   name: i18n.t('JobTitle'),
    //   field: 'jobTitle',
    //   selector: (row) => row[i18n.t('JobTitle')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('JobTitle')],
    //     }),
    //   export: true,
    //   hide: screenSizes.LG,
    // },
    // {
    //   name: i18n.t('Incentive'),
    //   field: 'incentive',
    //   selector: (row) => row[i18n.t('Incentive')],
    //   sortable: true,
    //   compact: true,
    //   center: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('Incentive')],
    //     }),
    //   export: true,
    //   hide: screenSizes.LG,
    // },
    // {
    //   name: i18n.t('Plan'),
    //   field: 'planName',
    //   selector: (row) => row[i18n.t('Plan')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('Plan')],
    //       className: 'ml-2',
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('ConsolidatedPercent'),
    //   field: 'consolidatedPercent',
    //   selector: (row) => row[i18n.t('ConsolidatedPercent')],
    //   sortable: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.ConsolidationPercent({
    //       consolidatedPercent: row[i18n.t('ConsolidatedPercent')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('Invitation'),
    //   field: 'invitationStatus',
    //   selector: (row) => row[i18n.t('Invitation')],
    //   sortable: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.BadgeText({
    //       text: row[i18n.t('Invitation')]?.text,
    //       style: row[i18n.t('Invitation')]?.color || 'dark',
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('ConsolidationDate'),
    //   field: 'consolidationDate',
    //   selector: (row) => row[i18n.t('ConsolidationDate')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainDate({
    //       date: row[i18n.t('ConsolidationDate')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('Status'),
    //   field: 'statusName',
    //   selector: (row) => row[i18n.t('Status')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('Status')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('UnitsSeen'),
    //   field: 'vestedUnits',
    //   selector: (row) => row[i18n.t('UnitsSeen')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('UnitsSeen')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('ToVest'),
    //   field: 'toVestUnits',
    //   selector: (row) => row[i18n.t('ToVest')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('ToVest')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('MonthsToVest'),
    //   field: 'months',
    //   selector: (row) => row[i18n.t('MonthsToVest')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('MonthsToVest')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },

    // {
    //   name: i18n.t('LaboralCategory'),
    //   field: 'laboralCategory',
    //   selector: (row) => row[i18n.t('LaboralCategory')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('LaboralCategory')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('StrikePrice'),
    //   field: 'strikePrice',
    //   selector: (row) => row[i18n.t('StrikePrice')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('StrikePrice')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              {/* <MenuDots
                menuOptions={row.beneficiaryOptions}
                id={row.beneficiary['_id']}
                params={{
                  data: row.beneficiary,
                  societyId: row.societyId,
                  userType: userTypes.BENEFICIARY,
                  certificateData: row.certificateData,
                }}
                downloadURL={row.documentURL}
                direction="left"
              /> */}
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}

export default tableColumns;
