/* eslint-disable react/jsx-props-no-spreading */
import { cleanValue, numberFormatDecimals } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { updateSociety } from 'modules/society/redux/societyActions';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';

function AddSocietyValue({ society, setUpdatedValues, updatedValues }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const [valueDate, setValueDate] = useState('');
  const [societyShares, setSocietyShares] = useState(0);

  const [isPriceBySociety, setIsPriceBySociety] = useState(true);
  const [isPriceByShare, setIsPriceByShare] = useState(false);
  const [totalPriceBySociety, setTotalPriceBySociety] = useState(0);
  const [totalPriceByShare, setTotalPriceByShare] = useState(0);

  const [isValueFD, setIsValueFD] = useState(false);
  const [hasFutureValues, setHasFutureValues] = useState(false);
  const [hasAutomaticUpdate, setHasAutomaticUpdate] = useState(false);

  const [futureValues, setFutureValues] = useState([]);

  const handleChangeTypeOfPrice = (value) => {
    setIsPriceByShare(value);
    setIsPriceBySociety(!value);
  };

  const handleChangePriceBySociety = (value) => {
    setTotalPriceBySociety(value);
    setTotalPriceByShare(cleanValue(value) / societyShares);
  };

  const handleChangePriceByShare = (value) => {
    setTotalPriceByShare(value);
    setTotalPriceBySociety(cleanValue(value) * societyShares);
  };

  const handleCheckFutureValues = () => {
    if (!hasFutureValues) {
      setFutureValues([0, 0, 0]);
    }
    setHasFutureValues(!hasFutureValues);
  };

  const handleChangeFutureValue = (event, index) => {
    const tempFutureValues = [...futureValues];
    tempFutureValues[index] = event.target.value;
    setFutureValues(tempFutureValues);
  };

  const cleanFutureValues = (values) =>
    values.map((value) => cleanValue(value)).filter((value) => value);

  const isAddButtonDisabled = () => {
    if (!valueDate) {
      return true;
    }
    if (isPriceBySociety && !totalPriceBySociety) {
      return true;
    }
    if (isPriceByShare && !totalPriceByShare) {
      return true;
    }
    if (
      hasFutureValues &&
      futureValues.every((value) => cleanValue(value) === 0)
    ) {
      return true;
    }
    return false;
  };

  function updateSocietyValues(event) {
    event.preventDefault();

    const value = totalPriceBySociety;
    const shareValue = totalPriceByShare;

    if (value && valueDate) {
      const newValue = {
        date: new Date(valueDate),
        value: cleanValue(value),
        shareValue: cleanValue(shareValue),
        isPriceByShare,
        futureValues: cleanFutureValues(futureValues),
      };
      const tempUpdatedValues = [...updatedValues, newValue];
      const newSocietyData = {
        societyValues: tempUpdatedValues,
        updateSocietyValueND: hasAutomaticUpdate && !isValueFD,
        updateSocietyValueFD: hasAutomaticUpdate && isValueFD,
      };
      setUpdatedValues(tempUpdatedValues);
      dispatch(updateSociety(society['_id'], newSocietyData));
    }
    dispatch(setModal(null));
  }

  useEffect(() => {
    if (isPriceByShare) {
      setTotalPriceBySociety(cleanValue(totalPriceByShare) * societyShares);
    } else {
      setTotalPriceByShare(cleanValue(totalPriceBySociety) / societyShares);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyShares]);

  useEffect(() => {
    if (isValueFD) {
      setSocietyShares(
        society?.sharesCount?.actual +
          society?.sharesCount?.future +
          society?.sharesCount?.drafts
      );
    } else {
      setSocietyShares(society?.sharesCount?.actual);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValueFD]);

  return (
    <>
      <Modal.Header>
        <h5 className="title">{t('AddValue')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross mouse-pointer" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="tab-content">
          <form>
            <div className="row gy-4">
              <div className="col-12 col-md-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="society-value-date">
                    {t('Date')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      id="society-value-date"
                      name="society-value-date"
                      type="date"
                      className="form-control date-picker"
                      value={valueDate}
                      onChange={(event) => setValueDate(event.target.value)}
                      max="2100-01-01T00:00"
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <label className="form-label" htmlFor="default-01">
                      {t('ValueOfSociety')}
                    </label>
                    <div className="form-control-wrap">
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-md form-control-outlined"
                        allowLeadingZeros={false}
                        disabled={isPriceByShare}
                        value={totalPriceBySociety}
                        onChange={(e) =>
                          handleChangePriceBySociety(e.target.value)
                        }
                        {...numberFormatDecimals}
                      />
                      <div className="form-info px-2">€</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4">
                <div className="form-group">
                  <div className="form-control-wrap">
                    <label className="form-label" htmlFor="default-01">
                      {t('ValueShares')}
                    </label>
                    <div className="form-control-wrap">
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-md form-control-outlined"
                        allowLeadingZeros={false}
                        disabled={isPriceBySociety}
                        value={totalPriceByShare}
                        onChange={(e) =>
                          handleChangePriceByShare(e.target.value)
                        }
                        {...numberFormatDecimals}
                        decimalScale={8}
                      />
                      <div className="form-info px-2">€</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mt-0 pt-0 g-0 d-flex justify-content-center">
                <div
                  className="custom-control-sm custom-switch d-flex align-content-center"
                  id="switch-fn-nd"
                >
                  <span
                    style={{
                      marginRight: '45px',
                      fontSize: '12px',
                      paddingTop: '0',
                    }}
                  >
                    {t('%ND')}
                  </span>
                  <input
                    type="checkbox"
                    id="dillution-switch"
                    className="custom-control-input float-right"
                    checked={isValueFD === true}
                    onChange={(event) => setIsValueFD(event.target.checked)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="dillution-switch"
                  >
                    {t('%FD')}
                  </label>
                </div>
                <Tooltip
                  anchorId="switch-fn-nd"
                  place="bottom"
                  style={{
                    maxWidth: '300px',
                    zIndex: 9999,
                    padding: '6px !important',
                  }}
                >
                  {t('SocietyValueSwitchMessage')}
                </Tooltip>
              </div>
              <div className="col-12 col-md-8 mt-0 pt-0 g-0 d-flex justify-content-center">
                <div className="custom-control-sm custom-switch d-flex align-content-center">
                  <span
                    style={{
                      marginRight: '45px',
                      fontSize: '12px',
                      paddingTop: '0',
                    }}
                  >
                    {t('BySociety')}
                  </span>
                  <input
                    type="checkbox"
                    id="price-switch"
                    className="custom-control-input float-right"
                    checked={isPriceByShare === true}
                    onChange={(event) =>
                      handleChangeTypeOfPrice(event.target.checked)
                    }
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="price-switch"
                  >
                    {t('ByShares')}
                  </label>
                </div>
              </div>

              {/* @mandalf13 commented temporally - for releasing */}
              {isValueFD ? (
                <div className="col-12">
                  <div className="form-group mt-2">
                    <div className="custom-control custom-control-xs custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox-notifications"
                        className="custom-control-input"
                        value={hasAutomaticUpdate}
                        checked={hasAutomaticUpdate}
                        onChange={() =>
                          setHasAutomaticUpdate(!hasAutomaticUpdate)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox-notifications"
                      >
                        {t('AutomaticUpdatedRequestForFD')}
                      </label>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-12">
                    <div className="form-group mt-2">
                      <div className="custom-control custom-control-xs custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-notifications"
                          className="custom-control-input"
                          value={hasAutomaticUpdate}
                          checked={hasAutomaticUpdate}
                          onChange={() =>
                            setHasAutomaticUpdate(!hasAutomaticUpdate)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-notifications"
                        />
                        {t('AutomaticUpdatedRequestForND')}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group mt-0">
                      <div className="custom-control custom-control-xs custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-future-values"
                          className="custom-control-input"
                          value={hasFutureValues}
                          checked={hasFutureValues}
                          onChange={handleCheckFutureValues}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-future-values"
                        />
                        {t('DoYouWantToAddMoreValuations')}
                      </div>
                    </div>

                    {hasFutureValues && (
                      <div className="row">
                        {futureValues.map((futureValue, index) => (
                          <div className="col-12 col-md-4">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <label
                                  className="form-label"
                                  htmlFor="default-01"
                                >
                                  {t('ValuationNr')} <b>{index + 1}</b>
                                </label>
                                <div className="form-control-wrap">
                                  <NumberFormat
                                    id="outlined-normal"
                                    className="form-control form-control-md form-control-outlined"
                                    allowLeadingZeros={false}
                                    value={futureValues[index]}
                                    onChange={(e) =>
                                      handleChangeFutureValue(e, index)
                                    }
                                    {...numberFormatDecimals}
                                  />
                                  <div className="form-info px-2">€</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <div className="col-12">
                          <div
                            className="alert alert-icon alert-info mt-4"
                            role="alert"
                          >
                            <em className="icon ni ni-alert-circle" />
                            {t('AddFutureValuationsDescriptionLine1')}
                            <b>{t('SocietyValueSimulator')}</b>
                            {t('AddFutureValuationsDescriptionLine2')}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col-12">
            <button
              type="button"
              className="btn btn-lg btn-primary"
              onClick={updateSocietyValues}
              disabled={isAddButtonDisabled()}
            >
              {t('Add')}
            </button>
            <button
              type="button"
              className="btn btn-lg btn-light ml-3"
              onClick={() => dispatch(setModal(null))}
            >
              {t('ToClose')}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}

export default AddSocietyValue;
