export default {
  var: {
    NC_DRAFT: 'NC_DRAFT',
    NC_ACTIVE: 'NC_ACTIVE',
    NC_EXECUTED: 'NC_EXECUTED',
  },
  name: {
    NC_DRAFT: 'Draft',
    NC_ACTIVE: 'InForce',
    NC_EXECUTED: 'Converted',
  },
  style: {
    NC_DRAFT: 'badge-success',
    NC_ACTIVE: 'badge-danger',
    NC_EXECUTED: 'badge-gray',
  },
};
