import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import menuTypes from 'constants/menuTypes';
import { setMenu } from 'modules/_shared/redux/menuActions';

export const useGetMenu = () => {
  const dispatch = useDispatch();

  const society = useSelector((state: State) => state.society?.actualSociety);

  useEffect(() => {
    if (society) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyName: society?.name,
          societyId: society?.['_id'],
        })
      );
    }
  }, [dispatch, society]);

  return {
    data: {},
    isLoading: false,
    isError: false,
    actions: {},
  };
};
