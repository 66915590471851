import { FC } from 'react';

import { CoOwner } from 'types';

import ShareEncumbranceViewCoOwnersTableRow from './ShareEncumbranceViewCoOwnersTableRow';

type Props = {
  coOwnersList: CoOwner[];
  // eslint-disable-next-line no-unused-vars
  handleRemoveCoOwner: (index: number) => void;
};

const ShareEncumbranceViewCoOwnersTableBody: FC<Props> = ({
  coOwnersList,
  handleRemoveCoOwner,
}) => (
  <>
    <tbody>
      {coOwnersList?.map((coOwner: CoOwner, index: number) => (
        <ShareEncumbranceViewCoOwnersTableRow
          index={index}
          coOwner={coOwner}
          handleRemoveCoOwner={handleRemoveCoOwner}
        />
      ))}
    </tbody>
  </>
);

export default ShareEncumbranceViewCoOwnersTableBody;
