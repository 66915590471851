import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

import AddressDataView from './components/AddressDataView';
import LegalPersonRepresentantView from './components/LegalPersonRepresentantView';
import LegalPersonScripturesView from './components/LegalPersonScripturesView';
import OtherDataView from './components/OtherDataView';
import PersonalDataView from './components/PersonalDataView';

type Props = {
  isNaturalPerson: boolean;
  originalPartner: Partner;
  partner: Partner;
  page: number;
  constitutionFile: File | undefined;
  appointmentFile: File | undefined;
  ownershipFile: File | undefined;
  sindicationFile: File | undefined;
  handleChangeConstitutionFile: any;
  handleChangeAppointmentFile: any;
  handleChangeOwnershipFile: any;
  handleChangeSindicationFile: any;
  handleChangePage: any;
  handleChangePartnerData: any;
};

const EditPartnerModalBody: FC<Props> = ({
  isNaturalPerson,
  originalPartner,
  partner,
  page,
  constitutionFile,
  appointmentFile,
  ownershipFile,
  sindicationFile,
  handleChangeConstitutionFile,
  handleChangeAppointmentFile,
  handleChangeOwnershipFile,
  handleChangeSindicationFile,
  handleChangePage,
  handleChangePartnerData,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Body style={{ minHeight: '620px', overflowY: 'auto' }}>
        <ul className="nk-nav nav nav-tabs mt-xl">
          <li className="nav-item" onClick={() => handleChangePage(0)}>
            <p className={`nav-link ${page === 0 && 'active'}`}>
              {t('PersonalInformation')}
            </p>
          </li>

          <li className="nav-item" onClick={() => handleChangePage(1)}>
            <p className={`nav-link ${page === 1 && 'active'}`}>
              {t('Address')}
            </p>
          </li>

          {!isNaturalPerson && (
            <li className="nav-item" onClick={() => handleChangePage(2)}>
              <p className={`nav-link ${page === 2 && 'active'}`}>
                {t('DeedAndRegistryData')}
              </p>
            </li>
          )}

          {!isNaturalPerson && (
            <li className="nav-item" onClick={() => handleChangePage(3)}>
              <p className={`nav-link ${page === 3 && 'active'}`}>
                {t('LegalPartnerRepresentative')}
              </p>
            </li>
          )}

          <li className="nav-item" onClick={() => handleChangePage(4)}>
            <p className={`nav-link ${page === 4 && 'active'}`}>
              {t('OtherInformation')}
            </p>
          </li>
        </ul>

        {page === 0 && (
          <PersonalDataView
            isNaturalPerson={isNaturalPerson}
            originalPartner={originalPartner}
            partner={partner}
            sindicationFile={sindicationFile}
            handleChangePartnerData={handleChangePartnerData}
            handleChangeSindicationFile={handleChangeSindicationFile}
          />
        )}

        {page === 1 && (
          <AddressDataView
            partner={partner}
            handleChangePartnerData={handleChangePartnerData}
          />
        )}

        {page === 2 && (
          <LegalPersonScripturesView
            partner={partner}
            constitutionFile={constitutionFile}
            appointmentFile={appointmentFile}
            ownershipFile={ownershipFile}
            handleChangeConstitutionFile={handleChangeConstitutionFile}
            handleChangeAppointmentFile={handleChangeAppointmentFile}
            handleChangeOwnershipFile={handleChangeOwnershipFile}
            handleChangePartnerData={handleChangePartnerData}
          />
        )}

        {page === 3 && (
          <LegalPersonRepresentantView
            partner={partner}
            handleChangePartnerData={handleChangePartnerData}
          />
        )}

        {page === 4 && (
          <OtherDataView
            partner={partner}
            handleChangePartnerData={handleChangePartnerData}
          />
        )}
      </Modal.Body>
    </>
  );
};

export default EditPartnerModalBody;
