/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { store } from 'redux/store';

import convertibleNoteStatus from 'constants/convertibleNoteStatus';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addInvestor } from 'modules/_shared/redux/modalsActions';
import { addDraft } from 'modules/drafts/redux/draftActions';
import { getActualSociety } from 'utils/filters';

import '../Modals.scss';

function SaveDraftModal({ draftData, draftStatus, draftPartners, setTouched }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, actualSociety } = useSelector((state) => ({
    user: state?.user,
    actualSociety: state?.society?.actualSociety,
  }));
  const { t } = useTranslate();
  const { draftId } = useParams();
  const [name, setName] = useState('');

  const setDraftStatus = () => {
    /* eslint-disable no-param-reassign */
    draftData.status = draftStatus;
  };

  async function handleSaveDraft(event) {
    event.preventDefault();

    setDraftStatus();
    let draft;
    try {
      draft = await store.dispatch(addDraft({ name, ...draftData }));
      if (draftStatus === convertibleNoteStatus.var.NC_ACTIVE) {
        // eslint-disable-next-line no-restricted-syntax
        for (const partner of draftPartners) {
          const newInvestor = {
            name: partner?.name,
            cif: partner?.cif || null,
            email: partner?.email || null,
            society: draftData?.society,
            societyPartnerId: partner?.societyPartnerId || null,
            address: partner?.address,
            realContribution: partner?.realContribution,
            userId: user?.['_id'],
            userEmail: user?.email,
            draft: draft?.['_id'],
            sharesCount: { drafts: partner?.shares?.new },
            contributionDate: new Date(partner?.contributionDate),
            contribution: partner?.contribution,
            totalContribution: partner?.totalPartnerContribution,
            discountPercent: partner?.discountPercent,
            holdingClass: partner?.holdingClass,
          };
          // eslint-disable-next-line no-await-in-loop
          await store.dispatch(addInvestor(newInvestor));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTouched(false);
      getActualSociety(user, actualSociety?.['_id']);
      if (!draftId && draft) {
        history.push(`${history?.location?.pathname}/${draft?.['_id']}`);
      }
      dispatch(setModal(null));
    }
    setTouched(false);
    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('SaveDraft')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={handleSaveDraft}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="partner-file">
                  {t('DraftName')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      type="text"
                      id="draft-name"
                      name="draft-name"
                      className="form-control form-control-lg"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">{t('TheNamesOfTheDraftsMustBe')}</span>
      </Modal.Footer>
    </>
  );
}

export default SaveDraftModal;
