/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */

import screens from 'constants/screens';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';
import userTypes from 'constants/userTypes';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Beneficiary'),
      field: 'name',
      selector: (row) => row[i18n.t('Beneficiary')],
      sortable: true,
      grow: 4,
      cell: (row) =>
        CellData.Beneficiary({
          beneficiary: row.beneficiary,
          status: row.status,
          planId: row.planId,
          isDraft: row.isDraft,
          societyId: row.societyId,
          url: '/detalle-beneficiario',
        }),
      export: true,
    },
    {
      name: i18n.t('Units'),
      field: 'units',
      selector: (row) => row[i18n.t('Units')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('Units')],
        }),
      export: true,
    },
    {
      name: i18n.t('ExercisePrice'),
      field: 'amount',
      selector: (row) => row[i18n.t('ExercisePrice')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.CurrencyDecimals({
          value: row[i18n.t('ExercisePrice')],
        }),
      export: true,
    },
    {
      name: i18n.t('%FD'),
      field: 'FDpercent',
      selector: (row) => row[i18n.t('%FD')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%FD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: i18n.t('JobTitle'),
      field: 'jobTitle',
      selector: (row) => row[i18n.t('JobTitle')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('JobTitle')],
        }),
      export: true,
    },
    {
      name: i18n.t('Incentive'),
      field: 'incentive',
      selector: (row) => row[i18n.t('Incentive')],
      sortable: true,
      compact: true,
      center: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Incentive')],
        }),
      export: true,
    },
    {
      name: i18n.t('Plan'),
      field: 'planName',
      selector: (row) => row[i18n.t('Plan')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Plan')],
        }),
      export: true,
    },
    {
      name: i18n.t('ConsolidatedPercent'),
      field: 'consolidatedPercent',
      selector: (row) => row[i18n.t('ConsolidatedPercent')],
      sortable: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.ConsolidationPercent({
          consolidatedPercent: row[i18n.t('ConsolidatedPercent')],
        }),
      export: true,
    },
    {
      name: i18n.t('ConsolidationDate'),
      field: 'consolidationDate',
      selector: (row) => row[i18n.t('ConsolidationDate')],
      sortable: true,
      center: true,
      hide: screens.LG,
      compact: true,
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('ConsolidationDate')],
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={row.beneficiaryOptions}
                id={row.beneficiary['_id']}
                params={{
                  data: row.beneficiary,
                  societyId: row.societyId,
                  userType: userTypes.BENEFICIARY_DRAFT,
                  certificateData: row.certificateData,
                  isReadOnly: row.isReadOnly,
                }}
                downloadURL={row.documentURL}
                direction="left"
              />
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}
export default tableColumns;
