/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import initialState from 'redux/initialState';

export default function reducer(preview = initialState.preview, action) {
  switch (action.type) {
    case actionTypes.SET_PREVIEW:
      return action.preview;

    case actionTypes.UPDATE_PREVIEW:
      return {
        ...preview,
        ...action.data,
      };

    case actionTypes.DELETE_PREVIEW:
      return null;

    default:
      return preview;
  }
}
