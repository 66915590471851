import { useTranslate } from 'hooks/useTranslate';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import InputCharCounter from 'modules/_shared/components/InputCharCounter';
import { Landing, Section } from 'modules/dashboard/types/CustomDashboard';

import DashboardEditSectionFolders from '../DashboardEditSectionFolders';

type DashboardEditSectionProps = {
  section: Section;
  customDashboard: Landing;
  setCustomDashboard: any;
};

const DashboardEditSection: FC<DashboardEditSectionProps> = ({
  section,
  customDashboard,
  setCustomDashboard,
}) => {
  const { t } = useTranslate();

  const [title, setTitle] = useState(section?.title || '');
  const [subTitle, setSubTitle] = useState(section?.description || '');
  const [folders, setFolders] = useState(section?.folders || []);

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeSubTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setSubTitle(event.target.value);
  };

  useMemo(() => {
    const updatedSections = customDashboard.sections.map((s: Section) => {
      if (s.index === section.index) {
        return {
          ...s,
          title,
          description: subTitle,
          folders,
        };
      }
      return s;
    });

    setCustomDashboard((prev: Section) => ({
      ...prev,
      sections: updatedSections,
    }));
  }, [title, subTitle, folders]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (section.index === 0) {
      const updatedSections = [section, ...customDashboard.sections];
      setCustomDashboard((prev: Section) => ({
        ...prev,
        sections: updatedSections,
      }));
    }

    if (section.index === 999) {
      const updatedSections = [...customDashboard.sections, section];
      setCustomDashboard((prev: Section) => ({
        ...prev,
        sections: updatedSections,
      }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label className="form-label" htmlFor="dashboard-title">
            {t('Title')}
          </label>

          <div className="form-control-wrap">
            <input
              type="text"
              id="dashboard-title"
              className="form-control form-control-lg"
              name="dashboard-title"
              value={title}
              maxLength={50}
              onChange={handleChangeTitle}
            />
          </div>

          <InputCharCounter value={title} limit={50} />
        </div>
      </div>

      <div className="col-12">
        <div className="form-group">
          <label className="form-label" htmlFor="operation-comments">
            {t('SubTitle')}
          </label>
          <div className="form-control-wrap">
            <input
              type="text"
              id="dashboard-title"
              className="form-control form-control-lg"
              name="dashboard-title"
              value={subTitle}
              maxLength={100}
              onChange={handleChangeSubTitle}
            />
          </div>

          <InputCharCounter value={subTitle} limit={100} />
        </div>
      </div>

      <DashboardEditSectionFolders section={section} setFolders={setFolders} />
    </div>
  );
};

export default DashboardEditSection;
