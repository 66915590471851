import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';

import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import { setModal } from 'modules/_shared/redux/modalActions';

import AddCommitteeMemberModal from './AddCommitteMemberModal';

type Props = {
  committeeId: string | undefined;
  committeeName: string | undefined;
  membersCount: number;
  getUpdatedCommittee: () => void;
};

const CommitteeMembersPageHeader: FC<Props> = ({
  committeeId,
  committeeName,
  membersCount,
  getUpdatedCommittee,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleAddCommitteeMember = () => {
    if (committeeId) {
      dispatch(
        setModal(
          <AddCommitteeMemberModal
            committeeId={committeeId}
            getUpdatedCommittee={getUpdatedCommittee}
          />
        )
      );
    }
  };

  return (
    <PageListHeader
      title={t('CommitteeMembers')}
      subTitle={committeeName}
      description={`${committeeName || ''} ${t('HaveCommitteeMembers', {
        count: membersCount,
      })}`}
      actions={[
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddCommitteeMember}
        >
          {t('AddCommitteeMember')}
        </button>,
      ]}
    />
  );
};

export default CommitteeMembersPageHeader;
