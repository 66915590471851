import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import voteValues from 'constants/voteValues';

import getVoteInfoLabelText from '../utils/getVoteInfoLabelText';

type Props = {
  voteType: string;
  voteValue: string;
  handleChangeVoteValue: any;
};

const VoteInput: FC<Props> = ({
  voteType,
  voteValue,
  handleChangeVoteValue,
}) => {
  const { t } = useTranslate();

  const voteLabel = voteValues[voteType as keyof typeof voteValues]?.label;
  const voteInfoLabel = getVoteInfoLabelText(voteType);

  return (
    <>
      <li className="sp-package-item">
        <input
          className="sp-package-choose"
          type="radio"
          id={`${voteType}-vote`}
          value={voteType}
          checked={voteValue === voteType}
          onChange={handleChangeVoteValue}
        />
        <label className="sp-package-desc" htmlFor={`${voteType}-vote`}>
          <span className="sp-package-info">
            <span className="sp-package-title title">{t(voteLabel)}</span>
            <span className="sp-package-detail">{t(voteInfoLabel)}</span>
          </span>
          <span className="sp-package-price d-flex align-items-center justify-content-center">
            <span className="sp-package-amount yearly">
              <span className="amount">{t(voteLabel)}</span>
            </span>
          </span>
        </label>
      </li>
    </>
  );
};

export default VoteInput;
