import i18n from 'i18n/config';

export default {
  YES: {
    value: 'YES',
    text: i18n.t('Yes'),
    style: 'success',
    icon: 'ni ni-check',
  },
  NO: {
    value: 'NO',
    text: i18n.t('Nope'),
    style: 'danger',
    icon: 'ni ni-cross',
  },
  ABS: {
    value: 'ABS',
    text: i18n.t('Abstention'),
    style: 'info',
    icon: 'ni ni-equal',
  },
  OPEN: {
    value: 'OPEN',
    text: i18n.t('Unfinished'),
    style: 'primary',
    icon: 'ni ni-minus',
  },
};
