import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { Modal } from 'react-bootstrap';

import { Encumbrance } from 'types';
import { FixedShare } from '../types/Share';
import ShareEncumbranceView from '../ShareEncumbranceView';
import ShareTagView from '../ShareTagView';

type Props = {
  page: number;
  share: FixedShare;
  encumbrances: Encumbrance[];
  tag: any;
  setEncumbrances: any;
  setEncumbrancesUpdated: any;
  setTag: any;
  setTagUpdated: any;
  handleChangePage: any;
};

const UpdateSharesBody: FC<Props> = ({
  page,
  share,
  encumbrances,
  tag,
  setEncumbrances,
  setEncumbrancesUpdated,
  setTag,
  setTagUpdated,
  handleChangePage,
}) => {
  const { t } = useTranslate();

  return (
    <Modal.Body
      style={{ minHeight: '500px', overflowY: 'auto', overflow: 'visible' }}
    >
      <div className="nk-modal gy-4">
        <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
          <li className="nav-item">
            <p
              id="share-encumbrance-tab"
              className={`nav-link ${page === 0 ? 'active' : ''}`}
              onClick={() => handleChangePage(0)}
            >
              <em />
              <span>{t('ShareEncumbrance')}</span>
            </p>
          </li>
          <li className="nav-item">
            <p
              id="share-tag-tab"
              className={`nav-link ${page === 1 ? 'active' : ''}`}
              onClick={() => handleChangePage(1)}
            >
              <em />
              <span>{t('ShareTag')}</span>
            </p>
          </li>
        </ul>

        {page === 0 && (
          <ShareEncumbranceView
            share={share}
            encumbrances={encumbrances}
            setEncumbrances={setEncumbrances}
            setEncumbrancesUpdated={setEncumbrancesUpdated}
          />
        )}

        {page === 1 && (
          <ShareTagView
            share={share}
            tag={tag}
            setTag={setTag}
            setTagUpdated={setTagUpdated}
          />
        )}
      </div>
    </Modal.Body>
  );
};

export default UpdateSharesBody;
