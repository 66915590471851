import { useEffect, useState } from 'react';

import statusTypes from 'modules/society/components/SocietyRepresentative/statusTypes';

import representativeTypes from 'modules/society/components/SocietyRepresentative/representativeTypes';

import { Partner } from 'types';
import { Option } from '../types/Option';

type Props = {
  currentPartner: Partner;
};

export function useGetRepresentativeSelectedOptions({ currentPartner }: Props) {
  const [selectedAuthorityOption, setSelectedAuthorityOption] =
    useState<Option>();
  const [selectedStatusOption, setSelectedStatusOption] = useState<Option>();

  useEffect(() => {
    const status = currentPartner?.representative?.status;
    const authority = currentPartner?.representative?.authority;

    if (status) {
      setSelectedStatusOption({
        label: statusTypes.name[status as keyof typeof statusTypes.name],
        value: status,
      });
    }

    if (authority) {
      setSelectedAuthorityOption({
        label:
          representativeTypes.name[
            authority as keyof typeof representativeTypes.name
          ],
        value: authority,
      });
    }
  }, [currentPartner]);

  return {
    data: {
      selectedAuthorityOption,
      selectedStatusOption,
    },
    isLoading: false,
    isError: false,
    actions: {
      setSelectedAuthorityOption,
      setSelectedStatusOption,
    },
  };
}
