import { useTranslate } from 'hooks/useTranslate';

const Loading = () => {
  const { t } = useTranslate();

  return (
    <div className="nk-spinner">
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only">{t('Loading')}...</span>
      </div>
    </div>
  );
};

export default Loading;
