import { getSocietyTotalShares, getUsedSharesFromPlan } from 'utils/filters';

const getAssignedAndTotalShares = (
  actualSociety: any,
  plans: any,
  tenderOffers: any
) => {
  if (!plans) {
    return { societyTotalShares: 0, assignedShares: 0, sharesTotal: 0 };
  }

  const societyTotalShares = getSocietyTotalShares(actualSociety);

  return plans
    ?.filter((plan: any) => !plan.isDraft)
    ?.reduce(
      (acc: any, plan: any) => ({
        assignedShares:
          acc.assignedShares +
          getUsedSharesFromPlan(plan, actualSociety, tenderOffers),
        sharesTotal: acc.sharesTotal + plan?.sharesTotal || 0,
        societyShares: societyTotalShares,
      }),
      { assignedShares: 0, sharesTotal: 0, societyShares: 0 }
    );
};

export default getAssignedAndTotalShares;
