import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  societyId: string;
};

const PortfoliosMenu: FC<Props> = ({ societyId }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="2">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-layers" />
            </span>
            <span className="nk-menu-text">{t('Portfolio')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="2">
        <ul className="nk-menu-sub">
          <MenuItem
            linkTo={`/cuadro-portafolio/${societyId}`}
            text="Portfolio"
          />
          <MenuItem linkTo={`/diagrama-portafolio/${societyId}`} text="Chart" />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default PortfoliosMenu;
