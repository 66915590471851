/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { numberFormat } from 'constants/formats';

import filterValue from './filterValue';
import PartnerBookTableFirstColumn from './PartnerBookTableFirstColumn';
import createPartnersArray from '../utils/createPartnersArray';
import { columns } from '../constants/partnerBookConstants';

import PartnerBookTableHeader from './PartnerBookTableHeader';
import PartnerBookTableBody from './PartnerBookTableBody';
import PartnerBookTableFooter from './PartnerBookTableFooter';

import '../PartnerBook.scss';

function PartnerBookTable({
  view,
  bookDates,
  bookColumns,
  bookHeaders,
  bookComments,
  bookPartners,
  bookOperations,
  bookTotals,
  bookTotalPartners,
  bookOrder,
  bookDocuments,
  isForScreen = false,
}) {
  const [tableTotalWidth, setTableTotalWidth] = useState(0);

  const tableRef = useRef(null);
  const contentRef = useRef(null);
  const topScrollBarRef = useRef(null);
  const bottomScrollBarRef = useRef(null);

  const tableTotalRows = bookPartners?.length + 2;
  const tableTotalHeight = `${tableTotalRows * 30 + 50 + 1}px`;

  const societyId = useSelector(
    (state) => state.society?.actualSociety?.['_id']
  );

  const syncScroll = (sourceRef, targetRefs) => {
    if (!sourceRef.current) return;

    const sourceScrollLeft = sourceRef.current.scrollLeft;

    const table = document.getElementById('table-overlap');
    if (table) {
      table.style.left = `${+sourceScrollLeft}px`;
    }

    targetRefs.forEach((ref) => {
      if (ref.current) {
        ref.current.scrollLeft = sourceScrollLeft; // eslint-disable-line no-param-reassign
      }
    });
  };

  useEffect(() => {
    const updateWidth = () => {
      if (tableRef.current) {
        setTableTotalWidth(tableRef.current.scrollWidth);
      }
    };

    const observer = new MutationObserver(updateWidth);
    if (tableRef.current) {
      observer.observe(tableRef.current, {
        childList: true, // observe direct children
        subtree: true, // and lower descendants too
        characterData: true, // observe text changes
      });
    }

    updateWidth();

    return () => {
      observer.disconnect();
    };
  }, [bookPartners]);

  useEffect(() => {
    const handleTopScroll = () =>
      syncScroll(topScrollBarRef, [bottomScrollBarRef, contentRef]);
    const handleBottomScroll = () =>
      syncScroll(bottomScrollBarRef, [topScrollBarRef, contentRef]);

    const topScrollBar = topScrollBarRef.current;
    const bottomScrollBar = bottomScrollBarRef.current;

    if (topScrollBar) {
      topScrollBar.addEventListener('scroll', handleTopScroll);
    }
    if (bottomScrollBar) {
      bottomScrollBar.addEventListener('scroll', handleBottomScroll);
    }

    return () => {
      if (topScrollBar) {
        topScrollBar.removeEventListener('scroll', handleTopScroll);
      }
      if (bottomScrollBar) {
        bottomScrollBar.removeEventListener('scroll', handleBottomScroll);
      }
    };
  }, []);

  return (
    <div className={view === 0 && isForScreen ? 'nk-block' : 'd-none'}>
      <div className="partnerBookTableWrapper card card-bordered">
        <div ref={topScrollBarRef} className="scroll-sync">
          <div
            className="top-scroll"
            style={{ width: `${tableTotalWidth}px` }}
          />
        </div>

        <div
          className="content table-responsive"
          ref={contentRef}
          style={{ height: tableTotalHeight }}
        >
          <PartnerBookTableFirstColumn
            bookPartners={bookPartners}
            bookTotalPartners={bookTotalPartners}
            createPartnersArray={createPartnersArray}
          />

          <table
            className="table table-striped partner-book-table"
            id={isForScreen ? 'partnerBookTable' : 'partnerBookNumberTable'}
            ref={tableRef}
          >
            <PartnerBookTableHeader
              societyId={societyId}
              columns={columns}
              bookHeaders={bookHeaders}
              bookOperations={bookOperations}
              bookOrder={bookOrder}
              bookDates={bookDates}
              bookComments={bookComments}
              bookDocuments={bookDocuments}
              bookColumns={bookColumns}
            />

            <PartnerBookTableBody
              createPartnersArray={createPartnersArray}
              bookPartners={bookPartners}
              bookOrder={bookOrder}
              bookColumns={bookColumns}
              filterValue={filterValue}
              isForScreen={isForScreen}
              bookOperations={bookOperations}
            />

            <PartnerBookTableFooter
              bookTotals={bookTotals}
              bookColumns={bookColumns}
              bookTotalPartners={bookTotalPartners}
              bookOrder={bookOrder}
              numberFormat={numberFormat}
              isForScreen={isForScreen}
            />
          </table>
        </div>

        <div ref={bottomScrollBarRef} className="scroll-sync">
          <div
            className="bottom-scroll"
            style={{ width: `${tableTotalWidth}px` }}
          />
        </div>
      </div>
    </div>
  );
}

export default PartnerBookTable;
