import i18n from 'i18n/config';

import Swal from 'sweetalert2';

export default function errorSwal(swalText: string) {
  Swal.fire({
    icon: 'error',
    title: `<h4 class="nk-modal-title">${swalText}<br></h4>`,
    confirmButtonText: i18n.t('OK'),
    allowOutsideClick: false,
    showCancelButton: false,
    confirmButtonColor: '#6576FF',
  });
}
