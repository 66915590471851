import { useTranslate } from 'hooks/useTranslate';
import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';
import { sindicationRefs } from 'modules/boards/constants/sindicationRefs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { Partner, PartnerCategory, Society } from 'types';

type Option = {
  label: string;
  value: string;
};

type Props = {
  currentPartner: Partner;
  actualSociety: Society;
  partnerCategories: PartnerCategory[];
};

export function useGetPartnerSelectedOptions({
  currentPartner,
  actualSociety,
  partnerCategories,
}: Props) {
  const { t } = useTranslate();
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  const [selectedCategoryOption, setSelectedCategoryOption] =
    useState<Option>();
  const [selectedSindicationOption, setSelectedSindicationOption] =
    useState<Option>();
  const [selectedCivilStatusOption, setSelectedCivilStatusOption] =
    useState<Option>();

  useEffect(() => {
    const category = partnerCategories.find(
      (category) => category?._id === currentPartner?.category
    );
    if (category) {
      const option = {
        value: category?._id,
        label: category?.name,
      };
      setSelectedCategoryOption(option);
    }
  }, [currentPartner, partnerCategories]);

  useEffect(() => {
    const representantPool =
      currentPartner?.sindicationRef === sindicationRefs.PARTNER
        ? actualSociety?.partners
        : partnerRepresentants;

    const sindicationPartner = representantPool.find(
      (member) => member?.['_id'] === currentPartner?.sindication
    );
    if (sindicationPartner) {
      const option = {
        role: boardDelegationRoles.PARTNER,
        value: currentPartner?.sindication || '',
        label: `${sindicationPartner?.cif} | ${sindicationPartner?.name}`,
      };
      setSelectedSindicationOption(option);
    }
  }, [actualSociety, currentPartner, partnerCategories]);

  useEffect(() => {
    const option = {
      value: currentPartner?.civilStatus || '',
      label: t(currentPartner?.civilStatus) || t('SelectCivilStatus'),
    };
    setSelectedCivilStatusOption(option);
  }, [currentPartner]); // eslint-disable-line

  return {
    data: {
      selectedCategoryOption,
      selectedSindicationOption,
      selectedCivilStatusOption,
    },
    isLoading: false,
    isError: false,
    actions: {
      setSelectedCategoryOption,
      setSelectedSindicationOption,
      setSelectedCivilStatusOption,
    },
  };
}
