import { useEffect, useState } from 'react';

type Props = { defaultPermissions: any[] };

export const useGetPermissions = ({ defaultPermissions }: Props) => {
  const [permissions, setPermissions] = useState<any[]>();

  useEffect(() => {
    if (defaultPermissions) {
      setPermissions(defaultPermissions);
    }
  }, [defaultPermissions]);

  return {
    data: { permissions },
    isLoading: false,
    isError: false,
    actions: { setPermissions },
  };
};
