/* eslint-disable react/jsx-props-no-spreading */
import tableRows from 'constants/tableRows';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslate } from 'hooks/useTranslate';

import {
  numberFormat,
  numberFormatDecimals,
} from '../../../../constants/formats';

const WidgetTableSimple = ({
  title,
  subtitle,
  linkText,
  linkUrl,
  tooltip,
  data,
  handleDownload,
  maxRows = 10,
  isReadOnly = false,
}) => {
  const { t } = useTranslate();

  return (
    <div className="col-12 col-xl-6 mb-4">
      <div className="card card-bordered h-100">
        <div className="card-inner">
          <div className="card-title-group align-start pb-3 g-2">
            <div className="card-title card-title-sm">
              <h6 className="title">{title}</h6>
              <p>{subtitle}</p>
            </div>
            <div className="card-tools">
              {tooltip && (
                <em
                  className="card-hint icon ni ni-help"
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title={tooltip}
                />
              )}
              {linkText && linkUrl && !handleDownload && !isReadOnly && (
                <Link to={linkUrl} className="link">
                  {linkText}
                </Link>
              )}
              {linkText && handleDownload && (
                <a href="" className="link" onClick={handleDownload}>
                  {linkText}
                </a>
              )}
            </div>
          </div>
          <div className="analytics-map">
            <table className="analytics-map-data-list">
              <tbody>
                {data?.map((item) => (
                  <tr className="analytics-map-data" key={uuidv4()}>
                    {item.type === tableRows.TITLE && (
                      <td colSpan={3}>
                        <div className="card-title card-title-sm">
                          <h6 className="title">{t(item.name)}</h6>
                          <p>{item?.value1}</p>
                        </div>
                      </td>
                    )}

                    {item.type === tableRows.DIVIDER && (
                      <td colSpan={3}>
                        <hr />
                      </td>
                    )}

                    {(item.type === tableRows.TEXT ||
                      item.type === tableRows.TOTAL) && (
                      <>
                        <td
                          className={`country ${
                            item.type === tableRows.TOTAL ? 'fw-bold' : ''
                          }`}
                        >
                          {t(item?.name)}
                        </td>
                        <td
                          className={`amount ${
                            item.type === tableRows.TOTAL ? 'fw-bold' : ''
                          }`}
                        >
                          <NumberFormat
                            value={item?.value1}
                            displayType="text"
                            {...numberFormat}
                          />
                        </td>
                        {title === 'Capital' ? (
                          <td
                            className={`percent ${
                              item.type === tableRows.TOTAL ? 'fw-bold' : ''
                            }`}
                          >
                            <NumberFormat
                              value={item?.value2}
                              displayType="text"
                              {...numberFormatDecimals}
                            />
                            %
                          </td>
                        ) : (
                          <td
                            className={`percent ${
                              item.type === tableRows.TOTAL ? 'fw-bold' : ''
                            }`}
                          >
                            /&nbsp;
                            <NumberFormat
                              value={item?.value2}
                              displayType="text"
                              {...numberFormat}
                            />
                          </td>
                        )}
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WidgetTableSimple;
