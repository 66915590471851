import { store } from 'redux/store';
import { useEffect, useState } from 'react';

import { Society, StockPlan } from 'types';

import { getDocuments } from 'modules/documents/redux/documentActions';

type Props = {
  society: Society;
  plan: StockPlan;
};

async function getSocietyDocuments(
  societyId: string,
  // eslint-disable-next-line no-unused-vars
  setDocuments: (documents: any[]) => void
) {
  try {
    if (societyId) {
      await store.dispatch(getDocuments({ society: societyId }, setDocuments));
    }
  } catch (error) {
    console.error('Error getting society documents', error);
  }
}

export function useGetAttachmentDocument({ plan, society }: Props) {
  const [documents, setDocuments] = useState<any[]>([]);
  const [attachmentFile, setAttachmentFile] = useState<File | undefined>();
  const [attachmentFileName, setAttachmentFileName] = useState<string>('');
  const [isAttachmentSaved, setIsAttachmentSaved] = useState<boolean>(false);

  useEffect(() => {
    if (documents?.length > 0) {
      const documentName = documents.find(
        (document) => document?.['_id'] === plan?.invitationAttachedDocument
      )?.name;
      setAttachmentFileName(documentName);
    }
  }, [documents, plan]);

  useEffect(() => {
    if (society) {
      getSocietyDocuments(society?._id, setDocuments);
    }
  }, [society]);

  return {
    data: { attachmentFile, attachmentFileName, isAttachmentSaved },
    isLoading: false,
    isError: false,
    actions: { setAttachmentFile, setIsAttachmentSaved },
  };
}
