/* eslint-disable react/jsx-props-no-spreading */
import { v4 as uuidv4 } from 'uuid';

import DoughnutChart from 'modules/_shared/components/Charts/DoughnutChart';
import NumberFormat from 'react-number-format';
import { numberFormat } from 'constants/formats';
import { Link } from 'react-router-dom';
import { formatNumberDecimals } from 'utils/formats';

export const legendModes = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

const WidgetChartDoughnut = ({
  title,
  subtitle,
  height,
  linkText,
  linkUrl,
  legend,
  legendMode,
  data,
  isEmbedded = false,
  isReadOnly = false,
}) => (
  <div className={`${!isEmbedded ? 'col-12 col-xl-6 mb-4' : ''}`}>
    <div className={`${!isEmbedded ? 'card card-bordered h-100' : ''}`}>
      <div className={`${!isEmbedded ? 'card-inner' : ''}`}>
        {!isEmbedded && (
          <div className="card-title-group pb-3 g-2">
            <div className="card-title card-title-sm">
              <h6 className="title">{title}</h6>
              <p>{subtitle}</p>
            </div>
            {linkText && linkUrl && !isReadOnly && (
              <div className="card-tools">
                <Link to={linkUrl} className="link">
                  {linkText}
                </Link>
              </div>
            )}
          </div>
        )}

        {data && (
          <div className="analytic-ov">
            <div className="mt-3 pb-2 w-250px mx-auto">
              <DoughnutChart
                data={data.data}
                options={data.options}
                height={height}
              />
            </div>

            {legendMode === legendModes.HORIZONTAL && (
              <div className="d-flex text-center mt-2 mx-auto mw-100 justify-content-between">
                {legend?.map((item) => (
                  <div
                    className="device-status-data"
                    style={{
                      width: '80px',
                    }}
                    key={uuidv4()}
                  >
                    <em
                      className={`icon mr-1 ni ${item?.icon}`}
                      style={{
                        color: item?.color,
                      }}
                    />
                    <div className="title">{item?.title}</div>
                    <div className="amount">
                      {formatNumberDecimals(+item?.percent)}%
                    </div>
                    <div className="change text-primary">{item?.value}</div>
                  </div>
                ))}
              </div>
            )}

            {legendMode === legendModes.VERTICAL && (
              <div
                className="d-flex flex-wrap mt-2 mx-auto mw-100 justify-content-between"
                style={{
                  maxWidth: '340px',
                }}
              >
                {legend?.map((item) => (
                  <div
                    className="traffic-channel-data mb-2"
                    style={{
                      width: '160px',
                    }}
                    key={uuidv4()}
                  >
                    <div className="title">
                      <span
                        className="dot dot-lg sq"
                        data-bg="#9cabff"
                        style={{
                          background: item?.color,
                          width: '12px',
                          height: '12px',
                        }}
                      />
                      <span className="text-ellipsis">
                        {item?.title?.slice(0, 20)}
                      </span>
                    </div>
                    <div className="amount">
                      {formatNumberDecimals(+item?.percent)}%{' '}
                      <small>
                        <NumberFormat
                          value={item?.value}
                          displayType="text"
                          {...numberFormat}
                        />
                      </small>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default WidgetChartDoughnut;
