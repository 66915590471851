import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  constitutionData: any;
  partnersData: any;
  societyData: any;
  handleSaveConstitution: () => void;
};

const ConstitutionAiModalFooter: FC<Props> = ({
  constitutionData,
  partnersData,
  societyData,
  handleSaveConstitution,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveConstitution}
          disabled={!constitutionData || !partnersData || !societyData}
        >
          {t('SaveConstitution')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default ConstitutionAiModalFooter;
