import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import AutomaticIcon from 'assets/images/automatic-vote.png';

type Props = {
  handleExcecuteVote: () => void;
};

const SendVoteAutomaticView: FC<Props> = ({ handleExcecuteVote }) => {
  const { t } = useTranslate();

  return (
    <>
      <div
        className="card card-bordered py-5 d-flex justify-content-center align-items-center"
        style={{
          height: '400px',
        }}
      >
        <span className="d-flex flex-column text-center">
          <span className="icon-wrap text-danger">
            <img
              src={AutomaticIcon}
              alt="available-vote-icon"
              width={60}
              height={60}
            />
          </span>
          <div className="">
            <h5 className="title">{t('VoteAutomatically')}</h5>
            <p className="px-5 text-soft">{t('VoteAutomaticallyMessage')}</p>
          </div>
        </span>
        <span className="icon-wrap text-danger mt-2">
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={handleExcecuteVote}
          >
            {t('Vote')}
          </button>
        </span>
      </div>
    </>
  );
};

export default SendVoteAutomaticView;
