import { Board } from 'types';
import boardStatus from 'constants/boardStatus';
import { store } from 'redux/store';

const forbiddenStatus = [boardStatus.PENDING.value, boardStatus.CANCELED.value];

const currentUserRepresentants = (): string[] =>
  store.getState().user?.partnerRepresentants || [];

export const isBoardVisibleAtList = (board: Board, memberId: string) => {
  const isStatusAllowed = !forbiddenStatus.includes(board.status);

  const isMemberParticipant = board.participants.some(
    (participant) => participant.member === memberId
  );

  const isMemberRepresentant = board?.delegationRepresentants?.some(
    (representant) => currentUserRepresentants().includes(representant)
  );

  return isStatusAllowed && (isMemberParticipant || isMemberRepresentant);
};
