import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function DoughnutChart({ data, options, height = 0 }) {
  return (
    <div className="chart-container">
      {height ? (
        <Doughnut data={data} options={options} height={height} />
      ) : (
        <Doughnut data={data} options={options} />
      )}
    </div>
  );
}

export default DoughnutChart;
