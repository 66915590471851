import editBeneficiary from 'utils/editBeneficiary';
import cancelBeneficiary from 'utils/cancelBeneficiary';
import duplicateBeneficiary from 'utils/duplicateBeneficiary';
import createCertificate from 'utils/createCertificate';

export default [
  {
    icon: 'ni-eye',
    text: 'Ver',
    url: '',
  },
  {
    icon: 'ni-file',
    text: 'Certificado',
    action: createCertificate,
  },
  {
    icon: 'ni-edit',
    text: 'Editar',
    action: editBeneficiary,
  },
  {
    icon: 'ni-copy',
    text: 'Duplicar',
    action: duplicateBeneficiary,
  },
  {
    icon: 'ni-trash',
    text: 'Eliminar',
    url: '',
  },
  {
    icon: 'ni-cross-c',
    text: 'Cancelar',
    action: cancelBeneficiary,
  },
  {
    icon: 'ni-download',
    text: 'Descargar Carta',
    download: true,
  },
];
