/* eslint-disable no-underscore-dangle */
import { FC, memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslate } from 'hooks/useTranslate';
import { format } from 'date-fns';

import attendanceTypes from 'constants/attendanceTypes';
import { formatDateToTime } from 'constants/formats';

import { Board, PartnerRepresentant, Society, User } from 'types';

import BoardStatusBadge from '../BoardStatusBadge';
import BoardMemberBadge from '../BoardMemberBadge';
import { getMemberId } from './utils';

type BoardCardPartnerProps = {
  data: Board & {
    user: User;
    society: Society;
    societyId: string;
    partnerRepresentants: PartnerRepresentant[];
  };
};

const BoardCardPartner: FC<BoardCardPartnerProps> = ({ data }) => {
  const {
    _id,
    name,
    status,
    date,
    place,
    attendanceType,
    partnerRepresentants,
    societyId,
    society,
    user,
  } = data;
  const { t } = useTranslate();
  const { memberId } = useParams();

  const memberIdForLink = getMemberId({ user, society, partnerRepresentants });
  const boardLink = `/voto-junta/${societyId}/${_id}/${memberIdForLink}`;

  return (
    <div className="card card-bordered sp-plan hover-shadow">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="sp-plan-info card-inner">
            <div className="row gx-0 gy-3">
              <div className="col-12">
                <div className="sp-plan-name">
                  <h6 className="title">
                    <Link to={boardLink}>
                      {name}
                      &nbsp;
                      <BoardStatusBadge status={status} />
                      <BoardMemberBadge
                        board={data}
                        user={user}
                        memberId={memberId as string}
                      />
                    </Link>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="sp-plan-desc card-inner pt-0">
            <ul className="row gx-1">
              <li className="col-3">
                <p>
                  <span className="text-soft">{t('Date')}</span>
                  {format(new Date(date), 'dd/MM/yyyy')}
                </p>
              </li>

              <li className="col-3">
                <p>
                  <span className="text-soft">{t('StartTime')}</span>
                  {formatDateToTime(date)}
                </p>
              </li>

              <li className="col-3">
                <p>
                  <span className="text-soft">{t('PlaceOfCelebration')}</span>
                  {place || '-'}
                </p>
              </li>

              <li className="col-3">
                <p>
                  <span className="text-soft">{t('AssistanceForm')}</span>
                  {attendanceTypes?.[attendanceType]?.text || '-'}
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4">
          <div className="sp-plan-action card-inner">
            <div className="sp-plan-btn">
              <Link
                to={boardLink}
                className="btn btn-dim btn-white btn-outline-primary"
              >
                <span>{t('ViewDetails')}</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BoardCardPartner);
