/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

import { setModal } from 'modules/_shared/redux/modalActions';
import {
  createHoldingClass,
  updateHoldingClass,
} from 'modules/partners/redux/holdingClassActions';

import actionTypes from 'constants/actionTypes';
import { NUMERATE_FROM_START } from 'constants/defaultConstants';
import { cleanValue, numberFormatDecimals } from 'constants/formats';

import '../Modals.scss';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

function HoldingClassModal({ action = 'ADD', currentHoldingClass }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const actualSociety = useSelector((state) => state.society.actualSociety);
  const holdingClasses = useSelector((state) => state.holdingClasses);

  const [ordinaryClass] = useState(holdingClasses?.[0]?.['_id']);
  const [name, setName] = useState('');
  const [votes, setVotes] = useState(0);
  const [numerationOption, setNumerationOption] = useState(ordinaryClass);
  const [lockupPeriod, setLockupPeriod] = useState('');
  const [conversionRate, setConversionRate] = useState(0);
  const [nominalValue, setNominalValue] = useState(0);
  const [hasConversionRights, setHasConversionRights] = useState(false);
  const [hasLiquidationRights, setHasLiquidationRights] = useState(false);
  const [hasAntidilutionRights, setHasAntidilutionRights] = useState(false);

  function saveHoldingClass(event) {
    event.preventDefault();
    const newHoldingClass = {
      name,
      votes,
      numerationOption:
        numerationOption !== NUMERATE_FROM_START ? numerationOption : null,
      lockupPeriod: new Date(lockupPeriod),
      conversionRate,
      hasConversionRights,
      hasLiquidationRights,
      hasAntidilutionRights,
      society: actualSociety['_id'],
    };

    if (cleanValue(nominalValue) !== 0) {
      newHoldingClass.nominalValue = cleanValue(nominalValue);
    }

    if (action === actionTypes.EDIT) {
      dispatch(updateHoldingClass(currentHoldingClass['_id'], newHoldingClass));
    } else {
      dispatch(createHoldingClass(newHoldingClass));
    }
    if (!showDetails) dispatch(setModal(null));
  }

  const handleChangeNominalValue = (event) => {
    setNominalValue(event.target.value);
  };

  useEffect(() => {
    if (actualSociety && action === actionTypes.ADD) {
      setNominalValue(actualSociety?.nominalValue || 0);
    }
  }, [action, actualSociety]);

  useEffect(() => {
    if (action === actionTypes.EDIT) {
      setName(currentHoldingClass?.name);
      setVotes(currentHoldingClass?.votes);
      setNumerationOption(currentHoldingClass?.numerationOption);
      setLockupPeriod(
        currentHoldingClass?.lockupPeriod
          ? format(new Date(currentHoldingClass?.lockupPeriod), 'yyyy-MM-dd')
          : ''
      );
      setNominalValue(currentHoldingClass?.nominalValue);
      setConversionRate(currentHoldingClass?.conversionRate);
      setHasConversionRights(currentHoldingClass?.hasConversionRights);
      setHasLiquidationRights(currentHoldingClass?.hasLiquidationRights);
      setHasAntidilutionRights(currentHoldingClass?.hasAntidilutionRights);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === actionTypes.EDIT ? t('Edit') : t('Add')}{' '}
          {` ${t('ClassOfParticipations')}`}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form
          className="form-validate is-alter"
          onSubmit={(event) => saveHoldingClass(event)}
        >
          <div className="form-group">
            <label className="form-label" htmlFor="holding-name">
              {t('ClassName')}
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">{t('ClassName')}</span>
              </div>
              <input
                type="text"
                id="holding-name"
                className="form-control"
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder={t('PEClassAClassB')}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="nominal-value">
              {t('NominalValue')}
            </label>
            <div className="form-control-wrap">
              <NumberFormat
                id="outlined-normal"
                className="form-control form-control-md form-control-outlined"
                allowLeadingZeros={false}
                value={nominalValue}
                onChange={handleChangeNominalValue}
                {...numberFormatDecimals}
                decimalScale={8}
              />
              <div className="form-info px-2">€</div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('StartNumberingFrom')}:
            </label>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control"
                  value={numerationOption}
                  disabled={action === actionTypes.EDIT}
                  onChange={(event) => setNumerationOption(event.target.value)}
                >
                  {holdingClasses?.map((holdingClass) => (
                    <option
                      value={holdingClass['_id']}
                      key={`class-${holdingClass['_id']}`}
                    >
                      {holdingClass.name} ({t('NumberFromTheLastClassPar')}{' '}
                      &quot;
                      {holdingClass.name}&quot;)
                    </option>
                  ))}
                  <option>{NUMERATE_FROM_START}</option>
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="holding-votes">
              {t('NumberOfVotesForEachParti')}:
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">{t('Votes')}</span>
              </div>
              <input
                type="number"
                id="holding-votes"
                className="form-control"
                value={votes}
                onChange={(event) => setVotes(+event.target.value)}
                onWheel={(event) => event.target.blur()}
                placeholder={t('OrdinaryVotesForEachParti')}
                required
              />
            </div>
          </div>

          <label className="form-label" htmlFor="pay-amount">
            {t('ClassRights')}
          </label>
          <div className="card card-preview mb-4">
            <div className="card-inner">
              <div className="row gy-3">
                <div className="col-md-4 col-sm-6">
                  <div className="preview-block">
                    <span className="preview-title overline-title">
                      {t('Ordinary')}
                    </span>
                    <div className="custom-control custom-checkbox checked">
                      <input
                        type="checkbox"
                        id="rights-conversion"
                        className="custom-control-input"
                        value={hasConversionRights}
                        checked={hasConversionRights}
                        onChange={(event) =>
                          setHasConversionRights(event.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="rights-conversion"
                      >
                        {t('RightOfConversionToPOrdin')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="preview-block">
                    <span className="preview-title overline-title">
                      {t('Settlement')}
                    </span>
                    <div className="custom-control custom-checkbox checked">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="rights-liquidation"
                        value={hasLiquidationRights}
                        checked={hasLiquidationRights}
                        onChange={(event) =>
                          setHasLiquidationRights(event.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="rights-liquidation"
                      >
                        {t('PreferentialLiquidationLa')}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="preview-block">
                    <span className="preview-title overline-title">
                      {t('Antidilution')}:
                    </span>
                    <div className="custom-control custom-checkbox checked">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="rights-antidilution"
                        value={hasAntidilutionRights}
                        checked={hasAntidilutionRights}
                        onChange={(event) =>
                          setHasAntidilutionRights(event.target.checked)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="rights-antidilution"
                      >
                        {t('Anti-DilutionLaw')}
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <p className="text-soft">{t('IfYouMarkAnyOfTheBoxesYou')}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('ConversionRate')}
            </label>
            <span className="sub-text mb-3">
              {t('WhatIsTheConversionRateWh')}
            </span>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">
                  {t('PartOfPartOrdinary')}
                </span>
              </div>
              <input
                type="number"
                className="form-control"
                value={conversionRate}
                onChange={(event) => setConversionRate(+event.target.value)}
                onWheel={(event) => event.target.blur()}
                placeholder={t('PartOfPartOrdinary?')}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('Lock-UpPeriod')}
            </label>
            <span className="sub-text mb-3">
              {t('RightOfTransmissionOfTheP')}
            </span>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control date-picker"
                value={lockupPeriod}
                onChange={(event) => setLockupPeriod(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name}
          >
            {action === actionTypes.EDIT ? t('ToUpdate') : t('Add')}{' '}
            {t('Class')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default HoldingClassModal;
