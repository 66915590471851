/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

import { SelectorOption } from 'types';

import vestingOptions from 'constants/vestingOptions';
import cliffOptions from 'constants/cliffOptions';
import consolidationOptions from 'constants/consolidationOptions';

type Props = {
  cliff: number;
  vesting: number;
  consolidation: number;
  handleChangeCliff: (event: any) => void;
  handleChangeVesting: (event: any) => void;
  handleChangeConsolidation: (event: any) => void;
};

const getCliffOption = (cliff: number) => {
  const findedOption = cliffOptions.find(
    (option: any) => option.value === cliff
  );
  if (findedOption) {
    return {
      value: findedOption.value.toString(),
      label: findedOption.text,
    };
  }
};

const getVestingOption = (vesting: number) => {
  const findedOption = vestingOptions.find(
    (option: any) => option.value === vesting
  );
  if (findedOption) {
    return {
      value: findedOption.value.toString(),
      label: findedOption.text,
    };
  }
};

const getConsolidationOption = (consolidation: number) => {
  const findedOption = consolidationOptions.find(
    (option: any) => option.value === consolidation
  );
  if (findedOption) {
    return {
      value: findedOption.value.toString(),
      label: findedOption.text,
    };
  }
};

export function useGetSelectedOptions({
  cliff,
  vesting,
  consolidation,
  handleChangeCliff,
  handleChangeVesting,
  handleChangeConsolidation,
}: Props) {
  const [selectedCliffOption, setSelectedCliffOption] =
    useState<SelectorOption>();
  const [selectedVestingOption, setSelectedVestingOption] =
    useState<SelectorOption>();
  const [selectedConsolidationOption, setSelectedConsolidationOption] =
    useState<SelectorOption>();

  const handleChangeCliffOption = (option: any) => {
    const { value } = option;
    handleChangeCliff(value);
  };

  const handleChangeVestingOption = (option: any) => {
    const { value } = option;
    handleChangeVesting(value);
  };

  const handleChangeConsolidationOption = (option: any) => {
    const { value } = option;
    handleChangeConsolidation(value);
  };

  useEffect(() => {
    if (cliff || cliff === 0) {
      const option = getCliffOption(cliff);
      setSelectedCliffOption(option);
    }
  }, [cliff]);

  useEffect(() => {
    if (vesting) {
      const option = getVestingOption(vesting);
      setSelectedVestingOption(option);
    }
  }, [vesting]);

  useEffect(() => {
    if (consolidation) {
      const option = getConsolidationOption(consolidation);
      setSelectedConsolidationOption(option);
    }
  }, [consolidation]);

  return {
    data: {
      selectedCliffOption,
      selectedVestingOption,
      selectedConsolidationOption,
    },
    isLoading: false,
    isError: false,
    actions: {
      handleChangeCliffOption,
      handleChangeVestingOption,
      handleChangeConsolidationOption,
    },
  };
}
