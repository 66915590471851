import { Society } from 'types';

export const generateCommunicationBodyEN = (
  actualSociety: Society
) => `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8"/>
    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <title>Annual Certificate of Shares Value of ${actualSociety?.name}</title>
  </head>
  <style>
    @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
    @media (max-width: 768px) {
      .main {
          padding: 0 !important;
      }
      .email-body {
        padding: 1.75rem 20px !important;
        width: 85% !important;
      }
    }
  </style>
  <body>
    <div class="main" style="padding: 24px">
      <div style="
          background: #f5f6fa;
          font-family: Roboto;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: #8094ae;
          width: 100%;
          text-align: center;
        ">
        <a href="https://www.sttok.com">
          <img src="https://www.resources.sttok.com/images/logo-dark2x.png" alt="Sttok" style="height: 40px; margin-top: 2.75rem"/>
        </a>
        <p style="
            font-size: 13px;
            color: #6576ff;
            padding-top: 6px;
            margin-top: 0;
            padding-bottom: 24px;
          ">
          Company management software
        </p>
        <div class="email-body" style="
            width: 96%;
            max-width: 620px;
            text-align: left;
            margin: 0 auto;
            padding: 1.75rem 2.75rem;
            background: #ffffff;
          ">
          <h3 style="
              font-family: Nunito, sans-serif;
              font-size: 18px;
              color: #6576ff;
              font-weight: 600;
              margin: 10px 0;
              padding-bottom: 15px;
              line-height: 1.4;
              text-align: center;
            ">
            Shares Value
          </h3>

          <p style="text-align: justify; font-weight: 500">
              Hello, {{partnerName}}
          </p>
          <p style="text-align: justify">
              We inform you that you now have available the certificate of shares value of the company  ${actualSociety?.name}. To download it, access it using the following button.

          </p>
          <a style="
          background: #6576ff;
          border-radius: 4px;
          border: none;
          color: #ffffff;
          display: block;
          font-size: 13px;
          font-weight: 700;
          line-height: 44px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          padding: 0 30px;
          margin: 40px auto 30px;
          width: fit-content;
      ">
            Access the file
          </a>
          <p style="text-align: justify">
            Best regards,
          </p>
            <p style="text-align: justify; font-weight: 600">
            Sttok
          </p>
        </div>

          <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
          Mail sent by
              <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">Sttok.com</a> You can contact at <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
        </p>
      </div>
    </div>
  </body>
</html>`;

export const generateCommunicationBodyES = (
  actualSociety: Society
) => `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8"/>
    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>Certificado anual del Valor de las Participaciones de {{societyName}}</title>
  </head>
  <style>
    @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
    @media (max-width: 768px) {
      .main {
          padding: 0 !important;
      }
      .email-body {
        padding: 1.75rem 20px !important;
        width: 85% !important;
      }
    }
  </style>
  <body>
    <div class="main" style="padding: 24px">
      <div style="
          background: #f5f6fa;
          font-family: Roboto;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: #8094ae;
          width: 100%;
          text-align: center;
        ">
        <a href="https://www.sttok.com">
          <img src="https://www.resources.sttok.com/images/logo-dark2x.png" alt="Sttok" style="height: 40px; margin-top: 2.75rem"/>
        </a>
        <p style="
            font-size: 13px;
            color: #6576ff;
            padding-top: 6px;
            margin-top: 0;
            padding-bottom: 24px;
          ">
          Software para gestión de Sociedades
        </p>
        <div class="email-body" style="
            width: 96%;
            max-width: 620px;
            text-align: left;
            margin: 0 auto;
            padding: 1.75rem 2.75rem;
            background: #ffffff;
          ">
          <h3 style="
              font-family: Nunito, sans-serif;
              font-size: 18px;
              color: #6576ff;
              font-weight: 600;
              margin: 10px 0;
              padding-bottom: 15px;
              line-height: 1.4;
              text-align: center;
            ">
            Valor de Participaciones
          </h3>

          <p style="text-align: justify; font-weight: 500">
              Hola, {{partnerName}}
          </p>
          <p style="text-align: justify">
              Le comunicamos que ya tiene disponible el certificado del valor de las participaciones de la sociedad ${actualSociety?.name}. Para descargarlo, acceda a su ficha mediante el siguiente botón.

          </p>
          <a style="
          background: #6576ff;
          border-radius: 4px;
          border: none;
          color: #ffffff;
          display: block;
          font-size: 13px;
          font-weight: 700;
          line-height: 44px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          padding: 0 30px;
          margin: 40px auto 30px;
          width: fit-content;
      ">
            Acceder a la ficha
          </a>
          <p style="text-align: justify">
            Saludos,
          </p>
            <p style="text-align: justify; font-weight: 600">
            Sttok
          </p>
        </div>

          <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
          Email enviado por
              <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">Sttok.com</a> Puedes contactarno en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
        </p>
      </div>
    </div>
  </body>
</html>`;

export const generateCommunicationBodyFR = (
  actualSociety: Society
) => `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8"/>
    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    <title>Certificat annuel de valeur des actions de ${actualSociety?.name}</title>
  </head>
  <style>
    @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
    @media (max-width: 768px) {
      .main {
          padding: 0 !important;
      }
      .email-body {
        padding: 1.75rem 20px !important;
        width: 85% !important;
      }
    }
  </style>
  <body>
    <div class="main" style="padding: 24px">
      <div style="
          background: #f5f6fa;
          font-family: Roboto;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: #8094ae;
          width: 100%;
          text-align: center;
        ">
        <a href="https://www.sttok.com">
          <img src="https://www.resources.sttok.com/images/logo-dark2x.png" alt="Sttok" style="height: 40px; margin-top: 2.75rem"/>
        </a>
        <p style="
            font-size: 13px;
            color: #6576ff;
            padding-top: 6px;
            margin-top: 0;
            padding-bottom: 24px;
          ">
          Gestion des Partenaires
        </p>
        <div class="email-body" style="
            width: 96%;
            max-width: 620px;
            text-align: left;
            margin: 0 auto;
            padding: 1.75rem 2.75rem;
            background: #ffffff;
          ">
          <h3 style="
              font-family: Nunito, sans-serif;
              font-size: 18px;
              color: #6576ff;
              font-weight: 600;
              margin: 10px 0;
              padding-bottom: 15px;
              line-height: 1.4;
              text-align: center;
            ">
            Offre de Vente
          </h3>

          <p style="text-align: justify; font-weight: 500">
              Bonjour {{partnerName}}
          </p>
          <p style="text-align: justify">
               Nous vous informons que vous disposez désormais du certificat de valeur des actions de la société ${actualSociety?.name}. Pour le télécharger, accédez-y en utilisant le bouton suivant.
          </p>
          <a style="
          background: #6576ff;
          border-radius: 4px;
          border: none;
          color: #ffffff;
          display: block;
          font-size: 13px;
          font-weight: 700;
          line-height: 44px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          padding: 0 30px;
          margin: 40px auto 30px;
          width: fit-content;
      ">
          Accéder au profil du partenaire
      </a>
          <p style="text-align: justify">
            Cordialement,
          </p>
            <p style="text-align: justify; font-weight: 600">
            Sttok
          </p>
        </div>

          <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
          Courrier envoyé par
              <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Vous pouvez contacter au <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
        </p>
      </div>
    </div>
  </body>
</html`;
