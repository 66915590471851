/* eslint-disable no-plusplus */
function addWatermark(doc, watermarkData) {
  const totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    const { pageSize } = doc.internal;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();

    doc.setPage(i);
    doc.setFont('courier', 'normal');
    doc.setFontSize(10);
    doc.setTextColor('#E0E0E0');
    doc.text(watermarkData, pageWidth / 2, pageHeight - 40, {
      align: 'center',
      maxWidth: 500,
    });
  }

  return doc;
}

export default addWatermark;
