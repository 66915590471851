/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getActualSociety } from 'utils/filters';

import menuTypes from 'constants/menuTypes';
import MenuDots from 'modules/_shared/components/MenuDots';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';

import { getDocuments } from 'modules/documents/redux/documentActions';
import TemplateDocumentModal from './documentModal';
import menuOptions from './menuOptions';

const viewTypes = {
  GRID: 'grid',
  LIST: 'list',
  GROUP: 'group',
};

const Templates = ({ embedded = false }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const { user, documents, actualSociety } = useSelector((state) => ({
    user: state.user,
    documents: state.documents,
    actualSociety: state.society?.actualSociety,
  }));

  const [rows, setRows] = useState([]);
  const [viewType, setViewType] = useState(
    embedded ? viewTypes.LIST : viewTypes.GRID
  );
  const [filteredDocuments, setFilteredDocuments] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    const docs = documents
      .filter((document) => document.isGenerated === true)
      .sort((a, b) => new Date(b.date) - new Date(a.date));
    setFilteredDocuments(docs);

    setRows(
      docs?.map((document) => (
        <div className="nk-file-item nk-file" key={document['_id']}>
          <div className="nk-file-info">
            <div className="nk-file-title">
              <div className="nk-file-icon">
                <span className="nk-file-icon-link">
                  <span className="nk-file-icon-type">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72">
                      <g>
                        <path
                          d="M49,61H23a5.0147,5.0147,0,0,1-5-5V16a5.0147,5.0147,0,0,1,5-5H40.9091L54,22.1111V56A5.0147,5.0147,0,0,1,49,61Z"
                          style={{ fill: '#e3edfc' }}
                        />
                        <path
                          d="M54,22.1111H44.1818a3.3034,3.3034,0,0,1-3.2727-3.3333V11s1.8409.2083,6.9545,4.5833C52.8409,20.0972,54,22.1111,54,22.1111Z"
                          style={{ fill: '#b7d0ea' }}
                        />
                        <path
                          d="M19.03,59A4.9835,4.9835,0,0,0,23,61H49a4.9835,4.9835,0,0,0,3.97-2Z"
                          style={{ fill: '#c4dbf2' }}
                        />
                        <rect
                          x="27"
                          y="31"
                          width="18"
                          height="2"
                          rx="1"
                          ry="1"
                          style={{ fill: '#599def' }}
                        />
                        <rect
                          x="27"
                          y="36"
                          width="18"
                          height="2"
                          rx="1"
                          ry="1"
                          style={{ fill: '#599def' }}
                        />
                        <rect
                          x="27"
                          y="41"
                          width="18"
                          height="2"
                          rx="1"
                          ry="1"
                          style={{ fill: '#599def' }}
                        />
                        <rect
                          x="27"
                          y="46"
                          width="12"
                          height="2"
                          rx="1"
                          ry="1"
                          style={{ fill: '#599def' }}
                        />
                      </g>
                    </svg>
                  </span>
                </span>
              </div>
              <div className="nk-file-name">
                <div className="nk-file-name-text">
                  <span className="title">{document.name}</span>
                </div>
              </div>
            </div>
            {viewType !== viewTypes.LIST && (
              <ul className="nk-file-desc">
                <li className="date">
                  {format(new Date(document.date), 'dd/MM/yyyy')}
                </li>
              </ul>
            )}
          </div>
          {viewType === viewTypes.LIST && (
            <div className="nk-file-meta">
              <div className="tb-lead text-soft">
                {format(new Date(document.date), 'dd/MM/yyyy')}
              </div>
            </div>
          )}
          <div className="nk-file-actions">
            <div className="dropdown">
              <MenuDots
                menuOptions={menuOptions}
                id={document['_id']}
                params={document} // TODO: create param for send data, not Id
                direction="left"
              />
            </div>
          </div>
        </div>
      ))
    );
  }, [documents, viewType]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: embedded ? null : menuTypes.EXTENDED,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety]);

  useEffect(() => {
    if (societyId) {
      dispatch(getDocuments({ society: societyId }));
    }
  }, [societyId]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        {embedded ? (
          <div
            className="nk-block-head d-flex justify-content-end"
            style={{ marginTop: '-80px' }}
          >
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                dispatch(
                  setModal(
                    <TemplateDocumentModal
                      user={user}
                      society={actualSociety}
                      generateDoc={() => {}}
                    />
                  )
                )
              }
            >
              {t('GenerateNewDocument')}
            </button>
          </div>
        ) : (
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {actualSociety?.name}
              </h3>
              <p />
            </div>
            <div className="nk-block-between g-3">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">{t('Templates')}</h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`Tienes ${filteredDocuments?.length} plantilla(s) de ${actualSociety?.name}`}
                  </p>
                </div>
              </div>
              <div className="nk-block-head-content">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    dispatch(
                      setModal(
                        <TemplateDocumentModal
                          user={user}
                          society={actualSociety}
                          generateDoc={() => {}}
                        />
                      )
                    )
                  }
                >
                  {t('GenerateNewDocument')}
                </button>
              </div>
            </div>
          </div>
        )}
        {rows?.length > 0 ? (
          <div className="nk-fmg-listing nk-block">
            <div className="nk-block-head-xs">
              <div className="nk-block-between g-2">
                <div className="nk-block-head-content" />
                <div className="nk-block-head-content">
                  <ul className="nk-block-tools g-3 nav">
                    <li>
                      <span
                        data-toggle="tab"
                        className={`nk-switch-icon cursor-pointer ${
                          viewType === viewTypes.GRID && 'active'
                        }`}
                        onClick={() => setViewType(viewTypes.GRID)}
                      >
                        <em className="icon ni ni-view-grid3-wd" />
                      </span>
                    </li>
                    <li>
                      <span
                        data-toggle="tab"
                        className={`nk-switch-icon cursor-pointer ${
                          viewType === viewTypes.GROUP && 'active'
                        }`}
                        onClick={() => setViewType(viewTypes.GROUP)}
                      >
                        <em className="icon ni ni-view-group-wd" />
                      </span>
                    </li>
                    <li>
                      <span
                        data-toggle="tab"
                        className={`nk-switch-icon cursor-pointer ${
                          viewType === viewTypes.LIST && 'active'
                        }`}
                        onClick={() => setViewType(viewTypes.LIST)}
                      >
                        <em className="icon ni ni-view-row-wd" />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="tab-content">
              <div className="tab-pane active" id="file-grid-view">
                <div className={`nk-files  nk-files-view-${viewType}`}>
                  <div className="nk-files-list">{rows}</div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Templates;
