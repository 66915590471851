import { SocietyChart } from 'types';
import diagramTypes from 'constants/diagramTypes';
import { formatNumber, formatNumberDecimals } from 'utils/formats';
import i18n from 'i18n/config';

type DiagramType = 'HOLDING' | 'PARTNER' | 'ADMIN';

const getHolderInfoByDiagram = (
  society: SocietyChart,
  diagramType: DiagramType
) => {
  switch (diagramType) {
    case diagramTypes.HOLDING:
    case diagramTypes.PARTNER: {
      const { holdingShares, holdingPercentND, holdingPercentFD } = society;

      if (holdingShares) {
        const prefix = `${formatNumberDecimals(holdingPercentND)}% ND`;
        const suffix = `${formatNumberDecimals(holdingPercentFD)}% FD`;

        return `${prefix} / ${suffix}`;
      }
      return `0% ND / 0% FD`;
    }

    case diagramTypes.ADMIN: {
      const { holdingValue, holdingPartners } = society || {};

      const partnersLabel =
        holdingPartners > 1 ? i18n.t('Partners') : i18n.t('Partner');

      const prefix = `${formatNumber(holdingPartners)} ${partnersLabel}`;
      const suffix = `${formatNumber(holdingValue)} €`;

      return `${prefix} / ${suffix}`;
    }
    default:
      break;
  }
};

export default getHolderInfoByDiagram;
