/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */

import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Modal } from 'react-bootstrap';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { createSociety as createSocietyAction } from 'modules/society/redux/societyActions';

import eventTypes from 'constants/eventTypes';
import queryTypes from 'constants/queryTypes';

import { fileToArrayBuffer } from 'utils/files';
import { hasBlockAI } from 'utils/showDetails';
import trackEvent from 'utils/trackEvent';

import documentsLogo from 'assets/images/icon-sections/documents.svg';
import {
  NOT_FOUND_TAG,
  extractDataFromPDF,
  extractDataFromText,
  getDocxContent,
  mapResultToParams,
} from '../Constitution/utils/documents';

import CustomLoading from '../../CustomLoading';
import AddSocietyDocPreview from './components/AddSocietyDocPreview';
import {
  fields,
  fileTypes,
  labels,
  params,
  requiredParams,
} from './constants/addSociety';

import alertBodyTypes from '../../Alert/alertBodyTypes';
import './AddSociety.scss';
import { uploadDocument } from './utils/uploadDocument';

const mockedResponse =
  'Nombre de la sociedad: GRAVITER DEMO, S.L.\nNúmero de identificación fiscal: NOT_FOUND\nDirección: Calle Tenor Fleta 64, 1º dcha\nCódigo postal: NOT_FOUND\nCiudad: Zaragoza\nPaís: España\n\nLa información que no se encuentra explícitamente en el texto, como el Número de identificación fiscal de la sociedad y el Código postal, no se puede determinar a partir del texto proporcionado y, por lo tanto, se ha puesto "NOT_FOUND" en esos campos.';

const storeAIResponse = true;

const AddSocietyAI = ({ file, onCloseModal }) => {
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [pendingParams, setPendingParams] = useState(params);

  const [extractedData, setExtractedData] = useState(null);
  const [extractedRawData, setExtractedRawData] = useState(null);

  const [customSocialDenomination, setCustomSocialDenomination] = useState('');
  const [customCIF, setCustomCIF] = useState('');
  const [customLine1, setCustomLine1] = useState('');
  const [customZip, setCustomZip] = useState('');
  const [customCity, setCustomCity] = useState('');
  const [customCountry, setCustomCountry] = useState('');

  const [modalPrivacy, setModalPrivacy] = useState(true);
  const [modalSendNotifications] = useState(false);
  const [modalHasAccessCaptable] = useState(false);

  const createSociety = async () => {
    const societyName = customSocialDenomination;
    const societyCIF = customCIF;
    const society = {
      name: societyName,
      cif: societyCIF,
      legalAddress: {
        line1: customLine1,
        zip: customZip,
        city: customCity,
        country: customCountry,
      },
      creationDate: new Date(),
      mainAdmin: user['_id'],
      administrators: [user['_id']],
      modalSendNotifications,
      modalHasAccessCaptable,
    };
    const newSociety = await dispatch(createSocietyAction(society));

    const eventData = {
      operation: eventTypes.ADD_SOCIETY_AI,
      societyName,
      societyCIF,
      notifications: modalSendNotifications,
      userId: user['_id'],
      userName: user?.name,
      userEmail: user?.email,
    };

    if (storeAIResponse) {
      const uploadedDocument = await uploadDocument({
        file,
        societyId: null,
        userId: null,
      });

      eventData.analysisAI = {
        document: uploadedDocument?.url || '',
        rawData: extractedRawData,
        data: extractedData,
        outputData: {
          name: societyName,
          cif: societyCIF,
          line1: customLine1,
          zip: customZip,
          city: customCity,
          country: customCountry,
        },
      };
    }

    trackEvent(mixpanel, eventData.operation, eventData);

    history.push(`/socios/${newSociety?.['_id']}`);
  };

  const getCustomValue = (key) => {
    switch (fields[key]) {
      case 'name':
        return customSocialDenomination;

      case 'cif':
        return customCIF;

      case 'line1':
        return customLine1;

      case 'zip':
        return customZip;

      case 'city':
        return customCity;

      case 'country':
        return customCountry;

      default:
        return '';
    }
  };

  const updateCustomValue = (key, value) => {
    switch (fields[key]) {
      case 'name':
        setCustomSocialDenomination(value);
        break;

      case 'cif':
        setCustomCIF(value);
        break;

      case 'line1':
        setCustomLine1(value);
        break;

      case 'zip':
        setCustomZip(value);
        break;

      case 'city':
        setCustomCity(value);
        break;

      case 'country':
        setCustomCountry(value);
        break;

      default:
        break;
    }
  };

  const isButtonDisabled =
    !modalPrivacy ||
    (pendingParams.includes('Número de identificación fiscal') &&
      !getCustomValue('Número de identificación fiscal')) ||
    (pendingParams.includes('Nombre de la sociedad') &&
      !getCustomValue('Nombre de la sociedad'));

  const emulateAPIResponse = async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return mockedResponse;
  };

  const handlePDF = async (file) => {
    try {
      const paramsList = params.join(', ');
      const extracted = hasBlockAI()
        ? await emulateAPIResponse()
        : await extractDataFromPDF(file, paramsList, queryTypes.SOCIETY);

      setExtractedRawData(extracted);
      setExtractedData(mapResultToParams(extracted));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_EXTRACTING_DOC_INFO_WITH_AI));
      dispatch(setModal(null));
      if (onCloseModal) onCloseModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocx = async (file) => {
    try {
      const arrayBuffer = await fileToArrayBuffer(file);
      const textElements = await getDocxContent(arrayBuffer);

      let text = '';
      Array.from(textElements).forEach((element) => {
        text += element.textContent;
      });

      const paramsList = params.join(', ');
      const extracted = hasBlockAI()
        ? await emulateAPIResponse()
        : await extractDataFromText(text, paramsList, queryTypes.SOCIETY);

      setExtractedRawData(extracted);
      setExtractedData(mapResultToParams(extracted));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_EXTRACTING_DOC_INFO_WITH_AI));
      dispatch(setModal(null));
      if (onCloseModal) onCloseModal();
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (file) => {
    if (!file) return;

    setIsLoading(true);
    setPendingParams([]);

    try {
      if (file.type === fileTypes.PDF) {
        await handlePDF(file);
      }

      if (file.type === fileTypes.DOCX) {
        await handleDocx(file);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmData = () => {
    dispatch(setModal(null));
    createSociety();
  };

  const handleChangeMissingParam = (key, value) => {
    updateCustomValue(key, value);
  };

  const handleReloadFile = () => {
    alert('Reload file');
  };

  useEffect(() => {
    if (file) {
      handleSubmit(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (extractedData) {
      Object.entries(extractedData).forEach(([key, value]) => {
        if (value === NOT_FOUND_TAG && requiredParams.includes(key)) {
          setPendingParams((prev) => [...prev, key]);
        } else {
          updateCustomValue(key, value !== NOT_FOUND_TAG ? value : '');
        }
      });
    }
  }, [extractedData]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir Sociedad desde una escritura</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross cursor-pointer" />
        </a>
      </Modal.Header>

      <Modal.Body>
        {isLoading && <CustomLoading />}

        <div className="row">
          <div className="col-md-6 px-4 d-flex flex-column justify-content-between">
            {file?.name && (
              <>
                <section>
                  <div className="mb-5">
                    <h6>{t('SelectedFile')}:</h6>
                    <div className="card card-bordered card-preview">
                      <div className="card-inner py-2">
                        <div className="preview-block d-flex justify-content-between align-items-center">
                          <div
                            className="preview-icon w-50px position-relative mr-3 py-1"
                            onClick={handleReloadFile}
                          >
                            <img
                              src={documentsLogo}
                              alt=""
                              width={50}
                              className="document-image"
                            />
                            <div className="reload-icon">
                              &#x21bb;{' '}
                              {/* This is the HTML entity for a circular arrow (reload) icon */}
                            </div>
                          </div>

                          <div className="preview-text w-100">
                            <span className="preview-title pb-0">
                              <span>
                                <b>{file.name}</b>
                              </span>
                              <br />

                              <div className="d-flex justify-content-between w-100">
                                {file?.size && (
                                  <span className="text-muted">
                                    {t('Size')}: &nbsp;
                                    {file.size / 1000} KB
                                  </span>
                                )}
                                {file?.lastModifiedDate && (
                                  <span className="text-muted">
                                    {t('LastModify')}: &nbsp;
                                    {file.lastModifiedDate.toLocaleDateString()}
                                  </span>
                                )}
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!isLoading && extractedData && (
                    <div>
                      <h6>Datos extraídos:</h6>

                      <div className="card card-bordered card-preview mb-2">
                        <table
                          className="table table-tranx"
                          id="dividends-distribution-table"
                        >
                          <thead>
                            <tr className="tb-tnx-head">
                              <th className="text-left">
                                <span>{t('Campo')}</span>
                              </th>

                              <th className="text-left">
                                <span>{t('Valor')}</span>
                              </th>

                              <th className="w-50px" />
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(extractedData).map(
                              ([key, value]) => (
                                <tr className="tb-tnx-item" key={key}>
                                  <td className="tb-tnx-info w-150px">
                                    <div className="tb-tnx-info w-150px">
                                      <span className="title lh-28px">
                                        <b>{labels[key] || key}</b>
                                      </span>
                                    </div>
                                  </td>

                                  <td className="tb-tnx-info">
                                    <div className="tb-tnx-info w-100">
                                      <input
                                        id={fields[key]}
                                        type="text"
                                        placeholder={t(value)}
                                        value={getCustomValue(key)}
                                        className="amount w-100 bg-none"
                                        readOnly={false}
                                        onChange={(e) =>
                                          handleChangeMissingParam(
                                            key,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </td>

                                  <td className="tb-tnx-info">
                                    {value === NOT_FOUND_TAG &&
                                      requiredParams.includes(key) &&
                                      (getCustomValue(key) === '' ? (
                                        <em className="icon ni ni-cross-round-fill fs-24px lh-24px text-danger" />
                                      ) : (
                                        <em className="icon ni ni-check-round-fill fs-24px lh-24px text-success" />
                                      ))}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>

                      <p>
                        Los campos marcados con una&nbsp;(
                        <em className="icon ni ni-cross-round-fill text-danger" />
                        )&nbsp;son obligatorios.
                      </p>
                    </div>
                  )}
                </section>

                <section>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="form-group mt-2">
                        <div className="custom-control custom-control-xs custom-checkbox">
                          <input
                            type="checkbox"
                            id="checkbox-modal-privacy"
                            className="custom-control-input"
                            value={modalPrivacy}
                            checked={modalPrivacy}
                            onChange={(event) =>
                              setModalPrivacy(event.target.checked)
                            }
                            required
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="checkbox-modal-privacy"
                          >
                            {t('IAgree')}{' '}
                            <a
                              href="https://www.sttok.com/privacidad/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t('PrivacyPolicy')}
                            </a>{' '}
                            {t('and')}{' '}
                            <a
                              href="https://www.sttok.com/aviso-legal/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              {t('TermsAndConditions')}
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            )}
          </div>

          <AddSocietyDocPreview file={file} />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleConfirmData}
          disabled={isButtonDisabled}
        >
          Añadir Sociedad
        </button>
      </Modal.Footer>
    </>
  );
};

export default AddSocietyAI;
