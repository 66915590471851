import { Partner } from 'types';

export default function createSelectorOption(
  partner: Partner,
  vote?: string,
  instruction?: string
) {
  return {
    value: partner?._id,
    label: partner?.name,
    vote,
    instruction,
  };
}
