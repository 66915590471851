import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { StockPlan } from 'types';

import { actions } from '../constants/actions';
import { getVestingMonths } from '../utils/getVestingMonths';
import { getCliffMonths } from '../utils/getCliffMonths';

import { useGetPlanOptions } from '../hook/useGetPlanOptions';
import { useGetSelectedPlanOption } from '../hook/useGetSelectedPlanOptions';

type Props = {
  action: (typeof actions)[keyof typeof actions];
  currentPlan: StockPlan;
  planId: string;
  societyId: string;
  hasCustomConditions: boolean;
  isDraft: boolean;
  // eslint-disable-next-line no-unused-vars
  handleChangePlan: (event: any) => void;
  handleChangeHasCustomConditions: () => void;
};

const AddBeneficiaryModalPlanView: FC<Props> = ({
  action,
  currentPlan,
  planId,
  societyId,
  hasCustomConditions,
  isDraft,
  handleChangePlan,
  handleChangeHasCustomConditions,
}) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const plans = useSelector((state: any) => state.plans);

  const { planOptions } = useGetPlanOptions({ plans, societyId, isDraft }).data;

  const selectedPlanOptionResult = useGetSelectedPlanOption({
    plans,
    planId,
    handleChangePlan,
  });
  const { selectedPlanOption } = selectedPlanOptionResult.data;
  const { handleChangeSelectedPlanOption } = selectedPlanOptionResult.actions;

  const vestingMonths = getVestingMonths(currentPlan?.vestingPeriod);
  const cliffMonths = getCliffMonths(currentPlan?.cliff);

  return (
    <>
      <div className="form-group">
        <label className="form-label" htmlFor="beneficiary-plan">
          {t('StockOptionsPlan')}
          <span className="sub-text">
            {t('SelectTheStockOptionsPlanInclude')}
          </span>
        </label>
        <div className="form-control-wrap ">
          <Select
            closeMenuOnSelect
            className="react-select react-select-lg"
            options={planOptions}
            value={selectedPlanOption}
            components={animatedComponents}
            onChange={handleChangeSelectedPlanOption}
            placeholder={t('SelectPlan')}
            isDisabled={action === actions.EDIT}
          />

          <div className="row col-md-12 mt-3">
            <div className="col-md-8 px-0">
              <label className="form-label" htmlFor="beneficiary-plan">
                {t('Conditions')}:
              </label>
              {currentPlan && (
                <span className="ml-1">
                  {t('Vesting')}:{' '}
                  <span className="text-primary">{vestingMonths}</span> |{' '}
                  {t('Cliff')}:{' '}
                  <span className="text-primary">{cliffMonths}</span>
                </span>
              )}
            </div>
            <div className="col-md-4 px-0">
              <div className="custom-control custom-control-xs custom-checkbox mt-1">
                <input
                  type="checkbox"
                  id="checkbox-conditions"
                  className="custom-control-input"
                  checked={hasCustomConditions}
                  disabled={action === actions.WATCH}
                  onChange={handleChangeHasCustomConditions}
                />
                <label
                  className="custom-control-label"
                  htmlFor="checkbox-conditions"
                >
                  {t('EditConditionsForBenef')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBeneficiaryModalPlanView;
