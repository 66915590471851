import Swal from 'sweetalert2';
import { rollbackAction } from 'modules/_shared/redux/modalsActions';
import { store } from 'redux/store';

export default async function removePartner(partner, society, userId, i18n) {
  Swal.fire({
    icon: 'warning',
    title: `<h4 className="nk-block-title page-title">${i18n.t(
      'AreYouSureAboutDeletePartner'
    )}  `,
    html: `<h3 class="text-primary fw-normal">${
      partner.name
    } </h3><br /><div class="caption-text">${i18n.t(
      'ThisProcedureCantUndo'
    )}</div>`,
    confirmButtonText: i18n.t('OkDelete'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      if (partner.operations.length === 1) {
        const operation = society.operations.find(
          (operation) => operation['_id'] === partner.operations[0]
        );
        const rollbackData = {
          operationId: operation['_id'],
          operationType: operation.operationType,
          transactions: operation.transactions,
          date: operation.date,
          user: userId,
        };
        const data = {
          operations: [rollbackData],
          society: society['_id'],
        };

        if (operation.operationType === 'ADD_PARTNERS') {
          data['partnerId'] = partner['_id'];
        }
        await store.dispatch(rollbackAction(data, false));
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${i18n.t(
            'PartnerSuccessfullyRemoved'
          )}<br></h4>`,
          confirmButtonText: i18n.t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
      }
    }
  });
}
