import { format } from 'date-fns';
import { SocietyRepresentative } from 'types';

interface POADateParams {
  initDate: Date;
  notaryName?: string;
  notaryCity?: string;
  protocolNr?: string;
  useAlternativeFormat?: boolean;
}

function getPOATranslationKey(params: POADateParams): string {
  const {
    notaryName,
    protocolNr,
    notaryCity,
    useAlternativeFormat = false,
  } = params;

  if (useAlternativeFormat) {
    const parts: string[] = ['ALTERNATIVE'];

    if (notaryCity) parts.push('CITY');
    if (notaryName) parts.push('NOTARY');
    if (protocolNr) parts.push('PROTOCOL');

    return `POA_DATE_${parts.join('_')}`;
  }

  const parts: string[] = ['STANDARD'];

  if (notaryCity) parts.push('CITY');
  if (notaryName) parts.push('NOTARY');
  if (protocolNr) parts.push('PROTOCOL');

  return `POA_DATE_${parts.join('_')}`;
}

export const createRepresentativePoaDateText = (
  representative: SocietyRepresentative,
  translate: any
): string => {
  const initDate = new Date(representative?.initDate);

  const { notaryName, notaryCity, protocolNr } =
    representative?.notarialRegistration || {
      notaryName: undefined,
      notaryCity: undefined,
      protocolNr: undefined,
    };

  const formattedDate = format(
    new Date(representative?.initDate),
    'dd MMMM yyyy'
  );

  const translationKey = getPOATranslationKey({
    initDate,
    notaryName,
    notaryCity,
    protocolNr,
  });
  const template = translate(translationKey) as string;

  return template
    .replace('{{date}}', formattedDate)
    .replace('{{notaryName}}', notaryName || '')
    .replace('{{city}}', notaryCity || '')
    .replace('{{protocolNr}}', protocolNr || '');
};
