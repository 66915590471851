import transactionTypes from './transactionTypes';

export default {
  [transactionTypes.ADD_PARTNER]: 'Añadir socio',
  [transactionTypes.CONSTITUTION]: 'Constitución',
  [transactionTypes.CAPITAL_INCREASE]: 'Ampliación',
  [transactionTypes.CAPITAL_DECREASE]: 'Reducción',
  [transactionTypes.BUY]: 'Compra',
  [transactionTypes.SELL]: 'Venta',
  [transactionTypes.SPLIT]: 'Split',
  [transactionTypes.RENUMERATION]: 'Renumeración',
  [transactionTypes.RECLASSIFICATION]: 'Reclasificación',
};
