import { folderContentIcons } from 'modules/dashboard/constants/foldersContentType';

import './DashboardFolderContentItem.scss';

type DashboardFolderContentItemProps = {
  index: number;
  title: string;
  type: string;
  url?: string;
};

type DashboardContentItemTypes =
  | 'LINK'
  | 'PDF_LINK'
  | 'VIDEO_LINK'
  | 'PDF_EMBEDDED'
  | 'VIDEO_EMBEDDED';

const DashboardFolderContentItem = (
  folderContent: DashboardFolderContentItemProps
) => {
  const { index, title, type } = folderContent;

  const handleOpenContent = () => {
    console.log('Open content');
  };

  return (
    <div
      key={String(index)}
      className="nk-file-item nk-file cursor-pointer w-100 mx-0 pld-content-item"
    >
      <div className="nk-file-info">
        <div className="nk-file-icon">
          <span className="nk-file-content-type">
            <em
              className={`icon ${
                folderContentIcons[type as DashboardContentItemTypes]
              }`}
            />
          </span>
        </div>
        <div className="nk-file-name">
          <div className="nk-file-name-text">
            <span className="title">{title}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFolderContentItem;
