import { Board } from 'types';

function isParticipantRepresented(participant: any, partnerId: string) {
  const isRepresentedByPartner =
    participant?.isRepresented && participant?.representative === partnerId;
  return isRepresentedByPartner;
}

export default function getRepresentedPartnersIds(
  board: Board,
  partnerId: string
) {
  const representedPartnersIds: string[] = [];

  board?.participants?.forEach((participant) => {
    if (isParticipantRepresented(participant, partnerId)) {
      representedPartnersIds.push(participant?.member);
    }
  });

  return representedPartnersIds;
}
