import { useTranslate } from 'hooks/useTranslate';

const DraftBanner = ({ setShowPartnerBook }) => {
  const { t } = useTranslate();
  return (
    <div className="nk-block mt-5">
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-help">
            <div className="nk-help-img">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                <path
                  d="M68.14,80.86,30.21,72.69a5.93,5.93,0,0,1-4.57-7l12.26-56A6,6,0,0,1,45,5.14l28.18,6.07L85.5,29.51,75.24,76.33A6,6,0,0,1,68.14,80.86Z"
                  fill="#eff1ff"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <polyline
                  points="73 12.18 69.83 26.66 85.37 30.08"
                  fill="#eff1ff"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  d="M66.26,71.15,29.05,82.46a6,6,0,0,1-7.46-4L4.76,23.15a6,6,0,0,1,4-7.47l27.64-8.4L56.16,17.39,70.24,63.68A6,6,0,0,1,66.26,71.15Z"
                  fill="#e3e7fe"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <polyline
                  points="36.7 8.22 41.05 22.53 56.33 17.96"
                  fill="#e3e7fe"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  d="M68,85H29a6,6,0,0,1-6-6V21a6,6,0,0,1,6-6H58L74,30.47V79A6,6,0,0,1,68,85Z"
                  fill="#fff"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <polyline
                  points="58 16 58 31 74 31.07"
                  fill="#fff"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <line
                  x1="45"
                  y1="41"
                  x2="61"
                  y2="41"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <line
                  x1="35"
                  y1="48"
                  x2="61"
                  y2="48"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <line
                  x1="35"
                  y1="55"
                  x2="61"
                  y2="55"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <line
                  x1="35"
                  y1="63"
                  x2="61"
                  y2="63"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <line
                  x1="35"
                  y1="69"
                  x2="51"
                  y2="69"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <text
                  transform="translate(34.54 43.18) scale(0.99 1)"
                  fontSize="9.31"
                  fill="#6576ff"
                  fontFamily="Nunito-Black, Nunito Black"
                >
                  $
                </text>
              </svg>
            </div>
            <div className="nk-help-text">
              <h5>{t('NewPartnerBook')}</h5>
              <p className="text-soft">{t('DraftBannerText')}</p>
            </div>
            <div className="nk-help-action">
              <button
                type="button"
                className="btn btn-lg btn-outline-primary"
                onClick={() => setShowPartnerBook(true)}
              >
                {t('ShowPartnerBook')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraftBanner;
