import { utils } from 'xlsx';

const errorTypes = {
  ERROR_BENEFICIARY_SHEET_NOT_FOUND: 'ERROR_BENEFICIARY_SHEET_NOT_FOUND',
  ERROR_BENEFICIARY_SHEET_COLUMNS: 'ERROR_BENEFICIARY_SHEET_COLUMNS',
  ERROR_PARTNER_SHEET_NOT_FOUND: 'ERROR_PARTNER_SHEET_NOT_FOUND',
  ERROR_PARTNER_SHEET_COLUMNS: 'ERROR_PARTNER_SHEET_COLUMNS',
};

// eslint-disable-next-line import/prefer-default-export
export function getExcelBeneficiaries(excel) {
  try {
    if (!excel.Sheets['Beneficiarios']) {
      throw new Error(errorTypes.ERROR_BENEFICIARY_SHEET_NOT_FOUND);
    }

    const sheet = excel.Sheets['Beneficiarios'];
    const userData = utils.sheet_to_json(sheet);

    return userData;
  } catch (error) {
    return {
      status: 401,
      error: error.message,
    };
  }
}

export function getExcelPartners(excel) {
  try {
    if (!excel.Sheets['Socios']) {
      throw new Error(errorTypes.ERROR_PARTNER_SHEET_NOT_FOUND);
    }

    const sheet = excel.Sheets['Socios'];
    const userData = utils.sheet_to_json(sheet);

    return userData;
  } catch (error) {
    return {
      status: 401,
      error: error.message,
    };
  }
}
