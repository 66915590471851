import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getUserSocietyPercent } from 'utils/filters';
import UsersArray from 'modules/profile/components/UsersArray';
import Badge from 'modules/_shared/components/Badge';

import 'assets/styles/Components.scss';

import MenuDots from '../../../_shared/components/MenuDots';
import menuOptions from './menuOptions';

function SocietyRow({ society, userId, isAdmin }) {
  const [percent] = useState(getUserSocietyPercent(userId, society));

  const [billing, setBilling] = useState();
  const [value, setValue] = useState();

  useEffect(() => {
    const { annualValues } = society;
    const totalBilling = annualValues.reduce(
      (acc, annual) => acc + annual.billing,
      0
    );
    const sortedByYear = annualValues.sort(
      (annualA, annualB) => +annualA - +annualB
    );
    setBilling(totalBilling);
    setValue(sortedByYear[0]?.billing);
  }, [society]);

  return (
    society && (
      <tr className="nk-tb-item" key={society.name}>
        <td className="nk-tb-col">
          <Link to={`/socios/${society['_id']}`} className="project-title">
            <div className="user-avatar bg-light">
              {society.additional.logo ? (
                <img src={society.additional?.logo} alt={society.name} />
              ) : (
                <span>{society.name.slice(0, 2).toUpperCase()}</span>
              )}
            </div>
            <div className="project-info">
              <h6 className="title">{society.name}</h6>
            </div>
          </Link>
        </td>
        <td className="nk-tb-col tb-col-xl">
          <span>{society.sharesCount?.actual}</span>
        </td>
        <td className="nk-tb-col tb-col-xl">
          {isAdmin ? (
            <span>{society.partners?.length}</span>
          ) : (
            <ul className="project-users g-1">
              <UsersArray users={society.partners} />
            </ul>
          )}
        </td>
        <td className="nk-tb-col tb-col-xxl">
          {billing > 0 ? `${billing}€` : '-'}
        </td>
        <td className="nk-tb-col tb-col-md">
          {isAdmin ? (
            <span>{value > 0 ? `${value}€` : '-'}</span>
          ) : (
            <div className="project-list-progress">
              <div className="progress progress-pill progress-md bg-light">
                <div
                  className="progress-bar"
                  data-progress={percent > 0 ? percent : 0}
                  style={{ width: `${percent > 0 ? percent : 0}%` }}
                />
              </div>
              <div className="project-progress-percent">
                {`${percent > 0 ? percent : 0}%`}
              </div>
            </div>
          )}
        </td>
        <td className="nk-tb-col tb-col-mb">
          {society.creationDate ? (
            <Badge
              creationDate={new Date(society.creationDate)}
              isAdmin={!!isAdmin}
            />
          ) : (
            <> </>
          )}
        </td>
        <td className="nk-tb-col nk-tb-col-tools">
          <ul className="nk-tb-actions gx-1">
            <li>
              <div className="dropdown">
                <MenuDots
                  menuOptions={menuOptions}
                  direction="left"
                  id={society['_id']}
                />
              </div>
            </li>
          </ul>
        </td>
      </tr>
    )
  );
}

export default SocietyRow;
