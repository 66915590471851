/* eslint-disable react/react-in-jsx-scope */
import sizes from 'constants/sizes';
import { tenderOfferSharesTypes } from 'utils/tenderOfferSharesTypes';

import { translate } from 'hooks/useTranslate';
import AddTenderOffer from 'modules/_shared/components/Modals/AddTenderOffer';
import AddTenderOfferShares from 'modules/_shared/components/Modals/AddTenderOfferShares';

function menuOptions() {
  return [
    {
      icon: 'ni-edit',
      text: translate('Units'),
      modal: <AddTenderOffer size={sizes.LG} />,
    },
    {
      icon: 'ni-edit',
      text: translate('Shares'),
      modal: (
        <AddTenderOfferShares
          size={sizes.LG}
          type={tenderOfferSharesTypes.BUY}
        />
      ),
    },
  ];
}
export default menuOptions;
