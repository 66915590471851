import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  currentStep: number;
  voteStep: number;
  title: string;
};

const SendVoteModalStep: FC<Props> = ({ currentStep, voteStep, title }) => {
  const { t } = useTranslate();

  const StepItem = () => {
    if (voteStep === currentStep) {
      return (
        <>
          <div className="icon-container">
            <em className="icon ni ni-circle-fill fs-24px text-primary pr-1" />
            <span className="icon-number pr-1">{currentStep + 1}</span>
          </div>
          <h5 className="vote-step-text pb-0">{t(title)}</h5>
        </>
      );
    }
    if (voteStep < currentStep) {
      return (
        <>
          <div className="icon-container">
            <em className="icon ni ni-circle-fill fs-24px text-light pr-1" />
            <span className="icon-number pr-1">{currentStep + 1}</span>
          </div>
          <h5 className="vote-step-text pb-0">{t(title)}</h5>
        </>
      );
    }

    return (
      <>
        <em className="icon ni ni-check-circle-fill fs-24px text-success pr-1" />
        <h5 className="vote-step-text pb-0">{t(title)}</h5>
      </>
    );
  };

  return (
    <li role="tab" className={voteStep === currentStep ? 'done' : ''}>
      <span className="d-flex justify-content-center align-items-center">
        <StepItem />
      </span>
    </li>
  );
};

export default SendVoteModalStep;
