import { CoOwner, Encumbrance, ScriptureData } from 'types';

type Props = {
  encumbrances: Encumbrance[];
  encumbranceName: string;
  encumbranceDate: string;
  encumbranceFrom: number;
  encumbranceTo: number;
  encumbranceScriptureData: ScriptureData;
  encumbranceFile: File | undefined;
  coOwners: CoOwner[];
};

export default function addEncumbrance({
  encumbrances,
  encumbranceName,
  encumbranceDate,
  encumbranceFrom,
  encumbranceTo,
  encumbranceScriptureData,
  encumbranceFile,
  coOwners,
}: Props) {
  const newEncumbrances = [
    ...encumbrances,
    {
      name: encumbranceName,
      from: encumbranceFrom,
      to: encumbranceTo,
      date: encumbranceDate,
      scriptureData: encumbranceScriptureData,
      scriptureDocument: '',
      file: encumbranceFile,
      coOwners,
    },
  ];

  return newEncumbrances;
}
