import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';
import { FC, memo } from 'react';
import { formatCurrencyDecimals, formatNumber } from 'utils/formats';
import { cleanValue } from 'constants/formats';
import getPriceByPlan from '../utils/getPriceByPlan';

type BeneficiarySellInvoiceTableProps = {
  beneficiaryAllPlans: Record<string, any>;
  simulatorIncludeAllUnits: boolean;
  simulatorSharePriceUnit: number;
  simulatorSubtotal: number;
  simulatorRetentionTotal: number;
  simulatorTotal: number;
};

const BeneficiarySellInvoiceTable: FC<BeneficiarySellInvoiceTableProps> = ({
  beneficiaryAllPlans,
  simulatorSharePriceUnit,
  simulatorIncludeAllUnits,
  simulatorSubtotal,
  simulatorRetentionTotal,
  simulatorTotal,
}) => {
  const { t } = useTranslate();

  const invoiceHeaders = [
    { title: 'Nº', width: '5%' },
    { title: t('Plan'), width: '23%' },
    { title: `${t('Cliff')} (${t('meses')})`, width: '9%' },
    { title: `${t('Vesting')} (${t('meses')})`, width: '9%' },
    { title: t('NoOfUnits'), width: '9%' },
    { title: t('Vested'), width: '9%' },
    { title: t('SoldUnits'), width: '9%' },
    { title: t('StrikePriceUnit'), width: '9%' },
    { title: t('SellingPriceUnit'), width: '9%' },
  ];

  return (
    <div className="invoice-bills border rounded ">
      <div className="table-responsive scroll-horizontal">
        <table className="table table-striped mb-0 scroll-horizontal">
          <thead>
            <tr>
              {invoiceHeaders.map((header) => (
                <th className="text-center" style={{ width: header.width }}>
                  {header.title}
                </th>
              ))}

              <th className="text-center" style={{ width: '9%' }}>
                {t('Profit')}
                <span
                  className="fw-normal"
                  style={{
                    textTransform: 'none',
                  }}
                >
                  <em
                    className="icon ni ni-info notification-icon ml-1 text-primary"
                    id="icon-profit-tooltip"
                  />
                  <Tooltip
                    anchorId="icon-profit-tooltip"
                    place="top"
                    style={{ maxWidth: '300px' }}
                  >
                    {t('IncentiveValueDetailsNoStrikePrice')}
                  </Tooltip>
                </span>
              </th>
            </tr>
          </thead>

          <tbody>
            {beneficiaryAllPlans?.map((beneficiaryPlan: any, index: number) => (
              <tr>
                <td className="text-center">{index + 1}</td>
                <td className="text-left w-min-150px">
                  {beneficiaryPlan?.planData?.name}
                </td>
                <td className="text-center">
                  {beneficiaryPlan?.planData?.cliff}
                </td>
                <td className="text-center">
                  {beneficiaryPlan?.planData?.vestingPeriod}
                </td>
                <td className="text-right">
                  {formatNumber(beneficiaryPlan?.sharesCount?.future)}
                </td>
                <td className="text-right">
                  {formatNumber(
                    beneficiaryPlan?.vestedUnits + beneficiaryPlan?.soldUnits
                  )}
                </td>
                <td className="text-right">
                  {formatNumber(beneficiaryPlan?.soldUnits)}
                </td>
                <td className="text-right">
                  {formatCurrencyDecimals(
                    beneficiaryPlan?.customConditions?.sharePrice ||
                      beneficiaryPlan?.planData?.sharePrice
                  )}
                </td>
                <td className="text-right">
                  {formatCurrencyDecimals(simulatorSharePriceUnit)}
                </td>

                <td className="text-right">
                  {formatCurrencyDecimals(
                    getPriceByPlan(
                      beneficiaryPlan,
                      cleanValue(simulatorSharePriceUnit),
                      simulatorIncludeAllUnits
                    )
                  )}
                  <sup>&nbsp;</sup>
                </td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan={6} />
              <td className="text-right" colSpan={2}>
                {t('Subtotal')}
              </td>
              <td />
              <td className="text-right">
                {formatCurrencyDecimals(simulatorSubtotal)}
              </td>
            </tr>

            <tr>
              <td colSpan={6} />
              <td className="text-right" colSpan={2}>
                {t('Retention')}
              </td>
              <td />
              <td className="text-right">
                -{formatCurrencyDecimals(simulatorRetentionTotal)}
              </td>
            </tr>

            <tr>
              <td colSpan={6} />
              <td className="text-right fw-bold" colSpan={2}>
                {t('Total')}
              </td>
              <td />
              <td className="text-right fw-bold">
                {formatCurrencyDecimals(simulatorTotal)}
              </td>
            </tr>
          </tfoot>
        </table>

        <span className="text-right text-soft fs-11px d-block mr-4 mb-3">
          {t('AmountWithholdingTax')}
        </span>
      </div>
    </div>
  );
};

export default memo(BeneficiarySellInvoiceTable);
