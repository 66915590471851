import React, { FC } from 'react';

import { EnvelopeSigner } from 'types';

import SignatureViewTableHeader from './SignatureViewTableHeader';
import SignatureViewTableBody from './SignatureViewTableBody';

type Props = {
  signers: EnvelopeSigner[];
};

const SignatureViewTable: FC<Props> = ({ signers }) => (
  <div className="card card-bordered">
    <table className="table my-2 nk-tb-list nk-tb-ulist" id="act-signers-table">
      <SignatureViewTableHeader />
      <SignatureViewTableBody signers={signers} />
    </table>
  </div>
);

export default SignatureViewTable;
