export default {
  PARTNER: 'PARTNER',
  BENEFICIARY: 'BENEFICIARY',
  INVESTOR: 'INVESTOR',
  OPERATION: 'OPERATION',
  DIRECTOR: 'DIRECTOR',
  REPRESENTATIVE: 'REPRESENTATIVE',
  AUDITOR: 'AUDITOR',
  BOARD: 'BOARD',
};
