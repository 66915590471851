export default function getClosestCancelationPeriod(
  consolidationPeriods: any[],
  cancelationDate: Date
) {
  return consolidationPeriods?.reduce((acc, period) => {
    if (
      new Date(period?.date).getTime() <= new Date(cancelationDate).getTime()
    ) {
      if (!acc || new Date(acc?.date) < new Date(period?.date)) {
        return period;
      }
    }
    return acc;
  }, null);
}
