import AddSocietyAI from 'modules/_shared/components/OpenAI/AddSociety';
import sizes from 'constants/sizes';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from 'modules/_shared/redux/modalActions';

const OpenAiIntegration = () => {
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    dispatch(setModal(<AddSocietyAI size={sizes.XL} />));
  };

  return (
    <div className="nk-content-body">
      <h1>OpenAI Integration</h1>
      <p>Custom Methods on Beta Version</p>

      <hr />

      <button
        type="button"
        className="btn btn-primary"
        onClick={handleOpenModal}
      >
        Add Society
      </button>
    </div>
  );
};

export default OpenAiIntegration;
