import { useEffect, useState } from 'react';

import { Envelope } from 'types';

import { getEnvelopeFromType } from '../utils/getEnvelopeFromType';

export default function useGetEnvelopeFromType(
  boardEnvelopes: Envelope[],
  type: string
) {
  const [envelope, setEnvelope] = useState<Envelope>();

  useEffect(() => {
    const envelopeFromType = getEnvelopeFromType(boardEnvelopes, type);
    setEnvelope(envelopeFromType);
  }, [boardEnvelopes, type]);

  return { envelope };
}
