import { useEffect, useState } from 'react';
import { Document, Partner } from 'types';
import { getDocumentById } from './useGetLegalDocuments';

export const useGetSindicationDocument = (partner: Partner) => {
  const [sindicationDocument, setSindicationDocument] =
    useState<Document | null>(null);

  useEffect(() => {
    const getSindicationDocument = async () => {
      const sindicationDocumentId = partner?.sindicationDocument;

      if (sindicationDocumentId) {
        const sindication = await getDocumentById(sindicationDocumentId);
        setSindicationDocument(sindication);
      }
    };

    getSindicationDocument();
  }, []); // eslint-disable-line

  return {
    data: sindicationDocument as Document,
    isLoading: false,
    isError: false,
  };
};
