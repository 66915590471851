import { FC } from 'react';

import { Partner } from 'types';

import PartnersPreviewTableRow from './PartnersPreviewTableRow';

type Props = {
  partners: (Partner & { isExisting?: boolean })[];
};

const PartnersPreviewTableBody: FC<Props> = ({ partners }) => (
  <>
    <tbody>
      {partners?.map((partner, index) => (
        <PartnersPreviewTableRow partner={partner} index={index} />
      ))}
    </tbody>
  </>
);

export default PartnersPreviewTableBody;
