/* eslint-disable react/react-in-jsx-scope */
import i18n from 'i18n/config';
import DocumentViewerModal from 'modules/_shared/components/Modals/DocumentViewer';
import documentDownload from './documentDownload';
import documentDelete from './documentDelete';
import documentShare from './documentShare';

export default [
  {
    icon: 'ni-eye',
    text: i18n.t('Open'),
    action: DocumentViewerModal,
  },
  {
    icon: 'ni-download-cloud',
    text: i18n.t('Download'),
    action: documentDownload,
  },
  {
    icon: 'ni-share',
    text: i18n.t('ToShare'),
    action: documentShare,
  },
  {
    icon: 'ni-trash',
    text: i18n.t('Erase'),
    action: documentDelete,
  },
];
