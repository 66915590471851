import { FC, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Board, Partner, PartnerRepresentant, Society } from 'types';

import { isBoardClosed } from 'utils/boards';

import { setModal } from 'modules/_shared/redux/modalActions';
import DelegateVoteModal from 'modules/_shared/components/Modals/DelegateVote';

import BoardPartnerUsersCard from './BoardPartnerUsersCard';
import BoardPartnerRepresentantsCard from './BoardPartnerRepresentantsCard';

type BoardPartnerActionsRowProps = {
  currentRepresentant: PartnerRepresentant;
  currentPartner: Partner;
  currentBoard: Board;
  actualSociety: Society;
  isParticipant: boolean;
  hasDelegated: boolean;
  isRepresentative: boolean;
  isPartnerRepresentant: boolean;
  haveVote: boolean;
  partnerId: string;
};

const BoardPartnerActionsRow: FC<BoardPartnerActionsRowProps> = ({
  currentRepresentant,
  currentPartner,
  currentBoard,
  actualSociety,
  isParticipant,
  hasDelegated,
  isRepresentative,
  isPartnerRepresentant,
  haveVote,
  partnerId,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleOpenDelegateVoteModal = () => {
    dispatch(
      setModal(<DelegateVoteModal board={currentBoard} partnerId={partnerId} />)
    );
  };

  const isDelegationButtonVisible =
    isParticipant &&
    !hasDelegated &&
    !isRepresentative &&
    !haveVote &&
    !isPartnerRepresentant;

  return (
    <div className="row mb-4">
      <div className="col-md-6">
        {isPartnerRepresentant ? (
          <BoardPartnerRepresentantsCard
            currentRepresentant={currentRepresentant}
            currentBoard={currentBoard}
            currentSociety={actualSociety}
          />
        ) : (
          <BoardPartnerUsersCard
            currentPartner={currentPartner}
            currentBoard={currentBoard}
            currentSociety={actualSociety}
          />
        )}
      </div>

      {isDelegationButtonVisible && (
        <div className="col-md-6 mt-5 mb-3 mt-md-0 mb-md-0 d-flex justify-content-center justify-content-md-end align-items-center">
          <div className="nk-block-head-content">
            <ul className="nk-block-toolsflex-wrap">
              <button
                type="button"
                className="btn btn-primary"
                disabled={isBoardClosed(currentBoard)}
                onClick={handleOpenDelegateVoteModal}
              >
                <em className="icon ni ni-user-add" />
                <span>{t('DelegateVote')}</span>
              </button>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(BoardPartnerActionsRow);
