import { Landing, Section } from '../types/CustomDashboard';

const updateSectionFolders = (section: Section): Section => {
  const { folders = [], ...sectionHeader } = { ...section };

  folders?.sort((a, b) => (a?.index || 0) - (b?.index || 0));

  const updatedFolders = folders?.map((folder, index) => ({
    ...folder,
    index: index + 1,
  }));

  return {
    ...sectionHeader,
    folders: updatedFolders,
  };
};

export const updateDashboardSections = (dashboard: Landing): Landing => {
  const { sections = [], ...dashboardHeader } = { ...dashboard };

  sections.sort((a, b) => (a?.index || 0) - (b?.index || 0));

  const updatedSections = sections.map((section: Section, index: number) => ({
    ...updateSectionFolders(section),
    index: index + 1,
  }));

  return {
    ...dashboardHeader,
    sections: updatedSections,
  };
};
