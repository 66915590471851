/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';
import TableLoader from 'modules/_shared/components/Tables/TableLoader';

import { setMenuType } from 'modules/_shared/redux/menuActions';
import { resetStateSociety } from 'modules/_shared/redux/resetActions';
import { getUserSocieties } from 'modules/society/redux/societyActions';

import menuTypes from 'constants/menuTypes';

import { useTranslate } from 'hooks/useTranslate';
import tableColumns from './tableColumns';

import './BeneficiatedList.scss';

function BeneficiatedList({ items = 10 }) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [societies, setSocieties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalPlansCount, setTotalPlansCount] = useState(0);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const [beneficiatedData, setBeneficiatedData] = useState([]);

  useEffect(() => {
    dispatch(setMenuType(menuTypes.MAIN));
  }, [dispatch]);

  useEffect(() => {
    if (user && user.societies.beneficiated.length) {
      getUserSocieties(
        user['_id'],
        'beneficiary',
        setBeneficiatedData,
        'table'
      ).then(() => setIsLoadingData(false));
    } else {
      setIsLoadingData(false);
    }
  }, [user]);

  useEffect(() => {
    if (i18n.language) {
      setCurrentTableColumns(tableColumns(i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const memoizedData = useMemo(() => {
    if (user && (societies?.length || beneficiatedData?.length)) {
      let plansCount = 0;
      const dataForTable = beneficiatedData.map((currentData) => {
        plansCount += currentData?.plansCount || 0;
        const noRedirect = currentData?.plansCount === 0;
        return {
          society: currentData?.society,
          societyName: currentData?.society?.name,
          plansTotal: currentData?.plansCount,
          name: currentData?.beneficiaryName,
          cif: currentData?.beneficiaryCif,
          link: `/detalle-beneficiario/${currentData?.society?._id}/${currentData?.beneficiaryPlanId}/${currentData?.beneficiaryId}`,
          noRedirect,
        };
      });
      setTotalPlansCount(plansCount);
      return dataForTable;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, societies, beneficiatedData]);

  useEffect(() => {
    if (memoizedData.length) {
      setTableData(
        transformData({
          data: memoizedData,
          columns: tableColumns(i18n),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedData, i18n.language]);

  useEffect(() => {
    dispatch(resetStateSociety());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('IncentivePlans')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {tableData?.length === 0
                    ? t('NoPlans')
                    : t('YouHavePlans', { count: totalPlansCount })}
                </p>
              </div>
            </div>
          </div>
        </div>

        {tableData?.length > 0 ? (
          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('PlanName')}
            pagination
            className="nk-tb-list"
            rowsPerPage={items}
            actions
            showDownload={false}
          />
        ) : (
          isLoadingData && <TableLoader rows={items} />
        )}
      </div>
    </div>
  );
}

export default BeneficiatedList;
