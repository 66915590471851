import { Society } from 'types';
import { STTOK_LOGO } from 'constants/defaultConstants';

const getSocietyLogoSrc = (society: Society) => {
  const hasWhiteBranding = society?.configuration?.hasWhiteBranding || false;

  const defaultLogo = society?.additional?.logo || STTOK_LOGO;
  const brandingLogo =
    society?.customization?.theme?.emailFrameLogo ||
    society?.customization?.theme?.logo;

  const logoSrc = hasWhiteBranding ? brandingLogo || defaultLogo : defaultLogo;

  return logoSrc;
};

export default getSocietyLogoSrc;
