import types from 'redux/actions/actionTypes';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export function setDocuments(documents) {
  return { type: types.SET_DOCUMENTS, documents };
}

export function storeUpdateDocument(document) {
  return { type: types.UPDATE_DOCUMENT, document };
}

export function addDocument(newDocumentData) {
  return async (dispatch) => {
    try {
      const { file, ...documentData } = newDocumentData;
      const formData = new FormData();

      if (file) {
        formData.append('file', file);
      }

      formData.append('data', JSON.stringify(documentData));
      formData.append('action', 'ADD');

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/documents`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );
      const decodedData = await decryptResponse(data);
      return decodedData;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_CREATING_DOCUMENT']));
    }
  };
}

export function updateDocument(newDocumentData, showAlert = true) {
  return async (dispatch) => {
    try {
      const formData = new FormData();

      formData.append('data', JSON.stringify(newDocumentData));

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/documents`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
      );
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes['DOCUMENT_UPDATED']));
      }

      const decodedData = await decryptResponse(data);
      dispatch(storeUpdateDocument(decodedData));
      return decodedData;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_UPDATING_DOCUMENT']));
    }
  };
}

export const replaceDocument =
  ({ documentId, file, documentData }) =>
  async () => {
    const formData = new FormData();

    formData.append(
      'data',
      JSON.stringify({ _id: documentId, ...documentData })
    );
    formData.append('file', file);

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/documents`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
    const decodedData = await decryptResponse(data);
    return decodedData;
  };

export const getDocument = (id, format) => async (dispatch) => {
  try {
    let baseUrl = `${process.env.REACT_APP_API_URL}/documents/${id}`;
    if (format) {
      baseUrl = `${baseUrl}?format=${format}`;
    }
    const { data } = await axios.get(baseUrl);
    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const getDocuments = (reference, setter) => async (dispatch) => {
  const query = { params: { ...reference } };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/documents/`,
      query
    );
    const decodedData = await decryptResponse(data);
    if (setter) setter(decodedData);
    dispatch(setDocuments(decodedData));
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const deleteDocument = (id) => async (dispatch) => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/documents/${id}`);
    return {
      type: types.DELETE_DOCUMENT,
      id,
    };
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const deleteDocuments = (ids) => async (dispatch) => {
  try {
    const firstId = ids[0];
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/documents/${firstId}`,
      {
        data: { ids },
      }
    );
    return {
      type: types.DELETE_DOCUMENTS,
      ids,
    };
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

// TODO transform this function from Contact to Email
export const shareDocument = (newEmailData) => {
  const { file, ...emailData } = newEmailData;
  const formData = new FormData();

  return async (dispatch) => {
    try {
      if (file) formData.append('file', file);
      formData.append('data', JSON.stringify(emailData));
      formData.append('action', 'SHARE');

      await axios.post(`${process.env.REACT_APP_API_URL}/documents`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      dispatch(addAlert(alertBodyTypes['DOCUMENT_SHARED']));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_CONTACT_EMAIL']));
    }
  };
};
