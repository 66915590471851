/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import Swal from 'sweetalert2';
import { store } from 'redux/store';
import wNumb from 'wnumb';

import { updateSociety } from 'modules/society/redux/societyActions';

export default function deleteAnnualValue(params) {
  const { societyId, annualValueId, annualValue, year } = params;

  const currencyFormat = wNumb({
    decimals: 0,
    thousand: '.',
    suffix: ' €',
  });

  const value = currencyFormat.to(annualValue);

  Swal.fire({
    icon: 'warning',
    title:
      '<h4 class="nk-modal-title">¿Está seguro de que desea eliminar este Valor de la sociedad?</h4>',
    html: `<h5 class="text-primary">${year} | ${value}</h5><br /><div class="caption-text">Este procedimiento no se puede deshacer.</div>`,
    confirmButtonText: 'Ok, eliminar',
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch(
        updateSociety(societyId, { annualValue: { remove: annualValueId } })
      );
    }
  });
}
