import statusTypes from "./statusTypes";

export default [
  {
    name: statusTypes.name.CURRENT,
    value: statusTypes.var.CURRENT,
  },
  {
    name: statusTypes.name.REVOKED,
    value: statusTypes.var.REVOKED,
  },
  
]