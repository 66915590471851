import i18n from 'i18n/config';

export default [
  {
    value: 'es',
    label: i18n.t('Spanish'),
  },
  {
    value: 'en',
    label: i18n.t('English'),
  },
];
