import { useTranslate } from 'hooks/useTranslate';
import React, { FC, useEffect, useState } from 'react'; // eslint-disable-line
import { useDispatch } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import { dayMonthYear } from 'utils/formatDate';
import { formatCurrencyDecimals } from 'utils/formats';

import MenuDots from 'modules/_shared/components/MenuDots';

import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import AnnualValueModal from './annualValueModal';
import menuOptions from './menuOptions';

type AnnualValue = {
  _id: string;
  year: string;
  contableValue: number;
  billing: number;
  ebitda: [number];
  modifiedDate: Date;
};

type ValueSharesViewProps = {
  societyId: string;
  annualValues: [AnnualValue];
};

const ValueSharesView: FC<ValueSharesViewProps> = ({
  societyId,
  annualValues,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isAdmin, isReadOnly } = useGetSocietySectionPermissions().data;

  const [rows, setRows] = useState<React.ReactNode[]>([]);
  const [actualAnnualValues, setActualAnnualValues] = useState<AnnualValue[]>();

  const getSortedValues = (values: [AnnualValue]) =>
    values.sort((value1, value2) => Number(value1.year) - Number(value2.year));

  const handleOpenModal = () => {
    if (isReadOnly) return;

    dispatch(
      setModal(
        <AnnualValueModal
          societyId={societyId}
          actualAnnualValues={actualAnnualValues}
        />
      )
    );
  };

  useEffect(() => {
    const sortedAnnualValues = getSortedValues(annualValues);
    setActualAnnualValues(sortedAnnualValues);
  }, [annualValues]);

  useEffect(() => {
    const tempRows = annualValues?.map((annualValue) => (
      <tr className="tb-ticket-item is-unread" key={Number(annualValue['_id'])}>
        <td className="tb-ticket-id text-center w-15">{annualValue?.year}</td>
        <td className="tb-ticket-desc text-right w-20">
          <span className="title pr-0">
            {formatCurrencyDecimals(annualValue?.contableValue)}
          </span>
        </td>
        <td className="tb-ticket-desc text-right w-20">
          <span className="title pr-0">
            {typeof annualValue?.ebitda === 'number'
              ? formatCurrencyDecimals(annualValue?.ebitda)
              : formatCurrencyDecimals(annualValue?.ebitda?.[0])}
          </span>
        </td>
        <td className="tb-ticket-date tb-col-md text-right w-30">
          <span className="date">
            {dayMonthYear(annualValue?.modifiedDate)}
          </span>
        </td>
        {isAdmin && !isReadOnly && (
          <td className="nk-tb-col nk-tb-col-tools w-15">
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="dropdown">
                  {/* @ts-ignore */}
                  <MenuDots
                    menuOptions={menuOptions}
                    // @ts-ignore
                    id={annualValue?.['_id']}
                    params={{
                      societyId,
                      annualValue: annualValue?.contableValue,
                      annualValueId: annualValue?.['_id'],
                      year: annualValue?.year,
                    }}
                    direction="left"
                  />
                </div>
              </li>
            </ul>
          </td>
        )}
      </tr>
    ));

    setRows(tempRows);
  }, [annualValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="nk-fmg-body-content">
      <div className="nk-block-head">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h5 className="nk-block-title title">{t('ValueShares')}</h5>
            <div className="nk-block-des text-soft">
              <p>{t('DetailOfTheAnnualValueOfTheCompany')}</p>
            </div>
          </div>

          <div className="nk-block-head-content">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleOpenModal}
              disabled={isReadOnly}
            >
              {t('AddAnnualValue')}
            </button>
          </div>
        </div>
      </div>
      {rows?.length > 0 ? (
        <div className="nk-block">
          <div className="card card-bordered">
            <table className="table table-tickets">
              <thead className="tb-ticket-head">
                <tr className="tb-ticket-title">
                  <th className="tb-ticket-id text-center w-15">
                    <span>{t('Year')}</span>
                  </th>
                  <th className="tb-ticket-desc text-right w-20">
                    <span>{t('AccountingValue')}</span>
                  </th>
                  <th className="tb-ticket-desc text-right w-20">
                    <span>{t('Ebitda')}</span>
                  </th>
                  <th className="tb-ticket-date tb-col-md text-right w-30">
                    <span>{t('Modified')}</span>
                  </th>
                  {isAdmin && !isReadOnly ? <th className="w-15" /> : <></>}
                </tr>
              </thead>
              <tbody className="tb-ticket-body">{rows}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ValueSharesView;
