/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';

import EditBeneficiaryModal from 'modules/beneficiaries/modals/EditBeneficiary';
import { useGetPlanSectionPermissions } from 'modules/beneficiaries/hooks/useGetPlanSectionPermissions';

function BeneficiaryPersonal({
  society,
  beneficiary,
  incorporationDate,
  setBeneficiaryData,
}) {
  const { t } = useTranslate();

  const { isAdmin, isReadOnly } = useGetPlanSectionPermissions().data;

  const [address, setAddress] = useState();
  const [beneficiarySocietyData, setBeneficiarySocietyData] = useState(null);

  const handleOpenEdit = () => {
    if (isAdmin && !isReadOnly) {
      store.dispatch(
        setModal(
          <EditBeneficiaryModal
            beneficiary={beneficiary}
            societyId={society.societyId}
            setBeneficiarySocietyData={setBeneficiarySocietyData}
          />
        )
      );
    }
  };

  useEffect(() => {
    const { line1, zip, city, country } = beneficiary.address || {};

    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : ''
      }`
    );
  }, [beneficiary.address]);

  useEffect(() => {
    if (beneficiarySocietyData) {
      setBeneficiaryData(beneficiarySocietyData?.beneficiary);
    }
  }, [beneficiarySocietyData]);

  return (
    <div className="card-inner">
      <div className="nk-block-head" onClick={handleOpenEdit}>
        {isAdmin && !isReadOnly && (
          <div className="nk-block-head-content">
            <div className="nk-block float-right">
              <button
                type="button"
                className="btn btn-icon btn-trigger btn-tooltip"
                onClick={handleOpenEdit}
              >
                <em className="icon ni ni-edit mr-1" />
              </button>
            </div>
          </div>
        )}
        <h5 className="title">{t('PersonalInformation')}</h5>
        <p>{t('BasicPersonalInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('FullName')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary.name || ''}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary.cif || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Telephone')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary?.phone || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('JobTitle')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary?.jobTitle || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value  text-left">{address}</span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {beneficiary?.emails?.length === 0 ? t('Email') : t('Emails')}
          </span>
          <span className="profile-ud-value  text-left">
            <div className="d-block">
              {beneficiary.email}
              <span className="badge badge-outline badge-pill text-primary ucap mx-1 float-right">
                {t('primary')}
              </span>
            </div>
            {beneficiary?.emails?.map((email) => (
              <div className="d-block mt-1">
                <span className="profile-ud-value text-left">{email.text}</span>
                <span className="badge badge-outline badge-pill text-primary ucap mx-1 float-right">
                  {t('Primary')}
                </span>
              </div>
            ))}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {t('IncoporationDate')}
          </span>
          <span className="profile-ud-value  text-left">
            {incorporationDate}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary?.nationality || '-'}
          </span>
        </div>
      </div>
    </div>
  );
}

export default BeneficiaryPersonal;
