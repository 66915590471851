import voteValues from 'constants/voteValues';

export default function getVoteInfoLabelText(voteType: string) {
  const voteTypes = {
    [voteValues.YES.value]: 'VoteInFavor',
    [voteValues.NO.value]: 'VoteAgainst',
    [voteValues.ABS.value]: 'Abstain',
  };

  return voteTypes?.[voteType] || '';
}
