import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';

import { dateFormat } from 'constants/formats';

import {
  getRepresentativeStatus,
  getRepresentativeType,
} from 'modules/society/components/RepresentativeSociety/utils/getRepresentativeValues';

type Props = {
  representative: Record<string, any> | undefined;
};

const RepresentativeSocietyDetailsList = ({ representative }: Props) => {
  const { t } = useTranslate();

  const dataList = [
    {
      label: t('RepresentativeFullName'),
      value: representative?.socialDenomination || '-',
    },
    {
      label: t('Email'),
      value: representative?.email || '-',
    },
    {
      label: t('NifNiPassport'),
      value: representative?.nif || '-',
    },
    {
      label: t('RepresentativeDate'),
      value: representative?.initDate
        ? format(new Date(representative?.initDate), dateFormat)
        : '-',
    },
    {
      label: t('RevocationDate'),
      value: representative?.endDate
        ? format(new Date(representative?.endDate), dateFormat)
        : '-',
    },
    {
      label: t('TypeOfAuthority'),
      value: getRepresentativeType(representative?.representativeType),
    },
    {
      label: t('Status'),
      value: getRepresentativeStatus(representative?.representativeStatus),
    },
    {
      label: t('Nationality'),
      value: representative?.nationality || '-',
    },
    {
      label: t('Address'),
      value: representative?.address || '-',
    },
    {
      label: t('Tag'),
      value: representative?.tag || '-',
    },
    {
      label: t('Comments'),
      value: representative?.comments || '-',
    },
  ];

  return (
    <div className="card-inner">
      <h6 className="overline-title-alt mb-4">
        {t('RepresentativeDetails')} {representative?.fullName}
      </h6>
      <ul className="list">
        {dataList.map(({ label, value }) => (
          <li key={label}>
            <b>{label}: </b>
            {value}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RepresentativeSocietyDetailsList;
