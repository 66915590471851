/* eslint-disable no-underscore-dangle */
import { store } from 'redux/store';
import { useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import { updateShare } from 'modules/society/redux/sharesActions';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';

import UpdateSharesHeader from './components/UpdateSharesHeader';
import UpdateSharesFooter from './components/UpdateSharesFooter';
import UpdateSharesBody from './components/UpdateSharesBody';

import './UpdateShares.scss';

const UpdateShares = ({ share, society }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [page, setPage] = useState(0);

  const [tag, setTag] = useState(share?.tag || '');

  const [encumbrances, setEncumbrances] = useState(share?.encumbrances || []);

  const [encumbrancesUpdated, setEncumbrancesUpdated] = useState(false);
  const [tagUpdated, setTagUpdated] = useState(false);

  const addEncumbrancesDocumnets = async (encumbrances) => {
    const result = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const encumbrance of encumbrances) {
      const currentEncumbrance = {
        name: encumbrance.name,
        date: encumbrance.date,
        from: encumbrance.from,
        to: encumbrance.to,
        scriptureData: encumbrance.scriptureData,
        scriptureDocument: encumbrance.scriptureDocument,
        coOwners: encumbrance.coOwners,
      };
      if (encumbrance?.file) {
        let uploadedDocument = null;
        const encumbranceFile = encumbrance.file;
        const newDocument = {
          file: encumbranceFile,
          date: new Date(),
          size: encumbranceFile.size,
          name: encumbranceFile.name,
          author: user['_id'],
          society: society['_id'],
          fileType: fileTypes[encumbranceFile.type],
          category: documentTypes.SCRIPTURES,
          description: t('DocumentLinkedToEncumbrance'),
        };
        // eslint-disable-next-line no-await-in-loop
        uploadedDocument = await store.dispatch(addDocument(newDocument));

        if (uploadedDocument) {
          result.push({
            ...currentEncumbrance,
            scriptureDocument: uploadedDocument['_id'],
          });
        }
      } else {
        result.push({
          ...currentEncumbrance,
        });
      }
    }
    return result;
  };

  const handleButtonClick = async () => {
    try {
      const newShare = {
        ...share,
      };
      if (tagUpdated) {
        newShare.tag = tag;
      }
      if (encumbrancesUpdated) {
        const newEncumbrances = await addEncumbrancesDocumnets(encumbrances);
        newShare.encumbrances = newEncumbrances;
      }
      await store.dispatch(updateShare(newShare, society));

      setEncumbrancesUpdated(false);
      setTagUpdated(false);
      dispatch(setModal(null));
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <UpdateSharesHeader handleCloseModal={handleCloseModal} />

      <UpdateSharesBody
        page={page}
        share={share}
        encumbrances={encumbrances}
        tag={tag}
        setEncumbrances={setEncumbrances}
        setEncumbrancesUpdated={setEncumbrancesUpdated}
        setTag={setTag}
        setTagUpdated={setTagUpdated}
        handleChangePage={handleChangePage}
      />

      <UpdateSharesFooter
        encumbrancesUpdated={encumbrancesUpdated}
        tagUpdated={tagUpdated}
        page={page}
        handleUpdateShare={handleButtonClick}
      />
    </>
  );
};

export default UpdateShares;
