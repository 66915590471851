import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  orderSubject: string;
  handleCloseModal: () => void;
};

const SendVoteModalHeader: FC<Props> = ({ orderSubject, handleCloseModal }) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Header>
        <div>
          <div className="row">
            <h5 className="title col-12">
              {t('VoteSubject')}:&nbsp;
              <span className="text-muted ff-alt fs-20px text-right">
                {orderSubject}
              </span>
            </h5>
          </div>
        </div>
        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
    </>
  );
};

export default SendVoteModalHeader;
