import { deleteBoard, duplicateBoard } from 'modules/boards/utils';

const menuOptions = (i18n) => [
  {
    index: 0,
    icon: 'ni-copy',
    text: i18n.t('Duplicate'),
    action: ({ board, user, actualSociety, mixpanel, history }) =>
      duplicateBoard({ board, user, actualSociety, mixpanel, history }),
    dontShowCurrent: ({ isReadOnly }) => isReadOnly,
  },
  {
    index: 1,
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: ({ board, user, actualSociety, mixpanel }) =>
      deleteBoard({ board, user, actualSociety, mixpanel }),
    dontShowCurrent: ({ isReadOnly }) => isReadOnly,
  },
];

export default menuOptions;
