import React from 'react';
import ReactToPrint from 'react-to-print';
import i18n from 'i18n/config';

const PrintButton = ({
  label = '',
  fileName,
  printRef,
  icon = '',
  className = '',
  hideLabel = false,
}) => {
  const buttonIcon = icon || 'ni-printer';
  const buttonLabel = label ? i18n.t(label) : i18n.t('Print');
  const buttonFileName = fileName || 'Document';
  const buttonClassName = className || 'btn-white btn-outline-light';

  return (
    <ReactToPrint
      trigger={() => (
        <span className={`btn screen-only ${buttonClassName}`}>
          <em className={`icon ni ${buttonIcon}`} />
          {!hideLabel && <span>{buttonLabel}</span>}
        </span>
      )}
      content={() => printRef.current}
      documentTitle={buttonFileName}
    />
  );
};

export default PrintButton;
