import initialState from 'redux/initialState';

import { actionTypes } from './menuActions';
import { actionTypes as customTypes } from './resetActions';

export default function reducer(menu = initialState.menu, action) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.menu;

    case actionTypes.SET_MENU_TYPE:
      return { ...menu, type: action.menuType };

    case actionTypes.SET_MENU:
      return action.menu;

    default:
      return menu;
  }
}
