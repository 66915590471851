/* eslint-disable no-underscore-dangle */
import { store } from 'redux/store';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import i18n from 'i18n/config';

import CKEditor from 'modules/_shared/components/CKEditor';
import DocumentsGenerator from 'modules/documents/components/Creators/DocumentsGenerator';
import { updatePreview } from 'modules/_shared/redux/previewActions';
import { updateDocument } from 'modules/documents/redux/documentActions';

import errorSwal from 'utils/errorSwal';

import menuOptions from './menuOptions';

import './Preview.scss';
import PreviewHeader from './PreviewHeader';

const Preview = ({ fullScreen, handleToggleFullScreen }) => {
  const { t } = useTranslate();

  const user = useSelector((state) => state.user);
  const preview = useSelector((state) => state.preview);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );
  const [editorInstance, setEditorInstance] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handleEditDocument = () => {
    if (editorInstance) {
      editorInstance.disableReadOnlyMode('readOnly');
      setIsEditing(true);
    }
  };

  const handleExportPDF = () => {
    if (editorInstance) {
      const config = editorInstance.config.get('exportPdf');
      editorInstance.execute('exportPdf', config);
    }
  };

  const handleExportWord = () => {
    if (editorInstance) {
      const config = editorInstance.config.get('exportWord');
      editorInstance.execute('exportWord', config);
    }
  };

  const handleSaveDocument = async () => {
    try {
      const htmlContent = editorInstance?.getData() || '';

      const document = (
        <DocumentsGenerator
          invitationModel={htmlContent}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );
      await store.dispatch(
        updatePreview({ document, documentModel: htmlContent })
      );
      await store.dispatch(
        updateDocument({ ...preview.bdDocument, editorModel: htmlContent })
      );
    } catch (error) {
      errorSwal(t('ErrorSavingDocument'));
    } finally {
      editorInstance.enableReadOnlyMode('readOnly');
      setIsEditing(false);
    }
  };

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setIsEditing(true);
  }, []);

  return (
    <>
      <div
        className={`nk-fmg-body-content ${
          fullScreen ? 'nk-fmg-body-content__no-padding' : ''
        }`}
      >
        <PreviewHeader
          documentName={preview?.name}
          templateName={preview?.template?.name}
          menuOptions={currentMenuOptions}
          params={{
            handleEditDocument,
            handleExportPDF,
            handleExportWord,
            society: actualSociety,
            user,
            preview,
          }}
          fullScreen={fullScreen}
          handleSaveDocument={handleSaveDocument}
          handleToggleFullScreen={handleToggleFullScreen}
          isEditing={isEditing}
        />

        <div className="avoid-default-font">
          <CKEditor
            editorInstance={editorInstance}
            setEditorInstance={setEditorInstance}
            documentName={preview?.name || 'export'}
            documentModel={preview?.documentModel || ''}
            enableMentions={false}
            fullScreen={fullScreen}
          />
        </div>
      </div>
    </>
  );
};

export default Preview;
