import { Partner, User } from 'types';

function isUserPartner(partner: Partner, user: User): boolean {
  return user['_id'] === partner?.user;
}

function isUserInOtherUsers(partner: Partner, user: User): boolean {
  return partner?.otherUsers?.includes(user['_id']);
}

function isUserInUsersWithAccess(partner: Partner, user: User): boolean {
  return user?.usersWithAccess?.some((userId) => partner?.user === userId);
}

export const isPartnerOfUser = (partner: Partner, user: User): boolean =>
  isUserPartner(partner, user) ||
  isUserInOtherUsers(partner, user) ||
  isUserInUsersWithAccess(partner, user);
