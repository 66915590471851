import { FC } from 'react';

import { Committee } from 'types';
import CommitteesTable from './CommitteesTable';

type Props = {
  committees: Committee[];
};

const CommitteesPageBody: FC<Props> = ({ committees }) => (
  <div className="nk-block">
    <CommitteesTable committees={committees} />
  </div>
);

export default CommitteesPageBody;
