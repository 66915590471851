import i18n from 'i18n/config';
import { store } from 'redux/store';

import { Guest } from 'types';

import { getNameFromEmail } from 'utils/getNameFromEmail';

import sendEmail from 'modules/communications/redux/mailActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { getSocietyMainAdmin } from 'modules/society/utils';
import { deleteGuest as deleteGuestAction } from 'modules/society/redux/guestsActions';

import { createGuestEmail } from './createGuestEmail';

type DeleteGuestProps = {
  data: Guest;
};

export const deleteGuest = async ({ data }: DeleteGuestProps) => {
  const { language } = i18n;
  const { user } = store.getState();
  const { society } = store.getState();

  const guestId = data._id;
  const societyId = society?.actualSociety._id;
  const deletedGuestName = data.name || getNameFromEmail(data.email);

  try {
    await store.dispatch(deleteGuestAction({ guestId, societyId }, false));

    const mainAdmin = await getSocietyMainAdmin();

    if (!mainAdmin) {
      throw new Error();
    }

    const emailData = createGuestEmail({
      t: i18n.t,
      language,
      actualSociety: society.actualSociety,
      user,
      email: mainAdmin.email,
      mainAdminName: mainAdmin.name,
      updatedGuestName: deletedGuestName,
      action: 'DELETE',
    });

    store.dispatch(sendEmail(emailData, false));
    store.dispatch(addAlert(alertBodyTypes.GUEST_DELETED_SUCCESSFULLY));
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes.ERROR_DELETING_GUEST));
  }
};
