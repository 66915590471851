import { FC } from 'react';

import { CommitteeMember } from 'types';
import CommitteeMembersTable from './CommitteeMembersTable';

type Props = {
  members: CommitteeMember[];
};

const CommitteeMembersPageBody: FC<Props> = ({ members }) => (
  <div className="nk-block">
    <CommitteeMembersTable members={members} />
  </div>
);

export default CommitteeMembersPageBody;
