export default {
  CREATE_SOCIETY: {
    label: 'Creando la sociedad',
    value: 'CREATE_SOCIETY',
  },
  CREATE_PARTNERS: {
    label: 'Añadiendo socios',
    value: 'CREATE_PARTNERS',
  },
  CREATE_CONSTITUTION: {
    label: 'Constituyendo la sociedad',
    value: 'CREATE_CONSTITUTION',
  },
};
