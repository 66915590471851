import { useTranslate } from 'hooks/useTranslate';
import { Link } from 'react-router-dom';

import { formatCurrency, formatNumber } from 'utils/formats';

const EmployeeAside = ({ employee, incentiveData = null }) => {
  const { t } = useTranslate();

  const {
    totalUnits = 0,
    vestedUnits = 0,
    incentiveValue = 0,
    beneficiaryURL = '',
  } = incentiveData || {};

  return (
    <>
      <div className="card-inner">
        <div className="user-card user-card-s2">
          <div className="user-avatar lg bg-primary">
            {employee?.image ? (
              <img src={employee?.image} alt="Logo" />
            ) : (
              <span>{employee?.fullName?.slice(0, 2).toUpperCase()}</span>
            )}
          </div>

          <div className="user-info">
            {incentiveData ? (
              <div className="badge badge-outline badge-pill text-primary ucap">
                {t('Beneficiary')}
              </div>
            ) : (
              <div className="badge badge-outline badge-pill text-primary ucap">
                {t('EmployeeHasNoPlans')}
              </div>
            )}
            <h5>{employee?.fullName || ''}</h5>
            <span className="sub-text">{employee?.email}</span>
          </div>
        </div>
      </div>

      <div className="card-inner text-center px-0 py-2">
        <Link
          to={beneficiaryURL}
          className="btn btn-primary report-button"
          disabled={!incentiveData}
        >
          <em className="icon ni ni-external-alt mr-1" />
          {t('GoToBeneficiaryDetails')}
        </Link>
      </div>

      <div className="card-inner">
        <div className="overline-title-alt text-center">
          {t('IncentivePlanDetails')}
        </div>

        <div className="profile-balance mt-4">
          <div className="profile-balance-group gx-4 text-center d-flex justify-content-between align-items-start">
            <div className="profile-balance-sub">
              <div className="profile-balance-amount ">
                <div className="number">
                  {incentiveData && totalUnits ? formatNumber(totalUnits) : '-'}
                  <small className="currency currency-usd" />
                </div>
              </div>
              <div className="profile-balance-subtitle">{t('Units')}</div>
            </div>

            <div className="profile-balance-sub flex-1">
              <div className="profile-balance-amount">
                <div className="number">
                  {incentiveData && vestedUnits
                    ? formatNumber(vestedUnits)
                    : '-'}
                </div>
              </div>
              <div className="profile-balance-subtitle">{t('Vested')}</div>
            </div>

            <div className="profile-balance-sub flex-1">
              <div className="profile-balance-amount">
                <div className="number">
                  {incentiveData && incentiveValue
                    ? formatCurrency(incentiveValue)
                    : '-'}
                </div>
              </div>
              <div className="profile-balance-subtitle">
                {t('IncentiveValue')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeAside;
