export const invalidEmailReasonsKeys = {
  GENERATED_EMAIL: 'InvalidRecipientReason_GENERATED_EMAIL',
  UPPERCASE_EMAIL: 'InvalidRecipientReason_UPPERCASE_EMAIL',
  INVALID_EMAIL: 'InvalidRecipientReason_INVALID_EMAIL',
  DUPLICATE_EMAIL: 'InvalidRecipientReason_DUPLICATE_EMAIL',
  SPACES_IN_EMAIL: 'InvalidRecipientReason_SPACES_IN_EMAIL',
};

export default {
  GENERATED_EMAIL: 'GENERATED_EMAIL',
  UPPERCASE_EMAIL: 'UPPERCASE_EMAIL',
  INVALID_EMAIL: 'INVALID_EMAIL',
  DUPLICATE_EMAIL: 'DUPLICATE_EMAIL',
  SPACES_IN_EMAIL: 'SPACES_IN_EMAIL',
};
