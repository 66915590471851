import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import voteValues from 'constants/voteValues';

import getInstructionTextColor from '../utils/getInstructionTextColor';

type Props = {
  index: number;
  partner: any;
};

const BoardPartnerDelegationsInstructionsTableRow: FC<Props> = ({
  index,
  partner,
}: Props) => {
  const { t } = useTranslate();

  const instructionLabel =
    voteValues[partner.instruction as keyof typeof voteValues]?.label ||
    partner.instruction;

  const instructionTextColor = getInstructionTextColor(partner?.instruction);

  return (
    <>
      <tr id={`participant-name-${index}`} key={`participant-name-${index}`}>
        <td className="w-65">{partner.name}</td>
        <td className={`w-25 ${instructionTextColor}`}>
          {t(instructionLabel)}
        </td>
      </tr>
    </>
  );
};

export default BoardPartnerDelegationsInstructionsTableRow;
