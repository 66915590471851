import { ChangeEvent, FC, useMemo, useState } from 'react';

import { useTranslate } from 'hooks/useTranslate';
import InputCharCounter from 'modules/_shared/components/InputCharCounter';
import { Landing } from 'modules/dashboard/types/CustomDashboard';

type DashboardEditHeaderProps = {
  customDashboard: Landing;
  setCustomDashboard: any;
};

const DashboardEditHeader: FC<DashboardEditHeaderProps> = ({
  customDashboard,
  setCustomDashboard,
}) => {
  const { t } = useTranslate();

  const [title, setTitle] = useState(customDashboard.title);
  const [subTitle, setSubTitle] = useState(customDashboard.subtitle);

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleChangeSubTitle = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSubTitle(event.target.value);
  };

  useMemo(() => {
    setCustomDashboard((prev: Landing) => ({
      ...prev,
      title,
      subtitle: subTitle,
    }));
  }, [title, subTitle]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group">
          <label className="form-label" htmlFor="dashboard-title">
            {t('Title')}
          </label>

          <div className="form-control-wrap">
            <input
              type="text"
              id="dashboard-title"
              className="form-control form-control-lg"
              name="dashboard-title"
              value={title}
              maxLength={50}
              onChange={handleChangeTitle}
            />
          </div>

          <InputCharCounter value={title} limit={50} />
        </div>
      </div>

      <div className="col-12">
        <div className="form-group">
          <label className="form-label" htmlFor="operation-comments">
            {t('SubTitle')}
          </label>
          <div className="form-control-wrap">
            <div className="form-editor-custom">
              <textarea
                id="operation-comments"
                rows={4}
                maxLength={300}
                className="form-control form-control-lg no-resize"
                value={subTitle}
                onChange={handleChangeSubTitle}
              />
            </div>
          </div>

          <InputCharCounter value={subTitle} limit={300} />
        </div>
      </div>
    </div>
  );
};

export default DashboardEditHeader;
