/* eslint-disable react/react-in-jsx-scope */

import DocumentModal from 'modules/documents/pages/Documents/documentModal';
import { setModal } from 'modules/_shared/redux/modalActions';
import { store } from 'redux/store';
import removePlan from 'utils/removePlan';
import StockOptionsModal from 'modules/_shared/components/Modals/StockOption';
import UpdateUnits from 'modules/_shared/components/Modals/UpdateUnits';

function planAction(action, currentPlan) {
  if (currentPlan) {
    store.dispatch(
      setModal(<StockOptionsModal action={action} currentPlan={currentPlan} />)
    );
  }
}

function planOptions(i18n) {
  return [
    {
      key: 'WATCH_PLAN',
      icon: 'ni-info',
      text: i18n.t('WatchPlan'),
      action: ({ stockPlan }) => {
        planAction('watch', stockPlan);
      },
    },
    {
      key: 'UPDATE_PLAN',
      icon: 'ni-edit',
      text: i18n.t('UpdatePlan'),
      action: ({ stockPlan }) => {
        planAction('edit', stockPlan);
      },
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      key: 'UPDATE_UNITS',
      icon: 'ni-exchange',
      text: i18n.t('UpdateUnits'),
      action: ({ societyId, user, stockPlan, soldUnits }) => {
        store.dispatch(
          setModal(
            <UpdateUnits
              plan={stockPlan}
              userId={user['_id']}
              societyId={societyId}
              soldUnits={soldUnits}
            />
          )
        );
      },
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      key: 'UP_DOCUMENT',
      icon: 'ni-upload',
      text: i18n.t('UpDocument'),
      action: ({ societyId, user, stockPlan }) => {
        store.dispatch(
          setModal(
            <DocumentModal
              userId={user['_id']}
              societyId={societyId}
              stockPlan={stockPlan}
            />
          )
        );
      },
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      key: 'REMOVE_PLAN',
      icon: 'ni-trash',
      text: i18n.t('EliminatePlan'),
      action: removePlan,
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
  ];
}
export default planOptions;
