import { useEffect, useState } from 'react';

import { Board, SelectorOption } from 'types';

type Props = {
  board: Board;
};

export default function useGetOrderOptions({ board }: Props) {
  const [orderOptions, setOrderOptions] = useState<SelectorOption[]>([]);

  useEffect(() => {
    const options: SelectorOption[] = [];

    board?.orders?.forEach((order) => {
      options.push({
        value: order?._id,
        label: order?.subject,
      });
    });

    setOrderOptions(options);
  }, [board]);

  return {
    data: { orderOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
