import { useDispatch, useSelector } from 'react-redux';
import { translate, useTranslate } from 'hooks/useTranslate';
import { State } from 'redux/initialState';
import { useState, useEffect, useRef } from 'react';

import { getPlansViewData } from '../pages/SocietyDashboard/getPlansViewData';
import { getCapitalViewData } from '../pages/SocietyDashboard/getCapitalViewData';
import { getPartnersViewData } from '../pages/SocietyDashboard/getPartnersViewData';
import { getPartnersChartViewData } from '../pages/SocietyDashboard/getPartnersChartViewData';
import { getOperationsViewData } from '../pages/SocietyDashboard/getOperationsViewData';
import { getBeneficiariesViewData } from '../pages/SocietyDashboard/getBeneficiariesViewData';
import { getDraftsViewData } from '../pages/SocietyDashboard/getDraftsViewData';
import { getCapitalChartViewData } from '../pages/SocietyDashboard/getCapitalChartViewData';
import { getFinancingChartViewData } from '../pages/SocietyDashboard/getFinancingChartViewData';
import { getSummaryChartViewData } from '../pages/SocietyDashboard/getDataSummaryChart';
import { oldGetDataSummaryChart } from '../pages/SocietyDashboard/_oldGetDataSummaryChart';

interface DashboardData {
  sharedData: any | null;
  capitalViewData: any | null;
  capitalChartViewData: any | null;
  summaryChartViewData: any | null;
  plansViewData: any | null;
  financialChartViewData: any | null;
  partnersViewData: any | null;
  partnersChartViewData: any | null;
  operationsViewData: any | null;
  beneficiariesViewData: any | null;
  draftsViewData: any | null;
}

const useGetSocietyDashboardData = (): {
  data: DashboardData;
  loadingState: Record<string, boolean>;
} => {
  const dispatch = useDispatch();
  const society = useSelector((state: State) => state.society?.actualSociety);

  const societyId = society?._id;

  const [data, setData] = useState<DashboardData>({
    sharedData: null,
    capitalViewData: null,
    capitalChartViewData: null,
    summaryChartViewData: null,
    plansViewData: null,
    financialChartViewData: null,
    partnersViewData: null,
    partnersChartViewData: null,
    operationsViewData: null,
    beneficiariesViewData: null,
    draftsViewData: null,
  });
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({
    summaryChartViewData: false,
    financialChartViewData: false,
    partnersViewData: false,
    partnersChartViewData: false,
    capitalViewData: false,
    capitalChartViewData: false,
    plansViewData: false,
    operationsViewData: false,
    beneficiariesViewData: false,
    draftsViewData: false,
  });

  const setViewLoading = (viewKey: string, isLoading: boolean) => {
    setLoadingState((prev) => ({ ...prev, [viewKey]: isLoading }));
  };

  useEffect(() => {
    const getSummaryView = async () => {
      setViewLoading('summaryChartViewData', true);
      const summaryChartViewData = await getSummaryChartViewData(
        societyId,
        dispatch
      );

      const oldSummary = oldGetDataSummaryChart({ society, t: translate });

      // TODO: @javierfaife - the new "summaryChartViewData.data" is wrong calculated. I added the oldSummary.data temporarily
      summaryChartViewData.data = oldSummary.data;

      setData((prev) => ({ ...prev, summaryChartViewData }));
      setViewLoading('summaryChartViewData', false);
    };

    const getFinancialView = async () => {
      setViewLoading('financialChartViewData', true);

      const financialChartViewData = await getFinancingChartViewData(
        societyId,
        dispatch
      );

      setData((prev) => ({ ...prev, financialChartViewData }));
      setViewLoading('financialChartViewData', false);
    };

    const getPartnersView = async () => {
      setViewLoading('partnersViewData', true);
      setViewLoading('partnersChartViewData', true);

      const partnersViewData = await getPartnersViewData(societyId, dispatch);
      setData((prev) => ({ ...prev, partnersViewData }));

      const partnersChartViewData = getPartnersChartViewData(partnersViewData);
      setData((prev) => ({ ...prev, partnersChartViewData }));

      setViewLoading('partnersViewData', false);
      setViewLoading('partnersChartViewData', false);
    };

    const getCapitaView = async () => {
      setViewLoading('capitalViewData', true);
      setViewLoading('capitalChartViewData', true);

      const capitalViewData = await getCapitalViewData(societyId, dispatch);
      setData((prev) => ({ ...prev, capitalViewData }));

      const capitalChartViewData = getCapitalChartViewData(capitalViewData);
      setData((prev) => ({ ...prev, capitalChartViewData }));

      setViewLoading('capitalViewData', false);
      setViewLoading('capitalChartViewData', false);
    };

    const getPlansView = async () => {
      setViewLoading('plansViewData', true);

      const plansViewData = await getPlansViewData(societyId, dispatch);
      setData((prev) => ({ ...prev, plansViewData }));

      setViewLoading('plansViewData', false);
    };

    const getOperationsView = async () => {
      setViewLoading('operationsViewData', true);

      const operationsViewData = await getOperationsViewData(
        societyId,
        dispatch
      );
      setData((prev) => ({ ...prev, operationsViewData }));

      setViewLoading('operationsViewData', false);
    };

    const getBeneficiariesView = async () => {
      setViewLoading('beneficiariesViewData', true);

      const beneficiariesViewData = await getBeneficiariesViewData(
        societyId,
        dispatch
      );
      setData((prev) => ({ ...prev, beneficiariesViewData }));

      setViewLoading('beneficiariesViewData', false);
    };

    const getDraftsView = async () => {
      setViewLoading('draftsViewData', true);

      const draftsViewData = await getDraftsViewData(societyId, dispatch);
      setData((prev) => ({ ...prev, draftsViewData }));

      setViewLoading('draftsViewData', false);
    };

    if (societyId) {
      getSummaryView();
      getFinancialView();
      getPartnersView();
      getCapitaView();
      getPlansView();
      getOperationsView();
      getBeneficiariesView();
      getDraftsView();
    }
  }, [dispatch, societyId]);

  return { data, loadingState };
};

export default useGetSocietyDashboardData;
