import { store } from 'redux/store';
import i18n from 'i18n/config';

import Swal from 'sweetalert2';
import { deleteBeneficiary } from 'modules/beneficiaries/redux/beneficiaryActions';
import { getActualSociety } from '../../../utils/filters';

export default function removeBeneficiary(params: any, beneficiaryId: string) {
  const { societyId, data: beneficiaryData, user } = params;
  Swal.fire({
    icon: 'warning',
    title: `<h4 className="nk-block-title page-title">${i18n.t(
      'AreYouSureAboutDelBeneficiary'
    )}  `,
    html: `<h3 class="text-primary fw-normal">${
      beneficiaryData?.name
    } </h3><br /><div class="caption-text">${i18n.t(
      'ThisProcedureCantUndo'
    )}</div>`,
    confirmButtonText: i18n.t('OkDelete'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      await store.dispatch(
        deleteBeneficiary({
          beneficiaryId,
          societyId,
        })
      );

      getActualSociety(user, societyId);
      Swal.fire({
        icon: 'success',
        title: `<h4 class="nk-modal-title">${i18n.t(
          'BeneficiaryDeletedSuccessfully'
        )}<br></h4>`,
        confirmButtonText: i18n.t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }
  });
}
