import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import isValidEmail from 'utils/isValidEmail';

type Props = {
  cancelationMode: string;
  cancelationDate: string;
  beneficiaryEmail: string;
  handleCancelBeneficiary: (event: any) => void;
};

const CancelBeneficiaryFooter: FC<Props> = ({
  cancelationMode,
  cancelationDate,
  beneficiaryEmail,
  handleCancelBeneficiary,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <button
        type="submit"
        className="btn btn-lg btn-primary"
        onClick={handleCancelBeneficiary}
        disabled={
          !cancelationMode ||
          !cancelationDate ||
          !isValidEmail(beneficiaryEmail)
        }
      >
        {t('ExecuteCancellation')}
      </button>
    </>
  );
};

export default CancelBeneficiaryFooter;
