// eslint-disable-next-line import/no-extraneous-dependencies
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import axios from '../interceptors/axios';
import { decryptResponse } from './token';

export default async function downloadZip({ documents, currentFolder }) {
  const zip = new JSZip();

  // use `map` instead of `forEach` to return an array of Promises
  const promises = documents.map(async (document) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/download/${document['_id']}`
    );

    const decodedData = await decryptResponse(data);
    const url = decodedData?.url;

    // return the fetched blob to be added to the zip object later
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // add the PDF file to the zip file
        zip.file(`${document.name}.pdf`, blob, { binary: true });
      })
      .catch((error) => {
        throw new Error(error);
      });
  });

  // wait for all the fetch calls to complete before calling zip.generateAsync
  Promise.all(promises).then(() => {
    zip.generateAsync({ type: 'blob' }).then((content) => {
      FileSaver.saveAs(content, `${currentFolder.label}.zip`);
    });
  });
}
