import Swal from 'sweetalert2';
import { format } from 'date-fns';
import i18n from 'i18n/config';

import { Board, Society, User } from 'types';
import eventTypes from 'constants/eventTypes';
import trackEvent from 'utils/trackEvent';

import { parseExistingToNewBoard } from './parseExistingToNewBoard';
import { saveBoard } from './saveBoard';

type DeleteBoardProps = {
  board: Board;
  user: User;
  actualSociety: Society;
  mixpanel: any;
  history: any;
};

const WarningBoardEquals = () => {
  Swal.fire({
    icon: 'error',
    title: i18n.t('WeAreSorry!'),
    text: i18n.t('WarningNewBoardNameAndDateAreEquals'),
  });
};

const DuplicatedSuccessfully = () => {
  Swal.fire({
    icon: 'success',
    title: i18n.t('BoardDuplicatedSuccessfully'),
    confirmButtonText: i18n.t('OK'),
    allowOutsideClick: false,
    showCancelButton: false,
    confirmButtonColor: '#6576FF',
  });
};

export const duplicateBoard = async (params: DeleteBoardProps) => {
  const { board, user, actualSociety, mixpanel, history } = params;

  const boardName = board.name;
  const boardDate = board.date
    ? format(new Date(board.date), "yyyy-MM-dd'T'HH:mm")
    : '';

  const title = `<h4 class="nk-modal-title">${i18n.t(
    'AreYouSureAboutDuplicateBoard'
  )}</h4>`;

  const { value: formValues } = await Swal.fire({
    icon: 'warning',
    title,
    confirmButtonText: i18n.t('OkDuplicate'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
    html: `
    <div className="form-group">
      <label className="form-label text-soft" htmlFor="swal-input-name">
        ${i18n.t('NewBoardName')}
      </label>
      <div className="form-control-wrap">
        <input id="swal-input-name" class="form-control form-control-lg" type="text" value="${boardName}">
      </div>
    </div>
    <br>
    <div className="form-group">
      <label className="form-label text-soft" htmlFor="swal-input-date">
        ${i18n.t('NewDateAndTime')}
      </label>
      <div className="form-control-wrap">
        <input id="swal-input-date" class="form-control date-picker" type="datetime-local" placeholder="dd/mm/yyyy"
        value="${boardDate}">
      </div>
    </div>
    `,
    focusConfirm: false,
    preConfirm: () => [
      (document.getElementById('swal-input-name') as HTMLInputElement)?.value ||
        boardName,
      (document.getElementById('swal-input-date') as HTMLInputElement)?.value ||
        boardDate,
    ],
  });

  if (formValues) {
    const [newBoardName, newBoardDate] = formValues;
    const isSameName = newBoardName === boardName;
    const isSameDate = newBoardDate === boardDate;

    if (isSameName && isSameDate) {
      return WarningBoardEquals();
    }

    const boardData = parseExistingToNewBoard({
      newBoardName,
      newBoardDate,
      existingBoard: board,
      actualSociety,
      user,
    });

    const isDuplicated = true;
    const newBoard = await saveBoard(boardData, isDuplicated);

    const currentEvent = eventTypes.DUPLICATE_BOARD;
    trackEvent(mixpanel, currentEvent, {
      userId: user?.['_id'],
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?.['_id'],
      societyName: actualSociety?.name,
      operation: currentEvent,
    });

    if (newBoard) {
      DuplicatedSuccessfully();

      const path = `/detalle-junta/${actualSociety['_id']}/${newBoard['_id']}`;
      history.push(path);
    }
  }
};
