import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { BoardParticipant } from 'types';
import { isParticipantAllowedForReminder } from '../../pages/BoardDetails/utils/isParticipantAllowedForReminder';

type Props = {
  participants: BoardParticipant[];
  allParticipants: boolean;
  selectedParticipants: string[];
  onSelectParticipant: (participant: BoardParticipant) => void;
};

const SendReminderParticipantsList = ({
  participants,
  allParticipants,
  selectedParticipants,
  onSelectParticipant,
}: Props) => {
  const { t } = useTranslate();

  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );

  const [expandDirection, setExpandDirection] = useState<string>('down');
  const [expandView, setExpandView] = useState<boolean>(false);

  const handleViewChange = (): void => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  const handleSelectParticipant = (participant: BoardParticipant): void => {
    onSelectParticipant(participant);
  };

  return (
    <div key="participants-card" className="card card-bordered mt-4">
      <table
        className="table"
        id="participants-table"
        style={{ marginBottom: '0px' }}
      >
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={2}>
              <span>
                {t('Selected')} ({selectedParticipants.length})
              </span>
            </th>
            <th className="col-2 text-right">
              <button
                type="button"
                className="badge btn-primary"
                onClick={() => handleViewChange()}
              >
                <em className={`icon ni ni-chevron-${expandDirection}`} />
              </button>
            </th>
          </tr>
        </thead>
      </table>
      <div
        key="reminder-participants-list"
        style={{ maxHeight: '200px', overflowY: 'auto' }}
      >
        <table className="table" id="participants-body">
          {expandView && (
            <tbody>
              {participants
                ?.filter((participant) =>
                  isParticipantAllowedForReminder({
                    participant,
                    actualSociety,
                    allParticipants,
                  })
                )
                ?.map((participant, index) => (
                  <tr className="tb-tnx-item">
                    <td
                      className="tb-tnx-info text-left"
                      id={`participant-${index}`}
                    >
                      <span className="title">{participant.name}</span>
                    </td>
                    <td
                      className="tb-tnx-info text-soft text-left"
                      id={`participant-${index}-email`}
                    >
                      <span className="title d-block">{participant.email}</span>
                    </td>
                    <td
                      className="tb-tnx-info"
                      id={`participant-${index}-blank`}
                    >
                      <div className="custom-control custom-checkbox custom-control-sm">
                        <input
                          type="checkbox"
                          id={`participant-${index}-checkbox`}
                          name={`participant-${index}-checkbox`}
                          className="custom-control-input"
                          value={participant._id}
                          checked={selectedParticipants.includes(
                            participant._id
                          )}
                          onChange={() => handleSelectParticipant(participant)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`participant-${index}-checkbox`}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default SendReminderParticipantsList;
