import i18n from 'i18n/config';

export default {
  var: {
    CURRENT: 'CURRENT',
    REVOKED: 'REVOKED',
  },
  name: {
    CURRENT: i18n.t('Current'),
    REVOKED: i18n.t('Revoked'),
  },
  badge: {
    CURRENT: 'success',
    REVOKED: 'danger',
  },
};
