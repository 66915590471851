import { translate } from 'hooks/useTranslate';
import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';
import { PartnerRepresentant } from 'types';

type Options = {
  role: string;
  value: string;
  label: string;
};

type SelectedPartnerOptions = {
  label: string;
  options: Options[];
};

export const getSindicationOptions = (
  partnerOptions: any[],
  partnerRepresentants: PartnerRepresentant[]
): SelectedPartnerOptions[] => {
  const representantOptions = partnerRepresentants.map((representant) => ({
    role: boardDelegationRoles.PARTNER_REPRESENTANT,
    value: representant['_id'],
    label: `${representant?.cif} | ${representant?.name}`,
  }));

  return [
    { label: translate('PartnerRepresentants'), options: representantOptions },
    { label: translate('Partners'), options: partnerOptions },
  ];
};
