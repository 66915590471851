/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import boardTypes from 'constants/boardTypes';
import { SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import { formatDateToTime } from 'constants/formats';
import instructionTypes from 'constants/instructionTypes';
import voteTypes from 'constants/voteTypes';
import voteValues from 'constants/voteValues';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { updateBoard } from 'modules/boards/redux/boardActions';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2';

function DelegateVoteModal({ board, partnerId }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const [selectablePartners, setSelectablePartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [hasInstructions, setHasInstructions] = useState(false);
  const [instructionType, setInstructionType] = useState('');
  const [orders, setOrders] = useState([]);
  const [instructions, setInstructions] = useState([]);

  const [time] = useState(formatDateToTime(board?.date));
  const [date] = useState(
    board?.date ? format(new Date(board?.date), 'dd/MM/yyyy') : ''
  );
  const animatedComponents = makeAnimated();
  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: SELECCIONAR_SOCIO,
  });
  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedPartner(selectedOption.value);
    setSelectedPartnerOption(selectedOption);
  };
  const [boardType] = useState(boardTypes?.[board?.boardType]?.text || '-');

  const saveDelegation = (event) => {
    event.preventDefault();
    if (selectedPartner !== SELECCIONAR_SOCIO) {
      const participant = board?.participants.find(
        (p) => p?.member === partnerId
      );
      if (participant) {
        const representative = board?.participants.find(
          (p) => p?.member === selectedPartner
        );
        if (representative?.assists) {
          participant.assists = true;
          participant.isAbsent = false;
        } else if (representative?.isAbsent) {
          participant.assists = false;
          participant.isAbsent = true;
        }
        participant.isRepresented = true;
        participant.representative = selectedPartner;
        const newInstructions =
          instructionType === instructionTypes.POINT_BY_POINT.value
            ? instructions
            : [];
        participant.delegationVote = hasInstructions
          ? { instructionType, instructions: newInstructions }
          : null;
        const newParticipants = board?.participants.map((p) =>
          p?.member === participant?.member ? participant : p
        );
        return Swal.fire({
          icon: 'info',
          title: `<h4 class="nk-modal-title">${t('Attention')}<br></h4>`,
          html: `<p>${t('SignDocumentToComplete')}`,
          confirmButtonText: t('Sign'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(
              updateBoard(board['_id'], { participantData: participant }, false)
            );
            dispatch(setModal(null));
            window.location.href = `#/firma-delegacion/${actualSociety['_id']}/${board['_id']}/${partnerId}`;
          }
        });
      }
    }
  };

  const handleSaveInstruction = (value, index) => {
    const newInstructions = [...instructions];
    if (newInstructions[index]) {
      newInstructions[index].vote = value;
      setInstructions(newInstructions);
    }
  };

  useEffect(() => {
    if (board && partnerId) {
      const validOrders = board?.orders?.filter(
        (order) => order.voteType === voteTypes.YES_NO_ABS.value
      );
      setOrders(validOrders);
      const orderInstructions = validOrders.map((order) => ({
        order: order['_id'],
        vote: '',
      }));
      setInstructions(orderInstructions);
      const actualPartner = board?.participants.find(
        (p) => p?.member === partnerId
      );
      if (actualPartner) {
        if (actualPartner?.representative) {
          setSelectablePartners(
            actualSociety?.partners.filter(
              (partner) => partner['_id'] === actualPartner?.representative
            ) || []
          );
        } else {
          setSelectablePartners(
            actualSociety?.partners.filter(
              (partner) =>
                partner['_id'] !== partnerId && partner?.sharesCount?.actual > 0
            ) || []
          );
        }
      }
    }
  }, [board, partnerId, actualSociety]);

  useEffect(() => {
    if (selectedPartner === SELECCIONAR_SOCIO) {
      setHasInstructions(false);
    }
  }, [selectedPartner]);

  return (
    <>
      <Modal.Header style={{ border: 'none' }}>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt" />
            <h4 className="nk-modal-title">{t('DelegateVote')}</h4>
            <div className="nk-modal-text mb-2">
              <p className="lead">
                {t('DelegateVoteMessagePart1', {
                  societyName: actualSociety?.name,
                })}
                {date}.{t('DelegateVoteMessagePart2')}
              </p>
              <p className="text-soft">{t('SelectPartnerToDelegateVote')}</p>

              <Select
                closeMenuOnSelect
                className="form-control-wrap react-select"
                value={selectedPartnerOption}
                options={selectablePartners.map((partner) => ({
                  value: partner['_id'],
                  label: `${partner?.cif} | ${partner?.name}`,
                }))}
                components={animatedComponents}
                onChange={handlePartnerOptionChange}
              />
            </div>
            <div className="nk-modal-text text-left">
              <div
                className="custom-control custom-control-sm custom-checkbox custom-control-pro"
                style={{
                  zIndex: 0,
                }}
              >
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="instruction-checkbox"
                  checked={hasInstructions}
                  onChange={(event) => setHasInstructions(event.target.checked)}
                  disabled={selectedPartner === SELECCIONAR_SOCIO}
                />
                <label
                  className="custom-control-label"
                  htmlFor="instruction-checkbox"
                >
                  {t('GiveVotingInstructions')}
                </label>
              </div>
              {hasInstructions ? (
                <>
                  <div className="form-group mt-3">
                    <label className="form-label" htmlFor="full-name">
                      {t('SelectHowTheDelegateVote')}
                    </label>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio1"
                            value={instructionTypes.ALL_YES.value}
                            checked={
                              instructionType === instructionTypes.ALL_YES.value
                            }
                            onChange={(event) =>
                              setInstructionType(event.target.value)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="btnRadio1"
                          >
                            {t('AllYes')}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio2"
                            value={instructionTypes.ALL_NO.value}
                            checked={
                              instructionType === instructionTypes.ALL_NO.value
                            }
                            onChange={(event) =>
                              setInstructionType(event.target.value)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="btnRadio2"
                          >
                            {t('AllNo')}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio3"
                            value={instructionTypes.ALL_ABS.value}
                            checked={
                              instructionType === instructionTypes.ALL_ABS.value
                            }
                            onChange={(event) =>
                              setInstructionType(event.target.value)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="btnRadio3"
                          >
                            {t('AllAbs')}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                          <input
                            type="radio"
                            className="custom-control-input"
                            name="btnRadio"
                            id="btnRadio4"
                            value={instructionTypes.POINT_BY_POINT.value}
                            checked={
                              instructionType ===
                              instructionTypes.POINT_BY_POINT.value
                            }
                            onChange={(event) =>
                              setInstructionType(event.target.value)
                            }
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="btnRadio4"
                          >
                            {t('PointByPoint')}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {instructionType === instructionTypes.POINT_BY_POINT.value &&
                  orders.length ? (
                    <>
                      <label className="form-label mt-2" htmlFor="full-name">
                        {t('SetOneVoteForEachPoint')}
                      </label>
                      {orders.map((order, index) => (
                        <div
                          key={order['_id']}
                          className="card-inner card-bordered mt-2 round-lg"
                        >
                          <span className="p-2">
                            {`${index + 1}. ${order.subject}`}
                          </span>
                          {order.voteType === voteTypes.YES_NO_ABS.value && (
                            <div className="form-group p-2 mt-1">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name={`btnRadio-${order['_id']}`}
                                      id={`btnRadio1-${order['_id']}`}
                                      value={voteValues.YES.value}
                                      checked={
                                        instructions[index].vote ===
                                        voteValues.YES.value
                                      }
                                      onChange={(event) =>
                                        handleSaveInstruction(
                                          event.target.value,
                                          index
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`btnRadio1-${order['_id']}`}
                                    >
                                      {t('Yes')}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name={`btnRadio-${order['_id']}`}
                                      id={`btnRadio2-${order['_id']}`}
                                      value={voteValues.NO.value}
                                      checked={
                                        instructions[index].vote ===
                                        voteValues.NO.value
                                      }
                                      onChange={(event) =>
                                        handleSaveInstruction(
                                          event.target.value,
                                          index
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`btnRadio2-${order['_id']}`}
                                    >
                                      {t('Nope')}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      name={`btnRadio-${order['_id']}`}
                                      id={`btnRadio3-${order['_id']}`}
                                      value={voteValues.ABS.value}
                                      checked={
                                        instructions[index].vote ===
                                        voteValues.ABS.value
                                      }
                                      onChange={(event) =>
                                        handleSaveInstruction(
                                          event.target.value,
                                          index
                                        )
                                      }
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`btnRadio3-${order['_id']}`}
                                    >
                                      {t('Abstention')}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>

            <div className="nk-modal-action mt-5">
              <button
                type="button"
                className="btn btn-primary"
                disabled={
                  selectedPartner === SELECCIONAR_SOCIO ||
                  (hasInstructions && !instructionType)
                }
                onClick={saveDelegation}
              >
                {t('DelegateVote')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default DelegateVoteModal;
