export const validationURL = {
  FACTORIAL: process.env.REACT_APP_FACTORIAL_VALIDATION_URL,
  PERSONIO: '',
};

export const validateIntegration = async (source, key) => {
  const url = validationURL[source.toUpperCase()];

  if (!url) {
    throw new Error('ERROR_VALIDATION_URL_NOT_FOUND');
  }

  try {
    const options = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'x-api-key': key,
      },
    };
    const { ok } = await fetch(url, options);
    return ok;
  } catch (error) {
    throw new Error('ERROR_VALIDATION');
  }
};
