import { Society } from 'types';
import { getSocietyEmailLinkColor } from 'modules/society/utils';

import './CommunicationSettingsSignaturePreview.scss';

type CommunicationSettingsSignaturePreviewProps = {
  signature: {
    name: string;
    jobTitle: string;
    logo: string;
    website: string;
    phone: string;
    address: string;
    footer: string;
  };
  society: Society;
};

const CommunicationSettingsSignaturePreview = ({
  signature,
  society,
}: CommunicationSettingsSignaturePreviewProps) => {
  const { name, jobTitle, logo, website, phone, address, footer } = signature;
  const linkColor = getSocietyEmailLinkColor(society);

  return (
    <div className="col-md-6 my-4 email-signature-preview">
      <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px' }}>
        <p style={{ lineHeight: '24px' }}>
          <b>{name}</b>
          <br />
          {jobTitle}
        </p>
        <img
          src={logo}
          alt="signature logo"
          style={{ maxWidth: '150px', marginBottom: '24px' }}
        />
        <br />
        <p style={{ lineHeight: '18px', marginBottom: '2px' }}>
          <a
            href={`https://${website}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: linkColor }}
          >
            {website}
          </a>
          <a href={`tel:${phone}`} style={{ color: linkColor }}>
            {phone && <> | Phone: {phone}</>}
          </a>
        </p>
        <p style={{ lineHeight: '18px', marginTop: '2px' }}>
          <a href="https://www.google.com/maps" style={{ color: linkColor }}>
            {address}
          </a>
        </p>
        <p style={{ color: 'grey', fontSize: '10px' }}>{footer}</p>
      </div>
    </div>
  );
};

export default CommunicationSettingsSignaturePreview;
