import { useTranslate } from 'hooks/useTranslate';

import CTAImage from 'assets/images/constitution-ai-cta.svg';
import AIStars from 'assets/images/ai-stars-white.png';

type ConstitutionAiModalCTAProps = {
  handleScanFile: any;
};

const ConstitutionAiModalCTA = ({
  handleScanFile,
}: ConstitutionAiModalCTAProps) => {
  const { t } = useTranslate();

  return (
    <div className="card d-flex flex-column justify-content-center text-center h-100 px-5">
      <img src={CTAImage} className="card-img-top h-max-150px" alt="" />
      <div className="card-inner">
        <h5 className="card-title">{t('ConstitutionAiCTAHeader')}</h5>
        <p className="card-text">{t('ConstitutionAiCTAInfo')}</p>

        <button
          type="button"
          className="btn btn-primary"
          onClick={handleScanFile}
        >
          {t('ConstitutionAiCTAButton')}

          <img
            src={AIStars}
            alt="Sttok AI stars"
            width={18}
            height={18}
            className="ml-2"
          />
        </button>
      </div>
    </div>
    /* <button type="button" className="btn btn-primary" onClick={handleScanFile}>
      {t('ScanFile')}
    </button> */
  );
};

export default ConstitutionAiModalCTA;
