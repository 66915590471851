import attendanceTypes from 'constants/attendanceTypes';
import { Board } from 'types';

const getPlaceByAttendanceType = (board: Board): string => {
  if (
    board.attendanceType === attendanceTypes.HYBRID.value ||
    board.attendanceType === attendanceTypes.FACE_TO_FACE.value
  ) {
    return board?.place || '';
  }

  return board?.onlineAccess || '';
};

export const getBoardPlace = (board: Board): string => {
  if (!board || !board.attendanceType) return '';

  return getPlaceByAttendanceType(board);
};
