const historicTypes = {
  INCORPORATION_DATE: 'INCORPORATION_DATE',
  LEAVING_DATE: 'LEAVING_DATE',
  SALARY_INCREASE: 'SALARY_INCREASE',
  SALARY_DECREASE: 'SALARY_DECREASE',
  JOBTITLE_CHANGE: 'JOBTITLE_CHANGE',
  CATEGORY_CHANGE: 'CATEGORY_CHANGE',
  DEPARTMENT_CHANGE: 'DEPARTMENT_CHANGE',
  COMPENSATION_ADDED: 'COMPENSATION_ADDED',
  SUPPLEMENT_ADDED: 'SUPPLEMENT_ADDED',
  CONTRACT_CHANGE: 'CONTRACT_CHANGE',
  PERSONAL_DATA_CHANGE: 'PERSONAL_DATA_CHANGE',
};

export const historicTypeIcons = {
  INCORPORATION_DATE: 'ni ni-calendar',
  LEAVING_DATE: 'ni ni-cross-circle',
  SALARY_INCREASE: 'ni ni-arrow-up',
  SALARY_DECREASE: 'ni ni-arrow-down',
  JOBTITLE_CHANGE: 'ni ni-briefcase',
  CATEGORY_CHANGE: 'ni ni-tag',
  DEPARTMENT_CHANGE: 'ni ni-building',
  COMPENSATION_ADDED: 'ni ni-money',
  SUPPLEMENT_ADDED: 'ni ni-gift',
  CONTRACT_CHANGE: 'ni ni-file-text',
  PERSONAL_DATA_CHANGE: 'ni ni-user-add',
};

export default historicTypes;
