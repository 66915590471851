import Swal from 'sweetalert2';
import i18n from 'i18n/config';

import { store } from 'redux/store';
import { getActualSociety } from 'utils/filters';
import { Board, Society, User } from 'types';

import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import { deleteBoard as deleteBoardAction } from 'modules/boards/redux/boardActions';
import eventTypes from 'constants/eventTypes';
import trackEvent from 'utils/trackEvent';

type DeleteBoardProps = {
  board: Board;
  user: User;
  actualSociety: Society;
  mixpanel: any;
};

export const deleteBoard = async (params: DeleteBoardProps) => {
  const { board, user, actualSociety, mixpanel } = params;

  const title = `<h4 class="nk-modal-title">${i18n.t(
    'AreYouSureAboutDelBoard'
  )}</h4>`;

  const html = `<h5 class="text-primary">${
    board.name
  }</h5><br /><div class="caption-text">${i18n.t(
    'ThisProcedureCantUndo'
  )}</div>`;

  Swal.fire({
    icon: 'warning',
    title,
    html,
    confirmButtonText: i18n.t('OkDelete'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await store.dispatch(deleteBoardAction(board._id));
        await getActualSociety(user, actualSociety._id);

        const currentEvent = eventTypes.DELETE_BOARD;
        trackEvent(mixpanel, currentEvent, {
          userId: user?.['_id'],
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
          operation: currentEvent,
        });
      } catch (error) {
        store.dispatch(addAlert(alertBodyTypes.ERROR_DELETING_BOARD));
      }
    }
  });
};
