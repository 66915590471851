export function countSocietyShares(society) {
  if (!society) return society;
  const newSociety = { ...society };
  const sharesInit = society.partners?.reduce((acc, partner) => {
    acc[partner['_id']] = {
      actual: 0,
      future: 0,
      drafts: 0,
      hasDilution: !partner?.isNotDiluted,
    };
    return acc;
  }, {});

  // Count shares per user
  const shares = society.shares?.reduce((acc, operation) => {
    const { partner, isActive, from, to } = operation;

    if (isActive) {
      if (acc[partner]) acc[partner]['actual'] += to - from + 1;
      else {
        acc[partner] = {
          actual: to - from + 1,
          future: 0,
          drafts: 0,
          hasDilution: true,
        };
      }
    }

    return acc;
  }, sharesInit);

  let societyTotalShares;
  if (shares) {
    societyTotalShares = Object.values(shares).reduce(
      (acc, { actual }) => {
        acc.actual += actual;
        return acc;
      },
      { actual: 0, future: 0, drafts: 0 }
    );
  }

  // // Count shares per draft
  society?.investors?.forEach((investor) => {
    const draftShares = investor?.sharesCount?.drafts || 0;

    societyTotalShares.drafts += draftShares;

    Object.entries(shares).forEach(([id]) => {
      const currentPartner = society.partners.find(
        (partner) => partner['_id'] === id
      );
      if (currentPartner?.cif === investor?.cif)
        shares[id]['drafts'] += draftShares;
    });
  });

  // Variable to keep the count of units for each plan id
  const plansCount = {};

  // Count shares per beneficiary
  society?.beneficiaries
    ?.filter((beneficiary) => !beneficiary?.isDraft)
    .forEach((beneficiary) => {
      const futureShares = beneficiary?.isCanceled
        ? beneficiary?.finalConsolidatedUnits
        : beneficiary?.sharesCount?.future;

      if (plansCount[beneficiary.plan]) {
        plansCount[beneficiary.plan] += futureShares;
      } else {
        plansCount[beneficiary.plan] = futureShares;
      }

      societyTotalShares.future += futureShares;
      Object.entries(shares).forEach(([id]) => {
        const currentPartner = society.partners.find(
          (partner) => partner['_id'] === id
        );
        if (currentPartner?.cif === beneficiary?.cif) {
          shares[id]['future'] += futureShares;
          if (currentPartner?.isNotDiluted) {
            shares[id]['hasDilution'] = false;
          }
        }
      });
    });

  newSociety.sharesCount = {
    ...societyTotalShares,
  };

  // Create partners array with shares
  const updatedPartners = society?.partners?.map((societyPartner) => {
    const newPartner = { ...societyPartner };
    newPartner.sharesCount = { actual: 0, future: 0, drafts: 0, diluted: 0 };
    Object.entries(shares).forEach(([id, sharesCount]) => {
      if (id === newPartner['_id']) newPartner.sharesCount = sharesCount;
    });
    return newPartner;
  });

  newSociety.partners = updatedPartners;

  if (society?.stockPlans?.length === 1) {
    const societySharesCount =
      newSociety?.sharesCount?.actual +
      newSociety?.sharesCount?.future +
      newSociety?.sharesCount?.drafts;

    const societyPotentialSharesCount =
      newSociety?.sharesCount?.actual +
      newSociety?.sharesCount?.drafts +
      society?.stockPlans?.[0]?.sharesTotal;
    // Calculate totalFD and totalPotentialFD assuming that all partners dilutes
    const totals = updatedPartners.reduce(
      (acc, curr) => {
        const sharesCount =
          curr?.sharesCount?.actual +
          curr?.sharesCount?.future +
          curr?.sharesCount?.drafts;
        acc.totalFD += (sharesCount * 100) / societySharesCount;
        acc.totalPotential += (sharesCount * 100) / societyPotentialSharesCount;
        return acc;
      },
      { totalFD: 0, totalPotential: 0 }
    );

    const totalDilutionPercents = updatedPartners.reduce(
      (acc, curr) => {
        const sharesCount = curr?.sharesCount?.hasDilution
          ? curr?.sharesCount?.actual +
            curr?.sharesCount?.future +
            curr?.sharesCount?.drafts
          : curr?.sharesCount?.actual + curr?.sharesCount?.drafts;
        const currSocietySharesCount = curr?.sharesCount?.hasDilution
          ? societySharesCount
          : newSociety?.sharesCount?.actual + newSociety?.sharesCount?.drafts;
        const currSocietyPotentialSharesCount = curr?.sharesCount?.hasDilution
          ? societyPotentialSharesCount
          : newSociety?.sharesCount?.actual + newSociety?.sharesCount?.drafts;
        acc.totalDilutionFD += (sharesCount * 100) / currSocietySharesCount;
        acc.totalDilutionPotential +=
          (sharesCount * 100) / currSocietyPotentialSharesCount;
        return acc;
      },
      {
        totalDilutionFD: 0,
        totalDilutionPotential: 0,
      }
    );
    const { totalFD, totalPotential } = totals;
    const { totalDilutionFD, totalDilutionPotential } = totalDilutionPercents;

    const biasFD = Math.abs(totalDilutionFD - totalFD);
    const biasPotential = Math.abs(totalDilutionPotential - totalPotential);
    const dilutedPartnerCount = updatedPartners.reduce(
      (acc, curr) => (curr?.sharesCount?.hasDilution ? acc + 1 : acc),
      0
    );
    const dilutionFDFactor = biasFD / dilutedPartnerCount;
    const dilutionPotentialFactor = biasPotential / dilutedPartnerCount;

    const newUpdatedPartners = updatedPartners.map((partner) => {
      const sharesCount = partner?.sharesCount?.hasDilution
        ? partner?.sharesCount?.actual +
          partner?.sharesCount?.future +
          partner?.sharesCount?.drafts
        : partner?.sharesCount?.actual + partner?.sharesCount?.drafts;

      const currSocietySharesCount = partner?.sharesCount?.hasDilution
        ? societySharesCount
        : newSociety?.sharesCount?.actual + newSociety?.sharesCount?.drafts;
      const currSocietyPotentialSharesCount = partner?.sharesCount?.hasDilution
        ? societyPotentialSharesCount
        : newSociety?.sharesCount?.actual + newSociety?.sharesCount?.drafts;

      const newSharesCount = {
        ...partner.sharesCount,
        computedFD: (sharesCount * 100) / currSocietySharesCount,
        computedPotentialFD:
          (sharesCount * 100) / currSocietyPotentialSharesCount,
      };
      return { ...partner, sharesCount: newSharesCount };
    }, 0);

    // Update computedFD for each partner that dilutes
    for (let i = 0; i < newUpdatedPartners.length; i += 1) {
      const currPartner = newUpdatedPartners[i];
      if (
        currPartner?.sharesCount?.hasDilution &&
        currPartner?.sharesCount?.computedFD &&
        currPartner?.sharesCount?.computedPotentialFD
      ) {
        newUpdatedPartners[i].sharesCount.computedFD -= dilutionFDFactor;
        newUpdatedPartners[i].sharesCount.computedPotentialFD -=
          dilutionPotentialFactor;
      }
    }
    newSociety.partners = newUpdatedPartners;
  }

  return newSociety;
}

export function countUserSocietiesShares(user, isUserWithAccess) {
  if (!user) return user;
  const userToUpdate = { ...user };

  const administrated = user.societies.administrated.map(countSocietyShares);
  const participated = user.societies.participated.map(countSocietyShares);
  const beneficiated = user.societies.beneficiated.map(countSocietyShares);

  let usersWithAccess;
  if (!isUserWithAccess) {
    usersWithAccess = user.usersWithAccess.map((society) =>
      countUserSocietiesShares(society, true)
    );
  }
  userToUpdate.usersWithAccess = usersWithAccess;
  userToUpdate.societies.administrated = administrated;
  userToUpdate.societies.participated = participated;
  userToUpdate.societies.beneficiated = beneficiated;

  return userToUpdate;
}
