import i18n from 'i18n/config';

export default {
  var: {
    CONSOLIDATED: 'CONSOLIDATED',
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    DRAFT: 'DRAFT',
  },
  name: {
    CONSOLIDATED: i18n.t('Consolidated'),
    ACTIVE: i18n.t('Active'),
    CANCELED: i18n.t('Canceled'),
    DRAFT: i18n.t('BeneficiariesDraft'),
  },
  style: {
    CONSOLIDATED: 'success',
    ACTIVE: 'warning',
    CANCELED: 'danger',
    DRAFT: 'info',
  },
};
