import { getEmailColumn } from 'constants/getExcelColumns';
import isValidEmail from 'utils/isValidEmail';

const getInvalidEmails = (partners: any): any => {
  const result = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const partner of partners) {
    const email = getEmailColumn(partner) || '-';
    const validEmial = isValidEmail(email);
    if (!validEmial) {
      result.push(email);
    }
  }
  return result;
};

export default getInvalidEmails;
