import { useEffect, useState } from 'react';
import { store } from 'redux/store';
import { getUsers } from 'modules/profile/redux/userActions';

import { Partner } from 'types';

import getUserCategoriesFromPartner from 'modules/profile/components/UserMyData/utils/getUserCategoriesFromPartner';
import sortEmailsAndCategories from 'modules/profile/components/UserMyData/utils/sortEmailsAndCategories';

type Props = {
  partner: Partner;
  partnerEmails: any;
};

const getEmailsAndCategories = async (users: string[], partner: Partner) => {
  if (!users?.length) return;

  try {
    const queryParam = { usersList: users };
    const partnerUsersList = await store.dispatch(getUsers(queryParam));

    if (!partnerUsersList?.length) return;

    const emailsList = partnerUsersList?.length
      ? partnerUsersList.map((curr: any) => curr.email)
      : [''];

    const emailsWithCategory = await getUserCategoriesFromPartner(
      partner,
      partnerUsersList
    );
    const currentCategories = emailsList.map((currentEmail: any) => {
      const { category } = emailsWithCategory.find(
        (emailsWithCategory) => emailsWithCategory?.email === currentEmail
      ) || { category: '' };
      return category;
    });

    const { sortedEmails, sortedCategories } = sortEmailsAndCategories(
      emailsList,
      currentCategories
    );

    const emails = sortedEmails?.map((email: any, index: number) => ({
      text: email,
      category: sortedCategories[index],
    }));

    return emails;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default function useGetPartnerEmails({ partner, partnerEmails }: Props) {
  const [emails, setEmails] = useState<any>([]);
  const [isFetchedData, setIsFetchedData] = useState<boolean>(false);

  useEffect(() => {
    const getEmails = async () => {
      if (!isFetchedData) {
        const otherUsers = partner?.otherUsers || [];
        const readOnlyUsers = partner?.readOnlyUsers || [];
        const users = [...otherUsers, ...readOnlyUsers];
        const emailsData = await getEmailsAndCategories(users, partner);
        setEmails(emailsData || []);
        setIsFetchedData(true);
      } else {
        setEmails(partnerEmails);
      }
    };
    getEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner, partnerEmails]);

  return {
    data: { emails },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
