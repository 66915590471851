import { SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import instructionTypes from 'constants/instructionTypes';
import { Board, BoardDelegationRole } from 'types';
import { boardDelegationRoles } from '../constants/boardDelegation';

type SaveDelegationProps = {
  board: Board;
  partnerId: string;
  selectedRole: BoardDelegationRole;
  selectedPartner: string;
  instructions: Record<string, any>[];
  hasInstructions: boolean;
  instructionType: keyof typeof instructionTypes;
};

export const getParticipantDelegationData = ({
  board,
  partnerId,
  selectedRole,
  selectedPartner: selectedMember,
  instructions,
  hasInstructions,
  instructionType,
}: SaveDelegationProps) => {
  if (!board.participants.length || selectedMember === SELECCIONAR_SOCIO) {
    return;
  }

  const currentParticipant = board.participants.find(
    (p) => p.member === partnerId
  );

  if (!currentParticipant) {
    return null;
  }

  const selectedRepresentative = board.participants.find(
    (p) => p.member === selectedMember
  );

  if (
    !selectedRepresentative &&
    selectedRole === boardDelegationRoles.PARTNER
  ) {
    return null;
  }

  if (selectedRole === boardDelegationRoles.PARTNER) {
    if (selectedRepresentative?.assists) {
      currentParticipant.assists = true;
      currentParticipant.isAbsent = false;
    } else if (selectedRepresentative?.isAbsent) {
      currentParticipant.assists = false;
      currentParticipant.isAbsent = true;
    }
  }

  if (selectedRole === boardDelegationRoles.PARTNER_REPRESENTANT) {
    currentParticipant.assists = true;
    currentParticipant.isAbsent = false;
  }

  currentParticipant.hasDelegated = false;
  currentParticipant.isRepresented = true;
  currentParticipant.representative = selectedMember;
  currentParticipant.hasPartnerRepresentant = !!(
    selectedRole === boardDelegationRoles.PARTNER_REPRESENTANT
  );

  const newInstructions =
    instructionType === instructionTypes.POINT_BY_POINT.value
      ? instructions
      : [];

  currentParticipant.delegationVote = hasInstructions
    ? { instructionType, instructions: newInstructions }
    : null;

  return currentParticipant;
};
