import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Cookies from 'js-cookie';

const Auth0ProviderWithDynamicConfig = ({ children }) => {
  const location = useLocation();

  const [authConfig, setAuthConfig] = useState(() => {
    const savedTenant = Cookies.get('authTenant');

    if (savedTenant === 'pwc') {
      return {
        domain: process.env.REACT_APP_AUTH0_DOMAIN_PWC,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID_PWC,
        redirectUri: window.location.origin,
      };
    }

    return {
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: window.location.origin,
    };
  });

  useEffect(() => {
    const { hash } = location;

    if (hash.includes('/pwc-login')) {
      console.log('PWC Space detected', window.location.origin); // eslint-disable-line no-console
      Cookies.set('authTenant', 'pwc', { secure: true, sameSite: 'strict' });
      setAuthConfig({
        domain: process.env.REACT_APP_AUTH0_DOMAIN_PWC,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID_PWC,
        redirectUri: `${window.location.origin}`,
      });
    } else if (hash.includes('/login')) {
      console.log('STTOK Space detected'); // eslint-disable-line no-console
      Cookies.set('authTenant', 'default', {
        secure: true,
        sameSite: 'strict',
      });

      setAuthConfig({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirectUri: `${window.location.origin}`,
      });
    }
  }, [location.hash]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Auth0Provider
      key={authConfig.domain}
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      redirectUri={authConfig.redirectUri}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithDynamicConfig;
