import cancelationTypes from 'constants/cancelationTypes';
import userTypes from 'constants/userTypes';
import { tenderParticipantStatusTypes } from 'utils/tenderOfferTypes';

const isPlanStartDateBeforeEndDate = (beneficiary, endDate) =>
  new Date(beneficiary.planStartDate) < new Date(endDate);

const isCancelationDateBeforeEndDate = (beneficiary, endDate) =>
  beneficiary.isCanceled &&
  new Date(beneficiary?.cancelationDate) < new Date(endDate);

const isOperationDateBeforeEndDate = (operationDate, endDate) =>
  new Date(operationDate) < new Date(endDate);

const isFullCancelled = (beneficiary) =>
  beneficiary?.isCanceled &&
  beneficiary?.cancelationOption === cancelationTypes.FULL;

const hasParticipantAcceptedUnits = (participant) =>
  participant?.status === tenderParticipantStatusTypes.ACCEPTED.value &&
  participant?.totalAccepted;

export function getBeneficiariesSummary({
  beneficiaries,
  plans,
  consolidationPeriods,
  tenderOffers,
  endDate,
  hasCif = false,
}) {
  if (!plans.length || !beneficiaries.length) return null;

  const summary = beneficiaries.reduce((acc, beneficiary) => {
    acc[beneficiary['_id']] = {
      assigned: 0,
      vested: 0,
      canceled: 0,
      acquired: 0,
    };

    if (hasCif) {
      acc[beneficiary['_id']].cif = beneficiary?.cif.toUpperCase() || '';
    }
    return acc;
  }, {});

  beneficiaries.forEach((beneficiary) => {
    let currentAssigned = 0;
    let lastDate = new Date();

    if (isPlanStartDateBeforeEndDate(beneficiary, endDate)) {
      if (summary[beneficiary['_id']]) {
        currentAssigned = beneficiary?.sharesCount?.future || 0;
        lastDate = new Date(beneficiary.planStartDate);
      }
    }

    if (isCancelationDateBeforeEndDate(beneficiary, endDate)) {
      if (summary[beneficiary['_id']]) {
        currentAssigned = beneficiary?.finalConsolidatedUnits || 0;
        lastDate = new Date(beneficiary?.cancelationDate);
        summary[beneficiary['_id']].canceled =
          beneficiary?.sharesCount?.future -
            beneficiary?.finalConsolidatedUnits || 0;
      }
    }

    const beneficiaryConsolidationPeriods =
      consolidationPeriods[beneficiary['_id']] || [];

    beneficiaryConsolidationPeriods.forEach((period) => {
      const { date, vestedUnits } = period;
      if (new Date(date) < new Date(endDate)) {
        if (summary[beneficiary['_id']]) {
          summary[beneficiary['_id']].vested += isFullCancelled(beneficiary)
            ? 0
            : vestedUnits || 0;
        }
      }
    });

    const beneficiaryTenders = beneficiary.soldUnits || [];

    const tenders = tenderOffers.filter((tender) =>
      beneficiaryTenders.includes(tender['_id'])
    );

    tenders.forEach((tender) => {
      const participants = tender.participants.filter(
        (participant) => participant.id === beneficiary['_id']
      );
      participants.forEach((participant) => {
        const { type, totalAccepted, operationDate } = participant;

        const isValidParticipant =
          hasParticipantAcceptedUnits(participant) &&
          isOperationDateBeforeEndDate(operationDate, endDate);

        if (type !== userTypes.BENEFICIARY || !isValidParticipant) return;

        if (summary[beneficiary['_id']]) {
          summary[beneficiary['_id']].acquired += totalAccepted || 0;
          if (new Date(operationDate) > lastDate) {
            currentAssigned -= totalAccepted || 0;
          }
        }
      });
    });

    summary[beneficiary['_id']].assigned = currentAssigned;
  });

  return summary;
}
