/* eslint-disable no-restricted-syntax */
import { Board, BoardOrder, BoardParticipant, Society } from 'types';

import getParticipantInstruction from 'utils/getParticipantInstructions';
import getRepresentedPartnersIds from './getRepresentedPartnersIds';
import getPartner from './getPartner';
import createSelectorOption from './createSelectOption';
import getVoteValueInOrder from './getVoteValueInOrder';
import { Represented } from '../types/Represented';

type RepresentedProps = {
  partnerId: string;
  board: Board;
  order: BoardOrder;
  actualSociety: Society;
  withInstructions: boolean;
};

type CurrentRepresentedProps = {
  partnerId: string;
  board: Board;
  order: BoardOrder;
  actualSociety: Society;
};

function isParticipantIncluded(
  participant: BoardParticipant,
  representedPartnersIds: string[]
) {
  return representedPartnersIds?.includes(participant?.member);
}

function isParticipantCurrentType(
  participant: BoardParticipant,
  withInstructions: boolean
) {
  return withInstructions
    ? participant?.delegationVote?.instructionType
    : !participant?.delegationVote?.instructionType;
}

function isParticipantRepresented(
  participant: BoardParticipant,
  representedPartnersIds: string[],
  withInstructions: boolean
) {
  const includesParticipant = isParticipantIncluded(
    participant,
    representedPartnersIds
  );
  const isCurrentType = isParticipantCurrentType(participant, withInstructions);

  return includesParticipant && isCurrentType;
}

function getParticipantPartner(
  participant: BoardParticipant,
  actualSociety: Society
) {
  return getPartner(participant?.member, actualSociety);
}

export function getCurrentRepresented({
  partnerId,
  board,
  order,
  actualSociety,
}: CurrentRepresentedProps) {
  for (const participant of board?.participants) {
    const isCurrentParticipant = participant?.member === partnerId;

    if (isCurrentParticipant) {
      const partner = getParticipantPartner(participant, actualSociety);
      const vote = getVoteValueInOrder(participant, order);
      const instruction = getParticipantInstruction(participant, order);

      if (partner) {
        const option = createSelectorOption(partner, vote, instruction);
        return option;
      }
    }
  }
}

export function getRepresented({
  partnerId,
  board,
  order,
  actualSociety,
  withInstructions,
}: RepresentedProps) {
  const options: Represented[] = [];

  const representedPartnersIds = getRepresentedPartnersIds(board, partnerId);

  board?.participants?.forEach((participant: BoardParticipant) => {
    const isRepresented = isParticipantRepresented(
      participant,
      representedPartnersIds,
      withInstructions
    );

    if (isRepresented) {
      const partner = getParticipantPartner(participant, actualSociety);
      const vote = getVoteValueInOrder(participant, order);
      const instruction = getParticipantInstruction(participant, order);

      if (partner) {
        const option = createSelectorOption(partner, vote, instruction);
        options.push(option);
      }
    }
  });
  return options;
}
