/* eslint-disable react/jsx-curly-newline */
import { differenceInCalendarDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';
import { getDrafts } from 'modules/drafts/redux/draftActions';

import menuTypes from 'constants/menuTypes';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';
import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';
import {
  getActualSociety,
  getDecimalScale,
  getInvestorFDPercent,
  getInvestorFDPotential,
  getInvestorNDPercent,
  getSocietyValue,
} from 'utils/filters';

import { useTranslate } from 'hooks/useTranslate';
import tableColumns from './tableColumns';

function InvestorsList() {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, userId } = useParams();

  const { isAdmin, isReadOnly } = useGetPartnersSectionPermissions().data;

  const { user, actualSociety, drafts, plans } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    isInvestor: state.society?.role?.isInvestor,
    drafts: state?.drafts,
    plans: state?.plans,
    user: state?.user,
  }));

  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  useEffect(() => {
    if (societyId) dispatch(getDrafts(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getStockPlans(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] === societyId && drafts?.length) {
      const societyValue = getSocietyValue(actualSociety);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;
      const newData = actualSociety?.investors
        .filter((investor) =>
          userId || (!userId && !isAdmin)
            ? user['_id'] === investor?.user ||
              user?.usersWithAccess.some(
                (currentUser) => investor?.user === currentUser?.['_id']
              )
            : true
        )
        .map((investor, index) => {
          const currentDraft = drafts?.find(
            (draft) => draft['_id'] === investor.draft
          );
          let interestTotal = 0;
          if (currentDraft) {
            const daysBetween = differenceInCalendarDays(
              currentDraft.dueDate,
              investor.contributionDate
            );
            const interestDaily =
              (investor.contribution * (currentDraft.interestPercent / 100)) /
              365;
            interestTotal = +(interestDaily * daysBetween).toFixed(
              decimalScale
            );
          }
          return {
            index: index + 1,
            name: investor.name,
            contribution: investor?.realContribution || investor.contribution,
            contributionDate: investor.contributionDate,
            discountPercent: investor.discountPercent,
            interest: interestTotal,
            shares: investor?.sharesCount?.drafts,
            draftName: currentDraft?.name || '',
            decimals: decimalScale,
            NDPercent: getInvestorNDPercent(investor, actualSociety),
            FDPercent: getInvestorFDPercent(investor, actualSociety),
            FDPotential: getInvestorFDPotential(investor, actualSociety, plans),
            investor,
            society: actualSociety,
            societyId: actualSociety['_id'],
            hasLink: investor?.user === user['_id'] || isAdmin,
            isReadOnly,
          };
        });

      if (newData.length > 0) {
        setTableData(
          transformData({
            data: newData,
            columns: tableColumns(i18n),
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, drafts, plans, userId, i18n.language]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">{t('Investors')}</h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${actualSociety?.name} ${t('HaveInvestors', {
                      count: actualSociety?.investors?.length,
                    })}`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {tableData?.length > 0 ? (
            <>
              <CustomDataTable
                data={tableData}
                columns={currentTableColumns}
                searchBy={`${t('Investor')}`}
                defaultSortFieldId={6}
                defaultSortAsc={false}
                pagination
                className="nk-tb-list"
                expandableRows
                actions
                showDense
                showDownload
                fileName={`${t('Investors')} ${actualSociety.name}`}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default InvestorsList;
