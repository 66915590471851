export default {
  STOCK_OPTION: {
    value: 'STOCK_OPTIONS',
    label: 'Stock Options',
    initials: 'SO',
  },
  PHANTOM_SHARE: {
    value: 'PHANTOM_SHARES',
    label: 'Phantom Shares',
    initials: 'PS',
  },
  WARRANT: {
    value: 'WARRANTS',
    label: 'Warrants',
    initials: 'W',
  },
};
