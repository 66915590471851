/* eslint-disable react/react-in-jsx-scope */

import documentTypes from 'constants/documentTypes';
import { updateSociety } from 'modules/society/redux/societyActions';
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import { setModal } from 'modules/_shared/redux/modalActions';
import DownloadFolderModal from './DownloadFolderModal';
import ChangeFolderNameModal from './ChangeFolderNameModal';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-edit-alt',
      text: i18n.t('Rename'),
      action: ({ currentFolder, actualSociety, isReadOnly }) => {
        if (isReadOnly) return;

        store.dispatch(
          setModal(
            <ChangeFolderNameModal
              actualSociety={actualSociety}
              currentFolder={currentFolder}
            />
          )
        );
      },
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
    {
      icon: 'ni-file-pdf',
      text: i18n.t('Discharge'),
      action: async ({ documents, currentFolder }) => {
        const docs = documents
          ?.filter(
            (document) =>
              document?.category === documentTypes.MYFOLDERS &&
              document?.subcategory === currentFolder['_id']
          )
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        store.dispatch(
          setModal(
            <DownloadFolderModal
              documents={docs}
              currentFolder={currentFolder}
            />
          )
        );
      },
    },
    {
      icon: 'ni-trash-alt',
      text: i18n.t('Delete'),
      action: ({ currentFolder, actualSociety, isReadOnly }) => {
        if (isReadOnly) return;

        Swal.fire({
          icon: 'warning',
          title: `<h4 className="nk-block-title page-title">${i18n.t(
            'AreYouSureAboutDelFolder'
          )}  `,
          html: `<h3 class="text-primary fw-normal">${
            currentFolder.label
          } </h3><br /><div class="caption-text">${i18n.t(
            'ThisProcedureCantUndo'
          )}</div>`,
          confirmButtonText: i18n.t('OkDelete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            const newFoldersData = actualSociety?.myFolders.filter(
              (folder) => folder['_id'] !== currentFolder['_id']
            );
            store.dispatch(
              updateSociety(actualSociety['_id'], {
                myFolders: newFoldersData,
              })
            );
          }
        });
      },
      dontShowCurrent: ({ isReadOnly }) => isReadOnly,
    },
  ];
}
export default menuOptions;
