import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const CommitteesTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <thead>
      <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
        <th className="nk-tb-col">
          <span>{t('Name')}</span>
        </th>
        <th className="nk-tb-col tb-col-xl">
          <span>{t('Members')}</span>
        </th>
        <th className="nk-tb-col tb-col-xl">
          <span>{t('createdAt')}</span>
        </th>
      </tr>
    </thead>
  );
};

export default CommitteesTableHeader;
