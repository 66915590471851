import boardStatus from 'constants/boardStatus';
import { Board, User } from 'types';

type BoardMemberBadgeProps = {
  board: Board;
  user: User;
  memberId: string;
};

const badgeText = {
  PARTICIPANT: 'Participante',
  REPRESENTANT: 'Representante',
};

const getBadgeText = (isParticipant: boolean, isRepresentant: boolean) => {
  if (isParticipant) return badgeText.PARTICIPANT;
  if (isRepresentant) return badgeText.REPRESENTANT;
  return '';
};

const BoardMemberBadge = ({ board, user, memberId }: BoardMemberBadgeProps) => {
  const isParticipant = board.participants.some(
    (participant) => participant.member === memberId
  );

  const isRepresentant =
    board?.delegationRepresentants?.some((representant) =>
      user.partnerRepresentants.includes(representant)
    ) || false;

  const badgeClassName = `badge badge-pill badge-success`;
  const badgeText = getBadgeText(isParticipant, isRepresentant);

  return badgeText ? <span className={badgeClassName}>{badgeText}</span> : null;
};

export default BoardMemberBadge;
