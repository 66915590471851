/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getBeneficiaryData } from 'modules/beneficiaries/redux/beneficiaryActions';

import createCertificate from 'utils/createCertificate';

import documentTypes from 'constants/documentTypes';

import DocumentModal from 'modules/documents/pages/Documents/documentModal';

import { useGetPlanSectionPermissions } from 'modules/beneficiaries/hooks/useGetPlanSectionPermissions';
import BeneficiaryDocumentsRow from './BeneficiaryDocumentsRow';

const BeneficiaryDocuments = ({
  society,
  beneficiary,
  plan,
  documentsByPlan,
}) => {
  const dispatch = useDispatch();

  const { isAdmin, isReadOnly } = useGetPlanSectionPermissions().data;

  const user = useSelector((state) => state?.user);
  const [documents, setDocuments] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState({});

  const userId = user['_id'];
  const societyId = society?.societyId;
  const beneficiaryId = beneficiary['_id'];

  const getBeneficiaryDocuments = async () => {
    const beneficiaryData = await getBeneficiaryData({
      beneficiaryId,
      societyId,
      source: 'documents',
    });

    setDocuments(beneficiaryData?.documents);
  };

  const handleOnClickUpload = () => {
    if (isReadOnly) return;
    dispatch(
      setModal(
        <DocumentModal
          userId={userId}
          societyId={societyId}
          beneficiaryId={beneficiaryId}
        />
      )
    );
  };

  const handleClickCertificate = () => {
    if (isReadOnly) return;
    createCertificate(
      {
        certificateData: {
          societyData: society,
          beneficiaryData: beneficiary,
          planData: plan,
        },
      },
      beneficiary['_id']
    );
  };

  const hasDocumentsAllowed = (plan) => {
    let hasDocuments = false;

    plan.documents.forEach((document) => {
      if (filteredDocuments[document]) {
        hasDocuments = true;
      }
    });

    return hasDocuments;
  };

  useEffect(() => {
    const documentsAllowed = {};

    documents
      ?.filter(
        (currentDoc) =>
          !currentDoc.isForAdmins &&
          currentDoc.category !== documentTypes.DELETED
      )
      .forEach((document) => {
        documentsAllowed[document._id] = document;
      });

    setFilteredDocuments(documentsAllowed);
  }, [documents]);

  useEffect(() => {
    getBeneficiaryDocuments();
  }, []); // eslint-disable-line

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-between g-3 d-flex flex-column flex-md-row">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('IncentivePlanDocumentatio')}</h4>
            <div className="nk-block-des">
              <p>
                {t('DocumentationOfTheIncenti', {
                  societyName: society?.name || '',
                })}
              </p>
            </div>
          </div>

          <div className="nk-block-head-content">
            {isAdmin && (
              <div className="nk-block float-right">
                <button
                  type="button"
                  className="btn btn-primary col-12 my-1"
                  onClick={handleOnClickUpload}
                  disabled={isReadOnly}
                >
                  <em className="icon ni ni-upload mr-1" />
                  {t('UpDocument')}
                </button>

                {!beneficiary?.isDraft && (
                  <button
                    type="button"
                    className="btn btn-primary col-12 my-1"
                    onClick={handleClickCertificate}
                    disabled={isReadOnly}
                  >
                    <em className="icon ni ni-reports-alt mr-1" />
                    {t('ParticipationCertificate')}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {Object.keys(documentsByPlan).length > 0 &&
        Object.values(documentsByPlan)?.map(
          (plan) =>
            hasDocumentsAllowed(plan) && (
              <div className="nk-block nk-block-lg">
                <h6>{plan.planName}</h6>
                <div className="card card-bordered card-preview">
                  <table className="nk-tb-list nk-tb-ulist">
                    <thead>
                      <tr className="nk-tb-item nk-tb-head">
                        <th
                          className="nk-tb-col d-none d-md-table-cell"
                          scope="col"
                        >
                          {t('Nr')}
                        </th>
                        <th className="nk-tb-col" scope="col">
                          {t('Name')}
                        </th>
                        <th
                          className="nk-tb-col d-none d-md-table-cell"
                          scope="col"
                        >
                          {t('Date')}
                        </th>
                        <th className="nk-tb-col" scope="col">
                          {t('Doc')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {plan?.documents?.map((document, index) =>
                        filteredDocuments[document] ? (
                          <BeneficiaryDocumentsRow
                            document={filteredDocuments[document]}
                            index={index + 1}
                            key={document}
                          />
                        ) : (
                          <></>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )
        )}
    </div>
  );
};

export default BeneficiaryDocuments;
