import { FC } from 'react';
import { Link } from 'react-router-dom';

import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';

type Props = {
  id: string;
  name: string;
  email: string;
  image?: string;
  societyId?: string;
  haveStatus: boolean;
  haveLink: boolean;
};

const PartnerAvatarInfo: FC<Props> = ({
  id,
  name,
  email,
  image,
  societyId,
  haveStatus,
  haveLink,
}) => (
  <div className="user-card">
    <div className="user-avatar bg-dim-primary d-flex ">
      {image ? (
        <img src={image} alt={name || ''} className="rounded-circle" />
      ) : (
        <img
          src={BLANK_PROFILE_PICTURE}
          alt={name || ''}
          className="rounded-circle"
        />
      )}
      {haveStatus && <div className="status dot dot-lg dot-success" />}
    </div>

    <div className="user-info">
      {haveLink ? (
        <Link to={`/detalle-socio/${societyId}/${id}`}>
          <span className="text-primary">{name || ''}</span>
        </Link>
      ) : (
        <>
          <span className="text-primary">{name || ''}</span>
          <span className="text-primary">{haveLink || ''}</span>
        </>
      )}
      <span className="d-block text-ellipsis">{email}</span>
    </div>
  </div>
);

export default PartnerAvatarInfo;
