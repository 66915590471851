/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { Doughnut } from 'react-chartjs-2';
import { Tooltip } from 'react-tooltip';
import { votingResultsChartOptions } from 'utils/charts';

function OrderRow({
  order,
  votingResult,
  chartData,
  orderVotesData,
  totalPercent,
  setSelectedOrder,
}) {
  const { t } = useTranslate();

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col w-40 py-1">
        <Tooltip id={order['_id']} place="left" className="text-center z-1000">
          {t('Result')}:
          <br />
          <b>{votingResult.text}</b>
        </Tooltip>

        <div
          data-tooltip-id={order['_id']}
          className="d-flex flex-row justify-content-start align-items-center cursor-pointer"
          onClick={() => setSelectedOrder(order['_id'])}
        >
          <div
            className={`nk-activity-media user-avatar bg-${votingResult.style} mr-2 cursor-pointer`}
          >
            <em className={`${votingResult.icon} fs-22px`} />
          </div>

          <span className="tb-lead">{order.subject}</span>
        </div>
      </td>

      <td className="nk-tb-col tb-col-xl text-right w-10 py-1">
        {totalPercent > 0 ? (
          <span className="tb-sub">{orderVotesData?.favor?.toFixed(2)}%</span>
        ) : (
          <>-</>
        )}
      </td>

      <td className="nk-tb-col tb-col-xl text-right w-10 py-1">
        {totalPercent ? (
          <span className="tb-sub">{orderVotesData?.against?.toFixed(2)}%</span>
        ) : (
          <>-</>
        )}
      </td>

      <td className="nk-tb-col tb-col-xl text-right w-10 py-1">
        {totalPercent ? (
          <span className="tb-sub">
            {orderVotesData?.abstention?.toFixed(2)}%
          </span>
        ) : (
          <>-</>
        )}
      </td>

      <td className="nk-tb-col tb-col-md text-right w-20 pt-4 pb-0">
        {totalPercent ? (
          <>
            <div className="progress progress-md bg-light">
              <div
                className="progress-bar"
                data-progress={totalPercent}
                style={{
                  width: `${totalPercent}%`,
                }}
              />
            </div>
            <span className="tb-sub">{totalPercent?.toFixed(2)}%</span>
          </>
        ) : (
          <></>
        )}
      </td>

      <td className="nk-tb-col text-center w-10 py-1">
        {totalPercent ? (
          <Doughnut data={chartData} options={votingResultsChartOptions} />
        ) : (
          <>-</>
        )}
      </td>
    </tr>
  );
}
export default OrderRow;
