/* eslint-disable react/no-array-index-key */

import filterValue from './filterValue';

type PartnerBookTableBodyProps = {
  createPartnersArray: any;
  bookPartners: any;
  bookOrder: any;
  bookColumns: any;
  isForScreen: any;
  bookOperations: any;
};

const getPartnerClassName = (index: number, bookOrder: any) => {
  if (bookOrder[index] % 2 === 0) {
    if (index === 0) {
      return 'bg-white text-right d-block text-ellipsis';
    }
    return 'bg-light-vert text-right';
  }
  if (index === 0) {
    return 'text-right d-block text-ellipsis';
  }
  return 'text-right';
};

const PartnerBookTableBody = ({
  createPartnersArray,
  bookOperations,
  bookPartners,
  bookColumns,
  bookOrder,
  isForScreen,
}: PartnerBookTableBodyProps) => (
  <tbody>
    {createPartnersArray(bookPartners)?.map(
      (partner: any, partnerIndex: number) => (
        <tr
          key={`${partner?.id}-${partnerIndex}`}
          className={!(partnerIndex % 2 === 0) ? 'bg-light' : 'bg-white'}
        >
          {partner.data?.map((data: any, index: number) => (
            <td
              key={`${partner?.id}-${partnerIndex}-${index}`}
              className={getPartnerClassName(index, bookOrder)}
            >
              {filterValue({
                partnerIndex,
                index,
                data: partner.data[index],
                column: bookColumns[index],
                isForScreen,
                bookPartners,
                bookOperations,
              })}
            </td>
          ))}
        </tr>
      )
    )}
  </tbody>
);

export default PartnerBookTableBody;
