import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';
import { FC, useState } from 'react';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';

import { createCommitteeMember } from 'modules/administratives/redux/committeeMemberActions';
import AddCommitteeMemberModalHeader from './AddCommitteeMemberModalHeader';
import AddCommitteeMemberModalBody from './AddCommitteeMemberModalBody';
import AddCommitteeMemberModalFooter from './AddCommitteeMemberModalFooter';

type Props = {
  committeeId: string;
  getUpdatedCommittee: () => void;
};

const AddCommitteeMemberModal: FC<Props> = ({
  committeeId,
  getUpdatedCommittee,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [name, setName] = useState<string>('');
  const [cif, setCif] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [nationality, setNationality] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [memberStatus, setMemberStatus] = useState<string>('');
  const [memberType, setMemberType] = useState<string>('');
  const [condition, setCondition] = useState<string>('');
  const [isNaturalPerson, setIsNaturalPerson] = useState<boolean>(true);
  const [image, setImage] = useState<any>(null);
  const [files, setFiles] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handelChangeName = (event: any) => {
    setName(event.target.value);
  };

  const handelChangeCif = (event: any) => {
    setCif(event.target.value);
  };

  const handelChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handelChangeAddress = (event: any) => {
    setAddress(event.target.value);
  };

  const handelChangeStartDate = (event: any) => {
    setStartDate(event.target.value);
  };

  const handelChangeEndDate = (event: any) => {
    setEndDate(event.target.value);
  };

  const handelChangeNationality = (event: any) => {
    setNationality(event.target.value);
  };

  const handelChangeMemberStatus = (option: any) => {
    setMemberStatus(option.value);
  };

  const handelChangeMemberType = (option: any) => {
    setMemberType(option.value);
  };

  const handelChangeCondition = (option: any) => {
    setCondition(option.value);
  };

  const handelChangeIsNaturalPerson = () => {
    setIsNaturalPerson((prev) => !prev);
  };

  const handleChangeFiles = (event: any) => {
    setFiles(event.target.files);
  };

  const handleChangeBirthdate = (event: any) => {
    setBirthdate(event.target.value);
  };

  // TODO: Implement imageupload restriction
  const handleChangeImage = async (event: any) => {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = e.target?.result || '';
        setImage(result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleSaveMember = async () => {
    try {
      setIsLoading(true);

      const newCommitteeMember = {
        name,
        cif,
        email,
        address,
        startDate: new Date(startDate),
        endDate: endDate ? new Date(endDate) : undefined,
        birthdate: birthdate ? new Date(birthdate) : undefined,
        image,
        nationality,
        memberStatus,
        memberType,
        condition,
        isNaturalPerson,
        // documents,
        committee: committeeId,
      };
      await store.dispatch(createCommitteeMember(newCommitteeMember));

      await getUpdatedCommittee();

      successSwal(t('MemberAddedSuccessfully'));
    } catch (error) {
      errorSwal(t('ErrorAddingMember'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <AddCommitteeMemberModalHeader handleCloseModal={handleCloseModal} />

      <AddCommitteeMemberModalBody
        name={name}
        cif={cif}
        email={email}
        address={address}
        startDate={startDate}
        endDate={endDate}
        nationality={nationality}
        isNaturalPerson={isNaturalPerson}
        image={image}
        birthdate={birthdate}
        handleChangeName={handelChangeName}
        handleChangeCif={handelChangeCif}
        handleChangeEmail={handelChangeEmail}
        handleChangeAddress={handelChangeAddress}
        handleChangeStartDate={handelChangeStartDate}
        handleChangeEndDate={handelChangeEndDate}
        handleChangeNationality={handelChangeNationality}
        handleChangeMemberStatus={handelChangeMemberStatus}
        handleChangeMemberType={handelChangeMemberType}
        handleChangeCondition={handelChangeCondition}
        handleChangeIsNaturalPerson={handelChangeIsNaturalPerson}
        handleChangeImage={handleChangeImage}
        handleChangeBirthdate={handleChangeBirthdate}
      />

      <AddCommitteeMemberModalFooter
        name={name}
        cif={cif}
        email={email}
        startDate={startDate}
        memberStatus={memberStatus}
        memberType={memberType}
        condition={condition}
        handleSaveMember={handleSaveMember}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default AddCommitteeMemberModal;
