import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(
  communications = initialState.communications,
  action
) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.communications;

    case actionTypes.CREATE_STOCK_COMMUNICATION:
      return [...communications, action.communication];

    case actionTypes.GET_STOCK_COMMUNICATIONS:
      return action.communications;

    case actionTypes.UPDATE_STOCK_COMMUNICATION:
      return [
        ...communications.filter(
          (comm) => comm['_id'] !== action.communication['_id']
        ),
        action.communication,
      ];

    case actionTypes.DELETE_STOCK_COMMUNICATION:
      return [
        ...communications.filter(
          (currentCommunication) =>
            currentCommunication['_id'] !== action.communication['_id']
        ),
      ];

    default:
      return communications;
  }
}
