import { STTOK_WEBSITE } from 'constants/defaultConstants';
import { useTranslate } from 'hooks/useTranslate';

type DelegationPartnerFooterProps = {
  hasWhiteBranding: boolean;
};

const DelegationPartnerFooter = ({
  hasWhiteBranding,
}: DelegationPartnerFooterProps) => {
  const { t } = useTranslate();

  return hasWhiteBranding ? (
    <ul className="nav justify-center gx-4">
      <li className="nav-item">{t('BoardManagedWithSttok')}</li>
    </ul>
  ) : (
    <>
      <div className="form-note-s2 text-center pt-4">
        &nbsp;
        {t('IfDoNotWantToDelegateCanLeave')}
      </div>

      <ul className="nav justify-center gx-4">
        <li className="nav-item">
          <a className="nav-link" href={STTOK_WEBSITE}>
            Sttok
          </a>
        </li>
      </ul>
    </>
  );
};

export default DelegationPartnerFooter;
