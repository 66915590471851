import userTypes from 'constants/userTypes';
import { BoardParticipant } from 'types';

export const mapParticipantsForSaving = (
  addAssistantsAbsent: boolean,
  partners: BoardParticipant[]
) =>
  partners.map((partner: BoardParticipant) => ({
    member: partner.member,
    name: partner.name || '',
    email: partner.email || '',
    userType: userTypes.PARTNER,
    assists: partner?.assists,
    isAbsent: !partner?.assists && addAssistantsAbsent,
    isRepresented: partner?.isRepresented && partner?.representative !== '',
    representative: partner?.isRepresented ? partner?.representative : null,
    hasDelegated: partner?.hasDelegated,
    hasPartnerRepresentant: partner?.hasPartnerRepresentant || false,
    delegationVote: partner?.delegationVote,
    delegationDocuments: partner?.delegationDocuments,
    votes: partner?.votes || [],
  }));
