import { ChangeEvent } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { BoardOrder } from 'types';

import instructionTypes from 'constants/instructionTypes';
import voteTypes from 'constants/voteTypes';
import voteValues from 'constants/voteValues';
import DelegationPartnerVoteOption from './DelegationPartnerVoteOption';
import DelegationPartnerInstructionOption from './DelegationPartnerInstructionOption';

type DelegationPartnerInstructionsProps = {
  orders: BoardOrder[];
  instructions: Record<string, any>[];
  instructionType: string;
  onSaveInstruction: (vote: string, index: number) => void; // eslint-disable-line
  onChangeInstructionType: (instructionType: string) => void; // eslint-disable-line
};

const DelegationPartnerInstructions = ({
  orders,
  instructions,
  instructionType,
  onSaveInstruction,
  onChangeInstructionType,
}: DelegationPartnerInstructionsProps) => {
  const { t } = useTranslate();

  const handleChangeInstructionType = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    onChangeInstructionType(event.target.value);
  };

  const handleSaveInstruction = (vote: string, index: number) => {
    onSaveInstruction(vote, index);
  };

  return (
    <>
      <div className="form-group mt-3">
        <label className="form-label" htmlFor="full-name">
          {t('SelectHowTheDelegateVote')}
        </label>
        <div className="row">
          <DelegationPartnerInstructionOption
            id="btnRadio1"
            value={instructionTypes.ALL_YES.value}
            checked={instructionType === instructionTypes.ALL_YES.value}
            label={t('AllYes')}
            onChange={handleChangeInstructionType}
          />
          <DelegationPartnerInstructionOption
            id="btnRadio2"
            value={instructionTypes.ALL_NO.value}
            checked={instructionType === instructionTypes.ALL_NO.value}
            label={t('AllNo')}
            onChange={handleChangeInstructionType}
          />
          <DelegationPartnerInstructionOption
            id="btnRadio3"
            value={instructionTypes.ALL_ABS.value}
            checked={instructionType === instructionTypes.ALL_ABS.value}
            label={t('AllAbs')}
            onChange={handleChangeInstructionType}
          />
          <DelegationPartnerInstructionOption
            id="btnRadio4"
            value={instructionTypes.POINT_BY_POINT.value}
            checked={instructionType === instructionTypes.POINT_BY_POINT.value}
            label={t('PointByPoint')}
            onChange={handleChangeInstructionType}
          />
        </div>
      </div>

      {instructionType === instructionTypes.POINT_BY_POINT.value &&
      orders.length ? (
        <>
          <label className="form-label mt-2" htmlFor="full-name">
            {t('SetOneVoteForEachPoint')}
          </label>

          {orders.map((order, index) => (
            <div
              key={order['_id']}
              className="card-inner card-bordered mt-2 round-lg"
            >
              <span className="p-2">{`${index + 1}. ${order.subject}`}</span>

              {order.voteType === voteTypes.YES_NO_ABS.value && (
                <div className="form-group p-2 mt-1">
                  <div className="row">
                    <DelegationPartnerVoteOption
                      orderId={order['_id']}
                      voteValue={voteValues.YES.value}
                      checked={
                        instructions[index].vote === voteValues.YES.value
                      }
                      label={t('Yes')}
                      onChange={(event) =>
                        handleSaveInstruction(event.target.value, index)
                      }
                    />
                    <DelegationPartnerVoteOption
                      orderId={order['_id']}
                      voteValue={voteValues.NO.value}
                      checked={instructions[index].vote === voteValues.NO.value}
                      label={t('Nope')}
                      onChange={(event) =>
                        handleSaveInstruction(event.target.value, index)
                      }
                    />
                    <DelegationPartnerVoteOption
                      orderId={order['_id']}
                      voteValue={voteValues.ABS.value}
                      checked={
                        instructions[index].vote === voteValues.ABS.value
                      }
                      label={t('Abstention')}
                      onChange={(event) =>
                        handleSaveInstruction(event.target.value, index)
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </>
      ) : null}
    </>
  );
};

export default DelegationPartnerInstructions;
