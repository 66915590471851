import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import downloadFolder from 'utils/downloadFolder';
import downloadMerged from 'utils/downloadMergedPDF';

function DownloadFolderModal({ documents, currentFolder }) {
  const { t } = useTranslate();

  const [splitDocument, setSplitDocument] = useState(false);
  const [mergedDocument, setMergedDocument] = useState(false);

  async function downloadDocuments() {
    if (splitDocument) {
      await downloadFolder({ documents, currentFolder });
    }
    if (mergedDocument) {
      await downloadMerged({ documents, currentFolder });
    }
    store.dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'FolderDownloadedSuccessfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  }
  return (
    <>
      <Modal.Header>
        <h5>{t('DownloadFolder')}</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={downloadDocuments}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('HowToDownloadFolder')}
            </label>
            <div className="form-control-wrap"> </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="custom-control custom-radio">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="split-document"
                  id="split-document"
                  value={splitDocument}
                  checked={splitDocument}
                  onChange={() => setSplitDocument(!splitDocument)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="split-document"
                >
                  {t('Separately')}
                </label>
              </div>
            </div>

            <div className="col-4">
              <div className="custom-control custom-radio">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="merged-document"
                  id="merged-document"
                  value={mergedDocument}
                  checked={mergedDocument}
                  onChange={() => setMergedDocument(!mergedDocument)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="merged-document"
                >
                  {t('InASingleDoc')}
                </label>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!splitDocument && !mergedDocument}
          >
            {t('Download')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default DownloadFolderModal;
