import periods from './periods';

export default [
  {
    name: 'Diariamente',
    value: periods.DAILY,
  },
  {
    name: 'Mensualmente',
    value: periods.MONTHLY,
  },
  {
    name: 'Trimestralmente',
    value: periods.QUARTERLY,
  },
  {
    name: 'Semestralmente',
    value: periods.SEMI_ANNUALLY,
  },
  {
    name: 'Anualmente',
    value: periods.ANNUALLY,
  }
];
