import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';

import { CoOwner } from 'types';

import ShareEncumbranceViewCoOwnersTable from './ShareEncumbranceViewCoOwnersTable';
import ShareEncumbranceViewCoOwnersForm from './ShareEncumbranceViewCoOwnersForm';

type Props = {
  coOwners: CoOwner[];
  // eslint-disable-next-line no-unused-vars
  handleAddCoOwner: (coOwner: CoOwner) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveCoOwner: (index: number) => void;
};

const ShareEncumbranceViewCoOwners: FC<Props> = ({
  coOwners,
  handleAddCoOwner,
  handleRemoveCoOwner,
}) => {
  const { t } = useTranslate();

  const [expandedView, setExpandedView] = useState<boolean>(false);

  const handleChangeView = () => {
    setExpandedView(!expandedView);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <h6>{t('CoOwners')}</h6>
        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="badge btn-primary"
            onClick={handleChangeView}
          >
            <em
              className={`icon ni ni-chevron-${expandedView ? 'up' : 'down'}`}
            />
          </button>
        </div>
      </div>

      {expandedView && (
        <>
          <ShareEncumbranceViewCoOwnersForm
            coOwners={coOwners}
            handleAddCoOwner={handleAddCoOwner}
          />

          {coOwners?.length > 0 && (
            <ShareEncumbranceViewCoOwnersTable
              coOwnersList={coOwners}
              handleRemoveCoOwner={handleRemoveCoOwner}
            />
          )}
        </>
      )}
    </>
  );
};

export default ShareEncumbranceViewCoOwners;
