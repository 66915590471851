/* eslint-disable no-nested-ternary */
import { useTranslate } from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';
import { formatNumber } from 'utils/formats';

import { hasInternalView } from 'utils/showDetails';

import '../PartnerBook.scss';

function PlanSummaryMainTable({ view, summary, plansNames }) {
  const { t } = useTranslate();

  if (hasInternalView()) {
    console.log('cclog > PlanSummaryMainTable');
  }

  return (
    summary && (
      <div className={view === 2 ? 'nk-block' : 'd-none'}>
        <div className="card card-bordered">
          <div className="table-responsive">
            <table
              className="table table-striped text-right border-right partner-book-table"
              id="summaryTable"
            >
              <tr className="text-center table-header">
                <th className="w-200px border-right">{t('Plans')}</th>
                <th className="no-break table-subheader border-right">
                  {t('TotalUnits')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('AssignedUnits')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('Available')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('Vested')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('CanceledUnits')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('Acquired')}
                </th>
              </tr>

              {Object.keys(summary.phantoms)?.map((planId, index) => (
                <>
                  <tr>
                    <td
                      id={planId}
                      className="text-left w-200px text-ellipsis border-right"
                    >
                      {plansNames[planId]}
                      <Tooltip anchorId={planId} place="bottom">
                        {plansNames[planId]}
                      </Tooltip>
                    </td>
                    {Object.values(summary.phantoms[planId]).map((value) => (
                      <td
                        className={
                          index === 0
                            ? 'text-right text-ellipsis border-right'
                            : 'text-right border-right'
                        }
                      >
                        {formatNumber(value)}
                      </td>
                    ))}
                  </tr>
                </>
              ))}

              {Object.keys(summary.stockOptions)?.map((planId, index) => (
                <>
                  <tr>
                    <td
                      id={planId}
                      className="text-left w-200px text-ellipsis border-right"
                    >
                      {plansNames[planId]}
                      <Tooltip anchorId={planId} place="bottom">
                        {plansNames[planId]}
                      </Tooltip>
                    </td>
                    {Object.values(summary.stockOptions[planId]).map(
                      (value) => (
                        <td
                          className={
                            index === 0
                              ? 'text-right text-ellipsis border-right'
                              : 'text-right border-right'
                          }
                        >
                          {formatNumber(value)}
                        </td>
                      )
                    )}
                  </tr>
                </>
              ))}

              {Object.keys(summary.warrants)?.map((planId, index) => (
                <>
                  <tr>
                    <td
                      id={planId}
                      className="text-left w-200px text-ellipsis border-right"
                    >
                      {plansNames[planId]}
                      <Tooltip anchorId={planId} place="bottom">
                        {plansNames[planId]}
                      </Tooltip>
                    </td>
                    {Object.values(summary.warrants[planId]).map((value) => (
                      <td
                        className={
                          index === 0
                            ? 'text-right text-ellipsis border-right'
                            : 'text-right border-right'
                        }
                      >
                        {formatNumber(value)}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </table>
          </div>
        </div>
      </div>
    )
  );
}

export default PlanSummaryMainTable;
