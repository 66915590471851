/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { useTranslate } from 'hooks/useTranslate';

import {
  getEmployeeAddress,
  getEmployeeEmail,
  getEmployeeFullName,
  getEmployeeNationality,
  getEmployeePhoneNumber,
} from 'utils/employees';

function EmployeePersonal({ employee, incorporationDate }) {
  const { t } = useTranslate();

  const fullName = getEmployeeFullName(employee);
  const address = getEmployeeAddress(employee);
  const phoneNumber = getEmployeePhoneNumber(employee);
  const email = getEmployeeEmail(employee);
  const nationality = getEmployeeNationality(employee);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <h4 className="title">{t('PersonalInformation')}</h4>
        <p>{t('BasicPersonalInformationEmployee')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('FullName')}</span>
          <span className="profile-ud-value text-left">{fullName || '-'}</span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value text-left">
            {employee?.cif || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Telephone')}</span>
          <span className="profile-ud-value text-left">
            {phoneNumber || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value text-left">{address}</span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Email')}</span>
          <span className="profile-ud-value text-left">
            <div className="d-block">{email || '-'}</div>
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {t('IncoporationDate')}
          </span>
          <span className="profile-ud-value text-left">
            {incorporationDate || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value text-left">
            {nationality || '-'}
          </span>
        </div>
      </div>
    </div>
  );
}

export default EmployeePersonal;
