/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import { BoardParticipant } from 'types';
import { cleanValue } from 'constants/formats';

import { getActualSociety } from 'utils/filters';
import { hasShowDetails } from 'utils/showDetails';

import getAssignedAndTotalShares from './utils/getAssignedAndTotalShares';
import getPriceByPlan from './utils/getPriceByPlan';
import BeneficiarySellInvoiceTable from './components/BeneficiarySellInvoiceTable';
import BeneficiarySellInvoiceHeader from './components/BeneficiarySellInvoiceHeader';

type Props = {
  participant: BoardParticipant;
  society: Record<string, any>;
  societyValue: Record<string, any>;
  beneficiaryAllPlans: any;
};

const BeneficiarySellInvoiceView = (props: Props) => {
  const { participant, beneficiaryAllPlans, society, societyValue } = props;

  const printRef = useRef<HTMLInputElement | null>(null);

  const { societyId } = society;

  const [simulatorLastSocietyValue] = useState(societyValue?.value || 0);
  const [simulatorSocietyValue, setSimulatorSocietyValue] = useState(
    societyValue?.value || 0
  );
  const [simulatorRetention, setSimulatorRetention] = useState(0);
  const [simulatorSharePriceUnit, setSimulatorSharePriceUnit] = useState(0);
  const [summaryShares, setSummaryShares] = useState({
    societyShares: 0,
    assignedShares: 0,
    sharesTotal: 0,
  });
  const [simulatorSubtotal, setSimulatorSubtotal] = useState(0);
  const [simulatorRetentionTotal, setSimulatorRetentionTotal] = useState(0);
  const [simulatorTotal, setSimulatorTotal] = useState(0);
  const [simulatorIncludeAllUnits, setSimulatorIncludeAllUnits] =
    useState(false);

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const tenderOffers = useSelector((state: State) => state.tenderOffers);

  const handleChangeSimulatorSocietyValue = (e: any) => {
    setSimulatorSocietyValue(e.target.value);
  };

  const handleChangeSimulatorRetention = (e: any) => {
    setSimulatorRetention(e.target.value);
  };

  const handleChangeIncludeAllUnits = () => {
    setSimulatorIncludeAllUnits(!simulatorIncludeAllUnits);
  };

  const getFinalPriceByPlan = (
    beneficiaryAllPlans: any,
    simulatorSharePriceUnit: any,
    simulatorRetention: any,
    simulatorIncludeAllUnits: boolean
  ) => {
    let total = 0;
    beneficiaryAllPlans?.forEach((beneficiaryPlan: any) => {
      total += getPriceByPlan(
        beneficiaryPlan,
        simulatorSharePriceUnit,
        simulatorIncludeAllUnits
      );
    });
    return total;
  };

  useEffect(() => {
    if (beneficiaryAllPlans && actualSociety?.beneficiaries?.length) {
      const currentSocietyPlans = beneficiaryAllPlans?.map(
        (beneficiaryPlan: any) => ({ ...beneficiaryPlan?.planData })
      );
      const currentSocietyTenderOffers = tenderOffers?.filter(
        (tenderOffer: any) => tenderOffer?.society === society?.societyId
      );

      if (hasShowDetails()) {
        // eslint-disable-next-line no-console
        console.log('cclog', {
          currentSocietyPlans,
          currentSocietyTenderOffers,
        });
      }

      setSummaryShares(
        getAssignedAndTotalShares(
          actualSociety,
          currentSocietyPlans,
          currentSocietyTenderOffers
        )
      );
    }
  }, [beneficiaryAllPlans, actualSociety]); // eslint-disable-line

  useEffect(() => {
    getActualSociety(user, societyId);
  }, [user, societyId]);

  useEffect(() => {
    setSimulatorSharePriceUnit(
      cleanValue(simulatorSocietyValue) / summaryShares?.societyShares
    );
  }, [simulatorSocietyValue, summaryShares?.societyShares]);

  useEffect(() => {
    const subtotal = getFinalPriceByPlan(
      beneficiaryAllPlans,
      cleanValue(simulatorSharePriceUnit),
      cleanValue(simulatorRetention),
      simulatorIncludeAllUnits
    );

    const retention = subtotal * (cleanValue(simulatorRetention) / 100);
    const total = subtotal - retention;

    setSimulatorSubtotal(subtotal);
    setSimulatorRetentionTotal(retention);
    setSimulatorTotal(total);
  }, [simulatorSharePriceUnit, simulatorRetention, simulatorIncludeAllUnits]); // eslint-disable-line

  if (hasShowDetails()) {
    console.log('cclog', {
      // eslint-disable-line
      beneficiaryAllPlans,
      society,
      summaryShares,
      simulatorSharePriceUnit,
      simulatorIncludeAllUnits,
    });
  }

  return (
    <div
      className="invoice-wrap p-0 border-0"
      id="margin-print-only"
      ref={printRef}
    >
      <BeneficiarySellInvoiceHeader
        actualSociety={actualSociety}
        participant={participant}
        printRef={printRef}
        simulatorSocietyValue={simulatorSocietyValue}
        simulatorRetention={simulatorRetention}
        simulatorIncludeAllUnits={simulatorIncludeAllUnits}
        simulatorLastSocietyValue={simulatorLastSocietyValue}
        onChangeSimulatorSocietyValue={handleChangeSimulatorSocietyValue}
        onChangeSimulatorRetention={handleChangeSimulatorRetention}
        onChangeIncludeAllUnits={handleChangeIncludeAllUnits}
      />

      <BeneficiarySellInvoiceTable
        beneficiaryAllPlans={beneficiaryAllPlans}
        simulatorSharePriceUnit={simulatorSharePriceUnit}
        simulatorIncludeAllUnits={simulatorIncludeAllUnits}
        simulatorSubtotal={simulatorSubtotal}
        simulatorRetentionTotal={simulatorRetentionTotal}
        simulatorTotal={simulatorTotal}
      />
    </div>
  );
};

export default BeneficiarySellInvoiceView;
