import { useTranslate } from 'hooks/useTranslate';

type Props = {
  bankAccountNumber: string;
  setBankAccountNumber: (value: string) => void; // eslint-disable-line no-unused-vars
  hideAccountNumber: boolean;
  handleHideAccountNumber: () => void;
  page: boolean;
};

const EditOtherInfoForm = ({
  bankAccountNumber,
  setBankAccountNumber,
  hideAccountNumber,
  handleHideAccountNumber,
  page,
}: Props) => {
  const { t } = useTranslate();

  const getMaskValue = (value: string): string => {
    if (!value) return '';
    return value?.replace(/.(?=.{4})/g, '*');
  };

  return (
    <div className={`tab-pane ${page && 'active'}`}>
      <div className="row gy-4">
        <div className="col-md-6">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="commercial-register">
              {t('BankAccountNumber')}
            </label>
            <div className="form-control-wrap">
              <div
                className="form-icon form-icon-right"
                onClick={handleHideAccountNumber}
              >
                {hideAccountNumber ? (
                  <em className="icon ni ni-eye cursor-pointer" />
                ) : (
                  <em className="icon ni ni-eye-off cursor-pointer" />
                )}
              </div>
              <input
                type="text"
                className="form-control form-control-lg"
                id="commercial-register"
                placeholder={t('EnterIBAN')}
                disabled={hideAccountNumber}
                value={
                  hideAccountNumber
                    ? getMaskValue(bankAccountNumber)
                    : bankAccountNumber
                }
                onChange={(event) => setBankAccountNumber(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOtherInfoForm;
