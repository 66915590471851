import { useEffect, useState } from 'react';

import { User } from 'types';

import societyAdministratorPermissions from '../constants/societyAdministratorPermissions';

type Props = {
  administrator: User;
  societyId: string;
};

const getAdminPermissions = ({ administrator, societyId }: Props) => {
  const societyPermissions = administrator.adminPermissions.find(
    (permission) => permission.society === societyId
  );

  if (societyPermissions) {
    const resultPermissions = societyAdministratorPermissions.map(
      (permission) => {
        const adminPermission =
          societyPermissions.permissions[
            permission.name as keyof typeof societyPermissions.permissions
          ];

        return {
          ...permission,
          checked: adminPermission.enabled,
          readOnly: adminPermission.readOnly,
        };
      }
    );
    return resultPermissions;
  }
  return societyAdministratorPermissions;
};

export const useGetAdminPermissions = ({ administrator, societyId }: Props) => {
  const [administratorPermissions, setAdministratorPermissions] = useState(
    societyAdministratorPermissions
  );

  useEffect(() => {
    if (administrator) {
      const permissions = getAdminPermissions({ administrator, societyId });
      setAdministratorPermissions(permissions);
    }
  }, [administrator, societyId]);

  return {
    data: { administratorPermissions },
    isLoading: false,
    isError: false,
    actions: { setAdministratorPermissions },
  };
};
