/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import menuTypes from 'constants/menuTypes';
import { getActualSociety } from 'utils/filters';

import { setMenu } from 'modules/_shared/redux/menuActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import RepresentantsListTable from 'modules/partners/components/RepresentantsListTable';
import RepresentantsListHeader from 'modules/partners/components/RepresentantsListHeader';
import { getPartnerRepresentants } from 'modules/partners/redux/partnerRepresentantsActions';

function RepresentantsList() {
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId && user)
      getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety]); // eslint-disable-line

  useEffect(() => {
    dispatch(getPartnerRepresentants({ societyId }));
  }, []); // eslint-disable-line

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <RepresentantsListHeader
            user={user}
            actualSociety={actualSociety}
            partnerRepresentants={partnerRepresentants}
          />

          {partnerRepresentants.length > 0 && (
            <RepresentantsListTable
              actualSociety={actualSociety}
              representants={partnerRepresentants}
            />
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default RepresentantsList;
