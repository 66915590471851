/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SaveTemplates from 'modules/_shared/components/Modals/SaveTemplates';
import { setModal } from 'modules/_shared/redux/modalActions';
import {
  getDocuments,
  updateDocument,
} from 'modules/documents/redux/documentActions';

import CKEditor from 'modules/_shared/components/CKEditor';
import { useGetDocumentsSectionPermissions } from 'modules/documents/hooks/useGetDocumentsSectionPermissions';

import './TemplateCreator.scss';
import TemplateCreatorHeader from './components/TemplateCreatorHeader';

const TemplateCreator = ({ document, fullScreen, handleToggleFullScreen }) => {
  const dispatch = useDispatch();

  const { isReadOnly } = useGetDocumentsSectionPermissions().data;
  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [editorInstance, setEditorInstance] = useState(null);

  const handleSave = () => {
    if (isReadOnly) return;

    const content = editorInstance?.getData();

    const newTemplate = {
      date: new Date(),
      lastAccess: new Date(),
      category: 'TEMPLATES',
      author: user['_id'],
      society: actualSociety['_id'],
      isGenerated: false,
      isFavorite: false,
      editorModel: content,
    };

    dispatch(setModal(<SaveTemplates data={newTemplate} />));
  };

  const handleUpdate = () => {
    if (isReadOnly) return;

    const content = editorInstance?.getData();

    const updatedTemplate = {
      ...document,
      lastAccess: new Date(),
      editorModel: content,
    };

    dispatch(updateDocument(updatedTemplate));
    dispatch(getDocuments({ society: actualSociety['_id'] }));
  };

  return (
    <>
      <div className="nk-fmg-body-content">
        <TemplateCreatorHeader
          documentName={document?.name}
          fullScreen={fullScreen}
          handleToggleFullScreen={handleToggleFullScreen}
          handleSave={handleSave}
          handleUpdate={handleUpdate}
          editorModel={document?.editorModel}
        />

        <div className="avoid-default-font">
          <CKEditor
            editorInstance={editorInstance}
            setEditorInstance={setEditorInstance}
            documentName={document?.name || 'export'}
            documentModel={document?.editorModel || ''}
            enableMentions
            fullScreen={fullScreen}
          />
        </div>
      </div>
    </>
  );
};

export default TemplateCreator;
