import { FC } from 'react';

import { BoardOrder } from 'types';

import SendVoteAutomaticView from './SendVoteAutomaticView';
import SendVoteManualView from './SendVoteManualView';

type Props = {
  order: BoardOrder;
  voteValue: string;
  voteStep: number;
  handleChangeVoteValue: any;
  handleExcecuteVote: () => void;
};

const SendVoteWizardController: FC<Props> = ({
  order,
  voteValue,
  voteStep,
  handleChangeVoteValue,
  handleExcecuteVote,
}) => (
  <div className="nk-stepper-step">
    {voteStep === 0 && (
      <>
        <ul className="row g-3">
          <li className="col-12">
            <SendVoteAutomaticView handleExcecuteVote={handleExcecuteVote} />
          </li>
        </ul>
      </>
    )}
    {voteStep === 1 && (
      <>
        <ul className="row g-3">
          <li className="col-12">
            <SendVoteManualView
              order={order}
              voteValue={voteValue}
              handleChangeVoteValue={handleChangeVoteValue}
            />
          </li>
        </ul>
      </>
    )}
    {voteStep === 2 && (
      <>
        <ul className="row g-3">
          <li className="col-12">
            <SendVoteManualView
              order={order}
              voteValue={voteValue}
              handleChangeVoteValue={handleChangeVoteValue}
            />
          </li>
        </ul>
      </>
    )}
  </div>
);

export default SendVoteWizardController;
