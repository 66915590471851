/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';

import { EMAIL_PATTERN, SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import documentLinkTypes from 'constants/documentLinkTypes';
import { getFullAddress } from 'utils/filters';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { updateSociety } from 'modules/society/redux/societyActions';
import removeAdminDeletedDocuments from 'utils/removeAdminDeletedDocuments';
import uploadAdminDocuments from 'utils/uploadAdminDocuments';

import UploadDocumentsSection from 'modules/documents/components/UploadDocumentsSection';
import directorTypesOptions from 'modules/society/components/SocietyDirectors/directorTypesOptions';
import statusTypes from 'modules/society/components/SocietyDirectors/statusTypes';
import statusTypesOptions from 'modules/society/components/SocietyDirectors/statusTypesOptions';

import { useTranslate } from 'hooks/useTranslate';
import ImageInput from 'modules/documents/components/ImageInput';
import RepresentativeSection from './RepresentativeSection';

function AddSocietyDirector({
  societyId,
  action,
  directorData,
  directorId,
  userId,
}) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const partners = useSelector(
    (state) => state.society?.actualSociety?.partners
  );

  const [socialDenomination, setSocialDenomination] = useState('');
  const [directorType, setDirectorType] = useState('');
  const [nationality, setNationality] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState([]);
  const [cif, setCif] = useState('');
  const [isPartnerAlready, setIsPartnerAlready] = useState(false);
  const animatedComponents = makeAnimated();
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [haveRepresentative, setHaveRepresentative] = useState(false);
  const [representative, setRepresentative] = useState({
    name: '',
    cif: '',
    email: '',
    address: '',
    nationality: '',
  });
  const [image, setImage] = useState(null);
  const [initialDocuments, setInitialDocuments] = useState([]);

  const [currDirectorTypesOptions, setCurrDirectorTypesOptions] = useState(
    directorTypesOptions(i18n)
  );
  const [currStatusTypesOptions, setCurrStatusTypesOptions] = useState(
    statusTypesOptions(i18n)
  );
  const [currStatusTypes, setCurrStatusTypes] = useState(statusTypes(i18n));

  const handleChangeImage = (event) => {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = e.target?.result || '';
        setImage(result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handlePartnerOptionChange = (selectedOption) => {
    const partner = selectedOption.value;
    setAddress(getFullAddress(partner?.address));
    setSocialDenomination(partner?.name);
    setNationality(partner?.nationality);
    setEmail(partner?.email);
    setCif(partner?.cif);

    if (!partner?.isNaturalPerson) {
      setHaveRepresentative(true);
      const partnerRepresentative = partner?.representative;
      setRepresentative({
        name: partnerRepresentative?.name,
        cif: partnerRepresentative?.nif,
        email: partnerRepresentative?.email,
        address: partnerRepresentative?.address,
        nationality: partnerRepresentative?.nationality,
      });
    }
  };

  const handleEditHaveRepresentative = (event) => {
    setHaveRepresentative(event.target.checked);
  };

  const getDirectorDocuments = async (directorId) => {
    await removeAdminDeletedDocuments(initialDocuments, files);
    const documents = await uploadAdminDocuments(
      files,
      userId,
      directorId,
      societyId,
      initDate,
      documentLinkTypes.DIRECTOR,
      t('FileAssociatedDirector')
    );
    return documents;
  };

  const handleChangeIsAlreadyPartner = () => {
    setIsPartnerAlready(!isPartnerAlready);
  };

  const handleUpdateDirector = async (event) => {
    event.preventDefault();

    const newDirector = {
      socialDenomination,
      cif,
      nationality,
      birthDate,
      address,
      email,
      directorType,
      initDate,
      endDate,
      directorStatus: status,
      isNaturalPerson: !haveRepresentative,
      representative: haveRepresentative ? representative : null,
      image,
    };

    let newSocietyData;
    const documents = await getDirectorDocuments(directorId);
    newDirector.documents = documents;

    if (action === 'ADD') {
      newSocietyData = {
        directors: {
          add: {
            newDirector,
          },
        },
      };
    } else if (action === 'EDIT') {
      newDirector['_id'] = directorId;
      newSocietyData = {
        directors: {
          edit: [
            {
              directorId,
              newDirector,
            },
          ],
        },
      };
    }

    // Update the society with the new director.
    dispatch(updateSociety(societyId, newSocietyData, false));
    dispatch(setModal(null));

    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${
        action === 'ADD'
          ? t('MemberAddedSuccessfully')
          : t('MemberUpdatedSuccessfully')
      }<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  const loadInitialValues = () => {
    setSocialDenomination(directorData?.socialDenomination);
    setCif(directorData?.cif);
    setNationality(directorData?.nationality);
    if (directorData?.birthDate)
      setBirthDate(format(new Date(directorData?.birthDate), 'yyyy-MM-dd'));
    setAddress(directorData?.address);
    setEmail(directorData?.email);
    setDirectorType(directorData?.directorType);
    if (directorData?.initDate)
      setInitDate(format(new Date(directorData?.initDate), 'yyyy-MM-dd'));
    if (directorData?.endDate)
      setEndDate(format(new Date(directorData?.endDate), 'yyyy-MM-dd'));
    if (directorData?.directorStatus) setStatus(directorData?.directorStatus);
    if (directorData?.documents) {
      setInitialDocuments(directorData?.documents);
    }
    if (!directorData?.isNaturalPerson) {
      setHaveRepresentative(true);
      const directorRepresentative = directorData?.representative;
      setRepresentative({
        name: directorRepresentative?.name,
        cif: directorRepresentative?.cif,
        email: directorRepresentative?.email,
        address: directorRepresentative?.address,
        nationality: directorRepresentative?.nationality,
      });
    }
    if (directorData?.image) {
      setImage(directorData?.image);
    }
  };

  useEffect(() => {
    if (action === 'EDIT' && directorData) {
      loadInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directorData]);

  useEffect(() => {
    if (directorId && societyId) {
      dispatch(
        getDocuments({ director: directorId, society: societyId }, setFiles)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directorId, societyId]);

  useEffect(() => {
    if (!directorData?.directorStatus) {
      if (!endDate || endDate === '' || new Date(endDate) - new Date() > 0)
        setStatus(currStatusTypes.var.ACTIVE);
      else setStatus(currStatusTypes.var.INACTIVE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  useEffect(() => {
    if (partners) {
      const options = partners
        .filter((partner) => partner?.sharesCount?.actual > 0)
        .map((partner) => ({
          value: partner,
          label: `${partner?.cif} | ${partner?.name}`,
        }));
      setPartnerOptions(options);
    }
  }, [partners]);

  useEffect(() => {
    setCurrDirectorTypesOptions(directorTypesOptions(i18n));
    setCurrStatusTypesOptions(statusTypesOptions(i18n));
    setCurrStatusTypes(statusTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === 'ADD' ? `${t('Add')} ` : `${t('Edit')} `}
          {t('Admin')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <form className="form-validate is-alter" onSubmit={handleUpdateDirector}>
        <Modal.Body>
          <div className="row gy-4">
            {action === 'ADD' && (
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    {t('IsItCurrentlyAPartner?')}
                  </label>

                  <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="already-partner"
                      id="already-partner"
                      checked={isPartnerAlready}
                      onChange={handleChangeIsAlreadyPartner}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="already-partner"
                    >
                      {t('Yes')}
                    </label>
                  </div>

                  <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="no-already-partner"
                      id="no-already-partner"
                      checked={!isPartnerAlready}
                      onChange={handleChangeIsAlreadyPartner}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="no-already-partner"
                    >
                      {t('Nope')}
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="form-group">
                {isPartnerAlready ? (
                  <div className="form-control-wrap">
                    <label
                      className="form-label"
                      htmlFor="societyDirectors-socialDenom"
                    >
                      {t('Partner')} *
                    </label>
                    <Select
                      closeMenuOnSelect
                      className="react-select-lg"
                      options={partnerOptions}
                      components={animatedComponents}
                      onChange={handlePartnerOptionChange}
                      placeholder={SELECCIONAR_SOCIO}
                    />
                  </div>
                ) : (
                  <div>
                    <label
                      className="form-label"
                      htmlFor="societyDirectors-socialDenom"
                    >
                      {t('NameAndSurnameSociety')}
                    </label>
                    <input
                      type="text"
                      id="societyDirectors-socialDenom"
                      className="form-control form-control-lg"
                      value={socialDenomination}
                      onChange={(event) =>
                        setSocialDenomination(event.target.value)
                      }
                      placeholder={t('SocialDenomination')}
                      required
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyDirectors-email">
                  {t('Email')} *
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="societyDirectors-email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('Email')}
                  pattern={EMAIL_PATTERN}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-initDate"
                >
                  {t('RegistrationDate')}
                </label>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyDirectors-initDate"
                  value={initDate}
                  onChange={(event) => setInitDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="d-flex">
                  <label
                    className="form-label"
                    htmlFor="societyDirectors-endDate"
                  >
                    {t('EndDate')}
                  </label>
                  <span className="sub-text ml-1 fs-12px">
                    {t('IndefiniteDefault')}
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyDirectors-endDate"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-directorType"
                >
                  {t('TypeOfCharge')} *
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      id="societyDirectors-directorType"
                      value={directorType}
                      onChange={(event) => {
                        setDirectorType(event.target.value);
                      }}
                      required
                    >
                      <option value="" className="text-light">
                        {t('TypeOfCharge')}
                      </option>
                      {currDirectorTypesOptions?.length > 0 &&
                        currDirectorTypesOptions
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((type) => (
                            <option value={type.value} key={type.value}>
                              {type.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyDirectors-status">
                  {t('Status')}
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control form-control-lg"
                      id="societyDirectors-status"
                      value={status}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                      required
                    >
                      {currStatusTypesOptions?.length > 0 &&
                        currStatusTypesOptions.map((type) => (
                          <option value={type.value} key={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyDirectors-cif">
                  {t('Cif')}
                </label>
                <input
                  type="text"
                  id="societyDirectors-cif"
                  className="form-control form-control-lg"
                  value={cif}
                  onChange={(event) => setCif(event.target.value)}
                  placeholder={t('Cif')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-nationality"
                >
                  {t('Nationality')}
                </label>
                <input
                  type="text"
                  id="societyDirectors-nationality"
                  className="form-control form-control-lg"
                  value={nationality}
                  onChange={(event) => setNationality(event.target.value)}
                  placeholder={t('Nationality')}
                />
              </div>
            </div>
            <div className="col-md-6 align-content-end">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-address"
                >
                  {t('Address')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="societyDirectors-address"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  placeholder={t('Address')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-birthDate"
                >
                  {`${t('DateOfBirth')} / ${t('ConstitutionDate')}`}
                </label>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyDirectors-birthDate"
                  value={birthDate}
                  onChange={(event) => setBirthDate(event.target.value)}
                />
              </div>
            </div>

            <div className="form-group col-6 mb-0">
              <div className="custom-control custom-control-xs custom-checkbox">
                <input
                  type="checkbox"
                  id="checkbox-admin-documents"
                  className="custom-control-input"
                  value={haveRepresentative}
                  checked={haveRepresentative}
                  onChange={handleEditHaveRepresentative}
                />
                <label
                  className="custom-control-label form-label"
                  htmlFor="checkbox-admin-documents"
                >
                  {t('HaveRepresentative')}
                  <em
                    className="icon ni ni-info text-gray ml-2"
                    id="representative-section-title"
                  />
                  <Tooltip anchorId="representative-section-title" place="top">
                    {t('HaveRepresentativeInfo')}
                  </Tooltip>
                </label>
              </div>
            </div>

            {haveRepresentative && (
              <div className="col-12">
                <RepresentativeSection
                  representative={representative}
                  setRepresentative={setRepresentative}
                />
              </div>
            )}

            <div className="col-12">
              <UploadDocumentsSection
                initialDocumentsCount={directorData?.documents?.length || 0}
                documents={files}
                setDocuments={setFiles}
              />
            </div>

            <ImageInput
              value={image}
              onChange={handleChangeImage}
              name={socialDenomination}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!socialDenomination || !directorType}
              >
                {t('Save')}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-light ml-3"
                onClick={() => dispatch(setModal(null))}
              >
                {t('ToClose')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </>
  );
}

export default AddSocietyDirector;
