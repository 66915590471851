/* eslint-disable react/react-in-jsx-scope */
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import { updateDocument } from 'modules/documents/redux/documentActions';
import downloadDocument from 'utils/downloadDocument';
import documentTypes from 'constants/documentTypes';
import { setModal } from 'modules/_shared/redux/modalActions';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import UpdateDocumentModal from 'modules/documents/components/List/UpdateDocumentModal';
import DocumentModal from 'modules/documents/pages/Documents/documentModal';
import boardDocumentSections from 'constants/boardDocumentSections';
import isSuperAdmin from 'utils/isSuperAdmin';

function menuOptions(i18n) {
  return [
    {
      index: 0,
      icon: 'ni-file-download',
      text: i18n.t('Download'),
      action: async ({ document }) => {
        downloadDocument({
          documentId: document['_id'],
          documentName: document.name,
        });
      },
    },
    {
      index: 1,
      icon: 'ni-file-docs',
      text: i18n.t('ChangeAnnouncement'),
      action: ({ society, user, boardId }) => {
        store.dispatch(
          setModal(
            <DocumentModal
              userId={user['_id']}
              societyId={society['_id']}
              boardId={boardId}
              boardSection={boardDocumentSections.ANNOUNCEMENT}
            />
          )
        );
      },
    },
    {
      index: 2,
      icon: 'ni-edit-alt',
      text: i18n.t('Rename'),
      action: ({ society, document }) => {
        store.dispatch(
          setModal(
            <UpdateDocumentModal
              renameUpdate
              societyId={society['_id']}
              document={document}
            />
          )
        );
      },
    },
    {
      index: 3,
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: ({ document }) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">${i18n.t(
            'AreYouSureAboutDelDocument'
          )}</h4>`,
          confirmButtonText: i18n.t('Delete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch(
              updateDocument({ ...document, category: documentTypes.DELETED })
            );
          }
        });
      },
      dontShowCurrent: ({ user, isAdmin }) => !isSuperAdmin(user) && !isAdmin,
    },
  ];
}
export default menuOptions;
