import { useTranslate } from 'hooks/useTranslate';

import { partnerLabels } from '../constants/partnerParams';
import ConstitutionAiModalRepresentativeTable from './ConstitutionAiModalRepresentativeTable';

type ConstitutionAiModalRepresentativeRowProps = {
  partnerKey: string;
  index: number;
  partner: any;
  onChangePartnerParam: any;
};

const ConstitutionAiModalRepresentativeRow = ({
  partnerKey,
  index,
  partner,
  onChangePartnerParam,
}: ConstitutionAiModalRepresentativeRowProps) => {
  const { t } = useTranslate();

  return (
    <div className="row row-internal">
      <div className="col-4">
        <span>
          <b>
            {t(partnerLabels[partnerKey as keyof typeof partnerLabels]) ||
              partnerKey}
          </b>
        </span>
      </div>
      <div className="col-7">
        <ConstitutionAiModalRepresentativeTable
          representativeData={partner[partnerKey]}
          partnerIndex={index}
          changePartnerParam={onChangePartnerParam}
        />
      </div>
      <div className="col-1" />
    </div>
  );
};

export default ConstitutionAiModalRepresentativeRow;
