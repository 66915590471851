import { Society } from 'types';

const isActiveDirector = (director: any): boolean =>
  !director?.endDate || new Date(director?.endDate) > new Date();

const getDirectorSocialDenomination = (
  society: Society,
  directorType: string
): string => {
  const { directors } = society;

  if (!directors) return '';

  const director = directors.find(
    (director) =>
      director?.directorType === directorType && isActiveDirector(director)
  );

  return director?.socialDenomination || '';
};

export default getDirectorSocialDenomination;
