import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'redux/initialState';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { setModal } from 'modules/_shared/redux/modalActions';

import { StockPlan } from 'types';

import { getActualSociety } from 'utils/filters';
import successSwal from 'utils/successSwal';

import { deleteAllBeneficiariesFromPlan } from 'modules/beneficiaries/redux/beneficiaryActions';
import { store } from 'redux/store';
import DeletePlanBeneficiariesBody from './components/DeletePlanBeneficiariesBody';
import DeletePlanBeneficiariesFooter from './components/DeletePlanBeneficiariesFooter';
import DeletePlanBeneficiariesHeader from './components/DeletePlanBeneficiariesHeader';

type Props = {
  societyId: string;
  plan: StockPlan;
};

const DeletePlanBeneficiaries: FC<Props> = ({ societyId, plan }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleDeletePlanBeneficiaries = async () => {
    try {
      setIsLoading(true);
      await store.dispatch(
        deleteAllBeneficiariesFromPlan({
          societyId,
          planId: plan._id,
        })
      );

      getActualSociety(user, societyId);
      successSwal(t('AllBeneficiariesDeletedSuccessfully'));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <DeletePlanBeneficiariesHeader
        planName={plan.name}
        handleCloseModal={handleCloseModal}
      />
      <DeletePlanBeneficiariesBody />
      <DeletePlanBeneficiariesFooter
        handleDeletePlanBeneficiaries={handleDeletePlanBeneficiaries}
      />
      {isLoading && <CustomLoading />}
    </>
  );
};

export default DeletePlanBeneficiaries;
