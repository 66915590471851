import { useEffect, useState } from 'react';

import { SelectorOption, StockPlan } from 'types';

type Props = {
  plans: StockPlan[];
  societyId: string;
  isDraft: boolean;
};

export function useGetPlanOptions({ plans, societyId, isDraft }: Props) {
  const [planOptions, setPlanOptions] = useState<SelectorOption[]>([]);

  useEffect(() => {
    const filteredPlans = plans?.filter((plan) => {
      const draftCondition = isDraft ? plan?.isDraft : !plan?.isDraft;
      return draftCondition && plan.society === societyId;
    });

    const options = filteredPlans.map((plan) => ({
      value: plan?.['_id'],
      label: plan?.name,
    }));

    setPlanOptions(options);
  }, [plans, societyId, isDraft]);

  return {
    data: { planOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
