import i18n from 'i18n/config';

export default {
  ANUALLY: {
    value: 'ANUALLY',
    text: i18n.t('ByYear'),
  },
  QUARTERLY: {
    value: 'QUARTERLY',
    text: i18n.t('ByQuarter'),
  },
  SEMIANUALLY: {
    value: 'SEMIANUALLY',
    text: i18n.t('BySemester'),
  },
};
