export default [
  {
    id: 'my-profile',
    icon: 'ni-user-alt',
    text: 'MyProfile',
    url: '/perfil-usuario/datos',
  },
  {
    id: 'users-with-access',
    icon: 'ni-lock-alt',
    text: 'UsersWithAccess',
    url: '/perfil-usuario/administradores',
  },
];
