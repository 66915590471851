export const getBeneficiariesGroupedByCif = (inputData) => {
  const outputData = {};
  const cifSeen = {};

  Object.keys(inputData).forEach((key) => {
    const item = inputData[key];
    const { cif } = item;

    if (!cifSeen[cif]) {
      cifSeen[cif] = key; // Remember the first key where this CIF is seen
      outputData[key] = { ...item }; // Copy the entire item to the output
    } else {
      const firstKey = cifSeen[cif];
      outputData[firstKey].assigned += item.assigned;
      outputData[firstKey].vested += item.vested;
      outputData[firstKey].canceled += item.canceled;
      outputData[firstKey].acquired += item.acquired;
    }
  });

  return outputData;
};
