import types from 'redux/actions/actionTypes';
import initialState from 'redux/initialState';

export default function reducer(menu = initialState.modal, action) {
  switch (action.type) {
    case types.SET_MODAL:
      return action.modal;

    default:
      return menu;
  }
}
