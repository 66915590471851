/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { store } from 'redux/store';
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import { Document } from 'types';
import { addDocument } from 'modules/documents/redux/documentActions';

type File = Omit<Document, 'fileType'> & {
  type: string;
};

export default async function uploadAdminDocuments(
  files: File[],
  userId: string,
  adminId: string,
  societyId: string,
  documentDate: Date,
  documentLinkType: string,
  description: string
): Promise<string[]> {
  if (files?.length > 0) {
    const documents: string[] = [];
    const date: Date = documentDate || new Date();

    for (const file of files) {
      let newDocument: Document | File = file;

      if (!file?._id) {
        const newFile = {
          file,
          size: file.size,
          date,
          // @ts-ignore
          fileType: fileTypes[file?.type],
          category: documentTypes.DOCUMENTS,
          name: file.name,
          author: userId,
          society: societyId,
          description,
          linkedTo: adminId
            ? [
                {
                  linkedId: adminId,
                  linkedType: documentLinkType,
                },
              ]
            : [],
        };

        newDocument = await store.dispatch(addDocument(newFile));
      }

      if (newDocument?._id) {
        documents.push(newDocument?._id);
      }
    }
    return documents;
  }
  return [];
}
