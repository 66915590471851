import AddPartner from 'modules/_shared/components/Modals/AddPartner';
import Constitution from 'modules/_shared/components/Modals/Constitution';
import CapitalIncrease from 'modules/_shared/components/Modals/CapitalIncrease';
import CapitalDecrease from 'modules/_shared/components/Modals/CapitalDecrease';
import SellParticipations from 'modules/_shared/components/Modals/SellParticipations';
import Renumeration from 'modules/_shared/components/Modals/Renumeration';
import Reclassification from 'modules/_shared/components/Modals/Reclassification';
import Split from 'modules/_shared/components/Modals/Split';
import DividendsDistribution from 'modules/_shared/components/Modals/DividendsDistribution';
import rollbackOperation from 'utils/rollbackOperation';
import { store } from 'redux/store';
import { setModal } from 'modules/_shared/redux/modalActions';
import operationTypes from 'constants/operationTypes';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-info',
      text: i18n.t('NewPartner'),
      action: () => {
        store.dispatch(setModal(<AddPartner />));
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Constitution'),
      action: () => {
        store.dispatch(setModal(<Constitution />));
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('CapitalExpansion'),
      action: () => {
        store.dispatch(setModal(<CapitalIncrease />));
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('CapitalReduction'),
      action: () => {
        store.dispatch(setModal(<CapitalDecrease />));
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('SaleOfShares'),
      action: () => {
        store.dispatch(
          setModal(
            <SellParticipations
              transmittingType={operationTypes.SELL_PARTICIPATION}
            />
          )
        );
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Heritage'),
      action: () => {
        store.dispatch(
          setModal(
            <SellParticipations transmittingType={operationTypes.HERITAGE} />
          )
        );
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Donation'),
      action: () => {
        store.dispatch(
          setModal(
            <SellParticipations transmittingType={operationTypes.DONATION} />
          )
        );
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Split'),
      action: () => {
        store.dispatch(setModal(<Split />));
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Renumeration'),
      action: () => {
        store.dispatch(setModal(<Renumeration />));
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Reclassification'),
      action: () => {
        store.dispatch(setModal(<Reclassification />));
      },
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('DividendsDistribution'),
      action: () => {
        store.dispatch(setModal(<DividendsDistribution />));
      },
    },
    {
      icon: 'ni-undo',
      text: i18n.t('RollbackOperation'),
      action: (params) => {
        rollbackOperation(params);
      },
      borderTop: true,
    },
  ];
}
export default menuOptions;
