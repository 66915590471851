import {
  DEFAULT_EMAIL_BODY_TEXT_COLOR,
  DEFAULT_EMAIL_BUTTON_COLOR,
  DEFAULT_EMAIL_BUTTON_TEXT_COLOR,
  DEFAULT_EMAIL_FRAME_COLOR,
  DEFAULT_EMAIL_HEADINGS_COLOR,
  DEFAULT_EMAIL_LINK_COLOR,
  DEFAULT_EMAIL_LOGO,
  STTOK_EMAIL_DOMAIN,
} from 'constants/defaultConstants';
import { Society, SocietyCommunicationsConfiguration } from 'types';

/* eslint-disable import/prefer-default-export */
export const getSocietySenderDomain = (society: Society): string => {
  const communications = society?.configuration?.communications;
  return communications?.senderDomain || STTOK_EMAIL_DOMAIN;
};

export const generateSocietyEmail = (
  society: Society,
  addSuffix = true
): string => {
  if (!society) return '';

  const { name } = society;
  const senderDomain = getSocietySenderDomain(society);

  const prefix = name?.replace(/[^a-zA-Z0-9]/g, '');
  const suffix = addSuffix ? senderDomain : '';

  const email = `${prefix.toLowerCase()}${suffix}`;

  return email;
};

const setSenderName = (society: Society): string => {
  const communications = society?.configuration?.communications;
  return communications?.senderName || society?.name || '';
};

const setSenderEmail = (society: Society, addSuffix: boolean): string => {
  const communications = society?.configuration?.communications;

  if (!communications?.senderEmail) {
    return generateSocietyEmail(society, addSuffix);
  }

  const senderDomain = getSocietySenderDomain(society);

  const senderEmail = addSuffix
    ? `${communications?.senderEmail}${senderDomain}`
    : communications?.senderEmail;

  return senderEmail;
};

const setReplyEnabled = (society: Society): boolean => {
  const communications = society?.configuration?.communications;
  return communications?.replyEnabled || false;
};

const setReplyEmail = (society: Society): string => {
  const communications = society?.configuration?.communications;
  return communications?.replyEmail || '';
};

const setCustomLogo = (society: Society): string => {
  const customization = society?.customization?.theme;
  const { hasWhiteBranding } = society?.configuration;
  if (hasWhiteBranding) {
    return (
      customization?.emailFrameLogo || customization?.logo || DEFAULT_EMAIL_LOGO
    );
  }
  return customization?.logo || DEFAULT_EMAIL_LOGO;
};

const setCustomBackgroundColor = (society: Society): string => {
  const customization = society?.customization?.theme;
  return customization?.emailFrameColor || DEFAULT_EMAIL_FRAME_COLOR;
};

const setWhiteBranding = (society: Society): boolean => {
  const configuration = society?.configuration;
  return configuration?.hasWhiteBranding || false;
};

const setEmailSignatureEnabled = (society: Society): boolean => {
  const communications = society?.configuration?.communications;
  return communications?.emailSignatureEnabled || false;
};

const setEmailSignatureHTML = (society: Society): string => {
  const communications = society?.configuration?.communications;
  const { emailSignatureEnabled } = communications || {};
  return emailSignatureEnabled ? communications?.emailSignatureHTML || '' : '';
};

const setHeadingsColor = (society: Society): string => {
  const customization = society?.customization?.theme;
  return customization?.emailHeadingsColor || DEFAULT_EMAIL_HEADINGS_COLOR;
};

const setBodyTextColor = (society: Society): string => {
  const customization = society?.customization?.theme;
  return customization?.emailBodyTextColor || DEFAULT_EMAIL_BODY_TEXT_COLOR;
};

const setLinkColor = (society: Society): string => {
  const customization = society?.customization?.theme;
  return customization?.emailLinkColor || DEFAULT_EMAIL_LINK_COLOR;
};

const setButtonColor = (society: Society): string => {
  const customization = society?.customization?.theme;
  return customization?.emailButtonColor || DEFAULT_EMAIL_BUTTON_COLOR;
};

const setButtonTextColor = (society: Society): string => {
  const customization = society?.customization?.theme;
  return customization?.emailButtonTextColor || DEFAULT_EMAIL_BUTTON_TEXT_COLOR;
};

const setButtonRounded = (society: Society): string => {
  const customization = society?.customization?.theme;
  return customization?.buttonRounded ? '22px' : '4px';
};

export const getSocietyEmailConfiguration = (
  society: Society,
  { addSuffix = true }
): SocietyCommunicationsConfiguration => ({
  senderName: setSenderName(society),
  senderEmail: setSenderEmail(society, addSuffix),
  replyEnabled: setReplyEnabled(society),
  replyEmail: setReplyEmail(society),
  customLogo: setCustomLogo(society),
  customBackgroundColor: setCustomBackgroundColor(society),
  hasWhiteBranding: setWhiteBranding(society),
  emailSignatureEnabled: setEmailSignatureEnabled(society),
  emailSignatureHTML: setEmailSignatureHTML(society),
  theme: {
    logo: setCustomLogo(society),
    frameColor: setCustomBackgroundColor(society),
    headingsColor: setHeadingsColor(society),
    bodyTextColor: setBodyTextColor(society),
    linkColor: setLinkColor(society),
    buttonColor: setButtonColor(society),
    buttonTextColor: setButtonTextColor(society),
    buttonRounded: setButtonRounded(society),
  },
});

export const addConfigurationToEmailData = (
  society: Society,
  emailData: Record<string, any>
) => {
  const {
    senderName,
    senderEmail,
    replyEnabled,
    replyEmail,
    customLogo,
    customBackgroundColor,
    hasWhiteBranding,
    emailSignatureHTML,
    theme,
  } = getSocietyEmailConfiguration(society, { addSuffix: true });

  const senderConfig = {
    sender: {
      email: senderEmail,
      name: senderName,
    },
  };

  const replyConfig = {
    replyTo: replyEnabled ? replyEmail : senderEmail,
  };

  const templateConfig = [
    {
      name: 'customBackgroundColor',
      content: customBackgroundColor,
    },
    {
      name: 'customLogo',
      content: customLogo,
    },
    {
      name: 'hasWhiteBranding',
      content: hasWhiteBranding,
    },
    {
      name: 'emailSignatureHTML',
      content: emailSignatureHTML,
    },
    {
      name: 'theme',
      content: theme,
    },
  ];

  return {
    ...emailData,
    ...senderConfig,
    ...replyConfig,
    templateData: [...(emailData?.templateData || []), ...templateConfig],
  };
};
