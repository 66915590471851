const transformData = ({ data, columns }) => {
  const hasInvalidData = !data || !data?.length || !columns;
  if (hasInvalidData) return [];

  const columnNames = {};

  Object.keys(data[0]).map((key) => {
    const currentColumn = columns.find((column) => column.field === key);
    if (currentColumn) {
      columnNames[key] = currentColumn.name;
      return { [key]: currentColumn.name };
    }
    return { [key]: key };
  });

  const customData = data.map((row) => {
    const newRow = {};
    Object.keys(row).map((key) => {
      newRow[columnNames[key] || key] = row[key];
      return { [key]: row[key] };
    });
    return newRow;
  });

  return customData;
};

export default transformData;
