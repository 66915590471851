import { useTranslate } from 'hooks/useTranslate';

import DOCXViewer from 'modules/_shared/components/DOCXViewer';
import PDFPreview from 'modules/_shared/components/PDFViewer';

import { fileTypes } from '../constants/addSociety';

type AddSocietyDocPreviewProps = {
  file: File;
};

const AddSocietyDocPreview = ({ file }: AddSocietyDocPreviewProps) => {
  const { t } = useTranslate();

  const viewersByType = {
    [fileTypes.DOCX]: <DOCXViewer file={file} height={600} />,
    [fileTypes.PDF]: <PDFPreview file={file} width={466} height={600} />,
  };

  return (
    <div className="col-md-6 px-4">
      <h6>{t('OriginalDocument')}</h6>

      <div className="card card-bordered card-preview mb-4 doc-preview-box">
        {viewersByType[file?.type] || null}
      </div>
    </div>
  );
};

export default AddSocietyDocPreview;
