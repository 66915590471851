import React, { FC } from 'react';

type Props = {
  currentPage: number;
  navPage: number;
  title: string;
  icon: string;
  handleChangePage: (page: number) => void;
};

const SignatureViewNavItem: FC<Props> = ({
  currentPage,
  navPage,
  title,
  icon,
  handleChangePage,
}) => (
  <li className="nav-item">
    <p
      className={`nav-link ${currentPage === navPage ? 'active' : ''}`}
      onClick={() => handleChangePage(navPage)}
    >
      <em className={`icon ni ${icon}`} />
      <span>{title}</span>
    </p>
  </li>
);

export default SignatureViewNavItem;
