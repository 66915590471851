import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { EMAIL_PATTERN } from 'constants/defaultConstants';

type Props = {
  extraSignerName: string;
  extraSignerEmail: string;
  hasExtraSigner: boolean;
  // eslint-disable-next-line no-unused-vars
  handleChangeExtraSignerName: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeExtraSignerEmail: (event: any) => void;
};

const LetterTabSignerDataView = ({
  extraSignerName,
  extraSignerEmail,
  hasExtraSigner,
  handleChangeExtraSignerName,
  handleChangeExtraSignerEmail,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <label className="form-label d-flex">
        <h6>{t('SignatoryCompany')}</h6>
        <em
          className="icon ni ni-info text-gray ml-2"
          data-tooltip-id="assistanceDelegation"
          data-tooltip-content={t('SignatorySocietyInfoMessage')}
        />
        <Tooltip id="assistanceDelegation" place="top" />
      </label>
      <div className="row gy-2">
        <div className="col-md-12">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="full-name">
              {t('NameDenomination')}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-user" />
              </div>
              <input
                type="text"
                className="form-control form-control-lg"
                value={extraSignerName}
                disabled={!hasExtraSigner}
                placeholder={t('IntroduceFullName')}
                onChange={handleChangeExtraSignerName}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="form-label w-100" htmlFor="birth-day">
              {t('Email')}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-mail" />
              </div>
              <input
                type="email"
                className="form-control form-control-lg"
                value={extraSignerEmail}
                disabled={!hasExtraSigner}
                placeholder={t('IntroduceNewEmail')}
                onChange={handleChangeExtraSignerEmail}
                pattern={EMAIL_PATTERN}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LetterTabSignerDataView;
