export default [
  {
    value: 'CURRENT',
    label: 'Current',
    badge: 'success',
  },
  {
    value: 'REVOKED',
    label: 'Revoked',
    badge: 'danger',
  },
];
