import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import isValidEmail from 'utils/isValidEmail';

type Props = {
  beneficiaryEmail: string;
  handleChangeBeneficiaryEmail: (event: any) => void;
};

const CancelBeneficiaryEmailSection: FC<Props> = ({
  beneficiaryEmail,
  handleChangeBeneficiaryEmail,
}) => {
  const { t } = useTranslate();

  return (
    <div className="form-group">
      <label className="form-label w-100" htmlFor="email">
        {t('Email')}

        {!isValidEmail(beneficiaryEmail) && (
          <span className="badge rounded-pill text-warning ml-3">
            {t('InvalidEmail')}
          </span>
        )}
      </label>
      <input
        type="email"
        className="form-control form-control-lg"
        id="beneficiaryEmail"
        value={beneficiaryEmail}
        onChange={handleChangeBeneficiaryEmail}
        // disabled={!isAdmin && user['_id'] !== beneficiary?.user}
        placeholder={t('IntroduceNewEmail')}
        pattern={EMAIL_PATTERN}
      />
    </div>
  );
};

export default CancelBeneficiaryEmailSection;
