import { useTranslate } from 'hooks/useTranslate';

const PageWithTabsError = () => {
  const { t } = useTranslate();
  return (
    <div className="alert alert-icon alert-danger" role="alert">
      <em className="icon ni ni-cross-circle" />
      <strong>Uups!</strong> {t('ErrorLoadingCommitteeMemberData')}
    </div>
  );
};

export default PageWithTabsError;
