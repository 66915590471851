/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { STTOK_LOGO } from 'constants/defaultConstants';

import 'assets/styles/Layouts.scss';
import { useTranslate } from 'hooks/useTranslate';
import './Footer.scss';

const languages = {
  es: { displayName: 'ES' },
  en: { displayName: 'EN' },
  fr: { displayName: 'FR' },
};

function Footer() {
  const { i18n } = useTranslate();

  const hasWhiteBranding = useSelector(
    (state) => state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  return (
    <div className="nk-footer footer">
      <div className="container wide-xl">
        <div className="nk-footer-wrap g-2">
          <div className="nk-footer-copyright text-primary">
            {!hasWhiteBranding && (
              <>
                &copy;&nbsp;
                <Link to="/">
                  <img
                    id="sttok-logo"
                    src={STTOK_LOGO}
                    alt="Logo"
                    className="rounded-0"
                    style={{ height: '20px' }}
                  />
                </Link>
              </>
            )}
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item d-flex flex-row">
                {Object.keys(languages).map((lng, index) => (
                  <div key={`${index}-${lng}`}>
                    <span
                      className="nav-link text-primary cursor-pointer"
                      style={{
                        fontWeight:
                          i18n.resolvedLanguage === lng ? 'bold' : 'normal',
                      }}
                      onClick={() => i18n.changeLanguage(lng)}
                    >
                      {languages[lng].displayName}
                    </span>
                    {index < Object.keys(languages).length - 1 && (
                      <span key={`divider-${lng}`}> | </span>
                    )}
                  </div>
                ))}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
