/* eslint-disable no-nested-ternary */
import { useTranslate } from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';
import { formatNumber } from 'utils/formats';

import { hasInternalView } from 'utils/showDetails';

import '../PartnerBook.scss';

function PlanSummaryBeneficiaryPeriodsTable({
  view,
  summary,
  names,
  periods,
  values,
}) {
  const { t } = useTranslate();

  if (hasInternalView()) {
    console.log('cclog > PlanSummaryBeneficiaryPeriodsTable');
  }

  return (
    summary && (
      <div className={view === 0 || view === 1 ? 'nk-block' : 'd-none'}>
        <div className="card card-bordered">
          <div className="table-responsive">
            <table
              className="table table-striped text-right cloned pos-abs bg-white border-right partner-book-table"
              id="beneficiary-periods-overlap"
            >
              <tr className="text-center table-header">
                <th className="w-200px">&nbsp;</th>
              </tr>
              <tr className="text-center table-subheader">
                <td className="w-200px">{t('Beneficiaries')}</td>
              </tr>

              {Object.keys(summary)?.map((beneficiaryId) => (
                <>
                  <tr>
                    <td
                      id={beneficiaryId}
                      className="text-left w-200px d-block text-ellipsis"
                    >
                      {names[beneficiaryId]}
                      <Tooltip anchorId={beneficiaryId} place="bottom">
                        {names[beneficiaryId]}
                      </Tooltip>
                    </td>
                  </tr>
                </>
              ))}
            </table>

            <table
              className="table table-striped text-right border-right partner-book-table"
              id="beneficiaryPeriodsTable"
              style={{
                width: `${periods.length * 400 + 210}px`,
              }}
            >
              <tr className="text-center table-header">
                <th className="w-200px d-block border-right">&nbsp;</th>

                {periods?.map((period) => (
                  <th
                    className="border-right"
                    style={{ height: '50px !important' }}
                    colSpan={5}
                  >
                    {period}
                  </th>
                ))}
              </tr>

              <tr className="text-center">
                <td className="w-200px d-block table-subheader border-right">
                  {t('Beneficiaries')}
                </td>
                {periods?.map((period) => (
                  <>
                    <td
                      className="table-subheader border-right"
                      style={{ height: '50px !important' }}
                    >
                      {t('AssignedUnits')}
                    </td>
                    <td
                      className="no-break table-subheader border-right"
                      style={{ height: '50px !important' }}
                    >
                      {t('Vested')}
                    </td>
                    <td
                      className="no-break table-subheader border-right"
                      style={{ height: '50px !important' }}
                    >
                      {t('TotalVested')}
                    </td>
                    <td
                      className="no-break table-subheader border-right"
                      style={{ height: '50px !important' }}
                    >
                      {t('CanceledUnits')}
                    </td>
                    <td
                      className="no-break table-subheader border-right"
                      style={{ height: '50px !important' }}
                    >
                      {t('Acquired')}
                    </td>
                  </>
                ))}
              </tr>

              {Object.keys(summary)?.map((beneficiaryId, index) => (
                <>
                  <tr>
                    <td
                      id={beneficiaryId}
                      className="text-left w-200px d-block text-ellipsis border-right"
                    >
                      {names[beneficiaryId]}
                      <Tooltip anchorId={beneficiaryId} place="bottom">
                        {names[beneficiaryId]}
                      </Tooltip>
                    </td>
                    {values[beneficiaryId].map((value) => (
                      <td
                        className={
                          index === 0
                            ? 'text-right text-ellipsis border-right'
                            : 'text-right border-right'
                        }
                        style={{ height: '50px !important' }}
                      >
                        {formatNumber(value)}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </table>
          </div>
        </div>
      </div>
    )
  );
}

export default PlanSummaryBeneficiaryPeriodsTable;
