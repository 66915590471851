import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toggleFullWidth from 'modules/_shared/redux/fullWidthActions';
import { setMenu } from 'modules/_shared/redux/menuActions';

const iconExpand = 'ni-expand';
const iconShrink = 'ni-shrink';

const ToggleWidthButton = ({ variant = 'primary', hideMenu = false }) => {
  const dispatch = useDispatch();

  const fullWidth = useSelector((state) => state.fullWidth);
  const menu = useSelector((state) => state.menu);
  const theme = useSelector(
    (state) => state.society?.actualSociety?.customization?.theme
  );

  const [icon, setIcon] = React.useState(iconExpand);
  const [menuPreviousType, setMenuPreviousType] = React.useState(menu.type);

  const handleToggleWidth = () => {
    dispatch(toggleFullWidth());
  };

  const showMenu = (show) => {
    if (!hideMenu) return;

    setMenuPreviousType(menu.type);

    dispatch(
      setMenu({
        ...menu,
        type: show ? menuPreviousType : null,
      })
    );
  };

  React.useEffect(() => {
    if (fullWidth) {
      showMenu(false);
      setIcon(iconShrink);
      localStorage.setItem('fullWidth', true);
    } else {
      showMenu(true);
      setIcon(iconExpand);
      localStorage.setItem('fullWidth', false);
    }
  }, [fullWidth]);

  return (
    <button
      type="button"
      className={`btn btn-outline-${variant} bg-transparent px-1`}
      style={{
        color: theme?.headerTextColor,
        borderColor: theme?.headerTextColor,
      }}
      onClick={handleToggleWidth}
    >
      <em className={`icon ni ${icon}`} />
    </button>
  );
};

export default ToggleWidthButton;
