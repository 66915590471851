export default [
  { value: 0, text: 'Sin período de vesting'},
  { value: 6, text: '6 meses' },
  { value: 12, text: '1 año' },
  { value: 16, text: '16 meses' },
  { value: 18, text: '1,5 años' },
  { value: 24, text: '2 años' },
  { value: 30, text: '2,5 años' },
  { value: 36, text: '3 años' },
  { value: 42, text: '3,5 años' },
  { value: 48, text: '4 años' },
  { value: 60, text: '5 años' },
  { value: 72, text: '6 años' },
  { value: 84, text: '7 años' },
  { value: 96, text: '8 años' },
  { value: 108, text: '9 años' },
];
