import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { State } from 'redux/initialState';

import {
  BoardDelegationRole,
  BoardParticipant,
  PartnerRepresentant,
} from 'types';
import downloadFile from 'utils/downloadFile';

type AssistentsModalPartnerRepresentantsRowProps = {
  index: number;
  boardId: string;
  societyId: string;
  participants: BoardParticipant[];
  representatives: BoardParticipant[];
  partnerRepresentants: PartnerRepresentant[];
  currentPartnerRepresentant: PartnerRepresentant;
  handleChangeAssists: (
    memberId: string,
    checked: boolean,
    isPartnerRepresentant: boolean
  ) => void; // eslint-disable-line
  handleChangeRepresentation: (memberId: string, value: boolean) => void; // eslint-disable-line
  handlePartnerOptionChange: (
    memberId: string, // eslint-disable-line
    value: string, // eslint-disable-line
    role: BoardDelegationRole // eslint-disable-line
  ) => void;
};

export const AssistentsModalPartnerRepresentantsRow = ({
  index,
  boardId,
  societyId,
  participants,
  representatives,
  partnerRepresentants,
  currentPartnerRepresentant,
  handleChangeAssists,
  handleChangeRepresentation,
  handlePartnerOptionChange,
}: AssistentsModalPartnerRepresentantsRowProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const board = useSelector((state: State) => state.boards).find(
    (board) => board['_id'] === boardId
  );

  const isPartnerRepresentant = true;

  const partnersRepresented = participants
    ?.filter(
      (participant) =>
        participant.representative === currentPartnerRepresentant._id
    )
    .map((participant) => participant.name);

  const hasVotedSomeRepresented = participants.some(
    (participant) =>
      participant.representative === currentPartnerRepresentant._id &&
      participant.votes.length
  );

  const hasAssistedSomeRepresented = participants.some(
    (participant) =>
      participant.representative === currentPartnerRepresentant._id &&
      participant.assists
  );

  const hasDelegationDocument =
    (currentPartnerRepresentant?.documents?.length || 0) > 0;

  const hasVotedAlready = hasVotedSomeRepresented;

  const isAssistentCheckboxDisabled = () => hasVotedAlready;

  const handleDownloadDocuments = async () => {
    const { documents } = currentPartnerRepresentant;

    if (documents?.length === 0) return;
    await Promise.all(
      documents.map((document: string) =>
        downloadFile({ documentId: document })
      )
    );
  };

  // const handleUploadDelegationDocument = () => {
  //   dispatch(
  //     setModal(
  //       <DocumentModal
  //         userId={user['_id']}
  //         boardId={boardId}
  //         societyId={societyId}
  //         boardSection={boardDocumentSections.DELEGATION}
  //         defaultRepresented={currentPartnerRepresentant.member}
  //         defaultRepresentative={currentPartnerRepresentant.representative}
  //         document={undefined}
  //         stockPlan={undefined}
  //         partnerId={undefined}
  //         directorId={undefined}
  //         investorId={undefined}
  //         beneficiaryId={undefined}
  //         representativeId={undefined}
  //         updateLink={false}
  //         showLinkOptions={false}
  //         defaultCategory=""
  //         defaultSubcategory=""
  //         isLiteVersion
  //       />
  //     )
  //   );
  // };

  return (
    <li
      className="nk-activity-item px-0 py-1 h-50px"
      key={currentPartnerRepresentant._id}
    >
      <div className="col-9 col-sm-8 col-md-5 d-flex align-center">
        <div className="custom-control custom-control-xs custom-checkbox">
          <input
            type="checkbox"
            id={`assistent-partner-representant-${index}`}
            className="custom-control-input"
            checked={hasAssistedSomeRepresented}
            disabled={isAssistentCheckboxDisabled()}
            onChange={(event) =>
              handleChangeAssists(
                currentPartnerRepresentant._id,
                event.target.checked,
                isPartnerRepresentant
              )
            }
          />
          <label
            className="custom-control-label"
            htmlFor={`assistent-partner-representant-${index}`}
          />
        </div>
        <div className="nk-activity-media user-avatar user-avatar-sm bg-success">
          <img src={currentPartnerRepresentant.image} alt="" />
        </div>
        <div className="pl-2" style={{ lineHeight: '16px' }}>
          <b className="d-block" style={{ lineHeight: '16px' }}>
            {currentPartnerRepresentant?.name}
          </b>
          <span className="fs-12px" style={{ lineHeight: '16px' }}>
            {currentPartnerRepresentant?.cif}
          </span>
        </div>
      </div>

      <div className="d-none d-sm-inline-flex col-sm-4 col-md-2 custom-control custom-control-xs custom-checkbox">
        <span className="fs-14px gx-3">
          {partnersRepresented.length}&nbsp;
          {partnersRepresented.length > 1
            ? t('representeds')
            : t('represented')}
          <em
            className="icon ni ni-eye text-primary ml-1 cursor-help fs-16px z-100"
            data-tooltip-id={`tooltip-${currentPartnerRepresentant._id}`}
          />
        </span>
        <Tooltip
          id={`tooltip-${currentPartnerRepresentant._id}`}
          place="right"
          style={{ zIndex: 9999, maxWidth: 400 }}
        >
          {partnersRepresented?.map((partner) => (
            <p className="d-block mb-0">{partner}</p>
          ))}
        </Tooltip>
      </div>

      <div className="d-none d-sm-inline-flex col-sm-6 col-md-4">
        <div className="form-group w-100">
          {/* TODO: Do we want to display something here */}
          {/* <AssistentsModalPartnerRepresentantsRowSelectorWarning /> */}
        </div>
      </div>

      <div className="col-3 col-sm-2 col-md-1 pl-0">
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-icon btn-dim btn-light py-0"
            disabled
            // onClick={handleUploadDelegationDocument}
          >
            <em className="icon ni ni-upload my-1" />
          </button>

          {hasDelegationDocument ? (
            <button
              type="button"
              className="btn btn-icon py-0"
              onClick={handleDownloadDocuments}
            >
              <em className="icon ni ni-file-check" />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-icon py-0"
              disabled
              data-toggle="tooltip"
              data-placement="top"
              title={t('HasNoDelegationDocuments')}
            >
              <em className="icon ni ni-file-check" />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default AssistentsModalPartnerRepresentantsRow;
