/* eslint-disable no-plusplus */
/* eslint-disable import/no-unresolved */

/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import { setModal } from 'modules/_shared/redux/modalActions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslate } from 'hooks/useTranslate';
import { Modal } from 'react-bootstrap';

import documentTypes from 'constants/documentTypes';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import Swal from 'sweetalert2';
import { decryptResponse } from 'utils/token';
import axios from '../../../../../interceptors/axios';

import '../Modals.scss';

function DocumentsSettingsModal({ society, setIsLoading }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const documents = useSelector((state) => state.documents);

  const [filteredDocuments, setFilteredDocuments] = useState([]);

  const downloadZip = async () => {
    const zip = new JSZip();
    const fileCounts = {};

    // use `map` instead of `forEach` to return an array of Promises
    const promises = filteredDocuments.map(async (document) => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/download/${document['_id']}`
      );

      const decodedData = await decryptResponse(data);
      const url = decodedData?.url;

      return fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const baseName = document.name.replace(/\.[^/.]+$/, '');

          if (fileCounts[baseName] === undefined) {
            fileCounts[baseName] = 1;
          } else {
            fileCounts[baseName]++;
          }

          const uniqueName =
            fileCounts[baseName] > 1
              ? `${baseName}_${fileCounts[baseName]}`
              : baseName;

          const fileExtension = document.name.includes('.')
            ? document.name.split('.').pop()
            : 'pdf';

          zip.file(`${uniqueName}.${fileExtension}`, blob, { binary: true });
        })
        .catch((error) => {
          console.log(error);
        });
    });

    await Promise.all(promises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((content) => {
        FileSaver.saveAs(content, `${society.name} documents.zip`);
      });
    });
    setIsLoading(false);
  };

  const downloadDocuments = async () => {
    dispatch(setModal(null));
    setIsLoading(true);
    await downloadZip();
    Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'DocumentsDownloadedSuccessfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  useEffect(() => {
    if (society) {
      const filteredDocuments = documents?.filter(
        (document) =>
          document.category !== documentTypes.DELETED &&
          document.category !== documentTypes.TEMPLATES &&
          document.society === society?.['_id']
      );
      setFilteredDocuments(filteredDocuments);
    }
  }, [documents, society]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('DocumentsSettings')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross cursor-pointer" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter">
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <div className="form-control-wrap">
                  {t('DocumentsSettingsMessage', {
                    societyName: society?.name,
                    countDocuments: filteredDocuments?.length,
                  })}
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={downloadDocuments}
            disabled={filteredDocuments?.length === 0}
          >
            {t('DownloadAllDocuments')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default DocumentsSettingsModal;
