const generateContext = (contextsList: string[], paramsList: string[]) => {
  const context = 'Context for each value:';
  const contextItems = contextsList
    .map(
      (contextItem, index) =>
        `${index + 1}. **${paramsList[index]}**: ${contextItem}`
    )
    .join('\n');
  const contextSuffix = 'INPUT TEXT:';

  return `${context}\n${contextItems}\n\n${contextSuffix}`;
};

export const generatePromptDefault = (paramsList: string[]) =>
  `Extrae ${paramsList} del siguiente texto, en los campos que no encuentres retorna "NOT_FOUND":`;

export const generatePromptAddSociety = (
  paramsList: string[],
  contextsList?: string[]
) => {
  const params = paramsList.join(', ');

  const prompt = `Extracts from the following text, a JSON representation, where ${params} is obtained. In the fields that you cannot find, return '', the response has to be just the JSON and the keys with the same text in which they are requested, fiscal identification must be only the value not the type. Dates must be in YYYY-MM-DD format.`;

  if (contextsList) {
    const context = generateContext(contextsList, paramsList);
    return `${prompt}\n\n${context}`;
  }

  return prompt;
};

export const generatePromptAddPartners = (
  paramsList: string[],
  representativeParamsList: string[],
  contextsList?: string[]
) => {
  const params = paramsList.join(', ');
  const representativeParams = representativeParamsList.join(', ');

  const prompt = `Extracts from the following text, a JSON representation with root 'partners', where ${params} is obtained. In the fields that you cannot find, return '', the response has to be just the JSON and the keys with the same text in which they are requested, fiscal identification must be only the value not the type. Dates must be in YYYY-MM-DD format. If have a representative, one of the keys will be Representative, with the following information ${representativeParams} if dont have dont return this key`;

  if (contextsList) {
    const context = generateContext(contextsList, paramsList);
    return `${prompt}\n\n${context}`;
  }

  return prompt;
};

export const generatePromptAddConstitution = (
  paramsList: string[],
  contextsList?: string[]
) => {
  const params = paramsList.join(', ');

  const prompt = `Extracts from the following text, a JSON representation, where ${params} is obtained. In the fields that you cannot find, return '', the response has to be just the JSON object and the keys with the same text in which they are requested, dates must be in YYYY-MM-DD format, amounts must be only the number`;

  if (contextsList) {
    const context = generateContext(contextsList, paramsList);
    return `${prompt}\n\n${context}`;
  }

  return prompt;
};
