/* eslint-disable no-console */
import { store } from 'redux/store';
import { Board } from 'types';
import boardStatus from 'constants/boardStatus';
import { updateBoard } from '../redux/boardActions';

export const updateBoardStatusIfOutdated = async (boards: Board[]) => {
  if (!boards.length) return [];

  const today = new Date();

  const updatePromises = boards.map(async (board) => {
    if (
      today > new Date(board.date) &&
      board.status !== boardStatus.CELEBRATED.value
    ) {
      await store.dispatch(
        updateBoard(
          board['_id'],
          { status: boardStatus.CELEBRATED.value },
          false
        )
      );
    }
    return board;
  });

  try {
    await Promise.all(updatePromises);
    return boards;
  } catch (error) {
    console.error('Failed to update boards:', error);
    return [];
  }
};
