import i18n from 'i18n/config';
import PartnerRepresentantModal from 'modules/partners/modals/PartnerRepresentantModal';

export default [
  {
    icon: 'ni-book-read',
    text: i18n.t('AddPartnerRepresentant'),
    url: PartnerRepresentantModal,
  },
];
