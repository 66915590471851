import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  handleDeletePlanBeneficiaries: () => void;
};

const DeletePlanBeneficiariesFooter: FC<Props> = ({
  handleDeletePlanBeneficiaries,
}: Props) => {
  const { t } = useTranslate();

  return (
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-lg btn-primary"
        onClick={handleDeletePlanBeneficiaries}
      >
        {t('OkDelete')}
      </button>
    </Modal.Footer>
  );
};

export default DeletePlanBeneficiariesFooter;
