/* eslint-disable react/jsx-curly-newline */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getDrafts } from 'modules/drafts/redux/draftActions';
import { getActualSociety } from 'utils/filters';

import menuTypes from 'constants/menuTypes';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import OperationMenuDots from 'modules/_shared/components/MenuDots/OperationMenuDots';
import DraftsRow from 'modules/drafts/components/DraftsRow';
import { useGetDraftsSectionPermissions } from 'modules/drafts/hooks/useGetDraftsSectionPermissions';
import menuOptions from './menuOptions';

const DraftsList = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { isAdmin, isReadOnly } = useGetDraftsSectionPermissions().data;

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const actualSocietyDrafts = useSelector((state) => state?.drafts);

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      setRows(
        actualSocietyDrafts?.map((draft, index) => (
          <DraftsRow
            key={draft['_id']}
            index={index + 1}
            societyId={actualSociety['_id']}
            draft={draft}
          />
        ))
      );
    }
  }, [actualSocietyDrafts, actualSociety]);

  useEffect(() => {
    if (societyId) dispatch(getDrafts(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('OperationsDrafts')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${actualSociety.name} ${t('HaveDrafts', {
                      count: rows?.length,
                    })}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <li>
                          <OperationMenuDots
                            title={t('AddDraft')}
                            menuOptions={menuOptions}
                            handleModal={(menuOption) =>
                              history.push(`${menuOption}/${societyId}`)
                            }
                            disabled={isReadOnly}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {rows.length ? (
            <div className="nk-block">
              <div className="card card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group">
                      <div className="card-tools" />
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar gx-1">
                          <li>
                            <a
                              className="btn btn-icon search-toggle toggle-search"
                              data-target="search"
                              aria-label="link"
                            >
                              <em className="icon ni ni-search" />
                            </a>
                          </li>
                          <li className="btn-toolbar-sep" />
                          <li>
                            <div className="toggle-wrap">
                              <a
                                className="btn btn-icon btn-trigger toggle"
                                data-target="cardTools"
                                aria-label="link"
                              >
                                <em className="icon ni ni-menu-right" />
                              </a>
                              <div
                                className="toggle-content"
                                data-content="cardTools"
                              >
                                <ul className="btn-toolbar gx-1">
                                  <li className="toggle-close">
                                    <a
                                      className="btn btn-icon btn-trigger toggle"
                                      data-target="cardTools"
                                      aria-label="link"
                                    >
                                      <em className="icon ni ni-arrow-left" />
                                    </a>
                                  </li>
                                  <li />
                                  <li>
                                    <div className="dropdown">
                                      <a
                                        className="btn btn-trigger btn-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-label="link"
                                      >
                                        <em className="icon ni ni-setting" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                                        <ul className="link-check">
                                          <li>
                                            <span>{t('Show')}</span>
                                          </li>
                                          <li className="active">
                                            <a aria-label="link">10</a>
                                          </li>
                                          <li>
                                            <a aria-label="link">20</a>
                                          </li>
                                          <li>
                                            <a aria-label="link">50</a>
                                          </li>
                                        </ul>
                                        <ul className="link-check">
                                          <li>
                                            <span>{t('Sort')}</span>
                                          </li>
                                          <li className="active">
                                            <a aria-label="link">
                                              {t('Descendant')}
                                            </a>
                                          </li>
                                          <li>
                                            <a aria-label="link">
                                              {t('Ascendant')}
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="card-search search-wrap"
                      data-search="search"
                    >
                      <div className="card-body">
                        <div className="search-content">
                          <a
                            className="search-back btn btn-icon toggle-search"
                            data-target="search"
                            aria-label="link"
                          >
                            <em className="icon ni ni-arrow-left" />
                          </a>
                          <input
                            type="text"
                            className="form-control border-transparent form-focus-none"
                            placeholder={t('SearchByNameOrEmail')}
                          />
                          <button
                            type="button"
                            className="search-submit btn btn-icon"
                            aria-label="link"
                          >
                            <em className="icon ni ni-search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <table
                      className="datatable-init nk-tb-list nk-tb-ulist"
                      data-auto-responsive="false"
                    >
                      <thead>
                        <tr className="nk-tb-item nk-tb-head">
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">{t('Nr')}</span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">{t('Name')}</span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">{t('DraftDate')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">{t('Percentage')}</span>
                          </th>
                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">
                              {t('PreMoneyValue')}
                            </span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">{t('Amount')}</span>
                          </th>
                          <th className="nk-tb-col">
                            <span className="sub-text">{t('Operation')}</span>
                          </th>

                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text">{t('Status')}</span>
                          </th>

                          <th className="nk-tb-col tb-col-xl">
                            <span className="sub-text" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>{rows}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default DraftsList;
