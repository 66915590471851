import { getPartnerNDPercent } from 'utils/filters';

const getAttend = (participant: Record<string, any>, translate: any) => {
  if (participant.isRepresented) return translate('Represented');
  if (participant.assists) return translate('Yes');
  if (participant.isAbsent) return translate('Nope');
  return '';
};

const getBoardAssistants = (board: any, actualSociety: any, translate: any) =>
  board.participants.map((participant: Record<string, any>, index: number) => {
    const partner = actualSociety?.partners.find(
      (partner: any) => partner['_id'] === participant.member
    );

    const representative = participant?.representative
      ? actualSociety?.partners.find(
          (p: any) => p['_id'] === participant?.representative
        )
      : null;

    return {
      ...participant,
      representative,
      index: index + 1,
      percentage: getPartnerNDPercent(partner, actualSociety),
      sharesCount: partner?.sharesCount?.actual || 0,
      societyId: actualSociety['_id'],
      attend: getAttend(participant, translate),
    };
  });

export default getBoardAssistants;
