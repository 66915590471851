/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';

import documentSections from 'constants/documentSections';
import i18n from 'i18n/config';

const Header = ({
  setSelectedSection,
  setCurrentBook,
  setCurrentFolder,
  setCurrentCouncilMinute,
  setSignatureDocumentCategory,
}) => {
  const { t } = useTranslate();
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const handleSelectSection = (section) => {
    let currentSection = section.id;
    if (!section.url) {
      currentSection = section.id + 1;
    }
    setSelectedSection(documentSections(i18n)[currentSection]);
    setCurrentBook(null);
    setCurrentFolder(null);
    setCurrentCouncilMinute(null);
    setSignatureDocumentCategory(null);
    window.location.href = `#${documentSections(i18n)[currentSection].url}/${
      actualSociety?._id
    }`;
  };

  return (
    <div className="nk-fmg-quick-list nk-block">
      <div className="nk-block-head-xs">
        <div className="nk-block-between g-2">
          <div className="nk-block-head-content">
            <h6 className="nk-block-title title">{t('Sections')}</h6>
          </div>
        </div>
      </div>
      <div
        className="toggle-expand-content expanded"
        data-content="quick-access"
      >
        <div className="nk-files nk-files-view-grid">
          <div className="nk-files-list">
            {documentSections(i18n).map(
              (section) =>
                section.logo && (
                  <div
                    className="nk-file-item nk-file cursor-pointer"
                    key={section.id}
                    onClick={() => handleSelectSection(section)}
                    style={{
                      maxWidth: '140px',
                    }}
                  >
                    <div className="nk-file-info">
                      <div className="nk-file-link">
                        <div className="nk-file-title">
                          <div className="nk-file-icon">
                            <span className="nk-file-icon-type">
                              <img src={section.logo} alt="section" />
                            </span>
                          </div>
                          <div className="nk-file-name ">
                            <div className="nk-file-name-text px-0 fs-13px">
                              <span className="title">{t(section.title)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
