import { Society, SocietyEmailSignature } from 'types/Society';
import { getSocietyEmailLinkColor } from 'modules/society/utils';

import defaultSignature from '../constants/defaultSignature';

const generateEmailSignature = (
  signature: SocietyEmailSignature,
  society: Society
) => {
  const name = signature.name || defaultSignature.name;
  const jobTitle = signature.jobTitle || defaultSignature.jobTitle;
  const logo = signature.logo || defaultSignature.logo;
  const website = signature.website || defaultSignature.website;
  const phone = signature.phone || defaultSignature.phone;
  const address = signature.address || defaultSignature.address;
  const footer = signature.footer || defaultSignature.footer;
  const linkColor = getSocietyEmailLinkColor(society);

  return `<div style="font-family: Arial, sans-serif; font-size: 14px;">
      <p style="line-height: 24px;">
        <b>${name}</b>
        <br />
        ${jobTitle}
      </p>
      <img src="${logo}" alt="signature logo" style="max-width: 150px;" />
      <br />
      <p style="line-height: 18px; margin-bottom: 2px;">
        <a href="https://${website}" target="_blank" rel="noreferrer" style="color: ${linkColor};">
          ${website}
        </a>
        <a href="tel:${phone}" style="color: ${linkColor};">${
    phone && ` | Phone: ${phone}`
  }</a>
      </p>
      <p style="line-height: 18px; margin-top: 2px;">
        <a href="https://www.google.com/maps" style="color: ${linkColor};">${address}</a>
      </p>
      <p style="color: grey; font-size: 10px;">${footer}</p>
    </div>`;
};

export default generateEmailSignature;
