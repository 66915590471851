import Swal from 'sweetalert2';
import i18n from 'i18n/config';

import { store } from 'redux/store';
import eventTypes from 'constants/eventTypes';
import trackEvent from 'utils/trackEvent';

import { Society, User } from 'types';

import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import {
  getPartnerRepresentants,
  deletePartnerRepresentant as deletePartnerRepresentantAction,
} from 'modules/partners/redux/partnerRepresentantsActions';

type Props = {
  representant: Record<string, any>;
  actualSociety: Society;
  user: User;
  mixpanel: any;
};

const deletePartnerRepresentant = (params: Props) => {
  const { representant, mixpanel, actualSociety, user } = params;

  const title = `<h4 class="nk-modal-title">${i18n.t(
    'AreYouSureAboutDeleteRepresentant'
  )}</h4>`;

  const html = `<div class="caption-text">${i18n.t(
    'ThisProcedureCantUndo'
  )}</div>`;

  Swal.fire({
    icon: 'warning',
    title,
    html,
    confirmButtonText: i18n.t('OkDelete'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await store.dispatch(deletePartnerRepresentantAction(representant._id));
        await getPartnerRepresentants({ societyId: representant.societyId });

        const currentEvent = eventTypes.DELETE_PARTNER_REPRESENTANT;
        trackEvent(mixpanel, currentEvent, {
          userId: user?.['_id'],
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
          operation: currentEvent,
        });
      } catch (error) {
        store.dispatch(
          addAlert(alertBodyTypes.ERROR_PARTNER_REPRESENTANT_DELETED)
        );
      }
    }
  });
};

export default deletePartnerRepresentant;
