import { Partner } from 'types';

export const isRepresentedByPartner = (
  representantId: string | null,
  partners: Partner[]
): boolean => {
  if (!representantId) return false;

  return partners.some((partner) => partner._id === representantId);
};
