export default function getColumnClass(
  index: number,
  length: number,
  ellipsis: boolean = false
) {
  if (ellipsis)
    return (index + length) % 2 === 0
      ? 'bg-light-vert text-ellipsis'
      : 'text-ellipsis';
  return (index + length) % 2 === 0 ? 'bg-light-vert' : '';
}
