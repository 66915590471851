export default {
  var: {
    SIGNED: 'SIGNED',
    PENDING: 'PENDING',
  },
  text: {
    SIGNED: 'Signed',
    PENDING: 'Pending',
  },
  badge: {
    SIGNED: 'success',
    PENDING: 'secondary',
  },
};
