import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import UploadDocument from 'modules/documents/components/UploadDocument';

type Props = {
  encumbranceScriptureData: any;
  encumbranceFile: File | undefined;
  inputLabel: string;
  // eslint-disable-next-line no-unused-vars
  handleChangeNotaryName: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeProtocolNr: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeNotaryCity: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeEncumbranceFile: (file: File) => void;
};

const ShareEncumbranceViewFormScriptureData: FC<Props> = ({
  encumbranceScriptureData,
  encumbranceFile,
  inputLabel,
  handleChangeNotaryName,
  handleChangeProtocolNr,
  handleChangeNotaryCity,
  handleChangeEncumbranceFile,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="notary-name">
              {t('NotaryName')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="notary-name"
                className="form-control form-control-md"
                placeholder="Mr. John Smith"
                value={encumbranceScriptureData?.notaryName}
                onChange={handleChangeNotaryName}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="notary-protocol">
              {t('NotaryProtocolNumber')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="notary-protocol"
                className="form-control form-control-md"
                placeholder="P-8234290A"
                value={encumbranceScriptureData?.protocolNr}
                onChange={handleChangeProtocolNr}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="notary-city">
              {t('NotaryCity')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="notary-city"
                className="form-control form-control-md"
                placeholder="Barcelona"
                value={encumbranceScriptureData?.notaryCity}
                onChange={handleChangeNotaryCity}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-0">
        <UploadDocument
          label="Scripture"
          inputLabel={t(inputLabel) || inputLabel}
          file={encumbranceFile}
          setFile={handleChangeEncumbranceFile}
        />
      </div>
    </>
  );
};

export default ShareEncumbranceViewFormScriptureData;
