/* eslint-disable no-restricted-syntax */
import { getNumberFormatted } from 'constants/formats';
import { getTimelinePartnerShares } from 'utils/filters';

export const oldGetDataSummaryChart = ({ society, t }) => {
  const { timelineShares, timelinePrices } = getTimelinePartnerShares(
    society
  ) || { timelineShares: {}, timelinePrices: {} };

  const sharesOperations = [];
  for (const [key, value] of Object.entries(timelineShares)) {
    const operation = society?.operations?.find((op) => op._id === key);
    sharesOperations.push({
      date: operation?.date,
      shares: value,
      price: timelinePrices[key],
      type: operation?.operationType,
    });
  }

  const sharesSorted = sharesOperations
    ? sharesOperations.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      })
    : [];

  if (sharesSorted.length) {
    const lastOperation = sharesSorted[sharesSorted.length - 1];
    const today = new Date();
    if (
      new Date(lastOperation.date).toDateString() !== today.toDateString() &&
      new Date(lastOperation.date).getTime() < today.getTime()
    ) {
      sharesSorted.push({
        date: today,
        shares: lastOperation.shares,
        price: lastOperation.price,
        type: '',
      });
    }
  }

  const shares = sharesSorted.map((op) => op.shares);

  const labels = sharesSorted.map((op) => new Date(op.date));

  const operations = sharesSorted.map((op) => t(op.type));

  const options = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Resumen Sociedad',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context) => {
            if (context.datasetIndex === 1) return '';
            return `${context.dataset?.label[context?.dataIndex] || ''}${
              context.dataset?.label[context?.dataIndex] ? ': ' : ''
            }${getNumberFormatted(context?.raw)}`;
          },
        },
      },
    },
    response: true,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'month',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: operations,
        data: shares,
        borderColor: '#798bff',
        backgroundColor: '#798bff5c',
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 10,
      },
    ],
  };

  return { data, options };
};
