import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import { FixedShare } from '../types/Share';

type Props = {
  share: FixedShare;
  affectedShares: number;
};

const ShareEncumbranceViewFormReadOnlyData: FC<Props> = ({
  share,
  affectedShares,
}) => {
  const { t } = useTranslate();

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group">
          <label className="form-label" htmlFor="encumbrance-affected-shares">
            {t('AffectedShares')}
          </label>
          <div className="form-control-wrap">
            <input
              type="text"
              id="encumbrance-affected-shares"
              className="form-control form-control-md"
              value={affectedShares}
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="form-group">
          <label className="form-label" htmlFor="encumbrance-class">
            {t('EncumbranceClass')}
          </label>
          <div className="form-control-wrap">
            <input
              type="text"
              id="encumbrance-calss"
              className="form-control form-control-md"
              value={share?.shareClass || ''}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareEncumbranceViewFormReadOnlyData;
