import { useState } from 'react';

import CKEditor from 'modules/_shared/components/CKEditor';

type Props = {
  htmlContent: string;
  // eslint-disable-next-line no-unused-vars
  handleChangeHtmlContent: (content: string) => void;
};

const LetterTabEditView = ({ htmlContent, handleChangeHtmlContent }: Props) => {
  const tagsToShow = {
    plan: {},
    partner: {},
    society: {},
    beneficiary: {},
  };

  const [editorInstance, setEditorInstance] = useState<any>(null);

  const handleChangeEditorInstance = (instance: any) => {
    setEditorInstance(instance);
  };

  const onChangeContent = () => {
    if (editorInstance) {
      const newContent = editorInstance.getData();
      if (newContent !== htmlContent) {
        handleChangeHtmlContent(newContent);
      }
    }
  };

  return (
    <div className="avoid-default-font">
      <CKEditor
        editorInstance={editorInstance}
        setEditorInstance={handleChangeEditorInstance}
        documentName="export"
        documentModel={htmlContent || ''}
        enableMentions
        fullScreen
        tagsToShow={tagsToShow}
        onChangeContent={onChangeContent}
      />
    </div>
  );
};

export default LetterTabEditView;
