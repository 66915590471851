const hasEmptyInvitationLetter = (currentPlan: Record<string, any>) => {
  if (currentPlan?.hasInvitation) {
    if (!currentPlan?.invitationModel?.html) {
      return true;
    }
  }
  return false;
};

export default hasEmptyInvitationLetter;
