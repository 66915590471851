import { useTranslate } from 'hooks/useTranslate';
import { useHistory, useParams } from 'react-router-dom';

import folders from 'assets/images/icon-sections/folders.svg';

type DashboardFolderProps = {
  index: number;
  title: string;
  content: any[];
  sectionIndex: string;
};

const DashboardFolder = (folder: DashboardFolderProps) => {
  const { t } = useTranslate();
  const { societyId } = useParams();
  const history = useHistory();

  const { index, title, content, sectionIndex } = folder;

  const handleNavigateToFolder = () => {
    const url = `/home/${societyId}/${sectionIndex}/${index}`;
    history.push(url);
  };

  return (
    <div
      key={String(index)}
      className="nk-file-item nk-file cursor-pointer w-100 h-100 mx-0"
    >
      <div onClick={handleNavigateToFolder}>
        <div className="nk-file-info">
          <div className="nk-file-link">
            <div className="nk-file-title">
              <div className="nk-file-icon">
                <span className="nk-file-icon-type">
                  <img src={folders} alt="section" />
                </span>
              </div>
              <div className="nk-file-name">
                <div className="nk-file-name-text">
                  <span className="title">{title}</span>
                  <span className="sub-text">
                    {content?.length || 0}&nbsp;{t('elements')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFolder;
