import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { StockPlan } from 'types';

import UploadDocument from 'modules/documents/components/UploadDocument';

import { actions } from '../constants/actions';

type Props = {
  action: string;
  currentPlan: StockPlan | undefined;
  invitationDate: string;
  sendInvitation: boolean;
  file: File | undefined;
  // eslint-disable-next-line no-unused-vars
  handleChangeInvitationDate: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeFile: (file: File) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeSendInvitation: (event: any) => void;
};

const AddBeneficiaryModalDocumentationView: FC<Props> = ({
  action,
  currentPlan,
  invitationDate,
  file,
  sendInvitation,
  handleChangeInvitationDate,
  handleChangeFile,
  handleChangeSendInvitation,
}) => {
  const { t } = useTranslate();

  return (
    <>
      {currentPlan?.hasInvitation && (
        <div className="form-group">
          <label className="form-label" htmlFor="beneficiary-inv-date">
            {t('InvitationDate')}
          </label>
          <div className="form-control-wrap">
            <input
              id="beneficiary-inv-date"
              name="beneficiary-inv-date"
              type="date"
              className="form-control date-picker form-control-lg"
              value={invitationDate}
              onChange={handleChangeInvitationDate}
              max="2100-01-01T00:00"
            />
          </div>
        </div>
      )}

      {action !== actions.EDIT && (
        <>
          <UploadDocument
            label=""
            inputLabel={file?.name || t('AttachDocument')}
            file={file}
            setFile={handleChangeFile}
          />

          {currentPlan?.hasInvitation && (
            <div className="custom-control custom-control-xs custom-checkbox mb-2">
              <input
                type="checkbox"
                id="checkbox-send-to-all"
                className="custom-control-input"
                checked={sendInvitation}
                disabled={!currentPlan}
                onChange={handleChangeSendInvitation}
              />
              <label
                className="custom-control-label mb-2"
                htmlFor="checkbox-send-to-all"
              >
                {t('SendInvitationLetterToThisBeneficiary')}
              </label>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AddBeneficiaryModalDocumentationView;
