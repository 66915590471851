import { useEffect, useState } from 'react';

import isValidEmail from 'utils/isValidEmail';

import {
  getEmailColumn,
  getNameColumn,
  getNroIdColumn,
  getNroPPSSColumn,
} from 'constants/getExcelColumns';

type Props = {
  partner: any;
};

export default function useGetPartnerRowData({ partner }: Props) {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [nroId, setNroId] = useState<string>('');
  const [ppss, setPPSS] = useState<string>('');
  const [validPartner, setValidPartner] = useState<boolean>(false);

  useEffect(() => {
    const partnerName = getNameColumn(partner) || '-';
    const partnerEmail = getEmailColumn(partner) || '-';
    const partnerNroId = getNroIdColumn(partner) || '-';
    const partnerPPSS = getNroPPSSColumn(partner) || '-';
    const partnerIsValidEmail = isValidEmail(partnerEmail);
    const partnerIsExisting = partner?.isExisting;

    setName(partnerName);
    setEmail(partnerEmail);
    setNroId(partnerNroId);
    setPPSS(partnerPPSS);
    setValidPartner(!partnerIsExisting && partnerIsValidEmail);
  }, [partner]);

  return {
    data: { name, email, nroId, ppss, validPartner },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
