import actionTypes from 'redux/actions/actionTypes';
import initialState from 'redux/initialState';

export default function reducer(
  globalNotifications = initialState.globalNotifications,
  action
) {
  switch (action.type) {
    case actionTypes.CREATE_GLOBAL_NOTIFICATION:
      return [...globalNotifications, action.globalNotification];

    case actionTypes.GET_GLOBAL_NOTIFICATIONS:
      return action.data;

    case actionTypes.UPDATE_GLOBAL_NOTIFICATION:
      return [
        ...globalNotifications.filter(
          (globalNotification) =>
            globalNotification['_id'] !== action.globalNotification['_id']
        ),
        action.globalNotification,
      ];

    case actionTypes.DELETE_GLOBAL_NOTIFICATION:
      return globalNotifications.filter(
        (globalNotification) =>
          globalNotification['_id'] !== action.globalNotificationId
      );

    default:
      return globalNotifications;
  }
}
