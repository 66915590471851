import React from 'react';

type Props = {
  value: string;
  limit: number;
  label?: string;
};

const InputCharCounter = ({ value, limit, label }: Props) => {
  const counterClassName =
    value?.length >= limit ? 'text-danger' : 'text-primary';

  return (
    <div className="form-note d-flex justify-content-between">
      <span>{label}</span>
      <span className={counterClassName}>
        {value?.length} / {limit}
      </span>
    </div>
  );
};

export default InputCharCounter;
