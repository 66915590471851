import { selectedTheme } from 'modules/_shared/components/ThemeSwitcher';
// import { getDataMainPartners } from './getPartnersData';

export const getPartnersChartViewData = (partnerViewData) => {
  const partnersToShow = [];
  const restValues = {
    value1: 0,
    value2: 0,
    value3: 0,
  };

  const chartColors = [
    '#798bff',
    '#b8acff',
    '#ffa9ce',
    '#7de1f8',
    '#f9db7b',
    '#88ea6a',
  ];

  partnerViewData?.forEach((partner, index) => {
    if (index < 5) {
      partnersToShow.push(partner);
    } else {
      restValues.value1 += partner.value1;
      restValues.value2 += partner.value2;
      restValues.value3 += partner.value3;
    }
  });

  partnersToShow[5] = {
    name: 'Rest',
    icon: 'ni-user',
    ...restValues,
  };

  const labels = partnersToShow.map((partner) => partner.name);
  const values = partnersToShow.map((partner) => partner.value3);

  const partnersToChart = partnersToShow.map((partner, index) => ({
    title: partner.name,
    icon: 'ni-user',
    color: chartColors[index],
    value: partner.value1,
    percent: partner.value3,
  }));

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      title: {
        display: false,
        text: 'Partners',
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Porciento',
        data: values,
        borderWidth: 2,
        borderColor: selectedTheme === 'dark' ? '#101924' : '#ffffff',
        backgroundColor: chartColors,
      },
    ],
  };

  return { chart: { data, options }, partners: partnersToChart };
};
