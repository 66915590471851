/**
 * Signature types for DocuSign "externalEnvelopeId" param
 * This is used to identify the type of document that is being signed
 */
export default {
  INVITATION_LETTER: 'INVITATION_LETTER',
  TENDER_OFFER: 'TENDER_OFFER',
  TENDER_OFFER_PARTNER: 'TENDER_OFFER_PARTNER',
  TENDER_OFFER_BENEF: 'TENDER_OFFER_BENEF',
  TENDER_OFFER_SHARES: 'TENDER_OFFER_SHARES',
  FREE_SIGNATURE: 'FREE_SIGNATURE',
  FREE_SIGNATURE_BENEF: 'FREE_SIGNATURE_BENEF',
  FREE_SIGNATURE_PARTNER: 'FREE_SIGNATURE_PARTNER',
  FREE_SIGNATURE_INVESTOR: 'FREE_SIGNATURE_INVESTOR',
  FREE_SIGNATURE_DIRECTOR: 'FREE_SIGNATURE_DIRECTOR',
  FREE_SIGNATURE_BENEF_ALL: 'FREE_SIGNATURE_BENEF_ALL',
  FREE_SIGNATURE_PARTNER_ALL: 'FREE_SIGNATURE_PARTNER_ALL',
  FREE_SIGNATURE_INVESTOR_ALL: 'FREE_SIGNATURE_INVESTOR_ALL',
  FREE_SIGNATURE_DIRECTOR_ALL: 'FREE_SIGNATURE_DIRECTOR_ALL',
  BOARD_ASSISTANTS: 'BOARD_ASSISTANTS',
  BOARD_ACT: 'BOARD_ACT',
  BOARD_CERTIFICATE: 'BOARD_CERTIFICATE',
};
