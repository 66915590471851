import { useTranslate } from 'hooks/useTranslate';
// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';

import 'assets/styles/Components.scss';

import { useGetPermissions } from '../hooks/useGetPermissions';

type Props = {
  className?: string;
  defaultPermissions?: Array<Record<string, any>>;
  onChangePermissions?: (permissions: Array<Record<string, any>>) => void; // eslint-disable-line no-unused-vars
};

const SocietyAdministratorPermissionsForm = (props: Props) => {
  const { t } = useTranslate();

  const {
    className = '',
    defaultPermissions = [],
    onChangePermissions,
  } = props;

  const permissionsResult = useGetPermissions({ defaultPermissions });

  const { permissions } = permissionsResult.data;
  const { setPermissions } = permissionsResult.actions;

  const [isOpened, setIsOpened] = useState(false);

  const toggleAccordion = () => {
    setIsOpened(!isOpened);
  };

  const getPermission = (eventTarget: any) => {
    const { id, checked } = eventTarget;

    const permission = permissions?.find(
      (permission) =>
        `checkbox-${permission.name}` === id ||
        `checkbox-${permission.name}-readonly-checkbox` === id
    );

    return { permission, checked };
  };

  const hasToShowReadOnly = (permission: any) => {
    const { checked } = permission;
    return checked;
  };

  const updatePermissions = (newPermissions: any) => {
    setPermissions(newPermissions);

    if (onChangePermissions) {
      onChangePermissions(newPermissions);
    }
  };

  const handleSelectPermission = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { permission, checked } = getPermission(event.target);

    if (permission) {
      const newPermissions = permissions?.map((p) =>
        p.name === permission.name ? { ...permission, checked } : p
      );
      updatePermissions(newPermissions);
    }
  };

  const handleChangeReadOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { permission, checked } = getPermission(event.target);

    if (permission) {
      const newPermissions = permissions?.map((p) =>
        p.name === permission.name ? { ...permission, readOnly: checked } : p
      );
      updatePermissions(newPermissions);
    }
  };

  return (
    <div id="accordion" className={`accordion border ${className}`}>
      <div className="accordion-item">
        <span
          className={`accordion-head cursor-pointer ${
            isOpened ? '' : 'collapsed'
          }`}
          onClick={toggleAccordion}
        >
          <h6 className="title">
            <em className="icon ni ni-lock-fill mr-2" />
            {t('LimitSectionsAccess')}
          </h6>
          <span className="accordion-icon" />
        </span>
        <div
          className={`accordion-body collapse ${isOpened ? 'show' : 'hide'}`}
          id="accordion-item-1"
          data-bs-parent="#accordion"
        >
          <div className="accordion-inner">
            <p>{t('LimitSectionsAccessInfo')}</p>

            <li className="list list-unstyled">
              {permissions?.map((permission, index) => (
                <ul
                  className="list list-unstyled mb-1"
                  key={`checkbox-${permission.name}`}
                >
                  <div className="custom-control custom-control-xs custom-checkbox col-6">
                    <input
                      type="checkbox"
                      id={`checkbox-${permission.name}`}
                      className="custom-control-input"
                      checked={permission.checked}
                      disabled={!permission.enabled}
                      onChange={handleSelectPermission}
                    />
                    <label
                      className={`custom-control-label ${
                        !permission.enabled
                          ? 'text-muted cursor-not-allowed'
                          : ''
                      }`}
                      htmlFor={`checkbox-${permission.name}`}
                    >
                      {t(permission.label)}
                    </label>
                  </div>

                  <div className="custom-control custom-control-xs custom-checkbox col-6">
                    <input
                      type="checkbox"
                      id={`checkbox-${permission.name}-readonly-checkbox`}
                      className="custom-control-input"
                      checked={permission.readOnly}
                      onChange={handleChangeReadOnly}
                      disabled={!hasToShowReadOnly(permission)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`checkbox-${permission.name}-readonly-checkbox`}
                    >
                      {t('Readonly')}
                    </label>
                  </div>

                  {index < permissions?.length - 1 && (
                    <hr className="mx-0 my-1" />
                  )}
                </ul>
              ))}
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietyAdministratorPermissionsForm;
