export default [
  {
    value: 'PROPRIETARY',
    label: 'Proprietary',
  },
  {
    value: 'INDEPENDENT',
    label: 'Independent',
  },
  {
    value: 'OTHER_EXTERNAL',
    label: 'OtherExternal',
  },
  {
    value: 'N/A',
    label: 'N/A',
  },
];
