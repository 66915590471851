import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import { Board, BoardOrder, SelectorOption } from 'types';
import { Represented } from '../types/Represented';

import useGetRepresentedInstructions from '../hooks/useGetRepresentedInstructions';
import InstructionsTableRow from './SendVoteInstructionTableRow';
import InstructionsTablePlaceholder from './SendVoteInstructionsTablePlaceholder';

type Props = {
  selectedRepresented: SelectorOption[];
  board: Board;
  order: BoardOrder;
  voteValue: string;
};

const InstructionsTableView: FC<Props> = ({
  selectedRepresented,
  board,
  order,
  voteValue,
}: Props) => {
  const { t } = useTranslate();

  const { data: representedInstructions } = useGetRepresentedInstructions({
    board,
    order,
    represented: selectedRepresented,
    voteValue,
  });

  return (
    <div className="card card-bordered card-preview instructions-table-wrapper">
      <table className="table">
        <thead className="table-light">
          <tr>
            <th className="w-30">{t('Name')}</th>
            <th className="w-25">{t('Instructions')}</th>
          </tr>
        </thead>
        <tbody>
          {representedInstructions?.length > 0 ? (
            representedInstructions?.map(
              (represented: Represented, index: number) => (
                <InstructionsTableRow index={index} represented={represented} />
              )
            )
          ) : (
            <InstructionsTablePlaceholder />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InstructionsTableView;
