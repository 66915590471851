import i18n from 'i18n/config';
import { getNumberFormatted } from 'constants/formats';
import { getDashboardData } from 'modules/society/actions/dashBoardActions';

const getOptions = () => ({
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Resumen Sociedad',
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        label: (context) => {
          if (context.datasetIndex === 1) return '';
          return `${context.dataset?.label[context?.dataIndex] || ''}${
            context.dataset?.label[context?.dataIndex] ? ': ' : ''
          }${getNumberFormatted(context?.raw)}`;
        },
      },
    },
  },
  response: true,
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'month',
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        precision: 0,
      },
    },
  },
});

export const getSummaryChartViewData = async (societyId, dispatch) => {
  const summaryViewData = await dispatch(
    getDashboardData(societyId, 'summary')
  );

  const summaryChartViewData = summaryViewData.chartData;
  if (summaryChartViewData.length) {
    const lastOperation = summaryChartViewData[summaryChartViewData.length - 1];
    const today = new Date();
    if (
      new Date(lastOperation.date).toDateString() !== today.toDateString() &&
      new Date(lastOperation.date).getTime() < today.getTime()
    ) {
      summaryChartViewData.push({
        date: today,
        shares: lastOperation.shares,
        price: lastOperation.price,
        type: '',
      });
    }
  }

  const shares = summaryChartViewData.map((op) => op.shares);

  const labels = summaryChartViewData.map((op) => new Date(op.date));

  const operations = summaryChartViewData.map((op) => i18n.t(op.operationType));

  const options = getOptions();

  const chartData = {
    labels,
    datasets: [
      {
        fill: true,
        label: operations,
        data: shares,
        borderColor: '#798bff',
        backgroundColor: '#798bff5c',
        tension: 0.4,
        pointRadius: 3,
        pointHoverRadius: 10,
      },
    ],
  };
  return { kpisData: summaryViewData.kpisData, data: chartData, options };
};
