/* eslint-disable no-unused-vars */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { actions } from '../constants/actions';

import { useGetCliffOptions } from '../hook/useGetCliffOptions';
import { useGetVestingOptions } from '../hook/useGetVestingOptions';
import { useGetConsolidationOptions } from '../hook/useGetConsolidationOptions';
import { useGetSelectedOptions } from '../hook/useGetSelectedOptions';
import AddBeneficiaryModalCustomConsolidationView from './AddBeneficiaryModalCustomConsolidationView';

type Props = {
  action: (typeof actions)[keyof typeof actions];
  sharePrice: number;
  isFixedPrice: boolean;
  cliff: number;
  vestingPeriod: number;
  consolidation: number;
  hasEndMonthConsolidation: boolean;
  hasDecimalConsolidation: boolean;
  customConsolidation: boolean;
  customConsolidationType: string;
  consolidationPeriodsByPercent: number[];
  consolidationPeriodsByUnits: number[];
  handleChangeSharePrice: (event: any) => void;
  handleChangeIsFixedPrice: () => void;
  handleChangeCliff: (value: any) => void;
  handleChangeVestingPeriod: (value: any) => void;
  handleChangeConsolidation: (value: any) => void;
  handleChangeEndMonthConsolidation: (event: any) => void;
  handleChangeHasDecimalConsolidation: () => void;
  handleChangeHasCustomConsolidation: () => void;
  handleSelectPeriodsByPercent: () => void;
  handleConsolidationPeriodsChange: (
    index: number,
    value: string,
    type: string
  ) => void;
  handleSelectPeriodsByUnits: () => void;
};

const AddBeneficiaryModalConditionsView: FC<Props> = ({
  action,
  sharePrice,
  isFixedPrice,
  cliff,
  vestingPeriod,
  consolidation,
  hasEndMonthConsolidation,
  hasDecimalConsolidation,
  customConsolidation,
  customConsolidationType,
  consolidationPeriodsByPercent,
  consolidationPeriodsByUnits,
  handleChangeSharePrice,
  handleChangeIsFixedPrice,
  handleChangeCliff,
  handleChangeVestingPeriod,
  handleChangeConsolidation,
  handleChangeEndMonthConsolidation,
  handleChangeHasDecimalConsolidation,
  handleChangeHasCustomConsolidation,
  handleSelectPeriodsByPercent,
  handleConsolidationPeriodsChange,
  handleSelectPeriodsByUnits,
}) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const isFormDisabled = action === actions.WATCH;

  const { resultCliffOptions } = useGetCliffOptions().data;
  const { resultVestingOptions } = useGetVestingOptions().data;
  const { resultConsolidationOptions } = useGetConsolidationOptions().data;

  const selectedOptions = useGetSelectedOptions({
    cliff,
    vesting: vestingPeriod,
    consolidation,
    handleChangeCliff,
    handleChangeVesting: handleChangeVestingPeriod,
    handleChangeConsolidation,
  });

  const {
    selectedCliffOption,
    selectedVestingOption,
    selectedConsolidationOption,
  } = selectedOptions.data;

  const {
    handleChangeCliffOption,
    handleChangeVestingOption,
    handleChangeConsolidationOption,
  } = selectedOptions.actions;

  const DAILY_CONSOLIDATION = 0.033;
  const ONE_YEAR_VESTING = 12;

  const hasDailyConsolidation = consolidation === DAILY_CONSOLIDATION;
  const hasVestingMoreThanOneYear = vestingPeriod > ONE_YEAR_VESTING;
  const hasLessVestingThanConsolidation = +vestingPeriod < +consolidation;
  const isReadOnlyInteraction = action === actions.WATCH;

  const isDisabledConsolidation =
    !vestingPeriod ||
    !consolidation ||
    (hasDailyConsolidation && hasVestingMoreThanOneYear) ||
    hasLessVestingThanConsolidation ||
    isReadOnlyInteraction;

  const isDisabledConsolidationAtEndOfMonth = isDisabledConsolidation;
  const isDisabledDecimalConsolidation = isDisabledConsolidation;
  const isDisabledCustomConsolidation =
    isDisabledConsolidation || hasDailyConsolidation;

  const priceLabel = isFixedPrice
    ? t('FixedPriceExercisePrice')
    : t('PricePerUnitExercisePrice');

  const pricePlaceholder = isFixedPrice
    ? t('FixedPriceToPay')
    : t('PriceToPayForEachUnit');

  const handleOnWheel = (event: any) => {
    event.target.blur();
  };

  return (
    <div className="card card-bordered mb-2">
      <div className="card-inner">
        <div className="form-group">
          <label className="form-label" htmlFor="pay-amount">
            {priceLabel}
          </label>

          <div className="form-control-wrap">
            <div className="form-text-hint">
              <span className="overline-title">Eur</span>
            </div>
            <input
              type="number"
              className="form-control form-control-lg"
              value={sharePrice}
              onChange={handleChangeSharePrice}
              disabled={isFormDisabled}
              onWheel={handleOnWheel}
              min="0"
              step="0.000000001"
              placeholder={pricePlaceholder}
            />
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                <span
                  style={{
                    marginRight: '45px',
                    fontSize: '12px',
                    lineHeight: '1.125rem',
                    paddingTop: '0',
                  }}
                >
                  {t('FixedPrice')}
                </span>
                <input
                  type="checkbox"
                  id="price-switch"
                  className="custom-control-input float-right"
                  checked={!isFixedPrice}
                  disabled={action === actions.WATCH}
                  onChange={handleChangeIsFixedPrice}
                />
                <label className="custom-control-label" htmlFor="price-switch">
                  {t('PriceByUnit')}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="default-06">
            {t('Cliff')}
          </label>
          <span className="sub-text mb-3">
            {t('MinimumPermanenceTimeThat')}:
          </span>
          <div className="form-control-wrap">
            <Select
              closeMenuOnSelect
              className="react-select react-select-lg"
              options={resultCliffOptions}
              value={selectedCliffOption}
              components={animatedComponents}
              onChange={handleChangeCliffOption}
              placeholder={t('SelectCliff')}
              isDisabled={isFormDisabled}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="default-01">
            {t('VestingConsolidationPeriod')}
          </label>
          <span className="sub-text mb-3">
            {t('TheBeneficiariesWillBeEnt')}:
          </span>
          <div className="form-control-wrap ">
            <Select
              closeMenuOnSelect
              className="react-select react-select-lg"
              options={resultVestingOptions}
              value={selectedVestingOption}
              components={animatedComponents}
              onChange={handleChangeVestingOption}
              placeholder={t('SelectVestingPeriod')}
              isDisabled={isFormDisabled}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label" htmlFor="default-01">
            {t('ConsolidationSpeed')}
          </label>
          <span className="sub-text mb-3">
            {t('PeriodOfTimeItTakesToCons')}
          </span>
          <div className="form-control-wrap ">
            <Select
              closeMenuOnSelect
              className="react-select react-select-lg"
              options={resultConsolidationOptions}
              value={selectedConsolidationOption}
              components={animatedComponents}
              onChange={handleChangeConsolidationOption}
              placeholder={t('SelectConsolidationPeriod')}
              isDisabled={isFormDisabled}
            />
          </div>

          <div className="custom-control custom-control-xs custom-checkbox mt-2">
            <input
              type="checkbox"
              id="checkbox-end-month-consolidation"
              className="custom-control-input"
              checked={hasEndMonthConsolidation}
              disabled={isDisabledConsolidationAtEndOfMonth}
              onChange={handleChangeEndMonthConsolidation}
            />
            <label
              className="custom-control-label"
              htmlFor="checkbox-end-month-consolidation"
            >
              {t('ConsolidateAtEndOfMonth')}
            </label>
          </div>

          <div className="custom-control custom-control-xs custom-checkbox mt-2">
            <input
              type="checkbox"
              id="checkbox-decimal-consolidation"
              className="custom-control-input"
              checked={hasDecimalConsolidation}
              disabled={isDisabledDecimalConsolidation}
              onChange={handleChangeHasDecimalConsolidation}
            />
            <label
              className="custom-control-label"
              htmlFor="checkbox-decimal-consolidation"
            >
              {t('ConsolidateAsUnitsAccumulatedDecimals')}
            </label>
          </div>

          <div className="custom-control custom-control-xs custom-checkbox mt-2">
            <input
              type="checkbox"
              id="checkbox-consolidation-period"
              className="custom-control-input"
              checked={customConsolidation}
              disabled={isDisabledCustomConsolidation}
              onChange={handleChangeHasCustomConsolidation}
            />
            <label
              className="custom-control-label"
              htmlFor="checkbox-consolidation-period"
            >
              {t('CustomizeTheDifferentCons')}
            </label>
          </div>
        </div>

        {customConsolidation && (
          <AddBeneficiaryModalCustomConsolidationView
            action={action}
            customConsolidationType={customConsolidationType}
            consolidationPeriodsByPercent={consolidationPeriodsByPercent}
            consolidationPeriodsByUnits={consolidationPeriodsByUnits}
            handleSelectPeriodsByPercent={handleSelectPeriodsByPercent}
            handleConsolidationPeriodsChange={handleConsolidationPeriodsChange}
            handleSelectPeriodsByUnits={handleSelectPeriodsByUnits}
          />
        )}
      </div>
    </div>
  );
};

export default AddBeneficiaryModalConditionsView;
