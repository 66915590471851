import { User } from 'types';
import { store } from 'redux/store';
import { getUsers } from 'modules/profile/redux/userActions';

export const getSocietyMainAdmin = async (): Promise<User | null> => {
  try {
    const {
      society: { actualSociety },
    } = store.getState();

    if (!actualSociety) {
      return null;
    }

    const mainAdminId: string =
      actualSociety.mainAdmin || actualSociety.administrators?.[0];

    if (!mainAdminId) {
      return null;
    }

    const mainAdminList = await store.dispatch(
      getUsers({ usersList: [mainAdminId] })
    );

    return mainAdminList?.[0] || null;
  } catch (error) {
    return null;
  }
};
