import { FC } from 'react';

import ShareEncumbranceViewCoOwnersTableHeader from './ShareEncumbranceViewCoOwnersTableHeader';
import ShareEncumbranceViewCoOwnersTableBody from './ShareEncumbranceViewCoOwnersTableBody';

type Props = {
  coOwnersList: any;
  // eslint-disable-next-line no-unused-vars
  handleRemoveCoOwner: (index: number) => void;
};

const ShareEncumbranceViewCoOwnersTable: FC<Props> = ({
  coOwnersList,
  handleRemoveCoOwner,
}) => (
  <>
    <table className="table my-2">
      <ShareEncumbranceViewCoOwnersTableHeader />
      <ShareEncumbranceViewCoOwnersTableBody
        coOwnersList={coOwnersList}
        handleRemoveCoOwner={handleRemoveCoOwner}
      />
    </table>
  </>
);

export default ShareEncumbranceViewCoOwnersTable;
