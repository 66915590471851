import { useTranslate } from 'hooks/useTranslate';

type AssistentsModalHeaderProps = {
  assistsAll: boolean | null;
  searchQuery: string;
  handleChangeAssistsAll: (event: any) => void;
  handleSearch: (event: any) => void;
};

const AssistentsModalHeader = ({
  assistsAll,
  searchQuery,
  handleSearch,
  handleChangeAssistsAll,
}: AssistentsModalHeaderProps) => {
  const { t } = useTranslate();

  return (
    <div className="d-flex justify-content-between mb-2">
      <div className="d-flex align-items-center">
        <label className="form-label mb-0" htmlFor="fw-first-name">
          {t('HaveAllThePartnersAttended')}
        </label>
        <div className="custom-control custom-radio ml-3 mr-3">
          <input
            type="radio"
            id="yes-constituted"
            name="society-status"
            className="custom-control-input"
            checked={assistsAll === true}
            onChange={handleChangeAssistsAll}
          />
          <label className="custom-control-label" htmlFor="yes-constituted">
            {t('Yes')}
          </label>
        </div>
        <div className="custom-control custom-radio">
          <input
            type="radio"
            id="no-constituted"
            name="society-status"
            className="custom-control-input"
            checked={assistsAll === false}
            onChange={handleChangeAssistsAll}
          />
          <label className="custom-control-label" htmlFor="no-constituted">
            No
          </label>
        </div>
      </div>

      <div className="custom-control custom-radio w-250px">
        <div className="form-icon form-icon-left">
          <em className="icon ni ni-search" />
        </div>
        <input
          type="text"
          className="form-control form-control-sm"
          value={searchQuery}
          onChange={handleSearch}
          placeholder={t('SearchPartner')}
        />
      </div>
    </div>
  );
};

export default AssistentsModalHeader;
