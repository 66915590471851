import { SocietyChart } from 'types';
import formatData from './formatData';

type DiagramType = 'HOLDING' | 'PARTNER' | 'ADMIN';

function getChildren(
  holding: SocietyChart,
  level: number,
  input: any,
  diagramType: DiagramType
) {
  if (level >= input.length) return [];

  return input[level]
    ?.filter((child: any) => child.holdingReference.includes(holding.holdingId))
    ?.map((child: any) => {
      const childData: any = formatData(child, diagramType);
      const childChildren: any = getChildren(
        child,
        level + 1,
        input,
        diagramType
      );

      if (childChildren.length > 0) {
        childData.children = childChildren;
      }

      return childData;
    });
}

export default getChildren;
