import deleteSociety from 'utils/deleteSociety';

function societyOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('SeePartners'),
      url: '/socios',
    },
    /*
      {
        icon: 'ni-file-text',
        text: 'Ver Documentos',
        url: '/documentos'
      },
      */
    {
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      url: '/perfil-sociedad/datos',
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: deleteSociety,
    },
  ];
}
export default societyOptions;
