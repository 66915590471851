/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';
import screenSizes from 'constants/screenSizes';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Title'),
      field: 'title',
      selector: (row) => row[i18n.t('Title')],
      sortable: true,
      grow: 0.75,
      cell: (row) =>
        CellData.PlainTextLink({
          text: row[i18n.t('Title')],
          url: row[i18n.t('link')],
          translated: true,
        }),
      export: true,
    },
    {
      name: i18n.t('Employee'),
      field: 'employeeName',
      selector: (row) => row[i18n.t('Employee')],
      sortable: true,
      grow: 0.75,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Employee')],
        }),
      export: true,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('Date'),
      field: 'date',
      selector: (row) => row[i18n.t('Date')],
      sortable: true,
      width: '120px',
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('Date')],
        }),
      export: true,
    },
    {
      name: i18n.t('Notification'),
      field: 'message',
      selector: (row) => row[i18n.t('Notification')],
      sortable: true,
      grow: 2,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Notification')],
        }),
      export: true,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('Priority'),
      field: 'priority',
      selector: (row) => row[i18n.t('Priority')],
      sortable: true,
      grow: 0.5,
      compact: true,
      cell: (row) =>
        CellData.PriorityBadge({
          priority: row[i18n.t('Priority')],
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => {
        if (!row.isDemo) {
          return (
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="dropdown">
                  <MenuDots
                    menuOptions={row.actions}
                    id={row?.['_id']}
                    params={{
                      data: row,
                      notificationId: row?.['_id'],
                    }}
                    direction="left"
                  />
                </div>
              </li>
            </ul>
          );
        }
      },
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
      omit: true,
    },
  ];
}

export default tableColumns;
