/* eslint-disable import/prefer-default-export */
import { Beneficiary } from 'types';
import beneficiaryStatus from 'constants/beneficiaryStatus';

export const getBeneficiaryStatus = (beneficiary: Beneficiary): string => {
  if (beneficiary?.isCanceled) {
    return beneficiaryStatus.name.CANCELED;
  }

  if (beneficiary?.percentConsolidated || 0 < 100) {
    return beneficiaryStatus.name.ACTIVE;
  }

  return beneficiaryStatus.name.CONSOLIDATED;
};
