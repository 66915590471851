import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';
import { FC, useState } from 'react';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import successSwal from 'utils/successSwal';
import errorSwal from 'utils/errorSwal';

import AddCommitteeModalHeader from './AddCommitteeModalHeader';
import AddCommitteeModalBody from './AddCommitteeModalBody';
import AddCommitteeModalFooter from './AddCommitteeModalFooter';
import { createCommittee } from '../../../redux/committeeActions';

type Props = {
  societyId: string;
};

const AddCommitteeModal: FC<Props> = ({ societyId }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [name, setName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handelChangeName = (event: any) => {
    setName(event.target.value);
  };

  const handleSaveCommittee = async () => {
    try {
      setIsLoading(true);

      const newCommittee = {
        name,
        society: societyId,
        members: [],
        createdAt: new Date(),
      };

      await store.dispatch(createCommittee(newCommittee));

      successSwal(t('CommitteeCreatedSuccessfully'));
    } catch (error) {
      errorSwal(t('ErrorCreatingCommittee'));
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <AddCommitteeModalHeader handleCloseModal={handleCloseModal} />

      <AddCommitteeModalBody name={name} handleChangeName={handelChangeName} />

      <AddCommitteeModalFooter
        name={name}
        handleSaveCommittee={handleSaveCommittee}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default AddCommitteeModal;
