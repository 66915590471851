import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  label: string;
  value: string;
  onChange: any;
};

const PersonalDataViewFullName: FC<Props> = ({ label, value, onChange }) => {
  const { t } = useTranslate();
  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label w-100" htmlFor="partner-name">
          {label}
        </label>

        <input
          id="partner-name"
          type="text"
          className="form-control form-control-lg"
          value={value}
          onChange={onChange}
          placeholder={t('IntroduceFullName')}
        />
      </div>
    </div>
  );
};

export default PersonalDataViewFullName;
