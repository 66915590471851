/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { Tooltip } from 'react-tooltip';
import { transactionNames } from '../constants/partnerBookConstants';
import DownloadButton from './DownloadButton';

type PartnerBookTableHeaderProps = {
  societyId: string;
  columns: any;
  bookHeaders: any;
  bookOperations: any;
  bookOrder: any;
  bookDates: any;
  bookComments: any;
  bookDocuments: any;
  bookColumns: any;
};

const PartnerBookTableHeader: FC<PartnerBookTableHeaderProps> = ({
  societyId,
  columns,
  bookHeaders,
  bookOperations,
  bookOrder,
  bookDates,
  bookComments,
  bookDocuments,
  bookColumns,
}) => {
  const { t } = useTranslate();

  const isAdmin = useSelector((state: State) => state.society.isAdmin);

  return (
    <thead>
      <tr className="table-header">
        <th className="w-200px" />

        {Object.entries(bookHeaders)?.map(
          ([, header], index) =>
            header !== '' && (
              <th
                key={`${header}-${index}`}
                colSpan={
                  bookOperations[index] !== '' &&
                  columns[bookOperations[index]].colSpan
                }
                className={
                  bookOrder[index] % 2 === 0 ? 'bg-light-vert' : 'bg-white'
                }
              >
                <div
                  style={{ zIndex: 1 }}
                  data-tooltip-id={`${index}-${societyId}`}
                >
                  {bookHeaders[index] !== '' &&
                    t(
                      transactionNames[
                        bookHeaders[index] as keyof typeof transactionNames
                      ]
                    )}
                  <br />
                  {bookDates[index] !== '' &&
                    format(new Date(bookDates[index]), dateFormat)}
                </div>

                {isAdmin && (
                  <Tooltip id={`${index}-${societyId}`} place="bottom">
                    {bookComments[index]}
                  </Tooltip>
                )}

                <DownloadButton documents={bookDocuments?.[index]} />
              </th>
            )
        )}
      </tr>

      <tr className="text-center table-subheader">
        {Object.entries(bookColumns)?.map(([, column], index) =>
          index === 0 ? (
            <td key={`${column}-${index}`} className="bg-white w-200px">
              {t('Partners')}
            </td>
          ) : (
            <td
              key={`${column}-${index}`}
              className={
                bookOrder[index] % 2 === 0
                  ? 'bg-light-vert no-break '
                  : 'bg-white no-break '
              }
            >
              {column !== '' && t(column as string)}
            </td>
          )
        )}
      </tr>
    </thead>
  );
};

export default PartnerBookTableHeader;
