/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';

import NumberFormat from 'react-number-format';

import { currencyFormatDecimals, numberFormat } from 'constants/formats';

import {
  getDecimalScale,
  getInvestorFDPercent,
  getInvestorTotalContribution,
  getInvestorTotalNDPercent,
  getInvestorTotalNewShares,
} from 'utils/filters';
import { formatNumberDecimalsLong } from 'utils/formats';

function InvestorSociety({ society, investor }) {
  const { t } = useTranslate();

  const [fullName] = useState(investor?.name || '');

  const { societyValue } = investor;
  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );
  const [NDpercent] = useState(
    +getInvestorTotalNDPercent(investor, society).toFixed(decimalScale)
  );
  const [FDpercent] = useState(
    +getInvestorFDPercent(investor, society).toFixed(decimalScale)
  );

  const [totalContribution] = useState(
    getInvestorTotalContribution(investor, society)
  );
  const [totalNewShares] = useState(
    getInvestorTotalNewShares(investor, society)
  );

  return (
    <div className="card-inner">
      <div className="card-inner-group" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div
              className="simplebar-offset"
              style={{ right: '0px', bottom: '0px' }}
            >
              <div
                className="simplebar-content-wrapper"
                style={{ height: 'auto' }}
              >
                <div className="simplebar-content" style={{ padding: '0px' }}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2">
                      <div className="user-avatar lg bg-primary">
                        {society.additional.logo ? (
                          <img src={society.additional.logo} alt="Logo" />
                        ) : (
                          <span>{society.name.slice(0, 2).toUpperCase()}</span>
                        )}
                      </div>
                      <div className="user-info">
                        <h5>{fullName}</h5>
                        <div className="badge badge-outline badge-pill text-primary ucap">
                          {t('InvestorNoteC')}
                        </div>
                        <span className="sub-text">
                          {t('HeIsAnInvestorOfSocietyName', {
                            societyName: society.name,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">
                      {t('InvestmentOfFullnameInSoc', {
                        fullName,
                        societyName: society.name,
                      })}
                    </h6>
                    <div className="row text-center">
                      <div className="col-4">
                        <div className="profile-balance-amount">
                          <div className="number">
                            <NumberFormat
                              value={totalNewShares || 0}
                              displayType="text"
                              {...numberFormat}
                            />
                          </div>
                        </div>
                        <div className="profile-stats">
                          <span className="badge badge-outline">
                            {t('Shares')}
                          </span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="profile-balance-amount">
                          <div className="number">
                            {`${
                              NDpercent > 0
                                ? formatNumberDecimalsLong(NDpercent)
                                : 0
                            }%`}
                          </div>
                        </div>
                        <div className="profile-stats">
                          <span className="badge badge-outline">
                            {t('Percentage')}
                          </span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="profile-balance-amount">
                          <div className="number">
                            <NumberFormat
                              value={totalContribution || 0}
                              displayType="text"
                              {...currencyFormatDecimals}
                            />
                          </div>
                        </div>
                        <div className="profile-stats">
                          <span className="badge badge-outline">
                            {t('Contribution')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nk-fmg-status">
                    <h6 className="nk-fmg-status-title">
                      <em className="icon ni ni-meter" />
                      <span>
                        {`${t('Percentage')}: ${
                          NDpercent > 0
                            ? formatNumberDecimalsLong(NDpercent)
                            : 0
                        }%`}
                      </span>
                    </h6>
                    <div className="progress progress-md bg-light">
                      <div
                        className="progress-bar"
                        data-progress={NDpercent || 0}
                        style={{ width: `${NDpercent > 0 ? NDpercent : 0}%` }}
                      />
                    </div>
                    <div className="nk-fmg-status-info">
                      {`${
                        NDpercent > 0 ? formatNumberDecimalsLong(NDpercent) : 0
                      }% ND - ${
                        FDpercent > 0 ? formatNumberDecimalsLong(FDpercent) : 0
                      }% FD`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 'auto', height: '767px' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: '0px', display: 'none' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ height: '0px', display: 'none' }}
          />
        </div>
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </div>
  );
}

export default InvestorSociety;
