import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import i18n from 'i18n/config';
import './Buttons.scss';

const ExcelButton = ({ tableRef, label, fileName, sheetName, className }) => {
  const buttonLabel = label ? i18n.t(label) : i18n.t('DownloadXLS');
  const buttonFileName = fileName || 'Document';
  const buttonClassName = className || 'btn-white btn-outline-light';
  const buttonSheetName = sheetName || 'Sheet 1';

  return (
    <ReactHTMLTableToExcel
      id="custom-table-xls-button"
      className={`btn screen-only ${buttonClassName}`}
      table={tableRef}
      filename={buttonFileName}
      sheet={buttonSheetName}
      buttonText={buttonLabel}
    />
  );
};

export default ExcelButton;
