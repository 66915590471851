import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

const ShareEncumbranceViewTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <>
      <thead className="table-light">
        <tr>
          <th className="text-left">{t('Name')}</th>
          <th className="text-center">{t('Shares')}</th>
          <th className="text-center">{t('From')}</th>
          <th className="text-center">{t('To')}</th>
          <th className="text-center">{t('CoOwners')}</th>
          <th className="text-center" />
          <th className="text-center" />
        </tr>
      </thead>
    </>
  );
};

export default ShareEncumbranceViewTableHeader;
