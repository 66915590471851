/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { numberFormat, currencyFormatDecimals } from 'constants/formats';
import operationTypesRealNames from 'constants/operationTypesRealNames';
import { dayMonthYear } from 'utils/formatDate';
import { calculateConsolidationPercent } from 'utils/consolidationPeriods';
import menuOptions from './menuOptions';
import MenuDots from '../../../_shared/components/MenuDots';

const beneficiaryStatus = {
  CONSOLIDATED: 'Consolidado',
  ACTIVE: 'Activo',
  CANCELED: 'Cancelado',
};

function BeneficiaryRow({
  beneficiary,
  societyId,
  societyShares,
  index,
  sharesCount,
  planId,
  amount,
  documentURL,
}) {
  const plans = useSelector((state) => state.plans);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const [units, setUnits] = useState(0);
  const [incentive, setIncentive] = useState();
  const [planName, setPlanName] = useState();
  const [percentConsolidated, setPercentConsolidated] = useState();
  const [status, setStatus] = useState();
  const [consolidationDate, setConsolidationDate] = useState('');

  const [partnerFutureShares, setPartnerFutureShares] = useState();
  const [FDpercent, setFDpercent] = useState();

  const [certificateData, setCertificateData] = useState();

  useEffect(() => {
    const futureShares = sharesCount?.future || 0;

    setPartnerFutureShares(futureShares);
    setFDpercent(((futureShares * 100) / societyShares).toFixed(2));
  }, [societyShares, partnerFutureShares]);

  useEffect(() => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    let percent;
    if (
      beneficiary?.isCanceled &&
      new Date(beneficiary?.cancelationDate) <= new Date()
    )
      percent = Number(
        (
          (beneficiary.finalConsolidatedUnits * 100) /
          beneficiary.sharesCount.future
        ).toFixed(2)
      );
    else percent = calculateConsolidationPercent(Date.now(), beneficiary, plan);
    setPercentConsolidated(percent);
    const endDate = new Date(beneficiary?.planStartDate);
    endDate.setMonth(endDate.getMonth() + Number(plan?.vestingPeriod));

    let currentStatus;
    if (beneficiary?.isCanceled) currentStatus = beneficiaryStatus.CANCELED;
    else if (percent < 100) currentStatus = beneficiaryStatus.ACTIVE;
    else currentStatus = beneficiaryStatus.CONSOLIDATED;
    setStatus(currentStatus);

    const beneficiaryData = {
      ...beneficiary,
    };
    const planData = {
      sharePrice: plan?.sharePrice,
      startDate: plan?.startDate,
      planId,
    };
    const societyData = {
      name: actualSociety?.name,
      cif: actualSociety?.cif,
      administrators: actualSociety?.administrators,
      societyId,
    };

    setCertificateData({ societyData, beneficiaryData, planData });

    setConsolidationDate(dayMonthYear(endDate));
    setFDpercent(((sharesCount?.future * 100) / societyShares).toFixed(2));
    setIncentive(operationTypesRealNames[plan?.planType]);
    setPlanName(plan?.name);
    setUnits(sharesCount?.future);
  }, [plans]);

  const options = [...menuOptions];
  options[0].url = `/detalle-beneficiario/${societyId}/${planId}`;
  if (!isAdmin) {
    if (userId !== beneficiary.user) options[1].disabled = true;
    options[2].disabled = true;
    options[3].disabled = true;
    options[5].disabled = true;
  }

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col tb-col-xl">
        <span>{index}</span>
      </td>

      <td className="nk-tb-col">
        <div className="user-card">
          <div className="user-avatar bg-dim-primary d-none d-md-flex">
            {beneficiary.image ? (
              <img
                src={beneficiary.image}
                alt={beneficiary.name.slice(0, 2).toUpperCase()}
                className="rounded-circle"
              />
            ) : (
              <span>{beneficiary.name.slice(0, 2).toUpperCase()}</span>
            )}
          </div>
          <div className="user-info">
            <span className="tb-lead">
              <Link
                to={`/detalle-beneficiario/${societyId}/${planId}/${beneficiary['_id']}`}
              >
                {beneficiary.name}
              </Link>
              <span className="dot dot-success d-md-none ml-1" />
            </span>
            <span className="d-block">{beneficiary.email}</span>
            {status === beneficiaryStatus.CANCELED && (
              <span className="badge rounded-pill text-danger">
                {beneficiaryStatus.CANCELED}
              </span>
            )}
            {status === beneficiaryStatus.CONSOLIDATED && (
              <span className="badge rounded-pill text-success">
                {beneficiaryStatus.CONSOLIDATED}
              </span>
            )}
            {status === beneficiaryStatus.ACTIVE && (
              <span className="badge rounded-pill text-warning">
                {beneficiaryStatus.ACTIVE}
              </span>
            )}
          </div>
        </div>
      </td>

      <td className="nk-tb-col" data-order="35040.34">
        <NumberFormat
          value={units && units.toFixed()}
          displayType="text"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...numberFormat}
        />
      </td>

      <td className="nk-tb-col tb-col-xl">
        <NumberFormat
          value={amount || 0}
          displayType="text"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...currencyFormatDecimals}
        />
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{FDpercent > 0 ? `${FDpercent}%` : '-'}</span>
      </td>

      <td className="nk-tb-col">
        <span>{incentive}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{planName}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          <div className="progress progress-lg">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              data-progress={percentConsolidated}
              style={{ width: percentConsolidated }}
            >
              {percentConsolidated}%
            </div>
          </div>
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{consolidationDate}</span>
      </td>

      <td className="nk-tb-col nk-tb-col-tools">
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={options}
                id={beneficiary['_id']}
                params={certificateData}
                downloadURL={documentURL}
                direction="left"
              />
            </div>
          </li>
        </ul>
      </td>
    </tr>
  );
}

export default BeneficiaryRow;
