import { FC } from 'react';

import { Encumbrance } from 'types';

import ShareEncumbranceViewTableHeader from './components/ShareEncumbranceViewTableHeader';
import ShareEncumbranceViewTableBody from './components/ShareEncumbranceViewTableBody';

type Props = {
  encumbrances: Encumbrance[];
  isEditing: boolean;
  // eslint-disable-next-line no-unused-vars
  handleEditEncumbrance: (index: number) => void;
  // eslint-disable-next-line no-unused-vars
  handleRemoveEncumbrance: (index: number) => void;
};

const ShareEncumbranceTable: FC<Props> = ({
  encumbrances,
  isEditing,
  handleEditEncumbrance,
  handleRemoveEncumbrance,
}) => (
  <>
    <table className="table my-2">
      <ShareEncumbranceViewTableHeader />
      <ShareEncumbranceViewTableBody
        encumbrances={encumbrances}
        isEditing={isEditing}
        handleEditEncumbrance={handleEditEncumbrance}
        handleRemoveEncumbrance={handleRemoveEncumbrance}
      />
    </table>
  </>
);

export default ShareEncumbranceTable;
