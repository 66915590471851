import { FC } from 'react';
import { Board } from 'types';
import { useTranslate } from 'hooks/useTranslate';
import BoardDocuments from '../BoardDocuments';

type BoardPartnerDocumentsProps = {
  board: Board;
  partnerId: string;
};

const BoardPartnerDocuments: FC<BoardPartnerDocumentsProps> = ({
  board,
  partnerId,
}) => {
  const { t } = useTranslate();

  return (
    <div className="nk-block">
      <div className="row">
        <div className="col-xl-12">
          <div className="card card-bordered">
            <div className="card-inner pt-0">
              <BoardDocuments
                board={board}
                partnerId={!!partnerId}
                title={t('Documents')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardPartnerDocuments;
