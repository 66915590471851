export const checkLoginRedirect = (societies: Record<string, any>): void => {
  const hasOnlyOneSociety = societies?.length === 1;

  if (!hasOnlyOneSociety) {
    return;
  }

  const society = societies[0];
  const societyId = society?.society._id;
  const hasLoginRedirect = society?.hasLoginRedirect;

  if (hasLoginRedirect) {
    window.location.href = `#/home/${societyId}`;
  }
};
