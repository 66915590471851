import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';

type Props = {
  rowsCount: number;
  rowHeight?: number;
};

const DocumentsListLoader: FC<Props> = ({ rowsCount = 5, rowHeight = 20 }) => {
  const totalHeight = rowsCount * rowHeight + 60;

  const getKey = (index: number) => `row_${index}`;

  return (
    <>
      <ContentLoader
        width="100%"
        height={totalHeight}
        backgroundColor="#eaeced"
        foregroundColor="#ffffff"
        style={{ width: '100%' }}
      >
        {Array(rowsCount)
          .fill(0)
          .map((_, row) => (
            <React.Fragment key={getKey(row)}>
              <rect
                x="3%"
                y={`${20 + row * 30}`}
                rx="3"
                ry="3"
                width="50%"
                height="20"
              />
              <rect
                x="55%"
                y={`${20 + row * 30}`}
                rx="3"
                ry="3"
                width="30%"
                height="20"
              />
              <rect
                x="87%"
                y={`${20 + row * 30}`}
                rx="3"
                ry="3"
                width="10%"
                height="20"
              />
            </React.Fragment>
          ))}
      </ContentLoader>
    </>
  );
};

export default DocumentsListLoader;
