import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { useTranslate } from 'hooks/useTranslate';
import { Tooltip } from 'react-tooltip';
import { Board, PartnerRepresentant, Society } from 'types';

type Props = {
  currentRepresentant: PartnerRepresentant;
  currentBoard: Board;
  currentSociety: Society;
};

const BoardPartnerRepresentantsCard = ({
  currentRepresentant,
  currentBoard,
  currentSociety,
}: Props) => {
  const { t } = useTranslate();

  const { image, name, email } = currentRepresentant || {};

  return (
    <div className="board-partner-representants-card card card-bordered p-2 w-fit-content">
      <div className="d-flex">
        <h5>{t('PartnerRepresentant')}</h5>
        <em
          className="icon ni ni-info text-gray ml-1 cursor-help"
          id="representant-card-tooltip"
        />
        <Tooltip
          anchorId="representant-card-tooltip"
          className="w-max-300px"
          place="top"
        >
          {t('BoardPartnerRepresentantCardTooltip')}
        </Tooltip>
      </div>

      <div className="user-card">
        <div className="user-avatar bg-dim-primary d-flex ">
          <img
            src={image || BLANK_PROFILE_PICTURE}
            alt={name}
            className="rounded-circle"
          />
          <div className="status dot dot-lg dot-success" />
        </div>

        <div className="user-info">
          <span className="text-primary">{name}</span>
          <span className="d-block text-ellipsis">{email}</span>
        </div>
      </div>
    </div>
  );
};

export default BoardPartnerRepresentantsCard;
