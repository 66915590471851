import { useTranslate } from 'hooks/useTranslate';

type Props = {
  page: number;
  setPage: (page: number) => void; // eslint-disable-line no-unused-vars
  naturalPerson: boolean;
  hasPersonOptions: () => boolean;
};

const EditModalNavTabs = ({
  page,
  setPage,
  naturalPerson,
  hasPersonOptions,
}: Props) => {
  const { t } = useTranslate();

  return (
    <ul className="nk-nav nav nav-tabs mt-xl">
      <li className="nav-item" onClick={() => setPage(0)}>
        <p className={`nav-link ${page === 0 && 'active'}`}>
          {t('PersonalInformation')}
        </p>
      </li>

      <li className="nav-item" onClick={() => setPage(1)}>
        <p className={`nav-link ${page === 1 && 'active'}`}>{t('Address')}</p>
      </li>

      {!naturalPerson && hasPersonOptions() && (
        <li className="nav-item" onClick={() => setPage(2)}>
          <p className={`nav-link ${page === 2 && 'active'}`}>
            {t('DeedAndRegistryData')}
          </p>
        </li>
      )}

      {!naturalPerson && hasPersonOptions() && (
        <li className="nav-item" onClick={() => setPage(3)}>
          <p className={`nav-link ${page === 3 && 'active'}`}>
            {t('LegalPartnerRepresentative')}
          </p>
        </li>
      )}

      <li className="nav-item" onClick={() => setPage(4)}>
        <p className={`nav-link ${page === 4 && 'active'}`}>
          {t('OtherInformation')}
        </p>
      </li>
    </ul>
  );
};

export default EditModalNavTabs;
