import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getPartnerRepresentants } from 'modules/partners/redux/partnerRepresentantsActions';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { getActualSociety, getSocietyValue } from 'utils/filters';
import menuTypes from 'constants/menuTypes';
import { PartnerRepresentant, User } from 'types';
import { State } from 'redux/initialState';

type UsePartnerRepresentantDetailProps = {
  user: User;
};

const usePartnerRepresentantDetail = ({
  user,
}: UsePartnerRepresentantDetailProps) => {
  const dispatch = useDispatch();
  const { societyId, partnerRepresentantId } = useParams();

  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  const [actualPartnerRepresentant, setActualPartnerRepresentant] = useState<
    PartnerRepresentant & { societyValue: number }
  >();

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (user && actualSociety) {
      const societyValue = getSocietyValue(actualSociety);
      const partnerRepresentant = partnerRepresentants.find(
        (representant) => representant['_id'] === partnerRepresentantId
      );
      if (partnerRepresentant) {
        const partnerRepresentantData = {
          ...partnerRepresentant,
          societyValue,
        };
        setActualPartnerRepresentant(partnerRepresentantData);
      }
    }
  }, [
    partnerRepresentantId,
    societyId,
    user,
    actualSociety,
    partnerRepresentants,
  ]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyName: actualSociety?.name,
          societyId: actualSociety?.['_id'],
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (societyId) {
      dispatch(getPartnerRepresentants(societyId));
    }
  }, [societyId, dispatch]);

  return {
    data: {
      actualPartnerRepresentant,
      actualSociety,
    },
  };
};

export default usePartnerRepresentantDetail;
