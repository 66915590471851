/* eslint-disable react/jsx-props-no-spreading */
import { FC, memo } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import NumberFormat from 'react-number-format';

import { currencyFormatDecimals } from 'constants/formats';
import { Tooltip } from 'react-tooltip';

type BeneficiaryAsidePersonalSectionProps = {
  currentVestedDate: string;
  currentVestedValue: number;
};

const BeneficiaryAsidePersonalSection: FC<
  BeneficiaryAsidePersonalSectionProps
> = ({ currentVestedDate, currentVestedValue }) => {
  const { t } = useTranslate();

  return (
    <div className="card-inner">
      <div className="overline-title-alt mb-2 text-center">
        {t('ActualVestedValue')}
      </div>

      <div className="profile-balance">
        <div className="profile-balance-group">
          <div className="profile-balance-sub mx-auto">
            <div className="profile-balance-amount">
              <div
                className="number text-teal d-flex justify-content-center align-items-start"
                style={{ textAlign: 'center' }}
              >
                <NumberFormat
                  value={currentVestedValue || 0}
                  displayType="text"
                  {...currencyFormatDecimals}
                />

                <em
                  className="icon ni ni-info notification-icon ml-1 text-primary"
                  data-tooltip-id="icon-actual-vested-value-message"
                />
              </div>
            </div>

            <Tooltip
              id="icon-actual-vested-value-message"
              place="bottom"
              style={{ maxWidth: '300px', zIndex: 99 }}
            >
              {t('ActualVestedValueDetails')}
            </Tooltip>

            <div className="profile-balance-subtitle text-center">
              {t('VestedValue')} - {currentVestedDate} -{' '}
              {t('VestedValueDetails')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BeneficiaryAsidePersonalSection);
