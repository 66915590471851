import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import MenuItem from './MenuItem';

import '../SideNav.scss';

type Props = {
  societyId: string;
};

const AdministrativesMenu: FC<Props> = ({ societyId }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="10">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-network" />
            </span>
            <span className="nk-menu-text">{t('AdminOrgan')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="10">
        <ul className="nk-menu-sub">
          <MenuItem linkTo={`/miembros/${societyId}`} text="Members" />

          <MenuItem linkTo={`/comites/${societyId}`} text="Committees" />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default AdministrativesMenu;
