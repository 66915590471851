import { Range } from 'types';

export default function removeRangeFromRanges(
  from: number,
  to: number,
  ranges: Range[]
): Range[] {
  const result: Range[] = [];
  ranges.forEach((range) => {
    if (range.from < from && range.to > to) {
      result.push(
        { from: range.from, to: from - 1 },
        { from: to + 1, to: range.to }
      );
    } else if (range.from === from && range.to > to) {
      result.push({ from: to + 1, to: range.to });
    } else if (range.from < from && range.to === to) {
      result.push({ from: range.from, to: from - 1 });
    } else if (range.from !== from && range.to !== to) {
      result.push(range);
    }
  });
  return result;
}
