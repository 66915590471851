import documentLinkTypes from 'constants/documentLinkTypes';
import uploadDocument from 'modules/profile/components/UserMyData/utils/uploadDocument';
import { Partner, User } from 'types';

type Props = {
  updatedPartner: Partial<Partner>;
  constitutionFile: File | null;
  sindicationFile: File | null;
  appointmentFile: File | null;
  ownershipFile: File | null;
  partnerId: string;
  societyId: string;
  user: User;
};

export default async function getPartnerData({
  updatedPartner,
  constitutionFile,
  sindicationFile,
  appointmentFile,
  ownershipFile,
  partnerId,
  societyId,
  user,
}: Props) {
  const partnerData: Partial<Partner> = {
    name: updatedPartner?.name,
    cif: updatedPartner?.cif,
    email: updatedPartner?.email,
    emails: updatedPartner?.emails,
    birthdate: updatedPartner?.birthdate,
    employeeNumber: updatedPartner?.employeeNumber,
    nationality: updatedPartner?.nationality,
    phone: updatedPartner?.phone,
    category: updatedPartner?.category,
    sindication: updatedPartner?.sindication,
    sindicationRef: updatedPartner?.sindicationRef,
    sindicationType: updatedPartner?.sindicationType,
    civilStatus: updatedPartner?.civilStatus,
    image: updatedPartner?.image,
    address: updatedPartner?.address,
    taxAddress: updatedPartner?.taxAddress,
    bankAccountNumber: updatedPartner?.bankAccountNumber,
    isNaturalPerson: updatedPartner?.isNaturalPerson,
  };

  if (!updatedPartner?.isNaturalPerson) {
    partnerData.representative = updatedPartner?.representative;
    partnerData.legalInfo = updatedPartner?.legalInfo;

    if (constitutionFile) {
      const uploadedDocument = await uploadDocument({
        file: constitutionFile,
        societyId,
        subcategory: '',
        user,
        linkedTo: [
          { linkedId: partnerId, linkedType: documentLinkTypes.PARTNER },
        ],
      });

      partnerData.constitutionDocument = uploadedDocument?.['_id'];
    }

    if (appointmentFile) {
      const uploadedDocument = await uploadDocument({
        file: appointmentFile,
        societyId,
        subcategory: '',
        user,
        linkedTo: [
          { linkedId: partnerId, linkedType: documentLinkTypes.PARTNER },
        ],
      });

      partnerData.appointmentDocument = uploadedDocument?.['_id'];
    }

    if (ownershipFile) {
      const uploadedDocument = await uploadDocument({
        file: ownershipFile,
        societyId,
        subcategory: '',
        user,
        linkedTo: [
          { linkedId: partnerId, linkedType: documentLinkTypes.PARTNER },
        ],
      });

      partnerData.ownershipDocument = uploadedDocument?.['_id'];
    }
  }

  if (sindicationFile) {
    const uploadedDocument = await uploadDocument({
      file: sindicationFile,
      societyId,
      subcategory: '',
      user,
      linkedTo: [
        { linkedId: partnerId, linkedType: documentLinkTypes.PARTNER },
      ],
    });

    partnerData.sindicationDocument = uploadedDocument?.['_id'];
  }

  if (partnerData.sindication === null) {
    partnerData.sindicationDocument = null;
  }

  return partnerData;
}
