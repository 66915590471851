import { translate } from 'hooks/useTranslate';

type OperationLabels = {
  [key: string]: string;
};

export const operationLabels: OperationLabels = {
  ADD_PARTNER: translate('AddPartner'),
  CONSTITUTION: translate('Constitution'),
  CAPITAL_INCREASE: translate('CapitalIncrease'),
  CAPITAL_DECREASE: translate('CapitalDecrease'),
  SELL_PARTICIPATION: translate('SELL_PARTICIPATION'),
  HERITAGE: translate('Heritage'),
  DONATION: translate('Donation'),
  SPLIT: translate('Split'),
  RENUMERATION: translate('Renumeration'),
  RECLASSIFICATION: translate('Reclassification'),
  DIVIDENDS_DISTRIBUTION: translate('DividendsDistribution'),
};
