import sendEmail from 'modules/communications/redux/mailActions';
import { store } from 'redux/store';
import { Society } from 'types';

type RepresentantEmailDataProps = {
  t: any;
  language: string;
  email: string;
  actualSociety: Society;
  representantId: string;
  action?: 'ADD';
};

export const getAddRepresentantEmailData = ({
  t,
  language,
  email,
  actualSociety,
  representantId,
}: RepresentantEmailDataProps) => ({
  to: [{ email, name }],
  subject: t('EmailNewPartnerRepresentantSubject'),
  template: `add-partner-representant-${language}`,
  templateData: [
    {
      name: 'newRepresentantName',
      content: name,
    },
    {
      name: 'societyName',
      content: actualSociety.name,
    },
    {
      name: 'targetUrl',
      content: `${process.env.REACT_APP_DOMAIN}/#/juntas/${actualSociety?._id}/${representantId}`,
    },
  ],
});

export const sendNotificationEmail = ({
  t,
  language,
  email,
  actualSociety,
  representantId,
}: RepresentantEmailDataProps) => {
  const emailData = getAddRepresentantEmailData({
    t,
    language,
    email,
    actualSociety,
    action: 'ADD',
    representantId,
  });

  store.dispatch(sendEmail(emailData));
};
