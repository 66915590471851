import { useTranslate } from 'hooks/useTranslate';
import React from 'react';

import { Document } from 'types';

import UploadDocumentsSectionRow from './UploadDocumentsSectionRow';

type Props = {
  documents: Document[];
  setDocuments: any;
};

const UploadDocumentsSectionList = (props: Props) => {
  const { documents, setDocuments } = props;

  const { t } = useTranslate();

  const getKey = (document: Document, index: number) =>
    `${document.name}-${index}`;

  return (
    <div className="card-borderer col-12 pt-0">
      <div className="nk-files nk-files-view-list">
        <div className="nk-files-head">
          <div className="nk-file-item">
            <div className="nk-file-info">
              <div className="tb-head ml-4">{t('Name')}</div>
            </div>

            <div className="nk-file-meta d-none d-xl-table-cell text-center">
              <div className="tb-head">{t('DocumentDate')}</div>
            </div>
          </div>
        </div>

        <div className="nk-files-list">
          {documents?.map((document, index) => (
            <React.Fragment key={getKey(document, index)}>
              <UploadDocumentsSectionRow
                index={index}
                document={document}
                documents={documents}
                setDocuments={setDocuments}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UploadDocumentsSectionList;
