/* eslint-disable array-callback-return */
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { store } from 'redux/store';

import AddSocietyAuditor from 'modules/_shared/components/Modals/AddSocietyAuditor';
import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

import SocietyAuditorRow from './SocietyAuditorsRow';
import statusTypes from './statusTypes';

function SocietyAuditors({ society, userId }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isAdmin, isReadOnly } = useGetSocietySectionPermissions().data;

  const [auditors, setAuditors] = useState([]);
  const [seeNotCurrent, setSeeNotCurrent] = useState(false);

  const changeView = () => {
    setSeeNotCurrent(!seeNotCurrent);
  };

  const handleOpenModal = () => {
    if (isReadOnly) return;

    store.dispatch(
      setModal(
        <AddSocietyAuditor
          societyId={society['_id']}
          action="ADD"
          userId={userId}
        />
      )
    );
  };

  const getStatus = (auditor) => {
    if (!auditor?.auditorStatus) {
      if (!auditor?.endDate || new Date(auditor?.endDate) - new Date() > 0)
        return statusTypes.var.ACTIVE;
      return statusTypes.var.INACTIVE;
    }
    return auditor?.auditorStatus;
  };

  useEffect(() => {
    dispatch(getDocuments({ society: society['_id'] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    if (society) {
      const filteredAuditors = society?.auditors?.filter((auditor) => {
        if (
          seeNotCurrent ||
          (!seeNotCurrent && getStatus(auditor) === statusTypes.var.ACTIVE)
        ) {
          return auditor;
        }
      });

      setAuditors(
        filteredAuditors?.map((auditor) => (
          <SocietyAuditorRow
            key={auditor['_id']}
            userId={userId}
            auditorId={auditor['_id']}
            societyId={society['_id']}
            socialDenomination={auditor.socialDenomination}
            nationality={auditor.nationality}
            initDate={auditor.initDate}
            endDate={auditor.endDate}
            cif={auditor.cif}
            address={auditor.address}
            email={auditor.email}
            auditorStatus={auditor.auditorStatus}
            documents={auditor.documents}
          />
        ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society, seeNotCurrent]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">{t('CompanyAuditors')}</h5>
          <div className="nk-block-des">
            <p>{t('TheseAreThePeopleOrCompanAuditors')}</p>
          </div>
        </div>
      </div>

      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            <div
              className="nk-block-head-content"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="btn btn-success"
                onClick={handleOpenModal}
                disabled={isReadOnly}
              >
                {t('AddAuditor')}
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => changeView()}
                >
                  <em
                    className={
                      !seeNotCurrent ? 'icon ni ni-eye' : 'icon ni ni-eye-off'
                    }
                  />
                  <span>
                    {!seeNotCurrent ? t('SeeNotCurrent') : t('HideNotCurrent')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : null}

        <div className="card card-bordered card-preview">
          <div className="table-responsive">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                  <th className="nk-tb-col">
                    <span>{t('NameAndSurnameSociety')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('Nationality')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('AppointmentDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('EndDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('E-Mail')}</span>
                  </th>
                  <th className="nk-tb-col">
                    <span>{t('Status')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl text-center">
                    <span>{t('Doc')}</span>
                  </th>
                  {!isReadOnly && (
                    <th className="nk-tb-col">
                      <span />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {auditors?.length ? (
                  auditors
                ) : (
                  <NoItemsAvailable mainMessage={t('NoAuditorsAvailable')} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietyAuditors;
