import { getDocument } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { store } from 'redux/store';
import { Board } from 'types';

async function getDocumentById(documentId: string) {
  try {
    const document = await store.dispatch(getDocument(documentId));

    return document;
  } catch (error) {
    console.error(error);
  }
}

export default function useGetBoardAnnouncementDocuments(board: Board) {
  const [announcementDocument, setAnnouncementDocument] =
    useState<Document | null>(null);
  const [announcementTemplate, setAnnouncementTemplate] =
    useState<Document | null>(null);

  useEffect(() => {
    const getDocuments = async () => {
      const announcementDocumentId = board?.announcementDocument;
      const announcementTemplateId = board?.announcementTemplate;

      if (!announcementDocumentId || !announcementTemplateId) {
        return;
      }

      const document = await getDocumentById(announcementDocumentId);
      const template = await getDocumentById(announcementTemplateId);
      setAnnouncementDocument(document);
      setAnnouncementTemplate(template);
    };

    getDocuments();
  }, [board]);

  return { announcementDocument, announcementTemplate };
}
