import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';
import { Society } from 'types';
import { calculateConsolidationPercent } from 'utils/consolidationPeriods';
import {
  getBeneficiaryAmount,
  getBeneficiaryFDPercent,
  getSocietyValueAtDate,
  getVestedPercent,
} from 'utils/filters';

type GetSimulatorValuesProps = {
  society: Society;
  societyValue: Record<string, any>;
  beneficiaryAllPlans: Record<string, any>[];
};

const getVestedNow = (beneficiaries: Record<string, any>[]) =>
  beneficiaries?.reduce((acc, curr) => {
    let vested;

    if (curr?.isCanceled) {
      vested = curr?.finalConsolidatedUnits;
    } else {
      const percent =
        calculateConsolidationPercent(new Date(), curr, curr?.planData) || 0;

      vested = curr?.sharesCount
        ? Math.floor((percent * (curr?.sharesCount?.future || 0)) / 100)
        : 0;
    }

    if (curr?.soldUnits) {
      vested -= curr?.soldUnits;
    }
    return acc + vested;
  }, 0);

const getCurrentValues = (
  society: Record<string, any>,
  societyValue: Record<string, any>,
  beneficiaries: Record<string, any>[]
) =>
  beneficiaries?.reduce(
    (acc, benef) => {
      const sharePrice =
        benef?.customConditions?.sharePrice || benef?.planData?.sharePrice;

      const soldAmount =
        benef?.customConditions?.isFixedPrice || benef?.planData?.isFixedPrice
          ? sharePrice
          : (benef?.soldUnits || 0) * sharePrice;

      const currentAmount =
        (getBeneficiaryAmount(benef, benef?.planData) || 0) - soldAmount;

      const currentFD = getBeneficiaryFDPercent(benef, society, true);

      const currentIncentiveValue = societyValue
        ? (+currentFD * societyValue.value) / 100
        : 0;

      acc.incentiveValue += currentIncentiveValue || currentAmount || 0;
      acc.exercisePrice += currentAmount || 0;
      acc.FDpercent += getBeneficiaryFDPercent(benef, society, true);

      return acc;
    },
    { incentiveValue: 0, exercisePrice: 0, FDpercent: 0 }
  );

const getSimulatorValues = ({
  society,
  societyValue,
  beneficiaryAllPlans,
}: GetSimulatorValuesProps) => {
  if (!society || !societyValue || !beneficiaryAllPlans) {
    return {};
  }

  const vestedNow = getVestedNow(beneficiaryAllPlans);

  const shareValue =
    getSocietyValueAtDate(society, new Date())?.shareValue ?? 0;

  const incentiveNow = shareValue
    ? vestedNow * shareValue
    : (getVestedPercent(vestedNow, society) * societyValue?.value) / 100;

  const currentVestedValue = incentiveNow;
  const currentVestedDate = format(new Date(), dateFormat);

  const currentValues = getCurrentValues(
    society,
    societyValue,
    beneficiaryAllPlans
  );

  const comparisonValues = {
    incentiveValue: currentValues.incentiveValue,
    exercisePrice: currentValues.exercisePrice,
  };

  const higherValue = Math.max(
    currentValues.incentiveValue,
    currentValues.exercisePrice
  );

  const incentiveValue = higherValue;
  const { exercisePrice, FDpercent } = currentValues;

  const summary = {
    ...currentValues,
    incentiveValue: higherValue,
  };

  return {
    currentVestedValue,
    currentVestedDate,
    comparisonValues,
    incentiveValue,
    exercisePrice,
    FDpercent,
    summary,
  };
};

export default getSimulatorValues;
