import { combineReducers } from 'redux';

import user from '../modules/profile/redux/userReducer';
import partnerBook from '../modules/partners/redux/partnerBookReducer';
import menu from '../modules/_shared/redux/menuReducer';
import sideNav from '../modules/_shared/redux/sideNavReducer';
import alerts from '../modules/_shared/redux/alertReducer';
import documents from '../modules/documents/redux/documentReducer';
import modal from '../modules/_shared/redux/modalReducer';
import society from '../modules/society/redux/societyReducer';
import plans from '../modules/beneficiaries/redux/planReducer';
import drafts from '../modules/drafts/redux/draftReducer';
import holdingClasses from '../modules/partners/redux/holdingClassReducer';
import tenderOffers from '../modules/transactions/redux/tenderOfferReducer';
import tenderOffersShares from '../modules/transactions/redux/tenderOfferSharesReducer';
import boards from '../modules/boards/redux/boardReducer';
import communications from '../modules/communications/redux/communicationReducer';
import preview from '../modules/_shared/redux/previewReducer';
import notification from '../modules/_shared/redux/notificationReducer';
import fullWidth from '../modules/_shared/redux/fullWidthReducer';
import employees from '../modules/beneficiaries/redux/employeesReducer';
import employeesPlans from '../modules/beneficiaries/redux/employeesPlanReducer';
import partnerCategories from '../modules/partners/redux/partnerCategoryReducer';
import partnerRepresentants from '../modules/partners/redux/partnerRepresentantsReducer';
import globalNotifications from '../modules/_shared/redux/globalNotificationReducer';
import guests from '../modules/society/redux/guestsReducer';
import committees from '../modules/administratives/redux/committeeReducer';

const rootReducer = combineReducers({
  menu,
  sideNav,
  alerts,
  modal,
  user,
  society,
  partnerBook,
  documents,
  plans,
  drafts,
  holdingClasses,
  tenderOffers,
  tenderOffersShares,
  boards,
  communications,
  preview,
  notification,
  fullWidth,
  employees,
  employeesPlans,
  partnerCategories,
  partnerRepresentants,
  globalNotifications,
  guests,
  committees,
});

export default rootReducer;
