function modeOptions(i18n) {
  return [
    {
      name: 'plans-view',
      icon: 'ni-book-read',
      text: i18n.t('ByPlans'),
      action: ({ setMode }) => {
        setMode(0);
      },
      dropdown: true,
    },
    {
      name: 'beneficiaries-view',
      icon: 'ni-book-read',
      text: i18n.t('ByBeneficiaries'),
      action: ({ setMode }) => {
        setMode(1);
      },
      dropdown: true,
    },
    {
      name: 'beneficiary-and-plans-view',
      icon: 'ni-book-read',
      text: i18n.t('ByBeneficiariesAndPlans'),
      action: ({ setMode }) => {
        setMode(2);
      },
      dropdown: true,
    },
  ];
}
export default modeOptions;
