/* eslint-disable no-prototype-builtins */

/**
 * @param {object} option
 * @returns {string} 'BENEFICIARY' | 'PARTNER' | 'PLAN' | 'HOLDING_CLASS' | 'UKNOWN'
 */
const getEligibleType = (option) => {
  if (option.hasOwnProperty('plan')) {
    return 'BENEFICIARY';
  }

  if (option.hasOwnProperty('partnerNumber')) {
    return 'PARTNER';
  }

  if (option?.hasOwnProperty('poolType')) {
    return 'PLAN';
  }

  if (option.hasOwnProperty('votes')) {
    return 'HOLDING_CLASS';
  }

  return 'UKNOWN';
};

export default getEligibleType;
