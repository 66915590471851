export default {
  var: {
    sent: 'sent',
    reclaimed: 'reclaimed',
    signed: 'signed',
    completed: 'completed',
  },
  text: {
    sent: 'Pending',
    reclaimed: 'Reclaimed',
    signed: 'Signed',
    completed: 'Signed',
  },
  badge: {
    sent: 'secondary',
    reclaimed: 'warning',
    signed: 'success',
    completed: 'success',
  },
};
