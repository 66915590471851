/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { formatNumber } from 'utils/formats';

function AssistantRow({ assistant, print = false }) {
  const { t } = useTranslate();

  let badgeData = { elem: '', text: '' };

  if (assistant.isRepresented) {
    badgeData = { elem: 'badge-info', text: t('Represented') };
  } else if (assistant.assists) {
    badgeData = { elem: 'badge-success', text: t('Yes') };
  } else if (assistant.isAbsent) {
    badgeData = { elem: 'badge-danger', text: t('Nope') };
  }

  return !print ? (
    <tr>
      <td className="text-left">
        <span>{assistant.index}</span>
      </td>
      <td className="text-left">
        <span>{assistant.name}</span>
      </td>
      <td className="text-left">
        <span className="date">{formatNumber(assistant.sharesCount)}</span>
      </td>
      <td className="text-left">
        <span className="date">{assistant.percentage?.toFixed(3)}%</span>
      </td>
      <td className="text-left">
        <span className="text">{assistant?.representative?.name || '-'}</span>
      </td>
      <td className="text-left">
        <span className={`badge badge-check ${badgeData.elem}`}>
          {badgeData.text}
        </span>
      </td>
    </tr>
  ) : (
    <tr>
      <td className="text-left">{assistant.index}</td>
      <td className="text-left" style={{ minWidth: '150px' }}>
        {assistant.name}
      </td>
      <td className="text-right">{formatNumber(assistant.sharesCount)}</td>
      <td className="text-right">{assistant.percentage?.toFixed(3)}%</td>
      <td className="text-left">{assistant?.representative?.name || '-'}</td>
      <td className="text-left">{badgeData.text}</td>
      <td
        className="text-left text-wrap"
        style={{ fontSize: '4px', color: 'black' }}
      >
        {assistant.member}
      </td>
    </tr>
  );
}
export default AssistantRow;
