/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';

import { dateFormat } from 'constants/formats';

import {
  getRepresentativeStatus,
  getRepresentativeType,
} from '../../utils/getRepresentativeValues';

type Props = {
  representative: Record<string, any> | undefined;
};

const RepresentativeSocietyMetrics = ({ representative }: Props) => {
  const { t } = useTranslate();

  return (
    <div className="card-inner">
      <h6 className="overline-title-alt mb-2">Resumen del Apoderado</h6>
      <div className="row text-center">
        <div className="col-4">
          <div className="profile-balance-amount">
            <div className="number">
              {format(new Date(representative?.initDate), dateFormat) || '-'}
            </div>
          </div>
          <div className="profile-stats">
            <span className="badge badge-outline-primary">
              {t('RepresentativeDate')}
            </span>
          </div>
        </div>
        <div className="col-4">
          <div className="profile-balance-amount">
            <div className="number">
              {getRepresentativeType(representative?.representativeType)}
            </div>
          </div>
          <div className="profile-stats">
            <span className="badge badge-outline-primary">
              {t('TypeOfAuthority')}
            </span>
          </div>
        </div>
        <div className="col-4">
          <div className="profile-balance-amount">
            <div className="number">
              {getRepresentativeStatus(representative?.representativeStatus)}
            </div>
          </div>
          <div className="profile-stats">
            <span className="badge badge-outline-primary">{t('Status')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentativeSocietyMetrics;
