import { Envelope } from 'types';

export function getEnvelopeFromType(
  envelopes: Envelope[],
  type: string
): Envelope {
  return envelopes.find(
    (envelope) => envelope?.type === type && envelope?.status === 'ACTIVE'
  )!;
}
