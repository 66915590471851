import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { actions } from '../constants/actions';

type Props = {
  action: (typeof actions)[keyof typeof actions];
  name: string;
  cif: string;
  email: string;
  plan: string;
  date: string;
  shares: string;
  validEmail: boolean;
  handleSaveBeneficiary: () => void;
};

const AddBeneficiaryModalFooter: FC<Props> = ({
  action,
  name,
  cif,
  email,
  plan,
  date,
  shares,
  validEmail,
  handleSaveBeneficiary,
}) => {
  const { t } = useTranslate();

  const isButtonDisabled =
    !name || !cif || !email || !plan || !date || !shares || !validEmail;

  const buttonText = action === actions.EDIT ? t('Save') : t('AddBeneficiary');

  return (
    <>
      <Modal.Footer className="bg-light d-flex justify-content-between">
        <span className="sub-text">{t('TheBeneficiaryWillReceive')}</span>
        <button
          type="button"
          className="btn btn-lg btn-primary"
          disabled={isButtonDisabled}
          onClick={handleSaveBeneficiary}
        >
          {buttonText}
        </button>
      </Modal.Footer>
    </>
  );
};

export default AddBeneficiaryModalFooter;
