/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { getNameInitials } from 'utils/filters';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';

import { generateColorScaleSVG } from 'modules/dashboard/utils/generateColorScaleSVG';
import WidgetLegalTableExtendedCell, {
  cellFormats,
} from '../WidgetLegalTableExtendedCell';
import { isMobileScreen } from '../../../../utils/getScreenSize';

export type WidgetLegalTableData = {
  icon?: string;
  image?: string;
  name: string;
  value1: string | number;
  value2?: string | number;
  value3?: string | number;
  link?: string;
};

type WidgetLegalTableExtendedProps = {
  title: string;
  linkText: string;
  linkUrl: string;
  columns: string[];
  data: WidgetLegalTableData[];
  maxRows?: number;
  isReadOnly?: boolean;
  isFullWidth?: boolean;
};

const WidgetLegalTableExtended: FC<WidgetLegalTableExtendedProps> = ({
  title,
  linkText,
  linkUrl,
  columns,
  data,
  maxRows = 10,
  isReadOnly = false,
  isFullWidth = false,
}) => {
  const headerTextHoverColor = useSelector(
    (state: State) =>
      state.society?.actualSociety?.customization?.theme?.headerTextHoverColor
  );

  const containerClass = isFullWidth ? 'col-12' : 'col-12 col-xl-6';
  const waveClass = {
    backgroundImage: `url("${generateColorScaleSVG(headerTextHoverColor)}")`,
  };
  const isNumber = (value: string | number) => typeof value === 'number';

  const getColumnHeaderStyle = (column: string, index: number) => {
    switch (index) {
      case 0:
        return 'text-left';
      case 1:
        return isNumber(data[0]?.value1) ? 'text-right' : 'text-left';
      case 2:
        return isNumber(data[0]?.value2 || '') ? 'text-right' : 'text-left';
      case 3:
        return isNumber(data[0]?.value3 || '') ? 'text-right' : 'text-left';
      default:
        return '';
    }
  };

  return (
    <div className={`${containerClass} mb-4`}>
      <div className="card card-bordered h-100">
        <div className="card-inner mb-n2 bg-wave" style={waveClass}>
          <div className="card-title-group">
            <div className="card-title card-title-sm">
              <h6 className="title">{title}</h6>
            </div>
            <div className="card-tools">
              {!isReadOnly && (
                <Link to={linkUrl} className="link">
                  {linkText}
                </Link>
              )}
            </div>
          </div>
        </div>

        <table className="table nk-tb-list nk-tb-ulist">
          <thead>
            <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px border-top">
              {columns?.map((column, index) => (
                <th
                  key={`${column}-${index}-${title}`}
                  className={`nk-tb-col ${getColumnHeaderStyle(column, index)}`}
                >
                  <span>{column}</span>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data?.slice(0, maxRows)?.map((item, index) => (
              <tr className="nk-tb-item" key={`${item.name}-${index}`}>
                <td
                  className="nk-tb-col"
                  style={{ paddingTop: 3, paddingBottom: 3 }}
                >
                  <div className="icon-text">
                    {item?.icon && !item?.image && (
                      <div className="d-flex justify-content-center align-center h-30px w-30px mr-2">
                        <em
                          className={`text-primary w-20px h-20px icon ni ${item?.icon}`}
                        />
                      </div>
                    )}

                    {item?.image && (
                      <div className="user-avatar d-flex h-30px w-30px mr-2">
                        {item.image === BLANK_PROFILE_PICTURE ? (
                          <span className="fs-12px">
                            {getNameInitials(item.name)}
                          </span>
                        ) : (
                          <img
                            src={item.image}
                            alt={item.name || ''}
                            className="rounded-circle h-30px w-30px"
                          />
                        )}
                      </div>
                    )}

                    {item.link && !isReadOnly ? (
                      <Link to={item.link} className="link mw-150px h-20px">
                        <span className="tb-lead text-ellipsis h-20px lh-24px">
                          {item?.name}
                        </span>
                      </Link>
                    ) : (
                      <span
                        className="tb-lead text-ellipsis mw-150px h-20px"
                        style={{
                          width: isMobileScreen() ? '120px' : '200px',
                        }}
                      >
                        {item?.name}
                      </span>
                    )}
                  </div>
                </td>

                {item?.value1 !== undefined && (
                  <WidgetLegalTableExtendedCell
                    value={item?.value1}
                    format={cellFormats.NUMBER_FORMAT}
                  />
                )}

                {item?.value2 !== undefined && (
                  <WidgetLegalTableExtendedCell
                    value={item?.value2}
                    format={cellFormats.NUMBER_FORMAT_DECIMALS}
                  />
                )}

                {item?.value3 !== undefined && (
                  <WidgetLegalTableExtendedCell
                    value={item?.value3}
                    format={cellFormats.NUMBER_FORMAT_DECIMALS}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WidgetLegalTableExtended;
