import statusTypes from "./statusTypes";

export default [
  {
    name: statusTypes.name.ACTIVE,
    value: statusTypes.var.ACTIVE,
  },
  {
    name: statusTypes.name.INACTIVE,
    value: statusTypes.var.INACTIVE,
  },
  {
    name: statusTypes.name.FINISHED,
    value: statusTypes.var.FINISHED,
  },
]