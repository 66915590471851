import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';

import { Document } from 'types';
import downloadFile from 'utils/downloadFile';
import { getFileDate } from 'utils/files';
import { getDocumentIcon } from 'utils/documentIcons';

import { setModal } from 'modules/_shared/redux/modalActions';
import ReplaceDocumentModal from 'modules/_shared/components/Modals/ReplaceDocumentModal';

type TransactionDetailsDocumentsRowProps = {
  index: number;
  document: Document;
  setIsDocumentReplaced: (setIsDocumentReplaced: boolean) => void; // eslint-disable-line
};

const TransactionDetailsDocumentsRow: FC<
  TransactionDetailsDocumentsRowProps
> = ({ index, document, setIsDocumentReplaced }) => {
  const dispatch = useDispatch();

  const handleReplaceDocument = () => {
    dispatch(
      setModal(
        <ReplaceDocumentModal
          document={document}
          setIsDocumentReplaced={setIsDocumentReplaced}
        />
      )
    );
  };

  return (
    <div
      className="nk-file-item nk-file documents-section-row"
      key={`document-${index}`}
    >
      <div className="nk-file-info">
        <div className="nk-file-title w-100">
          <div className="nk-file-icon">
            <span className="nk-file-icon-type">
              <img src={getDocumentIcon(document, false)} alt="icon-file" />
            </span>
          </div>

          <div className="nk-file-name">
            <div
              className="nk-file-name-text d-flex flex-column"
              style={{ maxWidth: '300px' }}
            >
              <span
                className="title text-ellipsis cursor-pointer"
                onClick={() => {
                  downloadFile({ documentId: document?._id });
                }}
                title={document?.name}
              >
                {document?.name}
              </span>
              <span className="fw-light text-muted text-ellipsis fs-12px">
                {document?.description || ''}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-file-meta d-none d-xl-table-cell">
        <div className="tb-lead text-center">{getFileDate(document)}</div>
      </div>

      <div className="nk-file-meta d-none d-xl-table-cell">
        <div className="tb-lead text-center">{document?.author?.name}</div>
      </div>

      <div className="text-center">
        <button
          type="button"
          className="text-soft btn btn-icon"
          onClick={handleReplaceDocument}
        >
          <em className="icon ni ni-files-fill" />
        </button>
      </div>
    </div>
  );
};

export default memo(TransactionDetailsDocumentsRow);
