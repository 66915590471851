import i18n from 'i18n/config';

export const columns = {
  CONSTITUTION: { colSpan: 2 },
  CAPITAL_INCREASE: { colSpan: 3 },
  STOCK_OPTION: { colSpan: 3 },
  PHANTOM_SHARE: { colSpan: 3 },
  CAPITAL_DECREASE: { colSpan: 3 },
  SELL_PARTICIPATION: { colSpan: 4 },
  HERITAGE: { colSpan: 4 },
  DONATION: { colSpan: 4 },
  SPLIT: { colSpan: 3 },
};

export const titleColumn = {
  NUEVAS: 'PB_New',
  TOTAL: 'PB_Total',
  PERCENT: 'PB_%',
  PARTNERS: 'Socios',
};

export const transactionNames = {
  ADD_PARTNER: 'AddPartner',
  CONSTITUTION: 'Constitution',
  CAPITAL_INCREASE: 'CapitalIncrease',
  CAPITAL_DECREASE: 'CapitalDecrease',
  SELL_PARTICIPATION: 'SELL_PARTICIPATION',
  HERITAGE: 'Heritage',
  DONATION: 'Donation',
  SPLIT: 'Split',
  RENUMERATION: 'Renumeration',
  RECLASSIFICATION: 'Reclassification',
  DIVIDENDS_DISTRIBUTION: 'DividendsDistribution',
  CAPITAL_DECREASE_DRAFT: 'CapitalIncreaseDraft',
  SELL_PARTICIPATION_DRAFT: 'SellParticipationsDraft',
};
