/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';

import screens from 'constants/screens';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';

import transactionOptions from './transactionOptions';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Operation'),
      field: 'operationName',
      selector: (row) => row[i18n.t('Operation')],
      sortable: true,
      omit: false,
      cell: (row) =>
        CellData.TransactionType({
          transaction: row.transaction,
          society: row.society,
          url: '/detalle-transacciones',
          type: 'warning',
        }),
      export: true,
      grow: 2,
    },
    {
      name: i18n.t('CommentsLabel'),
      field: 'comments',
      selector: (row) => row[i18n.t('Comments')],
      sortable: true,
      compact: true,
      omit: false,
      export: true,
      grow: 2,
      cell: (row) =>
        row.transaction?.comments ? row.transaction.comments : '-',
    },
    {
      name: i18n.t('Date'),
      field: 'date',
      selector: (row) => row[i18n.t('Date')],
      sortable: true,
      compact: true,
      center: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('Date')],
        }),
      export: true,
    },
    {
      name: i18n.t('NrPart'),
      field: 'participations',
      selector: (row) => row[i18n.t('NrPart')],
      sortable: true,
      center: true,
      compact: true,
      grow: 1,
      hide: screens.LG,
      cell: (row) =>
        CellData.TransactionParticipations({
          participations: row[i18n.t('NrPart')],
        }),
      export: true,
    },
    {
      name: i18n.t('Amount'),
      field: 'amount',
      selector: (row) => row[i18n.t('Amount')],
      sortable: true,
      center: true,
      compact: true,
      grow: 2,
      hide: screens.MD,
      cell: (row) =>
        CellData.TransactionAmount({
          amount: row[i18n.t('Amount')],
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={transactionOptions}
                id={row.transaction.society}
                params={{
                  operation: row?.transaction,
                  isReadOnly: row?.isReadOnly,
                }}
                direction="left"
              />
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}
export default tableColumns;
