export const getDataCapitalDetailsChart = ({
  t,
  capitalChartViewData,
  selectedTheme,
}) => {
  const labels = [
    t('Shares'),
    t('ConvertibleLoans'),
    t('Stockoptions'),
    t('Phantomshares'),
  ];

  const details = [
    capitalChartViewData?.societyActualShares,
    capitalChartViewData?.convertibleNotesShares,
    capitalChartViewData?.stockOptionsShares,
    capitalChartViewData?.phantomShares,
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
      },
      title: {
        display: false,
        text: 'Resumen Sociedad',
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: 'Total',
        data: details,
        borderWidth: 2,
        borderColor: selectedTheme === 'dark' ? '#101924' : '#ffffff',
        backgroundColor: [
          '#798bff',
          '#b8acff',
          '#ffa9ce',
          '#7de1f8',
          '#f9db7b',
        ],
      },
    ],
  };

  return { data, options };
};
