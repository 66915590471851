import React from 'react';
import { STTOK_LOGO } from 'constants/defaultConstants';

const LogoSttokPrint = () => (
  <div className="">
    <img
      id="print-only"
      src={STTOK_LOGO}
      alt="Logo"
      className="rounded-0"
      style={{ height: '30px' }}
    />
  </div>
);

export default LogoSttokPrint;
