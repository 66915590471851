import cancelationTypes from 'constants/cancelationTypes';
import { Beneficiary } from 'types';
import getFinalConsolidatedUnits from './getFinalConsolidatedUnits';

type Props = {
  beneficiary: Beneficiary;
  plans: any[];
  cancelationDate: Date;
  cancelationMode: string;
};

export default function getBeneficiaryUpdateData({
  beneficiary,
  plans,
  cancelationDate,
  cancelationMode,
}: Props) {
  let finalConsolidatedUnits = 0;

  if (cancelationMode === cancelationTypes.PARTIAL) {
    finalConsolidatedUnits = getFinalConsolidatedUnits(
      beneficiary,
      plans,
      cancelationDate
    );
  } else if (cancelationMode === cancelationTypes.CONSOLIDATED) {
    finalConsolidatedUnits = beneficiary?.sharesCount?.future || 0;
  }

  return {
    isCanceled: true,
    cancelationDate: new Date(cancelationDate),
    cancelationOption: cancelationMode,
    finalConsolidatedUnits,
  };
}
