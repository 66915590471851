import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { State } from 'redux/initialState';

import menuTypes from 'constants/menuTypes';
import { getActualSociety } from 'utils/filters';

import { setMenu } from 'modules/_shared/redux/menuActions';
import SocietyGuests from 'modules/society/components/SocietyGuests';
import SocietyAdministrators from 'modules/society/components/SocietyAdministrator';

const societyUsersPages = {
  SOCIETY_ADMINS: 'SOCIETY_ADMINS',
  SOCIETY_GUESTS: 'SOCIETY_GUESTS',
};

const SocietyUsers = () => {
  const { t } = useTranslate();
  const { societyId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const hasCustomLanding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasCustomLanding
  );

  const [page, setPage] = useState<string>(societyUsersPages.SOCIETY_ADMINS);
  const [pages, setPages] = useState<Record<string, React.ReactElement>>({});

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety]); // eslint-disable-line

  useEffect(() => {
    const viewPages = {
      [societyUsersPages.SOCIETY_ADMINS]: <SocietyAdministrators />,
      [societyUsersPages.SOCIETY_GUESTS]: <SocietyGuests />,
    };

    setPages(viewPages);
  }, [actualSociety, hasCustomLanding]);

  return (
    <div className="pt-2">
      <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
        <li className="nav-item">
          <p
            id="admins-users-tab"
            className={`nav-link ${
              page === societyUsersPages.SOCIETY_ADMINS && 'active'
            }`}
            onClick={() => setPage(societyUsersPages.SOCIETY_ADMINS)}
          >
            <em className="icon ni ni-account-setting-alt" />
            <span>{t('AdminUsers')}</span>
          </p>
        </li>

        {hasCustomLanding && (
          <li className="nav-item">
            <p
              id="guests-users-tab"
              className={`nav-link ${
                page === societyUsersPages.SOCIETY_GUESTS && 'active'
              }`}
              onClick={() => setPage(societyUsersPages.SOCIETY_GUESTS)}
            >
              <em className="icon ni ni-user-list" />
              <span>{t('GuestUsers')}</span>
            </p>
          </li>
        )}
      </ul>
      {pages[page]}
    </div>
  );
};

export default SocietyUsers;
