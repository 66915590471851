import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';

export const useGetSocietySectionPermissions = () => {
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);
  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);

  const adminPermissions = useSelector(
    (state: State) => state.society?.permissions
  );

  const isReadOnly = isDemo || adminPermissions?.societyConfiguration?.readOnly;

  return {
    data: { isAdmin, isDemo, isReadOnly },
  };
};
