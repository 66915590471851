import voteValidationTypes from 'constants/voteValidationTypes';
import { Board, Society } from 'types';
import { getPartnerActualShares } from 'utils/filters';
import getPartnerNDPercent from 'utils/getPartnerNDPercent';
import getOrderVotesData from '../components/BoardParticipants/BoardVotesTable/utils/getOrderVotesData';

type AddValuesToAgreementProps = {
  index: number;
  subject?: string;
  voteType?: string;
  favor: number;
  against: number;
  voteValidation?: string;
};

const addValuesToAgreement = ({
  index,
  subject = '',
  voteType = '',
  favor,
  against,
  voteValidation,
}: AddValuesToAgreementProps) => {
  const agreement: string = `<b>${
    index + 1
  }. </b><span style="font-size: 11pt; ">${subject}.</span></p>`;

  const textNoCalculate = ``; // TBD

  const textFullInFavor = `<p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt; "><span style="font-size: 11pt; font-family: arial;">Sometido a votación, se aprueba por unanimidad.</span></p><br />`;

  const textFullAgainst = `<p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: arial;">Sometido a votación, no se aprueba por unanimidad.</span></p><br />`;

  const textPartialInFavor = `<p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: arial;">Sometido a votación, se aprueba con los votos favorables correspondientes al </span><span style="font-size: 11pt; font-family: arial; ">${
    favor?.toFixed(2) || '[X]'
  }</span><span style="font-size: 11pt; font-family: arial;">% de las participaciones en que se divide el capital social. Votos en contra que corresponden al </span><span style="font-size: 11pt; font-family: arial; ">${
    against?.toFixed(2) || '[X]'
  }</span><span style="font-size: 11pt; font-family: arial;">% del capital social.</span></p><br />`;

  const textPartialAgainst = `<p style="text-align: justify; margin-top: 0pt; margin-bottom: 0pt;"><span style="font-size: 11pt; font-family: arial;">Sometido a votación, no se aprueba con los votos en contra correspondientes al </span><span style="font-size: 11pt; font-family: arial; ">${
    against?.toFixed(2) || '[X]'
  }</span><span style="font-size: 11pt; font-family: arial;">% de las participaciones en que se divide el capital social. Votos favorables que corresponden al </span><span style="font-size: 11pt; font-family: arial; ">${
    favor?.toFixed(2) || '[X]'
  }</span><span style="font-size: 11pt; font-family: arial;">% del capital social.</span></p><br />`;

  let description = '';

  if (voteValidation === voteValidationTypes.NO_CALCULATE.value) {
    description = textNoCalculate;
  } else {
    if (favor === 100) {
      description = textFullInFavor;
    } else if (against === 100) {
      description = textFullAgainst;
    }

    if (!description) {
      switch (voteValidation) {
        case voteValidationTypes.SIMPLE_MAJORITY.value:
          if (favor > against) {
            description = textPartialInFavor;
          } else if (against > favor) {
            description = textPartialAgainst;
          } else if (favor === against) {
            // TODO: add description EMPATE
          }
          break;

        case voteValidationTypes.SOCIETY_CAPITAL_50.value:
          if (favor >= 50) {
            description = textPartialInFavor;
          } else if (against >= 50) {
            description = textPartialAgainst;
          } else {
            // TODO: add description NADIE ALCANZA EL 50%
            // Aqui solo son 2 respuestas
          }
          break;

        case voteValidationTypes.SOCIETY_CAPITAL_66.value:
          if (favor >= 66) {
            description = textPartialInFavor;
          } else if (against >= 66) {
            description = textPartialAgainst;
          } else {
            // TODO: add description NADIE ALCANZA EL 66%
            // Aqui solo son 2 respuestas
          }
          break;

        case voteValidationTypes.PRESENT_CAPITAL_50_VOTE_33.value:
          // Tiene q estas +50% de las shares
          // q la suma de los porcentajes de los votos sea mayor q 33.33%
          break;
        default:
          break;
      }
    }
  }

  if (voteType === 'NONE') {
    return agreement;
  }
  return agreement + description;
};

export const getBoardAgreements = (board: Board, society: Society) => {
  const agreements: string[] = [];
  const votes: any = {};

  board.participants
    ?.filter(
      (participant) => getPartnerActualShares(participant.member, society) > 0
    )
    ?.forEach((participant) => {
      participant.votes.forEach((vote) => {
        const partner = society.partners.find(
          (p) => p._id === participant.member
        );
        const percent = getPartnerNDPercent(partner, society);

        const order = vote.order || 0;

        if (votes[order]) {
          votes[order].push({ ...vote, percent });
        } else {
          votes[order] = [{ ...vote, percent }];
        }
      });
    });

  board?.orders?.forEach((order, index) => {
    const votesData = getOrderVotesData(order, board.participants, society);
    const agreement = addValuesToAgreement({
      index,
      ...order,
      ...votesData,
    });
    agreements.push(agreement);
  });

  return agreements.join('');
};
