/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import documentTypes from 'constants/documentTypes';
import { setModal } from 'modules/_shared/redux/modalActions';
import DocumentModal from 'modules/documents/pages/Documents/documentModal';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';

import PartnerDocumentsRow from './PartnerDocumentsRow';

const InvestorDocuments = ({ society, investor }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { user, isAdmin, documents } = useSelector((state) => ({
    user: state.user,
    isAdmin: state.society?.role?.isAdmin,
    documents: state.documents,
  }));

  const { isReadOnly } = useGetPartnersSectionPermissions().data;

  const fullName = investor.name || '';

  const rows = documents
    ?.filter((currentDoc) =>
      investor?.documents.some(
        (docId) =>
          docId === currentDoc['_id'] &&
          !currentDoc.isForAdmins &&
          currentDoc.category !== documentTypes.DELETED
      )
    )
    .map((filteredDocument, index) => (
      <PartnerDocumentsRow
        document={filteredDocument}
        index={index + 1}
        key={filteredDocument?.['_id']}
      />
    ));

  useEffect(() => {
    if (society) {
      dispatch(getDocuments({ society: society['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  return (
    <div className="card-inner card-inner-lg">
      <div className="nk-block-head">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('Documents')}</h4>
            <div className="nk-block-des">
              <p>
                {t('InvestorDocumentationPar', {
                  investorName: fullName,
                  societyName: society.name,
                })}
              </p>
            </div>
          </div>

          <div className="nk-block-head-content">
            {isAdmin && (
              <div className="nk-block float-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    dispatch(
                      setModal(
                        <DocumentModal
                          userId={user['_id']}
                          societyId={society['_id']}
                          investorId={investor['_id']}
                        />
                      )
                    )
                  }
                  disabled={isReadOnly}
                >
                  <em className="icon ni ni-upload mr-1" />
                  {t('UpDocument')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {rows.length ? (
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered card-preview">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col" scope="col">
                    {t('No')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Name')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Date')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Doc')}
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InvestorDocuments;
