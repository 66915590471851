export default [
  'Name',
  'Shares count',
  'Fiscal Identification Number',
  'Shares from',
  'Shares to',
  'Shares class',
  'Shares premium',
  'Address',
  'Postal code',
  'City',
  'Country',
  'Email',
  'Nationality',
  'Civil status',
  'Birth date',
  'Profession',
];

export const partnerParamsContext = [
  'Exclude any prefixes such as "Don," "Doña," "Mr," "Mrs," etc',
];

export const partnerLabels = {
  Name: 'Name',
  'Shares count': 'Shares',
  'Fiscal Identification Number': 'CIF',
  'Shares from': 'SharesFrom',
  'Shares to': 'SharesTo',
  'Shares class': 'Class',
  'Shares premium': 'Premium',
  Address: 'Address',
  City: 'City',
  Country: 'Country',
  Email: 'Email',
  Nationality: 'Nationality',
  'Civil status': 'CivilStatus',
  'Birth date': 'DateOfBirth',
  Profession: 'JobTitle',
};

export const partnerInputTypes = {
  Name: 'text',
  'Fiscal Identification Number': 'text',
  'Shares count': 'number',
  'Shares from': 'number',
  'Shares to': 'number',
  'Shares class': 'text',
  'Shares premium': 'number',
  Address: 'text',
  City: 'text',
  Country: 'text',
  Email: 'text',
  Nationality: 'text',
  'Civil status': 'text',
  'Birth date': 'date',
  Profession: 'text',
};
