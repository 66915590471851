/* eslint-disable react/no-array-index-key */
import { numberFormat } from 'constants/formats';
import { FC } from 'react';
import NumberFormat from 'react-number-format';

import { CoOwner } from 'types';
import { formatNumberDecimals } from 'utils/formats';

type Props = {
  index: number;
  coOwner: CoOwner;
  // eslint-disable-next-line no-unused-vars
  handleRemoveCoOwner: (index: number) => void;
};

const ShareEncumbranceViewCoOwnersTableRow: FC<Props> = ({
  index,
  coOwner,
  handleRemoveCoOwner,
}) => (
  <>
    <tr>
      <td className="text-left text-primary" key={`co-owner-name-${index}`}>
        {coOwner?.name}
      </td>

      <td className="text-left" key={`co-owner-cif-${index}`}>
        {coOwner?.cif}
      </td>

      <td className="text-left" key={`co-owner-email-${index}`}>
        {coOwner?.email}
      </td>

      <td className="text-right" key={`co-owner-percent-${index}`}>
        <NumberFormat
          value={formatNumberDecimals(coOwner?.percent)}
          displayType="text"
          suffix="%"
          decimalSeparator={numberFormat.decimalSeparator}
        />
      </td>

      <td className="text-justify" key={`co-owner-comment-${index}`}>
        {coOwner?.comment}
      </td>

      <td className="text-center" key={`co-owner-remove-${index}`}>
        <button
          type="button"
          className="text-soft btn btn-icon pt-0 pb-0"
          onClick={() => handleRemoveCoOwner(index)}
        >
          <em className="icon ni ni-trash" />
        </button>
      </td>
    </tr>
  </>
);

export default ShareEncumbranceViewCoOwnersTableRow;
