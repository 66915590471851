import { translate } from 'hooks/useTranslate';

import { SocietyChart } from 'types';
import { formatNumber } from 'utils/formats';

const getSharesInfo = (society: SocietyChart) => {
  const { holdingShares } = society;

  if (holdingShares) {
    return `${formatNumber(holdingShares)} ${translate('Shares')}`;
  }

  return `0 ${translate('Shares')}`;
};

export default getSharesInfo;
