/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import sendEmail from 'modules/communications/redux/mailActions';
import {
  addDocument,
  getDocument,
} from 'modules/documents/redux/documentActions';
import { updateUser } from 'modules/profile/redux/userActions';
import Nouislider from 'nouislider-react';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import wNumb from 'wnumb';

import documentLinkTypes from 'constants/documentLinkTypes';
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import { cleanValue, currencyFormatDecimals } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import sharesCertificateTemplate from 'constants/sharesCertificateTemplate';

import activePartner from 'utils/activePartner';
import {
  getDecimalScale,
  getDirectorStatus,
  getPartnerInversion,
  getPartnerNDPercent,
} from 'utils/filters';

import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import statusTypes from 'modules/society/components/SocietyDirectors/statusTypes';

import { useTranslate } from 'hooks/useTranslate';
import html2pdf from 'html3pdf';
import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';

const PartnerAside = ({
  user,
  isAdmin,
  partner,
  society,
  incorporationDate,
  partnerNumber,
  setPage,
  setIsCardVisible,
}) => {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { societyValue } = partner;

  const { isReadOnly } = useGetPartnersSectionPermissions().data;

  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );
  const [NDpercent] = useState(
    getPartnerNDPercent(partner, society).toFixed(decimalScale)
  );

  const [participationsValue] = useState(
    societyValue ? (+NDpercent * societyValue.value) / 100 : 0
  );
  const sliderStep = participationsValue
    ? +((societyValue.value * 10) / 100).toFixed(2)
    : 0;
  const [valuation, setValuation] = useState(participationsValue);
  const [inversion] = useState(getPartnerInversion(partner['_id'], society));
  const [sign, setSign] = useState('');

  const [currStatusTypes, setCurrStatusTypes] = useState(statusTypes(i18n));
  const [currDirectorTypes, setCurrDirectorTypes] = useState(
    directorTypes(i18n)
  );

  /* CERTIFICATE */
  const [societyAdmin, setSocietyAdmin] = useState();
  const [hasConstitution, setHasConstitution] = useState(false);
  const [hasAnnualData, setHasAnnualData] = useState(false);
  const [hasEbitdas, setHasEbitdas] = useState(false);
  const [lastYear] = useState(new Date().getFullYear() - 1);
  const [annualValue] = useState(
    society?.annualValues.find((annualValue) => +annualValue.year === lastYear)
  );
  const [hasMissingData, setHasMissingData] = useState(true);

  async function updatePartnerDocuments(document) {
    // Update the corresponding partner to add the document.
    const partnerDocuemnts = partner?.documents || [];
    await store.dispatch(
      updateUser(
        partner?.user,
        {
          partner: partner?.['_id'],
          society: society?.['_id'],
          partnerData: {
            ...partner,
            documents: [...partnerDocuemnts, document['_id']],
          },
        },
        user['_id'],
        false
      )
    );
  }

  async function createDocument(html, options, fileName) {
    // Generate PDF using html2pdf
    const fileBlob = await html2pdf().from(html).set(options).outputPdf('blob');

    // const documentBlob = await pdf(doc).toBlob();
    const documentFile = new File([fileBlob], 'document.pdf', {
      type: 'application/pdf',
    });

    const newDocument = {
      name: fileName,
      file: documentFile,
      size: documentFile?.size || 0,
      date: new Date(),
      lastAccess: new Date(),
      category: documentTypes.CERTIFICATES,
      subcategory: 'VALUESHARES',
      author: user['_id'],
      society: society['_id'],
      isGenerated: true,
      fileType: fileTypes.PDF,
      linkedTo: {
        linkedId: partner['_id'],
        linkedType: documentLinkTypes.PARTNER,
      },
      description: t('DocumentGenerated'),
    };

    const uploadedDocument = await store.dispatch(addDocument(newDocument));

    return uploadedDocument;
  }

  // Returns the shares certificate template of the actual society or the default template.
  async function getSharesCertificateTemplate(actualSociety) {
    // Get the shares certificate template id.
    const sharesCertificateTemplateId =
      actualSociety?.sharesCertificateTemplate ||
      sharesCertificateTemplate[process.env.NODE_ENV];

    // Get the certificate template docuemnt.
    const document = await store.dispatch(
      getDocument(sharesCertificateTemplateId)
    );
    // Return the certificate template document.
    return document;
  }

  const saveDocument = async () => {
    if (isReadOnly) return;

    const templateDocumet = await getSharesCertificateTemplate(society);

    const documentUpdated = updateDocumentWithValues(
      templateDocumet?.editorModel,
      tags({
        partner,
        society,
        annualValue,
      })
    );

    const lastYear = new Date().getFullYear() - 1;

    const fileName = `Certificado Valor Participaciones ${society?.name} ${lastYear}.pdf`;

    const options = {
      filename: fileName,
      margin: [20, 20, 20, 20], // top, right, bottom, left
      pagebreak: { mode: 'avoid-all' },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      },
    };
    // Download the PDF docuemnt.
    html2pdf().from(documentUpdated.html).set(options).save();

    // Create the document in the database.
    const uploadedDocument = await createDocument(
      documentUpdated.html,
      options,
      fileName
    );

    // Update the corresponding partner to add the document.
    await updatePartnerDocuments(uploadedDocument);
  };

  const sendNotificationEmail = () => {
    const emailData = {
      to: [
        {
          email: partner?.mainAdmin?.email,
          name: partner?.mainAdmin?.name,
        },
      ],
      subject: t('MailAdminCertificateSubject'),
      template: 'sttok-general',
      body: [
        t('MailAdminCertificateGreetings', {
          adminName: partner?.mainAdmin?.name,
        }),
        t('MailAdminCertificateBody', {
          partnerName: partner?.name,
          societyName: society?.name,
        }),
        ``,
        ``,
        ``,
        `${process.env.REACT_APP_DOMAIN}/#/perfil-sociedad/valor-participaciones/${society['_id']}`,
        ``,
        ``,
        ``,
        t('MailSignGreetings'),
        ``,
        t('MailSignTeamSttok'),
      ],
      user: user['_id'],
      society: society['_id'],
      date: new Date(),
    };

    dispatch(sendEmail(emailData));
  };

  const handleCertificate = () => {
    if (isReadOnly) return;

    const messages = [];
    let url = '';
    if (!hasAnnualData) {
      messages.push(`- ${t('AnnualValueSociety')} (${lastYear}) <br />`);
      url = `#/perfil-sociedad/valor-participaciones/${society['_id']}`;
    }
    if (!hasEbitdas) {
      messages.push(`- ${t('LastEbitda')} <br />`);
      url = `#/perfil-sociedad/valor-participaciones/${society['_id']}`;
    }
    if (!hasConstitution) {
      messages.push(`- ${t('SocietyConstitution')}`);
      url = `#/socios/${society['_id']}`;
    }
    if (!societyAdmin) {
      messages.push(
        `- ${t('AdminMember')}: ${currDirectorTypes.name.UNIQUE_ADMIN}, ${
          currDirectorTypes.name.COUNCIL_SECRETARY
        }, ${currDirectorTypes.name.DELEGATE_COUNSELOR}`
      );
      url = `#/perfil-sociedad/organo/${society['_id']}`;
    }

    if (hasMissingData) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('IncompleteData')}</h4>`,
        html: `<h5 class="text-base">${t(
          'InOrderToIssueCertificate'
        )}<br /></h5> <div class="text-soft">${messages.join('\n')}</div>`,
        confirmButtonText: isAdmin ? t('Go') : t('NotifyTheAdmin'),
        confirmButtonColor: '#6576ff',
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (isAdmin) {
            window.location.href = url;
          } else {
            sendNotificationEmail();
          }
        }
      });
    }
  };

  useEffect(() => {
    if (
      society.operations
        .map((data) => data.operationType)
        .includes(operationTypes.CONSTITUTION)
    ) {
      setHasConstitution(true);
    }
    if (annualValue) {
      setHasAnnualData(true);
      if (annualValue?.ebitda?.length) setHasEbitdas(true);
    }
  }, [society.operations, annualValue]);

  useEffect(() => {
    if (hasAnnualData && hasConstitution && hasEbitdas && societyAdmin) {
      setHasMissingData(false);
    }
  }, [hasAnnualData, hasConstitution, partner]);

  useEffect(() => {
    let admin;
    if (society.mainDirector) {
      admin = society?.directors.find((d) => d['_id'] === society.mainDirector);
    } else {
      admin = society?.directors.find(
        (director) =>
          (director.directorType === currDirectorTypes.var.UNIQUE_ADMIN ||
            director.directorType ===
              currDirectorTypes.var.DELEGATE_COUNSELOR ||
            director.directorType ===
              currDirectorTypes.var.COUNCIL_SECRETARY) &&
          getDirectorStatus(director) === currStatusTypes.var.ACTIVE
      );
    }
    if (admin) {
      setSocietyAdmin(admin);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    if (inversion) {
      const temp = inversion >= 0 ? '+' : '';
      setSign(temp);
    }
  }, [inversion]);

  useEffect(() => {
    setCurrStatusTypes(statusTypes(i18n));
    setCurrDirectorTypes(directorTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <div className="card-inner">
        <div className="user-card user-card-s2">
          <div className="user-avatar lg bg-primary">
            {partner.image ? (
              <img src={partner.image} alt="Logo" />
            ) : (
              <span>{partner.name.slice(0, 2).toUpperCase()}</span>
            )}
          </div>
          <div className="user-info">
            {activePartner(partner) ? (
              <div className="badge badge-outline badge-pill text-primary ucap">
                {t('Partner')}
              </div>
            ) : (
              <div className="badge badge-outline-danger badge-pill text-danger ucap">
                {t('ExPartner')}
              </div>
            )}
            <h5>{partner.name || ''}</h5>
            <span className="sub-text">{partner.email}</span>
          </div>
        </div>
      </div>

      {participationsValue ? (
        <div className="card-inner">
          <div className="overline-title-alt text-center">
            {t('ValuesOfShares')} ND
          </div>
          <div className="profile-balance-amount mt-2">
            <div className="number" style={{ textAlign: 'center' }}>
              <NumberFormat
                value={valuation}
                displayType="text"
                {...currencyFormatDecimals}
              />
            </div>
          </div>
          <div className="overline-title-alt text-center pb-3 mt-4">
            {t('SocietyValueSimulator')} ND
          </div>
          <div className="form-control-wrap mx-5 mt-5">
            <Nouislider
              start={Math.trunc(societyValue.value)}
              range={{
                min: Math.trunc(societyValue.value),
                max: Math.trunc(societyValue.value) * 10,
              }}
              step={sliderStep}
              format={wNumb({
                decimals: 0,
                thousand: '.',
                suffix: ' €',
              })}
              animate
              tooltips
              connect
              onChange={(value) => {
                setValuation((+NDpercent * cleanValue(value[0])) / 100);
              }}
            />
          </div>

          <div className="row text-center mt-3">
            <span className="mt-2 fs-10px">
              {t('TheValueOfTheSharesIsOrie')}
            </span>
          </div>
          <div className="text-center mt-3">
            {hasMissingData ? (
              <button
                type="button"
                className="btn btn-outline-primary report-button"
                onClick={handleCertificate}
                disabled={isReadOnly}
              >
                <em className="icon ni ni-reports-alt mr-1" />
                {t('CertificateValueParticipations')}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline-primary report-button"
                onClick={saveDocument}
                disabled={isReadOnly}
              >
                <em className="icon ni ni-reports-alt mr-1" />
                {t('CertificateValueParticipations')}
              </button>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="card-inner">
        <div className="overline-title-alt text-center">
          {t('InvestmentResult')}
        </div>
        <div className="profile-balance-amount mt-2">
          <div className="number text-center">
            <NumberFormat
              value={inversion}
              displayType="text"
              renderText={(value) => (
                <div>
                  {sign}
                  {value}
                </div>
              )}
              {...currencyFormatDecimals}
            />
          </div>
        </div>
        <div className="row text-center">
          <span className="mt-2 fs-10px mx-2">
            {t('ItIsTheResultOfSubtractin')}{' '}
            <span
              className="fw-bold text-primary cursor-pointer"
              onClick={() => {
                setPage(2);
                setIsCardVisible(false);
              }}
            >
              {t('SeeDetail')}
            </span>
          </span>
        </div>
      </div>

      <div className="card-inner">
        <div className="row g-3">
          <div className="col-6 text-center">
            <span className="sub-text">{t('PartnerNo')}:</span>
            <span>{partnerNumber || '-'}</span>
          </div>
          <div className="col-6 text-center">
            <span className="sub-text">{t('HighDate')}:</span>
            <span>{incorporationDate}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerAside;
