import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { HoldingClass } from 'types';

import DOCXViewer from 'modules/_shared/components/DOCXViewer';
import PDFPreview from 'modules/_shared/components/PDFViewer';

import ConstitutionAiModalCTA from './ConstitutionAiModalCTA';
import ConstitutionAiModalSocietyTable from './ConstitutionAiModalSocietyTable';
import ConstitutionAiModalPartnersTable from './ConstitutionAiModalPartnersTable';
import ConstitutionAiModalConstitutionTable from './ConstitutionAiModalConstitutionTable';

type Props = {
  isLoading: boolean;
  isFirstLoad: boolean;
  file: any;
  societyData: any;
  constitutionData: any;
  partnersData: any;
  holdingClasses: HoldingClass[];
  handleScanFile: any;
  handleChangeSocietyParam: any;
  handleChangeConstitutionParam: any;
  handleChangePartnerParam: any;
};

const ConstitutionAiModalBody: FC<Props> = ({
  isLoading,
  isFirstLoad,
  file,
  societyData,
  constitutionData,
  partnersData,
  holdingClasses,
  handleScanFile,
  handleChangeSocietyParam,
  handleChangeConstitutionParam,
  handleChangePartnerParam,
}) => {
  const { t } = useTranslate();

  const fileTypes = {
    PDF: 'application/pdf',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };

  return (
    <>
      <Modal.Body>
        <div className="row">
          <div className="col-xl-5">
            {isFirstLoad && (
              <ConstitutionAiModalCTA handleScanFile={handleScanFile} />
            )}

            {!isLoading && partnersData && constitutionData && societyData && (
              <>
                <h6 className="mb-2">
                  {t('InformationExtractedFromDocument')}
                </h6>

                <div
                  className="overflow-auto"
                  style={{ height: 660, scrollbarGutter: 'stable' }}
                >
                  <ConstitutionAiModalSocietyTable
                    societyData={societyData}
                    changeSocietyParam={handleChangeSocietyParam}
                  />
                  <ConstitutionAiModalConstitutionTable
                    constitutionData={constitutionData}
                    changeConstitutionParam={handleChangeConstitutionParam}
                  />
                  <ConstitutionAiModalPartnersTable
                    partnersData={partnersData}
                    changePartnerParam={handleChangePartnerParam}
                    holdingClasses={holdingClasses}
                  />
                  <div className="fading-effect-bottom" />
                </div>
              </>
            )}
          </div>

          <div className="col-xl-7">
            <h6 className="mb-2">{t('OriginalDocument')}</h6>
            <div className="card card-bordered card-preview mb-4">
              {file?.type === fileTypes.DOCX ? (
                <DOCXViewer file={file} height={660} />
              ) : (
                <PDFPreview file={file} width={466} height={660} />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ConstitutionAiModalBody;
