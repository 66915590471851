/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import transformData from 'modules/_shared/components/Tables/helpers';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import TableLoader from 'modules/_shared/components/Tables/TableLoader';

import { setMenuType } from 'modules/_shared/redux/menuActions';
import { getUserSocieties } from 'modules/society/redux/societyActions';

import menuTypes from 'constants/menuTypes';

import { useTranslate } from 'hooks/useTranslate';
import { getDefaultGuestRoute } from 'modules/society/components/SocietyGuests/utils/getDefaultGuestRoute';
import tableColumns from './tableColumns';

import './InvitedList.scss';

function InvitedList({ items = 10 }) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const [guestData, setGuestData] = useState([]);

  useEffect(() => {
    dispatch(setMenuType(menuTypes.MAIN));
  }, [dispatch]);

  useEffect(() => {
    if (user && user?.societies?.invited?.length) {
      getUserSocieties(user['_id'], 'guest', setGuestData, 'table').then(() =>
        setIsLoadingData(false)
      );
    } else {
      setIsLoadingData(false);
    }
  }, [user]);

  useEffect(() => {
    if (i18n.language) {
      setCurrentTableColumns(tableColumns(i18n));
    }
  }, [i18n.language]); // eslint-disable-line

  const memoizedData = useMemo(() => {
    if (user && guestData?.length) {
      const dataForTable = guestData.map((currentData) => ({
        guest: {
          guestPermissions: [
            {
              permissions: currentData?.permissions,
              society: currentData.society._id,
            },
          ],
        },
        society: currentData.society,
        societyName: currentData.society?.name,
        link: getDefaultGuestRoute(
          currentData?.permissions,
          currentData.society._id
        ),
      }));

      return dataForTable;
    }
    return [];
  }, [user, guestData]);

  useEffect(() => {
    if (memoizedData.length) {
      setTableData(
        transformData({
          data: memoizedData,
          columns: tableColumns(i18n),
        })
      );
    }
  }, [memoizedData, i18n.language]); // eslint-disable-line

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('SocietiesWhereYouAreInvited')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {tableData?.length === 0
                    ? t('YouDonHaveSocietiesWhereYouAreInvited')
                    : t('YouHaveSocietiesWhereYouAreInvited', {
                        count: tableData?.length,
                      })}
                </p>
              </div>
            </div>
          </div>
        </div>

        {tableData?.length > 0 ? (
          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('Society')}
            pagination
            className="nk-tb-list"
            rowsPerPage={items}
            actions
            showDownload={false}
          />
        ) : (
          isLoadingData && <TableLoader rows={items} />
        )}
      </div>
    </div>
  );
}

export default InvitedList;
