import { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { Society } from 'types';

import { getSocietyEmailConfiguration } from 'utils/emails';
import { getAnnouncementCommunication } from 'utils/getAnnouncementCommunication';

export default function useGetAnnouncementCommunication(
  i18n: any,
  announcementData: any,
  society: Society
) {
  const [announcementCommunication, setAnnouncementCommunication] =
    useState<any>('');

  useEffect(() => {
    const societyTheme = getSocietyEmailConfiguration(society, {
      addSuffix: false,
    })?.theme;

    const hasWhiteBranding = society?.configuration?.hasWhiteBranding;

    const announcement = getAnnouncementCommunication(
      societyTheme,
      hasWhiteBranding,
      announcementData,
      i18n?.language || 'es'
    );

    const parsedResult = parse(announcement);

    setAnnouncementCommunication(parsedResult);
  }, [i18n, announcementData, society]);

  return { announcementCommunication };
}
