import { FC } from 'react';

type DetailsProfileSectionProps = {
  name: string;
  image?: string;
  position: string;
  description: string;
};

const DetailsProfileSection: FC<DetailsProfileSectionProps> = ({
  name,
  image,
  position,
  description,
}) => (
  <div className="card-inner">
    <div className="user-card user-card-s2">
      <div className="user-avatar lg bg-primary">
        {image ? (
          <img src={image} alt="Logo" />
        ) : (
          <span>{name?.slice(0, 2).toUpperCase()}</span>
        )}
      </div>

      <div className="user-info">
        <h5>{name}</h5>
        <div className="badge badge-outline badge-pill text-primary ucap">
          {position}
        </div>
        <span className="sub-text">{description}</span>
      </div>
    </div>
  </div>
);

export default DetailsProfileSection;
