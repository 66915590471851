import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { ClearButton, Typeahead } from 'react-bootstrap-typeahead';

type PartnerRepresentantModalInputNameProps = {
  name: string;
  options: any[];
  onSearch: (query: string) => void; // eslint-disable-line
  onClearOption: () => void;
  onSelectOption: (option: any) => void; // eslint-disable-line
};

const PartnerRepresentantInputName: FC<
  PartnerRepresentantModalInputNameProps
> = ({ options, onSearch, name, onSelectOption, onClearOption }) => {
  const { t } = useTranslate();

  const handleChangeOption = (selectedOption: any) => {
    if (selectedOption.length > 0) {
      onSelectOption(selectedOption[0]);
    } else {
      onClearOption();
    }
  };

  return (
    <div className="row mb-4">
      <div className="col">
        <div className="form-group">
          <label className="form-label" htmlFor="partner-name">
            {t('RepresentantName')}
          </label>

          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <em className="icon ni ni-user" />
            </div>

            <Typeahead
              id="partner-representant-name"
              labelKey="name"
              className="form-input-icon-left"
              placeholder={t('NameAndSurname')}
              minLength={1}
              options={options}
              selected={name ? [name] : []}
              onChange={handleChangeOption}
              onInputChange={onSearch}
            >
              {({ onClear, selected }) => (
                <div className="rbt-aux">
                  {!!selected.length && <ClearButton onClick={onClear} />}
                </div>
              )}
            </Typeahead>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerRepresentantInputName;
