import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import { Board } from 'types';

import ReplaceBoardTemplateHeader from './components/ReplaceBoardTemplateHeader';
import ReplaceBoardTemplateBody from './components/ReplaceBoardTemplateBody';
import ReplaceBoardTemplateFooter from './components/ReplaceBoardTemplateFooter';
import replaceBoardTemplate from './utils/replaceBoardTemplate';

type Props = {
  board: Board;
  templateType: string;
};

const ReplaceBoardTemplate: FC<Props> = ({ board, templateType }: Props) => {
  const dispatch = useDispatch();

  const society = useSelector((state: any) => state?.society?.actualSociety);
  const documents = useSelector((state: any) => state.documents);
  const user = useSelector((state: any) => state.user);

  const [societyTemplates, setSocietyTemplates] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>();

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleReplaceTemplate = async () => {
    try {
      await replaceBoardTemplate({
        board,
        templateType,
        selectedTemplate,
        society,
        user,
      });
    } catch (error) {
      // @javierfaife - We should handle this error
      console.error(error);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <>
      <ReplaceBoardTemplateHeader
        templateType={templateType}
        handleCloseModal={handleCloseModal}
      />
      <ReplaceBoardTemplateBody
        documents={documents}
        societyId={society['_id']}
        societyTemplates={societyTemplates}
        setSocietyTemplates={setSocietyTemplates}
        setSelectedTemplate={setSelectedTemplate}
      />
      <ReplaceBoardTemplateFooter
        selectedTemplate={selectedTemplate}
        handleReplaceTemplate={handleReplaceTemplate}
      />
    </>
  );
};

export default ReplaceBoardTemplate;
