/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import Select, { components } from 'react-select';
import makeAnimated from 'react-select/animated';

import { getActualSociety } from 'utils/filters';
import { isBoardClosedOrCanceled } from 'utils/boards';

import { SELECCIONAR_SOCIO } from 'constants/defaultConstants';

import Dialog from 'modules/_shared/components/Dialog';
import LogoSociety from 'modules/_shared/components/Logos/LogoSociety';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { useTranslate } from 'hooks/useTranslate';
import DelegationMessageBox from 'modules/boards/components/DelegationMessageBox';
import { getBoards, updateBoard } from 'modules/boards/redux/boardActions';
import { getParticipantDelegationData } from 'modules/boards/utils';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { getPartnerRepresentants } from 'modules/partners/redux/partnerRepresentantsActions';

import { boardDelegationRoles } from 'modules/boards/constants/boardDelegation';
import { getValidOrders } from './utils/getValidOrders';
import { getOrderInstructions } from './utils/getOrderInstructions';
import { getActualPartner } from './utils/getActualPartner';
import { getSelectablePartners } from './utils/getSelectablePartners';
import { getFilteredPartners } from './utils/getFilteredPartners';
import { getPartnerDelegate } from './utils/getPartnerDelegate';
import { getSelectedPartnerOptions } from './utils/getSelectedPartnerOptions';

import DelegationPartnerHeader from './DelegationPartnerHeader';
import DelegationPartnerInstructions from './DelegationPartnerInstructions';
import DelegationPartnerFooter from './DelegationPartnerFooter';

const DelegationPartner = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId, boardId, partnerId } = useParams();

  const user = useSelector((state) => state.user);
  const boards = useSelector((state) => state.boards);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const partnerRepresentants = useSelector(
    (state) => state.partnerRepresentants
  );
  const hasWhiteBranding = useSelector(
    (state) => state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  const [board, setBoard] = useState();
  const [isClosed, setIsClosed] = useState(false);
  const [currentPartner, setCurrentPartner] = useState();
  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [selectablePartners, setSelectablePartners] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [hasInstructions, setHasInstructions] = useState(false);
  const [instructionType, setInstructionType] = useState('');
  const [orders, setOrders] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [date, setDate] = useState('');

  const animatedComponents = makeAnimated();

  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: t('SelectPartnerOrPartnerRepresentant'),
  });

  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedRole(selectedOption.role);
    setSelectedPartner(selectedOption.value);
    setSelectedPartnerOption(selectedOption);
  };

  const handleSaveInstruction = (value, index) => {
    const newInstructions = [...instructions];
    if (newInstructions[index]) {
      newInstructions[index].vote = value;
      setInstructions(newInstructions);
    }
  };

  const isDelegationAllowed = () =>
    !currentPartner?.hasDelegated &&
    !isClosed &&
    currentPartner?.votes?.length === 0;

  const isAlreadyClosed = () => isClosed;

  const isAlreadyDelegated = () => currentPartner?.hasDelegated && !isClosed;

  const isAlreadyVoted = () =>
    currentPartner?.votes.length > 0 &&
    !currentPartner?.hasDelegated &&
    !isClosed;

  const isDelegateButtonDisabled = () =>
    selectedPartner === SELECCIONAR_SOCIO ||
    (hasInstructions && !instructionType);

  const handleSaveDelegation = (event) => {
    event.preventDefault();

    try {
      const participant = getParticipantDelegationData({
        board,
        partnerId,
        selectedRole,
        selectedPartner,
        instructions,
        instructionType,
        hasInstructions,
      });

      if (!participant) {
        return;
      }

      const representantsData = board?.delegationRepresentants || [];

      if (
        selectedPartner &&
        selectedRole === boardDelegationRoles.PARTNER_REPRESENTANT
      ) {
        representantsData.push(selectedPartner);
      }

      return Dialog({
        icon: 'info',
        title: t('Attention'),
        body: t('SignDocumentToComplete'),
        buttonText: t('Sign'),
        onConfirm: () => {
          dispatch(
            updateBoard(
              boardId,
              {
                participantData: participant,
                delegationRepresentants: representantsData,
              },
              false
            )
          );
          const path = `/firma-delegacion/${societyId}/${boardId}/${partnerId}`;
          history.push(path);
        },
      });
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_SAVING_DELEGATION));
    }
  };

  useEffect(() => {
    if (societyId) {
      dispatch(getBoards(societyId));
      dispatch(getPartnerRepresentants({ societyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (boards.length && boardId) {
      const actualBoard = boards.find((board) => board['_id'] === boardId);
      setIsClosed(isBoardClosedOrCanceled(actualBoard));
      setBoard(actualBoard);
    }
  }, [boards, boardId]);

  useEffect(() => {
    dispatch(
      setMenu({
        type: null,
        societyId: actualSociety?.['_id'] || null,
        societyName: actualSociety?.name,
      })
    );
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (board) {
      setDate(board.date ? format(new Date(board.date), 'dd/MM/yyyy') : '');

      const validOrders = getValidOrders(board);
      setOrders(validOrders);

      const orderInstructions = getOrderInstructions(validOrders);
      setInstructions(orderInstructions);

      const actualPartner = getActualPartner(board, partnerId);

      if (actualPartner) {
        if (
          actualPartner?.representative &&
          !actualPartner?.hasPartnerRepresentant
        ) {
          const currentSelectablePartners = getSelectablePartners(
            actualSociety,
            actualPartner
          );
          setSelectablePartners(currentSelectablePartners);
        } else {
          const filteredPartners = getFilteredPartners(
            board,
            actualSociety,
            partnerId
          );
          setSelectablePartners(filteredPartners || []);
        }

        if (actualPartner?.hasDelegated) {
          const delegate = getPartnerDelegate(
            board,
            actualPartner,
            partnerRepresentants
          );

          setCurrentPartner({
            ...actualPartner,
            representativeName: delegate?.name,
          });
        } else {
          setCurrentPartner(actualPartner);
        }
      }
    }
  }, [board, partnerId, actualSociety, partnerRepresentants]); // eslint-disable-line

  useEffect(() => {
    if (selectedPartner === SELECCIONAR_SOCIO) {
      setHasInstructions(false);
    }
  }, [selectedPartner]);

  const Group = (props) => (
    <div>
      <components.Group {...props} />
    </div>
  );

  return (
    <div className="custom-page-background">
      {isDelegationAllowed() ? (
        <div className="nk-block nk-auth-body wide-xs" id="delegation-box">
          <div className="brand-logo pb-4 text-center">
            <LogoSociety society={actualSociety} />
          </div>
          <div className="card card-bordered shadow">
            <div className="card-inner card-inner-lg">
              <DelegationPartnerHeader date={date} society={actualSociety} />

              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    {t('SelectPartnerToDelegateVote')}
                  </label>
                </div>
                <Select
                  closeMenuOnSelect
                  className="form-control-wrap react-select"
                  value={selectedPartnerOption}
                  options={getSelectedPartnerOptions(
                    selectablePartners,
                    board,
                    partnerRepresentants
                  )}
                  components={{ Group, animatedComponents }}
                  onChange={handlePartnerOptionChange}
                />
              </div>

              <div className="nk-modal-text text-left">
                <div
                  className="custom-control custom-control-sm custom-checkbox custom-control-pro"
                  style={{ zIndex: 0 }}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="instruction-checkbox"
                    checked={hasInstructions}
                    onChange={(event) =>
                      setHasInstructions(event.target.checked)
                    }
                    disabled={selectedPartner === SELECCIONAR_SOCIO}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="instruction-checkbox"
                  >
                    {t('GiveVotingInstructions')}
                  </label>
                </div>

                {hasInstructions ? (
                  <DelegationPartnerInstructions
                    orders={orders}
                    instructions={instructions}
                    instructionType={instructionType}
                    onSaveInstruction={handleSaveInstruction}
                    onChangeInstructionType={setInstructionType}
                  />
                ) : (
                  <div style={{ height: '86.594px' }} />
                )}
              </div>

              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-lg btn-primary btn-block"
                  disabled={isDelegateButtonDisabled()}
                  onClick={handleSaveDelegation}
                >
                  {t('DelegateVote')}
                </button>
              </div>

              <DelegationPartnerFooter hasWhiteBranding={hasWhiteBranding} />
            </div>
          </div>
        </div>
      ) : (
        <>
          {isAlreadyClosed() && (
            <DelegationMessageBox
              icon="INFO"
              title={t('BoardIsAlreadyClosed')}
              description={t('BoardIsAlreadyClosedDescription', {
                societyName: actualSociety?.name,
              })}
              link={`/detalle-socio/${societyId}/${partnerId}`}
              linkText={t('SeeMyPartnerDetails')}
            />
          )}

          {isAlreadyDelegated() && (
            <DelegationMessageBox
              icon="INFO"
              title={t('DelegationAlreadyExists', {
                representativeName: currentPartner?.representativeName,
              })}
              description={t('DelegationAlreadyExistsDescription')}
              link={`/detalle-socio/${societyId}/${partnerId}`}
              linkText={t('SeeMyPartnerDetails')}
            />
          )}

          {isAlreadyVoted() && (
            <DelegationMessageBox
              icon="INFO"
              title={t('YouAlreadyVoted')}
              description={t('YouAlreadyVotedDescription', {
                boardName: board?.name,
                boardDate: date.replace(/\//g, '-'),
                societyName: actualSociety?.name,
              })}
              link={`/detalle-socio/${societyId}/${partnerId}`}
              linkText={t('SeeMyPartnerDetails')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DelegationPartner;
