import { PartnerDraft } from 'types/Partner';

const getHoldingClassesFromDraft = (
  operation: Record<string, any>,
  holdingClasses: Record<string, any>[]
): string => {
  if (!operation || !holdingClasses) return '';

  const { draftPartners } = operation;

  let parsedPartners = [];
  if (typeof draftPartners === 'string') {
    parsedPartners = JSON.parse(draftPartners);
  }

  const partnerClassesId = parsedPartners.map(
    (partner: PartnerDraft) => partner?.holdingClass
  );

  const holdingClassList = partnerClassesId.map(
    (id: string) =>
      holdingClasses.find(
        (holdingClass: Record<string, any>) => holdingClass?._id === id
      )?.name
  );

  return holdingClassList.join(', ') || '';
};

export default getHoldingClassesFromDraft;
