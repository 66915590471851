const PageWithTabsLoading = () => (
  <div className="card-inner">
    <div className="text-center p-1">
      <p className="placeholder-glow ">
        <span className="placeholder h-80px w-80px rounded-circle" />
      </p>
      <p className="placeholder-glow">
        <span className="placeholder col-2 h-20px mb-2" />
        <br />
        <span className="placeholder col-4 h-20px mb-2" />
        <span className="placeholder col-12 h-20px mb-2 mt-2" />
      </p>
      <p className="placeholder-glow text-left">
        <span className="placeholder col-2 h-20px mb-3 text-left mt-3" />
        <br />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />

        <span className="placeholder col-6 h-25px mb-3" />
        <span className="placeholder col-6 h-25px mb-3" />
      </p>
    </div>
  </div>
);

export default PageWithTabsLoading;
