import i18n from 'i18n/config';

export default {
  YES_NO_ABS: {
    value: 'YES_NO_ABS',
    text: i18n.t('YesNoAbs'),
  },
  NUMERIC: {
    value: 'NUMERIC',
    text: i18n.t('Numeric'),
  },
  TEXT: {
    value: 'TEXT',
    text: i18n.t('Textual'),
  },
  NONE: {
    value: 'NONE',
    text: i18n.t('None'),
  },
};
