export const actionTypes = {
  SET_MENU_TYPE: 'SET_MENU_TYPE',
  SET_MENU: 'SET_MENU',
};

export function setMenuType(menuType) {
  return { type: actionTypes.SET_MENU_TYPE, menuType };
}

export function setMenu(menu) {
  return { type: actionTypes.SET_MENU, menu };
}
