/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { dateFormat } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';
import { historicTypeIcons } from './constants/employeeHistoricTypes';
import getEmployeeTimeline from './utils/getEmployeeTimeline';

function EmployeeHistoric({ employee }) {
  const { t, i18n } = useTranslate();

  const maxRows = 20;

  const historic = getEmployeeTimeline(employee);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <h4 className="title">{t('EmployeeHistoric')}</h4>
        <p>{t('EmployeeEventsTimeline')}</p>
      </div>

      <div className="card-inner mt-1">
        <div className="timeline">
          <ul className="timeline-list">
            {historic?.slice(0, maxRows)?.map((item, index) => (
              <li className="timeline-item" key={uuidv4()}>
                <div
                  className={`timeline-status bg-primary ${
                    index === 0 && 'is-outline'
                  }`}
                />
                <div className="timeline-date ml-1 w-100px">
                  <span className="mx-1 ff-mono">
                    {format(new Date(item?.date), dateFormat)}
                  </span>
                  <em
                    className={`icon text-primary ml-1 ${
                      historicTypeIcons[item.type]
                    }`}
                  />
                </div>

                <div className="timeline-data ml-3">
                  <h6 className="timeline-title">{i18n.t(item?.type)}</h6>
                  <div className="timeline-des">
                    <p>{t(item?.text)}</p>
                    {item?.link && (
                      <Link to={item.link} className="time text-primary">
                        Ver
                      </Link>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default EmployeeHistoric;
