/* eslint-disable no-restricted-syntax */
import { cleanValue, numberFormat } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { v4 as uuidv4 } from 'uuid';

import '../Modals.scss';

const AddNumerationEmbedded = ({
  setEditIndex,
  setPartnersShares,
  setCurrentPartnerShares,
  setRenumerations,
  partnersShares,
  currentPartnerShares,
  renumerations,
  total,
  partner,
  shareIndex,
  holdingClass,
}) => {
  const { t } = useTranslate();
  const { holdingClasses } = useSelector((state) => state);
  const animatedComponents = makeAnimated();

  const [sharesRange, setSharesRange] = useState([]);
  const [isValidRange, setIsValidRange] = useState(false);
  const [sharesToAssign, setSharesToAssign] = useState(total);

  function saveNumeration() {
    const currentShare = currentPartnerShares[shareIndex];
    const updateShares = [];
    const newRenumerations = [];
    for (const actualRange of sharesRange) {
      if (actualRange.from === '' || actualRange.to === '') break;
      const shareClass = holdingClass
        ? actualRange.shareClass
        : currentShare.shareClass;
      updateShares.push({
        shareFrom: actualRange.from,
        shareTo: actualRange.to,
        shareClass,
        sharePremium: currentShare.sharePremium,
        sharePrice: currentShare.sharePrice,
      });
      newRenumerations.push({
        partner: partner.name,
        previous: { from: currentShare.shareFrom, to: currentShare.shareTo },
        next: { from: actualRange.from, to: actualRange.to },
        className: shareClass?.name,
      });
    }
    const shares = currentPartnerShares;
    shares.splice(shareIndex, 1, ...updateShares);
    setCurrentPartnerShares(shares);
    const newPartnersShares = partnersShares;
    newPartnersShares[partner['_id']] = shares;
    setPartnersShares(newPartnersShares);
    setRenumerations([...renumerations, ...newRenumerations]);
    setEditIndex(-1);
  }
  const checkOverlappingRanges = (ranges) => {
    const compareFunction = (a, b) => +a.from - +b.from;
    const orderedRanges = ranges.sort(compareFunction);
    for (let index = 0; index < orderedRanges.length - 1; index += 1) {
      if (+orderedRanges[index].to >= +orderedRanges[index + 1].from)
        return false;
    }
    return true;
  };

  const handleAddRange = () => {
    const newSharesRange = [...sharesRange];
    if (holdingClass) {
      newSharesRange.push({
        from: '',
        to: '',
        shareClass: holdingClass,
      });
    } else {
      newSharesRange.push({
        from: '',
        to: '',
      });
    }

    setSharesRange(newSharesRange);
  };

  const handleRemoveRange = (index) => {
    const newSharesRange = [...sharesRange];
    newSharesRange.splice(index, 1);
    setSharesRange(newSharesRange);
  };

  const handleSharesRange = (index, field, value) => {
    const newSharesRange = [...sharesRange];
    if (field === 'FROM') {
      newSharesRange[index].from = value;
    } else if (field === 'TO') {
      newSharesRange[index].to = value;
    } else {
      const newClass =
        holdingClasses.find((holdingClass) => holdingClass['_id'] === value) ||
        newSharesRange[index].shareClass;
      newSharesRange[index].shareClass = newClass;
    }
    // validar rango
    if (newSharesRange[index].from !== '' && newSharesRange[index].to !== '') {
      if (+newSharesRange[index].to < +newSharesRange[index].from) {
        setIsValidRange(false);
      } else if (!checkOverlappingRanges([...newSharesRange])) {
        setIsValidRange(false);
      } else setIsValidRange(true);
    } else if (index === newSharesRange.length - 1) setIsValidRange(true);
    else setIsValidRange(false);
    setSharesRange(newSharesRange);
  };

  const handleAddMaxValue = (index) => {
    const newSharesRange = [...sharesRange];
    let sharesToAdd = sharesToAssign - 1;
    if (
      newSharesRange[index].to &&
      +newSharesRange[index].to >= +newSharesRange[index].from
    )
      sharesToAdd +=
        +newSharesRange[index].to - +newSharesRange[index].from + 1;
    const maxShareTo = +newSharesRange[index].from + sharesToAdd;
    handleSharesRange(index, 'TO', +maxShareTo.toString());
  };

  useEffect(() => {
    if (holdingClass) {
      setSharesRange([
        {
          from: '',
          to: '',
          shareClass: holdingClass,
        },
      ]);
    } else {
      setSharesRange([{ from: '', to: '' }]);
    }
  }, [holdingClass]);

  useEffect(() => {
    const totalSharesRange = sharesRange.reduce((acc, curr) => {
      if (+curr.to && +curr.from && +curr.to >= +curr.from)
        return acc + +curr.to - +curr.from + 1;
      return acc;
    }, 0);
    setSharesToAssign(total - totalSharesRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharesRange]);

  return (
    <>
      <div className="form-group">
        {sharesRange.map((shareRange, index) => (
          <div className="form-control-wrap pb-1">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  {t('From')}
                </span>
              </div>
              <NumberFormat
                id="outlined-normal"
                className="form-control form-control-outlined"
                value={shareRange.from}
                onChange={(e) =>
                  handleSharesRange(
                    index,
                    'FROM',
                    cleanValue(e.target.value) || ''
                  )
                }
                isAllowed={(inputObj) => {
                  const { floatValue, formattedValue } = inputObj;
                  if (formattedValue === '') return true;
                  if (floatValue >= 1 && Number.isInteger(floatValue))
                    return true;
                  return false;
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...numberFormat}
              />
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon3">
                  {t('To')}
                </span>
              </div>
              <NumberFormat
                id="outlined-normal"
                className="form-control form-control-outlined"
                value={shareRange.to}
                onChange={(e) =>
                  handleSharesRange(
                    index,
                    'TO',
                    cleanValue(e.target.value) || ''
                  )
                }
                isAllowed={(inputObj) => {
                  const { floatValue, formattedValue } = inputObj;
                  if (formattedValue === '') return true;
                  if (floatValue >= 1 && Number.isInteger(floatValue))
                    return true;
                  return false;
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...numberFormat}
              />
              {sharesRange.length > 1 && index < sharesRange.length - 1 ? (
                <>
                  <button
                    className="btn btn-sm btn-outline-primary btn-dim border-radius-0"
                    type="button"
                    onClick={() => handleAddMaxValue(index)}
                    disabled={!shareRange.from}
                  >
                    <em className="icon ni ni-edit-alt" />
                  </button>
                  {holdingClass && (
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      // eslint-disable-next-line react/no-array-index-key
                      key={uuidv4()}
                      value={{
                        value: shareRange?.shareClass?.['_id'],
                        label: shareRange?.shareClass?.name,
                      }}
                      options={holdingClasses?.map((holdingClass) => ({
                        value: holdingClass['_id'],
                        label: `${holdingClass.name}`,
                      }))}
                      components={animatedComponents}
                      onChange={(selectedOption) =>
                        handleSharesRange(index, 'CLASS', selectedOption.value)
                      }
                    />
                  )}
                  <button
                    className="btn btn-outline-primary btn-dim border-radius-0"
                    type="button"
                    onClick={() => handleRemoveRange(index)}
                  >
                    <em className="icon ni ni-minus" />
                    {!holdingClass && <span>{t('EliminateNumbering')}</span>}
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-sm btn-outline-primary btn-dim border-radius-0"
                    type="button"
                    onClick={() => handleAddMaxValue(index)}
                    disabled={!shareRange.from}
                  >
                    <em className="icon ni ni-edit-alt" />
                  </button>
                  {holdingClass && (
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      // eslint-disable-next-line react/no-array-index-key
                      key={uuidv4()}
                      value={{
                        value: shareRange?.shareClass?.['_id'],
                        label: shareRange?.shareClass?.name,
                      }}
                      options={holdingClasses?.map((holdingClass) => ({
                        value: holdingClass['_id'],
                        label: `${holdingClass.name}`,
                      }))}
                      components={animatedComponents}
                      onChange={(selectedOption) =>
                        handleSharesRange(index, 'CLASS', selectedOption.value)
                      }
                    />
                  )}

                  <button
                    className="btn btn-outline-primary btn-dim border-radius-0"
                    type="button"
                    onClick={() => handleAddRange()}
                    disabled={
                      !isValidRange ||
                      !shareRange.from ||
                      !shareRange.to ||
                      +shareRange.from > +shareRange.to
                    }
                  >
                    <em className="icon ni ni-plus" />
                    {!holdingClass && <span>{t('AddNumbering')}</span>}
                  </button>
                </>
              )}
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-between">
          <span className="sub-text">
            {t('ByAssigning')}:{' '}
            <NumberFormat
              displayType="text"
              value={sharesToAssign}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...numberFormat}
            />
          </span>
          <button
            type="button"
            className="btn btn-outline-primary btn-dim border-radius-0 align-self-end"
            disabled={!isValidRange || sharesToAssign !== 0}
            onClick={() => saveNumeration()}
          >
            {t('Save')}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddNumerationEmbedded;
