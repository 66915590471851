import draftIcons from 'constants/draftIcons';
import { getDashboardData } from 'modules/society/actions/dashBoardActions';

export const getDraftsViewData = async (societyId, dispatch) => {
  const draftsData = await dispatch(getDashboardData(societyId, 'drafts'));

  const resultData = draftsData?.map((draft) => ({
    name: draft?.name,
    icon: draftIcons[draft?.type],
    value1: parseFloat(draft?.contribution?.toFixed(2) || 0),
    hasProgressBar: false,
  }));
  return resultData;
};
