import { useEffect, useState } from 'react';

import { CoOwner, Encumbrance, Range, ScriptureData } from 'types';

import validRange from '../utils/validRange';
import { FixedShare } from '../types/Share';
import getInitialRanges from '../utils/getInitialRanges';
import getValidRanges from '../utils/getValidRanges';
import getScriptureDocument from '../utils/getScriptureDocument';
import removeRangeFromRanges from '../utils/removeRangeFromRanges';

type Props = {
  share: FixedShare;
};

export default function useEncumbranceForm({ share }: Props) {
  const [affectedShares, setAffectedShares] = useState<number>(
    share?.to - share?.from
  );
  const [encumbranceName, setEncumbranceName] = useState<string>('');
  const [encumbranceDate, setEncumbranceDate] = useState<string>('');
  const [encumbranceFrom, setEncumbranceFrom] = useState<number>(share?.from);
  const [encumbranceTo, setEncumbranceTo] = useState<number>(share?.to);
  const [encumbranceScriptureData, setEncumbranceScriptureData] =
    useState<ScriptureData>({ notaryName: '', protocolNr: '', notaryCity: '' });
  const [encumbranceFile, setEncumbranceFile] = useState<File | undefined>();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [editingIndex, setEditingIndex] = useState<number>(-1);

  const [isValidRange, setIsValidRange] = useState<boolean>(true);

  const [validRanges, setValidRanges] = useState<Range[]>([
    { from: share?.from, to: share?.to },
  ]);

  const [coOwners, setCoOwners] = useState<CoOwner[]>([]);

  const [inputLabel, setInputLabel] = useState<string>('DragDocumentToUpload');

  const setValidRangeOnInput = (ranges: Range[]) => {
    const range = ranges.find((range) => range?.to - range?.from > 0);
    if (range) {
      setEncumbranceFrom(range?.from);
      setEncumbranceTo(range?.to);
      setAffectedShares(range?.to - range?.from);
    }
    setIsValidRange(true);
  };

  const setEncumbranceValues = (encumbrance: Encumbrance) => {
    setEncumbranceName(encumbrance?.name);
    setEncumbranceDate(encumbrance?.date);
    setEncumbranceFrom(encumbrance?.from);
    setEncumbranceTo(encumbrance?.to);
    setEncumbranceScriptureData(encumbrance?.scriptureData);
    setCoOwners(encumbrance?.coOwners);
  };

  const setEncumbranceDocumentValues = async (encumbrance: Encumbrance) => {
    if (encumbrance.file) {
      setEncumbranceFile(encumbrance.file);
    }

    if (encumbrance?.scriptureDocument) {
      const document = await getScriptureDocument(
        encumbrance.scriptureDocument
      );
      setInputLabel(document?.name || 'DragDocumentToUpload');
    } else {
      setInputLabel('DragDocumentToUpload');
    }
  };

  const setEditValues = (index: number, encumbrances: Encumbrance[]) => {
    const encumbrance = encumbrances[index];
    setEncumbranceValues(encumbrance);

    setEncumbranceDocumentValues(encumbrance);

    setIsEditing(true);
    setEditingIndex(index);

    const ranges = getValidRanges(encumbrance, validRanges);
    setValidRanges(ranges);
    setIsValidRange(true);
  };

  const handleChangeEncumbranceName = (event: any) => {
    setEncumbranceName(event.target.value);
  };

  const handleChangeEncumbranceDate = (event: any) => {
    setEncumbranceDate(event.target.value);
  };

  const handleChangeEncumbranceFrom = (event: any) => {
    const { value } = event.target;

    setEncumbranceFrom(+value);

    const isValid = validRange(+value, encumbranceTo, validRanges);
    setIsValidRange(isValid);

    if (encumbranceTo) {
      setAffectedShares(encumbranceTo - value);
    }
  };

  const handleChangeEncumbranceTo = (event: any) => {
    const { value } = event.target;

    setEncumbranceTo(+value);

    const isValid = validRange(encumbranceFrom, +value, validRanges);
    setIsValidRange(isValid);

    if (encumbranceFrom) {
      setAffectedShares(value - encumbranceFrom);
    }
  };

  const handleChangeNotaryName = (event: any) => {
    setEncumbranceScriptureData({
      ...encumbranceScriptureData,
      notaryName: event.target.value,
    });
  };

  const handleChangeProtocolNr = (event: any) => {
    setEncumbranceScriptureData({
      ...encumbranceScriptureData,
      protocolNr: event.target.value,
    });
  };

  const handleChangeNotaryCity = (event: any) => {
    setEncumbranceScriptureData({
      ...encumbranceScriptureData,
      notaryCity: event.target.value,
    });
  };

  const handleAddCoOwner = (coOwner: CoOwner) => {
    setCoOwners([...coOwners, coOwner]);
  };

  const handleRemoveCoOwner = (index: number) => {
    const filteredCoOwners = coOwners.filter((_, i) => i !== index);
    setCoOwners(filteredCoOwners);
  };

  const handleChangeEncumbranceFile = (file: File) => {
    setEncumbranceFile(file);
  };

  const handleClickRange = (range: Range) => {
    setEncumbranceFrom(range?.from);
    setEncumbranceTo(range?.to);
    setAffectedShares(range?.to - range?.from);
    setIsValidRange(true);
  };

  const handleCleanForm = () => {
    setEncumbranceName('');
    setEncumbranceDate('');
    setEncumbranceScriptureData({
      notaryName: '',
      protocolNr: '',
      notaryCity: '',
    });
    setEncumbranceFile(undefined);
    setCoOwners([]);
    setInputLabel('DragDocumentToUpload');
  };

  const handleCancelEdition = (encumbrances: Encumbrance[]) => {
    const encumbrance = encumbrances[editingIndex];
    const ranges = removeRangeFromRanges(
      encumbrance?.from,
      encumbrance?.to,
      validRanges
    );
    setValidRanges(ranges);
    setValidRangeOnInput(ranges);
    setIsEditing(false);
    handleCleanForm();
  };

  useEffect(() => {
    if (share?.encumbrances?.length > 0) {
      const ranges = getInitialRanges(share);
      setValidRanges(ranges);
      setValidRangeOnInput(ranges);
    }
  }, [share]);

  return {
    data: {
      affectedShares,
      encumbranceName,
      encumbranceDate,
      encumbranceFrom,
      encumbranceTo,
      encumbranceScriptureData,
      encumbranceFile,
      validRanges,
      isEditing,
      editingIndex,
      isValidRange,
      coOwners,
      inputLabel,
    },
    isLoading: false,
    isError: false,
    actions: {
      handleChangeEncumbranceName,
      handleChangeEncumbranceDate,
      handleChangeEncumbranceFrom,
      handleChangeEncumbranceTo,
      handleChangeNotaryName,
      handleChangeProtocolNr,
      handleChangeNotaryCity,
      handleAddCoOwner,
      handleRemoveCoOwner,
      handleChangeEncumbranceFile,
      handleClickRange,
      handleCleanForm,
      handleCancelEdition,
      setCoOwners,
      setIsEditing,
      setValidRanges,
      setValidRangeOnInput,
      setEditValues,
    },
  };
}
