import React, { FC } from 'react';

import DrawCell from './DrawCell';

type Props = {
  columnsCount: number;
  rowsCount: number;
  rowIndex: number;
  previousColumnsLength: number;
};

const DrawEmptyColumns: FC<Props> = ({
  columnsCount,
  rowsCount,
  rowIndex,
  previousColumnsLength,
}) => (
  <>
    {Array(columnsCount)
      .fill(null)
      .map((_, index) => (
        <DrawCell
          columnIndex={index}
          value={' '}
          text={false}
          previousColumnsLength={previousColumnsLength}
          rowIndex={rowIndex}
          rowsCount={rowsCount}
        />
      ))}
  </>
);

export default DrawEmptyColumns;
