import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

type Props = {
  partner: Partner;
  handleChangePartnerData: any;
};

const AddressDataView: FC<Props> = ({ partner, handleChangePartnerData }) => {
  const { t } = useTranslate();

  const [line1, setLine1] = useState<string>(partner?.address?.line1);
  const [city, setCity] = useState<string>(partner?.address?.city);
  const [zip, setZip] = useState<string>(partner?.address?.zip);
  const [country, setCountry] = useState<string>(partner?.address?.country);
  const [hasTaxAddress, setHasTaxAddress] = useState<boolean>(
    !!(
      partner?.taxAddress?.line1 ||
      partner?.taxAddress?.city ||
      partner?.taxAddress?.zip ||
      partner?.taxAddress?.country
    )
  );
  const [taxLine1, setTaxLine1] = useState<string>(
    partner?.taxAddress?.line1 || ''
  );
  const [taxCity, setTaxCity] = useState<string>(
    partner?.taxAddress?.city || ''
  );
  const [taxZip, setTaxZip] = useState<string>(partner?.taxAddress?.zip || '');
  const [taxCountry, setTaxCountry] = useState<string>(
    partner?.taxAddress?.country || ''
  );

  const handleChangeTaxAddress = () => {
    if (hasTaxAddress) {
      setTaxLine1('');
      setTaxCity('');
      setTaxZip('');
      setTaxCountry('');
      handleChangePartnerData({
        ...partner,
        taxAddress: { line1: '', city: '', zip: '', country: '' },
      });
    }
    setHasTaxAddress(!hasTaxAddress);
  };

  const handleChangeLine1 = (event: any) => {
    const partnerLine1 = event.target.value;
    setLine1(partnerLine1);
    handleChangePartnerData({
      ...partner,
      address: { ...partner.address, line1: partnerLine1 },
    });
  };

  const handleChangeCity = (event: any) => {
    const partnerCity = event.target.value;
    setCity(partnerCity);
    handleChangePartnerData({
      ...partner,
      address: { ...partner.address, city: partnerCity },
    });
  };

  const handleChangeZip = (event: any) => {
    const partnerZip = event.target.value;
    setZip(partnerZip);
    handleChangePartnerData({
      ...partner,
      address: { ...partner.address, zip: partnerZip },
    });
  };

  const handleChangeCountry = (event: any) => {
    const partnerCountry = event.target.value;
    setCountry(partnerCountry);
    handleChangePartnerData({
      ...partner,
      address: { ...partner.address, country: partnerCountry },
    });
  };

  const handleChangeTaxLine1 = (event: any) => {
    const taxLine1 = event.target.value;
    setTaxLine1(taxLine1);
    handleChangePartnerData({
      ...partner,
      taxAddress: { ...partner.taxAddress, line1: taxLine1 },
    });
  };

  const handleChangeTaxCity = (event: any) => {
    const taxCity = event.target.value;
    setTaxCity(taxCity);
    handleChangePartnerData({
      ...partner,
      taxAddress: { ...partner.taxAddress, city: taxCity },
    });
  };

  const handleChangeTaxZip = (event: any) => {
    const taxZip = event.target.value;
    setTaxZip(taxZip);
    handleChangePartnerData({
      ...partner,
      taxAddress: { ...partner.taxAddress, zip: taxZip },
    });
  };

  const handleChangeTaxCountry = (event: any) => {
    const taxCountry = event.target.value;
    setTaxCountry(taxCountry);
    handleChangePartnerData({
      ...partner,
      taxAddress: { ...partner.taxAddress, country: taxCountry },
    });
  };

  return (
    <>
      <Modal.Body>
        <div className="tab-pane active">
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="address-l1">
                  {t('Address')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="address-l1"
                  placeholder={t('Line1')}
                  value={line1}
                  onChange={handleChangeLine1}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="address-l2">
                  {t('City')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="address-l2"
                  placeholder={t('IntroduceCity')}
                  value={city}
                  onChange={handleChangeCity}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="address-st">
                  {t('PostalCode')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="address-st"
                  placeholder={t('IntroduceZipCode')}
                  value={zip}
                  onChange={handleChangeZip}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label w-100" htmlFor="address-county">
                  {t('Country')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="country-st"
                  placeholder={t('IntroduceCountry')}
                  value={country}
                  onChange={handleChangeCountry}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-2">
              <div className="form-group mt-2">
                <div className="custom-control custom-control-xs custom-checkbox">
                  <input
                    type="checkbox"
                    id="checkbox-notifications"
                    className="custom-control-input"
                    checked={hasTaxAddress}
                    onChange={handleChangeTaxAddress}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox-notifications"
                  >
                    {t('HasFiscalResidenceDifferentFromAddress')}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {hasTaxAddress && (
            <>
              <h6 className="my-4">{t('FiscalResidence')}</h6>

              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="tax-address-l1"
                    >
                      {t('Address')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="tax-address-l1"
                      placeholder={t('Line1')}
                      value={taxLine1}
                      onChange={handleChangeTaxLine1}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="tax-address-city"
                    >
                      {t('City')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="tax-address-city"
                      placeholder={t('IntroduceCity')}
                      value={taxCity}
                      onChange={handleChangeTaxCity}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="tax-address-zip"
                    >
                      {t('PostalCode')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="tax-address-zip"
                      placeholder={t('IntroduceZipCode')}
                      value={taxZip}
                      onChange={handleChangeTaxZip}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="tax-address-country"
                    >
                      {t('Country')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="tax-address-country"
                      placeholder={t('IntroduceCountry')}
                      value={taxCountry}
                      onChange={handleChangeTaxCountry}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </>
  );
};

export default AddressDataView;
