import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  value: string;
  onChange: any;
};

const PersonalDataViewEmployeeNr: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslate();

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label" htmlFor="partner-employee-number">
          {t('EmployeeNumber')}
        </label>

        <div className="form-control-wrap">
          <input
            id="partner-employee-number"
            type="text"
            className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
            value={value}
            onChange={onChange}
            placeholder={t('IntroduceEmployeeNumber')}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDataViewEmployeeNr;
