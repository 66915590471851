import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Guest, Society } from 'types';

type SocietyGuestsAccessBadgeProps = {
  guest: Guest;
  society: Society;
};

const SocietyGuestsAccessBadge: FC<SocietyGuestsAccessBadgeProps> = ({
  guest,
  society,
}) => {
  const { t } = useTranslate();

  const badge = {
    text: 'AllSections',
    color: 'danger',
  };

  const currentSocietyPermissions = guest?.guestPermissions?.find(
    (permission) => permission.society === society?.['_id']
  );

  const hasAllPermissionsEnabled = currentSocietyPermissions
    ? Object.values(currentSocietyPermissions.permissions).every(
        (permission) => permission.enabled
      )
    : false;

  if (currentSocietyPermissions && !hasAllPermissionsEnabled) {
    badge.text = t('SomeSections');
    badge.color = 'warning';
  }

  return (
    <span className={`badge badge-dot badge-${badge.color}`}>
      {t(badge.text)}
    </span>
  );
};

export default SocietyGuestsAccessBadge;
