/* eslint-disable no-underscore-dangle */
import Swal from 'sweetalert2';
import { store } from 'redux/store';
import { deleteSocietyById } from 'modules/society/redux/societyActions';
import i18n from 'i18n/config';
import eventTypes from 'constants/eventTypes';
import trackEvent from './trackEvent';

export default function deleteSociety(params, societyId) {
  const { societyName, user, mixpanel } = params;

  Swal.fire({
    icon: 'warning',
    title: `<h4 class="nk-modal-title">${i18n.t(
      'AreYouSureAboutDelSociety'
    )}</h4>`,
    html: `<h5 class="text-primary">${societyName}</h5><br /><div class="caption-text">${i18n.t(
      'ThisProcedureCantUndo'
    )}</div>`,
    confirmButtonText: i18n.t('OkDelete'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch(deleteSocietyById(societyId));

      trackEvent(mixpanel, eventTypes.DELETE_SOCIETY, {
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
        societyId,
        societyName,
        operation: eventTypes.DELETE_SOCIETY,
      });
    }
  });
}
