import Record from 'typescript';

const hasPlanCustomDilution = (plans: Record<string, any>[]): boolean => {
  if (!plans) return false;
  return plans.some((plan) => plan?.hasCustomDilution);
};

export const getPartnersNamesWithCustomDilution = (
  partners: Record<string, any>[]
): string[] => {
  if (!partners) return [];
  return partners
    .filter((partner) => partner?.sharesCount?.hasDilution)
    .map((partner) => partner?.name);
};

export default hasPlanCustomDilution;
