import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import UploadDocumentsSection from 'modules/documents/components/UploadDocumentsSection';
import ImageInput from 'modules/documents/components/ImageInput';
import MandatoryField from 'modules/_shared/components/MandatoryField';
import useGetSelectorsOptions from '../hooks/useGetSelectorsOptions';

type Props = {
  name: string;
  cif: string;
  email: string;
  address: string;
  startDate: string;
  endDate: string;
  nationality: string;
  isNaturalPerson: boolean;
  birthdate: string;
  image: string;
  handleChangeName: any;
  handleChangeCif: any;
  handleChangeEmail: any;
  handleChangeAddress: any;
  handleChangeStartDate: any;
  handleChangeEndDate: any;
  handleChangeNationality: any;
  handleChangeMemberStatus: any;
  handleChangeMemberType: any;
  handleChangeCondition: any;
  handleChangeIsNaturalPerson: any;
  handleChangeBirthdate: any;
  handleChangeImage: any;
};

const AddCommitteeMemberModalBody: FC<Props> = ({
  name,
  cif,
  email,
  address,
  startDate,
  endDate,
  nationality,
  isNaturalPerson,
  birthdate,
  image,
  handleChangeName,
  handleChangeCif,
  handleChangeEmail,
  handleChangeAddress,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeNationality,
  handleChangeMemberStatus,
  handleChangeMemberType,
  handleChangeCondition,
  handleChangeIsNaturalPerson,
  handleChangeBirthdate,
  handleChangeImage,
}: Props) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const { memberStatusOptions, memberTypeOptions, memberConditionsOptions } =
    useGetSelectorsOptions().data;

  return (
    <>
      <Modal.Body>
        <div className="row gy-4">
          <div className="custom-control custom-radio mx-4">
            <input
              type="radio"
              className="custom-control-input"
              name="natural-person"
              id="natural-person"
              checked={isNaturalPerson}
              onChange={handleChangeIsNaturalPerson}
            />
            <label className="custom-control-label" htmlFor="natural-person">
              {t('NaturalPerson')}
            </label>
          </div>

          <div className="custom-control custom-radio mx-4">
            <input
              type="radio"
              className="custom-control-input"
              name="legal-person"
              id="legal-person"
              checked={!isNaturalPerson}
              onChange={handleChangeIsNaturalPerson}
            />
            <label className="custom-control-label" htmlFor="legal-person">
              {t('LegalPerson')}
            </label>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="member-name">
                {t('Name')} <MandatoryField />
              </label>

              <input
                id="member-name"
                type="text"
                className="form-control form-control-lg"
                value={name}
                onChange={handleChangeName}
                placeholder={t('NameAndSurname')}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="member-cif">
                {t('FiscalIdentification')} <MandatoryField />
              </label>

              <input
                id="member-cif"
                type="text"
                className="form-control form-control-lg"
                value={cif}
                onChange={handleChangeCif}
                placeholder={t('FiscalIdentification')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-email">
                {t('Email')} <MandatoryField />
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                id="member-email"
                value={email}
                onChange={handleChangeEmail}
                placeholder={t('Email')}
                pattern={EMAIL_PATTERN}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-address">
                {t('Address')}
              </label>
              <input
                id="member-address"
                type="text"
                className="form-control form-control-lg"
                value={address}
                onChange={handleChangeAddress}
                placeholder={t('Address')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-nationality">
                {t('Nationality')}
              </label>
              <input
                id="member-nationality"
                type="text"
                className="form-control form-control-lg"
                value={nationality}
                onChange={handleChangeNationality}
                placeholder={t('Nationality')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-birthdate">
                {t('DateOfBirth')}
              </label>
              <input
                type="date"
                className="form-control form-control-lg date-picker"
                id="member-birthdate"
                value={birthdate}
                onChange={handleChangeBirthdate}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-startDate">
                {t('StartDate')} <MandatoryField />
              </label>
              <input
                type="date"
                className="form-control form-control-lg date-picker"
                id="member-startDate"
                value={startDate}
                onChange={handleChangeStartDate}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <div className="d-flex">
                <label className="form-label" htmlFor="member-endDate">
                  {t('EndDate')}
                </label>
                <span className="sub-text ml-1 fs-12px">
                  {t('IndefiniteDefault')}
                </span>
              </div>
              <input
                type="date"
                className="form-control form-control-lg date-picker"
                id="member-endDate"
                value={endDate}
                onChange={handleChangeEndDate}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-status">
                {t('Status')} <MandatoryField />
              </label>
              <Select
                closeMenuOnSelect
                className="react-select react-select-lg"
                options={memberStatusOptions}
                // value={selectedPlanOption}
                components={animatedComponents}
                onChange={handleChangeMemberStatus}
                placeholder={t('SelectStatus')}
                // isDisabled={action === actions.EDIT}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-type">
                {t('Position')} <MandatoryField />
              </label>
              <Select
                closeMenuOnSelect
                className="react-select react-select-lg"
                options={memberTypeOptions}
                // value={selectedPlanOption}
                components={animatedComponents}
                onChange={handleChangeMemberType}
                placeholder={t('SelectPosition')}
                // isDisabled={action === actions.EDIT}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="member-condition">
                {t('MemberCondition')} <MandatoryField />
              </label>
              <Select
                closeMenuOnSelect
                className="react-select react-select-lg"
                options={memberConditionsOptions}
                // value={selectedPlanOption}
                components={animatedComponents}
                onChange={handleChangeCondition}
                placeholder={t('SelectCondition')}
                // isDisabled={action === actions.EDIT}
              />
            </div>
          </div>

          <ImageInput value={image} onChange={handleChangeImage} name={name} />

          {/* <div className="col-12">
            <UploadDocumentsSection
              initialDocumentsCount={representativeData?.documents?.length || 0}
              documents={files}
              setDocuments={setFiles}
            />
          </div> */}
        </div>
      </Modal.Body>
    </>
  );
};

export default AddCommitteeMemberModalBody;
