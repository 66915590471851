import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { CommitteeMember } from 'types';

import DetailsHeaderSection from 'modules/_shared/components/Sections/DetailsHeaderSection';
import DetailsListSection from 'modules/_shared/components/Sections/DetailsListSection';

import { getSummaryDetailsList } from '../utils/getSummaryDetailsList';

type CommitteeMemberDetailsPersonalTabProps = {
  member: CommitteeMember;
};

const CommitteeMemberDetailsPersonalTab: FC<
  CommitteeMemberDetailsPersonalTabProps
> = ({ member }) => {
  const { t } = useTranslate();

  const details = getSummaryDetailsList(member, t);

  return (
    <>
      <DetailsHeaderSection
        title={t('PersonalInformation')}
        description={t('BasicCommitteeMemberInformation')}
      />

      <DetailsListSection data={details} />
    </>
  );
};

export default CommitteeMemberDetailsPersonalTab;
