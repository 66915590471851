/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';
import screens from 'constants/screens';
import screenSizes from 'constants/screenSizes';
import { hasShowDetails } from 'utils/showDetails';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Nr'),
      field: 'index',
      sortable: true,
      center: true,
      compact: true,
      grow: 0.3,
      minWidth: 20,
      selector: (row) => row[i18n.t('Nr')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Nr')],
        }),
      export: true,
    },
    {
      name: i18n.t('Name'),
      field: 'name',
      sortable: true,
      left: true,
      compact: true,
      grow: 1.5,
      selector: (row) => row[i18n.t('Name')],
      cell: (row) =>
        CellData.Plan({
          name: row[i18n.t('Name')],
          isDraft: row.draft,
          url: `/beneficiarios/${row.society['_id']}/${row.id}`,
          initials: row.initials,
          showAvatar: false,
        }),
      export: true,
    },
    {
      name: i18n.t('StartDate'),
      field: 'startDate',
      sortable: true,
      center: true,
      compact: true,
      selector: (row) => row[i18n.t('StartDate')],
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('StartDate')],
        }),
      export: true,
      grow: 0.5,
    },
    {
      name: i18n.t('PriceByUnit'),
      field: 'sharePrice',
      sortable: true,
      center: true,
      compact: true,
      grow: 0.5,
      selector: (row) => row[i18n.t('PriceByUnit')],
      cell: (row) =>
        CellData.CurrencyNoDecimals({
          value: row[i18n.t('PriceByUnit')],
        }),
      export: true,
    },

    {
      name: i18n.t('CliffInMonths'),
      field: 'cliff',
      sortable: true,
      center: true,
      compact: true,
      grow: 0.2,
      selector: (row) => row[i18n.t('CliffInMonths')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('CliffInMonths')],
        }),
      export: true,
    },
    {
      name: i18n.t('VestingInMonths'),
      field: 'vestingPeriod',
      sortable: true,
      center: true,
      compact: true,
      grow: 0.2,
      selector: (row) => row[i18n.t('VestingInMonths')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('VestingInMonths')],
        }),
      export: true,
    },
    {
      name: i18n.t('ConsolidationVelocity'),
      field: 'consolidation',
      sortable: true,
      center: true,
      compact: true,
      selector: (row) => row[i18n.t('ConsolidationVelocity')],
      cell: (row) =>
        CellData.PlainText({
          text: i18n.t(`${row[i18n.t('ConsolidationVelocity')]}`),
        }),
      export: true,
      grow: 0.2,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('Pool'),
      field: 'pool',
      sortable: true,
      center: true,
      compact: true,
      selector: (row) => row[i18n.t('Pool')],
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('Pool')],
          decimals: 2,
        }),
      export: true,
      grow: 0.2,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('PlanType'),
      field: 'planType',
      sortable: true,
      center: true,
      compact: true,
      selector: (row) => row[i18n.t('PlanType')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('PlanType')],
        }),
      export: true,
      grow: 0.2,
      hide: screens.LG,
    },
    {
      name: i18n.t('AssignedTotals'),
      field: 'shares',
      sortable: true,
      center: true,
      compact: true,
      selector: (row) => row[i18n.t('AssignedTotals')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('AssignedTotals')],
        }),
      export: true,
      grow: 0.25,
    },
    hasShowDetails() && {
      name: i18n.t('SoldUnits'),
      field: 'soldUnits',
      sortable: true,
      center: true,
      compact: true,
      grow: 0.2,
      selector: (row) => row[i18n.t('SoldUnits')],
      cell: (row) =>
        CellData.PlainText({
          text: `-${row[i18n.t('SoldUnits')]}`,
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={row?.menuOptions}
                id={row.id}
                params={{
                  user: row.user,
                  planName: row.planName,
                  societyId: row.society['_id'],
                  stockPlan: row.currentPlan,
                  soldUnits: row[i18n.t('SoldUnits')],
                  isReadOnly: row.isReadOnly,
                }}
                direction="left"
                dropdownClassName="w-200px"
              />
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ].filter(Boolean);
}

export default tableColumns;
