type Props = {
  percent: number;
  totalShares: number;
};

export const getSharesFromPercent = ({ percent, totalShares }: Props) => {
  if (!totalShares) return 0;

  const resultShares = Math.floor((totalShares * percent) / 100);
  return resultShares;
};
