import { useAuth0 } from '@auth0/auth0-react';
import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Page403 = () => {
  const { logout } = useAuth0();

  const { t, i18n } = useTranslate();
  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const getSocietyName = (society) => {
    const societyByLang = {
      en: `${society?.name || ''} ${t('company')}`,
      es: `${t('theSociety')} ${society?.name || ''}`,
      fr: `${t('theSociety')} ${society?.name || ''}`,
    };
    if (society) {
      return societyByLang?.[i18n.language];
    }
    return t('thisSociety');
  };

  function onLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('access-token');
    logout({ returnTo: window.location.origin });
  }

  return (
    <div className="nk-app-root">
      <div className="nk-wrap nk-wrap-nosidebar bg-white">
        <div className="nk-content">
          <div className="nk-block-middle wide-md mx-auto h-500px">
            <div className="nk-error-ld text-center">
              <div className="wide-xs mx-auto">
                <h3 className="nk-error-title">{t('403Title')}</h3>
                <p className="nk-error-text">
                  {t('403TextPrefix')}
                  <b>{getSocietyName(actualSociety)}</b>
                  {t('403TextMiddle')}
                  <b>{user?.email}</b>
                  {t('403TextSuffix')}
                </p>

                <p className="nk-error-text">{t('403TextSecondary')}</p>

                <div className="">
                  <Link to="/">
                    <button
                      type="button"
                      color="primary"
                      size="lg"
                      className="btn btn-primary mt-2 mx-1 text-center w-200px d-inline-block"
                    >
                      {t('GoToHome')}
                    </button>
                  </Link>
                  <button
                    type="button"
                    color="primary"
                    size="lg"
                    className="btn btn-primary mt-2 mx-1 text-center w-200px d-inline-block"
                    onClick={onLogout}
                  >
                    {t('SignOut')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page403;
