import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { CommitteeMember } from 'types';

import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';
import CommitteeMembersTableRow from './CommitteeMembersTableRow';

type Props = {
  members: CommitteeMember[];
};

const CommitteeMembersTableBody: FC<Props> = ({ members }) => {
  const { t } = useTranslate();

  const hasData = members?.length > 0;

  return (
    <>
      <tbody>
        {hasData ? (
          members?.map((member: CommitteeMember, index: number) => (
            <CommitteeMembersTableRow index={index} member={member} />
          ))
        ) : (
          <NoItemsAvailable mainMessage={t('NoCommiteeMembersMessage')} />
        )}
      </tbody>
    </>
  );
};

export default CommitteeMembersTableBody;
