import { useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Board, Society } from 'types';

import { formatDateToTime } from 'constants/formats';
import votePeriods from 'constants/votePeriods';
import attendanceTypes from 'constants/attendanceTypes';
import boardTypes from 'constants/boardTypes';

export default function useGetAnnouncementData(board: Board, society: Society) {
  const [announcementData, setAnnouncementData] = useState<any>({});

  useEffect(() => {
    const data = {
      time: board.date ? formatDateToTime(board.date) : '-',
      boardName: board?.name || '',
      date: board.date ? format(new Date(board.date), 'dd/MM/yyyy') : '-',
      votePeriod:
        votePeriods.find((period) => period.value === board?.votePeriod)
          ?.text || '-',
      societyName: society?.name || '-',
      announcement: board?.announcement || '',
      place: board?.place || '-',
      onlineAccess: board?.onlineAccess || '-',
      attendanceType: attendanceTypes?.[board?.attendanceType]?.text || '-',
      boardType: boardTypes?.[board?.boardType]?.text || '-',
    };
    setAnnouncementData(data);
  }, [board, society]);

  return { announcementData };
}
