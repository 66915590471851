import { useEffect, useState } from 'react';

import { Board, BoardOrder } from 'types';
import getParticipantInstruction from 'utils/getParticipantInstructions';

type Props = {
  board: Board;
  partnerId: string;
  order: BoardOrder | undefined;
};

function isRepresented(participant: any, partnerId: string) {
  return (
    participant?.isRepresented && participant?.representative === partnerId
  );
}

function getRepresentedPartners(
  board: Board,
  partnerId: string,
  order: BoardOrder | undefined
) {
  const options: any[] = [];

  if (order) {
    board?.participants?.forEach((participant) => {
      const isRepresentedByPartner = isRepresented(participant, partnerId);
      const instruction = getParticipantInstruction(participant, order);

      if (isRepresentedByPartner) {
        options.push({
          name: participant?.name,
          instruction,
        });
      }
    });
  }

  return options;
}

export default function useGetRepresentedPartners({
  board,
  partnerId,
  order,
}: Props) {
  const [representedPartners, setRepresentedPartners] = useState<any[]>([]);

  useEffect(() => {
    const options = getRepresentedPartners(board, partnerId, order);
    setRepresentedPartners(options);
  }, [board, partnerId, order]);

  return {
    data: { representedPartners },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
