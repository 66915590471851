const internalFF = 'showDetails';
const newPDF = 'newPDF';
const beneficiariesTest = 'btest';
const showDev = 'show-dev';
const blockAI = 'blockAI';
const newUI = 'newUI';

const getFromStorage = (key) => JSON.parse(localStorage.getItem(key));

export const hasShowDetails = () => getFromStorage(internalFF);
export const hasNewPDF = () => getFromStorage(newPDF);
export const hasBeneficiariesTest = () => getFromStorage(beneficiariesTest);
export const hasInternalView = () => getFromStorage(showDev);
export const hasBlockAI = () => getFromStorage(blockAI);
export const hasNewUI = () => getFromStorage(newUI);
