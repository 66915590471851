import React from 'react';
import {
  getPartnerFromParticipant,
  getRepresentativeFromParticipant,
} from 'modules/boards/utils';
import { getPartnerNDPercent } from 'utils/filters';
import { Board, BoardParticipant, PartnerRepresentant, Society } from 'types';
import AssistantVoteRow from '../AssistantVoteRow';

type GetParticipantRowsProps = {
  order: string;
  board: Board;
  actualSociety: Society;
  partnerRepresentants: PartnerRepresentant[];
  customSelected: any;
  setCustomSelected: any;
};

export const getParticipantRows = ({
  order,
  board,
  actualSociety,
  partnerRepresentants,
  customSelected,
  setCustomSelected,
}: GetParticipantRowsProps) => {
  const participantRows: React.JSX.Element[] = [];

  board.participants.forEach((participant: BoardParticipant, index: number) => {
    const partner = getPartnerFromParticipant(participant, actualSociety);

    const hasShares = (partner?.sharesCount?.actual || 0) > 0;
    const isNotAbsent = !participant?.isAbsent;

    if (hasShares && isNotAbsent) {
      const representative = getRepresentativeFromParticipant(
        participant,
        actualSociety,
        partnerRepresentants
      );

      const orderData = board?.orders?.find((o) => o['_id'] === order) || {};
      const voteData = participant.votes.find((v) => v.order === order);

      const participantData = {
        ...participant,
        representative,
        index: index + 1,
        hasVoted: !!voteData,
        voteData,
        orderData,
        percentage: getPartnerNDPercent(partner, actualSociety),
        sharesCount: partner?.sharesCount?.actual || 0,
        societyId: actualSociety['_id'],
        image: partner?.image,
      };

      participantRows.push(
        <AssistantVoteRow
          assistant={participantData}
          board={board}
          key={`assistant-${participantData.index}`}
          order={order}
          customSelected={customSelected}
          setCustomSelected={setCustomSelected}
        />
      );
    }
  });
  return participantRows;
};
