// eslint-disable-next-line no-use-before-define
import React from 'react';

import { Board } from 'types';
import { BoardOrderVotesData } from 'types/Board';

import voteResultValues from 'constants/voteResultValues';

import { getChartVotingResultsData } from 'utils/charts';
import { isBoardClosed, isOpenVoting } from 'utils/boards';

import OrderRow from '../OrderRow';

type OrdersRowProps = {
  board: Board;
  ordersVotesData: BoardOrderVotesData[];
  setSelectedOrder: any;
};

const orderVotesInitialState = {
  favor: 0,
  against: 0,
  abstention: 0,
  totalVotesNDPercent: 0,
};

export const getOrdersRows = ({
  board,
  ordersVotesData,
  setSelectedOrder,
}: OrdersRowProps): React.JSX.Element[] => {
  if (!board?.orders?.length || !ordersVotesData.length) {
    return [];
  }

  const ordersRows = board?.orders.map((order) => {
    const orderId = order['_id'];
    const orderVotesData =
      ordersVotesData.find((order) => order.id === orderId) ||
      orderVotesInitialState;

    const favorPercent = orderVotesData.favor;
    const againstPercent = orderVotesData.against;
    const abstainPercent = orderVotesData.abstention;
    const totalPercent = orderVotesData.totalVotesNDPercent;

    const isOpen = !isBoardClosed(board) && isOpenVoting(board);

    const chartData = getChartVotingResultsData([
      Number(favorPercent.toFixed(2)),
      Number(againstPercent.toFixed(2)),
      Number(abstainPercent.toFixed(2)),
    ]);

    let votingResult;

    if (isOpen) {
      votingResult = voteResultValues.OPEN;
    } else if (favorPercent > againstPercent) {
      votingResult = voteResultValues.YES;
    } else if (favorPercent < againstPercent) {
      votingResult = voteResultValues.NO;
    } else {
      votingResult = voteResultValues.ABS;
    }

    return (
      <OrderRow
        key={order['_id']}
        order={order}
        votingResult={votingResult}
        chartData={chartData}
        orderVotesData={orderVotesData}
        totalPercent={totalPercent}
        setSelectedOrder={setSelectedOrder}
      />
    );
  });

  return ordersRows;
};
