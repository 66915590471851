import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Tooltip } from 'react-tooltip';

import voteValues from 'constants/voteValues';

import { Represented } from '../types/Represented';

type Props = {
  index: number;
  represented: Represented;
};

const InstructionsTableRow: FC<Props> = ({ index, represented }: Props) => {
  const { t } = useTranslate();

  const instructionValueLable =
    voteValues[represented.instruction as keyof typeof voteValues]?.label ||
    '-';

  return (
    <>
      <tr
        id={`participant-name-${index}`}
        key={`participant-name-${index}`}
        className={represented?.hasConflict ? 'text-danger' : ''}
      >
        <td className="w-65">{represented.label}</td>
        <td className="w-25">{t(instructionValueLable)}</td>
      </tr>

      {represented.hasConflict && (
        <Tooltip
          data-tooltip-id={`participant-name-${index}`}
          data-tooltip-place="top"
        >
          {t('VoteConflictTooltip')}
        </Tooltip>
      )}
    </>
  );
};

export default InstructionsTableRow;
