import React from 'react';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import actionTypes from 'constants/actionTypes';
import PartnerCategoryModal from 'modules/_shared/components/Modals/PartnerCategory';

export default function editPartnerCategory({ data }) {
  store.dispatch(
    setModal(
      <PartnerCategoryModal
        action={actionTypes.EDIT}
        currentPartnerCategory={data}
      />
    )
  );
}
