import { useEffect, useState } from 'react';
import { StockPlan } from 'types';

const EXTRA_SIGNER_TAG = '{FIRMA_SOCIEDAD}';
type Props = {
  htmlContent: string;
  plan: StockPlan;
};

export function useGetExtraSignerInitial({ htmlContent, plan }: Props) {
  const [extraSignerName, setExtraSignerName] = useState<string>('');
  const [extraSignerEmail, setExtraSignerEmail] = useState<string>('');
  const [hasExtraSigner, setHasExtraSigner] = useState<boolean>(false);

  useEffect(() => {
    if (htmlContent) {
      const isOnHtmlContent = htmlContent.indexOf(EXTRA_SIGNER_TAG) !== -1;
      setHasExtraSigner(isOnHtmlContent);
    }
  }, [htmlContent]);

  useEffect(() => {
    if (plan) {
      setExtraSignerName(plan?.invitationExtraSigner?.name || '');
      setExtraSignerEmail(plan?.invitationExtraSigner?.email || '');
    }
  }, [plan]);

  return {
    data: { extraSignerName, extraSignerEmail, hasExtraSigner },
    isLoading: false,
    isError: false,
    actions: { setExtraSignerName, setExtraSignerEmail },
  };
}
