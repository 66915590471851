import { SocietyChart } from 'types';
import getHolderInfoByDiagram from './getHolderInfoByDiagram';
import getSharesInfo from './getSharesInfo';
import getPercentInfo from './getPercentInfo';

type DiagramType = 'HOLDING' | 'PARTNER' | 'ADMIN';

const formatData = (holding: SocietyChart, diagramType: DiagramType) => {
  const id = holding?.holdingId;
  const name = holding?.holdingName;
  const logo = holding?.holdingLogo;
  const email = holding?.holdingEmail;
  const shares = getSharesInfo(holding);
  const userType = holding?.holdingUserType;

  const data =
    holding?.holdingShares === null
      ? holding?.holdingCIF
      : getHolderInfoByDiagram(holding, diagramType);

  const percent =
    holding?.holdingShares === null
      ? holding?.holdingCIF
      : getPercentInfo(holding);

  const percentNDValue = holding?.holdingPercentND;
  const percentFDValue = holding?.holdingPercentFD;

  return {
    id,
    name,
    logo,
    data,
    email,
    shares,
    percent,
    userType,
    percentNDValue,
    percentFDValue,
  };
};

export default formatData;
