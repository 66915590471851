import { useEffect, useState } from 'react';

import { Represented } from '../types/Represented';

type Props = {
  currentRepresented: Represented;
  representedWithInstructions: Represented[];
  representedWithoutInstructions: Represented[];
};

function getVoteStep(
  representedWithInstructions: Represented[],
  representedWithoutInstructions: Represented[]
) {
  if (representedWithInstructions?.length > 0) {
    return 0;
  }

  if (representedWithoutInstructions?.length > 0) {
    return 1;
  }

  return 2;
}

function getSelectedrepresented(
  step: number,
  currentRepresented: Represented,
  representedWithInstructions: Represented[],
  representedWithoutInstructions: Represented[]
): Represented[] {
  if (step === 0) {
    return representedWithInstructions;
  }
  if (step === 1) {
    return representedWithoutInstructions;
  }
  return [currentRepresented];
}

export default function useGetInitialVoteStep({
  currentRepresented,
  representedWithInstructions,
  representedWithoutInstructions,
}: Props) {
  const [voteStep, setVoteStep] = useState<number>(0);
  const [selectedRepresented, setSelectedRepresented] = useState<Represented[]>(
    []
  );

  useEffect(() => {
    const step = getVoteStep(
      representedWithInstructions,
      representedWithoutInstructions
    );

    const represented = getSelectedrepresented(
      step,
      currentRepresented,
      representedWithInstructions,
      representedWithoutInstructions
    );

    setVoteStep(step);
    setSelectedRepresented(represented);
  }, [
    currentRepresented,
    representedWithInstructions,
    representedWithoutInstructions,
  ]);

  return {
    data: {
      voteStep,
      selectedRepresented,
    },
    isLoading: false,
    isError: false,
    actions: { setVoteStep, setSelectedRepresented },
  };
}
