import countries from 'constants/countries';
import i18n from 'i18n/config';

export const getEmployeeSalaryAmount = (employee) => {
  if (!employee.terminationDate) {
    const lastContract = employee.contracts[employee.contracts.length - 1];
    return lastContract.salaryAmount ? lastContract.salaryAmount / 100 : 'N/A';
  }

  return 'N/A';
};

export const getEmployeeTeam = (employee) => {
  if (!employee.terminationDate && employee.teams?.length) {
    const teams = employee.teams?.map((team) => team.name);
    return teams.join(', ');
  }

  return 'N/A';
};

export const getEmployeeIncentive = (employee, cifList) => {
  const { cif } = employee;
  return cifList?.includes(cif?.toUpperCase());
};

export const getTotalEmployeesWithIncentive = (employees, cifList) => {
  const total = employees?.filter((employee) =>
    cifList?.includes(employee.cif)
  );
  return total?.length || 0;
};

export const getLastImportationDate = (employeesList) => {
  const lastImportationDate = employeesList?.reduce((prev, current) =>
    prev.importationDate > current.importationDate ? prev : current
  );

  return lastImportationDate?.importationDate;
};

export const getEmployeeLastContractModificationDate = (employee) => {
  const lastContract = employee?.contracts[employee?.contracts.length - 1];

  return lastContract?.startDate;
};

export const getEmployeeFirstEffectiveDate = (employee) => {
  const firstContract = employee?.contracts?.reduce((prev, current) =>
    prev.effectiveDate < current.effectiveDate ? prev : current
  );

  return firstContract?.effectiveDate;
};

export const getEmployeeFirstStartDate = (employee) => {
  const firstContract = employee?.contracts?.reduce((prev, current) =>
    prev.startDate < current.startDate ? prev : current
  );

  return firstContract?.startDate;
};

export const getEmployeeLastStartDate = (employee) => {
  const lastContract = employee?.contracts?.reduce((prev, current) =>
    prev.endDate > current.endDate ? prev : current
  );

  return lastContract?.endDate;
};

export const getEmployeeJobTitle = (employee) => {
  const lastContract = employee.contracts[employee.contracts.length - 1];
  return lastContract.jobTitle || lastContract.role;
};

export const getEmployeeTerminationDate = (employee) =>
  employee?.terminationDate;

export const getEmployeeAddress = (employee) => {
  const { line1, line2, city, zip, state } = employee?.address || {};

  const address = [line1, line2, city, zip, state].filter(Boolean).join(', ');

  return address;
};

export const getEmployeeFullName = (employee) =>
  employee?.fullName || employee?.name;

export const getEmployeePhoneNumber = (employee) => employee?.phone_number;

export const getEmployeeEmail = (employee) => employee?.email;

export const getEmployeeNationality = (employee) => {
  const nationalityCode = employee?.nationality?.toUpperCase();
  const nationalityGroup = countries?.[nationalityCode] || null;

  const lang = i18n?.language;

  if (lang && nationalityGroup) {
    return nationalityGroup[lang];
  }

  return 'N/A';
};

export const getEmployeeCIF = (employee) => employee?.cif;
