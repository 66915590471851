import { ChangeEvent, FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type PartnerRepresentantInputCifProps = {
  cif: string | undefined;
  onChangeCif: (event: any) => void; // eslint-disable-line
};

const PartnerRepresentantInputCif: FC<PartnerRepresentantInputCifProps> = ({
  cif,
  onChangeCif,
}) => {
  const { t } = useTranslate();

  const handleChangeCif = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeCif(event.target.value);
  };

  return (
    <div className="form-group mt-4">
      <label className="form-label" htmlFor="partner-cif">
        {t('FiscalIdentification')}
      </label>

      <div className="form-control-wrap">
        <div className="form-icon form-icon-left">
          <em className="icon ni ni-cc-alt2" />
        </div>

        <input
          type="text"
          name="partner-cif"
          className="form-control"
          value={cif}
          onChange={handleChangeCif}
          placeholder={t('EnterTheCifOrNif')}
          required
        />
      </div>
    </div>
  );
};

export default PartnerRepresentantInputCif;
