import { useLocation } from 'react-router-dom';

import { getThemeBackgroundColor, getThemeHeaderFullWidth } from 'utils/theme';

import './Header.scss';

import useHeader from './useHeader';
import HeaderLogo from '../HeaderLogo';
import HeaderMenu from '../HeaderMenu';
import HeaderTools from '../HeaderTools';

function Header() {
  const location = useLocation();

  const { data } = useHeader(location);

  const headerWidth = getThemeHeaderFullWidth(data)
    ? 'container-fluid'
    : 'container-lg wide-xl';

  return (
    <div
      className="app-header nk-header nk-header-fixed"
      style={{ backgroundColor: getThemeBackgroundColor(data) }}
    >
      <div className={headerWidth}>
        <div
          className="nk-header-wrap"
          style={{ height: data?.theme?.headerHeight }}
        >
          <HeaderLogo data={data} />

          <HeaderMenu data={data} />

          <HeaderTools data={data} />
        </div>
      </div>
    </div>
  );
}

export default Header;
