/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

import { numberFormat } from 'constants/formats';
import { formatCurrencyDecimals } from 'utils/formats';

function InvestorTransactionsRow({ investor, index }) {
  return (
    <tr>
      <td>
        <b>{index}</b>
      </td>
      <td>{formatCurrencyDecimals(investor?.contribution)}</td>
      <td>{format(new Date(investor?.contributionDate), 'dd/MM/yyyy')}</td>
      <td className="d-none d-xl-table-cell">
        <NumberFormat
          value={investor?.sharesCount?.drafts || 0}
          displayType="text"
          {...numberFormat}
          decimals={0}
        />
      </td>
      <td>{investor?.draftName}</td>
    </tr>
  );
}

export default InvestorTransactionsRow;
