/* eslint-disable react/jsx-indent */
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import html2pdf from 'html3pdf';
import { format } from 'date-fns';

import CertificationDownload from 'modules/communications/components/CertificationDownload';
import { updateRecipietns } from 'modules/communications/components/utils';

async function staticHtml(communication, recipientsInfo) {
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Certificado</title>
        <link rel="stylesheet" href="#" />
      </head>
      <body>
        <CertificationDownload
          communication={communication}
          recipientsInfo={recipientsInfo}
        />
      </body>
    </html>
  );
}

const handleGenerateCertificatePDF = async (communication) => {
  try {
    const certificateId = communication?.certificate?.id || '00746F5K28';
    const recipientsInfo = await updateRecipietns(communication);
    const htmlElement = await staticHtml(communication, recipientsInfo);
    const html = ReactDOMServer.renderToString(htmlElement);
    const options = {
      filename: `Certificado_${certificateId}_${format(
        new Date(),
        'yyyy-MM-dd_HH-mm-ss'
      )}.pdf`,
      html2canvas: { scale: 2 },
    };
    html2pdf().from(html).set(options).save();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log({ error });
  }
};

export default handleGenerateCertificatePDF;
