import i18n from 'i18n/config';

export default {
  ALL_YES: {
    value: 'ALL_YES',
    text: i18n.t('AllYes'),
  },
  ALL_NO: {
    value: 'ALL_NO',
    text: i18n.t('AllNo'),
  },
  ALL_ABS: {
    value: 'ALL_ABS',
    text: i18n.t('AllAbs'),
  },
  POINT_BY_POINT: {
    value: 'POINT_BY_POINT',
    text: i18n.t('PointByPoint'),
  },
};
