import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type Props = {
  value: string;
  onChange: any;
};

const PersonalDataViewFiscalIdentity: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslate();

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label" htmlFor="partner-cif">
          {t('FiscalIdentification')}
        </label>

        <div className="form-control-wrap">
          <div className="form-icon form-icon-left">
            <em className="icon ni ni-cc-alt2" />
          </div>
          <input
            id="partner-cif"
            type="text"
            className="form-control form-control-lg"
            value={value}
            onChange={onChange}
            placeholder={t('EnterTheCifOrNif')}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDataViewFiscalIdentity;
