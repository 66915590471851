import { BoardParticipant, Society } from 'types';
import { getPartnerActualShares } from 'utils/filters';

type Props = {
  participant: BoardParticipant;
  actualSociety: Society;
  allParticipants: boolean;
};

const isRepresentedButNotDelegated = (participant: BoardParticipant) =>
  participant?.isRepresented && !participant.hasDelegated;

const isNotAssistingNorAbsentNorDelegated = (participant: BoardParticipant) =>
  !participant?.assists && !participant?.isAbsent && !participant?.hasDelegated;

export const isParticipantAllowedForReminder = ({
  participant,
  actualSociety,
  allParticipants,
}: Props) => {
  const participantShares = getPartnerActualShares(
    participant.member,
    actualSociety
  );

  if (participantShares === 0) return false;

  if (allParticipants) return true;

  return (
    isRepresentedButNotDelegated(participant) ||
    isNotAssistingNorAbsentNorDelegated(participant)
  );
};
