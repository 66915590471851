/* eslint-disable no-underscore-dangle */
import {
  getAvailablePhantoms,
  getAvailableShares,
  getAvailableStocks,
  getBeneficiaryUnitsVested,
  getFullAddress,
  getPartnerAmount,
} from 'utils/filters';

const parseParticipant = (participant, tenderOffer, society, isPartner) => {
  let shares = 0;
  let originalShares = 0;
  let phantoms = 0;
  let originalPhantoms = 0;
  let stocks = 0;
  let originalStocks = 0;
  let value = 0;
  let exercisePrice = 0;
  let planType = '';
  let planData = {};

  if (isPartner) {
    const partner = participant;

    shares = getAvailableShares(partner, tenderOffer);
    originalShares = partner.sharesCount?.actual || 0;
    phantoms = 0;
    stocks = 0;
    value = getPartnerAmount(partner.shares, society) || 0;
    exercisePrice = 0;
  } else {
    const beneficiary = participant;
    beneficiary.calculationDate =
      tenderOffer.calculationDate || tenderOffer.startDate;

    shares = beneficiary.sharesCount?.actual || 0;
    phantoms =
      beneficiary.planType === 'PHANTOM_SHARE'
        ? getAvailablePhantoms(beneficiary, tenderOffer)
        : 0;
    originalPhantoms =
      beneficiary.planType === 'PHANTOM_SHARE'
        ? getBeneficiaryUnitsVested(
            beneficiary,
            beneficiary.planData,
            beneficiary.calculationDate
          )
        : 0;
    stocks =
      beneficiary.planType === 'STOCK_OPTION'
        ? getAvailableStocks(beneficiary, tenderOffer)
        : 0;
    originalStocks =
      beneficiary.planType === 'STOCK_OPTION'
        ? getBeneficiaryUnitsVested(
            beneficiary,
            beneficiary.planData,
            beneficiary.calculationDate
          )
        : 0;
    value = getPartnerAmount(beneficiary.shares, society) || 0;
    exercisePrice = beneficiary.amount || 0;
    planType = beneficiary.planType;
    planData = beneficiary.planData;
  }

  const address = getFullAddress(participant?.address) || '-';

  return {
    id: participant._id,
    name: participant.name || '-',
    email: participant.email.toLowerCase() || '-',
    cif: participant.cif.toUpperCase() || '-',
    address,
    shares,
    originalShares,
    phantoms,
    originalPhantoms,
    stocks,
    originalStocks,
    value,
    acquisitionPrice: value,
    exercisePrice,
    planType,
    planData,
    retentionPercent: tenderOffer.retentionPercent || 0,
  };
};

export default parseParticipant;
