/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';
import { store } from 'redux/store';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import '../Modals.scss';

const SendTestEmail = ({ emailData }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const [to, setTo] = useState([]);

  const handleChangeTo = (event) => {
    setTo(event.target.value);
  };

  const handleSendEmail = async () => {
    const DEFAULT_RECEIVER = 'Destinatario';

    try {
      const emailDataWithRecipient = {
        to: [{ email: to.trim(), name: DEFAULT_RECEIVER }],
        ...emailData,
      };

      await store.dispatch(sendCommEmail(emailDataWithRecipient, false));

      dispatch(addAlert(alertBodyTypes['TEST_EMAIL_SENT']));
      dispatch(setModal(null));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_SENDING_TEST_EMAIL']));
      dispatch(setModal(null));
    }
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('Enviar correo de prueba')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body className="no-padding">
        <div className="nk-block nk-block-lg">
          <div className="card p-4">
            <div className="card-aside-wrap">
              <div className="card-content">
                <div className="card-inner p-0">
                  <div className="row">
                    <div className="col-12">
                      <p className="text-secondary mb-4">
                        Introduce aquí un email y te enviaremos un pequeño
                        mensaje. Recuerda que el email debe ser válido. Si no
                        recibes el email, revisa la carpeta de spam.
                      </p>
                    </div>
                    <div className="form-group col-12 mb-3">
                      <label className="form-label" htmlFor="reply-email">
                        Email:
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          id="reply-email"
                          name="reply-email"
                          value={to}
                          onChange={handleChangeTo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-outline-light px-3"
          onClick={() => dispatch(setModal(null))}
        >
          {t('Close')}
        </button>
        <button
          type="button"
          className="btn btn-primary px-3"
          onClick={handleSendEmail}
        >
          {t('Send')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default SendTestEmail;
