import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import { Partner, Society } from 'types';

type NotificationModalProps = {
  actualSociety: Society;
  templateBody: string;
};

const NotificationModal: FC<NotificationModalProps> = ({
  actualSociety,
  templateBody,
}) => {
  const { t } = useTranslate();

  const user: any = useSelector((state: any) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recipientPartners, setRecipientPartners] = useState<Partner[] | []>(
    []
  );
  const [recipientsCount, setRecipientsCount] = useState<number>(0);
  const [expandDirection, setExpandDirection] = useState<string>('down');
  const [expandView, setExpandView] = useState<boolean>(false);
  const template = {
    es: 'shares-certificate-notification-es',
    en: 'shares-certificate-notification-en',
    fr: 'shares-certificate-notification-fr',
  };

  const handleViewChange = (): void => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  const handleSendNotification = async (): Promise<void> => {
    try {
      setIsLoading(true);

      // Get the recipients.
      const recipients = recipientPartners.map((partner) => ({
        email: partner.email,
        name: partner.name,
      }));

      // Get the dynamic data for the recipients.
      const recipientsForDynamicData = recipientPartners.map((recipient) => {
        const { _id, email, name } = recipient;
        return {
          id: _id,
          email: email ? email.trim() : '',
          name,
        };
      });

      const data = {
        societyName: actualSociety?.name || '-',
      };

      const emailData = {
        to: recipients,
        cc: '',
        bcc: '',
        files: [],
        subject: t('ValueShares'),
        bodySummary: '',
        template: template[i18n.language as keyof typeof template],
        templateBody,
        templateData: [
          {
            name: 'societyName',
            content: data.societyName,
          },
        ],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'partnerName', content: name },
              {
                name: 'targetUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=detalle-socio/${actualSociety['_id']}/${id}`,
              },
            ],
          })
        ),
        user: user['_id'],
        society: actualSociety['_id'],
        date: new Date(),
      };

      // Send the email and create the communication.
      await store.dispatch(sendCommEmail(emailData));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Get the active partners.
    const partners =
      actualSociety?.partners.filter(
        (partner) => partner?.sharesCount?.actual > 0
      ) || [];

    setRecipientPartners(partners);
    setRecipientsCount(partners?.length);
  }, [actualSociety]);

  return (
    <>
      <Modal.Body>
        <div className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <button
              type="button"
              className="close"
              onClick={() => store.dispatch(setModal(null))}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt" />
            <h4 className="nk-modal-title">{t('SendNotification')}</h4>
            <div className="nk-modal-text">
              <p>{t('SendNotificationMessage')}</p>
            </div>
            <div key="recipients-card" style={{ paddingBottom: '20px' }}>
              <table
                className="table"
                id="recipients-table"
                style={{ marginBottom: '0px' }}
              >
                <thead>
                  <tr className="tb-tnx-head">
                    <th className="text-left" colSpan={2}>
                      <span>
                        {t('Recipients')} ({recipientsCount})
                      </span>
                    </th>
                    <th className="col-2 text-right">
                      <button
                        type="button"
                        className="badge btn-primary"
                        onClick={() => handleViewChange()}
                      >
                        <em
                          className={`icon ni ni-chevron-${expandDirection}`}
                        />
                      </button>
                    </th>
                  </tr>
                </thead>
              </table>
              <div
                key="recipients-card"
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                <table className="table" id="recipients-body">
                  {expandView && (
                    <tbody>
                      {recipientPartners?.map((recipient, index) => (
                        <tr className="tb-tnx-item">
                          <td
                            className="tb-tnx-info text-left"
                            id={`recipients-${index}`}
                          >
                            <span className="title">{recipient.name}</span>
                          </td>
                          <td
                            className="tb-tnx-info text-soft text-left"
                            id={`recipients-${index}-email`}
                          >
                            <span className="title">{recipient.email}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={() => handleSendNotification()}
            >
              {t('SendNow')}
            </button>
          </div>
        </div>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default NotificationModal;
