// eslint-disable-next-line no-use-before-define
import React, { FC } from 'react';

type PageProps = {
  children: React.ReactNode;
};

const Page: FC<PageProps> = ({ children }) => (
  <div className="nk-content-body">
    <div className="nk-content-wrap">{children}</div>
  </div>
);

export default Page;
