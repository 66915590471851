import { useTranslate } from 'hooks/useTranslate';
import { BoardParticipant } from 'types';
import { isRepresentingOtherParticipant } from './utils';

type AssistentsModalRowSelectorWarningProps = {
  participant: BoardParticipant;
  participants: BoardParticipant[];
};

const getWarningMessage = (
  participant: BoardParticipant,
  participants: BoardParticipant[]
): string => {
  if (participant.votes.length > 0) {
    return 'ThisParticipantHasAlreadyVoted';
  }
  if (isRepresentingOtherParticipant(participant.member, participants)) {
    return 'ThisParticipantIsRepresentingAnotherParticipant';
  }

  return '';
};

const AssistentsModalRowSelectorWarning = ({
  participant,
  participants,
}: AssistentsModalRowSelectorWarningProps) => {
  const { t } = useTranslate();

  const message: string = getWarningMessage(participant, participants);

  return <span className="text-warning ">{t(message)}</span>;
};

export default AssistentsModalRowSelectorWarning;
