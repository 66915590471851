/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { FC, useEffect } from 'react';

import languages from 'constants/languages';

interface StepProps {
  data: Record<string, any>;
  setData: (data: any) => void;
  setCompletedSteps: (data: any) => void;
  setActiveStep: (data: any) => void;
}

const Step4: FC<StepProps> = ({
  data,
  setData,
  setCompletedSteps,
  setActiveStep,
}) => {
  const { t } = useTranslate();

  useEffect(() => {
    setCompletedSteps((prevData: any) => ({
      ...prevData,
      3: true,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nk-stepper-step">
      <h5 className="title mb-4">{t('AddSocietyWizard.step4')}</h5>

      <div className="row g-3">
        <div className="col-12">
          <p>
            Procederemos a dar de alta en nuestra plataforma a la Sociedad{' '}
            <b>{data.socialDenomination}</b>, con CIF <b>{data.cif}</b>, con
            domicilio en&nbsp;
            <b>
              {data.line1 || ''}, {data.zip || ''}, {data.city || ''},{' '}
              {data.country || ''}
            </b>
            .
          </p>
          <p>
            Las comunicaciones para los miembros de esta sociedad serán en
            idioma <b>{languages[data.commsLanguage]?.name}</b>.
          </p>
          <p>
            {data.sendNotifications
              ? 'Se enviarán notificaciones a los miembros de la sociedad cuando se realice alguna operación.'
              : 'No se enviarán notificaciones a los miembros de la sociedad cuando se realicen operaciones.'}
          </p>
          <p>
            {data.hasAccessCaptable
              ? 'Los miembros de la sociedad tendrán acceso al Captable.'
              : 'Los miembros de la sociedad no tendrán acceso al Captable.'}
          </p>
          <p>
            La sociedad cuenta con <b>{data.totalShares}</b> participaciones,
            con un valor nominal de <b>{data.nominalValue}€</b> cada una. Estas
            participaciones se encuentran divididas entre sus{' '}
            <b>{data.partners?.length}</b> socios, con la siguiente
            distribución:
          </p>
          <ul className="list list-item">
            {data.partners?.map((partner: any) => (
              <li>
                <b>{partner.name}</b> con <b>{partner.shares}</b>{' '}
                participaciones de clase <b>{partner?.holdingClass?.name}</b>
                &nbsp; ({partner?.shareFrom}-{partner?.shareTo})
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="wizard--pagination card-inner mt-5">
        <ul className="nk-stepper-pagination gx-4 stepper-pagination">
          <li className="step-prev">
            <button
              type="button"
              className="btn btn-dim btn-primary"
              onClick={() => setActiveStep((activeStep: any) => activeStep - 1)}
            >
              {t('Back')}
            </button>
          </li>

          <li className="step-next">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => setActiveStep((activeStep: any) => activeStep + 1)}
            >
              {t('AddSociety')}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Step4;
