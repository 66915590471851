import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updatePartner } from 'modules/partners/redux/partnerActions';

import { Partner } from 'types';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import { getActualSociety } from 'utils/filters';
import isValidEmail from 'utils/isValidEmail';
import successSwal from 'utils/successSwal';
import getPartnerData from './utils/getPartnerData';

import EditPartnerModalBody from './EditPartnerModalBody';
import EditPartnerModalFooter from './EditPartnerModalFooter';
import EditPartnerModalHeader from './EditPartnerModalHeader';

type Props = {
  partner: Partner;
  societyId: string;
};

const EditPartnerModal: FC<Props> = ({ partner, societyId }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const [page, setPage] = useState<number>(0);

  const hasTaxAddress = !!(
    partner?.taxAddress?.line1 ||
    partner?.taxAddress?.city ||
    partner?.taxAddress?.zip ||
    partner?.taxAddress?.country
  );

  const [updatedPartner, setUpdatedPartner] = useState<Partial<Partner>>({
    name: partner?.name,
    cif: partner?.cif,
    email: partner?.email,
    emails: partner?.emails,
    birthdate: partner?.birthdate,
    employeeNumber: partner?.employeeNumber,
    nationality: partner?.nationality,
    phone: partner?.phone,
    category: partner?.category,
    sindication: partner?.sindication,
    sindicationRef: partner?.sindicationRef,
    sindicationType: partner?.sindicationType,
    sindicationDocument: partner?.sindicationDocument,
    civilStatus: partner?.civilStatus,
    image: partner?.image,
    address: partner?.address,
    taxAddress: hasTaxAddress
      ? partner?.taxAddress
      : {
          line1: '',
          city: '',
          zip: '',
          country: '',
        },
    bankAccountNumber: partner?.bankAccountNumber,
    isNaturalPerson: partner?.isNaturalPerson,
    representative: partner?.representative,
    legalInfo: partner?.legalInfo,
    constitutionDocument: partner?.constitutionDocument,
    appointmentDocument: partner?.appointmentDocument,
    ownershipDocument: partner?.ownershipDocument,
    otherUsers: partner?.otherUsers,
    readOnlyUsers: partner?.readOnlyUsers,
  });

  const [constitutionFile, setConstitutionFile] = useState<any>(null);
  const [appointmentFile, setAppointmentFile] = useState<any>(null);
  const [ownershipFile, setOwnershipFile] = useState<any>(null);
  const [sindicationFile, setSindicationFile] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validEmails = () => {
    const partnerEmails = updatedPartner?.emails?.map(
      (email: any) => email?.text
    );
    const emails = [updatedPartner.email, ...(partnerEmails || [])];

    return emails.every((email) => email !== '' && isValidEmail(email));
  };

  const handleChangeConstitutionFile = (file: File) => {
    setConstitutionFile(file);
  };

  const handleChangeAppointmentFile = (file: File) => {
    setAppointmentFile(file);
  };

  const handleChangeOwnershipFile = (file: File) => {
    setOwnershipFile(file);
  };

  const handleChangeSindicationFile = (file: File) => {
    setSindicationFile(file);
  };

  const handleChangePartnerData = (data: any) => {
    setUpdatedPartner({ ...data });
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleUpdatePartner = async () => {
    try {
      setIsLoading(true);
      const partnerData = await getPartnerData({
        updatedPartner,
        constitutionFile,
        appointmentFile,
        ownershipFile,
        sindicationFile,
        partnerId: partner?._id,
        societyId,
        user,
      });

      await store.dispatch(
        updatePartner({
          id: partner?._id,
          data: {
            ...partnerData,
            userId: user?._id,
            societyId,
          },
        })
      );

      getActualSociety(user, societyId);
      successSwal(t('PartnerUpdatedSuccessfully'));
    } catch (error) {
      console.log(error); // eslint-disable-line
    } finally {
      setIsLoading(false);
      handleCloseModal();
    }
  };

  return (
    <>
      <EditPartnerModalHeader
        partner={updatedPartner as Partner}
        handleChangePage={handleChangePage}
        handleChangePartnerData={handleChangePartnerData}
        handleCloseModal={handleCloseModal}
      />

      <EditPartnerModalBody
        isNaturalPerson={updatedPartner.isNaturalPerson as boolean}
        originalPartner={partner}
        partner={updatedPartner as Partner}
        page={page}
        constitutionFile={constitutionFile}
        appointmentFile={appointmentFile}
        ownershipFile={ownershipFile}
        sindicationFile={sindicationFile}
        handleChangeConstitutionFile={handleChangeConstitutionFile}
        handleChangeAppointmentFile={handleChangeAppointmentFile}
        handleChangeOwnershipFile={handleChangeOwnershipFile}
        handleChangeSindicationFile={handleChangeSindicationFile}
        handleChangePage={handleChangePage}
        handleChangePartnerData={handleChangePartnerData}
      />

      <EditPartnerModalFooter
        validEmails={validEmails}
        handleUpdatePartner={handleUpdatePartner}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default EditPartnerModal;
