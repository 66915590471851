import { Society } from 'types';

type handleParticipantsProps = {
  sourceArray: any[];
  documentId: string;
  participantsArray: any;
  linkPrefix: string;
  linkField?: string | boolean;
  beneficiary: boolean;
};

type getLinkedProps = {
  documentId: string;
  participantsArray: any[];
  actualSociety: Society;
};

function handleParticipants({
  sourceArray,
  documentId,
  participantsArray,
  linkPrefix,
  linkField,
  beneficiary,
}: handleParticipantsProps) {
  const resultArray = { ...participantsArray };

  sourceArray.forEach((element) => {
    if (element.documents.includes(documentId)) {
      const participant = {
        ...element,
        name: element?.socialDenomination || element?.name,
        linkPrefix,
        linkField: beneficiary
          ? `${element?.plan}/${element?._id}`
          : linkField || element?._id,
      };

      resultArray[documentId] = resultArray[documentId]
        ? [...resultArray[documentId], participant]
        : [participant];
    }
  });

  return resultArray;
}

function getLinkedPartners({
  documentId,
  participantsArray,
  actualSociety,
}: getLinkedProps) {
  const societyPartners = actualSociety?.partners || [];
  const result = handleParticipants({
    sourceArray: societyPartners,
    documentId,
    participantsArray,
    linkPrefix: `detalle-socio/${actualSociety._id}`,
    linkField: false,
    beneficiary: false,
  });
  return result;
}

function getLinkedBeneficiaries({
  documentId,
  participantsArray,
  actualSociety,
}: getLinkedProps) {
  const societyBeneficiaries = actualSociety?.beneficiaries || [];
  const result = handleParticipants({
    sourceArray: societyBeneficiaries,
    documentId,
    participantsArray,
    linkPrefix: `detalle-beneficiario/${actualSociety._id}`,
    linkField: false,
    beneficiary: true,
  });
  return result;
}

function getLinkedInvestors({
  documentId,
  participantsArray,
  actualSociety,
}: getLinkedProps) {
  const societyInvestors = actualSociety?.investors || [];
  const result = handleParticipants({
    sourceArray: societyInvestors,
    documentId,
    participantsArray,
    linkPrefix: `detalle-inversor/${actualSociety._id}`,
    linkField: false,
    beneficiary: false,
  });
  return result;
}

function getLinkedDirectors({
  documentId,
  participantsArray,
  actualSociety,
}: getLinkedProps) {
  const societyDirectors = actualSociety?.directors || [];
  const result = handleParticipants({
    sourceArray: societyDirectors,
    documentId,
    participantsArray,
    linkPrefix: `miembros/${actualSociety._id}`,
    linkField: false,
    beneficiary: false,
  });
  return result;
}

function getLinkedRepresentatives({
  documentId,
  participantsArray,
  actualSociety,
}: getLinkedProps) {
  const societyRepresentatives = actualSociety?.representatives || [];
  const result = handleParticipants({
    sourceArray: societyRepresentatives,
    documentId,
    participantsArray,
    linkPrefix: `miembros/${actualSociety._id}`,
    linkField: false,
    beneficiary: false,
  });
  return result;
}

function getLinkedAuditors({
  documentId,
  participantsArray,
  actualSociety,
}: getLinkedProps) {
  const societyAuditors = actualSociety?.auditors || [];
  const result = handleParticipants({
    sourceArray: societyAuditors,
    documentId,
    participantsArray,
    linkPrefix: `miembros/${actualSociety._id}`,
    linkField: actualSociety._id,
    beneficiary: false,
  });
  return result;
}

export {
  getLinkedPartners,
  getLinkedBeneficiaries,
  getLinkedInvestors,
  getLinkedDirectors,
  getLinkedRepresentatives,
  getLinkedAuditors,
};
