import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { BoardParticipant } from 'types';

type Props = {
  voteValue: string;
  selectedRepresented: BoardParticipant[];
  handleExcecuteVote: () => void;
  handleCloseModal: () => void;
};

const SendVoteModalFooter: FC<Props> = ({
  voteValue,
  selectedRepresented,
  handleExcecuteVote,
  handleCloseModal,
}) => {
  const { t } = useTranslate();

  const buttonDisabled =
    voteValue === '' ||
    selectedRepresented?.length === 0 ||
    !selectedRepresented;

  return (
    <>
      <Modal.Footer>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 text-right">
              <button
                type="button"
                className="btn btn-lg btn-primary mr-2"
                disabled={buttonDisabled}
                onClick={handleExcecuteVote}
              >
                {t('Vote')}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-danger"
                onClick={handleCloseModal}
              >
                {t('Cancel')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};

export default SendVoteModalFooter;
