import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LogoSociety from 'modules/_shared/components/Logos/LogoSociety';
import { STTOK_DEMO_URL, STTOK_WEBSITE } from 'constants/defaultConstants';

import IconInfo from './IconInfo';
import IconSuccess from './IconSuccess';

import './DelegationMessageBox.scss';

const DelegationLink = ({ link, linkText }) => (
  <a className="nav-link" target="_blank" href={link} rel="noreferrer">
    {linkText}
  </a>
);

const DelegationMessageBox = ({ icon, title, description, link, linkText }) => {
  const { t } = useTranslate();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const hasWhiteBranding = useSelector(
    (state) => state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  return (
    <div
      id="delegation-message-box"
      className="nk-block nk-block-middle nk-auth-body wide-xs"
    >
      <div className="brand-logo pb-5 text-center">
        <LogoSociety society={actualSociety} />
      </div>

      <div className="card card-bordered shadow">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              {icon === 'SUCCESS' && <IconSuccess />}
              {icon === 'INFO' && <IconInfo />}

              <h4 className="nk-block-title text-center">{title}</h4>
              <div className="nk-block-des">
                <p>{description}</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                {t('NowYouCan')}:
              </label>
            </div>
          </div>

          <div className="form-group">
            <Link to={link} className="btn btn-lg btn-primary btn-block">
              {linkText}
            </Link>
          </div>

          {hasWhiteBranding ? (
            <div className="nk-block-des text-center mt-5">
              <p>{t('BoardManagedWithSttok')}</p>
            </div>
          ) : (
            <>
              <div className="nk-block-des text-center mt-5">
                <p>
                  <b>{t('DoYouWantToKnowMoreAboutSttok')}</b>
                </p>
              </div>
              <ul className="nav justify-center gx-4">
                <DelegationLink link={STTOK_WEBSITE} linkText="Sttok" />
                <DelegationLink
                  link={STTOK_DEMO_URL}
                  linkText={t('BookADemo')}
                />
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DelegationMessageBox;
