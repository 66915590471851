import { SignJWT } from 'jose';

import addHeaderClassesToHtml from './addHeaderClassesToHtml';

async function fetchCss(path: string) {
  const response = await fetch(path);
  return response.text();
}

async function getStyles() {
  const contentCss = await fetchCss('/styles/CKEditor/ck-content.css');
  const editorDefaultCss = await fetchCss('/styles/CKEditor/ckeditor5.css');
  const editorPremiumCss = await fetchCss(
    '/styles/CKEditor/ckeditor5-premium-features.css'
  );
  return `${contentCss}\n${editorDefaultCss}\n${editorPremiumCss}`;
}

export default async function getDocumentBlob(html: string): Promise<Blob> {
  const accessKey = process.env.REACT_APP_CKEDITOR_ACCESS_KEY;

  const payload = {
    aud: process.env.REACT_APP_CKEDITOR_ENVIRONMENT_ID,
    iat: Math.floor(Date.now() / 1000),
  };

  const token = await new SignJWT(payload)
    .setProtectedHeader({ alg: 'HS256' })
    .sign(new TextEncoder().encode(accessKey));

  const styles = await getStyles();

  const resultHtml = addHeaderClassesToHtml(html);

  const request = {
    html: resultHtml,
    css: styles,
    options: {
      format: 'A4',
      margin_top: '20mm',
      margin_bottom: '20mm',
      margin_right: '12mm',
      margin_left: '12mm',
      page_orientation: 'portrait',
    },
  };

  const res = await fetch('https://pdf-converter.cke-cs.com/v1/convert', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(request),
  });

  const blob = await res.blob();

  return blob;
}
