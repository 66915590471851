/* eslint-disable import/prefer-default-export */
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';

import EditModal from 'modules/profile/components/UserMyData/EditModal';

export function editUser({ data, societyId, userType }) {
  store.dispatch(
    setModal(
      <EditModal user={data} societyId={societyId} userType={userType} />
    )
  );
}
