/* eslint-disable no-restricted-syntax */
import convertibleNoteStatus from 'constants/convertibleNoteStatus';
import draftTypes from 'constants/draftTypes';
import { cleanValue } from 'constants/formats';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import finalProcesses from 'modules/drafts/pages/DraftConvertibleNote/finalProcesses';
import periods from 'modules/drafts/pages/DraftConvertibleNote/periods';
import { Draft, Investor, Society } from 'types';
import { getSocietyActualShares } from 'utils/filters';

const periodsByYear = {
  [periods.ANNUALLY]: 1,
  [periods.SEMI_ANNUALLY]: 6,
  [periods.QUARTERLY]: 4,
  [periods.MONTHLY]: 12,
  [periods.DAILY]: 365,
};

const getSocietyData = (society: Society, draft: Draft) => {
  const societyShares =
    draft?.status === convertibleNoteStatus.var.NC_ACTIVE
      ? draft?.societyShares
      : society?.sharesCount?.actual;
  const societyActualShares = getSocietyActualShares(society);

  return { societyShares, societyActualShares };
};

const getDraftData = (draft: Draft) => {
  const draftDueDate = draft.dueDate || '';
  const draftValuation = cleanValue(draft.valuation || 0);
  const draftInterestPeriod = draft.interestPeriod || '';
  const draftInterestPercent = cleanValue(draft.interestPercent || 0);
  const draftInterestFinalProcess = draft.interestFinalProcess || '';

  return {
    draftDueDate,
    draftValuation,
    draftInterestPeriod,
    draftInterestPercent,
    draftInterestFinalProcess,
  };
};

const getInvestorData = (investor: Investor) => {
  const investorContribution =
    investor?.realContribution || investor?.contribution || 0;
  const investorContributionDate = investor?.contributionDate || '';
  const investorDiscountPercent = cleanValue(investor?.discountPercent);

  return {
    investorContribution,
    investorContributionDate,
    investorDiscountPercent,
  };
};

const getInvestorUnitsFromDraft = (
  investor: Investor,
  draft: Draft | null,
  society: Society
) => {
  if (draft?.draftType !== draftTypes.DRAFT_CONVERTIBLE_NOTE) {
    return investor?.sharesCount?.drafts || 0;
  }

  const { societyShares, societyActualShares } = getSocietyData(society, draft);

  const {
    draftDueDate,
    draftValuation,
    draftInterestPeriod,
    draftInterestPercent,
    draftInterestFinalProcess,
  } = getDraftData(draft);

  const {
    investorContribution,
    investorContributionDate,
    investorDiscountPercent,
  } = getInvestorData(investor);

  const daysBetween =
    investorContribution && draftDueDate
      ? differenceInCalendarDays(
          parseISO(draftDueDate as string),
          parseISO(investorContributionDate as string)
        )
      : 0;

  const period = periodsByYear[draftInterestPeriod];

  const interestByPeriod =
    (investorContribution * (draftInterestPercent / 100)) / period;

  const interestTotal = +(interestByPeriod * daysBetween).toFixed(2);

  const totalInvestorContribution =
    draftInterestFinalProcess === finalProcesses.CONSOLIDATE
      ? investorContribution + interestTotal
      : investorContribution;

  const discountTotal = draftValuation * (investorDiscountPercent / 100);

  const investorUnits = investorContribution
    ? Math.floor(
        totalInvestorContribution /
          +(
            (draftValuation - discountTotal) /
            (societyShares || societyActualShares)
          )
      )
    : 0;

  return investorUnits;
};

export const getInvestorsTotalUnits = (
  investors: Investor[],
  drafts: Draft[],
  society: Society
): number => {
  let totalUnits = 0;

  for (const investor of investors) {
    const draftId = investor.draft;
    const currentDraft = drafts.find((d) => d._id === draftId) || null;
    const currentUnits = getInvestorUnitsFromDraft(
      investor,
      currentDraft,
      society
    );

    totalUnits += currentUnits;
  }

  return totalUnits;
};
