/* eslint-disable no-underscore-dangle */
import { SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import { numberFormat } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { renumerationAction } from 'modules/_shared/redux/modalsActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import cleanText from 'utils/cleanText';
import { getActualPartner, groupBy } from 'utils/filters';
import trackEvent from 'utils/trackEvent';
import AddNumerationEmbedded from '../AddNumerationEmbedded';
import '../Modals.scss';

const DECREMENT = 'DECREMENT';
const distribution = {
  AUTOMATIC: 'AUTOMATIC',
  BYPARTNER: 'BYPARTNER',
  BYSHARE: 'BYSHARE',
};

function RenumerationModal() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useSelector((state) => state);
  const { holdingClasses } = useSelector((state) => state);
  const { actualSociety } = useSelector((state) => state.society);

  const [step, setStep] = useState(0);

  const [missingParticipations, setMissingParticipations] = useState(0);

  const [actualFrom, setActualFrom] = useState();
  const [numerationRefs, setNumerationRefs] = useState();

  const [date, setDate] = useState('');
  const [lastOperationDate, setLastOperationDate] = useState();
  const [operationDate, setOperationDate] = useState();
  const [operationLimit, setOperationLimit] = useState();

  const [file, setFile] = useState();
  const [comments, setComments] = useState('');
  const [notaryName, setNotaryName] = useState('');
  const [notaryCity, setNotaryCity] = useState('');
  const [protocolNr, setProtocolNr] = useState('');

  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [partnerName, setPartnerName] = useState();

  const [distributionType, setDistributionType] = useState();

  const [updatedPartners, setUpdatedPartners] = useState([]);
  const [partnersList, setPartnersList] = useState();
  const [renumerations, setRenumerations] = useState([]);

  const [currentPartnerShares, setCurrentPartnerShares] = useState([]);
  const [partnersShares, setPartnersShares] = useState();
  const [editIndex, setEditIndex] = useState();
  const [errorMessage, setErrorMessage] = useState('');

  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: SELECCIONAR_SOCIO,
  });
  const animatedComponents = makeAnimated();

  const handleDrop = (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    if (files.length > 1) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('MustUploadOneDocument')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t(
          'WantUploadThisDocument'
        )} </h4>`,
        confirmButtonText: t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setFile(files[0]);
        }
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChooseDocument = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('WantUploadThisDocument')} </h4>`,
      confirmButtonText: t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setFile(file);
      }
    });
  };

  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedPartner(selectedOption.value);
  };
  const handleDate = (selectedDate) => {
    const objDate = new Date(selectedDate);
    if (objDate.getFullYear() < 1970) {
      if (operationDate) setOperationDate();
    } else if (objDate > new Date(lastOperationDate)) {
      setErrorMessage('');
      setDate(objDate);
      setOperationDate(format(objDate, 'yyyy-MM-dd'));
    } else {
      const nextDate = new Date(lastOperationDate);
      nextDate.setMinutes(nextDate.getMinutes() + 30);
      setDate(nextDate);
      setOperationDate(format(nextDate, 'yyyy-MM-dd'));
    }
  };

  const checkOverlappingShares = (shares) => {
    const compareFunction = (a, b) => +a.from - +b.from;
    const numerationGroups = shares.reduce((acc, share) => {
      const currentClass = share.shareClass;
      if (acc[numerationRefs[currentClass]])
        acc[numerationRefs[currentClass]] = [
          ...acc[numerationRefs[currentClass]],
          share,
        ];
      else acc[numerationRefs[currentClass]] = [share];
      return acc;
    }, {});

    const shareGroups = Object.values(numerationGroups);
    for (let i = 0; i < shareGroups.length; i += 1) {
      const orderedShares = shareGroups[i].sort(compareFunction);
      for (let j = 0; j < orderedShares.length - 1; j += 1) {
        if (+orderedShares[j].to >= +orderedShares[j + 1].from) return false;
      }
    }
    return true;
  };

  function getPartnersShares() {
    const movements = [];
    const transactions = [];
    const ranges = [];
    Object.entries(partnersShares).forEach(([partnerId, shares]) => {
      const partner = getActualPartner(actualSociety, partnerId);
      shares.forEach((share) => {
        movements.push({
          partner: partner['_id'],
          shareFrom: +share.shareFrom,
          shareTo: +share.shareTo,
          sharePremium: share.sharePremium,
          shareClass: share.shareClass['_id'],
          sharePrice: share.sharePrice,
          operation: 'THIS',
        });
        transactions.push({
          partner: partner['_id'],
          shareFrom: +share.shareFrom,
          shareTo: +share.shareTo,
          sharePremium: share.sharePremium,
          shareClass: share.shareClass['_id'],
          sharePrice: share.sharePrice,
          transactionType: transactionTypes.RENUMERATION,
        });
        ranges.push({
          from: +share.shareFrom,
          to: +share.shareTo,
          shareClass: share.shareClass['_id'],
        });
      });
    });
    return {
      movements,
      transactions,
      ranges,
    };
  }

  function joinPartnerShares(partners) {
    const joinedShares = [];
    const joinedTransactions = [];
    const currentFrom = actualFrom;
    partners.forEach((partner) => {
      const shares = actualSociety?.shares.filter(
        (share) => share.isActive && share.partner === partner.id
      );
      const numerationGroups = shares.reduce((acc, share) => {
        const currentClass = share.shareClass['_id'];
        if (acc[numerationRefs[currentClass]])
          acc[numerationRefs[currentClass]] = [
            ...acc[numerationRefs[currentClass]],
            share,
          ];
        else acc[numerationRefs[currentClass]] = [share];
        return acc;
      }, {});

      Object.entries(numerationGroups).forEach(([id, group]) => {
        const sharesGroup = group.map((share) => ({
          partner: share.partner,
          from: share.from,
          to: share.to,
          sharePremium: share.sharePremium,
          shareClass: share.shareClass['_id'],
          sharePrice: share.sharePrice,
        }));
        const sharesByClass = groupBy(sharesGroup, 'shareClass').flat();
        sharesByClass.forEach((share) => {
          const sharesCount = share.to - share.from + 1;
          joinedShares.push({
            partner: share.partner,
            shareFrom: currentFrom[numerationRefs[share.shareClass]],
            shareTo:
              currentFrom[numerationRefs[share.shareClass]] + sharesCount - 1,
            sharePremium: share.sharePremium,
            shareClass: share.shareClass,
            sharePrice: share.sharePrice,
            operation: 'THIS',
          });
          joinedTransactions.push({
            partner: share.partner,
            shareFrom: currentFrom[numerationRefs[share.shareClass]],
            shareTo:
              currentFrom[numerationRefs[share.shareClass]] + sharesCount - 1,
            sharePremium: share.sharePremium,
            shareClass: share.shareClass,
            sharePrice: share.sharePrice,
            transactionType: transactionTypes.RENUMERATION,
          });
          currentFrom[numerationRefs[share.shareClass]] += sharesCount;
        });
      });
    });
    return {
      movements: joinedShares,
      transactions: joinedTransactions,
    };
  }

  async function saveRenumeration() {
    let uploadedDocument = null;

    if (file) {
      const newDocument = {
        file,
        date,
        size: file.size,
        name: operationTypes.RENUMERATION_FILENAME,
        author: user['_id'],
        society: actualSociety['_id'],
        fileType: fileTypes[file.type],
        category: documentTypes.SCRIPTURES,
        description: t('DocumentLinkedToOperation'),
      };
      uploadedDocument = await store.dispatch(addDocument(newDocument));
    }
    let finalMovements = [];
    let finalTransactions = [];
    if (distributionType === distribution.BYSHARE) {
      const { movements, transactions, ranges } = getPartnersShares();
      if (!checkOverlappingShares(ranges)) {
        dispatch(addAlert(alertBodyTypes['INVALID_NUMERATIONS']));
        setDistributionType(distribution.BYPARTNER);
        return;
      }
      finalMovements = movements;
      finalTransactions = transactions;
    } else {
      const { movements, transactions } = joinPartnerShares(updatedPartners);
      finalMovements = movements;
      finalTransactions = transactions;
    }
    // set movements for rollback operation
    const rollbackMovements = actualSociety.shares
      .filter((share) => share.isActive)
      .map((share) => ({
        partner: share.partner,
        shareFrom: share.from,
        shareTo: share.to,
        shareClass: share?.shareClass,
        sharePremium: share?.sharePremium,
        sharePrice: share?.sharePrice,
        movementType: DECREMENT,
      }));

    dispatch(
      renumerationAction({
        movements: [...rollbackMovements, ...finalMovements],
        transactions: finalTransactions,
        nominalValue: actualSociety.nominalValue,
        date,
        society: actualSociety['_id'],
        user: user['_id'],
        documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
        comments: cleanText(comments),
        notarialRegistration: {
          notaryName,
          notaryCity,
          protocolNr,
        },
      })
    );
    dispatch(setModal(null));

    trackEvent(mixpanel, eventTypes.RENUMERATION, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?._id,
      societyName: actualSociety?.name,
      operation: eventTypes.RENUMERATION,
    });
  }

  function nextStep(event) {
    event.preventDefault();
    if (!step) {
      setStep(1);
    } else if (missingParticipations === 0) {
      saveRenumeration();
    }
  }

  function eliminatePartner(partnerId) {
    const partner = getActualPartner(actualSociety, partnerId);
    setPartnersList([...partnersList, partner]);

    setPartnerOptions([
      ...partnerOptions,
      {
        value: partner?.['_id'],
        label: `${partner?.cif} | ${partner?.name} | ${
          partner?.sharesCount?.actual
        } ${t('shares')}`,
      },
    ]);

    const filteredPartners = updatedPartners.filter(
      (partner) => partner.id !== partnerId
    );
    const currentPartners = [];
    filteredPartners?.forEach((partner) => {
      currentPartners.push({
        id: partner.id,
        cif: partner.cif,
        name: partner.name,
        totalShares: partner.totalShares,
      });
    });
    setSelectedPartner(SELECCIONAR_SOCIO);
    setSelectedPartnerOption({
      value: SELECCIONAR_SOCIO,
      label: SELECCIONAR_SOCIO,
    });
    setUpdatedPartners(currentPartners);
    setMissingParticipations(
      missingParticipations + partner.sharesCount.actual
    );
  }

  useEffect(() => {
    if (actualSociety) {
      const excludedOps = [
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredOperations = actualSociety.operations.filter(
        (operation) => !excludedOps.includes(operation.operationType)
      );
      const lastOperation = filteredOperations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );
      // Get last operation date from UTC to local timezone with new Date()
      const lastDate = format(
        new Date(lastOperation.date),
        "yyyy-MM-dd'T'HH:mm"
      );
      const dateLimit = format(new Date(lastOperation.date), 'yyyy-MM-dd');
      setOperationLimit(dateLimit);
      setLastOperationDate(lastDate || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  useEffect(() => {
    const activePartners = actualSociety?.partners.filter(
      (partner) => partner?.sharesCount?.actual > 0
    );
    const options = actualSociety?.partners
      .filter((partner) => partner?.sharesCount?.actual > 0)
      .map((partner) => ({
        value: partner?.['_id'],
        label: `${partner?.cif} | ${partner?.name} | ${
          partner?.sharesCount?.actual
        } ${t('shares')}`,
      }));
    if (distributionType === distribution.AUTOMATIC) {
      const currentPartners = [];
      activePartners.forEach((partner) => {
        currentPartners.push({
          id: partner['_id'],
          cif: partner.cif,
          name: partner.name,
          totalShares: partner.sharesCount.actual,
        });
      });

      setUpdatedPartners(currentPartners);
      setMissingParticipations(0);
    } else if (distributionType === distribution.BYPARTNER) {
      setPartnersList(activePartners);
      setPartnerOptions(options);
      setMissingParticipations(actualSociety.sharesCount.actual);
      setUpdatedPartners([]);
    } else {
      const partners = activePartners.reduce((acc, partner) => {
        const shares = actualSociety.shares.filter(
          (share) => share.isActive && share.partner === partner['_id']
        );
        acc[partner['_id']] = shares.map((share) => ({
          shareFrom: share.from,
          shareTo: share.to,
          shareClass: share.shareClass,
          sharePremium: share.sharePremium,
          sharePrice: share.sharePrice,
        }));
        return acc;
      }, {});
      setPartnersShares(partners);
      setPartnersList(activePartners);
      setPartnerOptions(options);
      setMissingParticipations(actualSociety.sharesCount.actual);
      setRenumerations([]);
    }
    setSelectedPartner(SELECCIONAR_SOCIO);
    setSelectedPartnerOption({
      value: SELECCIONAR_SOCIO,
      label: SELECCIONAR_SOCIO,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributionType]);

  useEffect(() => {
    if (
      selectedPartner !== SELECCIONAR_SOCIO &&
      distributionType === distribution.BYPARTNER
    ) {
      const id = selectedPartner;
      const partner = getActualPartner(actualSociety, id);
      const totalShares = partner.sharesCount.actual;
      setPartnerName(partner?.name);
      if (missingParticipations - totalShares >= 0) {
        setUpdatedPartners([
          ...updatedPartners,
          {
            id: partner['_id'],
            cif: partner.cif,
            name: partner.name,
            totalShares,
          },
        ]);

        setPartnersList(
          partnersList.filter((partner) => partner['_id'] !== id)
        );
        setPartnerOptions(
          partnerOptions.filter((option) => option.value !== id)
        );
        setMissingParticipations(missingParticipations - totalShares);
      }
    }
    if (
      selectedPartner !== SELECCIONAR_SOCIO &&
      distributionType === distribution.BYSHARE
    ) {
      const id = selectedPartner;
      const partner = getActualPartner(actualSociety, id);
      const totalShares = partner.sharesCount.actual;
      setPartnerName(partner?.name);
      setCurrentPartnerShares(partnersShares[id]);
      setPartnersList(partnersList.filter((partner) => partner['_id'] !== id));
      setPartnerOptions(partnerOptions.filter((option) => option.value !== id));
      setMissingParticipations(missingParticipations - totalShares);
    }
    if (selectedPartner === SELECCIONAR_SOCIO) setCurrentPartnerShares([]);
    setEditIndex(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  useEffect(() => {
    if (holdingClasses?.length) {
      const initial = holdingClasses?.reduce(
        (acc, currentClass) => {
          acc.from[currentClass['_id']] = 1;
          acc.refs[currentClass['_id']] =
            currentClass.numerationOption || currentClass['_id'];
          return acc;
        },
        {
          from: {},
          refs: {},
        }
      );
      const finalRefs = Object.entries(initial.refs).reduce(
        (acc, [id, ref]) => {
          acc[id] = acc[initial.refs[ref]] || initial.refs[ref];
          return acc;
        },
        {}
      );
      setActualFrom(initial.from);
      setNumerationRefs(finalRefs);
    }
  }, [holdingClasses]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('RenumerationOfParticipati')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={nextStep}>
          {!step ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('DateOfRenumeration')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control date-picker"
                    value={operationDate}
                    onChange={(event) => handleDate(event.target.value)}
                    required
                    max="2100-01-01"
                    min={operationLimit}
                  />
                </div>
                {errorMessage ? (
                  <span className="sub-text mt-1 text-danger">
                    {errorMessage}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  {t('AddWritingOptional')}
                </label>
                <div
                  className="nk-block-head-content"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div onDrop={handleDrop} onDragOver={handleDragOver}>
                    <div className="card card-bordered sp-plan">
                      <div
                        className="sp-plan-action card-inner"
                        style={{
                          height: '10px',
                          width: '460px',
                        }}
                      >
                        <div>
                          <span className="text-center w-300px d-block text-ellipsis">
                            {file?.name || t('DragDocumentToUpload')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleChooseDocument}
                    accept=".pdf"
                  />
                  <label
                    htmlFor="fileInput"
                    className="btn btn-light"
                    style={{ marginBottom: '0px' }}
                  >
                    {t('Choose')}
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="operation-comments">
                  {t('CommentsLabel')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-editor-custom">
                    <textarea
                      id="operation-comments"
                      maxLength="500"
                      className="form-control form-control-lg no-resize"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-note d-flex justify-content-between">
                  <>{t('CommentsVisibleOnlyAdmins')}</>
                  <CommentsCharCounter comments={comments} />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryName')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Mr. John Smith')}
                        value={notaryName}
                        onChange={(e) => setNotaryName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">
                      {t('NotaryProtocolNumber')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('P-8234290A')}
                        value={protocolNr}
                        onChange={(e) => setProtocolNr(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryCity')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Barcelona')}
                        value={notaryCity}
                        onChange={(e) => setNotaryCity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                {t('Following')}
              </button>
            </>
          ) : (
            <div>
              <label className="form-label">
                <span className="bg-warning p-2">
                  {t('TotalParticipationsPendingAssign')}:
                </span>
                <span className="badge-primary rounded px-2 py-1 ml-2">
                  <NumberFormat
                    displayType="text"
                    value={missingParticipations}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </span>
              </label>
              <hr />
              <div className="form-group">
                <label className="form-label" htmlFor="default-01">
                  {t('HowDoYouWantToAssignTheNe')}
                </label>
                <div className="row">
                  <div className="col-md-4">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="dist-auto"
                        id="dist-auto"
                        value={distribution.AUTOMATIC}
                        checked={distributionType === distribution.AUTOMATIC}
                        onChange={(event) =>
                          setDistributionType(event.target.value)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="dist-auto"
                      >
                        {t('AutomaticBySttok')}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="dist-semi"
                        id="dist-semi"
                        value={distribution.BYPARTNER}
                        checked={distributionType === distribution.BYPARTNER}
                        onChange={(event) =>
                          setDistributionType(event.target.value)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="dist-semi"
                      >
                        {t('ManualByPartners')}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="dist-manual"
                        id="dist-manual"
                        value={distribution.BYSHARE}
                        checked={distributionType === distribution.BYSHARE}
                        onChange={(event) =>
                          setDistributionType(event.target.value)
                        }
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="dist-manual"
                      >
                        {t('ManualBySections')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {distributionType === distribution.AUTOMATIC && (
                <div className="table-responsive">
                  <table className="table my-2">
                    <thead className="table-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t('Cif')}</th>
                        <th scope="col">{t('Name')}</th>
                        <th scope="col">{t('Shares')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedPartners?.map((partner, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{partner.cif}</td>
                          <td>{partner.name}</td>
                          <td className="text-primary text-right">
                            <NumberFormat
                              displayType="text"
                              value={partner?.totalShares}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormat}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {distributionType === distribution.BYPARTNER && (
                <>
                  <div className="form-group">
                    <label className="form-label" htmlFor="default-01">
                      {t('PartnerName')}
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        closeMenuOnSelect
                        className="react-select"
                        value={selectedPartnerOption}
                        options={partnerOptions}
                        components={animatedComponents}
                        onChange={handlePartnerOptionChange}
                      />
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table my-2">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{t('Cif')}</th>
                          <th scope="col">{t('Name')}</th>
                          <th scope="col">{t('Shares')}</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {updatedPartners?.map((partner, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{partner.cif}</td>
                            <td>{partner.name}</td>
                            <td className="text-primary text-right">
                              <NumberFormat
                                displayType="text"
                                value={partner?.totalShares}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...numberFormat}
                              />
                            </td>
                            <td onClick={() => eliminatePartner(partner.id)}>
                              <span className="btn btn-round btn-icon btn-dim btn-outline-primary btn-sm">
                                <em className="icon ni ni-cross" />
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              {distributionType === distribution.BYSHARE && (
                <>
                  <div className="form-group">
                    <label className="form-label" htmlFor="default-01">
                      {t('Shareholder')}
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        closeMenuOnSelect
                        className="react-select"
                        value={selectedPartnerOption}
                        options={partnerOptions}
                        components={animatedComponents}
                        onChange={handlePartnerOptionChange}
                      />
                    </div>
                  </div>
                  {currentPartnerShares.length ? (
                    <div className="form-group">
                      <label className="form-label" htmlFor="default-01">
                        {partnerName}
                      </label>
                      <span className="sub-text">
                        {t('ToAssignNewSectionsPressT')}&nbsp;
                        <span className="text-primary">{t('Renumber')}</span>
                      </span>
                      {currentPartnerShares.map((share, index) => (
                        <>
                          <div className="form-control-wrap pb-1">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text text-primary"
                                  id="basic-addon3"
                                >
                                  {t('From')}
                                </span>
                              </div>
                              <NumberFormat
                                id="outlined-normal"
                                className="form-control form-control-outlined"
                                value={share.shareFrom}
                                readOnly
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...numberFormat}
                              />
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text text-primary"
                                  id="basic-addon3"
                                >
                                  {t('To')}
                                </span>
                              </div>
                              <NumberFormat
                                id="outlined-normal"
                                className="form-control form-control-outlined"
                                value={share.shareTo}
                                readOnly
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...numberFormat}
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                {share?.shareClass?.name}
                              </span>
                              <button
                                className="btn btn-outline-primary btn-dim border-radius-0"
                                type="button"
                                onClick={() => setEditIndex(index)}
                              >
                                <span>{t('Renumber')}</span>
                              </button>
                            </div>
                          </div>
                          {index === editIndex ? (
                            <AddNumerationEmbedded
                              setEditIndex={setEditIndex}
                              setPartnersShares={setPartnersShares}
                              setCurrentPartnerShares={setCurrentPartnerShares}
                              setRenumerations={setRenumerations}
                              partnersShares={partnersShares}
                              currentPartnerShares={currentPartnerShares}
                              renumerations={renumerations}
                              partner={getActualPartner(
                                actualSociety,
                                selectedPartner
                              )}
                              total={share.shareTo - share.shareFrom + 1}
                              shareIndex={index}
                              key={selectedPartner}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                  {renumerations.length ? (
                    <div className="table-responsive">
                      <table className="table my-2">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t('Name')}</th>
                            <th scope="col">{t('Previous')}</th>
                            <th scope="col">{t('NewOne')}</th>
                            <th scope="col">{t('Class')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {renumerations?.map((renumeration, index) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{renumeration.partner}</td>
                              <td>
                                <NumberFormat
                                  displayType="text"
                                  value={renumeration.previous.from}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...numberFormat}
                                />
                                -
                                <NumberFormat
                                  displayType="text"
                                  value={renumeration.previous.to}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...numberFormat}
                                />
                              </td>
                              <td>
                                <NumberFormat
                                  displayType="text"
                                  value={renumeration.next.from}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...numberFormat}
                                />
                                -
                                <NumberFormat
                                  displayType="text"
                                  value={renumeration.next.to}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...numberFormat}
                                />
                              </td>
                              <td>{renumeration.className}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={missingParticipations !== 0}
              >
                {t('Save')}
              </button>
            </div>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

export default RenumerationModal;
