import { useTranslate } from 'hooks/useTranslate';
import { FC, useEffect, useState } from 'react';

import { Board } from 'types';

import NavItem from 'modules/_shared/components/NavItem';
import useGetBoardEnvelopes from '../hooks/useGetBoardEnvelopes';
import SignatureViewAct from './SignatureViewAct';
import SignatureViewAssistants from './SignatureViewAssistants';
import SignatureViewCertificate from './SignatureViewCertificate';

type Props = {
  board: Board;
};

const BoardDetailsSignatureView: FC<Props> = ({ board }) => {
  const { t } = useTranslate();

  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<JSX.Element[]>([]);

  const { boardEnvelopes } = useGetBoardEnvelopes(board);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  useEffect(() => {
    setPages([
      <SignatureViewAssistants
        key="signature-view-assistants"
        boardEnvelopes={boardEnvelopes}
      />,
      <SignatureViewAct
        key="signature-view-act"
        boardEnvelopes={boardEnvelopes}
      />,
      <SignatureViewCertificate
        key="signature-view-certificate"
        boardEnvelopes={boardEnvelopes}
      />,
    ]);
  }, [boardEnvelopes]);

  return (
    <>
      <div className="nk-block pt-4">
        <div className="card">
          <div className="card-aside-wrap">
            <div className="card-content">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <NavItem
                  currentPage={page}
                  navPage={0}
                  title={t('ListOfAttendees')}
                  icon="ni-user-list"
                  handleChangePage={handleChangePage}
                />
                <NavItem
                  currentPage={page}
                  navPage={1}
                  title={t('Act')}
                  icon="ni-file-text"
                  handleChangePage={handleChangePage}
                />
                <NavItem
                  currentPage={page}
                  navPage={2}
                  title={t('Certification')}
                  icon="ni-file-text-fill"
                  handleChangePage={handleChangePage}
                />
              </ul>
              {pages[page]}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardDetailsSignatureView;
