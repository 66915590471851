import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { setModal } from 'modules/_shared/redux/modalActions';

import successSwal from 'utils/successSwal';

import { useGetExtraSignerInitial } from './LetterTab/hooks/useGetExtraSignerInitialData';
import { useGetAttachmentDocument } from './LetterTab/hooks/useGetAttachmentDocument';

import LetterTabHeader from './LetterTab/components/LetterTabHeader';
import LetterTabBody from './LetterTab/components/LetterTabBody';
import LetterTabFooter from './LetterTab/components/LetterTabFooter';

import saveAttachment from './LetterTab/utils/saveAttachment';
import savePlanData from './LetterTab/utils/savePlanData';

import '../Modals.scss';
import isValidExtraSigner from './LetterTab/utils/isValidExtraSigner';

function PlanInvitation({ plan, society, invitationModel }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [htmlContent, setHtmlContent] = useState(invitationModel?.html || '');

  const extraSignerResult = useGetExtraSignerInitial({ htmlContent, plan });

  const { extraSignerName, extraSignerEmail, hasExtraSigner } =
    extraSignerResult.data;
  const { setExtraSignerName, setExtraSignerEmail } = extraSignerResult.actions;

  const attachmentResult = useGetAttachmentDocument({ plan, society });

  const { attachmentFile, attachmentFileName, isAttachmentSaved } =
    attachmentResult.data;
  const { setAttachmentFile, setIsAttachmentSaved } = attachmentResult.actions;

  const [isSaved, setIsSaved] = useState(true);

  const validExtraSigner = () => {
    if (!hasExtraSigner) return true;
    return isValidExtraSigner({
      extraSignerName,
      extraSignerEmail,
    });
  };

  const handleChangeAttachmentFile = (file) => {
    setAttachmentFile(file);
  };

  const handleChangeHtmlContent = (content) => {
    setHtmlContent(content);
    setIsSaved(false);
  };

  const handleChangeExtraSignerName = (event) => {
    setExtraSignerName(event.target.value);
    setIsSaved(false);
  };

  const handleChangeExtraSignerEmail = (event) => {
    setExtraSignerEmail(event.target.value);
    setIsSaved(false);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    try {
      let attachmentDocument;
      if (attachmentFile && !isAttachmentSaved) {
        attachmentDocument = await saveAttachment({
          file: attachmentFile,
          userId: user?.['_id'],
          societyId: actualSociety?.['_id'],
          description: t('DocumentGenerated'),
        });
        setIsAttachmentSaved(true);
      }

      await savePlanData({
        planId: plan?.['_id'],
        htmlContent,
        hasExtraSigner,
        extraSignerName,
        extraSignerEmail,
        attachmentDocument,
      });

      successSwal(t('LetterConditionsSuccessfullyUpdated'));
      setIsSaved(true);
    } catch (error) {
      setIsSaved(false);
    }
  };

  const handleClose = (event) => {
    event.preventDefault();
    dispatch(setModal(null));
  };

  return (
    <>
      <LetterTabHeader
        isSaved={isSaved}
        handleClose={handleClose}
        validExtraSigner={validExtraSigner}
        htmlContent={htmlContent}
      />

      <LetterTabBody
        planName={plan?.name || ''}
        extraSignerName={extraSignerName}
        extraSignerEmail={extraSignerEmail}
        hasExtraSigner={hasExtraSigner}
        htmlContent={htmlContent}
        attachmentFileName={attachmentFileName}
        attachmentFile={attachmentFile}
        handleChangeHtmlContent={handleChangeHtmlContent}
        handleChangeExtraSignerName={handleChangeExtraSignerName}
        handleChangeExtraSignerEmail={handleChangeExtraSignerEmail}
        handleChangeAttachmentFile={handleChangeAttachmentFile}
      />

      <LetterTabFooter
        isSaved={isSaved}
        isAttachmentSaved={isAttachmentSaved}
        validExtraSigner={validExtraSigner}
        handleSave={handleSave}
        handleClose={handleClose}
        htmlContent={htmlContent}
        attachmentFile={attachmentFile}
      />
    </>
  );
}

export default PlanInvitation;
