import { FC } from 'react';

import { Committee } from 'types';

import CommitteesTableHeader from './CommitteesTableHeader';
import CommitteesTableBody from './CommitteesTableBody';

type Props = {
  committees: Committee[];
};

const CommitteesTable: FC<Props> = ({ committees }) => (
  <div className="card card-bordered card-preview">
    <div className="table-responsive">
      <table className="nk-tb-list nk-tb-ulist">
        <CommitteesTableHeader />

        <CommitteesTableBody committees={committees} />
      </table>
    </div>
  </div>
);

export default CommitteesTable;
