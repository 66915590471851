import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { setModal } from 'modules/_shared/redux/modalActions';
import {
  getBeneficiaryData,
  updateBeneficiary,
} from 'modules/beneficiaries/redux/beneficiaryActions';
import { store } from 'redux/store';

import { getActualSociety } from 'utils/filters';
import isValidEmail from 'utils/isValidEmail';

import { Beneficiary } from 'types';

import successSwal from 'utils/successSwal';
import AddressDataView from './AddressDataView';
import OtherDataView from './OtherDataView';
import PersonalDataView from './PersonalDataView';

type Props = {
  beneficiary: Beneficiary;
  societyId: string;
  setBeneficiarySocietyData: any;
};

const EditBeneficiaryModal: FC<Props> = ({
  beneficiary,
  societyId,
  setBeneficiarySocietyData,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const hasTaxAddress = !!(
    beneficiary?.taxAddress?.line1 ||
    beneficiary?.taxAddress?.city ||
    beneficiary?.taxAddress?.zip ||
    beneficiary?.taxAddress?.country
  );

  const [updatedBeneficiary, setUpdatedBeneficiary] = useState<any>({
    name: beneficiary?.name,
    cif: beneficiary?.cif,
    email: beneficiary?.email,
    emails: beneficiary?.emails || [],
    nationality: beneficiary?.nationality,
    birthdate: beneficiary?.birthdate,
    jobTitle: beneficiary?.jobTitle,
    employeeNumber: beneficiary?.employeeNumber,
    phone: beneficiary?.phone,
    image: beneficiary?.image,
    address: beneficiary?.address,
    bankAccountNumber: beneficiary?.bankAccountNumber,
    taxAddress: hasTaxAddress
      ? beneficiary?.taxAddress
      : {
          line1: '',
          city: '',
          zip: '',
          country: '',
        },
  });
  const [page, setPage] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const validEmails = () => {
    const beneficiaryEmails = updatedBeneficiary?.emails?.map(
      (email: any) => email?.text
    );
    const emails = [updatedBeneficiary.email, ...(beneficiaryEmails || [])];
    return emails.every((email) => email !== '' && isValidEmail(email));
  };

  const handleUpdateBeneficiary = async () => {
    try {
      setIsLoading(true);

      await store.dispatch(
        updateBeneficiary({
          id: beneficiary['_id'],
          data: {
            ...updatedBeneficiary,
            userId: user['_id'],
            societyId,
            cascade: true,
          },
          showAlert: false,
        })
      );

      if (setBeneficiarySocietyData) {
        getBeneficiaryData(
          {
            beneficiaryId: beneficiary['_id'],
            societyId,
            userId: user?.['_id'],
            source: 'society',
          },
          setBeneficiarySocietyData
        );
      }
      getActualSociety(user, societyId);

      successSwal(t('BeneficiaryUpdatedSuccesfully'));

      dispatch(setModal(null));
    } catch (error) {
      console.error(error); // eslint-disable-line
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal.Header>
        <div className="row">
          <h5 className="title col-4">
            {t('UpdateData')}&nbsp;
            <span className="text-muted ff-alt fs-14px text-right">
              ({t('Beneficiary')})
            </span>
          </h5>
        </div>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '720px', overflowY: 'auto' }}>
        <ul className="nk-nav nav nav-tabs mt-xl">
          <li className="nav-item" onClick={() => setPage(0)}>
            <p className={`nav-link ${page === 0 && 'active'}`}>
              {t('PersonalInformation')}
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(1)}>
            <p className={`nav-link ${page === 1 && 'active'}`}>
              {t('Address')}
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(2)}>
            <p className={`nav-link ${page === 4 && 'active'}`}>
              {t('OtherInformation')}
            </p>
          </li>
        </ul>
        <div className="modal-body modal-body-lg">
          <div className="nk-modal">
            {page === 0 && (
              <PersonalDataView
                beneficiary={updatedBeneficiary}
                setUpdatedBeneficiary={setUpdatedBeneficiary}
              />
            )}
            {page === 1 && (
              <AddressDataView
                beneficiary={updatedBeneficiary}
                setUpdatedBeneficiary={setUpdatedBeneficiary}
              />
            )}
            {page === 2 && (
              <OtherDataView
                beneficiary={updatedBeneficiary}
                setUpdatedBeneficiary={setUpdatedBeneficiary}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={handleUpdateBeneficiary}
          disabled={!validEmails()}
        >
          {t('SaveChanges')}
        </button>
      </Modal.Footer>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default EditBeneficiaryModal;
