/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(society = initialState.society, action) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.society;

    case actionTypes.SET_SOCIETY:
      return {
        actualSociety: action.society.society,
        permissions: action.society.permissions,
        role: action.society.role,
      };

    case actionTypes.UPDATE_SOCIETY:
      return { ...society, actualSociety: action.society };

    case actionTypes.DELETE_SOCIETY:
      return initialState.society;

    default:
      return society;
  }
}
