import { ChangeEvent, FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { EMAIL_PATTERN } from 'constants/defaultConstants';

type PartnerRepresentantInputEmailProps = {
  email: string | undefined;
  onChangeEmail: (event: any) => void; // eslint-disable-line
};

const PartnerRepresentantInputEmail: FC<PartnerRepresentantInputEmailProps> = ({
  email,
  onChangeEmail,
}) => {
  const { t } = useTranslate();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeEmail(event.target.value);
  };

  return (
    <div className="form-group mt-4">
      <label className="form-label" htmlFor="partner-email">
        {t('Email')}
      </label>

      <div className="form-control-wrap">
        <div className="form-icon form-icon-left">
          <em className="icon ni ni-mail" />
        </div>

        <input
          type="email"
          name="partner-email"
          className="form-control"
          placeholder={t('EmailOfTheNewRepresentant')}
          value={email}
          onChange={handleEmailChange}
          pattern={EMAIL_PATTERN}
        />
      </div>
    </div>
  );
};

export default PartnerRepresentantInputEmail;
