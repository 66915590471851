import { useEffect, useState } from 'react';

type Props = {
  communicationId: string | undefined;
};

export default function useGetActivePage({ communicationId }: Props) {
  const [activePage, setActivePage] = useState<number>(0);

  const handleActiveChangePage = (page: number) => {
    setActivePage(page);
  };

  useEffect(() => {
    if (communicationId) {
      setActivePage(1);
    } else {
      setActivePage(0);
    }
  }, [communicationId]);

  return {
    data: {
      activePage,
    },
    isLoading: false,
    isError: false,
    actions: { handleActiveChangePage },
  };
}
