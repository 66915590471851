import { useTranslate } from 'hooks/useTranslate';
import { Society } from 'types';

type DelegationPartnerHeaderProps = {
  date: string;
  society: Society;
};

const DelegationPartnerHeader = ({
  date,
  society,
}: DelegationPartnerHeaderProps) => {
  const { t } = useTranslate();

  const societyName = society?.name || '';

  return (
    <div className="nk-block-head">
      <div className="nk-block-head-content">
        <h4 className="nk-block-title text-center">{t('VotingDelegation')}</h4>
        <div className="nk-block-des">
          <p>
            {t('DelegateVoteMessagePart1', { societyName })}
            <b>{date}</b>.&nbsp;
            {t('DelegateVoteMessagePart2')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DelegationPartnerHeader;
