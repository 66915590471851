export default {
  page: {
    paddingTop: 50,
    paddingHorizontal: 30,
    paddingBottom: 30,
  },
  a: {
    textDecoration: 'none',
  },
  p: {
    fontSize: 10,
    lineHeight: 1.5,
    marginTop: 5,
    marginBottom: 5,
  },
  h1: {
    fontSize: 32,
    marginTop: 10,
    marginBottom: 10,
  },
  h2: {
    fontSize: 21.5,
    marginTop: 8,
    marginBottom: 8,
  },
  h3: {
    fontSize: 18,
    marginTop: 6,
    marginBottom: 6,
  },
  h4: {
    fontSize: 13.5,
    marginTop: 5,
    marginBottom: 5,
  },
  h5: {
    fontSize: 11.5,
    marginTop: 5,
    marginBottom: 5,
  },
  h6: {
    fontSize: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  ul: {
    fontSize: 10,
    lineHeight: 1.5,
    marginTop: 5,
    marginBottom: 5,
  },
  ol: {
    fontSize: 10,
    lineHeight: 1.5,
    marginTop: 5,
    marginBottom: 5,
  },
  li: {
    fontSize: 10,
    lineHeight: 1.5,
    marginTop: 5,
    marginBottom: 5,
  },
  textSign: {
    color: '#274673',
    fontSize: 8,
    fontWeight: 300,
    textAlign: 'center',
    lineHeight: 1.2,
    marginTop: 30,
    marginBottom: 30,
    display: 'block',
  },
  strong: {
    fontWeight: 'bold',
  },
  calendar: {
    fontSize: 10,
  },
  calendarTitle: {
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  calendarHeader: {
    fontSize: 10,
    fontWeight: 700,
    paddingTop: 5,
    paddingBottom: 5,
  },
  calendarCell: {
    fontSize: 10,
    paddingTop: 3,
    paddingBottom: 3,
    textAlign: 'center',
  },
  '.wysiwyg-mention': {
    color: 'black',
    background: 'none',
  },
};
