import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

type Props = {
  partner: Partner;
  handleChangePage: any;
  handleChangePartnerData: any;
  handleCloseModal: any;
};

const EditPartnerModalHeader: FC<Props> = ({
  partner,
  handleChangePage,
  handleChangePartnerData,
  handleCloseModal,
}) => {
  const { t } = useTranslate();

  const [isNaturalPerson, setIsNaturalPerson] = useState<boolean>(
    partner?.isNaturalPerson
  );
  const handleChangePersonValue = () => {
    handleChangePage(0);
    setIsNaturalPerson(!isNaturalPerson);
    handleChangePartnerData({ ...partner, isNaturalPerson: !isNaturalPerson });
  };

  return (
    <>
      <Modal.Header>
        <div className="row">
          <h5 className="title col-4">
            {t('UpdateData')}&nbsp;
            <span className="text-muted ff-alt fs-14px text-right">
              ({t('Partner')})
            </span>
          </h5>
        </div>

        <div className="custom-control custom-radio mx-4">
          <input
            type="radio"
            className="custom-control-input"
            name="natural-person"
            id="natural-person"
            checked={isNaturalPerson}
            onChange={handleChangePersonValue}
          />
          <label className="custom-control-label" htmlFor="natural-person">
            {t('NaturalPerson')}
          </label>
        </div>

        <div className="custom-control custom-radio mx-4">
          <input
            type="radio"
            className="custom-control-input"
            name="legal-person"
            id="legal-person"
            checked={!isNaturalPerson}
            onChange={handleChangePersonValue}
          />
          <label className="custom-control-label" htmlFor="legal-person">
            {t('LegalPerson')}
          </label>
        </div>

        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
    </>
  );
};

export default EditPartnerModalHeader;
