/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';

import { store } from 'redux/store';
import { State } from 'redux/initialState';
import trackEvent from 'utils/trackEvent';

import eventTypes from 'constants/eventTypes';
import actionTypes from 'constants/actionTypes';

import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import UploadDocument from 'modules/documents/components/UploadDocument';
import { createPartnerRepresentant } from 'modules/partners/redux/partnerRepresentantsActions';

import 'modules/_shared/components/Modals/Modals.scss';
import PartnerRepresentantInputCif from './components/PartnerRepresentantInputCif';
import PartnerRepresentantInputName from './components/PartnerRepresentantInputName';
import PartnerRepresentantInputEmail from './components/PartnerRepresentantInputEmail';
import { sendNotificationEmail } from './utils/sendNotificationEmail';
import { uploadPartnerRepresentantDocument } from './utils/uploadPartnerRepresentantDocument';

const PartnerRepresentantModal = ({ action = actionTypes.ADD }) => {
  const mixpanel = useMixpanel();
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  const [email, setEmail] = useState<string>('');
  const [cif, setCif] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [file, setFile] = useState(null);
  const [options, setOptions] = useState([]);

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const handleSearch = (query: string) => {
    const availableOptions = [
      ...(actualSociety?.partners || []),
      ...(partnerRepresentants || []),
    ];
    const filteredOption = availableOptions.filter((partner) =>
      partner.name.toLowerCase().includes(query.toLowerCase())
    );
    setOptions(filteredOption as any);
    setName(query);
  };

  const handleOptionSelected = (selectedOption: any) => {
    setName(selectedOption?.name);
    setCif(selectedOption?.cif);
    setEmail(selectedOption.email);
  };

  const clearSelectedOption = () => {
    setCif('');
    setEmail('');
  };

  const savePartnerRepresentant = async (event: any) => {
    event.preventDefault();

    try {
      let uploadedDocument = null;

      if (file) {
        uploadedDocument = await uploadPartnerRepresentantDocument({
          t,
          file,
          user,
          actualSociety,
        });
      }

      const newPartnerRepresentant = {
        cif,
        name,
        email,
        phone: '',
        isPrivate: false,
        createdAt: new Date(),
        user: user._id,
        societyId: actualSociety._id,
        documents: uploadedDocument ? [uploadedDocument._id] : [],
      };

      if (action === actionTypes.EDIT) {
        // dispatch(
        //   updatePartnerCategory(
        //     currentPartnerCategory['_id'],
        //     newPartnerRepresentant
        //   )
        // );
      } else {
        const uploadedRepresentant = await store.dispatch(
          createPartnerRepresentant(newPartnerRepresentant, false)
        );
        sendNotificationEmail({
          t,
          email,
          language: i18n.language,
          actualSociety,
          representantId: uploadedRepresentant._id,
        });

        trackEvent(mixpanel, eventTypes.ADD_PARTNER_REPRESENTANT, {
          userId: user?._id,
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?._id,
          societyName: actualSociety?.name,
          operation: eventTypes.ADD_PARTNER_REPRESENTANT,
        });
      }

      dispatch(setModal(null));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_PARTNER_REPRESENTANT_CREATED));
    }
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('AddPartnerRepresentantFull')}</h5>
        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form
          className="form-validate is-alter"
          onSubmit={savePartnerRepresentant}
        >
          <PartnerRepresentantInputName
            name={name}
            options={options}
            onSearch={handleSearch}
            onClearOption={clearSelectedOption}
            onSelectOption={handleOptionSelected}
          />

          <PartnerRepresentantInputCif cif={cif} onChangeCif={setCif} />

          <PartnerRepresentantInputEmail
            email={email}
            onChangeEmail={setEmail}
          />

          <div className="form-group mt-4">
            <UploadDocument
              file={file as any}
              setFile={setFile}
              label="AddWritingOptional"
            />
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name || !email}
          >
            {t('AddPartnerRepresentant')}
          </button>
        </form>
      </Modal.Body>

      <Modal.Footer className="bg-light">
        <span className="sub-text">{t('ThePartnerWillReceiveANot')}</span>
      </Modal.Footer>
    </>
  );
};

export default PartnerRepresentantModal;
