import i18n from 'i18n/config';

export default {
  EXISTING_PARTNERS: i18n.t('ItCannotBeConstitutedWhet'),
  DATA_NOT_FOUND: i18n.t('NoPartnersHaveBeenFound'),
  DATA_WRONG_MODEL: i18n.t('TheColumnsDoNotCorrespond'),
  DUPLICATED_FIELDS: i18n.t('TheTemplateContainsDiffer'),
  SOCIETY_DUPLICATED_PARTNERS: i18n.t('ThereArePartnersWithDuplicatedInfo'),
  TEMPLATE_DUPLICATED_PARTNERS: i18n.t('ThereArePartnersWithDuplicatedCif'),
  DATA_INCOMPLETE: i18n.t('TheNamesSharesAndCifDat'),
  INVALID_EMAILS: i18n.t('ThereArePartnersWithInvalidEmails'),
};
