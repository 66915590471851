import { ReactNode } from 'react';

type Props = {
  title: string;
  subTitle?: string;
  actions?: ReactNode[];
};

const TabHeader = (props: Props) => {
  const { title, subTitle, actions } = props;

  return (
    <>
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">{title}</h5>
          <div className="nk-block-des">
            <p>{subTitle}</p>
          </div>
        </div>
      </div>

      {actions && (
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            {actions?.map((action) => action)}
          </div>
        </div>
      )}
    </>
  );
};

export default TabHeader;
