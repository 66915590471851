import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';

import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export const createPartnerRepresentant =
  (partnerRepresentant, showAlert = true) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/partnerRepresentants`,
        partnerRepresentant
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_PARTNER_REPRESENTANT,
        partnerRepresentant: decodedData,
      });
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.PARTNER_REPRESENTANT_CREATED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const getPartnerRepresentants = (params) => async (dispatch) => {
  try {
    const query = params ? { params } : { params: {} };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_V2}/partnerRepresentants`,
      query
    );

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_PARTNER_REPRESENTANTS,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updatePartnerRepresentant =
  (partnerRepresentantId, updatePartnerRepresentantData) =>
  async (dispatch) => {
    const body = {
      _id: partnerRepresentantId,
      ...updatePartnerRepresentantData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL_V2}/partnerRepresentants`,
        body
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_PARTNER_REPRESENTANT,
        partnerRepresentant: decodedData,
      });
      dispatch(addAlert(alertBodyTypes.PARTNER_REPRESENTANT_UPDATED));
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deletePartnerRepresentant =
  (partnerRepresentantId) => async (dispatch) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL_V2}/partnerRepresentants/${partnerRepresentantId}`
      );

      dispatch({
        type: types.DELETE_PARTNER_REPRESENTANT,
        partnerRepresentantId,
      });
      dispatch(addAlert(alertBodyTypes.PARTNER_REPRESENTANT_DELETED));
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
