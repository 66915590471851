import { Encumbrance, Range } from 'types';

import addRangeToRanges from './addRangeToRanges';

export default function getValidRanges(
  encumbrance: Encumbrance,
  validRanges: Range[]
) {
  let ranges: Range[] = [];
  if (validRanges?.length > 0) {
    ranges = addRangeToRanges(encumbrance?.from, encumbrance?.to, validRanges);
  } else {
    ranges = [{ from: encumbrance?.from, to: encumbrance?.to }];
  }
  return ranges;
}
