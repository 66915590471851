/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import {
  cleanValue,
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';
import { setModal } from 'modules/_shared/redux/modalActions';
import { dividendsDistributionAction } from 'modules/_shared/redux/modalsActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';
import Swal from 'sweetalert2';
import cleanText from 'utils/cleanText';
import { getPartnerNDPercent } from 'utils/filters';
import trackEvent from 'utils/trackEvent';
import '../Modals.scss';

function DividendsDistributionModal() {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const { user, actualSociety } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
  }));

  const [step, setStep] = useState(0);
  const [file, setFile] = useState();
  const [comments, setComments] = useState('');
  const [notaryName, setNotaryName] = useState('');
  const [notaryCity, setNotaryCity] = useState('');
  const [protocolNr, setProtocolNr] = useState('');

  const [totalDividendsAmount, setTotalDividendsAmount] = useState('');
  const [dividends, setDividends] = useState([]);

  const [date, setDate] = useState('');
  const [lastOperationDate, setLastOperationDate] = useState();

  const [operationDate, setOperationDate] = useState();
  const [operationLimit, setOperationLimit] = useState();

  const [hasRetention, setHasRetention] = useState(false);
  const [retentionPercent, setRetentionPercent] = useState(0);
  const [retentionByPartner, setRetentionByPartner] = useState({});
  const [totalAmountByPartner, setTotalAmountByPartner] = useState({});

  const [errorMessage, setErrorMessage] = useState('');

  const handleDrop = (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    if (files.length > 1) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('MustUploadOneDocument')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t(
          'WantUploadThisDocument'
        )} </h4>`,
        confirmButtonText: t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setFile(files[0]);
        }
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChooseDocument = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('WantUploadThisDocument')} </h4>`,
      confirmButtonText: t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setFile(file);
      }
    });
  };

  const handleDate = (selectedDate) => {
    const objDate = new Date(selectedDate);
    if (objDate.getFullYear() < 1970) {
      if (operationDate) setOperationDate();
    } else if (objDate > new Date(lastOperationDate)) {
      setErrorMessage('');
      setDate(objDate);
      setOperationDate(format(objDate, 'yyyy-MM-dd'));
    } else {
      const nextDate = new Date(lastOperationDate);
      nextDate.setMinutes(nextDate.getMinutes() + 30);
      setDate(nextDate);
      setOperationDate(format(nextDate, 'yyyy-MM-dd'));
    }
  };

  const getTotalToPerceive = (amount, hasRetention, retentionPercent) => {
    const retention = hasRetention ? (retentionPercent * amount) / 100 : 0;
    return amount - retention;
  };

  const saveDividends = async () => {
    let uploadedDocument = null;

    if (file) {
      const newDocument = {
        file,
        date,
        size: file.size,
        name: operationTypes.DIVIDENDS_DISTRIBUTION_FILENAME,
        author: user['_id'],
        society: actualSociety['_id'],
        fileType: fileTypes[file.type],
        category: documentTypes.SCRIPTURES,
        description: t('DocumentLinkedToOperation'),
      };
      uploadedDocument = await store.dispatch(addDocument(newDocument));
    }

    const finalDividends = dividends.map((data) => {
      const { name, ...finalDividend } = data;

      finalDividend.amount = totalAmountByPartner[data.partner];
      finalDividend.retention = retentionByPartner[data.partner];

      return finalDividend;
    });

    dispatch(
      dividendsDistributionAction({
        movements: [],
        transactions: [],
        dividends: finalDividends,
        date,
        nominalValue: actualSociety.nominalValue,
        society: actualSociety['_id'],
        user: user['_id'],
        documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
        comments: cleanText(comments),
        notarialRegistration: {
          notaryName,
          notaryCity,
          protocolNr,
        },
      })
    );

    dispatch(setModal(null));

    trackEvent(mixpanel, eventTypes.DIVIDENDS_DISTRIBUTION, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?._id,
      societyName: actualSociety?.name,
      operation: eventTypes.DIVIDENDS_DISTRIBUTION,
    });
  };

  const nextStep = (event) => {
    event.preventDefault();

    if (!step) {
      setStep(1);
    } else saveDividends();
  };

  const handleChangeRetentionByPartner = (data, value) => {
    const partnerId = data.partner;
    const newValue = cleanValue(value);

    const newRetentionByPartner = { ...retentionByPartner };
    const newTotalAmountByPartner = { ...totalAmountByPartner };

    newRetentionByPartner[partnerId] = newValue;
    newTotalAmountByPartner[partnerId] = getTotalToPerceive(
      data.grossAmount,
      hasRetention,
      newRetentionByPartner[partnerId]
    );

    setRetentionByPartner(newRetentionByPartner);
    setTotalAmountByPartner(newTotalAmountByPartner);
  };

  useEffect(() => {
    if (actualSociety) {
      const excludedOps = [
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredOperations = actualSociety.operations.filter(
        (operation) => !excludedOps.includes(operation.operationType)
      );
      const lastOperation = filteredOperations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );
      // Get last operation date from UTC to local timezone with new Date()
      const lastDate = format(
        new Date(lastOperation.date),
        "yyyy-MM-dd'T'HH:mm"
      );
      const dateLimit = format(new Date(lastOperation.date), 'yyyy-MM-dd');
      setOperationLimit(dateLimit);
      setLastOperationDate(lastDate || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  useEffect(() => {
    if (step === 1 && totalDividendsAmount && actualSociety?.partners?.length) {
      const initialRetentionByPartner = {};
      const initialTotalAmountByPartner = {};

      const partnersDividends = actualSociety?.partners
        ?.filter((partner) => partner?.sharesCount?.actual > 0)
        .map((partner) => {
          const NDPercent = getPartnerNDPercent(partner, actualSociety);
          const initialAmount = (NDPercent * totalDividendsAmount) / 100;

          initialRetentionByPartner[partner['_id']] = retentionPercent;
          initialTotalAmountByPartner[partner['_id']] = getTotalToPerceive(
            initialAmount,
            hasRetention,
            retentionPercent
          );

          return {
            partner: partner['_id'],
            name: partner.name || '',
            percent: NDPercent,
            retention: retentionPercent,
            grossAmount: initialAmount,
            amount: initialTotalAmountByPartner[partner['_id']],
          };
        });

      setDividends(partnersDividends);
      setRetentionByPartner(initialRetentionByPartner);
      setTotalAmountByPartner(initialTotalAmountByPartner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, step]);

  useEffect(() => {
    if (!hasRetention) setRetentionPercent(0);
  }, [hasRetention]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('DividendsDistribution')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={nextStep}>
          {!step ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('DividendsApprobationDate')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control"
                    value={operationDate}
                    onChange={(event) => handleDate(event.target.value)}
                    required
                    max="2100-01-01"
                    min={operationLimit}
                  />
                </div>
                {errorMessage && (
                  <span className="sub-text mt-1 text-danger">
                    {errorMessage}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('DividendsAmount')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">EUR</span>
                  </div>
                  <NumberFormat
                    id="outlined-normal"
                    className="form-control form-control-outlined"
                    placeholder={t('Amount')}
                    value={totalDividendsAmount}
                    onChange={(e) =>
                      setTotalDividendsAmount(cleanValue(e.target.value) || '')
                    }
                    isAllowed={(inputObj) => {
                      const { floatValue, formattedValue } = inputObj;
                      if (formattedValue === '') return true;
                      if (floatValue >= 1 && Number.isInteger(floatValue))
                        return true;
                      return false;
                    }}
                    required
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </div>
              </div>

              <div className="form-group">
                <div className="custom-control custom-control-xs custom-checkbox">
                  <input
                    type="checkbox"
                    id="checkbox-notifications"
                    className="custom-control-input"
                    value={!hasRetention}
                    checked={hasRetention}
                    onChange={() => setHasRetention(!hasRetention)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="checkbox-notifications"
                  >
                    {t('DoYouWantToApplyRetention')}
                  </label>
                </div>
              </div>

              {hasRetention && (
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">
                    {t('RetentionPercent')}
                  </label>
                  <span className="sub-text mb-3">
                    {t('SetPercentAndUpdateLater')}
                  </span>
                  <div className="form-control-wrap">
                    <div className="form-text-hint">
                      <span className="overline-title">%</span>
                    </div>
                    <NumberFormat
                      id="outlined-normal"
                      className="form-control form-control-outlined"
                      placeholder={t('Percent')}
                      value={retentionPercent}
                      onChange={(e) =>
                        setRetentionPercent(cleanValue(e.target.value) || '')
                      }
                      isAllowed={(inputObj) => {
                        const { floatValue, formattedValue } = inputObj;
                        if (formattedValue === '') return true;
                        if (floatValue >= 1 && Number.isInteger(floatValue))
                          return true;
                        return false;
                      }}
                      {...numberFormat}
                    />
                  </div>
                </div>
              )}

              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  {t('DividendsAddBoardAct')} ({t('optional')})
                </label>
                <div
                  className="nk-block-head-content"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div onDrop={handleDrop} onDragOver={handleDragOver}>
                    <div className="card card-bordered sp-plan">
                      <div
                        className="sp-plan-action card-inner"
                        style={{
                          height: '10px',
                          width: '460px',
                        }}
                      >
                        <div>
                          <span className="text-center w-300px d-block text-ellipsis">
                            {file?.name || t('DragDocumentToUpload')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleChooseDocument}
                    accept=".pdf"
                  />
                  <label
                    htmlFor="fileInput"
                    className="btn btn-light"
                    style={{ marginBottom: '0px' }}
                  >
                    {t('Choose')}
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="operation-comments">
                  {t('CommentsLabel')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-editor-custom">
                    <textarea
                      id="operation-comments"
                      maxLength="500"
                      className="form-control form-control-lg no-resize"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-note d-flex justify-content-between">
                  <>{t('CommentsVisibleOnlyAdmins')}</>
                  <CommentsCharCounter comments={comments} />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryName')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Mr. John Smith')}
                        value={notaryName}
                        onChange={(e) => setNotaryName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">
                      {t('NotaryProtocolNumber')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('P-8234290A')}
                        value={protocolNr}
                        onChange={(e) => setProtocolNr(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryCity')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Barcelona')}
                        value={notaryCity}
                        onChange={(e) => setNotaryCity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                {t('Following')}
              </button>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <span className="">{t('DividendsAmount')}:</span>
                <span className="badge-primary rounded px-4 py-1">
                  <NumberFormat
                    displayType="text"
                    value={totalDividendsAmount}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...currencyFormatDecimals}
                  />
                </span>
              </div>

              <div className="card card-bordered card-preview mb-4">
                <table
                  className="table table-tranx"
                  id="dividends-distribution-table"
                >
                  <thead>
                    <tr className="tb-tnx-head">
                      {/* <th className="tb-tnx-id">
                        <span className="">Nº</span>
                      </th> */}
                      <th className="text-left">
                        <span>{t('Name')}</span>
                      </th>

                      <th className="text-left">
                        <span>{t('%ND')}</span>
                      </th>

                      {hasRetention && (
                        <>
                          <th className="text-left">
                            <span>{t('%Retention')}</span>
                          </th>
                          <th className="text-left">
                            <span>{t('GrossAmount')}</span>
                          </th>
                        </>
                      )}

                      <th className="text-left">
                        <span>{t('ToPerceive')}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dividends.length > 0 &&
                      dividends.map((data, index) => (
                        <tr className="tb-tnx-item" key={data.partner}>
                          {/* <td className="tb-tnx-id">
                            <span className="text-primary">{index + 1}</span>
                          </td> */}

                          <td className="tb-tnx-info">
                            <div className="tb-tnx-info w-150px">
                              <span className="title">{data.name}</span>
                            </div>
                          </td>

                          <td className="tb-tnx-amount">
                            <div className="tb-tnx-total text-right w-100">
                              <span className="amount">
                                <NumberFormat
                                  displayType="text"
                                  value={data.percent}
                                  {...numberFormatDecimals}
                                />
                                %
                              </span>
                            </div>
                          </td>

                          {hasRetention && (
                            <>
                              <td className="tb-tnx-amount">
                                <div className="tb-tnx-total text-right w-100">
                                  <span className="amount">
                                    <NumberFormat
                                      value={retentionByPartner[data.partner]}
                                      className="form-control"
                                      onChange={(e) => {
                                        handleChangeRetentionByPartner(
                                          data,
                                          e.target.value
                                        );
                                      }}
                                      {...numberFormatDecimals}
                                      min={0}
                                      max={100}
                                      style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        padding: 0,
                                        fontWeight: '500',
                                        color: '#526484',
                                        fontSize: '14px',
                                        height: '22px',
                                        textAlign: 'right',
                                      }}
                                    />
                                  </span>
                                </div>
                              </td>

                              <td className="tb-tnx-amount">
                                <div className="tb-tnx-total text-right w-100">
                                  <span className="amount">
                                    <NumberFormat
                                      displayType="text"
                                      value={data.grossAmount}
                                      {...currencyFormatDecimals}
                                    />
                                  </span>
                                </div>
                              </td>
                            </>
                          )}

                          <td className="tb-tnx-amount">
                            <div className="tb-tnx-total text-right w-100">
                              <span className="amount">
                                <NumberFormat
                                  displayType="text"
                                  value={totalAmountByPartner[data.partner]}
                                  {...currencyFormatDecimals}
                                />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!step ? !totalDividendsAmount : !dividends.length}
              >
                {!step ? t('Following') : t('Save')}
              </button>
            </>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

export default DividendsDistributionModal;
