import React from 'react';
import { store } from 'redux/store';
import AddBeneficiary from 'modules/beneficiaries/modals/AddBeneficiary';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getBeneficiaryFromBeneficiaryId } from './filters';

export default function duplicateBeneficiary(params) {
  const beneficiaryId = params?.certificateData?.beneficiaryData?.['_id'];
  const { society } = store.getState();
  const currentBeneficiary = getBeneficiaryFromBeneficiaryId(
    society?.actualSociety,
    beneficiaryId
  );
  store.dispatch(
    setModal(
      <AddBeneficiary
        action="duplicate"
        currentBeneficiary={currentBeneficiary}
      />
    )
  );
}
