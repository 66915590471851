import UploadDocument from 'modules/documents/components/UploadDocument';
import { useTranslation } from 'react-i18next';
import LetterTabSignerDataView from './LetterTabSignerDataView';
import LetterTabInfoView from './LetterTabInfoView';

type Props = {
  planName: string;
  extraSignerName: string;
  extraSignerEmail: string;
  hasExtraSigner: boolean;
  attachmentFileName: string;
  attachmentFile: File | undefined;
  // eslint-disable-next-line no-unused-vars
  handleChangeExtraSignerName: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeExtraSignerEmail: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeAttachmentFile: (file: File) => void;
};

const LetterTabConditionsView = ({
  planName,
  extraSignerName,
  extraSignerEmail,
  hasExtraSigner,
  attachmentFileName,
  attachmentFile,
  handleChangeExtraSignerName,
  handleChangeExtraSignerEmail,
  handleChangeAttachmentFile,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="card">
        <div className="card-aside-wrap">
          <div className="card-content">
            <div className="row mx-1 my-3">
              <div className="col-6 pl-0 mb-3">
                <LetterTabInfoView planName={planName} />
              </div>

              <div className="col-6 pr-0 mb-3 border-left is-disabled">
                <LetterTabSignerDataView
                  extraSignerName={extraSignerName}
                  extraSignerEmail={extraSignerEmail}
                  hasExtraSigner={hasExtraSigner}
                  handleChangeExtraSignerName={handleChangeExtraSignerName}
                  handleChangeExtraSignerEmail={handleChangeExtraSignerEmail}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-1">
        <UploadDocument
          label=""
          inputLabel={
            attachmentFileName || attachmentFile?.name || t('AttachDocument') // TODO : inputLabel variable
          }
          file={attachmentFile}
          setFile={handleChangeAttachmentFile}
        />
      </div>
    </>
  );
};

export default LetterTabConditionsView;
