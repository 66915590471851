import editPartnerCategory from 'utils/editPartnerCategory';

import i18n from 'i18n/config';

export default [
  {
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    action: editPartnerCategory,
    dontShowCurrent: (params: any) => params?.isReadOnly,
  },
];
