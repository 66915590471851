import { translate } from 'hooks/useTranslate';

export default [
  {
    value: 'FROM_SENDING_TO_CLOSING',
    text: translate('FROM_SENDING_TO_CLOSING'),
  },
  {
    value: 'FROM_BOARD_DATE_TO_CLOSING',
    text: translate('FROM_BOARD_DATE_TO_CLOSING'),
  },
  {
    value: 'FROM_OPENING_VOTES_TO_CLOSING',
    text: translate('FROM_OPENING_VOTES_TO_CLOSING'),
  },
];
