import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import getColumnClass from '../utils/getColumnClass';

type Props = {
  previousColumnsLength: number;
};

const DrawInversionHeaders: FC<Props> = ({ previousColumnsLength }) => {
  const { t } = useTranslate();

  const inversionColumnsHeaders = [
    {
      text: t('AccumulatedNominalValue'),
      columns: 1,
    },
    {
      text: t('AccumulatedPremium'),
      columns: 1,
    },
    {
      text: t('AccumulatedTotal'),
      columns: 1,
    },
  ];

  return (
    <>
      {inversionColumnsHeaders?.map((column, index) => (
        <th
          key={`${column.text}`}
          className={getColumnClass(index, previousColumnsLength, true)}
          colSpan={column.columns}
        >
          {column.text}
        </th>
      ))}
    </>
  );
};

export default DrawInversionHeaders;
