import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { Committee as OriginalCommittee } from 'types';

import axios from '../../../interceptors/axios';

type Committee = Omit<OriginalCommittee, '_id'>;

export const getCommittee = (committeeId: string) => async (dispatch: any) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_V2}/committees/${committeeId}`
    );
    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const getCommittees = (societyId: string) => async (dispatch: any) => {
  const query = { params: { societyId } };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_V2}/committees`,
      query
    );
    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_COMMITTEES,
      committees: decodedData,
    });

    return decodedData;
  } catch (error) {
    dispatchError(error, dispatch);
  }
};

export const createCommittee =
  (committeeData: Committee) => async (dispatch: any) => {
    try {
      const body = { ...committeeData };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/committees`,
        body
      );
      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.CREATE_COMMITTEE,
        committee: decodedData,
      });
      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
