/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import ConstitutionAiModalPartnersRow from './ConstitutionAiModalPartnersRow';
import ConstitutionAiModalRepresentativeRow from './ConstitutionAiModalRepresentativeRow';
import ConstitutionAiModalPartnersSummaryRow from './ConstitutionAiModalPartnersSummaryRow';

const ConstitutionAiModalPartnersTable = ({
  partnersData,
  holdingClasses,
  changePartnerParam,
}) => {
  const { t } = useTranslate();

  const [partnerExpandViews, setPartnerExpandViews] = useState([]);

  const changePartnerExpandView = (index, value) => {
    const partnerViews = Array.from({ partnerExpandViews }, () => false);
    partnerViews[index] = value;

    setPartnerExpandViews(partnerViews);
  };

  useEffect(() => {
    if (partnersData) {
      const totalPartners = partnersData?.length ?? 0;
      const partnerViews = Array.from({ totalPartners }, () => false);

      setPartnerExpandViews(partnerViews);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section
      key="partners-card"
      className="constitution-ai-section card card-bordered card-preview mb-2"
    >
      <table className="table table-tranx" id="partners-table">
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={3}>
              <span>{t('Partners')}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            className="tb-tnx-item row-with-input"
            key="partners-card"
            colSpan={3}
          >
            {partnersData.map((partner, index) =>
              partnerExpandViews[index] ? (
                <>
                  {Object.entries(partner).map(([key, value], fieldIndex) =>
                    key === 'Representative' ? (
                      <ConstitutionAiModalRepresentativeRow
                        index={index}
                        partner={partner}
                        partnerKey={key}
                        onChangePartnerParam={changePartnerParam}
                      />
                    ) : (
                      <ConstitutionAiModalPartnersRow
                        index={index}
                        fieldIndex={fieldIndex}
                        value={value}
                        partnerKey={key}
                        holdingClasses={holdingClasses}
                        onChangePartnerParam={changePartnerParam}
                        onChangePartnerExpandView={changePartnerExpandView}
                      />
                    )
                  )}
                </>
              ) : (
                <ConstitutionAiModalPartnersSummaryRow
                  index={index}
                  partner={partner}
                  onChangePartnerExpandView={changePartnerExpandView}
                />
              )
            )}
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default ConstitutionAiModalPartnersTable;
