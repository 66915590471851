/* eslint-disable react/no-danger */
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';

import { Board, Society } from 'types';
import attendanceTypes from 'constants/attendanceTypes';
import { setModal } from 'modules/_shared/redux/modalActions';

type SendAnnouncementModalHeaderProps = {
  date: string;
  time: string;
  actualSociety: Society;
  currentBoard: Board;
};

const SendAnnouncementModalHeader: FC<SendAnnouncementModalHeaderProps> = ({
  actualSociety,
  currentBoard,
  date,
  time,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const headerText = t('SendAnnouncementMessage', {
    date,
    time,
    societyName: actualSociety.name,
    attendanceType: attendanceTypes[currentBoard.attendanceType].text,
  });

  return (
    <>
      <button
        type="button"
        className="close"
        onClick={() => dispatch(setModal(null))}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt" />

      <h4 className="nk-modal-title">{t('SendAnnouncement')}</h4>

      <div className="nk-modal-text">
        <p className="lead" dangerouslySetInnerHTML={{ __html: headerText }} />
        <p className="text-soft fs-14px">{t('SendAnnouncementFootMessage')}</p>
      </div>
    </>
  );
};

export default SendAnnouncementModalHeader;
