/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';

import { numberFormatDecimals } from 'constants/formats';
import userTypes from 'constants/userTypes';
import {
  getDecimalScale,
  getPartnerAmount,
  getSocietyValue,
} from 'utils/filters';

import { useSelector } from 'react-redux';
import { formatNumber, formatNumberDecimalsLong } from 'utils/formats';
import { getTheme, themeColors } from 'utils/theme';
import MenuDots from '../../../_shared/components/MenuDots';
import menuOptions from './menuOptions';

import './PartnerCard.scss';

const PartnerCard = ({ partner, society, hasLink }) => {
  const { t } = useTranslate();

  const { isReadOnly } = useGetPartnersSectionPermissions().data;

  const user = useSelector((state) => state.user);
  const isReadOnlyUser = useSelector((state) =>
    partner?.readOnlyUsers?.includes(state.user['_id'])
  );

  const [percent, setPercent] = useState();
  const [partnerTotalShares, setPartnerTotalShares] = useState();

  const [selectedTheme, setSelectedTheme] = useState(getTheme());
  const [cardWaveBackground, setCardWaveBackground] = useState({});

  useEffect(() => {
    if (partner) {
      const partnerShares = partner.sharesCount?.actual;
      setPartnerTotalShares(partnerShares);
      const societyValue = getSocietyValue(society);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;
      setPercent(
        ((partnerShares * 100) / society.sharesCount?.actual).toFixed(
          decimalScale
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner, partnerTotalShares]);

  useEffect(() => {
    const colors = themeColors[selectedTheme];

    setCardWaveBackground({
      COLOR_100: colors.WAVE_BACKGROUND,
      COLOR_70: colors.WAVE_BACKGROUND_70,
      COLOR_50: colors.WAVE_BACKGROUND_50,
      COLOR_30: colors.WAVE_BACKGROUND_30,
    });
  }, [selectedTheme]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = getTheme();
      setSelectedTheme(theme);
    });
  }, []);

  menuOptions[0].url = `/detalle-socio/${society?.['_id']}`;
  menuOptions[1].url = `/detalle-socio/${society?.['_id']}`;

  return (
    <div className="col-sm-6 col-xl-4">
      <div className="card card-bordered partner-card wave-container">
        <div className="header">
          <div className="card-inner pb-0">
            <div className="team d-flex flex-column">
              <MenuDots
                className="align-self-start text-danger"
                menuOptions={menuOptions}
                id={partner['_id']}
                params={{
                  data: partner,
                  societyId: society['_id'],
                  userType: userTypes.PARTNER,
                  society,
                  userId: user?.['_id'],
                  isReadOnly,
                  isReadOnlyUser,
                }}
              />
              <div className="user-card user-card-s2">
                <div className="user-avatar md bg-primary">
                  {partner.image ? (
                    <img src={partner.image} alt={partner.name || ''} />
                  ) : (
                    <span>{partner?.name?.slice(0, 2)?.toUpperCase()}</span>
                  )}
                </div>
                <div className="user-info">
                  <h6>{partner.name}</h6>
                  <h6 className="fw-normal fs-14px">{t('PARTNER')}</h6>
                </div>
              </div>
            </div>
          </div>

          <div>
            <svg
              className="waves"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shapeRendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <g className="parallax">
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="0"
                  fill={cardWaveBackground.COLOR_70}
                />
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="3"
                  fill={cardWaveBackground.COLOR_50}
                />
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="5"
                  fill={cardWaveBackground.COLOR_30}
                />
                <use
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="7"
                  fill={cardWaveBackground.COLOR_100}
                />
              </g>
            </svg>
          </div>
        </div>

        <div className="card-inner">
          <div className="team">
            <div className="d-flex card-statistics justify-content-sm-between justify-content-center flex-wrap flex-sm-row flex-column">
              <div className="d-flex flex-column">
                <h6>{formatNumber(partnerTotalShares) || 0}</h6>
                <span>{t('NrPart.')}</span>
              </div>
              <div className="d-flex flex-column">
                <h6>
                  {percent > 0 ? formatNumberDecimalsLong(+percent) : 0}
                  <span className="partner-values-suffix">%</span>
                </h6>
                <span>%</span>
              </div>
              <div className="d-flex flex-column">
                <h6>
                  <NumberFormat
                    value={getPartnerAmount(partner.shares, society) || 0}
                    displayType="text"
                    renderText={(value) => <div>{value}</div>}
                    {...numberFormatDecimals}
                  />
                  <span className="partner-values-suffix">€</span>
                </h6>
                <span>{t('Investment')}</span>
              </div>
            </div>
            <div className="team-view mt-2">
              {hasLink ? (
                <Link
                  to={`/detalle-socio/${society?.['_id']}/${partner?.['_id']}`}
                  className="btn btn-round btn-outline-light w-150px"
                >
                  <span>{t('ViewProfile')}</span>
                </Link>
              ) : (
                <span className="btn btn-round btn-outline-light w-150px text-center disabled">
                  <em className="icon ni ni-lock text-secondary text-black-50 mx-auto" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
