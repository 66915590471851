export const partnerRepresentantDetailTabs = [
  {
    key: 'investor-tab',
    title: 'PartnerRepresentant',
    icon: 'ni ni-user-circle',
  },
  {
    key: 'data-tab',
    title: 'Data',
    icon: 'ni ni-list',
  },
];
