import { FC } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

import PartnersPreviewTable from './PartnersPreviewTable';

type Props = {
  partners: (Partner & { isExisting?: boolean })[];
};

const PartnersPreviewBody: FC<Props> = ({ partners }) => (
  <>
    <Modal.Body>
      <PartnersPreviewTable partners={partners} />
    </Modal.Body>
  </>
);

export default PartnersPreviewBody;
