import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

import { store } from 'redux/store';
import { Committee, CommitteeMember } from 'types';
import { getCommittee } from 'modules/administratives/redux/committeeActions';

export const useCommitteeMemberDetails = () => {
  const { committeeId, memberId } = useParams();

  const [data, setData] = useState<CommitteeMember | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    if (!committeeId || !memberId) return;

    setIsLoading(true);
    setIsError(false);

    try {
      const committee: Committee = await store.dispatch(
        getCommittee(committeeId)
      );
      const member: CommitteeMember | null =
        committee?.members?.find((m) => m._id === memberId) || null;
      setData(member);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [committeeId, memberId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
    isError,
  };
};
