import React, { FC, useEffect } from 'react';

import styles from './index.module.scss';

interface Resource {
  name: string;
  url: string;
  extra?: string;
}

const DemoTS: FC = () => {
  const message: string = 'TypeScript resources';
  const [count, setCount] = React.useState<number | null>(0);

  const tsResources: Resource[] = [
    {
      name: 'TypeScript Handbook',
      url: 'https://www.typescriptlang.org/docs/handbook/intro.html',
    },
    {
      name: 'TypeScript Example on React',
      url: 'https://www.typescriptlang.org/play/index.html?jsx=2&esModuleInterop=true&e=196#example/typescript-with-react',
    },
    {
      name: 'React + TypeScript Cheatsheets',
      url: 'https://github.com/typescript-cheatsheets/react-typescript-cheatsheet#reacttypescript-cheatsheets',
      extra: 'has a good overview on how to use React with TypeScript',
    },
  ];

  useEffect(() => {
    document.title = 'DemoTS';
    setCount(1);
  }, []);

  return (
    <div className="nk-content-body">
      <h1 className={styles.DemoTitle}>DemoTS</h1>
      <h6 className={styles.DemoSubtitle}>
        {message} [{count}]
      </h6>

      <hr />

      <ul className="list">
        {tsResources.map((resource: Resource) => (
          <li key={resource.name}>
            <a href={resource.url} target="_blank" rel="noreferrer">
              {resource.name}
            </a>
            &nbsp;
            {resource.extra && <span>{resource.extra}</span>}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DemoTS;
