import Swal from 'sweetalert2';

import { store } from 'redux/store';
import documentTypes from 'constants/documentTypes';

import editPreview from 'utils/editPreview';
import { hasShowDetails } from 'utils/showDetails';
import duplicateDocument from 'utils/duplicateDocument';

import { updateDocument } from 'modules/documents/redux/documentActions';

function menuOptions(i18n) {
  const downloadOptions = [
    {
      icon: 'ni-file-pdf',
      text: i18n.t('DownloadPDF'),
      action: ({ handleExportPDF }) => handleExportPDF(),
    },
    {
      icon: 'ni-file-doc',
      text: i18n.t('DownloadWord'),
      action: ({ handleExportWord }) => handleExportWord(),
    },
  ];

  const editionOptions = [
    {
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      action: ({ handleEditDocument }) =>
        hasShowDetails ? handleEditDocument() : editPreview,
      disabled: false,
    },
    {
      icon: 'ni-copy',
      text: i18n.t('Duplicate'),
      action: (params) => duplicateDocument(params.preview.bdDocument),
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: (params) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">${i18n.t(
            'AreYouSureAboutDelDocument'
          )}</h4>`,
          confirmButtonText: i18n.t('Delete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch(
              updateDocument({
                ...params.preview.bdDocument,
                category: documentTypes.DELETED,
              })
            );
          }
        });
      },
    },
  ];

  return [...downloadOptions, ...editionOptions];
}

export default menuOptions;
