/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import {
  AsyncTypeahead,
  ClearButton,
  Typeahead,
} from 'react-bootstrap-typeahead';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import { EMAIL_PATTERN, SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import eventTypes from 'constants/eventTypes';

import { generateEmail, isCifAlready } from 'utils/filters';
import isValidEmail from 'utils/isValidEmail';
import normalizeCif from 'utils/normalizeCif';
import normalizeEmail from 'utils/normalizeEmail';
import trackEvent from 'utils/trackEvent';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { addPartner } from 'modules/_shared/redux/modalsActions';
import {
  getSocietiesRegistered,
  getUserSocieties,
} from 'modules/society/redux/societyActions';

import InputError from '../../InputError';
import '../Modals.scss';

const AddPartnerEmbeddedModal = ({
  setSelectedPartner,
  setSelectedPartnerOption,
  setCreatePartnerInsite,
  date,
  isDraft = false,
  setNewPartner,
}) => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { t } = useTranslate();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const [email, setEmail] = useState('');
  const [cif, setCif] = useState();
  const [name, setName] = useState('');
  const [naturalPerson, setNaturalPerson] = useState(true);
  const [societies, setSocieties] = useState([]);
  const [partners, setPartners] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [textFromSociety, setTextFromSociety] = useState('');
  const [societiesList, setSocietiesList] = useState([]);
  const [validEmail, setValidEmail] = useState(false);

  const generateAlternativeId = () =>
    uuidv4().replace(/-/g, '').substring(0, 24);

  const handleSearch = (query) => {
    const filteredOption = partners.filter((partner) =>
      partner.name.toLowerCase().includes(query.toLowerCase())
    );
    setOptions(filteredOption);
    setName(query);
  };

  const handleOptionSelected = (selectedOption) => {
    setName(selectedOption?.name);
    setCif(selectedOption?.cif);
    setEmail(selectedOption.email);
  };

  const clearSelectedOption = () => {
    setCif('');
    setEmail('');
  };

  const handleChangeCif = (event) => {
    const { value } = event.target;

    const normalicedCif = normalizeCif(value);
    setCif(normalicedCif);
  };

  const handleChangeEmail = (event) => {
    const { value } = event.target;

    const normalicedEmail = normalizeEmail(value);
    setEmail(normalicedEmail);

    const isValid = isValidEmail(normalicedEmail);
    setValidEmail(isValid);
  };

  const handleSelectCompany = (society) => {
    if (society?.customOption) {
      setName(society.label);
    } else {
      const { name } = society?.nameAddress || '';
      const { registeredOfficeCode } = society?.registeredOffice || '';

      setName(name);
      setCif(registeredOfficeCode);
    }
    setSocietiesList([]);
    setTextFromSociety('');
  };

  const clearSelectCompany = () => {
    setName('');
    setCif('');
  };

  const handleAutoCompleteBlur = () => {
    if (textFromSociety) {
      handleSelectCompany({
        customOption: true,
        label: textFromSociety,
        id: 'new-id-1',
      });
    }
  };

  function handleChangePersonValue() {
    setNaturalPerson(!naturalPerson);
  }

  function savePartner(event) {
    event.preventDefault();

    const validationCif = isCifAlready(cif, actualSociety);

    if (validationCif) {
      dispatch(addAlert(alertBodyTypes.DUPLICATED_CIF));
    } else {
      const id = generateAlternativeId();
      const newPartner = {
        name,
        email: email !== '' ? email : generateEmail(actualSociety),
        category: null,
        date,
        society: actualSociety['_id'],
        societyName: actualSociety?.name,
        userEmail: user.email,
        cif,
        user: user['_id'],
        isNaturalPerson: naturalPerson,
      };
      if (isDraft) {
        setNewPartner({
          ...newPartner,
          _id: id,
          sharesCount: {
            actual: 0,
          },
          isPartnerAlready: false,
          temporalId: id,
        });
      } else {
        dispatch(addPartner(newPartner));
        trackEvent(mixpanel, eventTypes.ADD_PARTNER_EMBEDDED, {
          userId: user?._id,
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?._id,
          societyName: actualSociety?.name,
          operation: eventTypes.ADD_PARTNER_EMBEDDED,
        });
      }
      setSelectedPartner(SELECCIONAR_SOCIO);
      setSelectedPartnerOption({
        value: SELECCIONAR_SOCIO,
        label: SELECCIONAR_SOCIO,
      });
    }
  }

  useEffect(() => {
    if (user && user.societies.administrated.length) {
      getUserSocieties(user['_id'], 'admin', setSocieties);
    }
  }, [user]);

  useEffect(() => {
    if (societies) {
      let partnersMerge = [];
      societies.forEach((society) => {
        const societyPartners = society?.partners || [];
        partnersMerge = [...partnersMerge, ...societyPartners];
      });
      setPartners(partnersMerge);
    }
  }, [societies]);

  return (
    <>
      <form className="form-validate is-alter">
        <label
          className="form-label d-flex justify-between align-center"
          htmlFor="email-address"
        >
          <span>{t('NewPartner')}</span>
          <em
            className="icon ni ni-cross text-primary"
            onClick={() => {
              setSelectedPartner(SELECCIONAR_SOCIO);
              setSelectedPartnerOption({
                value: SELECCIONAR_SOCIO,
                label: SELECCIONAR_SOCIO,
              });
              setCreatePartnerInsite(false);
            }}
          />
        </label>

        <div
          className="row"
          style={{
            marginBottom: '20px',
          }}
        >
          <div className="col d-flex">
            <div className="form-group col-4 mb-0 p-0">
              {naturalPerson ? (
                <div className="form-control-wrap">
                  <Typeahead
                    labelKey="name"
                    minLength={1}
                    onInputChange={handleSearch}
                    options={options}
                    placeholder={t('NameAndSurname')}
                    selected={name ? [name] : []}
                    onChange={(selectedOption) => {
                      if (selectedOption.length > 0) {
                        handleOptionSelected(selectedOption[0]);
                      } else {
                        clearSelectedOption();
                      }
                    }}
                  >
                    {({ onClear, selected }) => (
                      <div className="rbt-aux">
                        {!!selected.length && <ClearButton onClick={onClear} />}
                      </div>
                    )}
                  </Typeahead>
                </div>
              ) : (
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-user" />
                  </div>
                  <AsyncTypeahead
                    id="societies-search-box"
                    isLoading={isLoading}
                    labelKey="label"
                    minLength={3}
                    onInputChange={(query) => {
                      setName(query);
                      setIsLoading(true);

                      setTextFromSociety(query);
                      getSocietiesRegistered(query).then((societies) => {
                        setSocietiesList(societies);
                      });
                      setIsLoading(false);
                    }}
                    onChange={([society]) => {
                      if (society) {
                        handleSelectCompany(society);
                      } else {
                        clearSelectCompany();
                      }
                    }}
                    onBlur={handleAutoCompleteBlur}
                    options={societiesList}
                    useCache={false}
                    defaultInputValue={name}
                    newSelectionPrefix={`${t('Select:')} `}
                    placeholder={t('NameAndSurname')}
                    allowNew
                  >
                    {({ onClear, selected }) => (
                      <div className="rbt-aux">
                        {!!selected.length && <ClearButton onClick={onClear} />}
                      </div>
                    )}
                  </AsyncTypeahead>
                </div>
              )}
            </div>
            <div className="form-group col-4 mb-0 p-0">
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control"
                  value={cif}
                  onChange={handleChangeCif}
                  placeholder="CIF/NIF"
                  required
                />
              </div>
            </div>
            <div className="form-group col-4 mb-0 p-0">
              <div className="form-control-wrap">
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  value={email}
                  onChange={handleChangeEmail}
                  placeholder={t('Email')}
                  pattern={EMAIL_PATTERN}
                />
              </div>
              {!validEmail && <InputError errorMessage={t('InvalidEmail')} />}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <div className="col-6">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="natural-person"
                  id="natural-person"
                  value={naturalPerson}
                  checked={naturalPerson}
                  onChange={() => handleChangePersonValue()}
                />
                <label
                  className="custom-control-label"
                  htmlFor="natural-person"
                >
                  {t('NaturalPerson')}
                </label>
              </div>
            </div>
            <div className="col-6">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="legal-person"
                  id="legal-person"
                  value={!naturalPerson}
                  checked={!naturalPerson}
                  onChange={() => handleChangePersonValue()}
                />
                <label className="custom-control-label" htmlFor="legal-person">
                  {t('LegalPerson')}
                </label>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-block btn-primary mt-3 mb-4"
          disabled={!name || !cif || !validEmail}
          onClick={savePartner}
        >
          {t('AddPartner')}
        </button>
      </form>
    </>
  );
};

export default AddPartnerEmbeddedModal;
