/* eslint-disable import/prefer-default-export */
import { Beneficiary, StockPlan } from 'types';
import { getLastVesting } from 'utils/vestingDates';

type GetVestingDataProps = {
  beneficiaryData: Beneficiary;
  planData: StockPlan;
};

type VestingData = {
  vestingUnits: number;
  vestingDate: Date;
};

export const getVestingData = ({
  beneficiaryData,
  planData,
}: GetVestingDataProps): VestingData => {
  const lastVesting = getLastVesting(beneficiaryData, planData, new Date());

  return {
    vestingUnits: 0,
    vestingDate: lastVesting,
  };
};
