import superAdmins from 'constants/superAdmins';

/**
 * @param {string|object} user - User email or user object
 * @returns {boolean} - Returns true if user is super admin
 */
const isSuperAdmin = (user) => {
  const email = typeof user === 'string' ? user : user.email;
  return superAdmins.includes(email);
};

export default isSuperAdmin;
