import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import errors from 'modules/auth/pages/TryDemo/utils/errors';

import axios from '../../../interceptors/axios';
import { addAlert } from '../../_shared/redux/alertActions';

export const actionTypes = {
  SET_USER: 'SET_USER',
};

function setUser(user) {
  return { type: actionTypes.SET_USER, user };
}

export const getUser = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/${id}`
    );

    const decodedUserData = await decryptResponse(data);
    localStorage.setItem('token', decodedUserData['_id']);

    dispatch(setUser(decodedUserData));
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const getUsers = (usersList) => async (dispatch) => {
  const query = { params: { ...usersList } };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users`,
      query
    );

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateUser =
  (userId, updateUserData, loggedUser, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: userId,
      ...updateUserData,
      loggedUser: loggedUser || userId,
    };
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users`,
        body
      );

      const decodedUserData = await decryptResponse(data);

      dispatch(setUser(decodedUserData));

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.USER_UPDATED));
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_UPDATING_USER));
    }
  };

export const findUser = (user) => async (dispatch) => {
  const email = user?.email;
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users`,
      { email }
    );

    const decodedUserData = await decryptResponse(data);
    localStorage.setItem('token', decodedUserData['_id']);

    const currentImage = decodedUserData?.image;
    if (currentImage === BLANK_PROFILE_PICTURE && user?.picture) {
      decodedUserData.image = user?.picture;
      dispatch(
        updateUser(
          decodedUserData['_id'],
          { image: user?.picture },
          decodedUserData['_id'],
          false
        )
      );
    }

    dispatch(setUser(decodedUserData));
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const addUserDemo = (user) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL_V2}/users`,
      { ...user, source: 'demo', isDemo: true }
    );

    const decodedUserData = await decryptResponse(data);
    localStorage.setItem('token', decodedUserData['_id']);

    return decodedUserData;
  } catch (error) {
    if (error.response.status === 409) {
      const responseError = error.response.data.error;
      const currentError = errors[responseError] || errors.GENERIC_ERROR;
      throw new Error(currentError);
    }

    return dispatchError(error, dispatch);
  }
};

export const fetchIdentities = (email, society = '') =>
  axios.post(`${process.env.REACT_APP_API_URL}/identities`, { email, society });
