/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-undef */
import React from 'react';
import i18n from 'i18n/config';
import { Page, Text, Font, View, Document } from '@react-pdf/renderer';
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';
import customStyles from './customStyles';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: `/fonts/Roboto-Regular.ttf`,
    },
    {
      src: `/fonts/Roboto-Bold.ttf`,
      fontWeight: 'bold',
    },
    {
      src: `/fonts/Roboto-Italic.ttf`,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: `/fonts/Roboto-BoldItalic.ttf`,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

const columns = [
  i18n.t('TotalShares'),
  i18n.t('Numeration'),
  i18n.t('Cost'),
  i18n.t('PricePart.'),
  i18n.t('RetentionPercent'),
  i18n.t('TotalAmount'),
];

const TenderOfferSharesDynamic = ({ tenderOffer }) => (
  <Document>
    <Page size="A4" style={customStyles.page}>
      <View>
        <Text style={customStyles.calendarTitle}>
          {i18n.t('AppendixTable')}
        </Text>
        <Table data={[tenderOffer]}>
          <TableHeader textAlign={'center'}>
            <TableCell style={customStyles.calendarHeader}>
              {columns[0]}
            </TableCell>
            <TableCell style={customStyles.calendarHeader}>
              {columns[1]}
            </TableCell>
            {tenderOffer?.sharesCost && (
              <TableCell style={customStyles.calendarHeader}>
                {columns[2]}
              </TableCell>
            )}
            <TableCell style={customStyles.calendarHeader}>
              {columns[3]}
            </TableCell>
            {tenderOffer?.retention >= 0 && (
              <TableCell style={customStyles.calendarHeader}>
                {columns[4]}
              </TableCell>
            )}
            <TableCell style={customStyles.calendarHeader}>
              {columns[5]}
            </TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell
              textAlign={'center'}
              style={customStyles.summaryCell}
              getContent={(r) => r.totalShares}
            />
            <DataTableCell
              textAlign={'center'}
              style={customStyles.summaryCell}
              getContent={(r) => `${r.sharesFrom}-${r.sharesTo}`}
            />
            {tenderOffer?.sharesCost && (
              <DataTableCell
                textAlign={'center'}
                style={customStyles.summaryCell}
                getContent={(r) => r.sharesCost}
              />
            )}
            <DataTableCell
              textAlign={'center'}
              style={customStyles.summaryCell}
              getContent={(r) => r.sharePrice}
            />
            {tenderOffer?.retention >= 0 && (
              <DataTableCell
                textAlign={'center'}
                style={customStyles.summaryCell}
                getContent={(r) => r.retention || 0}
              />
            )}
            <DataTableCell
              textAlign={'center'}
              style={customStyles.summaryCell}
              getContent={(r) => r.totalPrice}
            />
          </TableBody>
        </Table>
      </View>
    </Page>
  </Document>
);

export default TenderOfferSharesDynamic;
