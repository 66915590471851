/* eslint-disable react/jsx-props-no-spreading */
import { cleanValue, numberFormatDecimals } from 'constants/formats';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import { SELECCIONAR_SOCIO, SELECT_YEAR } from 'constants/defaultConstants';
import { useTranslate } from 'hooks/useTranslate';
import UserDropdown from 'modules/_shared/components/Modals/UserDropdown';
import { setModal } from 'modules/_shared/redux/modalActions';
import sendEmail from 'modules/communications/redux/mailActions';
import directorTypes from 'modules/society/components/SocietyDirectors/directorTypes';
import statusTypes from 'modules/society/components/SocietyDirectors/statusTypes';
import { updateSociety as updateSocietyAction } from 'modules/society/redux/societyActions';
import { getActualSociety, getDirectorStatus } from 'utils/filters';

function AnnualValueModal({ societyId, actualAnnualValues }) {
  const { t, i18n } = useTranslate();
  const dispatch = useDispatch();
  const { user, society } = useSelector((state) => ({
    user: state?.user,
    society: state.society?.actualSociety,
  }));

  const [year, setYear] = useState(SELECT_YEAR);
  const [years, setYears] = useState([]);
  const [ebitda1, setEbitda1] = useState('');
  const [ebitda2, setEbitda2] = useState('');
  const [ebitda3, setEbitda3] = useState('');
  const [mainDirector, setMainDirector] = useState();
  const [directors, setDirectors] = useState();
  const [contableValue, setContableValue] = useState('');

  const [currStatusTypes, setCurrStatusTypes] = useState(statusTypes(i18n));
  const [currDirectorTypes, setCurrDirectorTypes] = useState(
    directorTypes(i18n)
  );
  useEffect(() => {
    setCurrStatusTypes(statusTypes(i18n));
    setCurrDirectorTypes(directorTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (society?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [society, user, societyId]);

  useEffect(() => {
    if (society.mainDirector) {
      const director = society?.directors.find(
        (d) => d?.['_id'] === society.mainDirector
      );
      setMainDirector(
        JSON.stringify({ id: director['_id'], cif: director?.cif })
      );
    }
    const availableDirectors = society?.directors.filter(
      (d) =>
        (d.directorType === currDirectorTypes.var.UNIQUE_ADMIN ||
          d.directorType === currDirectorTypes.var.DELEGATE_COUNSELOR ||
          d.directorType === currDirectorTypes.var.COUNCIL_SECRETARY) &&
        getDirectorStatus(d) === currStatusTypes.var.ACTIVE
    );
    setDirectors(availableDirectors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    const years = [];
    const lastYear = new Date().getFullYear() - 1;
    for (let i = 2015; i <= lastYear; i += 1) {
      years.push(i);
    }
    const filteredYears = years.filter(
      (year) =>
        !actualAnnualValues.some(
          (annualValue) => annualValue.year === year.toString()
        )
    );
    setYears(filteredYears);
  }, [actualAnnualValues]);

  function saveAnnualValue(event) {
    event.preventDefault();

    if (+year === new Date().getFullYear() - 1) {
      const admin = society?.directors?.find(
        (d) => d['_id'] === JSON.parse(mainDirector)?.id
      );
      if (admin) {
        const emailData = {
          to: [
            {
              email: admin?.email,
              name: admin?.socialDenomination,
            },
          ],
          subject: t('SignatureRequest'),
          template: `admin-sign-${i18n.language}`,
          templateData: [
            {
              name: 'adminName',
              content: admin?.socialDenomination || '',
            },
            {
              name: 'adminJob',
              content: currDirectorTypes.name?.[admin?.directorType] || '',
            },
            {
              name: 'societyName',
              content: society?.name || '',
            },
            {
              name: 'targetUrl',
              content: `${process.env.REACT_APP_DOMAIN}?redirect=firma-certificado/${society?.['_id']}`,
            },
          ],
          user: user['_id'],
          society: society['_id'],
          date: new Date(),
        };
        dispatch(sendEmail(emailData));
      }
    }

    const ebitdas = [ebitda1, ebitda2, ebitda3]
      .filter((elem) => elem !== '')
      .map((elem) => cleanValue(elem));

    const newSocietyData = {
      annualValues: [
        {
          year,
          contableValue: cleanValue(contableValue),
          ebitda: [...ebitdas],
          modifiedDate: new Date(),
        },
        ...actualAnnualValues,
      ],
    };

    store.dispatch(updateSocietyAction(societyId, newSocietyData));
    store.dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('AnnualValueDataCertificate')}</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveAnnualValue}>
          <div className="form-group">
            <label className="form-label" htmlFor="year">
              {t('Year')}
            </label>
            <div className="form-control-wrap">
              <select
                name="year"
                id="year"
                className="form-control date-picker"
                onChange={(event) => setYear(event.target.value)}
                required
              >
                <option>{SELECT_YEAR}</option>
                {years.map((year) =>
                  actualAnnualValues.some((data) => data.year === year) ? (
                    <option value={year} key={year} disabled>
                      {year}
                    </option>
                  ) : (
                    <option value={year} key={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              {t('AccountingValueBalance')}
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">EUR €</span>
              </div>
              <div className="form-control-wrap">
                <NumberFormat
                  id="outlined-normal"
                  className="form-control form-control-md form-control-outlined"
                  allowLeadingZeros={false}
                  value={contableValue}
                  onChange={(e) => setContableValue(e.target.value)}
                  {...numberFormatDecimals}
                  required
                />
                <div className="form-info">€</div>
              </div>
            </div>
          </div>

          {year !== SELECT_YEAR ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  {`${t('EbitdaResult')} ${year || ''}`}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">EUR €</span>
                  </div>
                  <div className="form-control-wrap">
                    <NumberFormat
                      id="outlined-normal"
                      className="form-control form-control-md form-control-outlined"
                      allowLeadingZeros={false}
                      value={ebitda1}
                      onChange={(e) => setEbitda1(e.target.value)}
                      {...numberFormatDecimals}
                      required
                    />
                    <div className="form-info">€</div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  {`${t('EbitdaResult')} ${year ? year - 1 : ''}`}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">EUR €</span>
                  </div>
                  <div className="form-control-wrap">
                    <NumberFormat
                      id="outlined-normal"
                      className="form-control form-control-md form-control-outlined"
                      allowLeadingZeros={false}
                      value={ebitda2}
                      onChange={(e) => setEbitda2(e.target.value)}
                      {...numberFormatDecimals}
                    />
                    <div className="form-info">€</div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  {`${t('EbitdaResult')} ${year ? year - 2 : ''}`}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">EUR €</span>
                  </div>
                  <div className="form-control-wrap">
                    <NumberFormat
                      id="outlined-normal"
                      className="form-control form-control-md form-control-outlined"
                      allowLeadingZeros={false}
                      value={ebitda3}
                      onChange={(e) => setEbitda3(e.target.value)}
                      {...numberFormatDecimals}
                    />
                    <div className="form-info">€</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="form-group">
            <label className="form-label" htmlFor="birth-day">
              Administrador que firma
            </label>
            <UserDropdown
              users={directors}
              action={setMainDirector}
              user={mainDirector}
              directorsData
            />
            <span className="sub-text mt-1 mb-5">
              Se le notificará por correo electrónico
            </span>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={
              year === SELECT_YEAR || mainDirector === SELECCIONAR_SOCIO
            }
          >
            Guardar valor anual
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default AnnualValueModal;
