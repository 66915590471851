import { ChangeEvent } from 'react';

type DelegationPartnerInstructionOptionProps = {
  id: string;
  value: string;
  checked: boolean;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void; // eslint-disable-line
};

const DelegationPartnerInstructionOption = ({
  id,
  value,
  checked,
  label,
  onChange,
}: DelegationPartnerInstructionOptionProps) => (
  <div className="col-md-3">
    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
      <input
        type="radio"
        className="custom-control-input"
        name="btnRadio"
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  </div>
);

export default DelegationPartnerInstructionOption;
