/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';

import UploadDocument from 'modules/documents/components/UploadDocument';

type Props = {
  file: File | undefined;
  fileName: string;
  onChange: any;
};

const PersonalDataViewSindicationDocument: FC<Props> = ({
  file,
  fileName,
  onChange,
}) => (
  <div className="col-md-6">
    <UploadDocument
      label="DeedOfSyndication"
      file={file}
      inputLabel={fileName}
      setFile={onChange}
      accept="application/pdf"
      idIndex={0}
    />
  </div>
);

export default PersonalDataViewSindicationDocument;
