import { useCallback, useEffect, useRef } from 'react';

import { DecoupledEditor } from 'ckeditor5';

type Props = {
  editorInstance: any;
  setEditorInstance: any;
  editorConfig: any;
  isReadOnly: boolean;
  onChangeContent?: () => void;
};

function extendsEditorSchema(editor: any) {
  // Extend the editor schema to allow 'span' elements with a 'class' attribute.
  editor.model.schema.register('span', {
    allowWhere: '$text',
    allowAttributes: ['class'],
    isInline: true,
  });

  // Define the conversion between model and view.
  editor.conversion.for('upcast').elementToElement({
    model: (viewElement: any, { writer: modelWriter }: { writer: any }) =>
      modelWriter.createElement('span', {
        class: viewElement.getAttribute('class'),
      }),
    view: 'span',
  });

  editor.conversion.for('downcast').elementToElement({
    model: 'span',
    view: (modelElement: any, { writer: viewWriter }: { writer: any }) => {
      const span = viewWriter.createContainerElement('span', {
        class: modelElement.getAttribute('class'),
      });
      return span;
    },
  });
}

export function useGetEditor({
  editorInstance,
  setEditorInstance,
  editorConfig,
  isReadOnly,
  onChangeContent,
}: Props) {
  const editorToolbarRef = useRef<any>(null);
  const editorRef = useRef<any>(null);

  const handleEditorChange = useCallback(() => {
    if (onChangeContent) {
      onChangeContent();
    }
  }, [onChangeContent]);

  useEffect(() => {
    const shouldCreateEditor =
      editorRef.current && editorConfig && !editorInstance;
    if (shouldCreateEditor) {
      // @ts-ignore
      DecoupledEditor.create(editorRef.current, editorConfig)
        .then((editor) => {
          if (isReadOnly) {
            editor.enableReadOnlyMode('readOnly');
          }
          setEditorInstance(editor);
          // Append the toolbar to the editorToolbarRef element
          if (editorToolbarRef.current) {
            // @ts-ignore
            editorToolbarRef.current.appendChild(
              editor.ui.view.menuBarView.element
            );
            // @ts-ignore
            editorToolbarRef.current.appendChild(
              editor.ui.view.toolbar.element
            );

            // extendsEditorSchema(editor);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('There was a problem initializing the editor.', error);
        });
    }

    if (editorInstance) {
      editorInstance.model.document.on('change', handleEditorChange);
    }
  }, [
    editorRef,
    editorConfig,
    isReadOnly,
    editorInstance,
    setEditorInstance,
    handleEditorChange,
  ]);

  return {
    data: { editorRef, editorToolbarRef },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
