import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getActualSociety } from 'utils/filters';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import { getCommittees } from 'modules/administratives/redux/committeeActions';
import { useGetMenu } from '../../hooks/useGetMenu';

import CommitteeMembersPageHeader from './components/CommitteeMembersPageHeader';
import useGetCommittee from './hooks/useGetCommitee';
import CommitteeMembersPageBody from './components/CommitteeMembersPageBody';

function CommitteeMembers() {
  const { societyId, committeeId } = useParams();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.user);

  const committeResult = useGetCommittee({ committeeId });

  const { committee } = committeResult.data;
  const { getUpdatedCommittee } = committeResult.actions;

  useGetMenu();

  useEffect(() => {
    if (user && societyId) getActualSociety(user, societyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (societyId) dispatch(getCommittees(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  return committee ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <CommitteeMembersPageHeader
          committeeId={committeeId}
          committeeName={committee?.name}
          membersCount={committee?.members?.length || 0}
          getUpdatedCommittee={getUpdatedCommittee}
        />

        <CommitteeMembersPageBody members={committee?.members || []} />
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default CommitteeMembers;
