import i18n from 'i18n/config';

export default {
  HYBRID: {
    value: 'HYBRID',
    text: i18n.t('HybridAttendance'),
  },
  ONLINE: {
    value: 'ONLINE',
    text: i18n.t('OnlineAttendance'),
  },
  FACE_TO_FACE: {
    value: 'FACE_TO_FACE',
    text: i18n.t('FaceToFaceAttendance'),
  },
  WRITTEN_NO_SESSION: {
    value: 'WRITTEN_NO_SESSION',
    text: i18n.t('WrittenNoSessionAttendance'),
  },
};
