import i18n from 'i18n/config';
import representativeTypes from './representativeTypes';

export default [
  {
    name: i18n.t('General'),
    value: representativeTypes.var.GENERAL,
  },
  {
    name: i18n.t('Special'),
    value: representativeTypes.var.SPECIAL,
  },
];
