export default {
  USER: 'USER',
  PARTNER: 'PARTNER',
  BENEFICIARY: 'BENEFICIARY',
  INVESTOR: 'INVESTOR',
  BENEFICIARY_DRAFT: 'DRAFT',
  EMPLOYEE: 'EMPLOYEE',
  ADMIN: 'ADMIN',
  PARTNER_REPRESENTANT: 'PARTNER_REPRESENTANT',
};
