/* eslint-disable import/prefer-default-export */
import { updateSociety } from 'modules/society/redux/societyActions';
import { Landing } from '../types/CustomDashboard';
import { updateDashboardSections } from '../utils';

export const saveCustomDashboard =
  (dashboard: Landing) => async (dispatch: any, getState: any) => {
    const updatedDashboard = updateDashboardSections(dashboard);
    const actualSociety = getState().society?.actualSociety;

    const updateData = {
      customLanding: { updateData: updatedDashboard },
    };

    await dispatch(updateSociety(actualSociety?._id, { ...updateData }, false));
  };
