import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import SignaturePad from 'signature_pad';

import { store } from 'redux/store';

import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { signatureColors } from 'modules/society/components/SocietyConfiguration/constants/themeColors';
import SocietyConfigurationColorRow from 'modules/society/components/SocietyConfiguration/components/SocietyConfigurationColorRow';

import './SignatureBox.scss';

const SignatureBox = ({
  title,
  signature,
  setSignature,
  isDelegation = false,
  setHasFinishedSignature,
}) => {
  const { t } = useTranslate();
  const [signaturePad, setSignaturePad] = useState(null);
  const [signatureColor, setSignatureColor] = useState('#000000');

  const handleClearSignature = () => {
    signaturePad.clear();
    setSignature(null);
  };

  const handleColorChange = (color) => {
    setSignatureColor(color);
    signaturePad.penColor = color;
  };

  useEffect(() => {
    setSignaturePad(
      new SignaturePad(document.getElementById('signature-pad'), {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: signatureColor,
      })
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="card card-bordered shadow w-100 mw-500px">
      <div className="card-header border-bottom bg-lighter">
        <h6>{title}</h6>
      </div>

      <div className="card-body">
        <div className="signature-wrapper">
          <canvas
            id="signature-pad"
            className="signature-pad"
            width="500"
            height="200"
          />
        </div>
        <div className="signature-toolbar">
          <SocietyConfigurationColorRow
            id="signature-color"
            colors={signatureColors}
            currentColor={signatureColor}
            onColorChange={handleColorChange}
            isColorDisabled={() => false}
            showTitle={false}
            showColorInput={false}
            className="w-100"
          />
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={handleClearSignature}
          >
            <em className="icon ni ni-trash mr-1" />
            {t('Clear')}
          </button>
        </div>

        <div className="signature-buttons-area">
          <button
            type="button"
            className="btn btn-block btn-primary"
            disabled={!!signature}
            onClick={() => {
              const data = signaturePad?.toDataURL('image/png');
              if (isDelegation) {
                setHasFinishedSignature(true);
              } else {
                store.dispatch(addAlert(alertBodyTypes.SIGNATURE_SAVED));
              }
              setSignature(data);
            }}
          >
            <em className="icon ni ni-save mr-1" />
            {t('SaveSign')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignatureBox;
