/* eslint-disable react/jsx-props-no-spreading */
import { numberFormat } from 'constants/formats';
import { useTranslate } from 'hooks/useTranslate';
import NumberFormat from 'react-number-format';

const labels = {
  'Number of new shares': 'Shares',
  'Shares nominal value': 'NominalValue',
};

const ConstitutionAiModalConstitutionTable = ({
  constitutionData,
  changeConstitutionParam,
}) => {
  const { t } = useTranslate();

  return (
    <section
      key="constitution-card"
      className="constitution-ai-section card card-bordered card-preview mb-2"
    >
      <table className="table table-tranx" id="constitution--table">
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={3}>
              <span>{t('Capital')}</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            className="tb-tnx-item row-with-input"
            key="constitution-card"
            colSpan={3}
          >
            {Object.entries(constitutionData)
              .filter(([key]) => labels[key])
              .map(([key, value]) => (
                <div className="row row-internal">
                  <div className="col-4">
                    <span>
                      <b>{t(labels[key]) || key}</b>
                    </span>
                  </div>

                  <div className="col-7 px-0">
                    <NumberFormat
                      id={`constitution-${key}-input2`}
                      className="form-control"
                      value={value}
                      onChange={(event) => {
                        changeConstitutionParam(key, event.target.value);
                      }}
                      {...numberFormat}
                    />
                  </div>

                  <div className="col-1" />
                </div>
              ))}
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default ConstitutionAiModalConstitutionTable;
