export const getCapitalChartViewData = (capitalViewData: any) => {
  if (!capitalViewData) {
    return null;
  }
  const societyActualShares = capitalViewData[0].value1;
  const societyActualSharesPercentage = capitalViewData[0].value2;

  const convertibleNotesShares = capitalViewData[1].value1;
  const convertibleNotesSharesPercentage = capitalViewData[1].value2;

  const stockOptionsShares = capitalViewData[2].value1;
  const stockOptionsSharesPercentage = capitalViewData[2].value2;

  const phantomShares = capitalViewData[3].value1;
  const phantomSharesPercentage = capitalViewData[3].value2;

  return {
    societyActualShares,
    societyActualSharesPercentage,
    convertibleNotesShares,
    convertibleNotesSharesPercentage,
    stockOptionsShares,
    stockOptionsSharesPercentage,
    phantomShares,
    phantomSharesPercentage,
  };
};
