import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import consolidationTypes from 'modules/beneficiaries/constants/consolidationTypes';

import { actions } from '../constants/actions';

type Props = {
  action: (typeof actions)[keyof typeof actions];
  customConsolidationType: string;
  consolidationPeriodsByPercent: number[];
  consolidationPeriodsByUnits: number[];
  handleSelectPeriodsByPercent: () => void;
  handleConsolidationPeriodsChange: (
    // eslint-disable-next-line no-unused-vars
    index: number,
    // eslint-disable-next-line no-unused-vars
    value: string,
    // eslint-disable-next-line no-unused-vars
    type: string
  ) => void;
  handleSelectPeriodsByUnits: () => void;
};

const AddBeneficiaryModalCustomConsolidationView: FC<Props> = ({
  action,
  customConsolidationType,
  consolidationPeriodsByPercent,
  consolidationPeriodsByUnits,
  handleSelectPeriodsByPercent,
  handleConsolidationPeriodsChange,
  handleSelectPeriodsByUnits,
}) => {
  const { t } = useTranslate();

  const isByPercentDisabled =
    action === actions.WATCH ||
    customConsolidationType !== consolidationTypes.BY_PERCENT;

  const isByUnitsDisabled =
    action === actions.WATCH ||
    customConsolidationType !== consolidationTypes.BY_UNITS;

  const handleOnWheel = (event: any) => {
    event.target.blur();
  };

  return (
    <div className="card card-bordered card-preview mb-3">
      <div className="card-inner row">
        <div className="col-6">
          <div className="custom-control custom-control-xs custom-checkbox mb-3">
            <input
              type="checkbox"
              id="checkbox-period-by-percent"
              className="custom-control-input"
              checked={
                customConsolidationType === consolidationTypes.BY_PERCENT
              }
              onChange={handleSelectPeriodsByPercent}
            />
            <label
              className="custom-control-label"
              htmlFor="checkbox-period-by-percent"
            >
              {t('ByPercents')}
            </label>
          </div>

          {consolidationPeriodsByPercent.map((period, index) => (
            <div className="form-group" key={`period-by-percent-${index + 1}`}>
              <label className="form-label" htmlFor="default-01">
                {t('Section')} {index + 1}
              </label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  className="form-control form-control-lg"
                  value={period}
                  disabled={isByPercentDisabled}
                  onChange={(event) =>
                    handleConsolidationPeriodsChange(
                      index,
                      event.target.value,
                      consolidationTypes.BY_PERCENT
                    )
                  }
                  onWheel={handleOnWheel}
                  min="0"
                  step="0.000000001"
                  placeholder="%"
                  required
                />
              </div>
            </div>
          ))}
        </div>

        <div className="col-6">
          <div className="custom-control custom-control-xs custom-checkbox mb-3">
            <input
              type="checkbox"
              id="checkbox-period-by-units"
              className="custom-control-input"
              checked={customConsolidationType === consolidationTypes.BY_UNITS}
              onChange={handleSelectPeriodsByUnits}
            />
            <label
              className="custom-control-label"
              htmlFor="checkbox-period-by-units"
            >
              {t('ByUnits')}
            </label>
          </div>

          {consolidationPeriodsByUnits.map((period, index) => (
            <div className="form-group" key={`period-by-units-${index + 1}`}>
              <label className="form-label" htmlFor="default-01">
                {t('Section')} {index + 1}
              </label>
              <div className="form-control-wrap">
                <input
                  type="number"
                  className="form-control form-control-lg"
                  value={period}
                  disabled={isByUnitsDisabled}
                  onChange={(event) =>
                    handleConsolidationPeriodsChange(
                      index,
                      event.target.value,
                      consolidationTypes.BY_UNITS
                    )
                  }
                  onWheel={handleOnWheel}
                  min="0"
                  step="0.000000001"
                  placeholder="%"
                  required
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddBeneficiaryModalCustomConsolidationView;
