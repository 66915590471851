/* eslint-disable no-plusplus */
import i18n from 'i18n/config';

import { format } from 'date-fns';
import { dateFormat } from 'constants/formats';

import backgroundConfetti from 'assets/images/bg-confetti.png';

export const generateCalendarPlanTable = (
  consolidationCalendar,
  {
    hasConsolidationCalendar,
    hasConsolidationCalendarNoStatus,
    hasConsolidationCalendarNoStatusENES,
  }
) => {
  const COUNT_ROWS = 31; // 32 rows per table
  const headerStyle =
    'color: #000000; background-color: #ffffff; font-family: Helvetica; font-size: 10pt; font-weight: 700; padding-top: 5px; padding-bottom: 5px; text-align: center; border: 1px solid #000000;';
  const cellStyle =
    'color: #000000; background-color: #ffffff; font-family: Helvetica; font-size: 10pt; padding-top: 3px; padding-bottom: 3px; text-align: center; border: 1px solid #000000;';

  const header = `<div style="text-align: center; font-family: Helvetica; font-size: 12pt; font-weight: 700; margin-bottom: 10px; color: #000000;">Anexo - Calendario de consolidación
  </div>`;

  const resultTables = [];

  let htmlTable = '';

  if (hasConsolidationCalendar) {
    htmlTable = `<table class="table table-hover">
    <colgroup>
      <col width="25%">
      <col width="25%">
      <col width="25%">
      <col width="25%">
    </colgroup>
    <thead >
      <tr>
        <th style="${headerStyle}">${i18n.t('Date')}</th>
        <th style="${headerStyle}">${i18n.t('VestedUnits')}</th>
        <th style="${headerStyle}">${i18n.t('TotalVestedUnits')}</th>
        <th style="${headerStyle}">${i18n.t('Status')}</th>
      </tr>
    </thead>
    <tbody>`;
  } else if (hasConsolidationCalendarNoStatus) {
    htmlTable = `<table class="table table-hover">
    <colgroup>
      <col width="25%">
      <col width="25%">
      <col width="25%">
    </colgroup>
    <thead >
      <tr>
        <th style="${headerStyle}">${i18n.t('Date')}</th>
        <th style="${headerStyle}">${i18n.t('VestedUnits')}</th>
        <th style="${headerStyle}">${i18n.t('TotalVestedUnits')}</th>
      </tr>
    </thead>
    <tbody>`;
  } else if (hasConsolidationCalendarNoStatusENES) {
    htmlTable = `<table class="table table-hover">
    <colgroup>
      <col width="25%">
      <col width="25%">
      <col width="25%">
    </colgroup>
    <thead >
      <tr>
        <th style="${headerStyle}">${i18n.t('Date', { lng: 'en' })} / ${i18n.t(
      'Date',
      { lng: 'es' }
    )}</th>
        <th style="${headerStyle}">${i18n.t('UnitsToVesting', {
      lng: 'en',
    })} / ${i18n.t('UnitsToVesting', { lng: 'es' })}</th>
        <th style="${headerStyle}">${i18n.t('TotalVestedUnits', {
      lng: 'en',
    })} / ${i18n.t('TotalVestedUnits', { lng: 'es' })}</th>
      </tr>
    </thead>
    <tbody>`;
  }

  // Create tables with 32 rows
  for (let i = 0; i < consolidationCalendar?.length; i++) {
    htmlTable += `<tr>
        <td style="${cellStyle}">${format(
      new Date(consolidationCalendar[i].date),
      dateFormat
    )}</td>
        <td style="${cellStyle}">${consolidationCalendar[i].vestedUnits}</td>
        <td style="${cellStyle}">${
      consolidationCalendar[i].totalVestedUnits
    }</td>`;

    if (hasConsolidationCalendar) {
      htmlTable += `<td style="${cellStyle}">${consolidationCalendar[i].status}</td></tr>`;
    } else if (hasConsolidationCalendarNoStatus) {
      htmlTable += '</tr>';
    } else if (hasConsolidationCalendarNoStatusENES) {
      htmlTable += '</tr>';
    }

    if (
      (i % COUNT_ROWS === 0 && i !== 0) ||
      i === consolidationCalendar.length - 1
    ) {
      htmlTable += '</tbody></table>';
      resultTables.push(header + htmlTable);
      htmlTable = `<table class="table table-hover">
        <colgroup>
          <col width="25%">
          <col width="25%">
          <col width="25%">`;

      if (hasConsolidationCalendar) {
        htmlTable += `<col width="25%">
        </colgroup>
        <tbody>`;
      } else if (hasConsolidationCalendarNoStatus) {
        htmlTable += `</colgroup>
        <tbody>`;
      } else if (hasConsolidationCalendarNoStatusENES) {
        htmlTable += `</colgroup>
        <tbody>`;
      }
    }
  }
  return resultTables;
};

export const generateCalendarPlanTableDivs = (consolidationCalendar) => {
  let htmlTable = `<p style="font-family: Helvetica;">
  <span style="width: 150px; border: solid 1px #000000; padding: 5px 10px; margin: 0;">Fecha</span>
  <span style="width: 150px; border: solid 1px #000000; padding: 5px 10px; margin: 0;">Unidades Vesteadas</span>
  <span style="width: 150px; border: solid 1px #000000; padding: 5px 10px; margin: 0;">Total Unidades Vesteadas</span>
  <span style="width: 150px; border: solid 1px #000000; padding: 5px 10px; margin: 0;">Estado</span>
  </p>`;

  consolidationCalendar.forEach((row) => {
    htmlTable += `
    <p style="font-family: Helvetica;">
<span style="width: 150px;">${format(new Date(row.date), dateFormat)}</span>
<span style="width: 150px;">${row.vestedUnits}</span>
<span style="width: 150px;">${row.totalVestedUnits}</span>
<span style="width: 150px;">${row.status}</span>
</p>`;
  });

  htmlTable += '</div>';

  return htmlTable;
};

export const vestingUnitsNotificationForSharing = (data) => {
  const { vestingDate, vestingUnits, vestingType, societyName, societyLogo } =
    data;

  const html = `
  <div id="vesting-notification-hidden-box" style="padding: 50px; background: url(${backgroundConfetti}); background-size: contain; margin: auto;">
    <div aria-labelledby="swal2-title" aria-describedby="swal2-content" class="" tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true" style="display: flex; box-shadow: 0 0 16px -2px rgba(60, 77, 98, 0.4); padding-bottom: 10px; border-radius: 10px; position: relative; box-sizing: border-box; flex-direction: column; justify-content: center; width: 32em; max-width: 100%; padding: 1.25em; border: none; border-radius: 12px; background: #fff; font-family: inherit; font-size: 1rem;">

    <div style="display: flex; flex-direction: column; align-items: center; padding: 0 1.8em;">
      <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;"><div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);"></div>
        <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
        <div class="swal2-success-ring"></div> <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);"></div>
      </div>

      <h2 class="" id="swal2-title" style="display: block; position: relative; max-width: 100%; margin: 0 0 0.4em; margin-bottom: 20px; padding: 0; font-size: 1.875em; font-weight: 600; text-align: center; text-transform: none; word-wrap: break-word;">¡Felicidades!</h2>
    </div>
    <div class="">
      <div id="swal2-content" class="" style="display: block; font-size: 1.125em; font-weight: 400; line-height: normal; text-align: center; word-wrap: break-word; line-height: 1.5em;">El día <b>${vestingDate}</b> vesteaste <b>${vestingUnits}</b> unidades de ${vestingType} de la Sociedad   <b>${societyName}</b>.
      </div>
      <div class="" style="display: flex; z-index: 1; box-sizing: border-box; flex-wrap: wrap; align-items: center; justify-content: center; width: 100%; margin: 1.25em auto 0; padding: 0;">
        <img src=${societyLogo} style="display: inline-block; max-height: 80px; margin-top: 20px; margin-bottom: 20px;" />
      </div>
      <div class="">
        <div class="swal2-timer-progress-bar" style="display: none;"></div>
      </div>
      <span style="display: block; font-size: 12px; text-align: center;">powered by <b class="text-primary">Sttok</b></span>
      </div>
    </div>
  </div>`;
  return html;
};
