import React, { FC } from 'react';

import { Envelope } from 'types';

import CustomLoading from 'modules/_shared/components/CustomLoading';

import signatureTypes from 'constants/signatureTypes';
import SignatureView from './SignatureView';
import useGetSignersFromType from '../hooks/useGetSignersFromType';
import useGetEnvelopeFromType from '../hooks/useGetEnvelopeFromType';

type Props = {
  boardEnvelopes: Envelope[];
};

const SignatureViewCertificate: FC<Props> = ({ boardEnvelopes }) => {
  const { signers, isLoading, refetchSigners } = useGetSignersFromType(
    boardEnvelopes,
    signatureTypes.BOARD_CERTIFICATE
  );

  const { envelope } = useGetEnvelopeFromType(
    boardEnvelopes,
    signatureTypes.BOARD_CERTIFICATE
  );

  const sentDate = envelope ? new Date(envelope.creationDate) : undefined;

  return (
    <>
      <SignatureView
        signers={signers}
        sentDate={sentDate}
        refetchSigners={refetchSigners}
      />
      {isLoading && <CustomLoading />}
    </>
  );
};

export default SignatureViewCertificate;
