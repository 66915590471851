/* eslint-disable react/jsx-props-no-spreading */
import {
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import React from 'react';
import NumberFormat from 'react-number-format';

function DraftSellRow({ print, index, draft }) {
  const {
    name,
    email,
    shares,
    NDPercent,
    FDPercent,
    NDValue,
    FDValue,
    remainingShares,
    remainingNDPercent,
  } = draft;

  const [firstName, lastName] = name?.split(' ');

  return (
    <>
      <tr className="nk-tb-item">
        <td className="nk-tb-col">
          <span className="tb-amount">{index}</span>
        </td>
        <td className="nk-tb-col">
          <div className="user-card">
            {!print && (
              <div className="user-avatar bg-dim-primary d-none d-sm-flex">
                <span>
                  {`${firstName && firstName[0]}${lastName && lastName[0]}`}
                </span>
              </div>
            )}
            <div className="user-info">
              <span className="tb-lead">
                {name}
                <span className="dot dot-success d-md-none ml-1" />
              </span>
              {!print && <span>{email}</span>}
            </div>
          </div>
        </td>
        {print && (
          <td className="nk-tb-col">
            <span>{email}</span>
          </td>
        )}
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            <NumberFormat value={shares} displayType="text" {...numberFormat} />
          </span>
        </td>
        <td className="nk-tb-col tb-col-md">
          <span className="tb-amount">
            <NumberFormat
              value={NDPercent}
              displayType="text"
              suffix="%"
              {...numberFormatDecimals}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            <NumberFormat
              value={FDPercent}
              displayType="text"
              suffix="%"
              {...numberFormatDecimals}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            <NumberFormat
              value={NDValue}
              displayType="text"
              {...currencyFormatDecimals}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            <NumberFormat
              value={FDValue}
              displayType="text"
              {...currencyFormatDecimals}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            <NumberFormat
              value={remainingShares}
              displayType="text"
              {...numberFormat}
            />
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            <NumberFormat
              value={remainingNDPercent}
              displayType="text"
              suffix="%"
              {...numberFormatDecimals}
            />
          </span>
        </td>
      </tr>
    </>
  );
}

export default DraftSellRow;
