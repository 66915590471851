/* eslint-disable no-console */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-bind */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import { addPartner, constitution } from 'modules/_shared/redux/modalsActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import {
  createSociety as createSocietyAction,
  getSocietyById,
} from 'modules/society/redux/societyActions';

import eventTypes from 'constants/eventTypes';
import documentTypes from 'constants/documentTypes';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import { cleanValue } from 'constants/formats';

import Swal from 'sweetalert2';
import errorSwal from 'utils/errorSwal';
import trackEvent from 'utils/trackEvent';
import successSwal from 'utils/successSwal';
import normalizeCif from 'utils/normalizeCif';
import { generateEmail } from 'utils/filters';
import normalizeEmail from 'utils/normalizeEmail';

import ConstitutionAiModalHeader from './components/ConstitutionAiModalHeader';
import ConstitutionAiModalBody from './components/ConstitutionAiModalBody';
import ConstitutionAiModalFooter from './components/ConstitutionAiModalFooter';

import CustomLoading from '../../CustomLoading';
import useGetData from './hooks/useGetPartnerData';
import { scanFile } from './utils/scanFile';

import './Constitution.scss';

const fileTypes = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

const ConstitutionAI = ({ file }) => {
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const holdingClasses = useSelector((state) => state.holdingClasses);
  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [extractedSocietyData, setExtractedSocietyData] = useState(null);
  const [extractedConstitutionData, setExtractedConstitutionData] =
    useState(null);
  const [extractedPartnerData, setExtractedPartnerData] = useState(null);

  const getDataResult = useGetData({
    holdingClasses,
    extractedSocietyData,
    extractedConstitutionData,
    extractedPartnerData,
  });

  const { societyData, constitutionData, partnersData } = getDataResult.data;
  const { setSocietyData, setConstitutionData, setPartnersData } =
    getDataResult.actions;

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  const createSociety = async () => {
    const emptyValue = '';

    try {
      const name = societyData?.['Society Name'];
      const cif = societyData?.['Society Fiscal Identification Number'];
      const legalAddress = {
        line1: societyData?.['Society Address'] ?? emptyValue,
      };

      if (!name || !cif) {
        throw new Error('Error creating society');
      }

      const society = {
        name,
        cif,
        legalAddress,
        creationDate: new Date(),
        mainAdmin: user['_id'],
        administrators: [user['_id']],
        sendNotifications: false,
        hasAccessCaptable: false,
      };

      const newSociety = await dispatch(createSocietyAction(society, false));
      return newSociety;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const createRepresentative = (partner) => {
    const emptyValue = '';

    const resutlRepresentative = partner?.['Representative']
      ? {
          name: partner?.['Representative']['Name'] ?? emptyValue,
          email: partner?.['Representative']['Email'] ?? emptyValue,
          initDate:
            partner?.['Representative']['Empowerment Date'] ?? emptyValue,
          nif:
            partner?.['Representative']['Fiscal Identification Number'] ??
            emptyValue,
          nationality: partner?.['Representative']['Nationality'] ?? emptyValue,
          address: partner?.['Representative']['Address'] ?? emptyValue,
          endDate: emptyValue,
          authority: emptyValue,
          status: emptyValue,
        }
      : {};

    return resutlRepresentative;
  };

  const createPartner = async (partner, index, society) => {
    const emptyValue = '';
    const representative = createRepresentative(partner);

    const name = partner['Name'] ?? emptyValue;
    const email = partner?.['Email']
      ? partner['Email']
      : generateEmail(society, index);
    const cif = partner['Fiscal Identification Number'] ?? emptyValue;
    const societyId = society['_id'];
    const userId = user['_id'];
    const nationality = partner?.['Nationality'] ?? emptyValue;
    const address = {
      line1: partner['Address'] ?? emptyValue,
      zip: partner['Postal code'] ?? emptyValue,
      city: partner['City'] ?? emptyValue,
      country: partner['Country'] ?? emptyValue,
    };
    const isNaturalPerson = !partner?.['Representative'];

    const resultPartner = {
      name,
      email: normalizeEmail(email),
      cif: normalizeCif(cif),
      category: null,
      society: societyId,
      user: userId,
      nationality,
      address,
      representative,
      isNaturalPerson,
    };

    return resultPartner;
  };

  async function createPartners(society) {
    // TODO Add cif validations
    for (const partner of partnersData) {
      const index = partnersData.indexOf(partner) + 1;
      const newPartner = await createPartner(partner, index, society);

      await store.dispatch(addPartner(newPartner, false));
    }
  }

  const createMovementsAndTransactions = async (society) => {
    try {
      const movements = [];
      const transactions = [];

      partnersData.map((data) => {
        const currentPartner = society?.partners.find(
          (partner) => partner.cif === data['Fiscal Identification Number']
        );

        if (currentPartner) {
          const newMovement = {
            partner: currentPartner._id,
            shareFrom: data['Shares from'],
            shareTo: data['Shares to'],
            shareClass: holdingClasses?.[0]?.['_id'],
            sharePremium: data['Shares premium'],
          };
          const newTransaction = {
            partner: currentPartner._id,
            shareFrom: data['Shares from'],
            shareTo: data['Shares to'],
            shareClass: holdingClasses?.[0]?.['_id'],
            sharePremium: data['Shares premium'],
            transactionType: transactionTypes.CONSTITUTION,
          };
          movements.push(newMovement);
          transactions.push(newTransaction);
        }
      });

      return { movements, transactions };
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const uploadDocument = async (file, society) => {
    let uploadedDocument = null;
    if (file) {
      const newDocument = {
        file,
        date: societyData['Society Constitution Date'],
        size: file.size,
        name: operationTypes.CONSTITUTION_FILENAME,
        author: user['_id'],
        society: society['_id'],
        fileType: fileTypes[file.type],
        category: documentTypes.SCRIPTURES,
        description: t('DocumentLinkedToOperation'),
      };
      uploadedDocument = await store.dispatch(addDocument(newDocument));
    }
    return uploadedDocument;
  };

  async function handleSaveConstitution() {
    setIsLoading(true);

    try {
      const newSociety = await createSociety();

      await createPartners(newSociety);

      const updatedSociety = await getSocietyById(newSociety['_id']);

      const { movements, transactions } = await createMovementsAndTransactions(
        updatedSociety
      );

      const uploadedDocument = await uploadDocument(file, updatedSociety);

      if (transactions.length > 0) {
        await store.dispatch(
          constitution(
            {
              movements,
              transactions,
              date: societyData['Society Constitution Date'],
              nominalValue: cleanValue(
                constitutionData['Shares nominal value']
              ),
              society: updatedSociety['_id'],
              user: user['_id'],
              documents: uploadedDocument?.['_id']
                ? [uploadedDocument['_id']]
                : [],
            },
            false
          )
        );
        store.dispatch(setModal(null));
        successSwal(t('SuccessfullyIncorporatedMessage'));
      }

      const eventData = {
        operation: eventTypes.ADD_SOCIETY_AI_CONSTITUTION,
        societyName: updatedSociety?.name,
        societyCIF: updatedSociety?.cif,
        notifications: false,
        userId: user['_id'],
        userName: user?.name,
        userEmail: user?.email,
      };

      trackEvent(mixpanel, eventData.operation, eventData);

      history.push(`/socios/${updatedSociety?.['_id']}`);
    } catch (error) {
      errorSwal(t('ErrorIncorporationMessage'));
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeSocietyParam = (field, value) => {
    const newData = { ...societyData };
    newData[field] = value;
    setSocietyData(newData);
  };

  const handleChangeConstitutionParam = (field, value) => {
    const newData = { ...constitutionData };
    newData[field] = value;
    setConstitutionData(newData);
  };

  const handleChangePartnerParam = (
    index,
    field,
    value,
    representative = false
  ) => {
    const newData = [...partnersData];
    if (representative) {
      newData[index]['Representative'][field] = value;
    } else {
      newData[index][field] = value;
    }
    setPartnersData(newData);
  };

  const setExtractedData = async (data) => {
    const { extractedSociety, extractedConstitution, extractedPartners } = data;

    setExtractedSocietyData(extractedSociety);
    setExtractedConstitutionData(extractedConstitution);
    setExtractedPartnerData(extractedPartners);
  };

  async function handleScanFile() {
    try {
      setIsLoading(true);
      setIsFirstLoad(false);

      let resultData;

      if (constitutionData?.length || partnersData?.length) {
        Swal.fire({
          icon: 'info',
          title: `<h4 className="nk-block-title page-title">${t(
            'AreYouSureWantScan'
          )}  `,
          html: `<br /><div class="caption-text">${t('AllDataWillLost')}</div>`,
          confirmButtonText: t('Scan'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            resultData = await scanFile(file);
            setExtractedData(resultData);
          }
        });
      } else {
        resultData = await scanFile(file);
        setExtractedData(resultData);
      }
    } catch (error) {
      errorSwal(t('ErrorScanningFile'));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <ConstitutionAiModalHeader handleCloseModal={handleCloseModal} />

      <ConstitutionAiModalBody
        file={file}
        isLoading={isLoading}
        isFirstLoad={isFirstLoad}
        societyData={societyData}
        constitutionData={constitutionData}
        partnersData={partnersData}
        holdingClasses={holdingClasses}
        handleScanFile={handleScanFile}
        handleChangeSocietyParam={handleChangeSocietyParam}
        handleChangeConstitutionParam={handleChangeConstitutionParam}
        handleChangePartnerParam={handleChangePartnerParam}
      />

      <ConstitutionAiModalFooter
        societyData={societyData}
        constitutionData={constitutionData}
        partnersData={partnersData}
        handleSaveConstitution={handleSaveConstitution}
      />

      {isLoading && <CustomLoading />}
    </>
  );
};

export default ConstitutionAI;
