import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { HoldingClass } from 'types';
import { generateEmail } from 'utils/filters';

type Props = {
  holdingClasses: any;
  extractedSocietyData: any;
  extractedPartnerData: any;
  extractedConstitutionData: any;
};

function findHoldingClass(partner: any, holdingClasses: HoldingClass[]) {
  const resultClass = holdingClasses?.find(
    (holdingClass: HoldingClass) =>
      holdingClass?.name?.toUpperCase() ===
      partner['Shares class']?.toUpperCase()
  );

  return resultClass ? resultClass['_id'] : holdingClasses?.[0]?.['_id'];
}

function getSocietyDataForPartners(societyData: any) {
  const suffix = format(new Date(), 'yyyyMMdd.HHmmss');
  const DEFAULT_SOCIETY_NAME = `Society New ${suffix}`;

  const name = societyData?.['Society Name'] || DEFAULT_SOCIETY_NAME;

  return { name };
}

function parsePartnersData(
  extractedPartnerData: any,
  extractedSocietyData: any,
  holdingClasses: HoldingClass[]
) {
  const resultData = [...extractedPartnerData];
  const society = getSocietyDataForPartners(extractedSocietyData);

  extractedPartnerData.forEach((partner: any, index: number) => {
    const holdingClass = findHoldingClass(partner, holdingClasses);

    resultData[index]['Shares class'] = holdingClass;
    resultData[index]['Email'] =
      partner['Email'] || generateEmail(society, index + 1);

    if (typeof partner['Shares premium'] !== 'number') {
      resultData[index]['Shares premium'] = 0;
    }
  });
  return resultData;
}

export default function useGetData({
  holdingClasses,
  extractedSocietyData,
  extractedConstitutionData,
  extractedPartnerData,
}: Props) {
  const [societyData, setSocietyData] = useState<any>();
  const [constitutionData, setConstitutionData] = useState<any>();
  const [partnersData, setPartnersData] = useState<any>();

  useEffect(() => {
    if (extractedSocietyData) {
      const constitutionDate = extractedConstitutionData?.['Constitution date'];
      const resultData = {
        ...extractedSocietyData,
        'Society Constitution Date': constitutionDate,
      };
      setSocietyData({ ...resultData });
    }
  }, [extractedSocietyData, extractedConstitutionData]);

  useEffect(() => {
    if (extractedConstitutionData) {
      setConstitutionData({ ...extractedConstitutionData });
    }
  }, [extractedConstitutionData]);

  useEffect(() => {
    if (extractedPartnerData && holdingClasses) {
      const resultData = parsePartnersData(
        [...extractedPartnerData?.partners],
        extractedSocietyData,
        holdingClasses
      );

      setPartnersData(resultData);
    }
  }, [extractedPartnerData, extractedSocietyData, holdingClasses]);

  return {
    data: { societyData, constitutionData, partnersData },
    isLoading: false,
    isError: false,
    actions: { setSocietyData, setConstitutionData, setPartnersData },
  };
}
