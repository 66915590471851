/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-undef */
import React from 'react';
import { Page, Document, Text, Font, View } from '@react-pdf/renderer';
import { format } from 'date-fns';

import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';

import { dateFormat } from 'constants/formats';
import customStyles from './customStyles';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: `/fonts/Roboto-Regular.ttf`,
    },
    {
      src: `/fonts/Roboto-Bold.ttf`,
      fontWeight: 'bold',
    },
    {
      src: `/fonts/Roboto-Italic.ttf`,
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: `/fonts/Roboto-BoldItalic.ttf`,
      fontWeight: 'bold',
      fontStyle: 'italic',
    },
  ],
});

const columns = [
  'Column 1\n\nPlan Name',
  'Column 2\n\nTotal Phantom Shares awarded under the amended Award Letters',
  'Column 3\n\nAward Date',
  'Column 4\n\nVested Phantom Shares',
  'Column 5\n\nUnvested Phantom Shares',
  'Column 6\n\nStrike Price (per phantom share)',
  'Column 7\n\nTerminated Phantom Shares',
  'Column 8\n\nVested Phantom Shares remaining on the date hereof after cancellation of the Terminated Phantom Shares',
  'Column 9\n\nTotal Strike Price',
  'Column 10\n\nConsideration*',
];

const TenderOfferDynamic = ({ hasTenderOfferSummary, tenderOffer }) => (
  <Document>
    <Page size="A4" style={customStyles.page}>
      {hasTenderOfferSummary && (
        <View style={customStyles.calendar}>
          <Text style={customStyles.calendarTitle}>APPENDIX I</Text>

          <Table data={[tenderOffer]}>
            <TableHeader textAlign={'center'}>
              <TableCell style={customStyles.calendarHeader}>
                {columns[0]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[1]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[2]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[3]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[4]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[5]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[6]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[7]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[8]}
              </TableCell>
              <TableCell style={customStyles.calendarHeader}>
                {columns[9]}
              </TableCell>
            </TableHeader>
            <TableBody>
              <DataTableCell
                textAlign={'left'}
                style={customStyles.calendarCell}
                getContent={(r) => r.planName}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.planTotalUnits}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) =>
                  format(new Date(r.planGrantDate), dateFormat)
                }
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.vestedPhantoms}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.unVestedPhantoms}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.planStrikePrice}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.soldUnits}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.remainingVestedPhantoms}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.totalStrikePrice}
              />
              <DataTableCell
                textAlign={'center'}
                style={customStyles.calendarCell}
                getContent={(r) => r.totalPrice}
              />
            </TableBody>
          </Table>
        </View>
      )}
    </Page>
  </Document>
);

export default TenderOfferDynamic;
