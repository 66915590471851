/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line no-use-before-define
import React, { FC, useEffect, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Board } from 'types';
import { getOrdersRows } from './utils/getOrdersRows';

type BoardOrdersTableProps = {
  board: Board;
  setSelectedOrder: any;
  ordersVotesData: any;
};

const BoardOrdersTable: FC<BoardOrdersTableProps> = ({
  board,
  setSelectedOrder,
  ordersVotesData,
}) => {
  const { t } = useTranslate();

  const [rows, setRows] = useState<React.JSX.Element[]>([]);

  useEffect(() => {
    const rowsData = getOrdersRows({
      board,
      ordersVotesData,
      setSelectedOrder,
    });
    setRows(rowsData);
  }, [board, ordersVotesData]); // eslint-disable-line

  return (
    <table
      className="nk-tb-list is-loose traffic-channel-table"
      id="orders-table"
    >
      <thead>
        <tr className="nk-tb-item nk-tb-head">
          <th className="nk-tb-col">
            <span>{t('Subject')}</span>
          </th>
          <th className="nk-tb-col tb-col-xl text-center">
            <span>{t('Yes')}</span>
          </th>
          <th className="nk-tb-col tb-col-xl text-center">
            <span>{t('Nope')}</span>
          </th>
          <th className="nk-tb-col tb-col-xl text-center">
            <span>{t('Abstention')}</span>
          </th>
          <th className="nk-tb-col tb-col-md text-center">
            <span>{t('Participation')}</span>
          </th>
          <th className="nk-tb-col text-center">
            <span>{t('Result')}</span>
          </th>
        </tr>
      </thead>

      <tbody>{rows}</tbody>
    </table>
  );
};

export default BoardOrdersTable;
