export default {
  name: 'John Doe',
  jobTitle: 'Marketing Department',
  logo: 'https://www.pldspace.com/images/estructura/logo.svg',
  website: 'www.website.com',
  phone: '(+34) 654 654 654',
  address: 'C/ Street, 123, 45678 City, Country',
  footer:
    'CONFIDENTIALITY: This email is confidential and for the exclusive use of its addressee.',
};
