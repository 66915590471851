import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

const PartnersPreviewTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <thead>
      <tr className="tb-tnx-head">
        <th className="nk-tb-col p-3">
          <span>Nº</span>
        </th>
        <th className="nk-tb-col p-3">
          <span>{t('Name')}</span>
        </th>
        <th className="nk-tb-col p-3">
          <span>{t('E-Mail')}</span>
        </th>
        <th className="nk-tb-col p-0" />
        <th className="nk-tb-col p-3">
          <span>{t('CIF')}</span>
        </th>
        <th className="nk-tb-col text-right p-3">
          <span>{t('Shares')}</span>
        </th>
      </tr>
    </thead>
  );
};

export default PartnersPreviewTableHeader;
