import i18n from 'i18n/config';

export default {
  NOT_SENT: {
    value: 'NOT_SENT',
    text: i18n.t('NotSent'),
    color: 'warning',
  },
  PENDING: {
    value: 'PENDING',
    text: i18n.t('PendingAccept'),
    color: 'secondary',
  },
  ACCEPTED: {
    value: 'ACCEPTED',
    text: i18n.t('Accepted'),
    color: 'success',
  },
  REJECTED: {
    value: 'REJECTED',
    text: i18n.t('Rejected'),
    color: 'danger',
  },
};
