import axios from 'interceptors/axios';

import { decryptResponse } from 'utils/token';

export const getPartnersPrimaryEmails = async (societyId: string) => {
  const query = { params: { primaryEmails: societyId } };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/partners`,
      query
    );

    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    throw new Error();
  }
};
