function downloadOptions(i18n) {
  return [
    // {
    //   icon: 'ni-info',
    //   text: i18n.t('PartnerBookEquity'),
    //   action: ({ handleExportTable, buttons }) => {
    //     handleExportTable(buttons.equity);
    //   },
    //   dropdown: true,
    // },
    // {
    //   icon: 'ni-book-read',
    //   text: i18n.t('PartnerBookSummary'),
    //   action: ({ handleExportTable, buttons }) => {
    //     handleExportTable(buttons.summary);
    //   },
    //   dropdown: true,
    // },
    {
      icon: 'ni-book-read',
      text: i18n.t('Table'),
      action: ({ handleExportTable, buttons }) => {
        handleExportTable(buttons.original);
      },
      dropdown: true,
    },
  ];
}
export default downloadOptions;
