import { FC, Fragment, memo } from 'react';

import { useTranslate } from 'hooks/useTranslate';
import { Document } from 'types';

import TransactionDocumentsRow from './TransactionDetailsDocumentsRow';

type TransactionDetailsDocumentsTabProps = {
  documents: Document[];
  setIsDocumentReplaced: (isDocumentReplaced: boolean) => void; // eslint-disable-line
};

const TransactionDetailsDocumentsTab: FC<
  TransactionDetailsDocumentsTabProps
> = ({ documents, setIsDocumentReplaced }) => {
  const { t } = useTranslate();

  const getKey = (document: Document, index: number) =>
    `${document.name}-${index}`;

  return (
    <>
      <div className="card-borderer col-12 pt-2">
        <div className="nk-files nk-files-view-list">
          <div className="nk-files-head">
            <div className="nk-file-item">
              <div className="nk-file-info">
                <div className="tb-head ml-4">{t('Name')}</div>
              </div>

              <div className="nk-file-meta d-none d-xl-table-cell text-center">
                <div className="tb-head">{t('DocumentDate')}</div>
              </div>

              <div className="nk-file-meta d-none d-xl-table-cell text-center">
                <div className="tb-head">{t('Author')}</div>
              </div>
            </div>
          </div>

          <div className="nk-files-list">
            {documents?.map((document, index) => (
              <Fragment key={getKey(document, index)}>
                <TransactionDocumentsRow
                  index={index}
                  document={document}
                  setIsDocumentReplaced={setIsDocumentReplaced}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(TransactionDetailsDocumentsTab);
