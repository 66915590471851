function societyOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('SeePartners'),
      url: '/socios',
    },
  ];
}

export default societyOptions;
