/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import { differenceInCalendarDays, format } from 'date-fns';

import screens from 'constants/screens';
import menuTypes from 'constants/menuTypes';
import planTypes from 'constants/planTypes';
import eventTypes from 'constants/eventTypes';
import { dateFormat } from 'constants/formats';
import {
  BLANK_COMPANY_LOGO,
  DAYS_FOR_DISPLAY_VESTING_MESSAGE,
} from 'constants/defaultConstants';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getBeneficiaryData } from 'modules/beneficiaries/redux/beneficiaryActions';
import { setSociety } from 'modules/society/redux/societyActions';

import trackEvent from 'utils/trackEvent';
import { vestingUnitsNotificationForSharing } from 'utils/htmlTemplates';
import {
  copyImageToClipBoardOtherBrowsers,
  copyImageToClipBoardSafari,
} from 'utils/clipboard';

import BeneficiarySociety from 'modules/beneficiaries/components/BeneficiarySociety';
import BeneficiaryPersonal from 'modules/beneficiaries/components/BeneficiaryPersonal';
import BeneficiaryPlansTab from 'modules/beneficiaries/components/BeneficiaryPlansTab';
import BeneficiaryCalendarTab from 'modules/beneficiaries/components/BeneficiaryCalendarTab';
import BeneficiaryDocuments from 'modules/beneficiaries/components/BeneficiaryDocuments';
import BeneficiaryTenderOffersTab from 'modules/beneficiaries/components/BeneficiaryTenderOffersTab';
import BeneficiaryAside from 'modules/beneficiaries/components/BeneficiaryAside';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import AlertWithConfetti from 'modules/_shared/components/Alert/AlertWithConfetti';

import BeneficiaryDetailsHeader from './components/BeneficiaryDetailsHeader';
import BeneficiaryDetailsTabs from './components/BeneficiaryDetailsTabs';

import './BeneficiaryDetails.scss';
import { getBeneficiaryStatus } from './utils/getBeneficiaryStatus';
import { getVestingData } from './utils/getVestingData';
import { getDocumentsByPlans } from './utils/getDocumentsByPlan';

const screenShowAside = screens.XL;

function BeneficiaryDetails() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const user = useSelector((state) => state?.user);

  const { societyId, beneficiaryId } = useParams();

  const [status, setStatus] = useState();
  const [simulatorValue, setSimulatorValue] = useState();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [beneficiarySocietyData, setBeneficiarySocietyData] = useState(null);

  const [beneficiaryData, setBeneficiaryData] = useState(null);
  const [societyData, setSocietyData] = useState(null);
  const [beneficiariesData, setBeneficiariesData] = useState(null);
  const [planData, setPlanData] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertProps, setAlertProps] = useState({});

  const userId = user['_id'];

  const handleCopyVestingToClipboard = async () => {
    window.focus();
    document.body.focus();

    const element = document.getElementById('vesting-notification-hidden-box');

    if (!element) return;

    const isSafari = /^((?!chrome|android).)*safari/i.test(
      navigator?.userAgent
    );

    const isNotFirefox = navigator.userAgent.indexOf('Firefox') < 0;

    if (isSafari) {
      copyImageToClipBoardSafari(element);
    } else if (isNotFirefox) {
      copyImageToClipBoardOtherBrowsers(element);
    }
  };

  const handleCloseVestingNotification = () => {
    setShowAlert(false);
  };

  const launchConfetti = async () => {
    setTimeout(async () => {
      let vestingUnits = 0;
      let vestingDate = '';

      const urlParams = new URLSearchParams(location.search);

      if (urlParams.size > 0) {
        vestingDate = urlParams.get('date');
        vestingUnits = urlParams.get('vested');
      } else {
        const vestingData = getVestingData({
          beneficiaryData,
          planData,
        });

        vestingUnits = vestingData?.vestingUnits;
        vestingDate = vestingData?.vestingDate;
      }

      const formattedDate = format(new Date(vestingDate), dateFormat);
      const formattedToday = format(new Date(), dateFormat);

      const planId = planData?._id;

      let vestingNotifications =
        JSON.parse(localStorage.getItem('vestingNotifications')) || {};

      if (Array.isArray(vestingNotifications)) {
        vestingNotifications = {};
      }

      const hasAlreadyNotified =
        vestingNotifications?.[planId] === formattedDate;

      if (hasAlreadyNotified) {
        return;
      }

      const newNotification = { [planId]: formattedDate };

      localStorage.setItem(
        'vestingNotifications',
        JSON.stringify({
          ...vestingNotifications,
          ...newNotification,
        })
      );

      const isVestingToday = !!(formattedDate === formattedToday);

      if (!isVestingToday) {
        const diffDays = differenceInCalendarDays(
          new Date(),
          new Date(vestingDate)
        );

        if (diffDays > DAYS_FOR_DISPLAY_VESTING_MESSAGE) {
          return;
        }
      }

      const societyName = societyData.name;
      const unitsType = planTypes[beneficiaryData.planType]?.label;

      setAlertProps({
        isVestingToday,
        vestingDate: formattedDate,
        vestingUnits: vestingUnits || 0,
        vestingType: unitsType || '',
        vestingSociety: societyName || '',
        onShare: handleCopyVestingToClipboard,
        onClose: handleCloseVestingNotification,
      });

      const currentEvent = eventTypes.VESTING_UNITS_NOTIFICATION;
      trackEvent(mixpanel, currentEvent, {
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?._id,
        societyName: actualSociety?.name,
        operation: currentEvent,
        vestingDate: formattedDate,
        vestedUnits: vestingUnits,
      });
    }, 100);
  };

  const handleGoBack = () => {
    if (actualSociety) {
      history.goBack();
    } else {
      history.push('/lista-beneficiadas');
    }
  };

  useEffect(() => {
    if (alertProps?.vestingDate) {
      setShowAlert(true);
    }
  }, [alertProps]);

  useEffect(() => {
    if (beneficiaryData && planData) {
      const incorporationDate = beneficiaryData?.planStartDate
        ? format(new Date(beneficiaryData?.planStartDate), dateFormat)
        : '';

      const beneficiaryStatus = getBeneficiaryStatus(beneficiaryData);
      setStatus(beneficiaryStatus);

      const documentsByPlan = getDocumentsByPlans(beneficiariesData);

      setPages([
        <BeneficiarySociety
          key="BeneficiarySociety"
          beneficiary={beneficiaryData}
          society={societyData}
          beneficiaryAllPlans={beneficiariesData}
          simulatorValue={simulatorValue}
          setPage={setPage}
          setSimulatorValue={setSimulatorValue}
        />,
        <BeneficiaryPersonal
          key="BeneficiaryPersonal"
          beneficiary={beneficiaryData}
          society={societyData}
          incorporationDate={incorporationDate}
          setBeneficiaryData={setBeneficiaryData}
        />,
        <BeneficiaryPlansTab
          key="BeneficiaryPlansTab"
          beneficiary={beneficiaryData}
          society={societyData}
          beneficiaryAllPlans={beneficiariesData}
        />,
        <BeneficiaryCalendarTab
          key="BeneficiaryCalendarsTab"
          society={societyData}
          beneficiaryAllPlans={beneficiariesData}
        />,
        <BeneficiaryDocuments
          key="BeneficiaryDocuments"
          society={societyData}
          beneficiary={beneficiaryData}
          plan={planData}
          documentsByPlan={documentsByPlan}
        />,
        <BeneficiaryTenderOffersTab
          key="BeneficiaryTendersTab"
          society={societyData}
          beneficiaryAllPlans={beneficiariesData}
        />,
      ]);
    }
  }, [
    beneficiaryData,
    societyData,
    beneficiariesData,
    planData,
    simulatorValue,
  ]);

  useEffect(() => {
    if (societyData && !actualSociety) {
      const result = {
        society: null,
        role: societyData.role,
        societyId: societyData?.societyId,
      };

      dispatch(setSociety(result, user));

      const societyName = societyData?.name;
      const societyId = societyData?.societyId;

      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId,
          societyName,
        })
      );
    }
  }, [societyData]);

  useEffect(() => {
    if (actualSociety) {
      const societyName = actualSociety?.name;
      const societyId = actualSociety?.['_id'];

      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId,
          societyName,
        })
      );
    }
  }, [actualSociety]);

  const [isCardVisible, setIsCardVisible] = useState(false);
  const handleClick = () => {
    setIsCardVisible(!isCardVisible);
  };

  useEffect(() => {
    if (societyData && beneficiaryData) {
      launchConfetti();
    }
  }, [societyData, beneficiaryData]);

  useEffect(() => {
    if (beneficiaryId && societyId) {
      getBeneficiaryData(
        { beneficiaryId, societyId, userId, source: 'society' },
        setBeneficiarySocietyData
      );
    }
  }, [beneficiaryId, societyId, actualSociety?.beneficiaries]);

  useEffect(() => {
    if (beneficiarySocietyData) {
      setBeneficiaryData(beneficiarySocietyData?.beneficiary);
      setSocietyData(beneficiarySocietyData?.society);
      setBeneficiariesData(beneficiarySocietyData?.beneficiariesData);
      setPlanData(beneficiarySocietyData?.planData);
    }
  }, [beneficiarySocietyData]);

  return beneficiaryData ? (
    <div className="nk-content-body">
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">{societyData?.name}</h3>
      </div>
      <div className="nk-content-wrap">
        <BeneficiaryDetailsHeader
          data={{ beneficiaryData, status }}
          actions={{ handleGoBack }}
        />

        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content w-100">
                <BeneficiaryDetailsTabs
                  data={{ page, screenShowAside, isCardVisible }}
                  actions={{ setPage, setIsCardVisible }}
                />

                <div style={{ overflow: 'hidden', height: 0 }}>
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: vestingUnitsNotificationForSharing({
                        vestingDate: alertProps?.vestingDate || '',
                        vestingUnits: alertProps?.vestingUnits || 0,
                        vestingType: alertProps?.vestingType || '',
                        societyName: societyData?.name || '',
                        societyLogo:
                          societyData?.additional?.logo || BLANK_COMPANY_LOGO,
                      }),
                    }}
                  />
                </div>

                {pages[page]}
              </div>

              <div
                className={
                  isCardVisible
                    ? `card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-${screenShowAside} toggle-screen-${screenShowAside} content-active`
                    : `card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-${screenShowAside} toggle-screen-${screenShowAside}`
                }
                data-content="userAside"
                data-toggle-screen={screenShowAside}
                data-toggle-overlay="true"
                data-toggle-body="true"
              >
                <div className="card-inner-group">
                  <div className="simplebar-wrapper">
                    <div className="simplebar-height-auto-observer-wrapper">
                      <div className="simplebar-height-auto-observer" />
                    </div>
                    <div className="simplebar-mask">
                      <div className="simplebar-offset">
                        <div className="simplebar-content-wrapper">
                          <div className="simplebar-content">
                            <ul>
                              <li
                                className={`nav-item nav-item-trigger d-${screenShowAside}-none m-4`}
                              >
                                <p
                                  className="toggle btn btn-icon btn-trigger"
                                  data-target="userAside"
                                  onClick={handleClick}
                                >
                                  <em className="icon ni ni-cross" />
                                </p>
                              </li>
                            </ul>
                            <BeneficiaryAside
                              beneficiary={beneficiaryData}
                              beneficiaryAllPlans={beneficiariesData}
                              society={societyData}
                              setSimulatorValue={setSimulatorValue}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simplebar-placeholder" />
                </div>
                <div className="simplebar-track simplebar-horizontal">
                  <div className="simplebar-scrollbar" />
                </div>
                <div className="simplebar-track simplebar-vertical">
                  <div className="simplebar-scrollbar" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showAlert && <AlertWithConfetti data={alertProps} />}
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
}

export default BeneficiaryDetails;
