import { useTranslate } from 'hooks/useTranslate';
import { FC, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import sizes from 'constants/sizes';
import { getActualSociety } from 'utils/filters';

import Inbox from 'modules/communications/components/Inbox';
import Sidenav from 'modules/communications/components/Sidenav';
import SendEmail from 'modules/_shared/components/Modals/SendEmail';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import InboxDetails from 'modules/communications/components/Inbox/components/InboxDetails';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import CommunicationSettings from 'modules/communications/components/components/CommunicationSettings';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { useGetCommunicationsSectionPermissions } from 'modules/communications/hooks/useGetCommunicationSectionPermissions';
import { State } from 'redux/initialState';
import { getCommEmails } from 'modules/communications/redux/communicationActions';
import useGetActivePage from './hooks/useGetActivePage';
import useGetCurrentCommunication from './hooks/useGetCurrentCommunication';

const Communications: FC = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, communicationId } = useParams();

  const { isAdmin, isReadOnly } = useGetCommunicationsSectionPermissions().data;
  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const communications = useSelector((state: State) => state.communications);

  const pageResult = useGetActivePage({ communicationId });

  const { activePage } = pageResult.data;
  const { handleActiveChangePage } = pageResult.actions;

  const { currentCommunication } = useGetCurrentCommunication({
    communicationId,
    communications,
  }).data;

  const communicationPages = [
    <Inbox communications={communications || []} />,
    <InboxDetails communication={currentCommunication} />,
    <CommunicationSettings society={actualSociety} />,
  ];

  const handleOpenNewCommsModal = () => {
    if (isReadOnly) return;
    // @ts-ignore
    dispatch(setModal(<SendEmail size={sizes.XL} />));
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (societyId) {
      dispatch(getDocuments({ society: societyId }));
      dispatch(getCommEmails(societyId));
    }
  }, [societyId]); // eslint-disable-line

  return (
    <div className="nk-content-body">
      <PageListHeader
        title={actualSociety?.name}
        subTitle={t('Communications')}
        actions={[
          <GoBackButton />,
          isAdmin && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleOpenNewCommsModal}
              disabled={isReadOnly}
            >
              {t('NewCommunication')}
            </button>
          ),
        ]}
      />

      <div className="nk-content-wrap">
        <div className="nk-ibx">
          <Sidenav
            activePage={activePage}
            onChangePage={handleActiveChangePage}
            societyId={societyId}
            communications={communications}
          />

          <div className="nk-ibx-body bg-white">
            {communicationPages[activePage]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Communications);
