import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Partner } from 'types';

import { format } from 'date-fns';

import { EMAIL_PATTERN } from 'constants/defaultConstants';
import { dateFormatInverted } from 'constants/formats';

import InputError from 'modules/_shared/components/InputError';
import isValidEmail from 'utils/isValidEmail';
import { useGetRepresentativeOptions } from '../hooks/useGetRepresentativeOptions';
import { useGetRepresentativeSelectedOptions } from '../hooks/useGetRepresentativeSelectedOptions';

type Props = {
  partner: Partner;
  handleChangePartnerData: any;
};

const LegalPersonRepresentantView: FC<Props> = ({
  partner,
  handleChangePartnerData,
}) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const { authorityOptions, statusOptions } =
    useGetRepresentativeOptions().data;

  const selectedOptionsResult = useGetRepresentativeSelectedOptions({
    currentPartner: partner,
  });

  const { selectedAuthorityOption, selectedStatusOption } =
    selectedOptionsResult.data;

  const { setSelectedAuthorityOption, setSelectedStatusOption } =
    selectedOptionsResult.actions;

  const [name, setName] = useState<string>(partner?.representative?.name || '');
  const [email, setEmail] = useState<string>(
    partner?.representative?.email || ''
  );
  const [initDate, setInitDate] = useState<string>(
    partner?.representative?.initDate
      ? format(new Date(partner?.representative?.initDate), dateFormatInverted)
      : ''
  );
  const [endDate, setEndDate] = useState<string>(
    partner?.representative?.endDate
      ? format(new Date(partner?.representative?.endDate), dateFormatInverted)
      : ''
  );
  const [nif, setNif] = useState<string>(partner?.representative?.nif || '');
  const [nationality, setNationality] = useState<string>(
    partner?.representative?.nationality || ''
  );
  const [address, setAddress] = useState<string>(
    partner?.representative?.address || ''
  );

  const handleChangeName = (event: any) => {
    const { value } = event.target;
    setName(event.target.value);
    handleChangePartnerData({
      ...partner,
      representative: { ...partner?.representative, name: value },
    });
  };

  const handleChangeEmail = (event: any) => {
    const { value } = event.target;
    setEmail(value);
    handleChangePartnerData({
      ...partner,
      representative: { ...partner?.representative, email: value },
    });
  };

  const handleChangeInitDate = (event: any) => {
    const { value } = event.target;
    setInitDate(value);
    handleChangePartnerData({
      ...partner,
      representative: { ...partner?.representative, initDate: value },
    });
  };

  const handleChangeEndDate = (event: any) => {
    const { value } = event.target;
    setEndDate(value);
    handleChangePartnerData({
      ...partner,
      representative: { ...partner?.representative, endDate: value },
    });
  };

  const handleChangeAuthority = (selectedOption: any) => {
    setSelectedAuthorityOption(selectedOption);
    handleChangePartnerData({
      ...partner,
      representative: {
        ...partner?.representative,
        authority: selectedOption?.value,
      },
    });
  };

  const handleChangeStatus = (selectedOption: any) => {
    setSelectedStatusOption(selectedOption);
    handleChangePartnerData({
      ...partner,
      representative: {
        ...partner?.representative,
        status: selectedOption?.value,
      },
    });
  };

  const handleChangeNif = (event: any) => {
    const { value } = event.target;
    setNif(value);
    handleChangePartnerData({
      ...partner,
      representative: { ...partner?.representative, nif: value },
    });
  };

  const handleChangeNationality = (event: any) => {
    const { value } = event.target;
    setNationality(value);
    handleChangePartnerData({
      ...partner,
      representative: { ...partner?.representative, nationality: value },
    });
  };

  const handleChangeAddress = (event: any) => {
    const { value } = event.target;
    setAddress(value);
    handleChangePartnerData({
      ...partner,
      representative: { ...partner?.representative, address: value },
    });
  };

  return (
    <>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="representative-name">
                {t('NameAndSurname')}
              </label>
              <input
                id="representative-name"
                type="text"
                className="form-control form-control-lg"
                value={name}
                onChange={handleChangeName}
                placeholder={t('NameAndSurname')}
                required
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="representative-email">
                {t('Email')}
              </label>
              <input
                id="representative-email"
                type="email"
                className="form-control form-control-lg"
                value={email}
                onChange={handleChangeEmail}
                placeholder={t('Email')}
                pattern={EMAIL_PATTERN}
              />
              {email && !isValidEmail(email) && (
                <InputError errorMessage={t('InvalidEmail')} />
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="representative-initDate">
                {t('RepresentativeDate')}
              </label>
              <input
                id="representative-initDate"
                type="date"
                className="form-control form-control-lg date-picker"
                value={initDate}
                onChange={handleChangeInitDate}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <div className="d-flex">
                <label className="form-label" htmlFor="representative-endDate">
                  {t('RevocationDate')}
                </label>
              </div>
              <input
                id="representative-endDate"
                type="date"
                className="form-control form-control-lg date-picker"
                value={endDate}
                onChange={handleChangeEndDate}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="representative-authority">
                {t('TypeOfAuthority')}
              </label>
              <div id="representative-authority" className="form-control-wrap ">
                <Select
                  closeMenuOnSelect
                  className="react-select react-select-lg"
                  value={selectedAuthorityOption}
                  options={authorityOptions}
                  components={animatedComponents}
                  onChange={handleChangeAuthority}
                  placeholder={t('SelectAuthority')}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="representative-status">
                {t('Status')}
              </label>
              <div id="representative-status" className="form-control-wrap ">
                <Select
                  closeMenuOnSelect
                  className="react-select react-select-lg"
                  value={selectedStatusOption}
                  options={statusOptions}
                  components={animatedComponents}
                  onChange={handleChangeStatus}
                  placeholder={t('SelectStatus')}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="representative-nif">
                {t('Nif')}
              </label>
              <input
                id="representative-nif"
                type="text"
                className="form-control form-control-lg"
                value={nif}
                onChange={handleChangeNif}
                placeholder={t('Nif')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label
                className="form-label"
                htmlFor="representative-nationality"
              >
                {t('Nationality')}
              </label>
              <input
                id="representative-nationality"
                type="text"
                className="form-control form-control-lg"
                value={nationality}
                onChange={handleChangeNationality}
                placeholder={t('Nationality')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="representative-address">
                {t('Address')}
              </label>
              <input
                id="representative-address"
                type="text"
                className="form-control form-control-lg"
                value={address}
                onChange={handleChangeAddress}
                placeholder={t('Address')}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default LegalPersonRepresentantView;
