import { FC } from 'react';
import { Tooltip } from 'react-tooltip';

type TooltipPlace = 'top' | 'bottom' | 'left' | 'right';

type IconInfoProps = {
  id: string;
  icon?: string;
  className?: string;
  tooltip?: string;
  place?: TooltipPlace;
};

const DEFAULT_ICON = 'info';
const DEFAULT_PLACE = 'top';

const IconInfo: FC<IconInfoProps> = ({
  className,
  icon = DEFAULT_ICON,
  id,
  place = DEFAULT_PLACE,
  tooltip,
}) => {
  const iconClass = `icon ni ni-${icon} text-gray ml-1 cursor-help ${className}`;
  const tooltipClass = 'w-max-300px z-1000';

  return (
    <div>
      <em className={iconClass} data-tooltip-id={id} />

      {tooltip && (
        <Tooltip id={id} className={tooltipClass} place={place}>
          {tooltip}
        </Tooltip>
      )}
    </div>
  );
};

export default IconInfo;
