/* eslint-disable react/jsx-props-no-spreading */
import { State } from 'redux/initialState';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Page404 from 'modules/_shared/pages/404';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';

import { store } from 'redux/store';
import {
  Folder,
  FolderContent,
  Section,
} from 'modules/dashboard/types/CustomDashboard';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import DashboardFolderContentItem from 'modules/dashboard/components/DashboardFolderContentItem';
import foldersContentType from 'modules/dashboard/constants/foldersContentType';
import PDFAdobeViewer from 'modules/_shared/components/PDFAdobeViewer';
import { getDocument } from 'modules/documents/redux/documentActions';
import VimeoVideoPlayer from 'modules/_shared/components/VimeoVideoPlayer';
import { setMenu } from 'modules/_shared/redux/menuActions';
import menuTypes from 'constants/menuTypes';

const CustomDashboardDetails = () => {
  const { societyId, sectionIndex, folderIndex } = useParams();

  const dispatch = useDispatch();
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const customLanding = useSelector(
    (state: State) => state.society?.actualSociety?.configuration?.customLanding
  );
  const [isValidUrl, setIsValidUrl] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [section, setSection] = useState<Section | null>(null);
  const [folder, setFolder] = useState<Folder | null>(null);

  const [selectedContent, setSelectedContent] = useState<any>(null);

  const handleSelectContent = async (item: FolderContent) => {
    try {
      if (item.type === foldersContentType.PDF_EMBEDDED) {
        const format = 'url';
        const url = await store.dispatch(getDocument(item?.documentId, format));
        // TODO: Improve this selected content with url added
        setSelectedContent({ ...item, url });
      }

      if (item.type === foldersContentType.VIDEO_EMBEDDED) {
        setSelectedContent(item);
      }
      setIsError(false);
    } catch (error) {
      setSelectedContent(null);
      console.log('error', error);
      setIsError(true);
    }
  };

  const selectFirstContent = (folder: Folder) => {
    if (folder?.content?.length) {
      handleSelectContent(folder.content[0]);
    }
  };

  useEffect(() => {
    const currentSection = customLanding?.sections?.find(
      (s: Section) => s.index === Number(sectionIndex)
    );
    const currentFolder = currentSection?.folders?.find(
      (f: Folder) => f.index === Number(folderIndex)
    );

    setSection(currentSection);
    setFolder(currentFolder);

    selectFirstContent(currentFolder);

    setIsValidUrl(!!(societyId && currentSection && currentFolder));
    setIsLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  return isValidUrl ? (
    <div className="nk-content-body pld-wrapper pld-wrapper-details">
      <div className="nk-content-wrap">
        <PageListHeader
          title={folder?.title || ''}
          actions={[<GoBackButton icon="ni-chevron-left" />]}
        />

        <div className="nk-files nk-files-view-grid row my-4">
          {folder?.content?.map((item: FolderContent) => (
            <div
              key={String(item.index)}
              className="nk-content-block col-6 col-sm-6 col-md-4 col-xl-4"
              onClick={() => handleSelectContent(item)}
            >
              <DashboardFolderContentItem {...item} />
            </div>
          ))}
        </div>

        {selectedContent?.type === foldersContentType.PDF_EMBEDDED &&
          !isError && (
            <div className="card card-bordered pdf-embedded-box">
              <PDFAdobeViewer
                fileUrl={selectedContent?.url}
                fileName={selectedContent?.title}
              />
            </div>
          )}

        {selectedContent?.type === foldersContentType.VIDEO_EMBEDDED &&
          !isError && (
            <div className="card card-bordered video-embedded-box">
              <VimeoVideoPlayer videoId={selectedContent?.videoId} />
            </div>
          )}
      </div>
    </div>
  ) : (
    <>
      {isLoading && <CustomLoading />}
      {!isLoading && <Page404 />}
    </>
  );
};

export default CustomDashboardDetails;
