import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import types from 'redux/actions/actionTypes';
import { decryptResponse } from 'utils/token';

import { dispatchError } from 'utils/dispatchError';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export function createStockPlan(plan) {
  return {
    type: types.CREATE_STOCK_PLAN,
    plan,
  };
}

export const getStockPlanById = (planId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/stockPlans/${planId}`
    );
    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_STOCK_PLAN,
      plan: decodedData,
    });
    return decodedData;
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const getStockPlans = (societyId) => async (dispatch) => {
  try {
    const query = societyId ? { params: { societyId } } : { params: {} };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/stockPlans`,
      query
    );
    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_STOCK_PLANS,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateStockPlan =
  (stockPlanId, updateStockPlanData, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: stockPlanId,
      ...updateStockPlanData,
    };
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/stockPlans`,
        body
      );
      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.UPDATE_STOCK_PLAN,
        plan: decodedData,
      });
      if (showAlert) dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteStockPlan = (plan) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/stockPlans/${plan}`
    );
    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.DELETE_STOCK_PLAN,
      plan: decodedData,
    });
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    return decodedData['_id'];
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};
