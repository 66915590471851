import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import types from 'redux/actions/actionTypes';

import { dispatchError } from 'utils/dispatchError';
import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export function createBoard(board) {
  return {
    type: types.CREATE_BOARD,
    board,
  };
}

export const getBoards = (societyId) => async (dispatch) => {
  try {
    const query = societyId ? { params: { societyId } } : { params: {} };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/boards`,
      query
    );

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_BOARDS,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const getBoard = (boardId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/boards/${boardId}`
    );

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.GET_BOARD,
      board: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updateBoard =
  (boardId, updateBoardData, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: boardId,
      ...updateBoardData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/boards`,
        body
      );

      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.UPDATE_BOARD,
        board: decodedData,
      });
      if (showAlert) dispatch(addAlert(alertBodyTypes.BOARD_UPDATED));
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteBoard = (boardId) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/boards/${boardId}`
    );

    const decodedData = await decryptResponse(data);
    dispatch({
      type: types.DELETE_BOARD,
      board: decodedData,
    });
    dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
    return decodedData['_id'];
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};
