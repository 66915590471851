import { useEffect, useState } from 'react';

import { User } from 'types';
import isSuperAdmin from 'utils/isSuperAdmin';

import beneficiaryOptions from '../beneficiaryOptions';

export default function useGetBeneficiaryOptions(user: User, i18n: any) {
  const [currentBeneficiaryOptions, setCurrentBeneficiaryOptions] = useState<
    any[]
  >([]);

  useEffect(() => {
    const options = beneficiaryOptions(i18n);

    const filteredOptions = isSuperAdmin(user)
      ? options
      : options?.slice(0, -1);

    setCurrentBeneficiaryOptions(filteredOptions);
  }, [i18n, i18n.language, user]);

  return { currentBeneficiaryOptions };
}
