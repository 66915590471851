const IconSuccess = () => (
  <div className="swal2-icon swal2-success swal2-icon-show d-flex">
    <div className="swal2-success-circular-line-left bg-white" />
    <span className="swal2-success-line-tip" />
    <span className="swal2-success-line-long" />
    <div className="swal2-success-ring" />
    <div className="swal2-success-fix bg-white" />
    <div className="swal2-success-circular-line-right bg-white" />
  </div>
);

export default IconSuccess;
