/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';
import { getActualSociety } from 'utils/filters';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { getBoards } from 'modules/boards/redux/boardActions';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import AddBoard from 'modules/_shared/components/Modals/AddBoard';
import BoardCard from 'modules/boards/components/BoardCard';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';

import { useGetBoardsSectionPermissions } from 'modules/boards/hooks/useGetBoardsSectionPermissions';
import BoardListBanner from '../../components/BoardListBanner';

const BoardsList = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { isAdmin, isReadOnly } = useGetBoardsSectionPermissions().data;

  const user = useSelector((state) => state.user);
  const boards = useSelector((state) =>
    state.boards.sort((a, b) => new Date(b.date) - new Date(a.date))
  );
  const actualSociety = useSelector((state) => state.society.actualSociety);

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety._id,
        societyName: actualSociety.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(societyId));
  };

  const handleAddBoard = () => {
    if (isReadOnly) return;
    dispatch(setModal(<AddBoard />));
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <PageListHeader
          subTitle={t('BoardPartners')}
          actions={[
            isAdmin && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleAddBoard}
                disabled={isReadOnly}
              >
                <span>{t('NewBoardCouncil')}</span>
              </button>
            ),
          ]}
        />

        <div className="nk-content-wrap">
          {boards?.length > 0 &&
            boards.map((board) => <BoardCard key={board._id} board={board} />)}
        </div>

        <div className="nk-block my-5">
          <BoardListBanner />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default BoardsList;
