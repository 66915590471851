/* eslint-disable react/no-danger */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import html2pdf from 'html3pdf';
import { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import { getBoards, updateBoard } from 'modules/boards/redux/boardActions';
import { sendCommEmail } from 'modules/communications/redux/communicationActions';

import { getSingleDocument } from 'utils/downloadDocument';
import { getPartnerActualShares } from 'utils/filters';

import boardStatus from 'constants/boardStatus';
import { formatDateToTime } from 'constants/formats';

import CustomLoading from 'modules/_shared/components/CustomLoading';
import { updateDocumentWithValues } from 'modules/_shared/components/EditorWYSIWYG/helpers';
import tags from 'modules/_shared/components/EditorWYSIWYG/tags';
import useGetAnnouncementData from 'modules/_shared/components/Modals/AnnouncementTemplateViewer/hooks/useGetAnnouncementData';
import SendTestEmail from 'modules/_shared/components/Modals/SendTestEmail';

import {
  createAnnouncementEmailData,
  createAnnouncementPartnerRepresentantsEmailData,
} from 'modules/_shared/utils';
import { getPartnerRepresentantsFromParticipantsList } from 'modules/boards/utils';

import { Board, BoardParticipant, Society } from 'types';

import { hasShowDetails } from 'utils/showDetails';
import EditParticipantModal from '../../pages/BoardDetails/EditParticipantModal';
import ParticipantsTable from './components/ParticipantsTable';
import PartnerRepresentantsTable from './components/PartnerRepresentantsTable';
import SendAnnouncementModalHeader from './components/SendAnnouncementModalHeader';
import { getSocietySecondaryEmails, updateParticipantsEmails } from './utils';

type SendAnnouncementModalProps = {
  currentBoard: Board;
  actualSociety: Society;
  announcementTemplateDoc: any;
  hasAlreadyUpdatedParticipants?: boolean;
};

const SendAnnouncementModal: FC<SendAnnouncementModalProps> = ({
  currentBoard,
  actualSociety,
  announcementTemplateDoc,
  hasAlreadyUpdatedParticipants = false,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );
  const hasWhiteBranding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  const { announcementData } = useGetAnnouncementData(
    currentBoard,
    actualSociety
  );

  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasUpdatedParticipants, setHasUpdatedParticipants] =
    useState<boolean>(false);
  const [hasInvalidEmails, setHasInvalidEmails] = useState<boolean>(false);
  const [otherPartnerEmails, setOtherPartnerEmails] = useState<any>({});

  const isParticipantAllowedForAnnouncement = (
    participant: BoardParticipant
  ): boolean => getPartnerActualShares(participant.member, actualSociety) > 0;

  const getTotalParticipantsAllowed = (): number =>
    currentBoard?.participants?.filter((participant) =>
      isParticipantAllowedForAnnouncement(participant)
    )?.length || 0;

  const getParticipantsWithNullEmails = (): BoardParticipant[] =>
    currentBoard?.participants?.filter(
      (participant) =>
        isParticipantAllowedForAnnouncement(participant) && !participant.email
    ) || [];

  const getInvalidEmailsMessage = () => {
    const participants = getParticipantsWithNullEmails();
    const initialMessage = t('ParticipantsWithInvalidEmailsMessage');

    return (
      <div className="text-left">
        <b>{t('Emails inválidos')}</b>
        <br />
        <p className="text-justify">
          {initialMessage} <br />
        </p>
        <ul>
          {participants.map((participant) => (
            <li>• {participant.name}</li>
          ))}
        </ul>
      </div>
    );
  };

  const handleEditParticipant = (participant: any): void => {
    store.dispatch(
      setModal(
        <EditParticipantModal
          currentBoard={currentBoard}
          actualSociety={actualSociety}
          participant={participant}
          announcementTemplateDoc={announcementTemplateDoc}
        />
      )
    );
  };

  const handleSendAnnouncement = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const buttonText =
        currentBoard?.votePeriod === 'FROM_SENDING_TO_CLOSING' ||
        currentBoard?.votePeriod === 'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE'
          ? 'Votar ahora'
          : 'Acceder a la Junta';

      const activePartner = currentBoard?.participants?.filter((participant) =>
        isParticipantAllowedForAnnouncement(participant)
      );

      const recipients = activePartner.map((partner) => ({
        email: partner.email,
        name: partner.name,
      }));

      const extraEmails: any = [];

      activePartner.forEach((partner) => {
        if (otherPartnerEmails?.[partner.member]) {
          extraEmails.push(...otherPartnerEmails[partner.member]);
        }
      });

      const otherPartnerRecipients = extraEmails.map((recipient: any) => {
        const { email, name } = recipient;
        return {
          email: email ? email.trim() : '',
          name,
        };
      });

      const representantIds = (getPartnerRepresentantsFromParticipantsList(
        currentBoard?.participants
      ) || []) as string[];

      const uniqueRepresentantIds = Array.from(new Set(representantIds));

      const partnerRepresentantsRecipients = partnerRepresentants
        .filter((representant) =>
          uniqueRepresentantIds.includes(representant._id)
        )
        .map((representant) => {
          const { email, name } = representant;
          return {
            id: representant._id,
            email: email ? email.trim() : '',
            name,
          };
        });

      const mainPartnerEmails = activePartner.map((recipient) => {
        const { member, email, name } = recipient;
        return {
          id: member,
          email: email ? email.trim() : '',
          name,
        };
      });

      const recipientsForSending = [...recipients, ...otherPartnerRecipients];

      const recipientsForDynamicData = [...mainPartnerEmails, ...extraEmails];

      if (hasShowDetails()) {
        console.log('🚀 cclog ~ handleSendAnnouncement:', {
          mainPartnerEmails,
          extraEmails,
        });
      }

      let fileBlob;

      if (currentBoard?.announcementDocument) {
        fileBlob = await getSingleDocument(currentBoard?.announcementDocument);
      } else {
        const documentUpdated = updateDocumentWithValues(
          announcementTemplateDoc?.editorModel,
          tags({
            society: actualSociety,
            board: currentBoard,
            plan: null,
            partner: null,
            beneficiary: null,
            signatures: null,
            tenderOffer: null,
            tenderOfferShares: null,
            operation: null,
            holdingClasses: null,
            annualValue: null,
          })
        );

        const options = {
          filename: `${t('AnnouncementBoard')}.pdf`,
          margin: [10, 10, 10, 10], // top, right, bottom, left
          pagebreak: { mode: 'avoid-all' },
          html2canvas: { scale: 2 },
          jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
          },
        };
        fileBlob = await html2pdf()
          .from(documentUpdated?.html)
          .set(options)
          .output('blob');
      }
      const announcementFile = new File(
        [fileBlob],
        `${t('AnnouncementBoard')}.pdf`,
        {
          type: 'application/pdf',
        }
      );

      const emailData = {
        ...createAnnouncementEmailData({
          announcementData,
          societyId: actualSociety['_id'],
          society: actualSociety,
          userId: user?._id,
          userEmail: user?.email,
        }),
        to: recipientsForSending,
        files: announcementFile ? [announcementFile] : [],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'name', content: name },
              { name: 'email', content: email },
              {
                name: 'delegationUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=delegacion/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
              {
                name: 'boardUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-junta/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
            ],
          })
        ),
      };

      const emailRepresentantsData = {
        ...createAnnouncementPartnerRepresentantsEmailData({
          announcementData,
          societyId: actualSociety['_id'],
          society: actualSociety,
          userId: user?._id,
          userEmail: user?.email,
        }),
        to: partnerRepresentantsRecipients,
        files: announcementFile ? [announcementFile] : [],
        templateDynamicData: partnerRepresentantsRecipients.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'name', content: name },
              { name: 'email', content: email },
              {
                name: 'delegationUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=delegacion/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
              {
                name: 'boardUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-junta/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
            ],
          })
        ),
      };

      const communicationId = await store.dispatch(sendCommEmail(emailData));
      await store.dispatch(sendCommEmail(emailRepresentantsData, false));
      await store.dispatch(
        updateBoard(
          currentBoard['_id'],
          {
            announcementComm: communicationId,
            status: boardStatus.ACTIVE.value,
          },
          false
        )
      );

      await store.dispatch(getBoards(actualSociety['_id']));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error); // eslint-disable-line
    }
  };

  const handleSendTest = () => {
    const emailData = createAnnouncementEmailData({
      announcementData,
      userId: user?._id,
      userEmail: user?.email,
      society: actualSociety,
      societyId: actualSociety?._id,
    });

    dispatch(setModal(<SendTestEmail emailData={emailData} />));
  };

  const handleCloseUpdatedParticipantsAlert = () => {
    setHasUpdatedParticipants(false);
  };

  const getOtherPartnerEmails = async () => {
    const emails = await getSocietySecondaryEmails(actualSociety);
    setOtherPartnerEmails(emails);
  };

  const updateEmails = async () => {
    const hasUpdated = await updateParticipantsEmails(
      currentBoard,
      actualSociety
    );
    setHasUpdatedParticipants(hasUpdated || false);
  };

  useEffect(() => {
    if (currentBoard) {
      setTime(currentBoard?.date ? formatDateToTime(currentBoard?.date) : '');
      setDate(
        currentBoard?.date
          ? format(new Date(currentBoard?.date), 'dd/MM/yyyy')
          : ''
      );
      setHasInvalidEmails(getParticipantsWithNullEmails().length > 0);
    }
  }, [currentBoard]); // eslint-disable-line

  useEffect(() => {
    if (hasAlreadyUpdatedParticipants) {
      return;
    }
    updateEmails();
    setHasInvalidEmails(getParticipantsWithNullEmails().length > 0);
    getOtherPartnerEmails();
  }, []); // eslint-disable-line

  return (
    <>
      <Modal.Body>
        <div className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <SendAnnouncementModalHeader
              actualSociety={actualSociety}
              currentBoard={currentBoard}
              date={date || ''}
              time={time || ''}
            />

            <ParticipantsTable
              currentBoard={currentBoard}
              otherPartnerEmails={otherPartnerEmails}
              handleEditParticipant={handleEditParticipant}
              isParticipantAllowedForAnnouncement={
                isParticipantAllowedForAnnouncement
              }
              getTotalParticipantsAllowed={getTotalParticipantsAllowed}
            />

            <PartnerRepresentantsTable
              currentBoard={currentBoard}
              partnerRepresentants={partnerRepresentants}
            />

            {hasUpdatedParticipants && (
              <div className="alert alert-icon alert-warning alert-dismissible">
                <em className="icon ni ni-alert-circle" />
                {t('ParticipantsEmailsUpdated')}
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="alert"
                  onClick={handleCloseUpdatedParticipantsAlert}
                />
              </div>
            )}

            {hasInvalidEmails && (
              <div className="alert alert-icon alert-warning alert-dismissible">
                <em className="icon ni ni-alert-circle" />
                {getInvalidEmailsMessage()}
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="alert"
                />
              </div>
            )}

            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSendTest}
                disabled={hasInvalidEmails}
              >
                {t('SendTestEmail')}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSendAnnouncement}
                disabled={hasInvalidEmails}
              >
                {t('SendNow')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default SendAnnouncementModal;
