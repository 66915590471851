/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';
import { employeesPlanAction } from 'modules/_shared/redux/modalsActions';
import { updateEmployeesPlan } from 'modules/beneficiaries/redux/employeesPlanActions';
import { getStockPlans } from 'modules/beneficiaries/redux/planActions';

import actionTypes from 'constants/actionTypes';
import eventTypes from 'constants/eventTypes';
import {
  cleanValue,
  dateTimeFormat,
  formatDateToInput,
  numberFormatDecimals,
} from 'constants/formats';

import { getLastImportationDate } from 'utils/employees';
import {
  employeesGroupTypes,
  employeesPlanStatusTypes,
  employeesSubgroupTypes,
} from 'utils/employeesFilterTypes';
import trackEvent from 'utils/trackEvent';

import ReactDualList from 'modules/_shared/components/DualList';

import '../Modals.scss';

const AddEmployeesToPlan = ({ data = {}, action = actionTypes.ADD }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const { user, plans, employees, actualSociety } = useSelector((state) => ({
    user: state.user,
    plans: state.plans,
    employees: state.employees,
    actualSociety: state.society?.actualSociety,
  }));
  const [name, setName] = useState('');
  const [eligiblesType, setEligiblesType] = useState('');
  const [eligiblesGroup, setEligiblesGroup] = useState('');
  const [eligiblesSubgroup, setEligiblesSubgroup] = useState('');
  const [hiringStartDate, setHiringStartDate] = useState('');
  const [hiringEndDate, setHiringEndDate] = useState('');
  const [hasRequirements, setHasRequirements] = useState(false);
  const [hasSalaryIncrease, setHasSalaryIncrease] = useState(false);
  const [salaryMinimum, setSalaryMinimum] = useState(0);
  const [salaryMaximum, setSalaryMaximum] = useState(0);
  const [salaryMinimumVariable, setSalaryMinimumVariable] = useState(0);
  const [salaryIncrease, setSalaryIncrease] = useState(0);
  const [salaryIncreaseStartDate, setSalaryIncreaseStartDate] = useState('');
  const [salaryIncreaseEndDate, setSalaryIncreaseEndDate] = useState('');

  const [calculationDate, setCalculationDate] = useState('');
  const [plansSorted, setPlansSorted] = useState([]);
  const [planSelected, setPlanSelected] = useState(null);

  const [eligibles, setEligibles] = useState([]);
  const [eligibleOptions, setEligibleOptions] = useState([]);

  const [categoriesAll, setCategoriesAll] = useState([]);
  const [categoriesWithPlans, setCategoriesWithPlans] = useState([]);
  const [categoriesWithoutPlans, setCategoriesWithoutPlans] = useState([]);
  const [employeesWithPlans, setEmployeesWithPlans] = useState([]);
  const [employeesWithoutPlans, setEmployeesWithoutPlans] = useState([]);
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [beneficiariesCIFList, setBeneficiariesCIFList] = useState([]);

  const lastImportationDate = employees.length
    ? format(new Date(getLastImportationDate(employees)), dateTimeFormat)
    : 'N/A';

  const getPlanItemText = (plan) => {
    const planTypes = {
      STOCK_OPTION: '[S.O]',
      PHANTOM_SHARE: '[P.S]',
      WARRANT: '[W]',
    };
    const planName = plan?.name || '';
    const planType = planTypes[plan?.planType] || '';
    const planUnits = `${plan?.sharesTotal?.toString()} ${t('units')}` || '';
    const planStatus = plan?.isDraft ? t('Draft') : t('Active');

    return `${planType} ${planName} | ${planUnits} (${planStatus})`;
  };

  const availableOptions = useMemo(
    () => ({
      ALL_EMPLOYEES: {
        ALL: activeEmployees,
        SOME_GROUPS: categoriesAll,
        SOME_EMPLOYEES: activeEmployees,
      },
      EMPLOYEES_WITH_PLANS: {
        ALL: activeEmployees,
        SOME_GROUPS: categoriesWithPlans,
        SOME_EMPLOYEES: employeesWithPlans,
      },
      EMPLOYEES_WITHOUT_PLANS: {
        ALL: activeEmployees,
        SOME_GROUPS: categoriesWithoutPlans,
        SOME_EMPLOYEES: employeesWithoutPlans,
      },
    }),
    [
      activeEmployees,
      categoriesAll,
      categoriesWithPlans,
      categoriesWithoutPlans,
      employeesWithPlans,
      employeesWithoutPlans,
    ]
  );

  const parseOptionType = (option) => {
    if ('firstName' in option) {
      return 'EMPLOYEE';
    }
    return 'GROUP';
  };

  const handleSelectPlan = (e) => {
    setPlanSelected(e.target.value);
  };

  const handleChangeEligiblesSubgroup = (group, subgroup) => {
    if (!group || !subgroup) return;

    setEligiblesSubgroup(subgroup);

    const selectedOptions = availableOptions[group][subgroup];
    const options =
      selectedOptions?.map((option) => ({
        value: option?._id,
        label: option?.fullName,
        type: parseOptionType(option),
      })) || [];

    setEligibleOptions(options);
  };

  const isFormValid = () => {
    const generalData = !!(name && calculationDate);
    const eligiblesData = !!(eligiblesGroup && eligiblesSubgroup);
    const planData = !!planSelected;

    return generalData && eligiblesData && planData;
  };

  const saveTenderOffer = async (event) => {
    event.preventDefault();

    const newEmployeesPlan = {
      name,
      calculationDate,
      eligiblesType,
      eligiblesGroup,
      eligiblesSubgroup,
      eligibles,
      hiringStartDate,
      hiringEndDate,
      hasRequirements,
      hasSalaryIncrease,
      salaryIncreaseStartDate,
      salaryIncreaseEndDate,
      planSelected,
      salaryMinimum: cleanValue(salaryMinimum),
      salaryMaximum: cleanValue(salaryMaximum),
      salaryIncrease: cleanValue(salaryIncrease),
      salaryMinimumVariable: cleanValue(salaryMinimumVariable),
      society: actualSociety?._id,
      status: employeesPlanStatusTypes.DRAFT.value,
    };

    let currentEvent;

    if (action === actionTypes.ADD) {
      await dispatch(employeesPlanAction(newEmployeesPlan));
      currentEvent = eventTypes.ADD_EMPLOYEES_PLAN;
    } else {
      await dispatch(
        updateEmployeesPlan(data._id, {
          ...newEmployeesPlan,
        })
      );
      currentEvent = eventTypes.EDIT_EMPLOYEES_PLAN;
    }

    trackEvent(mixpanel, currentEvent, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?._id,
      societyName: actualSociety?.name,
      operation: currentEvent,
    });

    dispatch(setModal(null));
  };

  const getAllBeneficiariesCIFs = (beneficiaries) => {
    const cifs = beneficiaries?.map((beneficiary) =>
      beneficiary.cif?.toUpperCase()
    );
    setBeneficiariesCIFList(cifs);
  };

  const getEmployeesTeams = (employees) => {
    const teams = employees.reduce((acc, employee) => {
      if (employee?.teams) {
        employee.teams.forEach((team) => {
          acc.add({
            _id: team.externalId,
            fullName: team.name,
          });
        });
      }
      return acc;
    }, new Set());

    const uniqueTeams = [...teams].reduce((acc, team) => {
      if (!acc.find((t) => t._id === team._id)) {
        acc.push(team);
      }
      return acc;
    }, []);

    return uniqueTeams;
  };

  useEffect(() => {
    if (action === actionTypes.EDIT && data._id) {
      setName(data.name);
      setCalculationDate(formatDateToInput(data.calculationDate));
      setEligiblesType(data.eligiblesType);
      setEligiblesGroup(data.eligiblesGroup);
      setEligiblesSubgroup(data.eligiblesSubgroup);
      setEligibles(data.eligibles);
      setHiringStartDate(formatDateToInput(data.hiringStartDate));
      setHiringEndDate(formatDateToInput(data.hiringEndDate));
      setHasRequirements(data.hasRequirements);
      setHasSalaryIncrease(data.hasSalaryIncrease);
      setSalaryIncreaseStartDate(
        formatDateToInput(data.salaryIncreaseStartDate)
      );
      setSalaryIncreaseEndDate(formatDateToInput(data.salaryIncreaseEndDate));
      setPlanSelected(data.planSelected);
      setSalaryMinimum(data.salaryMinimum);
      setSalaryMaximum(data.salaryMaximum);
      setSalaryIncrease(data.salaryIncrease);
      setSalaryMinimumVariable(data.salaryMinimumVariable);
      handleChangeEligiblesSubgroup(
        data.eligiblesGroup,
        data.eligiblesSubgroup
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getStockPlans(actualSociety?.['_id']));
      getAllBeneficiariesCIFs(actualSociety?.beneficiaries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (plans) {
      setPlansSorted(
        plans?.sort((a, b) => {
          if (a?.isDraft && !b?.isDraft) {
            return 1;
          }
          if (!a?.isDraft && b?.isDraft) {
            return -1;
          }
          return 0;
        })
      );
    }
  }, [plans]);

  useEffect(() => {
    if (employees?.length) {
      const actives = employees.filter(
        (employee) => !employee?.terminationDate
      );
      setActiveEmployees(actives);
    }
  }, [employees]);

  useEffect(() => {
    if (activeEmployees.length) {
      const withPlans = [];
      const withoutPlans = [];

      activeEmployees.forEach((employee) => {
        if (beneficiariesCIFList.includes(employee?.cif?.toUpperCase())) {
          withPlans.push(employee);
        } else {
          withoutPlans.push(employee);
        }
      });

      setCategoriesAll(getEmployeesTeams(activeEmployees));
      setCategoriesWithPlans(getEmployeesTeams(withPlans));
      setCategoriesWithoutPlans(getEmployeesTeams(withoutPlans));
      setEmployeesWithPlans(withPlans);
      setEmployeesWithoutPlans(withoutPlans);
    }
  }, [activeEmployees, beneficiariesCIFList]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {t('Selección de empleados (borrador)')}
          <small className="d-block fs-14px text-soft">
            Establece las condiciones que han de cumplir los empleados a los que
            dar un nuevo incentivo. Empleados actualizados hasta el día{' '}
            <span className="text-primary">{lastImportationDate}</span>
          </small>
        </h5>

        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <div className="card-aside-wrap">
              <div className="card-content">
                <form className="form-contact" onSubmit={saveTenderOffer}>
                  <div className="row g-4 mb-3">
                    {/* NAME */}
                    <div className="col-12">
                      <div className="form-group">
                        <label className="form-label">
                          Nombre del borrador
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            placeholder="Indica un nombre para este borrador... "
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label" htmlFor="filter-end-date">
                          Fecha de cálculo de las condiciones:
                        </label>
                        <div className="form-control-wrap">
                          <input
                            type="date"
                            className="form-control date-picker"
                            id="filter-end-date"
                            name="filter-end-date"
                            placeholder="dd/mm/yyyy"
                            value={calculationDate}
                            onChange={(e) => setCalculationDate(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    {/* TARGET */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            ¿A quién va dirigido el Plan de Incentivos?
                            <small className="d-block fs-14px text-soft mt-1">
                              Indica las condiciones para ser beneficiario
                            </small>
                          </h5>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">
                          ¿Qué grupo recibirá el incentivo?
                        </label>
                        <div className="form-control-wrap">
                          <ul className="custom-control-group w-100 d-inline-flex">
                            <li className="col-4">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="offer-target"
                                  id="partners"
                                  value={employeesGroupTypes.ALL_EMPLOYEES}
                                  checked={
                                    eligiblesGroup ===
                                    employeesGroupTypes.ALL_EMPLOYEES
                                  }
                                  onChange={(e) => {
                                    setEligiblesGroup(e.target.value);
                                    handleChangeEligiblesSubgroup(
                                      e.target.value,
                                      eligiblesSubgroup
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="partners"
                                >
                                  Todos los empleados
                                </label>
                              </div>
                            </li>
                            <li className="col-4">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="offer-target"
                                  id="benficiary-phantoms"
                                  value={
                                    employeesGroupTypes.EMPLOYEES_WITHOUT_PLANS
                                  }
                                  checked={
                                    eligiblesGroup ===
                                    employeesGroupTypes.EMPLOYEES_WITHOUT_PLANS
                                  }
                                  onChange={(e) => {
                                    setEligiblesGroup(e.target.value);
                                    handleChangeEligiblesSubgroup(
                                      e.target.value,
                                      eligiblesSubgroup
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="benficiary-phantoms"
                                >
                                  Empleados sin ningún Plan
                                </label>
                              </div>
                            </li>
                            <li className="col-4">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="offer-target"
                                  id="beneficiary-stock-options"
                                  value={
                                    employeesGroupTypes.EMPLOYEES_WITH_PLANS
                                  }
                                  checked={
                                    eligiblesGroup ===
                                    employeesGroupTypes.EMPLOYEES_WITH_PLANS
                                  }
                                  onChange={(e) => {
                                    setEligiblesGroup(e.target.value);
                                    handleChangeEligiblesSubgroup(
                                      e.target.value,
                                      eligiblesSubgroup
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="beneficiary-stock-options"
                                >
                                  Empleados con algún Plan
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>

                        <div className="nk-block-head-content">
                          <br />
                          <label className="form-label">
                            ¿Para todos?
                            <small className="d-block fs-14px text-soft mt-1">
                              ¿Todos los empleados del grupo recibirán el
                              incentivo?
                            </small>
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <ul className="custom-control-group  w-100 d-inline-flex">
                            <li className="col-4">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="eligibles-subgroup"
                                  id="eligibles-subgroup-all"
                                  value={employeesSubgroupTypes.ALL}
                                  checked={
                                    eligiblesSubgroup ===
                                    employeesSubgroupTypes.ALL
                                  }
                                  onChange={(e) => {
                                    setEligibles([]);
                                    setEligiblesType('');
                                    handleChangeEligiblesSubgroup(
                                      eligiblesGroup,
                                      e.target.value
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="eligibles-subgroup-all"
                                >
                                  Sí, todos
                                </label>
                              </div>
                            </li>
                            <li className="col-4">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="eligibles-subgroup"
                                  id="eligibles-subgroup-some-groups"
                                  value={employeesSubgroupTypes.SOME_GROUPS}
                                  checked={
                                    eligiblesSubgroup ===
                                    employeesSubgroupTypes.SOME_GROUPS
                                  }
                                  onChange={(e) => {
                                    setEligibles([]);
                                    setEligiblesType('GROUPS');
                                    handleChangeEligiblesSubgroup(
                                      eligiblesGroup,
                                      e.target.value
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="eligibles-subgroup-some-groups"
                                >
                                  No, sólo algunos grupos/categorías
                                </label>
                              </div>
                            </li>
                            <li className="col-4">
                              <div className="custom-control custom-radio">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="eligibles-subgroup"
                                  id="eligibles-subgroup-some-employees"
                                  value={employeesSubgroupTypes.SOME_EMPLOYEES}
                                  checked={
                                    eligiblesSubgroup ===
                                    employeesSubgroupTypes.SOME_EMPLOYEES
                                  }
                                  onChange={(e) => {
                                    setEligibles([]);
                                    setEligiblesType('EMPLOYEES');
                                    handleChangeEligiblesSubgroup(
                                      eligiblesGroup,
                                      e.target.value
                                    );
                                  }}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="eligibles-subgroup-some-employees"
                                >
                                  No, sólo algunos empleados
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {eligiblesGroup &&
                        [
                          employeesSubgroupTypes.SOME_GROUPS,
                          employeesSubgroupTypes.SOME_EMPLOYEES,
                        ].includes(eligiblesSubgroup) &&
                        eligibleOptions.length > 0 && (
                          <div className="card">
                            <ReactDualList
                              options={eligibleOptions}
                              icon
                              canFilter
                              setValue={setEligibles}
                            />
                          </div>
                        )}
                    </div>

                    {/* CONTRATATION DATE */}
                    <div className="nk-block col-12">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            Fecha de contratación
                            <small className="d-block fs-14px text-soft mt-1">
                              Si quieres limitar los empleados a los que
                              entraron en determinada fecha o antes de una
                              fecha.
                            </small>
                          </h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="filter-start-date"
                            >
                              Desde:
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                className="form-control date-picker"
                                id="filter-start-date"
                                name="filter-start-date"
                                placeholder="dd/mm/yyyy"
                                value={hiringStartDate}
                                onChange={(e) =>
                                  setHiringStartDate(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="filter-end-date"
                            >
                              Hasta:
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="date"
                                className="form-control date-picker"
                                id="filter-end-date"
                                name="filter-end-date"
                                placeholder="dd/mm/yyyy"
                                value={hiringEndDate}
                                onChange={(e) =>
                                  setHiringEndDate(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* OTHER CONDITIONS */}
                    <div className="nk-block">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            Otras condiciones
                            <small className="d-block fs-14px text-soft mt-1">
                              ¿Qué condiciones han de cumplir los empleados que
                              recibirán Incentivos
                            </small>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 mb-0">
                      <div className="form-control-wrap">
                        <ul className="custom-control-group w-100 d-inline-flex">
                          <li className="col-6">
                            <div className="custom-control custom-radio ">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="filter-requirements"
                                id="has-requirements"
                                value="true"
                                checked={hasRequirements === true}
                                onChange={() => setHasRequirements(true)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="has-requirements"
                              >
                                Sí, hay requisitos
                              </label>
                            </div>
                          </li>
                          <li className="col-6">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="filter-requirements"
                                id="has-no-requirements"
                                value="false"
                                checked={hasRequirements === false}
                                onChange={() => {
                                  setHasRequirements(false);
                                  setSalaryMinimum(0);
                                  setSalaryMaximum(0);
                                  setSalaryIncrease(0);
                                  setSalaryMinimumVariable(0);
                                }}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="has-no-requirements"
                              >
                                No hay ningún requisito
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {hasRequirements && (
                      <>
                        <div className="col-sm-3 mb-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="cp1-project-deadline"
                            >
                              Sueldo mínimo:
                            </label>
                            <NumberFormat
                              name="price-type"
                              className="form-control"
                              value={salaryMinimum}
                              disabled={!hasRequirements}
                              onChange={(e) => {
                                setSalaryMinimum(e.target.value);
                              }}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormatDecimals}
                              decimalScale={2}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3 mb-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="price-per-society"
                            >
                              Sueldo máximo:
                            </label>
                            <NumberFormat
                              name="price-per-society"
                              className="form-control"
                              value={salaryMaximum}
                              disabled={!hasRequirements}
                              onChange={(e) => {
                                setSalaryMaximum(e.target.value);
                              }}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormatDecimals}
                              decimalScale={2}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3 mb-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="cp1-project-deadline"
                            >
                              Sueldo variable mín.:
                            </label>
                            <NumberFormat
                              name="price-type"
                              className="form-control"
                              value={salaryMinimumVariable}
                              disabled={!hasRequirements}
                              onChange={(e) => {
                                setSalaryMinimumVariable(e.target.value);
                              }}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormatDecimals}
                              decimalScale={2}
                            />
                          </div>
                        </div>
                        <div className="col-sm-3 mb-2">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="price-per-society"
                            >
                              Incremento de sueldo:
                            </label>
                            <NumberFormat
                              name="price-per-society"
                              className="form-control"
                              value={salaryIncrease}
                              disabled={!hasRequirements}
                              onChange={(e) => {
                                setSalaryIncrease(e.target.value);
                              }}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormatDecimals}
                              decimalScale={2}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="form-group col-12">
                      <div className="form-control-wrap">
                        <ul className="custom-control-group w-100 d-inline-flex">
                          <li className="col-6">
                            <div className="custom-control custom-radio ">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="filter-salary-increase"
                                id="has-salary-increase"
                                value="true"
                                checked={hasSalaryIncrease === true}
                                onChange={(e) => setHasSalaryIncrease(true)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="has-salary-increase"
                              >
                                Hay incremento de sueldo
                              </label>
                            </div>
                          </li>
                          <li className="col-6">
                            <div className="custom-control custom-radio">
                              <input
                                type="radio"
                                className="custom-control-input"
                                name="filter-salary-increase"
                                id="has-no-salary-increase"
                                value="false"
                                checked={hasSalaryIncrease === false}
                                onChange={(e) => setHasSalaryIncrease(false)}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="has-no-salary-increase"
                              >
                                No hay incremento de sueldo
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* INCREASE SALARY DATE */}
                    {hasSalaryIncrease && (
                      <div className="nk-block col-12">
                        <div className="nk-block-head">
                          <div className="nk-block-head-content">
                            <h5 className="nk-block-title">
                              Fecha desde el incremento de sueldo
                              <small className="d-block fs-14px text-soft mt-1">
                                Establece la fecha desde la que se ha de revisar
                                si ha habído incremento de sueldo.
                              </small>
                            </h5>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="increase-salary-start-date"
                              >
                                Desde:
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="date"
                                  className="form-control date-picker"
                                  id="increase-salary-start-date"
                                  name="increase-salary-start-date"
                                  placeholder="dd/mm/yyyy"
                                  value={salaryIncreaseStartDate}
                                  onChange={(e) =>
                                    setSalaryIncreaseStartDate(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="increase-salary-end-date"
                              >
                                Hasta:
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="date"
                                  className="form-control date-picker"
                                  id="increase-salary-end-date"
                                  name="increase-salary-end-date"
                                  placeholder="dd/mm/yyyy"
                                  value={salaryIncreaseEndDate}
                                  onChange={(e) =>
                                    setSalaryIncreaseEndDate(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* PLANS */}
                    <div className="nk-block col-12">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h5 className="nk-block-title">
                            ¿Qué Plan quieres aplicarle?
                            <small
                              className="d-block fs-14px text-soft mt-1"
                              style={{ lineHeight: '18px' }}
                            >
                              Indicar el Plan al que quieres adherir estos
                              empleados (Si no está creado, puedes guardar este
                              borrador, crear el Plan y volver para aplicarlo a
                              este borrador).
                            </small>
                          </h5>
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="default-06"
                            />
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <select
                                  className="form-control form-control-lg"
                                  id="incentive-plan"
                                  name="incentive-plan"
                                  value={planSelected}
                                  onChange={handleSelectPlan}
                                >
                                  <option value="" default>
                                    {t('Select')}
                                  </option>
                                  {plansSorted?.length > 0 &&
                                    plansSorted.map((plan) => (
                                      <option
                                        value={plan['_id']}
                                        key={plan['_id']}
                                      >
                                        {getPlanItemText(plan)}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isFormValid()}
                      >
                        Guardar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default AddEmployeesToPlan;
