/* eslint-disable react/jsx-one-expression-per-line */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import RepresentativeDocuments from 'modules/society/components/RepresentativeDocuments';
import RepresentativePersonal from 'modules/society/components/RepresentativePersonal';
import RepresentativeSociety from 'modules/society/components/RepresentativeSociety';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getDocuments } from 'modules/documents/redux/documentActions';
import { getHoldingClasses } from 'modules/partners/redux/holdingClassActions';
import { getActualSociety, getSocietyValue } from 'utils/filters';

import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';

import menuTypes from 'constants/menuTypes';
import './RepresentativeDetail.scss';

const RepresentativeDetail = ({ initialPage = 0 }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId, representativeId } = useParams();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [representative, setRepresentative] = useState();
  const [date, setDate] = useState('');
  const [isOutdated, setIsOutdated] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (user && actualSociety) {
      const societyValue = getSocietyValue(actualSociety);
      const actualRepresentative = actualSociety?.representatives?.find(
        (rep) => rep['_id'] === representativeId
      );
      if (actualRepresentative) {
        if (
          actualRepresentative?.endDate &&
          new Date() > new Date(actualRepresentative?.endDate)
        ) {
          setDate(format(new Date(actualRepresentative.endDate), dateFormat));
          setIsOutdated(true);
        } else if (actualRepresentative?.initDate) {
          setDate(format(new Date(actualRepresentative.initDate), dateFormat));
        }
        const partner = actualSociety?.partners?.find(
          (p) => p?.cif && p?.cif === actualRepresentative?.nif
        );
        const partnerData = partner
          ? {
              ...partner,
              societyValue,
            }
          : null;
        setRepresentative(actualRepresentative);
        setPages([
          <RepresentativeSociety
            key="RepresentativeSociety"
            society={actualSociety}
            representative={actualRepresentative}
            partner={partnerData}
          />,
          <RepresentativePersonal
            key="RepresentativePersonal"
            society={actualSociety}
            representative={actualRepresentative}
          />,
          <RepresentativeDocuments
            key="RepresentativeDocuments"
            society={actualSociety}
            representative={actualRepresentative}
          />,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativeId, societyId, user, actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyName: actualSociety?.name,
          societyId: actualSociety?.['_id'],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getHoldingClasses(actualSociety?.['_id']));
      dispatch(getDocuments({ society: actualSociety?.['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return representative ? (
    <div className="nk-content-body">
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
      </div>
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('Representative')} /{' '}
                <strong className="text-primary small">
                  {representative.socialDenomination || ''}
                </strong>
              </h3>
              <div className="nk-block-des text-soft">
                <ul className="list-inline">
                  <li>
                    {isOutdated
                      ? `${t('RevocationDate')}: ${date}`
                      : `${t('RepresentativeDate')}: ${date}`}
                  </li>
                </ul>
              </div>
            </div>
            <div className="nk-block-head-content">
              <GoBackButton />
            </div>
          </div>
        </div>

        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content">
                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 0 ? 'active' : ''}`}
                      onClick={() => setPage(0)}
                    >
                      <em className="icon ni ni-user-circle" />
                      <span>{t('Representative')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 1 ? 'active' : ''}`}
                      onClick={() => setPage(1)}
                    >
                      <em className="icon ni ni-list" />
                      <span>{t('Data')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 3 ? 'active' : ''}`}
                      onClick={() => setPage(2)}
                    >
                      <em className="icon ni ni-file-text" />
                      <span>{t('Documents')}</span>
                    </p>
                  </li>
                </ul>
                {pages[page]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default RepresentativeDetail;
