/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */

import screens from 'constants/screens';
import userTypes from 'constants/userTypes';
import MenuDots from 'modules/_shared/components/MenuDots';
import CellData from 'modules/_shared/components/Tables/CellData';

import { translate } from 'hooks/useTranslate';
import partnerOptions from './partnerOptions';

function tableColumns(i18n, showNationality = false) {
  return [
    {
      name: translate('Nr'),
      field: 'index',
      hide: screens.SM,
      selector: (row, index) => index + 1,
      cell: (row, index) => index + 1,
      disableSortBy: true,
      disableFilters: true,
      center: true,
      grow: 0.5,
      compact: true,
      minWidth: 20,
      export: true,
    },
    {
      // mandatory props
      name: translate('Partner'),
      field: 'name',
      selector: (row) => row[translate('Partner')],
      sortable: true,
      // optional props
      grow: 4,
      compact: false,
      cell: (row) =>
        CellData.User({
          societyId: row?.societyId,
          partner: row?.partner,
          hasLink: row?.hasLink,
          url: '/detalle-socio',
        }),
      export: true,
    },
    {
      name: translate('Email'),
      field: 'email',
      selector: (row) => row[translate('Email')],
      cell: (row) =>
        CellData.PlainText({
          text: row[translate('Email')],
        }),
      export: false,
      omit: true,
    },
    {
      name: translate('CIF'),
      field: 'cif',
      selector: (row) => row[translate('CIF')],
      cell: (row) =>
        CellData.PlainText({
          text: row[translate('cif')],
        }),
      export: false,
      omit: true,
    },
    {
      name: translate('Shares'),
      field: 'shares',
      selector: (row) => row[translate('Shares')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[translate('Shares')] }),
      export: true,
    },
    {
      name: translate('Nominal'),
      field: 'partnerNominalValue',
      selector: (row) => row[translate('Nominal')],
      sortable: true,
      center: true,
      grow: 2,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.CurrencyDecimals({ value: row[translate('Nominal')] }),
      export: true,
    },
    {
      name: translate('TotalPremium'),
      field: 'partnerPremium',
      selector: (row) => row[translate('TotalPremium')],
      sortable: true,
      center: true,
      grow: 2,
      hide: screens.SM,
      compact: true,
      cell: (row) =>
        CellData.CurrencyDecimals({ value: row[translate('TotalPremium')] }),
      export: true,
    },
    {
      name: translate('%ND'),
      field: 'NDPercent',
      selector: (row) => row[translate('%ND')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[translate('%ND')],
          decimals: row.decimals,
          noPadding: true,
        }),
      export: true,
    },
    {
      name: translate('%FD'),
      field: 'FDPercent',
      selector: (row) => row[translate('%FD')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[translate('%FD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    !showNationality
      ? {
          name: translate('%PotentialFD'),
          field: 'FDPotential',
          selector: (row) => row[translate('%PotentialFD')],
          sortable: true,
          center: true,
          hide: screens.MD,
          compact: true,
          cell: (row) =>
            CellData.PercentDecimals({
              value: row[translate('%PotentialFD')],
              decimals: row.decimals,
            }),
          export: true,
        }
      : null,
    showNationality
      ? {
          name: translate('Nationality'),
          field: 'nationality',
          selector: (row) => row[translate('Nationality')],
          sortable: true,
          center: true,
          hide: screens.MD,
          compact: true,
          cell: (row) =>
            CellData.PlainText({
              text: row[translate('Nationality')],
            }),
          export: true,
        }
      : null,
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              <MenuDots
                disabled={!row?.hasLink}
                menuOptions={partnerOptions(i18n)}
                direction="left"
                id={`${row.societyId}/${row.partner['_id']}`}
                params={{
                  data: row.partner,
                  societyId: row.societyId,
                  userType: userTypes.PARTNER,
                  society: row.society,
                  userId: row.userId,
                  isReadOnlyUser: row.isReadOnlyUser,
                  isReadOnly: row.isReadOnly,
                }}
              />
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      hide: screens.SM,
      allowOverflow: true,
    },
  ].filter(Boolean);
}

export default tableColumns;
