/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { Document } from 'types';
import useGetTemplateOptions from '../hooks/useGetTemplateOptions';

type Props = {
  documents: Document[];
  societyId: string;
  societyTemplates: boolean;
  setSocietyTemplates: (value: boolean) => void;
  setSelectedTemplate: (value: string) => void;
};

const ReplaceBoardTemplateBody: FC<Props> = ({
  documents,
  societyId,
  societyTemplates,
  setSocietyTemplates,
  setSelectedTemplate,
}: Props) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const societyTemplatesOptions = [
    {
      value: 0,
      label: t('AllTemplates'),
    },
    {
      value: 1,
      label: t('CompanyTemplates'),
    },
  ];

  const { templateOptions } = useGetTemplateOptions(
    documents,
    societyTemplates,
    societyId,
    t
  );

  const handleChangeSocietyTemplates = (selectedOption: any) => {
    setSocietyTemplates(selectedOption.value === 1);
  };

  const handleChangeSelectedTemplate = (selectedOption: any) => {
    setSelectedTemplate(selectedOption.value);
  };

  return (
    <>
      <Modal.Body>
        <div className="row mb-4">
          <div className="col">
            <div className="form-group">
              <label
                className="form-label"
                htmlFor="board-template-type-select"
              >
                {t('TemplateType')}
              </label>
              <Select
                id="board-template-type-select"
                closeMenuOnSelect
                className="react-select-lg"
                options={societyTemplatesOptions}
                components={animatedComponents}
                onChange={handleChangeSocietyTemplates}
                placeholder={t('Select')}
                defaultValue={societyTemplatesOptions[0]}
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col">
            <div className="form-group">
              <label className="form-label" htmlFor="board-template-select">
                {t('Template')}
              </label>
              <Select
                id="board-template-select"
                closeMenuOnSelect
                className="react-select-lg"
                options={templateOptions}
                components={animatedComponents}
                onChange={handleChangeSelectedTemplate}
                placeholder={t('Select')}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ReplaceBoardTemplateBody;
