import { store } from 'redux/store';

import { Document } from 'types';

import { updateStockPlan } from 'modules/beneficiaries/redux/planActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

type Props = {
  planId: string;
  htmlContent: string;
  hasExtraSigner: boolean;
  extraSignerName: string;
  extraSignerEmail: string;
  attachmentDocument: Document;
};

export default async function savePlanData({
  planId,
  htmlContent,
  hasExtraSigner,
  extraSignerName,
  extraSignerEmail,
  attachmentDocument,
}: Props): Promise<void> {
  try {
    const extraSignerHasData =
      extraSignerName !== '' && extraSignerEmail !== '';

    let planData: {
      invitationModel: { html: string };
      invitationExtraSigner?: { name: string; email: string } | {};
      invitationAttachedDocument?: string;
    } = {
      invitationModel: { html: htmlContent },
    };

    let invitationExtraSigner = {};
    let invitationAttachedDocument;

    if (hasExtraSigner) {
      if (extraSignerHasData) {
        invitationExtraSigner = {
          name: extraSignerName,
          email: extraSignerEmail,
        };
      } else {
        store.dispatch(
          addAlert(alertBodyTypes.INVITATION_NO_EXTRA_SIGNER_DATA)
        );
        return;
      }
    }
    if (attachmentDocument) {
      invitationAttachedDocument = attachmentDocument?.['_id'];
    }

    planData = {
      ...planData,
      invitationExtraSigner,
      invitationAttachedDocument,
    };

    await store.dispatch(updateStockPlan(planId, planData, false));
  } catch (error) {
    console.error('Error saving attachment', error);
  }
}
