import documentTypes from 'constants/documentTypes';
import { translate } from 'hooks/useTranslate';
import { addDocument } from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

const fileTypes = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const uploadDocument = async (data: any) => {
  let uploadedDocument = null;

  const { file, userId, societyId } = data;
  const name = `AI - ${file.name}`;

  if (file) {
    const newDocument = {
      file,
      date: new Date(),
      size: file.size,
      name,
      author: userId,
      society: societyId,
      fileType: fileTypes[file.type as keyof typeof fileTypes],
      category: documentTypes.SCRIPTURES,
      subcategory: 'AI',
      description: translate('DocumentUploadedWithAI'),
    };

    uploadedDocument = await store.dispatch(addDocument(newDocument));
  }

  return uploadedDocument;
};
