import { useEffect, useState } from 'react';

import representativeTypesOptions from 'modules/society/components/SocietyRepresentative/representativeTypesOptions';
import statusTypesOptions from 'modules/society/components/SocietyRepresentative/statusTypeOptions';

import { Option } from '../types/Option';

export function useGetRepresentativeOptions() {
  const [authorityOptions, setAuthorityOptions] = useState<Option[]>([]);
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);

  useEffect(() => {
    const options = representativeTypesOptions.map((type: any) => ({
      value: type.value,
      label: type.name,
    }));
    setAuthorityOptions(options);
  }, []);

  useEffect(() => {
    const options = statusTypesOptions.map((type: any) => ({
      value: type.value,
      label: type.name,
    }));
    setStatusOptions(options);
  }, []);

  return {
    data: { authorityOptions, statusOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
