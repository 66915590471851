import React, { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import '../OrganizationChart.scss';

interface NodeProps {
  id: string;
  name: string;
  logo: string;
  data: string;
  shares: string;
  percent: string;
  userType: boolean;
  email: string;
  tooltip: string;
}

interface ChartItemProps {
  node: NodeProps;
}

const ChartItem: FC<ChartItemProps> = ({ node }) => {
  const handleClickChartItem = (): void => {
    const url: string =
      node?.userType === true
        ? `${process.env.REACT_APP_DOMAIN}/#/perfil-usuario/datos`
        : `${process.env.REACT_APP_DOMAIN}/#/sociedad/${node.id}`;
    window.open(url, '_blank');
  };

  const itemId = uuidv4();

  return (
    <div className="chart-item">
      <div
        className="user-card border shadow"
        id={itemId}
        onClick={handleClickChartItem}
      >
        <div className="user-avatar">
          <img src={node.logo} alt="logo" className="chart-item-logo" />
        </div>
        <div className="user-info">
          <p className="lead-text text-left limited">{node.name}</p>
          {node?.userType === true ? (
            <span className="sub-text text-left">{node.email}</span>
          ) : (
            <span className="sub-text text-left">{node.data}</span>
          )}
        </div>
      </div>

      {node.tooltip && (
        <Tooltip anchorId={itemId} place="top">
          {node.tooltip}
        </Tooltip>
      )}
    </div>
  );
};

export default ChartItem;
