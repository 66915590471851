import AddBeneficiary from 'modules/beneficiaries/modals/AddBeneficiary';
import StockOptions from 'modules/_shared/components/Modals/StockOption';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-info',
      text: i18n.t('NewBeneficiary'),
      url: AddBeneficiary,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('NewStockPhantomPlan'),
      url: StockOptions,
    },
  ];
}
export default menuOptions;
