import { Board, Partner, Society } from 'types';

const getDelegationPartners = (
  board: Board,
  society: Society,
  partnerId: string
): Partner[] =>
  society?.partners.filter(
    (partner) =>
      board?.delegationPartners?.includes(partner['_id']) &&
      partner['_id'] !== partnerId
  );

const getNotDelegationPartners = (
  society: Society,
  partnerId: string
): Partner[] =>
  society?.partners.filter((partner) => {
    const { hasOldPartnersOn } = society?.configuration || {};

    if (hasOldPartnersOn) {
      return partner['_id'] !== partnerId;
    }
    return partner['_id'] !== partnerId && partner?.sharesCount?.actual > 0;
  });

export const getFilteredPartners = (
  board: Board,
  society: Society,
  partnerId: string
): Partner[] => {
  let filteredPartners = [];

  if (board?.delegationPartners && board?.delegationPartners?.length > 0) {
    filteredPartners = getDelegationPartners(board, society, partnerId);
  } else {
    filteredPartners = getNotDelegationPartners(society, partnerId);
  }

  return filteredPartners;
};
