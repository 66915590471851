/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-underscore-dangle */
import i18n from 'i18n/config';

import { store } from 'redux/store';

import notificationIds from 'constants/notificationIds';
import votePeriods from 'constants/votePeriods';

import { hasOutdatedSocietyValue } from 'utils/filters';
import { tenderStatusTypes } from 'utils/tenderOfferTypes';

import { updateRecipietns } from 'modules/communications/components/utils';
import RetryAnnouncementModal from 'modules/boards/modals/AnnouncementModal/RetryAnnouncementModal';

import {
  hideNotification,
  showNotification,
} from '../redux/notificationActions';
import { setModal } from '../redux/modalActions';

const notificationTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info',
  LIGHT: 'light',
  DARK: 'dark',
};

const hasPassedTwoHours = (fromDate) => {
  const date = new Date(fromDate);

  // Get the current time
  const currentTime = new Date();

  // Calculate the time difference in milliseconds.
  const timeDifference = currentTime - date;

  // Convert the time difference to hours.
  const hoursDifference = timeDifference / (1000 * 60 * 60);

  // Check if more than 2 hours have passed.
  return hoursDifference > 2;
};

const getNotDeliveredRecipients = (recipients) => {
  const result = recipients?.filter(
    (recipient) => recipient?.status !== 'delivered'
  );

  return result;
};

export const validateOutdatedSocietyValue = (society) => async (dispatch) => {
  const isOutdated = hasOutdatedSocietyValue(society);

  if (isOutdated) {
    dispatch(
      showNotification({
        id: notificationIds.SOCIETY_VALUATION_OUTDATED,
        title: i18n.t('ValuationOutdated'),
        message: i18n.t('ValuationOutdatedMessage'),
        type: notificationTypes.DANGER,
        link: `/perfil-sociedad/valor/${society._id}`,
        linkText: i18n.t('GoToLink'),
      })
    );
  } else {
    dispatch(hideNotification());
  }
};

export const validateIsAbsent = (isAbsent) => async (dispatch) => {
  if (isAbsent) {
    dispatch(
      showNotification({
        id: notificationIds.PARTNER_IS_ABSENT,
        title: i18n.t('YouCannotVote'),
        message: i18n.t('YouCannotVoteMessage'),
        type: notificationTypes.WARNING,
      })
    );
  } else {
    dispatch(hideNotification());
  }
};

export const validateHasTenderOffer =
  (tenderOffers, memberId) => async (dispatch) => {
    const tenderOffersList = tenderOffers.filter((tenderOffer) =>
      tenderOffer.participants.some(
        (participant) =>
          participant.id === memberId && participant.status === 'NOTIFIED'
      )
    );

    const hasTenderOffer = tenderOffersList.length > 0;
    const tenderOffer = tenderOffersList[0];
    // @Marti: Qué hacemos cuando haya más de 1 oferta de compra?

    if (!tenderOffer) return;

    const { society: societyId } = tenderOffer;

    const currentType = tenderOffer.participants.find(
      (participant) => participant.id === memberId
    ).type;

    const memberType = currentType === 'PARTNER' ? 'socio' : 'beneficiario';

    const isTenderOfferFinished =
      tenderOffer.status === tenderStatusTypes.FINISHED.value;

    if (hasTenderOffer && !isTenderOfferFinished) {
      dispatch(
        showNotification({
          id: notificationIds.MEMBER_HAS_TENDER_OFFER,
          title: i18n.t('NewTenderOffer'),
          message: i18n.t('NewTenderOfferMessage'),
          type: notificationTypes.SUCCESS,
          link: `/participacion-tender-offer/${societyId}/${tenderOffer._id}/${memberType}/${memberId}`,
          linkText: i18n.t('SeeOffer'),
          fill: false,
        })
      );
    }
  };

export const validateOpenVoting = (isOpenVoting, board) => async (dispatch) => {
  if (!isOpenVoting) {
    const periodText = votePeriods.find(
      (period) => period.value === board?.votePeriod
    )?.text;
    dispatch(
      showNotification({
        id: notificationIds.OPEN_VOTING,
        title: i18n.t('YouCannotVote'),
        message: periodText
          ? `${i18n.t('VoteWillBeAvailable')}: ${periodText}`
          : '',
        type: notificationTypes.WARNING,
      })
    );
  } else {
    dispatch(hideNotification());
  }
};

export const validateDeliveredAnnouncement =
  (actualSociety, board, announcementTemplateDoc) => async (dispatch) => {
    const communications = actualSociety?.communications;
    if (board?.announcementComm && communications?.length > 0) {
      const retriedAnnouncementsLength = board?.retriedAnnouncements?.length;

      // If there are retried announcements, get the last one.
      const comunicationId =
        retriedAnnouncementsLength > 0
          ? board?.retriedAnnouncements[retriedAnnouncementsLength - 1]
          : board?.announcementComm;

      // Get the current comunication.
      const currentCommunication = communications?.find(
        (communication) => communication._id === comunicationId
      );

      // Start the validation if the communication is older than 2 hours.
      if (!hasPassedTwoHours(currentCommunication?.date)) return;

      // Get the recipients status.
      const recipients = await updateRecipietns(currentCommunication);
      const notDeliveredRecipients = getNotDeliveredRecipients(recipients);

      // If there are recipients with status not delivered, show the notification.
      if (notDeliveredRecipients?.length > 0) {
        dispatch(
          showNotification({
            id: `${notificationIds.NOT_DELIVERED_ANNOUNCEMENT}_${board?._id}`,
            title: 'NotDeliveredAnnouncement',
            message: 'NotDeliveredAnnouncementMessage',
            type: notificationTypes.WARNING,
            action: ({
              currentBoard,
              actualSociety,
              announcementTemplateDoc,
            }) => {
              store.dispatch(
                setModal(
                  <RetryAnnouncementModal
                    currentBoard={currentBoard}
                    actualSociety={actualSociety}
                    announcementTemplateDoc={announcementTemplateDoc}
                    recipients={notDeliveredRecipients}
                  />
                )
              );
            },
            actionData: {
              currentBoard: board,
              actualSociety,
              announcementTemplateDoc,
            },
            actionText: 'ReviewSending',
          })
        );
      }
    } else {
      dispatch(hideNotification());
    }
  };

export default validateOutdatedSocietyValue;
