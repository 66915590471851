import Swal from 'sweetalert2';
import { store } from 'redux/store';
import { shareDocument } from 'modules/documents/redux/documentActions';

const documentShare = async (document, documentId) => {
  const { user } = store.getState();
  const { society } = store.getState();

  const { value: email } = await Swal.fire({
    title: '<h4 class="nk-modal-title">Compartir plantilla</h4>',
    html: '<div class="caption-text">Introduzca un correo electrónico</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>El destinatario recibirá un mail con este documento</p>
    </div></div>`,
    input: 'email',
    inputPlaceholder: 'Enter your email address',
    confirmButtonText: 'Enviar',
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  });

  if (email) {
    store.dispatch(
      shareDocument({
        email,
        user: `${user.firstName} ${user.lastName || ''}`,
        society: society.actualSociety.name,
        document,
        documentId,
      })
    );
  }
};

export default documentShare;
