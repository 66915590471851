export default {
  SUCCESS: {
    icon: 'success',
    iconColor: '#a5dc86',
    iconHtml:
      '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>',
  },
  INFO: {
    icon: 'info',
    iconColor: '#3fc3ee',
    iconHtml:
      '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>',
  },
  WARNING: {
    icon: 'warning',
    iconColor: '#f8bb86',
    iconHtml:
      '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-info bg-warning"></em>',
  },
  ERROR: {
    icon: 'error',
    iconColor: '#f27474',
    iconHtml:
      '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>',
  },
  QUESTION: {
    icon: 'question',
    iconColor: '#87adbd',
    iconHtml:
      '<em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-question bg-primary"></em>',
  },
  NONE: {
    icon: '',
    iconColor: 'none',
  },
};
