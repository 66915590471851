import { translate } from 'hooks/useTranslate';

export const getClassesTableRows = (data: any) => {
  const {
    decimalScale,
    totalShares,
    totalNDShares,
    totalNDPercent,
    totalFDShares,
    totalFDPercent,
    totalPFDShares,
    totalPFDPercent,
    stockAssignedShares,
    stockUnassignedShares,
    phantomAssignedShares,
    phantomUnassignedShares,
    inForceCNShares,
  } = data;

  const aditionalRowHeaders = [
    {
      text: 'Warrants/Stock Option',
      style1: 'text-left w-200px fw-bold bg-light ',
      style2: 'text-left w-200px fw-bold',
    },
    {
      text: ` • ${translate('Allocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: ` • ${translate('Unallocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: 'ESOP/Phantom Share',
      style1: 'text-left w-200px fw-bold bg-light ',
      style2: 'text-left w-200px fw-bold',
    },
    {
      text: ` • ${translate('Allocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: ` • ${translate('Unallocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: translate('ConvertibleNotes'),
      style1: 'text-left w-200px fw-bold bg-light ',
      style2: 'text-left w-200px fw-bold',
    },
    {
      text: ` • ${translate('InForce')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
  ];

  const totalsRowHeaders = [
    {
      text: 'TOTAL',
      style1: 'text-left font-weight-bold bg-light',
      style2: 'text-left font-weight-bold',
    },
  ];

  const aditionalRows = [
    {
      text: 'Warrants/Stock Option',
      column1Value: '', // 1 Total Shares
      column2Value: '', // 2 %ND - units
      column3Value: '', // 3 %ND - %
      column4Value: '', // 4 %FD - units
      column5Value: '', // 5 %FD - %
      column6Value: '', // 6 %P.FD - units
      column7Value: '', // 7 %P.FD - %
    },
    {
      text: ` • ${translate('Allocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: stockAssignedShares,
      column5Value: ((stockAssignedShares * 100) / totalFDShares).toFixed(
        decimalScale
      ),
      column6Value: stockAssignedShares,
      column7Value: ((stockAssignedShares * 100) / totalPFDShares).toFixed(
        decimalScale
      ),
    },
    {
      text: ` • ${translate('Unallocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: '',
      column5Value: '',
      column6Value: stockUnassignedShares,
      column7Value: ((stockUnassignedShares * 100) / totalPFDShares).toFixed(
        decimalScale
      ),
    },
    {
      text: 'ESOP/Phantom Share',
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: '',
      column5Value: '',
      column6Value: '',
      column7Value: '',
    },
    {
      text: ` • ${translate('Allocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: phantomAssignedShares,
      column5Value: ((phantomAssignedShares * 100) / totalFDShares).toFixed(
        decimalScale
      ),
      column6Value: phantomAssignedShares,
      column7Value: ((phantomAssignedShares * 100) / totalPFDShares).toFixed(
        decimalScale
      ),
    },
    {
      text: ` • ${translate('Unallocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: '',
      column5Value: '',
      column6Value: phantomUnassignedShares,
      column7Value: ((phantomUnassignedShares * 100) / totalPFDShares).toFixed(
        decimalScale
      ),
    },
    {
      text: translate('ConvertibleNotes'),
      column1Value: '',
      column2Value: '',
      column3Value: '',
    },
    {
      text: ` • ${translate('InForce')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: inForceCNShares,
      column5Value: ((inForceCNShares * 100) / totalFDShares).toFixed(
        decimalScale
      ),
      column6Value: inForceCNShares,
      column7Value: ((inForceCNShares * 100) / totalPFDShares).toFixed(
        decimalScale
      ),
    },
  ];

  const totalsRows = [
    {
      text: 'TOTAL',
      column1Value: totalShares,
      column2Value: totalNDShares,
      column3Value: totalNDPercent,
      column4Value: totalFDShares,
      column5Value: totalFDPercent,
      column6Value: totalPFDShares,
      column7Value: totalPFDPercent,
    },
  ];

  return {
    aditionalRowHeaders,
    totalsRowHeaders,
    aditionalRows,
    totalsRows,
  };
};
