import percentRound from 'percent-round';
import tableRows from 'constants/tableRows';
import { getDashboardData } from 'modules/society/actions/dashBoardActions';

const getPercents = (capitalData) => {
  const {
    societyActualShares,
    stockOptionsUsedShares,
    phantomUsedShares,
    convertibleNotesShares,
    stockOptionsWithDraftsUsedShares,
    phantomWithDraftsUsedShares,
    convertibleNotesSharesDraft,
    totalShares,
    totalSharesDrafts,
  } = capitalData;

  const percents = totalShares
    ? [
        (societyActualShares * 100) / totalShares,
        (convertibleNotesShares * 100) / totalShares,
        (stockOptionsUsedShares * 100) / totalShares,
        (phantomUsedShares * 100) / totalShares,
      ]
    : [0, 0, 0, 0];
  const percentsWithDrafts = totalSharesDrafts
    ? [
        (societyActualShares * 100) / totalSharesDrafts,
        (convertibleNotesSharesDraft * 100) / totalSharesDrafts,
        (stockOptionsWithDraftsUsedShares * 100) / totalSharesDrafts,
        (phantomWithDraftsUsedShares * 100) / totalSharesDrafts,
      ]
    : [0, 0, 0, 0];

  const roundPercents = percentRound(percents, 2);
  const roundPercentsWithDrafts = percentRound(percentsWithDrafts, 2);

  return { roundPercents, roundPercentsWithDrafts };
};
const getResultData = (capitalData) => {
  const {
    societyActualShares,
    stockOptionsUsedShares,
    phantomUsedShares,
    convertibleNotesShares,
    stockOptionsWithDraftsUsedShares,
    phantomWithDraftsUsedShares,
    convertibleNotesSharesDraft,
    totalShares,
    totalSharesDrafts,
  } = capitalData;

  const { roundPercents, roundPercentsWithDrafts } = getPercents(capitalData);

  return [
    // NO DRAFTS
    {
      type: tableRows.TEXT,
      name: 'Shares',
      value1: societyActualShares,
      value2: roundPercents[0],
    },
    {
      type: tableRows.TEXT,
      name: 'ConvertibleLoans',
      value1: convertibleNotesShares,
      value2: roundPercents[1],
    },
    {
      type: tableRows.TEXT,
      name: 'Stockoptions',
      value1: stockOptionsUsedShares,
      value2: roundPercents[2],
    },
    {
      type: tableRows.TEXT,
      name: 'Phantomshares',
      value1: phantomUsedShares,
      value2: roundPercents[3],
    },
    { type: tableRows.TOTAL, name: 'TOTAL', value1: totalShares, value2: 100 },
    { type: tableRows.DIVIDER },
    // WITH DRAFTS
    {
      type: tableRows.TITLE,
      name: 'CapitalIncludingDrafts',
      value1: 'CapitalIncludingDraftsTooltip',
    },
    {
      type: tableRows.TEXT,
      name: 'Shares',
      value1: societyActualShares,
      value2: roundPercentsWithDrafts[0],
    },
    {
      type: tableRows.TEXT,
      name: 'ConvertibleLoans',
      value1: convertibleNotesSharesDraft,
      value2: roundPercentsWithDrafts[1],
    },
    {
      type: tableRows.TEXT,
      name: 'Stockoptions',
      value1: stockOptionsWithDraftsUsedShares,
      value2: roundPercentsWithDrafts[2],
    },
    {
      type: tableRows.TEXT,
      name: 'Phantomshares',
      value1: phantomWithDraftsUsedShares,
      value2: roundPercentsWithDrafts[3],
    },
    {
      type: tableRows.TOTAL,
      name: 'TOTAL',
      value1: totalSharesDrafts,
      value2: 100,
    },
  ];
};
export const getCapitalViewData = async (societyId, dispatch) => {
  const capitalData = await dispatch(getDashboardData(societyId, 'capital'));

  const resultData = getResultData(capitalData);
  return resultData;
};
