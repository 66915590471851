import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  validEmails: any;
  handleUpdatePartner: any;
};

const EditPartnerModalFooter: FC<Props> = ({
  validEmails,
  handleUpdatePartner,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={handleUpdatePartner}
          disabled={!validEmails()}
        >
          {t('SaveChanges')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default EditPartnerModalFooter;
