function notificationOptions(i18n: any) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('Open'),
      url: '#',
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      url: '#',
    },
  ];
}
export default notificationOptions;
