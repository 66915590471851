import tags from '../../EditorWYSIWYG/tags';

type GetTagsProps = {
  tagsToShow?: any;
  groupTags?: boolean;
};

export default function getTags({
  tagsToShow,
  groupTags = false,
}: GetTagsProps) {
  const defaultTags = {
    plan: {},
    partner: {},
    society: {},
    beneficiary: {},
    board: {},
    partnerRepresentants: {},
    signatures: {},
    tenderOffer: {},
    tenderOfferShares: {},
    operation: {},
    holdingClasses: {},
    annualValue: {},
  };

  const tagsValues = tags(tagsToShow || defaultTags);

  const resultTags: any[] = [];

  tagsValues.forEach((tag) => {
    if (groupTags && tag.menu.length) {
      resultTags.push({
        id: tag.title,
        text: tag.title.toUpperCase(),
        label: `📁 ${tag.title.toUpperCase()}`,
        disabled: true,
      });
    }

    tag.menu.forEach((tagValue: any) => {
      if (tagValue?.title) {
        const tagId = `{${tagValue.title}}`;
        const tagText = `{${tagValue.title}}`;
        const tagLabel = tagValue.title;

        resultTags.push({
          id: tagId,
          text: tagText,
          label: tagLabel,
        });
      }
    });
  });

  return resultTags;
}
