/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-wrap-multilines */
import { FC, useEffect, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { Partner, PartnerRepresentant, Society } from 'types';
import PartnersRepresentedTable from '../PartnersRepresentedTable';

type PartnerRepresentantSocietyProps = {
  society: Society;
  partnerRepresentant: PartnerRepresentant;
};

const PartnerRepresentantSociety: FC<PartnerRepresentantSocietyProps> = ({
  society,
  partnerRepresentant,
}) => {
  const { t } = useTranslate();
  const [partners, setPartners] = useState<Partner[]>([]);

  const { _id, name } = partnerRepresentant;

  useEffect(() => {
    if (_id && society?.partners?.length) {
      setPartners(
        society.partners.filter((partner) => partner?.sindication === _id)
      );
    }
  }, [_id, society.partners]);

  return (
    <section className="card-inner">
      <div className="card-inner-group" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div
              className="simplebar-offset"
              style={{ right: '0px', bottom: '0px' }}
            >
              <div
                className="simplebar-content-wrapper"
                style={{ height: 'auto' }}
              >
                <div className="simplebar-content" style={{ padding: '0px' }}>
                  <div className="card-inner">
                    <div className="user-card user-card-s2">
                      <div className="user-avatar lg bg-primary">
                        {society.additional.logo ? (
                          <img src={society.additional.logo} alt="Logo" />
                        ) : (
                          <span>{society.name.slice(0, 2).toUpperCase()}</span>
                        )}
                      </div>

                      <div className="user-info">
                        <h5>{name}</h5>
                        <div className="badge badge-outline badge-pill text-primary ucap">
                          {t('PartnerRepresentant')}
                        </div>
                        <span className="sub-text">
                          {t('HeIsPartnerRepresentantOfSocietyName', {
                            societyName: society.name,
                          })}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="card-inner">
                    <PartnersRepresentedTable
                      title={t('ListOfRepresentedPartners')}
                      partners={partners}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 'auto', height: '500px' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: '0px', display: 'none' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ height: '0px', display: 'none' }}
          />
        </div>
      </div>

      <div className="nk-block" />
      <div className="nk-divider divider md" />
    </section>
  );
};

export default PartnerRepresentantSociety;
