import { Partner, Society, User } from 'types';

type GetSharePartnerEmailProps = {
  t: any;
  language: string;
  user: User;
  email: string;
  actualSociety: Society;
  partner: Partner;
};

const getSharePartnerEmailData = (props: GetSharePartnerEmailProps) => {
  const { t, language, email, actualSociety, partner } = props;

  return {
    to: [{ email, name: 'Invitado' }],
    subject: t('MailSharePartnerSubject'),
    template: `share-partner-access-${language}`,
    templateData: [
      {
        name: 'partnerName',
        content: partner?.name || '',
      },
      {
        name: 'societyName',
        content: actualSociety?.name || '',
      },
      {
        name: 'targetUrl',
        content: `${process.env.REACT_APP_DOMAIN}/#/detalle-socio/${actualSociety?._id}/${partner?._id}`,
      },
    ],
  };
};

export const createShareEmail = (props: GetSharePartnerEmailProps) => {
  const { user, actualSociety } = props;

  const baseEmailData = {
    user: user['_id'],
    society: actualSociety['_id'],
    date: new Date(),
    status: 'SENT',
  };

  const emailData = getSharePartnerEmailData(props);

  return {
    ...baseEmailData,
    ...emailData,
  };
};
