/* eslint-disable no-alert */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import Logo from './account.svg';

const AdminCenter = () => (
  <>
    <div className="nk-content-body">
      <h1>
        <img
          src={Logo}
          alt="Logo"
          style={{ height: '70px', marginRight: '20px' }}
        />
        4dmin C3nt3r
      </h1>
      <p>Processing data</p>
    </div>
  </>
);

export default AdminCenter;
