/* eslint-disable no-underscore-dangle */
/* eslint-disable no-new */
/* eslint-disable import/no-extraneous-dependencies */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getHolderSocieties } from 'modules/society/redux/societyActions';

import diagramTypes from 'constants/diagramTypes';
import menuTypes from 'constants/menuTypes';

import { getActualSociety } from 'utils/filters';

import PrintButton from 'modules/_shared/components/Buttons/PrintButton';
import ToggleWidthButton from 'modules/_shared/components/Buttons/ToggleWidthButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PrintHeader from 'modules/_shared/components/Headers/PrintHeader';
import OrganizationChart from 'modules/society/components/OrganizationChart';

import getHoldingInfoForChart from './utils/getHoldingInfoForChart';

import './ChartJointJS.scss';

const portfolioFilters = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  EXIT: 'EXIT',
};

const OrgChartLayoutView = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const printRef = useRef();

  const { user, actualSociety } = useSelector((state) => ({
    hasAccessCaptable: state.society?.actualSociety?.hasAccessCaptable,
    actualSociety: state.society?.actualSociety,
    plans: state?.plans,
    user: state?.user,
  }));

  const [societies, setSocieties] = useState([]);
  const [updatedSocieties, setUpdatedSocieties] = useState([]);
  const [filterBy, setFilterBy] = useState(portfolioFilters.ACTIVE);
  const [isLoading, setIsLoading] = useState(true);

  const downloadFileName = `${t('PrintHeaderSocietyDiagram')} - ${
    actualSociety?.name
  }`;

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
  }, [filterBy]); // eslint-disable-line

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety && user && filterBy) {
      getHolderSocieties(
        actualSociety['_id'],
        user['_id'],
        filterBy,
        setSocieties
      ).then(() => {
        setIsLoading(false);
      });
    }
  }, [actualSociety, user, filterBy]);

  useEffect(() => {
    if (societies?.length) {
      const societiesArray = societies?.filter((levelArray) => levelArray);

      const societiesWithHoldingInfo = getHoldingInfoForChart(
        societiesArray,
        actualSociety
      );

      setUpdatedSocieties(societiesWithHoldingInfo);
      setIsLoading(false);
    } else {
      setUpdatedSocieties([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societies]);

  return (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">{t('Portfolio')}</h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {societies?.length === 1
                      ? t('PortfolioNoSocieties', {
                          societyName: actualSociety?.name,
                        })
                      : t('PortfolioHaveSocieties', {
                          societyName: actualSociety?.name,
                          count: updatedSocieties[0]?.length || 0,
                        })}
                  </p>
                </div>
              </div>
              <div className="nk-block-head-content">
                <ul className="nk-block-tools g-3">
                  <li>
                    <div className="form-group cursor-pointer">
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <div className="form-icon form-icon-left">
                            <em
                              className={`icon ni ${
                                filterBy === portfolioFilters.ALL
                                  ? 'ni-filter'
                                  : 'ni-filter-fill'
                              }`}
                            />
                          </div>
                          <select
                            className="form-control pr-4  cursor-pointer"
                            id="filter-society"
                            name="filter-society"
                            value={filterBy}
                            onChange={(event) =>
                              setFilterBy(event.target.value)
                            }
                          >
                            <option
                              value={portfolioFilters.ACTIVE}
                              className="cursor-pointer"
                            >
                              {t('PortfolioFilterActive')}
                            </option>
                            <option
                              value={portfolioFilters.EXIT}
                              className="cursor-pointer"
                            >
                              {t('PortfolioFilterExit')}
                            </option>
                            <option
                              value={portfolioFilters.ALL}
                              className="cursor-pointer"
                            >
                              {t('PortfolioFilterAll')}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <PrintButton
                      fileName={downloadFileName}
                      printRef={printRef}
                    />
                  </li>
                  <li>
                    <ToggleWidthButton variant="light" hideMenu />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div ref={printRef} id="margin-print-only">
            <PrintHeader title={t('PrintHeaderSocietyDiagram')} />

            {updatedSocieties?.length > 0 && (
              <OrganizationChart
                actualSociety={{ ...actualSociety, holdingKey: uuidv4() }}
                societies={updatedSocieties}
                diagramType={diagramTypes.HOLDING}
              />
            )}
          </div>
        </div>
      </div>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default OrgChartLayoutView;
