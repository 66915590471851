import React from 'react';
import 'chartjs-adapter-moment';
import {
  Chart as ChartJS,
  LogarithmicScale,
  LinearScale,
  TimeScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  LogarithmicScale,
  LinearScale,
  TimeScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function BarChart({ data, options, height = 0 }) {
  return (
    <div className="chart-container">
      {height ? (
        <Bar data={data} options={options} height={height} />
      ) : (
        <Bar data={data} options={options} />
      )}
    </div>
  );
}

export default BarChart;
