/* eslint-disable no-console */
import { store } from 'redux/store';
import { Board, Society, User } from 'types';

import { updateBoard } from 'modules/boards/redux/boardActions';
import boardTemplateTypes from 'modules/boards/constants/boardTemplateTypes';
import {
  getDocument,
  getDocuments,
} from 'modules/documents/redux/documentActions';

import createDocumentFromHtml from 'utils/createDocumentFromHtml';
import duplicateCurrentTemplate from './duplicateCurrentTemplate';

type ReplaceBoardTemplateProps = {
  board: Board;
  templateType: string;
  selectedTemplate: string | undefined;
  society: Society;
  user: User;
};

async function replaceActTemplate(
  board: Board,
  selectedTemplate: string | undefined
) {
  const newTemplate = await duplicateCurrentTemplate(
    selectedTemplate,
    null,
    board.name
  );
  try {
    await store.dispatch(
      updateBoard(board._id, {
        actTemplate: newTemplate ? newTemplate['_id'] : null,
      })
    );
  } catch (error) {
    // @javierfaife - We should handle this error
    console.error(error);
  }
}

async function replaceCertificationTemplate(
  board: Board,
  selectedTemplate: string | undefined
) {
  const newTemplate = await duplicateCurrentTemplate(
    selectedTemplate,
    null,
    board.name
  );
  try {
    await store.dispatch(
      updateBoard(board._id, {
        actCertificateTemplate: newTemplate ? newTemplate['_id'] : null,
      })
    );
  } catch (error) {
    // @javierfaife - We should handle this error
    console.error(error);
  }
}

async function replaceAnnouncementTemplate(
  board: Board,
  selectedTemplate: string | undefined,
  society: Society,
  user: User
) {
  try {
    const templateDocument = await store.dispatch(
      getDocument(selectedTemplate)
    );
    const editorModel = templateDocument?.editorModel;

    const documentId = await createDocumentFromHtml({
      html: editorModel,
      documentName: templateDocument.name,
      documentDescription: templateDocument.description,
      tagsData: {
        society,
        board,
      },
      society,
      user,
    });

    const newTemplate = await duplicateCurrentTemplate(
      '',
      templateDocument,
      board.name
    );

    await store.dispatch(
      updateBoard(board._id, {
        announcementTemplate: newTemplate ? newTemplate['_id'] : null,
        announcementDocument: documentId,
      })
    );
  } catch (error) {
    // @javierfaife - We should handle this error
    console.error(error);
  }
}

const replaceBoardTemplate = async ({
  board,
  templateType,
  selectedTemplate,
  society,
  user,
}: ReplaceBoardTemplateProps) => {
  switch (templateType) {
    case boardTemplateTypes.ACT:
      replaceActTemplate(board, selectedTemplate);
      break;
    case boardTemplateTypes.CERTIFICATION:
      replaceCertificationTemplate(board, selectedTemplate);
      break;
    case boardTemplateTypes.ANNOUNCEMENT:
      replaceAnnouncementTemplate(board, selectedTemplate, society, user);
      break;
    default:
      break;
  }

  return store.dispatch(getDocuments({ society: society['_id'] }));
};

export default replaceBoardTemplate;
