import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
  isSaved: boolean;
  isAttachmentSaved: boolean;
  handleSave: () => void;
  handleClose: () => void;
  validExtraSigner: () => boolean;
  htmlContent?: string;
  attachmentFile?: File;
};

const LetterTabFooter = ({
  isSaved,
  isAttachmentSaved,
  handleSave,
  handleClose,
  validExtraSigner,
  htmlContent,
  attachmentFile,
}: Props) => {
  const { t } = useTranslation();

  const isValidExtraSigner = validExtraSigner();

  const isDisabled =
    !htmlContent ||
    !isValidExtraSigner ||
    (isSaved && (!attachmentFile || (attachmentFile && isAttachmentSaved)));

  return (
    <Modal.Footer>
      <button
        type="button"
        className="btn btn-primary mr-2"
        onClick={handleSave}
        disabled={isDisabled}
      >
        {t('Save')}
      </button>

      <button type="button" className="btn btn-primary" onClick={handleClose}>
        {t('EndUp')}
      </button>
    </Modal.Footer>
  );
};

export default LetterTabFooter;
