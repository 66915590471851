import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const ShareEncumbranceViewCoOwnersTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <>
      <thead className="table-light">
        <tr>
          <th className="text-left">{t('Name')}</th>
          <th className="text-center">{t('CIF')}</th>
          <th className="text-center">{t('Email')}</th>
          <th className="text-center">%</th>
          <th className="text-center">{t('Comment')}</th>
          <th className="text-center" />
        </tr>
      </thead>
    </>
  );
};

export default ShareEncumbranceViewCoOwnersTableHeader;
