/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(boards = initialState.boards, action) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.boards;

    case actionTypes.CREATE_BOARD:
      return [...boards, action.board];

    case actionTypes.GET_BOARDS:
      return action.data;

    case actionTypes.GET_BOARD:
      return [
        ...boards.filter((board) => board['_id'] !== action.board['_id']),
        action.board,
      ];

    case actionTypes.UPDATE_BOARD:
      return [
        ...boards.filter((board) => board['_id'] !== action.board['_id']),
        action.board,
      ];

    case actionTypes.DELETE_BOARD:
      return [
        ...boards.filter(
          (currentBoard) => currentBoard['_id'] !== action.board['_id']
        ),
      ];

    default:
      return boards;
  }
}
