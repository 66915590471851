import { useEffect, useState } from 'react';

import { Partner, SelectorOption } from 'types';

type Props = {
  partners: Partner[];
};

export function useGetPartnerOptions({ partners }: Props) {
  const [partnerOptions, setPartnerOptions] = useState<SelectorOption[]>([]);

  useEffect(() => {
    const filteredPartners = partners.filter(
      (partner: Partner) => partner?.sharesCount?.actual > 0
    );

    const options = filteredPartners.map((partner: Partner) => ({
      value: partner['_id'],
      label: `${partner?.cif} | ${partner?.name}`,
    }));

    setPartnerOptions(options);
  }, [partners]);

  return {
    data: { partnerOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
