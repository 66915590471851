import { SocietyRepresentative } from 'types';
import { formatCurrency } from 'utils/formats';

export const formatRepresentativePoaLimits = (
  representative: SocietyRepresentative,
  translate: any
): string => {
  const { representativeType, maximumAmount } = representative || {
    representativeType: null,
    maximumAmount: 0,
  };

  const representativeTypeText = representativeType
    ? `${translate(representativeType)}`
    : '';
  const limitText = maximumAmount
    ? `${translate('Limit')}: ${formatCurrency(maximumAmount)}`
    : '-';

  return [representativeTypeText, limitText].join(' / ');
};
