import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import '../SideNav.scss';
import MenuItem from './MenuItem';

type Props = {
  getBoardLinkByRole: () => string;
};

const BoardsMenu: FC<Props> = ({ getBoardLinkByRole }) => {
  const { t } = useTranslate();

  return (
    <>
      <Accordion.Toggle as={Button} variant="link" eventKey="7">
        <li className="nk-menu-item has-sub">
          <a className="nk-menu-link nk-menu-toggle">
            <span className="nk-menu-icon">
              <em className="icon ni ni-chat-circle" />
            </span>
            <span className="nk-menu-text">{t('BoardCouncil')}</span>
          </a>
        </li>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey="7">
        <ul className="nk-menu-sub">
          <MenuItem linkTo={getBoardLinkByRole()} text="Boards" />
        </ul>
      </Accordion.Collapse>
    </>
  );
};

export default BoardsMenu;
