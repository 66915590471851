/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(
  committees = initialState.committees,
  action: any
) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.committees;

    case actionTypes.CREATE_COMMITTEE:
      if (action.committee) {
        return [...committees, action.committee];
      }
      return [...committees];

    case actionTypes.GET_COMMITTEES: {
      return action.committees;
    }
    case actionTypes.UPDATE_COMMITTEE:
      const currentId = action.committee['_id'];

      const updatedCommittes = committees?.map((committee) => {
        if (committee['_id'] === currentId) {
          return action.committee;
        }
        return committee;
      });
      return [...updatedCommittes];

    case actionTypes.DELETE_COMMITTEE:
      return [
        ...committees.filter(
          (currentCommitte) =>
            currentCommitte['_id'] !== action.committee['_id']
        ),
      ];

    default:
      return committees;
  }
}
