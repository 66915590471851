/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import downloadXLS from 'utils/downloadXLS';

import '../Modals.scss';
import sendReminder from './sendReminder';

function BeneficiariesPendingSignature({ beneficiaries }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society.actualSociety);

  const [downloadData, setDownloadData] = React.useState(null);

  const handleDownloadList = () => {
    const downloadFileName = `${t('InvitationLettersPendingOfSignature')}.xlsx`;
    const downloadTabName = format(new Date(), 'yyyy-MM-dd');

    downloadXLS(downloadData, downloadFileName, downloadTabName);
  };

  const handleSendReminder = () => {
    sendReminder({
      t,
      user,
      actualSociety,
      beneficiaries,
    });
  };

  useEffect(() => {
    if (beneficiaries?.length) {
      const data = beneficiaries.map((beneficiary) => ({
        Nombre: beneficiary.name,
        Email: beneficiary.email,
        Plan: beneficiary.planName,
        Estado: t(beneficiary.invitationStatus),
      }));
      setDownloadData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiaries]);

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-flex justify-content-between">
        <h5 className="modal-title">
          {`${beneficiaries?.length || 0} ${t('beneficiaries')}`}
        </h5>

        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <div className="nk-block-head-content mb-3">
              <ul className="nk-block-tools justify-content-md-end g-3 flex-wrap">
                <li className="order-md-last">
                  <button
                    type="button"
                    className="btn btn-white btn-outline-light ml-5"
                    onClick={handleDownloadList}
                  >
                    <em className="icon ni ni-download-cloud" />
                    <span>{t('DownloadListing')}</span>
                  </button>
                </li>

                <li className="order-md-last">
                  <button
                    type="button"
                    className="btn btn-dim btn-primary"
                    disabled={beneficiaries.length === 0}
                    onClick={handleSendReminder}
                  >
                    <em className="icon ni ni-send" />
                    <span>{t('SendReminder')}</span>
                  </button>
                </li>
              </ul>
            </div>
            <table className="table table-tranx">
              <thead>
                <tr className="tb-tnx-head">
                  <th className="nk-tb-col p-3">
                    <span>Nº</span>
                  </th>
                  <th className="nk-tb-col p-3">
                    <span>{t('Name')}</span>
                  </th>
                  <th className="nk-tb-col p-3">
                    <span>{t('E-Mail')}</span>
                  </th>
                  <th className="nk-tb-col p-3">
                    <span>{t('Plan')}</span>
                  </th>
                  <th className="nk-tb-col text-right p-3 pr-4">
                    <span>{t('Status')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {beneficiaries?.map((beneficiary, index) => (
                  <tr className="tb-tnx-item" key={beneficiary?.['_id']}>
                    <td className="tb-tnx-id">
                      <span>{index + 1}</span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info w-100">
                        {beneficiary?.name || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info w-100">
                        {beneficiary?.email || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info w-100">
                        {beneficiary?.planName || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info text-right">
                      <span className="badge badge-dot badge-warning">
                        {t(beneficiary?.invitationStatus)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default BeneficiariesPendingSignature;
