import { Society, TenderOfferParticipant } from 'types';
import { tenderOfferParticipantStatus } from 'utils/tenderOfferTypes';

type Participant = TenderOfferParticipant & {
  tenderOfferId: string;
};

const getTenderParticipantsByPlan = (
  tenderOffers: any,
  society: Society,
  planId = ''
): Participant[] => {
  if (!tenderOffers || !society) return [];

  const tenderOfferParticipants = tenderOffers
    ?.map((offer: any) => {
      const { participants } = offer;

      return participants?.map((participant: any) => ({
        ...participant,
        tenderOfferId: offer._id,
      }));
    })
    .flat();

  const participantsFiltered = tenderOfferParticipants?.filter(
    (participant: Participant) =>
      participant.status === tenderOfferParticipantStatus.ACCEPTED &&
      participant.type === 'BENEFICIARY'
  );

  const planBeneficiariesId =
    society?.beneficiaries
      ?.filter((beneficiary: any) => beneficiary?.plan === planId)
      ?.map((beneficiary: any) => beneficiary?.['_id']) || [];

  const result = participantsFiltered?.filter((participant: Participant) =>
    planBeneficiariesId.includes(participant?.id)
  );

  return result;
};

export default getTenderParticipantsByPlan;
