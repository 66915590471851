import { CoOwner, Encumbrance, ScriptureData } from 'types';

type Props = {
  encumbrances: Encumbrance[];
  editingIndex: number;
  encumbranceName: string;
  encumbranceDate: string;
  encumbranceFrom: number;
  encumbranceTo: number;
  encumbranceScriptureData: ScriptureData;
  encumbranceFile: File | undefined;
  coOwners: CoOwner[];
};

export default function editEncumbrance({
  encumbrances,
  editingIndex,
  encumbranceName,
  encumbranceDate,
  encumbranceFrom,
  encumbranceTo,
  encumbranceScriptureData,
  encumbranceFile,
  coOwners,
}: Props) {
  const encumbrance = encumbrances[editingIndex];
  const newEncumbrances = [
    ...encumbrances?.slice(0, editingIndex),
    {
      ...encumbrance,
      name: encumbranceName,
      date: encumbranceDate,
      from: encumbranceFrom,
      to: encumbranceTo,
      scriptureData: encumbranceScriptureData,
      file: encumbranceFile,
      coOwners,
    },
    ...encumbrances?.slice(editingIndex + 1),
  ];
  return newEncumbrances;
}
