import { useTranslate } from 'hooks/useTranslate';
import React from 'react';

type Props = {
  naturalPerson: boolean;
  handleChangePersonValue: (event: React.ChangeEvent<HTMLInputElement>) => void; // eslint-disable-line no-unused-vars
};

const EditModalHeaderOptions = ({
  naturalPerson,
  handleChangePersonValue,
}: Props) => {
  const { t } = useTranslate();

  return (
    <>
      <div className="custom-control custom-radio mx-4">
        <input
          type="radio"
          className="custom-control-input"
          name="natural-person"
          id="natural-person"
          checked={naturalPerson}
          onChange={handleChangePersonValue}
        />
        <label className="custom-control-label" htmlFor="natural-person">
          {t('NaturalPerson')}
        </label>
      </div>

      <div className="custom-control custom-radio mx-4">
        <input
          type="radio"
          className="custom-control-input"
          name="legal-person"
          id="legal-person"
          checked={!naturalPerson}
          onChange={handleChangePersonValue}
        />
        <label className="custom-control-label" htmlFor="legal-person">
          {t('LegalPerson')}
        </label>
      </div>
    </>
  );
};

export default EditModalHeaderOptions;
