/* eslint-disable import/prefer-default-export */
import fileTypes from 'constants/fileTypes';
import pdfIcon from 'assets/images/file-types/pdf.svg';
import docIcon from 'assets/images/file-types/doc.svg';
import xlsIcon from 'assets/images/file-types/xls.svg';
import pptIcon from 'assets/images/file-types/ppt.svg';
import templateIcon from 'assets/images/file-types/templates.svg';
import defaultIcon from 'assets/images/file-types/default.svg';

export const getDocumentIcon = (document, rawFile = false) => {
  // Depending on whether it is a raw file or not, have type or fileType
  const type = rawFile ? fileTypes[document?.type] : document?.fileType;

  switch (type) {
    case fileTypes.PDF:
      return pdfIcon;

    case fileTypes.DOC:
    case fileTypes.DOCX:
      return docIcon;

    case fileTypes.XLS:
    case fileTypes.XLSX:
      return xlsIcon;

    case fileTypes.PPT:
    case fileTypes.PPTX:
      return pptIcon;

    case fileTypes.TEMPLATE:
      return document?.subcategory === 'DOCUMENTS' ? docIcon : templateIcon;

    default: {
      return defaultIcon;
    }
  }
};
