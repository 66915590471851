import bigDecimal from 'js-big-decimal';

type Props = {
  units: number;
  totalShares: number;
};

export const getPercentFromShares = ({ units, totalShares }: Props) => {
  if (!totalShares) return 0;

  const resultPercent = +bigDecimal.divide(units * 100, totalShares, 2);
  return resultPercent;
};
