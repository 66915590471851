import { getEnvelopesByReference } from 'modules/_shared/redux/envelopeActions';
import { useEffect, useState } from 'react';
import { store } from 'redux/store';

import { Board, Envelope } from 'types';

export default function useGetBoardEnvelopes(board: Board) {
  const [boardEnvelopes, setBoardEnvelopes] = useState<Envelope[]>([]);

  useEffect(() => {
    const getBoardEnvelopes = async () => {
      try {
        const envelopes = await store.dispatch(
          getEnvelopesByReference({ boardId: board._id })
        );
        setBoardEnvelopes(envelopes);
      } catch (error) {
        console.log(error);
      }
    };

    getBoardEnvelopes();
  }, [board]);

  return { boardEnvelopes };
}
