const sortEmailsAndCategories = (emails: string[], categories: string[]) => {
  const combined = emails.map((email, index) => ({
    email,
    category: categories[index],
  }));

  combined.sort((a, b) => a.category.localeCompare(b.category));

  const sortedEmails = combined.map((item) => item.email);
  const sortedCategories = combined.map((item) => item.category);

  return { sortedEmails, sortedCategories };
};

export default sortEmailsAndCategories;
