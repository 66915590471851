import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { CommitteeMember as OriginalCommitteeMember } from 'types';

import axios from '../../../interceptors/axios';

type CommitteeMember = Omit<OriginalCommitteeMember, '_id'>;

export const createCommitteeMember =
  (committeeMemberData: CommitteeMember) => async (dispatch: any) => {
    try {
      const body = { ...committeeMemberData };
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/committeeMembers`,
        body
      );
      const decodedData = await decryptResponse(data);
      dispatch({
        type: types.CREATE_COMMITTEE,
        committee: decodedData,
      });
      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
