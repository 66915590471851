/* eslint-disable import/prefer-default-export */
/* eslint-disable no-bitwise */
import {
  APP_THEME_FF,
  DEFAULT_APP_THEME,
  DEFAULT_CONTENT_PADDING,
  DEFAULT_HEADER_HEIGHT,
} from 'constants/defaultConstants';
import { Theme } from 'modules/_shared/types/Theme';

export const applyGlobalTheme = (theme: Theme): void => {
  const currentTheme = theme?.customTheme || 'default';

  document.querySelector('body')?.setAttribute('custom-theme', currentTheme);
};

function darkenColor(hex: string, percent: number) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  const adjust = (100 - percent) / 100;

  r = Math.floor(r * adjust);
  g = Math.floor(g * adjust);
  b = Math.floor(b * adjust);

  const newR = r.toString(16).padStart(2, '0');
  const newG = g.toString(16).padStart(2, '0');
  const newB = b.toString(16).padStart(2, '0');

  return `#${newR}${newG}${newB}`;
}

function lightenColor(color: string, percent: number) {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const G = ((num >> 8) & 0x00ff) + amt;
  const B = (num & 0x0000ff) + amt;

  const newR = (R < 255 ? R : 255).toString(16).padStart(2, '0');
  const newG = (G < 255 ? G : 255).toString(16).padStart(2, '0');
  const newB = (B < 255 ? B : 255).toString(16).padStart(2, '0');

  return `#${newR}${newG}${newB}`;
}

export const updatePrimaryColor = (color: string): void => {
  const lightColor = lightenColor(color, 15);
  const darkColor = darkenColor(color, 20);
  const darkerColor = darkenColor(color, 30);
  document.documentElement.style.setProperty('--primary', color);
  document.documentElement.style.setProperty('--primary-light', lightColor);
  document.documentElement.style.setProperty('--primary-dark', darkColor);
  document.documentElement.style.setProperty('--primary-darker', darkerColor);
};

export const getTheme = (): string =>
  localStorage.getItem(APP_THEME_FF) || DEFAULT_APP_THEME;

export const getThemeBackgroundColor = (data: Record<string, any>): string =>
  data?.selectedTheme === 'light' ? data?.theme?.headerColor : '';

export const getThemeLogoLink = (data: Record<string, any>): any => {
  const { hasWhiteBranding, hasCustomLanding, theme, societyId } = data;

  if (hasCustomLanding) {
    return `/home/${societyId}`;
  }

  if (hasWhiteBranding && theme?.logoHref) {
    return theme.logoHref;
  }

  if (hasWhiteBranding) {
    return `/sociedad/${societyId}`;
  }

  return '/';
};

export const getThemeLogoLinkTarget = (data: Record<string, any>): string =>
  data?.hasWhiteBranding && data?.theme?.logoHref ? '_blank' : '_self';

export const getThemeHeaderFullWidth = (data: Record<string, any>): boolean =>
  data?.theme?.headerFullWidth || false;

export const getThemeContentPadding = (theme: Theme): number => {
  if (theme?.headerHeight && theme.headerHeight > DEFAULT_HEADER_HEIGHT) {
    const diff = theme.headerHeight - DEFAULT_HEADER_HEIGHT;
    return DEFAULT_CONTENT_PADDING + diff;
  }

  return DEFAULT_CONTENT_PADDING;
};

export const getHeaderMenuTextColor = (
  data: Record<string, any>,
  themeKey = 'headerTextColor'
) => ({
  color: data?.theme?.[themeKey],
});

export const getHeaderMenuBackgroundColor = (data: Record<string, any>) => ({
  backgroundColor:
    data?.selectedTheme === 'light' ? data?.theme?.headerColor : '',
});

/**
 * These colors are declarated in Styles.scss
 */
export const themeColors = {
  light: {
    BODY_BACKGROUND: '#FFFFFF',
    BODY_BACKGROUND_SECONDARY: '#EAECED',
    BODY_COLOR: '#526484',
    BODY_TEXT_DISABLED: '#D3D3D3',
    CARD_BORDERED: 'rgb(219, 233, 234)',
    BORDER_COLOR: '#D9D9D9',
    WAVE_BACKGROUND: '#FFFFFF',
    WAVE_BACKGROUND_70: 'rgba(255, 255, 255, 0.7)',
    WAVE_BACKGROUND_50: 'rgba(255, 255, 255, 0.5)',
    WAVE_BACKGROUND_30: 'rgba(255, 255, 255, 0.3)',
  },
  dark: {
    BODY_BACKGROUND: '#101924',
    BODY_BACKGROUND_SECONDARY: 'rgb(32, 50, 71)',
    BODY_COLOR: '#FFFFFF',
    BODY_TEXT_DISABLED: '#343A45',
    CARD_BORDERED: 'rgb(32, 50, 71)',
    BORDER_COLOR: 'rgb(32, 50, 71)',
    WAVE_BACKGROUND: '#18212d',
    WAVE_BACKGROUND_70: 'rgba(24, 33, 45, 0.7)',
    WAVE_BACKGROUND_50: 'rgba(24, 33, 45, 0.5)',
    WAVE_BACKGROUND_30: 'rgba(24, 33, 45, 0.3)',
  },
};
