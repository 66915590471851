import React from 'react';

import './VimeoVideoPlayer.scss';

type VimeoVideoProps = {
  videoId: string;
};

const VimeoVideoPlayer = (props: VimeoVideoProps) => {
  const { videoId } = props;
  const src = `https://player.vimeo.com/video/${videoId}`;

  return (
    <div className="video-responsive">
      <iframe
        title="video"
        src={src}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        style={{
          width: '100% !important',
          height: 'auto !important',
        }}
      />
    </div>
  );
};

export default VimeoVideoPlayer;
