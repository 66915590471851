import operationTypes from 'constants/operationTypes';

const headersTextSell = {
  title: 'SaleOfShares',
  transmittingDate: 'TransmissionDate',
  transmittingPartner: 'PartnerThatSells',
  transmittingSharesNumber: 'NºParticipationsThatSells',
  transmitAllShares: 'Sell​​AllParticipations',
  recipientPartner: 'MemberWhoBuys',
  addTransmit: 'AddAnotherSale',
};
const headersTextHeritage = {
  title: 'Heritage',
  transmittingDate: 'TransmissionDate',
  transmittingPartner: 'TransmittingPartner',
  transmittingSharesNumber: 'TransmittingSharesNumber',
  transmitAllShares: 'TransmitAllShares',
  recipientPartner: 'RecipientHeritagePartner',
  addTransmit: 'AddHeritage',
};
const headersTextDonation = {
  title: 'Donation',
  transmittingDate: 'DonationDate',
  transmittingPartner: 'DonationPartner',
  transmittingSharesNumber: 'DonationSharesNumber',
  transmitAllShares: 'DonateAllShares',
  recipientPartner: 'RecipientDonationPartner',
  addTransmit: 'AddDonation',
};

export default function getHeadersText(operationType: string) {
  switch (operationType) {
    case operationTypes.SELL_PARTICIPATION:
      return headersTextSell;
    case operationTypes.HERITAGE:
      return headersTextHeritage;
    case operationTypes.DONATION:
      return headersTextDonation;
    default:
      return headersTextSell;
  }
}
