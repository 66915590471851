/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';

import { getPartnerDividends } from 'utils/filters';
import { formatCurrencyDecimals } from 'utils/formats';

import PartnerDividendsRow from 'modules/partners/components/PartnerDividendsRow';

const PartnerDividends = ({
  partner,
  society,
  showTitle = true,
  summaryView = false,
}) => {
  const { t } = useTranslate();

  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);

  const [dividends, setDividends] = useState([]);

  useEffect(() => {
    if (dividends.length) {
      setRows(
        dividends
          ?.reverse()
          ?.map((dividend, index) => (
            <PartnerDividendsRow
              key={dividend._id}
              dividend={dividend}
              index={dividends.length - index}
              summaryView={summaryView}
            />
          )) || []
      );
      setTotal(
        dividends?.reduce((acc, dividend) => acc + dividend.amount, 0) || 0
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dividends]);

  useEffect(() => {
    setDividends(getPartnerDividends(partner._id, society));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showTitle && (
        <h6 className="overline-title-alt mb-2">
          {t('DividendsDistribution')}
        </h6>
      )}
      <div className="card card-bordered card-preview">
        <table
          className={`table table-striped ${
            summaryView ? 'table-font-sm' : ''
          }`}
        >
          <thead>
            <tr>
              <th
                scope="col"
                className="text-left d-none d-md-table-cell w-20px"
              >
                {t('Nr')}
              </th>
              <th scope="col" className="text-center text-md-left">
                {t('Date')}
              </th>
              <th scope="col" className="text-center d-none d-md-table-cell">
                {t('DividendsTotalAmount')}
              </th>
              <th scope="col" className="text-center">
                {t('%ND')}
              </th>
              <th scope="col" className="text-center d-none d-md-table-cell">
                {t('GrossAmount')}
              </th>
              <th scope="col" className="text-center d-none d-md-table-cell">
                {t('%Retention')}
              </th>
              <th scope="col" className="text-right  pr-2 pr-md-3">
                {t('ToPerceive')}
              </th>
              {!summaryView && (
                <>
                  <th
                    scope="col"
                    className="text-left d-none d-md-table-cell"
                    style={{ width: '20px !important' }}
                  >
                    {t('Doc.')}
                  </th>
                  <th
                    scope="col"
                    className="text-left d-table-cell d-md-none"
                    style={{ width: '20px !important' }}
                  />
                </>
              )}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
          <tfoot>
            <tr>
              <td className="pl-2 pl-md-3 fw-bold w-20px">{t('Total')}</td>
              <td className="" />
              <td className=" d-none d-md-table-cell" />
              <td className=" d-none d-md-table-cell" />
              <td className=" d-none d-md-table-cell" />
              <td className=" d-none d-md-table-cell" />
              <td className="fw-bold text-right pr-2 pr-md-3">
                {formatCurrencyDecimals(total)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export default PartnerDividends;
