import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  FC,
  ReactNode,
} from 'react';

type ContextProps = {
  darkTheme: boolean;
  setDarkTheme: (darkTheme: boolean) => void;
  toggleTheme: () => void;
};

type ThemeContextProviderProps = {
  children: ReactNode;
};

const ThemeContext = createContext<ContextProps>({
  darkTheme: false,
  setDarkTheme: () => {},
  toggleTheme: () => {},
});

export const ThemeProvider: FC<ThemeContextProviderProps> = ({ children }) => {
  const [darkTheme, setDarkTheme] = useState(
    () => localStorage.getItem('app-theme') === 'dark'
  );

  const setMode = (mode: boolean) => {
    localStorage.setItem('app-theme', mode ? 'dark' : 'light');
    setDarkTheme(mode);
    document
      .querySelector('body')
      ?.setAttribute('data-theme', mode ? 'dark' : 'light');
    document.querySelector('body')?.classList.toggle('dark-mode', mode);
    document.querySelector('.app-header')?.classList.toggle('is-dark', mode);
    document.querySelector('.app-header')?.classList.toggle('is-light', !mode);
    window.dispatchEvent(new Event('storage'));
  };

  const toggleTheme = useCallback(() => {
    setMode(!darkTheme);
  }, [darkTheme]);

  const value = {
    darkTheme,
    toggleTheme,
    setDarkTheme,
  };

  useEffect(() => {
    setMode(darkTheme);
  }, [darkTheme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeContext;
