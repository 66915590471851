import { useContext } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import starsIcon from 'assets/images/icon-stars.png';
import SocietyFormAiImage from 'assets/images/society-form-ai.gif';
import SocietyFormAiImageDark from 'assets/images/society-form-ai-dark.gif';
import SocietyFormManualImage from 'assets/images/society-form-manual.gif';
import SocietyFormManualImageDark from 'assets/images/society-form-manual-dark.gif';

import { hasShowDetails } from 'utils/showDetails';
import ThemeContext from 'modules/_shared/contexts/ThemeContext';

const manualImage = {
  DARK: SocietyFormManualImageDark,
  LIGHT: SocietyFormManualImage,
};

const aiImage = {
  DARK: SocietyFormAiImageDark,
  LIGHT: SocietyFormAiImage,
};

type Props = {
  onSelectManualForm: () => void;
  onSelectAIForm: () => void;
};

const SocietiesAddSelector = ({
  onSelectManualForm,
  onSelectAIForm,
}: Props) => {
  const { t } = useTranslate();
  const { darkTheme } = useContext(ThemeContext);

  const handleSelectManual = () => {
    if (onSelectManualForm) onSelectManualForm();
  };

  const handleSelectAI = () => {
    if (onSelectAIForm) onSelectAIForm();
  };

  return (
    <div className="d-flex flex-column flex-md-row justify-content-between g-5">
      {/* MANUAL REGISTRATION */}
      <div
        className="flex-item"
        style={{ flex: 1, order: hasShowDetails() ? 2 : 1 }}
      >
        <div className="card card-bordered wizard--final-button pt-4 flex-column">
          <img
            src={darkTheme ? manualImage.DARK : manualImage.LIGHT}
            className="card-img-top h-250px mx-auto w-fit-content"
            alt="Use society demo"
          />
          <div className="card-inner">
            <h5 className="card-title">{t('AddSocietyManualFormTitle')}</h5>
            <p className="card-text">{t('AddSocietyManualFormMessage')}</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSelectManual}
            >
              Seleccionar
              <em className="icon ni ni-chevron-right ml-1" />
            </button>
          </div>
        </div>
      </div>

      {/* UPLOAD SCRIPTURE */}
      <div
        className="flex-item"
        style={{ flex: 1, order: hasShowDetails() ? 1 : 2 }}
      >
        <div className="card card-bordered wizard--final-button pt-4 flex-column">
          <img
            src={darkTheme ? aiImage.DARK : aiImage.LIGHT}
            className="card-img-top h-250px mx-auto w-fit-content"
            alt="Upload society scripture"
          />
          <div className="card-inner">
            <h5 className="card-title">
              {t('AddSocietyAIFormTitle')}
              <img
                src={starsIcon}
                alt="IA"
                width={20}
                height={20}
                className="ml-2"
              />
              <span className="text-primary fs-12px fw-normal ml-1">
                ({t('recommended')})
              </span>
            </h5>
            <p className="card-text">{t('AddSocietyAIFormMessage')}</p>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSelectAI}
            >
              Seleccionar
              <em className="icon ni ni-chevron-right ml-1" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocietiesAddSelector;
