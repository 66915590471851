import {
  tenderOfferParticipantStatus,
  tenderParticipantStatusTypes,
} from 'utils/tenderOfferTypes';
import { getAvailablePhantoms, getAvailableStocks } from 'utils/filters';

type GetTenderOfferRowDataProps = {
  tenderOffer: Record<string, any>;
  beneficiary: Record<string, any>;
};

type TenderOfferRowDataProps = {
  units: number;
  totalPriceUnits: number;
  soldUnits: number;
  totalPriceSoldUnits: number;
  netAmount: number;
  status: Record<string, any>;
};

const getTenderOfferRowData = ({
  tenderOffer,
  beneficiary,
}: GetTenderOfferRowDataProps): TenderOfferRowDataProps => {
  const hasStocks = beneficiary?.planType === 'STOCK_OPTION';

  const beneficiaryWithParams = {
    ...beneficiary,
    calculationDate: tenderOffer?.calculationDate || tenderOffer?.startDate,
  };

  const units = hasStocks
    ? getAvailableStocks(beneficiaryWithParams, tenderOffer)
    : getAvailablePhantoms(beneficiaryWithParams, tenderOffer);

  const totalPriceUnits = units * tenderOffer?.totalPriceByShare;

  const participant = tenderOffer?.participants?.find(
    (p: Record<string, any>) =>
      p.id === beneficiary._id &&
      p.status === tenderOfferParticipantStatus.ACCEPTED
  );

  const soldUnits = participant?.totalAccepted || 0;

  const totalPriceSoldUnits = soldUnits * tenderOffer?.totalPriceByShare;

  const exercisePrice = soldUnits * (beneficiary?.planData?.sharePrice || 0);

  const baseAmount = totalPriceSoldUnits - exercisePrice;

  const discountByRetention =
    (baseAmount * tenderOffer?.retentionPercent) / 100;

  const netAmount = baseAmount - discountByRetention;

  const status =
    tenderParticipantStatusTypes[
      participant?.status as keyof typeof tenderParticipantStatusTypes
    ];

  return {
    units,
    totalPriceUnits,
    soldUnits,
    totalPriceSoldUnits,
    netAmount,
    status,
  };
};

export default getTenderOfferRowData;
