import { useEffect, useState } from 'react';

import { SelectorOption } from 'types';

type Props = {
  currentPlanLaboralCategories: any;
};

export function useGetLaboralCategoryOptions({
  currentPlanLaboralCategories,
}: Props) {
  const [laboralCategoryOptions, setLaboralCategoryOptions] = useState<
    SelectorOption[]
  >([]);

  useEffect(() => {
    const options = currentPlanLaboralCategories.map((category: any) => ({
      value: JSON.stringify({
        name: category.name,
        percent: category.percent,
      }),
      label: category.name,
    }));

    setLaboralCategoryOptions(options);
  }, [currentPlanLaboralCategories]);

  return {
    data: { laboralCategoryOptions },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
