import { useCallback, useEffect, useState } from 'react';

import { Board, BoardOrder } from 'types';

import instructionTypes from 'constants/instructionTypes';
import voteValues from 'constants/voteValues';

import { Represented } from '../types/Represented';

type Props = {
  board: Board;
  order: BoardOrder;
  represented: Represented[];
  voteValue: string;
};

function getHasVoteConflict(voteValue: string, instructionValue?: string) {
  if (!instructionValue) return false;
  if (voteValue === '') return false;
  if (instructionValue === '') return false;

  if (instructionValue === instructionTypes.ALL_ABS.value) {
    return voteValue === voteValues.ABS.value;
  }

  if (instructionValue === instructionTypes.ALL_YES.value) {
    return voteValue === voteValues.YES.value;
  }

  if (instructionValue === instructionTypes.ALL_NO.value) {
    return voteValue === voteValues.NO.value;
  }

  return instructionValue !== voteValue;
}

export default function useGetRepresentedInstructions({
  board,
  order,
  represented,
  voteValue,
}: Props) {
  const [representedInstructions, setRepresentedInstructions] = useState<
    Represented[]
  >([]);

  const getRepresentedInstructions = useCallback(
    (currentRepresented: Represented[], voteValue: string) => {
      const instructions: Represented[] = [];

      currentRepresented.forEach((represented: Represented) => {
        const hasConflict = getHasVoteConflict(
          voteValue,
          represented?.instruction
        );

        instructions.push({
          ...represented,
          hasConflict,
        });
      });

      setRepresentedInstructions(instructions);
    },
    []
  );

  useEffect(() => {
    getRepresentedInstructions(represented, voteValue);
  }, [board, order, represented, voteValue]); // eslint-disable-line

  return {
    data: representedInstructions,
    isLoading: false,
    isError: false,
    actions: {},
  };
}
