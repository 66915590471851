export const employeesGroupTypes = {
  ALL_EMPLOYEES: 'ALL_EMPLOYEES',
  EMPLOYEES_WITH_PLANS: 'EMPLOYEES_WITH_PLANS',
  EMPLOYEES_WITHOUT_PLANS: 'EMPLOYEES_WITHOUT_PLANS',
};

export const employeesSubgroupTypes = {
  ALL: 'ALL',
  SOME_GROUPS: 'SOME_GROUPS',
  SOME_EMPLOYEES: 'SOME_EMPLOYEES',
};

export const employeesPlanStatusTypes = {
  DRAFT: { value: 'DRAFT', label: 'Draft', style: 'badge-primary' },
  ACTIVE: { value: 'ACTIVE', label: 'Active', style: 'badge-success' },
  CANCELED: { value: 'CANCELED', label: 'Canceled', style: 'badge-danger' },
  FINISHED: { value: 'FINISHED', label: 'Finished', style: 'badge-secondary' },
  REJECTED: { value: 'REJECTED', label: 'Rejected', style: 'badge-danger' },
};

export const employeesIncentiveTypes = {
  NOTHING: { value: 'NOTHING', label: 'Nothing', style: 'badge-primary' },
  ACTIVE: { value: 'ACTIVE', label: 'Active', style: 'badge-warning' },
  CANCELED: { value: 'CANCELED', label: 'Canceled', style: 'badge-danger' },
  CONSOLIDATED: {
    value: 'CONSOLIDATED',
    label: 'Consolidated',
    style: 'badge-success',
  },
};
