import i18n from 'i18n/config';
import deletePartnerRepresentant from './deletePartnerRepresentant';

export default [
  {
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: deletePartnerRepresentant,
    dontShowCurrent: (params: any) => params?.isReadOnly,
  },
];
