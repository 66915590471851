import { FC, useEffect, useRef } from 'react';

import './PDFAdobeViewer.scss';

interface PDFAdobeViewerProps {
  fileUrl: string;
  fileName?: string;
  height?: string;
}

const DEFAULT_HEIGHT = '500px';

const ADOBE_PDF_EMBED_API_KEY =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_ADOBE_PDF_EMBED_API_KEY
    : process.env.REACT_APP_ADOBE_PDF_EMBED_API_KEY_DEV;

const PDFAdobeViewer: FC<PDFAdobeViewerProps> = ({
  fileUrl,
  fileName,
  height,
}) => {
  const adobeDCView = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!(window as any).AdobeDC) {
      return;
    }

    const adobeDC = new (window as any).AdobeDC.View({
      clientId: ADOBE_PDF_EMBED_API_KEY,
      divId: 'adobe-dc-view',
    });

    adobeDC.previewFile(
      {
        content: {
          location: { url: fileUrl },
        },
        metaData: { fileName },
      },
      {
        embedMode: 'FULL_WINDOW',
        defaultViewMode: 'FIT_WIDTH',
        showAnnotationTools: false,
        showDownloadPDF: false,
        showPrintPDF: false,
      }
    );
  }, [fileUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id="adobe-dc-view"
      ref={adobeDCView}
      style={{ width: '100%', height: height || DEFAULT_HEIGHT }}
    />
  );
};

export default PDFAdobeViewer;
