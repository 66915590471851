import { BoardParticipant, PartnerRepresentant } from 'types';

export const getRepresentativeSelectorValue = (
  currentParticipant: BoardParticipant,
  participants: BoardParticipant[],
  partnerRepresentants: PartnerRepresentant[]
) => {
  if (!currentParticipant.representative) {
    return null;
  }

  if (currentParticipant.hasPartnerRepresentant) {
    const partnerRepresentant = partnerRepresentants.find(
      (representant) => representant._id === currentParticipant.representative
    );
    return {
      value: partnerRepresentant?._id,
      label: `${partnerRepresentant?.cif} | ${partnerRepresentant?.name}`,
      disabled: false,
    };
  }

  const representativeData = participants.find(
    (participant) => participant.member === currentParticipant.representative
  );
  return {
    value: representativeData?.member,
    label: `${representativeData?.cif} | ${representativeData?.name}`,
    disabled: false,
  };
};
