import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Location } from 'react-router';

import { State } from 'redux/initialState';

import {
  APP_THEME_FF,
  DEFAULT_HEADER_COLOR,
  DEFAULT_HEADER_FULL_WIDTH,
  DEFAULT_HEADER_HEIGHT,
  DEFAULT_HEADER_LOGO,
  DEFAULT_HEADER_TEXT_COLOR,
  DEFAULT_HEADER_TEXT_HOVER_COLOR,
  DEFAULT_LOGO_HREF,
} from 'constants/defaultConstants';

import useUserValidation from 'hooks/useUserValidation';

import { getTheme, updatePrimaryColor } from 'utils/theme';

const useHeader = (location: Location) => {
  const [selectedTheme, setSelectedTheme] = useState(getTheme());
  const [hasSocietyButtonEnabled, setHasSocietyButtonEnabled] = useState(false);

  const user = useSelector((state: State) => state.user);

  const theme = useSelector(
    (state: State) => state.society?.actualSociety?.customization?.theme
  );

  const showSideNav = useSelector((state: State) => state.sideNav);

  const societyId = useSelector(
    (state: State) => state.society?.actualSociety?._id
  );

  const hasWhiteBranding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasWhiteBranding
  );

  const hasCustomLanding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.hasCustomLanding
  );

  const [headerLogo, setHeaderLogo] = useState(
    theme?.logo || DEFAULT_HEADER_LOGO
  );
  const [headerColor, setHeaderColor] = useState(
    theme?.headerColor || DEFAULT_HEADER_COLOR
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    theme?.headerTextColor || DEFAULT_HEADER_TEXT_COLOR
  );
  const [headerTextHoverColor, setHeaderTextHoverColor] = useState(
    theme?.headerTextHoverColor || DEFAULT_HEADER_TEXT_HOVER_COLOR
  );
  const [logoHref, setLogoHref] = useState(
    theme?.logoHref || DEFAULT_LOGO_HREF
  );
  const [headerFullWidth, setHeaderFullWidth] = useState(
    theme?.headerFullWidth || DEFAULT_HEADER_FULL_WIDTH
  );
  const [headerHeight, setHeaderHeight] = useState(
    theme?.headerHeight || DEFAULT_HEADER_HEIGHT
  );

  const userValidations = useUserValidation(user);

  useEffect(() => {
    if (location.pathname === '/') {
      setHasSocietyButtonEnabled(true);
    } else {
      setHasSocietyButtonEnabled(false);
    }
  }, [location]);

  useEffect(() => {
    if (theme) {
      setHeaderLogo(theme?.logo || DEFAULT_HEADER_LOGO);
      setHeaderColor(theme?.headerColor || DEFAULT_HEADER_COLOR);
      setHeaderTextColor(theme?.headerTextColor || DEFAULT_HEADER_TEXT_COLOR);
      setHeaderTextHoverColor(
        theme?.headerTextHoverColor || DEFAULT_HEADER_TEXT_HOVER_COLOR
      );
      setLogoHref(theme?.logoHref || DEFAULT_LOGO_HREF);
      setHeaderFullWidth(theme?.headerFullWidth || DEFAULT_HEADER_FULL_WIDTH);
      setHeaderHeight(theme?.headerHeight || DEFAULT_HEADER_HEIGHT);
    } else {
      setHeaderLogo(DEFAULT_HEADER_LOGO);
      setHeaderColor(DEFAULT_HEADER_COLOR);
      setHeaderTextColor(DEFAULT_HEADER_TEXT_COLOR);
      setHeaderTextHoverColor(DEFAULT_HEADER_TEXT_HOVER_COLOR);
      setLogoHref(DEFAULT_LOGO_HREF);
      setHeaderFullWidth(DEFAULT_HEADER_FULL_WIDTH);
      setHeaderHeight(DEFAULT_HEADER_HEIGHT);
    }
  }, [theme]);

  useEffect(() => {
    if (headerTextHoverColor) {
      updatePrimaryColor(headerTextHoverColor);
    }
  }, [headerTextHoverColor]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = localStorage.getItem(APP_THEME_FF);
      setSelectedTheme(theme || 'light');
    });
  }, []);

  return {
    data: {
      user,
      theme: {
        logoHref,
        headerLogo,
        headerColor,
        headerTextColor,
        headerTextHoverColor,
        headerFullWidth,
        headerHeight,
      },
      societyId,
      showSideNav,
      selectedTheme,
      userValidations,
      hasWhiteBranding,
      hasCustomLanding,
      hasSocietyButtonEnabled,
    },
  };
};

export default useHeader;
