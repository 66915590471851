import editHoldingClass from 'utils/editHoldingClass';

export default [
  {
    icon: 'ni-edit',
    text: 'Editar',
    action: editHoldingClass,
    dontShowCurrent: ({ isReadOnly }) => isReadOnly,
  },
];
