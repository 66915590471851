/* eslint-disable no-unused-vars */
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import { CoOwner, Range } from 'types';
import InputError from 'modules/_shared/components/InputError';
import { FixedShare } from '../types/Share';

import ShareEncumbranceViewCoOwner from './ShareEncumbranceViewCoOwners';
import ShareEncumbranceViewFormAvailableRanges from './ShareEncumbranceViewFormAvailableRanges';
import ShareEncumbranceViewFormReadOnlyData from './ShareEncumbranceViewFormReadOnlyData';
import ShareEncumbranceViewFormScriptureData from './ShareEncumbranceViewFormScriptureData';
import ShareEncumbranceViewFormEncumbranceData from './ShareEncumbranceViewFormEncumbranceData';

type Props = {
  share: FixedShare;
  encumbranceName: string;
  encumbranceDate: string;
  encumbranceFrom: number;
  encumbranceTo: number;
  affectedShares: number;
  encumbranceScriptureData: any;
  encumbranceFile: File | undefined;
  coOwners: CoOwner[];
  validRanges: Range[];
  inputLabel: string;
  isValidRange: boolean;
  isEditing: boolean;
  handleChangeEncumbranceName: (event: any) => void;
  handleChangeEncumbranceDate: (event: any) => void;
  handleChangeEncumbranceFrom: (event: any) => void;
  handleChangeEncumbranceTo: (event: any) => void;
  handleAddCoOwner: (coOwner: CoOwner) => void;
  handleRemoveCoOwner: (index: number) => void;
  handleAddEncumbrance: () => void;
  handleCancelEdit: () => void;
  handleClickRange: (range: Range) => void;
  handleChangeNotaryName: (event: any) => void;
  handleChangeProtocolNr: (event: any) => void;
  handleChangeNotaryCity: (event: any) => void;
  handleChangeEncumbranceFile: (file: File) => void;
};

const ShareEncumbranceViewForm: FC<Props> = ({
  share,
  encumbranceName,
  encumbranceDate,
  encumbranceFrom,
  encumbranceTo,
  affectedShares,
  encumbranceScriptureData,
  encumbranceFile,
  coOwners,
  validRanges,
  inputLabel,
  isValidRange,
  isEditing,
  handleChangeEncumbranceName,
  handleChangeEncumbranceDate,
  handleChangeEncumbranceFrom,
  handleChangeEncumbranceTo,
  handleAddCoOwner,
  handleRemoveCoOwner,
  handleAddEncumbrance,
  handleCancelEdit,
  handleClickRange,
  handleChangeNotaryName,
  handleChangeProtocolNr,
  handleChangeNotaryCity,
  handleChangeEncumbranceFile,
}) => {
  const { t } = useTranslate();

  return (
    <>
      <ShareEncumbranceViewFormEncumbranceData
        share={share}
        encumbranceName={encumbranceName}
        encumbranceDate={encumbranceDate}
        encumbranceFrom={encumbranceFrom}
        encumbranceTo={encumbranceTo}
        handleChangeEncumbranceName={handleChangeEncumbranceName}
        handleChangeEncumbranceDate={handleChangeEncumbranceDate}
        handleChangeEncumbranceFrom={handleChangeEncumbranceFrom}
        handleChangeEncumbranceTo={handleChangeEncumbranceTo}
      />

      {!isValidRange && <InputError errorMessage="InvalidRange" />}

      <ShareEncumbranceViewFormAvailableRanges
        validRanges={validRanges}
        handleClickRange={handleClickRange}
      />

      <ShareEncumbranceViewFormReadOnlyData
        share={share}
        affectedShares={affectedShares}
      />

      <hr />
      <ShareEncumbranceViewCoOwner
        coOwners={coOwners}
        handleAddCoOwner={handleAddCoOwner}
        handleRemoveCoOwner={handleRemoveCoOwner}
      />
      <hr />

      <ShareEncumbranceViewFormScriptureData
        encumbranceScriptureData={encumbranceScriptureData}
        encumbranceFile={encumbranceFile}
        inputLabel={inputLabel}
        handleChangeNotaryName={handleChangeNotaryName}
        handleChangeProtocolNr={handleChangeProtocolNr}
        handleChangeNotaryCity={handleChangeNotaryCity}
        handleChangeEncumbranceFile={handleChangeEncumbranceFile}
      />

      <div className="form-group d-flex justify-content-end">
        <div>
          <button
            type="button"
            className="btn btn-outline-primary btn-dim border-radius-0"
            disabled={
              !encumbranceName ||
              !encumbranceDate ||
              !isValidRange ||
              validRanges?.length === 0
            }
            onClick={handleAddEncumbrance}
          >
            {isEditing ? t('EditEncumbrance') : t('AddEncumbrance')}
          </button>
        </div>
        {isEditing && (
          <div className="ml-2">
            <button
              type="button"
              className="btn btn-outline-danger btn-dim border-radius-0 "
              onClick={handleCancelEdit}
            >
              {t('Cancel')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ShareEncumbranceViewForm;
