import { FC, memo, useState } from 'react';

import { Operation, Society } from 'types';

import TransactionDetailsOperationTableHeader from './TransactionDetailsOperationTableHeader';
import TransactionDetailsOperationTableFooter from './TransactionDetailsOperationTableFooter';
import TransactionDetailsOperationRow from './TransactionDetailsOperationRow';

type TransactionDetailsOperationTabProps = {
  operation: Operation;
  actualSociety: Society;
};

const TransactionDetailsOperationTab: FC<
  TransactionDetailsOperationTabProps
> = ({ operation, actualSociety }) => {
  const [totalShares, setTotalShares] = useState<number>(0);
  const [totalSum, setTotalSum] = useState<number>(0);

  return (
    <>
      <div className="invoice-bills">
        <div className="table-responsive">
          <table className="table table-striped">
            <TransactionDetailsOperationTableHeader
              operationType={operation.operationType}
            />

            <TransactionDetailsOperationRow
              operation={operation}
              actualSociety={actualSociety}
              onUpdateTotalSum={setTotalSum}
              onUpdateTotalShares={setTotalShares}
            />

            <TransactionDetailsOperationTableFooter
              operationType={operation.operationType}
              totalShares={totalShares}
              totalSum={totalSum}
            />
          </table>
        </div>
      </div>
    </>
  );
};

export default memo(TransactionDetailsOperationTab);
