import React, { FC } from 'react';

import { EnvelopeSigner } from 'types';

import SignerRow from './SignerRow';

type Props = {
  signers: EnvelopeSigner[];
};

const SignatureViewTableBody: FC<Props> = ({ signers }) => (
  <>
    <tbody>
      {signers?.map((signer) => (
        <SignerRow signer={signer} />
      ))}
    </tbody>
  </>
);

export default SignatureViewTableBody;
