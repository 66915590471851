import i18n from 'i18n/config';
import { SocietyTypes } from 'types/Society';

export default [
  {
    value: SocietyTypes.SL,
    label: i18n.t('SocietyTypeSL'),
  },
  {
    value: SocietyTypes.SA,
    label: i18n.t('SocietyTypeSA'),
  },
  {
    value: SocietyTypes.COOP,
    label: i18n.t('SocietyTypeCOOP'),
  },
  {
    value: SocietyTypes.OTHER,
    label: i18n.t('SocietyTypeOTHER'),
  },
];
