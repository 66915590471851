import { Beneficiary } from 'types';
import { getConsolidationPeriods } from 'utils/consolidationPeriods';
import getClosestCancelationPeriod from './getClosestCancelationPeriod';
import getBeneficiaryPlan from './getBeneficiaryPlan';

export default function getFinalConsolidatedUnits(
  beneficiary: Beneficiary,
  plans: any[],
  cancelationDate: Date
) {
  const currentPlan = getBeneficiaryPlan(beneficiary, plans);

  const consolidationPeriods = getConsolidationPeriods(
    beneficiary,
    currentPlan
  );

  const closestCancelationPeriod = getClosestCancelationPeriod(
    consolidationPeriods,
    cancelationDate
  );

  return closestCancelationPeriod?.totalVestedUnits || 0;
}
