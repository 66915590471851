import { useTranslate } from 'hooks/useTranslate';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

type Props = {
  id: string;
  label: string;
  labelSize: string;
  currentLogo: string;
  backgroundColor: string;
  onLogoChange: any;
};

const SocietyConfigurationLogoRow = (props: Props) => {
  const { t } = useTranslate();
  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const { id, label, labelSize, currentLogo, onLogoChange, backgroundColor } =
    props;

  return (
    <div className="data-item">
      <div className="data-col">
        <span className="data-label">
          {label}
          <br />
          <span className="text-primary fw-light ff-italic">
            ({t('recommended')}: <b>{labelSize}</b>)
          </span>
        </span>
      </div>

      <div className="data-col data-col-end w-auto">
        {isReadOnly ? (
          <button
            type="button"
            className="btn btn-primary form-control-file form-control-lg w-200px mr-3"
            disabled={isReadOnly}
          >
            {t('SelectFile')}
          </button>
        ) : (
          <input
            id={id}
            type="file"
            className="form-control-file form-control-lg cursor-pointer w-200px mr-3"
            accept="image/*"
            multiple={false}
            onChange={onLogoChange}
            disabled={isReadOnly}
          />
        )}
        <div
          className="logo-preview-box rounded border-light"
          style={{
            backgroundColor,
            backgroundImage: `url(${currentLogo})`,
          }}
        />
      </div>
    </div>
  );
};

export default SocietyConfigurationLogoRow;
