import { formatNumber } from 'utils/formats';

export const getEncumbranceCellValue = (
  encumbrance: Record<string, any>
): string => {
  const { from, to, name } = encumbrance ?? {};

  if (!encumbrance || to === from) {
    return '-';
  }

  const textRange = `${formatNumber(from)} - ${formatNumber(to)}`;
  const textName = name ? `(${name})` : '';

  return `${textRange} ${textName}`;
};

export const getCellValues = {};
