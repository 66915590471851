import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

type Option = {
  label: string;
  value: string;
};

type Props = {
  value: Option;
  options: Option[];
  onChange: any;
};

const PersonalDataViewCivilStatus: FC<Props> = ({
  value,
  options,
  onChange,
}) => {
  const { t } = useTranslate();

  const animatedComponents = makeAnimated();

  return (
    <div className="col-md-6">
      <div className="form-group">
        <label className="form-label w-100" htmlFor="partner-civil-satus">
          {t('CivilStatus')}
        </label>

        <div id="partner-civil-status" className="form-control-wrap">
          <Select
            closeMenuOnSelect
            value={value}
            className="react-select react-select-lg"
            options={options}
            components={animatedComponents}
            onChange={onChange}
            placeholder={t('SelectCivilStatus')}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDataViewCivilStatus;
