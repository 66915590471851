import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import axios from '../../../interceptors/axios';

export const getDashboardData =
  (societyId: string, view: string) => async (dispatch: any) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/societyDashboard/${view}/${societyId}`
      );

      const decodedData = await decryptResponse(data);

      return decodedData;
    } catch (error) {
      dispatchError(error, dispatch);
    }
  };
