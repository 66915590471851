/* eslint-disable react/jsx-no-bind */
import React, { useContext } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import ThemeContext from 'modules/_shared/contexts/ThemeContext';

import 'assets/styles/Icons.scss';
import 'assets/styles/Bootstrap.scss';

import './UserMenuDots.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdown-toggle mr-lg-n1"
  >
    {children}
  </div>
));
CustomToggle.displayName = 'CustomToggle';

function UserMenuDots({ menuOptions }) {
  const { logout } = useAuth0();
  const { t } = useTranslate();

  const { darkTheme, toggleTheme } = useContext(ThemeContext);

  const { user } = useSelector((state) => state);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const options = isDemo ? menuOptions.slice(0, -1) : menuOptions;

  function onLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('access-token');
    logout({ returnTo: window.location.origin });
  }

  const menu = options.map((menuOption) => (
    <Dropdown.Item key={menuOption.id}>
      <Link
        to={menuOption.url}
        key={menuOption.text}
        className="user-menu-item-link"
      >
        <em className={`icon ni ${menuOption.icon}`} />
        <span>{t(menuOption.text)}</span>
      </Link>
    </Dropdown.Item>
  ));

  menu.push(
    <Dropdown.Item key="theme-switcher" onClick={toggleTheme}>
      {darkTheme ? (
        <>
          <em className="icon ni ni-sun" />
          <span>{t('LightMode')}</span>
        </>
      ) : (
        <>
          <em className="icon ni ni-moon" />
          <span>{t('DarkMode')}</span>
        </>
      )}
    </Dropdown.Item>
  );

  menu.push(
    <Dropdown.Item key="signout" onClick={onLogout} className="dropdown-inner">
      <em className="icon ni ni-signout" />
      <span>{t('SignOut')}</span>
    </Dropdown.Item>
  );

  return (
    <>
      <Dropdown drop={menuOptions.direction}>
        <Dropdown.Toggle as={CustomToggle}>
          <div className="user-toggle">
            <div className="user-avatar sm">
              {user ? (
                <img
                  src={user.image}
                  alt={user.name}
                  className="rounded-circle"
                />
              ) : (
                <em className="icon ni ni-user-alt" />
              )}
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu
          id="user-menu-dots"
          size="sm"
          title=""
          className="link-list-opt no-bdr dropdown-menu dropdown-menu-s1"
        >
          <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block py-2">
            <div className="user-card">
              <div className="user-avatar">
                {user ? (
                  <img
                    src={user.image}
                    alt={user.name}
                    className="rounded-circle"
                  />
                ) : (
                  <em className="icon ni ni-user-alt" />
                )}
              </div>
              <div className="user-info">
                <span className="lead-text">{user?.name || ''}</span>
                <span className="sub-text">{user?.email}</span>
              </div>
            </div>
          </div>
          {menu}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default UserMenuDots;
