import { PartnerRepresentant } from 'types';
import { Board, BoardDelegations } from 'types/Board';

export const getBoardRepresentants = (
  board: Board | undefined,
  partnerRepresentants: PartnerRepresentant[]
) => {
  if (!board) {
    return [];
  }

  const { delegationType } = board;

  if (delegationType === BoardDelegations.ALL_MEMBERS) {
    const representants =
      partnerRepresentants?.map((representant) => ({
        _id: representant['_id'],
        name: representant.name,
        cif: representant?.cif || '-',
      })) || [];

    return representants;
  }

  return [];
};
