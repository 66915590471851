export const administratedItems = (i18n: any) => [
  {
    to: '/cuadro-administradas',
    text: i18n.t('AdministratedTable'),
  },
  {
    to: '/lista-administradas',
    text: i18n.t('AdministratedList'),
  },
  {
    to: '/diagrama-administradas',
    text: i18n.t('AdministratedChart'),
  },
  {
    to: '/kanban-administradas',
    text: i18n.t('AdministratedKanban'),
    isDisabled: true,
  },
];

export const participatedItems = (i18n: any) => [
  {
    to: '/cuadro-participadas',
    text: i18n.t('ParticipatedTable'),
  },
  {
    to: '/lista-participadas',
    text: i18n.t('ParticipatedList'),
  },
  {
    to: '/diagrama-participadas',
    text: i18n.t('ParticipatedChart'),
  },
  {
    to: '/kanban-participadas',
    text: i18n.t('ParticipatedKanban'),
    isDisabled: true,
  },
];

export const plansItems = (i18n: any) => [
  {
    to: '/lista-beneficiadas',
    text: i18n.t('PlansList'),
  },
];
