import { FC } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslate } from 'hooks/useTranslate';

type PartnerBookRow = {
  id: string;
  data: (string | number)[];
};

type PartnerBookTableFirstColumnProps = {
  bookPartners: PartnerBookRow[];
  bookTotalPartners: number;
  createPartnersArray: any;
};

const PartnerBookTableFirstColumn: FC<PartnerBookTableFirstColumnProps> = ({
  bookPartners,
  bookTotalPartners,
  createPartnersArray,
}) => {
  const { t } = useTranslate();

  return (
    <table
      className="table table-striped cloned pos-abs partner-book-table"
      id="table-overlap"
    >
      <thead>
        <tr className="table-header">
          <th />
        </tr>
        <tr className="table-subheader">
          <th>{t('Partners')}</th>
        </tr>
      </thead>

      <tbody>
        {createPartnersArray(bookPartners)?.map(
          (partner: any, index: number) => (
            <tr key={partner?.id}>
              <td
                data-tooltip-id={partner.id}
                className={
                  !(index % 2 === 0)
                    ? 'bg-light text-left w-200px d-block text-ellipsis'
                    : 'bg-white text-left w-200px d-block text-ellipsis'
                }
              >
                {partner.data[0]}
                <Tooltip id={partner.id} place="bottom">
                  {partner.data[0]}
                </Tooltip>
              </td>
            </tr>
          )
        )}
      </tbody>

      <tfoot>
        <tr
          className={
            !(bookTotalPartners % 2 === 0)
              ? 'bg-light font-weight-bold'
              : 'bg-white font-weight-bold'
          }
        >
          <td className="text-left">{t('Total').toUpperCase()}</td>
        </tr>
      </tfoot>
    </table>
  );
};

export default PartnerBookTableFirstColumn;
