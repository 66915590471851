import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { Modal } from 'react-bootstrap';

type Props = {
  encumbrancesUpdated: boolean;
  tagUpdated: boolean;
  page: number;
  handleUpdateShare: () => void;
};

const UpdateSharesFooter: FC<Props> = ({
  handleUpdateShare,
  encumbrancesUpdated,
  tagUpdated,
  page,
}) => {
  const { t } = useTranslate();

  const isUpdateButtonDisabled = () =>
    (!encumbrancesUpdated && page === 0) || (!tagUpdated && page === 1);

  return (
    <Modal.Footer className="bg-light">
      <div className="nk-modal-action float-left">
        <span className="sub-text">{t('ModalVisibleForSuperAdminsOnly')}</span>
      </div>
      <div className="nk-modal-action">
        <button
          type="button"
          className="btn btn-lg btn-block btn-primary"
          onClick={handleUpdateShare}
          disabled={isUpdateButtonDisabled()}
        >
          {t('Update')}
        </button>
      </div>
    </Modal.Footer>
  );
};

export default UpdateSharesFooter;
