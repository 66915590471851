import { useTranslate } from 'hooks/useTranslate';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  addDocument,
  updateDocument,
} from 'modules/documents/redux/documentActions';
import { store } from 'redux/store';

import EditorWYSIWYG from 'modules/_shared/components/EditorWYSIWYG';

import ReadOnlyEditor from 'modules/_shared/components/ReadOnlyEditor';
import { updateSociety } from 'modules/society/redux/societyActions';
import { State } from 'redux/initialState';
import Swal from 'sweetalert2';
import { Society } from 'types';
import { Document } from 'types/Document';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

type CertificateTemplateViewProps = {
  sharesCertificateDocument: Document;
  setShraesCertificateDocument: any;
  actualSociety: Society;
};

const CertificateTemplateView: FC<CertificateTemplateViewProps> = ({
  sharesCertificateDocument,
  setShraesCertificateDocument,
  actualSociety,
}) => {
  const { t } = useTranslate();

  const user = useSelector((state: State) => state.user);

  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const [htmlContent, setHtmlContent] = useState<string>(
    sharesCertificateDocument?.editorModel || ''
  );
  const [savedContent, setSavedContent] = useState<boolean>(true);

  const handleSetHtmlContent = (htmlContent: string) => {
    setHtmlContent(htmlContent);
    if (savedContent) {
      setSavedContent(false);
    }
  };

  const showSuccessAlert = () => {
    Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'TemplateUpdatedSuccessfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  const handleSave = async () => {
    if (isReadOnly) return;

    try {
      if (actualSociety?.sharesCertificateTemplate) {
        const updatedTemplate = {
          ...sharesCertificateDocument,
          lastAccess: new Date(),
          editorModel: htmlContent,
        };
        const uploadedDocument = await store.dispatch(
          updateDocument(updatedTemplate, false)
        );

        setShraesCertificateDocument(uploadedDocument);
        showSuccessAlert();
      } else {
        const newTemplate = {
          date: new Date(),
          lastAccess: new Date(),
          category: 'TEMPLATES',
          author: user['_id'],
          isGenerated: false,
          isFavorite: false,
          editorModel: htmlContent,
          society: actualSociety['_id'],
        };

        const uploadedDocument = await store.dispatch(addDocument(newTemplate));

        setShraesCertificateDocument(uploadedDocument);

        if (uploadedDocument) {
          await store.dispatch(
            updateSociety(
              actualSociety?.['_id'],
              {
                sharesCertificateTemplate: uploadedDocument['_id'],
              },
              false
            )
          );
          showSuccessAlert();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t(
          'ErrorSavingTemplate'
        )}<br></h4>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }

    setSavedContent(true);
  };

  useEffect(() => {
    if (sharesCertificateDocument) {
      setHtmlContent(sharesCertificateDocument?.editorModel);
    }
  }, [sharesCertificateDocument]);

  return (
    <>
      <div className="nk-fmg-body-content">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between position-relative">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title title">
                {t('CertificateTemplate')}{' '}
                {!savedContent && (
                  <span className="badge rounded-pill text-warning ml-3">
                    {t('UnsavedChanges')}
                  </span>
                )}
              </h5>
              <div className="nk-block-des text-soft">
                <p>{t('TemplateForSharesCertificate')}</p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <ul className="nk-block-tools g-3">
                <li>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    disabled={savedContent || isReadOnly}
                  >
                    <em className="icon ni ni-save mr-2" />
                    {t('ToSave')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {isReadOnly ? (
          <div className="w-100">
            <ReadOnlyEditor editorContent={htmlContent} />
          </div>
        ) : (
          <div className="w-100">
            <EditorWYSIWYG
              values={{
                society: {},
                partner: {},
                signatures: {},
              }}
              setEditorContent={handleSetHtmlContent}
              editorContent={htmlContent}
              setEditorReference={null}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CertificateTemplateView;
