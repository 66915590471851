/* eslint-disable react/destructuring-assignment */
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';

import { Society, SocietyEmailSignature } from 'types/Society';

import CommunicationSettingsSignaturePreview from '../CommunicationSettingsSignaturePreview';

import './CommunicationSettingsSignature.scss';

type CommunicationSettingsSignatureProps = {
  signature: SocietyEmailSignature;
  onChangeSignature: any;
  society: Society;
};

const CommunicationSettingsSignature = ({
  signature,
  onChangeSignature,
  society,
}: CommunicationSettingsSignatureProps) => {
  const { t } = useTranslate();

  const societyLogo = useSelector(
    (state: State) => state.society?.actualSociety?.logo
  );

  const [name, setName] = useState(signature?.name);
  const [jobTitle, setJobTitle] = useState(signature?.jobTitle);
  const [logo] = useState(signature?.logo || societyLogo);
  const [website, setWebsite] = useState(signature?.website);
  const [phone, setPhone] = useState(signature?.phone);
  const [address, setAddress] = useState(signature?.address);
  const [footer, setFooter] = useState(signature?.footer);

  const handleChangeSignatureName = (event: any) => {
    setName(event.target.value);
    onChangeSignature('name', event.target.value);
  };

  const handleChangeSignatureJobTitle = (event: any) => {
    setJobTitle(event.target.value);
    onChangeSignature('jobTitle', event.target.value);
  };

  const handleChangeSignatureWebsite = (event: any) => {
    setWebsite(event.target.value);
    onChangeSignature('website', event.target.value);
  };

  const handleChangeSignaturePhone = (event: any) => {
    setPhone(event.target.value);
    onChangeSignature('phone', event.target.value);
  };

  const handleChangeSignatureAddress = (event: any) => {
    setAddress(event.target.value);
    onChangeSignature('address', event.target.value);
  };

  const handleChangeSignatureFooter = (event: any) => {
    setFooter(event.target.value);
    onChangeSignature('footer', event.target.value);
  };

  return (
    <>
      <div className="steps clearfix mt-2">
        <ul role="tablist">
          <li role="tab" className="first last current">
            <h5>{t('EmailSignature')}</h5>
          </li>
        </ul>
      </div>

      <div className="nk-wizard-content body current email-signature-form">
        <div className="row g-5 mr-0">
          <div className="col-md-6">
            <div className="form-group">
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-md"
                  id="signature-name"
                  value={name}
                  maxLength={50}
                  onChange={handleChangeSignatureName}
                  placeholder={t('Name')}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-md"
                  id="signature-job-title"
                  value={jobTitle}
                  maxLength={50}
                  onChange={handleChangeSignatureJobTitle}
                  placeholder={t('JobTitle')}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-6">
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    id="signature-website"
                    value={website}
                    maxLength={50}
                    onChange={handleChangeSignatureWebsite}
                    placeholder={t('WebSite')}
                  />
                </div>
              </div>
              <div className="form-group col-6">
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    id="signature-phone"
                    value={phone}
                    maxLength={50}
                    onChange={handleChangeSignaturePhone}
                    placeholder={t('Telephone')}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-md"
                  id="signature-address"
                  value={address}
                  maxLength={150}
                  onChange={handleChangeSignatureAddress}
                  placeholder={t('Address')}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-md"
                  id="signature-footer"
                  value={footer}
                  maxLength={250}
                  onChange={handleChangeSignatureFooter}
                  placeholder={t('EmailFooter')}
                />
              </div>
            </div>
          </div>

          <CommunicationSettingsSignaturePreview
            signature={{
              name,
              jobTitle,
              logo,
              website,
              phone,
              address,
              footer,
            }}
            society={society}
          />
        </div>
      </div>
    </>
  );
};

export default CommunicationSettingsSignature;
