/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

export default async function replaceImageUrlsWithDataUrls(htmlString) {
  let resultHtml = htmlString;
  const regex = /<img.*?src=["'](.*?)['"]/g;
  const matches = htmlString.matchAll(regex);
  for (const match of matches) {
    const imageUrl = match[1];
    if (imageUrl.startsWith('http')) {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      const dataUrlPromise = new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
      const dataUrl = await dataUrlPromise;
      resultHtml = htmlString.replaceAll(imageUrl, dataUrl);
    }
  }
  return resultHtml;
}
