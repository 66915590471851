import { JSX, ReactNode } from 'react';
import TransactionDetailsCell from '../components/TransactionDetailsOperationTableCell';

const getTableCell = (
  cellValue: number | string | undefined,
  format: Record<string, any>,
  renderText?: (formattedValue: string) => ReactNode // eslint-disable-line
): JSX.Element => (
  <TransactionDetailsCell
    cellValue={cellValue}
    format={format}
    renderText={renderText}
  />
);

export default getTableCell;
