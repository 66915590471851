import { useEffect, useState } from 'react';
import { Document } from 'types';

type DocumentOption = {
  value: string;
  label: string;
};

const isDocumentAllowed = (
  document: Document,
  societyId: string,
  societyTemplates: boolean
) =>
  document?.category === 'TEMPLATES' &&
  document?.subcategory !== 'DOCUMENTS' &&
  document?.subcategory !== 'GENERATED' &&
  (!societyTemplates || document.society === societyId);

export default function useGetTemplateOptions(
  documents: Document[],
  societyTemplates: boolean,
  societyId: string,
  t: (text: string) => string
) {
  const [templateOptions, setTemplateOptions] = useState<DocumentOption[]>([]);

  useEffect(() => {
    const options = documents
      ?.filter((document) =>
        isDocumentAllowed(document, societyId, societyTemplates)
      )
      ?.map((document) => ({
        value: document._id,
        label: document.name,
      }));

    setTemplateOptions(options);
  }, [documents, societyId, societyTemplates, t]);

  return { templateOptions };
}
