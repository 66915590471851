interface TenderOfferStatus {
  DRAFT: { value: string; label: string; style: string };
  ACTIVE: { value: string; label: string; style: string };
  CANCELED: { value: string; label: string; style: string };
  FINISHED: { value: string; label: string; style: string };
}

const tenderOfferStatus: TenderOfferStatus = {
  DRAFT: { value: 'DRAFT', label: 'Draft', style: 'badge-primary' },
  ACTIVE: { value: 'ACTIVE', label: 'Active', style: 'badge-success' },
  CANCELED: { value: 'CANCELED', label: 'Canceled', style: 'badge-danger' },
  FINISHED: { value: 'FINISHED', label: 'Finished', style: 'badge-secondary' },
};

export default tenderOfferStatus;
