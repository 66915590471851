import { useTranslate } from 'hooks/useTranslate';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { Guest } from 'types';

import NoItemsAvailable from 'modules/_shared/components/Modals/NoItemsAvailable';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';
import SocietyGuestsRow from './SocietyGuestsRow';

type SocietyGuestsTableProps = {
  societyGuests: Guest[];
};

const SocietyGuestsTable: FC<SocietyGuestsTableProps> = ({ societyGuests }) => {
  const { t } = useTranslate();

  const { isAdmin, isReadOnly } = useGetSocietySectionPermissions().data;

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state?.society?.actualSociety
  );

  const [rows, setRows] = useState<ReactElement[]>([]);

  useEffect(() => {
    if (societyGuests?.length) {
      setRows(
        societyGuests?.map((guest, index) => (
          <SocietyGuestsRow
            guest={guest}
            society={actualSociety}
            user={user}
            isAdmin={isAdmin}
            index={index}
            key={guest['_id'] || ''}
          />
        ))
      );
    } else {
      setRows([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyGuests]);

  return (
    <div className="card card-bordered card-preview">
      <table className="nk-tb-list nk-tb-ulist">
        <thead>
          <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
            <th className="nk-tb-col tb-col-xl">
              <span>#</span>
            </th>

            <th className="nk-tb-col">
              <span>{t('NameAndSurname')}</span>
            </th>

            <th className="nk-tb-col tb-col-xl">
              <span>{t('HighDate')}</span>
            </th>

            <th className="nk-tb-col tb-col-xl">
              <span>{t('E-Mail')}</span>
            </th>

            <th className="nk-tb-col">
              <span>{t('AccessLevel')}</span>
            </th>

            {isAdmin && !isReadOnly && (
              <th className="nk-tb-col nk-tb-col-tools">
                <span />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows?.length ? (
            rows
          ) : (
            <NoItemsAvailable mainMessage={t('NoGuestsAvailable')} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SocietyGuestsTable;
