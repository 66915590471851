import { decryptResponse } from 'utils/token';

import { dispatchError } from 'utils/dispatchError';
import axios from '../../../interceptors/axios';

type SignRedirectionProps = {
  envelopeId: string;
  signerId: string;
};

const getSignRedirection =
  ({ envelopeId, signerId }: SignRedirectionProps): any =>
  async (dispatch: any) => {
    try {
      const query = {
        params: { envelopeId, signerId },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/signRedirection`,
        query
      );

      const decodedData = await decryptResponse(data);
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export default getSignRedirection;
