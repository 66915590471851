const createPartnersArray = (partnerObject: Record<string, any>) => {
  if (Array.isArray(partnerObject)) {
    return partnerObject;
  }

  if (typeof partnerObject === 'object') {
    return Object.entries(partnerObject).map(([key, value]) => ({
      id: key,
      data: value,
    }));
  }

  return [];
};
export default createPartnersArray;
