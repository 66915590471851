import React from 'react';
import { useParams } from 'react-router-dom';

const SignConfirmation = () => {
  const { authToken } = useParams();

  return (
    <>
      <h1>SignConfirmation</h1>
      <div>{authToken}</div>
    </>
  );
};

export default SignConfirmation;
