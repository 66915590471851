/* eslint-disable no-underscore-dangle */
import { useTranslate } from 'hooks/useTranslate';
import i18n from 'i18n/config';
import { ChangeEvent, FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';

import { store } from 'redux/store';
import { State } from 'redux/initialState';
import eventTypes from 'constants/eventTypes';
import { EMAIL_PATTERN } from 'constants/defaultConstants';
import trackEvent from 'utils/trackEvent';

import sendEmail from 'modules/communications/redux/mailActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { addAlert } from 'modules/_shared/redux/alertActions';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { createGuest } from 'modules/society/redux/guestsActions';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

import 'modules/_shared/components/Modals/Modals.scss';

import SocietyGuestsPermissionsForm from './SocietyGuestsPermissionsForm';
import societyGuestsPermissions from '../constants/societyGuestsPermissions';
import { createGuestEmail } from '../utils';

type SocietyGuestsAddModalProps = {};

const SocietyGuestsAddModal: FC<SocietyGuestsAddModalProps> = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const [email, setEmail] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const isButtonDisabled = !email || !selectedPermissions.length || isReadOnly;

  const sendNotificationEmail = () => {
    const { language } = i18n;
    const emailData = createGuestEmail({
      t,
      language,
      user,
      email,
      actualSociety,
      action: 'ADD',
    });

    dispatch(sendEmail(emailData));
  };

  async function saveGuest(event: any) {
    if (isReadOnly) return;
    event.preventDefault();

    try {
      const guestsPermissions = {};

      selectedPermissions.forEach((permission) => {
        // @ts-ignore
        guestsPermissions[permission['name']] = { enabled: permission.checked };
      });

      const newGuest = {
        email,
        user: user['_id'],
        createdAt: new Date(),
        createdBy: user['_id'],
        societyId: actualSociety._id,
        permissions: {
          society: actualSociety._id,
          permissions: { ...guestsPermissions },
        },
      };

      store.dispatch(createGuest(newGuest, false));

      sendNotificationEmail();

      trackEvent(mixpanel, eventTypes.ADD_GUEST, {
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?._id,
        societyName: actualSociety?.name,
        operation: eventTypes.ADD_GUEST,
      });

      dispatch(setModal(null));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_ADDING_GUEST));
    }
  }

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangePermissions = (permissions: any) => {
    setSelectedPermissions(permissions);
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('AddGuest')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveGuest}>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  {t('Email')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-mail" />
                  </div>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    placeholder={t('EmailOfTheNewGuest')}
                    value={email}
                    onChange={handleChangeEmail}
                    pattern={EMAIL_PATTERN}
                  />
                </div>
              </div>
            </div>
          </div>

          <SocietyGuestsPermissionsForm
            className="my-4"
            defaultPermissions={societyGuestsPermissions}
            onChangePermissions={handleChangePermissions}
          />

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={isButtonDisabled}
          >
            {t('AddGuest')}
          </button>
        </form>
      </Modal.Body>

      <Modal.Footer className="bg-light">
        <span className="sub-text">
          {t('GuestWillReceiveAnEmailNotification')}
        </span>
      </Modal.Footer>
    </>
  );
};

export default SocietyGuestsAddModal;
