import { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import UsersArray from 'modules/profile/components/UsersArray';

import { Communication } from 'types';

import Swal from 'sweetalert2';
import MenuDots from 'modules/_shared/components/MenuDots';
import { deleteCommEmail } from 'modules/communications/redux/communicationActions';
import { useGetCommunicationsSectionPermissions } from 'modules/communications/hooks/useGetCommunicationSectionPermissions';
import { getDateTime, getRecipientsText } from '../../utils';
import menuOptions from '../utils/menuOptions';

type InboxRowProps = {
  communication: Communication;
};

const InboxRow: FC<InboxRowProps> = ({ communication }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { isReadOnly } = useGetCommunicationsSectionPermissions().data;

  const getRecipientsForBadge = () => {
    if (communication?.recipients?.length === 1) {
      const recipient = communication?.recipients[0];

      if (!recipient.name && recipient.email) {
        recipient.name = recipient.email;
      }
      return [recipient];
    }
    return communication?.recipients;
  };

  const goToCommunicationDetails = (
    societyId: string,
    communicationId: string | undefined
  ) => {
    history.push(`/comunicaciones/${societyId}/${communicationId}`);
  };

  const handleDeleteCommunication = () => {
    Swal.fire({
      icon: 'warning',
      title:
        '<h4 class="nk-modal-title">¿Está seguro de que desea eliminar esta comunicación?</h4>',
      confirmButtonText: 'Ok, eliminar',
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCommEmail(communication?._id));
      }
    });
  };

  return (
    <div
      className={`nk-ibx-item ${communication?.isUnread ? 'is-unread' : ''}`}
      key={communication['_id']}
    >
      {/* ROW LINK */}
      <Link
        to={`/comunicaciones/${communication?.society}/${communication['_id']}`}
        className="nk-ibx-link"
      />

      {/* CERTIFICATE */}
      <div className="nk-ibx-item-elem nk-ibx-item-star">
        <Link
          to={`/comunicaciones/${communication?.society}/${communication?._id}/certificacion`}
          className="btn btn-icon btn-trigger"
        >
          <em className=" icon ni ni-shield-check-fill text-soft" />
        </Link>
      </div>

      {/* RECIPIENTS */}
      <div className="nk-ibx-item-elem nk-ibx-item-user">
        <div className="user-card">
          {/* @ts-ignore */}
          <UsersArray
            users={getRecipientsForBadge()}
            show={1}
            size="w-40px"
            bgColor="bg-primary"
          />
          <div className="user-name ml-1">
            <div className="lead-text">
              {getRecipientsText(
                communication?.recipients,
                communication?.presetGroup
              )}
            </div>
          </div>
        </div>
      </div>

      {/* SUBJECT & BODY */}
      <div
        className="nk-ibx-item-elem nk-ibx-item-fluid"
        onClick={() =>
          goToCommunicationDetails(communication.society, communication._id)
        }
      >
        <div className="nk-ibx-context-group">
          <div className="nk-ibx-context">
            <span className="nk-ibx-context-text">
              <span className="heading mr-1">{communication?.subject}</span>
              {communication?.bodySummary || ''}
            </span>
          </div>
        </div>
      </div>

      <div
        className="nk-ibx-item-elem nk-ibx-item-attach"
        onClick={() =>
          goToCommunicationDetails(communication.society, communication._id)
        }
      />

      {/* DATE */}
      <div className="nk-ibx-item-elem nk-ibx-item-time">
        <div className="sub-text">{getDateTime(communication?.date)}</div>
      </div>

      {/* ACTIONS */}
      <div className="nk-ibx-item-elem nk-ibx-item-tools">
        <div className="ibx-actions">
          {!isReadOnly && (
            <>
              <ul className="ibx-actions-hidden gx-1">
                <li>
                  <span
                    className="btn btn-sm btn-icon btn-trigger"
                    data-toggle="tooltip"
                    data-placement="top"
                    data-original-title="Delete"
                    onClick={handleDeleteCommunication}
                  >
                    <em className="icon ni ni-trash" />
                  </span>
                </li>
              </ul>
              <ul className="ibx-actions-visible gx-2">
                <li>
                  <div className="dropdown">
                    <MenuDots
                      menuOptions={menuOptions}
                      // @ts-ignore
                      id={communication['_id']}
                      params={{
                        data: communication,
                        societyId: communication?.society,
                      }}
                      direction="left"
                    />
                  </div>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(InboxRow);
