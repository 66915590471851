// TODO: unify with consolidationPeriods methods
export const getVestingDates = (beneficiary, plan) => {
  const cliff =
    beneficiary?.customConditions && 'cliff' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.cliff
      : plan?.cliff;

  const consolidation =
    beneficiary?.customConditions &&
    'consolidation' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.consolidation
      : plan?.consolidation;

  const vestingPeriod =
    beneficiary?.customConditions &&
    'vestingPeriod' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.vestingPeriod
      : plan?.vestingPeriod;

  const cliffDate = new Date(beneficiary?.planStartDate);
  cliffDate.setMonth(cliffDate.getMonth() + (Number(cliff) || 0));

  const consolidationDate = new Date(beneficiary?.planStartDate);
  consolidationDate.setMonth(
    consolidationDate.getMonth() + Number(vestingPeriod || 0)
  );

  const hasEndMonthConsolidation =
    beneficiary?.customConditions &&
    'hasEndMonthConsolidation' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.hasEndMonthConsolidation
      : plan?.hasEndMonthConsolidation || false;

  const periods = [];

  if (consolidation < 1 || !vestingPeriod) {
    periods.push(consolidationDate);
  } else {
    const count = Math.ceil(vestingPeriod / consolidation);

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < count; i++) {
      let months = (i + 1) * consolidation;

      if (i === count - 1 && vestingPeriod % consolidation !== 0) {
        months = i * consolidation + (vestingPeriod % consolidation);
      }

      const finalDate = new Date(beneficiary.planStartDate);

      if (hasEndMonthConsolidation) {
        finalDate.setMonth(finalDate.getMonth() + months, 0);
      } else {
        finalDate.setMonth(finalDate.getMonth() + months);
      }

      periods.push(finalDate);
    }
  }

  return periods;
};

export const getLastVesting = (beneficiary, plan, date) => {
  const vestingDates = getVestingDates(beneficiary, plan);

  const lastVesting = vestingDates.reduce((acc, curr) => {
    if (curr <= date) return curr;
    return acc;
  }, null);

  return lastVesting;
};
