/* eslint-disable no-console */

import { fileToArrayBuffer } from 'utils/files';
import queryTypes from 'constants/queryTypes';

import { getDocxContent } from './documents';
import extractData from './extractData';

async function readDocx(file: any) {
  try {
    const arrayBuffer = await fileToArrayBuffer(file);
    const textElements = await getDocxContent(arrayBuffer);

    let text = '';
    Array.from(textElements).forEach((element: any) => {
      text += element.textContent;
    });
    return text;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function handleDocxDocument(file: any) {
  try {
    const text = await readDocx(file);

    const extractedSociety = await extractData(text, queryTypes.SOCIETY);
    const extractedConstitution = await extractData(
      text,
      queryTypes.CONSTITUTION
    );
    const extractedPartners = await extractData(text, queryTypes.PARTNERS);

    return {
      extractedSociety,
      extractedConstitution,
      extractedPartners,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function handlePDFDocument(file: any) {
  try {
    const [extractedSociety, extractedPartners, extractedConstitution] =
      await extractData('', queryTypes.SOCIETY_PARTNERS_CONSTITUTION, file);

    return { extractedSociety, extractedConstitution, extractedPartners };
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function scanFile(file: any) {
  const fileTypes = {
    PDF: 'application/pdf',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };

  try {
    let resultData = {};

    if (file?.type === fileTypes.PDF) {
      resultData = await handlePDFDocument(file);
    } else if (file?.type === fileTypes.DOCX) {
      resultData = await handleDocxDocument(file);
    }

    return resultData;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
