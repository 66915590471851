const transactionTypes = {
  ADD_PARTNER: 'ADD_PARTNER',
  CONSTITUTION: 'CONSTITUTION',
  CAPITAL_INCREASE: 'CAPITAL_INCREASE',
  CAPITAL_DECREASE: 'CAPITAL_DECREASE',
  HERITAGE: 'HERITAGE',
  DONATION: 'DONATION',
  BUY: 'BUY',
  SELL: 'SELL',
  SPLIT: 'SPLIT',
  RENUMERATION: 'RENUMERATION',
  RECLASSIFICATION: 'RECLASSIFICATION',
};

module.exports = transactionTypes;
