export default {
  var: {
    SAFE_DRAFT: 'SAFE_DRAFT',
    SAFE_ACTIVE: 'SAFE_ACTIVE',
    SAFE_EXECUTED: 'SAFE_EXECUTED',
  },
  name: {
    SAFE_DRAFT: 'Draft',
    SAFE_ACTIVE: 'InForce',
    SAFE_EXECUTED: 'Converted',
  },
  style: {
    SAFE_DRAFT: 'badge-success',
    SAFE_ACTIVE: 'badge-danger',
    SAFE_EXECUTED: 'badge-gray',
  },
};
