import jwtDecode from 'jwt-decode';
import { generateToken } from 'utils/token';

/* eslint-disable no-underscore-dangle */
const updateHeaderInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const jwtToken = localStorage.getItem('access-token');

      if (jwtToken) {
        const decodedToken = jwtDecode(jwtToken);
        const userToken = localStorage.getItem('token');

        const updatedToken = generateToken({
          ...decodedToken,
          id: userToken,
        });
        // eslint-disable-next-line no-param-reassign
        config.headers['access-token'] = `${updatedToken}`;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalConfig = error.config;
      if (error.response) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const id = localStorage.getItem('token');
            const newToken = generateToken({
              _id: id,
            });
            localStorage.setItem('access-token', newToken);

            const { data } = await axiosInstance.get(
              `${process.env.REACT_APP_API_URL}/users/${id}`
            );

            const decodedUserData = jwtDecode(data);
            const accessToken = generateToken({
              _id: decodedUserData._id,
              email: decodedUserData.email,
              name: decodedUserData.name,
              phone: decodedUserData.phone,
              isActive: decodedUserData.isActive,
              creationDate: decodedUserData.creationDate,
              verificationDate: decodedUserData.verificationDate,
              lastAccess: decodedUserData.lastAccess,
              check: decodedUserData.check,
            });

            localStorage.setItem('access-token', accessToken);
            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(error);
    }
  );
};

export default updateHeaderInterceptor;
