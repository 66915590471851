import { FC } from 'react';

import { CoOwner } from 'types';
import { formatNumberDecimals } from 'utils/formats';

type Props = {
  index: number;
  coOwner: CoOwner;
};

const ShareEncumbranceViewTableCoOwnerRow: FC<Props> = ({ index, coOwner }) => (
  <>
    <div className="co-owner-row" key={`encumbrance-co-owner-${index}`}>
      <span className="fixed-width-percent">
        • {formatNumberDecimals(coOwner?.percent)}%
      </span>
      <span> | {coOwner?.name}</span>
    </div>
  </>
);

export default ShareEncumbranceViewTableCoOwnerRow;
