import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  handleCloseModal: () => void;
};

const ConstitutionAiModalHeader: FC<Props> = ({ handleCloseModal }) => {
  const { t } = useTranslate();

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('SocietyConstitution')}</h5>
        <a className="close cursor-pointer" onClick={handleCloseModal}>
          <em className="icon ni ni-cross cursor-pointer" />
        </a>
      </Modal.Header>
    </>
  );
};

export default ConstitutionAiModalHeader;
