export default function getInstructionTextColor(instruction: string) {
  switch (instruction) {
    case 'YES':
      return 'text-success';
    case 'NO':
      return 'text-danger';
    case 'ABS':
      return 'text-gray';
    default:
      return '';
  }
}
