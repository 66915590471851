import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslate } from 'hooks/useTranslate';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

type Props = {
  handleSaveAdministrator: () => void;
};

const AddAdminModalFooter: FC<Props> = ({ handleSaveAdministrator }) => {
  const { t } = useTranslate();

  const { isAdmin, isReadOnly } = useGetSocietySectionPermissions().data;

  return (
    <Modal.Footer className="bg-light d-flex justify-content-between">
      <span className="sub-text">{t('TheAdminWillReceive')}</span>
      <button
        type="button"
        onClick={handleSaveAdministrator}
        className="btn btn-lg btn-primary"
        disabled={isReadOnly}
      >
        {t('AddAdmin')}
      </button>
    </Modal.Footer>
  );
};

export default AddAdminModalFooter;
