import { useTranslate } from 'hooks/useTranslate';
import { useDispatch } from 'react-redux';

import { setModal } from 'modules/_shared/redux/modalActions';

import signatureCompleted from 'assets/images/signature-completed.svg';
import signaturePending from 'assets/images/signature-pending.svg';
import signatureSend from 'assets/images/signature-send.svg';
import SignatureModal from 'modules/documents/pages/Documents/signatureModal';
import { useGetDocumentsSectionPermissions } from 'modules/documents/hooks/useGetDocumentsSectionPermissions';

const SignaturesHeader = ({ setSignatureDocumentCategory }) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { isReadOnly } = useGetDocumentsSectionPermissions().data;

  const handleSignDocument = () => {
    if (isReadOnly) return;
    dispatch(setModal(<SignatureModal />));
  };

  return (
    <div className="nk-files nk-files-view-grid mb-4">
      <div className="nk-files-list">
        <div
          className="nk-file-item nk-file cursor-pointer"
          onClick={handleSignDocument}
        >
          <div className="nk-file-info">
            <div href="#" className={isReadOnly ? '' : 'nk-file-link'}>
              <div className="nk-file-title">
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={signatureSend} alt="Sign new document" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{t('SignNewDocument')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="nk-file-item nk-file cursor-pointer"
          onClick={() => setSignatureDocumentCategory('SIGNED')}
        >
          <div className="nk-file-info">
            <div className="nk-file-link">
              <div className="nk-file-title">
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={signatureCompleted} alt="Documents signed" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{t('DocumentsSigned')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="nk-file-item nk-file cursor-pointer"
          onClick={() => setSignatureDocumentCategory('PENDING')}
        >
          <div className="nk-file-info">
            <div className="nk-file-link">
              <div className="nk-file-title">
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={signaturePending} alt="Documents pending" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{t('DocumentsPending')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignaturesHeader;
