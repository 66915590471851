import { EMAIL_PATTERN } from 'constants/defaultConstants';
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import SocietyAdministratorPermissionsForm from './SocietyAdministratorPermissionsForm';

type Props = {
  email: string;
  societyAdministratorPermissions: any;
  // eslint-disable-next-line no-unused-vars
  handleChangeEmail: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangePermissions: (permissions: any) => void;
};

const AddAdminModalBody: FC<Props> = ({
  email,
  societyAdministratorPermissions,
  handleChangeEmail,
  handleChangePermissions,
}) => {
  const { t } = useTranslate();

  return (
    <Modal.Body>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              {t('Email')}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-mail" />
              </div>
              <input
                type="email"
                className="form-control form-control-lg"
                placeholder={t('EmailOfTheNewAdmin')}
                value={email}
                onChange={handleChangeEmail}
                pattern={EMAIL_PATTERN}
              />
            </div>
          </div>
        </div>
      </div>

      <SocietyAdministratorPermissionsForm
        className="my-4"
        defaultPermissions={societyAdministratorPermissions}
        onChangePermissions={handleChangePermissions}
      />
    </Modal.Body>
  );
};

export default AddAdminModalBody;
