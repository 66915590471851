import { Partner, Society } from 'types';

export default function getPartnerNDPercent(
  partner: Partner | undefined,
  society: Society | undefined
): number {
  if (!partner || !society) return 0;

  const partnerActualShares = partner.sharesCount?.actual || 0;
  const societyActualShares = society.sharesCount?.actual || 0;

  const NDPercent = (partnerActualShares * 100) / societyActualShares;

  return NDPercent || 0;
}
