/* eslint-disable react/react-in-jsx-scope */
import AddBeneficiary from 'modules/beneficiaries/modals/AddBeneficiary';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-info',
      text: i18n.t('NewBeneficiary'),
      modal: <AddBeneficiary action="add" isDraft />,
    },
  ];
}
export default menuOptions;
