import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { store } from 'redux/store';

import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { saveCustomDashboard } from 'modules/dashboard/api/customDashboard';
import DashboardEditHeader from 'modules/dashboard/components/DashboardEditHeader';
import DashboardEditSection from 'modules/dashboard/components/DashboardEditSection';
import dashboardSectionType from 'modules/dashboard/constants/dashboardSectionType';
import { Landing, Section } from 'modules/dashboard/types/CustomDashboard';

type EditCustomDashboardProps = {
  type: string;
  sectionIndex?: number;
};

const EditCustomDashboard: FC<EditCustomDashboardProps> = ({
  type,
  sectionIndex,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const customLanding = useSelector(
    (state: State) =>
      state.society?.actualSociety?.configuration?.customLanding || {}
  );

  const [currentDashboard, setCurrentDashboard] =
    useState<Landing>(customLanding);

  const [currentSection] = useState<Section>(
    customLanding?.sections?.find(
      (section: Section) => section.index === sectionIndex
    ) || {
      index: sectionIndex || 0,
      title: '',
      description: '',
      folders: [],
    }
  );

  const modalsBySection = (type: string) => {
    if (type === dashboardSectionType.HEADER) {
      return (
        <DashboardEditHeader
          customDashboard={currentDashboard}
          setCustomDashboard={setCurrentDashboard}
        />
      );
    }
    if (type === dashboardSectionType.SECTION) {
      return (
        <DashboardEditSection
          section={currentSection}
          customDashboard={currentDashboard}
          setCustomDashboard={setCurrentDashboard}
        />
      );
    }
  };

  const modalTitle = {
    [dashboardSectionType.HEADER]: 'EditDashboardHeader',
    [dashboardSectionType.SECTION]: 'EditDashboardSection',
  };

  const handleSaveCloseModal = async () => {
    try {
      await store.dispatch(saveCustomDashboard(currentDashboard));
      dispatch(addAlert(alertBodyTypes.UPDATING_CUSTOM_DASHBOARD_SUCCESSFULLY));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.UPDATING_CUSTOM_DASHBOARD_ERROR));
    } finally {
      dispatch(setModal(null));
    }
  };

  const closeModal = () => {
    dispatch(setModal(null));
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t(modalTitle[type])}</h5>
        <a className="close cursor-pointer" onClick={closeModal}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>{modalsBySection(type)}</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleSaveCloseModal}
        >
          {t('Save')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default EditCustomDashboard;
