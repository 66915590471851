import { Range } from 'types';
import { FixedShare } from '../types/Share';
import removeRangeFromRanges from './removeRangeFromRanges';

export default function getInitialRanges(share: FixedShare) {
  const encumbrances = share?.encumbrances;
  let ranges: Range[] = [{ from: share?.from, to: share?.to }];
  encumbrances.forEach((encumbrance) => {
    ranges = removeRangeFromRanges(encumbrance?.from, encumbrance?.to, ranges);
  });

  return ranges;
}
