import React, { FC } from 'react';

import { EnvelopeSigner } from 'types';

import useGetSignedSignersCount from '../hooks/useGetSignedSignersCount';

import SignatureViewHeader from './SignatureViewHeader';
import SignatureViewTable from './SignatureViewTable';
import useGetSignatureEnvelopeStatus from '../hooks/useGetSignatureEnvelopeStatus';

type Props = {
  signers: EnvelopeSigner[];
  sentDate: Date | undefined;
  refetchSigners: () => void;
};

const SignatureView: FC<Props> = ({ signers, sentDate, refetchSigners }) => {
  const { signedSignersCount } = useGetSignedSignersCount(signers);
  const { signatureEnvelopeStatus } = useGetSignatureEnvelopeStatus(
    signedSignersCount,
    signers
  );

  return (
    <>
      <SignatureViewHeader
        signatureEnvelopeStatus={signatureEnvelopeStatus}
        sentDate={sentDate}
        signedSignersCount={signedSignersCount}
        totalSigners={signers?.length}
        refetchSigners={refetchSigners}
      />
      {signers.length > 0 && <SignatureViewTable signers={signers} />}
    </>
  );
};

export default SignatureView;
