import representativeTypes from 'modules/society/components/SocietyRepresentative/representativeTypes';
import statusTypes from 'modules/society/components/SocietyRepresentative/statusTypes';

type RepresentativeType = {
  [key: string]: Record<string, string>;
};

type RepresentativeStatus = {
  [key: string]: Record<string, string>;
};

export const getRepresentativeType = (type: string): string => {
  if (!type) return '-';

  const currentType: RepresentativeType = representativeTypes;
  return currentType.name[type] || '-';
};

export const getRepresentativeStatus = (status: string): string => {
  if (!status) return '-';

  const currentStatus: RepresentativeStatus = statusTypes;
  return currentStatus.name[status] || '-';
};
