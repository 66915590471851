import i18n from 'i18n/config';
import operationTypes from './operationTypes';

export default {
  [operationTypes.ADD_PARTNER]: i18n.t('ADD_PARTNER'),
  [operationTypes.ADD_BENEFICIARY]: i18n.t('ADD_BENEFICIARY'),
  [operationTypes.ADD_INVESTOR]: i18n.t('AddInvestor'),
  [operationTypes.ADD_HOLDING_CLASS]: i18n.t('AddClass'),
  [operationTypes.SELL_PARTICIPATION]: i18n.t('SELL_PARTICIPATION'),
  [operationTypes.HERITAGE]: i18n.t('HERITAGE'),
  [operationTypes.DONATION]: i18n.t('DONATION'),
  [operationTypes.CONSTITUTION]: i18n.t('CONSTITUTION'),
  [operationTypes.CAPITAL_INCREASE]: i18n.t('CAPITAL_INCREASE'),
  [operationTypes.CAPITAL_DECREASE]: i18n.t('CAPITAL_DECREASE'),
  [operationTypes.STOCK_OPTION]: i18n.t('STOCK_OPTION'),
  [operationTypes.PHANTOM_SHARE]: i18n.t('PHANTOM_SHARE'),
  [operationTypes.WARRANT]: 'Warrant',
  [operationTypes.SPLIT]: i18n.t('SPLIT'),
  [operationTypes.RENUMERATION]: i18n.t('RENUMERATION'),
  [operationTypes.RECLASSIFICATION]: i18n.t('Reclassification'),
  [operationTypes.DIVIDENDS_DISTRIBUTION]: i18n.t('DividendsDistribution'),
};
