import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

const SignatureViewTableHeader: FC = () => {
  const { t } = useTranslate();

  return (
    <>
      <thead>
        <tr>
          <th className="text-left">{t('Signer')}</th>
          <th className="text-center">{t('SignDate')}</th>
          <th className="text-center">{t('SignStatus')}</th>
        </tr>
      </thead>
    </>
  );
};

export default SignatureViewTableHeader;
