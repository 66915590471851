import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { useSelector } from 'react-redux';

type InputErrorProps = {
  errorMessage: string;
};

const InputError: FC<InputErrorProps> = ({ errorMessage }) => {
  const { t } = useTranslate();

  // eslint-disable-next-line no-unused-vars
  const user: any = useSelector((state: any) => state.user);
  // eslint-disable-next-line no-unused-vars
  const isAdmin: boolean = useSelector(
    (state: any) => state.society?.role?.isAdmin
  );

  return (
    <span className="sub-text text-danger">
      <i>{t(errorMessage)}</i>
    </span>
  );
};

export default InputError;
