import { useMixpanel } from 'react-mixpanel-browser';

import { FC, useEffect, useState } from 'react';
import { Partner, Society } from 'types';

import { translate, useTranslate } from 'hooks/useTranslate';
import CustomDataTable from 'modules/_shared/components/Tables/CustomDataTable';
import transformData from 'modules/_shared/components/Tables/helpers';
import { sindicationTypes } from 'modules/partners/constants/sindicationTypes';
import { useGetPartnersSectionPermissions } from 'modules/partners/hooks/useGetPartnerSectionsPermissions';
import tableColumns from './tableColumns';

type Props = {
  representants: Record<string, any>[];
  actualSociety: Society;
};

const getPartnerRepresentedData = (
  partners: Partner[],
  representantId: string
) => {
  const partnerData = partners.filter(
    (partner) => partner?.sindication === representantId
  );

  if (partnerData?.length) {
    const totalPartners = partnerData.length;
    const partnersList = partnerData.map((partner) => (
      <p className="pb-0 mb-0">
        {partner.name}&nbsp;
        <b>
          ({translate(partner?.sindicationType || sindicationTypes.STANDARD)})
        </b>
      </p>
    ));

    return {
      partnersRepresented: totalPartners,
      partnersRepresentedText: partnersList,
    };
  }

  return {
    partnersRepresented: 0,
  };
};

const parseRepresentantsToTable = (
  representants: Record<string, any>[],
  isAdmin: boolean,
  isReadOnly: boolean,
  mixpanel: any,
  partners: Partner[],
  societyId: string
) => {
  if (!representants) return [{}];

  const tableData = representants.map((representant, index) => ({
    ...representant,
    index: index + 1,
    name: representant?.name,
    representant,
    hasLink: true,
    societyId,
    isReadOnly,
    isAdmin,
    mixpanel,
    ...getPartnerRepresentedData(partners, representant._id),
  }));

  return tableData;
};

const RepresentantsListTable: FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslate();
  const mixpanel = useMixpanel();

  const { isAdmin, isReadOnly } = useGetPartnersSectionPermissions().data;

  const { representants, actualSociety } = props;

  const partners = actualSociety?.partners || [];
  const societyId = actualSociety._id;

  const [tableData, setTableData] = useState([]);

  const columns = tableColumns(i18n);

  useEffect(() => {
    setTableData(
      parseRepresentantsToTable(
        representants,
        isAdmin,
        isReadOnly,
        mixpanel,
        partners,
        societyId
      ) as any
    );
  }, [representants]); // eslint-disable-line

  return (
    <div className="nk-block">
      <div className="card card-bordered card-stretch">
        <div className="card-inner-group">
          <div className="card-inner p-0">
            <CustomDataTable
              data={transformData({
                data: tableData,
                columns,
              })}
              columns={columns}
              searchBy={[t('Name'), t('Email'), t('Cif')]}
              searchByPlaceholder={[t('name'), t('email'), t('CIF')].join(', ')}
              pagination
              rowsPerPage={10}
              className="nk-tb-list"
              actions
              showDense
              showDownload
              fileName={`${t('Representants')} ${actualSociety?.name}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentantsListTable;
