import { useEffect, useState } from 'react';
import { Communication } from 'types';

type Props = {
  communicationId: string | undefined;
  communications: Communication[];
};

export default function useGetCurrentCommunication({
  communicationId,
  communications,
}: Props) {
  const [currentCommunication, setCurrentCommunication] =
    useState<Communication>();

  useEffect(() => {
    if (communicationId && communications.length > 0) {
      const communication = communications.find(
        (communication: Communication) =>
          communication['_id'] === communicationId
      );

      setCurrentCommunication(communication);
    }
  }, [communications, communicationId]);

  return {
    data: {
      currentCommunication,
    },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
