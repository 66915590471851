const isValidEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }

  if (email.length !== email.trim().length) {
    return false;
  }

  if (email !== email.toLowerCase()) {
    return false;
  }

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const isValidEmailPrefix = (email: string): boolean => {
  const regex = /^[a-zA-Z0-9._-]+$/;
  return regex.test(email);
};

export default isValidEmail;
