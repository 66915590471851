import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

type Props = {
  cancelationDate: string | undefined;
  handleChangeCancelationDate: (event: any) => void;
};

const CancelBeneficiaryDateSection: FC<Props> = ({
  cancelationDate,
  handleChangeCancelationDate,
}) => {
  const { t } = useTranslate();

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="pay-amount">
        {t('CancelationDate')}
      </label>
      <div className="form-control-wrap">
        <input
          type="date"
          className="form-control date-picker form-control-lg"
          value={cancelationDate}
          onChange={handleChangeCancelationDate}
          required
          max="2100-01-01"
        />
      </div>
    </div>
  );
};

export default CancelBeneficiaryDateSection;
