import React, { useState } from 'react';
import i18n from 'i18n/config';

import List from 'modules/documents/components/List';
import documentSections from 'constants/documentSections';
import Header from 'modules/documents/components/Header';

const currentSection = 'ALL';

const Alls = ({
  documents,
  setSelectedSection,
  setCurrentBook,
  setCurrentFolder,
  setCurrentCouncilMinute,
  setSignatureDocumentCategory,
  isLoadingData,
  searchTerm,
}) => {
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );
  return (
    <>
      <div className="nk-fmg-body-content">
        <Header
          setSelectedSection={setSelectedSection}
          setCurrentBook={setCurrentBook}
          setCurrentFolder={setCurrentFolder}
          setCurrentCouncilMinute={setCurrentCouncilMinute}
          setSignatureDocumentCategory={setSignatureDocumentCategory}
        />
      </div>

      <List
        section={section}
        documents={documents}
        isLoadingData={isLoadingData}
        searchTerm={searchTerm}
      />
    </>
  );
};

export default Alls;
