/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import NumberFormat from 'react-number-format';
import { connect, useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import { SELECCIONAR_SOCIO, SELECT_OPTION } from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import { cleanValue, currencyFormat, numberFormat } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import CommentsCharCounter from 'modules/_shared/components/CommentsCharCounter';
import { addAlert } from 'modules/_shared/redux/alertActions';
import { setModal } from 'modules/_shared/redux/modalActions';
import { capitalDecreaseAction } from 'modules/_shared/redux/modalsActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import cleanText from 'utils/cleanText';
import { getActualPartner } from 'utils/filters';
import trackEvent from 'utils/trackEvent';

import Swal from 'sweetalert2';
import '../Modals.scss';

const DECREMENT = 'DECREMENT';

function CapitalDecreaseModal() {
  const { t } = useTranslate();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society.actualSociety);
  const holdingClasses = useSelector((state) => state.holdingClasses);

  const [step, setStep] = useState(0);
  const [missingParticipations, setMissingParticipations] = useState();
  const [movements, setMovements] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const [historyRanges, setHistoryRanges] = useState();
  const [historyOps, setHistoryOps] = useState([]);
  const [userShareRanges, setUserShareRanges] = useState([]);
  const [sharesTotal, setSharesTotal] = useState(0);
  const [sharesRange, setSharesRange] = useState([{ from: '', to: '' }]);
  const [isValidRange, setIsValidRange] = useState(false);
  const [participationsToRemove, setParticipationsToRemove] = useState();
  const [date, setDate] = useState('');
  const [lastOperationDate, setLastOperationDate] = useState();
  const [operationDate, setOperationDate] = useState();
  const [operationLimit, setOperationLimit] = useState();
  const [totalParticipationsToDelete, setTotalParticipationsToDelete] =
    useState('');
  const [customizeSharePrice, setCustomizeSharePrice] = useState(false);
  const [sharePrice, setSharePrice] = useState();
  const [file, setFile] = useState();
  const [comments, setComments] = useState('');
  const [notaryName, setNotaryName] = useState('');
  const [notaryCity, setNotaryCity] = useState('');
  const [protocolNr, setProtocolNr] = useState('');
  const [holdingClass, setHoldingClass] = useState(SELECT_OPTION);
  const [ordinaryClass] = useState(holdingClasses?.[0]?.['_id']);
  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [errorMessage, setErrorMessage] = useState('');

  const [partnerOptions, setPartnerOptions] = useState([]);

  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: t('SelectPartner'),
  });
  const [selectedClassOption, setSelectedClassOption] = useState({
    value: SELECT_OPTION,
    label: t('Select'),
  });

  const animatedComponents = makeAnimated();

  const handleDrop = (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    if (files.length > 1) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('MustUploadOneDocument')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    }
    // const pdfFiles = files.filter((file) => file.type === 'application/pdf');
    else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t(
          'WantUploadThisDocument'
        )} </h4>`,
        confirmButtonText: t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setFile(files[0]);
        }
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChooseDocument = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('WantUploadThisDocument')} </h4>`,
      confirmButtonText: t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setFile(file);
      }
    });
  };

  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedPartner(selectedOption.value);
    setSelectedPartnerOption(selectedOption);
  };

  const handleClassOptionChange = (selectedOption) => {
    setHoldingClass(selectedOption.value);
    setSelectedClassOption(selectedOption);
  };

  const handleDate = (selectedDate) => {
    const objDate = new Date(selectedDate);
    if (objDate.getFullYear() < 1970) {
      if (operationDate) setOperationDate();
    } else if (objDate > new Date(lastOperationDate)) {
      setErrorMessage('');
      setDate(objDate);
      setOperationDate(format(objDate, 'yyyy-MM-dd'));
    } else {
      const nextDate = new Date(lastOperationDate);
      nextDate.setMinutes(nextDate.getMinutes() + 30);
      setDate(nextDate);
      setOperationDate(format(nextDate, 'yyyy-MM-dd'));
    }
  };

  const handleSellAll = () => {
    const newSharesRange = userShareRanges.map((range) => ({
      from: range.shareFrom,
      to: range.shareTo,
    }));
    setSharesRange(newSharesRange);
    setIsValidRange(true);
  };

  const handleAddRange = () => {
    const newSharesRange = [...sharesRange];
    newSharesRange.push({
      from: '',
      to: '',
    });
    setSharesRange(newSharesRange);
  };

  const handleRemoveRange = (index) => {
    const newSharesRange = [...sharesRange];
    newSharesRange.splice(index, 1);
    setSharesRange(newSharesRange);
  };

  // devuelve true si no hay solapamiento entre los rangos
  const checkOverlappingRanges = (ranges) => {
    const compareFunction = (a, b) => +a.from - +b.from;
    const orderedRanges = ranges.sort(compareFunction);
    for (let index = 0; index < orderedRanges.length - 1; index += 1) {
      if (+orderedRanges[index].to >= +orderedRanges[index + 1].from)
        return false;
    }
    return true;
  };

  // devuelve true si el rango es subintervalo de algun intervalo en userShareRanges
  const checkExistsRange = (range) =>
    userShareRanges.some(
      (userRange) =>
        +range.from >= +userRange.shareFrom && +range.to <= +userRange.shareTo
    );

  const handleSharesRange = (index, field, value) => {
    const newSharesRange = [...sharesRange];
    if (field === 'FROM') {
      newSharesRange[index].from = value;
    } else {
      newSharesRange[index].to = value;
    }
    // validar rango de venta
    if (newSharesRange[index].from !== '' && newSharesRange[index].to !== '') {
      if (+newSharesRange[index].to < +newSharesRange[index].from) {
        setIsValidRange(false);
      } else if (!checkExistsRange(newSharesRange[index])) {
        setIsValidRange(false);
      } else if (!checkOverlappingRanges([...newSharesRange])) {
        setIsValidRange(false);
      } else setIsValidRange(true);
    }
    setSharesRange(newSharesRange);
  };

  useEffect(() => {
    if (selectedPartner !== SELECCIONAR_SOCIO) {
      const totalSharesRange = sharesRange.reduce((acc, curr) => {
        if (+curr.to && +curr.from && +curr.to >= +curr.from)
          return acc + +curr.to - +curr.from + 1;
        return acc;
      }, 0);
      setParticipationsToRemove(totalSharesRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharesRange]);

  useEffect(() => {
    if (selectedPartner !== SELECCIONAR_SOCIO) {
      setHoldingClass(SELECT_OPTION);
      setSelectedClassOption({ value: SELECT_OPTION, label: t('Select') });
    }
  }, [selectedPartner]); // eslint-disable-line

  useEffect(() => {
    if (actualSociety) {
      // initialize historyRanges for tracking of ranges
      const initialRanges = actualSociety?.partners.reduce((acc, partner) => {
        const partnerShares = actualSociety.shares.filter(
          (share) => share.isActive && share.partner === partner['_id']
        );
        const partnerRanges = partnerShares.map((share) => ({
          shareFrom: share.from,
          shareTo: share.to,
          sharePremium: share.sharePremium,
          sharePrice: share.sharePrice,
          shareClass: share.shareClass
            ? share.shareClass['_id']
            : ordinaryClass,
          operation: share?.operation || null,
        }));
        const compareFunction = (a, b) => +a.shareFrom - +b.shareFrom;
        const orderedRanges = partnerRanges.sort(compareFunction);
        acc[partner['_id']] = orderedRanges;
        return acc;
      }, {});
      setHistoryRanges(initialRanges);

      // Get last operation date
      const excludedOps = [
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredOperations = actualSociety.operations.filter(
        (operation) => !excludedOps.includes(operation.operationType)
      );
      const lastOperation = filteredOperations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );
      // Get last operation date from UTC to local timezone with new Date()
      const lastDate = format(
        new Date(lastOperation.date),
        "yyyy-MM-dd'T'HH:mm"
      );
      const dateLimit = format(new Date(lastOperation.date), 'yyyy-MM-dd');
      setOperationLimit(dateLimit);
      setLastOperationDate(lastDate || '1990-01-01T00:00');

      const options = actualSociety?.partners.map((partner) => ({
        value: partner?.['_id'],
        label: `${partner?.cif} | ${partner?.name} | ${
          partner?.sharesCount?.actual
        } ${t('shares')}`,
      }));

      setPartnerOptions(options);
    }
  }, [actualSociety]); // eslint-disable-line

  useEffect(() => {
    if (selectedPartner !== SELECCIONAR_SOCIO) {
      const totalSharesRange = sharesRange.reduce((acc, curr) => {
        if (+curr.to && +curr.from && +curr.to >= +curr.from)
          return acc + +curr.to - +curr.from + 1;
        return acc;
      }, 0);
      setParticipationsToRemove(totalSharesRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharesRange]);

  useEffect(() => {
    if (selectedPartner !== SELECCIONAR_SOCIO) {
      const currentPartner = getActualPartner(actualSociety, selectedPartner);
      const compareFunction = (a, b) => +a.shareFrom - +b.shareFrom;

      const ranges =
        holdingClass !== SELECT_OPTION
          ? historyRanges[currentPartner['_id']]
              .filter((share) => share.shareClass === holdingClass)
              .sort(compareFunction)
          : [];

      setUserShareRanges(ranges);
      setSharesRange([{ from: '', to: '' }]);
      setIsValidRange(false);
    } else {
      setUserShareRanges([]);
      setSharesRange([{ from: '', to: '' }]);
      setIsValidRange(false);
      setParticipationsToRemove('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner, holdingClass]);

  useEffect(() => {
    if (historyRanges && selectedPartner !== SELECCIONAR_SOCIO) {
      const sharesByClass = {};
      const partnerShares = historyRanges[selectedPartner];
      partnerShares.forEach((share) => {
        if (!sharesByClass[share.shareClass]) {
          sharesByClass[share.shareClass] = 0;
        }
        sharesByClass[share.shareClass] += share.shareTo - share.shareFrom + 1;
      });
      setSharesTotal(sharesByClass);
    }
  }, [selectedPartner, historyRanges, historyOps]);

  useEffect(() => {
    if (!customizeSharePrice) {
      setSharePrice('');
    }
  }, [customizeSharePrice]);

  async function saveDecrease() {
    if (selectedPartner === SELECCIONAR_SOCIO)
      dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      let uploadedDocument = null;

      if (file) {
        const newDocument = {
          file,
          date,
          size: file.size,
          name: operationTypes.CAPITAL_DECREASE_FILENAME,
          author: user['_id'],
          society: actualSociety['_id'],
          fileType: fileTypes[file.type],
          category: documentTypes.SCRIPTURES,
          description: t('DocumentLinkedToOperation'),
        };
        uploadedDocument = await store.dispatch(addDocument(newDocument));
      }

      const newMovements = [];
      const newTransactions = [];
      for (const actualRange of sharesRange) {
        if (actualRange.from === '' || actualRange.to === '') break;
        const currentUserRange = userShareRanges.find(
          (range) =>
            +actualRange.from >= range.shareFrom &&
            +actualRange.to <= range.shareTo
        );
        newMovements.push({
          partner: selectedPartner,
          shareFrom: currentUserRange.shareFrom,
          shareTo: currentUserRange.shareTo,
          shareClass: currentUserRange.shareClass,
          movementType: DECREMENT,
        });
        newTransactions.push({
          partner: selectedPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          sharePremium: currentUserRange.sharePremium,
          shareClass: currentUserRange.shareClass,
          sharePrice: +sharePrice || 0,
          operation: currentUserRange?.operation || null,
          transactionType: transactionTypes.CAPITAL_DECREASE,
        });
        if (currentUserRange.shareFrom !== +actualRange.from) {
          newMovements.push({
            partner: selectedPartner,
            shareFrom: currentUserRange.shareFrom,
            shareTo: +actualRange.from - 1,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
        if (currentUserRange.shareTo !== +actualRange.to) {
          newMovements.push({
            partner: selectedPartner,
            shareFrom: +actualRange.to + 1,
            shareTo: currentUserRange.shareTo,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
      }
      const incrementMovements = newMovements.filter(
        (movement) => !movement.movementType
      );
      const decrementMovements = newMovements.filter(
        (movement) => movement.movementType
      );
      const allMovements = [...incrementMovements, ...decrementMovements];
      const finalMovements = [...movements, ...allMovements];
      const finalTransactions = [...transactions, ...newTransactions];

      dispatch(
        capitalDecreaseAction({
          movements: finalMovements,
          transactions: finalTransactions,
          nominalValue: actualSociety?.nominalValue,
          date,
          society: actualSociety['_id'],
          user: user['_id'],
          documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
          comments: cleanText(comments),
          notarialRegistration: {
            notaryName,
            notaryCity,
            protocolNr,
          },
        })
      );
      dispatch(setModal(null));

      trackEvent(mixpanel, eventTypes.CAPITAL_DECREASE, {
        userId: user?._id,
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?._id,
        societyName: actualSociety?.name,
        operation: eventTypes.CAPITAL_DECREASE,
      });
    }
  }

  function nextPartner() {
    if (selectedPartner === SELECCIONAR_SOCIO)
      dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else {
      const newMovements = [];
      const newTransactions = [];
      const newHistoryOps = [...historyOps];
      let actualUserShareRanges = [...userShareRanges];
      for (const actualRange of sharesRange) {
        if (actualRange.from === '' || actualRange.to === '') break;
        // encuentra el rango de participaciones del que se vende
        const currentUserRange = actualUserShareRanges.find(
          (range) =>
            +actualRange.from >= range.shareFrom &&
            +actualRange.to <= range.shareTo
        );
        // elimina las participaciones vendidas de userShareRanges
        actualUserShareRanges = actualUserShareRanges.filter(
          (range) =>
            range.shareFrom !== currentUserRange.shareFrom &&
            range.shareTo !== currentUserRange.shareTo
        );
        // agrega el movement de las participaciones vendidas como un DECREMENT
        newMovements.push({
          partner: selectedPartner,
          shareFrom: currentUserRange.shareFrom,
          shareTo: currentUserRange.shareTo,
          shareClass: currentUserRange.shareClass,
          movementType: DECREMENT,
        });
        // agrega la transaction de tipo CAPITAL_DECREASE
        newTransactions.push({
          partner: selectedPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          sharePremium: currentUserRange.sharePremium,
          shareClass: currentUserRange.shareClass,
          sharePrice: +sharePrice || 0,
          operation: currentUserRange?.operation || null,
          transactionType: transactionTypes.CAPITAL_DECREASE,
        });
        const className = holdingClasses?.find(
          (shareClass) => shareClass['_id'] === holdingClass
        )?.name;
        newHistoryOps.push({
          partner: getActualPartner(actualSociety, selectedPartner)?.name,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: className,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: +sharePrice || 0,
        });
        // agrega los nuevos rangos resultantes a actualUserShareRanges
        if (currentUserRange.shareFrom !== +actualRange.from) {
          actualUserShareRanges.push({
            shareFrom: currentUserRange.shareFrom,
            shareTo: +actualRange.from - 1,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
          newMovements.push({
            partner: selectedPartner,
            shareFrom: currentUserRange.shareFrom,
            shareTo: +actualRange.from - 1,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
        if (currentUserRange.shareTo !== +actualRange.to) {
          actualUserShareRanges.push({
            shareFrom: +actualRange.to + 1,
            shareTo: currentUserRange.shareTo,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
          newMovements.push({
            partner: selectedPartner,
            shareFrom: +actualRange.to + 1,
            shareTo: currentUserRange.shareTo,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
      }
      const incrementMovements = newMovements.filter(
        (movement) => !movement.movementType
      );
      const decrementMovements = newMovements.filter(
        (movement) => movement.movementType
      );
      const finalMovements = [...incrementMovements, ...decrementMovements];
      setMovements([...movements, ...finalMovements]);
      setTransactions([...transactions, ...newTransactions]);

      const subsetRanges = historyRanges[selectedPartner].filter(
        (share) => share.shareClass !== holdingClass
      );
      const compareFunction = (a, b) => +a.shareFrom - +b.shareFrom;
      const orderedRanges = actualUserShareRanges.sort(compareFunction);
      const newHistoryRanges = historyRanges;
      newHistoryRanges[selectedPartner] = [...subsetRanges, ...orderedRanges];
      setHistoryRanges(newHistoryRanges);
      setHistoryOps(newHistoryOps);
      setUserShareRanges(orderedRanges);
      setSharesRange([{ from: '', to: '' }]);
      setIsValidRange(false);
    }
  }

  function nextStep(event) {
    event.preventDefault();
    setHoldingClass(SELECT_OPTION);
    setSelectedClassOption({ value: SELECT_OPTION, label: t('Select') });
    if (!step) {
      setTotalParticipationsToDelete(totalParticipationsToDelete);
      setMissingParticipations(totalParticipationsToDelete);
      setStep(1);
    } else if (missingParticipations > +participationsToRemove) {
      nextPartner();
      setMissingParticipations(missingParticipations - +participationsToRemove);
    } else if (missingParticipations < +participationsToRemove)
      dispatch(addAlert(alertBodyTypes['INVALID_RANGES']));
    else saveDecrease();
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('CapitalReduction')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={nextStep}>
          {!step ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('CapitalReductionDate')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control date-picker"
                    value={operationDate}
                    onChange={(event) => handleDate(event.target.value)}
                    required
                    max="2100-01-01"
                    min={operationLimit}
                  />
                </div>
                {errorMessage ? (
                  <span className="sub-text mt-1 text-danger">
                    {errorMessage}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('NºInvokedParticipations')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Nr')}</span>
                  </div>
                  <NumberFormat
                    id="outlined-normal"
                    className="form-control form-control-outlined"
                    placeholder={t('NºInvokedParticipations')}
                    value={totalParticipationsToDelete}
                    onChange={(e) =>
                      setTotalParticipationsToDelete(
                        cleanValue(e.target.value) || ''
                      )
                    }
                    isAllowed={(inputObj) => {
                      const { floatValue, formattedValue } = inputObj;
                      if (formattedValue === '') return true;
                      if (
                        Number.isInteger(floatValue) &&
                        floatValue >= 1 &&
                        floatValue <= actualSociety?.sharesCount?.actual
                      )
                        return true;
                      return false;
                    }}
                    required
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </div>
                <span className="form-label text-primary">
                  {t('TotalParticipationsAvaila', {
                    societyShares: actualSociety?.sharesCount?.actual,
                  })}
                </span>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  {t('AddWritingOptional')}
                </label>
                <div
                  className="nk-block-head-content"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div onDrop={handleDrop} onDragOver={handleDragOver}>
                    <div className="card card-bordered sp-plan">
                      <div
                        className="sp-plan-action card-inner"
                        style={{
                          height: '10px',
                          width: '460px',
                        }}
                      >
                        <div>
                          <span className="text-center w-300px d-block text-ellipsis">
                            {file?.name || t('DragDocumentToUpload')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleChooseDocument}
                    accept=".pdf"
                  />
                  <label
                    htmlFor="fileInput"
                    className="btn btn-light"
                    style={{ marginBottom: '0px' }}
                  >
                    {t('Choose')}
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="operation-comments">
                  {t('CommentsLabel')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-editor-custom">
                    <textarea
                      id="operation-comments"
                      maxLength="500"
                      className="form-control form-control-lg no-resize"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-note d-flex justify-content-between">
                  <>{t('CommentsVisibleOnlyAdmins')}</>
                  <CommentsCharCounter comments={comments} />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryName')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Mr. John Smith')}
                        value={notaryName}
                        onChange={(e) => setNotaryName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">
                      {t('NotaryProtocolNumber')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('P-8234290A')}
                        value={protocolNr}
                        onChange={(e) => setProtocolNr(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label className="form-label">{t('NotaryCity')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-md"
                        placeholder={t('Barcelona')}
                        value={notaryCity}
                        onChange={(e) => setNotaryCity(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                {t('Following')}
              </button>
            </>
          ) : (
            <div>
              <label className="form-label mb-4">
                <span className="bg-warning p-2">
                  {t('TotalParticipationsPendingDelete')}:
                </span>
                <span className="badge-primary rounded px-2 py-1 ml-2">
                  <NumberFormat
                    displayType="text"
                    value={missingParticipations}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </span>
              </label>
              <div className="form-group">
                <label className="form-label">{t('PartnerName')}</label>
                <div className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    className="react-select"
                    value={selectedPartnerOption}
                    options={partnerOptions}
                    components={animatedComponents}
                    onChange={handlePartnerOptionChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('ClassOfParticipations')}
                </label>
                <div className="form-control-wrap">
                  <div className="">
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      value={selectedClassOption}
                      options={holdingClasses
                        ?.filter(
                          (holdingClass) => sharesTotal?.[holdingClass['_id']]
                        )
                        .map((holdingClass) => ({
                          value: holdingClass['_id'],
                          label: `${holdingClass.name} | ${
                            sharesTotal?.[holdingClass['_id']] || 0
                          }${' '}
              ${t('shares')}`,
                        }))}
                      components={animatedComponents}
                      onChange={handleClassOptionChange}
                      placeholder={SELECT_OPTION}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 d-flex justify-content-between align-items-center">
                  <label className="form-label mb-0" htmlFor="pay-amount">
                    {t('NºParticipationsThatElimi')}
                    <span className="badge-primary rounded px-2 py-1 ml-2">
                      <NumberFormat
                        displayType="text"
                        value={participationsToRemove || 0}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                    </span>
                  </label>

                  <button
                    className="btn btn-outline-primary btn-dim border-radius-0 float-right"
                    type="button"
                    onClick={() => handleSellAll()}
                    disabled={selectedPartner === SELECCIONAR_SOCIO}
                  >
                    <span>{t('EliminateAllParticipation')}</span>
                  </button>
                </div>
              </div>
              <div className="form-group">
                {sharesRange.map((shareRange, index) => (
                  <div className="form-control-wrap pb-1">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon3">
                          {t('OfTheNo')}
                        </span>
                      </div>
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-outlined"
                        value={shareRange.from}
                        onChange={(e) =>
                          handleSharesRange(
                            index,
                            'FROM',
                            cleanValue(e.target.value) || ''
                          )
                        }
                        isAllowed={(inputObj) => {
                          const { floatValue, formattedValue } = inputObj;
                          if (formattedValue === '') return true;
                          if (floatValue >= 1 && Number.isInteger(floatValue))
                            return true;
                          return false;
                        }}
                        disabled={!selectedPartner}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon3">
                          {t('ToTheNo')}
                        </span>
                      </div>
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-outlined"
                        value={shareRange.to}
                        onChange={(e) =>
                          handleSharesRange(
                            index,
                            'TO',
                            cleanValue(e.target.value) || ''
                          )
                        }
                        isAllowed={(inputObj) => {
                          const { floatValue, formattedValue } = inputObj;
                          if (formattedValue === '') return true;
                          if (floatValue >= 1 && Number.isInteger(floatValue))
                            return true;
                          return false;
                        }}
                        disabled={!selectedPartner}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                      {sharesRange.length > 1 &&
                      index < sharesRange.length - 1 ? (
                        <button
                          className="btn btn-outline-primary btn-dim border-radius-0 col-4"
                          type="button"
                          onClick={() => handleRemoveRange(index)}
                        >
                          <em className="icon ni ni-minus" />
                          <span>{t('EliminateNumbering')}</span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-outline-primary btn-dim border-radius-0 col-4"
                          type="button"
                          onClick={() => handleAddRange()}
                          disabled={
                            !isValidRange ||
                            !selectedPartner ||
                            !shareRange.from ||
                            !shareRange.to ||
                            +shareRange.from > +shareRange.to
                          }
                        >
                          <em className="icon ni ni-plus" />
                          <span>{t('AddNumbering')}</span>
                        </button>
                      )}
                    </div>
                  </div>
                ))}
                {selectedPartner && (
                  <span className="mt-2">
                    {t('Available')}
                    {userShareRanges.map((userShareRange) => (
                      <>
                        <b className="badge badge-primary ml-1">
                          <NumberFormat
                            displayType="text"
                            value={userShareRange.shareFrom}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...numberFormat}
                          />
                          &nbsp;-&nbsp;
                          <NumberFormat
                            displayType="text"
                            value={userShareRange.shareTo}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...numberFormat}
                          />
                          &nbsp;
                        </b>
                      </>
                    ))}
                  </span>
                )}
              </div>

              <div className="form-group">
                <div className="custom-control custom-control-xs custom-checkbox mt-1">
                  <input
                    type="checkbox"
                    id="checkbox-toggle"
                    className="custom-control-input"
                    value={customizeSharePrice}
                    onChange={(event) =>
                      setCustomizeSharePrice(event.target.checked)
                    }
                  />
                  <label
                    className="custom-control-label mb-2"
                    htmlFor="checkbox-toggle"
                  >
                    {t('ReportDecreaseValue')}
                  </label>
                </div>
                {customizeSharePrice && (
                  <div className="form-control-wrap">
                    <div className="form-text-hint">
                      <span className="overline-title">Eur</span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      placeholder={t('PriceForParticipation')}
                      value={sharePrice}
                      onChange={(event) => setSharePrice(event.target.value)}
                      onWheel={(event) => event.target.blur()}
                      min="0"
                      step="0.000000001"
                      required
                    />
                  </div>
                )}
              </div>

              {historyOps.length ? (
                <div className="table-responsive">
                  <table className="table my-2">
                    <thead className="table-light">
                      <tr>
                        <th>{t('Partner')}</th>
                        <th>{t('Class')}</th>
                        <th className="text-center">{t('Price')}</th>
                        <th className="text-center">{t('From')}</th>
                        <th className="text-center">{t('To')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyOps?.map((operation) => (
                        <tr>
                          <td>{operation.partner}</td>
                          <td>{operation.shareClass}</td>
                          <td className="text-center">
                            <NumberFormat
                              displayType="text"
                              value={operation.sharePrice}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...currencyFormat}
                            />
                          </td>
                          <td className="text-center">
                            <NumberFormat
                              displayType="text"
                              value={operation.shareFrom}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormat}
                            />
                          </td>
                          <td className="text-center">
                            <NumberFormat
                              displayType="text"
                              value={operation.shareTo}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...numberFormat}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={
                  +participationsToRemove <= 0 ||
                  !isValidRange ||
                  selectedPartner === SELECCIONAR_SOCIO ||
                  holdingClass === SELECT_OPTION
                }
              >
                {missingParticipations - +participationsToRemove
                  ? t('Following')
                  : t('Save')}
              </button>
            </div>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ user, society }) {
  return { user, actualSociety: society?.actualSociety };
}

export default connect(mapStateToProps)(CapitalDecreaseModal);
