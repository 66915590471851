import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import types from 'redux/actions/actionTypes';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';

export const createPartnerCategory =
  (partnerCategory, showAlert = true) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/partnerCategories`,
        partnerCategory
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.CREATE_PARTNER_CATEGORY,
        partnerCategory: decodedData,
      });
      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.PARTNER_CATEGORY_CREATED));
      }
      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const getPartnerCategories = (societyId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/partnerCategories`,
      {
        params: { societyId },
      }
    );

    const decodedData = await decryptResponse(data);

    dispatch({
      type: types.GET_PARTNER_CATEGORIES,
      data: decodedData,
    });
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export const updatePartnerCategory =
  (partnerCategoryId, updatePartnerCategoryData) => async (dispatch) => {
    const body = {
      _id: partnerCategoryId,
      ...updatePartnerCategoryData,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/partnerCategories`,
        body
      );

      const decodedData = await decryptResponse(data);

      dispatch({
        type: types.UPDATE_PARTNER_CATEGORY,
        partnerCategory: decodedData,
      });
      dispatch(addAlert(alertBodyTypes.PARTNER_CATEGORY_UPDATED));
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deletePartnerCategory =
  (partnerCategoryId) => async (dispatch) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/partnerCategories/${partnerCategoryId}`
      );

      dispatch({
        type: types.DELETE_PARTNER_CATEGORY,
        partnerCategoryId,
      });
      dispatch(addAlert(alertBodyTypes.PARTNER_CATEGORY_DELETED));
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
