import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  name: string;
  cif: string;
  email: string;
  startDate: string;
  memberStatus: string;
  memberType: string;
  condition: string;
  handleSaveMember: any;
};

const AddCommitteeMemberModalFooter: FC<Props> = ({
  name,
  cif,
  email,
  startDate,
  memberStatus,
  memberType,
  condition,
  handleSaveMember,
}: Props) => {
  const { t } = useTranslate();

  const isSaveDisabled =
    !name ||
    !cif ||
    !email ||
    !startDate ||
    !memberStatus ||
    !memberType ||
    !condition;

  return (
    <>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={handleSaveMember}
          disabled={isSaveDisabled}
        >
          {t('SaveMember')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default AddCommitteeMemberModalFooter;
