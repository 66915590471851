import i18n from 'i18n/config';

export default {
  YES: {
    value: 'YES',
    text: i18n.t('Yes'),
    style: 'success',
    label: 'Yes',
  },
  NO: {
    value: 'NO',
    text: i18n.t('Nope'),
    style: 'danger',
    label: 'Nope',
  },
  ABS: {
    value: 'ABS',
    text: i18n.t('Abstention'),
    style: 'secondary',
    label: 'Abstention',
  },
};
