/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import { updateBeneficiary } from 'modules/beneficiaries/redux/beneficiaryActions';

import { getActualSociety } from 'utils/filters';
import isValidEmail from 'utils/isValidEmail';

import Swal from 'sweetalert2';

import CancelBeneficiaryAllPlansSection from './components/CancelBeneficiaryAllPlansSection';
import CancelBeneficiaryDateSection from './components/CancelBeneficiaryDateSection';
import CancelBeneficiaryEmailSection from './components/CancelBeneficiaryEmailSection';
import CancelBeneficiaryHeader from './components/CancelBeneficiaryHeader';
import CancelBeneficiaryModeSection from './components/CancelBeneficiaryModeSection';
import CancelBeneficiaryFooter from './components/CandelBeneficiaryFooter';

import getBeneficiaryUpdateData from './utils/getBeneficiaryUpdatedData';
import getSameCifBeneficiaries from './utils/getSameCifBeneficiaries';

import CustomLoading from '../../../_shared/components/CustomLoading';
import '../Modals.scss';

function CancelBeneficiaryModal({ beneficiaryId, beneficiary }) {
  const dispatch = useDispatch();
  const { t } = useTranslate();

  const plans = useSelector((state) => state?.plans);
  const { actualSociety } = useSelector((state) => state?.society);
  const user = useSelector((state) => state?.user);

  const [cancelationDate, setCancelationDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [cancelationMode, setCancelationMode] = useState();
  const [beneficiaryEmail, setBeneficiaryEmail] = useState(beneficiary?.email);
  const [allPlansCancelation, setAllPlansCancelation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeCancelationDate = (event) => {
    setCancelationDate(event.target.value);
  };

  const handleChangeCancelationMode = (event) => {
    setCancelationMode(event.target.value);
  };

  const handleChangeBeneficiaryEmail = (event) => {
    setBeneficiaryEmail(event.target.value);
  };

  const handleChangeAllPlansCancelation = (event) => {
    setAllPlansCancelation(event.target.checked);
  };

  const handleCloseModal = () => {
    dispatch(setModal(null));
  };

  async function updateAllBeneficiariesEmail() {
    await store.dispatch(
      updateBeneficiary({
        id: beneficiaryId,
        data: {
          email: beneficiaryEmail,
          userId: user['_id'],
          newEmails: beneficiary?.emails || [],
          societyId: actualSociety['_id'],
          cascade: true,
        },
        showAlert: false,
      })
    );
  }

  async function updateBeneficiaryData(id, beneficiaryData) {
    try {
      await store.dispatch(
        updateBeneficiary({
          id,
          data: {
            ...beneficiaryData,
            societyId: actualSociety['_id'],
            cascade: false,
          },
          showAlert: false,
        })
      );
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  }

  const handleCancelBeneficiary = async () => {
    try {
      setIsLoading(true);

      if (allPlansCancelation) {
        const sameCifBeneficiaries = getSameCifBeneficiaries(
          actualSociety,
          beneficiary
        );
        // eslint-disable-next-line no-restricted-syntax
        for (const currentBeneficiary of sameCifBeneficiaries) {
          if (!currentBeneficiary?.isCanceled) {
            const updateData = getBeneficiaryUpdateData({
              beneficiary: currentBeneficiary,
              plans,
              cancelationDate,
              cancelationMode,
            });
            updateData.email = beneficiaryEmail;
            // eslint-disable-next-line no-await-in-loop
            await updateBeneficiaryData(currentBeneficiary['_id'], updateData);
          }
        }
      } else {
        await updateAllBeneficiariesEmail();

        const updateData = getBeneficiaryUpdateData({
          beneficiary,
          plans,
          cancelationDate,
          cancelationMode,
        });
        await updateBeneficiaryData(beneficiaryId, updateData);
      }
      getActualSociety(user, actualSociety['_id']);

      Swal.fire({
        icon: 'success',
        title: `<h4 class="nk-modal-title">${t(
          'SuccessfullyCanceledBeneficiary'
        )}<br></h4>`,
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      dispatch(setModal(null));
    }
  };

  return (
    <>
      <Modal.Header>
        <CancelBeneficiaryHeader handleCloseModal={handleCloseModal} />
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter">
          <CancelBeneficiaryDateSection
            cancelationDate={cancelationDate}
            handleChangeCancelationDate={handleChangeCancelationDate}
          />

          <CancelBeneficiaryModeSection
            cancelationMode={cancelationMode}
            handleChangeCancelationMode={handleChangeCancelationMode}
          />

          <CancelBeneficiaryEmailSection
            beneficiaryEmail={beneficiaryEmail}
            handleChangeBeneficiaryEmail={handleChangeBeneficiaryEmail}
            isValidEmail={isValidEmail}
          />

          <CancelBeneficiaryAllPlansSection
            allPlansCancelation={allPlansCancelation}
            handleChangeAllPlansCancelation={handleChangeAllPlansCancelation}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <CancelBeneficiaryFooter
          cancelationMode={cancelationMode}
          cancelationDate={cancelationDate}
          beneficiaryEmail={beneficiaryEmail}
          handleCancelBeneficiary={handleCancelBeneficiary}
        />
      </Modal.Footer>
      {isLoading && <CustomLoading />}
    </>
  );
}

export default CancelBeneficiaryModal;
