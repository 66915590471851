export default {
  LINK: 'LINK',
  PDF_LINK: 'PDF_LINK',
  VIDEO_LINK: 'VIDEO_LINK',
  PDF_EMBEDDED: 'PDF_EMBEDDED',
  VIDEO_EMBEDDED: 'VIDEO_EMBEDDED',
};

export const folderContentIcons = {
  LINK: 'ni ni-link',
  PDF_LINK: 'ni ni-file-pdf',
  VIDEO_LINK: 'ni ni-video',
  PDF_EMBEDDED: 'ni ni-file-pdf',
  VIDEO_EMBEDDED: 'ni ni-video',
};

export const folderContentKeys = {
  LINK: 'url',
  PDF_LINK: 'documentUrl',
  VIDEO_LINK: 'videoUrl',
  PDF_EMBEDDED: 'documentId',
  VIDEO_EMBEDDED: 'videoId',
};
