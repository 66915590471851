import { Beneficiary, Society } from 'types';

export default function getSameCifBeneficiaries(
  actualSociety: Society,
  beneficiary: Beneficiary
) {
  return actualSociety?.beneficiaries.filter(
    (currentBeneficiary) => currentBeneficiary?.cif === beneficiary?.cif
  );
}
