import React from 'react';
import { format } from 'date-fns';
import ReactDOMServer from 'react-dom/server';
import { MemoryRouter } from 'react-router-dom';
import html2pdf from 'html3pdf';

import { formatDateToTime } from 'constants/formats';
import { formatNumberDecimals } from 'utils/formats';
import { Board, Society } from 'types';

type CertificateHtmlProps = {
  board: Board;
  secretaryName: string;
  print?: boolean;
  actualSociety: Society;
  rowsPrint: React.JSX.Element[];
  rows: React.JSX.Element[];
  attendanceSummaryByCapital: Record<string, number>;
  t: (key: string, options?: Record<string, string>) => string; // eslint-disable-line
};

const certificateHtml = ({
  board,
  secretaryName,
  print = false,
  actualSociety,
  rowsPrint,
  rows,
  attendanceSummaryByCapital,
  t,
}: CertificateHtmlProps) =>
  ReactDOMServer.renderToString(
    <MemoryRouter>
      <div>
        <h6
          style={{
            marginBottom: '20px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '24px',
          }}
        >
          {t('AttendanceCertificateTitle', {
            societyName: actualSociety?.name,
          })}
        </h6>
        <div style={{ marginBottom: '20px' }}>
          {t('AttendanceCertificateBodyPart1', {
            boardPlace:
              board?.place || `[${t('PlaceOfCelebration').toUpperCase()}]`,
          })}{' '}
          {format(new Date(board.date), 'dd/MM/yyyy')}{' '}
          {t('AttendanceCertificateBodyPart2', {
            boardTime: formatDateToTime(board.date),
            societyName: actualSociety?.name,
          })}
        </div>

        <div className="card card-bordered w-100 mb-5">
          <table className="table table-tranx" id="assistants-table">
            <thead>
              <tr
                style={{
                  fontSize: '11px',
                  fontWeight: '700',
                  textTransform: 'uppercase',
                  letterSpacing: '0.12em',
                  backgroundColor: '#f5f6fa',
                }}
              >
                <th className="">
                  <span>Nº</span>
                </th>
                <th className="" style={{ minWidth: '150px' }}>
                  <span>{t('Name')}</span>
                </th>
                <th className="">
                  <span>Nº {t('Share.')}</span>
                </th>
                <th className="">
                  <span>% ND</span>
                </th>
                <th className="">
                  <span>{t('RepresentativeBoard')}</span>
                </th>
                <th className="">
                  <span>{t('Attend')}</span>
                </th>
                {print && (
                  <th className="">
                    <span>{t('Signature')}</span>
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="fs-12px">{print ? rowsPrint : rows}</tbody>
          </table>
        </div>

        <div style={{ marginBottom: '20px' }}>
          {t('AttendanceCertificatePercentages')}
        </div>

        <div
          className="sp-plan-desc sp-plan-desc-mb border-0"
          style={{ marginBottom: '40px' }}
        >
          <ul className="row gx-1">
            <li className="col-3">
              <p>
                <span className="text-soft">{t('Attend')}:</span>{' '}
                {formatNumberDecimals(attendanceSummaryByCapital.assistants)}
                %&nbsp;
                <span className="d-inline text-soft fs-12px">
                  ({t('OfCapital')})
                </span>
              </p>
            </li>
            <li className="col-3">
              <p>
                <span className="text-soft">{t('AttendsRepresented')}:</span>{' '}
                {formatNumberDecimals(attendanceSummaryByCapital.represented)}
                %&nbsp;
                <span className="d-inline text-soft fs-12px">
                  ({t('OfCapital')})
                </span>
              </p>
            </li>

            <li className="col-3">
              <p>
                <span className="text-soft">{t('Missing')}:</span>{' '}
                {formatNumberDecimals(attendanceSummaryByCapital.absents)}
                %&nbsp;
                <span className="d-inline text-soft fs-12px">
                  ({t('OfCapital')})
                </span>
              </p>
            </li>
            <li className="col-3">
              <p>
                <span className="text-soft">Quorum:</span>{' '}
                {formatNumberDecimals(attendanceSummaryByCapital.quorum)}
                %&nbsp;
                <span className="d-inline text-soft fs-12px">
                  ({t('OfCapital')})
                </span>
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        {t('AttendanceCertificateFooter')}
      </div>
      <div style={{ marginBottom: '20px' }}>
        {secretaryName || `[${t('SecretaryName').toUpperCase()}]`}
      </div>
      <div style={{ marginBottom: '20px' }}>{t('Secretary')}.</div>
    </MemoryRouter>
  );

export const downloadCertificate = ({
  board,
  secretaryName,
  print = false,
  actualSociety,
  rowsPrint,
  rows,
  attendanceSummaryByCapital,
  t,
}: CertificateHtmlProps) => {
  const html = certificateHtml({
    board,
    secretaryName,
    print,
    actualSociety,
    rowsPrint,
    rows,
    attendanceSummaryByCapital,
    t,
  });

  const options = {
    filename: `${'Certificate'}.pdf`,
    margin: [10, 10, 10, 10], // top, right, bottom, left
    pagebreak: { mode: 'avoid-all' },
    html2canvas: { scale: 2 },
    jsPDF: {
      unit: 'mm',
      format: 'a4',
      orientation: 'portrait',
    },
  };
  html2pdf().from(html).set(options).save();
};
