export default [
  {
    value: 'CHAIRMAN',
    label: 'Chairman',
  },
  {
    value: 'COUNSELOR',
    label: 'Counselor',
  },
  {
    value: 'SECRETARY_NON_COUNSELOR',
    label: 'SecretaryNonCounselor',
  },
  {
    value: 'SECRETARY_NON_MEMBER',
    label: 'SecretaryNonMember',
  },
  {
    value: 'VICE_SECRETARY_NON_COUNSELOR',
    label: 'ViceSecretaryNonCounselor',
  },
  {
    value: 'VICE_SECRETARY_NON_MEMBER',
    label: 'ViceSecretaryNonMember',
  },
  {
    value: 'SECOND_VICE_SECRETARY_NON_COUNSELOR',
    label: 'SecondViceSecretaryNonCounselor',
  },
  {
    value: 'SECOND_VICE_SECRETARY_NON_MEMBER',
    label: 'SecondViceSecretaryNonMember',
  },
  {
    value: 'MEMBER',
    label: 'Member',
  },
];
