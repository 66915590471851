import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import Select from 'react-select';

import makeAnimated from 'react-select/animated';

import NumberFormat from 'react-number-format';
import { numberFormat } from 'constants/formats';
import { useGetLaboralCategoryOptions } from '../hook/useGetLaboralCategoryOptions';
import { useGetLaboralCategorySelectedOption } from '../hook/useGetLaboralCategorySelectedOption';

type Props = {
  laboralCategory: string;
  salary: number;
  currentPlanLaboralCategories: any[];
  // eslint-disable-next-line no-unused-vars
  handleChangeLaboralCategory: (event: any) => void;
  // eslint-disable-next-line no-unused-vars
  handleChangeSalary: (event: any) => void;
};

const AddBeneficiaryModalLaboralCategoryView: FC<Props> = ({
  laboralCategory,
  salary,
  currentPlanLaboralCategories,
  handleChangeLaboralCategory,
  handleChangeSalary,
}) => {
  const { t } = useTranslate();
  const animatedComponents = makeAnimated();

  const { laboralCategoryOptions } = useGetLaboralCategoryOptions({
    currentPlanLaboralCategories,
  }).data;

  const laboralCategoryOptionResult = useGetLaboralCategorySelectedOption({
    laboralCategory,
    handleChangeLaboralCategory,
  });

  const { selectedLaboralCategoryOption } = laboralCategoryOptionResult.data;
  const { handleChangeLaboralCategoryOption } =
    laboralCategoryOptionResult.actions;

  return (
    <>
      <div className="row">
        <div className="form-group col-6">
          <label className="form-label" htmlFor="laboral-category">
            {t('LaboralCategory')}
          </label>
          <div className="form-control-wrap ">
            <Select
              closeMenuOnSelect
              className="react-select react-select-lg"
              options={laboralCategoryOptions}
              value={selectedLaboralCategoryOption}
              components={animatedComponents}
              onChange={handleChangeLaboralCategoryOption}
              placeholder={t('SelectCategory')}
            />
          </div>
        </div>

        <div className="form-group col-6">
          <label className="form-label" htmlFor="user-salary">
            {t('AnnualSalary')}
          </label>
          <div className="form-control-wrap ">
            <div className="form-text-hint">
              <span className="overline-title">EUR</span>
            </div>
            <NumberFormat
              id="outlined-normal"
              className="form-control form-control-outlined form-control-lg"
              value={salary}
              onChange={handleChangeSalary}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...numberFormat}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBeneficiaryModalLaboralCategoryView;
