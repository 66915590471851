/* eslint-disable no-console */
function trackEvent(mixpanel, name, properties) {
  if (mixpanel?.config?.token) {
    mixpanel.track(name, {
      ...properties,
    });
  } else {
    console.error('[MIXPANEL_ERROR] => Token not found!');
  }
}

export default trackEvent;
