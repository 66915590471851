// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from 'dompurify';

const cleanText = (text) => {
  if (!text) return '';

  const trimmedText = text.trim();
  const sanitizedText = DOMPurify.sanitize(trimmedText);

  return sanitizedText;
};

export default cleanText;
