import Swal from 'sweetalert2';

import './Dialog.scss';

type DialogProps = {
  icon: string;
  title?: string;
  body?: string;
  footer?: string;
  buttonText: string;
  cancelButtonText?: string;
  checkBoxText?: string;
  radioOptions?: Record<string, any>;
  onRadioChecked?: (value: string) => void;
  onChecked?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
  onError?: () => void;
};

const getInputType = ({
  checkbox,
  radio,
}: {
  checkbox?: string;
  radio?: Record<string, any>;
}) => {
  if (checkbox) return 'checkbox';
  if (radio) return 'radio';
  return undefined;
};

const getInputClass = ({
  checkbox,
  radio,
}: {
  checkbox?: string;
  radio?: Record<string, any>;
}) => {
  if (checkbox) return 'dialog-checkbox-input';
  if (radio) return 'dialog-radio-input';
  return undefined;
};

const Dialog = ({
  icon,
  title,
  body,
  footer,
  buttonText,
  cancelButtonText,
  checkBoxText,
  radioOptions,
  onRadioChecked,
  onChecked,
  onConfirm,
  onCancel,
  onError,
}: DialogProps) =>
  Swal.fire({
    html: `<div class="modal-body modal-body-lg text-center p-0">
              <div class="nk-modal">
                <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ${icon} bg-primary-alt"></em>
                <h4 class="nk-modal-title" style="line-height: 1.5;">${
                  title || ''
                }</h4>
                <div class="nk-modal-text">
                  <p class="lead">
                    ${body || ''}
                  </p>
                  <p class="text-soft fs-14px">
                    ${footer || ''}
                  </p>
                </div>
              </div>
            </div>`,
    confirmButtonText: buttonText,
    confirmButtonColor: '#6576ff',
    cancelButtonText,
    cancelButtonColor: '#e85347',
    showCancelButton: !!cancelButtonText,
    showCloseButton: true,
    input: getInputType({ checkbox: checkBoxText, radio: radioOptions }),
    inputValue: 0,
    inputPlaceholder: checkBoxText,
    inputOptions: radioOptions,
    customClass: {
      htmlContainer: 'dialog-container',
      input: getInputClass({ checkbox: checkBoxText, radio: radioOptions }),
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        if (onRadioChecked && result.value) {
          onRadioChecked(result.value);
          return;
        }

        if (onChecked && result.value) {
          onChecked();
          return;
        }

        if (onConfirm) onConfirm();
      } catch (error) {
        if (onError) onError();
      }
    } else if (
      result.isDismissed &&
      result.dismiss === Swal.DismissReason.cancel &&
      onCancel
    ) {
      onCancel();
    }
  });

export default Dialog;
