/* eslint-disable no-console */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';

import { setMenu } from 'modules/_shared/redux/menuActions';
import { getHolderSocieties } from 'modules/society/redux/societyActions';

import { getActualSociety, isPartnerActiveByShares } from 'utils/filters';
import { countSocietyShares } from 'utils/getShareCount';

import CardLoader from 'modules/_shared/components/Cards/CardLoader';
import SocietyCard from 'modules/society/components/SocietyCard';

import './PortfolioCards.scss';

const portfolioFilters = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  EXIT: 'EXIT',
};

const portfolioFilterLabels = {
  [portfolioFilters.ALL]: 'PortfolioFilterAll',
  [portfolioFilters.ACTIVE]: 'PortfolioFilterActive',
  [portfolioFilters.EXIT]: 'PortfolioFilterExit',
};

const portfolioStatusColors = {
  [portfolioFilters.ALL]: 'bg-none',
  [portfolioFilters.ACTIVE]: 'badge-primary',
  [portfolioFilters.EXIT]: 'badge-success',
};

function PortfolioCards({ alternativeMenuOptions }) {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { user, actualSociety, isHolder } = useSelector((state) => ({
    user: state.user,
    isHolder: state.society?.role?.isHolder,
    actualSociety: state.society?.actualSociety,
  }));

  const [cards, setCards] = useState([]);
  const [societies, setSocieties] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [filterBy, setFilterBy] = useState(portfolioFilters.ALL);

  const getUserIdFromPartnersCif = (cif, society) => {
    if (!cif || !society) return '';

    const userId = society?.partners.find(
      (partner) => partner?.cif === cif
    )?.user;

    return userId || '';
  };

  const isPartnerActiveFromCif = (cif, society) => {
    if (!cif || !society) return '';
    const partner = society?.partners.find((partner) => partner?.cif === cif);
    if (partner) {
      return isPartnerActiveByShares(partner, society);
    }
    return false;
  };

  const getPartnerCreationDate = (cif, society) => {
    if (!cif || !society) return '';
    const partner = society?.partners.find((partner) => partner?.cif === cif);
    const creationDate = partner?.registerDate || '';
    return creationDate;
  };

  useEffect(() => {
    if (!isLoadingData) {
      setIsLoadingData(true);
    }
  }, [filterBy]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety && user && filterBy) {
      getHolderSocieties(
        actualSociety['_id'],
        user['_id'],
        filterBy,
        setSocieties
      ).then(() => {
        setIsLoadingData(false);
      });
    }
  }, [actualSociety, user, filterBy]);

  useEffect(() => {
    if (societies.length) {
      const updatedSocieties = societies[1]?.length
        ? societies[1].map((society) => ({
            ...society,
            holdingStatus: isPartnerActiveFromCif(actualSociety?.cif, society)
              ? portfolioFilters.ACTIVE
              : portfolioFilters.EXIT,
            holdingPartnerCreationDate: getPartnerCreationDate(
              actualSociety?.cif,
              society
            ),
          }))
        : [];
      const filteredSocieties =
        filterBy === portfolioFilters.ALL
          ? updatedSocieties
          : updatedSocieties.filter(
              (society) => society.holdingStatus === filterBy
            );

      setCards(
        filteredSocieties?.map((society) => (
          <SocietyCard
            data={{
              society: countSocietyShares(society),
            }}
            userId={getUserIdFromPartnersCif(actualSociety?.cif, society)}
            isAdmin={society.administrators.includes(user?.['_id'])}
            isHolder={isHolder}
            alternativeMenuOptions={alternativeMenuOptions}
            key={society['_id']}
            holdingStatus={portfolioFilterLabels[society.holdingStatus]}
            holdingStatusColor={portfolioStatusColors[society.holdingStatus]}
            holdingPartnerCreationDate={society.holdingPartnerCreationDate}
          />
        ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societies, filterBy, actualSociety]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
        </div>
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title"> </h3>
        </div>
        <div className="nk-block-head">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">{t('Portfolio')}</h3>
              <div className="nk-block-des text-soft">
                <p>
                  {societies?.length === 1
                    ? t('PortfolioNoSocieties', {
                        societyName: actualSociety?.name,
                      })
                    : t('PortfolioHaveSocieties', {
                        societyName: actualSociety?.name,
                        count: societies[1] ? societies[1]?.length : 0,
                      })}
                </p>
              </div>
            </div>

            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-group cursor-pointer">
                        <div className="form-control-wrap ">
                          <div className="form-control-select">
                            <div className="form-icon form-icon-left">
                              <em
                                className={`icon ni ${
                                  filterBy === portfolioFilters.ALL
                                    ? 'ni-filter'
                                    : 'ni-filter-fill'
                                }`}
                              />
                            </div>
                            <select
                              className="form-control pr-4  cursor-pointer"
                              id="filter-society"
                              name="filter-society"
                              value={filterBy}
                              onChange={(event) =>
                                setFilterBy(event.target.value)
                              }
                            >
                              <option
                                value={portfolioFilters.ALL}
                                className="cursor-pointer"
                              >
                                {t('PortfolioFilterAll')}
                              </option>
                              <option
                                value={portfolioFilters.ACTIVE}
                                className="cursor-pointer"
                              >
                                {t('PortfolioFilterActive')}
                              </option>
                              <option
                                value={portfolioFilters.EXIT}
                                className="cursor-pointer"
                              >
                                {t('PortfolioFilterExit')}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link to={`/lista-portafolio/${societyId}`}>
                        <button type="button" className="btn btn-primary">
                          <em className="icon ni ni-view-list-wd" />
                          <span>{t('View')}</span>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cuadro-participadas__main d-flex flex-wrap g-gs">
          {cards.length > 0 ? (
            <>{cards}</>
          ) : (
            <>
              <div className="w-100 pt-1">
                {isLoadingData && <CardLoader cards={6} />}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PortfolioCards;
