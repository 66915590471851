import { useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

const labels = {
  'Society Name': 'Name',
  'Society Address': 'Address',
  'Society Fiscal Identification Number': 'CIF',
  'Society Protocol Number': 'ProtocolNumber',
  'Society Social object': 'SocialObject',
  'Society Constitution Date': 'ConstitutionDate',
};

const inputTypes = {
  'Society Name': 'text',
  'Society Address': 'text',
  'Society Fiscal Identification Number': 'text',
  'Society Protocol Number': 'text',
  'Society Social object': 'text',
  'Society Constitution Date': 'date',
};

const ConstitutionAiModalSocietyTable = ({
  societyData,
  changeSocietyParam,
}) => {
  const { t } = useTranslate();

  const [societyExpandView, setSocietyExpandView] = useState(false);

  return (
    <section
      key="society-card"
      className="constitution-ai-section card card-bordered card-preview mb-2"
    >
      <table className="table table-tranx" id="society--table">
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={3}>
              <span>{t('SocietyData')}</span>
            </th>
          </tr>
        </thead>

        {societyExpandView ? (
          <tbody>
            <tr
              className="tb-tnx-item row-with-input"
              key="society-card"
              colSpan={3}
            >
              {Object.entries(societyData)
                .filter(([key]) => labels[key])
                .map(([key, value], index) => (
                  <div className="row row-internal">
                    <div className="col-4">
                      <span>
                        <b>{t(labels[key]) || key}</b>
                      </span>
                    </div>

                    <div className="col-7 px-0">
                      <input
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        id={`constitution-${key}-input`}
                        type={inputTypes[key]}
                        value={value}
                        className="form-control date-picker"
                        max="2100-01-01"
                        min="1000-01-01"
                        onChange={(event) =>
                          changeSocietyParam(key, event.target.value)
                        }
                      />
                    </div>

                    {index === 0 ? (
                      <div className="col-1 text-right">
                        <button
                          type="button"
                          className="badge btn-primary"
                          onClick={() => setSocietyExpandView(false)}
                        >
                          <em className="icon ni ni-chevron-up" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-1 text-right" />
                    )}
                  </div>
                ))}
            </tr>
          </tbody>
        ) : (
          <div className="row row-internal" key="society-name">
            <div className="col-10 col-sm-11">
              <span>
                <b>{societyData['Society Name']}</b>
              </span>
            </div>
            <div className="col-2 col-sm-1">
              <button
                type="button"
                className="badge btn-primary"
                onClick={() => setSocietyExpandView(true)}
              >
                <em className="icon ni ni-chevron-down" />
              </button>
            </div>
          </div>
        )}
      </table>
    </section>
  );
};

export default ConstitutionAiModalSocietyTable;
