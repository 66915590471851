/* eslint-disable react/jsx-indent */
import html2pdf from 'html3pdf';

const handleGeneratePdfFromHtml = async (html, filename) => {
  try {
    const options = {
      filename,
      html2canvas: { scale: 2 },
    };
    html2pdf().from(html).set(options).save();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log({ error });
  }
};

export default handleGeneratePdfFromHtml;
