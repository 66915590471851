import { getPartnerFromParticipant } from 'modules/boards/utils';
import { Society } from 'types';
import getPartnerNDPercent from 'utils/getPartnerNDPercent';

const FAVOR_VOTE = 'YES';
const AGAINST_VOTE = 'NO';
const ABSTENTION_VOTE = 'ABS';

function getGetTotalVotesNDPercent(
  order: any,
  participants: any[],
  actualSociety: Society
) {
  let result = 0;

  const orderId = order?._id;

  participants?.forEach((participant) => {
    const hasVoted = participant?.votes?.some(
      (vote: any) => vote.order === orderId
    );
    if (hasVoted) {
      const partner = getPartnerFromParticipant(participant, actualSociety);
      const ndPercentage = getPartnerNDPercent(partner, actualSociety);
      result += ndPercentage || 0;
    }
  });
  return result;
}

function getOrderPercentage(
  orderId: string,
  participants: any[],
  actualSociety: Society,
  voteType: string
) {
  let result = 0;

  participants?.forEach((participant) => {
    const orderVote = participant?.votes?.find(
      (vote: any) => vote.order === orderId
    );
    const partner = getPartnerFromParticipant(participant, actualSociety);
    const ndPercentage = getPartnerNDPercent(partner, actualSociety);
    if (orderVote?.vote === voteType) {
      result += ndPercentage || 0;
    }
  });
  return result;
}

export default function getOrderVotesData(
  order: any,
  participants: any[],
  actualSociety: Society
) {
  const orderId = order?._id;

  const favorPercentage = getOrderPercentage(
    orderId,
    participants,
    actualSociety,
    FAVOR_VOTE
  );

  const againstPercentage = getOrderPercentage(
    orderId,
    participants,
    actualSociety,
    AGAINST_VOTE
  );

  const abstentionPercentage = getOrderPercentage(
    orderId,
    participants,
    actualSociety,
    ABSTENTION_VOTE
  );

  const totalVotesNDPercent = getGetTotalVotesNDPercent(
    order,
    participants,
    actualSociety
  );

  return {
    id: orderId,
    favor: (favorPercentage * 100) / totalVotesNDPercent,
    against: (againstPercentage * 100) / totalVotesNDPercent,
    abstention: (abstentionPercentage * 100) / totalVotesNDPercent,
    totalVotesNDPercent,
  };
}
