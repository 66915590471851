/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { State } from 'redux/initialState';
import { getActualSociety } from 'utils/filters';

import EditButton from 'modules/_shared/components/Buttons/EditButton';
import { setMenu } from 'modules/_shared/redux/menuActions';
import { Section } from 'modules/dashboard/types/CustomDashboard';

import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import { setModal } from 'modules/_shared/redux/modalActions';
import DashboardSection from 'modules/dashboard/components/DashboardSection';
import dashboardSectionType from 'modules/dashboard/constants/dashboardSectionType';
import EditCustomDashboard from 'modules/dashboard/modals/EditCustomDashboard';
import { useGetDashboardSectionPermissions } from 'modules/dashboard/hooks/useGetDashboardSectionPermissions';

import menuTypes from 'constants/menuTypes';

import './CustomDashboard.scss';

const CustomDashboard = () => {
  const { t } = useTranslate();
  const { societyId } = useParams();

  const dispatch = useDispatch();

  const { isAdmin, isReadOnly } = useGetDashboardSectionPermissions().data;

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );

  const customLanding = actualSociety?.configuration?.customLanding;
  const hasWhiteBranding = actualSociety?.configuration?.hasWhiteBranding;

  const handleOpenDashboardEdit = () => {
    if (isReadOnly) return;

    dispatch(
      setModal(<EditCustomDashboard type={dashboardSectionType.HEADER} />)
    );
  };

  const handleAddSectionAbove = () => {
    if (isReadOnly) return;

    dispatch(
      setModal(
        <EditCustomDashboard
          type={dashboardSectionType.SECTION}
          sectionIndex={0}
        />
      )
    );
  };

  const handleAddSectionBelow = () => {
    if (isReadOnly) return;

    dispatch(
      setModal(
        <EditCustomDashboard
          type={dashboardSectionType.SECTION}
          sectionIndex={999}
        />
      )
    );
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  return (
    <>
      <div className="nk-content-body pld-wrapper">
        <div className="nk-content-wrap">
          {hasWhiteBranding && isAdmin && (
            <EditButton
              className="position-relative align-self-end bg-white"
              action={handleOpenDashboardEdit}
              disabled={isReadOnly}
            />
          )}

          <PageListHeader
            title={customLanding?.title}
            description={customLanding?.subtitle}
            actions={customLanding?.header?.map((item: any) => (
              <button
                key={item.key}
                type="button"
                className="btn btn-primary"
                onClick={() => {}}
                disabled={isReadOnly}
              >
                <span>{item.label}</span>
                <em className="icon ni ni-chevron-right" />
              </button>
            ))}
          />
        </div>

        {hasWhiteBranding && isAdmin && (
          <div className="col-12 text-center mt-4">
            <button
              type="button"
              className="btn btn-outline-primary px-5"
              onClick={handleAddSectionAbove}
              disabled={isReadOnly}
            >
              <em className="icon ni ni-plus mr-1" />

              {t('AddSectionAbove')}
            </button>
          </div>
        )}

        <div className="nk-content-wrap">
          {customLanding?.sections?.map((section: Section) => (
            <DashboardSection key={section?.index} {...section} />
          ))}
        </div>

        {hasWhiteBranding && isAdmin && (
          <div className="col-12 text-center mt-4">
            <button
              type="button"
              className="btn btn-outline-primary px-5"
              onClick={handleAddSectionBelow}
              disabled={isReadOnly}
            >
              <em className="icon ni ni-plus mr-1" />

              {t('AddSectionBelow')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomDashboard;
