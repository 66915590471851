import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

import envelopeSignerStatus from 'constants/envelopeSignerStatus';
import { EnvelopeSigner } from 'types';

import PartnerAvatarInfo from 'modules/_shared/components/PartnerAvatarInfo';

type Props = {
  signer: EnvelopeSigner;
};

const SignerRow: FC<Props> = ({ signer }) => {
  const { t } = useTranslate();

  // const { signerOptions } = useGetSignerOptions(t);

  const statusBadge =
    envelopeSignerStatus.badge[
      signer?.status as keyof typeof envelopeSignerStatus.badge
    ] || envelopeSignerStatus.badge.sent;

  const statusText =
    envelopeSignerStatus.text[
      signer?.status as keyof typeof envelopeSignerStatus.text
    ] || envelopeSignerStatus.text.sent;

  return (
    <tr>
      <td className="text-left" key={`${signer.id}-avatar-info`}>
        <PartnerAvatarInfo
          id={signer?.id}
          name={signer?.name}
          email={signer?.email}
          haveLink={false}
          haveStatus={false}
        />
      </td>
      <td className="text-center align-middle" key={`${signer.id}-sign-date`}>
        {signer?.signDate
          ? format(new Date(signer?.signDate), 'dd/MM/yyyy')
          : '-'}
      </td>
      <td className="text-center align-middle" key={`${signer.id}-sign-status`}>
        <span className={`badge badge-dot badge-${statusBadge}`}>
          {statusText ? t(statusText) : '-'}
        </span>
      </td>
      {/* <td className="text-rigth align-middle">
        <MenuDots
          menuOptions={signerOptions}
          // @ts-ignore
          id={signer.id}
          params={{
            signer,
          }}
          direction="left"
        />
      </td> */}
    </tr>
  );
};
export default SignerRow;
