import React from 'react';
import { format } from 'date-fns';
import { dateFormat } from 'constants/formats';
import i18n from 'i18n/config';

// TODO* move to constants file
const consolidationStatus = {
  COMPLETED: i18n.t('Completed'),
  PENDING: i18n.t('Pending'),
  CANCELED: i18n.t('Canceled'),
};

function BeneficiaryTransactionsRow({ period, index }) {
  return (
    <tr>
      <td className="fw-bold">{index}</td>
      <td>{format(new Date(period?.date), dateFormat)}</td>
      <td className="text-center">{period.vestedUnits}</td>
      <td className="text-center">{period.totalVestedUnits}</td>
      <td>
        {period.status === consolidationStatus.COMPLETED && (
          <span className="badge badge-dot text-success">
            {consolidationStatus.COMPLETED}
          </span>
        )}
        {period.status === consolidationStatus.PENDING && (
          <span className="badge badge-dot text-warning">
            {consolidationStatus.PENDING}
          </span>
        )}
        {period.status === consolidationStatus.CANCELED && (
          <span className="badge badge-dot text-danger">
            {consolidationStatus.CANCELED}
          </span>
        )}
      </td>
    </tr>
  );
}

export default BeneficiaryTransactionsRow;
