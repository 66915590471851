function downloadOptions(i18n) {
  return [
    {
      icon: 'ni-info',
      text: i18n.t('DraftScripture'),
      action: ({ generateScripture }) => generateScripture(),
      dropdown: true,
    },
  ];
}
export default downloadOptions;
