import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { CommitteeMember, Society } from 'types';

import DetailsProfileSection from 'modules/_shared/components/Sections/DetailsProfileSection';
import DetailsTableSection from 'modules/_shared/components/Sections/DetailsTableSection';
import { getSummaryDetailsList } from '../utils/getSummaryDetailsList';
import { getSummaryProfileData } from '../utils/getSummaryProfileData';

type CommitteeMemberDetailsSummaryTabProps = {
  member: CommitteeMember;
  society: Society;
};

const CommitteeMemberDetailsSummaryTab: FC<
  CommitteeMemberDetailsSummaryTabProps
> = ({ member, society }) => {
  const { t } = useTranslate();

  const { name, image, position, description } = getSummaryProfileData(
    member,
    society,
    t
  );

  const details = getSummaryDetailsList(member, t);

  return (
    <>
      <DetailsProfileSection
        name={name}
        image={image}
        position={position}
        description={description}
      />

      <div className="nk-divider divider my-0" />

      <DetailsTableSection title={t('Details')} data={details} />
    </>
  );
};

export default CommitteeMemberDetailsSummaryTab;
