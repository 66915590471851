/* eslint-disable react/jsx-one-expression-per-line */
import { FC, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from 'hooks/useTranslate';
import { format } from 'date-fns';

import { State } from 'redux/initialState';
import { dateFormat } from 'constants/formats';

import DetailsTab from 'modules/_shared/components/Tabs/DetailsTab';
import GoBackButton from 'modules/_shared/components/Buttons/GoBackButton';
import CustomLoading from 'modules/_shared/components/CustomLoading';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import PartnerRepresentantSociety from 'modules/partners/components/PartnerRepresentantSociety';
import PartnerRepresentantPersonal from 'modules/partners/components/PartnerRepresentantPersonal';

import './PartnerRepresentantDetail.scss';
import usePartnerRepresentantDetail from './usePartnerRepresentantDetail';
import { partnerRepresentantDetailTabs } from './partnerRepresentantDetailTabs';

type PartnerRepresentantDetailProps = {
  initialPage?: number;
};

const PartnerRepresentantDetail: FC<PartnerRepresentantDetailProps> = ({
  initialPage = 0,
}) => {
  const { t } = useTranslate();
  const user = useSelector((state: State) => state.user);

  const [page, setPage] = useState<number>(initialPage);

  const { data } = usePartnerRepresentantDetail({ user });
  const { actualPartnerRepresentant, actualSociety } = data;

  const pages = useMemo(() => {
    if (!actualSociety || !actualPartnerRepresentant) return [];
    return [
      <PartnerRepresentantSociety
        key="PartnerRepresentantSociety"
        society={actualSociety}
        partnerRepresentant={actualPartnerRepresentant}
      />,
      <PartnerRepresentantPersonal
        key="PartnerRepresentantPersonal"
        society={actualSociety}
        partnerRepresentant={actualPartnerRepresentant}
      />,
    ];
  }, [actualSociety, actualPartnerRepresentant]);

  const getPageDescription = () =>
    `${t('Since')}: ${
      actualPartnerRepresentant
        ? format(new Date(actualPartnerRepresentant.createdAt), dateFormat)
        : '-'
    }`;

  return actualPartnerRepresentant ? (
    <div className="nk-content-body">
      <PageListHeader
        title={actualSociety?.name}
        subTitle={t('PartnerRepresentant')}
        subTitleSuffix={actualPartnerRepresentant?.name}
        description={getPageDescription()}
        actions={[<GoBackButton />]}
      />

      <div className="nk-content-wrap">
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content">
                <ul
                  className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card justify-content-start"
                  id="partner-representant-tabs"
                >
                  {partnerRepresentantDetailTabs.map((tab, index) => (
                    <DetailsTab
                      key={tab.key}
                      tab={tab}
                      index={index}
                      currentPage={page}
                      onChangePage={setPage}
                    />
                  ))}
                </ul>

                {pages[page]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default PartnerRepresentantDetail;
