import { useTranslate } from 'hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { setModal } from 'modules/_shared/redux/modalActions';
import DocumentModal from 'modules/documents/pages/Documents/documentModal';
import { State } from 'redux/initialState';

import boardDocumentSections from 'constants/boardDocumentSections';
import {
  BoardDelegationRole,
  BoardParticipant,
  PartnerRepresentant,
} from 'types';
import downloadFile from 'utils/downloadFile';
import AssistentsModalRowSelectorWarning from './AssistentsModalRowSelectorWarning';
import {
  getRepresentativeSelectorOptions,
  getRepresentativeSelectorValue,
  isAssistant,
  isDelegate,
  isRepresentingOtherParticipant,
} from './utils';

const animatedComponents = makeAnimated();

type Option = {
  value: string | undefined;
  label: string;
  disabled: boolean;
};

type AssistentsModalRowProps = {
  index: number;
  boardId: string;
  societyId: string;
  participants: BoardParticipant[];
  representatives: BoardParticipant[];
  partnerRepresentants: PartnerRepresentant[];
  currentParticipant: any;
  handleChangeAssists: (memberId: string, checked: boolean) => void; // eslint-disable-line
  handleChangeRepresentation: (memberId: string, value: boolean) => void; // eslint-disable-line
  handlePartnerOptionChange: (
    memberId: string, // eslint-disable-line
    value: string, // eslint-disable-line
    role: BoardDelegationRole // eslint-disable-line
  ) => void;
};

export const AssistentsModalRow = ({
  index,
  boardId,
  societyId,
  participants,
  representatives,
  partnerRepresentants,
  currentParticipant,
  handleChangeAssists,
  handleChangeRepresentation,
  handlePartnerOptionChange,
}: AssistentsModalRowProps) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state.user);
  const board = useSelector((state: State) => state.boards).find(
    (board) => board['_id'] === boardId
  );

  const hasVotedRepresentative = participants.some(
    (participant) =>
      participant.votes.length &&
      participant.member === currentParticipant.representative
  );
  const hasDelegationDocument =
    (currentParticipant.delegationDocuments?.length || 0) > 0;

  const hasVotedAlready =
    currentParticipant.votes.length || hasVotedRepresentative;

  const hasAssistedAlready = currentParticipant.assists;

  const isAssistentCheckboxDisabled = () =>
    hasVotedAlready || isAssistant(currentParticipant, participants);

  const isRepresentedCheckboxDisabled = () =>
    hasVotedAlready ||
    isRepresentingOtherParticipant(currentParticipant.member, participants);

  const isRepresentativeSelectorDisabled = () =>
    hasVotedAlready ||
    (hasAssistedAlready &&
      currentParticipant.isRepresented &&
      currentParticipant.representative) ||
    isDelegate(currentParticipant.member, participants);

  // TODO: When block the Selector
  // - cuando se cierre la lista de asistentes ✅
  // - cuando el participante ha delegado y el representante ya ha votado
  // - cuando el representante ha asistido (assists)
  // - cuando el participante ha delegado

  const showRepresentativeSelector =
    currentParticipant.isRepresented &&
    !isRepresentingOtherParticipant(currentParticipant.member, participants);

  const representativeSelectorOptions = getRepresentativeSelectorOptions({
    currentParticipant,
    representatives,
    partnerRepresentants,
    board,
  });

  const handleGetRepresentativeSelectorValue = () =>
    getRepresentativeSelectorValue(
      currentParticipant,
      participants,
      partnerRepresentants
    );

  const handleDownloadDocuments = async () => {
    const documents = currentParticipant.delegationDocuments;

    if (documents?.length === 0) return;
    await Promise.all(
      documents.map((document: string) =>
        downloadFile({ documentId: document })
      )
    );
  };

  const handleUploadDelegationDocument = () => {
    dispatch(
      setModal(
        <DocumentModal
          userId={user['_id']}
          boardId={boardId}
          societyId={societyId}
          boardSection={boardDocumentSections.DELEGATION}
          defaultRepresented={currentParticipant.member}
          defaultRepresentative={currentParticipant.representative}
          document={undefined}
          stockPlan={undefined}
          partnerId={undefined}
          directorId={undefined}
          investorId={undefined}
          beneficiaryId={undefined}
          representativeId={undefined}
          updateLink={false}
          showLinkOptions={false}
          defaultCategory=""
          defaultSubcategory=""
          isLiteVersion
        />
      )
    );
  };

  return (
    <li
      className="nk-activity-item px-0 py-1 h-50px"
      key={currentParticipant.member}
    >
      <div className="col-9 col-sm-8 col-md-5 d-flex align-center">
        <div className="custom-control custom-control-xs custom-checkbox">
          <input
            type="checkbox"
            id={`assistent-${index}`}
            className="custom-control-input"
            checked={currentParticipant.assists}
            disabled={isAssistentCheckboxDisabled()}
            onChange={(event) =>
              handleChangeAssists(
                currentParticipant.member,
                event.target.checked
              )
            }
          />
          <label
            className="custom-control-label"
            htmlFor={`assistent-${index}`}
          />
        </div>
        <div className="nk-activity-media user-avatar user-avatar-sm bg-success">
          <img src={currentParticipant.image} alt="" />
        </div>
        <div className="pl-2" style={{ lineHeight: '16px' }}>
          <b className="d-block" style={{ lineHeight: '16px' }}>
            {currentParticipant?.name}
          </b>
          <span className="fs-12px" style={{ lineHeight: '16px' }}>
            {currentParticipant?.cif} | {currentParticipant?.percentage}%
          </span>
        </div>
      </div>

      <div className="d-none d-sm-inline-flex col-sm-4 col-md-2 custom-control custom-control-xs custom-checkbox">
        <input
          type="checkbox"
          id={`representated-${index}`}
          className="custom-control-input"
          value={currentParticipant.isRepresented}
          checked={currentParticipant.isRepresented}
          disabled={isRepresentedCheckboxDisabled()}
          onChange={(event) =>
            handleChangeRepresentation(
              currentParticipant.member,
              event.target.checked
            )
          }
        />
        <label
          className="custom-control-label"
          htmlFor={`representated-${index}`}
        >
          {t('Represented')}
        </label>
      </div>

      <div className="d-none d-sm-inline-flex col-sm-6 col-md-4">
        <div className="form-group w-100">
          <div className="form-control-wrap">
            {showRepresentativeSelector ? (
              <Select
                closeMenuOnSelect
                className="react-select"
                isDisabled={isRepresentativeSelectorDisabled()}
                value={handleGetRepresentativeSelectorValue()}
                options={representativeSelectorOptions}
                isOptionDisabled={(option: Option) => option.disabled}
                components={animatedComponents}
                onChange={(option: any) => {
                  handlePartnerOptionChange(
                    currentParticipant.member,
                    option?.value || '',
                    option?.role
                  );
                }}
              />
            ) : (
              <AssistentsModalRowSelectorWarning
                participant={currentParticipant}
                participants={participants}
              />
            )}
          </div>
        </div>
      </div>

      <div className="col-3 col-sm-2 col-md-1 pl-0">
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-icon btn-dim btn-light py-0"
            onClick={handleUploadDelegationDocument}
          >
            <em className="icon ni ni-upload my-1" />
          </button>

          {hasDelegationDocument ? (
            <button
              type="button"
              className="btn btn-icon py-0"
              onClick={handleDownloadDocuments}
            >
              <em className="icon ni ni-file-check" />
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-icon py-0"
              disabled
              data-toggle="tooltip"
              data-placement="top"
              title={t('HasNoDelegationDocuments')}
            >
              <em className="icon ni ni-file-check" />
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default AssistentsModalRow;
