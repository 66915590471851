import Record from 'typescript';

const filterUniqueElementsByProperty = (
  list: Record<string, any>[],
  property: any
): Record<string, any>[] => {
  const uniqueValues = new Set();
  const result = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const element of list) {
    const value = element[property];
    if (!uniqueValues.has(value)) {
      uniqueValues.add(value);
      result.push(element);
    }
  }
  return result;
};

export default filterUniqueElementsByProperty;
