import { useEffect, useState } from 'react';

import { StockPlan } from 'types';

export default function useGetPlan(planId: string, plans: StockPlan[]) {
  const [currentPlan, setCurrentPlan] = useState<StockPlan | undefined>();

  useEffect(() => {
    const plan = plans.find((p) => p._id === planId);
    setCurrentPlan(plan);
  }, [planId, plans]);

  return { currentPlan };
}
