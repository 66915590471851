/* eslint-disable react/react-in-jsx-scope */
import i18n from 'i18n/config';
import removePlan from 'utils/removePlan';
import watchPlan from 'utils/watchPlan';

export default [
  {
    icon: 'ni-info',
    text: i18n.t('WatchPlan'),
    action: watchPlan,
  },
  {
    icon: 'ni-trash',
    text: i18n.t('EliminatePlan'),
    action: removePlan,
  },
];
