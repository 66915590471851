import { useTranslate } from 'hooks/useTranslate';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { EMAIL_PATTERN } from 'constants/defaultConstants';

type Props = {
  representativeName: string;
  setRepresentativeName: (value: string) => void; // eslint-disable-line no-unused-vars
  representativeEmail: string;
  setRepresentativeEmail: (value: string) => void; // eslint-disable-line no-unused-vars
  representativeInitDate: string;
  setRepresentativeInitDate: (value: string) => void; // eslint-disable-line no-unused-vars
  representativeEndDate: string;
  setRepresentativeEndDate: (value: string) => void; // eslint-disable-line no-unused-vars
  typeOptionValue: any;
  representativeTypesOptions: any;
  handleChangeSelectedType: (value: any) => void; // eslint-disable-line no-unused-vars
  statusOptionValue: any;
  statusTypesOptions: any;
  handleChangeSelectedStatus: (value: any) => void; // eslint-disable-line no-unused-vars
  representativeNif: string;
  setRepresentativeNif: (value: string) => void; // eslint-disable-line no-unused-vars
  representativeNationality: string;
  setRepresentativeNationality: (value: string) => void; // eslint-disable-line no-unused-vars
  representativeAddress: string;
  setRepresentativeAddress: (value: string) => void; // eslint-disable-line no-unused-vars
};

const EditLegalPersonRepresentationForm = ({
  representativeName,
  setRepresentativeName,
  representativeEmail,
  setRepresentativeEmail,
  representativeInitDate,
  setRepresentativeInitDate,
  representativeEndDate,
  setRepresentativeEndDate,
  typeOptionValue,
  representativeTypesOptions,
  handleChangeSelectedType,
  statusOptionValue,
  statusTypesOptions,
  handleChangeSelectedStatus,
  representativeNif,
  setRepresentativeNif,
  representativeNationality,
  setRepresentativeNationality,
  representativeAddress,
  setRepresentativeAddress,
}: Props) => {
  const { t } = useTranslate();

  const animatedComponents = makeAnimated();

  return (
    <div className="row gy-4">
      <div className="col-md-6">
        <div className="form-group">
          <label
            className="form-label"
            htmlFor="societyRepresentative-socialDenom"
          >
            {t('NameAndSurname')} *
          </label>
          <input
            type="text"
            id="societyRepresentative-socialDenom"
            className="form-control form-control-lg"
            value={representativeName}
            onChange={(event) => setRepresentativeName(event.target.value)}
            placeholder={t('NameAndSurname')}
            required
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label className="form-label" htmlFor="societyRepresentative-email">
            {t('Email')} *
          </label>
          <input
            type="email"
            className="form-control form-control-lg"
            id="societyRepresentative-email"
            value={representativeEmail}
            onChange={(event) => setRepresentativeEmail(event.target.value)}
            placeholder={t('Email')}
            pattern={EMAIL_PATTERN}
            required
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label
            className="form-label"
            htmlFor="societyRepresentative-initDate"
          >
            {t('RepresentativeDate')}
          </label>
          <input
            type="date"
            className="form-control form-control-lg date-picker"
            id="societyRepresentative-initDate"
            value={representativeInitDate}
            onChange={(event) => setRepresentativeInitDate(event.target.value)}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div className="d-flex">
            <label
              className="form-label"
              htmlFor="societyRepresentative-endDate"
            >
              {t('RevocationDate')}
            </label>
            <span className="sub-text ml-1 fs-12px">
              {t('IndefiniteDefault')}
            </span>
          </div>
          <input
            type="date"
            className="form-control form-control-lg date-picker"
            id="societyRepresentative-endDate"
            value={representativeEndDate}
            onChange={(event) => setRepresentativeEndDate(event.target.value)}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label
            className="form-label"
            htmlFor="societyRepresentative-representativeType"
          >
            {t('TypeOfAuthority')} *
          </label>
          <div className="form-control-wrap ">
            <Select
              closeMenuOnSelect
              className="react-select react-select-lg"
              value={typeOptionValue}
              options={
                representativeTypesOptions?.length > 0 &&
                representativeTypesOptions
                  .sort(
                    (a: Record<string, string>, b: Record<string, string>) =>
                      a.name.localeCompare(b.name)
                  )
                  .map((type: any) => ({
                    value: type.value,
                    label: type.name,
                  }))
              }
              placeholder={t('Select')}
              components={animatedComponents}
              onChange={handleChangeSelectedType}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label className="form-label" htmlFor="societyRepresentative-status">
            {t('Status')}
          </label>
          <Select
            closeMenuOnSelect
            className="react-select react-select-lg"
            value={statusOptionValue}
            options={
              statusTypesOptions?.length > 0 &&
              statusTypesOptions
                .sort((a: Record<string, string>, b: Record<string, string>) =>
                  a.name.localeCompare(b.name)
                )
                .map((type: any) => ({
                  value: type.value,
                  label: type.name,
                }))
            }
            placeholder={t('Select')}
            components={animatedComponents}
            onChange={handleChangeSelectedStatus}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label className="form-label" htmlFor="societyRepresentative-nif">
            {t('Nif')}
          </label>
          <input
            type="text"
            id="societyDirectors-cif"
            className="form-control form-control-lg"
            value={representativeNif}
            onChange={(event) => setRepresentativeNif(event.target.value)}
            placeholder={t('Nif')}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label
            className="form-label"
            htmlFor="societyRepresentative-nationality"
          >
            {t('Nationality')}
          </label>
          <input
            type="text"
            id="societyRepresentative-nationality"
            className="form-control form-control-lg"
            value={representativeNationality}
            onChange={(event) =>
              setRepresentativeNationality(event.target.value)
            }
            placeholder={t('Nationality')}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <label className="form-label" htmlFor="societyRepresentative-address">
            {t('Address')}
          </label>
          <input
            type="text"
            className="form-control form-control-lg"
            id="societyRepresentative-address"
            value={representativeAddress}
            onChange={(event) => setRepresentativeAddress(event.target.value)}
            placeholder={t('Address')}
          />
        </div>
      </div>
    </div>
  );
};

export default EditLegalPersonRepresentationForm;
