import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Society, User } from 'types';

type AdministratorAccessBadgeProps = {
  administrator: User;
  society: Society;
};

const AdministratorAccessBadge: FC<AdministratorAccessBadgeProps> = ({
  administrator,
  society,
}) => {
  const { t } = useTranslate();

  const badge = {
    text: 'Full',
    color: 'danger',
  };

  const currentSocietyPermissions = administrator?.adminPermissions?.find(
    (permission) => permission.society === society?.['_id']
  );

  const hasAllPermissionsEnabled = currentSocietyPermissions
    ? Object.values(currentSocietyPermissions.permissions).every(
        (permission) => permission.enabled
      )
    : false;

  if (currentSocietyPermissions && !hasAllPermissionsEnabled) {
    badge.text = t('Limited');
    badge.color = 'warning';
  }

  return (
    <span className={`badge badge-dot badge-${badge.color}`}>
      {t(badge.text)}
    </span>
  );
};

export default AdministratorAccessBadge;
