import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';

import imageTypes from 'constants/imageTypes';

import { store } from 'redux/store';
import { decryptResponse } from 'utils/token';
import axios from '../../../interceptors/axios';

import { addAlert } from '../redux/alertActions';

export async function uploadImage(file) {
  try {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/image`,
      formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes['ERROR_UPLOADING_IMAGE']));
  }
}

export async function promiseUploadImage(file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();

    if (file) {
      formData.append('file', file);
      formData.append(
        'data',
        JSON.stringify({ type: imageTypes[file.type] || '' })
      );
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/image`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(async (response) => {
        const decodedData = await decryptResponse(response.data);
        resolve(decodedData);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
