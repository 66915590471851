import { DATE_FORMAT } from 'constants/defaultConstants';
import { format } from 'date-fns';

export const createTermFromDates = (
  startDate: string,
  endDate: string,
  translate: any
): string => {
  const initialDate = startDate
    ? format(new Date(startDate), DATE_FORMAT)
    : translate('UndefinedDate');

  const finalDate = endDate
    ? format(new Date(endDate), DATE_FORMAT)
    : translate('Indefinite');

  return `${initialDate} - ${finalDate}`;
};
