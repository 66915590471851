/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React from 'react';
import downloadFile from 'utils/downloadFile';

const DownloadButton = ({ documents }) => {
  const handleDownload = async (documents) => {
    let docs;

    if (!documents) {
      return;
    }

    if (Array.isArray(documents)) {
      docs = documents;
    } else if (typeof documents === 'string') {
      docs = [documents];
    } else {
      throw new Error('Invalid input - only arrays or strings are allowed');
    }

    try {
      for (const doc of docs) {
        await downloadFile({ documentId: doc });
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 sec between each download to avoid server throttling.
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <div
      className=" position-absolute"
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 2,
      }}
    >
      <span
        className={`btn btn-icon btn-trigger ${
          !documents || (!documents.length && 'disabled')
        }`}
        onClick={() => handleDownload(documents)}
      >
        <em className="icon ni ni-file-download" />
      </span>
    </div>
  );
};

export default DownloadButton;
