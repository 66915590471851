import actionTypes from 'redux/actions/actionTypes';
import { actionTypes as customTypes } from 'modules/_shared/redux/resetActions';
import initialState from 'redux/initialState';

export default function reducer(
  partnerBook = initialState.partnerBook,
  action
) {
  switch (action.type) {
    case customTypes.RESET_STATE_SOCIETY:
      return initialState.partnerBook;

    case actionTypes.SET_PARTNER_BOOK:
      return action.partnerBook;

    default:
      return partnerBook;
  }
}
