export default {
  ADD_BENEFICIARY_DRAFT: 'ADD_BENEFICIARY_DRAFT',
  ADD_BENEFICIARY_DRAFT_FILENAME: 'Añadir Beneficiario Borrador',

  ADD_BENEFICIARY: 'ADD_BENEFICIARY',
  ADD_BENEFICIARY_FILENAME: 'Añadir Beneficiario',

  ADD_BOARD: 'ADD_BOARD',
  ADD_BOARD_FILENAME: 'Junta',

  ADD_EMPLOYEES_PLAN: 'ADD_EMPLOYEES_PLAN',
  ADD_EMPLOYEES_PLAN_FILENAME: 'Plan de Empleados',

  ADD_HOLDING_CLASS: 'ADD_HOLDING_CLASS',
  ADD_HOLDING_CLASS_FILENAME: 'Añadir Clase',

  ADD_INVESTOR: 'ADD_INVESTOR',
  ADD_INVESTOR_FILENAME: 'Añadir Inversor',

  ADD_PARTNER_DRAFT: 'ADD_DRAFT',
  ADD_PARTNER_DRAFT_FILENAME: 'Añadir Inversor',

  ADD_PARTNER: 'ADD_PARTNER',
  ADD_PARTNER_FILENAME: 'Añadir Socio',

  ADD_PARTNERS: 'ADD_PARTNERS',
  ADD_PARTNERS_FILENAME: 'Añadir Socios',

  ADD_PARTNER_REPRESENTANT: 'ADD_PARTNER_REPRESENTANT',
  ADD_PARTNER_REPRESENTANT_FILENAME: 'Añadir Representante',

  ADD_TENDER_OFFER: 'ADD_TENDER_OFFER',
  ADD_TENDER_OFFER_FILENAME: 'Oferta de Compra',

  ADD_TENDER_OFFER_SHARES: 'ADD_TENDER_OFFER_SHARES',
  ADD_TENDER_OFFER_SHARES_FILENAME: 'Oferta de Compra de Acciones',

  CAPITAL_DECREASE: 'CAPITAL_DECREASE',
  CAPITAL_DECREASE_FILENAME: 'Reducción de Capital',

  CAPITAL_INCREASE: 'CAPITAL_INCREASE',
  CAPITAL_INCREASE_FILENAME: 'Aumento de Capital',

  CONSTITUTION: 'CONSTITUTION',
  CONSTITUTION_FILENAME: 'Constitución de Sociedad',

  DIVIDENDS_DISTRIBUTION: 'DIVIDENDS_DISTRIBUTION',
  DIVIDENDS_DISTRIBUTION_FILENAME: 'Reparto de Dividendos',

  DONATION: 'DONATION',
  DONATION_FILENAME: 'Donación',

  DRAFT: 'DRAFT',
  DRAFT_FILENAME: 'Añadir borrador',

  HERITAGE: 'HERITAGE',
  HERITAGE_FILENAME: 'Herencia',

  PHANTOM_SHARE: 'PHANTOM_SHARE',
  PHANTOM_SHARE_FILENAME: 'Plan de Phantom Shares',

  RECLASSIFICATION: 'RECLASSIFICATION',
  RECLASSIFICATION_FILENAME: 'Reclasificación',

  RENUMERATION: 'RENUMERATION',
  RENUMERATION_FILENAME: 'Renumeración',

  ROLLBACK: 'ROLLBACK',
  ROLLBACK_FILENAME: 'Deshacer Operación',

  SELL_PARTICIPATION: 'SELL_PARTICIPATION',
  SELL_PARTICIPATION_FILENAME: 'Compraventa',

  SPLIT: 'SPLIT',
  SPLIT_FILENAME: 'Split',

  STOCK_OPTION: 'STOCK_OPTION',
  STOCK_OPTION_FILENAME: 'Plan de Stock Options',

  WARRANT: 'WARRANT',
  WARRANT_FILENAME: 'Plan de Warrants',
};
