import { useTranslate } from 'hooks/useTranslate';
import { Link } from 'react-router-dom';

import { hideNotification } from 'modules/_shared/redux/notificationActions';
import { useDispatch } from 'react-redux';

import './Notification.scss';

/**
 * @param {string} id - id of the notification
 * @param {string} type - 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'gray' | 'light'
 * @param {string} title - first part of the message, bold
 * @param {string} message - main content of the message
 * @param {string} link - link to redirect to
 * @param {string} linkText - text to display for the link
 * @param {string} icon - icon to display (ex: 'ni-alert-circle')
 * @param {boolean} fill - fill the background
 * @param {boolean} dismissible - show the close button
 * @returns
 */
const Notification = ({
  id,
  type = 'primary',
  title,
  message,
  link,
  action,
  actionText,
  actionData,
  linkText,
  icon = 'ni-alert-circle',
  fill = true,
  dismissible = true,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleCloseNotification = () => {
    dispatch(hideNotification(id));
  };

  return (
    <div className="notification-wrapper nk-block-head-content">
      <div
        className={`alert ${fill ? 'alert-fill' : ''} ${
          icon ? 'alert-icon' : ''
        } alert-${type} ${
          dismissible ? 'alert-dismissible' : ''
        } text-center notification-box`}
        role="alert"
      >
        <em className={`icon ni ${icon} notification-icon`} />
        <strong>{t(title)}</strong>. &nbsp;{t(message)}. &nbsp;
        {link ? (
          <Link to={link} className="link notification-link">
            {t(linkText)}
          </Link>
        ) : (
          ''
        )}
        {action ? (
          <button
            type="button"
            className="link notification-link"
            onClick={() => {
              handleCloseNotification();
              action(actionData);
            }}
          >
            {t(`${actionText}`)}
          </button>
        ) : (
          ''
        )}
        <button
          type="button"
          className="close"
          data-bs-dismiss="alert"
          onClick={handleCloseNotification}
        />
      </div>
    </div>
  );
};

export default Notification;
