import { Board } from 'types';

export const getBoardOrdersString = (board: Board) => {
  if (!board) return null;

  const orders = board?.orders || [];

  let ordersString = '<ol style="list-style: decimal; margin-left: 30px;">';

  orders.forEach((order) => {
    ordersString += `<li><span>${order?.subject}</span></li>`;
  });

  ordersString += '</ol>';

  return ordersString;
};
