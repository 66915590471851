import { FC } from 'react';

import { Partner } from 'types';

import PartnersPreviewHeader from './PartnersPreviewHeader';
import PartnersPreviewBody from './PartnerPreviewBody';

type Props = {
  partners: (Partner & { isExisting?: boolean })[];
  onClose: () => void;
};

const PartnersPreview: FC<Props> = ({ partners, onClose }) => (
  <>
    <PartnersPreviewHeader partners={partners} onClose={onClose} />
    <PartnersPreviewBody partners={partners} />
  </>
);

export default PartnersPreview;
