/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import { useTranslate } from 'hooks/useTranslate';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ClearButton, Typeahead } from 'react-bootstrap-typeahead';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Tooltip } from 'react-tooltip';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';

import convertibleNoteStatus from 'constants/convertibleNoteStatus';
import {
  EMAIL_PATTERN,
  SELECCIONAR_SOCIO,
  SELECT_OPTION,
} from 'constants/defaultConstants';
import documentTypes from 'constants/documentTypes';
import draftTypes from 'constants/draftTypes';
import fileTypes from 'constants/fileTypes';
import { cleanValue, numberFormatDecimals } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import safeStatus from 'constants/safeStatus';

import { setModal } from 'modules/_shared/redux/modalActions';
import { addDocument } from 'modules/documents/redux/documentActions';
import {
  getFullAddress,
  getInvestorShares,
  getPartnerById,
} from 'utils/filters';

import proportionalityTypes from 'modules/drafts/pages/DraftCapitalIncrease/proportionalityTypes';

import '../Modals.scss';

const actionTypes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

function AddPartnerDraftModal({
  draftType,
  partnerData = {},
  addPartnerToDraft,
  updatePartnerToDraft,
  currentDraft,
  noteConditions,
  action = actionTypes.ADD,
  setTouched,
  hasSharesProportional = false,
}) {
  const dispatch = useDispatch();

  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const drafts = useSelector((state) => state?.drafts);
  const partners = useSelector(
    (state) => state?.society?.actualSociety?.partners
  );
  const beneficiaries = useSelector(
    (state) => state?.society?.actualSociety?.beneficiaries
  );
  const investors = useSelector(
    (state) => state?.society?.actualSociety?.investors
  );

  const { t } = useTranslate();

  const translationSuffix =
    draftType === draftTypes.DRAFT_CAPITAL_INCREASE ? '.FORCED' : '';

  // General partner data
  const [name, setName] = useState('');
  const [cif, setCif] = useState('');
  const [email, setEmail] = useState('');
  const [shares, setShares] = useState(0);
  const [NDPercent, setNDPercent] = useState(0);
  const [proportionalityPercent, setProportionalityPercent] = useState();
  const [isContribution, setIsContribution] = useState(true);
  const [contribution, setContribution] = useState();
  const [contributionNotRound, setContributionNotRound] = useState(false);
  const [holdingClass, setHoldingClass] = useState();
  const [initialShares, setInitialShares] = useState(0);
  const [futureShares, setFutureShares] = useState(0);
  const [selectedPartner, setSelectedPartner] = useState({});
  const [isPartnerAlready, setIsPartnerAlready] = useState(false);
  const [societyPartnerId, setSocietyPartnerId] = useState(null);
  const [actualDraft, setActualDraft] = useState();
  const [proportionality, setProportionality] = useState('');

  const [discountByValuation, setDiscountByValuation] = useState(true);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [contributionDate, setContributionDate] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [errorMessage, setErrorMessage] = useState('');
  const [validCif, setValidCif] = useState(true);
  const [options, setOptions] = useState([]);

  const [file, setFile] = useState();
  const user = useSelector((state) => state?.user);

  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: SELECCIONAR_SOCIO,
  });

  const [selectedClassOption, setSelectedClassOption] = useState({
    value: SELECT_OPTION,
    label: t('Select'),
  });

  const animatedComponents = makeAnimated();

  const handleOptionSelected = (selectedOption) => {
    setIsPartnerAlready(true);
    setSelectedPartner(selectedOption);
    setSelectedPartnerOption({
      value: selectedOption,
      label: `${selectedOption?.cif} | ${selectedOption?.name}`,
    });
  };

  const clearSelectedOption = () => {
    setCif('');
    setEmail('');
    setValidCif(true);
  };

  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedPartner(selectedOption.value);
    setSelectedPartnerOption(selectedOption);
  };

  const handleClassOptionChange = (selectedOption) => {
    setHoldingClass(selectedOption.value);
    setSelectedClassOption(selectedOption);
  };
  const handleSearch = (query) => {
    const options = [...partners, ...beneficiaries, ...investors];
    const filteredOption = options.filter((partner) =>
      partner.name.toLowerCase().includes(query.toLowerCase())
    );
    setOptions(filteredOption);
    setName(query);
  };
  const validateCif = (cif) => {
    if (cif !== '' && !isPartnerAlready) {
      const draftPartners =
        typeof currentDraft?.draftPartners === 'string'
          ? JSON.parse(currentDraft.draftPartners)
          : currentDraft.draftPartners;

      const existingPartner = actualSociety?.partners?.find(
        (partner) => partner?.cif === cif
      );
      if (existingPartner) {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">Ya existe un socio con ese CIF, ¿desea cargar sus datos personales?</h4>`,
          html: `<h5 class="text-primary">${existingPartner?.name} | ${existingPartner?.cif}</h5><br /><div class="caption-text">Debe aceptar para validar la operación.</div>`,
          confirmButtonText: t('Accept'),
          cancelButtonText: t('Cancel'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            setIsPartnerAlready(true);
            setSelectedPartner(JSON.stringify(existingPartner));
            setValidCif(true);
          } else {
            setFutureShares(0);
            setSocietyPartnerId(null);
            setValidCif(false);
            setCif(cif);
          }
        });
        return;
      }
      const existingInvestor = actualSociety?.investors?.find(
        (investor) => investor?.cif === cif
      );
      if (existingInvestor) {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">Ya existe un inversor con ese CIF, ¿desea cargar sus datos personales?</h4>`,
          html: `<h5 class="text-primary">${existingInvestor?.name} | ${existingInvestor?.cif}</h5><br /><div class="caption-text">Debe aceptar para validar la operación.</div>`,
          confirmButtonText: t('Accept'),
          cancelButtonText: t('Cancel'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            setValidCif(true);
            setCif(cif);
            setName(existingInvestor?.name);
            setEmail(existingInvestor?.email || null);
            setSocietyPartnerId(existingInvestor?.societyPartnerId || null);
            const investorShares = getInvestorShares(cif, actualSociety);
            if (
              currentDraft &&
              (currentDraft?.status === convertibleNoteStatus.var.NC_ACTIVE ||
                currentDraft?.status === safeStatus.var.SAFE_ACTIVE)
            ) {
              const currentInvestor = actualSociety?.investors?.find(
                (investor) =>
                  investor?.draft === currentDraft?.['_id'] &&
                  investor?.cif === cif
              );
              setFutureShares(
                investorShares - (currentInvestor?.sharesCount?.drafts || 0)
              );
            } else {
              setFutureShares(investorShares);
            }
          } else {
            setFutureShares(0);
            setSocietyPartnerId(null);
            setValidCif(false);
            setCif(cif);
          }
        });
        return;
      }
      const duplicatedPartner = draftPartners?.find(
        (partner) => partner?.cif === cif
      );
      if (duplicatedPartner) {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">Ya se ha añadido un inversor al borrador con ese CIF, ¿desea cargar sus datos personales?</h4>`,
          html: `<h5 class="text-primary">${duplicatedPartner?.name} | ${duplicatedPartner?.cif}</h5><br /><div class="caption-text">Debe aceptar para validar la operación.</div>`,
          confirmButtonText: t('Accept'),
          cancelButtonText: t('Cancel'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            setName(duplicatedPartner.name);
            setEmail(duplicatedPartner?.email);
            setFutureShares(0);
            setValidCif(true);
            setCif(cif);
          } else {
            setFutureShares(0);
            setSocietyPartnerId(null);
            setValidCif(false);
            setCif(cif);
          }
        });
      } else {
        setFutureShares(0);
        setSocietyPartnerId(null);
        setValidCif(true);
        setCif(cif);
      }
    } else {
      setFutureShares(0);
      setSocietyPartnerId(null);
      setValidCif(true);
      setCif(cif);
    }
  };

  const handleSavePartner = (event) => {
    event.preventDefault();
    const cleanedContribution = cleanValue(contribution);
    const newPartner = {
      name,
      email,
      cif,
      society: actualSociety['_id'],
      contribution: +cleanedContribution,
      realContribution: +cleanedContribution,
      discountPercent: +discountPercent,
      discountByValuation,
      shares: {
        initial: initialShares || 0,
        future: futureShares || 0,
      },
      isPartnerAlready,
      proportionality,
      proportionalityPercent,
      societyPartnerId,
      carriedShares: partnerData?.carriedShares || 0,
      isHidden: !!partnerData?.isHidden,
      holdingClass,
    };

    if (draftType === draftTypes.DRAFT_SAFE) {
      newPartner.contributionDate = contributionDate;
      if (currentDraft?.status === safeStatus.var.SAFE_ACTIVE) {
        newPartner.investorId = partnerData?.investorId || null;
      }
    }
    if (draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE) {
      newPartner.contributionDate = contributionDate;
      if (currentDraft?.status === convertibleNoteStatus.var.NC_ACTIVE) {
        newPartner.investorId = partnerData?.investorId || null;
      }
    }
    if (draftType === draftTypes.DRAFT_CAPITAL_INCREASE) {
      newPartner.contributionNotRound =
        isContribution && !proportionality ? contributionNotRound : false;
    }
    if (isPartnerAlready) {
      const parsedPartner = selectedPartner;
      newPartner.address = getFullAddress(parsedPartner?.address);
    }
    newPartner.generatedKey = partnerData?.generatedKey || uuidv4();
    if (action === actionTypes.ADD) {
      addPartnerToDraft(newPartner);
    } else {
      updatePartnerToDraft(newPartner);
    }

    if (file) {
      const newDocument = {
        file,
        date: new Date(),
        name: `${operationTypes.ADD_PARTNER_DRAFT_FILENAME}_${file.name}`,
        author: user['_id'],
        society: actualSociety['_id'],
        size: file.size,
        fileType: fileTypes[file.type],
        category: documentTypes.DOCUMENTS,
        description: t('DocumentLinkedToOperation'),
      };
      dispatch(addDocument(newDocument));
    }
    if (setTouched) setTouched(true);
    dispatch(setModal(null));
  };

  const handleChangeIsAlreadyPartner = (event) => {
    setIsPartnerAlready(event.target.value === 'true');
  };

  const handleChangePercent = (e) => {
    const numberValue = Number(e.target.value);

    if (numberValue >= 0 && numberValue <= 100) {
      setDiscountPercent(e.target.value);
    }
  };

  const loadPartnerFromSociety = (partnerId) => {
    const currentPartner = getPartnerById(actualSociety, partnerId);
    setSelectedPartner(currentPartner);
    setSelectedPartnerOption({
      value: currentPartner,
      label: `${currentPartner?.cif} | ${currentPartner?.name}`,
    });
    setName(currentPartner.name);
    setCif(currentPartner?.cif);
    setEmail(currentPartner?.email);
  };

  useEffect(() => {
    setHoldingClass(holdingClasses[0]?.['_id']);
    setSelectedClassOption({
      value: holdingClasses[0]?.['_id'],
      label: holdingClasses[0]?.name,
    });

    if (action === actionTypes.EDIT) {
      setIsPartnerAlready(!!partnerData?.societyPartnerId);

      if (!partnerData?.societyPartnerId) {
        setName(partnerData.name);
        setCif(partnerData?.cif);
        setEmail(partnerData?.email);
        setFutureShares(partnerData?.shares?.future || 0);
        setInitialShares(partnerData?.shares?.initial || 0);
      } else {
        loadPartnerFromSociety(partnerData?.societyPartnerId);
      }
      setHoldingClass(partnerData?.holdingClass);
      setSelectedClassOption({
        value: partnerData?.holdingClass,
        label: holdingClasses?.find(
          (holdingClass) => holdingClass['_id'] === partnerData?.holdingClass
        )?.name,
      });
      setContribution(
        partnerData?.realContribution || partnerData?.contribution
      );
      setProportionality(
        partnerData?.isSharesProportional
          ? proportionalityTypes.ND
          : partnerData?.proportionality || ''
      );
      setProportionalityPercent(partnerData?.proportionalityPercent || 0);
      setContributionNotRound(!!partnerData?.contributionNotRound);
      if (
        draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE ||
        draftType === draftTypes.DRAFT_SAFE
      ) {
        setContributionDate(
          partnerData?.contributionDate
            ? format(new Date(partnerData.contributionDate), 'yyyy-MM-dd')
            : ''
        );
      }
      setHoldingClass(partnerData?.holdingClass);
      setDiscountPercent(partnerData?.discountPercent);
      if (
        partnerData?.discountByValuation === true ||
        partnerData?.discountByValuation === false
      ) {
        setDiscountByValuation(partnerData?.discountByValuation);
      } else {
        setDiscountByValuation(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check if selectedPartner has "name" property to avoid error
    if (selectedPartner && selectedPartner?.name) {
      const currentPartner = selectedPartner;
      let draftShares = 0;
      if (
        actualDraft &&
        (actualDraft?.status === convertibleNoteStatus.var.NC_ACTIVE ||
          actualDraft?.status === safeStatus.var.SAFE_ACTIVE)
      ) {
        const draftPartners =
          typeof actualDraft?.draftPartners === 'string'
            ? JSON.parse(actualDraft.draftPartners)
            : actualDraft.draftPartners;
        const currentDraftPartner = draftPartners.find(
          (partner) => partner.societyPartnerId === currentPartner['_id']
        );
        if (currentDraftPartner) {
          draftShares = currentDraftPartner?.newShares || 0;
        }
      }

      const partnerActualShares = currentPartner?.sharesCount?.actual;
      const societyActualShares = actualSociety?.sharesCount?.actual;
      setName(currentPartner?.name);
      setCif(currentPartner?.cif);
      setValidCif(true);
      setEmail(currentPartner?.email);
      setShares(partnerActualShares);
      setNDPercent(
        +((partnerActualShares * 100) / societyActualShares).toFixed(2)
      );
      setInitialShares(partnerActualShares);
      setFutureShares(
        partnerActualShares +
          currentPartner?.sharesCount?.future +
          currentPartner?.sharesCount?.drafts -
          draftShares
      );
      setSocietyPartnerId(currentPartner?.['_id'] || null);

      if (
        currentPartner?.discountByValuation === true ||
        currentPartner?.discountByValuation === false
      ) {
        setDiscountByValuation(currentPartner?.discountByValuation);
      } else {
        setDiscountByValuation(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPartner]);

  useEffect(() => {
    if (action === actionTypes.EDIT) {
      setDiscountPercent(partnerData?.discountPercent || 0);
    } else {
      setDiscountPercent(cleanValue(noteConditions?.discountPercent) || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteConditions]);

  useEffect(() => {
    if (currentDraft && drafts?.length) {
      const existingDraft = drafts?.find(
        (draft) => draft['_id'] === currentDraft['_id']
      );
      setActualDraft(existingDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDraft]);

  useEffect(() => {
    if (actualSociety) {
      const options = actualSociety?.partners.map((partner) => ({
        value: partner,
        label: `${partner?.cif} | ${partner?.name}`,
      }));
      setPartnerOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (
      proportionality === proportionalityTypes.CUSTOM_ND ||
      proportionality === proportionalityTypes.CUSTOM_FD ||
      proportionality === proportionalityTypes.CUSTOM_POTENTIAL
    ) {
      setIsContribution(false);
    } else {
      setIsContribution(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proportionality]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {t(`AddNewInvestor${translationSuffix}`)}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={handleSavePartner}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('IsItCurrentlyAPartner?')}
            </label>

            <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
              <input
                type="radio"
                className="custom-control-input"
                name="already-partner"
                id="already-partner"
                value="true"
                checked={isPartnerAlready === true}
                onChange={handleChangeIsAlreadyPartner}
              />
              <label className="custom-control-label" htmlFor="already-partner">
                {t('Yes')}
              </label>
            </div>

            <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
              <input
                type="radio"
                className="custom-control-input"
                name="no-already-partner"
                id="no-already-partner"
                value="false"
                checked={isPartnerAlready === false}
                onChange={handleChangeIsAlreadyPartner}
              />
              <label
                className="custom-control-label"
                htmlFor="no-already-partner"
              >
                {t('Nope')}
              </label>
            </div>
          </div>

          <div className="form-group mt-4">
            <label className="form-label" htmlFor="partner-name">
              {t('NameAndSurname')}
            </label>

            {isPartnerAlready ? (
              <div className="form-control-wrap">
                <Select
                  closeMenuOnSelect
                  className="react-select"
                  value={selectedPartnerOption}
                  options={partnerOptions}
                  components={animatedComponents}
                  onChange={handlePartnerOptionChange}
                />
              </div>
            ) : (
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-user" />
                </div>
                <Typeahead
                  id="partner-name"
                  labelKey="name"
                  minLength={1}
                  onInputChange={handleSearch}
                  options={options}
                  placeholder={t('NameAndSurname')}
                  selected={name ? [name] : []}
                  onChange={(selectedOption) => {
                    if (selectedOption.length > 0) {
                      handleOptionSelected(selectedOption[0]);
                    } else {
                      clearSelectedOption();
                    }
                  }}
                >
                  {({ onClear, selected }) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                    </div>
                  )}
                </Typeahead>
              </div>
            )}
            <span className="sub-text mt-1 mb-3">
              {selectedPartner &&
                t('ItHasSharesParticipations', {
                  shares,
                  NDPercent,
                })}
            </span>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              {`${t('Email')} (${t(`OptionalDontNotify${translationSuffix}`)})`}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-mail" />
              </div>
              <input
                type="email"
                className="form-control"
                placeholder={t('EmailOfTheNewInvestor')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isPartnerAlready}
                pattern={EMAIL_PATTERN}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="partner-name">
              {`${t('FiscalIdentification')} (${t('Optional')})`}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-cc-alt2" />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder={t('EnterTheCifOrNif')}
                value={cif}
                onChange={(event) => validateCif(event.target.value)}
                disabled={isPartnerAlready}
              />
            </div>
          </div>

          {draftType === draftTypes.DRAFT_CAPITAL_INCREASE && (
            <div className="row">
              <div className="col-6 form-group">
                <label className="form-label" htmlFor="partner-contribution">
                  {isContribution
                    ? t(`AmountOfTheContributionOf${translationSuffix}`)
                    : t('PercentageThatPartnerWillKeep')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em
                      className={`icon ni ${
                        isContribution ? 'ni-sign-euro' : 'ni-percent'
                      }`}
                    />
                  </div>
                  <NumberFormat
                    name="partner-contribution"
                    className="form-control"
                    placeholder={isContribution ? 'Ex: 100.000' : 'Ex: 50%'}
                    value={
                      isContribution ? contribution : proportionalityPercent
                    }
                    disabled={
                      proportionality === proportionalityTypes.ND ||
                      proportionality === proportionalityTypes.FD ||
                      proportionality === proportionalityTypes.POTENTIAL
                    }
                    onChange={(event) =>
                      isContribution
                        ? setContribution(event.target.value)
                        : setProportionalityPercent(
                            cleanValue(event.target.value)
                          )
                    }
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormatDecimals}
                  />
                </div>
              </div>
              <div className="col-6 form-group align-center">
                <div className="custom-control custom-control-xs custom-checkbox">
                  <input
                    type="checkbox"
                    id="checkbox-not-round"
                    className="custom-control-input"
                    value={contributionNotRound}
                    checked={contributionNotRound}
                    disabled={
                      !isContribution ||
                      proportionality === proportionalityTypes.ND ||
                      proportionality === proportionalityTypes.FD ||
                      proportionality === proportionalityTypes.POTENTIAL
                    }
                    onChange={(event) =>
                      setContributionNotRound(event.target.checked)
                    }
                  />
                  <label
                    id="label-not-round"
                    className="custom-control-label"
                    htmlFor="checkbox-not-round"
                  >
                    {t('ContributionNotRound')}
                  </label>
                  <Tooltip
                    anchorId="label-not-round"
                    place="right"
                    style={{
                      inlineSize: '250px',
                      zIndex: 9999, // Adjust the value as needed
                      textAlign: 'left',
                      wordBreak: 'normal',
                    }}
                    html={`<span style="color: #ffffff">
                    Al marcar esta opción, no ajustaremos el importe indicado. El importe de la aportación
                    determinará el número de participaciones asignadas
                     y el valor nominal total de dichas participaciones.
                     La diferencia hasta el importe exacto de la aportación
                     se contabilizará como prima de emisión. Esto puede suponer
                     que, para este socio, la valoración de la sociedad en esta
                     ampliación, sea ligeramente distinta al resto de inversores
                     </span>`}
                  />
                </div>
              </div>
            </div>
          )}

          {(draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE ||
            draftType === draftTypes.DRAFT_SAFE) && (
            <div className="form-group">
              <label className="form-label" htmlFor="partner-contribution">
                {isContribution
                  ? t('AmountOfTheContributionOf')
                  : t('PercentageThatPartnerWillKeep')}
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em
                    className={`icon ni ${
                      isContribution ? 'ni-sign-euro' : 'ni-percent'
                    }`}
                  />
                </div>
                <NumberFormat
                  name="partner-contribution"
                  className="form-control"
                  placeholder={isContribution ? 'Ex: 100.000' : 'Ex: 50%'}
                  value={isContribution ? contribution : proportionalityPercent}
                  disabled={
                    proportionality === proportionalityTypes.ND ||
                    proportionality === proportionalityTypes.FD ||
                    proportionality === proportionalityTypes.POTENTIAL
                  }
                  onChange={(event) =>
                    isContribution
                      ? setContribution(event.target.value)
                      : setProportionalityPercent(
                          cleanValue(event.target.value)
                        )
                  }
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...numberFormatDecimals}
                />
              </div>
            </div>
          )}

          {hasSharesProportional && (
            <div className="row">
              <div className="col-6 form-group">
                <div className="col-12 custom-control custom-control-sm custom-radio">
                  <input
                    type="radio"
                    id="proportion-null"
                    name="proportion-checkbox"
                    className="custom-control-input"
                    value=""
                    checked={proportionality === ''}
                    onChange={(event) => setProportionality(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="proportion-null"
                  >
                    {t('IndicateContribution')}
                  </label>
                </div>
                <div className="col-12 custom-control custom-control-sm custom-radio">
                  <input
                    type="radio"
                    id="proportion-nd"
                    name="proportion-checkbox"
                    className="custom-control-input"
                    value={proportionalityTypes.ND}
                    checked={proportionality === proportionalityTypes.ND}
                    onChange={(event) => setProportionality(event.target.value)}
                    disabled={isPartnerAlready === 'false' || !isPartnerAlready}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="proportion-nd"
                  >
                    {t('ThePartnerGoesProportionalND', { NDPercent })}
                  </label>
                </div>
                <div className="col-12 custom-control custom-control-sm custom-radio mt-1">
                  <input
                    type="radio"
                    id="proportion-fd"
                    name="proportion-checkbox"
                    className="custom-control-input"
                    value={proportionalityTypes.FD}
                    checked={proportionality === proportionalityTypes.FD}
                    onChange={(event) => setProportionality(event.target.value)}
                    disabled={isPartnerAlready === 'false' || !isPartnerAlready}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="proportion-fd"
                  >
                    {t('ThePartnerGoesProportionalFD', { NDPercent })}
                  </label>
                </div>
                <div className="col-12 custom-control custom-control-sm custom-radio mt-1">
                  <input
                    type="radio"
                    id="proportion-potential"
                    name="proportion-checkbox"
                    className="custom-control-input"
                    value={proportionalityTypes.POTENTIAL}
                    checked={proportionality === proportionalityTypes.POTENTIAL}
                    onChange={(event) => setProportionality(event.target.value)}
                    disabled={isPartnerAlready === 'false' || !isPartnerAlready}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="proportion-potential"
                  >
                    {t('ThePartnerGoesProportionalPotential', { NDPercent })}
                  </label>
                </div>
              </div>
              <div className="col-6 form-group">
                <div className="col-12 custom-control custom-control-sm custom-radio">
                  <input
                    type="radio"
                    id="proportion-nd-custom"
                    name="proportion-checkbox"
                    className="custom-control-input"
                    value={proportionalityTypes.CUSTOM_ND}
                    checked={proportionality === proportionalityTypes.CUSTOM_ND}
                    onChange={(event) => setProportionality(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="proportion-nd-custom"
                  >
                    {t(`IndicatePercent${translationSuffix}`, { type: 'ND' })}
                  </label>
                </div>
                <div className="col-12 custom-control custom-control-sm custom-radio">
                  <input
                    type="radio"
                    id="proportion-fd-custom"
                    name="proportion-checkbox"
                    className="custom-control-input"
                    value={proportionalityTypes.CUSTOM_FD}
                    checked={proportionality === proportionalityTypes.CUSTOM_FD}
                    onChange={(event) => setProportionality(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="proportion-fd-custom"
                  >
                    {t(`IndicatePercent${translationSuffix}`, { type: 'FD' })}
                  </label>
                </div>

                <div className="col-12 custom-control custom-control-sm custom-radio">
                  <input
                    type="radio"
                    id="proportion-potential-custom"
                    name="proportion-checkbox"
                    className="custom-control-input"
                    value={proportionalityTypes.CUSTOM_POTENTIAL}
                    checked={
                      proportionality === proportionalityTypes.CUSTOM_POTENTIAL
                    }
                    onChange={(event) => setProportionality(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="proportion-potential-custom"
                  >
                    {t(`IndicatePercent${translationSuffix}`, {
                      type: 'Potential',
                    })}
                  </label>
                </div>
              </div>

              {errorMessage && (
                <span className="sub-text mt-1 text-danger">
                  {errorMessage}
                </span>
              )}
            </div>
          )}
          <div className="form-group">
            <label className="form-label" htmlFor="partner-discount-percent">
              {t('Discount')}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-percent" />
              </div>
              <input
                type="number"
                name="partner-discount-percent"
                className="form-control"
                placeholder="% de descuento"
                value={discountPercent}
                onChange={handleChangePercent}
                min="0"
                step="0.01"
                required
              />
            </div>

            {noteConditions?.discountPercent ? (
              <span className="sub-text mt-1 mb-3">
                {t('TheGeneralDiscountHasBeen', {
                  noteConditionsPercent: noteConditions.discountPercent,
                })}
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            {draftType === draftTypes.DRAFT_CAPITAL_INCREASE && (
              <div className="row mt-2">
                <div className="col-md-4">
                  <div className="custom-control custom-control-sm custom-radio">
                    <input
                      type="radio"
                      id="discount-valuation"
                      name="discount-type"
                      className="custom-control-input"
                      checked={discountByValuation === true}
                      onChange={() => setDiscountByValuation(true)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="discount-valuation"
                    >
                      Por valoración
                    </label>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="custom-control custom-control-sm custom-radio">
                    <input
                      type="radio"
                      id="discount-share"
                      name="discount-type"
                      className="custom-control-input"
                      checked={discountByValuation === false}
                      onChange={() => setDiscountByValuation(false)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="discount-share"
                    >
                      Por precio participación
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('ClassOfParticipations')}
            </label>
            <div className="form-control-wrap">
              <div>
                <Select
                  closeMenuOnSelect
                  className="react-select"
                  value={selectedClassOption}
                  options={holdingClasses?.map((holdingClass) => ({
                    value: holdingClass['_id'],
                    label: `${holdingClass.name}`,
                  }))}
                  components={animatedComponents}
                  onChange={handleClassOptionChange}
                  placeholder={SELECT_OPTION}
                />
              </div>
            </div>
          </div>

          {(draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE ||
            draftType === draftTypes.DRAFT_SAFE) && (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="partner-contribution">
                  {t('ContributionDate')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-calendar" />
                  </div>
                  <input
                    id="partner-contribution-date"
                    name="partner-contribution-date"
                    type="date"
                    className="form-control date-picker"
                    value={contributionDate}
                    onChange={(event) =>
                      setContributionDate(event.target.value)
                    }
                    required
                    min="2020-01-01"
                    max={noteConditions.dueDate}
                  />
                </div>
                <span className="sub-text mt-1 mb-3">
                  {noteConditions?.dueDate &&
                    `${t('TheDateHasToBeLessThanNot')}${format(
                      new Date(noteConditions.dueDate),
                      'dd/MM/yyy'
                    )}`}
                </span>
              </div>
              <div className="form-group">
                <label className="form-label">
                  {t('AddConvertibleParticipato')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      id="partnerContributionDoc"
                      type="file"
                      className="custom-file-input"
                      accept="application/pdf"
                      onChange={(event) => setFile(event.target.files[0])}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="partnerContributionDoc"
                    >
                      {file?.name || t('SelectDocument')}
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={
              !validCif ||
              !name ||
              (draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE &&
                !contributionDate) ||
              (draftType === draftTypes.DRAFT_SAFE && !contributionDate) ||
              (draftType === draftTypes.DRAFT_CAPITAL_INCREASE && !holdingClass)
            }
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">{t('WhenThisInformationChange')}</span>
      </Modal.Footer>
    </>
  );
}

export default AddPartnerDraftModal;
