import { FC, useState } from 'react';
import { useTranslate } from 'hooks/useTranslate';

import { Board, BoardParticipant, PartnerRepresentant } from 'types';
import { getPartnerRepresentantsFromParticipantsList } from 'modules/boards/utils';

type PartnerRepresentantsTableProps = {
  currentBoard: Board;
  partnerRepresentants: PartnerRepresentant[];
};

const PartnerRepresentantsTable: FC<PartnerRepresentantsTableProps> = ({
  currentBoard,
  partnerRepresentants,
}) => {
  const { t } = useTranslate();

  const [expandView, setExpandView] = useState<boolean>(false);
  const [expandDirection, setExpandDirection] = useState<string>('down');

  const handleViewChange = (): void => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  const representantIds = (getPartnerRepresentantsFromParticipantsList(
    currentBoard?.participants
  ) || []) as string[];

  const uniqueRepresentantIds = Array.from(new Set(representantIds));

  return (
    <div key="partner-representants-card" className="card card-bordered mb-4">
      <table
        className="table"
        id="partner-representants-table"
        style={{ marginBottom: '0px' }}
      >
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={2}>
              <span>
                {t('PartnerRepresentants')} ({uniqueRepresentantIds.length})
              </span>
            </th>
            <th className="col-2 text-right">
              <button
                type="button"
                className="badge btn-primary"
                onClick={() => handleViewChange()}
              >
                <em className={`icon ni ni-chevron-${expandDirection}`} />
              </button>
            </th>
          </tr>
        </thead>
      </table>
      <div
        key="partner-representants-card "
        style={{
          maxHeight: '200px',
          overflowY: 'auto',
        }}
      >
        <table className="table" id="partner-representants-body">
          {expandView && (
            <tbody>
              {partnerRepresentants
                ?.filter((partnerRepresentant) =>
                  representantIds.includes(partnerRepresentant._id)
                )
                ?.map(
                  (partnerRepresentant: PartnerRepresentant, index: number) => (
                    <tr className="tb-tnx-item">
                      <td
                        className="tb-tnx-info text-left py-0"
                        id={`partner-representant-${index}`}
                      >
                        <span className="title">
                          {partnerRepresentant.name}
                        </span>
                      </td>
                      <td
                        className="tb-tnx-info text-soft text-left py-0"
                        id={`partner-representant-${index}-email`}
                      >
                        <span className="title d-block">
                          {partnerRepresentant.email}
                        </span>
                      </td>
                      <td
                        className="tb-tnx-info text-soft text-left py-0"
                        id={`partner-representant-${index}-blank`}
                      >
                        <div className="nk-block float-right">
                          <button
                            type="button"
                            className="btn btn-icon btn-trigger px-1"
                            disabled
                          >
                            <em className="icon ni ni-edit" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default PartnerRepresentantsTable;
