import i18n from 'i18n/config';

export default {
  var: {
    GENERAL: 'GENERAL',
    SPECIAL: 'SPECIAL',
  },
  name: {
    GENERAL: i18n.t('General'),
    SPECIAL: i18n.t('Special'),
  },
};
