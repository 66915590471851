import { useTranslate } from 'hooks/useTranslate';
import { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Partner } from 'types';

import UploadDocument from 'modules/documents/components/UploadDocument';
import useGetLegalDocuments from '../hooks/useGetLegalDocuments';

type Props = {
  partner: Partner;
  constitutionFile: File | undefined;
  appointmentFile: File | undefined;
  ownershipFile: File | undefined;
  handleChangeConstitutionFile: any;
  handleChangeAppointmentFile: any;
  handleChangeOwnershipFile: any;
  handleChangePartnerData: any;
};

const LegalPersonScripturesView: FC<Props> = ({
  partner,
  constitutionFile,
  appointmentFile,
  ownershipFile,
  handleChangeConstitutionFile,
  handleChangeAppointmentFile,
  handleChangeOwnershipFile,
  handleChangePartnerData,
}) => {
  const { t } = useTranslate();

  const { constitutionDocument, appointmentDocument, ownershipDocument } =
    useGetLegalDocuments({
      currentPartner: partner,
    }).data;

  const [register, setRegister] = useState<string>(
    partner?.legalInfo?.register || ''
  );
  const [section, setSection] = useState<string>(
    partner?.legalInfo?.section || ''
  );
  const [sheet, setSheet] = useState<string>(partner?.legalInfo?.sheet || '');

  const handleChangeRegister = (event: any) => {
    const { value } = event.target;
    setRegister(event.target.value);
    handleChangePartnerData({
      ...partner,
      legalInfo: { ...partner?.legalInfo, register: value },
    });
  };

  const handleChangeSection = (event: any) => {
    const { value } = event.target;
    setSection(event.target.value);
    handleChangePartnerData({
      ...partner,
      legalInfo: { ...partner?.legalInfo, section: value },
    });
  };

  const handleChangeSheet = (event: any) => {
    const { value } = event.target;
    setSheet(event.target.value);
    handleChangePartnerData({
      ...partner,
      legalInfo: { ...partner?.legalInfo, sheet: value },
    });
  };

  return (
    <>
      <Modal.Body>
        <div className="row gy-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="register">
                {t('CommercialRegister')}
              </label>
              <input
                id="register"
                type="text"
                className="form-control form-control-lg"
                value={register}
                placeholder={t('EnterCommercialRegister')}
                onChange={handleChangeRegister}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="section">
                {t('PartnerSection')}
              </label>
              <input
                id="section"
                type="text"
                className="form-control form-control-lg"
                value={section}
                onChange={handleChangeSection}
                placeholder={t('EnterSection')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="sheet">
                {t('Sheet')}
              </label>
              <input
                id="sheet"
                type="text"
                className="form-control form-control-lg"
                value={sheet}
                onChange={handleChangeSheet}
                placeholder={t('EnterSheet')}
              />
            </div>
          </div>

          <div className="col-md-6">
            <UploadDocument
              label="DeedOfConstitution"
              file={constitutionFile}
              inputLabel={constitutionDocument?.name}
              setFile={handleChangeConstitutionFile}
              accept="application/pdf"
              idIndex={0}
            />
          </div>

          <div className="col-md-6">
            <UploadDocument
              label="DeedAppointmentRepresentative"
              file={appointmentFile}
              inputLabel={appointmentDocument?.name}
              setFile={handleChangeAppointmentFile}
              accept="application/pdf"
              idIndex={1}
            />
          </div>

          <div className="col-md-6">
            <UploadDocument
              label="DeedOfRealOwnership"
              file={ownershipFile}
              inputLabel={ownershipDocument?.name}
              setFile={handleChangeOwnershipFile}
              accept="application/pdf"
              idIndex={2}
            />
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default LegalPersonScripturesView;
