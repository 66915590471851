import { formatNumber } from 'utils/formats';

type ConstitutionAiModalPartnersSummaryRowProps = {
  index: number;
  partner: any;
  onChangePartnerExpandView: any;
};

const ConstitutionAiModalPartnersSummaryRow = ({
  index,
  partner,
  onChangePartnerExpandView,
}: ConstitutionAiModalPartnersSummaryRowProps) => {
  const sharesCount = formatNumber(partner?.['Shares count'] || 0);
  const sharesFrom = formatNumber(partner?.['Shares from'] || 0);
  const sharesTo = formatNumber(partner?.['Shares to'] || 0);

  return (
    <div className="row row-internal border-top border-bottom">
      <div className="col-5 col-sm-4">
        <span className="text-ellipsis">
          <b>{partner['Name']}</b>
        </span>
      </div>

      <div className="col-5 col-sm-7">
        <div className="row">
          <b className="col-12 col-sm-4 text-right">{sharesCount}</b>
          <b className="col-12 col-sm-8 text-right d-none d-sm-block">{`${sharesFrom} - ${sharesTo}`}</b>
        </div>
      </div>

      <div className="col-2 col-sm-1">
        <button
          type="button"
          className="badge btn-primary"
          onClick={() => onChangePartnerExpandView(index, true)}
        >
          <em className="icon ni ni-chevron-down" />
        </button>
      </div>
    </div>
  );
};

export default ConstitutionAiModalPartnersSummaryRow;
