import { useTranslate } from 'hooks/useTranslate';
import { useGetSocietySectionPermissions } from 'modules/society/hooks/useGetSocietySectionPermissions';

type Props = {
  onApplyChanges: any;
  onResetValues: any;
};

function SocietyConfigurationHeaderButtons(props: Props) {
  const { t } = useTranslate();
  const { isReadOnly } = useGetSocietySectionPermissions().data;

  const { onApplyChanges, onResetValues } = props;

  return (
    <div className="nk-block-head-content d-flex justify-content-between">
      <button
        type="button"
        className="btn btn-success"
        onClick={onApplyChanges}
        disabled={isReadOnly}
      >
        {t('ApplyChanges')}
      </button>

      <button
        type="button"
        className="btn btn-light"
        onClick={onResetValues}
        disabled={isReadOnly}
      >
        {t('ResetValues')}
      </button>
    </div>
  );
}

export default SocietyConfigurationHeaderButtons;
