import { getUsers } from 'modules/profile/redux/userActions';
import { store } from 'redux/store';
import { Partner, User } from 'types';
import categoryTypes from '../constants/categoryTypes';

const getUserCategoriesFromPartner = async (
  partner: Partner,
  usersList?: User[]
) => {
  const { otherUsers, readOnlyUsers } = partner;

  let currentUsersList = usersList || [];

  if (!currentUsersList?.length) {
    const usersId = [...otherUsers, ...readOnlyUsers];
    const queryParam = { usersList: usersId };
    const usersList: User[] = await store.dispatch(getUsers(queryParam));
    currentUsersList = usersList;
  }

  const categories: { email: string; category: string }[] = [];

  currentUsersList.forEach((user) => {
    const isPrimary = otherUsers.includes(user._id);
    const isSecondary = readOnlyUsers.includes(user._id);

    if (isPrimary) {
      categories.push({ email: user.email, category: categoryTypes.PRIMARY });
    }

    if (isSecondary) {
      categories.push({ email: user.email, category: categoryTypes.SECONDARY });
    }
  });

  return categories;
};

export default getUserCategoriesFromPartner;
