import { useTranslate } from 'hooks/useTranslate';
import Swal from 'sweetalert2';

import { Document } from 'types';
import { getDocumentIcon } from 'utils/documentIcons';
import { getFileDate } from 'utils/files';

import '../UploadDocumentsSection.scss';

type Props = {
  index: number;
  document: Document;
  documents: Document[];
  setDocuments: any;
};

function UploadDocumentsSectionRow(props: Props) {
  const { setDocuments, documents, document, index } = props;

  const itsRawFile: boolean =
    document?.['_id'] === undefined || document?.['_id'] === null;

  const { t } = useTranslate();

  function handleDeleteDocument(documentIndex: number) {
    const indexedFile = documents?.[documentIndex];

    if (indexedFile) {
      Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t(
          'AreYouSureAboutDelDocument'
        )}</h4>`,
        html: `<h5 class="text-primary">${indexedFile?.name}</h5><br />`,
        confirmButtonText: t('OkDelete'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (documents?.length === 1) {
            setDocuments([]);
          }

          const filteredDocuments = documents.filter(
            (file, index) => documentIndex !== index
          );
          setDocuments(filteredDocuments || []);
        }
      });
    }
  }

  return (
    <div
      className="nk-file-item nk-file documents-section-row"
      key={`document-${index}`}
    >
      <div className="nk-file-info">
        <div className="nk-file-title w-100">
          <div className="nk-file-icon">
            <span className="nk-file-icon-type">
              <img
                src={getDocumentIcon(document, itsRawFile)}
                alt="icon-file"
              />
            </span>
          </div>

          <div className="nk-file-name">
            <div
              className="nk-file-name-text d-flex flex-column"
              style={{ maxWidth: '300px' }}
            >
              <span className="title text-ellipsis" title={document?.name}>
                {document?.name}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-file-meta d-none d-xl-table-cell">
        <div className="tb-lead text-center">{getFileDate(document)}</div>
      </div>
      <div className="text-center">
        <button
          type="button"
          className="text-soft btn btn-icon"
          onClick={() => handleDeleteDocument(index)}
        >
          <em className="icon ni ni-trash" />
        </button>
      </div>
    </div>
  );
}

export default UploadDocumentsSectionRow;
