/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-nested-ternary */
import types from 'redux/actions/actionTypes';
import alertBodyTypes from 'modules/_shared/components/Alert/alertBodyTypes';
import { countSocietyShares } from 'utils/getShareCount';

import { decryptResponse } from 'utils/token';
import { dispatchError } from 'utils/dispatchError';

import { getSocietyDataByUser } from 'utils/societies';
import axios from '../../../interceptors/axios';

import { addAlert } from '../../_shared/redux/alertActions';
import { uploadImage } from '../../_shared/api/imageActions';

export const fastUpdateSociety = async (societyId, updateSocietyData) => {
  const body = {
    _id: societyId,
    ...updateSocietyData,
  };
  try {
    await axios.put(`${process.env.REACT_APP_API_URL}/societies`, body);
  } catch (error) {
    console.log(error);
  }
};

export function setSociety(result, user) {
  if (result) {
    const actualSociety = countSocietyShares(result?.society);
    const activePartners = actualSociety?.partners?.filter(
      (partner) => partner?.sharesCount?.actual > 0
    ).length;

    if (actualSociety) {
      actualSociety.activePartners = activePartners || 0;
      fastUpdateSociety(actualSociety['_id'], { activePartners });
    }
    const society = getSocietyDataByUser(actualSociety, user);

    return {
      type: types.SET_SOCIETY,
      society: { ...result, society },
    };
  }
  return {
    type: types.DELETE_SOCIETY,
  };
}

export function reSetSociety(society) {
  if (!society) return;

  const updatedSociety = countSocietyShares(society);
  const activePartners = updatedSociety?.partners.filter(
    (partner) => partner?.sharesCount?.actual > 0
  ).length;

  updatedSociety.activePartners = activePartners || 0;
  fastUpdateSociety(updatedSociety['_id'], { activePartners });

  return { type: types.UPDATE_SOCIETY, society: updatedSociety };
}

export const createSociety =
  (society, showAlert = true, dispatchForUser = true) =>
  async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL_V2}/societies`,
        society
      );

      const decodedData = await decryptResponse(data);

      if (dispatchForUser) {
        dispatch({ type: types.CREATE_SOCIETY, society: decodedData['_id'] });
      }

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_ADDED));
      }

      return decodedData;
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const setSocietyForUser = (societyId) => async (dispatch) => {
  try {
    if (societyId) {
      dispatch({ type: types.CREATE_SOCIETY, society: societyId });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateSociety =
  (societyId, updateSocietyData, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: societyId,
      ...updateSocietyData,
    };
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/societies`,
        body
      );

      const decodedData = await decryptResponse(data);

      if (decodedData) {
        dispatch(reSetSociety(decodedData));
      }

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const deleteSocietyById = (society) => async (dispatch) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/societies/${society}`
    );

    const decodedData = await decryptResponse(data);

    dispatch({ type: types.DELETE_SOCIETY, society: decodedData['_id'] });

    dispatch(addAlert(alertBodyTypes.SOCIETY_DELETED));

    return decodedData['_id'];
  } catch (error) {
    return dispatchError(error, dispatch);
  }
};

export async function getSocietyById(societyId) {
  const query = { params: { societyId } };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/societies/`,
      query
    );

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    return { name: '' };
  }
}

export async function getHolderSocieties(
  holderId,
  userId,
  filterBy = 'ALL',
  setter
) {
  const query = { holder: holderId, holderUserId: userId, filterBy };

  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/societies`,
      {
        params: query,
      }
    );

    const decodedData = await decryptResponse(data);
    if (setter) setter(decodedData);
    return decodedData;
  } catch (error) {
    console.log(error);
  }
}

export async function getUserSocieties(userId, role = 'admin', setter, source) {
  let query;

  const roleKeyMap = {
    admin: 'admin',
    partner: 'partner',
    beneficiary: 'beneficiary',
    investor: 'investor',
    holder: 'holder',
    partnerRepresentant: 'partnerRepresentant',
    guest: 'guest',
  };

  const key = roleKeyMap[role];

  const rolesApiV2 = [
    'admin',
    'beneficiary',
    'partner',
    'partnerRepresentant',
    'guest',
  ];

  const baseURL =
    rolesApiV2.includes(role) && source
      ? process.env.REACT_APP_API_URL_V2
      : process.env.REACT_APP_API_URL;

  if (key) {
    query = { [key]: userId, source };
  }

  try {
    const { data } = await axios.get(`${baseURL}/societies`, {
      params: query,
    });

    const decodedData = await decryptResponse(data);

    if (setter) setter(decodedData);
    return decodedData;
  } catch (error) {
    return { name: '' };
  }
}

export async function getUserTransactions(userId, setter) {
  const query = { transactions: true, admin: userId };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/societies`,
      {
        params: query,
      }
    );

    const decodedData = await decryptResponse(data);
    if (setter) setter(decodedData);
    return decodedData;
  } catch (error) {
    return { name: '' };
  }
}

export const getSocietiesRegistered = async (name) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/registered/${name}`
    );

    const decodedData = await decryptResponse(data);

    const societies = await decodedData?.organisations?.map((society) => ({
      label: society.nameAddress.name,
      nameAddress: society.nameAddress,
      activity: society.activity,
      registeredOffice: society.registeredOffice,
    }));

    return societies;
  } catch (error) {
    return [];
  }
};

export const updateSocietyTheme =
  (societyId, updateSocietyData, showAlert = false) =>
  async (dispatch) => {
    const body = {
      _id: societyId,
      customization: {
        updateData: {
          theme: {
            ...updateSocietyData,
          },
        },
      },
    };

    try {
      if (updateSocietyData.logo instanceof File) {
        const logoData = await uploadImage(updateSocietyData.logo);
        body.customization.updateData.theme.logo = logoData;
      }

      if (updateSocietyData.emailFrameLogo instanceof File) {
        const emailFrameLogoData = await uploadImage(
          updateSocietyData.emailFrameLogo
        );
        body.customization.updateData.theme.emailFrameLogo = emailFrameLogoData;
      }

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL_V2}/societies`,
        body
      );

      const decodedData = await decryptResponse(data);
      dispatch(reSetSociety(decodedData));

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };

export const updateSocietyConfiguration =
  (societyId, updateSocietyData, showAlert = false) =>
  async (dispatch) => {
    const body = {
      _id: societyId,
      configuration: {
        updateData: { ...updateSocietyData },
      },
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL_V2}/societies`,
        body
      );

      const decodedData = await decryptResponse(data);
      dispatch(reSetSociety(decodedData));

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
    } catch (error) {
      return dispatchError(error, dispatch);
    }
  };
