export default {
  AF: { en: 'Afghanistan', es: 'Afganistán', fr: 'Afghanistan' },
  AX: { en: 'Åland Islands', es: 'Islas Aland', fr: 'Iles Aland' },
  AL: { en: 'Albania', es: 'Albania', fr: 'Albanie' },
  DZ: { en: 'Algeria', es: 'Argelia', fr: 'Algérie' },
  AS: { en: 'American Samoa', es: 'Samoa Americana', fr: 'Samoa américaines' },
  AD: { en: 'Andorra', es: 'Andorra', fr: 'Andorre' },
  AO: { en: 'Angola', es: 'Angola', fr: 'Angola' },
  AI: { en: 'Anguilla', es: 'Anguila', fr: 'Anguilla' },
  AQ: { en: 'Antarctica', es: 'Antártida', fr: 'Antarctique' },
  AG: {
    en: 'Antigua and Barbuda',
    es: 'Antigua y Barbuda',
    fr: 'Antigua-et-Barbuda',
  },
  AR: { en: 'Argentina', es: 'Argentina', fr: 'Argentine' },
  AM: { en: 'Armenia', es: 'Armenia', fr: 'Arménie' },
  AW: { en: 'Aruba', es: 'Aruba', fr: 'Aruba' },
  AU: { en: 'Australia', es: 'Australia', fr: 'Australie' },
  AT: { en: 'Austria', es: 'Austria', fr: "L'Autriche" },
  AZ: { en: 'Azerbaijan', es: 'Azerbaiyán', fr: 'Azerbaïdjan' },
  BS: { en: 'Bahamas', es: 'bahamas', fr: 'Bahamas' },
  BH: { en: 'Bahrain', es: 'Bahréin', fr: 'Bahreïn' },
  BD: { en: 'Bangladesh', es: 'Bangladesh', fr: 'Bangladesh' },
  BB: { en: 'Barbados', es: 'Barbados', fr: 'Barbade' },
  BY: { en: 'Belarus', es: 'Bielorrusia', fr: 'Biélorussie' },
  BE: { en: 'Belgium', es: 'Bélgica', fr: 'Belgique' },
  BZ: { en: 'Belize', es: 'Belice', fr: 'Bélize' },
  BJ: { en: 'Benin', es: 'Benín', fr: 'Bénin' },
  BM: { en: 'Bermuda', es: 'islas Bermudas', fr: 'Bermudes' },
  BT: { en: 'Bhutan', es: 'Bután', fr: 'Bhoutan' },
  BO: {
    en: 'Bolivia, Plurinational State of',
    es: 'Bolivia, Estado Plurinacional de',
    fr: 'Bolivie, État plurinational de',
  },
  BQ: {
    en: 'Bonaire, Sint Eustatius and Saba',
    es: 'Bonaire, San Eustaquio y Saba',
    fr: 'Bonaire, Saint-Eustache et Saba',
  },
  BA: {
    en: 'Bosnia and Herzegovina',
    es: 'Bosnia y Herzegovina',
    fr: 'Bosnie Herzégovine',
  },
  BW: { en: 'Botswana', es: 'Botsuana', fr: 'Botswana' },
  BV: { en: 'Bouvet Island', es: 'Isla Bouvet', fr: 'Île Bouvet' },
  BR: { en: 'Brazil', es: 'Brasil', fr: 'Brésil' },
  IO: {
    en: 'British Indian Ocean Territory',
    es: 'Territorio Británico del Océano Índico',
    fr: "Territoire britannique de l'océan Indien",
  },
  BN: {
    en: 'Brunei Darussalam',
    es: 'Brunei Darussalam',
    fr: 'Brunei Darussalam',
  },
  BG: { en: 'Bulgaria', es: 'Bulgaria', fr: 'Bulgarie' },
  BF: { en: 'Burkina Faso', es: 'Burkina Faso', fr: 'Burkina Faso' },
  BI: { en: 'Burundi', es: 'Burundi', fr: 'Burundi' },
  KH: { en: 'Cambodia', es: 'Camboya', fr: 'Cambodge' },
  CM: { en: 'Cameroon', es: 'Camerún', fr: 'Cameroun' },
  CA: { en: 'Canada', es: 'Canadá', fr: 'Canada' },
  CV: { en: 'Cape Verde', es: 'Cabo Verde', fr: 'Cap-Vert' },
  KY: { en: 'Cayman Islands', es: 'Islas Caimán', fr: 'Îles Caïmans' },
  CF: {
    en: 'Central African Republic',
    es: 'República Centroafricana',
    fr: 'République centrafricaine',
  },
  TD: { en: 'Chad', es: 'Chad', fr: 'Tchad' },
  CL: { en: 'Chile', es: 'Chile', fr: 'Chili' },
  CN: { en: 'China', es: 'Porcelana', fr: 'Chine' },
  CX: { en: 'Christmas Island', es: 'Isla de Navidad', fr: "L'île de noël" },
  CC: {
    en: 'Cocos (Keeling) Islands',
    es: 'Islas Cocos (Keeling)',
    fr: 'Îles Cocos (Keeling)',
  },
  CO: { en: 'Colombia', es: 'Colombia', fr: 'Colombie' },
  KM: { en: 'Comoros', es: 'Comoras', fr: 'Comores' },
  CG: { en: 'Congo', es: 'Congo', fr: 'Congo' },
  CD: {
    en: 'Congo, the Democratic Republic of the',
    es: 'Congo, República Democrática del',
    fr: 'Congo, République démocratique du',
  },
  CK: { en: 'Cook Islands', es: 'Islas Cook', fr: 'les Îles Cook' },
  CR: { en: 'Costa Rica', es: 'Costa Rica', fr: 'Costa Rica' },
  CI: { en: "Côte d'Ivoire", es: 'Costa de Marfil', fr: "Côte d'Ivoire" },
  HR: { en: 'Croatia', es: 'Croacia', fr: 'Croatie' },
  CU: { en: 'Cuba', es: 'Cuba', fr: 'Cuba' },
  CW: { en: 'Curaçao', es: 'Curazao', fr: 'Curacao' },
  CY: { en: 'Cyprus', es: 'Chipre', fr: 'Chypre' },
  CZ: { en: 'Czech Republic', es: 'República Checa', fr: 'République tchèque' },
  DK: { en: 'Denmark', es: 'Dinamarca', fr: 'Danemark' },
  DJ: { en: 'Djibouti', es: 'Yibuti', fr: 'Djibouti' },
  DM: { en: 'Dominica', es: 'república dominicana', fr: 'Dominique' },
  DO: {
    en: 'Dominican Republic',
    es: 'República Dominicana',
    fr: 'République dominicaine',
  },
  EC: { en: 'Ecuador', es: 'Ecuador', fr: 'Équateur' },
  EG: { en: 'Egypt', es: 'Egipto', fr: 'Egypte' },
  SV: { en: 'El Salvador', es: 'El Salvador', fr: 'Le Salvador' },
  GQ: {
    en: 'Equatorial Guinea',
    es: 'Guinea Ecuatorial',
    fr: 'Guinée Équatoriale',
  },
  ER: { en: 'Eritrea', es: 'Eritrea', fr: 'Érythrée' },
  EE: { en: 'Estonia', es: 'Estonia', fr: 'Estonie' },
  ET: { en: 'Ethiopia', es: 'Etiopía', fr: 'Ethiopie' },
  FK: {
    en: 'Falkland Islands (Malvinas)',
    es: 'Islas Malvinas (Falkland)',
    fr: 'Îles Falkland (Malvinas)',
  },
  FO: { en: 'Faroe Islands', es: 'Islas Faroe', fr: 'Îles Féroé' },
  FJ: { en: 'Fiji', es: 'Fiyi', fr: 'Fidji' },
  FI: { en: 'Finland', es: 'Finlandia', fr: 'Finlande' },
  FR: { en: 'France', es: 'Francia', fr: 'France' },
  GF: { en: 'French Guiana', es: 'Guayana Francesa', fr: 'Guyane Française' },
  PF: {
    en: 'French Polynesia',
    es: 'Polinesia francés',
    fr: 'Polynésie française',
  },
  TF: {
    en: 'French Southern Territories',
    es: 'Territorios Franceses del Sur',
    fr: 'Terres australes françaises',
  },
  GA: { en: 'Gabon', es: 'Gabón', fr: 'Gabon' },
  GM: { en: 'Gambia', es: 'Gambia', fr: 'Gambie' },
  GE: { en: 'Georgia', es: 'Georgia', fr: 'Géorgie' },
  DE: { en: 'Germany', es: 'Alemania', fr: 'Allemagne' },
  GH: { en: 'Ghana', es: 'Ghana', fr: 'Ghana' },
  GI: { en: 'Gibraltar', es: 'Gibraltar', fr: 'Gibraltar' },
  GR: { en: 'Greece', es: 'Grecia', fr: 'Grèce' },
  GL: { en: 'Greenland', es: 'Groenlandia', fr: 'Groenland' },
  GD: { en: 'Grenada', es: 'Granada', fr: 'Grenade' },
  GP: { en: 'Guadeloupe', es: 'Guadalupe', fr: 'Guadeloupe' },
  GU: { en: 'Guam', es: 'Guam', fr: 'Guam' },
  GT: { en: 'Guatemala', es: 'Guatemala', fr: 'Guatemala' },
  GG: { en: 'Guernsey', es: 'Guernesey', fr: 'Guernesey' },
  GN: { en: 'Guinea', es: 'Guinea', fr: 'Guinée' },
  GW: { en: 'Guinea-Bissau', es: 'Guinea-Bisáu', fr: 'Guinée-Bissau' },
  GY: { en: 'Guyana', es: 'Guayana', fr: 'Guyane' },
  HT: { en: 'Haiti', es: 'Haití', fr: 'Haïti' },
  HM: {
    en: 'Heard Island and McDonald Islands',
    es: 'Islas Heard y McDonald',
    fr: 'Île Heard et îles McDonald',
  },
  VA: {
    en: 'Holy See (Vatican City State)',
    es: 'Santa Sede (Estado de la Ciudad del Vaticano)',
    fr: 'Saint-Siège (État de la Cité du Vatican)',
  },
  HN: { en: 'Honduras', es: 'Honduras', fr: 'Honduras' },
  HK: { en: 'Hong Kong', es: 'Hong Kong', fr: 'Hong Kong' },
  HU: { en: 'Hungary', es: 'Hungría', fr: 'Hongrie' },
  IS: { en: 'Iceland', es: 'Islandia', fr: 'Islande' },
  IN: { en: 'India', es: 'India', fr: 'Inde' },
  ID: { en: 'Indonesia', es: 'Indonesia', fr: 'Indonésie' },
  IR: {
    en: 'Iran, Islamic Republic of',
    es: 'Irán (República Islámica de',
    fr: 'Iran (République islamique d',
  },
  IQ: { en: 'Iraq', es: 'Irak', fr: 'Irak' },
  IE: { en: 'Ireland', es: 'Irlanda', fr: 'Irlande' },
  IM: { en: 'Isle of Man', es: 'Isla del hombre', fr: 'île de Man' },
  IL: { en: 'Israel', es: 'Israel', fr: 'Israël' },
  IT: { en: 'Italy', es: 'Italia', fr: 'Italie' },
  JM: { en: 'Jamaica', es: 'Jamaica', fr: 'Jamaïque' },
  JP: { en: 'Japan', es: 'Japón', fr: 'Japon' },
  JE: { en: 'Jersey', es: 'Jersey', fr: 'Jersey' },
  JO: { en: 'Jordan', es: 'Jordán', fr: 'Jordan' },
  KZ: { en: 'Kazakhstan', es: 'Kazajstán', fr: 'Kazakhstan' },
  KE: { en: 'Kenya', es: 'Kenia', fr: 'Kenya' },
  KI: { en: 'Kiribati', es: 'Kiribati', fr: 'Kiribati' },
  KP: {
    en: "Korea, Democratic People's Republic of",
    es: 'República de Corea, Popular Democrática de',
    fr: 'République populaire démocratique de Corée',
  },
  KR: {
    en: 'Korea, Republic of',
    es: 'Corea, república de',
    fr: 'Corée, République de',
  },
  KW: { en: 'Kuwait', es: 'Kuwait', fr: 'Koweit' },
  KG: { en: 'Kyrgyzstan', es: 'Kirguistán', fr: 'Kirghizistan' },
  LA: {
    en: "Lao People's Democratic Republic",
    es: 'República Democrática Popular Lao',
    fr: 'République démocratique populaire lao',
  },
  LV: { en: 'Latvia', es: 'Letonia', fr: 'Lettonie' },
  LB: { en: 'Lebanon', es: 'Líbano', fr: 'Liban' },
  LS: { en: 'Lesotho', es: 'Lesoto', fr: 'Lesotho' },
  LR: { en: 'Liberia', es: 'Liberia', fr: 'Libéria' },
  LY: { en: 'Libya', es: 'Libia', fr: 'Libye' },
  LI: { en: 'Liechtenstein', es: 'Liechtenstein', fr: 'Liechtenstein' },
  LT: { en: 'Lithuania', es: 'Lituania', fr: 'Lituanie' },
  LU: { en: 'Luxembourg', es: 'Luxemburgo', fr: 'Luxembourg' },
  MO: { en: 'Macao', es: 'macao', fr: 'Macao' },
  MK: {
    en: 'Macedonia, the Former Yugoslav Republic of',
    es: 'Macedonia, Antigua República Yugoslava de',
    fr: 'Macédoine, ancienne République yougoslave de',
  },
  MG: { en: 'Madagascar', es: 'Madagascar', fr: 'Madagascar' },
  MW: { en: 'Malawi', es: 'Malaui', fr: 'Malawi' },
  MY: { en: 'Malaysia', es: 'Malasia', fr: 'Malaisie' },
  MV: { en: 'Maldives', es: 'Maldivas', fr: 'Maldives' },
  ML: { en: 'Mali', es: 'Malí', fr: 'Mali' },
  MT: { en: 'Malta', es: 'Malta', fr: 'Malte' },
  MH: { en: 'Marshall Islands', es: 'Islas Marshall', fr: 'Iles Marshall' },
  MQ: { en: 'Martinique', es: 'Martinica', fr: 'Martinique' },
  MR: { en: 'Mauritania', es: 'Mauritania', fr: 'Mauritanie' },
  MU: { en: 'Mauritius', es: 'Mauricio', fr: 'Maurice' },
  YT: { en: 'Mayotte', es: 'Mayotte', fr: 'Mayotte' },
  MX: { en: 'Mexico', es: 'México', fr: 'Mexique' },
  FM: {
    en: 'Micronesia, Federated States of',
    es: 'Micronesia, Estados Federados de',
    fr: 'Micronésie, États fédérés de',
  },
  MD: {
    en: 'Moldova, Republic of',
    es: 'Moldavia, República de',
    fr: 'Moldavie, République de',
  },
  MC: { en: 'Monaco', es: 'Mónaco', fr: 'Monaco' },
  MN: { en: 'Mongolia', es: 'Mongolia', fr: 'Mongolie' },
  ME: { en: 'Montenegro', es: 'Montenegro', fr: 'Monténégro' },
  MS: { en: 'Montserrat', es: 'Montserrat', fr: 'Montserrat' },
  MA: { en: 'Morocco', es: 'Marruecos', fr: 'Maroc' },
  MZ: { en: 'Mozambique', es: 'Mozambique', fr: 'Mozambique' },
  MM: { en: 'Myanmar', es: 'Birmania', fr: 'Birmanie' },
  NA: { en: 'Namibia', es: 'Namibia', fr: 'Namibie' },
  NR: { en: 'Nauru', es: 'Nauru', fr: 'Nauru' },
  NP: { en: 'Nepal', es: 'Nepal', fr: 'Népal' },
  NL: { en: 'Netherlands', es: 'Países Bajos', fr: 'Pays-Bas' },
  NC: { en: 'New Caledonia', es: 'Nueva Caledonia', fr: 'Nouvelle Calédonie' },
  NZ: { en: 'New Zealand', es: 'Nueva Zelanda', fr: 'Nouvelle-Zélande' },
  NI: { en: 'Nicaragua', es: 'Nicaragua', fr: 'Nicaragua' },
  NE: { en: 'Niger', es: 'Níger', fr: 'Niger' },
  NG: { en: 'Nigeria', es: 'Nigeria', fr: 'Nigeria' },
  NU: { en: 'Niue', es: 'Niue', fr: 'Nioué' },
  NF: { en: 'Norfolk Island', es: 'Isla Norfolk', fr: "l'ile de Norfolk" },
  MP: {
    en: 'Northern Mariana Islands',
    es: 'Islas Marianas del Norte',
    fr: 'Îles Mariannes du Nord',
  },
  NO: { en: 'Norway', es: 'Noruega', fr: 'Norvège' },
  OM: { en: 'Oman', es: 'Omán', fr: 'Oman' },
  PK: { en: 'Pakistan', es: 'Pakistán', fr: 'Pakistan' },
  PW: { en: 'Palau', es: 'Palaos', fr: 'Palaos' },
  PS: {
    en: 'Palestine, State of',
    es: 'Palestina, Estado de',
    fr: 'Palestine, État de',
  },
  PA: { en: 'Panama', es: 'Panamá', fr: 'Panama' },
  PG: {
    en: 'Papua New Guinea',
    es: 'Papúa Nueva Guinea',
    fr: 'Papouasie Nouvelle Guinée',
  },
  PY: { en: 'Paraguay', es: 'Paraguay', fr: 'Paraguay' },
  PE: { en: 'Peru', es: 'Perú', fr: 'Pérou' },
  PH: { en: 'Philippines', es: 'Filipinas', fr: 'Philippines' },
  PN: { en: 'Pitcairn', es: 'Pitcairn', fr: 'Pitcairn' },
  PL: { en: 'Poland', es: 'Polonia', fr: 'Pologne' },
  PT: { en: 'Portugal', es: 'Portugal', fr: 'le Portugal' },
  PR: { en: 'Puerto Rico', es: 'Puerto Rico', fr: 'Porto Rico' },
  QA: { en: 'Qatar', es: 'Katar', fr: 'Qatar' },
  RE: { en: 'Réunion', es: 'Reunión', fr: 'Réunion' },
  RO: { en: 'Romania', es: 'Rumania', fr: 'Roumanie' },
  RU: {
    en: 'Russian Federation',
    es: 'Federación Rusa',
    fr: 'Fédération Russe',
  },
  RW: { en: 'Rwanda', es: 'Ruanda', fr: 'Rwanda' },
  BL: { en: 'Saint Barthélemy', es: 'San Bartolomé', fr: 'Saint Barthélemy' },
  SH: {
    en: 'Saint Helena, Ascension and Tristan da Cunha',
    es: 'Santa Elena, Ascensión y Tristán da Cunha',
    fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
  },
  KN: {
    en: 'Saint Kitts and Nevis',
    es: 'San Cristóbal y Nieves',
    fr: 'Saint-Christophe-et-Niévès',
  },
  LC: { en: 'Saint Lucia', es: 'Santa Lucía', fr: 'Sainte-Lucie' },
  MF: {
    en: 'Saint Martin (French part)',
    es: 'San Martín (parte francesa)',
    fr: 'Saint-Martin (partie française)',
  },
  PM: {
    en: 'Saint Pierre and Miquelon',
    es: 'San Pedro y Miquelón',
    fr: 'Saint-Pierre-et-Miquelon',
  },
  VC: {
    en: 'Saint Vincent and the Grenadines',
    es: 'San Vicente y las Granadinas',
    fr: 'Saint-Vincent-et-les-Grenadines',
  },
  WS: { en: 'Samoa', es: 'Samoa', fr: 'Samoa' },
  SM: { en: 'San Marino', es: 'San Marino', fr: 'Saint Marin' },
  ST: {
    en: 'Sao Tome and Principe',
    es: 'Santo Tomé y Príncipe',
    fr: 'Sao Tomé et Principe',
  },
  SA: { en: 'Saudi Arabia', es: 'Arabia Saudita', fr: 'Arabie Saoudite' },
  SN: { en: 'Senegal', es: 'Senegal', fr: 'Sénégal' },
  RS: { en: 'Serbia', es: 'Serbia', fr: 'Serbie' },
  SC: { en: 'Seychelles', es: 'Seychelles', fr: 'les Seychelles' },
  SL: { en: 'Sierra Leone', es: 'Sierra Leona', fr: 'Sierra Leone' },
  SG: { en: 'Singapore', es: 'Singapur', fr: 'Singapour' },
  SX: {
    en: 'Sint Maarten (Dutch part)',
    es: 'Sint Maarten (parte holandesa)',
    fr: 'Sint Maarten (partie néerlandaise)',
  },
  SK: { en: 'Slovakia', es: 'Eslovaquia', fr: 'Slovaquie' },
  SI: { en: 'Slovenia', es: 'Eslovenia', fr: 'Slovénie' },
  SB: { en: 'Solomon Islands', es: 'Islas Salomón', fr: 'Les îles Salomon' },
  SO: { en: 'Somalia', es: 'Somalia', fr: 'Somalie' },
  ZA: { en: 'South Africa', es: 'Sudáfrica', fr: 'Afrique du Sud' },
  GS: {
    en: 'South Georgia and the South Sandwich Islands',
    es: 'Georgia del sur y las islas Sandwich del sur',
    fr: 'Géorgie du Sud et îles Sandwich du Sud',
  },
  SS: { en: 'South Sudan', es: 'Sudán del Sur', fr: 'Soudan du sud' },
  ES: { en: 'Spain', es: 'España', fr: 'Espagne' },
  LK: { en: 'Sri Lanka', es: 'Sri Lanka', fr: 'Sri Lanka' },
  SD: { en: 'Sudan', es: 'Sudán', fr: 'Soudan' },
  SR: { en: 'Suriname', es: 'Surinam', fr: 'Surinam' },
  SJ: {
    en: 'Svalbard and Jan Mayen',
    es: 'Svalbard y Jan Mayen',
    fr: 'Svalbard et Jan Mayen',
  },
  SZ: { en: 'Swaziland', es: 'Suazilandia', fr: 'Swaziland' },
  SE: { en: 'Sweden', es: 'Suecia', fr: 'Suède' },
  CH: { en: 'Switzerland', es: 'Suiza', fr: 'Suisse' },
  SY: {
    en: 'Syrian Arab Republic',
    es: 'República Árabe Siria',
    fr: 'République arabe syrienne',
  },
  TW: {
    en: 'Taiwan, Province of China',
    es: 'Taiwan, provincia de China',
    fr: 'Taiwan, Province de Chine',
  },
  TJ: { en: 'Tajikistan', es: 'Tayikistán', fr: 'Tadjikistan' },
  TZ: {
    en: 'Tanzania, United Republic of',
    es: 'Tanzania, República Unida de',
    fr: 'Tanzanie, République-Unie de',
  },
  TH: { en: 'Thailand', es: 'Tailandia', fr: 'Thaïlande' },
  TL: { en: 'Timor-Leste', es: 'Timor Oriental', fr: 'Timor-Leste' },
  TG: { en: 'Togo', es: 'Ir', fr: 'Aller' },
  TK: { en: 'Tokelau', es: 'Tokelau', fr: 'Tokélaou' },
  TO: { en: 'Tonga', es: 'Tonga', fr: 'Tonga' },
  TT: {
    en: 'Trinidad and Tobago',
    es: 'Trinidad y Tobago',
    fr: 'Trinité-et-Tobago',
  },
  TN: { en: 'Tunisia', es: 'Túnez', fr: 'Tunisie' },
  TR: { en: 'Turkey', es: 'Pavo', fr: 'Turquie' },
  TM: { en: 'Turkmenistan', es: 'Turkmenistán', fr: 'Turkménistan' },
  TC: {
    en: 'Turks and Caicos Islands',
    es: 'Islas Turcas y Caicos',
    fr: 'îles Turques-et-Caïques',
  },
  TV: { en: 'Tuvalu', es: 'Tuvalu', fr: 'Tuvalu' },
  UG: { en: 'Uganda', es: 'Uganda', fr: 'Ouganda' },
  UA: { en: 'Ukraine', es: 'Ucrania', fr: 'Ukraine' },
  AE: {
    en: 'United Arab Emirates',
    es: 'Emiratos Árabes Unidos',
    fr: 'Emirats Arabes Unis',
  },
  GB: { en: 'United Kingdom', es: 'Reino Unido', fr: 'Royaume-Uni' },
  US: { en: 'United States', es: 'Estados Unidos', fr: 'États-Unis' },
  UM: {
    en: 'United States Minor Outlying Islands',
    es: 'Islas menores alejadas de los Estados Unidos',
    fr: 'Îles mineures éloignées des États-Unis',
  },
  UY: { en: 'Uruguay', es: 'Uruguay', fr: 'Uruguay' },
  UZ: { en: 'Uzbekistan', es: 'Uzbekistán', fr: 'Ouzbékistan' },
  VU: { en: 'Vanuatu', es: 'Vanuatu', fr: 'Vanuatu' },
  VE: {
    en: 'Venezuela, Bolivarian Republic of',
    es: 'Venezuela, República Bolivariana de',
    fr: 'Venezuela, République bolivarienne du',
  },
  VN: { en: 'Viet Nam', es: 'Vietnam', fr: 'Viet Nam' },
  VG: {
    en: 'Virgin Islands, British',
    es: 'Islas Vírgenes, Británicas',
    fr: 'Îles Vierges britanniques',
  },
  VI: {
    en: 'Virgin Islands, U.S.',
    es: 'Islas Vírgenes, EE.UU.',
    fr: 'Îles Vierges, États-Unis',
  },
  WF: {
    en: 'Wallis and Futuna',
    es: 'Wallis y Futuna',
    fr: 'Wallis et Futuna',
  },
  EH: {
    en: 'Western Sahara',
    es: 'Sahara Occidental',
    fr: 'Sahara occidental',
  },
  YE: { en: 'Yemen', es: 'Yemen', fr: 'Yémen' },
  ZM: { en: 'Zambia', es: 'Zambia', fr: 'Zambie' },
  ZW: { en: 'Zimbabwe', es: 'Zimbabue', fr: 'Zimbabwe' },
};
