import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import { Partner } from 'types';
import PartnersRepresentedRow from '../PartnersRepresentedRow';

type PartnersRepresentedTableProps = {
  title?: string;
  partners?: Partner[];
};

const PartnersRepresentedTable: FC<PartnersRepresentedTableProps> = ({
  title,
  partners,
}) => {
  const { t } = useTranslate();

  return (
    <>
      {title && (
        <h6 className="overline-title-alt mb-2">
          {t('ListOfRepresentedPartners')}
        </h6>
      )}

      <div className="row card card-bordered card-preview">
        <table className="table table-striped">
          <thead>
            <tr key="transactions-table">
              <th scope="col">{t('Nr')}</th>
              <th scope="col">{t('Name')}</th>
              <th scope="col">{t('Cif')}</th>
              <th scope="col">{t('Sindication')}</th>
              <th scope="col">{t('Doc.')}</th>
            </tr>
          </thead>
          <tbody>
            {partners?.map((partner, index) => (
              <PartnersRepresentedRow
                key={partner._id}
                index={index + 1}
                partner={partner}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PartnersRepresentedTable;
