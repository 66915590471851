import { useTranslate } from 'hooks/useTranslate';
import { useSelector } from 'react-redux';

import { State } from 'redux/initialState';
import { BoardDelegationRole, BoardParticipant } from 'types';

import AssistentsModalRow from './AssistentsModalRow';
import AssistentsModalPartnerRepresentantsRow from './AssistentsModalPartnerRepresentantsRow';
import { usePartnerRepresentantsParticipants } from './hooks/usePartnerRepresentantsParticipants';

type AssistentsModalTableProps = {
  boardId: string;
  societyId: string;
  participants: BoardParticipant[];
  representatives: BoardParticipant[];
  handleChangeAssists: (memberId: string, checked: boolean) => void; // eslint-disable-line
  handleChangeRepresentation: (memberId: string, value: boolean) => void; // eslint-disable-line
  handlePartnerOptionChange: (
    memberId: string, // eslint-disable-line
    value: string, // eslint-disable-line
    role: BoardDelegationRole // eslint-disable-line
  ) => void;
};

const AssistentsModalTableHeader = ({ title }: { title: string }) => (
  <div className="nk-tb-item nk-tb-head tb-tnx-head fs-11px ">
    <div className="nk-tb-col tb-col-xl border-0">{title}</div>
  </div>
);

const getRowClassName = (index: number, items: unknown[]) => {
  if (index === 0) {
    return 'nk-activity border-top border-bottom';
  }

  return index + 1 > items?.length - 1
    ? 'nk-activity'
    : 'nk-activity border-bottom';
};

const AssistentsModalTable = ({
  boardId,
  societyId,
  participants,
  representatives,
  handleChangeAssists,
  handleChangeRepresentation,
  handlePartnerOptionChange,
}: AssistentsModalTableProps) => {
  const { t } = useTranslate();
  const partnerRepresentants = useSelector(
    (state: State) => state.partnerRepresentants
  );

  const { data: partnerRepresentantsParticipants } =
    usePartnerRepresentantsParticipants(participants);

  return (
    <div className="form-group">
      <label className="form-label" htmlFor="fw-first-name">
        {t('SelectTheMembersWhoHaveAttended')}:
      </label>

      <div className="form-control-wrap">
        {partnerRepresentantsParticipants?.length > 0 && (
          <div className="card card-bordered card-full">
            <AssistentsModalTableHeader title={t('PartnerRepresentants')} />

            {partnerRepresentantsParticipants
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((partnerRepresentant, index) => (
                <>
                  <ul
                    key={partnerRepresentant?._id}
                    className={getRowClassName(
                      index,
                      partnerRepresentantsParticipants
                    )}
                  >
                    <AssistentsModalPartnerRepresentantsRow
                      index={index}
                      boardId={boardId}
                      societyId={societyId}
                      currentPartnerRepresentant={partnerRepresentant}
                      participants={participants}
                      representatives={representatives}
                      partnerRepresentants={partnerRepresentants}
                      handleChangeAssists={handleChangeAssists}
                      handleChangeRepresentation={handleChangeRepresentation}
                      handlePartnerOptionChange={handlePartnerOptionChange}
                    />
                  </ul>
                </>
              ))}
          </div>
        )}

        <div className="card card-bordered card-full">
          <AssistentsModalTableHeader title={t('Partners')} />

          {participants?.length > 0 &&
            participants
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((partner, index) => (
                <>
                  <ul
                    key={partner?.cif || index}
                    className={getRowClassName(index, participants)}
                  >
                    <AssistentsModalRow
                      index={index}
                      boardId={boardId}
                      societyId={societyId}
                      currentParticipant={partner}
                      participants={participants}
                      representatives={representatives}
                      partnerRepresentants={partnerRepresentants}
                      handleChangeAssists={handleChangeAssists}
                      handleChangeRepresentation={handleChangeRepresentation}
                      handlePartnerOptionChange={handlePartnerOptionChange}
                    />
                  </ul>
                </>
              ))}
        </div>
      </div>
    </div>
  );
};

export default AssistentsModalTable;
