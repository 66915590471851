import React from 'react';
import { store } from 'redux/store';

import { setModal } from 'modules/_shared/redux/modalActions';
import HoldingClassModal from 'modules/_shared/components/Modals/HoldingClass';
import actionTypes from 'constants/actionTypes';

export default function editHoldingClass({ data }) {
  store.dispatch(
    setModal(
      <HoldingClassModal action={actionTypes.EDIT} currentHoldingClass={data} />
    )
  );
}
