import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';
import PageListHeader from 'modules/_shared/components/Headers/PageListHeader';
import { setModal } from 'modules/_shared/redux/modalActions';
import { useDispatch } from 'react-redux';
import AddCommitteeModal from './AddCommitteeModal';

type Props = {
  societyId: string | undefined;
  societyName: string;
  committeesCount: number;
};

const CommitteesPageHeader: FC<Props> = ({
  societyId,
  societyName,
  committeesCount,
}) => {
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const handleAddCommittee = () => {
    if (societyId) {
      dispatch(setModal(<AddCommitteeModal societyId={societyId} />));
    }
  };

  return (
    <PageListHeader
      title={t('Committees')}
      subTitle={societyName}
      description={`${societyName || ''} ${t('HaveCommittees', {
        count: committeesCount,
      })}`}
      actions={[
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddCommittee}
        >
          {t('AddCommittee')}
        </button>,
      ]}
    />
  );
};

export default CommitteesPageHeader;
