import Loading from 'modules/_shared/components/Loading';
import PDFAdobeViewer from 'modules/_shared/components/PDFAdobeViewer';

import { useTranslate } from 'hooks/useTranslate';
import { useGetDocumentFrame } from '../hooks/useGetDocumentFrame';

type Props = {
  htmlContent: string;
};

const LetterTabDocumentPreviewView = ({ htmlContent }: Props) => {
  const { t } = useTranslate();
  const documentFrameResult = useGetDocumentFrame({ htmlContent });

  const { documentFrame } = documentFrameResult.data;

  const { isLoading } = documentFrameResult;

  return (
    <>
      {documentFrame && (
        <PDFAdobeViewer
          fileUrl={documentFrame}
          fileName={t('Document')}
          height="750px"
        />
      )}
      {isLoading && <Loading />}
    </>
  );
};

export default LetterTabDocumentPreviewView;
