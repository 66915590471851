/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ContentLoader from 'react-content-loader';
import { getTheme, themeColors } from 'utils/theme';

const rows = 5;
const rowHeight = 70;

const TableLoader = (props) => {
  const totalRows = props?.rows || rows;
  const showHeader = props?.notHeader || false;
  const totalHeight = totalRows * rowHeight + 60;

  const { notHeader, ...currentProps } = props;

  const { BODY_BACKGROUND, BODY_BACKGROUND_SECONDARY } =
    themeColors[getTheme()];

  return (
    <ContentLoader
      width="100%"
      height={totalHeight}
      foregroundColor={BODY_BACKGROUND}
      backgroundColor={BODY_BACKGROUND_SECONDARY}
      style={{ width: '100%' }}
      {...currentProps}
    >
      {/* Header */}
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="20" />
      {showHeader && (
        <>
          <rect x="0" y="40" rx="3" ry="3" width="100%" height="20" />
          <rect x="0" y="5" rx="3" ry="3" width="2.5%" height="50" />
          <rect x="27.5%" y="5" rx="3" ry="3" width="5%" height="50" />
          <rect x="67.5%" y="5" rx="3" ry="3" width="5%" height="50" />
          <rect x="87.5%" y="5" rx="3" ry="3" width="5%" height="50" />
          <rect x="97.5%" y="5" rx="3" ry="3" width="2.5%" height="50" />
        </>
      )}
      {/* Borders */}
      <rect x="0" y="4" rx="3" ry="3" width="2" height={totalHeight - 6} />
      <rect x="99.8%" y="4" rx="3" ry="3" width="2" height={totalHeight - 6} />
      <rect x="0" y={totalHeight - 2} rx="3" ry="3" width="100%" height="2" />
      {/* Rows */}
      {Array(totalRows)
        .fill()
        .map((_, row) => (
          <React.Fragment key={row.toString()}>
            {showHeader && (
              <>
                <circle cx="4%" cy={`${95 + row * rowHeight}`} r="2%" />
                <circle cx="93.5%" cy={`${95 + row * rowHeight}`} r="1%" />
                <circle cx="97.0%" cy={`${95 + row * rowHeight}`} r="1%" />
              </>
            )}
            <rect
              x="7.5%"
              y={`${87 + row * rowHeight}`}
              rx="3"
              ry="3"
              width="20%"
              height="15"
            />
            <rect
              x="32.5%"
              y={`${87 + row * rowHeight}`}
              rx="3"
              ry="3"
              width="35%"
              height="15"
            />
            <rect
              x="72.5%"
              y={`${87 + row * rowHeight}`}
              rx="3"
              ry="3"
              width="15%"
              height="15"
            />
            <rect
              x="1%"
              y={`${130 + row * rowHeight}`}
              rx="3"
              ry="3"
              width="98%"
              height="2"
            />
          </React.Fragment>
        ))}
    </ContentLoader>
  );
};

export default TableLoader;
