import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';
import { Modal } from 'react-bootstrap';

const DeletePlanBeneficiariesBody: FC = () => {
  const { t } = useTranslate();
  return (
    <>
      <Modal.Body>
        <div className="caption-text">
          {t('AreYouSureAboutDelAllBeneficiariesOfPlan')}
        </div>
        <div className="caption-text">{t('ThisProcedureCantUndo')}</div>
      </Modal.Body>
    </>
  );
};

export default DeletePlanBeneficiariesBody;
