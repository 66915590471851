export const actionTypes = {
  RESET_STATE_FULL: 'RESET_STATE_FULL',
  RESET_STATE_SOCIETY: 'RESET_STATE_SOCIETY',
};

export const resetStateFull = () => ({
  type: actionTypes.RESET_STATE_FULL,
});

export const resetStateSociety = () => ({
  type: actionTypes.RESET_STATE_SOCIETY,
});
