import { useTranslate } from 'hooks/useTranslate';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useUserValidation from 'hooks/useUserValidation';
import toggleSideNav from 'modules/_shared/redux/sideNavActions';

import 'assets/styles/Layouts.scss';
import './SideNav.scss';

function MainMenu({ dispatch }) {
  const { t } = useTranslate();

  const { user } = useSelector((state) => state);

  const userValidations = useUserValidation(user);

  return (
    <Accordion id="main-menu__collapsed" className="bg-transparent">
      {userValidations.hasAdministrated && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-opt" />
                </span>
                <span className="nk-menu-text">{t('Administrated')}</span>
              </a>
            </li>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to="/cuadro-administradas"
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">
                    {t('AdministratedTable')}
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to="/lista-administradas"
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('AdministratedList')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to="/diagrama-administradas"
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">
                    {t('AdministratedChart')}
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to="/kanban-administradas"
                  className="nk-menu-link is-disable"
                >
                  <span className="nk-menu-text">
                    {t('AdministratedKanban')}
                  </span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {userValidations.hasParticipated && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-layers" />
                </span>
                <span className="nk-menu-text">{t('Participated')}</span>
              </a>
            </li>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="1">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to="/cuadro-participadas"
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('ParticipatedTable')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to="/lista-participadas"
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('ParticipatedList')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to="/diagrama-participadas"
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('ParticipatedChart')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to="/kanban-participadas"
                  className="nk-menu-link is-disable"
                >
                  <span className="nk-menu-text">
                    {t('ParticipatedKanban')}
                  </span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {userValidations.hasBeneficiated && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-star" />
                </span>
                <span className="nk-menu-text">{t('Plans')}</span>
              </a>
            </li>
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="2">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to="/lista-beneficiadas"
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('PlansList')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}
    </Accordion>
  );
}
function mapStateToProps({ showSideNav }) {
  return { showSideNav };
}

export default connect(mapStateToProps)(MainMenu);
