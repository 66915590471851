import { useTranslate } from 'hooks/useTranslate';
import { FC } from 'react';

type Representative = {
  name: string;
  cif: string;
  email: string;
  address: string;
  nationality: string;
};

type Props = {
  representative: Representative;
  setRepresentative: (representative: Representative) => void;
};

const RepresentativeSection: FC<Props> = ({
  representative,
  setRepresentative,
}) => {
  const { t } = useTranslate();

  const handleChangeName = (event: any) => {
    const newName = event.target.value;
    const newRepresentative = { ...representative, name: newName };
    setRepresentative(newRepresentative);
  };

  const handleChangeCif = (event: any) => {
    const newCif = event.target.value;
    const newRepresentative = { ...representative, cif: newCif };
    setRepresentative(newRepresentative);
  };

  const handleChangeEmail = (event: any) => {
    const newEmail = event.target.value;
    const newRepresentative = { ...representative, email: newEmail };
    setRepresentative(newRepresentative);
  };

  const handleChangeAddress = (event: any) => {
    const newAddress = event.target.value;
    const newRepresentative = { ...representative, address: newAddress };
    setRepresentative(newRepresentative);
  };

  const handleChangeNationality = (event: any) => {
    const newNationality = event.target.value;
    const newRepresentative = {
      ...representative,
      nationality: newNationality,
    };
    setRepresentative(newRepresentative);
  };

  return (
    <div className="row gy-4">
      <div className="col-md-12 d-flex align-items-center">
        <h5 className="nk-block-title mb-0">
          {t('LegalPartnerRepresentative')}
        </h5>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div>
            <label
              className="form-label"
              htmlFor="director-representative-name"
            >
              {t('Name')}
            </label>
            <input
              type="text"
              id="director-representative-name"
              className="form-control form-control-lg"
              value={representative?.name || ''}
              onChange={handleChangeName}
              placeholder={t('Name')}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div>
            <label className="form-label" htmlFor="director-representative-cif">
              {t('Cif')}
            </label>
            <input
              type="text"
              id="director-representative-cif"
              className="form-control form-control-lg"
              value={representative?.cif || ''}
              onChange={handleChangeCif}
              placeholder={t('Cif')}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div>
            <label
              className="form-label"
              htmlFor="director-representative-email"
            >
              {t('Email')}
            </label>
            <input
              type="text"
              id="director-representative-email"
              className="form-control form-control-lg"
              value={representative?.email || ''}
              onChange={handleChangeEmail}
              placeholder={t('Email')}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div>
            <label
              className="form-label"
              htmlFor="director-representative-address"
            >
              {t('Address')}
            </label>
            <input
              type="text"
              id="director-representative-address"
              className="form-control form-control-lg"
              value={representative?.address || ''}
              onChange={handleChangeAddress}
              placeholder={t('Address')}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div>
            <label
              className="form-label"
              htmlFor="director-representative-nationality"
            >
              {t('Nationality')}
            </label>
            <input
              type="text"
              id="director-representative-nationality"
              className="form-control form-control-lg"
              value={representative?.nationality || ''}
              onChange={handleChangeNationality}
              placeholder={t('Nationality')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RepresentativeSection;
