import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import confetti from 'canvas-confetti';
import i18n from 'i18n/config';

import './Alert.scss';
import './AlertWithConfetti.scss';

const confettiDelay = 100;

function fireConfetti() {
  const count = 200;
  const defaults = {
    origin: { y: 0.45 },
    gravity: 0.7,
    zIndex: 1200,
  };

  function fire(particleRatio, opts) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
      colors: [
        '#FFF', // white
        '#6576ff',
        '#6576ff',
        '#6576ff',
        '#6576ff',
        '#14BB69',
        '#14BB69',
        '#50BAE2',
        '#A4FFF5',
        '#FFBF28',
      ],
    });
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

function getVestingMessage(
  isVestingToday,
  vestingDate,
  vestingUnits,
  vestingType,
  vestingSociety
) {
  let vestingTodayWithUnits = '';
  let vestingTodayWithoutUnits = '';

  if (isVestingToday) {
    vestingTodayWithUnits = `Hoy has vesteado <b>${vestingUnits}</b> unidades de ${vestingType} de la Sociedad <b>${vestingSociety}</b>.`;
    vestingTodayWithoutUnits = `Hoy vesteaste nuevas unidades de ${vestingType} de la Sociedad <b>${vestingSociety}</b>.`;
  } else {
    vestingTodayWithUnits = `El día <b>${vestingDate}</b> vesteaste <b>${vestingUnits}</b> unidades de ${vestingType} de la Sociedad <b>${vestingSociety}</b>.`;
    vestingTodayWithoutUnits = `El día <b>${vestingDate}</b> vesteaste nuevas unidades de ${vestingType} de la Sociedad <b>${vestingSociety}</b>.`;
  }

  const vestingMessage = vestingUnits
    ? vestingTodayWithUnits
    : vestingTodayWithoutUnits;

  return vestingMessage;
}

const AlertWithConfetti = ({ data }) => {
  const {
    isVestingToday,
    vestingDate,
    vestingUnits,
    vestingType,
    vestingSociety,
    onShare,
    onClose,
  } = data;

  const myAlert = withReactContent(Swal);

  myAlert
    .fire({
      icon: 'success',
      title: i18n.t('Congratulations'),
      html: getVestingMessage(
        isVestingToday,
        vestingDate,
        vestingUnits,
        vestingType,
        vestingSociety
      ),
      allowOutsideClick: false,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#6576ff',
      cancelButtonColor: '#1ee0ac',
      cancelButtonText: i18n.t('ToShare'),
    })
    .then(async (result) => {
      if (result.isDismissed) {
        onShare();
      }
      onClose();
    });

  useEffect(() => {
    setTimeout(() => fireConfetti(), confettiDelay);
  }, []);

  return <></>;
};

export default AlertWithConfetti;
