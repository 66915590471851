/* eslint-disable react/jsx-props-no-spreading */
import { useTranslate } from 'hooks/useTranslate';
import InputCharCounter from 'modules/_shared/components/InputCharCounter';
import { ChangeEvent, FC, useState } from 'react';

type DashboardEditContentStep2Props = {
  onChange: (type: string) => void;
};

const DashboardEditContentStep2: FC<DashboardEditContentStep2Props> = ({
  onChange,
}) => {
  const { t } = useTranslate();

  const [contentTitle, setContentTitle] = useState<string>('');

  const handleChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setContentTitle(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className="col-12 mb-4">
      <h6 className="mb-3">{t('EnterTheTitle')}</h6>

      <input
        type="text"
        className="form-control"
        value={contentTitle}
        maxLength={50}
        onChange={handleChangeTitle}
      />

      <InputCharCounter value={contentTitle} limit={50} />
    </div>
  );
};

export default DashboardEditContentStep2;
