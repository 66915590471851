import { useEffect, useState } from 'react';
import { CommitteeMember } from 'types';
import memberStatus from '../constants/memberStatus';
import { getMemberConditionLabel } from '../utils/getMemberConditionLabel';
import { getMemberTypeLabel } from '../utils/getMemberTypeLabel';

type Props = {
  member: CommitteeMember;
};

const getStatusData = (member: CommitteeMember) => {
  const status = memberStatus.find(
    (status) => status.value === member?.memberStatus
  );

  const statusLabel = status ? status.label : '-';
  const statusBadge = status ? status.badge : '-';

  return { statusLabel, statusBadge };
};

export default function useGetSelectedValues({ member }: Props) {
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [statusBadge, setStatusBadge] = useState<string>('');
  const [conditionLabel, setConditionLabel] = useState<string>('');
  const [memberTypeLabel, setMemberTypeLabel] = useState<string>('');

  useEffect(() => {
    const { statusLabel, statusBadge } = getStatusData(member);
    setStatusLabel(statusLabel);
    setStatusBadge(statusBadge);

    const conditonLabel = getMemberConditionLabel(member);
    setConditionLabel(conditonLabel);

    const memberTypeLabel = getMemberTypeLabel(member);
    setMemberTypeLabel(memberTypeLabel);
  }, [member]);

  return {
    data: { statusLabel, statusBadge, conditionLabel, memberTypeLabel },
    isLoading: false,
    isError: false,
    actions: {},
  };
}
