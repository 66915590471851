import societyGuestsRoutes from '../constants/societyGuestsRoutes';
import societyGuestsPermissions from '../constants/societyGuestsPermissions';

type GuestPermission = {
  name: string;
  label: string;
  enabled: boolean;
  checked: boolean;
};

export const getDefaultGuestRoute = (permissions: any, societyId: string) => {
  const firstPermission = societyGuestsPermissions.find(
    (permission: GuestPermission) => permissions?.[permission.name]?.enabled
  );

  const prefixRoute =
    societyGuestsRoutes[
      firstPermission?.name as keyof typeof societyGuestsRoutes
    ];

  if (prefixRoute) {
    return prefixRoute + societyId;
  }

  return '/';
};
