/* eslint-disable react/no-array-index-key */
import { FC } from 'react';
import { useTranslate } from 'hooks/useTranslate';

type DetailsTableSectionProps = {
  title?: string;
  columns?: string[];
  data?: { label: string; value: string | undefined }[];
};

const DetailsTableSection: FC<DetailsTableSectionProps> = ({
  title,
  columns,
  data,
}) => {
  const { t } = useTranslate();

  return (
    <section className="card-inner">
      {title && <h6 className="overline-title-alt mb-2">{title}</h6>}

      <div className="row card card-bordered card-preview">
        <table className="table table-striped">
          {columns && columns.length > 0 && (
            <thead>
              <tr>
                {columns.map((column) => (
                  <th scope="col" key={column}>
                    {t(column)}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          {data && data.length > 0 && (
            <tbody>
              {data?.map((item, index) => (
                <tr key={`member-details-row-${index}`}>
                  <td className="fw-bold">{item.label}</td>
                  <td>{item.value || '-'}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </section>
  );
};

export default DetailsTableSection;
