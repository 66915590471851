/* eslint-disable no-plusplus */
import i18n from 'i18n/config';
import cancelationTypes from 'constants/cancelationTypes';

const getMainDecimalConsolidation = (beneficiary, plan) => {
  const hasDecimalConsolidation =
    beneficiary?.customConditions &&
    'hasDecimalConsolidation' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.hasDecimalConsolidation
      : plan?.hasDecimalConsolidation;

  return hasDecimalConsolidation;
};

const getMainCliff = (beneficiary, plan) => {
  const cliff =
    beneficiary?.customConditions && 'cliff' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.cliff
      : plan?.cliff;

  return cliff;
};

const getMainConsolidation = (beneficiary, plan) => {
  const consolidation =
    beneficiary?.customConditions &&
    'consolidation' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.consolidation
      : plan?.consolidation;

  return consolidation;
};

const getMainVestingPeriod = (beneficiary, plan) => {
  const vestingPeriod =
    beneficiary?.customConditions &&
    'vestingPeriod' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.vestingPeriod
      : plan?.vestingPeriod;

  return vestingPeriod;
};

const getMainConsolidationPeriods = (beneficiary, plan) => {
  const consolidationPeriods =
    beneficiary?.customConditions &&
    'consolidationPeriods' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.consolidationPeriods
      : plan?.consolidationPeriods;

  return consolidationPeriods;
};

const getMainHasEndMonthConsolidation = (beneficiary, plan) => {
  const hasEndMonthConsolidation =
    beneficiary?.customConditions &&
    'hasEndMonthConsolidation' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.hasEndMonthConsolidation
      : plan?.hasEndMonthConsolidation || false;

  return hasEndMonthConsolidation;
};

export const setPeriodsPercent = (periods, vesting, consolidation, cliff) => {
  const months = periods; // store how many months consolidates in period i
  let totalMonthsConsolidated = 0;

  const firstConsolidationPeriod =
    cliff === 0 ? 0 : Math.floor((cliff - 1) / consolidation);

  for (let i = 0; i < periods.length; i += 1) {
    const periodMonths =
      i === periods.length - 1 && vesting % consolidation !== 0
        ? vesting % consolidation
        : consolidation;

    if (i < firstConsolidationPeriod) {
      months[i] = 0;
    } else {
      months[i] = periodMonths;
    }

    totalMonthsConsolidated += months[i];
  }
  const result = months.reduce((acc, curr) => {
    acc.push(
      totalMonthsConsolidated ? (curr * 100) / totalMonthsConsolidated : 0
    );
    return acc;
  }, []);

  return result;
};

/**
 * This function is duplicated, it is also in the backend. If you change it here, you must change it in the backend.
 */
export const getConsolidationPeriods = (beneficiary, plan) => {
  if (!beneficiary || !plan) return [];

  const consolidationStatus = {
    COMPLETED: i18n.t('Completed'),
    PENDING: i18n.t('Pending'),
    CANCELED: i18n.t('Canceled'),
  };

  const cliff = getMainCliff(beneficiary, plan);
  const consolidation = getMainConsolidation(beneficiary, plan);
  const vestingPeriod = getMainVestingPeriod(beneficiary, plan);
  const consolidationPeriods = getMainConsolidationPeriods(beneficiary, plan);
  const hasEndMonthConsolidation = getMainHasEndMonthConsolidation(
    beneficiary,
    plan
  );

  const units = beneficiary?.sharesCount?.future || 0;

  const cliffDate = new Date(beneficiary?.planStartDate);
  cliffDate.setMonth(cliffDate.getMonth() + (Number(cliff) || 0));

  const consolidationDate = new Date(beneficiary?.planStartDate);
  consolidationDate.setMonth(
    consolidationDate.getMonth() + Number(vestingPeriod || 0)
  );

  const isCustomDilutionByUnits =
    beneficiary?.customConditions?.isCustomDilutionByUnits || false;

  let periods;

  if (consolidation < 1) {
    const finalPeriod = {
      percent: cliffDate < consolidationDate ? 100 : 0,
      date: consolidationDate,
    };
    periods = [finalPeriod];
  } else {
    let periodPercents;

    if (!consolidationPeriods?.length) {
      const count = Math.ceil(vestingPeriod / consolidation);
      periodPercents = Array(count);
      periodPercents = setPeriodsPercent(
        Array(count),
        vestingPeriod,
        consolidation,
        cliff || 0
      );
    } else periodPercents = consolidationPeriods;

    periods = periodPercents.map((period, index) => {
      let months = (index + 1) * consolidation;

      if (
        index === periodPercents.length - 1 &&
        vestingPeriod % consolidation !== 0
      ) {
        months = index * consolidation + (vestingPeriod % consolidation);
      }

      const finalDate = new Date(beneficiary.planStartDate);

      if (hasEndMonthConsolidation) {
        finalDate.setMonth(finalDate.getMonth() + months, 0);
      } else {
        finalDate.setMonth(finalDate.getMonth() + months);
      }

      const periodItem = { date: finalDate };

      if (isCustomDilutionByUnits) {
        periodItem.units = period;
      } else {
        periodItem.percent = period;
      }

      return { ...periodItem };
    });
  }

  let totalConsolidated = 0;
  let addedCancelationPeriod = false;
  let finalConsolidatedUnits = 0;
  let decimalsAccumulator = 0;

  const result = [];

  for (let i = 0; i < periods.length; i += 1) {
    if (beneficiary.isCanceled) {
      if (
        !addedCancelationPeriod &&
        new Date(beneficiary.cancelationDate).getTime() <=
          periods[i].date.getTime()
      ) {
        if (beneficiary?.cancelationOption === cancelationTypes.PARTIAL) {
          finalConsolidatedUnits = totalConsolidated;
        } else if (
          beneficiary?.cancelationOption === cancelationTypes.CONSOLIDATED
        ) {
          finalConsolidatedUnits = units;
        }
        result.push({
          date: beneficiary.cancelationDate,
          vestedUnits: 0,
          totalVestedUnits: finalConsolidatedUnits,
          status: consolidationStatus.CANCELED,
        });
        result.push({
          date: periods[i].date,
          vestedUnits: 0,
          totalVestedUnits: finalConsolidatedUnits,
          status: consolidationStatus.CANCELED,
        });
        addedCancelationPeriod = true;
        totalConsolidated = finalConsolidatedUnits;
      } else if (
        !addedCancelationPeriod &&
        new Date(beneficiary.cancelationDate) > periods[i].date
      ) {
        let status;
        if (new Date(Date.now()) >= periods[i].date) {
          status = consolidationStatus.COMPLETED;
        } else {
          status = consolidationStatus.PENDING;
        }

        const vested = isCustomDilutionByUnits
          ? periods[i].units
          : (periods[i].percent * units) / 100;

        let currentVested = Math.floor(vested);
        decimalsAccumulator += vested - currentVested;

        const hasDecimalConsolidation = getMainDecimalConsolidation(
          beneficiary,
          plan
        );

        if (hasDecimalConsolidation && decimalsAccumulator >= 1) {
          decimalsAccumulator -= 1;
          currentVested += 1;
        }

        const vestedUnits =
          i === periods.length - 1 ? units - totalConsolidated : currentVested;

        result.push({
          date: periods[i].date,
          vestedUnits,
          totalVestedUnits: totalConsolidated + vestedUnits,
          status,
        });
        totalConsolidated += vestedUnits;
      } else {
        result.push({
          date: periods[i].date,
          vestedUnits: 0,
          totalVestedUnits: totalConsolidated,
          status: consolidationStatus.CANCELED,
        });
      }
    } else {
      let status;
      if (new Date().getTime() >= periods[i].date.getTime())
        status = consolidationStatus.COMPLETED;
      else status = consolidationStatus.PENDING;

      const vested = isCustomDilutionByUnits
        ? periods[i].units
        : (periods[i].percent * units) / 100;

      let currentVested = Math.floor(vested);
      decimalsAccumulator += vested - currentVested;

      const hasDecimalConsolidation = getMainDecimalConsolidation(
        beneficiary,
        plan
      );

      if (hasDecimalConsolidation && decimalsAccumulator >= 1) {
        decimalsAccumulator -= 1;
        currentVested += 1;
      }

      const vestedUnits =
        i === periods.length - 1 ? units - totalConsolidated : currentVested;

      result.push({
        date: periods[i].date,
        vestedUnits,
        totalVestedUnits: totalConsolidated + vestedUnits,
        status,
      });

      totalConsolidated += vestedUnits;
    }
  }

  return result;
};

export const sumConsolidationPeriods = ({
  initialDate,
  finalDate,
  cliff,
  vesting,
  consolidationPeriods,
  consolidation,
  isCustomDilutionByUnits,
  totalUnits,
  hasEndMonthConsolidation,
}) => {
  const cliffDate = new Date(initialDate);
  cliffDate.setMonth(cliffDate.getMonth() + (Number(cliff) || 0));

  let periods;

  if (consolidation < 1) {
    const finalPeriod = {
      percent: cliffDate < finalDate ? 100 : 0,
      date: finalDate,
    };
    periods = [finalPeriod];
  } else {
    let periodPercents;

    if (!consolidationPeriods?.length) {
      const count = Math.ceil(vesting / consolidation);
      periodPercents = Array(count);
      periodPercents = setPeriodsPercent(
        Array(count),
        vesting,
        consolidation,
        cliff || 0
      );
    } else {
      periodPercents = consolidationPeriods;
    }

    periods = periodPercents.map((period, index) => {
      let months = (index + 1) * consolidation;
      if (
        index === periodPercents.length - 1 &&
        vesting % consolidation !== 0
      ) {
        months = index * consolidation + (vesting % consolidation);
      }
      const endDate = new Date(initialDate);

      if (hasEndMonthConsolidation) {
        endDate.setMonth(endDate.getMonth() + months, 0);
      } else {
        endDate.setMonth(endDate.getMonth() + months);
      }

      const periodItem = { date: endDate };

      if (isCustomDilutionByUnits) {
        periodItem.units = period;
      } else {
        periodItem.percent = period;
      }

      return { ...periodItem };
    });
  }

  if (isCustomDilutionByUnits) {
    let vestedUnits = 0;

    for (let i = 0; i < periods.length; i++) {
      if (
        new Date(finalDate) > periods[i].date ||
        new Date(finalDate).getTime() === new Date(periods[i].date).getTime()
      ) {
        vestedUnits += periods[i].units;
      }
    }
    const percent = (vestedUnits * 100) / totalUnits;

    return +percent.toFixed(2);
  }

  let percent = 0;

  for (let i = 0; i < periods.length; i++) {
    if (
      new Date(finalDate) > periods[i].date ||
      new Date(finalDate).getTime() === new Date(periods[i].date).getTime()
    ) {
      percent += periods[i].percent;
    }
  }

  return +percent.toFixed(2);
};

export const getBeneficiariesConsolidationPeriods = (beneficiaries, plans) => {
  if (!plans.length || !beneficiaries.length) return null;

  const beneficiariesSummary = beneficiaries.reduce((acc, beneficiary) => {
    const currentPlan = plans.find((plan) => plan['_id'] === beneficiary?.plan);
    const consolidationPeriods = getConsolidationPeriods(
      beneficiary,
      currentPlan
    );

    acc[beneficiary['_id']] = consolidationPeriods;

    return acc;
  }, {});

  return beneficiariesSummary;
};

export function calculateConsolidationPercent(date, beneficiary, plan) {
  const planStartDate = new Date(beneficiary?.planStartDate);
  const endDate = new Date(beneficiary?.planStartDate);

  const cliff = getMainCliff(beneficiary, plan);
  const consolidation = getMainConsolidation(beneficiary, plan);
  const vestingPeriod = getMainVestingPeriod(beneficiary, plan);
  const consolidationPeriods = getMainConsolidationPeriods(beneficiary, plan);

  const isCustomDilutionByUnits =
    beneficiary?.customConditions?.isCustomDilutionByUnits || false;

  const hasEndMonthConsolidation =
    beneficiary?.customConditions &&
    'hasEndMonthConsolidation' in beneficiary?.customConditions
      ? beneficiary?.customConditions?.hasEndMonthConsolidation
      : plan?.hasEndMonthConsolidation || false;

  const totalUnits = beneficiary?.sharesCount?.future || 0;

  if (hasEndMonthConsolidation) {
    endDate.setMonth(endDate.getMonth() + Number(vestingPeriod || 0), 0);
  } else {
    endDate.setMonth(endDate.getMonth() + Number(vestingPeriod || 0));
  }

  const cliffDate = new Date(beneficiary?.planStartDate);
  cliffDate.setMonth(cliffDate.getMonth() + (Number(cliff) || 0));

  let percent;
  if (cliffDate > new Date(date)) {
    percent = 0;
  } else if (planStartDate > new Date(date)) {
    percent = 0;
  } else if (
    new Date(date) > endDate ||
    new Date(date).getTime() === endDate.getTime()
  ) {
    percent = 100;
  } else if (plan) {
    percent = sumConsolidationPeriods({
      initialDate: planStartDate,
      finalDate: new Date(date),
      cliff,
      vesting: vestingPeriod,
      consolidationPeriods,
      consolidation,
      isCustomDilutionByUnits,
      totalUnits,
      hasEndMonthConsolidation,
    });
  }
  return percent;
}
